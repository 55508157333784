import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ClientCommitmentCategory,
  ClientCommitmentCategoriesResult,
  getClientCommitmentCategories,
  createClientCommitmentCategory,
  updateClientCommitmentCategory,
  deleteClientCommitmentCategory,
} from "api/clientCommitmentCategoryAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface ClientCommitmentCategoryState {
  clientCommitmentCategoriesById: Record<number, ClientCommitmentCategory>;
  clientCommitmentCategoryList: number[];
  isLoading: boolean;
  isLoadingAdd: boolean;
  subClientCommitmentCategoryisLoading: boolean;
  isLoaded: boolean;
  error: string | null;
}

const ClientCommitmentCategoryInitialState: ClientCommitmentCategoryState = {
  clientCommitmentCategoriesById: {},
  clientCommitmentCategoryList: [],
  isLoading: false,
  isLoadingAdd: false,
  subClientCommitmentCategoryisLoading: false,
  isLoaded: false,
  error: null,
};

function startLoading(state: ClientCommitmentCategoryState) {
  state.isLoading = true;
}

function startLoadingAdd(state: ClientCommitmentCategoryState) {
  state.isLoadingAdd = true;
}

function loadingFailed(
  state: ClientCommitmentCategoryState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const clientCommitmentCategories = createSlice({
  name: "clientCommitmentCategories",
  initialState: ClientCommitmentCategoryInitialState,
  reducers: {
    getClientCommitmentCategoriesStart: startLoading,
    getClientCommitmentCategoriesSuccess(
      state,
      { payload }: PayloadAction<ClientCommitmentCategoriesResult>
    ) {
      const { clientCommitmentCategories } = payload;

      state.isLoading = false;
      state.error = null;
      state.isLoaded = true;

      clientCommitmentCategories.forEach((clientCommitmentCategory) => {
        state.clientCommitmentCategoriesById[
          clientCommitmentCategory.ClientCommitmentCategoryID
        ] = clientCommitmentCategory;
      });

      state.clientCommitmentCategoryList = clientCommitmentCategories.map(
        (clientCommitmentCategory) =>
          clientCommitmentCategory.ClientCommitmentCategoryID
      );
    },
    getClientCommitmentCategoriesFailure: loadingFailed,
    createClientCommitmentCategoryStart: startLoadingAdd,
    createClientCommitmentCategorySuccess(
      state,
      { payload }: PayloadAction<ClientCommitmentCategory>
    ) {
      const { ClientCommitmentCategoryID } = payload;
      state.clientCommitmentCategoriesById[ClientCommitmentCategoryID] =
        payload;
      state.clientCommitmentCategoryList.push(ClientCommitmentCategoryID);

      state.isLoading = false;
      state.isLoadingAdd = false;
      state.error = null;
    },
    updateClientCommitmentCategorySuccess(
      state,
      { payload }: PayloadAction<ClientCommitmentCategory>
    ) {
      const { ClientCommitmentCategoryID } = payload;

      state.clientCommitmentCategoriesById[ClientCommitmentCategoryID] =
        payload;
      //state.clientCommitmentCategoryList.push(ClientCommitmentCategoryID);

      state.isLoading = false;
      state.error = null;
    },
    deleteClientCommitmentCategorySuccess(
      state,
      { payload }: PayloadAction<number>
    ) {
      const ClientCommitmentCategoryID = payload;
      delete state.clientCommitmentCategoriesById[ClientCommitmentCategoryID];
      state.clientCommitmentCategoryList =
        state.clientCommitmentCategoryList.filter(
          (item) => item !== ClientCommitmentCategoryID
        );

      state.isLoading = false;
      state.error = null;
    },
    createClientCommitmentCategoryFailure: loadingFailed,
  },
});

export const {
  getClientCommitmentCategoriesStart,
  getClientCommitmentCategoriesSuccess,
  getClientCommitmentCategoriesFailure,
  createClientCommitmentCategoryStart,
  createClientCommitmentCategorySuccess,
  updateClientCommitmentCategorySuccess,
  deleteClientCommitmentCategorySuccess,
  createClientCommitmentCategoryFailure,
} = clientCommitmentCategories.actions;

export default clientCommitmentCategories.reducer;

export const fetchClientCommitmentCategories =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getClientCommitmentCategoriesStart());
      const clientCommitmentCategories = await getClientCommitmentCategories(
        accessToken
      );
      dispatch(
        getClientCommitmentCategoriesSuccess(clientCommitmentCategories)
      );
    } catch (err: any) {
      dispatch(getClientCommitmentCategoriesFailure(err.toString()));
    }
  };

export const addClientCommitmentCategory =
  (
    accessToken: String,
    newClientCommitmentCategory: Partial<ClientCommitmentCategory>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientCommitmentCategoryStart());
      const clientCommitmentCategory = await createClientCommitmentCategory(
        accessToken,
        newClientCommitmentCategory
      );
      dispatch(createClientCommitmentCategorySuccess(clientCommitmentCategory));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push("/admin/clientCommitmentCategories"));
    } catch (err: any) {
      dispatch(createClientCommitmentCategoryFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updClientCommitmentCategory =
  (
    accessToken: String,
    clientCommitmentCategoryID: number,
    newClientCommitmentCategory: Partial<ClientCommitmentCategory>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientCommitmentCategoryStart());
      const clientCommitmentCategory = await updateClientCommitmentCategory(
        accessToken,
        clientCommitmentCategoryID,
        newClientCommitmentCategory
      );

      dispatch(updateClientCommitmentCategorySuccess(clientCommitmentCategory));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push("/admin/clientCommitmentCategories"));
    } catch (err: any) {
      dispatch(createClientCommitmentCategoryFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delClientCommitmentCategory =
  (accessToken: String, clientCommitmentCategoryID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientCommitmentCategoryStart());
      const result = await deleteClientCommitmentCategory(
        accessToken,
        clientCommitmentCategoryID
      );
      dispatch(
        deleteClientCommitmentCategorySuccess(clientCommitmentCategoryID)
      );
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
      dispatch(push("/admin/clientCommitmentCategories"));
    } catch (err: any) {
      dispatch(createClientCommitmentCategoryFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };
