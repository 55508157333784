import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CommitmentActionSummary,
  CommitmentActionSummarysResult,
  getCommitmentActionSummarys,
} from "api/commitments/homePage/commitmentActionSummaryAPI";
import { AppThunk } from "app/store";

interface CommitmentActionSummaryState {
  commitmentActionSummarysById: Record<number, CommitmentActionSummary>;
  commitmentActionSummaryList: number[];
  isLoading: boolean;
  error: string | null;
}

const CommitmentActionSummaryInitialState: CommitmentActionSummaryState = {
  commitmentActionSummarysById: {},
  commitmentActionSummaryList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: CommitmentActionSummaryState) {
  state.isLoading = true;
}

function loadingFailed(
  state: CommitmentActionSummaryState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const commitmentActionSummarys = createSlice({
  name: "commitmentActionSummarys",
  initialState: CommitmentActionSummaryInitialState,
  reducers: {
    getCommitmentActionSummarysStart: startLoading,
    getCommitmentActionSummarysSuccess(
      state,
      { payload }: PayloadAction<CommitmentActionSummarysResult>
    ) {
      const { commitmentActionSummarys } = payload;
      state.isLoading = false;
      state.error = null;

      commitmentActionSummarys.forEach((item) => {
        state.commitmentActionSummarysById[item.ItemID] = item;
      });

      state.commitmentActionSummaryList = commitmentActionSummarys.map(
        (item) => item.ItemID
      );
    },
    getCommitmentActionSummarysFailure: loadingFailed,
  },
});

export const {
  getCommitmentActionSummarysStart,
  getCommitmentActionSummarysSuccess,
  getCommitmentActionSummarysFailure,
} = commitmentActionSummarys.actions;

export default commitmentActionSummarys.reducer;

export const fetchCommitmentActionSummarys =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCommitmentActionSummarysStart());
      const commitmentActionSummarys = await getCommitmentActionSummarys(
        accessToken
      );
      dispatch(getCommitmentActionSummarysSuccess(commitmentActionSummarys));
    } catch (err: any) {
      dispatch(getCommitmentActionSummarysFailure(err.toString()));
    }
  };
