import axiosClient from "../axiosClient";

export interface Issue {
  IssueID: number;
  IssueName: string;
  IssueDescription: string;
  ParentIssueID: number;
  ClientID: number;
  InteractionCount: number;
  LatestInteraction: Date;
}

export interface IssuesResult {
  issues: Issue[];
}

export interface IssueTreeview extends Issue {
  expanded: boolean;
}

export async function getIssues(accessToken: String): Promise<IssuesResult> {
  const url = `/issue`;

  try {
    const { data } = await axiosClient.get<IssuesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

// COMMENT OUT FOR FUTURE CLEAN UP - DONE IN INTERSECTION

// export async function getIssuesByGrievance(
//   accessToken: String,
//   grievanceID: number
// ): Promise<IssuesResult> {
//   const url = `/issue/grievance/${grievanceID}`;

//   try {
//     const { data } = await axiosClient.get<IssuesResult>(url, {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//     });

//     return data;
//   } catch (err: any) {
//     throw err;
//   }
// }

// export async function getIssuesByInteraction(
//   accessToken: String,
//   interactionID: number
// ): Promise<IssuesResult> {
//   const url = `/issue/interaction/${interactionID}`;

//   try {
//     const { data } = await axiosClient.get<IssuesResult>(url, {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//     });

//     return data;
//   } catch (err: any) {
//     throw err;
//   }
// }

export async function createIssue(
  accessToken: String,
  itemData: Partial<Issue>,
  groupList: any[],
  contactList: any[]
): Promise<Issue> {
  const url = `/issue`;

  try {
    const { data } = await axiosClient.post<Issue>(
      url,
      { itemData: itemData, groupList: groupList, contactList: contactList },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateIssue(
  accessToken: String,
  issueID: number,
  itemData: Partial<Issue>,
  groupList: any[] | null,
  contactList: any[] | null
): Promise<Issue> {
  const url = `/issue/` + issueID;

  try {
    const { data } = await axiosClient.post<Issue>(
      url,
      { itemData: itemData, groupList: groupList, contactList: contactList },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteIssue(
  accessToken: String,
  issueID: number
): Promise<string> {
  const url = `/issue/` + issueID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
