import axiosClient from "./axiosClient";

export interface WorkHourChart {
  MonthSequenceID: number;
  YearNumber: number;
  MonthName: string;
  Indigenous: number;
  NonIndigenous: number;
}

export interface WorkHourChartsResult {
  workHours: WorkHourChart[];
}

export async function getWorkHourCharts(
  accessToken: String
): Promise<WorkHourChartsResult> {
  const url = `/charts/workhours`;
  try {
    const { data } = await axiosClient.get<WorkHourChartsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
