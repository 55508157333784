import { SsheForm } from "./SsheForm";

export const SsheCreatePage = () => {
  let ssheCreateView = (
    <div>
      <SsheForm />
    </div>
  );

  return <div id="sshe-create-page">{ssheCreateView}</div>;
};
