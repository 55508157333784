import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchProjects } from "./ProjectSlice";
import { useParams } from "react-router-dom";

import { ProjectForm } from "./ProjectForm";
import LoadingIndicator from "components/LoadingIndicator";

import { useTranslation } from "react-i18next";
import i18next from "i18next";

interface ProjDetProps {
  projectID: string;
}

export const ProjectDetailPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation(["objPlt", "strGen"]);

  // console.log("COMMON", i18next.getResourceBundle("en", "strGen"));
  //console.log("UPDOBJ", i18next.getResourceBundle("en", "objPlt"));
  // console.log("objPlttest", t("objPlt:objects.projectcomponent.fullname"));

  const {
    isLoading,
    error: projError,
    projectsById,
  } = useSelector((state: RootState) => state.projects);

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  // get project ID from url param and attempt to find project in store
  const { projectID } = useParams<ProjDetProps>();
  const projID = parseInt(projectID, 10);

  const project = projectsById[parseInt(projectID, 10)];

  useEffect(() => {
    (async () => {
      try {
        // can avoid going to the DB if we navigated from inventory
        if (!project) {
          if (clientId !== -1) {
            const accessToken = await getAccessTokenSilently({
              authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
              },
            });
            dispatch(fetchProjects(accessToken));
          }
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, project, dispatch, getAccessTokenSilently]);

  if (typeof projID != "number") {
    return (
      <div>
        <h1>{t("strGen:errors.wentwrong")}</h1>
        <div>{projectID} is not a valid Project ID</div>
      </div>
    );
  }

  if (projError) {
    return (
      <div>
        <h1>{t("strGen:errors.wentwrong")}</h1>
        <div>{projError.toString()}</div>
      </div>
    );
  }

  let projDetView =
    isLoading || clientIsLoading ? (
      <LoadingIndicator />
    ) : (
      <div>{project && <ProjectForm project={project} />}</div>
    );

  return <div id="project-detail-page">{projDetView}</div>;
};
