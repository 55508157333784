import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  NotificationType,
  NotificationTypesResult,
  getNotificationTypes,
} from "api/notificationTypeAPI";
import { AppThunk } from "app/store";

interface NotificationTypeState {
  notificationTypesById: Record<number, NotificationType>;
  notificationTypeList: number[];
  isLoading: boolean;
  error: string | null;
}

const NotificationTypeInitialState: NotificationTypeState = {
  notificationTypesById: {},
  notificationTypeList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: NotificationTypeState) {
  state.isLoading = true;
}

function loadingFailed(
  state: NotificationTypeState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const notificationTypes = createSlice({
  name: "notificationType",
  initialState: NotificationTypeInitialState,
  reducers: {
    getNotificationTypesStart: startLoading,
    getNotificationTypesSuccess(
      state,
      { payload }: PayloadAction<NotificationTypesResult>
    ) {
      const { notificationTypes } = payload;
      console.log("PAYLOAD", notificationTypes);
      state.isLoading = false;
      state.error = null;

      notificationTypes.forEach((notificationType) => {
        state.notificationTypesById[notificationType.NotificationTypeID] =
          notificationType;
      });

      state.notificationTypeList = notificationTypes.map(
        (notificationType) => notificationType.NotificationTypeID
      );
    },
    getNotificationTypesFailure: loadingFailed,
  },
});

export const {
  getNotificationTypesStart,
  getNotificationTypesSuccess,
  getNotificationTypesFailure,
} = notificationTypes.actions;

export default notificationTypes.reducer;

export const fetchNotificationTypes =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getNotificationTypesStart());
      const notificationTypes = await getNotificationTypes(accessToken);
      //console.log("RETURN", notificationTypes);
      dispatch(getNotificationTypesSuccess(notificationTypes));
    } catch (err: any) {
      dispatch(getNotificationTypesFailure(err.toString()));
    }
  };
