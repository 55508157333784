import { Employee, EmployeeNeedsReviewToggles } from "api/employeeAPI";
import { JobPosition } from "api/jobPositionAPI";

// Checks if the basic required fields are missing, or if there is a Raw value for Indigenous Group but no ID set on the record.
const checkForMissingBasicInfo = (empData: Employee): boolean => {
  return (
    !empData.CompanyEmployeeID ||
    !empData.CompanyID ||
    !empData.ProvinceState ||
    !empData.CountryName ||
    (!!empData.IndigenousGroupRaw && !empData.IndigenousGroupID)
  );
};

// Checks if there is a Job Position Set, and if it is an apprenticeable trade, if the employee is an apprentice, and if they are, if they have a year set.
const checkJobPositionAndApprenticeship = (
  empData: Employee,
  jobPositions: JobPosition[]
): boolean => {
  if (!empData.JobPositionID) return true;

  const isApprenticeable = jobPositions.find(
    (jp) => jp.JobPositionID === empData.JobPositionID
  )?.ApprenticeableTrade;
  if (!isApprenticeable) return false;

  return (
    !empData.ApprenticeStatus ||
    (empData.ApprenticeStatus === "Apprentice" && !empData.ApprenticeYear)
  );
};

// Checks that Gender is set, unless the DiversityDeclined toggle is set to true and the employee record has Declined as true.
const checkDiversityInfo = (
  empData: Employee,
  ft_ben_DiversityDeclined: boolean
): boolean => {
  return (
    !empData.GenderID &&
    (!ft_ben_DiversityDeclined || !empData.DiversityDeclined)
  );
};

// Checks if the employee needs review based on the required fields and the toggles set for the client.
export const checkEmployeeNeedsReview = (
  empData: Employee,
  toggles: EmployeeNeedsReviewToggles,
  jobPositions: JobPosition[]
): boolean => {
  if (checkForMissingBasicInfo(empData)) return true;

  if (
    toggles.ft_ben_JobPosition &&
    checkJobPositionAndApprenticeship(empData, jobPositions)
  ) {
    return true;
  }

  if (toggles.hasRegionTypes && !empData.RegionID) return true;

  if (checkDiversityInfo(empData, toggles.ft_ben_DiversityDeclined))
    return true;

  return false;
};
