import * as yup from "yup";
import { validateDate } from "utils/DateFunctions";
import { useTypedTranslation } from "utils/customHooks";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { PaymentPeriod } from "api/benefits/paymentPeriodAPI";

export const PaymentValidation = (paymentPeriod: PaymentPeriod | undefined) => {
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  const { ft_ben_Contract } = useSelector((state: RootState) => state.client);

  return yup.object({
    CompanyID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.payment.fields.paidto"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.payment.fields.paidto"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.company.name"),
        })
      ),
    ProjectID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.project.name"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.project.name"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:objects.project.name"),
        })
      ),
    ProjectComponentID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.projectcomponent.fullname"),
        })
      )
      .required(
        t("strGen:validation.relationalrequired", {
          fieldname1: t("objPlt:objects.project.name"),
          fieldname2: t("objPlt:objects.projectcomponent.name"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:objects.projectcomponent.fullname"),
        })
      ),
    ...(ft_ben_Contract &&
      !paymentPeriod && {
        ContractID: yup
          .number()
          .nullable()
          .typeError(
            t("strGen:validation.invalid", {
              fieldname: t("objBen:objects.contract.name"),
            })
          ),
      }),
    PaymentDate: yup
      .date()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.payment.fields.pmtdate"),
        })
      )
      .test(
        "PaymentDate",
        paymentPeriod
          ? t("strGen:validation.date.range", {
              fieldname1: t("objBen:objects.payment.fields.pmtdate"),
              fieldname2: t("objBen:objects.spendreporting.name"),
            })
          : t("strGen:validation.date.nofuturedate", {
              fieldname: t("objBen:objects.payment.fields.pmtdate"),
            }),
        (value) => (value ? validateDate(value, paymentPeriod) : false)
      )
      .typeError(t("strGen:validation.date.improperformat")),
    PaymentAmount: yup
      .number()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.payment.fields.pmtamount"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.payment.fields.pmtamount"),
        })
      ),
  });
};
