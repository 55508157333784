import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  WorkflowStage,
  WorkflowStagesResult,
  getWorkflowStages,
} from "api/workflowStageAPI";
import { AppThunk } from "app/store";

interface WorkflowStageState {
  workflowStagesById: Record<number, WorkflowStage>;
  workflowStageList: number[];
  isLoading: boolean;
  error: string | null;
}

const WorkflowStageInitialState: WorkflowStageState = {
  workflowStagesById: {},
  workflowStageList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: WorkflowStageState) {
  state.isLoading = true;
}

function loadingFailed(
  state: WorkflowStageState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const workflowStages = createSlice({
  name: "workflowstage",
  initialState: WorkflowStageInitialState,
  reducers: {
    getWorkflowStagesStart: startLoading,
    getWorkflowStagesSuccess(
      state,
      { payload }: PayloadAction<WorkflowStagesResult>
    ) {
      const { workflowStages } = payload;
      // console.log("PAYLOAD", workflowStages);
      state.isLoading = false;
      state.error = null;

      workflowStages.forEach((workflowStage) => {
        state.workflowStagesById[workflowStage.WorkflowStageID] = workflowStage;
      });

      state.workflowStageList = workflowStages.map(
        (workflowStage) => workflowStage.WorkflowStageID
      );
    },
    getWorkflowStagesFailure: loadingFailed,
  },
});

export const {
  getWorkflowStagesStart,
  getWorkflowStagesSuccess,
  getWorkflowStagesFailure,
} = workflowStages.actions;

export default workflowStages.reducer;

export const fetchWorkflowStages =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getWorkflowStagesStart());
      const workflowStages = await getWorkflowStages(accessToken);
      //console.log("RETURN", workflowStages);
      dispatch(getWorkflowStagesSuccess(workflowStages));
    } catch (err: any) {
      dispatch(getWorkflowStagesFailure(err.toString()));
    }
  };
