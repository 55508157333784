import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ReportEmbed,
  RefreshResponse,
  getEmbedToken,
  postReportRefresh,
} from "api/reportEmbedAPI";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { push } from "redux-first-history";
import { SnackBarConstants } from "utils/customHooks";

interface ReportEmbedState {
  reportEmbedToken: ReportEmbed | null;
  isLoading: boolean;
  isRefreshing: boolean;
  error: string | null;
}

const ReportEmbedInitialState: ReportEmbedState = {
  reportEmbedToken: null,
  isLoading: false,
  isRefreshing: false,
  error: null,
};

function startLoading(state: ReportEmbedState) {
  state.isLoading = true;
  state.reportEmbedToken = null;
}

function startRefresh(state: ReportEmbedState) {
  state.isRefreshing = true;
}

function loadingFailed(state: ReportEmbedState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.reportEmbedToken = null;
  state.error = action.payload;
}

function refreshFailed(state: ReportEmbedState, action: PayloadAction<string>) {
  state.isRefreshing = false;
  //state.error = action.payload;
}

const reportEmbed = createSlice({
  name: "reportEmbed",
  initialState: ReportEmbedInitialState,
  reducers: {
    getEmbedTokenStart: startLoading,
    getEmbedTokenSuccess(state, { payload }: PayloadAction<ReportEmbed>) {
      const reportEmbedToken = payload;
      state.isLoading = false;
      state.error = null;

      state.reportEmbedToken = reportEmbedToken;
    },
    getEmbedTokenFailure: loadingFailed,
    getReportRefreshStart: startRefresh,
    getReportRefreshSuccess(
      state,
      { payload }: PayloadAction<RefreshResponse>
    ) {
      state.isRefreshing = false;
    },
    getReportRefreshFailure: refreshFailed,
  },
});

export const {
  getEmbedTokenStart,
  getEmbedTokenSuccess,
  getEmbedTokenFailure,
  getReportRefreshStart,
  getReportRefreshSuccess,
  getReportRefreshFailure,
} = reportEmbed.actions;

export default reportEmbed.reducer;

export const fetchEmbedToken =
  (accessToken: String, moduleShortName: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getEmbedTokenStart());
      const embedToken = await getEmbedToken(accessToken, moduleShortName);
      dispatch(getEmbedTokenSuccess(embedToken));
    } catch (err: any) {
      dispatch(getEmbedTokenFailure(err.toString()));
    }
  };

export const refreshReport =
  (
    accessToken: String,
    moduleShortName: String,
    path: any,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getReportRefreshStart());
      const result = await postReportRefresh(accessToken, moduleShortName);
      dispatch(getReportRefreshSuccess(result));

      dispatch(
        openSnackBar(snackbarConstants.REPORT_REFRESH_SUCCESS, "success")
      );
      dispatch(push(path));
    } catch (err: any) {
      dispatch(getReportRefreshFailure(err.toString()));
      if (err.status === 429) {
        dispatch(
          openSnackBar(snackbarConstants.REFRESH_LIMIT_EXCEEDED, "error")
        );
      } else {
        dispatch(openSnackBar(err.toString(), "error"));
      }
    }
  };
