import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { IppFormHeader } from "components/IppFormHeader";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppTextField } from "components/IppTextField";
import { IppAutocomplete } from "components/IppAutocomplete";
import LoadingIndicator from "components/LoadingIndicator";
import { Prompt } from "react-router";
import {
  addActionReminder,
  updActionReminder,
  delActionReminder,
} from "./ActionReminderSlice";
import useActionSources from "components/ActionSources";
import { useTypedTranslation } from "utils/customHooks";

const PREFIX = "ActionReminderForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 650,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

const validationSchema = yup.object({
  ActionOffset: yup.number().required("# of days is required."),
  ActionSource: yup.string().required("Action Source is required"),
});

export const ActionReminderForm = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const ActionSources = useActionSources();
  const t = useTypedTranslation(["objCom", "objStk"]);

  let reminderData = props.actionReminder || {};

  const [isEditing, setIsEditing] = useState(
    reminderData.ActionReminderID ? false : true
  );
  const [isAdding, setIsAdding] = useState(
    reminderData.ActionReminderID ? false : true
  );

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const [interval, setInterval] = useState("");

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        dispatch(addActionReminder(accessToken, values));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  if (reminderData.ActionReminderID) {
    //Update case
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });

          dispatch(
            updActionReminder(accessToken, values.ActionReminderID, values)
          );
        } catch (e) {
          console.error(e);
        }
      })();
    };
  } else {
    reminderData.ActionSource = ActionSources[0];
    reminderData.ActionOffset = 7;
  }

  const handleDelete = () => {
    // function to delete current entry
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(delActionReminder(accessToken, reminderData.ActionReminderID));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const formik = useFormik({
    initialValues: reminderData,
    validationSchema: validationSchema,
    onSubmit: submitFunc,
  });

  const getOffsetText = () => {
    let text, color;
    if (formik.values.ActionOffset > 0) {
      text = `A reminder will be sent ${formik.values.ActionOffset} day(s) BEFORE the due date`;
      color = "primary"; // Color for "before" reminder
    } else if (formik.values.ActionOffset < 0) {
      text = `A reminder will be sent ${-formik.values
        .ActionOffset} day(s) AFTER the due date`;
      color = "secondary"; // Color for "after" reminder
    } else {
      text = "A reminder will be sent ON the due date";
      color = "textPrimary"; // Neutral color for "on the due date"
    }

    return (
      <Typography
        variant="body1"
        color={color}
        style={{
          fontWeight: 500,
          fontSize: "16px",
          padding: "4px 8px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {text}
      </Typography>
    );
  };

  let schedForm = clientIsLoading ? (
    <LoadingIndicator />
  ) : (
    <Root>
      {!isAdding && (
        <Prompt
          when={formik.dirty}
          message="You have unsaved changes; are you sure you want to leave this page?"
        />
      )}

      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" justifyContent="center">
          <Grid container className={classes.editForm} spacing={2}>
            <Grid item xs={12}>
              <Paper className={classes.boxSpace}>
                <Grid container spacing={1}>
                  <IppFormHeader
                    title="Reminder"
                    isEditing={isEditing}
                    isAdding={isAdding}
                    returnPath="/admin/actionReminder"
                  />
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      {`This form is used to determine when reminders will be sent
                      for ${t("objPlt:platformwide.action.name_other")}, ${t(
                        "objStk:objects.interaction.name_other"
                      )}, or ${t(
                        "objCom:objects.commitment.name_other"
                      )} that are
                      coming due. Select the type of reminder, and the # of days
                      before/after the due/applicable date.`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <IppAutocomplete
                      id="ActionSource"
                      options={ActionSources}
                      value={formik.values.ActionSource}
                      onChangeFunction={(event: ChangeEvent, newValue: any) => {
                        if (newValue) {
                          formik.setFieldValue("ActionSource", newValue);
                        } else {
                          formik.setFieldValue("ActionSource", "");
                        }
                      }}
                      textValueFunction={formik.values.ActionSource}
                      touchedFunction={formik.touched.ActionSource}
                      errorFunction={formik.errors.ActionSource}
                      label="Reminder Source"
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <IppTextField
                      id="ActionOffset"
                      label="# of Days"
                      value={formik.values.ActionOffset}
                      fieldType="number"
                      onChangeFunction={formik.handleChange}
                      errorsExpression={formik.errors.ActionOffset}
                      touchedExpression={formik.touched.ActionOffset}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    {getOffsetText()}
                  </Grid>

                  <Grid item xs={12}>
                    <IppFormButtons
                      isEditing={isEditing}
                      isAdding={isAdding}
                      setIsEditing={setIsEditing}
                      resetFunction={() => formik.resetForm()}
                      showDelete={true}
                      deleteFunction={() => handleDelete()}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Root>
  );

  return <div id="sched-form">{schedForm}</div>;
};
