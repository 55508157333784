import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchSuppliers } from "./SupplierSlice";
import { useParams } from "react-router-dom";
import { Alert } from "@mui/material";
import { SupplierForm } from "./SupplierForm";
import LoadingIndicator from "components/LoadingIndicator";
import { Trans, useTranslation } from "react-i18next";

interface SupplierDetProps {
  supplierID: string;
}

export const SupplierDetailPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation(["objStk", "objPlt", "strGen", "objCom"]);

  const {
    isLoading,
    error: suppError,
    errorList: suppErrorList,
    suppliersById,
  } = useSelector((state: RootState) => state.suppliers);

  // get supplier ID from url param and attempt to find company in store
  const { supplierID } = useParams<SupplierDetProps>();
  const itemID = parseInt(supplierID, 10);

  const supplier = suppliersById[parseInt(supplierID, 10)];

  useEffect(() => {
    (async () => {
      try {
        // can avoid going to the DB if we navigated from inventory
        if (
          !supplier
          //!supplier.PersonResponsibleName ||
          // !supplier.SupplierStatusTypeName
        ) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchSuppliers(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [supplier, dispatch, getAccessTokenSilently]);

  let supplierDetView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      {supplier && (
        <>
          {suppError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              <span>
                <b>{t("strGen:errors.wentwrong")}</b> - {suppError.toString()}
                {suppErrorList && (
                  <ul>
                    {suppErrorList.map((error) => {
                      return (
                        <li key={error.param}>
                          <b>{error.param}:</b> {error.msg}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </span>
            </Alert>
          )}
          {!itemID && (
            <Alert severity="error" sx={{ mb: 2 }}>
              <div>
                <Trans
                  i18nKey="strGen:errors.invalidid"
                  /* eslint-disable-next-line jsx-a11y/heading-has-content */
                  components={[<h1 />, <div />]}
                  values={{
                    variable: supplierID,
                    fieldname: t("objSlr:objects.supplier.name"),
                  }}
                />
              </div>
            </Alert>
          )}
          <SupplierForm supplier={supplier} />
        </>
      )}
    </div>
  );

  return <div id="supplier-detail-page">{supplierDetView}</div>;
};
