import { Box, Divider, Grid, Typography } from "@mui/material";
import { push } from "redux-first-history";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import { RootState } from "app/rootReducer";
import { IppButton } from "./Buttons/IppButton";
import { useTypedTranslation } from "utils/customHooks";

interface ippFormHeaderProps {
  title: string;
  isEditing: boolean;
  isAdding: boolean;
  returnPath?: string;
  returnTitle?: string;
  showReturn?: boolean; // whether to show return link
  showReturnToInv?: boolean; // whether to show return path link
}

export const IppFormHeader = (props: ippFormHeaderProps) => {
  const dispatch = useDispatch();
  const t = useTypedTranslation(["strGen"]);

  const {
    title,
    isEditing,
    isAdding,
    returnPath,
    returnTitle,
    showReturn,
    showReturnToInv,
  } = props;
  const history = useHistory();
  const curPath = history.location.pathname;

  const { previousLocations } = useSelector((state: RootState) => state.router);

  /*
  By dafault it uses the current url and strips the last part
  to get the previous path. This ONLY works because of how our
  routes works: By adding path info to the end of the old url
  E.g. oldurl/infos => oldurl/infos/25 and vise versa
  So this might need review for stakeholders pending how its route works
   */
  const handleReturn = (event: React.MouseEvent<unknown>) => {
    const route: string = returnPath || "o";
    if (route === "o") {
      const newPath = curPath.slice(0, curPath.lastIndexOf("/"));
      // history.goBack();
      dispatch(push(newPath));
    } else dispatch(push(route));
  };

  const goBack = () => {
    const isInternalPlatformUrl = (pathname: string) => {
      return pathname.startsWith("/");
    };

    // Find the first valid non-merge location that is internal and not the current path
    const nextNonMerge = previousLocations.find(
      (hist: any) =>
        !hist.location.pathname.includes("merge") &&
        hist.location.pathname !== curPath &&
        isInternalPlatformUrl(hist.location.pathname)
    );

    if (nextNonMerge) {
      // Navigate to the first valid non-merge and internal platform path

      dispatch(push(nextNonMerge.location.pathname));
    } else {
      handleReturn(null as unknown as React.MouseEvent<unknown>);
    }
  };

  return (
    <>
      <Grid item xs={6}>
        <Typography variant="h5" component="h1">
          {isAdding
            ? t("strGen:components.ippformheader.title.add", { title })
            : isEditing
            ? t("strGen:components.ippformheader.title.edit", { title })
            : t("strGen:components.ippformheader.title.view", { title })}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        {showReturn && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                  height: "100%",
                  marginX: 0,
                }}
              >
                <IppButton
                  onClick={goBack}
                  variant="text"
                  startIcon={<ArrowBackIcon />}
                  color={"secondary"}
                  sx={{
                    fontSize: "12px",
                    paddingX: 0,
                    marginX: 0,
                  }}
                >
                  {t("strGen:buttons.back")}
                </IppButton>
              </Box>
              {showReturnToInv && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      paddingX: "8px",
                    }}
                  >
                    <Divider
                      orientation="vertical"
                      style={{
                        width: "2px",
                        height: "2rem",
                        alignSelf: "center",
                      }}
                      flexItem
                      color="secondary"
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      height: "100%",
                      marginX: 0,
                    }}
                  >
                    <IppButton
                      onClick={handleReturn}
                      variant="text"
                      color={"secondary"}
                      sx={{
                        fontSize: "12px",
                        paddingX: 0,
                        marginX: 0,
                      }}
                    >
                      {t("strGen:buttons.backtolist", {
                        title: returnTitle || title,
                      })}
                    </IppButton>
                  </Box>
                </>
              )}
            </Box>
          </>
        )}
      </Grid>
    </>
  );
};

IppFormHeader.defaultProps = {
  showReturn: true,
  showReturnToInv: true,
};
