import { Step, Stepper, StepLabel } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

// Define styled StepLabel
const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  ".Mui-disabled": {
    color: theme.palette.secondary.dark,
  },
  "&:hover": {
    cursor: "pointer",
    // non-active icons change
    ".MuiStepIcon-root": {
      color: theme.palette.grey[800],
      boxShadow: `0px 0px 6px 3px ${theme.palette.grey[200]}`,
      borderRadius: "50%",
    },
    ".MuiStepLabel-label": {
      color: theme.palette.grey[800],
    },
    // active / completed icons
    ".Mui-active,.Mui-completed": {
      ".MuiStepIcon-root": {
        color: theme.palette.primary.main,
      },
    },
  },
}));
export interface step {
  id: string;
  label: string;
  error: boolean | undefined;
  fields?: string[] | [];
}

interface ippStepperProps {
  steps: step[];
  activeStep: number;
  setActiveStep: any;
  // if viewOnly === true, no error states shown
  viewOnly: boolean;
}

export const IppStepper = (props: ippStepperProps) => {
  const { steps, activeStep, setActiveStep, viewOnly } = props;
  const theme = useTheme();
  return (
    <Stepper activeStep={activeStep} alternativeLabel nonLinear={viewOnly}>
      {steps.map((step, index) => {
        const stepProps: {
          optional?: React.ReactNode;
          error?: boolean | undefined;
        } = {};
        stepProps.error = viewOnly ? false : step.error === true;
        return (
          <Step key={step?.label}>
            <StyledStepLabel
              {...stepProps}
              onClick={() => setActiveStep(index)}
            >
              {step?.label}
            </StyledStepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};
