import React, { useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { openSnackBar, closeSnackBar } from "./SnackBarSlice";
import { RootState } from "app/rootReducer";
import { useLocation } from "react-router";

const PREFIX = "IppSnackBars";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props: JSX.IntrinsicAttributes & AlertProps,
  ref
) {
  return <MuiAlert ref={ref} elevation={6} variant="filled" {...props} />;
});

function IppSnackBars() {
  const dispatch = useDispatch();

  //the alert colors are ['"error"', '"warning"', '"info"', '"success"'];

  const { message, severity, open, duration } = useSelector(
    (state: RootState) => state.snackBar
  );

  const location = useLocation();
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      // This will only happen if location changes, not on the initial render.
      if (duration === null) {
        dispatch(closeSnackBar());
      }
    } else {
      isMounted.current = true;
    }
  }, [location]);

  const handleClick = () => {
    dispatch(openSnackBar("test works", "info"));
  };

  const handleClose = (event?: any, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(closeSnackBar());
  };

  return (
    <Root>
      <div className={classes.root}>
        <Snackbar
          open={open}
          autoHideDuration={duration}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleClose} severity={severity}>
            {message}
          </Alert>
        </Snackbar>
      </div>
    </Root>
  );
}

export default IppSnackBars;
