import { Edit } from "@mui/icons-material";
import { Divider, Grid, IconButton, Typography } from "@mui/material";
import { RootState } from "app/rootReducer";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";

interface IppCurrencyDisplayProps {
  label?: string | null;
  value: number | string;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  showFooter?: boolean;
  showEdit?: boolean;
  showCurrency?: boolean;
}

export const IppCurrencyDisplay = ({
  label,
  value,
  isEditing,
  setIsEditing,
  showFooter = true,
  showEdit = true,
  showCurrency = false,
}: IppCurrencyDisplayProps) => {
  const { clientCurrencyList, clientCurrenciesById } = useSelector(
    (state: RootState) => state.clientCurrencies
  );

  const clientCurrency = clientCurrencyList.map(
    (id) => clientCurrenciesById[id]
  );

  const currencyFormattedValue = showCurrency
    ? new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: clientCurrency[0].CurrencyName ?? "CAD",
      }).format(parseFloat(value.toString()))
    : null;

  const editChange = () => {
    setIsEditing(!isEditing);
  };

  return (
    <div>
      <Grid container spacing={0}>
        {label && (
          <Grid item xs={12}>
            <Typography
              noWrap={true}
              variant="caption"
              sx={{ color: "text.secondary" }}
            >
              {label}
            </Typography>
          </Grid>
        )}

        <Grid item xs style={{ flexGrow: 1, overflowWrap: "break-word" }}>
          <NumericFormat
            value={value}
            displayType={"text"}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            prefix={showCurrency ? undefined : "$"}
            renderText={(formattedValue: string | number) => (
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {(currencyFormattedValue ?? formattedValue) || ""}
              </Typography>
            )}
          />
        </Grid>

        {showEdit && (
          <Grid item>
            <IconButton onClick={editChange} size="small">
              <Edit fontSize="inherit" />
            </IconButton>
          </Grid>
        )}
      </Grid>

      {showFooter && <Divider />}
    </div>
  );
};
