import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchCommitments } from "./CommitmentSlice";
import { useParams } from "react-router-dom";
import { Alert } from "@mui/material";
import { CommitmentForm } from "./CommitmentForm";
import LoadingIndicator from "components/LoadingIndicator";
import { Trans, useTranslation } from "react-i18next";

interface CommitmentDetProps {
  commitmentID: string;
}

export const CommitmentDetailPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation(["objStk", "objPlt", "strGen", "objCom"]);

  const {
    isLoading,
    error: compError,
    errorList: compErrorList,
    commitmentsById,
  } = useSelector((state: RootState) => state.commitments);

  // get commitment ID from url param and attempt to find company in store
  const { commitmentID } = useParams<CommitmentDetProps>();
  const itemID = parseInt(commitmentID, 10);

  const commitment = commitmentsById[parseInt(commitmentID, 10)];

  useEffect(() => {
    (async () => {
      try {
        // can avoid going to the DB if we navigated from inventory
        if (
          !commitment
          //!commitment.PersonResponsibleName ||
          // !commitment.CommitmentStatusTypeName
        ) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchCommitments(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [commitment, dispatch, getAccessTokenSilently]);

  let commitmentDetView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      {commitment && (
        <>
          {compError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              <span>
                <b>{t("strGen:errors.wentwrong")}</b> - {compError.toString()}
                {compErrorList && (
                  <ul>
                    {compErrorList.map((error) => {
                      return (
                        <li key={error.param}>
                          <b>{error.param}:</b> {error.msg}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </span>
            </Alert>
          )}
          {!itemID && (
            <Alert severity="error" sx={{ mb: 2 }}>
              <span>
                <Trans
                  i18nKey="strGen:errors.invalidid"
                  /* eslint-disable-next-line jsx-a11y/heading-has-content */
                  components={[<h1 />, <div />]}
                  values={{
                    variable: commitmentID,
                    fieldname: t("objCom:objects.commitment.name"),
                  }}
                />
              </span>
            </Alert>
          )}
          <CommitmentForm commitment={commitment} />
        </>
      )}
    </div>
  );

  return <div id="commitment-detail-page">{commitmentDetView}</div>;
};
