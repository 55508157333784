import axiosClient from "./axiosClient";

export interface ProjectType {
  ProjectTypeID: number;
  ProjectTypeName: string;
  ClientID: number;
}

export interface ProjectTypesResult {
  projectTypes: ProjectType[];
}

export const getProjectTypes = async (
  accessToken: string
): Promise<ProjectTypesResult> => {
  const url = `/projectTypes`;

  try {
    const { data } = await axiosClient.get<ProjectTypesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
};

export const createProjectType = async (
  accessToken: string,
  newProjectType: Partial<ProjectType>
): Promise<ProjectType> => {
  const url = `/projectTypes`;

  try {
    const { data } = await axiosClient.post<ProjectType>(url, newProjectType, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
};

export const updateProjectType = async (
  accessToken: string,
  projectTypeID: number,
  updatedProjectType: Partial<ProjectType>
): Promise<ProjectType> => {
  const url = `/projectTypes/${projectTypeID}`;

  try {
    const { data } = await axiosClient.post<ProjectType>(
      url,
      updatedProjectType,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
};

export const deleteProjectType = async (
  accessToken: string,
  projectTypeID: number
): Promise<void> => {
  const url = `/projectTypes/${projectTypeID}`;

  try {
    await axiosClient.delete(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } catch (err: any) {
    throw err;
  }
};
