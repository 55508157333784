import axiosClient from "api/axiosClient";

export interface CommitmentGroupSummary {
  GroupID: number;
  GroupName: string;
  Completed: number;
  Outstanding: number;
}

export interface CommitmentGroupSummarysResult {
  commitmentGroupSummarys: CommitmentGroupSummary[];
}

export async function getCommitmentGroupSummarys(
  accessToken: String
): Promise<CommitmentGroupSummarysResult> {
  const url = `/commitmentHomePage/groupSummary`;
  try {
    const { data } = await axiosClient.get<CommitmentGroupSummarysResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
