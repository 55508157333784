import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ProximityIndicatorLSD,
  ProximityIndicatorLSDsResult,
  getProximityIndicatorLSDs,
} from "api/PILSDApi";
import { AppThunk } from "app/store";

interface ProximityIndicatorLSDState {
  proximityIndicatorLSDsById: Record<number, ProximityIndicatorLSD>;
  proximityIndicatorLSDList: number[];
  proximityIndicators?: string[];
  LSDs?: string[];
  isLoading: boolean;
  error: string | null;
}

const ProximityIndicatorLSDInitialState: ProximityIndicatorLSDState = {
  proximityIndicatorLSDsById: {},
  proximityIndicatorLSDList: [],
  proximityIndicators: [],
  LSDs: [],
  isLoading: false,
  error: null,
};

function startLoading(state: ProximityIndicatorLSDState) {
  state.isLoading = true;
}

function loadingFailed(
  state: ProximityIndicatorLSDState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const proximityIndicatorLSD = createSlice({
  name: "proximityIndicatorLSDs",
  initialState: ProximityIndicatorLSDInitialState,
  reducers: {
    getproximityIndicatorLSDsStart: startLoading,
    getproximityIndicatorLSDsSuccess(
      state,
      { payload }: PayloadAction<ProximityIndicatorLSDsResult>
    ) {
      const { proximityIndicatorLSDs } = payload;
      state.isLoading = false;
      state.error = null;
      proximityIndicatorLSDs.forEach((proximityIndicatorLSD) => {
        state.proximityIndicatorLSDsById[proximityIndicatorLSD.PILSDID] =
          proximityIndicatorLSD;
      });

      state.proximityIndicatorLSDList = proximityIndicatorLSDs.map(
        (proximityIndicator) => proximityIndicator.PILSDID
      );
    },
    getproximityIndicatorLSDsFailure: loadingFailed,
  },
});

export const {
  getproximityIndicatorLSDsStart,
  getproximityIndicatorLSDsSuccess,
  getproximityIndicatorLSDsFailure,
} = proximityIndicatorLSD.actions;

export default proximityIndicatorLSD.reducer;

export const fetchProximityIndicatorLSDs =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getproximityIndicatorLSDsStart());
      const proximityIndicatorLSDs = await getProximityIndicatorLSDs(
        accessToken
      );
      dispatch(getproximityIndicatorLSDsSuccess(proximityIndicatorLSDs));
    } catch (err: any) {
      dispatch(getproximityIndicatorLSDsFailure(err.toString()));
    }
  };
