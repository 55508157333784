import axiosClient from "../../axiosClient";

export interface CommitmentInteraction {
  CommitmentInteractionID: number;
  CommitmentID: number;
  InteractionID: number;
  InteractionProjectID: number;
  CommunicationID: number;
  InteractionTitle: string;
  InteractionDate: Date;
  InteractionTypeName: string;
  Contacts: string;
  SequenceID: number;
  CommitmentProjectID: number;
  CommitmentTitle: string;
  CommitmentStatusType: string;
  PersonResponsibleName: string;
}

export interface CommitmentInteractionResult {
  commitmentInteractions: CommitmentInteraction[];
}

export const getCommitmentInteractionsByCommitment = async (
  accessToken: String,
  commitmentID: number
): Promise<CommitmentInteractionResult> => {
  const url = `/commitmentInteraction/commitment/${commitmentID}`;

  try {
    const { data } = await axiosClient.get<CommitmentInteractionResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
};

export const getCommitmentInteractionsByInteraction = async (
  accessToken: String,
  interactionID: number
): Promise<CommitmentInteractionResult> => {
  const url = `/commitmentInteraction/interaction/${interactionID}`;

  try {
    const { data } = await axiosClient.get<CommitmentInteractionResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
};

export const createCommitmentInteraction = async (
  accessToken: String,
  newCommitmentInteraction: Partial<CommitmentInteraction>
): Promise<CommitmentInteraction> => {
  const url = `/commitmentInteraction`;

  try {
    const { data } = await axiosClient.post<CommitmentInteraction>(
      url,
      newCommitmentInteraction,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
};

export const deleteCommitmentInteraction = async (
  accessToken: String,
  commitmentInteractionID: number
): Promise<string> => {
  const url = `/commitmentInteraction/${commitmentInteractionID}`;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
};
