import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  GrievanceInteraction,
  GrievanceInteractionsResult,
  getGrievanceInteractions,
  getGrievanceInteractionsByGrievance,
  getGrievanceInteractionsByInteraction,
  createGrievanceInteraction,
  updateGrievanceInteraction,
  deleteGrievanceInteraction,
} from "api/stakeholder/grievanceInteractionAPI";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { SnackBarConstants } from "utils/customHooks";

interface GrievanceInteractionState {
  grievanceInteractionsById: Record<number, GrievanceInteraction>;
  grievanceInteractionList: number[];
  isLoading: boolean;
  subGrievanceInteractionisLoading: boolean;
  error: string | null;
}

const GrievanceInteractionInitialState: GrievanceInteractionState = {
  grievanceInteractionsById: {},
  grievanceInteractionList: [],
  isLoading: false,
  subGrievanceInteractionisLoading: false,
  error: null,
};

function startLoading(state: GrievanceInteractionState) {
  state.isLoading = true;
}

function loadingFailed(
  state: GrievanceInteractionState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const grievanceInteractions = createSlice({
  name: "grievanceInteractions",
  initialState: GrievanceInteractionInitialState,
  reducers: {
    getGrievanceInteractionsStart: startLoading,
    getGrievanceInteractionsSuccess(
      state,
      { payload }: PayloadAction<GrievanceInteractionsResult>
    ) {
      const { grievanceInteractions } = payload;

      state.isLoading = false;
      state.error = null;

      grievanceInteractions.forEach((grievanceInteraction) => {
        state.grievanceInteractionsById[
          grievanceInteraction.GrievanceInteractionID
        ] = grievanceInteraction;
      });

      state.grievanceInteractionList = grievanceInteractions.map(
        (grievanceInteraction) => grievanceInteraction.GrievanceInteractionID
      );
    },
    getGrievanceInteractionsFailure: loadingFailed,
    createGrievanceInteractionStart: startLoading,
    createGrievanceInteractionSuccess(
      state,
      { payload }: PayloadAction<GrievanceInteraction>
    ) {
      const { GrievanceInteractionID } = payload;
      state.grievanceInteractionsById[GrievanceInteractionID] = payload;
      state.grievanceInteractionList.push(GrievanceInteractionID);

      state.isLoading = false;
      state.error = null;
    },
    updateGrievanceInteractionSuccess(
      state,
      { payload }: PayloadAction<GrievanceInteraction>
    ) {
      const { GrievanceInteractionID } = payload;

      state.grievanceInteractionsById[GrievanceInteractionID] = payload;
      //state.grievanceInteractionList.push(GrievanceInteractionID);

      state.isLoading = false;
      state.error = null;
    },
    deleteGrievanceInteractionSuccess(
      state,
      { payload }: PayloadAction<number>
    ) {
      const GrievanceInteractionID = payload;
      delete state.grievanceInteractionsById[GrievanceInteractionID];
      state.grievanceInteractionList = state.grievanceInteractionList.filter(
        (item) => item !== GrievanceInteractionID
      );

      state.isLoading = false;
      state.error = null;
    },
    createGrievanceInteractionFailure: loadingFailed,
  },
});

export const {
  getGrievanceInteractionsStart,
  getGrievanceInteractionsSuccess,
  getGrievanceInteractionsFailure,
  createGrievanceInteractionStart,
  createGrievanceInteractionSuccess,
  updateGrievanceInteractionSuccess,
  deleteGrievanceInteractionSuccess,
  createGrievanceInteractionFailure,
} = grievanceInteractions.actions;

export default grievanceInteractions.reducer;

export const fetchGrievanceInteractions =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getGrievanceInteractionsStart());
      const grievanceInteractions = await getGrievanceInteractions(accessToken);
      dispatch(getGrievanceInteractionsSuccess(grievanceInteractions));
    } catch (err: any) {
      dispatch(getGrievanceInteractionsFailure(err.toString()));
    }
  };

export const fetchGrievanceInteractionsByGrievance =
  (accessToken: String, grievanceID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getGrievanceInteractionsStart());
      const grievanceInteractions = await getGrievanceInteractionsByGrievance(
        accessToken,
        grievanceID
      );
      dispatch(getGrievanceInteractionsSuccess(grievanceInteractions));
    } catch (err: any) {
      dispatch(getGrievanceInteractionsFailure(err.toString()));
    }
  };

export const fetchGrievanceInteractionsByInteraction =
  (accessToken: String, interactionID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getGrievanceInteractionsStart());
      const grievanceInteractions = await getGrievanceInteractionsByInteraction(
        accessToken,
        interactionID
      );
      dispatch(getGrievanceInteractionsSuccess(grievanceInteractions));
    } catch (err: any) {
      dispatch(getGrievanceInteractionsFailure(err.toString()));
    }
  };

export const addGrievanceInteraction =
  (
    accessToken: String,
    newGrievanceInteraction: Partial<GrievanceInteraction>,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createGrievanceInteractionStart());
      const grievanceInteraction = await createGrievanceInteraction(
        accessToken,
        newGrievanceInteraction
      );
      dispatch(createGrievanceInteractionSuccess(grievanceInteraction));
      dispatch(openSnackBar(snackbarConstants.ADD_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createGrievanceInteractionFailure(err.toString()));
      dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
    }
  };

//! COMMENTED OUT FOR FUTURE CLEAN UP
// export const updGrievanceInteraction =
//   (
//     accessToken: String,
//     grievanceInteractionID: number,
//     newGrievanceInteraction: Partial<GrievanceInteraction>
//   ): AppThunk =>
//   async (dispatch) => {
//     try {
//       dispatch(createGrievanceInteractionStart());
//       const grievanceInteraction = await updateGrievanceInteraction(
//         accessToken,
//         grievanceInteractionID,
//         newGrievanceInteraction
//       );
//       dispatch(updateGrievanceInteractionSuccess(grievanceInteraction));
//       dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
//     } catch (err: any) {
//       dispatch(createGrievanceInteractionFailure(err.toString()));
//       dispatch(openSnackBar(Constants.FAILED, "error"));
//     }
//   };

export const delGrievanceInteraction =
  (
    accessToken: String,
    grievanceInteractionID: number,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createGrievanceInteractionStart());
      const result = await deleteGrievanceInteraction(
        accessToken,
        grievanceInteractionID
      );
      dispatch(deleteGrievanceInteractionSuccess(grievanceInteractionID));
      dispatch(openSnackBar(snackbarConstants.DELETE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createGrievanceInteractionFailure(err.toString()));
      dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
    }
  };
