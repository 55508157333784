import axiosClient from "./axiosClient";

export interface ActionStatus {
  ActionStatusID: number;
  ActionStatusText: string;
}

export interface ActionStatussResult {
  actionStatuss: ActionStatus[];
}

export async function getActionStatuss(
  accessToken: String
): Promise<ActionStatussResult> {
  const url = `/actionStatus`;

  try {
    const { data } = await axiosClient.get<ActionStatussResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
