import axiosClient from "./axiosClient";

export interface IndigenousGroup {
  IndigenousGroupID: number;
  IndigenousGroupName: string;
  ShortName: string;
  CoreGroup: boolean;
  StatusGroup: boolean;
}

export interface IndigenousGroupsResult {
  indigenousGroups: IndigenousGroup[];
}

export async function getIndigenousGroups(
  accessToken: String
): Promise<IndigenousGroupsResult> {
  const url = `/indigenousgroup`;

  try {
    const { data } = await axiosClient.get<IndigenousGroupsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getIndigenousGroupsByClient(
  accessToken: String
): Promise<IndigenousGroupsResult> {
  const url = `/indigenousgroup/client`;

  try {
    const { data } = await axiosClient.get<IndigenousGroupsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
