import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { push } from "redux-first-history";

export const SsheHomePage = () => {
  // Todo: Add real home page view
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(push("/sshe/sshe"));
  }, []);

  return <></>;
};
