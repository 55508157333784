import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import {
  Chart,
  ChartTooltip,
  ChartSeries,
  ChartSeriesItem,
  ChartXAxis,
  ChartXAxisItem,
  ChartYAxis,
  ChartYAxisItem,
} from "@progress/kendo-react-charts";
import { IppSkeleton } from "components/IppSkeleton";
import { fetchEngagementHealths } from "./EngagementHealthSlice";
import { useTypedTranslation } from "utils/customHooks";

export const EngagementHealthChart = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["strGen"]);

  const { isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const {
    engagementHealthList,
    engagementHealthsById,
    isLoading: chartIsLoading,
  } = useSelector((state: RootState) => state.engagementHealths);

  const data = engagementHealthList.map((item) => engagementHealthsById[item]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchEngagementHealths(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [getAccessTokenSilently, dispatch]);

  let categoryColors = [{}];
  const randomColor = (props: any) => {
    if (categoryColors[props.dataItem.GroupName]) {
      return categoryColors[props.dataItem.GroupName];
    } else {
      const randomColor = Math.floor(Math.random() * 16777215).toString(16);
      categoryColors[props.dataItem.category] = "#" + randomColor;
      return "#" + randomColor;
    }
  };

  let pageView =
    clientIsLoading || chartIsLoading ? (
      <IppSkeleton height={350} />
    ) : (
      <div>
        <Chart style={{ height: 350 }}>
          <ChartSeries>
            <ChartSeriesItem
              type="bubble"
              xField="LevelOfInfluence"
              yField="LevelOfInterest"
              sizeField="InteractionCount"
              categoryField="GroupName"
              data={data}
              color={randomColor}
            />
          </ChartSeries>

          <ChartXAxis>
            <ChartXAxisItem
              title={{
                text: t(
                  "strGen:pages.home.engagement.shadowboxes.engagementhealth.chartxaxis"
                ),
              }}
            />
          </ChartXAxis>
          <ChartYAxis>
            <ChartYAxisItem
              title={{
                text: t(
                  "strGen:pages.home.engagement.shadowboxes.engagementhealth.chartyaxis"
                ),
              }}
            />
          </ChartYAxis>

          <ChartTooltip
            format={`{3}: {2:N0} ${t("objStk:objects.interaction.name", {
              count: 2,
            })}`}
            opacity={1}
          />
        </Chart>
      </div>
    );

  return <div id="engagement-health-chart">{pageView}</div>;
};
