import { UploadFile, Link } from "@mui/icons-material";
import { ButtonProps } from "@mui/material/Button";
import { IppButton, IppButtonProps } from "components/Buttons/IppButton";
import { useTypedTranslation } from "utils/customHooks";

interface IppAttachUploadButtonProps extends IppButtonProps {
  color?: ButtonProps["color"];
  isLinkAttachment?: boolean;
}

export const IppAttachUploadButton = ({
  color = "secondary",
  isLinkAttachment = false,
  ...props
}: IppAttachUploadButtonProps) => {
  const t = useTypedTranslation(["strGen"]);

  // Set buttonText and startIcon based on isLinkAttachment
  const buttonText = isLinkAttachment
    ? t("objPlt:objects.attachment.addLink")
    : t("objPlt:objects.attachment.addFile");

  const startIcon = isLinkAttachment ? <Link /> : <UploadFile />;

  return (
    <IppButton color={color} startIcon={startIcon} {...props}>
      {buttonText}
    </IppButton>
  );
};
