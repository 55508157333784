import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IssueComment,
  IssueCommentsResult,
  getIssueComments,
  getIssueCommentsByComment,
  createIssueComment,
  updateIssueComment,
  deleteIssueComment,
  getIssueCommentsByInteraction,
} from "api/stakeholder/issueCommentAPI";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { SnackBarConstants } from "utils/customHooks";

interface IssueCommentState {
  issueCommentsById: Record<number, IssueComment>;
  issueCommentList: number[];
  isLoading: boolean;
  isLoadingAdd: boolean;
  error: string | null;
  errorList: any[] | null;
}

const IssueCommentInitialState: IssueCommentState = {
  issueCommentsById: {},
  issueCommentList: [],
  isLoading: false,
  isLoadingAdd: false,
  error: null,
  errorList: null,
};

function startLoading(state: IssueCommentState) {
  state.isLoading = true;
}

function startLoadingAdd(state: IssueCommentState) {
  state.isLoadingAdd = true;
}

function loadingFailed(
  state: IssueCommentState,
  action: PayloadAction<{ status: string; message: string; errors: [] }>
) {
  state.isLoading = false;
  state.error = action.payload.message;
  if (action.payload.errors) {
    state.errorList = action.payload.errors;
  }
}

const issueComments = createSlice({
  name: "issueComments",
  initialState: IssueCommentInitialState,
  reducers: {
    getIssueCommentsStart: startLoading,
    getIssueCommentsSuccess(
      state,
      { payload }: PayloadAction<IssueCommentsResult>
    ) {
      const { issueComments } = payload;

      state.isLoading = false;
      state.error = null;
      state.errorList = null;

      issueComments.forEach((issueComment) => {
        state.issueCommentsById[issueComment.IssueCommentID] = issueComment;
      });

      state.issueCommentList = issueComments.map(
        (issueComment) => issueComment.IssueCommentID
      );
    },
    getIssueCommentsFailure: loadingFailed,
    createIssueCommentStart: startLoadingAdd,
    createIssueCommentSuccess(state, { payload }: PayloadAction<IssueComment>) {
      const { IssueCommentID } = payload;
      state.issueCommentsById[IssueCommentID] = payload;
      state.issueCommentList.push(IssueCommentID);

      state.isLoading = false;
      state.isLoadingAdd = false;
      state.error = null;
      state.errorList = null;
    },
    updateIssueCommentSuccess(state, { payload }: PayloadAction<IssueComment>) {
      const { IssueCommentID } = payload;

      state.issueCommentsById[IssueCommentID] = payload;

      state.isLoading = false;
      state.error = null;
      state.errorList = null;
    },
    deleteIssueCommentSuccess(state, { payload }: PayloadAction<number>) {
      const IssueCommentID = payload;
      delete state.issueCommentsById[IssueCommentID];
      state.issueCommentList = state.issueCommentList.filter(
        (item) => item !== IssueCommentID
      );

      state.isLoading = false;
      state.error = null;
      state.errorList = null;
    },
    createIssueCommentFailure: loadingFailed,
  },
});

export const {
  getIssueCommentsStart,
  getIssueCommentsSuccess,
  getIssueCommentsFailure,
  createIssueCommentStart,
  createIssueCommentSuccess,
  updateIssueCommentSuccess,
  deleteIssueCommentSuccess,
  createIssueCommentFailure,
} = issueComments.actions;

export default issueComments.reducer;

export const fetchIssueComments =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getIssueCommentsStart());
      const issueComments = await getIssueComments(accessToken);
      dispatch(getIssueCommentsSuccess(issueComments));
    } catch (err: any) {
      dispatch(getIssueCommentsFailure(err.toString()));
    }
  };

export const fetchIssueCommentsByComment =
  (accessToken: String, interactionCommentID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getIssueCommentsStart());
      const issueComments = await getIssueCommentsByComment(
        accessToken,
        interactionCommentID
      );
      dispatch(getIssueCommentsSuccess(issueComments));
    } catch (err: any) {
      dispatch(getIssueCommentsFailure(err.toString()));
    }
  };

export const fetchIssueCommentsByInteraction =
  (accessToken: String, interactionID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getIssueCommentsStart());
      const issueComments = await getIssueCommentsByInteraction(
        accessToken,
        interactionID
      );
      dispatch(getIssueCommentsSuccess(issueComments));
    } catch (err: any) {
      dispatch(getIssueCommentsFailure(err.toString()));
    }
  };

//! COMMENTED OUT FOR FUTURE CLEAN UP
// export const addIssueComment =
//   (accessToken: String, newIssueComment: Partial<IssueComment>,
//    snackbarConstants: SnackBarConstants): AppThunk =>
//   async (dispatch) => {
//     try {
//       dispatch(createIssueCommentStart());
//       const issueComment = await createIssueComment(
//         accessToken,
//         newIssueComment
//       );
//       dispatch(createIssueCommentSuccess(issueComment));
//       dispatch(openSnackBar(snackbarConstants.ADD_SUCCESS, "success"));
//     } catch (error: any) {
//       if (error.response.data.message) {
//         dispatch(createIssueCommentFailure(error.response.data));
//         dispatch(openSnackBar(error.response.data.message, "error"));
//       } else {
//         dispatch(createIssueCommentFailure(error.toString()));
//         dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
//       }
//     }
//   };

// export const updIssueComment =
//   (
//     accessToken: String,
//     issueCommentID: number,
//     newIssueComment: Partial<IssueComment>,
//    snackbarConstants: SnackBarConstants
//   ): AppThunk =>
//   async (dispatch) => {
//     try {
//       dispatch(createIssueCommentStart());
//       const issueComment = await updateIssueComment(
//         accessToken,
//         issueCommentID,
//         newIssueComment
//       );

//       dispatch(updateIssueCommentSuccess(issueComment));

//       dispatch(openSnackBar(snackbarConstants.UPDATE_SUCCESS, "success"));
//     } catch (error: any) {
//       if (error.response.data.message) {
//         dispatch(createIssueCommentFailure(error.response.data));
//         dispatch(openSnackBar(error.response.data.message, "error"));
//       } else {
//         dispatch(createIssueCommentFailure(error.toString()));
//         dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
//       }
//     }
//   };

// export const delIssueComment =
//   (accessToken: String, issueCommentID: number,
//    snackbarConstants: SnackBarConstants): AppThunk =>
//   async (dispatch) => {
//     try {
//       dispatch(createIssueCommentStart());
//       const result = await deleteIssueComment(accessToken, issueCommentID);
//       dispatch(deleteIssueCommentSuccess(issueCommentID));
//       dispatch(openSnackBar(snackbarConstants.DELETE_SUCCESS, "success"));
//     } catch (error: any) {
//       if (error.response.data.message) {
//         dispatch(createIssueCommentFailure(error.response.data));
//         dispatch(openSnackBar(error.response.data.message, "error"));
//       } else {
//         dispatch(createIssueCommentFailure(error.toString()));
//         dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
//       }
//     }
//   };
