import * as yup from "yup";
import { useTypedTranslation } from "utils/customHooks";
import { Employee } from "api/employeeAPI";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";

export const EmployeeValidationSchema = (
  employees: Employee[],
  isEditing: boolean,
  showApprentice: boolean,
  regionRequired: boolean,
  oldCompEmpID?: string,
  isNewPosition?: boolean
) => {
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  const {
    ft_ben_TempEmployee,
    ft_ben_JobPosition,
    ft_ben_CustomJobPosition,
    ft_ben_JobHistory,
    ft_ben_DiversityDeclined,
    ft_ben_EmployeePostalCode,
  } = useSelector((state: RootState) => state.client);

  const checkEmpIdExist = (newValue: string, formValues: any) => {
    let companyEmployees = [] as any;
    if (formValues.CompanyID) {
      let comp = formValues.CompanyID as number;
      companyEmployees = employees.filter(
        (d) => d.CompanyID === comp && d.CompanyEmployeeID === newValue
      );
      if (isEditing && oldCompEmpID) {
        let curEmpl = oldCompEmpID as string;
        if (curEmpl === newValue) return true;
      }
    }
    return companyEmployees.length === 0;
  };

  let validationSchema: Record<string, yup.AnySchema> = {
    CompanyID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.company.name"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.company.name"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.company.name"),
        })
      ),
    CompanyEmployeeID: yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.employee.fields.employeeid"),
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.employee.fields.employeeid"),
          count: 50,
        })
      )
      .test(
        "CompanyEmployeeID",
        t("strGen:validation.existsforthisobj", {
          fieldname: t("objBen:objects.employee.fields.employeeid"),
          objectname: t("objBen:objects.employee.fields.employer"),
        }),
        function (value) {
          return checkEmpIdExist(value, this.parent);
        }
      ),
    TownCity: yup
      .string()
      .trim()
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objPlt:platformwide.fields.towncity"),
          count: 50,
        })
      )
      .nullable()
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:platformwide.fields.towncity"),
        })
      ),
    ProvinceState: yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:platformwide.fields.provincestate"),
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objPlt:platformwide.fields.provincestate"),
          count: 50,
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:platformwide.fields.provincestate"),
        })
      ),
    CountryName: yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:platformwide.fields.country"),
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objPlt:platformwide.fields.country"),
          count: 50,
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:platformwide.fields.country"),
        })
      ),

    PostalCode: yup
      .string()
      .max(
        10,
        t("strGen:validation.max", {
          fieldname: t("objPlt:platformwide.fields.postalcode"),
          count: 10,
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:platformwide.fields.postalcode"),
        })
      )
      .nullable(),

    GenderID: yup
      .number()
      .nullable()
      .test(
        "is-gender-required",
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.employee.fields.gender"),
        }),
        function (value) {
          const diversityDeclined = this.parent.DiversityDeclined;

          // If the diversity declined toggle is on and diversity declined is true, GenderID is not required
          if (ft_ben_DiversityDeclined && diversityDeclined) {
            return true;
          }

          // In all other cases, GenderID is required
          return value != null;
        }
      )
      .typeError(t("strGen:validation.invalidformat")),
    IndigenousGroupID: yup
      .number()
      .nullable()
      .typeError(t("strGen:validation.invalidformat")),
    Disability: yup
      .boolean()
      .nullable()
      .typeError(t("strGen:validation.invalidformat")),
    VisibleMinority: yup
      .boolean()
      .nullable()
      .typeError(t("strGen:validation.invalidformat")),
    LGBTQ: yup
      .boolean()
      .nullable()
      .typeError(t("strGen:validation.invalidformat")),
    Local: yup
      .boolean()
      .nullable()
      .typeError(t("strGen:validation.invalidformat")),
    NorthernResident: yup
      .boolean()
      .nullable()
      .typeError(t("strGen:validation.invalidformat")),
  };

  if (ft_ben_JobPosition) {
    validationSchema["JobPositionID"] = yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.employee.fields.jobposition"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.employee.fields.jobposition"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.employee.fields.jobposition"),
        })
      );

    validationSchema["ApprenticeStatus"] = yup
      .string()
      .trim()
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.employee.fields.apprenticestatus"),
          count: 50,
        })
      )
      .nullable()
      .test(
        "ApprenticeStatus",
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.employee.fields.apprenticestatus"),
        }),
        function (value) {
          if (showApprentice && !value) {
            return false;
          }
          return true;
        }
      );

    validationSchema["ApprenticeYear"] = yup.number().when("ApprenticeStatus", {
      is: "Apprentice",
      then: (schema) =>
        schema
          .positive(
            t("strGen:validation.positive", {
              fieldname: t("objBen:objects.employee.fields.apprenticeyear"),
            })
          )
          .required(
            t("strGen:validation.required", {
              fieldname: t("objBen:objects.employee.fields.apprenticeyear"),
            })
          )
          .typeError(t("strGen:validation.invalidformat")),
      otherwise: (schema) =>
        schema.nullable().equals(
          [null],
          t("strGen:validation.emptywhennotcondition", {
            fieldname: t("objBen:objects.employee.fields.apprenticeyear"),
            condition: t("objBen:objects.employee.fields.apprentice"),
          })
        ),
    });
  }

  if (ft_ben_JobPosition && ft_ben_JobHistory) {
    validationSchema["ValidFrom"] = isNewPosition
      ? yup
          .date()
          .required(
            t("strGen:validation.required", {
              fieldname: t("objPlt:platformwide.fields.datestarted"),
            })
          )
          .typeError(
            t("strGen:validation.invalid", {
              fieldname: t("objPlt:platformwide.fields.date"),
            })
          )
      : yup
          .date()
          .nullable()
          .typeError(
            t("strGen:validation.invalid", {
              fieldname: t("objPlt:platformwide.fields.date"),
            })
          );
  }

  if (ft_ben_CustomJobPosition) {
    validationSchema["CustomJobPositionID"] = yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.employee.fields.customJobPosition"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.employee.fields.customJobPosition"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.employee.fields.customJobPosition"),
        })
      );
  }

  if (regionRequired) {
    validationSchema["RegionID"] = yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.employee.fields.region"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.employee.fields.region"),
        })
      )
      .typeError(t("strGen:validation.invalidformat"));
  }

  if (ft_ben_TempEmployee) {
    validationSchema["TempEmployee"] = yup
      .boolean()
      .nullable()
      .typeError(t("strGen:validation.invalidformat"));
  }

  if (ft_ben_DiversityDeclined) {
    validationSchema["DiversityDeclined"] = yup
      .boolean()
      .nullable()
      .typeError(t("strGen:validation.invalidformat"))
      .test(
        "diversityFieldsCheck",
        t("strGen:validation.diversityprofile.mustbeempty"),
        function (value) {
          if (value) {
            // If DiversityDeclined is true
            const fieldsToCheck = [
              "GenderID",
              "IndigenousGroupID",
              "Disability",
              "VisibleMinority",
              "LGBTQ",
              "Local",
              "NorthernResident",
            ];
            return fieldsToCheck.every((field) => !this.parent[field]);
          }
          return true;
        }
      );
  }

  return yup.object().shape(validationSchema);
};
