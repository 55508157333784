import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ActionStatus,
  ActionStatussResult,
  getActionStatuss,
} from "api/actionStatusAPI";
import { AppThunk } from "app/store";

interface ActionStatusState {
  actionStatussById: Record<number, ActionStatus>;
  actionStatusList: number[];
  isLoading: boolean;
  error: string | null;
}

const ActionStatusInitialState: ActionStatusState = {
  actionStatussById: {},
  actionStatusList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: ActionStatusState) {
  state.isLoading = true;
}

function loadingFailed(
  state: ActionStatusState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const actionStatuss = createSlice({
  name: "actionStatus",
  initialState: ActionStatusInitialState,
  reducers: {
    getActionStatussStart: startLoading,
    getActionStatussSuccess(
      state,
      { payload }: PayloadAction<ActionStatussResult>
    ) {
      const { actionStatuss } = payload;
      // console.log("PAYLOAD", actionStatuss);
      state.isLoading = false;
      state.error = null;

      actionStatuss.forEach((actionStatus) => {
        state.actionStatussById[actionStatus.ActionStatusID] = actionStatus;
      });

      state.actionStatusList = actionStatuss.map(
        (actionStatus) => actionStatus.ActionStatusID
      );
    },
    getActionStatussFailure: loadingFailed,
  },
});

export const {
  getActionStatussStart,
  getActionStatussSuccess,
  getActionStatussFailure,
} = actionStatuss.actions;

export default actionStatuss.reducer;

export const fetchActionStatuss =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getActionStatussStart());
      const actionStatuss = await getActionStatuss(accessToken);
      //console.log("RETURN", actionStatuss);
      dispatch(getActionStatussSuccess(actionStatuss));
    } catch (err: any) {
      dispatch(getActionStatussFailure(err.toString()));
    }
  };
