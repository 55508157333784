import { Save } from "@mui/icons-material";
import { ButtonProps } from "@mui/material/Button";
import { IppButton, IppButtonProps } from "components/Buttons/IppButton";

interface IppSubmitButtonProps extends IppButtonProps {
  buttonText: string;
  color?: ButtonProps["color"];
  startIcon?: React.ReactNode;
}

export const IppSubmitButton = ({
  buttonText,
  color = "secondary",
  startIcon = <Save />,
  ...props
}: IppSubmitButtonProps) => {
  return (
    <IppButton color={color} startIcon={startIcon} type="submit" {...props}>
      {buttonText}
    </IppButton>
  );
};
