import { EmployeeForm } from "./EmployeeForm";

export const EmployeeCreatePage = () => {
  let employeeCreateView = (
    <div>
      <EmployeeForm />
    </div>
  );

  return <div id="employee-detail-page">{employeeCreateView}</div>;
};
