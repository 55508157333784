import axiosClient from "../axiosClient";

export interface Sshe {
  SSHE_ID: number;
  ProjectID: number;
  ProjectName: string;
  CompanyID: number;
  CompanyName: number;
  EndingDate: Date;
  CompletionDate: Date;
  ExposureHours: number;
  NumberInWorkforce: number;
  ExposureHoursSubcontractor: number;
  NoTreatment: number;
  FirstAid: number;
  MedicalTreatment: number;
  RestrictedWork: number;
  LostTime: number;
  LTI_Description: string | null;
  RI_Description: string | null;
  SubcontractorIncidents: number;
  SpillsReleases: number;
  SafetyAlerts: number;
  EnvironmentalAlerts: number;
  StatisticsNotes: string | null;
  RandomTestingRequired: number;
  RandomTestingCompleted: number;
  RandomTestingCompliant: number;
  RandomTestingNonCompliant: number;
  ReasonableCauseRequired: number;
  ReasonableCauseCompleted: number;
  ReasonableCauseCompliant: number;
  ReasonableCauseNonCompliant: number;
  SafetyManagementNotes: string;
  PreAccessRequiredRecert: number;
  PreAccessCompletedRecert: number;
  PreAccessCompliantRecert: number;
  PreAccessNonCompliantRecert: number;
  PreAccessRequiredNew: number;
  PreAccessCompletedNew: number;
  PreAccessCompliantNew: number;
  PreAccessNonCompliantNew: number;
  PostIncidentRequired: number;
  PostIncidentCompleted: number;
  PostIncidentCompliant: number;
  PostIncidentNonCompliant: number;
  TB_Required: number;
  TB_Completed: number;
  TB_Compliant: number;
  TB_NonCompliant: number;
  ContractualNotes: string;
  BBO: number;
  PermitAssessments: number;
  HazID: number;
  NearMisses: number;
  PotentialHurt: number;
  Walkthroughs: number;
  WorkSiteInspections: number;
  JSA: number;
  CriticalProcedures: number;
  ItemsOutstanding: number;
  StatusOpenItems: string | null;
  ImprovementOpportunities: string | null;
  BestPractices: string | null;
  SafetyObjectives: string | null;
  CompletedBy: number;
  CompletedByName: string | null;
  NotesComments: string | null;
  ProcessSafety: number;
  ProcessSafetyAlerts: number;
  VisitInPerson: number;
  VisitVirtual: number;
  IsSubmitted: number;
}
export interface SshesResult {
  sshes: Sshe[];
}

export async function getAllSshes(accessToken: String): Promise<SshesResult> {
  const url = `/sshe`;
  try {
    const { data } = await axiosClient.get<SshesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}
export async function createSshe(
  accessToken: String,
  newSshe: Partial<Sshe>
): Promise<Sshe> {
  const url = `/sshe`;
  try {
    const { data } = await axiosClient.post<Sshe>(url, newSshe, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateSshe(
  accessToken: String,
  ssheID: number,
  newSshe: Partial<Sshe>
): Promise<Sshe> {
  const url = `/sshe/` + ssheID;
  try {
    const { data } = await axiosClient.post<Sshe>(url, newSshe, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteSshe(
  accessToken: String,
  ssheID: number
): Promise<string> {
  const url = `/sshe/` + ssheID;
  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}
