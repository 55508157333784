import { TextField, InputAdornment } from "@mui/material";

interface IppStaticTextfieldProps {
  id: string;
  label: string;
  value: string;
  isCurrency?: boolean;
}

export const IppStaticTextfield = (props: IppStaticTextfieldProps) => {
  const { id, label, value, isCurrency } = props;

  return (
    <TextField
      id={id}
      name={id}
      label={label}
      value={value}
      disabled={true}
      InputProps={
        isCurrency
          ? {
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }
          : { readOnly: true }
      }
    />
  );
};
