import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { useDispatch, useSelector } from "react-redux";
import { push } from "redux-first-history";
import { RootState } from "app/rootReducer";

export const Login = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const goToLoginPage = () => {
    loginWithRedirect();
  };

  const { clientModuleList, clientModulesById } = useSelector(
    (state: RootState) => state.clientModules
  );

  const clientModules = clientModuleList.map((id) => clientModulesById[id]);

  useEffect(() => {
    (async () => {
      try {
        if (isAuthenticated) {
          // if user has only one module, sent them to that module
          if (clientModules.length >= 1) {
            if (clientModules.length === 1) {
              dispatch(push("/" + clientModules[0].ShortName));
            } else {
              // if user has access to more than one module, send to root page
              dispatch(push("/"));
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientModules, isAuthenticated, dispatch]);

  if (isAuthenticated === false) {
    goToLoginPage();
  }

  return null;
};
