import { commitmentsPermissions } from "./commitmentsPermissions";

// Aggregate permissions for all modules
export const permissions = {
  // Include permissions from each module
  // Benefits: benefitsPermissions,
  Commitments: commitmentsPermissions,
  // Engagements: engagementsPermissions,
  // Global: globalPermissions,
  // Supplier: supplierPermissions,
  // SSHE: sshePermissions,
};
