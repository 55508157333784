import * as yup from "yup";
import { useTypedTranslation } from "utils/customHooks";

export const JobHistoryValidation = (
  oldEmployeeID: number | undefined,
  showApprentice: boolean
) => {
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  return yup.object({
    EmployeeID: yup
      .number()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.employee.fields.employeeid"),
        })
      )
      .test(
        "EmployeeID",
        t("strGen:validation.cannotchangerecord", {
          fieldname: t("objBen:objects.employee.fields.employeeid"),
        }),
        function (value) {
          if (oldEmployeeID && value !== oldEmployeeID) {
            return false;
          }
          return true;
        }
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.employee.fields.employeeid"),
        })
      ),
    JobPositionID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.employee.fields.jobposition"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.employee.fields.jobposition"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.employee.fields.jobposition"),
        })
      ),
    ApprenticeStatus: yup
      .string()
      .trim()
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.employee.fields.apprenticestatus"),
          count: 50,
        })
      )
      .nullable()
      .test(
        "ApprenticeStatus",
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.employee.fields.apprenticestatus"),
        }),
        function (value) {
          if (showApprentice && !value) {
            return false;
          }
          return true;
        }
      ),
    ApprenticeYear: yup.number().when("ApprenticeStatus", {
      is: "Apprentice",
      then: (schema) =>
        schema
          .positive(
            t("strGen:validation.positive", {
              fieldname: t("objBen:objects.employee.fields.apprenticeyear"),
            })
          )
          .required(
            t("strGen:validation.required", {
              fieldname: t("objBen:objects.employee.fields.apprenticeyear"),
            })
          )
          .typeError(t("strGen:validation.invalidformat")),
      otherwise: (schema) =>
        schema.nullable().equals(
          [null],
          t("strGen:validation.emptywhennotcondition", {
            fieldname: t("objBen:objects.employee.fields.apprenticeyear"),
            condition: t("objBen:objects.employee.fields.apprentice"),
          })
        ),
    }),
    // ApprenticeYear: yup
    //   .number()
    //   .when("ApprenticeStatus", ([ApprenticeStatus], schema) => {
    //     return ApprenticeStatus === "Apprentice"
    //       ? schema
    //           .required(
    //             'Apprentice Year is required when Status is "Apprentice"'
    //           )
    //           .typeError("Apprentice Year should be a number")
    //       : schema.nullable();
    //   }),
    EndDate: yup
      .date()
      .required("End Date is required")
      .typeError("End Date should be a date"),
  });
};
