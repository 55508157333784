import axiosClient from "../axiosClient";

export interface InteractionComment {
  InteractionCommentID: number;
  InteractionID: number;
  CommentDetails: string;
  ContactID: number;
  GroupID: number;
  ContactName: string;
  GroupName: string;
  Issues: string;
  InteractionProjectID: number;
  CommunicationID: number;
  InteractionTitle: string;
  SentimentLevel: number;
  SequenceID: number;
  InteractionDate: Date;
  Response: string;
}

export interface InteractionCommentsResult {
  interactionComments: InteractionComment[];
}

export async function getInteractionComments(
  accessToken: String
): Promise<InteractionCommentsResult> {
  const url = `/interactionComment`;

  try {
    const { data } = await axiosClient.get<InteractionCommentsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getInteractionCommentsByInteraction(
  accessToken: String,
  interactionID: number
): Promise<InteractionCommentsResult> {
  const url = `/interactionComment/${interactionID}`;

  try {
    const { data } = await axiosClient.get<InteractionCommentsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createInteractionComment(
  accessToken: String,
  itemData: Partial<InteractionComment>,
  issueList: string[]
): Promise<InteractionComment> {
  const url = `/interactionComment`;

  try {
    const { data } = await axiosClient.post<InteractionComment>(
      url,
      {
        itemData: itemData,
        issueList: issueList,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateInteractionComment(
  accessToken: String,
  itemID: number,
  itemData: Partial<InteractionComment>,
  issueList: string[] | null
): Promise<InteractionComment> {
  const url = `/interactionComment/` + itemID;

  try {
    const { data } = await axiosClient.post<InteractionComment>(
      url,
      {
        itemData: itemData,
        issueList: issueList,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteInteractionComment(
  accessToken: String,
  itemID: number
): Promise<string> {
  const url = `/interactionComment/` + itemID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
