import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { IppSkeleton } from "components/IppSkeleton";
import { ArrowBack, ArrowForward, Lock } from "@mui/icons-material";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { push } from "redux-first-history";
import { addClientCurrency, delClientCurrency } from "./ClientCurrencySlice";
import { currencies } from "components/Currencies";
import { IppFormDivider } from "components/IppFormDivider";
import { IppSaveButton } from "components/Buttons/IppSaveButton";

const PREFIX = "ClientCurrencyTypeForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
  roleCard: `${PREFIX}-roleCard`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1100,
  },

  [`& .${classes.boxSpace}`]: {
    padding: 10,
  },

  [`& .${classes.roleCard}`]: {
    minWidth: 250,

    minHeight: 300,
  },
}));

// format for display lists
interface listValue {
  itemID: number;
  itemLabel: string;
  wasSelected: boolean; // to track deletions
  isRequired: boolean;
}

export const ClientCurrencyTypeForm = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [pageLoaded, setPageLoaded] = useState(false);

  // allGroups contains unfiltered list of groups
  // filteredGroups contains list of groups with filter applied
  // selectedGroups contains list of items selected
  const [allGroups, setAllGroups] = useState<listValue[]>([]);
  const [filteredGroups, setFilteredGroups] = useState<listValue[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<listValue[]>([]);
  const [filterValue, setFilterValue] = useState("");

  const { clientId } = useSelector((state: RootState) => state.client);

  const {
    clientCurrencyList,
    clientCurrenciesById,
    isLoading: clientCurrencyIsLoading,
    error: clientCurrencyError,
  } = useSelector((state: RootState) => state.clientCurrencies);

  const clientCurrency = clientCurrencyList.map(
    (id) => clientCurrenciesById[id]
  );

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        //dispatch(fetchCurrencyTypes(accessToken));
        //dispatch(fetchClientCurrencies(accessToken));
        setPageLoaded(true);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  // make sure lists are initialized only one time
  useEffect(() => {
    if (
      pageLoaded &&
      //!interactionTypesIsLoading &&
      !clientCurrencyIsLoading &&
      allGroups.length === 0
    ) {
      initializeLists();
    }
  }, [pageLoaded, clientCurrencyIsLoading]);

  //useEffect( () => {})

  const initializeLists = () => {
    // initialize full list
    let data1: listValue[] = [];
    currencies.forEach((item) => {
      // check if item is already selected
      const foundItem = clientCurrency.find((obj) => {
        return obj.CurrencyName === item.Currency;
      });
      if (!foundItem) {
        let n: listValue = {
          itemID: 1,
          itemLabel: item.Currency,
          wasSelected: false,
          isRequired: false,
        };
        data1.push(n);
      }
    });
    setAllGroups(data1);
    setFilteredGroups(data1);

    let data2 = clientCurrency.map((item) => {
      let n: listValue = {
        itemID: item.ClientCurrencyID,
        itemLabel: item.CurrencyName,
        wasSelected: true,
        isRequired: item.IsRequired,
      };
      return n;
    });
    setSelectedGroups(data2);
  };

  const handleSave = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
      },
    });
    // Delete items marked for removal
    // if item was selected and is now in all groups,
    // then item was REMOVED
    allGroups.forEach((item) => {
      if (item.wasSelected) {
        dispatch(delClientCurrency(accessToken, item.itemID));
      }
    });

    // insert new items
    selectedGroups.forEach((item) => {
      if (!item.wasSelected) {
        const newItem = {
          ClientID: clientId,
          CurrencyName: item.itemLabel,
          IsRequired: false,
        };
        dispatch(addClientCurrency(accessToken, newItem));
      }
    });
    dispatch(openSnackBar("Updates completed", "success"));
    dispatch(push("/admin/clientCurrency"));
  };

  const handleToggleRight = (value: listValue) => () => {
    // add item to full list, and sort it
    setAllGroups((oldArray) =>
      allGroups.concat(value).sort((a, b) => {
        let fa = a.itemLabel.toLowerCase(),
          fb = b.itemLabel.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      })
    );

    setFilteredGroups((oldArray) =>
      filteredGroups.concat(value).sort((a, b) => {
        let fa = a.itemLabel.toLowerCase(),
          fb = b.itemLabel.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      })
    );

    // remove item from selected list
    setSelectedGroups(
      selectedGroups.filter(function (a) {
        return a !== value;
      })
    );
  };

  const handleToggleLeft = (value: listValue) => () => {
    // remove currently selected currencies
    const oldArray = selectedGroups;

    // add item to selected list, and sort it
    setSelectedGroups((oldArray) =>
      selectedGroups.concat(value).sort((a, b) => {
        let fa = a.itemLabel.toLowerCase(),
          fb = b.itemLabel.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      })
    );

    setSelectedGroups([value]);

    // remove item from full list

    const all = () => {
      // removes seleceted item from left side
      let temp = allGroups.filter(function (a) {
        return a !== value;
      });

      // adds previous right items to the left
      let last = [...temp];
      oldArray.forEach((item) => {
        last.unshift(item);
      });
      return last;
    };

    setAllGroups(all);

    setFilteredGroups(
      filteredGroups.filter(function (a) {
        return a !== value;
      })
    );
  };

  const onFilterChange = (e: any) => {
    const val = e.target.value || "";
    setFilterValue(val);
    if (val === "") {
      setFilteredGroups(allGroups);
    } else {
      setFilteredGroups(
        allGroups.filter(function (a) {
          return a.itemLabel.toLowerCase().includes(val.toLowerCase());
        })
      );
    }
  };

  let fullList = false ? (
    <IppSkeleton height={400} />
  ) : (
    <Paper sx={{ height: 400, overflow: "auto" }}>
      <List dense component="div" role="list">
        {filteredGroups.map((item) => {
          return (
            <Tooltip title="Click to add item to selected groups">
              <ListItem
                button
                key={item.itemID}
                onClick={handleToggleLeft(item)}
                role="listitem"
              >
                <ListItemIcon>
                  <ArrowForward />
                </ListItemIcon>
                <ListItemText primary={item.itemLabel} />
              </ListItem>
            </Tooltip>
          );
        })}
      </List>
    </Paper>
  );

  let selectedList = false ? (
    <IppSkeleton height={400} />
  ) : (
    <Paper sx={{ height: 400, overflow: "auto" }}>
      <List dense component="div" role="list">
        {selectedGroups.map((item) => {
          return item.isRequired ? (
            <Tooltip title="This is a required item and cannot be removed">
              <ListItem button role="listitem" key={item.itemID}>
                <ListItemIcon>
                  <Lock />
                </ListItemIcon>
                <ListItemText primary={item.itemLabel} />
              </ListItem>
            </Tooltip>
          ) : (
            <Tooltip title="Click to remove item from selected list">
              <ListItem
                button
                role="listitem"
                key={item.itemID}
                onClick={handleToggleRight(item)}
              >
                <ListItemIcon>
                  <ArrowBack />
                </ListItemIcon>
                <ListItemText primary={item.itemLabel} />
              </ListItem>
            </Tooltip>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <Root>
      <Box display="flex" justifyContent="center">
        <Paper className={classes.boxSpace}>
          <Grid container className={classes.editForm} spacing={2}>
            <Grid item xs={9}>
              <Typography variant="h5" component="h1">
                Currency
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Grid container justifyContent="flex-end">
                <IppSaveButton handleSave={handleSave} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <IppFormDivider title="Guide" />
              <Typography display="inline">
                This option allows you to select the currency you wish to have
                appear on forms. Only one currency can be chosen at a time. Your
                selection will be the only one available to users. When you
                choose a currency from 'Available Types', it will be moved to
                'Selected Types' and the current selection will be removed. Once
                you have completed your changes, click
                <strong> Save Changes</strong> to finalize them.
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="h6">Available Types</Typography>
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Search Currencies"
                value={filterValue}
                onChange={onFilterChange}
                size="small"
              />
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h6">Selected Types</Typography>
            </Grid>
            <Grid item xs={6}>
              {fullList}
            </Grid>
            <Grid item xs={6}>
              <div style={{ opacity: 0.5, pointerEvents: "none" }}>
                {selectedList}
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Root>
  );
};
