import { formatStringForCompare } from "./functions";

type OptionLabelExtractor = (option: any) => string;

/**
 * Creates a custom filter function for Autocomplete component.
 * This function enhances filtering by applying custom string normalization.
 * @param extractOptionLabel - Function to extract a label from an option for comparison.
 * @returns Function for filtering options based on user input.
 */
export const createCustomAutoCompleteFilter = (
  extractOptionLabel: OptionLabelExtractor
) => {
  return (options: any[], state: { inputValue: string }) => {
    // Normalize the user input for comparison
    const trimmedInput = formatStringForCompare(state.inputValue) || "";

    // Filter the options based on normalized user input
    return options.filter((option) => {
      // Normalize option label for comparison
      const optionLabel =
        formatStringForCompare(extractOptionLabel(option)) || "";

      // Check if the normalized label includes the normalized input
      return optionLabel.includes(trimmedInput);
    });
  };
};
