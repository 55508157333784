import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CountriesResult,
  Country,
  getCountries,
} from "api/dataList/countriesAPI";
import { AppThunk } from "app/store";

interface CountryState {
  countries: Country[];
  countriesById: Record<number, Country>;
  countriesList: number[];
  isLoading: boolean;
  error: string | null;
}

const CountriesInitialState: CountryState = {
  countries: [],
  countriesById: {},
  countriesList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: CountryState) {
  state.isLoading = true;
}

function loadingFailed(state: CountryState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const countries = createSlice({
  name: "countries",
  initialState: CountriesInitialState,
  reducers: {
    getCountriesStart: startLoading,
    getCountriesSuccess(state, { payload }: PayloadAction<CountriesResult>) {
      const { countries } = payload;
      state.isLoading = false;
      state.error = null;

      countries.forEach((country) => {
        state.countriesById[country.CountryID] = country;
      });

      state.countriesList = countries.map((country) => country.CountryID);
    },
    getCountriesFailure: loadingFailed,
  },
});

export const { getCountriesStart, getCountriesSuccess, getCountriesFailure } =
  countries.actions;

export default countries.reducer;

export const fetchCountries =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCountriesStart());
      const countries = await getCountries(accessToken);
      dispatch(getCountriesSuccess(countries));
    } catch (err: any) {
      dispatch(getCountriesFailure(err.toString()));
    }
  };
