import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CustomJobPosition,
  CustomJobPositionsResult,
  getCustomJobPositionsByClient,
} from "api/customJobPositionAPI";
import { AppThunk } from "app/store";

interface CustomJobPositionState {
  customJobPositionsById: Record<number, CustomJobPosition>;
  customJobPositionList: number[];
  isLoading: boolean;
  error: string | null;
}

const CustomJobPositionInitialState: CustomJobPositionState = {
  customJobPositionsById: {},
  customJobPositionList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: CustomJobPositionState) {
  state.isLoading = true;
}

function loadingFailed(
  state: CustomJobPositionState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const customJobPositions = createSlice({
  name: "customJobPosition",
  initialState: CustomJobPositionInitialState,
  reducers: {
    getCustomJobPositionsStart: startLoading,
    getCustomJobPositionsSuccess(
      state,
      { payload }: PayloadAction<CustomJobPositionsResult>
    ) {
      const { customJobPositions } = payload;
      state.isLoading = false;
      state.error = null;

      customJobPositions.forEach((customJobPosition) => {
        state.customJobPositionsById[customJobPosition.CustomJobPositionID] =
          customJobPosition;
      });

      state.customJobPositionList = customJobPositions.map(
        (customJobPosition) => customJobPosition.CustomJobPositionID
      );
    },
    getCustomJobPositionsFailure: loadingFailed,
  },
});

export const {
  getCustomJobPositionsStart,
  getCustomJobPositionsSuccess,
  getCustomJobPositionsFailure,
} = customJobPositions.actions;

export default customJobPositions.reducer;

export const fetchCustomJobPositionsByClient =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCustomJobPositionsStart());
      const jobPositions = await getCustomJobPositionsByClient(accessToken);
      dispatch(getCustomJobPositionsSuccess(jobPositions));
    } catch (err: any) {
      dispatch(getCustomJobPositionsFailure(err.toString()));
    }
  };
