import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CompanyShortForm,
  CompanyShortFormResult,
  getCompanyShortForms,
} from "api/companyShortFormAPI";
import { AppThunk } from "app/store";

interface CompanyShortFormState {
  companyShortFormsById: Record<number, CompanyShortForm>;
  companyShortFormList: number[];
  isLoading: boolean;
  error: string | null;
}

const CompanyShortFormInitialState: CompanyShortFormState = {
  companyShortFormsById: {},
  companyShortFormList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: CompanyShortFormState) {
  state.isLoading = true;
}

function loadingFailed(
  state: CompanyShortFormState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const companyShortForms = createSlice({
  name: "companyShortForms",
  initialState: CompanyShortFormInitialState,
  reducers: {
    getCompanyShortFormsStart: startLoading,
    getCompanyShortFormsSuccess(
      state,
      { payload }: PayloadAction<CompanyShortFormResult>
    ) {
      const { companyShortForms } = payload;
      state.isLoading = false;
      state.error = null;

      companyShortForms.forEach((company) => {
        state.companyShortFormsById[company.CompanyID] = company;
      });

      state.companyShortFormList = companyShortForms.map(
        (company) => company.CompanyID
      );
    },
    getCompanyShortFormsFailure: loadingFailed,
    appendCompanyShortForm(
      state,
      { payload }: PayloadAction<CompanyShortForm>
    ) {
      const { CompanyID } = payload;
      state.companyShortFormsById[CompanyID] = payload;
      state.companyShortFormList.push(CompanyID);
    },
  },
});

export const {
  getCompanyShortFormsStart,
  getCompanyShortFormsSuccess,
  getCompanyShortFormsFailure,
  appendCompanyShortForm,
} = companyShortForms.actions;

export default companyShortForms.reducer;

export const fetchCompanyShortForms =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCompanyShortFormsStart());
      const companies = await getCompanyShortForms(accessToken);
      dispatch(getCompanyShortFormsSuccess(companies));
    } catch (err: any) {
      dispatch(getCompanyShortFormsFailure(err.toString()));
    }
  };
