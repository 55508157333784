import axiosClient from ".././axiosClient";

export interface Country {
  CountryID: number;
  CountryName: string;
}

export interface CountriesResult {
  countries: Country[];
}

export const getCountries = async (
  accessToken: String
): Promise<CountriesResult> => {
  const url = `/countries`;

  try {
    const { data } = await axiosClient.get<CountriesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
};
