import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchInteractions } from "./InteractionSlice";
import { useParams } from "react-router-dom";
import { Alert } from "@mui/material";
import { InteractionForm } from "./InteractionForm";
import LoadingIndicator from "components/LoadingIndicator";
import { Trans, useTranslation } from "react-i18next";

interface InteractionDetProps {
  interactionID: string;
}

export const InteractionDetailPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation(["objPlt", "strGen", "objCom", "objStk"]);

  const {
    isLoading,
    error: compError,
    errorList: compErrorList,
    interactionsById,
  } = useSelector((state: RootState) => state.interactions);

  // get interaction ID from url param and attempt to find company in store
  const { interactionID } = useParams<InteractionDetProps>();
  const itemID = parseInt(interactionID, 10);

  const interaction = interactionsById[parseInt(interactionID, 10)];

  useEffect(() => {
    (async () => {
      try {
        // can avoid going to the DB if we navigated from inventory
        if (!interaction) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchInteractions(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [interaction, dispatch, getAccessTokenSilently]);

  let interactionDetView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      {interaction && (
        <>
          {compError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              <span>
                <b>{t("strGen:errors.wentwrong")}</b> - {compError.toString()}
                {compErrorList && (
                  <ul>
                    {compErrorList.map((error) => {
                      return (
                        <li key={error.param}>
                          <b>{error.param}:</b> {error.msg}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </span>
            </Alert>
          )}
          {!itemID && (
            <Alert severity="error" sx={{ mb: 2 }}>
              <div>
                <Trans
                  i18nKey="strGen:errors.invalidid"
                  /* eslint-disable-next-line jsx-a11y/heading-has-content */
                  components={[<h1 />, <div />]}
                  values={{
                    variable: itemID,
                    fieldname: t("objStk:objects.interaction.name"),
                  }}
                />
              </div>
            </Alert>
          )}
          <InteractionForm interaction={interaction} />
        </>
      )}
    </div>
  );

  return <div id="interaction-detail-page">{interactionDetView}</div>;
};
