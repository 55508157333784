import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { useTypedTranslation } from "utils/customHooks";
import { addJobHistory, updJobHistory } from "./JobHistorySlice";
import { useFormik } from "formik";
import { ChangeEvent, useEffect, useState } from "react";
import { JobHistoryValidation } from "./JobHistoryValidation";
import { Prompt } from "react-router";
import { Grid } from "@mui/material";
import { IppAutocomplete } from "components/IppAutocomplete";
import { IppTextField } from "components/IppTextField";
import ApprenticeshipStatusTypes from "components/ApprenticeshipStatus";
import { IppDatePicker } from "components/IppDatePicker";
import { ConvertDateOffset, GetJSDate } from "utils/DateFunctions";
import { IppCheckbox } from "components/IppCheckbox";
import { IppFormButtons } from "components/Buttons/IppFormButtons";

interface JobHistoryChildFormProps {
  closeAction: any;
  employeeID: number;
  jobHistoryID?: number;
}

export const JobHistoryChildForm = (props: JobHistoryChildFormProps) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["obBen", "strGen"]);

  const { closeAction, employeeID, jobHistoryID } = props;

  const [showApprentice, setShowApprentice] = useState(false);

  const { ft_ben_TempEmployee } = useSelector(
    (state: RootState) => state.client
  );

  const {
    jobHistoryList,
    jobHistoryById,
    isLoading: jobHistoryIsLoading,
  } = useSelector((state: RootState) => state.jobHistory);

  const jobHistory = jobHistoryID
    ? jobHistoryList
        .map((id) => jobHistoryById[id])
        .find((jh) => jh.JobHistoryID === jobHistoryID)
    : null;

  const {
    jobPositionList,
    jobPositionsById,
    isLoading: jobPositionIsLoading,
  } = useSelector((state: RootState) => state.jobPositions);

  const jobPositions = jobPositionList.map((id) => jobPositionsById[id]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        if (jobHistory) {
          dispatch(updJobHistory(accessToken, values));
        } else {
          dispatch(addJobHistory(accessToken, values));
        }
        closeAction();
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const itemData: any = jobHistory
    ? jobHistory
    : {
        EmployeeID: employeeID,
        JobPositionID: -1,
        ApprenticeStatus: "",
        ApprenticeYear: null,
        EndDate: null,
        TempEmployee: false,
      };

  const formik = useFormik({
    initialValues: itemData,
    validationSchema: JobHistoryValidation(
      jobHistory?.EmployeeID,
      showApprentice
    ),
    onSubmit: onSub,
  });

  useEffect(() => {
    if (jobPositions.length > 0 && !jobPositionIsLoading) {
      const posID = formik.values.JobPositionID;
      if (posID !== -1) {
        const pos = jobPositions.find((d) => d.JobPositionID === posID);
        if (pos) {
          if (pos.ApprenticeableTrade) {
            setShowApprentice(true);
          } else {
            setShowApprentice(false);
          }
        }
      }
    }
  }, [jobPositionIsLoading, jobPositions, formik.values.JobPositionID]);

  const pageForm = (
    <form noValidate onSubmit={formik.handleSubmit}>
      {!formik.isSubmitting && (
        <Prompt
          when={formik.dirty}
          message={t("strGen:prompts.unsavedchanges")}
        />
      )}

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <IppAutocomplete
            id="JobPositionID"
            toolTip="These jobs align with specific NOC codes. If you do not see a position in the list, please select one that most closely relates to the required NOC code."
            required={true}
            options={jobPositions}
            value={jobPositions.find((obj) => {
              return obj.JobPositionID === formik.values.JobPositionID;
            })}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (!newValue || newValue.ApprenticeableTrade === false) {
                formik.setFieldValue("ApprenticeStatus", null);
                formik.setFieldValue("ApprenticeYear", null);
                setShowApprentice(false);
              } else {
                setShowApprentice(true);
              }

              if (newValue) {
                formik.setFieldValue("JobPositionID", newValue.JobPositionID);
              } else {
                formik.setFieldValue("JobPositionID", null);
              }
            }}
            label="Job Position"
            isEditing={true}
            setIsEditing={null}
            optionLabelFunction={(option: any) => option.JobPositionName}
            errorFunction={formik.errors.JobPositionID}
            touchedFunction={formik.touched.JobPositionID}
            textValueFunction={
              !jobPositionIsLoading && formik.values.JobPositionID > 0
                ? jobPositionsById[formik.values.JobPositionID].JobPositionName
                : ""
            }
          />
        </Grid>
        <Grid item xs={6}>
          <IppDatePicker
            id="EndDate"
            label="End Date"
            required={true}
            value={ConvertDateOffset(formik.values.EndDate)}
            disableFuture={true}
            onChangeFunction={(newValue: any) => {
              formik.setFieldValue("EndDate", GetJSDate(newValue), true);
              formik.setFieldTouched("EndDate", true, false);
            }}
            errorsExpression={formik.errors.EndDate}
            touchedExpression={formik.touched.EndDate}
            isEditing={true}
            setIsEditing={null}
          />
        </Grid>
        {showApprentice && (
          <Grid item xs={6}>
            <IppAutocomplete
              id="ApprenticeStatus"
              options={ApprenticeshipStatusTypes}
              value={formik.values.ApprenticeStatus}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("ApprenticeStatus", newValue);
                } else {
                  formik.setFieldValue("ApprenticeStatus", "");
                }

                if (newValue === "Journeyperson") {
                  formik.setFieldValue("ApprenticeYear", null);
                }
              }}
              textValueFunction={formik.values.ApprenticeStatus}
              label="Apprentice Status"
              isEditing={true}
              setIsEditing={null}
              touchedFunction={formik.touched.ApprenticeStatus}
              errorFunction={formik.errors.ApprenticeStatus}
            />
          </Grid>
        )}
        {formik.values.ApprenticeStatus === "Apprentice" && showApprentice && (
          <Grid item xs={6}>
            <IppTextField
              id="ApprenticeYear"
              label="Apprentice Year"
              fieldType="number"
              value={formik.values.ApprenticeYear}
              onChangeFunction={formik.handleChange}
              isEditing={true}
              setIsEditing={null}
              touchedExpression={formik.touched.ApprenticeYear}
              errorsExpression={formik.errors.ApprenticeYear}
            />
          </Grid>
        )}
        {ft_ben_TempEmployee && (
          <Grid item xs={6}>
            <IppCheckbox
              id="TempEmployee"
              label={t("objBen:objects.employee.fields.tempemployee")}
              value={formik.values.TempEmployee}
              onChangeFunction={formik.handleChange}
              isEditing={true}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <IppFormButtons
            isEditing={true}
            setIsEditing={null}
            isAdding={!jobHistory}
            showCancel={true}
            resetFunction={closeAction}
          />
        </Grid>
      </Grid>
    </form>
  );

  return <div id="jobHistory-child-form">{pageForm}</div>;
};
