import axiosClient from "api/axiosClient";
import { IppDebug } from "app/App";

export interface EngagementTile {
  ItemID: number;
  OverdueInteractions: number;
  ResolvedGrievances: number;
  UnresolvedGrievances: number;
  IncompleteInteractions: number;
  OpenActions: number;
  OverdueActions: number;
}

export interface EngagementTilesResult {
  engagementTiles: EngagementTile[];
}

export async function getEngagementTiles(
  accessToken: String
): Promise<EngagementTilesResult> {
  const url = `/charts/engagementTile`;

  try {
    const { data } = await axiosClient.get<EngagementTilesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    // IppDebug("API", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}
