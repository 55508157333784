import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  TextField,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { Grid as KendoGrid, GridColumn } from "@progress/kendo-react-grid";
import LoadingIndicator from "components/LoadingIndicator";
import { Link } from "react-router-dom";
import { getCurrentModule } from "../../../utils/urlUtils";
import { IppMergeButton } from "components/Buttons/IppMergeButton";
import { useTypedTranslation } from "utils/customHooks";

interface CheckDuplicatesProps {
  originalID: number;
  isOpen: boolean;
  closeWindow: any;
}

export const CompanyCheckDuplicates = (props: CheckDuplicatesProps) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["strGen"]);
  const { originalID, isOpen, closeWindow } = props;
  const [filterString, setFilterString] = useState("");

  const { companyList, companiesById, isLoading, error } = useSelector(
    (state: RootState) => state.companies
  );

  const companies = companyList.map((id) => companiesById[id]);

  //Filter company list should exclude Client Company.
  const data = companies
    .filter((item) => item.CompanyID !== originalID && !item.IsClient)
    .map((p) => {
      let n = Object.assign({}, p) as any;
      n.CompanyName
        ? (n.CompanyName = p.CompanyName)
        : (n.CompanyName = "* Missing");
      return n;
    });

  const onChangeFilterString = (event: any) => {
    setFilterString(event.target.value);
  };

  const linkCell = (props: any) => {
    return (
      <td align="right">
        <Link
          to={
            `/${getCurrentModule()}/companies/merge/` +
            originalID +
            "/" +
            props.dataItem.CompanyID
          }
        >
          <Tooltip title="View and merge these companies">
            <IppMergeButton buttonText={t("strGen:pages.merge.select")} />
          </Tooltip>
        </Link>
      </td>
    );
  };

  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <Dialog open={isOpen} onClose={closeWindow} maxWidth="lg" fullWidth>
      <DialogTitle>Check for Duplicate Companies</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body2">
              This option is used to check for any duplicate companies, and
              potentially to merge and consolidate them. If there is a duplicate
              for this group, please select it from the list below.
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1">Search for:</Typography>
          </Grid>
          <Grid item xs>
            <TextField
              id="txtSearch"
              placeholder="Enter text to search for name"
              onChange={onChangeFilterString}
              value={filterString}
            />
          </Grid>
          <Grid item xs={12}>
            <KendoGrid
              data={
                filterString === ""
                  ? data
                  : data.filter((item) =>
                      item.CompanyName.toLowerCase().includes(
                        filterString.toLowerCase()
                      )
                    )
              }
            >
              <GridColumn field="CompanyName" title="Company" />
              <GridColumn field="VendorID" title="Vendor ID" />
              <GridColumn field="TownCity" title="City" />
              <GridColumn field="ProvinceState" title="Province/State" />
              <GridColumn field="EmployeeCount" title="Employees" />
              <GridColumn
                field="CompanyID"
                title=" "
                cell={linkCell}
                width="110"
              />
            </KendoGrid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
