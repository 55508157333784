import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CommitmentInteraction,
  CommitmentInteractionResult,
  getCommitmentInteractionsByCommitment,
  getCommitmentInteractionsByInteraction,
  createCommitmentInteraction,
  deleteCommitmentInteraction,
} from "api/crossModule/stakeholderCommitment/commitmentInteractionAPI";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface CommitmentInteractionState {
  commitmentInteractionsByID: Record<number, CommitmentInteraction>;
  commitmentInteractionList: number[];
  isLoading: boolean;
  subCommitmentInteractionIsLoading: boolean;
  error: string | null;
}

const CommitmentInteractionInitialState: CommitmentInteractionState = {
  commitmentInteractionsByID: {},
  commitmentInteractionList: [],
  isLoading: false,
  subCommitmentInteractionIsLoading: false,
  error: null,
};

const startLoading = (state: CommitmentInteractionState) => {
  state.isLoading = true;
};

const loadingFailed = (
  state: CommitmentInteractionState,
  action: PayloadAction<string>
) => {
  state.isLoading = false;
  state.error = action.payload;
};

const commitmentInteractions = createSlice({
  name: "commitmentInteractions",
  initialState: CommitmentInteractionInitialState,
  reducers: {
    getCommitmentInteractionsStart: startLoading,
    getCommitmentInteractionsSuccess(
      state,
      { payload }: PayloadAction<CommitmentInteractionResult>
    ) {
      const { commitmentInteractions } = payload;

      state.isLoading = false;
      state.error = null;

      commitmentInteractions.forEach((commitmentInteraction) => {
        state.commitmentInteractionsByID[
          commitmentInteraction.CommitmentInteractionID
        ] = commitmentInteraction;
      });

      state.commitmentInteractionList = commitmentInteractions.map(
        (commitmentInteraction) => commitmentInteraction.CommitmentInteractionID
      );
    },
    getCommitmentInteractionsFailure: loadingFailed,
    createCommitmentInteractionStart: startLoading,
    createCommitmentInteractionSuccess(
      state,
      { payload }: PayloadAction<CommitmentInteraction>
    ) {
      const { CommitmentInteractionID } = payload;
      state.commitmentInteractionsByID[CommitmentInteractionID] = payload;
      state.commitmentInteractionList.push(CommitmentInteractionID);

      state.isLoading = false;
      state.error = null;
    },
    deleteCommitmentInteractionSuccess(
      state,
      { payload }: PayloadAction<number>
    ) {
      const CommitmentInteractionID = payload;
      delete state.commitmentInteractionsByID[CommitmentInteractionID];
      state.commitmentInteractionList = state.commitmentInteractionList.filter(
        (commitmentInteraction) =>
          commitmentInteraction !== CommitmentInteractionID
      );

      state.isLoading = false;
      state.error = null;
    },
    createCommitmentInteractionFailure: loadingFailed,
  },
});

export const {
  getCommitmentInteractionsStart,
  getCommitmentInteractionsSuccess,
  getCommitmentInteractionsFailure,
  createCommitmentInteractionStart,
  createCommitmentInteractionSuccess,
  deleteCommitmentInteractionSuccess,
  createCommitmentInteractionFailure,
} = commitmentInteractions.actions;

export default commitmentInteractions.reducer;

export const fetchCommitmentInteractionsByCommitment =
  (accessToken: String, commitmentID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCommitmentInteractionsStart());
      const commitmentInteractions =
        await getCommitmentInteractionsByCommitment(accessToken, commitmentID);
      dispatch(getCommitmentInteractionsSuccess(commitmentInteractions));
    } catch (err: any) {
      dispatch(getCommitmentInteractionsFailure(err.toString()));
    }
  };

export const fetchCommitmentInteractionsByInteraction =
  (accessToken: String, interactionID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCommitmentInteractionsStart());
      const commitmentInteractions =
        await getCommitmentInteractionsByInteraction(
          accessToken,
          interactionID
        );
      dispatch(getCommitmentInteractionsSuccess(commitmentInteractions));
    } catch (err: any) {
      dispatch(getCommitmentInteractionsFailure(err.toString()));
    }
  };

export const addCommitmentInteraction =
  (
    accessToken: String,
    newCommitmentInteraction: Partial<CommitmentInteraction>,
    parentIsCommitment: boolean,
    parentID: number
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCommitmentInteractionStart());
      const commitmentInteraction = await createCommitmentInteraction(
        accessToken,
        newCommitmentInteraction
      );
      dispatch(createCommitmentInteractionSuccess(commitmentInteraction));
      parentIsCommitment
        ? dispatch(
            fetchCommitmentInteractionsByCommitment(accessToken, parentID)
          )
        : dispatch(
            fetchCommitmentInteractionsByInteraction(accessToken, parentID)
          );
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createCommitmentInteractionFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delCommitmentInteraction =
  (accessToken: String, commitmentInteractionID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCommitmentInteractionStart());
      const result = await deleteCommitmentInteraction(
        accessToken,
        commitmentInteractionID
      );
      dispatch(deleteCommitmentInteractionSuccess(commitmentInteractionID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createCommitmentInteractionFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };
