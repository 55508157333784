import { Grid, Typography } from "@mui/material";
import { useTypedTranslation } from "utils/customHooks";

export const ReportHelpPage = () => {
  const t = useTypedTranslation(["strGen"]);

  return (
    <div>
      <Grid container p={1}>
        <Grid item lg={4} md={6} xs={12}>
          <Typography variant="body1">
            {t("strGen:pages.reports.helppage.reportoverview")}
          </Typography>
          <ul>
            <li>{t("strGen:pages.reports.helppage.graphsandtables")}</li>
            <li>{t("strGen:pages.reports.helppage.toeditmessage")}</li>
          </ul>
          <Typography variant="body1">
            {t("strGen:pages.reports.helppage.exportingmessage")}
          </Typography>
          <ul>
            <li>{t("strGen:pages.reports.helppage.graphandtablehover")}</li>
            <li>{t("strGen:pages.reports.helppage.pleaseusescreenshots")}</li>
          </ul>
          <Typography variant="body1">
            {t("strGen:pages.reports.helppage.reportnavigation")}
          </Typography>
          <ul>
            <li>{t("strGen:pages.reports.helppage.tonavigate")}</li>
            <li>{t("strGen:pages.reports.helppage.tonavigatearrows")}</li>
          </ul>
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <img
            src={process.env.PUBLIC_URL + "/powerbi/table.png"}
            style={{ width: 300, height: undefined }}
            alt="Tables"
          />
          <Typography variant="body1">
            {t("strGen:pages.reports.helppage.titlesandheadings")}
          </Typography>
          <ul>
            <li>{t("strGen:pages.reports.helppage.sortanddrill")}</li>
            <li>{t("strGen:pages.reports.helppage.hoverandselectsettings")}</li>
          </ul>
          <Typography variant="body1">
            {t("strGen:pages.reports.helppage.options")}
          </Typography>
          <ul>
            <li>{t("strGen:pages.reports.helppage.hoveroptions")}</li>
            <li>{t("strGen:pages.reports.helppage.filterindication")}</li>
            <li>{t("strGen:pages.reports.helppage.focus")}</li>
            <li>{t("strGen:pages.reports.helppage.moreoptions")}</li>
          </ul>
        </Grid>

        <Grid item lg={4} md={6} xs={12}>
          <img
            src={process.env.PUBLIC_URL + "/powerbi/filtering.png"}
            alt="Filtering"
            style={{ width: 160, height: undefined }}
          />
          <Typography variant="body1">
            {t("strGen:pages.reports.helppage.filters")}
          </Typography>
          <ul>
            <li>{t("strGen:pages.reports.helppage.filterdate")}</li>
            <li>{t("strGen:pages.reports.helppage.filterboxselection")}</li>
            <li>{t("strGen:pages.reports.helppage.clearfilters")}</li>
          </ul>
        </Grid>
      </Grid>
    </div>
  );
};
