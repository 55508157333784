import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Issue,
  IssuesResult,
  getIssues,
  createIssue,
  updateIssue,
  deleteIssue,
  IssueTreeview,
} from "api/stakeholder/issueAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { fetchGroupIssuesByIssue } from "../groupIssue/GroupIssueSlice";
import { Contact } from "api/stakeholder/contactAPI";
import { SnackBarConstants } from "utils/customHooks";

interface IssueState {
  issuesById: Record<number, Issue>;
  issueList: number[];
  isLoading: boolean;
  isLoadingAdd: boolean;
  subIssueisLoading: boolean;
  isLoaded: boolean;
  error: string | null;
}

const IssueInitialState: IssueState = {
  issuesById: {},
  issueList: [],
  isLoading: false,
  isLoadingAdd: false,
  subIssueisLoading: false,
  isLoaded: false,
  error: null,
};

function startLoading(state: IssueState) {
  state.isLoading = true;
}

function startLoadingAdd(state: IssueState) {
  state.isLoadingAdd = true;
}

function loadingFailed(state: IssueState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const issues = createSlice({
  name: "issues",
  initialState: IssueInitialState,
  reducers: {
    getIssuesStart: startLoading,
    getIssuesSuccess(state, { payload }: PayloadAction<IssuesResult>) {
      const { issues } = payload;

      state.isLoading = false;
      state.error = null;
      state.isLoaded = true;

      issues.forEach((issue) => {
        state.issuesById[issue.IssueID] = issue;
      });

      state.issueList = issues.map((issue) => issue.IssueID);
    },
    getIssuesFailure: loadingFailed,
    createIssueStart: startLoadingAdd,
    createIssueSuccess(state, { payload }: PayloadAction<Issue>) {
      const { IssueID } = payload;
      state.issuesById[IssueID] = payload;
      state.issueList.push(IssueID);

      state.isLoading = false;
      state.isLoadingAdd = false;
      state.error = null;
    },
    updateIssueSuccess(state, { payload }: PayloadAction<Issue>) {
      const { IssueID } = payload;

      state.issuesById[IssueID] = payload;
      //state.issueList.push(IssueID);

      state.isLoading = false;
      state.error = null;
    },
    deleteIssueSuccess(state, { payload }: PayloadAction<number>) {
      const IssueID = payload;
      delete state.issuesById[IssueID];
      state.issueList = state.issueList.filter((item) => item !== IssueID);

      state.isLoading = false;
      state.error = null;
    },
    createIssueFailure: loadingFailed,
  },
});

export const {
  getIssuesStart,
  getIssuesSuccess,
  getIssuesFailure,
  createIssueStart,
  createIssueSuccess,
  updateIssueSuccess,
  deleteIssueSuccess,
  createIssueFailure,
} = issues.actions;

export default issues.reducer;

export const fetchIssues =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getIssuesStart());
      const issues = await getIssues(accessToken);
      dispatch(getIssuesSuccess(issues));
    } catch (err: any) {
      dispatch(getIssuesFailure(err.toString()));
    }
  };

export const addIssue =
  (
    accessToken: String,
    newIssue: Partial<Issue>,
    groupList: String[],
    contactList: any[],
    setReturnRoute: boolean,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createIssueStart());
      const issue = await createIssue(
        accessToken,
        newIssue,
        groupList,
        contactList
      );
      dispatch(createIssueSuccess(issue));
      dispatch(openSnackBar(snackbarConstants.ADD_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(push(`/engagement/issues/${issue.IssueID}`));
      }
    } catch (err: any) {
      dispatch(createIssueFailure(err.toString()));
      dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
    }
  };

export const updIssue =
  (
    accessToken: String,
    issueID: number,
    newIssue: Partial<IssueTreeview>,
    groupList: String[] | null,
    contactList: Contact[] | null,
    setReturnRoute: boolean,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      let contactIDs: any[] | null = contactList
        ? contactList.map((contact) => {
            return { ContactID: contact.ContactID };
          })
        : null;
      dispatch(createIssueStart());

      const { expanded, ...issueObject } = newIssue;

      const issue = await updateIssue(
        accessToken,
        issueID,
        issueObject,
        groupList,
        contactIDs
      );

      dispatch(updateIssueSuccess(issue));
      dispatch(fetchGroupIssuesByIssue(accessToken, issueID));
      dispatch(openSnackBar(snackbarConstants.UPDATE_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(push("/engagement/issues/" + issueID));
      }
    } catch (err: any) {
      dispatch(createIssueFailure(err.toString()));
      dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
    }
  };

export const delIssue =
  (
    accessToken: String,
    issueID: number,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createIssueStart());
      const result = await deleteIssue(accessToken, issueID);
      dispatch(deleteIssueSuccess(issueID));
      dispatch(openSnackBar(snackbarConstants.DELETE_SUCCESS, "success"));
      dispatch(push("/engagement/issues"));
    } catch (err: any) {
      dispatch(createIssueFailure(err.toString()));
      dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
    }
  };
