import {
  Edit,
  DragHandle,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";
import { CSSProperties } from "react";
import { useTypedTranslation } from "utils/customHooks";

interface IppDisplayPriorityProps {
  label?: string | null;
  value: any;
  isEditing: boolean;
  setIsEditing: any;
  showFooter?: boolean;
  showEdit?: boolean;
  showLabel?: boolean;
  showEmphasis?: boolean;
  color?: CSSProperties["color"];
  priorityValue: number | null;
}

export const IppDisplayPriority = (props: IppDisplayPriorityProps) => {
  const {
    label,
    value,
    isEditing,
    setIsEditing,
    showFooter,
    showEdit,
    showLabel,
    color,
    priorityValue,
    showEmphasis,
  } = props;

  const t = useTypedTranslation(["strGen"]);

  const editChange = () => {
    setIsEditing(!isEditing);
  };

  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          {showLabel && (
            <Typography
              noWrap={true}
              variant="caption"
              sx={{ color: "text.secondary" }}
            >
              {label}
            </Typography>
          )}
        </Grid>
        <Grid item xs>
          <Box sx={{ display: "flex", overflowWrap: "break-word" }}>
            {priorityValue === 1 && (
              <Tooltip
                title={t(
                  "strGen:components.inventoryicons.getpriorityicons.high"
                )}
              >
                <KeyboardArrowUp
                  fontSize="small"
                  color="error"
                  sx={{ paddingTop: 0.75, paddingRight: 0.5 }}
                />
              </Tooltip>
            )}
            {priorityValue === 2 && (
              <Tooltip
                title={t(
                  "strGen:components.inventoryicons.getpriorityicons.medium"
                )}
              >
                <DragHandle
                  fontSize="small"
                  color="warning"
                  sx={{ paddingTop: 0.75, paddingRight: 0.5 }}
                />
              </Tooltip>
            )}
            {priorityValue === 3 && (
              <Tooltip
                title={t(
                  "strGen:components.inventoryicons.getpriorityicons.low"
                )}
              >
                <KeyboardArrowDown
                  fontSize="small"
                  color="info"
                  sx={{ paddingTop: 0.75, paddingRight: 0.5 }}
                />
              </Tooltip>
            )}
            <Typography
              variant={showEmphasis ? "h6" : "body2"}
              sx={{ color: !color ? "text.secondary" : color }}
            >
              {!value ? "\u00A0" : value}
            </Typography>
          </Box>
        </Grid>
        {showEdit && (
          <Grid item width={30}>
            <IconButton onClick={editChange} size="small">
              <Edit fontSize="inherit" />
            </IconButton>
          </Grid>
        )}
      </Grid>

      {showFooter && <Divider />}
    </div>
  );
};

IppDisplayPriority.defaultProps = {
  showFooter: true,
  showEdit: true,
  showLabel: true,
  isWebLink: false,
  openInNewTab: true,
  showEmphasis: false,
};
