import axiosClient from "api/axiosClient";
import { IppDebug } from "app/App";

export interface EngagementHealth {
  GroupID: number;
  GroupName: string;
  LevelOfInfluence: number;
  LevelOfInterest: number;
  InteractionCount: number;
}

export interface EngagementHealthsResult {
  engagementHealths: EngagementHealth[];
}

export async function getEngagementHealths(
  accessToken: String
): Promise<EngagementHealthsResult> {
  const url = `/charts/engagementHealth`;

  try {
    const { data } = await axiosClient.get<EngagementHealthsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
