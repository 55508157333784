import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  EngagementHealth,
  EngagementHealthsResult,
  getEngagementHealths,
} from "api/stakeholder/charts/engagementHealthAPI";
import { AppThunk } from "app/store";

interface EngagementHealthState {
  engagementHealthsById: Record<number, EngagementHealth>;
  engagementHealthList: number[];
  isLoading: boolean;
  error: string | null;
}

const EngagementHealthInitialState: EngagementHealthState = {
  engagementHealthsById: {},
  engagementHealthList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: EngagementHealthState) {
  state.isLoading = true;
}

function loadingFailed(
  state: EngagementHealthState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const engagementHealths = createSlice({
  name: "engagementHealths",
  initialState: EngagementHealthInitialState,
  reducers: {
    getEngagementHealthsStart: startLoading,
    getEngagementHealthsSuccess(
      state,
      { payload }: PayloadAction<EngagementHealthsResult>
    ) {
      const { engagementHealths } = payload;
      state.isLoading = false;
      state.error = null;

      engagementHealths.forEach((item) => {
        state.engagementHealthsById[item.GroupID] = item;
      });

      state.engagementHealthList = engagementHealths.map(
        (item) => item.GroupID
      );
    },
    getEngagementHealthsFailure: loadingFailed,
  },
});

export const {
  getEngagementHealthsStart,
  getEngagementHealthsSuccess,
  getEngagementHealthsFailure,
} = engagementHealths.actions;

export default engagementHealths.reducer;

export const fetchEngagementHealths =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getEngagementHealthsStart());
      const engagementHealths = await getEngagementHealths(accessToken);
      // console.log("RETURN", engagementHealths);
      dispatch(getEngagementHealthsSuccess(engagementHealths));
    } catch (err: any) {
      dispatch(getEngagementHealthsFailure(err.toString()));
    }
  };
