import { RootState } from "app/rootReducer";
import React, { useState } from "react";
import { useSelector } from "react-redux";

export const UserWriteAccess = (
  module:
    | "Benefits"
    | "Engagement"
    | "Commitment"
    | "EngComm"
    | "Suppplier"
    | "SSHE",
  projID?: number
) => {
  // this always loads with the app no need to dispatch
  const {
    currentProfile,
    currentUserRoleList,
    currentUserRolesById,
    isLoading,
    error: profileError,
  } = useSelector((state: RootState) => state.profile);

  const curUserRoles = currentUserRoleList.map(
    (id) => currentUserRolesById[id]
  );

  let moduleID = 0;
  let roleID: number[] = [0]; //array cause benefits has 2 write roles

  switch (module) {
    case "Benefits":
      moduleID = 1;
      roleID = [1, 2];
      break;
    case "Engagement":
      moduleID = 3;
      roleID = [5];
      break;
    case "Commitment":
      moduleID = 2;
      roleID = [3];
      break;
    case "EngComm":
      moduleID = 4;
      roleID = [3, 5]; // contributor role 4 excluded
      break;
    case "Suppplier":
      moduleID = 5;
      roleID = [6];
      break;
    case "SSHE":
      moduleID = 5;
      roleID = [11];
  }

  // always shows to an admin user
  if (currentProfile.IsClientAdmin) {
    return true;
  } else if (module && !projID) {
    // case where object is in both engagement and commitment check for write access
    if (moduleID === 4) {
      return curUserRoles.find((role) => roleID.includes(role.ModuleRoleID))
        ? true
        : false;
    }
    // case for individual modules check against write roles
    else
      return curUserRoles.find(
        (role) =>
          role.ModuleID == moduleID && roleID.includes(role.ModuleRoleID)
      )
        ? true
        : false;
  } else if (projID) {
    // case for specific project on a module
    return curUserRoles.find(
      (role) =>
        role.ProjectID == projID &&
        role.ModuleID == moduleID &&
        [1, 2, 3, 5].includes(role.ModuleRoleID)
    )
      ? true
      : false;
  }
  // add missing supplier power user case
  else return false;
};
export {};
