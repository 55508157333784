import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { useParams } from "react-router-dom";
import { Alert } from "@mui/material";
import LoadingIndicator from "components/LoadingIndicator";
import { fetchAllSshes } from "./SsheSlice";
import { SsheForm } from "./SsheForm";
import { useTranslation } from "react-i18next";
interface SsheProps {
  ssheID: string;
}

export const SsheDetailPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation(["objStk", "objPlt", "strGen", "objCom"]);

  const { isLoading, error, sshesById } = useSelector(
    (state: RootState) => state.sshe
  );

  // get sshe ID from url param
  const { ssheID } = useParams<SsheProps>();
  const itemID = parseInt(ssheID, 10); // invalid param will return NaN
  const paramErr = `${t(
    "strGen:errors.wentwrong"
  )} - ${ssheID} is not a valid SSHE ID`;

  const sshe = sshesById[parseInt(ssheID, 10)];
  const isSubmitted = sshe?.IsSubmitted;

  // fetch sshes
  useEffect(() => {
    (async () => {
      try {
        // can avoid going to the DB if we navigated from inventory
        if (!sshe?.CompanyName) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchAllSshes(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  let ssheDetailView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      {/* parma is invalid */}
      {Number.isNaN(itemID) && (
        <Alert severity="error" sx={{ mb: 2 }}>
          <span>{paramErr}</span>
        </Alert>
      )}
      {/* get sshes error */}
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          <span>
            <b>{t("strGen:errors.wentwrong")}</b> - {error.toString()}
          </span>
        </Alert>
      )}
      {/* display sshe if exists */}
      {sshe ? (
        <SsheForm sshe={sshe} isSubmitted={isSubmitted} ssheID={ssheID} />
      ) : (
        <Alert severity="error" sx={{ mb: 2 }}>
          <span>{paramErr}</span>
        </Alert>
      )}
    </div>
  );

  return <div id="sshe-detail-page">{ssheDetailView}</div>;
};
