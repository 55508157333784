const WorkHourUploadGuide = ({ closeModalGuide }: { closeModalGuide: any }) => {
  const columns = [
    {
      column: "A",
      value: "Employee ID",
      description: "The unique ID of the Employee",
    },
    {
      column: "B",
      value: "Hours",
      description: "The number of regular hours the Employee has worked",
    },
    {
      column: "C",
      value: "Alternate Hours 2",
      description: "The number of overtime hours the Employee has worked",
    },
    {
      column: "D",
      value: "Alternate Hours 3",
      description:
        "The number of 'special' hours the Employee has worked (ie onshore/offshore/training)",
    },
  ];

  return (
    <div className="modal-overlay">
      <div className="modal">
        <span className="close" onClick={closeModalGuide}>
          &times;
        </span>
        <h2>Upload Work Hour Details</h2>
        <p>
          Work Hour data is uploaded to the platform using an Excel spreadsheet
          and must be in Excel format (.xlsx) <br />
          Refer to the guide below to correctly fill out your spreadsheet.
        </p>
        <div className="guide-table">
          <div className="guide-row">
            <div className="column-header">Column</div>
            <div className="value-header">Value</div>
            <div className="description-header">Description</div>
          </div>
          <div className="scrollable-content">
            {columns.map(({ column, value, description }) => (
              <div className="guide-row" key={column}>
                <div className="guide-cell">{column}</div>
                <div className="guide-cell value-cell">{value}</div>
                <div className="guide-cell description-cell">{description}</div>
              </div>
            ))}
          </div>
          <strong>
            <p>The following field is required: Employee ID</p>
          </strong>
        </div>
      </div>
    </div>
  );
};

export default WorkHourUploadGuide;
