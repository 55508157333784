import axiosClient from "./axiosClient";

export interface ClientCountry {
  ClientCountryID: number;
  ClientID: number;
  CountryID: number;
  CountryName: string;
}

export interface ClientCountriesResult {
  clientCountries: ClientCountry[];
}

export async function getClientCountries(
  accessToken: String
): Promise<ClientCountriesResult> {
  const url = `/clientCountry`;

  try {
    const { data } = await axiosClient.get<ClientCountriesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createClientCountry(
  accessToken: String,
  newClientCountry: Partial<ClientCountry>
): Promise<ClientCountry> {
  const url = `/ClientCountry`;
  console.log("country", newClientCountry);
  try {
    const { data } = await axiosClient.post<ClientCountry>(
      url,
      newClientCountry,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateClientCountry(
  accessToken: String,
  ClientCountryID: number,
  newClientCountry: Partial<ClientCountry>
): Promise<ClientCountry> {
  const url = `/ClientCountry/` + ClientCountryID;

  try {
    const { data } = await axiosClient.post<ClientCountry>(
      url,
      newClientCountry,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteClientCountry(
  accessToken: String,
  ClientCountryID: number
): Promise<string> {
  const url = `/ClientCountry/` + ClientCountryID;
  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    console.log("DELDATA", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}
