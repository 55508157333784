import { useAuth0 } from "@auth0/auth0-react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import {
  addProjectComponent,
  updProjectComponent,
} from "./ProjectComponentSlice";
import { IppTextField } from "components/IppTextField";
import { useTypedTranslation } from "utils/customHooks";
import { IppFormButtons } from "components/Buttons/IppFormButtons";

interface DeletePaymentProps {
  isOpen: boolean;
  handleClose: any;
  cmp: any;
  cmpID: number;
  isAdding: boolean;
}

export const EditComponentDialog = (props: DeletePaymentProps) => {
  const { isOpen, handleClose, cmp, cmpID, isAdding } = props;
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen"]);

  const validationSchema = yup.object({
    ProjectComponentName: yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.projectcomponent.fields.name"),
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.projectcomponent.fields.name"),
          count: 50,
        })
      ),
    ComponentLocation: yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.projectcomponent.fields.locationfull"),
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.projectcomponent.fields.locationfull"),
          count: 50,
        })
      ),
  });

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        if (isAdding) {
          dispatch(addProjectComponent(accessToken, values));
        } else {
          dispatch(updProjectComponent(accessToken, cmpID, values));
        }
        handleClose();
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const formik = useFormik({
    initialValues: cmp,
    validationSchema: validationSchema,
    onSubmit: onSub,
    enableReinitialize: true,
  });

  return (
    <Dialog maxWidth="sm" open={isOpen} onClose={handleClose}>
      <DialogTitle>
        {isAdding
          ? t("strGen:buttons.addobj", {
              objectname: t("objPlt:objects.projectcomponent.fullname"),
            })
          : t("strGen:buttons.editobj", {
              objectname: t("objPlt:objects.projectcomponent.fullname"),
            })}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              Please enter any changes/additions below.
            </Grid>
            <Grid item xs={12}>
              <IppTextField
                id="ProjectComponentName"
                label={t("objPlt:objects.projectcomponent.name")}
                value={formik.values.ProjectComponentName}
                onChangeFunction={formik.handleChange}
                errorsExpression={formik.errors.ProjectComponentName}
                touchedExpression={formik.touched.ProjectComponentName}
                isEditing={true}
                setIsEditing={null}
              />
            </Grid>
            <Grid item xs={12}>
              <IppTextField
                id="ComponentLocation"
                label={t("objPlt:objects.projectcomponent.fields.location")}
                value={formik.values.ComponentLocation}
                onChangeFunction={formik.handleChange}
                errorsExpression={formik.errors.ComponentLocation}
                touchedExpression={formik.touched.ComponentLocation}
                isEditing={true}
                setIsEditing={null}
              />
            </Grid>
            <Grid item xs={12}>
              <IppFormButtons
                isEditing={true}
                setIsEditing={null}
                isAdding={isAdding}
                showCancel={true}
                resetFunction={() => {
                  formik.resetForm();
                  handleClose();
                }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};
