import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Upload } from "@progress/kendo-react-upload";
import { read, utils } from "xlsx";
import { RootState } from "app/rootReducer";
import { useParams } from "react-router-dom";
import { fetchWorkHourPeriods } from "../WorkHourPeriodSlice";
import { fetchCompanies } from "features/benefits/companies/CompaniesSlice";
import { fetchEmployeesByCompany } from "features/benefits/employees/EmployeeSlice";
import { Box, Grid, Paper } from "@mui/material";
import { IppFormHeader } from "components/IppFormHeader";
import { IppFormDivider } from "components/IppFormDivider";
import { FileUploadErrors } from "components/FileUploadErrors/FileUploadErrors";
import { push } from "redux-first-history";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import * as Constants from "utils/snackBarConstants";
import { formatStringForCompare } from "utils/functions";
import WorkHourUploadGuide from "./WorkHourUploadGuide";
import Info from "@mui/icons-material/Info";
import { fetchJobPositionsByClient } from "features/datalists/JobPositionSlice";
import { checkEmployeeNeedsReview } from "utils/benefits/employeeFunctions";
import { fetchRegionsByRecordType } from "../../regions/RegionSlice";
import { IppButton } from "components/Buttons/IppButton";
import {
  generateWorkHoursErrorTitles,
  getNewWorkHoursConstructedErrors,
  getNewWorkHoursConstructedWarnings,
} from "./WorkHoursUploadErrors";
import {
  processConstructedErrorsObject,
  pushListError,
} from "utils/uploadUtils";
import { ProcessedUploadErrors } from "utils/types/index.types";
import { useTypedTranslation } from "utils/customHooks";
import { validateCurrency } from "utils/functions";

const PREFIX = "WorkHourUploadPage";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1000,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

interface WHUploadProps {
  periodID: string;
}

export const WorkHourUploadPage = (props: any) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const t = useTypedTranslation(["strGen", "objPlt", "objBen"]);

  const [A0token, setA0token] = useState("");
  const [companyId, setCompanyId] = useState(-1);
  const [processing, setProcessing] = useState(false);
  const [warnings, setWarnings] = useState<ProcessedUploadErrors[]>([]);
  const [files, setFiles] = useState<Partial<any>>({
    files: [] as any,
    events: [] as any,
    errors: [] as any,
    emptyFile: false,
  });

  const [isModalGuideOpen, setModalGuideOpen] = useState(false);

  // get period ID from url param and attempt to find company in store
  const { periodID } = useParams<WHUploadProps>();
  const perID = parseInt(periodID, 10);

  const {
    ft_ben_AltWorkHours2,
    ft_ben_AltWorkHours3,
    ft_ben_JobPosition,
    ft_ben_Region,
    ft_ben_DiversityDeclined,
    ft_ben_WageAmount,
  } = useSelector((state: RootState) => state.client);

  const { regionList, regionsById } = useSelector(
    (state: RootState) => state.region
  );

  const regions = ft_ben_Region ? regionList.map((id) => regionsById[id]) : [];
  const hasRegionTypes = !!(ft_ben_Region && regions.length > 0);

  const empNeedsReviewToggles = {
    ft_ben_JobPosition: ft_ben_JobPosition ?? false,
    ft_ben_DiversityDeclined: ft_ben_DiversityDeclined ?? false,
    hasRegionTypes: hasRegionTypes,
  };

  const { workHourPeriodsById, isLoading } = useSelector(
    (state: RootState) => state.workHourPeriods
  );

  const period = workHourPeriodsById[perID];

  const {
    employeeList,
    employeesById,
    isLoading: empIsLoading,
  } = useSelector((state: RootState) => state.employees);
  const employees = employeeList.map((EmployeeID) => employeesById[EmployeeID]);

  const { jobPositionList, jobPositionsById } = useSelector(
    (state: RootState) => state.jobPositions
  );

  const jobPositions = ft_ben_JobPosition
    ? jobPositionList.map((id) => jobPositionsById[id])
    : [];

  const hoursLabel = t("objBen:objects.workhour.detail.fields.hours");

  const altHours2 = t("objBen:objects.workhour.detail.fields.althours1full");

  const altHours3 = t("objBen:objects.workhour.detail.fields.althours2full");

  const wageAmount = t("objBen:objects.workhour.detail.fields.wageamount");

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        dispatch(fetchCompanies(accessToken, 1));
        if (ft_ben_JobPosition)
          dispatch(fetchJobPositionsByClient(accessToken));
        if (ft_ben_Region)
          dispatch(fetchRegionsByRecordType(accessToken, "Employee"));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        setA0token(accessToken);

        if (!period) {
          dispatch(fetchWorkHourPeriods(accessToken));
        } else {
          setCompanyId(period.CompanyID);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently, period]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        if (companyId !== -1) {
          dispatch(fetchEmployeesByCompany(accessToken, companyId));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently, companyId]);

  const basePath = process.env.REACT_APP_API;
  const pathArray = window.location.pathname.split("/");
  const clientShortName = pathArray[1];
  const baseURL = `${basePath}/${clientShortName}/api`;

  const fileStatuses = [
    "UploadFailed",
    "Initial",
    "Selected",
    "Uploading",
    "Uploaded",
    "RemoveFailed",
    "Removing",
  ];

  const onBeforeUpload = (event: any) => {
    event.headers.Authorization = `Bearer ${A0token}`;
    event.additionalData.periodID = perID;
  };

  const onAdd = (event: any) => {
    const afterStateChange = () => {
      event.affectedFiles
        .filter((file: any) => !file.validationErrors)
        .forEach((file: any) => {
          const reader = new FileReader();

          reader.onloadend = (ev) => {
            var data = ev.target ? ev.target.result : null;
            var workbook = read(data, {
              type: "binary",
            });
            var sheetName = workbook.SheetNames[0];

            try {
              const XL_row_object = utils.sheet_to_json(
                workbook.Sheets[sheetName]
              );

              const XL_header_object: any[] = utils.sheet_to_json(
                workbook.Sheets[sheetName],
                { header: 1 }
              );

              let sheetIsValid = true;
              let emptyFile = false;
              let hasEmpMissingRequiredData = false;

              //validate data from excel sheet here
              //-------------------------------------
              const constructedErrors = getNewWorkHoursConstructedErrors();
              const constructedWarnings = getNewWorkHoursConstructedWarnings();

              //make sure sheet isn't empty
              if (XL_row_object.length < 1) {
                emptyFile = true;
              } else {
                //Check required headers exist
                const headers = XL_header_object[0];

                // Hours columns handled separately so not included in this list -- unless both alt hours columns are disabled or missing from the sheet, in which case Hours is enforced as required
                let stringPropList = [
                  { propName: "Employee ID", req: true },
                  {
                    propName: hoursLabel,
                    req: !(
                      (ft_ben_AltWorkHours2 && headers.includes(altHours2)) ||
                      (ft_ben_AltWorkHours3 && headers.includes(altHours3))
                    ),
                  },
                ];

                stringPropList.forEach((prop) => {
                  if (!headers.includes(prop.propName) && prop.req) {
                    pushListError(
                      constructedErrors.requiredColumn,
                      prop.propName
                    );
                  }
                });

                const newEmployeesWarningObject: {
                  header: string;
                  rowNumber: number;
                }[] = [];

                //Check required Headers exist and data is in correct format
                XL_row_object.forEach((row: any, ix: number) => {
                  let rowNum = row.__rowNum__ + 1;

                  stringPropList.forEach((prop: any) => {
                    if (
                      prop.req === true &&
                      headers.includes(prop.propName) &&
                      (prop.propName in row !== true ||
                        !row[prop.propName] ||
                        row[prop.propName]?.toString().trim() === "")
                    ) {
                      pushListError(constructedErrors.requiredData, {
                        header: prop.propName,
                        rowNumber: rowNum,
                      });
                    }

                    if (
                      prop.propName in row === true &&
                      typeof row[prop.propName].toString() != "string"
                    ) {
                      pushListError(constructedErrors.incorrectType, {
                        header: prop.propName,
                        rowNumber: rowNum,
                        expectedValue: "a text value",
                      });
                    }
                  });

                  //Validate that Employee exists
                  const valEmployee = employees.find(
                    (emp) =>
                      formatStringForCompare(emp.CompanyEmployeeID) ===
                      formatStringForCompare(row["Employee ID"])
                  );

                  if (!valEmployee) {
                    pushListError(
                      constructedWarnings.employeeDoesntExist,
                      {
                        header: row["Employee ID"]?.toString().trim(),
                        rowNumber: rowNum,
                      },
                      true
                    );
                  } else if (
                    !hasEmpMissingRequiredData &&
                    checkEmployeeNeedsReview(
                      valEmployee,
                      empNeedsReviewToggles,
                      jobPositions
                    )
                  ) {
                    hasEmpMissingRequiredData = true;

                    // setting the count to 1 will ensure the error message shows
                    constructedWarnings.outdatedRecords.count = 1;
                  }

                  //Validate Hours columns are number types if they exist
                  if (
                    hoursLabel in row === true &&
                    row[hoursLabel]?.toString().trim() !== "" &&
                    typeof row[hoursLabel] != "number" &&
                    Number.isNaN(Number(row[hoursLabel].toString().trim()))
                  ) {
                    pushListError(constructedErrors.incorrectType, {
                      header: hoursLabel,
                      rowNumber: rowNum,
                      expectedValue: "a number",
                    });
                  }

                  if (
                    altHours2 in row === true &&
                    row[altHours2]?.toString().trim() !== "" &&
                    typeof row[altHours2] != "number" &&
                    Number.isNaN(Number(row[altHours2].toString().trim()))
                  ) {
                    pushListError(constructedErrors.incorrectType, {
                      header: altHours2,
                      rowNumber: rowNum,
                      expectedValue: "a number",
                    });
                  }

                  if (
                    altHours3 in row === true &&
                    row[altHours3]?.toString().trim() !== "" &&
                    typeof row[altHours3] != "number" &&
                    Number.isNaN(Number(row[altHours3].toString().trim()))
                  ) {
                    pushListError(constructedErrors.incorrectType, {
                      header: altHours3,
                      rowNumber: rowNum,
                      expectedValue: "a number",
                    });
                  }

                  if (
                    (row[hoursLabel] === 0 ||
                      !row[hoursLabel] ||
                      row[hoursLabel]?.toString().trim() === "") &&
                    (row[altHours2] === 0 ||
                      !row[altHours2] ||
                      row[altHours2]?.toString().trim() === "") &&
                    (row[altHours3] === 0 ||
                      !row[altHours3] ||
                      row[altHours3]?.toString().trim() === "")
                  ) {
                    pushListError(
                      constructedWarnings.invalidHours,
                      `Row ${rowNum}`
                    );
                  }

                  if (!ft_ben_AltWorkHours2) {
                    // Check that Alternate Hours 2 is left empty or only an empty string
                    if ((row[altHours2]?.toString().trim() ?? "") !== "") {
                      pushListError(
                        constructedWarnings.disabledFeature,
                        {
                          header: altHours2,
                          rowNumber: rowNum,
                        },
                        true // onlyCountUniqueHeaders
                      );
                    }
                  }

                  if (!ft_ben_AltWorkHours3) {
                    // Check that Alternate Hours 3 is left empty or only an empty string
                    if ((row[altHours3]?.toString().trim() ?? "") !== "") {
                      pushListError(
                        constructedWarnings.disabledFeature,
                        {
                          header: altHours3,
                          rowNumber: rowNum,
                        },
                        true // onlyCountUniqueHeaders
                      );
                    }
                  }

                  if (!ft_ben_WageAmount) {
                    // Check if the WageAmount column exists and contains non-empty data
                    if (wageAmount in row === true) {
                      const wageValue = row[wageAmount]?.toString().trim();
                      if (wageValue !== "") {
                        pushListError(
                          constructedWarnings.disabledFeature,
                          {
                            header: wageAmount,
                            rowNumber: rowNum,
                          },
                          true // onlyCountUniqueHeaders
                        );
                      }
                    }
                  }

                  // Validate currency fields
                  if (wageAmount in row === true) {
                    const amountValue = row[wageAmount]?.toString().trim();

                    if (!validateCurrency(amountValue)) {
                      pushListError(constructedErrors.incorrectType, {
                        header: wageAmount,
                        rowNumber: rowNum,
                        expectedValue: "a valid currency format (e.g., 123.45)",
                      });
                    }
                  }
                });
              }

              //-------------------------------------
              const fileErrors = processConstructedErrorsObject(
                constructedErrors,
                generateWorkHoursErrorTitles
              );

              const fileWarnings = processConstructedErrorsObject(
                constructedWarnings,
                generateWorkHoursErrorTitles
              );

              if (fileWarnings.length > 0) {
                setWarnings(fileWarnings);
              }

              if (fileErrors.length > 0 || emptyFile) {
                sheetIsValid = false;
              }

              if (sheetIsValid) {
                setProcessing(false);
              } else {
                setFiles({
                  files: [],
                  events: [
                    ...files.events,
                    `File failed validatation: ${event.affectedFiles[0].name}`,
                  ],
                  errors: fileErrors,
                  emptyFile: emptyFile,
                });
                setProcessing(false);
              }
            } catch (err: any) {
              throw err;
            }
          };

          reader.onerror = function (ex) {
            console.log(ex);
          };

          reader.readAsBinaryString(file.getRawFile());
        });
    };

    setProcessing(true);

    setFiles({
      files: event.newState,
      events: [
        ...files.events,
        `File selected: ${event.affectedFiles[0].name}`,
      ],
      errors: [],
    });

    afterStateChange();
    setProcessing(false);

    // clear state
    setWarnings([]);
  };

  const onRemove = (event: any) => {
    setFiles({
      files: event.newState,
      events: [...files.events, `File removed: ${event.affectedFiles[0].name}`],
      errors: [],
    });
    setWarnings([]);
    setProcessing(false);

    // clear state
    setWarnings([]);
  };

  const onProgress = (event: any) => {
    setFiles({
      files: event.newState,
      events: [
        ...files.events,
        `On Progress: ${event.affectedFiles[0].progress} %`,
      ],
      errors: [...files.errors],
    });
  };

  const onStatusChange = (event: any) => {
    const file = event.affectedFiles[0];

    setFiles({
      files: event.newState,
      events: [
        ...files.events,
        `File '${file.name}' status changed to: ${fileStatuses[file.status]}`,
      ],
      errors: [...files.errors],
    });

    // Check if all files have been uploaded
    const allUploaded = event.newState.every(
      (file: any) => file.status === 4 // 4 corresponds to 'Uploaded' status
    );

    if (allUploaded) {
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push(`/benefits/workhours/${perID}`));
    }
  };

  const openModalGuide = () => {
    setModalGuideOpen(true);
  };

  const closeModalGuide = () => {
    setModalGuideOpen(false);
  };

  let workHourUploadView = (
    <Root>
      <Box display="flex" justifyContent="center">
        <Paper className={classes.boxSpace}>
          <Grid container className={classes.editForm} spacing={1}>
            <IppFormHeader
              title="Work-Hour Details"
              isEditing={true}
              isAdding={true}
              returnPath={`/benefits/workhours/${perID}`}
            />
            <Grid item container xs justifyContent="flex-end">
              <IppButton
                onClick={openModalGuide}
                startIcon={<Info />}
                variant="text"
              >
                Guide
              </IppButton>
              {isModalGuideOpen && (
                <WorkHourUploadGuide closeModalGuide={closeModalGuide} />
              )}
            </Grid>
            <IppFormDivider title="File Details" />

            <Grid item xs={12}>
              <FileUploadErrors
                errors={files.errors}
                emptyFile={files.emptyFile}
              />

              {files.errors.length === 0 ? (
                <FileUploadErrors errors={warnings} isWarning />
              ) : (
                ""
              )}
            </Grid>

            <Grid item xs={12}>
              <Upload
                disabled={isLoading || empIsLoading}
                showActionButtons={
                  !processing && files.errors.length === 0 && A0token !== ""
                }
                autoUpload={false}
                multiple={false}
                files={files.files}
                restrictions={{
                  allowedExtensions: [".xlsx"],
                }}
                withCredentials={true}
                onAdd={onAdd}
                onRemove={onRemove}
                onProgress={onProgress}
                onStatusChange={onStatusChange}
                onBeforeUpload={onBeforeUpload}
                saveUrl={`${baseURL}/workHourDetail/upload`}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Root>
  );

  return <div id="workhour-upload-page">{workHourUploadView}</div>;
};
