import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  FollowupLog,
  FollowupLogsResult,
  getFollowupLogs,
} from "api/stakeholder/logTables/followupLogAPI";
import { AppThunk } from "app/store";

interface FollowupLogState {
  followupLogsById: Record<number, FollowupLog>;
  followupLogList: number[];
  isLoading: boolean;
  error: string | null;
}

const FollowupLogInitialState: FollowupLogState = {
  followupLogsById: {},
  followupLogList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: FollowupLogState) {
  state.isLoading = true;
}

function loadingFailed(state: FollowupLogState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const followupLogs = createSlice({
  name: "followupLogs",
  initialState: FollowupLogInitialState,
  reducers: {
    getFollowupLogsStart: startLoading,
    getFollowupLogsSuccess(
      state,
      { payload }: PayloadAction<FollowupLogsResult>
    ) {
      const { followupLogs } = payload;
      state.isLoading = false;
      state.error = null;

      followupLogs.forEach((item) => {
        state.followupLogsById[item.InteractionID] = item;
      });

      state.followupLogList = followupLogs.map((item) => item.InteractionID);
    },
    getFollowupLogsFailure: loadingFailed,
  },
});

export const {
  getFollowupLogsStart,
  getFollowupLogsSuccess,
  getFollowupLogsFailure,
} = followupLogs.actions;

export default followupLogs.reducer;

export const fetchFollowupLogs =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getFollowupLogsStart());
      const followupLogs = await getFollowupLogs(accessToken);
      dispatch(getFollowupLogsSuccess(followupLogs));
    } catch (err: any) {
      dispatch(getFollowupLogsFailure(err.toString()));
    }
  };
