import {
  IconButton,
  InputAdornment,
  TextField,
  Autocomplete,
  Chip,
  Dialog,
  DialogContent,
  Typography,
  Paper,
  Box,
  MenuList,
  MenuItem,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { ContactChildForm } from "features/platform/contacts/ContactChildForm";
import { StakeholderGroupChildForm } from "features/platform/groups/StakeholderGroupChildForm";
import { IssueChildForm } from "features/stakeholder/issue/IssueChildForm";
import { useDispatch } from "react-redux";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { formatStringForCompare } from "utils/functions";
import { useTypedTranslation } from "utils/customHooks";
import { defaultRenderOption } from "utils/renderFunctions";

interface IppTokenAutocompleteProps {
  id: string;
  label: string;
  options: any;
  selectedValues: any;
  setSelectedValues: any;
  touchedExpression?: boolean | undefined;
  errorsExpression?: string[] | undefined;
  isEditing: boolean;
  setIsEditing: any;
  setCanSave?: any;
  singleItem?: boolean;
  noOptionsText?: string;
  renderOption?: any;
}

export interface tokenItem {
  itemID: number;
  itemText: string;
}

export const IppTokenAutocomplete = (props: IppTokenAutocompleteProps) => {
  const {
    id,
    label,
    options,
    selectedValues,
    setSelectedValues,
    touchedExpression,
    errorsExpression,
    isEditing,
    setIsEditing,
    setCanSave,
    singleItem,
    noOptionsText,
    renderOption,
  } = props;
  const dispatch = useDispatch();
  const t = useTypedTranslation(["strGen"]);

  const [showContact, setShowContact] = useState(false);
  const [showGroup, setShowGroup] = useState(false);
  const [showIssue, setShowIssue] = useState(false);
  const [newItem, setNewItem] = useState("");
  const [didSaveChild, setDidSaveChild] = useState(false);
  const [isTypingNewItem, setIsTypingNewItem] = useState(false);
  const [typedEntry, setTypedEntry] = useState("");

  const editChange = () => {
    setIsEditing(!isEditing);
  };

  const closeContact = (event: any, reason: any) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      dispatch(
        openSnackBar(t("strGen:components.snackbar.saveorcancel"), "info")
      );
    } else {
      // form was closed using either Save or Cancel
      // remove last item from list
      setShowContact(false);
      if (setCanSave != undefined) setCanSave(false);
    }
  };

  const closeGroup = (event: any, reason: any) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      dispatch(
        openSnackBar(t("strGen:components.snackbar.saveorcancel"), "info")
      );
    } else {
      // form was closed using either Save or Cancel
      // remove last item from list

      setShowGroup(false);
      if (setCanSave != undefined) setCanSave(false);
    }
  };

  const closeIssue = (event: any, reason: any) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      dispatch(
        openSnackBar(t("strGen:components.snackbar.saveorcancel"), "info")
      );
    } else {
      // form was closed using either Save or Cancel
      // remove last item from list
      setShowIssue(false);
      if (setCanSave != undefined) setCanSave(false);
    }
  };

  function handleClickAdd() {
    try {
      switch (id) {
        case "GroupList":
          setNewItem(typedEntry);
          setIsTypingNewItem(false);
          setTypedEntry("");
          setShowGroup(true);
          break;

        case "IssueList":
          setNewItem(typedEntry);
          setIsTypingNewItem(false);
          setTypedEntry("");
          setShowIssue(true);
          break;

        default:
          break;
      }
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    //When a new item is added successfully, we should add it to the selected values.
    if (newItem && !selectedValues.includes(newItem)) {
      setSelectedValues([...selectedValues, newItem]);
    }
  }, [JSON.stringify(options)]);

  useEffect(() => {
    if (didSaveChild) {
      // update selected values with last item added
      selectedValues.pop();
      selectedValues.push(newItem);
    } else {
      if (newItem === "" && id !== "GroupList" && id !== "IssueList") {
        selectedValues.pop();
      }
    }
  }, [didSaveChild, newItem]);

  useEffect(() => {
    if (singleItem && selectedValues.length > 1) {
      setSelectedValues([selectedValues[selectedValues.length - 1]]);
    }
  }, [selectedValues]);

  const handleInputChange = (event: any, value: any, reason: any) => {
    setTypedEntry(value);

    let exists = options.filter(
      (options: any) =>
        formatStringForCompare(options) === formatStringForCompare(value)
    );

    if (reason === "input" && value !== "" && exists.length === 0) {
      if (setCanSave != undefined) setCanSave(false);

      setIsTypingNewItem(true);
    } else {
      if (setCanSave != undefined) setCanSave(true);

      setIsTypingNewItem(false);
    }
  };

  const handleChange = (event: any, value: any, reason: any) => {
    setSelectedValues(value);

    // if new item added,show appropriate dialog
    // added item will always be the last in the list
    if (reason === "createOption") {
      let newString = value.pop();

      let exists = options.filter(
        (options: any) => options.toLowerCase() === newString.toLowerCase()
      );

      if (exists.length === 0) {
        setIsTypingNewItem(false);

        switch (id) {
          case "ContactList":
            setNewItem(newString.trim());
            setShowContact(true);
            break;

          case "GroupList":
            setNewItem(newString.trim());
            setShowGroup(true);
            break;

          case "IssueList":
            setNewItem(newString.trim());
            setShowIssue(true);
            break;

          default:
            break;
        }
      } else {
        let exists = selectedValues.filter(
          (options: any) => options.toLowerCase() === newString.toLowerCase()
        );

        if (exists.length === 0) {
          let test = [...selectedValues, newString];
          setSelectedValues(test);
        } else {
          let test = [...selectedValues];
          setSelectedValues(test);
        }
      }
    }
  };

  return (
    <div>
      {isEditing ? (
        <>
          {isTypingNewItem && (
            <Typography color="primary" variant="caption">
              {t("strGen:components.ipptokenautocomplete.newitem", {
                variable: typedEntry,
              })}
            </Typography>
          )}
          <Autocomplete
            multiple
            id={id}
            options={options}
            renderOption={
              renderOption ||
              ((props: any, option: any) => defaultRenderOption(props, option))
            }
            value={selectedValues}
            freeSolo
            handleHomeEndKeys={true}
            onChange={handleChange}
            inputValue={typedEntry}
            noOptionsText={noOptionsText}
            onInputChange={handleInputChange}
            PaperComponent={({ children }) => (
              <Paper>
                {children}
                {isTypingNewItem && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <MenuList>
                      <MenuItem
                        onMouseDown={(event) => {
                          event.preventDefault();
                          handleClickAdd();
                        }}
                      >
                        <Typography
                          variant="body2"
                          color="primary"
                          alignContent={"center"}
                        >
                          {t(
                            "strGen:components.ipptokenautocomplete.clickorenter",
                            { variable: typedEntry }
                          )}
                        </Typography>
                      </MenuItem>
                    </MenuList>
                  </Box>
                )}
              </Paper>
            )}
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                error={touchedExpression && Boolean(errorsExpression)}
                helperText={touchedExpression && errorsExpression}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </>
      ) : (
        <TextField
          id={id}
          name={id}
          label={label}
          value={selectedValues}
          multiline={true}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={editChange}>
                  <Edit />
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ shrink: true }}
        />
      )}
      <>
        <Dialog open={showContact} onClose={closeContact}>
          <DialogContent>
            <div>
              <ContactChildForm
                setAddingItem={closeContact}
                fullName={newItem}
                onClose={closeContact}
              />
            </div>
          </DialogContent>
        </Dialog>
        <Dialog open={showGroup} onClose={closeGroup}>
          <DialogContent>
            <div>
              <StakeholderGroupChildForm
                setAddingItem={closeGroup}
                groupName={newItem}
                setNewItem={setNewItem}
                setDidSaveChild={setDidSaveChild}
              />
            </div>
          </DialogContent>
        </Dialog>
        <Dialog open={showIssue} onClose={closeIssue}>
          <DialogContent>
            <div>
              <IssueChildForm
                setAddingItem={closeIssue}
                issueName={newItem}
                setNewItem={setNewItem}
                setDidSaveChild={setDidSaveChild}
              />
            </div>
          </DialogContent>
        </Dialog>
      </>
    </div>
  );
};

IppTokenAutocomplete.defaultProps = {
  singleItem: false,
};
