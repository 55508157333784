import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  AffiliationHoursChart,
  AffiliationHoursChartsResult,
  getAffiliationHoursCharts,
} from "api/affiliationHoursChartAPI";
import { AppThunk } from "app/store";

interface AffiliationHoursChartState {
  affiliationHoursChartsById: Record<number, AffiliationHoursChart>;
  affiliationHoursChartList: number[];
  isLoading: boolean;
  error: string | null;
}

const AffiliationHoursChartInitialState: AffiliationHoursChartState = {
  affiliationHoursChartsById: {},
  affiliationHoursChartList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: AffiliationHoursChartState) {
  state.isLoading = true;
}

function loadingFailed(
  state: AffiliationHoursChartState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const affiliationHoursCharts = createSlice({
  name: "spendtype",
  initialState: AffiliationHoursChartInitialState,
  reducers: {
    getAffiliationHoursChartsStart: startLoading,
    getAffiliationHoursChartsSuccess(
      state,
      { payload }: PayloadAction<AffiliationHoursChartsResult>
    ) {
      const { hoursbyaffiliation } = payload;
      state.isLoading = false;
      state.error = null;

      hoursbyaffiliation.forEach((item) => {
        state.affiliationHoursChartsById[item.IndigenousGroupID] = item;
      });

      state.affiliationHoursChartList = hoursbyaffiliation.map(
        (item) => item.IndigenousGroupID
      );
    },
    getAffiliationHoursChartsFailure: loadingFailed,
  },
});

export const {
  getAffiliationHoursChartsStart,
  getAffiliationHoursChartsSuccess,
  getAffiliationHoursChartsFailure,
} = affiliationHoursCharts.actions;

export default affiliationHoursCharts.reducer;

export const fetchAffiliationHoursCharts =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getAffiliationHoursChartsStart());
      const affiliationHoursCharts = await getAffiliationHoursCharts(
        accessToken
      );
      dispatch(getAffiliationHoursChartsSuccess(affiliationHoursCharts));
    } catch (err: any) {
      dispatch(getAffiliationHoursChartsFailure(err.toString()));
    }
  };
