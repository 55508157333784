import * as yup from "yup";
import { useTypedTranslation } from "utils/customHooks";

export const ProjectTypeValidationSchema = () => {
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  return yup.object({
    ProjectTypeName: yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.project.projecttype.projecttypename"),
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.project.projecttype.projecttypename"),
          count: 50,
        })
      ),
  });
};
