import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRnDExpenses } from "./RnDExpenseSlice";
import { useParams } from "react-router";
import LoadingIndicator from "components/LoadingIndicator";
import { Alert } from "@mui/material";
import { RnDExpenseForm } from "./RnDExpenseForm";

interface RnDExpenseDetProps {
  RnDExpenseID: string;
}

export const RnDExpenseDetailPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const {
    isLoading: RnDExpenseIsLoading,
    error,
    RnDExpensesById,
  } = useSelector((state: RootState) => state.RnDExpense);

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const { RnDExpenseID } = useParams<RnDExpenseDetProps>();
  const itemID = parseInt(RnDExpenseID, 10);

  const RnDExpense = RnDExpensesById[itemID];

  useEffect(() => {
    (async () => {
      try {
        if (!RnDExpense) {
          if (clientId !== -1) {
            const accessToken = await getAccessTokenSilently({
              authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
              },
            });
            dispatch(fetchRnDExpenses(accessToken));
          }
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently, RnDExpense]);

  let RnDExpenseDetView = RnDExpenseIsLoading ? (
    <LoadingIndicator />
  ) : (
    <>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          <span>
            <b>Something went wrong</b> - {error.toString()}
          </span>
        </Alert>
      )}
      {typeof itemID != "number" && (
        <Alert severity="error" sx={{ mb: 2 }}>
          <span>
            Something went wrong - {RnDExpenseID} is not a valid Expense ID
          </span>
        </Alert>
      )}
      <div>{RnDExpense && <RnDExpenseForm RnDExpense={RnDExpense} />}</div>
    </>
  );
  return <div id="RnDExpense-detail-page">{RnDExpenseDetView}</div>;
};
