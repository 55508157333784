import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import LoadingIndicator from "components/LoadingIndicator";
import { fetchIssues } from "./IssueSlice";
import { Grid, Typography, Box, Paper } from "@mui/material";
import {
  TreeView,
  TreeViewItemClickEvent,
} from "@progress/kendo-react-treeview";
import { createDataTree } from "@progress/kendo-react-treelist";
import { IssueCreatePage } from "./IssueCreatePage";
import { IssueForm } from "./IssueForm";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { UserWriteAccess } from "utils/userAccess";
import { IppAddButton } from "components/Buttons/IppAddButton";

interface IssueProps {
  issueID: string;
}

export const IssueInventoryPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation(["objStk", "strGen"]);
  const [isAdding, setIsAdding] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const { issueID } = useParams<IssueProps>();
  const itemID = parseInt(issueID, 10) || 0;
  const showEdit = UserWriteAccess("Engagement");

  const {
    issueList,
    issuesById,
    isLoading: issueIsLoading,
    error: issueError,
  } = useSelector((state: RootState) => state.issues);

  const issues = issueList.map((id) => issuesById[id]);

  const issueData = issues.map((p) => {
    let n = Object.assign({}, p) as any;
    if (p.IssueID === itemID) {
      n.selected = true;
    } else {
      n.selected = false;
    }

    return n;
  });

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchIssues(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  const [treeData, setTreeData] = useState(
    createDataTree(
      issueData,
      (i) => i.IssueID,
      (i) => i.ParentIssueID,
      "items"
    )
  );

  const [data, setData] = useState(treeData);

  useEffect(() => {
    if (issues.length > 0 && treeData.length === 0) {
      let dataTree = createDataTree(
        issueData,
        (i) => i.IssueID,
        (i) => i.ParentIssueID,
        "items"
      );
      setTreeData(dataTree);
    }

    if (treeData.length > 0) {
      if (data.length === 0) {
        setData(treeData);
      } else {
        setDataLoaded(true);
      }
    }
  }, [issues, treeData]);

  const onExpandChange = (event: any) => {
    event.item.expanded = !event.item.expanded;
    setData([...data]);
  };

  const [selectedItem, setSelectedItem] = useState(
    itemID > 0 ? issueData.find((item) => item.IssueID === itemID) : data[0]
  );

  useEffect(() => {
    if (data.length > 0 && itemID === 0 && !data[0].selected) {
      let initialItem = data[0];
      initialItem.selected = true;
      setSelectedItem(initialItem);
    }
  }, [data]);

  const onItemClick = (event: TreeViewItemClickEvent) => {
    if (selectedItem) {
      let newItem = selectedItem;
      newItem.selected = false;
      setSelectedItem(newItem);
    }
    event.item.selected = true;
    setSelectedItem(event.item);
  };

  let issueInvView = issueIsLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <Box padding={1}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="h5" component="h1">
                    {t("objStk:objects.issue.name", { count: 2 })}{" "}
                  </Typography>
                </Grid>
                {showEdit && (
                  <Grid item xs={6}>
                    <Grid container justifyContent="flex-end">
                      <IppAddButton onClick={() => setIsAdding(true)} />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={4} md={3} xl={2}>
          <Paper>
            <Box padding={1}>
              <TreeView
                data={data}
                expandIcons={true}
                onExpandChange={onExpandChange}
                textField="IssueName"
                onItemClick={onItemClick}
                className={"scrollable-treeview"}
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={8} md={9} xl={10}>
          {isAdding ? <IssueCreatePage /> : <IssueForm issue={selectedItem} />}
        </Grid>
      </Grid>
    </div>
  );

  return <div id="issue-inventory-page">{issueInvView}</div>;
};
