import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import LoadingIndicator from "components/LoadingIndicator";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import EmployeesByGenderChartSlice, {
  fetchEmployeesByGenderCharts,
} from "./EmployeesByGenderChartSlice";
import "hammerjs";
import { IppSkeleton } from "components/IppSkeleton";

export const EmployeesByGenderChart = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const {
    employeesByGenderChartList,
    employeesByGenderChartsById,
    error,
    isLoading,
  } = useSelector((state: RootState) => state.employeesByGenderCharts);

  const employeesByGenderData = employeesByGenderChartList.map(
    (id) => employeesByGenderChartsById[id]
  );

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchEmployeesByGenderCharts(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const categories = employeesByGenderData.map((item) => {
    let newItem = item.MonthName;
    return newItem;
  });

  let employeesByGenderChartForm = isLoading ? (
    <IppSkeleton height={350} />
  ) : (
    <div>
      <Chart style={{ height: 350 }}>
        {/* <ChartTitle text="Employees by Gender" /> */}

        <ChartLegend position="bottom" />

        <ChartCategoryAxis>
          <ChartCategoryAxisItem categories={categories} />
        </ChartCategoryAxis>

        <ChartValueAxis>
          <ChartValueAxisItem title={{ text: "Employees" }} />
        </ChartValueAxis>

        <ChartSeries>
          <ChartSeriesItem
            data={employeesByGenderData}
            type="column"
            style="smooth"
            field="Female"
            name="Female"
            tooltip={{ visible: true, format: "Female: {0:n0}" }}
          />
          <ChartSeriesItem
            data={employeesByGenderData}
            type="column"
            style="smooth"
            field="Male"
            name="Male"
            tooltip={{ visible: true, format: "Male: {0:n0}" }}
          />
          <ChartSeriesItem
            data={employeesByGenderData}
            type="column"
            style="smooth"
            field="Other"
            name="Other"
            tooltip={{ visible: true, format: "Other: {0:n0}" }}
          />
        </ChartSeries>
        <ChartLegend position="bottom" />
      </Chart>
    </div>
  );

  return <div id="work-hour-chart-form">{employeesByGenderChartForm}</div>;
};
