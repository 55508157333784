import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Feedback, createFeedback } from "api/FeedbackAPI";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { SnackBarConstants } from "utils/customHooks";

interface FeedbackState {
  feedbacksById: Record<number, Feedback>;
  feedbackList: number[];
  isLoading: boolean;
  error: string | null;
}

const FeedbackInitialState: FeedbackState = {
  feedbacksById: {},
  feedbackList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: FeedbackState) {
  state.isLoading = true;
}

function loadingFailed(state: FeedbackState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const feedbacks = createSlice({
  name: "feedback",
  initialState: FeedbackInitialState,
  reducers: {
    createFeedbackStart: startLoading,
    createFeedbackSuccess(state, { payload }: PayloadAction<Feedback>) {
      const { FeedbackID } = payload;
      state.feedbacksById[FeedbackID] = payload;
      state.feedbackList.push(FeedbackID);

      state.isLoading = false;
      state.error = null;
    },
    createFeedbackFailure: loadingFailed,
  },
});

export const {
  createFeedbackStart,
  createFeedbackSuccess,
  createFeedbackFailure,
} = feedbacks.actions;

export default feedbacks.reducer;

export const addFeedback =
  (
    accessToken: String,
    newFeedback: Partial<Feedback>,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createFeedbackStart());
      const feedback = await createFeedback(accessToken, newFeedback);
      dispatch(createFeedbackSuccess(feedback));
      dispatch(openSnackBar(snackbarConstants.ADD_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createFeedbackFailure(err.toString()));
      dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
    }
  };
