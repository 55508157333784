import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  Sshe,
  SshesResult,
  getAllSshes,
  createSshe,
  updateSshe,
  deleteSshe,
} from "api/sshe/ssheAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import * as Constants from "utils/snackBarConstants";
interface SsheState {
  sshesById: Record<number, Sshe>;
  ssheList: number[];
  isLoading: boolean;
  isLoadingAdd: boolean;
  error: string | null;
}

const SsheInitialState: SsheState = {
  sshesById: {},
  ssheList: [],
  isLoading: false,
  isLoadingAdd: false,
  error: null,
};

function startLoading(state: SsheState) {
  state.isLoading = true;
}

function startLoadingAdd(state: SsheState) {
  state.isLoadingAdd = true;
}

function loadingFailed(state: SsheState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const sshe = createSlice({
  name: "sshe",
  initialState: SsheInitialState,
  reducers: {
    getSshesStart: startLoading,
    getSshesSuccess(state, { payload }: PayloadAction<SshesResult>) {
      const { sshes } = payload;

      state.isLoading = false;
      state.error = null;

      sshes.forEach((sshe) => {
        state.sshesById[sshe.SSHE_ID] = sshe;
      });

      state.ssheList = sshes.map((sshe) => sshe.SSHE_ID);
    },
    getSshesFailure: loadingFailed,
    createSsheStart: startLoadingAdd,
    createSsheSuccess(state, { payload }: PayloadAction<Sshe>) {
      const { SSHE_ID } = payload;
      state.sshesById[SSHE_ID] = payload;
      state.ssheList.push(SSHE_ID);

      state.isLoading = false;
      state.isLoadingAdd = false;
      state.error = null;
    },
    updateSsheStart: startLoading,
    updateSsheSuccess(state, { payload }: PayloadAction<Sshe>) {
      const { SSHE_ID } = payload;

      state.sshesById[SSHE_ID] = payload;

      state.isLoading = false;
      state.error = null;
    },
    deleteSsheSuccess(state, { payload }: PayloadAction<number>) {
      const SSHE_ID = payload;
      delete state.sshesById[SSHE_ID];
      state.ssheList = state.ssheList.filter((item) => item !== SSHE_ID);
      state.isLoading = false;
      state.error = null;
    },
    createSsheFailure: loadingFailed,
  },
});

export const {
  getSshesStart,
  getSshesSuccess,
  getSshesFailure,
  createSsheStart,
  createSsheSuccess,
  updateSsheStart,
  updateSsheSuccess,
  deleteSsheSuccess,
  createSsheFailure,
} = sshe.actions;

export default sshe.reducer;

export const fetchAllSshes =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getSshesStart());
      const sshes = await getAllSshes(accessToken);
      dispatch(getSshesSuccess(sshes));
    } catch (err: any) {
      console.log(err);
      dispatch(getSshesFailure(err.toString()));
    }
  };

export const addSshe =
  (
    accessToken: String,
    newSshe: Partial<Sshe>,
    setReturnRoute: boolean,
    isTokenCreate?: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createSsheStart());
      const sshe = await createSshe(accessToken, newSshe);
      dispatch(createSsheSuccess(sshe));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      if (!isTokenCreate) {
        dispatch(fetchAllSshes(accessToken));
      }
      if (setReturnRoute) {
        dispatch(push(`/sshe/sshe/${sshe.SSHE_ID}`));
      }
    } catch (err: any) {
      console.log(err);
      dispatch(createSsheFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updSshe =
  (
    accessToken: String,
    ssheID: number,
    newSshe: Partial<Sshe>,
    setReturnRoute: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateSsheStart());
      const sshe = await updateSshe(accessToken, ssheID, newSshe);
      dispatch(updateSsheSuccess(sshe));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(push(`/sshe/sshe/${sshe.SSHE_ID}`));
      }
    } catch (err: any) {
      dispatch(createSsheFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delSshe =
  (accessToken: String, ssheID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createSsheStart());
      const result = await deleteSshe(accessToken, ssheID);
      dispatch(deleteSsheSuccess(ssheID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
      dispatch(push("/sshe/sshe"));
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(createSsheFailure(error.response.data));
        dispatch(openSnackBar(error.response.data.message, "error"));
      } else {
        dispatch(createSsheFailure(error.toString()));
        dispatch(openSnackBar(Constants.FAILED, "error"));
      }
    }
  };
