import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  VendorSocioEconSpend,
  VendorSocioEconSpendResult,
  getVendorSocioEconSpend,
} from "api/benefits/vendorInsights/vendorSocioEconSpendAPI";
import { AppThunk } from "app/store";

interface VendorSocioEconSpendState {
  vendorSocioEconSpendsById: Record<number, VendorSocioEconSpend>;
  vendorSocioEconSpendList: number[];
  isLoading: boolean;
  error: string | null;
}

const VendorSocioEconSpendInitialState: VendorSocioEconSpendState = {
  vendorSocioEconSpendsById: {},
  vendorSocioEconSpendList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: VendorSocioEconSpendState) {
  state.isLoading = true;
}

function loadingFailed(
  state: VendorSocioEconSpendState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const vendorSocioEconSpends = createSlice({
  name: "vendorSocioEconSpend",
  initialState: VendorSocioEconSpendInitialState,
  reducers: {
    getVendorSocioEconSpendsStart: startLoading,
    getVendorSocioEconSpendsSuccess(
      state,
      { payload }: PayloadAction<VendorSocioEconSpendResult>
    ) {
      const { vendorSocioEconSpend } = payload;
      state.isLoading = false;
      state.error = null;

      vendorSocioEconSpend.forEach((vendorSocioEconSpend) => {
        state.vendorSocioEconSpendsById[vendorSocioEconSpend.ContractID] =
          vendorSocioEconSpend;
      });

      state.vendorSocioEconSpendList = vendorSocioEconSpend.map(
        (vendorSocioEconSpend) => vendorSocioEconSpend.ContractID
      );
    },
    getVendorSocioEconSpendsFailure: loadingFailed,
  },
});

export const {
  getVendorSocioEconSpendsStart,
  getVendorSocioEconSpendsSuccess,
  getVendorSocioEconSpendsFailure,
} = vendorSocioEconSpends.actions;

export default vendorSocioEconSpends.reducer;

export const fetchVendorSocioEconSpends =
  (
    accessToken: String,
    startDate: Date,
    endDate: Date,
    regionID: number,
    projectID: number
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getVendorSocioEconSpendsStart());
      const vendorSocioEconSpends = await getVendorSocioEconSpend(
        accessToken,
        startDate,
        endDate,
        regionID,
        projectID
      );
      dispatch(getVendorSocioEconSpendsSuccess(vendorSocioEconSpends));
    } catch (err: any) {
      dispatch(getVendorSocioEconSpendsFailure(err.toString()));
    }
  };
