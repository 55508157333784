import { Edit } from "@mui/icons-material";
import { ButtonProps } from "@mui/material/Button";
import { IppButton, IppButtonProps } from "components/Buttons/IppButton";
import { useTypedTranslation } from "utils/customHooks";

interface IppEditButtonProps extends IppButtonProps {
  buttonText: string;
  color?: ButtonProps["color"];
  startIcon?: React.ReactNode;
}

export const IppEditButton = ({
  buttonText,
  color = "secondary",
  startIcon = <Edit />,
  ...props
}: IppEditButtonProps) => {
  const t = useTypedTranslation(["strGen"]);
  const T_buttonText = buttonText || t("strGen:buttons.edit");

  return (
    <IppButton color={color} startIcon={startIcon} {...props}>
      {T_buttonText}
    </IppButton>
  );
};
