import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  Employee,
  EmployeesResult,
  getEmployees,
  getEmployeesByCompany,
  getEmployeesForUploadReview,
  createEmployee,
  updateEmployee,
  deleteEmployee,
} from "api/employeeAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface EmployeeState {
  employeesById: Record<number, Employee>;
  employeeList: number[];
  isLoading: boolean;
  error: string | null;
}

const EmployeeInitialState: EmployeeState = {
  employeesById: {},
  employeeList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: EmployeeState) {
  state.isLoading = true;
}

function loadingFailed(state: EmployeeState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const employees = createSlice({
  name: "employees",
  initialState: EmployeeInitialState,
  reducers: {
    getEmployeesStart: startLoading,
    getEmployeesSuccess(state, { payload }: PayloadAction<EmployeesResult>) {
      const { employees } = payload;
      // console.log("PAYLOAD", employees);
      state.isLoading = false;
      state.error = null;

      employees.forEach((employee) => {
        state.employeesById[employee.EmployeeID] = employee;
      });

      state.employeeList = employees.map((employee) => employee.EmployeeID);
    },
    getEmployeesFailure: loadingFailed,
    createEmployeeStart: startLoading,
    createEmployeeSuccess(state, { payload }: PayloadAction<Employee>) {
      const { EmployeeID } = payload;
      state.employeesById[EmployeeID] = payload;
      state.employeeList.push(EmployeeID);

      state.isLoading = false;
      state.error = null;
    },
    updateEmployeeSuccess(state, { payload }: PayloadAction<Employee>) {
      const { EmployeeID } = payload;
      state.employeesById[EmployeeID] = payload;

      state.isLoading = false;
      state.error = null;
    },
    deleteEmployeeSuccess(state, { payload }: PayloadAction<number>) {
      const EmployeeID = payload;
      delete state.employeesById[EmployeeID];
      state.employeeList = state.employeeList.filter(
        (item) => item !== EmployeeID
      );

      state.isLoading = false;
      state.error = null;
    },
    createEmployeeFailure: loadingFailed,
  },
});

export const {
  getEmployeesStart,
  getEmployeesSuccess,
  getEmployeesFailure,
  createEmployeeStart,
  createEmployeeSuccess,
  updateEmployeeSuccess,
  deleteEmployeeSuccess,
  createEmployeeFailure,
} = employees.actions;

export default employees.reducer;

export const fetchEmployees =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getEmployeesStart());
      const employees = await getEmployees(accessToken);
      //console.log("RETURN", employees);
      dispatch(getEmployeesSuccess(employees));
    } catch (err: any) {
      dispatch(getEmployeesFailure(err.toString()));
    }
  };

export const fetchEmployeesByCompany =
  (accessToken: String, companyID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getEmployeesStart());
      const employees = await getEmployeesByCompany(accessToken, companyID);
      //console.log("RETURN", employees);
      dispatch(getEmployeesSuccess(employees));
    } catch (err: any) {
      dispatch(getEmployeesFailure(err.toString()));
    }
  };

export const fetchEmployeesForUploadReview =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getEmployeesStart());
      const payments = await getEmployeesForUploadReview(accessToken);
      //console.log("RETURN", payments);
      dispatch(getEmployeesSuccess(payments));
    } catch (err: any) {
      dispatch(getEmployeesFailure(err.toString()));
    }
  };

export const addEmployee =
  (accessToken: String, newEmployee: Partial<Employee>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createEmployeeStart());
      const employee = await createEmployee(accessToken, newEmployee);
      dispatch(createEmployeeSuccess(employee));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push(`/benefits/employees/${employee.EmployeeID}`));
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(openSnackBar(error.response.data.message, "error"));
        dispatch(createEmployeeFailure(error.response.data.message));
      } else {
        dispatch(createEmployeeFailure(error.toString()));
        dispatch(openSnackBar(Constants.FAILED, "error"));
      }
    }
  };

export const updEmployee =
  (
    accessToken: String,
    employeeID: number,
    newEmployee: Partial<Employee>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createEmployeeStart());
      const employee = await updateEmployee(
        accessToken,
        employeeID,
        newEmployee
      );
      dispatch(updateEmployeeSuccess(employee));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(openSnackBar(error.response.data.message, "error"));
        dispatch(createEmployeeFailure(error.response.data.message));
      } else {
        dispatch(createEmployeeFailure(error.toString()));
        dispatch(openSnackBar(Constants.FAILED, "error"));
      }
    }
  };

export const delEmployee =
  (accessToken: String, employeeID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createEmployeeStart());
      const result = await deleteEmployee(accessToken, employeeID);
      dispatch(deleteEmployeeSuccess(employeeID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
      dispatch(push(`/benefits/employees`));
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(openSnackBar(error.response.data.message, "error"));
        dispatch(createEmployeeFailure(error.response.data.message));
      } else {
        dispatch(createEmployeeFailure(error.toString()));
        dispatch(openSnackBar(Constants.FAILED, "error"));
      }
    }
  };
