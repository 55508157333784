import axiosClient from "../axiosClient";

export interface CommitmentCategory {
  CommitmentCategoryID: number;
  CommitmentCategoryName: string;
}

export interface CommitmentCategoriesResult {
  commitmentCategories: CommitmentCategory[];
}

export async function getCommitmentCategories(
  accessToken: String
): Promise<CommitmentCategoriesResult> {
  const url = `/commitmentCategory`;

  try {
    const { data } = await axiosClient.get<CommitmentCategoriesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createCommitmentCategory(
  accessToken: String,
  newCommInv: Partial<CommitmentCategory>
): Promise<CommitmentCategory> {
  const url = `/commitmentCategory`;

  try {
    const { data } = await axiosClient.post<CommitmentCategory>(
      url,
      newCommInv,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateCommitmentCategory(
  accessToken: String,
  commInvID: number,
  newCommInv: Partial<CommitmentCategory>
): Promise<CommitmentCategory> {
  const url = `/commitmentCategory/` + commInvID;

  try {
    const { data } = await axiosClient.post<CommitmentCategory>(
      url,
      newCommInv,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteCommitmentCategory(
  accessToken: String,
  commInvID: number
): Promise<string> {
  const url = `/commitmentCategory/` + commInvID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
