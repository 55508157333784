import { useCallback } from "react";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TextField } from "@mui/material";

interface FilterProps {
  value: Date | null; // The selected date value for the filter.
  operator: string; // The operator type like 'eq', 'lt', 'gte', etc.
  field: string; // The field in the data to which the filter is applied.
  onChange: (filter: FilterChangeParams) => void; // Function to call when the filter changes.
  operators: Array<{ operator: string; text: string }>; // Array of available operators.
}

interface FilterChangeParams {
  value: Date | null; // The value of the filter.
  operator: string; // The operator type.
  field: string; // The field to which the filter is applied.
}

// Pre-defined list of operators with their display text.
const operators = [
  { operator: "eq", text: "Is equal to" },
  { operator: "lt", text: "Is before" },
  { operator: "lte", text: "Is before or equal to" },
  { operator: "gt", text: "Is after" },
  { operator: "gte", text: "Is after or equal to" },
  { operator: "isnull", text: "Is empty" },
  { operator: "isnotnull", text: "Is not empty" },
];

// GridColumnMenuFilterCell component renders the filter cell with a dropdown and a date picker configured for Month and Year selection.
const GridColumnMenuFilterCell = (props: FilterProps) => {
  // Callback to handle changes in the DatePicker component.
  const handleDateChange = useCallback(
    (newValue: Date | null) => {
      // If operator is "isnull" or "isnotnull", value should be set to null
      const value =
        props.operator === "isnull" || props.operator === "isnotnull"
          ? null
          : newValue;
      props.onChange({
        value: value,
        operator: props.operator,
        field: props.field,
      });
    },
    [props]
  );

  // Callback to handle changes in the operator dropdown.
  const cellOperatorChange = useCallback(
    (event: DropDownListChangeEvent) => {
      const operator = event.target.value.operator;
      // Reset value to null if "isnull" or "isnotnull" is selected
      const value =
        operator === "isnull" || operator === "isnotnull" ? null : props.value;
      props.onChange({
        value: value,
        operator: operator,
        field: props.field,
      });
    },
    [props]
  );

  // Function to render the operator dropdown editor.
  const renderOperatorEditor = () => {
    const value =
      props.operators.find((item) => item.operator === props.operator) || null;

    return (
      <DropDownList
        value={value}
        onChange={cellOperatorChange}
        data={props.operators}
        textField="text"
      />
    );
  };

  // Determines whether to render the DatePicker based on the selected operator.
  const shouldRenderDatePicker =
    props.operator !== "isnull" && props.operator !== "isnotnull";

  return (
    <>
      {renderOperatorEditor()}
      {shouldRenderDatePicker && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            views={["year", "month"]}
            label="Year and Month"
            minDate={new Date("2000-01-01")}
            maxDate={new Date("2030-12-31")}
            value={props.value}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} />}
            inputFormat="MM/yyyy"
            PopperProps={{
              style: { zIndex: 10000 },
            }}
          />
        </LocalizationProvider>
      )}
    </>
  );
};

// MonthYearDateFilter component wraps the GridColumnMenuFilterCell to provide a custom filter UI.
export const MonthYearDateFilter = (props: any) => {
  const { firstFilterProps } = props;

  return (
    <div className="custom-filter-ui">
      <GridColumnMenuFilterCell {...firstFilterProps} operators={operators} />
    </div>
  );
};
