import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ClientCommitmentSourceType,
  ClientCommitmentSourceTypesResult,
  getClientCommitmentSourceTypes,
  createClientCommitmentSourceType,
  updateClientCommitmentSourceType,
  deleteClientCommitmentSourceType,
} from "api/clientCommitmentSourceTypeAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface ClientCommitmentSourceTypeState {
  clientCommitmentSourceTypesById: Record<number, ClientCommitmentSourceType>;
  clientCommitmentSourceTypeList: number[];
  isLoading: boolean;
  error: string | null;
}

const ClientCommitmentSourceTypeInitialState: ClientCommitmentSourceTypeState =
  {
    clientCommitmentSourceTypesById: {},
    clientCommitmentSourceTypeList: [],
    isLoading: false,
    error: null,
  };

function startLoading(state: ClientCommitmentSourceTypeState) {
  state.isLoading = true;
}

function loadingFailed(
  state: ClientCommitmentSourceTypeState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const clientCommitmentSourceTypes = createSlice({
  name: "clientCommitmentSourceTypes",
  initialState: ClientCommitmentSourceTypeInitialState,
  reducers: {
    getClientCommitmentSourceTypesStart: startLoading,
    getClientCommitmentSourceTypesSuccess(
      state,
      { payload }: PayloadAction<ClientCommitmentSourceTypesResult>
    ) {
      const { clientCommitmentSourceTypes } = payload;
      // console.log("PAYLOAD", clientCommitmentSourceTypes);
      state.isLoading = false;
      state.error = null;

      clientCommitmentSourceTypes.forEach((clientCommitmentSourceType) => {
        state.clientCommitmentSourceTypesById[
          clientCommitmentSourceType.ClientCommitmentSourceTypeID
        ] = clientCommitmentSourceType;
      });

      state.clientCommitmentSourceTypeList = clientCommitmentSourceTypes.map(
        (clientCommitmentSourceType) =>
          clientCommitmentSourceType.ClientCommitmentSourceTypeID
      );
    },
    getClientCommitmentSourceTypesFailure: loadingFailed,
    createClientCommitmentSourceTypeStart: startLoading,
    createClientCommitmentSourceTypeSuccess(
      state,
      { payload }: PayloadAction<ClientCommitmentSourceType>
    ) {
      const { ClientCommitmentSourceTypeID } = payload;
      state.clientCommitmentSourceTypesById[ClientCommitmentSourceTypeID] =
        payload;
      state.clientCommitmentSourceTypeList.push(ClientCommitmentSourceTypeID);

      state.isLoading = false;
      state.error = null;
    },
    updateClientCommitmentSourceTypeSuccess(
      state,
      { payload }: PayloadAction<ClientCommitmentSourceType>
    ) {
      const { ClientCommitmentSourceTypeID } = payload;
      state.clientCommitmentSourceTypesById[ClientCommitmentSourceTypeID] =
        payload;

      state.isLoading = false;
      state.error = null;
    },
    deleteClientCommitmentSourceTypeSuccess(
      state,
      { payload }: PayloadAction<number>
    ) {
      const ClientCommitmentSourceTypeID = payload;
      delete state.clientCommitmentSourceTypesById[
        ClientCommitmentSourceTypeID
      ];
      state.clientCommitmentSourceTypeList =
        state.clientCommitmentSourceTypeList.filter(
          (item) => item !== ClientCommitmentSourceTypeID
        );

      state.isLoading = false;
      state.error = null;
    },
    createClientCommitmentSourceTypeFailure: loadingFailed,
  },
});

export const {
  getClientCommitmentSourceTypesStart,
  getClientCommitmentSourceTypesSuccess,
  getClientCommitmentSourceTypesFailure,
  createClientCommitmentSourceTypeStart,
  createClientCommitmentSourceTypeSuccess,
  updateClientCommitmentSourceTypeSuccess,
  deleteClientCommitmentSourceTypeSuccess,
  createClientCommitmentSourceTypeFailure,
} = clientCommitmentSourceTypes.actions;

export default clientCommitmentSourceTypes.reducer;

export const fetchClientCommitmentSourceTypes =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getClientCommitmentSourceTypesStart());
      const clientCommitmentSourceTypes = await getClientCommitmentSourceTypes(
        accessToken
      );
      //console.log("RETURN", clientCommitmentSourceTypes);
      dispatch(
        getClientCommitmentSourceTypesSuccess(clientCommitmentSourceTypes)
      );
    } catch (err: any) {
      dispatch(getClientCommitmentSourceTypesFailure(err.toString()));
    }
  };

export const addClientCommitmentSourceType =
  (
    accessToken: String,
    newClientCommitmentSourceType: Partial<ClientCommitmentSourceType>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientCommitmentSourceTypeStart());
      const clientCommitmentSourceType = await createClientCommitmentSourceType(
        accessToken,
        newClientCommitmentSourceType
      );
      dispatch(
        createClientCommitmentSourceTypeSuccess(clientCommitmentSourceType)
      );
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push("/admin/clientCommitmentSourceTypes"));
    } catch (err: any) {
      dispatch(createClientCommitmentSourceTypeFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updClientCommitmentSourceType =
  (
    accessToken: String,
    clientCommitmentSourceTypeID: number,
    newClientCommitmentSourceType: Partial<ClientCommitmentSourceType>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientCommitmentSourceTypeStart());
      const clientCommitmentSourceType = await updateClientCommitmentSourceType(
        accessToken,
        clientCommitmentSourceTypeID,
        newClientCommitmentSourceType
      );
      dispatch(
        updateClientCommitmentSourceTypeSuccess(clientCommitmentSourceType)
      );
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push("/admin/clientCommitmentSourceTypes"));
    } catch (err: any) {
      dispatch(createClientCommitmentSourceTypeFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delClientCommitmentSourceType =
  (accessToken: String, clientCommitmentSourceTypeID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientCommitmentSourceTypeStart());
      const result = await deleteClientCommitmentSourceType(
        accessToken,
        clientCommitmentSourceTypeID
      );
      dispatch(
        deleteClientCommitmentSourceTypeSuccess(clientCommitmentSourceTypeID)
      );
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push("/admin/clientCommitmentSourceTypes"));
    } catch (err: any) {
      dispatch(createClientCommitmentSourceTypeFailure(err.toString()));
    }
  };
