import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useTypedTranslation } from "utils/customHooks";
import { IsExtraLargeScreen, IsMedSmall } from "utils/mediaQueries";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Add,
  AssignmentReturned,
  CloudUpload,
  Edit,
} from "@mui/icons-material";
import { ChangeEvent, useEffect, useState } from "react";
import {
  ConvertDateOffset,
  FirstDayInPreviousMonth,
  GetJSDate,
  LastDayInPreviousMonth,
  getFirstAndLastDateOfMonth,
} from "../../../utils/DateFunctions";
import { PaymentPeriodWorkflowBanner } from "./PaymentPeriodWorkflowBanner";
import { Link, Prompt } from "react-router-dom";
import { Contract } from "api/contractAPI";
import { fetchContracts } from "features/contract/ContractSlice";
import { fetchProjectComponentsByProject } from "features/project/ProjectComponentSlice";
import { fetchProjects } from "features/project/ProjectSlice";
import { fetchCompanies } from "../companies/CompaniesSlice";
import {
  addPaymentPeriod,
  delPaymentPeriod,
  fetchPaymentPeriods,
  updPaymentPeriod,
} from "./PaymentPeriodSlice";
import { PaymentPeriodValidation } from "./PaymentPeriodValidation";
import { IppDisplayField } from "components/IppDisplayField";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppFormHeader } from "components/IppFormHeader";
import { UserWriteAccess } from "utils/userAccess";
import LoadingIndicator from "components/LoadingIndicator";
import { IppAutocomplete } from "components/IppAutocomplete";
import { IppStaticTextfield } from "components/IppStaticTextfield";
import SmallLoadingIndicator from "components/SmallLoadingIndicator";
import { IppDatePicker } from "components/IppDatePicker";
import { IppChildInventory } from "components/Inventory/IppChildInventory";
import { IppTabPanel } from "components/IppTabPanel";
import { IppCheckbox } from "components/IppCheckbox";
import { fetchPaymentsByPeriod } from "../payments/PaymentSlice";
import { ButtonProps } from "components/IppPopoverMenu";
import { IppChildAdd } from "components/IppChildAdd";
import { PaymentUploadChildPage } from "../payments/PaymentChildUpload/PaymentUploadChildPage";
import { Payment } from "api/paymentAPI";
import { IppTextField } from "components/IppTextField";
import { generateTemplate } from "utils/uploadUtils";
import { fetchIndigenousGroups } from "features/datalists/IndigenousGroupSlice";
import { IppRichTextEditor } from "components/RichTextEditor/IppRichTextEditor";
import { IppDisplayRichText } from "components/IppDisplayRichText";
import { findDuplicateObject } from "utils/functions";
import { IppCancelButton } from "components/Buttons/IppCancelButton";
import { fetchAllTiers } from "../tier/TierSlice";
import { fetchStandardStatements } from "features/platform/admin/standardStatement/StandardStatementSlice";
import { Company } from "api/companyAPI";
import { CompanyOptionsRender } from "utils/renderFunctions";

const PREFIX = "PaymentPeriodForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    // minWidth: 650,
    maxWidth: 1150,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const PaymentPeriodForm = (props: any) => {
  let itemData = props.paymentPeriod || {};

  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen", "objSlr", "objPlt", "strGen"]);
  const customBP = IsMedSmall();
  const [activeTab, setActiveTab] = useState(0);
  const [filteredContracts, setFilteredContracts] = useState([] as Contract[]);
  const [projectID, setProjectID] = useState(itemData.ProjectID || 0);
  const [companyID, setCompanyID] = useState(itemData.CompanyID || 0);
  const showEdit = UserWriteAccess("Benefits", itemData.ProjectID);
  const [didSaveInventory, setDidSaveInventory] = useState(false);
  const [isAddingNewComp, setIsAddingNewComp] = useState(false);
  const [isUploadingPmts, setIsUploadingPmts] = useState(false);

  const [isEditing, _setIsEditing] = useState(
    itemData.PaymentPeriodID ? false : true
  );

  const [isAdding, setIsAdding] = useState(
    itemData.PaymentPeriodID ? false : true
  );

  // ---------------------- Redux imports ------------------------------

  const {
    clientId,
    isLoading: clientIsLoading,
    ft_ben_Contract,
    ft_ben_SpendReportDate,
    dm_Disability,
    dm_LGBTQ,
    dm_Local,
    dm_VisibleMinority,
    dm_WomanOwned,
    dm_VeteranOwned,
    dm_NorthernResident,
    ft_ben_Tier,
  } = useSelector((state: RootState) => state.client);

  const DMToggle =
    dm_Disability ||
    dm_LGBTQ ||
    dm_Local ||
    dm_VisibleMinority ||
    dm_WomanOwned ||
    dm_VeteranOwned ||
    dm_NorthernResident;

  const {
    clientModuleList,
    clientModulesById,
    isLoading: clientModuleIsLoading,
  } = useSelector((state: RootState) => state.clientModules);

  const clientModules = clientModuleList.map((id) => clientModulesById[id]);
  const clientHasStk = clientModules
    .map((module) => module.ModuleID)
    .includes(3);

  const { currentProfile, currentUserRoleList, currentUserRolesById } =
    useSelector((state: RootState) => state.profile);

  const userRoles = currentUserRoleList.map((id) => currentUserRolesById[id]);

  const {
    paymentPeriodsById,
    paymentPeriodList,
    isLoading: paymentPeriodIsLoading,
    error: payPeriodError,
  } = useSelector((state: RootState) => state.paymentPeriods);
  const paymentPeriods = paymentPeriodList.map((id) => paymentPeriodsById[id]);
  const {
    companiesById,
    companyList,
    isLoading: companyIsLoading,
  } = useSelector((state: RootState) => state.companies);

  const companies = companyList.map((CompanyID) => companiesById[CompanyID]);

  const {
    projectList,
    projectsById,
    isLoading: projectIsLoading,
  } = useSelector((state: RootState) => state.projects);

  const projects = projectList
    .map((id) => projectsById[id])
    .filter((proj) => {
      if (currentProfile.IsClientAdmin) {
        return true;
      } else {
        return userRoles.some((role) => {
          return role.ProjectID === proj.ProjectID && role.ModuleID === 1;
        });
      }
    });

  const {
    projectComponentsById,
    projectComponentList,
    isLoading: projectComponentIsLoading,
  } = useSelector((state: RootState) => state.projectComponents);

  const projectcomponents = projectComponentList
    .map((ProjectComponentID) => projectComponentsById[ProjectComponentID])
    .filter((pc) => pc.ProjectID === projectID);

  const {
    clientCurrencyList,
    clientCurrenciesById,
    isLoading: clientCurrencyIsLoading,
  } = useSelector((state: RootState) => state.clientCurrencies);

  const clientCurrency = clientCurrencyList.map(
    (id) => clientCurrenciesById[id]
  );

  const {
    contractList,
    contractsById,
    isLoading: contractIsLoading,
  } = useSelector((state: RootState) => state.contracts);

  //Full list of Contracts User can access on API, not filtered
  const contractOptions = contractList.map((id) => contractsById[id]);

  const { indigenousGroupsById } = useSelector(
    (state: RootState) => state.indigenousGroups
  );

  const { tiersById } = useSelector((state: RootState) => state.tier);

  const {
    paymentList,
    paymentsById,
    isLoading: paymentsIsLoading,
  } = useSelector((state: RootState) => state.payments);

  const payments = paymentList.map((id) => paymentsById[id]);
  const periodPaymentsList = payments.filter(
    (payment) => payment.PaymentPeriodID === itemData.PaymentPeriodID
  );

  const periodPayments: Payment[] = periodPaymentsList.map((p) => {
    let newPmts = { ...p };
    let pmtDate = ConvertDateOffset(new Date(p.PaymentDate));
    newPmts.PaymentDate = new Date(pmtDate.setHours(0, 0, 0, 0));

    const company = companiesById[newPmts.CompanyID];

    let diversityMetrics = "";
    let indigenousAffiliation = "";

    // Show Diversity metrics under Diversity column
    if (company) {
      diversityMetrics = Object.entries({
        Local: dm_Local && company.Local,
        "Woman-Owned": dm_WomanOwned && company.WomanOwned,
        "Veteran-Owned": dm_VeteranOwned && company.VeteranOwned,
        "Owned by Northern Resident":
          dm_NorthernResident && company.NorthernResident,
        "LGBTQ+": dm_LGBTQ && company.LGBTQ,
        "Visible Minority": dm_VisibleMinority && company.VisibleMinority,
        "Person(s) with Disability": dm_Disability && company.Disability,
      })
        .map(([key, value]) => (value ? key : ""))
        .filter((metric) => metric !== "")
        .join(", ");

      // Show Indigenous Group name under Indigenous Group column
      indigenousAffiliation = company.IndigenousGroupID
        ? indigenousGroupsById[company.IndigenousGroupID]
            ?.IndigenousGroupName || ""
        : "";
    }

    const newPayments = {
      ...newPmts,
      Diversity: diversityMetrics,
      IndigenousAffiliation: indigenousAffiliation,
    };

    return newPayments;
  });

  const { isLoading: standardStatementIsLoading } = useSelector(
    (state: RootState) => state.standardStatement
  );

  // ----------------------- Setting variables ---------------------------------

  // used when view only user access is added
  // const showEdit = UserWriteAccess("Benefits", itemData.ProjectID);

  let allowCompanyUserEdit = false;
  // determines editing for company user
  if (currentProfile.IsClientAdmin) {
    allowCompanyUserEdit = true;
  } else if (itemData.WorkflowStageID === 1) {
    allowCompanyUserEdit = true;
  } else if (itemData.WorkflowStageID === 2 && currentProfile.IsClient) {
    allowCompanyUserEdit = true;
  } else allowCompanyUserEdit = false;
  const setIsEditing = (edits: Boolean) => {
    _setIsEditing(edits && allowCompanyUserEdit); // prevents non client users from doing any edits on this page
  };

  // when editing, get project id
  if (!isAdding && projectID === 0) {
    if (!projectComponentIsLoading) {
      let pc = projectComponentsById[itemData.ProjectComponentID];

      if (pc) {
        setProjectID(pc.ProjectID);
      }
    }
  }

  // ------------------------- Use Effects ----------------------------------

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchCompanies(accessToken, 1));
        dispatch(fetchProjects(accessToken));
        dispatch(fetchIndigenousGroups(accessToken));
        dispatch(fetchStandardStatements(accessToken));
        if (ft_ben_Tier) {
          dispatch(fetchAllTiers(accessToken));
        }
        if (itemData.PaymentPeriodID) {
          dispatch(
            fetchPaymentsByPeriod(accessToken, itemData.PaymentPeriodID)
          );
        }
        if (ft_ben_Contract) {
          dispatch(fetchContracts(accessToken));
        }
        // Fetch paymentPeriods when adding new paymentPeriod
        if (isAdding && !paymentPeriods?.length) {
          dispatch(fetchPaymentPeriods(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently, ft_ben_Contract]);

  // useEffect for when project changes
  //only handles ProjectComponents
  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        if (projectID > 0) {
          dispatch(fetchProjectComponentsByProject(accessToken, projectID));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [projectID, dispatch, getAccessTokenSilently]);

  // useEffect for when project or company changes
  useEffect(() => {
    (async () => {
      try {
        //Filter full list of Contracts to generate options list after Proj and Comp set
        if (ft_ben_Contract && projectID > 0 && formik.values.CompanyID > 0) {
          const contractsFiltered = contractOptions.filter(
            (contractItem: Contract) =>
              contractItem.ProjectID === projectID &&
              contractItem.CompanyID === formik.values.CompanyID
          );
          setFilteredContracts(contractsFiltered);
        } else {
          setFilteredContracts([]);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [
    projectID,
    companyID,
    contractIsLoading, //This is used to check that contractOptions has changed
    ft_ben_Contract,
    dispatch,
    getAccessTokenSilently,
  ]);

  // save when changes made to child records
  useEffect(() => {
    if (didSaveInventory) {
      submitFunc(itemData);
      setDidSaveInventory(false);
    }
    return () => {
      // cleanup
    };
  }, [didSaveInventory]);

  // ---------------------- Helper Functions ------------------------
  const handleDelete = () => {
    // function to delete current Payment entry
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(delPaymentPeriod(accessToken, itemData.PaymentPeriodID));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  // Validate if new paymentPeriod is existed in paymentPeriods list
  const [duplicateDialog, setDuplicateDialog] = useState({
    open: false,
    SequenceID: "",
    path: "",
  });
  const duplicateContent = t("strGen:prompts.duplicate", {
    fieldname: t("objBen:objects.spendreporting.name"),
  });
  const isDuplicate = (values: any) => {
    const keysToCompare: string[] = [
      "CompanyID",
      "ProjectComponentID",
      "StartDate",
      "EndDate",
    ];
    if (ft_ben_Contract) {
      keysToCompare.push("ContractID");
    }
    const updatedValues = {
      ...values,
      StartDate: values.StartDate + "T00:00:00.000Z",
      EndDate: values.EndDate + "T00:00:00.000Z",
    };
    const duplicateObj = findDuplicateObject(
      paymentPeriods,
      updatedValues,
      keysToCompare
    );
    if (duplicateObj) {
      setDuplicateDialog({
        open: true,
        SequenceID: duplicateObj?.SequenceID?.toString(),
        path: `${duplicateObj?.PaymentPeriodID}`,
      });
      return true;
    } else {
      return false;
    }
  };

  // ----------------------- Formik Set-up ----------------------------
  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        // add project id to object, for back-end validation
        values.ProjectID = projectID;
        delete values.ZeroSpend;
        // validate new paymentPeriod
        const isUnique = !isDuplicate(values);
        isUnique && dispatch(addPaymentPeriod(accessToken, values, true));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  if (!isAdding) {
    // update case
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          delete values.ZeroSpend;
          // prevents non client users from updating
          allowCompanyUserEdit &&
            dispatch(
              updPaymentPeriod(
                accessToken,
                values.PaymentPeriodID,
                values,
                true
              )
            );
        } catch (e) {
          console.error(e);
        }
      })();
    };
  } else {
    //set defaults for create case
    itemData.CompanyID = -1;
    itemData.ProjectID = -1;
    itemData.CompanyName = "";
    itemData.StartDate = FirstDayInPreviousMonth();
    itemData.EndDate = LastDayInPreviousMonth();
    itemData.TotalPayment = 0;
    itemData.ProjectComponentID = null;
    itemData.WorkflowStageID = currentProfile.IsClientAdmin ? 3 : 1;
    itemData.PaymentPeriodStatusID = currentProfile.IsClientAdmin ? 3 : 1;
    itemData.Currency = clientCurrency[0]
      ? clientCurrency[0].CurrencyName
      : "CAD";
    if (ft_ben_Contract) {
      itemData.ContractID = null;
    }
    itemData.PaymentNotes = "";
  }

  const handleTabSelect = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };

  const handleAddNewCompOpen = () => {
    setIsAddingNewComp(true);
  };

  const handleAddNewCompClose = () => {
    setIsAddingNewComp(false);
  };

  const handlePmtUploadOpen = () => {
    setIsUploadingPmts(true);
  };

  const handlePmtUploadClose = () => {
    setDialogKey((prevKey: number) => prevKey + 1);
    setIsUploadingPmts(false);
  };

  const formik = useFormik({
    initialValues: itemData,
    validationSchema: PaymentPeriodValidation(
      ft_ben_Contract || false,
      ft_ben_SpendReportDate || false,
      itemData.PaymentPeriodStatusID
    ),
    onSubmit: submitFunc,
  });

  // --------------------- View Form -------------------------
  let viewForm = (
    <Root>
      <Box display="flex" justifyContent="center">
        <Paper className={classes.boxSpace}>
          <Grid container className={classes.editForm} spacing={1}>
            <IppFormHeader
              title={t("objBen:objects.spendreporting.name")}
              isEditing={isEditing}
              isAdding={isAdding}
              returnPath="/benefits/paymentperiods"
            />
            {!isAdding && (
              <PaymentPeriodWorkflowBanner
                isEditing={isEditing}
                period={itemData}
                hideActions={
                  !currentProfile.IsClientAdmin &&
                  itemData.WorkflowStageID === 3
                }
              />
            )}
            {itemData.ReviewReason && (
              <Grid item xs={12}>
                <IppDisplayField
                  value={itemData.ReviewReason}
                  showEdit={currentProfile.IsClient && showEdit ? true : false}
                  label="Reason for Review"
                  showWarning={true}
                  warningMessage="It appears this Payment Period requires Review. Please update the record and Submit For Review"
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                />
              </Grid>
            )}
            <Grid item xs={12} alignItems={"center"} justifyContent={"center"}>
              <Tooltip title={"This is the Payment period identifier"}>
                <Typography>{`SR - ${itemData.SequenceID}`}</Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Typography
                    variant="caption"
                    sx={{ color: "text.secondary" }}
                  >
                    {t("objBen:objects.company.name")}{" "}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Tooltip
                    title={t("strGen:buttons.tooltip.clicktoview", {
                      objectname: t("objBen:objects.company.name"),
                    })}
                  >
                    <Link to={`/benefits/companies/${companyID}`}>
                      <Typography variant="body2">
                        {formik.values.CompanyName}
                      </Typography>
                    </Link>
                  </Tooltip>
                </Grid>
                <Grid item width={30}>
                  {allowCompanyUserEdit && showEdit && (
                    <IconButton onClick={() => setIsEditing(true)} size="small">
                      <Edit fontSize="inherit" />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <IppDisplayField
                label={t("objPlt:objects.project.name")}
                isEditing={isEditing}
                showEdit={allowCompanyUserEdit && showEdit}
                setIsEditing={setIsEditing}
                value={
                  !projectIsLoading && projectID > 0
                    ? projectsById[projectID].ProjectName
                    : ""
                }
              />
            </Grid>
            <Grid item xs={6}>
              <IppDisplayField
                label={t("objPlt:objects.projectcomponent.fullname")}
                isEditing={isEditing}
                showEdit={allowCompanyUserEdit && showEdit}
                setIsEditing={setIsEditing}
                value={projectID > 0 ? itemData.ProjectComponentName : ""}
              />
            </Grid>
            {ft_ben_Contract && (
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objBen:objects.contract.name")}
                  value={
                    !contractIsLoading &&
                    itemData.ContractID > 0 &&
                    contractsById[itemData.ContractID]
                      ? contractsById[itemData.ContractID].ContractTitle
                      : ""
                  }
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={allowCompanyUserEdit && showEdit}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <IppDisplayField
                label={t("objBen:objects.spendreporting.fields.spenddate")}
                value={new Date(formik.values.EndDate)?.toLocaleString(
                  "default",
                  {
                    month: "short",
                    year: "numeric",
                  }
                )}
                isEditing={isEditing}
                showEdit={allowCompanyUserEdit && showEdit}
                setIsEditing={setIsEditing}
              />
            </Grid>
            <Grid item xs={6}>
              <IppDisplayField
                value={formik.values.Currency}
                label={t("objBen:objects.payment.fields.currency")}
                isEditing={isEditing}
                showEdit={false}
                setIsEditing={setIsEditing}
              />
            </Grid>
            {!isAdding && (
              <Grid item xs={6}>
                <IppDisplayField
                  value={
                    "$" +
                    formik.values.TotalPayment.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  }
                  label={t("objBen:objects.spendreporting.fields.totalspend")}
                  isEditing={isEditing}
                  showEdit={false}
                  setIsEditing={setIsEditing}
                />
              </Grid>
            )}
            {/* comment area */}
            <Grid item xs={12}>
              <IppDisplayRichText
                label={t("objBen:objects.spendreporting.fields.comments")}
                value={formik.values.PaymentNotes}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                showEdit={allowCompanyUserEdit && showEdit}
              />
            </Grid>
            <Grid item xs={12}>
              <IppFormButtons
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                isAdding={isAdding}
                resetFunction={() => formik.resetForm()}
                showDelete={allowCompanyUserEdit && showEdit}
                deleteFunction={handleDelete}
                deleteText={t(
                  "strGen:components.ippconfirmdialog.confirmspenddelete"
                )}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Root>
  );

  // ----------------------- Edit Form ---------------------------

  let editForm = clientIsLoading ? (
    <LoadingIndicator />
  ) : (
    <form noValidate onSubmit={formik.handleSubmit}>
      {!formik.isSubmitting && (
        <Prompt
          when={formik.dirty}
          message={t("strGen:prompts.unsavedchanges")}
        />
      )}

      <Grid container className={classes.editForm} spacing={1}>
        <IppFormHeader
          title={t("objBen:objects.spendreporting.name")}
          isEditing={isEditing}
          isAdding={isAdding}
          returnPath="/benefits/paymentPeriods"
        />
        {!isAdding && (
          <PaymentPeriodWorkflowBanner
            isEditing={isEditing}
            period={itemData}
          />
        )}
        {(itemData.ReviewReason || itemData.PaymentPeriodStatusID === 4) &&
          currentProfile.IsClient && (
            <Grid item xs={12}>
              <IppTextField
                id="ReviewReason"
                label={t("objBen:objects.spendreporting.fields.reviewreason")}
                required={itemData.PaymentPeriodStatusID === 4}
                value={formik.values.ReviewReason}
                onChangeFunction={formik.handleChange}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                touchedExpression={formik.touched.ReviewReason}
                errorsExpression={formik.errors.ReviewReason}
              />
            </Grid>
          )}
        <Grid item xs={6}>
          <IppAutocomplete
            id="CompanyID"
            options={companies.sort((a, b) =>
              a.ShowAsActive.localeCompare(b.ShowAsActive)
            )}
            groupBy={(option: any) => option.ShowAsActive}
            value={companies.find((obj) => {
              return obj.CompanyID === formik.values.CompanyID;
            })}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                formik.setFieldValue("CompanyID", newValue.CompanyID);
                setCompanyID(newValue.CompanyID);
                formik.setFieldValue("ContractID", null);
              } else {
                formik.setFieldValue("CompanyID", -1);
                formik.setFieldValue("ContractID", null);
              }
            }}
            label={t("objBen:objects.company.name")}
            required={true}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            optionLabelFunction={(option: any) => option.CompanyName}
            renderOption={(props: any, option: Company) => {
              return CompanyOptionsRender(props, option);
            }}
            textValueFunction={formik.values.CompanyName}
            errorFunction={formik.errors.CompanyID}
            touchedFunction={formik.touched.CompanyID}
          />
        </Grid>

        <Grid item xs={6}>
          <IppStaticTextfield
            id="Currency"
            label={t("objBen:objects.payment.fields.currency")}
            value={formik.values.Currency}
          />
        </Grid>

        <Grid item xs={6}>
          <IppAutocomplete
            id="ProjectID"
            required={true}
            options={projects}
            value={projects.find((obj) => {
              return obj.ProjectID === projectID;
            })}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                setProjectID(newValue.ProjectID);
                formik.setFieldValue("ProjectID", newValue.ProjectID);
                formik.setFieldValue("ProjectComponentID", -1);
                formik.setFieldValue("ContractID", null);
              } else {
                formik.setFieldValue("ProjectID", -1);
                formik.setFieldValue("ContractID", null);
              }
            }}
            label={t("objPlt:objects.project.name")}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            errorFunction={formik.errors.ProjectID}
            touchedFunction={formik.touched.ProjectID}
            optionLabelFunction={(option: any) => option.ProjectName}
            textValueFunction={
              !projectIsLoading && projectID > 0
                ? projectsById[projectID].ProjectName
                : ""
            }
          />
        </Grid>
        <Grid item xs={6}>
          {projectComponentIsLoading ? (
            <SmallLoadingIndicator />
          ) : (
            <IppAutocomplete
              id="ProjectComponentID"
              options={projectID > 0 ? projectcomponents : []}
              value={projectcomponents.find((obj) => {
                return (
                  obj.ProjectComponentID === formik.values.ProjectComponentID
                );
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue(
                    "ProjectComponentID",
                    newValue.ProjectComponentID
                  );
                } else {
                  formik.setFieldValue("ProjectComponentID", -1);
                }
              }}
              label={t("objPlt:objects.projectcomponent.fullname")}
              required={true}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.ProjectComponentName}
              textValueFunction={
                projectID > 0 ? formik.values.ProjectComponentName : ""
              }
              errorFunction={formik.errors.ProjectComponentID}
              touchedFunction={formik.touched.ProjectComponentID}
              noOptionsText={t("strGen:components.nooptionsforobj", {
                objectname: t("objPlt:objects.project.name"),
              })}
            />
          )}
        </Grid>

        {ft_ben_SpendReportDate ? (
          <>
            <Grid item xs={6}>
              <IppDatePicker
                id="EndDate"
                label={t("objPlt:platformwide.fields.month")}
                required={true}
                value={ConvertDateOffset(formik.values.EndDate)}
                disableFuture={false}
                onChangeFunction={(newValue: any) => {
                  const { firstDay, lastDay } =
                    getFirstAndLastDateOfMonth(newValue);
                  formik.setFieldValue("StartDate", GetJSDate(firstDay), true);
                  formik.setFieldTouched("StartDate", true, false);
                  formik.setFieldValue("EndDate", GetJSDate(lastDay), true);
                  formik.setFieldTouched("EndDate", true, false);
                }}
                errorsExpression={
                  formik.errors.EndDate || formik.errors.StartDate
                }
                touchedExpression={
                  formik.touched.EndDate || formik.touched.StartDate
                }
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                monthOnly={true}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={6}>
              <IppDatePicker
                id="StartDate"
                label={t("objBen:objects.spendreporting.fields.startdate")}
                required={true}
                value={ConvertDateOffset(formik.values.StartDate)}
                disableFuture={false}
                onChangeFunction={(newValue: any) => {
                  formik.setFieldValue("StartDate", GetJSDate(newValue), true);
                  formik.setFieldTouched("StartDate", true, false);
                }}
                errorsExpression={formik.errors.StartDate}
                touchedExpression={formik.touched.StartDate}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                monthOnly={false}
              />
            </Grid>
            <Grid item xs={6}>
              <IppDatePicker
                id="EndDate"
                label={t("objBen:objects.spendreporting.fields.enddate")}
                required={true}
                value={ConvertDateOffset(formik.values.EndDate)}
                disableFuture={false}
                onChangeFunction={(newValue: any) => {
                  formik.setFieldValue("EndDate", GetJSDate(newValue), true);
                  formik.setFieldTouched("EndDate", true, false);
                }}
                errorsExpression={formik.errors.EndDate}
                touchedExpression={formik.touched.EndDate}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                monthOnly={false}
              />
            </Grid>
          </>
        )}

        {ft_ben_Contract && (
          <Grid item xs={6}>
            <IppAutocomplete
              id="ContractID"
              options={projectID > 0 ? filteredContracts : []}
              value={
                contractOptions.find((obj) => {
                  return obj.ContractID === formik.values.ContractID;
                }) || ""
              }
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("ContractID", newValue.ContractID);
                } else {
                  formik.setFieldValue("ContractID", null);
                }
              }}
              required
              label={t("objBen:objects.contract.name")}
              noOptionsText={t("strGen:components.nooptionsforobj", {
                objectname: `${t("objBen:objects.company.name")} ${t(
                  "objPlt:objects.project.name"
                )}`,
              })}
              disabled={
                !(formik.values.CompanyID > 0 && formik.values.ProjectID > 0)
              }
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.ContractTitle || ""}
              errorFunction={formik.errors.ContractID}
              touchedFunction={formik.touched.ContractID}
              textValueFunction={
                formik.values.ContractID > 0 ? formik.values.ContractTitle : ""
              }
              autoPopulate={false}
            />
          </Grid>
        )}

        {!isAdding && (
          <Grid item xs={6}>
            <IppStaticTextfield
              id="TotalPayment"
              value={formik.values.TotalPayment.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              label={t("objBen:objects.spendreporting.fields.totalspend")}
              isCurrency={true}
            />
          </Grid>
        )}
        {isAdding && (
          <Grid item xs={6}>
            <IppCheckbox
              id={"ZeroSpend"}
              value={formik.values.ZeroPeriod}
              label={"Mark as zero dollar spend"}
              onChangeFunction={(event: any, newValue: any) => {
                if (newValue === true) {
                  formik.setFieldValue(
                    "WorkflowStageID",
                    currentProfile.IsClient ? 3 : 2
                  );
                  formik.setFieldValue(
                    "PaymentPeriodStatusID",
                    currentProfile.IsClient ? 3 : 2
                  );
                } else {
                  formik.setFieldValue("PaymentPeriodStatusID", 1);
                  formik.setFieldValue("WorkflowStageID", 1);
                }
              }}
              isEditing={isEditing}
            />
          </Grid>
        )}

        {/* comment area */}
        <Grid item xs={12}>
          <IppRichTextEditor
            id="PaymentNotes"
            label={t("objBen:objects.spendreporting.fields.comments")}
            value={formik.values.PaymentNotes}
            isEditing={isEditing}
            touchedExpression={formik.touched.PaymentNotes}
            errorsExpression={formik.errors.PaymentNotes}
            setFieldValue={formik.setFieldValue}
            height={100}
            standardStatementsRecordType="PaymentPeriodNotes"
          />
        </Grid>

        <Grid item xs={12}>
          <IppFormButtons
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            isAdding={isAdding}
            resetFunction={() => formik.resetForm()}
            showDelete={true}
            deleteFunction={handleDelete}
          />
        </Grid>

        {/* duplicate warning */}
        <Dialog
          open={duplicateDialog.open}
          onClose={() =>
            setDuplicateDialog({ ...duplicateDialog, open: false })
          }
          fullWidth
          maxWidth="sm"
        >
          <DialogContent>
            {duplicateContent}
            <span
              style={{
                cursor: "pointer",
                color: "blue",
              }}
            >
              <Link to={duplicateDialog?.path} replace={true}>
                SR-{duplicateDialog?.SequenceID}
              </Link>
            </span>
          </DialogContent>
          <DialogActions>
            <IppCancelButton
              buttonText={t("strGen:buttons.close")}
              onClick={() =>
                setDuplicateDialog({ ...duplicateDialog, open: false })
              }
            />
          </DialogActions>
        </Dialog>
      </Grid>
    </form>
  );

  const tierColumn = ft_ben_Tier
    ? [
        {
          field: "TierName",
          title: t("objBen:objects.tiers.name"),
          columnWidth: 100,
        },
      ]
    : [];

  const indigenousColumn = currentProfile.IsClient
    ? [
        {
          field: "IndigenousAffiliation",
          title: t("objPlt:platformwide.indigenous.indigenousgroup"),
          columnWidth: IsExtraLargeScreen() ? 160 : 100,
        },
      ]
    : [];

  const attachmentColumn = [
    {
      field: "Attachments",
      title: "",
      columnWidth: 60,
      format: "S-{0:0}",
    },
  ];

  const diversityColumn =
    currentProfile.IsClient && DMToggle
      ? [
          {
            field: "Diversity",
            title: "Diversity",
            commaDelimited: true,
          },
        ]
      : [];

  const paymentsColumns = [
    {
      field: "SequenceID",
      title: " ",
      format: "S-{0:0}",
      columnWidth: 80,
    },
    {
      field: "CompanyName",
      title: t("objBen:objects.company.name"),
    },
    ...tierColumn,
    {
      field: "PaymentAmount",
      title: t("objBen:objects.payment.fields.amount"),
      format: "{0:c}",
      columnWidth: currentProfile.IsClient ? 130 : "",
    },
    ...indigenousColumn,
    ...diversityColumn,
    ...attachmentColumn,
  ];

  const [dialogKey, setDialogKey] = useState(0);
  const templatePath = `${process.env.REACT_APP_FILE_STORAGE}/templates/PeriodPaymentsUpload.xlsx`;

  // const templateBtn: LinkButtonProps = {
  //   startIcon: <AssignmentReturned />,
  //   disabled: !allowCompanyUserEdit,
  //   text: "Template",
  //   linkPath: templatePath,
  //   gridSize: 6,
  // };

  const templateBtn: ButtonProps = {
    onClick: () =>
      generateTemplate(
        getAccessTokenSilently,
        "periodpayment",
        itemData.PaymentPeriodID
      ),
    startIcon: <AssignmentReturned />,
    disabled: !allowCompanyUserEdit,
    text: "Template",
    gridSize: 6,
  };

  const uploadBtn: ButtonProps = {
    onClick: () => handlePmtUploadOpen(),
    startIcon: <CloudUpload />,
    disabled: !allowCompanyUserEdit,
    text: "Upload",
    gridSize: 6,
  };

  const addNewCompReqBtn: ButtonProps = {
    onClick: () => handleAddNewCompOpen(),
    startIcon: <Add />,
    disabled: !allowCompanyUserEdit,
    text: t("objBen:objects.company.newcompanyrequest"),
  };

  const popoverBtns = [templateBtn, uploadBtn, addNewCompReqBtn];

  // ------------------------ Page Form ----------------------------
  let pageForm =
    paymentPeriodIsLoading ||
    clientIsLoading ||
    companyIsLoading ||
    projectIsLoading ||
    clientCurrencyIsLoading ||
    standardStatementIsLoading ? (
      <LoadingIndicator />
    ) : isAdding ? (
      <Box display="flex" justifyContent="center">
        <Paper className={classes.boxSpace}>{editForm}</Paper>
      </Box>
    ) : (
      <Grid container spacing={1}>
        <Grid item xs={customBP ? 12 : 5}>
          {isEditing ? (
            <Dialog open={isEditing} fullWidth maxWidth="lg">
              <DialogContent>{editForm}</DialogContent>
            </Dialog>
          ) : (
            <div>{viewForm}</div>
          )}
        </Grid>
        <Grid item xs={customBP ? 12 : 7}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={activeTab}
                      onChange={handleTabSelect}
                      variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                    >
                      <Tab
                        value={0}
                        label={"Spend Data (" + payments.length + ")"}
                      />
                    </Tabs>
                  </Box>

                  <IppTabPanel value={activeTab} index={0}>
                    <Box>
                      {/* double check these values */}
                      <IppChildInventory
                        title={t("objBen:objects.payment.name")}
                        parentTitle={t("objBen:objects.spendreporting.report")}
                        linkURL="payments"
                        tableData={periodPayments}
                        idField="PaymentID"
                        nameField="SequenceID"
                        parentID={itemData.PaymentPeriodID}
                        relatedField="PaymentID"
                        columns={paymentsColumns}
                        showAdd={true}
                        showEdit={false}
                        tableName="Payments"
                        id="PaymentList"
                        label={t("objBen:objects.payment.name")}
                        options={payments}
                        selectedValues={periodPaymentsList}
                        setSelectedValues={null}
                        setDidSaveInventory={setDidSaveInventory}
                        parentValue={itemData}
                        showOptions={true}
                        popoverMenu={true}
                        popoverBtns={popoverBtns}
                        disableBtns={!allowCompanyUserEdit}
                        deleteNoteType="Delete"
                        parentProjectID={projectID}
                      />
                    </Box>
                  </IppTabPanel>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>

        <IppChildAdd
          title={t("objBen:objects.company.name")}
          parentTitle={t("objBen:objects.spendreporting.name")}
          parentID={itemData.PaymentPeriodID}
          addFormOpen={isAddingNewComp}
          closeAction={handleAddNewCompClose}
          setDidSaveInventory={false}
          parentValue={itemData}
          formTitle={t("strGen:prompts.add.addtitlewitharticle", {
            fieldname: t("objBen:objects.company.newcompanyrequest"),
          })}
          formDescription={t("strGen:forms.description.addobjrequestwithuse", {
            objectname: t("objBen:objects.company.name"),
            objectname2: t("objBen:objects.spendreporting.name"),
            button: t("strGen:buttons.addobj", {
              objectname: t("objBen:objects.payment.subcontractor"),
            }),
          })}
        />

        <PaymentUploadChildPage
          key={dialogKey}
          isOpen={isUploadingPmts}
          handleClose={handlePmtUploadClose}
          paymentPeriod={itemData}
        />
      </Grid>
    );

  return <Root>{pageForm}</Root>;
};
