import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ClientActivityLocation,
  ClientActivityLocationsResult,
  getClientActivityLocations,
  createClientActivityLocation,
  updateClientActivityLocation,
  deleteClientActivityLocation,
} from "api/clientActivityLocationAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface ClientActivityLocationState {
  clientActivityLocationsById: Record<number, ClientActivityLocation>;
  clientActivityLocationList: number[];
  isLoading: boolean;
  error: string | null;
}

const ClientActivityLocationInitialState: ClientActivityLocationState = {
  clientActivityLocationsById: {},
  clientActivityLocationList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: ClientActivityLocationState) {
  state.isLoading = true;
}

function loadingFailed(
  state: ClientActivityLocationState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const clientActivityLocations = createSlice({
  name: "clientActivityLocations",
  initialState: ClientActivityLocationInitialState,
  reducers: {
    getClientActivityLocationsStart: startLoading,
    getClientActivityLocationsSuccess(
      state,
      { payload }: PayloadAction<ClientActivityLocationsResult>
    ) {
      const { clientActivityLocations } = payload;
      // console.log("PAYLOAD", clientActivityLocations);
      state.isLoading = false;
      state.error = null;

      clientActivityLocations.forEach((clientActivityLocation) => {
        state.clientActivityLocationsById[
          clientActivityLocation.ClientActivityLocationID
        ] = clientActivityLocation;
      });

      state.clientActivityLocationList = clientActivityLocations.map(
        (clientActivityLocation) =>
          clientActivityLocation.ClientActivityLocationID
      );
    },
    getClientActivityLocationsFailure: loadingFailed,
    createClientActivityLocationStart: startLoading,
    createClientActivityLocationSuccess(
      state,
      { payload }: PayloadAction<ClientActivityLocation>
    ) {
      const { ClientActivityLocationID } = payload;
      state.clientActivityLocationsById[ClientActivityLocationID] = payload;
      state.clientActivityLocationList.push(ClientActivityLocationID);

      state.isLoading = false;
      state.error = null;
    },
    updateClientActivityLocationSuccess(
      state,
      { payload }: PayloadAction<ClientActivityLocation>
    ) {
      const { ClientActivityLocationID } = payload;
      state.clientActivityLocationsById[ClientActivityLocationID] = payload;

      state.isLoading = false;
      state.error = null;
    },
    deleteClientActivityLocationSuccess(
      state,
      { payload }: PayloadAction<number>
    ) {
      const ClientActivityLocationID = payload;
      delete state.clientActivityLocationsById[ClientActivityLocationID];
      state.clientActivityLocationList =
        state.clientActivityLocationList.filter(
          (item) => item !== ClientActivityLocationID
        );

      state.isLoading = false;
      state.error = null;
    },
    createClientActivityLocationFailure: loadingFailed,
  },
});

export const {
  getClientActivityLocationsStart,
  getClientActivityLocationsSuccess,
  getClientActivityLocationsFailure,
  createClientActivityLocationStart,
  createClientActivityLocationSuccess,
  updateClientActivityLocationSuccess,
  deleteClientActivityLocationSuccess,
  createClientActivityLocationFailure,
} = clientActivityLocations.actions;

export default clientActivityLocations.reducer;

export const fetchClientActivityLocations =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getClientActivityLocationsStart());
      const clientActivityLocations = await getClientActivityLocations(
        accessToken
      );
      //console.log("RETURN", clientActivityLocations);
      dispatch(getClientActivityLocationsSuccess(clientActivityLocations));
    } catch (err: any) {
      dispatch(getClientActivityLocationsFailure(err.toString()));
    }
  };

export const addClientActivityLocation =
  (
    accessToken: String,
    newClientActivityLocation: Partial<ClientActivityLocation>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientActivityLocationStart());
      const clientActivityLocation = await createClientActivityLocation(
        accessToken,
        newClientActivityLocation
      );
      dispatch(createClientActivityLocationSuccess(clientActivityLocation));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push("/admin/clientActivityLocations"));
    } catch (err: any) {
      dispatch(createClientActivityLocationFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updClientActivityLocation =
  (
    accessToken: String,
    clientActivityLocationID: number,
    newClientActivityLocation: Partial<ClientActivityLocation>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientActivityLocationStart());
      const clientActivityLocation = await updateClientActivityLocation(
        accessToken,
        clientActivityLocationID,
        newClientActivityLocation
      );
      dispatch(updateClientActivityLocationSuccess(clientActivityLocation));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push("/admin/clientActivityLocations"));
    } catch (err: any) {
      dispatch(createClientActivityLocationFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delClientActivityLocation =
  (accessToken: String, clientActivityLocationID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientActivityLocationStart());
      const result = await deleteClientActivityLocation(
        accessToken,
        clientActivityLocationID
      );
      dispatch(deleteClientActivityLocationSuccess(clientActivityLocationID));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push("/admin/clientActivityLocations"));
    } catch (err: any) {
      dispatch(createClientActivityLocationFailure(err.toString()));
    }
  };
