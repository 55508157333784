import axiosClient from "./axiosClient";

export interface AffiliationHoursChart {
  IndigenousGroupID: number;
  IndigenousGroupName: string;
  TotalHours: number;
}

export interface AffiliationHoursChartsResult {
  hoursbyaffiliation: AffiliationHoursChart[];
}

export async function getAffiliationHoursCharts(
  accessToken: String
): Promise<AffiliationHoursChartsResult> {
  const url = `/charts/affiliationHours`;
  try {
    const { data } = await axiosClient.get<AffiliationHoursChartsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
