import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

// ! This File is no longer used as Point of Hire has been removed and Employee Region has been changed to use the Platform Region list. This can be used for future Options List items

interface OptionListState {
  employeeRegions: string[];
  isLoading: boolean;
  error: string | null;
  errorList: any[] | null;
  loadingCount: number;
}

// 1 - build options types as needed
type OptionType = "employeeRegions" | "employeePointOfHire";

const OptionListInitialState: OptionListState = {
  employeeRegions: [],
  isLoading: false,
  error: null,
  errorList: null,
  loadingCount: 0,
};

function startLoading(state: OptionListState) {
  state.isLoading = true;
  state.loadingCount++;
}

function loadingFailed(
  state: OptionListState,
  action: PayloadAction<{ status: string; message: string; errors: [] }>
) {
  state.isLoading = false;
  state.loadingCount--;
  state.error = action.payload.message;
  if (action.payload.errors) {
    state.errorList = action.payload.errors;
  }
}

const optionLists = createSlice({
  name: "optionLists",
  initialState: OptionListInitialState,
  reducers: {
    getOptionListStart: startLoading,
    getOptionListsSuccess(state, { payload }: PayloadAction<any>) {
      state.loadingCount--;
      state.isLoading = state.loadingCount > 0 ? true : false;
      state.error = null;
      state.errorList = null;
      // 2 - assign options to state
      switch (payload.option) {
        case "employeeRegions":
          state.employeeRegions = payload.employeeRegions.regions.map(
            (item: any) => item.Region
          );
          break;
        default:
          break;
      }
    },
    //---------------------------
    getOptionListsFailure: loadingFailed,
  },
});

export const {
  getOptionListStart,
  getOptionListsSuccess,
  getOptionListsFailure,
} = optionLists.actions;

export default optionLists.reducer;

export const fetchOptionLists =
  (access_token: string, option: OptionType): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getOptionListStart());
      // add new options API calls here
      switch (option) {
        // ! Left for reference in case of future use of this function
        // case "employeeRegions":
        //   const employeeRegions = await getEmployeeRegions(access_token);
        //   //console.log("SLice", employeeRegions);
        //   dispatch(
        //     getOptionListsSuccess({
        //       employeeRegions,
        //       option: "employeeRegions",
        //     })
        //   );
        //   break;
        // case "employeePointOfHire":
        //   const employeePointOfHire = await getEmployeePointOfHire(
        //     access_token
        //   );
        //   dispatch(
        //     getOptionListsSuccess({
        //       employeePointOfHire,
        //       option: "employeePointOfHire",
        //     })
        //   );
        //   break;
        default:
          break;
      }
    } catch (err: any) {
      dispatch(getOptionListsFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, err.toString()));
    }
  };
