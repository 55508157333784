import axiosClient from "./axiosClient";

export interface ClientPosition {
  ClientPositionID: number;
  ClientID: number;
  JobPositionID: number;
  JobPositionName: string;
  EmployeeCount: number;
  JobHistoryCount: number;
}

export interface ClientPositionsResult {
  clientPositions: ClientPosition[];
}

export async function getClientPositions(
  accessToken: String
): Promise<ClientPositionsResult> {
  const url = `/clientPosition`;

  try {
    const { data } = await axiosClient.get<ClientPositionsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createClientPosition(
  accessToken: String,
  newClientPosition: Partial<ClientPosition>
): Promise<ClientPosition> {
  const url = `/ClientPosition`;

  try {
    const { data } = await axiosClient.post<ClientPosition>(
      url,
      newClientPosition,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateClientPosition(
  accessToken: String,
  ClientPositionID: number,
  newClientPosition: Partial<ClientPosition>
): Promise<ClientPosition> {
  const url = `/ClientPosition/` + ClientPositionID;

  try {
    const { data } = await axiosClient.post<ClientPosition>(
      url,
      newClientPosition,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteClientPosition(
  accessToken: String,
  ClientPositionID: number
): Promise<string> {
  const url = `/ClientPosition/` + ClientPositionID;
  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    console.log("DELDATA", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}
