import { ClientCommitmentStatusType } from "api/clientCommitmentStatusTypeAPI";

export const isCompletedCommitmentStatus = (
  commitmentStatusList: ClientCommitmentStatusType[],
  statusID: number | null
) => {
  if (!statusID) return false;

  const status = commitmentStatusList.find(
    (status) => status.CommitmentStatusTypeID === statusID
  );

  return status?.IsCompleted ?? false;
};
