import {
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Autocomplete,
  Stack,
} from "@mui/material";
import { InfoOutlined, Edit } from "@mui/icons-material";
import { markRequired } from "utils/functions";
import { createCustomAutoCompleteFilter } from "utils/customFilters";
import { defaultRenderOption } from "utils/renderFunctions";

interface IppAutocompleteProps {
  id: string;
  options: any;
  value: any;
  onChangeFunction: any;
  label: string;
  isEditing: boolean;
  setIsEditing: any;
  optionLabelFunction?: any;
  touchedFunction?: any;
  errorFunction?: any;
  textValueFunction?: any;
  disabled?: boolean;
  required?: boolean;
  toolTip?: string;
  autoPopulate?: boolean;
  noOptionsText?: string;
  multiple?: boolean;
  freeSolo?: boolean;
  renderOption?: any; // Provides workaround for MUI 5 bug with handling repeated options
  groupBy?: any; //must sort list according to groupBy or gives duplicate headers
  placeholder?: string;
  key?: number;
  showWarning?: boolean;
}

export const IppAutocomplete = (props: IppAutocompleteProps) => {
  const {
    id,
    options,
    value,
    onChangeFunction,
    label,
    isEditing,
    setIsEditing,
    optionLabelFunction,
    touchedFunction,
    errorFunction,
    textValueFunction,
    disabled = false,
    required,
    toolTip,
    autoPopulate,
    noOptionsText,
    multiple,
    freeSolo,
    renderOption,
    groupBy,
    placeholder,
    key,
    showWarning = false,
  } = props;

  const editChange = () => {
    setIsEditing(!isEditing);
  };

  const setTooltip = !toolTip ? "" : toolTip;

  //If autoPopulate is True or NULL we should still attempt to populate the value.
  const setAutoPopulate = autoPopulate === false ? false : true;

  // sets multiple to false by default
  const setMultiple = multiple ? true : false;

  // sets default freesolo to false
  const setFreeSolo = freeSolo ? true : false;

  // Custom filter logic - normalize input and compare to options
  const customFilter = createCustomAutoCompleteFilter((option: any) =>
    optionLabelFunction ? optionLabelFunction(option) : option
  );

  //This is to auto-select an option when there is only one. Overriding the 'value' is necessary
  //so that it shows on the input box.
  let defaultValue = value;
  if (options) {
    if (setAutoPopulate && options.length === 1 && (!value || value === "")) {
      onChangeFunction(null, options[0]);
      defaultValue = options[0];
    }
  }

  const warningColor = showWarning ? "#FF9800" : undefined;

  return (
    <div>
      {isEditing && !disabled ? (
        <Autocomplete
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: showWarning ? warningColor : undefined,
              },
              "&:hover fieldset": {
                borderColor: showWarning ? warningColor : undefined,
              },
              "&.Mui-focused fieldset": {
                borderColor: showWarning ? warningColor : undefined,
              },
            },
          }}
          id={id}
          key={key}
          disabled={disabled}
          multiple={setMultiple}
          freeSolo={setFreeSolo}
          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
            // if freesolo, capture the input on blur
            if (setFreeSolo) {
              const target = event.target as HTMLInputElement;
              onChangeFunction(event, target.value.trim());
            }
          }}
          autoHighlight={true}
          autoComplete={true}
          value={defaultValue || null}
          onChange={onChangeFunction}
          options={options}
          filterOptions={customFilter}
          renderOption={
            renderOption ||
            ((props: any, option: any) =>
              defaultRenderOption(props, option, optionLabelFunction))
          }
          getOptionLabel={optionLabelFunction}
          noOptionsText={noOptionsText}
          groupBy={groupBy}
          renderInput={(params) => (
            <TextField
              {...params}
              label={markRequired(label, required)}
              placeholder={placeholder}
              error={touchedFunction && Boolean(errorFunction)}
              helperText={touchedFunction && errorFunction}
              onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                if (event.key === "Enter") {
                  // Prevent the default action to stop submitting the form
                  event.preventDefault();
                }
              }}
              InputProps={{
                ...params.InputProps,
                style: { color: warningColor },
                endAdornment: (
                  <Stack direction="row" spacing={1}>
                    {setTooltip.length > 0 ? (
                      <InputAdornment position="end">
                        <Tooltip title={setTooltip} placement="top">
                          <InfoOutlined fontSize="small" />
                        </Tooltip>
                      </InputAdornment>
                    ) : (
                      ""
                    )}
                    {params.InputProps.endAdornment}
                  </Stack>
                ),
              }}
              InputLabelProps={{ style: { color: warningColor }, shrink: true }}
            />
          )}
        />
      ) : (
        <TextField
          id={id}
          name={id}
          disabled={disabled}
          label={markRequired(label, required)}
          value={textValueFunction || (value ? optionLabelFunction(value) : "")}
          placeholder={placeholder}
          //required={required}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                {setTooltip.length > 0 ? (
                  <Tooltip title={setTooltip} placement="top">
                    <InfoOutlined fontSize="small" style={{ marginTop: -3 }} />
                  </Tooltip>
                ) : (
                  ""
                )}
                {!disabled && (
                  <IconButton onClick={editChange} size="small">
                    <Edit fontSize="inherit" />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ shrink: true }}
        />
      )}
    </div>
  );
};
