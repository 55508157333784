import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CommitmentAction,
  CommitmentActionsResult,
  getCommitmentActions,
  createCommitmentAction,
  updateCommitmentAction,
  deleteCommitmentAction,
} from "api/commitments/commitmentActionAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { getCommitmentActionsByCommitment } from "api/commitments/commitmentActionAPI";
import { IppDebug } from "app/App";

interface CommitmentActionState {
  commitmentActionsById: Record<number, CommitmentAction>;
  commitmentActionList: number[];
  isLoading: boolean;
  isLoadingAdd: boolean;
  subCommitmentActionisLoading: boolean;
  isLoaded: boolean;
  error: string | null;
  errorList: any[] | null;
}

const CommitmentActionInitialState: CommitmentActionState = {
  commitmentActionsById: {},
  commitmentActionList: [],
  isLoading: false,
  isLoadingAdd: false,
  subCommitmentActionisLoading: false,
  isLoaded: false,
  error: null,
  errorList: null,
};

function startLoading(state: CommitmentActionState) {
  state.isLoading = true;
}

function startLoadingAdd(state: CommitmentActionState) {
  state.isLoadingAdd = true;
}

function loadingFailed(
  state: CommitmentActionState,
  action: PayloadAction<{ status: string; message: string; errors: [] }>
) {
  state.isLoading = false;
  state.error = action.payload.message;
  if (action.payload.errors) {
    state.errorList = action.payload.errors;
  }
}

const commitmentActions = createSlice({
  name: "commitmentActions",
  initialState: CommitmentActionInitialState,
  reducers: {
    getCommitmentActionsStart: startLoading,
    getCommitmentActionsSuccess(
      state,
      { payload }: PayloadAction<CommitmentActionsResult>
    ) {
      const { commitmentActions } = payload;

      state.isLoading = false;
      state.error = null;
      state.errorList = null;
      state.isLoaded = true;

      commitmentActions.forEach((commitmentAction) => {
        state.commitmentActionsById[commitmentAction.CommitmentActionID] =
          commitmentAction;
      });

      state.commitmentActionList = commitmentActions.map(
        (commitmentAction) => commitmentAction.CommitmentActionID
      );
    },
    getCommitmentActionsFailure: loadingFailed,
    createCommitmentActionStart: startLoadingAdd,
    createCommitmentActionSuccess(
      state,
      { payload }: PayloadAction<CommitmentAction>
    ) {
      const { CommitmentActionID } = payload;
      state.commitmentActionsById[CommitmentActionID] = payload;
      state.commitmentActionList.push(CommitmentActionID);

      state.isLoading = false;
      state.isLoadingAdd = false;
      state.error = null;
    },
    updateCommitmentActionSuccess(
      state,
      { payload }: PayloadAction<CommitmentAction>
    ) {
      const { CommitmentActionID } = payload;

      state.commitmentActionsById[CommitmentActionID] = payload;
      //state.commitmentActionList.push(CommitmentActionID);

      state.isLoading = false;
      state.error = null;
    },
    deleteCommitmentActionSuccess(state, { payload }: PayloadAction<number>) {
      const CommitmentActionID = payload;
      delete state.commitmentActionsById[CommitmentActionID];
      state.commitmentActionList = state.commitmentActionList.filter(
        (item) => item !== CommitmentActionID
      );

      state.isLoading = false;
      state.error = null;
    },
    createCommitmentActionFailure: loadingFailed,
  },
});

export const {
  getCommitmentActionsStart,
  getCommitmentActionsSuccess,
  getCommitmentActionsFailure,
  createCommitmentActionStart,
  createCommitmentActionSuccess,
  updateCommitmentActionSuccess,
  deleteCommitmentActionSuccess,
  createCommitmentActionFailure,
} = commitmentActions.actions;

export default commitmentActions.reducer;

export const fetchCommitmentActions =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCommitmentActionsStart());
      const commitmentActions = await getCommitmentActions(accessToken);
      dispatch(getCommitmentActionsSuccess(commitmentActions));
    } catch (err: any) {
      dispatch(getCommitmentActionsFailure(err.toString()));
    }
  };

export const fetchCommitmentActionsByCommitment =
  (accessToken: String, commitmentID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCommitmentActionsStart());
      const commitmentActions = await getCommitmentActionsByCommitment(
        accessToken,
        commitmentID
      );
      dispatch(getCommitmentActionsSuccess(commitmentActions));
    } catch (err: any) {
      dispatch(getCommitmentActionsFailure(err.toString()));
    }
  };

export const addCommitmentAction =
  (
    accessToken: String,
    newCommitmentAction: Partial<CommitmentAction>,
    setReturnRoute: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCommitmentActionStart());
      const commitmentAction = await createCommitmentAction(
        accessToken,
        newCommitmentAction
      );
      dispatch(createCommitmentActionSuccess(commitmentAction));
      if (
        newCommitmentAction.IsRecurring === true &&
        newCommitmentAction.CommitmentID
      ) {
        dispatch(
          fetchCommitmentActionsByCommitment(
            accessToken,
            newCommitmentAction.CommitmentID
          )
        );
      }
      //IppDebug("Action", newCommitmentAction );
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(
          push(`/commitments/actions/${commitmentAction.CommitmentActionID}`)
        );
      }
    } catch (error: any) {
      IppDebug("Action", newCommitmentAction);
      IppDebug("Error", error);
      if (error.response.data.message) {
        dispatch(createCommitmentActionFailure(error.response.data));
        dispatch(openSnackBar(error.response.data.message, "error"));
      } else {
        dispatch(createCommitmentActionFailure(error.toString()));
        dispatch(openSnackBar(Constants.FAILED, "error"));
      }
    }
  };

export const updCommitmentAction =
  (
    accessToken: String,
    commitmentActionID: number,
    newCommitmentAction: Partial<CommitmentAction>,
    setReturnRoute: boolean
  ): AppThunk =>
  async (dispatch) => {
    console.log(newCommitmentAction);
    try {
      dispatch(createCommitmentActionStart());
      const commitmentAction = await updateCommitmentAction(
        accessToken,
        commitmentActionID,
        newCommitmentAction
      );

      dispatch(updateCommitmentActionSuccess(commitmentAction));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(push("/commitments/actions/" + commitmentActionID));
      }
    } catch (error: any) {
      dispatch(createCommitmentActionFailure(error.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delCommitmentAction =
  (accessToken: String, commitmentActionID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCommitmentActionStart());
      const result = await deleteCommitmentAction(
        accessToken,
        commitmentActionID
      );
      dispatch(deleteCommitmentActionSuccess(commitmentActionID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
      dispatch(push("/commitments/actions/"));
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(createCommitmentActionFailure(error.response.data));
        dispatch(openSnackBar(error.response.data.message, "error"));
      } else {
        dispatch(createCommitmentActionFailure(error.toString()));
        dispatch(openSnackBar(Constants.FAILED, "error"));
      }
    }
  };
