import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchNotificationTemplates } from "./NotificationTemplateSlice";
import { useParams } from "react-router-dom";

import { NotificationTemplateForm } from "./NotificationTemplateForm";
import LoadingIndicator from "components/LoadingIndicator";

interface NotificationTemplateDetProps {
  notificationTemplateID: string;
}

export const NotificationTemplateDetailPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const {
    isLoading,
    error: compError,
    notificationTemplatesById,
  } = useSelector((state: RootState) => state.notificationTemplates);

  // get notificationTemplate ID from url param and attempt to find company in store
  const { notificationTemplateID } = useParams<NotificationTemplateDetProps>();
  const payID = parseInt(notificationTemplateID, 10);

  const notificationTemplate =
    notificationTemplatesById[parseInt(notificationTemplateID, 10)];

  useEffect(() => {
    (async () => {
      try {
        // can avoid going to the DB if we navigated from inventory
        if (!notificationTemplate) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchNotificationTemplates(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [notificationTemplate, dispatch, getAccessTokenSilently]);

  if (typeof payID != "number") {
    return (
      <div>
        <h1>Something went wrong...</h1>
        <div>{notificationTemplateID} is not a valid Company ID</div>
      </div>
    );
  }

  if (compError) {
    return (
      <div>
        <h1>Something went wrong...</h1>
        <div>{compError.toString()}</div>
      </div>
    );
  }

  let notificationTemplateDetView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      {notificationTemplate && (
        <NotificationTemplateForm notificationTemplate={notificationTemplate} />
      )}
    </div>
  );

  return (
    <div id="notificationTemplate-detail-page">
      {notificationTemplateDetView}
    </div>
  );
};
