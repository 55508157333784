import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { InfoOutlined, OpenInBrowser } from "@mui/icons-material";
import { getClickableLink } from "../utils/urlUtils";
import { markRequired } from "utils/functions";
import { useTypedTranslation } from "utils/customHooks";

interface ippTextFieldProps {
  id: string;
  label: string;
  value: any;
  onChangeFunction: any;
  touchedExpression?: any;
  errorsExpression?: any;
  isEditing: boolean;
  setIsEditing: any;
  fieldType?: string;
  defaultValue?: any;
  placeholder?: string;
  multiLine?: boolean;
  required?: boolean;
  toolTip?: string;
  externalLink?: boolean;
  disabled?: boolean;
  onBlurFunction?: any; // Used if onBlur needs to be overridden
  onWheelFunction?: any;
  onKeyDown?: any;
}

export const IppTextField = (props: ippTextFieldProps) => {
  const {
    id,
    label,
    value,
    onChangeFunction,
    touchedExpression,
    errorsExpression,
    isEditing,
    setIsEditing,
    fieldType,
    defaultValue,
    placeholder,
    multiLine,
    required,
    toolTip,
    externalLink,
    disabled,
    onBlurFunction,
    onWheelFunction,
    onKeyDown,
  } = props;
  const t = useTypedTranslation(["strGen"]);

  const editChange = () => {
    setIsEditing(!isEditing);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const trimmedValue = event.target.value.trim();
    onChangeFunction({ target: { name: id, value: trimmedValue } });
  };

  const setType = !fieldType ? "string" : fieldType;
  const setTooltip = !toolTip ? "" : toolTip;

  return (
    <TextField
      id={id}
      name={id}
      label={markRequired(label, required)}
      value={value === null ? "" : value}
      placeholder={placeholder}
      type={setType}
      onChange={onChangeFunction}
      onBlur={onBlurFunction ? onBlurFunction : handleBlur}
      error={touchedExpression && Boolean(errorsExpression)}
      helperText={touchedExpression && errorsExpression}
      disabled={disabled}
      InputProps={
        isEditing
          ? {
              readOnly: false,
              endAdornment:
                setTooltip.length > 0 ? ( //Only show the tooltip if the tooltip prop has been assigned a value.
                  <InputAdornment position="end">
                    <Tooltip title={setTooltip} placement="top">
                      <InfoOutlined
                        fontSize="small"
                        style={{ marginTop: -3 }}
                      />
                    </Tooltip>
                  </InputAdornment>
                ) : (
                  ""
                ),
            }
          : {
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  {setTooltip.length > 0 ? (
                    <Tooltip title={setTooltip} placement="top">
                      <InfoOutlined
                        fontSize="small"
                        style={{ marginTop: -3 }}
                      />
                    </Tooltip>
                  ) : (
                    ""
                  )}
                  {externalLink && value ? (
                    <Tooltip title={t("strGen:tooltips.visitwebsite")}>
                      <IconButton
                        href={getClickableLink(value)}
                        target="_blank"
                      >
                        <OpenInBrowser />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                  <IconButton onClick={editChange} size="small">
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                </InputAdornment>
              ),
            }
      }
      InputLabelProps={{
        shrink: true,
        //required: required,
        style: { pointerEvents: "auto" },
      }}
      defaultValue={defaultValue}
      multiline={multiLine}
      maxRows={4}
      // For number textField, disable number change while scroll
      onWheel={(e: any) =>
        fieldType === "number" &&
        e.target instanceof HTMLElement &&
        e.target.blur()
      }
      // For number textField, prevent 'e' and '-' while typing
      onKeyDown={(e: any) => {
        if (fieldType === "number" && (e.key === "-" || e.key === "e")) {
          e.preventDefault();
        }
      }}
    />
  );
};
