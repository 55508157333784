import { IconButton, Tooltip } from "@mui/material";
import { ExitToApp } from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";

const pathArray = window.location.pathname.split("/");
const redirectLoc = window.location.origin + "/" + pathArray[1];

const LogoutButton = () => {
  const { logout } = useAuth0();
  const { t } = useTranslation(["strGen"]);

  return (
    <Tooltip title={t("strGen:header.icons.logout.tooltip")}>
      <IconButton
        color="inherit"
        onClick={() => logout({ logoutParams: { returnTo: redirectLoc } })}
      >
        <ExitToApp />
      </IconButton>
    </Tooltip>
  );
};

export default LogoutButton;
