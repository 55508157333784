import Accordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useTypedTranslation } from "utils/customHooks";

interface IppAccordionProps extends AccordionProps {
  titleContent?: string | React.ReactNode;
  titlePrefix?: string;
  titlePrefixColor?: string;
  bgColor?: string;
  expandable?: boolean;
  showExpandInfo?: boolean;
}

export const IppAccordion: React.FC<IppAccordionProps> = ({
  titleContent,
  titlePrefix,
  titlePrefixColor,
  bgColor = "transparent",
  expandable = true,
  showExpandInfo = true,
  ...props
}) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const t = useTypedTranslation(["strGen"]);

  const handleChange = () => {
    if (expandable) {
      setExpanded((expandedState) => !expandedState);
    }
  };

  return (
    <Accordion
      expanded={expanded && expandable}
      onChange={handleChange}
      disableGutters
      sx={{
        backgroundColor: bgColor,
        "& .MuiAccordionSummary-root": {
          // Reduce padding inside the summary
          minHeight: 35,
          "& > .MuiAccordionSummary-content": {
            margin: "0px",
            flexGrow: 1,
            cursor: expandable ? "pointer" : "default",
          },
          "& > .MuiIconButton-root": {
            // Specifically target expand icon
            cursor: expandable ? "pointer" : "default",
          },
        },
        "& .MuiAccordionDetails-root": {
          paddingTop: 0, // Reduces space between summary and details
          paddingBottom: 0, // Adjust bottom padding as needed
        },
        ...props.sx,
      }}
    >
      <AccordionSummary
        expandIcon={expandable ? <ExpandMoreIcon /> : null}
        aria-controls="accordion-content"
        id="accordion-header"
      >
        {titleContent && (
          <Typography sx={{ color: "text.secondary" }}>
            {titlePrefix && (
              <span
                style={{
                  color: titlePrefixColor ?? theme.palette.primary.main,
                  paddingRight: 10,
                }}
              >
                {titlePrefix}
              </span>
            )}
            {titleContent}
          </Typography>
        )}
        {showExpandInfo && expandable && (
          <Typography
            sx={{
              fontSize: "12px",
              color: "text.secondary",
              alignSelf: "center",
              paddingLeft: 1,
            }}
          >
            {expanded
              ? `[${t("strGen:buttons.clicktocollapse")}]`
              : `[${t("strGen:buttons.clicktoexpand")}]`}
          </Typography>
        )}
      </AccordionSummary>
      {expandable && (
        <AccordionDetails>
          <Typography sx={{ wordWrap: "break-word" }}>
            {props.children}
          </Typography>
        </AccordionDetails>
      )}
    </Accordion>
  );
};
