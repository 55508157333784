import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Upload } from "@progress/kendo-react-upload";
import { read, utils } from "xlsx";
import { RootState } from "app/rootReducer";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { FileUploadErrors } from "components/FileUploadErrors/FileUploadErrors";
import {
  ConvertDateOffset,
  excelValueFromJSDate,
  isExcelDateValid,
  validateDate,
} from "utils/DateFunctions";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { Close } from "@mui/icons-material";
import { fetchPaymentsByPeriod } from "../PaymentSlice";
import { PaymentPeriod } from "api/benefits/paymentPeriodAPI";
import { formatStringForCompare, validateCurrency } from "utils/functions";
import { fetchCompanyShortForms } from "../../companies/CompanyShortFormSlice";
import "components/Inventory/modalGuide.css";
import Info from "@mui/icons-material/Info";
import PaymentChildUploadGuide from "./PaymentChildUploadGuide";
import { fetchRegionsByRecordType } from "../../regions/RegionSlice";
import { IppButton } from "components/Buttons/IppButton";
import { IppCancelButton } from "components/Buttons/IppCancelButton";
import {
  generatePaymentChildErrorTitles,
  getNewPmtChildConstructedErrors,
  getNewPmtChildConstructedWarnings,
} from "./PaymentChildUploadErrors";
import {
  processConstructedErrorsObject,
  pushFeatureToggles,
  pushListError,
} from "utils/uploadUtils";
import { ProcessedUploadErrors } from "utils/types/index.types";
import { useTypedTranslation } from "utils/customHooks";

const PREFIX = "PaymentUploadChildDialog";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1000,
    height: { xs: "auto", md: "400px" },
    width: { xs: "auto", md: "876px" },
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },

  [`& .new-comp-list`]: {
    marginLeft: theme.spacing(4),
    listStyleType: "none",
  },
}));

interface PaymentUploadChildProps {
  key: number;
  isOpen: boolean;
  handleClose: any;
  paymentPeriod: PaymentPeriod;
}

export const PaymentUploadChildPage = (props: PaymentUploadChildProps) => {
  const { key, isOpen, handleClose, paymentPeriod } = props;
  const paymentPeriodID = paymentPeriod.PaymentPeriodID;
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);
  const dispatch = useDispatch();
  const [A0token, setA0token] = useState("");
  const [processing, setProcessing] = useState(false);
  const [warnings, setWarnings] = useState<ProcessedUploadErrors[]>([]);
  const [successDialog, setSuccessDialog] = useState(false);
  const [files, setFiles] = useState<Partial<any>>({
    files: [] as any,
    events: [] as any,
    errors: [] as any,
    emptyFile: false,
  });
  // counters for duplicate check
  const [checkedRows, setCheckedRows] = useState(0);
  const [matchFound, setMatchFound] = useState(false);

  const [isModalGuideOpen, setModalGuideOpen] = useState(false);

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const {
    ft_ben_Region,
    ft_ben_ActivityLocation,
    ft_ben_Tier,
    ft_ben_BusinessCategory,
    dm_Disability,
    dm_VisibleMinority,
    dm_WomanOwned,
    dm_VeteranOwned,
    dm_LGBTQ,
    dm_Local,
    dm_NorthernResident,
  } = useSelector((state: RootState) => state.client);

  const { regionList, regionsById } = useSelector(
    (state: RootState) => state.region
  );

  const regions = ft_ben_Region ? regionList.map((id) => regionsById[id]) : [];
  const hasRegionTypes = !!(ft_ben_Region && regions.length > 0);

  const regionNames = regions.map((region) => region.RegionName);

  const { paymentList, paymentsById } = useSelector(
    (state: RootState) => state.payments
  );

  const payments = paymentList.map((id) => paymentsById[id]);

  const { companyShortFormList, companyShortFormsById } = useSelector(
    (state: RootState) => state.companyShortForms
  );

  const companies = companyShortFormList.map(
    (CompanyID: number) => companyShortFormsById[CompanyID]
  );

  const companyVendorIDs = companies
    .filter((comp) => comp.VendorID)
    .map((company) => company.VendorID);

  const companyNames = companies.map((company) => company.CompanyName);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        setA0token(accessToken);
        dispatch(fetchCompanyShortForms(accessToken));
        if (ft_ben_Region) {
          dispatch(fetchRegionsByRecordType(accessToken, "Company"));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        setA0token(accessToken);
        dispatch(fetchPaymentsByPeriod(accessToken, paymentPeriodID));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [paymentPeriodID, dispatch, getAccessTokenSilently]);

  // trigger call when match is found
  // this is used to get around usestate latency
  useEffect(() => {
    if (matchFound) {
      setCheckedRows(checkedRows + 1);
      setMatchFound(false);
    }
  }, [matchFound, checkedRows]);

  const basePath = process.env.REACT_APP_API;
  const pathArray = window.location.pathname.split("/");
  const clientShortName = pathArray[1];
  const baseURL = `${basePath}/${clientShortName}/api`;
  const postalCodeHeader = t("objPlt:platformwide.fields.postalcode");

  const fileStatuses = [
    "UploadFailed",
    "Initial",
    "Selected",
    "Uploading",
    "Uploaded",
    "RemoveFailed",
    "Removing",
  ];

  const onBeforeUpload = (event: any) => {
    event.headers.Authorization = `Bearer ${A0token}`;
    event.additionalData.PaymentPeriodID = paymentPeriodID;
  };

  const onAdd = (event: any) => {
    const afterStateChange = () => {
      event.affectedFiles
        .filter((file: any) => !file.validationErrors)
        .forEach((file: any) => {
          const reader = new FileReader();

          reader.onloadend = (ev) => {
            let data = ev.target ? ev.target.result : null;
            let workbook = read(data, {
              type: "binary",
              cellDates: true,
            });
            let sheetName = workbook.SheetNames[0];

            try {
              let XL_row_object = utils.sheet_to_json(
                workbook.Sheets[sheetName],
                //when no header provided, would need to map columns to object properties manually
                { defval: "" }
              );

              let XL_header_object: any[] = utils.sheet_to_json(
                workbook.Sheets[sheetName],
                { header: 1 }
              );

              let sheetIsValid = true;
              let emptyFile = false;

              //validate data from excel sheet here
              //-------------------------------------
              const constructedErrors = getNewPmtChildConstructedErrors();
              const constructedWarnings = getNewPmtChildConstructedWarnings();

              //Check file isn't empty
              if (XL_row_object.length < 1) {
                emptyFile = true;
              } else {
                let stringPropList = [
                  {
                    propName: "Spend Date",
                    req: true,
                  },
                  { propName: "Amount", req: true },
                  { propName: "Company Name", req: true },
                  { propName: "Address", req: false },
                  { propName: "Town/City", req: false },
                  { propName: "Province/State", req: false },
                  { propName: "Country", req: false },
                  { propName: postalCodeHeader, req: false },
                  { propName: "Indigenous Group", req: false },
                  { propName: "Ownership Structure", req: false },
                ];

                if (currentProfile.IsClient) {
                  stringPropList = [
                    ...stringPropList,
                    { propName: "Vendor ID", req: false },
                  ];
                }

                if (hasRegionTypes) {
                  stringPropList = [
                    ...stringPropList,
                    { propName: "Region", req: false },
                  ];
                }

                if (ft_ben_ActivityLocation && currentProfile.IsClient) {
                  stringPropList = [
                    ...stringPropList,
                    { propName: "Activity Location", req: false },
                  ];
                }

                if (ft_ben_Tier && currentProfile.IsClient) {
                  stringPropList = [
                    ...stringPropList,
                    { propName: "Tier", req: false },
                  ];
                }

                if (ft_ben_BusinessCategory) {
                  stringPropList = [
                    ...stringPropList,
                    { propName: "Category of Business", req: false },
                  ];
                }

                if (dm_Disability) {
                  stringPropList = [
                    ...stringPropList,
                    {
                      propName: "Owned by Person(s) with Disability",
                      req: false,
                    },
                  ];
                }

                if (dm_VisibleMinority) {
                  stringPropList = [
                    ...stringPropList,
                    {
                      propName: "Owned by member(s) of Visible Minority",
                      req: false,
                    },
                  ];
                }

                if (dm_WomanOwned) {
                  stringPropList = [
                    ...stringPropList,
                    { propName: "Woman-Owned", req: false },
                  ];
                }

                if (dm_VeteranOwned) {
                  stringPropList = [
                    ...stringPropList,
                    { propName: "Veteran-Owned", req: false },
                  ];
                }

                if (dm_NorthernResident) {
                  stringPropList = [
                    ...stringPropList,
                    { propName: "Owned by Northern Resident", req: false },
                  ];
                }

                if (dm_LGBTQ) {
                  stringPropList = [
                    ...stringPropList,
                    { propName: "LGBTQ+ Owned", req: false },
                  ];
                }

                if (dm_Local) {
                  stringPropList = [
                    ...stringPropList,
                    { propName: "Local?", req: false },
                  ];
                }

                //Check required headers exist
                const headers = XL_header_object[0];

                stringPropList.forEach((prop: any) => {
                  if (!headers.includes(prop.propName) && prop.req) {
                    pushListError(
                      constructedErrors.requiredColumn,
                      prop.propName
                    );
                  } else if (!headers.includes(prop.propName) && !prop.req) {
                    pushListError(
                      constructedWarnings.optionalColumn,
                      prop.propName
                    );
                  }
                });

                //Check required data exists and is in the correct format.
                XL_row_object.forEach((row: any, ix: number) => {
                  //let rowNum = ix + 2;
                  let rowNum = row.__rowNum__ + 1;

                  stringPropList.forEach((prop: any) => {
                    if (
                      prop.req === true &&
                      headers.includes(prop.propName) &&
                      (prop.propName in row !== true ||
                        !row[prop.propName] ||
                        row[prop.propName]?.toString().trim() === "")
                    ) {
                      pushListError(constructedErrors.requiredData, {
                        header: prop.propName,
                        rowNumber: rowNum,
                      });
                    }

                    if (prop.propName in row === true) {
                      if (typeof row[prop.propName].toString() != "string") {
                        pushListError(constructedErrors.incorrectType, {
                          header: prop.propName,
                          rowNumber: rowNum,
                          expectedValue: "a text value",
                        });
                      }
                      if (
                        row[prop.propName].toString().length >=
                        (["Spend Date", "Category of Business"].includes(
                          prop.propName
                        )
                          ? 250
                          : 50)
                      ) {
                        pushListError(constructedErrors.characterLimit, {
                          header: prop.propName,
                          rowNumber: rowNum,
                          expectedValue: [
                            "Spend Date",
                            "Category of Business",
                          ].includes(prop.propName)
                            ? 250
                            : 50,
                        });
                      }
                    }
                  });

                  if ("Company Name" in row === true) {
                    let foundCompany = null;

                    if ("Vendor ID" in row === true) {
                      foundCompany = companyVendorIDs.find(
                        (vendorID) =>
                          formatStringForCompare(vendorID) ===
                          formatStringForCompare(row["Vendor ID"])
                      );
                    }

                    if (!foundCompany) {
                      foundCompany = companyNames.find(
                        (companyName) =>
                          formatStringForCompare(companyName) ===
                          formatStringForCompare(row["Company Name"])
                      );
                    }

                    if (!foundCompany) {
                      pushListError(
                        constructedWarnings.newCompanies,
                        {
                          header: row["Company Name"],
                          rowNumber: rowNum,
                        },
                        true
                      );

                      if (hasRegionTypes && "Region" in row) {
                        if (row["Region"]?.trim() === "") {
                          pushListError(constructedErrors.requiredData, {
                            header: "Region",
                            rowNumber: rowNum,
                          });
                        } else {
                          const foundRegion = regionNames.find(
                            (regionName) =>
                              formatStringForCompare(regionName) ===
                              formatStringForCompare(row["Region"])
                          );

                          if (!foundRegion) {
                            pushListError(
                              constructedErrors.invalidRegion,
                              {
                                header: row["Region"],
                                rowNumber: rowNum,
                              },
                              true
                            );
                          }
                        }
                      }

                      if (
                        "Town/City" in row &&
                        row["Town/City"]?.trim() === ""
                      ) {
                        pushListError(constructedErrors.requiredData, {
                          header: "Town/City",
                          rowNumber: rowNum,
                        });
                      }

                      // All of these feature toggles only matter if the Company is new
                      const featureToggleConfigs = [
                        {
                          toggle: hasRegionTypes,
                          header: "Region",
                          messageOverride: ft_ben_Region ? (
                            <>
                              <strong>Region</strong> is enabled but has no
                              matching Record Types. If you wish to see this
                              Data on the platform please Contact your
                              Administrator to have Record Types added for
                              Region.
                            </>
                          ) : undefined,
                        },
                        {
                          toggle: ft_ben_ActivityLocation,
                          header: "Activity Location",
                        },
                        {
                          toggle: ft_ben_Tier,
                          header: "Tier",
                        },
                        {
                          toggle: ft_ben_BusinessCategory,
                          header: "Category of Business",
                        },
                        {
                          toggle: dm_Disability,
                          header: "Owned by Person(s) with Disability",
                        },
                        {
                          toggle: dm_VisibleMinority,
                          header: "Owned by member(s) of Visible Minority",
                        },
                        {
                          toggle: dm_WomanOwned,
                          header: "Woman-Owned",
                        },
                        {
                          toggle: dm_VeteranOwned,
                          header: "Veteran-Owned",
                        },
                        {
                          toggle: dm_NorthernResident,
                          header: "Owned by Northern Resident",
                        },
                        {
                          toggle: dm_LGBTQ,
                          header: "LGBTQ+ Owned",
                        },
                        {
                          toggle: dm_Local,
                          header: "Local?",
                        },
                      ];

                      pushFeatureToggles(
                        featureToggleConfigs,
                        row,
                        rowNum,
                        constructedWarnings.disabledFeature
                      );
                    }
                  }

                  if ("Amount" in row) {
                    // Check if the value is not empty
                    if (row["Amount"] !== "") {
                      if (!validateCurrency(row["Amount"])) {
                        pushListError(constructedErrors.incorrectType, {
                          header: "Amount",
                          rowNumber: rowNum,
                          expectedValue: "a valid currency amount",
                        });
                      }
                    }
                  }

                  if ("Spend Date" in row === true && row["Spend Date"]) {
                    const paymentDate =
                      new Date(row["Spend Date"]) ?? undefined;
                    const startDate = new Date(paymentPeriod.StartDate)
                      .toISOString()
                      .substring(0, 10);
                    const endDate = new Date(paymentPeriod.EndDate)
                      .toISOString()
                      .substring(0, 10);

                    if (
                      !paymentDate ||
                      !isExcelDateValid(row["Spend Date"]) ||
                      !validateDate(paymentDate, paymentPeriod)
                    ) {
                      pushListError(constructedErrors.incorrectType, {
                        header: "Spend Date",
                        rowNumber: rowNum,
                        expectedValue: `a valid Date in the format 'YYYY-MM-DD' and should be within the  Spend Report (${startDate} to ${endDate})`,
                      });
                    } else {
                      // Convert to JavaScript Date

                      row["Spend Date"] = ConvertDateOffset(paymentDate);
                    }
                  }

                  // check first nn rows for duplicates
                  if (checkedRows < 10) {
                    if (isExcelDateValid(row["Spend Date"])) {
                      let foundItem = payments.find(
                        (item: any) =>
                          (formatStringForCompare(row["Vendor ID"]) ===
                            formatStringForCompare(item.VendorID) ||
                            formatStringForCompare(row["Company Name"]) ===
                              formatStringForCompare(item.CompanyName)) &&
                          row["Amount"] === item.PaymentAmount &&
                          excelValueFromJSDate(row["Spend Date"]) ===
                            excelValueFromJSDate(item.PaymentDate)
                      );
                      if (foundItem) {
                        setMatchFound(true);
                        constructedWarnings.potentialDuplicate.count++;
                      }
                    }
                  }
                });

                //-------------------------------------
                const fileErrors = processConstructedErrorsObject(
                  constructedErrors,
                  generatePaymentChildErrorTitles
                );

                const fileWarnings = processConstructedErrorsObject(
                  constructedWarnings,
                  generatePaymentChildErrorTitles
                );

                if (fileWarnings.length > 0) {
                  setWarnings(fileWarnings);
                }

                if (fileErrors.length > 0 || emptyFile) {
                  sheetIsValid = false;
                }

                if (sheetIsValid) {
                  setProcessing(false);
                } else {
                  setFiles({
                    files: [],
                    events: [
                      ...files.events,
                      `File failed validation: ${event.affectedFiles[0].name}`,
                    ],
                    errors: fileErrors,
                    emptyFile: emptyFile,
                  });
                  setProcessing(false);
                }
              }
            } catch (err: any) {
              throw err;
            }
          };

          reader.onerror = function (ex) {
            console.log(ex);
          };

          reader.readAsBinaryString(file.getRawFile());
        });
    };

    setProcessing(true);

    setFiles({
      files: event.newState,
      events: [
        ...files.events,
        `File selected: ${event.affectedFiles[0].name}`,
      ],
      errors: [],
    });
    afterStateChange();
    setProcessing(false);

    // clear state
    setCheckedRows(0);
    setMatchFound(false);
    setWarnings([]);
  };

  const onRemove = (event: any) => {
    setFiles({
      files: event.newState,
      events: [...files.events, `File removed: ${event.affectedFiles[0].name}`],
      errors: [],
    });
    setWarnings([]);
    setProcessing(false);

    // clear state
    setCheckedRows(0);
    setMatchFound(false);
    setWarnings([]);
  };

  const onProgress = (event: any) => {
    setFiles({
      files: event.newState,
      events: [
        ...files.events,
        `On Progress: ${event.affectedFiles[0].progress} %`,
      ],
      errors: [...files.errors],
    });
  };

  const onStatusChange = (event: any) => {
    const file = event.affectedFiles[0];

    // On success, clear state
    if (file.status === 4) {
      setWarnings([]);
    }

    setFiles({
      files: event.newState,
      events: [
        ...files.events,
        `File '${file.name}' status changed to: ${fileStatuses[file.status]}`,
      ],
      errors: [...files.errors],
    });

    // Check if the upload was successful
    if (file.status === 4) {
      setSuccessDialog(true);
    }
  };

  const closeDialog = (event: any, reason: any) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      dispatch(openSnackBar("Please select either Save or Cancel", "info"));
    } else {
      handleClose();
    }
  };

  const openModalGuide = () => {
    setModalGuideOpen(true);
  };

  const closeModalGuide = () => {
    setModalGuideOpen(false);
  };

  return (
    <StyledDialog maxWidth="md" open={isOpen} onClose={closeDialog}>
      <DialogTitle>
        <Typography variant="h5" component="h1">
          {successDialog
            ? "Successfully Uploaded"
            : "Upload Spend for this Spend Report"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container className={classes.editForm} spacing={1}>
          <Grid item container xs justifyContent="flex-end">
            <IppButton
              onClick={openModalGuide}
              startIcon={<Info />}
              variant="text"
            >
              Guide
            </IppButton>
            {isModalGuideOpen && (
              <PaymentChildUploadGuide
                closeModalGuide={closeModalGuide}
                ft_ben_Region={hasRegionTypes}
                ft_ben_BusinessCategory={ft_ben_BusinessCategory}
                ft_ben_Tier={ft_ben_Tier}
                ft_ben_ActivityLocation={ft_ben_ActivityLocation}
                dm_Disability={dm_Disability}
                dm_LGBTQ={dm_LGBTQ}
                dm_Local={dm_Local}
                dm_VeteranOwned={dm_VeteranOwned}
                dm_VisibleMinority={dm_VisibleMinority}
                dm_WomanOwned={dm_WomanOwned}
                dm_NorthernResident={dm_NorthernResident}
              />
            )}
          </Grid>

          {!successDialog && (
            <Grid item xs={12}>
              <FileUploadErrors
                errors={files.errors}
                emptyFile={files.emptyFile}
              />

              {files.errors.length === 0 ? (
                <FileUploadErrors errors={warnings} isWarning />
              ) : (
                ""
              )}
            </Grid>
          )}

          <Grid item xs={12}>
            <Upload
              disabled={false}
              showActionButtons={
                !processing && files.errors.length === 0 && A0token !== ""
              }
              autoUpload={false}
              multiple={false}
              files={files.files}
              restrictions={{
                allowedExtensions: [".xlsx"],
              }}
              withCredentials={true}
              onAdd={onAdd}
              onRemove={onRemove}
              onProgress={onProgress}
              onStatusChange={onStatusChange}
              onBeforeUpload={onBeforeUpload}
              saveUrl={`${baseURL}/payment/${paymentPeriodID}/upload`}
            />
          </Grid>

          <Grid item xs={12}>
            {successDialog ? (
              <IppCancelButton
                buttonText={t("strGen:buttons.close")}
                onClick={handleClose}
                startIcon={<Close />}
              />
            ) : (
              <IppCancelButton onClick={handleClose} />
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
};
