import { styled } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { fetchTags, delTag } from "./TagSlice";
import { Box, Chip, Grid, Paper, Tooltip, Typography } from "@mui/material";
import {
  Grid as KendoGrid,
  GridColumn,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { Delete, Edit } from "@mui/icons-material";
import { useTypedTranslation } from "utils/customHooks";
import LoadingIndicator from "components/LoadingIndicator";
import { fetchDeleteChecksByTag } from "features/deleteCheck/DeleteCheckSlice";
import IppConfirmDialog from "components/IppConfirmDialog";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { TagForm } from "./TagForm";
import { IppButton } from "components/Buttons/IppButton";
import { IppAddButton } from "components/Buttons/IppAddButton";
import { useRecordTypeLabel } from "utils/hooks/useRecordTypeLabel";

const PREFIX = "TagForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
  roleCard: `${PREFIX}-roleCard`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1100,
  },

  [`& .${classes.boxSpace}`]: {
    padding: 10,
    maxWidth: "75vw",
  },

  [`& .${classes.roleCard}`]: {
    minWidth: 250,

    minHeight: 300,
  },
}));

export const TagPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [editID, setEditID] = useState<number | undefined>(undefined);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);
  const { getSortedLabels } = useRecordTypeLabel();

  const { clientId } = useSelector((state: RootState) => state.client);

  const { tagList, tagsById, isLoading } = useSelector(
    (state: RootState) => state.tag
  );
  const tags = tagList.map((id) => tagsById[id]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchTags(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  const handleDelete = (tagId: number | undefined) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        if (tagId) {
          dispatch(delTag(accessToken, tagId));
          setDeleteOpen(false);
        } else {
          dispatch(openSnackBar(Constants.FAILED, "error"));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const deleteCell = (props: any) => {
    return (
      <td>
        <Tooltip title={t("strGen:buttons.tooltip.deleteentry")}>
          <div
            style={{ cursor: "pointer", color: "blue" }}
            onClick={(e) => {
              setEditID(props.dataItem.TagID);
              setDeleteOpen(true);
            }}
          >
            <IppButton variant="text" startIcon={<Delete />} color={"error"} />
          </div>
        </Tooltip>
      </td>
    );
  };

  const editCell = (props: any) => {
    return (
      <td>
        <Tooltip title={t("strGen:buttons.tooltip.editentry")}>
          <div
            style={{ cursor: "pointer", color: "blue" }}
            onClick={(e) => {
              setEditID(props.dataItem.TagID);
              setEditOpen(true);
            }}
          >
            <IppButton
              variant="text"
              startIcon={<Edit />}
              color={"secondary"}
            />
          </div>
        </Tooltip>
      </td>
    );
  };

  let tagView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <Box display="flex" justifyContent="center">
        <Paper className={classes.boxSpace}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h5" component="h3">
                {t("objPlt:objects.tag.name_other")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Grid container justifyContent="flex-end">
                <IppAddButton
                  buttonText={t("strGen:buttons.addobj", {
                    objectname: t("objPlt:objects.tag.name"),
                  })}
                  onClick={() => {
                    setIsAdding(true);
                    setEditOpen(true);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <KendoGrid data={tags} total={tags.length}>
                <GridNoRecords>
                  **{" "}
                  {t("strGen:inventory.norecordsexist", {
                    objectname: t("objPlt:objects.tag.name_other"),
                  })}{" "}
                  **
                </GridNoRecords>
                <GridColumn field="Name" title={t("objPlt:objects.tag.name")} />
                <GridColumn
                  field="RecordType"
                  title={t("objPlt:objects.tag.fields.recordtype")}
                  cell={(props) => {
                    const recordTypes =
                      props.dataItem?.RecordType?.split(",") || [];

                    const sortedRecordTypes = getSortedLabels(
                      recordTypes.filter((type: string[]) => type)
                    ).map((item) => item.label);

                    return (
                      <td>
                        {sortedRecordTypes.map(
                          (label: string, index: number) => (
                            <Tooltip key={index} title={label}>
                              <Chip
                                label={label}
                                variant="outlined"
                                size="small"
                              />
                            </Tooltip>
                          )
                        )}
                      </td>
                    );
                  }}
                />
                <GridColumn
                  cell={editCell}
                  width="50px"
                  field="TagID"
                  title=" "
                />
                <GridColumn cell={deleteCell} width="50px" title=" " />
              </KendoGrid>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <TagForm
        editOpen={editOpen}
        setEditOpen={setEditOpen}
        setEditID={setEditID}
        isAdding={isAdding}
        setIsAdding={setIsAdding}
        tags={tags}
        tagData={tags.find((tag) => tag.TagID === editID) || undefined}
      />

      <IppConfirmDialog
        title={t("strGen:prompts.deletechecks.titles.tags", {
          title: tags.find((tag) => tag.TagID === editID)?.Name || "",
        })}
        open={deleteOpen}
        setOpen={() => setDeleteOpen(!deleteOpen)}
        onConfirm={() => handleDelete(editID)}
        fetchFunc={fetchDeleteChecksByTag}
        recordID={editID}
        info={t("strGen:prompts.deletechecks.tags")}
      />
    </div>
  );

  return <Root id="Tag-Page">{tagView}</Root>;
};
