import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Gender, GendersResult, getGenders } from "api/genderAPI";
import { AppThunk } from "app/store";

interface GenderState {
  gendersById: Record<number, Gender>;
  genderList: number[];
  isLoading: boolean;
  error: string | null;
}

const GenderInitialState: GenderState = {
  gendersById: {},
  genderList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: GenderState) {
  state.isLoading = true;
}

function loadingFailed(state: GenderState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const genders = createSlice({
  name: "gender",
  initialState: GenderInitialState,
  reducers: {
    getGendersStart: startLoading,
    getGendersSuccess(state, { payload }: PayloadAction<GendersResult>) {
      const { genders } = payload;
      // console.log("PAYLOAD", genders);
      state.isLoading = false;
      state.error = null;

      genders.forEach((gender) => {
        state.gendersById[gender.GenderID] = gender;
      });

      state.genderList = genders.map((gender) => gender.GenderID);
    },
    getGendersFailure: loadingFailed,
  },
});

export const { getGendersStart, getGendersSuccess, getGendersFailure } =
  genders.actions;

export default genders.reducer;

export const fetchGenders =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getGendersStart());
      const genders = await getGenders(accessToken);
      //console.log("RETURN", genders);
      dispatch(getGendersSuccess(genders));
    } catch (err: any) {
      dispatch(getGendersFailure(err.toString()));
    }
  };
