import { Delete } from "@mui/icons-material";
import { ButtonProps } from "@mui/material/Button";
import { IppButton, IppButtonProps } from "components/Buttons/IppButton";
import { useTypedTranslation } from "utils/customHooks";

interface IppDeleteButtonProps extends IppButtonProps {
  buttonText?: string;
  color?: ButtonProps["color"];
  startIcon?: React.ReactNode;
}

export const IppDeleteButton = ({
  buttonText,
  color = "error",
  startIcon = <Delete />,
  ...props
}: IppDeleteButtonProps) => {
  const t = useTypedTranslation(["strGen"]);
  const T_buttonText = buttonText || t("strGen:buttons.delete");

  return (
    <IppButton color={color} startIcon={startIcon} {...props}>
      {T_buttonText}
    </IppButton>
  );
};
