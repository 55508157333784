import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ClientCompanyStructure,
  ClientCompanyStructuresResult,
  getClientCompanyStructures,
  createClientCompanyStructure,
  updateClientCompanyStructure,
  deleteClientCompanyStructure,
} from "api/clientCompanyStructureAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface ClientCompanyStructureState {
  clientCompanyStructuresById: Record<number, ClientCompanyStructure>;
  clientCompanyStructureList: number[];
  isLoading: boolean;
  error: string | null;
}

const ClientCompanyStructureInitialState: ClientCompanyStructureState = {
  clientCompanyStructuresById: {},
  clientCompanyStructureList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: ClientCompanyStructureState) {
  state.isLoading = true;
}

function loadingFailed(
  state: ClientCompanyStructureState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const clientCompanyStructures = createSlice({
  name: "clientCompanyStructures",
  initialState: ClientCompanyStructureInitialState,
  reducers: {
    getClientCompanyStructuresStart: startLoading,
    getClientCompanyStructuresSuccess(
      state,
      { payload }: PayloadAction<ClientCompanyStructuresResult>
    ) {
      const { clientCompanyStructures } = payload;
      state.isLoading = false;
      state.error = null;

      clientCompanyStructures.forEach((clientCompanyStructure) => {
        state.clientCompanyStructuresById[
          clientCompanyStructure.ClientCompanyStructureID
        ] = clientCompanyStructure;
      });

      state.clientCompanyStructureList = clientCompanyStructures.map(
        (clientCompanyStructure) =>
          clientCompanyStructure.ClientCompanyStructureID
      );
    },
    getClientCompanyStructuresFailure: loadingFailed,
    createClientCompanyStructureStart: startLoading,
    createClientCompanyStructureSuccess(
      state,
      { payload }: PayloadAction<ClientCompanyStructure>
    ) {
      state.isLoading = false;
      state.error = null;
      state.clientCompanyStructuresById[payload.ClientCompanyStructureID] =
        payload;
      state.clientCompanyStructureList.push(payload.ClientCompanyStructureID);
    },
    createClientCompanyStructureFailure: loadingFailed,
    updateClientCompanyStructureStart: startLoading,
    updateClientCompanyStructureSuccess(
      state,
      { payload }: PayloadAction<ClientCompanyStructure>
    ) {
      state.isLoading = false;
      state.error = null;
      state.clientCompanyStructuresById[payload.ClientCompanyStructureID] =
        payload;
    },
    updateClientCompanyStructureFailure: loadingFailed,
    deleteClientCompanyStructureStart: startLoading,
    deleteClientCompanyStructureSuccess(
      state,
      { payload }: PayloadAction<number>
    ) {
      state.isLoading = false;
      state.error = null;
      delete state.clientCompanyStructuresById[payload];
      state.clientCompanyStructureList =
        state.clientCompanyStructureList.filter(
          (clientCompanyStructureID) => clientCompanyStructureID !== payload
        );
    },
    deleteClientCompanyStructureFailure: loadingFailed,
  },
});

export const {
  getClientCompanyStructuresStart,
  getClientCompanyStructuresSuccess,
  getClientCompanyStructuresFailure,
  createClientCompanyStructureStart,
  createClientCompanyStructureSuccess,
  createClientCompanyStructureFailure,
  updateClientCompanyStructureSuccess,
  deleteClientCompanyStructureSuccess,
} = clientCompanyStructures.actions;

export default clientCompanyStructures.reducer;

export const fetchClientCompanyStructures =
  (accessToken: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getClientCompanyStructuresStart());
      const clientCompanyStructures = await getClientCompanyStructures(
        accessToken
      );
      dispatch(getClientCompanyStructuresSuccess(clientCompanyStructures));
    } catch (err: any) {
      dispatch(getClientCompanyStructuresFailure(err.toString()));
    }
  };

export const addClientCompanyStructure =
  (
    accessToken: string,
    newClientCompanyStructure: Partial<ClientCompanyStructure>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientCompanyStructureStart());
      const clientCompanyStructure = await createClientCompanyStructure(
        accessToken,
        newClientCompanyStructure
      );
      dispatch(createClientCompanyStructureSuccess(clientCompanyStructure));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push("/admin/clientCompanyStructures"));
    } catch (err: any) {
      dispatch(createClientCompanyStructureFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updClientCompanyStructure =
  (
    accessToken: string,
    clientCompanyStructureID: number,
    clientCompanyStructure: Partial<ClientCompanyStructure>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientCompanyStructureStart());
      const updatedClientCompanyStructure = await updateClientCompanyStructure(
        accessToken,
        clientCompanyStructureID,
        clientCompanyStructure
      );
      dispatch(
        updateClientCompanyStructureSuccess(updatedClientCompanyStructure)
      );
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push("/admin/clientCompanyStructures"));
    } catch (err: any) {
      dispatch(createClientCompanyStructureFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delClientCompanyStructure =
  (accessToken: string, clientCompanyStructureID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientCompanyStructureStart());
      const result = await deleteClientCompanyStructure(
        accessToken,
        clientCompanyStructureID
      );
      dispatch(deleteClientCompanyStructureSuccess(clientCompanyStructureID));
      dispatch(openSnackBar(Constants.REMOVE_SUCCESS, "success"));
      dispatch(push("/admin/clientCompanyStructures"));
    } catch (err: any) {
      dispatch(createClientCompanyStructureFailure(err.toString()));
    }
  };
