import axiosClient from "./axiosClient";

export interface UserRole {
  UserRoleID: number;
  ModuleID: number;
  UserAccountID: number;
  ModuleRoleID: number;
  ClientModuleID: number;
  ProjectID: number;
  ActiveForNotification: boolean;
  CompanyID?: number;
}

export interface UserRolesResult {
  userRoles: UserRole[];
}

export async function getUserRolesByUser(
  accessToken: String,
  userID: number
): Promise<UserRolesResult> {
  const url = `/userRoles/${userID}`;

  try {
    const { data } = await axiosClient.get<UserRolesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getCurrentUserRoles(
  accessToken: String
): Promise<UserRolesResult> {
  const url = `/userRoles/current`;

  try {
    const { data } = await axiosClient.get<UserRolesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getAllUserRoles(
  accessToken: String,
  filterBy?: string,
  filterID?: number
): Promise<UserRolesResult> {
  const url = `/userRoles/all/`;

  let params: any = {};

  if (filterBy && filterID) {
    if (filterBy.toLowerCase() === "project") {
      params.ProjectID = filterID;
    }
    if (filterBy.toLowerCase() === "company") {
      params.CompanyID = filterID;
    }
  }

  try {
    const { data } = await axiosClient.get<UserRolesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: params,
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createUserRole(
  accessToken: String,
  newUserRole: Partial<UserRole>,
  isBulk?: boolean
): Promise<UserRole> {
  const url = isBulk ? `/userRoles/bulkAdd` : `/userRoles`;

  try {
    const { data } = await axiosClient.post<UserRole>(url, newUserRole, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateUserRole(
  accessToken: String,
  userRoleID: number,
  newUserRole: Partial<UserRole>
): Promise<UserRole> {
  const url = `/userRoles/` + userRoleID;

  try {
    const { data } = await axiosClient.post<UserRole>(url, newUserRole, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteUserRole(
  accessToken: String,
  userRoleID: number
): Promise<string> {
  const url = `/userRoles/` + userRoleID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
