import { StakeholderGroup } from "api/stakeholder/stakeholderGroupAPI";
import { useTypedTranslation } from "utils/customHooks";
import { checkDuplicateGroup, formatStringForCompare } from "utils/functions";
import * as yup from "yup";
// a very comprehensive url regex that allows for alot
const URL =
  /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

export const ValidationSchema = (
  group: StakeholderGroup,
  groupList: StakeholderGroup[],
  oldGroupName?: StakeholderGroup,
  dupeGroupName?: StakeholderGroup
) => {
  const t = useTypedTranslation(["objPlt", "strGen"]);

  const validateEmailList = (message: string) => {
    return yup.string().test("Email", message, (list) => {
      if (!list) return true; // Allows for empty field
      const emailList = list.split(",").map((email) => email.trim());
      return emailList.every((email) =>
        yup.string().email().isValidSync(email)
      );
    });
  };

  return yup.object({
    GroupName: yup
      .string()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.group.fields.groupname"),
        })
      )
      .max(
        250,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.group.fields.groupname"),
          count: 250,
        })
      )
      .test(
        "checkDuplicate",
        t("strGen:validation.exists", {
          fieldname: t("objPlt:objects.group.fields.groupname"),
        }),
        function (value: any) {
          if (
            value &&
            formatStringForCompare(value) ===
              formatStringForCompare(group.GroupName)
          ) {
            return true;
          }

          if (
            value &&
            oldGroupName &&
            dupeGroupName &&
            (formatStringForCompare(value) ===
              formatStringForCompare(oldGroupName.GroupName) ||
              formatStringForCompare(value) ===
                formatStringForCompare(dupeGroupName.GroupName))
          ) {
            return true;
          }

          const groupExists = checkDuplicateGroup(groupList, value);

          if (groupExists) {
            return false;
          } else {
            return true;
          }
        }
      ),
    OrganizationTypeID: yup
      .number()
      .typeError(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.group.fields.organizationtype"),
        })
      )
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.group.fields.organizationtype"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.group.fields.organizationtype"),
        })
      ),
    WebsiteAddress: yup
      .string()
      .matches(
        URL,
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:objects.group.url"),
        })
      )
      .nullable()
      .max(
        100,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.group.fields.website"),
          count: 100,
        })
      ),
    Indigenous: yup.boolean().nullable(),
    IndigenousGroupID: yup
      .number()
      .nullable()
      .when("Indigenous", {
        is: true, //(Indigenous: boolean) => formik.values.Indigenous === true,
        then: (value) =>
          value
            .required(
              t("strGen:validation.select", {
                fieldname: t("objPlt:objects.group.fields.indigenousgroup"),
              })
            )
            .positive(
              t("strGen:validation.select", {
                fieldname: t("objPlt:objects.group.fields.indigenousgroup"),
              })
            ),
        otherwise: (value) => value.nullable(),
      }),
    Address: yup
      .string()
      .nullable()
      .max(
        100,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.group.fields.address"),
          count: 100,
        })
      ),
    TownCity: yup
      .string()
      .nullable()
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.group.fields.towncity"),
          count: 50,
        })
      ),
    PostalCode: yup
      .string()
      .nullable()
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.group.fields.postalcode"),
          count: 50,
        })
      ),
    GroupEmail: validateEmailList(
      t("strGen:validation.validrequired", {
        fieldname: t("objPlt:objects.group.fields.email"),
      })
    )
      .nullable()
      .max(
        100,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.group.fields.email"),
          count: 100,
        })
      ),
  });
};
