import axiosClient from "api/axiosClient";

export interface Ethnicity {
  EthnicityID: number;
  EthnicityName: string;
  ClientID: number;
}

export interface EthnicitysResult {
  ethnicitys: Ethnicity[];
}

export async function getEthnicitys(
  accessToken: String
): Promise<EthnicitysResult> {
  const url = `/ethnicity`;

  try {
    const { data } = await axiosClient.get<EthnicitysResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createEthnicity(
  accessToken: String,
  newEthnicity: Partial<Ethnicity>
): Promise<Ethnicity> {
  const url = `/ethnicity`;

  try {
    const { data } = await axiosClient.post<Ethnicity>(url, newEthnicity, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateEthnicity(
  accessToken: String,
  ethnicityID: number,
  newEthnicity: Partial<Ethnicity>
): Promise<Ethnicity> {
  const url = `/ethnicity/` + ethnicityID;

  try {
    const { data } = await axiosClient.post<Ethnicity>(url, newEthnicity, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteEthnicity(
  accessToken: String,
  ethnicityID: number
): Promise<string> {
  const url = `/ethnicity/` + ethnicityID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
