import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Category, CategoriesResult, getCategories } from "api/categoryAPI";
import { AppThunk } from "app/store";

interface CategoryState {
  categoriesById: Record<number, Category>;
  categoryList: number[];
  isLoading: boolean;
  error: string | null;
}

const CategoryInitialState: CategoryState = {
  categoriesById: {},
  categoryList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: CategoryState) {
  state.isLoading = true;
}

function loadingFailed(state: CategoryState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const categories = createSlice({
  name: "category",
  initialState: CategoryInitialState,
  reducers: {
    getCategoriesStart: startLoading,
    getCategoriesSuccess(state, { payload }: PayloadAction<CategoriesResult>) {
      const { categories } = payload;
      // console.log("PAYLOAD", categories)
      state.isLoading = false;
      state.error = null;

      categories.forEach((category) => {
        state.categoriesById[category.CategoryID] = category;
      });

      state.categoryList = categories.map((category) => category.CategoryID);
    },
    getCategoriesFailure: loadingFailed,
  },
});

export const {
  getCategoriesStart,
  getCategoriesSuccess,
  getCategoriesFailure,
} = categories.actions;

export default categories.reducer;

export const fetchCategories =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCategoriesStart());
      const categories = await getCategories(accessToken);
      //console.log("RETURN", categories);
      dispatch(getCategoriesSuccess(categories));
    } catch (err: any) {
      dispatch(getCategoriesFailure(err.toString()));
    }
  };
