import axiosClient from "./axiosClient";

export interface ClientActionType {
  ClientActionTypeID: number;
  ClientID: number;
  ActionTypeID: number;
  ActionTypeName: string;
  IsRequired: boolean;
}

export interface ClientActionTypesResult {
  clientActionTypes: ClientActionType[];
}

export async function getClientActionTypes(
  accessToken: String
): Promise<ClientActionTypesResult> {
  const url = `/clientActionType`;

  try {
    const { data } = await axiosClient.get<ClientActionTypesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createClientActionType(
  accessToken: String,
  newClientActionType: Partial<ClientActionType>
): Promise<ClientActionType> {
  const url = `/ClientActionType`;

  try {
    const { data } = await axiosClient.post<ClientActionType>(
      url,
      newClientActionType,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateClientActionType(
  accessToken: String,
  ClientActionTypeID: number,
  newClientActionType: Partial<ClientActionType>
): Promise<ClientActionType> {
  const url = `/ClientActionType/` + ClientActionTypeID;

  try {
    const { data } = await axiosClient.post<ClientActionType>(
      url,
      newClientActionType,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteClientActionType(
  accessToken: String,
  ClientActionTypeID: number
): Promise<string> {
  const url = `/ClientActionType/` + ClientActionTypeID;
  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}
