import axiosClient from "api/axiosClient";

export interface EnTExclusionList {
  EnTExclusionListID: number;
  ExclusionName: string;
  ExclusionDetail: string;
  ExclusionAcronym: string;
}

export interface EnTExclusionListResult {
  exclusionList: EnTExclusionList[];
}

const BASE_URL = "/entExclusionList";

export const getEnTExclusionList = async (
  accessToken: String
): Promise<EnTExclusionListResult> => {
  const url = `${BASE_URL}`;
  try {
    const { data } = await axiosClient.get<EnTExclusionListResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// rest are not needed yet
