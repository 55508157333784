import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ClientActionType,
  ClientActionTypesResult,
  getClientActionTypes,
  createClientActionType,
  updateClientActionType,
  deleteClientActionType,
} from "api/clientActionTypeAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface ClientActionTypeState {
  clientActionTypesById: Record<number, ClientActionType>;
  clientActionTypeList: number[];
  isLoading: boolean;
  error: string | null;
}

const ClientActionTypeInitialState: ClientActionTypeState = {
  clientActionTypesById: {},
  clientActionTypeList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: ClientActionTypeState) {
  state.isLoading = true;
}

function loadingFailed(
  state: ClientActionTypeState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const clientActionTypes = createSlice({
  name: "clientActionTypes",
  initialState: ClientActionTypeInitialState,
  reducers: {
    getClientActionTypesStart: startLoading,
    getClientActionTypesSuccess(
      state,
      { payload }: PayloadAction<ClientActionTypesResult>
    ) {
      const { clientActionTypes } = payload;
      // console.log("PAYLOAD", clientActionTypes);
      state.isLoading = false;
      state.error = null;

      clientActionTypes.forEach((clientActionType) => {
        state.clientActionTypesById[clientActionType.ClientActionTypeID] =
          clientActionType;
      });

      state.clientActionTypeList = clientActionTypes.map(
        (clientActionType) => clientActionType.ClientActionTypeID
      );
    },
    getClientActionTypesFailure: loadingFailed,
    createClientActionTypeStart: startLoading,
    createClientActionTypeSuccess(
      state,
      { payload }: PayloadAction<ClientActionType>
    ) {
      const { ClientActionTypeID } = payload;
      state.clientActionTypesById[ClientActionTypeID] = payload;
      state.clientActionTypeList.push(ClientActionTypeID);

      state.isLoading = false;
      state.error = null;
    },
    updateClientActionTypeSuccess(
      state,
      { payload }: PayloadAction<ClientActionType>
    ) {
      const { ClientActionTypeID } = payload;
      state.clientActionTypesById[ClientActionTypeID] = payload;

      state.isLoading = false;
      state.error = null;
    },
    deleteClientActionTypeSuccess(state, { payload }: PayloadAction<number>) {
      const ClientActionTypeID = payload;
      delete state.clientActionTypesById[ClientActionTypeID];
      state.clientActionTypeList = state.clientActionTypeList.filter(
        (item) => item !== ClientActionTypeID
      );

      state.isLoading = false;
      state.error = null;
    },
    createClientActionTypeFailure: loadingFailed,
  },
});

export const {
  getClientActionTypesStart,
  getClientActionTypesSuccess,
  getClientActionTypesFailure,
  createClientActionTypeStart,
  createClientActionTypeSuccess,
  updateClientActionTypeSuccess,
  deleteClientActionTypeSuccess,
  createClientActionTypeFailure,
} = clientActionTypes.actions;

export default clientActionTypes.reducer;

export const fetchClientActionTypes =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getClientActionTypesStart());
      const clientActionTypes = await getClientActionTypes(accessToken);
      //console.log("RETURN", clientActionTypes);
      dispatch(getClientActionTypesSuccess(clientActionTypes));
    } catch (err: any) {
      dispatch(getClientActionTypesFailure(err.toString()));
    }
  };

export const addClientActionType =
  (
    accessToken: String,
    newClientActionType: Partial<ClientActionType>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientActionTypeStart());
      const clientActionType = await createClientActionType(
        accessToken,
        newClientActionType
      );
      dispatch(createClientActionTypeSuccess(clientActionType));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push("/admin/clientActionTypes"));
    } catch (err: any) {
      dispatch(createClientActionTypeFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updClientActionType =
  (
    accessToken: String,
    clientActionTypeID: number,
    newClientActionType: Partial<ClientActionType>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientActionTypeStart());
      const clientActionType = await updateClientActionType(
        accessToken,
        clientActionTypeID,
        newClientActionType
      );
      dispatch(updateClientActionTypeSuccess(clientActionType));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push("/admin/clientActionTypes"));
    } catch (err: any) {
      dispatch(createClientActionTypeFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delClientActionType =
  (accessToken: String, clientActionTypeID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientActionTypeStart());
      const result = await deleteClientActionType(
        accessToken,
        clientActionTypeID
      );
      dispatch(deleteClientActionTypeSuccess(clientActionTypeID));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push("/admin/clientActionTypes"));
    } catch (err: any) {
      dispatch(createClientActionTypeFailure(err.toString()));
    }
  };
