import { useEffect, useState } from "react";
import { RootState } from "app/rootReducer";
import Parser from "rss-parser";
import { Tooltip } from "@mui/material";
import {
  Grid as KendoGrid,
  GridColumn,
  GridPageChangeEvent,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { useSelector } from "react-redux";
import { IppSkeleton } from "components/IppSkeleton";
import { useTypedTranslation } from "utils/customHooks";

export const NewsFeedPage = () => {
  const basePath = process.env.REACT_APP_API;
  const [posts, setPosts] = useState([]);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);
  const t = useTypedTranslation(["strGen"]);

  const { clientName, clientSearchString } = useSelector(
    (state: RootState) => state.client
  );

  const fetchPosts = async (url: any, parser: any) => {
    const feed = await parser.parseURL(url);

    // Convert the pubDate into a Date to be sorted
    const sortedPosts = feed.items
      .map((post: any) => ({
        ...post,
        pubDate: new Date(post.pubDate),
      }))
      .sort((a: any, b: any) => b.pubDate.getTime() - a.pubDate.getTime());

    // Convert the pubDate back into a string in the desired format
    sortedPosts.forEach((post: any) => {
      post.pubDate = post.pubDate.toUTCString();
    });

    setPosts(sortedPosts);
    setLoadingComplete(true);
  };

  useEffect(() => {
    const parser = new Parser();

    if (clientSearchString) {
      const url = basePath + "/newsfeed/" + clientSearchString;

      fetchPosts(url, parser);
    } else {
      setLoadingComplete(true);
    }
  }, [basePath, clientName, clientSearchString]);

  const CustomLinkCell = (props: any) => {
    return (
      <td>
        <Tooltip title={t("strGen:components.newsfeedpage.tooltip")}>
          <div>
            <a
              style={{
                // display: "table-cell",
                cursor: "pointer",
                color: "blue",
              }}
              href={`${props.dataItem.link}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.dataItem[props.field]}
              {/* <br>{props.dataItem}</br> */}
            </a>
            <span style={{ fontSize: "10px" }}>
              {"  "}({`${props.dataItem.pubDate}`})
            </span>
          </div>
        </Tooltip>
        <div></div>
      </td>
    );
  };

  const pageChange = (event: GridPageChangeEvent) => {
    setSkip(event.page.skip);
    setTake(event.page.take);
  };

  let pageView = !loadingComplete ? (
    <IppSkeleton height={400} />
  ) : (
    <div>
      <KendoGrid
        data={posts.slice(skip, (take ? take : 10) + (skip || 0))}
        pageable={{
          buttonCount: 5,
          info: true,
          // type: "input",
          pageSizes: [5, 10, 25, 50, 100],
        }}
        skip={skip}
        take={take}
        total={posts.length}
        onPageChange={pageChange}
      >
        <GridNoRecords>
          {t("strGen:components.newsfeedpage.nonewsfound")}
        </GridNoRecords>
        <GridColumn
          title={t("strGen:components.newsfeedpage.recentheadlines")}
          field="title"
          cell={CustomLinkCell}
        />
      </KendoGrid>
    </div>
  );

  return <div id="newsfeed-page">{pageView}</div>;
};
