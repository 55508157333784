import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  GrievanceGroup,
  GrievanceGroupsResult,
  getGrievanceGroups,
  getGrievanceGroupsByGroup,
  getGrievanceGroupsByGrievance,
  createGrievanceGroup,
  updateGrievanceGroup,
  deleteGrievanceGroup,
} from "api/stakeholder/grievanceGroupAPI";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { SnackBarConstants } from "utils/customHooks";

interface GrievanceGroupState {
  grievanceGroupsById: Record<number, GrievanceGroup>;
  grievanceGroupList: number[];
  isLoading: boolean;
  subGrievanceGroupisLoading: boolean;
  error: string | null;
}

const GrievanceGroupInitialState: GrievanceGroupState = {
  grievanceGroupsById: {},
  grievanceGroupList: [],
  isLoading: false,
  subGrievanceGroupisLoading: false,
  error: null,
};

function startLoading(state: GrievanceGroupState) {
  state.isLoading = true;
}

function loadingFailed(
  state: GrievanceGroupState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const grievanceGroups = createSlice({
  name: "grievanceGroups",
  initialState: GrievanceGroupInitialState,
  reducers: {
    getGrievanceGroupsStart: startLoading,
    getGrievanceGroupsSuccess(
      state,
      { payload }: PayloadAction<GrievanceGroupsResult>
    ) {
      const { grievanceGroups } = payload;

      state.isLoading = false;
      state.error = null;

      grievanceGroups.forEach((grievanceGroup) => {
        state.grievanceGroupsById[grievanceGroup.GrievanceGroupID] =
          grievanceGroup;
      });

      state.grievanceGroupList = grievanceGroups.map(
        (grievanceGroup) => grievanceGroup.GrievanceGroupID
      );
    },
    getGrievanceGroupsFailure: loadingFailed,
    createGrievanceGroupStart: startLoading,
    createGrievanceGroupSuccess(
      state,
      { payload }: PayloadAction<GrievanceGroup>
    ) {
      const { GrievanceGroupID } = payload;
      state.grievanceGroupsById[GrievanceGroupID] = payload;
      state.grievanceGroupList.push(GrievanceGroupID);

      state.isLoading = false;
      state.error = null;
    },
    updateGrievanceGroupSuccess(
      state,
      { payload }: PayloadAction<GrievanceGroup>
    ) {
      const { GrievanceGroupID } = payload;

      state.grievanceGroupsById[GrievanceGroupID] = payload;
      //state.grievanceGroupList.push(GrievanceGroupID);

      state.isLoading = false;
      state.error = null;
    },
    deleteGrievanceGroupSuccess(state, { payload }: PayloadAction<number>) {
      const GrievanceGroupID = payload;
      delete state.grievanceGroupsById[GrievanceGroupID];
      state.grievanceGroupList = state.grievanceGroupList.filter(
        (item) => item !== GrievanceGroupID
      );

      state.isLoading = false;
      state.error = null;
    },
    createGrievanceGroupFailure: loadingFailed,
  },
});

export const {
  getGrievanceGroupsStart,
  getGrievanceGroupsSuccess,
  getGrievanceGroupsFailure,
  createGrievanceGroupStart,
  createGrievanceGroupSuccess,
  updateGrievanceGroupSuccess,
  deleteGrievanceGroupSuccess,
  createGrievanceGroupFailure,
} = grievanceGroups.actions;

export default grievanceGroups.reducer;

export const fetchGrievanceGroups =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getGrievanceGroupsStart());
      const grievanceGroups = await getGrievanceGroups(accessToken);
      dispatch(getGrievanceGroupsSuccess(grievanceGroups));
    } catch (err: any) {
      dispatch(getGrievanceGroupsFailure(err.toString()));
    }
  };

export const fetchGrievanceGroupsByGroup =
  (accessToken: String, groupID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getGrievanceGroupsStart());
      const grievanceGroups = await getGrievanceGroupsByGroup(
        accessToken,
        groupID
      );
      dispatch(getGrievanceGroupsSuccess(grievanceGroups));
    } catch (err: any) {
      dispatch(getGrievanceGroupsFailure(err.toString()));
    }
  };

export const fetchGrievanceGroupsByGrievance =
  (accessToken: String, grievanceID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getGrievanceGroupsStart());
      const grievanceGroups = await getGrievanceGroupsByGrievance(
        accessToken,
        grievanceID
      );
      dispatch(getGrievanceGroupsSuccess(grievanceGroups));
    } catch (err: any) {
      dispatch(getGrievanceGroupsFailure(err.toString()));
    }
  };

//! COMMENTED OUT FOR FUTURE CLEAN UP
// export const addGrievanceGroup =
//   (accessToken: String, newGrievanceGroup: Partial<GrievanceGroup>): AppThunk =>
//   async (dispatch) => {
//     try {
//       dispatch(createGrievanceGroupStart());
//       const grievanceGroup = await createGrievanceGroup(
//         accessToken,
//         newGrievanceGroup
//       );
//       dispatch(createGrievanceGroupSuccess(grievanceGroup));
//       dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
//     } catch (err: any) {
//       dispatch(createGrievanceGroupFailure(err.toString()));
//       dispatch(openSnackBar(Constants.FAILED, "error"));
//     }
//   };

// export const updGrievanceGroup =
//   (
//     accessToken: String,
//     grievanceGroupID: number,
//     newGrievanceGroup: Partial<GrievanceGroup>
//   ): AppThunk =>
//   async (dispatch) => {
//     try {
//       dispatch(createGrievanceGroupStart());
//       const grievanceGroup = await updateGrievanceGroup(
//         accessToken,
//         grievanceGroupID,
//         newGrievanceGroup
//       );

//       dispatch(updateGrievanceGroupSuccess(grievanceGroup));
//       dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
//     } catch (err: any) {
//       dispatch(createGrievanceGroupFailure(err.toString()));
//       dispatch(openSnackBar(Constants.FAILED, "error"));
//     }
//   };

export const delGrievanceGroup =
  (
    accessToken: String,
    grievanceGroupID: number,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createGrievanceGroupStart());
      const result = await deleteGrievanceGroup(accessToken, grievanceGroupID);
      dispatch(deleteGrievanceGroupSuccess(grievanceGroupID));
      dispatch(openSnackBar(snackbarConstants.DELETE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createGrievanceGroupFailure(err.toString()));
      dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
    }
  };
