import { SchedulerForm } from "./SchedulerForm";

export const SchedulerCreatePage = () => {
  let schedulerCreateView = (
    <div>
      <SchedulerForm />
    </div>
  );

  return <div id="scheduler-detail-page">{schedulerCreateView}</div>;
};
