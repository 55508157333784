import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ClientOrganizationType,
  ClientOrganizationTypesResult,
  getClientOrganizationTypes,
  createClientOrganizationType,
  updateClientOrganizationType,
  deleteClientOrganizationType,
} from "api/clientOrganizationTypeAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface ClientOrganizationTypeState {
  clientOrganizationTypesById: Record<number, ClientOrganizationType>;
  clientOrganizationTypeList: number[];
  isLoading: boolean;
  error: string | null;
}

const ClientOrganizationTypeInitialState: ClientOrganizationTypeState = {
  clientOrganizationTypesById: {},
  clientOrganizationTypeList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: ClientOrganizationTypeState) {
  state.isLoading = true;
}

function loadingFailed(
  state: ClientOrganizationTypeState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const clientOrganizationTypes = createSlice({
  name: "clientOrganizationTypes",
  initialState: ClientOrganizationTypeInitialState,
  reducers: {
    getClientOrganizationTypesStart: startLoading,
    getClientOrganizationTypesSuccess(
      state,
      { payload }: PayloadAction<ClientOrganizationTypesResult>
    ) {
      const { clientOrganizationTypes } = payload;
      // console.log("PAYLOAD", clientOrganizationTypes);
      state.isLoading = false;
      state.error = null;

      clientOrganizationTypes.forEach((clientOrganizationType) => {
        state.clientOrganizationTypesById[
          clientOrganizationType.ClientOrganizationTypeID
        ] = clientOrganizationType;
      });

      state.clientOrganizationTypeList = clientOrganizationTypes.map(
        (clientOrganizationType) =>
          clientOrganizationType.ClientOrganizationTypeID
      );
    },
    getClientOrganizationTypesFailure: loadingFailed,
    createClientOrganizationTypeStart: startLoading,
    createClientOrganizationTypeSuccess(
      state,
      { payload }: PayloadAction<ClientOrganizationType>
    ) {
      const { ClientOrganizationTypeID } = payload;
      state.clientOrganizationTypesById[ClientOrganizationTypeID] = payload;
      state.clientOrganizationTypeList.push(ClientOrganizationTypeID);

      state.isLoading = false;
      state.error = null;
    },
    updateClientOrganizationTypeSuccess(
      state,
      { payload }: PayloadAction<ClientOrganizationType>
    ) {
      const { ClientOrganizationTypeID } = payload;
      state.clientOrganizationTypesById[ClientOrganizationTypeID] = payload;

      state.isLoading = false;
      state.error = null;
    },
    deleteClientOrganizationTypeSuccess(
      state,
      { payload }: PayloadAction<number>
    ) {
      const ClientOrganizationTypeID = payload;
      delete state.clientOrganizationTypesById[ClientOrganizationTypeID];
      state.clientOrganizationTypeList =
        state.clientOrganizationTypeList.filter(
          (item) => item !== ClientOrganizationTypeID
        );

      state.isLoading = false;
      state.error = null;
    },
    createClientOrganizationTypeFailure: loadingFailed,
  },
});

export const {
  getClientOrganizationTypesStart,
  getClientOrganizationTypesSuccess,
  getClientOrganizationTypesFailure,
  createClientOrganizationTypeStart,
  createClientOrganizationTypeSuccess,
  updateClientOrganizationTypeSuccess,
  deleteClientOrganizationTypeSuccess,
  createClientOrganizationTypeFailure,
} = clientOrganizationTypes.actions;

export default clientOrganizationTypes.reducer;

export const fetchClientOrganizationTypes =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getClientOrganizationTypesStart());
      const clientOrganizationTypes = await getClientOrganizationTypes(
        accessToken
      );
      //console.log("RETURN", clientOrganizationTypes);
      dispatch(getClientOrganizationTypesSuccess(clientOrganizationTypes));
    } catch (err: any) {
      dispatch(getClientOrganizationTypesFailure(err.toString()));
    }
  };

export const addClientOrganizationType =
  (
    accessToken: String,
    newClientOrganizationType: Partial<ClientOrganizationType>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientOrganizationTypeStart());
      const clientOrganizationType = await createClientOrganizationType(
        accessToken,
        newClientOrganizationType
      );
      dispatch(createClientOrganizationTypeSuccess(clientOrganizationType));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push("/admin/clientOrganizationTypes"));
    } catch (err: any) {
      dispatch(createClientOrganizationTypeFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updClientOrganizationType =
  (
    accessToken: String,
    clientOrganizationTypeID: number,
    newClientOrganizationType: Partial<ClientOrganizationType>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientOrganizationTypeStart());
      const clientOrganizationType = await updateClientOrganizationType(
        accessToken,
        clientOrganizationTypeID,
        newClientOrganizationType
      );
      dispatch(updateClientOrganizationTypeSuccess(clientOrganizationType));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push("/admin/clientOrganizationTypes"));
    } catch (err: any) {
      dispatch(createClientOrganizationTypeFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delClientOrganizationType =
  (accessToken: String, clientOrganizationTypeID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientOrganizationTypeStart());
      const result = await deleteClientOrganizationType(
        accessToken,
        clientOrganizationTypeID
      );
      dispatch(deleteClientOrganizationTypeSuccess(clientOrganizationTypeID));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push("/admin/clientOrganizationTypes"));
    } catch (err: any) {
      dispatch(createClientOrganizationTypeFailure(err.toString()));
    }
  };
