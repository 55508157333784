import React from "react";
import { Route, Switch } from "react-router";
import ProtectedRoute from "./PrivateRoute";

import { Login } from "features/auth/Login";
import { ProfilePage } from "features/profile/ProfilePage";
import { UsersListPage } from "features/users/UsersListPage";
import { ClientUserInvPage } from "features/platform/admin/ClientUserInvPage";
import { UserDetailPage } from "features/platform/admin/UserDetailPage";
import { UserCreatePage } from "features/platform/admin/UserCreatePage";
import { CompanyUserInvPage } from "features/benefits/companies/CompanyUserInvPage";
import { CompanyInventoryPage } from "features/benefits/companies/CompanyInventoryPage";
import { CompanyDetailPage } from "features/benefits/companies/CompanyDetailPage";
import { CompanyCreatePage } from "features/benefits/companies/CompanyCreatePage";
import { CompanyMergePage } from "features/benefits/companies/CompanyMergePage";
import { ProjectInventoryPage } from "features/project/ProjectInventoryPage";
import { ProjectDetailPage } from "features/project/ProjectDetailPage";
import { ProjectCreatePage } from "features/project/ProjectCreatePage";
import { BenefitsHomePage } from "features/benefits/benefitsHomePage";
import { PaymentPeriodCreatePage } from "features/benefits/paymentPeriods/PaymentPeriodCreatePage";
import { PaymentPeriodInventoryPage } from "features/benefits/paymentPeriods/PaymentPeriodInventoryPage";
import { PaymentPeriodDetailPage } from "features/benefits/paymentPeriods/PaymentPeriodDetailPage";
import { PaymentInventoryPage } from "features/benefits/payments/PaymentInventoryPage";
import { PaymentUploadReviewPage } from "features/benefits/payments/PaymentUploadReviewPage";
import { PaymentCreatePage } from "features/benefits/payments/PaymentCreatePage";
import { PaymentDetailPage } from "features/benefits/payments/PaymentDetailPage";
import { PaymentUploadPage } from "features/benefits/payments/PaymentUpload/PaymentUploadPage";
import { EmployeeInventoryPage } from "features/benefits/employees/EmployeeInventoryPage";
import { EmployeeDetailPage } from "features/benefits/employees/EmployeeDetailPage";
import { EmployeeCreatePage } from "features/benefits/employees/EmployeeCreatePage";
import { EmployeeUploadPage } from "features/benefits/employees/EmployeeUpload/EmployeeUploadPage";
import { InitiativeDetailPage } from "features/benefits/initiatives/InitiativeDetailPage";
import { InitiativeCreatePage } from "features/benefits/initiatives/InitiativeCreatePage";
import { InitiativeInventoryPage } from "features/benefits/initiatives/InitiativeInventoryPage";
import { WorkHourPeriodInventoryPage } from "features/benefits/workHours/WorkHourPeriodInventoryPage";
import { WorkHourPeriodCreatePage } from "features/benefits/workHours/WorkHourPeriodCreatePage";
import { WorkHourPeriodDetailPage } from "features/benefits/workHours/WorkHourPeriodDetailPage";
import { WorkHourUploadPage } from "features/benefits/workHours/WorkHoursUpload/WorkHourUploadPage";
import { CommunityInvestmentInventoryPage } from "features/benefits/communityInvestments/CommunityInvestmentInventoryPage";
import { CommunityInvestmentCreatePage } from "features/benefits/communityInvestments/CommunityInvestmentCreatePage";
import { CommunityInvestmentDetailPage } from "features/benefits/communityInvestments/CommunityInvestmentDetailPage";
import { NotificationTemplateInventoryPage } from "features/platform/admin/notificationTemplates/NotificationTemplateInventoryPage";
import { NotificationTemplateCreatePage } from "features/platform/admin/notificationTemplates/NotificationTemplateCreatePage";
import { NotificationTemplateDetailPage } from "features/platform/admin/notificationTemplates/NotificationTemplateDetailPage";
import { CustomNotificationForm } from "features/platform/admin/customNotification/CustomNotificationForm";
import { AdminHomePage } from "features/platform/admin/AdminHomePage";
import { SchedulerInventoryPage } from "features/platform/admin/schedulers/SchedulerInventoryPage";
import { SchedulerCreatePage } from "features/platform/admin/schedulers/SchedulerCreatePage";
import { SchedulerDetailPage } from "features/platform/admin/schedulers/SchedulerDetailPage";
import { ActionReminderInventoryPage } from "features/platform/admin/actionReminders/ActionReminderInventoryPage";
import { ClientBranding } from "features/platform/admin/branding/ClientBranding";
import { ClientGroupForm } from "features/platform/admin/clientGroups/ClientGroupForm";
import { ClientPositionForm } from "features/platform/admin/clientPositions/ClientPositionForm";
import { ClientInteractionTypeForm } from "features/platform/admin/clientInteractionTypes/ClientInteractionTypeForm";
import { ClientCurrencyTypeForm } from "features/platform/admin/currency/ClientCurrencyForm";
import { ClientCountryForm } from "features/platform/admin/clientCountry/ClientCountryForm";
import { ClientCommitmentStatusTypeForm } from "features/platform/admin/clientCommitmentStatusType/ClientCommitmentStatusTypeForm";
import { ClientCommitmentSourceTypeForm } from "features/platform/admin/clientCommitmentSourceTypes/ClientCommitmentSourceTypeForm";
import { ClientCommitmentCategoryForm } from "features/platform/admin/clientCommitmentCategories/ClientCommitmentCategoryForm";
import { ClientOrganizationTypeForm } from "features/platform/admin/clientOrganizationType/ClientOrganizationTypeForm";
import { ClientActionTypeForm } from "features/platform/admin/clientActionTypes/ClientActionTypeForm";
import { ClientCompanyStructureForm } from "features/platform/admin/clientCompanyStructures/ClientCompanyStructureForm";
import { TriggeredNotificationsFeaturesForm } from "features/platform/admin/features/TriggeredNotificationFeaturesForm";
import { ReportPage } from "features/reporting/ReportPage";
import { CompanyReviewInventoryPage } from "features/benefits/companies/CompanyReviewInventoryPage";
import { EmployeeReviewInventoryPage } from "features/benefits/employees/EmployeeReviewInventoryPage";
import { PlatformHomePage } from "features/platform/PlatformHomePage";
import { CompanyRequestInventoryPage } from "features/benefits/companies/CompanyRequestInventoryPage";
import { StakeholderGroupInventoryPage } from "features/platform/groups/StakeholderGroupInventoryPage";
import { StakeholderGroupDetailPage } from "features/platform/groups/StakeholderGroupDetailPage";
import { StakeholderGroupMergePage } from "features/platform/groups/StakeholderGroupMergePage";
import { StakeholderGroupCreatePage } from "features/platform/groups/StakeholderGroupCreatePage";
import { ContactInventoryPage } from "features/platform/contacts/ContactInventoryPage";
import { ContactMergePage } from "features/platform/contacts/ContactMergePage";
import { ContactCreatePage } from "features/platform/contacts/ContactCreatePage";
import { ContactDetailPage } from "features/platform/contacts/ContactDetailPage";
import { InteractionInventoryPage } from "features/stakeholder/interaction/InteractionInventoryPage";
import { InteractionReviewInventoryPage } from "features/stakeholder/interaction/InteractionReviewInventoryPage";
import { InteractionDetailPage } from "features/stakeholder/interaction/InteractionDetailPage";
import { InteractionCreatePage } from "features/stakeholder/interaction/InteractionCreatePage";
import { InteractionActionDetailPage } from "features/stakeholder/interactionAction/InterationActionDetailPage";
import { ThreadInventoryPage } from "features/stakeholder/thread/ThreadInventoryPage";
import { ThreadCreatePage } from "features/stakeholder/thread/ThreadCreatePage";
import { ThreadDetailPage } from "features/stakeholder/thread/ThreadDetailPage";
import { IssueInventoryPage } from "features/stakeholder/issue/IssueInventoryPage";
import { IssueCreatePage } from "features/stakeholder/issue/IssueCreatePage";
import { IssueDetailPage } from "features/stakeholder/issue/IssueDetailPage";
import { StakeholderHomePage } from "features/stakeholder/homePage/StakeholderHomePage";
import { GrievanceInventoryPage } from "features/stakeholder/grievance/GrievanceInventoryPage";
import { GrievanceCreatePage } from "features/stakeholder/grievance/GrievanceCreatePage";
import { GrievanceDetailPage } from "features/stakeholder/grievance/GrievanceDetailPage";
import { InteractionUploadPage } from "features/stakeholder/interaction/InteractionUpload/InteractionUploadPage";
import { ContactUploadPage } from "features/platform/contacts/ContactUpload/ContactUploadPage";
import { StakeholderGroupUploadPage } from "features/platform/groups/StakeholderGroupUpload/StakeholderGroupUploadPage";
import { CommitmentInventoryPage } from "features/commitments/commitment/CommitmentInventoryPage";
import { CommitmentCreatePage } from "features/commitments/commitment/CommitmentCreatePage";
import { CommitmentDetailPage } from "features/commitments/commitment/CommitmentDetailPage";
import { CommitmentUploadPage } from "features/commitments/commitment/CommitmentUpload/CommitmentUploadPage";
import { CommitmentActionInventoryPage } from "features/commitments/commitmentAction/CommitmentActionInventoryPage";
import { CommitmentActionCreatePage } from "features/commitments/commitmentAction/CommitmentActionCreatePage";
import { CommitmentActionDetailPage } from "features/commitments/commitmentAction/CommitmentActionDetailPage";
import { CommitmentSourceCreatePage } from "features/commitments/commitmentSource/CommitmentSourceCreatePage";
import { CommitmentSourceDetailPage } from "features/commitments/commitmentSource/CommitmentSourceDetailPage";
import { CommitmentSourceInventoryPage } from "features/commitments/commitmentSource/CommitmentSourceInventoryPage";
import { CommitmentsHomePage } from "features/commitments/homePage/CommitmentsHomePage";
import { UnauthorizedUser } from "features/platform/UnauthorizedUser";
//import { StakeholderLogs } from "features/stakeholder/logTables/StakeholderLogs";
//import { EngagementLogs } from "features/stakeholder/logTables/EngagementLogs";
//import { FollowupLogs } from "features/stakeholder/logTables/FollowupLogs";
import { InteractionActionInventoryPage } from "features/stakeholder/interactionAction/InteractionActionInventoryPage";
import { InteractionCommentInventoryPage } from "features/stakeholder/interactionComment/InteractionCommentInventory";
import { FeaturesForm } from "features/platform/admin/features/FeaturesForm";
//import { FeedbackByGroup } from "features/stakeholder/logTables/FeedbackByGroup";
//import { FeedbackByStakeholder } from "features/stakeholder/logTables/FeedbackByStakeholder";
//import { FeedbackByIssue } from "features/stakeholder/logTables/FeedbackByIssue";
import { ContractDetailPage } from "features/contract/ContractDetailPage";
import { ContractCreatePage } from "features/contract/ContractCreateForm";
import { ContractInventoryPage } from "features/contract/ContractInventoryPage";
import { ActionReminderCreatePage } from "features/platform/admin/actionReminders/ActionReminderCreatePage";
import { ActionReminderDetailPage } from "features/platform/admin/actionReminders/ActionReminderDetailPage";
import { DiversityMonitoringForm } from "features/platform/admin/clientLists/DiversityMonitoringForm";
import { PhaseForm } from "features/platform/admin/phase/PhasePage";
import { TagPage } from "features/platform/admin/tag/TagPage";
import { TierPage } from "features/benefits/tier/TierPage";
import { RnDExpenseInventoryPage } from "features/benefits/RnDExpense/RnDExpenseInventoryPage";
import { RnDExpenseCreatePage } from "features/benefits/RnDExpense/RnDExpenseCreatePage";
import { RnDExpenseDetailPage } from "features/benefits/RnDExpense/RnDExpenseDetailPage";
import { EnTExpenseCreatePage } from "features/benefits/entExpenses/EnTExpenseCreatePage";
import { EnTExpenseDetailPage } from "features/benefits/entExpenses/EnTExpenseDetailPage";
import { EnTExpenseInventoryPage } from "features/benefits/entExpenses/EnTExpenseInventoryPage";
import { AssetDetailPage } from "features/benefits/assets/AssetDetailPage";
import { AssetCreatePage } from "features/benefits/assets/AssetCreatePage";
import { AssetInventoryPage } from "features/benefits/assets/AssetInventoryPage";
import { EducationTrainingInventoryPage } from "features/benefits/educationTrainings/EducationTrainingInventoryPage";
import { EducationTrainingDetailPage } from "features/benefits/educationTrainings/EducationTrainingDetailPage";
import { EducationTrainingCreatePage } from "features/benefits/educationTrainings/EducationTrainingCreatePage";
import { CustomRnDCreatePage } from "features/benefits/customRnD/CustomRnDCreatePage";
import { CustomRnDDetailPage } from "features/benefits/customRnD/CustomRnDDetailPage";
import { CustomRnDInventoryPage } from "features/benefits/customRnD/CustomRnDInventoryPage";
import { EthnicityForm } from "features/platform/admin/ethnicity/EthnicityPage";
import { CustomEmpCategoryForm } from "features/platform/admin/customEmpCategory/CustomEmpCategoryForm";
//--------------------sshe-------------------------------
import { SsheCreatePage } from "features/sshe/SsheCreatePage";
import { SsheDetailPage } from "features/sshe/SsheDetailPage";
import { SsheInventoryPage } from "features/sshe/SsheInventoryPage";

//--------------------supplier---------------------------
import { SupplierCreatePage } from "features/suppliers/supplier/SupplierCreatePage";
import { SupplierDetailPage } from "features/suppliers/supplier/SupplierDetailPage";
import { SupplierInventoryPage } from "features/suppliers/supplier/SupplierInventory";
import { SupplierReviewInventoryPage } from "features/suppliers/supplier/SupplierReviewInventoryPage";
import { DisabledFeaturePage } from "features/platform/DisabledFeaturePage";
import { ProjectTypesPage } from "features/project/projectTypes/ProjectTypesPage";
import { VendorInsightsPage } from "features/benefits/vendorInsights/VendorInsights";
import { SsheHomePage } from "features/sshe/SsheHomePage";
import { StandardStatementInventory } from "features/platform/admin/standardStatement/StandardStatementInventory";

/*  More specific Routes should be place heigher up than the general ones and 
routes with Id 
E.g. /users/new 
above /users/:UserID 
above /users 
*/

const routes = (
  <Switch>
    {/* <ProtectedRoute
      exact
      path="/"
      component={() => {
        return null;
      }}
    /> */}
    <Route exact path="/login" component={Login} />
    <ProtectedRoute path="/profile" component={ProfilePage} />
    <ProtectedRoute path="/users" component={UsersListPage} />
    <ProtectedRoute path="/:moduleShortName/reporting" component={ReportPage} />
    <ProtectedRoute
      path="/:moduleShortName/admin/reporting"
      component={ReportPage}
    />
    <ProtectedRoute
      path="/admin/:moduleShortName/reporting"
      component={ReportPage}
    />
    <ProtectedRoute path="/admin/users/all/new" component={UserCreatePage} />
    <ProtectedRoute
      path="/admin/users/all/:userID"
      component={UserDetailPage}
    />
    <ProtectedRoute path="/admin/users/all" component={UsersListPage} />
    <ProtectedRoute
      path="/admin/home/:companyID"
      component={CompanyUserInvPage}
    />
    <ProtectedRoute path="/admin/users/new" component={UserCreatePage} />
    <ProtectedRoute path="/admin/users/:userID" component={UserDetailPage} />
    <ProtectedRoute path="/admin/users" component={ClientUserInvPage} />
    <ProtectedRoute path="/admin/phases" component={PhaseForm} />
    <ProtectedRoute path="/admin/ethnicitys" component={EthnicityForm} />
    <ProtectedRoute
      path="/admin/customEmpCategories"
      component={CustomEmpCategoryForm}
    />
    <ProtectedRoute path="/admin/tags" component={TagPage} />
    <ProtectedRoute
      path="/admin/standardStatement"
      component={StandardStatementInventory}
    />
    <ProtectedRoute path="/admin/tiers" component={TierPage} />
    <ProtectedRoute path="/admin/projectTypes" component={ProjectTypesPage} />
    <ProtectedRoute path="/admin/clientGroups" component={ClientGroupForm} />
    <ProtectedRoute
      path="/admin/clientPositions"
      component={ClientPositionForm}
    />
    <ProtectedRoute
      path="/admin/diversityMonitoring"
      component={DiversityMonitoringForm}
    />
    <ProtectedRoute path="/admin/features" component={FeaturesForm} />
    <ProtectedRoute
      path="/admin/disabledFeature"
      component={DisabledFeaturePage}
    />
    <ProtectedRoute
      path="/admin/clientCurrency"
      component={ClientCurrencyTypeForm}
    />
    <ProtectedRoute path="/admin/clientCountry" component={ClientCountryForm} />
    <ProtectedRoute
      path="/admin/clientInteractionTypes"
      component={ClientInteractionTypeForm}
    />
    <ProtectedRoute
      path="/admin/clientCommitmentStatusTypes"
      component={ClientCommitmentStatusTypeForm}
    />
    <ProtectedRoute
      path="/admin/clientCommitmentSourceTypes"
      component={ClientCommitmentSourceTypeForm}
    />
    <ProtectedRoute
      path="/admin/clientCommitmentCategories"
      component={ClientCommitmentCategoryForm}
    />
    <ProtectedRoute
      path="/admin/clientOrganizationTypes"
      component={ClientOrganizationTypeForm}
    />
    <ProtectedRoute
      path="/admin/clientActionTypes"
      component={ClientActionTypeForm}
    />
    <ProtectedRoute
      path="/admin/clientCompanyStructures"
      component={ClientCompanyStructureForm}
    />
    <ProtectedRoute path="/admin/projects/new" component={ProjectCreatePage} />
    <ProtectedRoute
      path="/admin/projects/:projectID"
      component={ProjectDetailPage}
    />
    <ProtectedRoute path="/admin/projects" component={ProjectInventoryPage} />
    <ProtectedRoute
      path="/admin/scheduler/new"
      component={SchedulerCreatePage}
    />
    <ProtectedRoute
      path="/admin/scheduler/:schedulerID"
      component={SchedulerDetailPage}
    />
    <ProtectedRoute
      path="/admin/scheduler"
      component={SchedulerInventoryPage}
    />
    <ProtectedRoute
      path="/admin/actionreminder/new"
      component={ActionReminderCreatePage}
    />
    <ProtectedRoute
      path="/admin/actionreminder/:actionReminderID"
      component={ActionReminderDetailPage}
    />
    <ProtectedRoute
      path="/admin/actionreminder"
      component={ActionReminderInventoryPage}
    />
    <ProtectedRoute
      path="/admin/notificationTemplates/new"
      component={NotificationTemplateCreatePage}
    />
    <ProtectedRoute
      path="/admin/notificationTemplates/:notificationTemplateID"
      component={NotificationTemplateDetailPage}
    />
    <ProtectedRoute
      path="/admin/triggerednotificationsfeatures"
      component={TriggeredNotificationsFeaturesForm}
    />
    <ProtectedRoute
      path="/admin/notificationTemplates"
      component={NotificationTemplateInventoryPage}
    />
    <ProtectedRoute
      path="/admin/customNotification"
      component={CustomNotificationForm}
    />
    <ProtectedRoute path="/admin/branding" component={ClientBranding} />
    <ProtectedRoute path="/admin" component={AdminHomePage} />
    {/* Benefits Monitoring */}
    <ProtectedRoute
      path="/benefits/contracts/new"
      component={ContractCreatePage}
    />
    <ProtectedRoute
      path="/benefits/contracts/:contractID"
      component={ContractDetailPage}
    />
    <ProtectedRoute
      path="/benefits/contracts"
      component={ContractInventoryPage}
    />
    <ProtectedRoute
      path="/benefits/paymentPeriods/new"
      component={PaymentPeriodCreatePage}
    />
    <ProtectedRoute
      path="/benefits/paymentPeriods/:paymentPeriodID"
      component={PaymentPeriodDetailPage}
    />
    <ProtectedRoute
      path="/benefits/paymentPeriods"
      component={PaymentPeriodInventoryPage}
    />
    <ProtectedRoute
      path="/benefits/payments/new"
      component={PaymentCreatePage}
    />
    <ProtectedRoute
      path="/benefits/payments/upload"
      component={PaymentUploadPage}
    />
    <ProtectedRoute
      path="/benefits/payments/uploadReview/:paymentID"
      component={PaymentDetailPage}
    />
    <ProtectedRoute
      path="/benefits/payments/uploadReview"
      component={PaymentUploadReviewPage}
    />
    <ProtectedRoute
      path="/benefits/payments/:paymentID"
      component={PaymentDetailPage}
    />
    <ProtectedRoute
      path="/benefits/payments"
      component={PaymentInventoryPage}
    />
    <ProtectedRoute
      path="/benefits/employees/new"
      component={EmployeeCreatePage}
    />
    <ProtectedRoute
      path="/benefits/employees/upload"
      component={EmployeeUploadPage}
    />
    <ProtectedRoute
      path="/benefits/employees/uploadReview/:employeeID"
      component={EmployeeDetailPage}
    />
    <ProtectedRoute
      path="/benefits/employees/uploadReview"
      component={EmployeeReviewInventoryPage}
    />
    <ProtectedRoute
      path="/benefits/employees/:employeeID"
      component={EmployeeDetailPage}
    />
    <ProtectedRoute
      path="/benefits/employees"
      component={EmployeeInventoryPage}
    />
    <ProtectedRoute
      path="/benefits/communityinvestments/new"
      component={CommunityInvestmentCreatePage}
    />
    <ProtectedRoute
      path="/benefits/communityinvestments/:communityInvestmentID"
      component={CommunityInvestmentDetailPage}
    />
    <ProtectedRoute
      path="/benefits/communityinvestments"
      component={CommunityInvestmentInventoryPage}
    />
    <ProtectedRoute
      path="/benefits/initiatives/new"
      component={InitiativeCreatePage}
    />
    <ProtectedRoute
      path="/benefits/initiatives/:initiativeID"
      component={InitiativeDetailPage}
    />
    <ProtectedRoute
      path="/benefits/initiatives"
      component={InitiativeInventoryPage}
    />
    <ProtectedRoute
      path="/benefits/workhours/new"
      component={WorkHourPeriodCreatePage}
    />
    <ProtectedRoute
      path="/benefits/workhours/:periodID/upload"
      component={WorkHourUploadPage}
    />
    <ProtectedRoute
      path="/benefits/workhours/:periodID"
      component={WorkHourPeriodDetailPage}
    />
    <ProtectedRoute
      path="/benefits/workhours"
      component={WorkHourPeriodInventoryPage}
    />
    <ProtectedRoute
      path="/benefits/companies/review/:companyID"
      component={CompanyDetailPage}
    />
    <ProtectedRoute
      path="/benefits/companies/review"
      component={CompanyReviewInventoryPage}
    />
    <ProtectedRoute
      path="/benefits/companies/unconfirmed/:companyID"
      component={CompanyDetailPage}
    />
    <ProtectedRoute
      path="/benefits/companies/unconfirmed"
      component={CompanyRequestInventoryPage}
    />
    <ProtectedRoute
      path="/benefits/companies/new"
      component={CompanyCreatePage}
    />
    <ProtectedRoute
      path="/benefits/companies/merge/:originalID/:newID"
      component={CompanyMergePage}
    />
    <ProtectedRoute
      path="/benefits/companies/:companyID/users/new"
      component={UserCreatePage}
    />
    <ProtectedRoute
      path="/benefits/companies/:companyID/users/:userID"
      component={UserDetailPage}
    />
    <ProtectedRoute
      path="/benefits/companies/:companyID/users"
      component={CompanyUserInvPage}
    />

    <ProtectedRoute
      path="/benefits/companies/:companyID"
      component={CompanyDetailPage}
    />
    <ProtectedRoute
      path="/benefits/companies"
      component={CompanyInventoryPage}
    />
    <ProtectedRoute
      path="/benefits/rndexpenses/new"
      component={RnDExpenseCreatePage}
    />
    <ProtectedRoute
      path="/benefits/rndexpenses/:RnDExpenseID"
      component={RnDExpenseDetailPage}
    />
    <ProtectedRoute
      path="/benefits/rndexpenses"
      component={RnDExpenseInventoryPage}
    />
    <ProtectedRoute
      path="/benefits/customrnds/new"
      component={CustomRnDCreatePage}
    />
    <ProtectedRoute
      path="/benefits/customrnds/:CustomRnDID"
      component={CustomRnDDetailPage}
    />
    <ProtectedRoute
      path="/benefits/customrnds"
      component={CustomRnDInventoryPage}
    />
    <ProtectedRoute
      path="/benefits/entexpenses/new"
      component={EnTExpenseCreatePage}
    />
    <ProtectedRoute
      path="/benefits/entexpenses/:entExpenseID"
      component={EnTExpenseDetailPage}
    />
    <ProtectedRoute
      path="/benefits/entexpenses"
      component={EnTExpenseInventoryPage}
    />
    <ProtectedRoute path="/benefits/assets/new" component={AssetCreatePage} />
    <ProtectedRoute
      path="/benefits/assets/:assetID"
      component={AssetDetailPage}
    />
    <ProtectedRoute path="/benefits/assets" component={AssetInventoryPage} />
    <ProtectedRoute
      path="/benefits/educationtrainings/new"
      component={EducationTrainingCreatePage}
    />
    <ProtectedRoute
      path="/benefits/educationtrainings/:educationTrainingID"
      component={EducationTrainingDetailPage}
    />
    <ProtectedRoute
      path="/benefits/educationtrainings"
      component={EducationTrainingInventoryPage}
    />
    {/*---------------------- benefits cut off ------------------------- */}
    <ProtectedRoute path="/benefits/insights" component={VendorInsightsPage} />
    <ProtectedRoute path="/benefits" component={BenefitsHomePage} />

    {/*---------------------- SSHE ------------------------- */}
    <ProtectedRoute path="/sshe/sshe/new" component={SsheCreatePage} />
    <ProtectedRoute path="/sshe/sshe/:ssheID" component={SsheDetailPage} />
    <ProtectedRoute path="/sshe/sshe" component={SsheInventoryPage} />
    {/* <ProtectedRoute path="/sshe/sshe/drafts" component={PlatformHomePage} /> */}
    <ProtectedRoute
      path="/sshe/companies/review/:companyID"
      component={CompanyDetailPage}
    />
    <ProtectedRoute
      path="/sshe/companies/review"
      component={CompanyReviewInventoryPage}
    />
    <ProtectedRoute
      path="/sshe/companies/unconfirmed/:companyID"
      component={CompanyDetailPage}
    />
    <ProtectedRoute
      path="/sshe/companies/unconfirmed"
      component={CompanyRequestInventoryPage}
    />
    <ProtectedRoute path="/sshe/companies/new" component={CompanyCreatePage} />
    <ProtectedRoute
      path="/sshe/companies/merge/:originalID/:newID"
      component={CompanyMergePage}
    />
    <ProtectedRoute
      path="/sshe/companies/:companyID/users/new"
      component={UserCreatePage}
    />
    <ProtectedRoute
      path="/sshe/companies/:companyID/users/:userID"
      component={UserDetailPage}
    />
    <ProtectedRoute
      path="/sshe/companies/:companyID/users"
      component={CompanyUserInvPage}
    />

    <ProtectedRoute
      path="/sshe/companies/:companyID"
      component={CompanyDetailPage}
    />
    <ProtectedRoute path="/sshe/companies" component={CompanyInventoryPage} />
    <ProtectedRoute path="/sshe" component={SsheHomePage} />

    {/*---------------------- Commitments ------------------------- */}
    <ProtectedRoute
      path="/commitments/contacts/merge/:originalID/:newID"
      component={ContactMergePage}
    />
    <ProtectedRoute
      path="/commitments/contacts/new"
      component={ContactCreatePage}
    />
    <ProtectedRoute
      path="/commitments/contacts/upload"
      component={ContactUploadPage}
    />
    <ProtectedRoute
      path="/commitments/contacts/:contactID"
      component={ContactDetailPage}
    />
    <ProtectedRoute
      path="/commitments/contacts"
      component={ContactInventoryPage}
    />
    <ProtectedRoute
      path="/commitments/groups/merge/:originalID/:newID"
      component={StakeholderGroupMergePage}
    />
    <ProtectedRoute
      path="/commitments/groups/new"
      component={StakeholderGroupCreatePage}
    />
    <ProtectedRoute
      path="/commitments/groups/upload"
      component={StakeholderGroupUploadPage}
    />
    <ProtectedRoute
      path="/commitments/groups/:groupID"
      component={StakeholderGroupDetailPage}
    />
    <ProtectedRoute
      path="/commitments/groups"
      component={StakeholderGroupInventoryPage}
    />
    <ProtectedRoute
      path="/commitments/commitments/new"
      component={CommitmentCreatePage}
    />
    <ProtectedRoute
      path="/commitments/commitments/upload"
      component={CommitmentUploadPage}
    />
    <ProtectedRoute
      path="/commitments/commitments/:commitmentID"
      component={CommitmentDetailPage}
    />
    <ProtectedRoute
      path="/commitments/commitments"
      component={CommitmentInventoryPage}
    />
    <ProtectedRoute
      path="/commitments/actions/new"
      component={CommitmentActionCreatePage}
    />
    <ProtectedRoute
      path="/commitments/actions/:commitmentActionID"
      component={CommitmentActionDetailPage}
    />
    <ProtectedRoute
      path="/commitments/actions"
      component={CommitmentActionInventoryPage}
    />
    <ProtectedRoute
      path="/commitments/sources/new"
      component={CommitmentSourceCreatePage}
    />
    <ProtectedRoute
      path="/commitments/sources/:commitmentSourceID"
      component={CommitmentSourceDetailPage}
    />
    <ProtectedRoute
      path="/commitments/sources"
      component={CommitmentSourceInventoryPage}
    />
    <ProtectedRoute path="/commitments" component={CommitmentsHomePage} />
    {/* Stakeholder Consultation */}
    <ProtectedRoute
      path="/engagement/groups/new"
      component={StakeholderGroupCreatePage}
    />
    <ProtectedRoute
      path="/engagement/groups/merge/:originalID/:newID"
      component={StakeholderGroupMergePage}
    />
    <ProtectedRoute
      path="/engagement/groups/upload"
      component={StakeholderGroupUploadPage}
    />
    <ProtectedRoute
      path="/engagement/groups/:groupID"
      component={StakeholderGroupDetailPage}
    />
    <ProtectedRoute
      path="/engagement/groups"
      component={StakeholderGroupInventoryPage}
    />
    <ProtectedRoute
      path="/engagement/contacts/new"
      component={ContactCreatePage}
    />
    <ProtectedRoute
      path="/engagement/contacts/upload"
      component={ContactUploadPage}
    />
    <ProtectedRoute
      path="/engagement/contacts/merge/:originalID/:newID"
      component={ContactMergePage}
    />
    <ProtectedRoute
      path="/engagement/contacts/:contactID"
      component={ContactDetailPage}
    />
    <ProtectedRoute
      path="/engagement/contacts"
      component={ContactInventoryPage}
    />
    <ProtectedRoute
      path="/engagement/communications/:interactionID/actions/:interactionActionID"
      component={InteractionActionDetailPage}
    />
    <ProtectedRoute
      path="/engagement/communications/new"
      component={InteractionCreatePage}
    />
    <ProtectedRoute
      path="/engagement/communications/upload"
      component={InteractionUploadPage}
    />
    <ProtectedRoute
      path="/engagement/communications/review/:interactionID"
      component={InteractionDetailPage}
    />
    <ProtectedRoute
      path="/engagement/communications/review"
      component={InteractionReviewInventoryPage}
    />
    <ProtectedRoute
      path="/engagement/communications/:interactionID"
      component={InteractionDetailPage}
    />
    <ProtectedRoute
      path="/engagement/communications"
      component={InteractionInventoryPage}
    />
    <ProtectedRoute
      path="/engagement/communicationactions/:interactionActionID"
      component={InteractionActionDetailPage}
    />
    <ProtectedRoute
      path="/engagement/communicationthreads/new"
      component={ThreadCreatePage}
    />
    <ProtectedRoute
      path="/engagement/communicationthreads/:threadID"
      component={ThreadDetailPage}
    />
    <ProtectedRoute
      path="/engagement/communicationthreads"
      component={ThreadInventoryPage}
    />
    <ProtectedRoute
      path="/engagement/communicationactions"
      component={InteractionActionInventoryPage}
    />
    <ProtectedRoute
      path="/engagement/comments/:interactionID"
      component={InteractionDetailPage}
    />
    <ProtectedRoute
      path="/engagement/comments"
      component={InteractionCommentInventoryPage}
    />
    <ProtectedRoute path="/engagement/issues/new" component={IssueCreatePage} />
    <ProtectedRoute
      path="/engagement/issues/:issueID"
      component={IssueInventoryPage}
    />
    <ProtectedRoute path="/engagement/issues" component={IssueInventoryPage} />
    <ProtectedRoute
      path="/engagement/grievances/new"
      component={GrievanceCreatePage}
    />
    <ProtectedRoute
      path="/engagement/grievances/:grievanceID"
      component={GrievanceDetailPage}
    />
    <ProtectedRoute
      path="/engagement/grievances"
      component={GrievanceInventoryPage}
    />
    {/*---------suppliers-------------*/}
    <ProtectedRoute
      path="/suppliers/suppliers/new"
      component={SupplierCreatePage}
    />
    <ProtectedRoute
      path="/suppliers/suppliers/review/:supplierID"
      component={SupplierDetailPage}
    />
    <ProtectedRoute
      path="/suppliers/suppliers/review"
      component={SupplierReviewInventoryPage}
    />
    <ProtectedRoute
      path="/suppliers/suppliers/:supplierID"
      component={SupplierDetailPage}
    />
    <ProtectedRoute
      path="/suppliers/suppliers"
      component={SupplierInventoryPage}
    />
    <ProtectedRoute path="/suppliers" component={PlatformHomePage} />
    {/* <ProtectedRoute
      path="/engagement/logTables/stakeholder"
      component={StakeholderLogs}
    /> */}
    {/* <ProtectedRoute
      path="/engagement/logTables/engagement"
      component={EngagementLogs}
    /> */}
    {/* <ProtectedRoute
      path="/engagement/logTables/followup"
      component={FollowupLogs}
    /> */}
    {/* <ProtectedRoute
      path="/engagement/logtables/feedbackbygroup"
      component={FeedbackByGroup}
    /> */}
    {/* <ProtectedRoute
      path="/engagement/logtables/feedbackbystakeholder"
      component={FeedbackByStakeholder}
    /> */}
    {/* <ProtectedRoute
      path="/engagement/logtables/feedbackbyissue"
      component={FeedbackByIssue}
    /> */}
    <ProtectedRoute path="/engagement" component={StakeholderHomePage} />
    <ProtectedRoute path="/home" component={PlatformHomePage} />
    <ProtectedRoute path="/unauthorized" component={UnauthorizedUser} />
    <ProtectedRoute path="/" component={PlatformHomePage} />
  </Switch>
);

export default routes;
