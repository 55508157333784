import * as yup from "yup";
import { useTypedTranslation } from "utils/customHooks";

export const TierValidationSchema = () => {
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  return yup.object({
    TierName: yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.tiers.fields.namefull"),
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.tiers.fields.namefull"),
          count: 50,
        })
      ),
    TierRank: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.tiers.fields.rankfull"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.tiers.fields.rankfull"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.tiers.fields.rankfull"),
        })
      ),
    TierDescription: yup
      .string()
      .trim()
      .nullable()
      .max(
        250,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.tiers.fields.descriptionfull"),
          count: 250,
        })
      ),
  });
};
