import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { Grid, Paper, Typography } from "@mui/material";
import { ExpenditureChart } from "features/benefits/charts/ExpenditureChart";
import { WorkHourChart } from "features/benefits/charts/WorkHourChart";
import { PaymentsNeedingReviewForm } from "./charts/PaymentsNeedingReviewForm";
import { WorkHourReportingStatusForm } from "./charts/WorkHourReportingStatusForm";
import LoadingIndicator from "components/LoadingIndicator";
import { useTypedTranslation } from "utils/customHooks";
import { PaymentPeriodExceptionForm } from "./charts/PaymentPeriodExceptionForm";

export const AdminHomePage = () => {
  const t = useTypedTranslation(["strGen"]);
  const {
    ft_ben_SpendReport,
    ft_ben_WorkForce,
    isLoading: clientIsLoading,
  } = useSelector((state: RootState) => state.client);

  let adminHomePageForm = clientIsLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper>
            <Typography variant="h5" component="h1" sx={{ padding: "8px" }}>
              {t("strGen:pages.admin.insights")}{" "}
            </Typography>
          </Paper>
        </Grid>
        {ft_ben_WorkForce && (
          <Grid item xs={12} md={ft_ben_SpendReport ? 6 : 12}>
            <Paper>
              <WorkHourChart />
            </Paper>
          </Grid>
        )}
        {ft_ben_SpendReport && (
          <Grid item xs={12} md={ft_ben_WorkForce ? 6 : 12}>
            <Paper>
              <ExpenditureChart />
            </Paper>
          </Grid>
        )}
        {ft_ben_WorkForce && (
          <Grid item xs={12} md={ft_ben_SpendReport ? 6 : 12}>
            <Paper>
              <WorkHourReportingStatusForm />
            </Paper>
          </Grid>
        )}
        {ft_ben_SpendReport && (
          <Grid item xs={12} md={ft_ben_WorkForce ? 6 : 12}>
            <Paper>
              <PaymentPeriodExceptionForm instanceId="PmtPeriodExceptions" />
            </Paper>
          </Grid>
        )}
      </Grid>
    </div>
  );

  return <div id="admin-form">{adminHomePageForm}</div>;
};
