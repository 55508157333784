import { Edit, InfoOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import { createCustomAutoCompleteFilter } from "utils/customFilters";
import { useTypedTranslation } from "utils/customHooks";
import { markRequired } from "utils/functions";
import { defaultRenderOption } from "utils/renderFunctions";

interface IppYesNoAutocompleteProps {
  id: string;
  value: boolean | null;
  onChangeFunction: (event: React.ChangeEvent<object>, value: any) => void;
  label: string;
  required?: boolean;
  toolTip?: string;
  touchedExpression?: any;
  errorsExpression?: any;
  isEditing: boolean;
  setIsEditing: any;
}

export const IppYesNoAutocomplete = (props: IppYesNoAutocompleteProps) => {
  const {
    id,
    value,
    onChangeFunction,
    label,
    required,
    toolTip,
    touchedExpression,
    errorsExpression,
    isEditing,
    setIsEditing,
  } = props;
  const t = useTypedTranslation(["strGen"]);

  // set the tooltip to an empty string if not provided
  const setTooltip = toolTip ? toolTip : "";

  // create options for the autocomplete
  const options = [
    {
      label: t("strGen:components.ippyesnoautocomplete.fields.yes"),
      value: true,
    },
    {
      label: t("strGen:components.ippyesnoautocomplete.fields.no"),
      value: false,
    },
  ];

  const editChange = () => {
    setIsEditing(!isEditing);
  };

  // Custom filter logic - normalize input and compare to options
  const customFilter = createCustomAutoCompleteFilter((option) => option.label);

  return (
    <div>
      {isEditing ? (
        <Autocomplete
          sx={{ width: "100%" }}
          id={id}
          options={options}
          renderOption={(props: any, option: any) =>
            defaultRenderOption(props, option.label)
          }
          filterOptions={customFilter}
          value={options.find((option) => option.value === value)}
          onChange={onChangeFunction}
          autoHighlight={true}
          autoComplete={true}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) =>
            value ? option.value === value.value : false
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              required={required}
              error={touchedExpression && Boolean(errorsExpression)}
              helperText={touchedExpression && errorsExpression}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {setTooltip.length > 0 && (
                      <Tooltip title={setTooltip} placement="top">
                        <InfoOutlined fontSize="small" />
                      </Tooltip>
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      ) : (
        <TextField
          id={id}
          name={id}
          label={markRequired(label, required)}
          value={
            value
              ? t("strGen:components.ippyesnoautocomplete.fields.yes")
              : t("strGen:components.ippyesnoautocomplete.fields.no")
          }
          //required={required}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                {setTooltip.length > 0 ? (
                  <Tooltip title={setTooltip} placement="top">
                    <InfoOutlined fontSize="small" style={{ marginTop: -3 }} />
                  </Tooltip>
                ) : (
                  ""
                )}
                <IconButton onClick={editChange} size="small">
                  <Edit fontSize="inherit" />
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ shrink: true }}
        />
      )}
    </div>
  );
};
