import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  RnDExpense,
  RnDExpensesResult,
  getRnDExpenses,
  getRnDExpensesByInitiative,
  createRnDExpense,
  updateRnDExpense,
  deleteRnDExpense,
} from "api/benefits/RnDExpenseAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { fetchInitiatives } from "../initiatives/InitiativeSlice";

interface RnDExpenseState {
  RnDExpensesById: Record<number, RnDExpense>;
  RnDExpenseList: number[];
  isLoading: boolean;
  isLoadingAdd: boolean;
  isLoaded: boolean;
  error: string | null;
  errorList: any[] | null;
}

const RnDExpenseInitialState: RnDExpenseState = {
  RnDExpensesById: {},
  RnDExpenseList: [],
  isLoading: false,
  isLoadingAdd: false,
  isLoaded: false,
  error: null,
  errorList: null,
};

function startLoading(state: RnDExpenseState) {
  state.isLoading = true;
}

function startLoadingAdd(state: RnDExpenseState) {
  state.isLoadingAdd = true;
}

function loadingFailed(state: RnDExpenseState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const RnDExpenses = createSlice({
  name: "RnDExpenses",
  initialState: RnDExpenseInitialState,
  reducers: {
    getRnDExpensesStart: startLoading,
    getRnDExpensesSuccess(
      state,
      { payload }: PayloadAction<RnDExpensesResult>
    ) {
      const { RnDExpenses } = payload;

      state.isLoading = false;
      state.isLoaded = true;
      state.error = null;
      state.errorList = null;

      RnDExpenses.forEach((RnDExpense) => {
        state.RnDExpensesById[RnDExpense.RnDExpenseID] = RnDExpense;
      });

      state.RnDExpenseList = RnDExpenses.map(
        (RnDExpense) => RnDExpense.RnDExpenseID
      );
    },
    getRnDExpensesFailure: loadingFailed,
    createRnDExpenseStart: startLoadingAdd,
    createRnDExpenseSuccess(state, { payload }: PayloadAction<RnDExpense>) {
      const { RnDExpenseID } = payload;

      state.RnDExpensesById[RnDExpenseID] = payload;
      state.RnDExpenseList.push(RnDExpenseID);

      state.isLoading = false;
      state.isLoadingAdd = false;
      state.error = null;
      state.errorList = null;
    },
    updateRnDExpenseSuccess(state, { payload }: PayloadAction<RnDExpense>) {
      const { RnDExpenseID } = payload;

      state.RnDExpensesById[RnDExpenseID] = payload;

      state.isLoading = false;
      state.error = null;
      state.errorList = null;
    },
    deleteRnDExpenseSuccess(state, { payload }: PayloadAction<number>) {
      const RnDExpenseID = payload;
      delete state.RnDExpensesById[RnDExpenseID];

      state.RnDExpenseList = state.RnDExpenseList.filter(
        (id) => id !== RnDExpenseID
      );

      state.isLoading = false;
      state.error = null;
      state.errorList = null;
    },
    createRnDExpenseFailure: loadingFailed,
  },
});

export const {
  getRnDExpensesStart,
  getRnDExpensesSuccess,
  getRnDExpensesFailure,
  createRnDExpenseStart,
  createRnDExpenseSuccess,
  updateRnDExpenseSuccess,
  deleteRnDExpenseSuccess,
  createRnDExpenseFailure,
} = RnDExpenses.actions;

export default RnDExpenses.reducer;

export const fetchRnDExpenses =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getRnDExpensesStart());
      const RnDExpenses = await getRnDExpenses(accessToken);
      dispatch(getRnDExpensesSuccess(RnDExpenses));
    } catch (err: any) {
      dispatch(getRnDExpensesFailure(err.toString()));
    }
  };

export const fetchRnDExpensesByInitiative =
  (accessToken: String, initiativeID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getRnDExpensesStart());
      const expenses = await getRnDExpensesByInitiative(
        accessToken,
        initiativeID
      );
      dispatch(getRnDExpensesSuccess(expenses));
    } catch (err: any) {
      dispatch(getRnDExpensesFailure(err.toString()));
    }
  };

export const addRnDExpense =
  (
    accessToken: String,
    newRnDExpense: Partial<RnDExpense>,
    setReturnRoute: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createRnDExpenseStart());
      const RnDExpense = await createRnDExpense(accessToken, newRnDExpense);
      dispatch(createRnDExpenseSuccess(RnDExpense));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      // If parent, return to R&D expense inventory page. If child, stay on current Initiative
      if (setReturnRoute) {
        dispatch(push("/benefits/rndexpenses"));
      } else {
        dispatch(push(`/benefits/initiatives/${RnDExpense.InitiativeID}`));
      }
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(openSnackBar(error.response.data.message, "error"));
        dispatch(createRnDExpenseFailure(error.response.data));
      } else {
        dispatch(createRnDExpenseFailure(error.toString()));
        dispatch(openSnackBar(Constants.FAILED, "error"));
      }
    }
  };

export const updRnDExpense =
  (
    accessToken: String,
    RnDExpenseID: number,
    newRnDExpense: Partial<RnDExpense>,
    setReturnRoute: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createRnDExpenseStart());
      const RnDExpense = await updateRnDExpense(
        accessToken,
        RnDExpenseID,
        newRnDExpense
      );

      dispatch(updateRnDExpenseSuccess(RnDExpense));
      dispatch(fetchRnDExpenses(accessToken));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      // If parent, stay on current R&D Expense. If child, stay on current Initiative
      if (setReturnRoute) {
        dispatch(push("/benefits/rndexpenses/" + RnDExpenseID));
      } else {
        dispatch(push(`/benefits/initiatives/${RnDExpense.InitiativeID}`));
      }
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(createRnDExpenseFailure(error.response.data));
        dispatch(openSnackBar(error.response.data.message, "error"));
      } else {
        dispatch(createRnDExpenseFailure(error.toString()));
        dispatch(openSnackBar(Constants.FAILED, "error"));
      }
    }
  };

export const delRnDExpense =
  (
    accessToken: String,
    RnDExpenseID: number,
    setReturnRoute: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createRnDExpenseStart());
      const result = await deleteRnDExpense(accessToken, RnDExpenseID);

      dispatch(deleteRnDExpenseSuccess(RnDExpenseID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
      // If parent, return to R&D Expense inventory page. If child, stay on current Initiative & refresh form to update total expense field
      if (setReturnRoute) {
        dispatch(push("/benefits/rndexpenses"));
      } else {
        dispatch(fetchInitiatives(accessToken));
      }
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(createRnDExpenseFailure(error.response.data));
        dispatch(openSnackBar(error.response.data.message, "error"));
      } else {
        dispatch(createRnDExpenseFailure(error.toString()));
        dispatch(openSnackBar(Constants.FAILED, "error"));
      }
    }
  };
