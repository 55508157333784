import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ContactIssue,
  ContactIssuesResult,
  getContactIssuesByIssue,
  getContactIssuesByContact,
  deleteContactIssue,
} from "api/stakeholder/contactIssueAPI";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { SnackBarConstants } from "utils/customHooks";

interface ContactIssueState {
  contactIssuesById: Record<number, ContactIssue>;
  contactIssueList: number[];
  isLoading: boolean;
  subContactIssueisLoading: boolean;
  error: string | null;
}

const ContactIssueInitialState: ContactIssueState = {
  contactIssuesById: {},
  contactIssueList: [],
  isLoading: false,
  subContactIssueisLoading: false,
  error: null,
};

function startLoading(state: ContactIssueState) {
  state.isLoading = true;
}

function loadingFailed(
  state: ContactIssueState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const contactIssues = createSlice({
  name: "contactIssues",
  initialState: ContactIssueInitialState,
  reducers: {
    getContactIssuesStart: startLoading,
    getContactIssuesSuccess(
      state,
      { payload }: PayloadAction<ContactIssuesResult>
    ) {
      const { contactIssues } = payload;

      state.isLoading = false;
      state.error = null;

      contactIssues.forEach((contactIssue) => {
        state.contactIssuesById[contactIssue.ContactIssueID] = contactIssue;
      });

      state.contactIssueList = contactIssues.map(
        (contactIssue) => contactIssue.ContactIssueID
      );
    },
    getContactIssuesFailure: loadingFailed,
    createContactIssueStart: startLoading,
    createContactIssueSuccess(state, { payload }: PayloadAction<ContactIssue>) {
      const { ContactIssueID } = payload;
      state.contactIssuesById[ContactIssueID] = payload;
      state.contactIssueList.push(ContactIssueID);

      state.isLoading = false;
      state.error = null;
    },
    updateContactIssueSuccess(state, { payload }: PayloadAction<ContactIssue>) {
      const { ContactIssueID } = payload;

      state.contactIssuesById[ContactIssueID] = payload;

      state.isLoading = false;
      state.error = null;
    },
    deleteContactIssueSuccess(state, { payload }: PayloadAction<number>) {
      const ContactIssueID = payload;
      delete state.contactIssuesById[ContactIssueID];
      state.contactIssueList = state.contactIssueList.filter(
        (item) => item !== ContactIssueID
      );

      state.isLoading = false;
      state.error = null;
    },
    createContactIssueFailure: loadingFailed,
  },
});

export const {
  getContactIssuesStart,
  getContactIssuesSuccess,
  getContactIssuesFailure,
  createContactIssueStart,
  createContactIssueSuccess,
  updateContactIssueSuccess,
  deleteContactIssueSuccess,
  createContactIssueFailure,
} = contactIssues.actions;

export default contactIssues.reducer;

// NOT BEING USED

// export const fetchContactIssues =
//   (accessToken: String): AppThunk =>
//   async (dispatch) => {
//     try {
//       dispatch(getContactIssuesStart());
//       const contactIssues = await getContactIssues(accessToken);
//       dispatch(getContactIssuesSuccess(contactIssues));
//     } catch (err: any) {
//       dispatch(getContactIssuesFailure(err.toString()));
//     }
//   };

export const fetchContactIssuesByIssue =
  (accessToken: String, issueID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getContactIssuesStart());
      const contactIssues = await getContactIssuesByIssue(accessToken, issueID);
      dispatch(getContactIssuesSuccess(contactIssues));
    } catch (err: any) {
      dispatch(getContactIssuesFailure(err.toString()));
    }
  };

export const fetchContactIssuesByContact =
  (accessToken: String, contactID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getContactIssuesStart());
      const contactIssues = await getContactIssuesByContact(
        accessToken,
        contactID
      );
      dispatch(getContactIssuesSuccess(contactIssues));
    } catch (err: any) {
      dispatch(getContactIssuesFailure(err.toString()));
    }
  };

export const delContactIssue =
  (
    accessToken: String,
    contactIssueID: number,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createContactIssueStart());
      const result = await deleteContactIssue(accessToken, contactIssueID);
      dispatch(deleteContactIssueSuccess(contactIssueID));
      dispatch(openSnackBar(snackbarConstants.DELETE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createContactIssueFailure(err.toString()));
      dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
    }
  };
