import { Add } from "@mui/icons-material";
import { ButtonProps } from "@mui/material/Button";
import { IppButton, IppButtonProps } from "components/Buttons/IppButton";
import { useTypedTranslation } from "utils/customHooks";

interface IppAddButtonProps extends IppButtonProps {
  buttonText?: string;
  color?: ButtonProps["color"];
  startIcon?: React.ReactNode;
}

export const IppAddButton = ({
  buttonText,
  color = "secondary",
  startIcon = <Add />,
  ...props
}: IppAddButtonProps) => {
  const t = useTypedTranslation(["strGen"]);
  const T_buttonText = buttonText || t("strGen:buttons.addnew");

  return (
    <IppButton color={color} startIcon={startIcon} {...props}>
      {T_buttonText}
    </IppButton>
  );
};
