import axiosClient from "../axiosClient";

export interface Asset {
  AssetID: number;
  ClientID: number;
  AssetName: string;
  OwnerPercentage: number;
  AssetDetails: string;
}

export interface AssetsResult {
  assets: Asset[];
}

export const getAssets = async (accessToken: String): Promise<AssetsResult> => {
  const url = `/asset`;
  try {
    const { data } = await axiosClient.get<AssetsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createAsset = async (
  accessToken: String,
  asset: Partial<Asset>
): Promise<Asset> => {
  const url = `/asset`;

  try {
    const { data } = await axiosClient.post<Asset>(url, asset, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateAsset = async (
  accessToken: String,
  assetID: number,
  asset: Asset
): Promise<Asset> => {
  const url = `/asset/${assetID}`;

  try {
    const { data } = await axiosClient.post<Asset>(url, asset, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteAsset = async (
  accessToken: String,
  assetID: number
): Promise<void> => {
  const url = `/asset/${assetID}`;

  try {
    await axiosClient.delete(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};
