import { RootState } from "app/rootReducer";
import { useSelector } from "react-redux";
import { useTypedTranslation } from "utils/customHooks";

const PaymentChildUploadGuide = ({
  closeModalGuide,
  ft_ben_Region,
  ft_ben_BusinessCategory,
  ft_ben_Tier,
  ft_ben_ActivityLocation,
  dm_Disability,
  dm_LGBTQ,
  dm_Local,
  dm_VeteranOwned,
  dm_VisibleMinority,
  dm_WomanOwned,
  dm_NorthernResident,
}: {
  closeModalGuide: any;
  ft_ben_Region: boolean;
  ft_ben_BusinessCategory: boolean;
  ft_ben_Tier: boolean;
  ft_ben_ActivityLocation: boolean;
  dm_Disability: boolean;
  dm_LGBTQ: boolean;
  dm_Local: boolean;
  dm_VeteranOwned: boolean;
  dm_VisibleMinority: boolean;
  dm_WomanOwned: boolean;
  dm_NorthernResident: boolean;
}) => {
  const t = useTypedTranslation(["objBen", "objSlr", "objPlt", "strGen"]);
  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const columns = [
    {
      value: "Spend Date",
      description: "The date the Spend was created (YYYY-MM-DD)",
    },
    {
      value: "Amount",
      description:
        "The total amount of the Spend. This field cannot be left blank or have 0 as a value.",
    },
    {
      value: "Company Name",
      description: "Name of Company",
    },
    ft_ben_Region && {
      value: "Region",
      description: "Region where the Company is located",
    },
    ft_ben_ActivityLocation && {
      value: "Activity Location",
      description: "The Activity Location of the Company",
    },
    ft_ben_Tier && {
      value: "Tier",
      description: "Teir level of the Company (ie Tier One)",
    },
    currentProfile.IsClient && {
      value: "Vendor ID",
      description: "If applicable, enter the Vendor ID for the Company.",
    },
    ft_ben_BusinessCategory && {
      value: "Category of Business",
      description:
        "A category the Company is related to (ie Educational Services)",
    },
    {
      value: "Address",
      description: "Street address of the Company",
    },
    {
      value: "Town/City",
      description: "Town or City of the Company",
    },
    {
      value: "Province/State",
      description: "Province or State of the Company",
    },
    { value: "Country", description: "Country of the Company" },
    {
      value: "Postal Code",
      description: "Postal Code of the Company",
    },
    {
      value: t("objPlt:platformwide.indigenous.indigenousgroup"),
      description: `${t(
        "objPlt:platformwide.indigenous.indigenousgroup"
      )} affiliated with the Company - if this field is left blank, the Company profile will keep the current indication.`,
    },
    {
      value: "Ownership Structure",
      description: `The structure of ownership in relation to ${t(
        "objPlt:platformwide.indigenous.indigenousgroup"
      )} if selected.`,
    },
    dm_WomanOwned && {
      value: "Woman-Owned",
      description:
        "Indicates if the Company is owned by a woman (ie YES). If this field is left blank, the Company profile will keep the current indication.",
    },
    dm_VeteranOwned && {
      value: "Veteran-Owned",
      description:
        "Indicates if the Company is owned by a Veteran (ie YES). If this field is left blank, the Company profile will keep the current indication.",
    },
    dm_NorthernResident && {
      value: "Owned by Northern Resident",
      description:
        "Indicates if the Company is owned by a Northern Resident (ie YES). If this field is left blank, the Company profile will keep the current indication.",
    },
    dm_Disability && {
      value: "Owned by person(s) with Disability",
      description:
        "Indicates if the Company is owned by a person with disability (ie NO). If this field is left blank, the Company profile will keep the current indication.",
    },
    dm_VisibleMinority && {
      value: "Owned by member(s) of Visible Minority",
      description:
        "Indicates if the Company is owned by a member of Visible Minority (ie YES). If this field is left blank, the Company profile will keep the current indication.",
    },
    dm_LGBTQ && {
      value: "LGBTQ+ Owned",
      description:
        "Indicates if the Company is owned by a member of the LGBTQ+ community (ie YES). If this field is left blank, the Company profile will keep the current indication.",
    },
    ft_ben_Region &&
      dm_Local && {
        value: "Local",
        description: "Indicates if the Company is local (ie YES)",
      },
  ].filter(Boolean) as { value: string; description: string }[];

  return (
    <div className="modal-overlay">
      <div className="modal">
        <span className="close" onClick={closeModalGuide}>
          &times;
        </span>
        <h2>Upload Spend</h2>
        <p>
          Spend data is uploaded to the platform using an Excel spreadsheet and
          must be in Excel format (.xlsx) <br />
          Refer to the guide below to correctly fill out your spreadsheet.
        </p>
        <div className="guide-table">
          <div className="guide-row">
            <div className="column-header">Column</div>
            <div className="value-header">Value</div>
            <div className="description-header">Description</div>
          </div>
          <div className="scrollable-content">
            {columns.map(({ value, description }, index) => (
              <div className="guide-row" key={index}>
                <div className="guide-cell">
                  {String.fromCharCode(65 + index)}
                </div>
                <div className="guide-cell value-cell">{value}</div>
                <div className="guide-cell description-cell">
                  {value === "Company Name" && !currentProfile.IsClient
                    ? "Name of Company - if it does not exist on the platform, a new Company request will be created."
                    : description}
                </div>
              </div>
            ))}
          </div>
          <strong>
            <p>
              The following fields are required: Spend Date, Amount, Company
              Name, Town/City
              {ft_ben_Region && ", and Region."}.
            </p>
          </strong>
        </div>
      </div>
    </div>
  );
};

export default PaymentChildUploadGuide;
