import axiosClient from "./axiosClient";

export interface CompanyProject {
  CompanyProjectID: number;
  CompanyID: number;
  ProjectID: number;
  ActiveForPayment: boolean;
  ActiveForEmployee: boolean;
  CompanyName: string;
  ProjectName: string;
  ActiveUsers?: number;
}

export interface CompanyProjectsResult {
  companyProjects: CompanyProject[];
}

export async function getCompanyProjects(
  accessToken: String
): Promise<CompanyProjectsResult> {
  const url = `/companyProject`;

  try {
    const { data } = await axiosClient.get<CompanyProjectsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getCompanyProjectsByCompany(
  accessToken: String,
  companyID: number
): Promise<CompanyProjectsResult> {
  const url = `/company/` + companyID + "/projects";

  try {
    const { data } = await axiosClient.get<CompanyProjectsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getCompanyProjectsByProject(
  accessToken: String,
  projectID: number
): Promise<CompanyProjectsResult> {
  const url = `/project/` + projectID + "/companies";

  try {
    const { data } = await axiosClient.get<CompanyProjectsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createCompanyProject(
  accessToken: String,
  newCompanyProject: Partial<CompanyProject>
): Promise<CompanyProject> {
  const url = `/CompanyProject`;

  try {
    const { data } = await axiosClient.post<CompanyProject>(
      url,
      newCompanyProject,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateCompanyProject(
  accessToken: String,
  CompanyProjectID: number,
  newCompanyProject: Partial<CompanyProject>
): Promise<CompanyProject> {
  const url = `/CompanyProject/` + CompanyProjectID;

  try {
    const { data } = await axiosClient.post<CompanyProject>(
      url,
      newCompanyProject,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteCompanyProject(
  accessToken: String,
  CompanyProjectID: number
): Promise<string> {
  const url = `/CompanyProject/` + CompanyProjectID;
  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    //console.log("DELDATA", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}
