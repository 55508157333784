import axiosClient from "api/axiosClient";

export interface CustomRnD {
  CustomRnDID: number;
  ProjectID: number;
  ProjectName: string;
  RnDProjectName: string;
  CreatedBy: number;
  LastUpdatedBy: number;
  Description: string;
  ClaimYear: Date;
  Date: Date;
  Expenditure: number;
  Classification: string;
  Prequalification: string;
  CompanyID: number;
  CompanyName: string;
  ClientID: number;
}
export interface CustomRnDsResult {
  customRnDs: CustomRnD[];
}

export async function getAllCustomRnDs(
  accessToken: String
): Promise<CustomRnDsResult> {
  const url = `/customRnD`;

  try {
    const { data } = await axiosClient.get<CustomRnDsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createCustomRnD(
  accessToken: String,
  newCustomRnD: Partial<CustomRnD>
): Promise<CustomRnD> {
  const url = `/customRnD`;
  try {
    const { data } = await axiosClient.post<CustomRnD>(url, newCustomRnD, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateCustomRnD(
  accessToken: String,
  customRnDID: number,
  newCustomRnD: Partial<CustomRnD>
): Promise<CustomRnD> {
  const url = `/customRnD/` + customRnDID;
  try {
    const { data } = await axiosClient.post<CustomRnD>(url, newCustomRnD, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteCustomRnD(
  accessToken: String,
  customRnDID: number
): Promise<string> {
  const url = `/customRnD/` + customRnDID;
  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}
