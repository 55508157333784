import { Grid, Typography, IconButton, Box } from "@mui/material";
import { BackspaceOutlined } from "@mui/icons-material";
import { ChangeEvent, useEffect, useState } from "react";
import { IppAutocomplete } from "./IppAutocomplete";
import { markRequired } from "utils/functions";
import { FormikTouched } from "formik";
import { IppCurrencyField } from "./IppCurrencyField";
import { useTypedTranslation } from "utils/customHooks";
import { IppButton } from "./Buttons/IppButton";
import { classifications } from "features/datalists/Classification";

interface IppExpenseProps {
  id: string; //not being  used
  options?: string[];
  value: string;
  onChangeFunction: (value: any) => void;
  label: string;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  touchedFunction?:
    | boolean
    | FormikTouched<any>
    | FormikTouched<any>[]
    | undefined;
  required?: boolean;
}

interface RnDSpend {
  classification: string;
  amount: number;
}

export const IppRnDClassifications = (props: IppExpenseProps) => {
  const t = useTypedTranslation(["objBen"]);

  const {
    onChangeFunction,
    label,
    isEditing,
    setIsEditing,
    touchedFunction,
    required,
  } = props;

  const [rnDSpends, setRnDSpends] = useState<RnDSpend[]>([
    { classification: "", amount: 0 },
  ]);

  const handleRnDSpends = () => {
    const newValue = [...rnDSpends, { classification: "", amount: 0 }];
    setRnDSpends(newValue);
  };

  const handleRemoveRnDSpend = (index: number) => {
    const updatedRnDSpends = rnDSpends.filter((_, idx) => idx !== index);
    setRnDSpends(updatedRnDSpends);
  };

  useEffect(() => {
    onChangeFunction(rnDSpends);
  }, [rnDSpends]);

  const validateField = (field: "classification" | "amount", index: number) => {
    const rnDSpend = rnDSpends[index];
    const { classification, amount } = rnDSpend;

    // const isSingleRnDSpend = rnDSpends.length === 1;
    // const isMultipleRnDSpends = rnDSpends.length > 1;
    // if not editing, no need to validate
    if (!isEditing) return null;

    if (
      field === "classification" &&
      !classification
      //  && amount !== 0
    ) {
      return t(
        "strGen:components.ipprndclassification.validation.provideclassificationforamount",
        { amount }
      );
    }

    //! Potential for future clean up -- removed the following validations as they are not needed for the current implementation if we will allow amount to be 0 or negative

    // if (field === "amount" && amount < 0) {
    //   return t(
    //     "strGen:components.ipprndclassification.validation.amountcannotbenegative"
    //   );
    // }

    // if (isSingleRnDSpend && !classification && amount === 0) {
    //   return field === "classification"
    //     ? t(
    //         "strGen:components.ipprndclassification.validation.atleastoneclassification"
    //       )
    //     : t(
    //         "strGen:components.ipprndclassification.validation.amountcannotbezero"
    //       );
    // }

    // if (isMultipleRnDSpends && !classification && amount === 0) {
    //   return t(
    //     "strGen:components.ipprndclassification.validation.provideamountandclassification"
    //   );
    // }

    // if (field === "amount" && amount === 0 && classification) {
    //   return t(
    //     "strGen:components.ipprndclassification.validation.provideamountforclassification",
    //     { classification }
    //   );
    // }

    return null;
  };

  //console.log(rnDSpends, "rnDSpends");

  return (
    <>
      <Grid item xs={6} marginY={2}>
        <Typography>{label}</Typography>
        <Typography display={"block"} borderColor={"primary"}>
          {t("objBen:objects.rndExpense.currenttotal", {
            total: rnDSpends
              .reduce((acc: any, item: { amount: any }) => acc + item.amount, 0)
              .toLocaleString("en-US"),
          })}
        </Typography>
      </Grid>
      {rnDSpends.map((rnDSpend, index) => (
        <Grid container spacing={2} key={index}>
          <Grid item xs={5.5}>
            <IppAutocomplete
              id={`RnDSpend.${index}.classification`}
              options={classifications}
              value={rnDSpend.classification}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  let data = [...rnDSpends];
                  data[index]["classification"] = newValue;
                  setRnDSpends(data);
                }
              }}
              touchedFunction={touchedFunction}
              errorFunction={validateField("classification", index)}
              label={markRequired(
                t("objBen:objects.rndExpense.fields.classification_other"),
                required
              )}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              toolTip={t(
                "objBen:objects.rndExpense.tooltips.expenseclassification"
              )}
            />
          </Grid>
          <Grid item xs={5.5}>
            <IppCurrencyField
              id={`RnDSpend.${index}.amount`}
              label={t("objBen:objects.rndExpense.fields.expenseamount")}
              value={rnDSpends[index].amount}
              onChangeFunction={(newValue) => {
                let data = [...rnDSpends];
                data[index]["amount"] = Number(newValue);
                setRnDSpends(data);
              }}
              touchedExpression={touchedFunction}
              errorsExpression={validateField("amount", index)}
              isEditing={isEditing}
            />
          </Grid>
          <Grid item xs={1} alignSelf={"center"}>
            <IconButton
              color="error"
              onClick={() => handleRemoveRnDSpend(index)}
              disabled={rnDSpends.length > 1 ? false : true}
              size="large"
            >
              <BackspaceOutlined />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <IppButton
          variant="outlined"
          color="primary"
          onClick={() => handleRnDSpends()}
          disabled={!isEditing}
          noTimeout
        >
          {t("strGen:buttons.addobj", {
            objectname: t("objBen:objects.rndExpense.expense"),
          })}
        </IppButton>
      </Box>
    </>
  );
};
