import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ClientCommitmentStatusType,
  ClientCommitmentStatusTypesResult,
  getClientCommitmentStatusTypes,
  createClientCommitmentStatusType,
  updateClientCommitmentStatusType,
  deleteClientCommitmentStatusType,
} from "api/clientCommitmentStatusTypeAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface ClientCommitmentStatusTypeState {
  clientCommitmentStatusTypesById: Record<number, ClientCommitmentStatusType>;
  clientCommitmentStatusTypeList: number[];
  isLoading: boolean;
  error: string | null;
}

const ClientCommitmentStatusTypeInitialState: ClientCommitmentStatusTypeState =
  {
    clientCommitmentStatusTypesById: {},
    clientCommitmentStatusTypeList: [],
    isLoading: false,
    error: null,
  };

function startLoading(state: ClientCommitmentStatusTypeState) {
  state.isLoading = true;
}

function loadingFailed(
  state: ClientCommitmentStatusTypeState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const clientCommitmentStatusTypes = createSlice({
  name: "clientCommitmentStatusTypes",
  initialState: ClientCommitmentStatusTypeInitialState,
  reducers: {
    getClientCommitmentStatusTypesStart: startLoading,
    getClientCommitmentStatusTypesSuccess(
      state,
      { payload }: PayloadAction<ClientCommitmentStatusTypesResult>
    ) {
      const { clientCommitmentStatusTypes } = payload;
      // console.log("PAYLOAD", clientCommitmentStatusTypes);
      state.isLoading = false;
      state.error = null;

      clientCommitmentStatusTypes.forEach((clientCommitmentStatusType) => {
        state.clientCommitmentStatusTypesById[
          clientCommitmentStatusType.ClientCommitmentStatusTypeID
        ] = clientCommitmentStatusType;
      });

      state.clientCommitmentStatusTypeList = clientCommitmentStatusTypes.map(
        (clientCommitmentStatusType) =>
          clientCommitmentStatusType.ClientCommitmentStatusTypeID
      );
    },
    getClientCommitmentStatusTypesFailure: loadingFailed,
    createClientCommitmentStatusTypeStart: startLoading,
    createClientCommitmentStatusTypeSuccess(
      state,
      { payload }: PayloadAction<ClientCommitmentStatusType>
    ) {
      const { ClientCommitmentStatusTypeID } = payload;
      state.clientCommitmentStatusTypesById[ClientCommitmentStatusTypeID] =
        payload;
      state.clientCommitmentStatusTypeList.push(ClientCommitmentStatusTypeID);

      state.isLoading = false;
      state.error = null;
    },
    updateClientCommitmentStatusTypeSuccess(
      state,
      { payload }: PayloadAction<ClientCommitmentStatusType>
    ) {
      const { ClientCommitmentStatusTypeID } = payload;
      state.clientCommitmentStatusTypesById[ClientCommitmentStatusTypeID] =
        payload;

      state.isLoading = false;
      state.error = null;
    },
    deleteClientCommitmentStatusTypeSuccess(
      state,
      { payload }: PayloadAction<number>
    ) {
      const ClientCommitmentStatusTypeID = payload;
      delete state.clientCommitmentStatusTypesById[
        ClientCommitmentStatusTypeID
      ];
      state.clientCommitmentStatusTypeList =
        state.clientCommitmentStatusTypeList.filter(
          (item) => item !== ClientCommitmentStatusTypeID
        );

      state.isLoading = false;
      state.error = null;
    },
    createClientCommitmentStatusTypeFailure: loadingFailed,
  },
});

export const {
  getClientCommitmentStatusTypesStart,
  getClientCommitmentStatusTypesSuccess,
  getClientCommitmentStatusTypesFailure,
  createClientCommitmentStatusTypeStart,
  createClientCommitmentStatusTypeSuccess,
  updateClientCommitmentStatusTypeSuccess,
  deleteClientCommitmentStatusTypeSuccess,
  createClientCommitmentStatusTypeFailure,
} = clientCommitmentStatusTypes.actions;

export default clientCommitmentStatusTypes.reducer;

export const fetchClientCommitmentStatusTypes =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getClientCommitmentStatusTypesStart());
      const clientCommitmentStatusTypes = await getClientCommitmentStatusTypes(
        accessToken
      );
      // console.log("RETURN", clientCommitmentStatusTypes);
      dispatch(
        getClientCommitmentStatusTypesSuccess(clientCommitmentStatusTypes)
      );
    } catch (err: any) {
      dispatch(getClientCommitmentStatusTypesFailure(err.toString()));
    }
  };

export const addClientCommitmentStatusType =
  (
    accessToken: String,
    newClientCommitmentStatusType: Partial<ClientCommitmentStatusType>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientCommitmentStatusTypeStart());
      const clientCommitmentStatusType = await createClientCommitmentStatusType(
        accessToken,
        newClientCommitmentStatusType
      );
      dispatch(
        createClientCommitmentStatusTypeSuccess(clientCommitmentStatusType)
      );
      dispatch(fetchClientCommitmentStatusTypes(accessToken));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push("/admin/clientCommitmentStatusTypes"));
      // dispatch(push("/clientCommitmentStatusTypes/new"));
    } catch (err: any) {
      dispatch(createClientCommitmentStatusTypeFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updClientCommitmentStatusType =
  (
    accessToken: String,
    clientCommitmentStatusTypeID: number,
    newClientCommitmentStatusType: Partial<ClientCommitmentStatusType>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientCommitmentStatusTypeStart());
      const clientCommitmentStatusType = await updateClientCommitmentStatusType(
        accessToken,
        clientCommitmentStatusTypeID,
        newClientCommitmentStatusType
      );
      //console.log("RETURN UPDATE", clientCommitmentStatusTypes);
      dispatch(
        updateClientCommitmentStatusTypeSuccess(clientCommitmentStatusType)
      );
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      // dispatch(push("/clientCommitmentStatusTypes/" + clientCommitmentStatusTypeID));
    } catch (err: any) {
      dispatch(createClientCommitmentStatusTypeFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delClientCommitmentStatusType =
  (accessToken: String, clientCommitmentStatusTypeID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientCommitmentStatusTypeStart());
      const result = await deleteClientCommitmentStatusType(
        accessToken,
        clientCommitmentStatusTypeID
      );
      //console.log("RETURN DEL", clientCommitmentStatusTypes);
      dispatch(
        deleteClientCommitmentStatusTypeSuccess(clientCommitmentStatusTypeID)
      );
      dispatch(openSnackBar(Constants.REMOVE_SUCCESS, "success"));
      dispatch(push("/admin/clientCommitmentStatusTypes"));
    } catch (err: any) {
      dispatch(createClientCommitmentStatusTypeFailure(err.toString()));
    }
  };
