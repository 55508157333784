import axiosClient from "./axiosClient";

export interface User {
  UserAccountID: number;
  AuthID: string;
  UserTitle: string;
  FirstName: string;
  Surname: string;
  EmailAddress: string;
  CompanyID: number;
  IsActive: boolean;
  IsClientAdmin: boolean;
  LastActive: Date;
  PrimaryPhone: string;
  AltPhone: string;
  IsPrimary: boolean;
  BenReportAccess: boolean;
  EngReportAccess: boolean;
  ComReportAccess: boolean;
}

export interface Profile extends User {
  CompanyName: string;
  IsClient: boolean;
  //add new field to profile... whats the new field for?
  NotifApiHashedUID: string;
  SystemUser: boolean;
}

export interface UsersResult {
  users: User[];
}

export async function getUsers(
  accessToken: String,
  clientId: number,
  companyId?: number
): Promise<UsersResult> {
  const url = `/users/`;

  let params: any = {};

  if (companyId) {
    params.CompanyID = companyId;
  }

  try {
    const usersResponse = await axiosClient.get<User[]>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: params,
    });

    return {
      users: usersResponse.data,
    };
  } catch (err: any) {
    throw err;
  }
}

export async function getUser(
  accessToken: String,
  clientId: number,
  userId: number
): Promise<User> {
  const url = `/users/${userId}`;

  try {
    const { data } = await axiosClient.get<User>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getProfile(
  accessToken: String,
  clientId?: number //no longer needed but exists in some places in the code
): Promise<Profile> {
  const url = `/users/profile`;

  try {
    const { data } = await axiosClient.get<Profile>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function pwChange(
  accessToken: String,
  redirectLoc: string
): Promise<any> {
  const url = `/users/pwchange`;

  const payload = {
    redirectLoc: redirectLoc,
  };

  try {
    const { data } = await axiosClient.post<any>(url, payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function pwChangeForUser(
  accessToken: String,
  userID: number
): Promise<any> {
  const url = `/users/pwchange/${userID}`;

  try {
    const { data } = await axiosClient.post<any>(
      url,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createUser(
  accessToken: String,
  clientId: number,
  newUser: Partial<User>
): Promise<any> {
  const url = `/users`;

  try {
    const { data } = await axiosClient.post<User>(url, newUser, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    console.log("USER", data);
    return data;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.error) {
      const errData = err.response.data.error;
      throw errData;
      //return { error: errData.message || "An error has occurred" };
    }
    throw err;
  }
}

export async function updateUser(
  accessToken: String,
  clientId: number,
  userID: number,
  newUser: Partial<User>
): Promise<User> {
  const url = `/users/` + userID;

  try {
    const { data } = await axiosClient.post<User>(url, newUser, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteUser(
  accessToken: String,
  clientId: number,
  userID: number
): Promise<string> {
  const url = `/users/` + userID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
