import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { push } from "redux-first-history";
import {
  getStandardStatements,
  createStandardStatement,
  updateStandardStatement,
  deleteStandardStatement,
  StandardStatement,
  StandardStatementsResult,
  NewStandardStatement,
  UpdateStandardStatement,
} from "api/standardStatementAPI";

interface StandardStatementState {
  standardStatementsById: Record<number, StandardStatement>;
  standardStatementList: number[];
  isLoading: boolean;
  error: string | null;
}

const StandardStatementInitialState: StandardStatementState = {
  standardStatementsById: {},
  standardStatementList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: StandardStatementState) {
  state.isLoading = true;
}

function loadingFailed(
  state: StandardStatementState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const standardStatements = createSlice({
  name: "standardStatements",
  initialState: StandardStatementInitialState,
  reducers: {
    getStandardStatementsStart: startLoading,
    getStandardStatementsSuccess(
      state,
      { payload }: PayloadAction<StandardStatementsResult>
    ) {
      const { standardStatements } = payload;
      state.isLoading = false;
      state.error = null;

      standardStatements.forEach((statement) => {
        state.standardStatementsById[statement.StandardStatementID] = statement;
      });

      state.standardStatementList = standardStatements.map(
        (statement) => statement.StandardStatementID
      );
    },
    getStandardStatementsFailure: loadingFailed,
    createStandardStatementStart: startLoading,
    createStandardStatementSuccess(
      state,
      { payload }: PayloadAction<StandardStatement>
    ) {
      const { StandardStatementID } = payload;
      state.standardStatementsById[StandardStatementID] = payload;
      state.standardStatementList.push(StandardStatementID);

      state.isLoading = false;
      state.error = null;
    },
    createStandardStatementFailure: loadingFailed,
    updateStandardStatementStart: startLoading,
    updateStandardStatementSuccess(
      state,
      { payload }: PayloadAction<StandardStatement>
    ) {
      const { StandardStatementID } = payload;
      state.standardStatementsById[StandardStatementID] = payload;
      state.standardStatementList.push(StandardStatementID);

      state.isLoading = false;
      state.error = null;
    },
    updateStandardStatementFailure: loadingFailed,
    deleteStandardStatementStart: startLoading,
    deleteStandardStatementSuccess(state, { payload }: PayloadAction<number>) {
      const StandardStatementID = payload;
      delete state.standardStatementsById[StandardStatementID];
      state.standardStatementList = state.standardStatementList.filter(
        (item) => item !== StandardStatementID
      );

      state.isLoading = false;
      state.error = null;
    },
    deleteStandardStatementFailure: loadingFailed,
  },
});

export const {
  getStandardStatementsStart,
  getStandardStatementsSuccess,
  getStandardStatementsFailure,
  createStandardStatementStart,
  createStandardStatementSuccess,
  createStandardStatementFailure,
  updateStandardStatementStart,
  updateStandardStatementSuccess,
  updateStandardStatementFailure,
  deleteStandardStatementStart,
  deleteStandardStatementSuccess,
  deleteStandardStatementFailure,
} = standardStatements.actions;

export default standardStatements.reducer;

export const fetchStandardStatements =
  (accessToken: string): AppThunk =>
  async (dispatch) => {
    dispatch(getStandardStatementsStart());
    try {
      const standardStatements = await getStandardStatements(accessToken);
      dispatch(getStandardStatementsSuccess(standardStatements));
    } catch (err: any) {
      dispatch(getStandardStatementsFailure(err.toString()));
    }
  };

export const addStandardStatement =
  (accessToken: string, newStandardStatement: NewStandardStatement): AppThunk =>
  async (dispatch) => {
    dispatch(createStandardStatementStart());
    try {
      const standardStatement = await createStandardStatement(
        accessToken,
        newStandardStatement
      );
      dispatch(createStandardStatementSuccess(standardStatement));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push(`/admin/standardStatement`));
    } catch (err: any) {
      dispatch(createStandardStatementFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updStandardStatement =
  (
    accessToken: string,
    updatedStandardStatement: UpdateStandardStatement
  ): AppThunk =>
  async (dispatch) => {
    dispatch(updateStandardStatementStart());
    try {
      const standardStatement = await updateStandardStatement(
        accessToken,
        updatedStandardStatement
      );
      dispatch(updateStandardStatementSuccess(standardStatement));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push(`/admin/standardStatement`));
    } catch (err: any) {
      dispatch(updateStandardStatementFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delStandardStatement =
  (accessToken: string, standardStatementID: number): AppThunk =>
  async (dispatch) => {
    dispatch(deleteStandardStatementStart());
    try {
      await deleteStandardStatement(accessToken, standardStatementID);
      dispatch(deleteStandardStatementSuccess(standardStatementID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(deleteStandardStatementFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };
