import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  IconButton,
  Tooltip,
  Tabs,
  Tab,
  TextField,
  Paper,
  Typography,
  Grid,
  Popover,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { ContactSupportOutlined, Send } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { addFeedback } from "./FeedbackSlice";

import { RootState } from "app/rootReducer";
import { IppButton } from "components/Buttons/IppButton";
import { useSnackBarConstants, useTypedTranslation } from "utils/customHooks";

const PREFIX = "Feedback";

const classes = {
  boxSpace: `${PREFIX}-boxSpace`,
  button: `${PREFIX}-button`,
  tab: `${PREFIX}-tab`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(2),
  },
  [`& .${classes.tab}`]: {
    color: theme.palette.background,
    //background: "#007FFF",
  },
}));

function Feedback() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [type, setType] = React.useState("General");
  const [placeholder, setPlaceholder] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [value, setValue] = React.useState(0);
  const [followUp, setFollowUp] = React.useState(true);
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["strGen"]);
  const snackbarConstants = useSnackBarConstants();
  const dispatch = useDispatch();
  const { currentProfile } = useSelector((state: RootState) => state.profile);

  let feedbackData: any = {
    FeedbackType: type,
    CurrentPage: window.location.pathname,
    Message: message,
    DateReported: new Date(),
    UserEmail: currentProfile.EmailAddress,
    //not as crucial now
    FollowUpConsent: followUp,
    //ClientID: -1,
    IsHandled: false,
  };

  // opens the popper
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  //used to reset types on close
  const handleClose = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
        },
      });
      dispatch(addFeedback(accessToken, feedbackData, snackbarConstants));
    } catch (e) {
      console.error(e);
    }
    //dispatch(addFeedback(accessToken, feedbackData))
    setAnchorEl(null);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const changePlaceholder = (item?: number) => {
    /* switch (item) {
      case 0:
        setPlaceholder("Enter your feedback");
        setType("General");
        break;
      case 1:
        setPlaceholder(
          "Need a hand? Explain whats happening in as much detail as you can"
        );
        setType("Bug");
        break;
      case 2:
        setPlaceholder("We'll love to hear your idea");
        setType("idea");
        break;
      default:
        setPlaceholder("Enter yours Feedback");
    } */
    const placeholderText = t("strGen:header.icons.feedback.tooltip");
    setPlaceholder(placeholderText);
  };

  useEffect(() => {
    (async () => {
      try {
        changePlaceholder(value);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [value]);

  return (
    <div>
      <Tooltip title={t("strGen:header.icons.feedback.tooltip")}>
        <IconButton color="inherit" type="button" onClick={handleClick}>
          <ContactSupportOutlined />
        </IconButton>
      </Tooltip>
      <Popover
        open={Boolean(anchorEl)}
        onClose={handleClick}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Root>
          <Paper className={classes.boxSpace}>
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              color="primary"
            >
              {t("strGen:header.icons.feedback.hello", {
                name: currentProfile.FirstName,
              })}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Tabs
                  value={value}
                  variant="fullWidth"
                  //indicatorColor="primary"
                  onChange={handleTabChange}
                >
                  <Tab
                    className={classes.tab}
                    label={t("strGen:header.icons.feedback.title")}
                    {...a11yProps(0)}
                  />
                  {/* <Tab
                    className={classes.tab}
                    label="Trouble?"
                    {...a11yProps(1)}
                  />
                  <Tab
                    className={classes.tab}
                    label="Got an idea?"
                    {...a11yProps(2)}
                  /> */}
                </Tabs>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="filled-multiline-static"
                  label={t("strGen:header.icons.feedback.messagelabel")}
                  multiline
                  rows={4}
                  placeholder={placeholder}
                  variant="outlined"
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <IppButton
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  startIcon={<Send />}
                  fullWidth
                  onClick={handleClose}
                >
                  {t("strGen:buttons.sendfeedback")}
                </IppButton>
              </Grid>
              <Grid item xs={12} maxWidth={500}>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setFollowUp(event.target.checked);
                      }}
                    />
                  }
                  label={t("strGen:header.icons.feedback.responselabel")}
                  checked={followUp}
                />
              </Grid>
            </Grid>
          </Paper>
        </Root>
      </Popover>
    </div>
  );
}

export default Feedback;
