import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ProjectType,
  ProjectTypesResult,
  getProjectTypes,
  createProjectType,
  updateProjectType,
  deleteProjectType,
} from "api/projectTypeAPI";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { push } from "redux-first-history";

interface ProjectTypeState {
  projectTypesById: Record<number, ProjectType>;
  projectTypeList: number[];
  isLoading: boolean;
  error: string | null;
}

const ProjectTypeInitialState: ProjectTypeState = {
  projectTypesById: {},
  projectTypeList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: ProjectTypeState) {
  state.isLoading = true;
}

function loadingFailed(state: ProjectTypeState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const projectTypes = createSlice({
  name: "projectTypes",
  initialState: ProjectTypeInitialState,
  reducers: {
    getProjectTypesStart: startLoading,
    getProjectTypesSuccess(
      state,
      { payload }: PayloadAction<ProjectTypesResult>
    ) {
      const { projectTypes } = payload;
      state.isLoading = false;
      state.error = null;

      projectTypes.forEach((projectType) => {
        state.projectTypesById[projectType.ProjectTypeID] = projectType;
      });

      state.projectTypeList = projectTypes.map(
        (projectType) => projectType.ProjectTypeID
      );
    },
    getProjectTypesFailure: loadingFailed,
    createProjectTypeStart: startLoading,
    createProjectTypeSuccess(state, { payload }: PayloadAction<ProjectType>) {
      const { ProjectTypeID } = payload;
      state.projectTypesById[ProjectTypeID] = payload;
      state.projectTypeList.push(ProjectTypeID);

      state.isLoading = false;
      state.error = null;
    },
    createProjectTypeFailure: loadingFailed,
    updateProjectTypeStart: startLoading,
    updateProjectTypeSuccess(state, { payload }: PayloadAction<ProjectType>) {
      const { ProjectTypeID } = payload;
      state.projectTypesById[ProjectTypeID] = payload;

      state.isLoading = false;
      state.error = null;
    },
    updateProjectTypeFailure: loadingFailed,
    deleteProjectTypeStart: startLoading,
    deleteProjectTypeSuccess(state, { payload }: PayloadAction<number>) {
      const ProjectTypeID = payload;
      delete state.projectTypesById[ProjectTypeID];
      state.projectTypeList = state.projectTypeList.filter(
        (item) => item !== ProjectTypeID
      );

      state.isLoading = false;
      state.error = null;
    },
    deleteProjectTypeFailure: loadingFailed,
  },
});

export const {
  getProjectTypesStart,
  getProjectTypesSuccess,
  getProjectTypesFailure,
  createProjectTypeStart,
  createProjectTypeSuccess,
  createProjectTypeFailure,
  updateProjectTypeStart,
  updateProjectTypeSuccess,
  updateProjectTypeFailure,
  deleteProjectTypeStart,
  deleteProjectTypeSuccess,
  deleteProjectTypeFailure,
} = projectTypes.actions;

export default projectTypes.reducer;

export const fetchProjectTypes =
  (accessToken: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getProjectTypesStart());
      const projectTypes = await getProjectTypes(accessToken);
      dispatch(getProjectTypesSuccess(projectTypes));
    } catch (err: any) {
      dispatch(getProjectTypesFailure(err.toString()));
    }
  };

export const addProjectType =
  (accessToken: string, newProjectType: Partial<ProjectType>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createProjectTypeStart());
      const projectType = await createProjectType(accessToken, newProjectType);
      dispatch(createProjectTypeSuccess(projectType));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push("/admin/projectTypes"));
    } catch (err: any) {
      dispatch(createProjectTypeFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updProjectType =
  (
    accessToken: string,
    projectTypeID: number,
    updatedProjectType: Partial<ProjectType>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateProjectTypeStart());
      const projectType = await updateProjectType(
        accessToken,
        projectTypeID,
        updatedProjectType
      );
      dispatch(updateProjectTypeSuccess(projectType));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push("/admin/projectTypes"));
    } catch (err: any) {
      dispatch(updateProjectTypeFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delProjectType =
  (accessToken: string, projectTypeID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteProjectTypeStart());
      await deleteProjectType(accessToken, projectTypeID);
      dispatch(deleteProjectTypeSuccess(projectTypeID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(deleteProjectTypeFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };
