import { styled } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Grid as KendoGrid,
  GridColumn,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { Delete, Edit } from "@mui/icons-material";
import { IppTextField } from "components/IppTextField";
import { useTypedTranslation } from "utils/customHooks";
import LoadingIndicator from "components/LoadingIndicator";
import IppConfirmDialog from "components/IppConfirmDialog";
import {
  addProjectType,
  delProjectType,
  fetchProjectTypes,
  updProjectType,
} from "./ProjectTypesSlice";
import { ProjectTypeValidationSchema } from "./ProjectTypeValidation";
import { fetchDeleteChecksByProjectTypes } from "features/deleteCheck/DeleteCheckSlice";
import { IppButton } from "components/Buttons/IppButton";
import { IppAddButton } from "components/Buttons/IppAddButton";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppDeleteButton } from "components/Buttons/IppDeleteButton";
import { IppCancelButton } from "components/Buttons/IppCancelButton";

const PREFIX = "ProjectTypesPage";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
  roleCard: `${PREFIX}-roleCard`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1100,
  },

  [`& .${classes.boxSpace}`]: {
    padding: 10,
    maxWidth: 500,
  },

  [`& .${classes.roleCard}`]: {
    minWidth: 250,

    minHeight: 300,
  },
}));

export const ProjectTypesPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [editID, setEditID] = useState(0);
  const [deleteName, setDeleteName] = useState("");
  const [isEditing, setIsEditing] = useState(true);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  const { clientId } = useSelector((state: RootState) => state.client);

  const {
    projectTypeList,
    projectTypesById,
    isLoading: projectTypeIsLoading,
  } = useSelector((state: RootState) => state.projectTypes);

  const projectTypes = projectTypeList.map((id) => projectTypesById[id]);

  const newProjectType = {
    ProjectTypeName: undefined,
  };
  const [editItem, setEditItem] = useState(newProjectType);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchProjectTypes(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        if (isAdding) {
          dispatch(addProjectType(accessToken, values));
        } else {
          dispatch(updProjectType(accessToken, editID, values));
        }
        setEditOpen(false);
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const handleDelete = (projectTypeID: number) => {
    // function to delete current Tier
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(delProjectType(accessToken, projectTypeID));
        setDeleteOpen(false);
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  const deleteCell = (props: any) => {
    return (
      <td>
        <Tooltip
          title={t("strGen:buttons.deleteobj", {
            objectname: t("objPlt:objects.project.projecttype.name"),
          })}
        >
          <div
            style={{ cursor: "pointer", color: "blue" }}
            onClick={(e) => {
              setEditID(props.dataItem.ProjectTypeID);
              setDeleteName(props.dataItem.ProjectTypeName);
              setDeleteOpen(true);
            }}
          >
            <IppButton variant="text" startIcon={<Delete />} color={"error"} />
          </div>
        </Tooltip>
      </td>
    );
  };

  const editCell = (props: any) => {
    return (
      <td>
        <Tooltip
          title={t("strGen:buttons.editobj", {
            objectname: t("objPlt:objects.project.projecttype.name"),
          })}
        >
          <div
            style={{ cursor: "pointer", color: "blue" }}
            onClick={(e) => {
              setEditID(props.dataItem.ProjectTypeID);
              formik.setValues(props.dataItem);
              setEditOpen(true);
            }}
          >
            <IppButton variant="text" startIcon={<Edit />} />
          </div>
        </Tooltip>
      </td>
    );
  };

  const formik = useFormik({
    initialValues: editItem,
    validationSchema: ProjectTypeValidationSchema(),
    onSubmit: submitFunc,
    enableReinitialize: true,
  });

  let projectTypeView = projectTypeIsLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <Box display="flex" justifyContent="center">
        <Paper className={classes.boxSpace}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h5" component="h3">
                {t("objPlt:objects.project.projecttype.name", { count: 2 })}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Grid container justifyContent="flex-end">
                <IppAddButton
                  buttonText={t("strGen:buttons.addobj", {
                    objectname: t("objPlt:objects.project.projecttype.name"),
                  })}
                  onClick={() => {
                    setIsAdding(true);
                    setEditOpen(true);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} paddingTop={1}>
              <KendoGrid data={projectTypes} total={projectTypes.length}>
                <GridNoRecords>
                  <strong>
                    **{" "}
                    {t("strGen:inventory.norecordsexist", {
                      objectname: t("objPlt:objects.project.projecttype.name", {
                        count: 2,
                      }),
                    })}{" "}
                    **
                  </strong>
                </GridNoRecords>
                <GridColumn
                  field="ProjectTypeName"
                  title={t(
                    "objPlt:objects.project.projecttype.projecttypename"
                  )}
                />
                <GridColumn
                  cell={editCell}
                  width="50px"
                  field="TierID"
                  title=" "
                />
                <GridColumn cell={deleteCell} width="50px" title=" " />
              </KendoGrid>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <Dialog
        open={editOpen}
        onClose={() => {
          setEditOpen(false);
          formik.resetForm();
        }}
      >
        <DialogTitle>
          {isAdding
            ? t("strGen:prompts.add.addtitle", {
                fieldname: t("objPlt:objects.project.projecttype.name"),
              })
            : t("strGen:prompts.edit.editactive", {
                fieldname: t("objPlt:objects.project.projecttype.name"),
              })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText variant="body1">
            {isAdding
              ? t("strGen:prompts.add.message", {
                  fieldname: t("objPlt:objects.project.projecttype.name"),
                })
              : t("strGen:prompts.edit.modifyingmessage", {
                  fieldname: t("objPlt:objects.project.projecttype.name"),
                })}
          </DialogContentText>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <IppTextField
                  id="ProjectTypeName"
                  label={t(
                    "objPlt:objects.project.projecttype.projecttypename"
                  )}
                  value={formik.values.ProjectTypeName || ""}
                  onChangeFunction={formik.handleChange}
                  errorsExpression={formik.errors.ProjectTypeName}
                  touchedExpression={formik.touched.ProjectTypeName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                />
              </Grid>
              <Grid item xs={12}>
                <IppFormButtons
                  isEditing={true}
                  setIsEditing={null}
                  isAdding={isAdding}
                  showCancel={true}
                  resetFunction={() => {
                    formik.resetForm();
                    setEditOpen(false);
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
        <DialogTitle>
          {t("strGen:prompts.delete.deletetitle", {
            objectname: t("objPlt:objects.project.projecttype.name"),
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText variant="body1">
            {t("strGen:prompts.delete.deletecannotbeundone", {
              objectname: t("objPlt:objects.project.projecttype.name"),
            })}
          </DialogContentText>
          <DialogContentText
            variant="body1"
            sx={{ textAlign: "center", fontWeight: "bold", paddingTop: 1 }}
          >
            {deleteName}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IppDeleteButton onClick={() => setConfirmOpen(true)} />
          <IppCancelButton onClick={() => setDeleteOpen(false)} />
        </DialogActions>
      </Dialog>

      <IppConfirmDialog
        title={t("strGen:prompts.delete.deletetitle", {
          objectname: t("objPlt:objects.project.projecttype.name"),
        })}
        open={confirmOpen}
        setOpen={() => setConfirmOpen(!confirmOpen)}
        onConfirm={() => handleDelete(editID)}
        fetchFunc={fetchDeleteChecksByProjectTypes}
        recordID={editID}
        info={t("strGen:prompts.deletechecks.projecttypes")}
      />
    </div>
  );

  return <Root id="Project-Type-Page">{projectTypeView}</Root>;
};
