import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  Interaction,
  InteractionsResult,
  getInteractions,
  getInteractionsForReview,
  createInteraction,
  updateInteraction,
  deleteInteraction,
  getInteractionsByGroup,
  getInteractionsByContact,
  getInteractionsByIssue,
} from "api/stakeholder/interactionAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { Contact } from "../../../api/stakeholder/contactAPI";
import { fetchGroupInteractions } from "../groupInteraction/GroupInteractionSlice";
import { fetchContactInteractions } from "../contactInteraction/ContactInteractionSlice";
import { fetchGrievanceInteractions } from "../grievanceInteraction/GrievanceInteractionSlice";
import { fetchCommitmentInteractionsByCommitment } from "features/crossModule/stakeholderCommitment/commitmentInteractionSlice";
import { SnackBarConstants } from "utils/customHooks";

interface InteractionState {
  interactionsById: Record<number, Interaction>;
  interactionList: number[];
  isLoading: boolean;
  subInteractionisLoading: boolean;
  error: string | null;
  errorList: any[] | null;
}

const InteractionInitialState: InteractionState = {
  interactionsById: {},
  interactionList: [],
  isLoading: false,
  subInteractionisLoading: false,
  error: null,
  errorList: null,
};

function startLoading(state: InteractionState) {
  state.isLoading = true;
}

function loadingFailed(
  state: InteractionState,
  action: PayloadAction<{ status: string; message: string; errors: [] }>
) {
  state.isLoading = false;
  state.error = action.payload.message;
  if (action.payload.errors) {
    state.errorList = action.payload.errors;
  }
}

const interactions = createSlice({
  name: "interactions",
  initialState: InteractionInitialState,
  reducers: {
    getInteractionsStart: startLoading,
    getInteractionsSuccess(
      state,
      { payload }: PayloadAction<InteractionsResult>
    ) {
      const { interactions } = payload;

      state.isLoading = false;
      state.error = null;
      state.errorList = null;

      interactions.forEach((interaction) => {
        state.interactionsById[interaction.InteractionID] = interaction;
      });

      state.interactionList = interactions.map(
        (interaction) => interaction.InteractionID
      );
    },
    getInteractionsFailure: loadingFailed,
    createInteractionStart: startLoading,
    createInteractionSuccess(state, { payload }: PayloadAction<Interaction>) {
      const { InteractionID } = payload;
      state.interactionsById[InteractionID] = payload;
      state.interactionList.push(InteractionID);

      state.isLoading = false;
      state.error = null;
      state.errorList = null;
    },
    updateInteractionSuccess(state, { payload }: PayloadAction<Interaction>) {
      const { InteractionID } = payload;

      state.interactionsById[InteractionID] = payload;

      state.isLoading = false;
      state.error = null;
      state.errorList = null;
    },
    deleteInteractionSuccess(state, { payload }: PayloadAction<number>) {
      const InteractionID = payload;
      delete state.interactionsById[InteractionID];
      state.interactionList = state.interactionList.filter(
        (item) => item !== InteractionID
      );

      state.isLoading = false;
      state.error = null;
      state.errorList = null;
    },
    createInteractionFailure: loadingFailed,
  },
});

export const {
  getInteractionsStart,
  getInteractionsSuccess,
  getInteractionsFailure,
  createInteractionStart,
  createInteractionSuccess,
  updateInteractionSuccess,
  deleteInteractionSuccess,
  createInteractionFailure,
} = interactions.actions;

export default interactions.reducer;

export const fetchInteractions =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getInteractionsStart());
      const interactions = await getInteractions(accessToken);
      dispatch(getInteractionsSuccess(interactions));
    } catch (err: any) {
      dispatch(getInteractionsFailure(err.toString()));
    }
  };

export const fetchInteractionsForReview =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getInteractionsStart());
      //Loads a filtered list of Communications into the store instead of the full one
      const interactions = await getInteractionsForReview(accessToken);
      dispatch(getInteractionsSuccess(interactions));
    } catch (err: any) {
      dispatch(getInteractionsFailure(err.toString()));
    }
  };

export const fetchInteractionsByGroup =
  (accessToken: String, groupID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getInteractionsStart());
      const interactions = await getInteractionsByGroup(accessToken, groupID);
      dispatch(getInteractionsSuccess(interactions));
    } catch (err: any) {
      dispatch(getInteractionsFailure(err.toString()));
    }
  };

export const fetchInteractionsByContact =
  (accessToken: String, contactID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getInteractionsStart());
      const interactions = await getInteractionsByContact(
        accessToken,
        contactID
      );
      dispatch(getInteractionsSuccess(interactions));
    } catch (err: any) {
      dispatch(getInteractionsFailure(err.toString()));
    }
  };

export const fetchInteractionsByIssue =
  (accessToken: String, issueID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getInteractionsStart());
      const interactions = await getInteractionsByIssue(accessToken, issueID);

      dispatch(getInteractionsSuccess(interactions));
    } catch (err: any) {
      dispatch(getInteractionsFailure(err.toString()));
    }
  };

export const addInteraction =
  (
    accessToken: String,
    newInteraction: Partial<Interaction>,
    contactList: Contact[],
    groupList: string[],
    issueList: string[],
    grievanceList: number[],
    setReturnRoute: boolean,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      let contactIDs: any[] = contactList.map((contact) => {
        return { ContactID: contact.ContactID };
      });

      dispatch(createInteractionStart());
      const interaction = await createInteraction(
        accessToken,
        newInteraction,
        contactIDs,
        groupList,
        issueList,
        grievanceList
      );
      dispatch(createInteractionSuccess(interaction));
      dispatch(fetchInteractions(accessToken));
      let interactionID = interaction.InteractionID;
      dispatch(openSnackBar(snackbarConstants.ADD_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(push("/engagement/communications/" + interactionID));
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        dispatch(createInteractionFailure(error.response.data));
        dispatch(openSnackBar(error.response.data.message, "error"));
      } else {
        dispatch(createInteractionFailure(error.toString()));
        dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
      }
    }
  };

export const updInteraction =
  (
    accessToken: String,
    interactionID: number,
    newInteraction: Partial<Interaction>,
    contactList: Contact[] | null,
    groupList: string[] | null,
    issueList: string[] | null,
    didEditParent: boolean,
    snackbarConstants: SnackBarConstants,
    parentCommitmentID?: number | null
  ): AppThunk =>
  async (dispatch) => {
    try {
      let contactIDs: any[] | null = contactList
        ? contactList.map((contact) => {
            return { ContactID: contact.ContactID };
          })
        : null;
      dispatch(createInteractionStart());
      const interaction = await updateInteraction(
        accessToken,
        interactionID,
        newInteraction,
        contactIDs,
        groupList,
        issueList,
        didEditParent
      );

      dispatch(updateInteractionSuccess(interaction));
      dispatch(fetchGroupInteractions(accessToken));
      dispatch(fetchContactInteractions(accessToken));
      dispatch(fetchGrievanceInteractions(accessToken));
      dispatch(fetchInteractions(accessToken));
      if (parentCommitmentID) {
        dispatch(
          fetchCommitmentInteractionsByCommitment(
            accessToken,
            parentCommitmentID
          )
        );
      }
      dispatch(openSnackBar(snackbarConstants.UPDATE_SUCCESS, "success"));
    } catch (error: any) {
      if (error.response.status === 400) {
        dispatch(createInteractionFailure(error.response.data));
        dispatch(openSnackBar(error.response.data.message, "error"));
      } else {
        dispatch(createInteractionFailure(error.toString()));
        dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
      }
    }
  };

export const delInteraction =
  (
    accessToken: String,
    interactionID: number,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createInteractionStart());
      const result = await deleteInteraction(accessToken, interactionID);
      dispatch(deleteInteractionSuccess(interactionID));
      dispatch(openSnackBar(snackbarConstants.DELETE_SUCCESS, "success"));
      dispatch(push("/engagement/communications"));
    } catch (error: any) {
      if (error.response.status === 400) {
        dispatch(createInteractionFailure(error.response.data));
        dispatch(openSnackBar(error.response.data.message, "error"));
      } else {
        dispatch(createInteractionFailure(error.toString()));
        dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
      }
    }
  };
