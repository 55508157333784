import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { Prompt } from "react-router";
import { Grid, Typography, Paper, Box, Divider } from "@mui/material";
import { updClientTriggeredNotifs } from "features/client/ClientSlice";
import { ChangeEvent, useState } from "react";
import { IppSwitch } from "components/IppSwitch";
import { IppSkeleton } from "components/IppSkeleton";
import { useTypedTranslation } from "utils/customHooks";
import { IppFormDivider } from "components/IppFormDivider";
import { IppSubmitButton } from "components/Buttons/IppSubmitButton";
import { getChangedValues } from "utils/functions";

const PREFIX = "TriggeredNotificationsFeaturesForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    // minWidth: 650,
    maxWidth: 1500,
  },
  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const TriggeredNotificationsFeaturesForm = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen"]);
  const [isEditing] = useState(true);

  const {
    isLoading,
    clientName,
    tn_CompanyAccept,
    tn_CompanyReject,
    tn_PaymentPeriodReject,
    tn_PaymentPeriodAccepted,
    tn_PersonResponsibleAssigned,
    ft_ben_Contract,
  } = useSelector((state: RootState) => state.client);

  const { clientModuleList, clientModulesById } = useSelector(
    (state: RootState) => state.clientModules
  );
  const clientModules = clientModuleList.map((id) => clientModulesById[id]);

  const moduleName = (ID: number) => {
    let result = clientModules.find((item) => item.ModuleID === ID);
    return result?.ModuleName || "No Access";
  };

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        const updatedValues = getChangedValues(values, formik.initialValues);

        if (Object.keys(updatedValues).length === 0) return;

        dispatch(updClientTriggeredNotifs(accessToken, updatedValues, true));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let formData = {
    TN_CompanyAccept: tn_CompanyAccept ?? false,
    TN_CompanyReject: tn_CompanyReject ?? false,
    TN_PaymentPeriodReject: tn_PaymentPeriodReject ?? false,
    TN_PaymentPeriodAccepted: tn_PaymentPeriodAccepted ?? false,
    TN_PersonResponsibleAssigned: tn_PersonResponsibleAssigned ?? false,
  };

  const formik = useFormik({
    initialValues: formData,
    onSubmit: onSub,
  });

  const editForm = (
    <div>
      {!formik.isSubmitting && (
        <Prompt
          when={formik.dirty}
          message="You have unsaved changes; are you sure you want to leave this page?"
        />
      )}

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h1">
              Triggered Notifications
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              When a toggle is enabled, a notification is automatically sent out
              via email to the user when the action occurs. To prevent a
              notification, disable the corresponding toggle.
            </Typography>
          </Grid>
          <Box padding={1} sx={{ flexGrow: 1, borderRadius: 1 }}>
            <Grid
              container
              direction={"row"}
              display="flex"
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              justifyContent="space-around"
            >
              {
                //----------PLATFORM --------------//
              }
              {(clientModules.find((item) => item.ModuleID === 2) ||
                clientModules.find((item) => item.ModuleID === 3)) && (
                <Grid container item xs direction={"column"}>
                  <Paper elevation={6} className={classes.boxSpace}>
                    <Grid item xs>
                      <IppFormDivider title="Platform" />

                      <Grid item xs>
                        <IppSwitch
                          id="TN_PersonResponsibleAssigned"
                          label="Assigned Person Responsible"
                          value={formik.values.TN_PersonResponsibleAssigned}
                          onChangeFunction={(
                            event: ChangeEvent,
                            newValue: any
                          ) => {
                            formik.handleChange(event);
                            formik.setFieldValue(
                              "TN_PersonResponsibleAssigned",
                              newValue
                            );
                          }}
                          isEditing={isEditing}
                        />
                        <Typography
                          variant="caption"
                          paragraph
                          sx={{ paddingLeft: "45px" }}
                        >
                          When the Person Responsible is changed to someone
                          else, that person receives a notification.
                        </Typography>
                        <Divider variant="middle" />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              )}

              {
                //----------BENEFITS --------------//
              }
              {clientModules.find((item) => item.ModuleID === 1) && (
                <Grid container item xs direction={"column"}>
                  <Paper elevation={6} className={classes.boxSpace}>
                    <IppFormDivider title={moduleName(1)} />
                    <Grid item xs>
                      <IppSwitch
                        id="TN_CompanyReject"
                        label="Reject Company Request"
                        value={formik.values.TN_CompanyReject}
                        onChangeFunction={(
                          event: ChangeEvent,
                          newValue: any
                        ) => {
                          formik.handleChange(event);
                          formik.setFieldValue("TN_CompanyReject", newValue);
                        }}
                        isEditing={isEditing}
                      />
                      <Typography
                        variant="caption"
                        paragraph
                        sx={{ paddingLeft: "45px" }}
                      >
                        When a new Company request is submitted by a contractor
                        and rejected by an Administrator, the contractor
                        receives a notification.
                      </Typography>
                      <Divider variant="middle" />
                    </Grid>
                    <Grid item xs>
                      <IppSwitch
                        id="TN_PaymentPeriodAccepted"
                        label="Accept Spend Report"
                        value={formik.values.TN_PaymentPeriodAccepted}
                        onChangeFunction={(
                          event: ChangeEvent,
                          newValue: any
                        ) => {
                          formik.handleChange(event);
                          formik.setFieldValue(
                            "TN_PaymentPeriodAccepted",
                            newValue
                          );
                        }}
                        isEditing={isEditing}
                      />
                      <Typography
                        variant="caption"
                        paragraph
                        sx={{ paddingLeft: "45px" }}
                      >
                        When a Spend Report is Accepted by an Administrator or
                        Client Power User, all Company Users who are Active for
                        Notifications on the{" "}
                        {ft_ben_Contract ? "Contract" : "Project"} related to
                        the Spend Report receive a notification.
                      </Typography>
                      <Divider variant="middle" />
                    </Grid>
                    <Grid item xs>
                      <IppSwitch
                        id="TN_PaymentPeriodReject"
                        label="Reject Spend Report"
                        value={formik.values.TN_PaymentPeriodReject}
                        onChangeFunction={(
                          event: ChangeEvent,
                          newValue: any
                        ) => {
                          formik.handleChange(event);
                          formik.setFieldValue(
                            "TN_PaymentPeriodReject",
                            newValue
                          );
                        }}
                        isEditing={isEditing}
                      />
                      <Typography
                        variant="caption"
                        paragraph
                        sx={{ paddingLeft: "45px" }}
                      >
                        When a Spend Report is submitted by a user and rejected
                        by an Administrator or Client Power User, the user
                        receives a notification.
                      </Typography>
                      <Divider variant="middle" />
                    </Grid>
                    {/* ********** MAY IMPLEMENT IN FUTURE ********** */}
                    {/* <Grid item xs>
                      <IppSwitch
                        id="TN_CompanyAccept"
                        label="Accept Company Request"
                        value={formik.values.TN_CompanyAccept}
                        onChangeFunction={(
                          event: ChangeEvent,
                          newValue: any
                        ) => {
                          formik.handleChange(event);
                          formik.setFieldValue("TN_CompanyAccept", newValue);
                        }}
                        isEditing={isEditing}
                      />
                      <Typography
                        variant="caption"
                        paragraph
                        sx={{ paddingLeft: "45px" }}
                      >
                        When a new Company request is submitted by a contractor
                        and approved by an Administrator, the contractor
                        receives a notification.
                      </Typography>
                      <Divider variant="middle" />
                    </Grid> */}
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <IppSubmitButton
            buttonText={t("strGen:buttons.savechanges")}
            disabled={!formik.dirty}
          />
        </Grid>
      </form>
    </div>
  );

  return (
    <div>
      <Root>
        <Box display="flex" justifyContent="center">
          <Paper className={classes.boxSpace}>
            <Grid container className={classes.editForm} spacing={1}>
              {isLoading ? (
                <IppSkeleton height={400} />
              ) : (
                <Grid item sm={12}>
                  {editForm}
                </Grid>
              )}
            </Grid>
          </Paper>
        </Box>
      </Root>
    </div>
  );
};
