import {
  ConstructedErrors,
  GenericErrorTitlesObject,
} from "utils/types/index.types";
import {
  generateErrorTitle,
  getGenericConstructedErrors,
  getGenericConstructedWarnings,
} from "utils/uploadUtils";

type GroupErrorTitles = GenericErrorTitlesObject & {};

export const generateGroupErrorTitles: GroupErrorTitles = {
  ...generateErrorTitle,
};

export const getNewGroupConstructedErrors: () => ConstructedErrors = () => ({
  ...getGenericConstructedErrors(),
});

export const getNewGroupConstructedWarnings: () => ConstructedErrors = () => ({
  ...getGenericConstructedWarnings(),
});
