import { Paper, Box, Typography, Tooltip } from "@mui/material";
import { push } from "redux-first-history";
import { useDispatch } from "react-redux";
import { setFilter } from "utils/filterSlice";
import { useTypedTranslation } from "utils/customHooks";
interface summaryCardProps {
  title: string;
  value?: string;
  component?: any;
  colorSet?: string;
  padding?: number;
  pageName?: any;
  filter?: any;
  minHeight?: string;
}

const IppShadowBox = (props: summaryCardProps) => {
  const dispatch = useDispatch();
  const t = useTypedTranslation(["strGen"]);

  const {
    title,
    value,
    component,
    colorSet,
    padding,
    pageName,
    filter,
    minHeight,
  } = props;

  // valid colorsets are; red, green, blue, white
  let fg, bg, captionColor;
  switch (colorSet) {
    case "red":
      fg = "white";
      bg = "red";
      captionColor = "whitesmoke";
      break;

    case "blue":
      fg = "white";
      bg = "blue";
      captionColor = "whitesmoke";
      break;

    case "green":
      fg = "white";
      bg = "green";
      captionColor = "whitesmoke";
      break;

    case "yellow":
      fg = "white";
      bg = "warning.main";
      captionColor = "whitesmoke";
      break;

    default:
      fg = "primary";
      bg = "white";
      captionColor = "textSecondary";
      break;
  }

  const onBoxClick = () => {
    const pathArray = window.location.pathname.split("/");
    const clientShortName = pathArray[1];
    const fullPath = "/" + clientShortName + pageName;

    // dispatch(clearFilterState(fullPath));
    if (filter) {
      dispatch(
        setFilter({
          page: fullPath,
          filter,
        })
      );
    }

    dispatch(push(pageName));
  };

  return (
    <Paper
      elevation={6}
      sx={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      {pageName ? (
        <Tooltip title={t("strGen:tooltips.clickfordetails")}>
          <Box
            onClick={onBoxClick}
            padding={padding}
            bgcolor={bg}
            sx={{
              flex: 1,
              cursor: "pointer",
              borderRadius: 1,
              minHeight: minHeight || "",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Typography
              color={captionColor}
              variant="h5"
              gutterBottom
              sx={{ fontSize: "20px" }}
            >
              {title}
            </Typography>
            {component || (
              <Typography color={fg} variant="h2">
                {value}
              </Typography>
            )}
          </Box>
        </Tooltip>
      ) : (
        <Box padding={padding} bgcolor={bg} sx={{ borderRadius: 1 }}>
          <Typography color={captionColor} variant="h5" gutterBottom>
            {title}
          </Typography>
          {component || (
            <Typography color={fg} variant="h2">
              {value}
            </Typography>
          )}
        </Box>
      )}
    </Paper>
  );
};

export default IppShadowBox;

IppShadowBox.defaultProps = {
  padding: 3,
};
