import axiosClient from "../axiosClient";

export interface GrievanceInteraction {
  GrievanceInteractionID: number;
  GrievanceID: number;
  InteractionID: number;
  GrievanceName: string;
  InteractionTitle: string;
  ClientID: number;
  GrievanceStatusText: string;
  ReportedDate: Date;
  ResolutionDate: Date;
  InteractionDate: Date;
  InteractionTypeName: string;
  SentimentLevel: number;
  GrievanceProjectID: number;
  InteractionProjectID: number;
  CommunicationID: number;
  SequenceID: number;
}

export interface GrievanceInteractionsResult {
  grievanceInteractions: GrievanceInteraction[];
}

export async function getGrievanceInteractions(
  accessToken: String
): Promise<GrievanceInteractionsResult> {
  const url = `/grievanceInteraction`;

  try {
    const { data } = await axiosClient.get<GrievanceInteractionsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getGrievanceInteractionsByInteraction(
  accessToken: String,
  interactionID: number
): Promise<GrievanceInteractionsResult> {
  const url = `/grievanceInteraction/interaction/${interactionID}`;

  try {
    const { data } = await axiosClient.get<GrievanceInteractionsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getGrievanceInteractionsByGrievance(
  accessToken: String,
  grievanceID: number
): Promise<GrievanceInteractionsResult> {
  const url = `/grievanceInteraction/grievance/${grievanceID}`;

  try {
    const { data } = await axiosClient.get<GrievanceInteractionsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createGrievanceInteraction(
  accessToken: String,
  newCommInv: Partial<GrievanceInteraction>
): Promise<GrievanceInteraction> {
  const url = `/grievanceInteraction`;

  try {
    const { data } = await axiosClient.post<GrievanceInteraction>(
      url,
      newCommInv,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateGrievanceInteraction(
  accessToken: String,
  commInvID: number,
  newCommInv: Partial<GrievanceInteraction>
): Promise<GrievanceInteraction> {
  const url = `/grievanceInteraction/` + commInvID;

  try {
    const { data } = await axiosClient.post<GrievanceInteraction>(
      url,
      newCommInv,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteGrievanceInteraction(
  accessToken: String,
  commInvID: number
): Promise<string> {
  const url = `/grievanceInteraction/` + commInvID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
