import axiosClient from "../axiosClient";

export interface CommitmentSourceType {
  CommitmentSourceTypeID: number;
  CommitmentSourceTypeName: string;
}

export interface CommitmentSourceTypesResult {
  commitmentSourceTypes: CommitmentSourceType[];
}

export async function getCommitmentSourceTypes(
  accessToken: String
): Promise<CommitmentSourceTypesResult> {
  const url = `/commitmentSourceType`;

  try {
    const { data } = await axiosClient.get<CommitmentSourceTypesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}