import axiosClient from "./axiosClient";

export interface WorkflowStage {
  WorkflowStageID: number;
  WorkflowStageName: string;
}

export interface WorkflowStagesResult {
  workflowStages: WorkflowStage[];
}

export async function getWorkflowStages(
  accessToken: String
): Promise<WorkflowStagesResult> {
  const url = `/workflowstage`;

  try {
    const { data } = await axiosClient.get<WorkflowStagesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
