import { useAuth0 } from "@auth0/auth0-react";
import { styled } from "@mui/material/styles";
import { RootState } from "app/rootReducer";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IsMedSmall } from "utils/mediaQueries";
import { addRnDExpense, delRnDExpense, updRnDExpense } from "./RnDExpenseSlice";
import { useFormik } from "formik";
import { IppAutocomplete } from "components/IppAutocomplete";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { IppFormHeader } from "components/IppFormHeader";
import { IppDisplayField } from "components/IppDisplayField";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { UserWriteAccess } from "utils/userAccess";
import LoadingIndicator from "components/LoadingIndicator";
import { Prompt } from "react-router";
import { IppCurrencyField } from "components/IppCurrencyField";
import { IppStaticTextfield } from "components/IppStaticTextfield";
import { IppAttachmentInventory } from "components/IppAttachmentInventory";
import { IppTextField } from "components/IppTextField";
import { IppRnDClassifications } from "components/IppRnDClassifications";
import {
  ConvertDateOffset,
  GetJSDate,
  GetJSYear,
  getDateFromDateString,
} from "utils/DateFunctions";
import { IppDatePicker } from "components/IppDatePicker";
import { IppFormDivider } from "components/IppFormDivider";
import { fetchInitiatives } from "../initiatives/InitiativeSlice";
import { useTypedTranslation } from "utils/customHooks";
import { RnDExpenseValidationSchema } from "./RnDExpenseValidation";
import { IppCheckbox } from "components/IppCheckbox";
import { fetchCompanies } from "../companies/CompaniesSlice";
import { fetchAssets } from "../assets/AssetSlice";
import { classifications } from "features/datalists/Classification";
import { Initiative } from "api/benefits/initiativeAPI";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { IppCurrencyDisplay } from "components/IppCurrencyDisplayField";
import { Company } from "api/companyAPI";
import { CompanyOptionsRender } from "utils/renderFunctions";
import { IppDisplayCheckbox } from "components/IppDisplayCheckbox";
import { RecordHistoryDisplay } from "components/Reusable Utils/RecordHistoryDisplay";

const PREFIX = "InteractionActionForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    maxWidth: 1150,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const RnDExpenseForm = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);
  const customBP = IsMedSmall();

  let expenseData = props.RnDExpense || {};

  const [isEditing, setIsEditing] = useState(
    expenseData.RnDExpenseID ? false : true
  );
  const [isAdding, setIsAdding] = useState(
    expenseData.RnDExpenseID ? false : true
  );
  const [initiativeData, setInitiativeData] = useState<Initiative | null>(null);

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const showEdit = UserWriteAccess("Benefits");

  const {
    isLoading: clientIsLoading,
    clientId,
    ft_ben_AllocatedDate,
    ft_ben_Asset,
  } = useSelector((state: RootState) => state.client);

  const { isLoading: expenseIsLoading } = useSelector(
    (state: RootState) => state.RnDExpense
  );

  const {
    initiativeList,
    initiativesById,
    isLoading: initiativeIsLoading,
  } = useSelector((state: RootState) => state.initiatives);

  const initiatives = initiativeList
    .map((id) => initiativesById[id])
    .filter((item) => item.Category == "Research & Development");

  const {
    clientCurrencyList,
    clientCurrenciesById,
    isLoading: clientCurrencyIsLoading,
  } = useSelector((state: RootState) => state.clientCurrencies);

  const clientCurrency = clientCurrencyList.map(
    (id) => clientCurrenciesById[id]
  );

  const {
    isLoading: companyIsLoading,
    companiesById,
    companyList,
  } = useSelector((state: RootState) => state.companies);
  const companies = companyList.map((id) => companiesById[id]);
  const confirmedCompanies = companies.filter((comp) => comp.ListingConfirmed);

  const { assetIsLoading, assetsById, assetList } = useSelector(
    (state: RootState) => state.assets
  );
  const assets = assetList.map((id) => assetsById[id]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchInitiatives(accessToken));
        dispatch(fetchCompanies(accessToken, clientId));
        if (ft_ben_Asset) {
          dispatch(fetchAssets(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(addRnDExpense(accessToken, values, true));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  if (expenseData.RnDExpenseID) {
    //Update case
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });

          dispatch(
            updRnDExpense(accessToken, values.RnDExpenseID, values, true)
          );
        } catch (e) {
          console.error(e);
        }
      })();
    };
  } else {
    // sets defaults for create case
    expenseData.InitiativeID = null;
    expenseData.InitiativeName = null;
    expenseData.ExpenseAmount = 0;
    expenseData.Reference = "";
    expenseData.Classification = "";
    expenseData.ExpenseDate = new Date().toISOString().slice(0, 10);
    expenseData.CompanyID = currentProfile.CompanyID;
    expenseData.AssetID = null;
    expenseData.Applied = false;
    expenseData.AppliedDate = null;
    expenseData.RnDExpenses = [];
    expenseData.SourceOfFunds = null;
    expenseData.InEligible = false;
  }

  const handleDelete = () => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(delRnDExpense(accessToken, expenseData.RnDExpenseID, true));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const formik = useFormik({
    initialValues: expenseData,
    validationSchema: RnDExpenseValidationSchema(isAdding),
    onSubmit: submitFunc,
  });

  useEffect(() => {
    if (!formik.values.Applied) {
      if (formik.values.AppliedDate) formik.setFieldValue("AppliedDate", null);
    }
  }, [formik.values.Applied]);

  useEffect(() => {
    if (formik.values.InitiativeID > 0 && initiativeData) {
      //setInitiativeData(initiativesById[formik.values.InitiativeID]);
      // set AssetID, CompanyID and SourceOfFunds based on Initiative Data if initiative value exist and is not null
      if (initiativeData?.AssetID) {
        formik.setFieldValue("AssetID", initiativeData.AssetID);
      }
      if (initiativeData?.CompanyID) {
        formik.setFieldValue("CompanyID", initiativeData.CompanyID);
      }
      if (initiativeData?.Owner)
        formik.setFieldValue("SourceOfFunds", initiativeData.Owner);
      // show snackbar message that values changed
      isEditing &&
        dispatch(
          openSnackBar(
            "Some values have been updated based on the selected initiative",
            "info"
          )
        );
    } else {
      setInitiativeData(null);
    }
  }, [initiativeData, formik.values.InitiativeID]);

  // --------------------------------- VIEW FORM ------------------------------

  let viewForm = (
    <Box display="flex" justifyContent="center">
      <Grid container className={classes.editForm} spacing={1}>
        <Grid item xs={12}>
          <Paper className={classes.boxSpace}>
            <Grid container spacing={1}>
              <IppFormHeader
                title={t("objBen:objects.rndExpense.rndexpense")}
                isEditing={isEditing}
                isAdding={isAdding}
                showReturn={true}
              />
              <IppFormDivider title="Expense Details" />
              <Grid item xs={12}>
                <IppDisplayField
                  label={"Sequence-ID"}
                  value={`RD-${formik.values.SequenceID}`}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={false}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label="Initiative"
                  value={formik.values.InitiativeName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  isWebLink={true}
                  route={`${window.location.href.substring(
                    0,
                    window.location.href.lastIndexOf("rndexpenses")
                  )}initiatives/${formik.values.InitiativeID}`}
                  openInNewTab={false}
                  showEdit={showEdit}
                  tooltip="Click to view Initiative"
                />
              </Grid>
              <Grid item xs={6}>
                <IppCurrencyDisplay
                  label={t("objBen:objects.rndExpense.fields.expenseamount")}
                  value={formik.values.ExpenseAmount}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                  showCurrency
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objBen:objects.rndExpense.fields.expensedate")}
                  value={getDateFromDateString(formik.values.ExpenseDate)}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objBen:objects.rndExpense.fields.classification")}
                  value={formik.values.Classification}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objBen:objects.rndExpense.fields.reference")}
                  value={formik.values.Reference}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>

              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objBen:objects.company.name")}
                  value={
                    companyList.length > 0
                      ? companiesById[formik.values.CompanyID]?.CompanyName
                      : ""
                  }
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              {currentProfile.IsClient && (
                <Grid item xs={6}>
                  <IppDisplayField
                    label={t("objBen:objects.rndExpense.fields.sourceoffunds")}
                    value={formik.values.SourceOfFunds}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
              )}
              {ft_ben_Asset && (
                <>
                  <Grid item xs={6}>
                    <IppDisplayField
                      label={t("objBen:objects.asset.name")}
                      value={assetsById[formik.values.AssetID]?.AssetName}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      showEdit={showEdit}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <IppDisplayField
                      label={t("objBen:objects.rndExpense.fields.applied")}
                      value={formik.values.Applied ? "Yes" : "No"}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      showEdit={showEdit}
                    />
                  </Grid>
                  {formik.values.Applied && (
                    <Grid item xs={6}>
                      <IppDisplayField
                        label={t(
                          "objBen:objects.rndExpense.fields.applieddate"
                        )}
                        value={
                          ft_ben_AllocatedDate
                            ? formik.values.AppliedDate?.slice(0, 4)
                            : formik.values.AppliedDate?.slice(0, 10)
                        }
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        showEdit={showEdit}
                      />
                    </Grid>
                  )}
                </>
              )}
              <Grid item xs={6} style={{ marginTop: 30 }}>
                <IppDisplayCheckbox
                  label={t("objBen:objects.rndExpense.fields.ineligible")}
                  isChecked={formik.values.InEligible}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>

              {currentProfile.IsClient && (
                <Grid item xs={12}>
                  <RecordHistoryDisplay
                    createDate={formik.values.CreateDate}
                    createdBy={formik.values.CreatedBy}
                    lastUpdated={formik.values.LastUpdated}
                    lastUpdatedBy={formik.values.LastUpdatedBy}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <IppFormButtons
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  isAdding={isAdding}
                  resetFunction={() => formik.resetForm()}
                  showDelete={
                    currentProfile.IsClient ||
                    currentProfile.CompanyID === formik.values.CompanyID
                  }
                  deleteFunction={handleDelete}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );

  // --------------------------------- EDIT FORM ------------------------------

  let editForm =
    clientIsLoading || initiativeIsLoading ? (
      <LoadingIndicator />
    ) : (
      <form onSubmit={formik.handleSubmit}>
        {!formik.isSubmitting && (
          <Prompt
            when={formik.dirty}
            message={t("strGen:prompts.unsavedchanges")}
          />
        )}
        <Grid container className={classes.editForm} spacing={1}>
          <IppFormHeader
            title={t("objBen:objects.rndExpense.rndexpense")}
            isEditing={isEditing}
            isAdding={isAdding}
            returnPath="/benefits/rndexpenses"
          />
          <IppFormDivider title="Expense Details" />
          <Grid item xs={6}>
            <IppAutocomplete
              id="InitiativeID"
              required
              options={initiatives}
              value={initiatives.find((obj) => {
                return obj.InitiativeID === formik.values.InitiativeID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("InitiativeID", newValue.InitiativeID);
                  setInitiativeData(initiativesById[newValue.InitiativeID]);
                } else {
                  formik.setFieldValue("InitiativeID", null);
                }
              }}
              label={t("objBen:objects.initiative.name")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.InitiativeName}
              errorFunction={formik.errors.InitiativeID}
              touchedFunction={formik.touched.InitiativeID}
              textValueFunction={
                formik.values.InitiativeID > 0 &&
                initiativesById[formik.values.InitiativeID]
                  ? initiativesById[formik.values.InitiativeID].InitiativeName
                  : ""
              }
              toolTip={t(
                "objBen:objects.initiative.tooltips.expenseinitiative"
              )}
            />
          </Grid>
          {!isAdding && (
            <>
              <Grid item xs={6}>
                <IppCurrencyField
                  id="ExpenseAmount"
                  label={t("objBen:objects.rndExpense.fields.expenseamount")}
                  required
                  value={formik.values.ExpenseAmount}
                  onChangeFunction={(newValue) =>
                    formik.setFieldValue("ExpenseAmount", newValue)
                  }
                  touchedExpression={formik.touched.ExpenseAmount}
                  errorsExpression={formik.errors.ExpenseAmount}
                  isEditing={isEditing}
                />
              </Grid>
              <Grid item xs={6}>
                <IppAutocomplete
                  id="Classification"
                  options={classifications}
                  label={t("objBen:objects.entExpense.fields.classification")}
                  value={formik.values.Classification}
                  required={true}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  onChangeFunction={(event: ChangeEvent, newValue: any) => {
                    if (newValue) {
                      formik.setFieldValue("Classification", newValue);
                    } else {
                      formik.setFieldValue("Classification", null);
                    }
                  }}
                  errorFunction={formik.errors.Classification}
                  touchedFunction={formik.touched.Classification}
                  optionLabelFunction={(option: any) => option}
                  toolTip={t(
                    "objBen:objects.rndExpense.tooltips.expenseclassification"
                  )}
                />
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <IppDatePicker
              id="ExpenseDate"
              label={t("objBen:objects.rndExpense.fields.expensedate")}
              required={true}
              value={ConvertDateOffset(formik.values.ExpenseDate)}
              onChangeFunction={(newValue: any) => {
                formik.setFieldValue("ExpenseDate", GetJSDate(newValue), true);
                formik.setFieldTouched("ExpenseDate", true, false);
              }}
              errorsExpression={formik.errors.ExpenseDate}
              touchedExpression={formik.touched.ExpenseDate}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              disableFuture={true}
              toolTip="The date when the Expense was created or paid for."
            />
          </Grid>
          <Grid item xs={6}>
            <IppStaticTextfield
              id="CurrencyType"
              label={t("objBen:objects.payment.fields.currency")}
              value={clientCurrency[0]?.CurrencyName}
            />
          </Grid>
          <Grid item xs={6}>
            <IppTextField
              id="Reference"
              label={t("objBen:objects.rndExpense.fields.reference")}
              required={false}
              value={formik.values.Reference}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.Reference}
              errorsExpression={formik.errors.Reference}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              toolTip={t("objBen:objects.rndExpense.tooltips.reference")}
            />
          </Grid>
          <Grid item xs={6}>
            <IppAutocomplete
              id="CompanyID"
              required
              options={confirmedCompanies.sort((a, b) =>
                a.ShowAsActive.localeCompare(b.ShowAsActive)
              )}
              renderOption={(props: any, option: Company) => {
                return CompanyOptionsRender(props, option);
              }}
              groupBy={(option: any) => option.ShowAsActive}
              value={confirmedCompanies.find((obj) => {
                return obj.CompanyID === formik.values.CompanyID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("CompanyID", newValue.CompanyID);
                } else {
                  formik.setFieldValue("CompanyID", null);
                }
              }}
              label={t("objBen:objects.company.name")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.CompanyName}
              touchedFunction={formik.touched.CompanyID}
              errorFunction={formik.errors.CompanyID}
              textValueFunction={
                !companyIsLoading &&
                formik.values.CompanyID > 0 &&
                companiesById[formik.values.CompanyID]
                  ? companiesById[formik.values.CompanyID].CompanyName
                  : ""
              }
            />
          </Grid>
          {currentProfile.IsClient && (
            <Grid item xs={6}>
              <IppTextField
                id="SourceOfFunds"
                label={t("objBen:objects.rndExpense.fields.sourceoffunds")}
                value={formik.values.SourceOfFunds}
                onChangeFunction={formik.handleChange}
                touchedExpression={formik.touched.SourceOfFunds}
                errorsExpression={formik.errors.SourceOfFunds}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Grid>
          )}
          {ft_ben_Asset && (
            <>
              <Grid item xs={6}>
                <IppAutocomplete
                  id="AssetID"
                  options={assets}
                  value={assets.find((obj) => {
                    return obj.AssetID === formik.values.AssetID;
                  })}
                  onChangeFunction={(event: ChangeEvent, newValue: any) => {
                    if (newValue) {
                      formik.setFieldValue("AssetID", newValue.AssetID);
                    } else {
                      formik.setFieldValue("AssetID", null);
                      formik.setFieldValue("Applied", false);
                    }
                  }}
                  label={t("objBen:objects.asset.name")}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  optionLabelFunction={(option: any) => option.AssetName}
                  errorFunction={formik.errors.AssetID}
                  touchedFunction={formik.touched.AssetID}
                  textValueFunction={
                    assetsById[formik.values.AssetID]?.AssetName
                  }
                  placeholder="What asset was this expense applied to?"
                />
              </Grid>
              <Grid item xs={6}>
                <IppCheckbox
                  id="Applied"
                  label={t("objBen:objects.rndExpense.fields.applied")}
                  value={formik.values.Applied}
                  onChangeFunction={formik.handleChange}
                  isEditing={isEditing}
                  disabled={
                    !(formik.values.CompanyID > 0 && formik.values.AssetID > 0)
                  }
                />
              </Grid>
              {formik.values.Applied && (
                <Grid item xs={6}>
                  <IppDatePicker
                    id="AppliedDate"
                    label={t("objBen:objects.rndExpense.fields.applieddate")}
                    required={true}
                    value={ConvertDateOffset(formik.values.AppliedDate)}
                    onChangeFunction={(newValue: any) => {
                      formik.setFieldValue(
                        "AppliedDate",
                        ft_ben_AllocatedDate
                          ? GetJSYear(newValue, 1, 2)
                          : GetJSDate(newValue),
                        true
                      );
                      formik.setFieldTouched("AppliedDate", true, false);
                    }}
                    errorsExpression={formik.errors.AppliedDate}
                    touchedExpression={formik.touched.AppliedDate}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    disableFuture={true}
                    yearOnly={ft_ben_AllocatedDate}
                    disabled={!formik.values.Applied}
                    //toolTip="Year the expense was applied to the asset"
                  />
                </Grid>
              )}
            </>
          )}
          <Grid item xs={6}>
            <IppCheckbox
              id="InEligible"
              label={t("objBen:objects.rndExpense.fields.ineligible")}
              value={formik.values.InEligible}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("InEligible", newValue);
                } else {
                  formik.setFieldValue("InEligible", false);
                }
              }}
              isEditing={isEditing}
            />
          </Grid>
          {/* dynamic classification */}
          {isAdding && (
            <Grid xs={12} item>
              <IppFormDivider title="Detailed Expense Classifications" />
              <IppRnDClassifications
                id="RnDExpenses"
                //options={RnDClassifications}
                value={formik.values.RnDExpenses}
                onChangeFunction={(item: any) => {
                  formik.setFieldValue("RnDExpenses", item);
                }}
                touchedFunction={formik.touched.RnDExpenses}
                label={t("objBen:objects.rndExpense.tooltips.expenselist")}
                isEditing={isAdding}
                setIsEditing={setIsEditing}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <IppFormButtons
              isEditing={isEditing}
              isAdding={isAdding}
              setIsEditing={setIsEditing}
              resetFunction={() => formik.resetForm()}
              showDelete={
                currentProfile.IsClient ||
                currentProfile.CompanyID === formik.values.CompanyID
              }
              deleteFunction={handleDelete}
            />
          </Grid>
        </Grid>
      </form>
    );

  // --------------------------------- PAGE FORM ------------------------------

  let pageForm =
    expenseIsLoading ||
    clientIsLoading ||
    companyIsLoading ||
    assetIsLoading ||
    initiativeIsLoading ||
    clientCurrencyIsLoading ? (
      <LoadingIndicator />
    ) : isAdding ? (
      <Box display="flex" justifyContent="center">
        <Paper className={classes.boxSpace}>{editForm}</Paper>
      </Box>
    ) : (
      <Grid container spacing={1}>
        <Grid item xs={customBP ? 12 : 5}>
          {isEditing ? (
            <Dialog open={isEditing} fullWidth maxWidth="lg">
              <DialogContent>{editForm}</DialogContent>
            </Dialog>
          ) : (
            <div>{viewForm}</div>
          )}
        </Grid>
        <Grid item xs={customBP ? 12 : 7}>
          <Box>
            <Paper>
              {!isAdding && (
                <Root>
                  <Box display="flex" justifyContent="center">
                    <Paper className={classes.boxSpace}>
                      <Grid container>
                        <IppAttachmentInventory
                          recordType="RnDExpenses"
                          itemID={expenseData.RnDExpenseID}
                          companyID={formik.values.CompanyID}
                          projectID={0}
                          moduleID={1}
                        />
                      </Grid>
                    </Paper>
                  </Box>
                </Root>
              )}
            </Paper>
          </Box>
        </Grid>
      </Grid>
    );

  return <Root>{pageForm}</Root>;
};
