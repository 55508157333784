import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  WorkHourPeriod,
  WorkHourPeriodsResult,
  getWorkHourPeriods,
  getWorkHourPeriodsByProject,
  getWorkHourPeriodsByCompany,
  createWorkHourPeriod,
  updateWorkHourPeriod,
  deleteWorkHourPeriod,
} from "api/workHourPeriodAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface WorkHourPeriodState {
  workHourPeriodsById: Record<number, WorkHourPeriod>;
  workHourPeriodList: number[];
  isLoading: boolean;
  error: string | null;
}

const WorkHourPeriodInitialState: WorkHourPeriodState = {
  workHourPeriodsById: {},
  workHourPeriodList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: WorkHourPeriodState) {
  state.isLoading = true;
}

function loadingFailed(
  state: WorkHourPeriodState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const workHourPeriods = createSlice({
  name: "workHourPeriods",
  initialState: WorkHourPeriodInitialState,
  reducers: {
    getWorkHourPeriodsStart: startLoading,
    getWorkHourPeriodsSuccess(
      state,
      { payload }: PayloadAction<WorkHourPeriodsResult>
    ) {
      const { workHourPeriods } = payload;
      // console.log("PAYLOAD", workHourPeriods);
      state.isLoading = false;
      state.error = null;

      workHourPeriods.forEach((workHourPeriod) => {
        state.workHourPeriodsById[workHourPeriod.PeriodID] = workHourPeriod;
      });

      state.workHourPeriodList = workHourPeriods.map(
        (workHourPeriod) => workHourPeriod.PeriodID
      );
    },
    getWorkHourPeriodsFailure: loadingFailed,
    createWorkHourPeriodStart: startLoading,
    createWorkHourPeriodSuccess(
      state,
      { payload }: PayloadAction<WorkHourPeriod>
    ) {
      const { PeriodID } = payload;
      state.workHourPeriodsById[PeriodID] = payload;
      state.workHourPeriodList.push(PeriodID);

      state.isLoading = false;
      state.error = null;
    },
    updateWorkHourPeriodSuccess(
      state,
      { payload }: PayloadAction<WorkHourPeriod>
    ) {
      const { PeriodID } = payload;
      state.workHourPeriodsById[PeriodID] = payload;
      state.workHourPeriodList.push(PeriodID);

      state.isLoading = false;
      state.error = null;
    },
    deleteWorkHourPeriodSuccess(state, { payload }: PayloadAction<number>) {
      const PeriodID = payload;
      delete state.workHourPeriodsById[PeriodID];
      state.workHourPeriodList = state.workHourPeriodList.filter(
        (item) => item !== PeriodID
      );

      state.isLoading = false;
      state.error = null;
    },
    createWorkHourPeriodFailure: loadingFailed,
  },
});

export const {
  getWorkHourPeriodsStart,
  getWorkHourPeriodsSuccess,
  getWorkHourPeriodsFailure,
  createWorkHourPeriodStart,
  createWorkHourPeriodSuccess,
  updateWorkHourPeriodSuccess,
  deleteWorkHourPeriodSuccess,
  createWorkHourPeriodFailure,
} = workHourPeriods.actions;

export default workHourPeriods.reducer;

export const fetchWorkHourPeriods =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getWorkHourPeriodsStart());
      const workHourPeriods = await getWorkHourPeriods(accessToken);
      //console.log("RETURN", workHourPeriods);
      dispatch(getWorkHourPeriodsSuccess(workHourPeriods));
    } catch (err: any) {
      dispatch(getWorkHourPeriodsFailure(err.toString()));
    }
  };

export const fetchWorkHourPeriodsByProject =
  (accessToken: String, projectID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getWorkHourPeriodsStart());
      const workHourPeriods = await getWorkHourPeriodsByProject(
        accessToken,
        projectID
      );
      //console.log("RETURN", workHourPeriods);
      dispatch(getWorkHourPeriodsSuccess(workHourPeriods));
    } catch (err: any) {
      dispatch(getWorkHourPeriodsFailure(err.toString()));
    }
  };

export const fetchWorkHourPeriodsByCompany =
  (accessToken: String, companyID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getWorkHourPeriodsStart());
      const workHourPeriods = await getWorkHourPeriodsByCompany(
        accessToken,
        companyID
      );
      //console.log("RETURN", workHourPeriods);
      dispatch(getWorkHourPeriodsSuccess(workHourPeriods));
    } catch (err: any) {
      dispatch(getWorkHourPeriodsFailure(err.toString()));
    }
  };

export const addWorkHourPeriod =
  (accessToken: String, newWorkHourPeriod: Partial<WorkHourPeriod>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createWorkHourPeriodStart());
      const workHourPeriods = await createWorkHourPeriod(
        accessToken,
        newWorkHourPeriod
      );
      dispatch(createWorkHourPeriodSuccess(workHourPeriods));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(fetchWorkHourPeriods(accessToken));
      dispatch(push(`/benefits/workhours/${workHourPeriods.PeriodID}`));
    } catch (err: any) {
      dispatch(createWorkHourPeriodFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updWorkHourPeriod =
  (
    accessToken: String,
    workHourPeriodID: number,
    newWorkHourPeriod: Partial<WorkHourPeriod>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createWorkHourPeriodStart());
      const workHourPeriod = await updateWorkHourPeriod(
        accessToken,
        workHourPeriodID,
        newWorkHourPeriod
      );
      //console.log("UPDRETTHK", newWorkHourPeriod);
      dispatch(updateWorkHourPeriodSuccess(workHourPeriod));
      dispatch(fetchWorkHourPeriods(accessToken));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push(`/benefits/workhours/${workHourPeriod.PeriodID}`));
    } catch (err: any) {
      dispatch(createWorkHourPeriodFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delWorkHourPeriod =
  (accessToken: String, workHourPeriodID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createWorkHourPeriodStart());
      const result = await deleteWorkHourPeriod(accessToken, workHourPeriodID);
      dispatch(deleteWorkHourPeriodSuccess(workHourPeriodID));
      dispatch(push("/benefits/workhours/"));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
    } catch (err: any) {
      console.log("WHP DELETE ERROR", err);
      dispatch(createWorkHourPeriodFailure(err.toString()));
    }
  };
