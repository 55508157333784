import axiosClient from "./axiosClient";

export interface ClientOrganizationType {
  ClientOrganizationTypeID: number;
  ClientID: number;
  OrganizationTypeID: number;
  OrganizationTypeName: string;
  IsRequired: boolean;
}

export interface ClientOrganizationTypesResult {
  clientOrganizationTypes: ClientOrganizationType[];
}

export async function getClientOrganizationTypes(
  accessToken: String
): Promise<ClientOrganizationTypesResult> {
  const url = `/clientOrganizationType`;

  try {
    const { data } = await axiosClient.get<ClientOrganizationTypesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    // console.log("GETDATA", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createClientOrganizationType(
  accessToken: String,
  newClientOrganizationType: Partial<ClientOrganizationType>
): Promise<ClientOrganizationType> {
  const url = `/ClientOrganizationType`;

  try {
    const { data } = await axiosClient.post<ClientOrganizationType>(
      url,
      newClientOrganizationType,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateClientOrganizationType(
  accessToken: String,
  ClientOrganizationTypeID: number,
  newClientOrganizationType: Partial<ClientOrganizationType>
): Promise<ClientOrganizationType> {
  const url = `/ClientOrganizationType/` + ClientOrganizationTypeID;

  try {
    const { data } = await axiosClient.post<ClientOrganizationType>(
      url,
      newClientOrganizationType,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteClientOrganizationType(
  accessToken: String,
  ClientOrganizationTypeID: number
): Promise<string> {
  const url = `/ClientOrganizationType/` + ClientOrganizationTypeID;
  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    // console.log("DELDATA", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}
