import { styled, alpha } from "@mui/material/styles";
import { AppBar, Toolbar, Typography } from "@mui/material";
import { useTypedTranslation } from "utils/customHooks";

const PREFIX = "ErrorPage";

const classes = {
  logo: `${PREFIX}-logo`,
  header: `${PREFIX}-header`,
  grow: `${PREFIX}-grow`,
  spacer: `${PREFIX}-spacer`,
  search: `${PREFIX}-search`,
  searchIcon: `${PREFIX}-searchIcon`,
  inputRoot: `${PREFIX}-inputRoot`,
  inputInput: `${PREFIX}-inputInput`,
};

const Root = styled(AppBar)(({ theme }) => ({
  [`& .${classes.logo}`]: {
    paddingRight: theme.spacing(2),
  },

  [`&.${classes.header}`]: {
    spacing: 1,
    color: "#000",
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[800],
  },

  [`& .${classes.grow}`]: {
    flexGrow: 1,
  },

  [`& .${classes.spacer}`]: {
    flex: "1 1 auto",
  },

  [`& .${classes.search}`]: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.35),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.65),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },

  [`& .${classes.searchIcon}`]: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  [`& .${classes.inputRoot}`]: {
    color: "inherit",
  },

  [`& .${classes.inputInput}`]: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const ErrorPage = () => {
  const t = useTypedTranslation(["strGen"]);

  return (
    <Root>
      <AppBar position="static" component="header" className={classes.header}>
        <div>
          <Toolbar>
            <img
              src={process.env.PUBLIC_URL + "/logoSmall.png"}
              alt="NetBenefit"
              className={classes.logo}
            />

            <div className={classes.grow} />
          </Toolbar>
        </div>
        <div>
          <Typography align="center" style={{ backgroundColor: "#8fc9b0" }}>
            <h1>{t("strGen:pages.error.header")}</h1>
            <h2>{t("strGen:pages.error.waystosolve")}</h2>
            <li>{t("strGen:pages.error.checkurl")}</li>
            <li>{t("strGen:pages.error..checkusername")}</li>
            <li>{t("strGen:pages.error.checkpermissions")}</li>
          </Typography>
        </div>
      </AppBar>
    </Root>
  );
};

export default ErrorPage;
