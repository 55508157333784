import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchContacts } from "./ContactSlice";
import { useParams } from "react-router-dom";
import { ContactForm } from "./ContactForm";
import LoadingIndicator from "components/LoadingIndicator";
import { useTypedTranslation } from "utils/customHooks";
import { Trans } from "react-i18next";

interface ContactDetProps {
  contactID: string;
}

export const ContactDetailPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen"]);

  const {
    isLoading,
    error: compError,
    contactsById,
  } = useSelector((state: RootState) => state.contacts);
  const { ft_eng_PILSD } = useSelector((state: RootState) => state.client);

  // get contact ID from url param and attempt to find company in store
  const { contactID } = useParams<ContactDetProps>();
  const parsedContactID = parseInt(contactID, 10);

  const contact = contactsById[parseInt(contactID, 10)];

  useEffect(() => {
    (async () => {
      try {
        // can avoid going to the DB if we navigated from inventory
        //if (!contact || !contact.UserSurname) {
        if (
          !contact ||
          (ft_eng_PILSD &&
            !Object.keys(contact).includes("ProximityIndicatorLSDs"))
        ) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchContacts(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [contact, dispatch, getAccessTokenSilently]);

  if (!parsedContactID) {
    return (
      <div>
        <Trans
          i18nKey="strGen:errors.invalidid"
          /* eslint-disable-next-line jsx-a11y/heading-has-content */
          components={[<h1 />, <div />]}
          values={{
            variable: contactID,
            fieldname: t("objPlt:objects.contact.name"),
          }}
        />
      </div>
    );
  }

  if (compError) {
    return (
      <div>
        <h1>{t("strGen:errors.wentwrong")}</h1>
        <div>{compError.toString()}</div>
      </div>
    );
  }

  let contactDetView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>{contact && <ContactForm contact={contact} />}</div>
  );

  return <div id="contact-detail-page">{contactDetView}</div>;
};
