import axiosClient from "./axiosClient";

export interface Gender {
  GenderID: number;
  GenderText: string;
}

export interface GendersResult {
  genders: Gender[];
}

export async function getGenders(accessToken: String): Promise<GendersResult> {
  const url = `/gender`;

  try {
    const { data } = await axiosClient.get<GendersResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
