import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CommitmentStatusType,
  CommitmentStatusTypesResult,
  getCommitmentStatusTypes,
  createCommitmentStatusType,
  updateCommitmentStatusType,
  deleteCommitmentStatusType,
} from "api/commitments/commitmentStatusTypeAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface CommitmentStatusTypeState {
  commitmentStatusTypesById: Record<number, CommitmentStatusType>;
  commitmentStatusTypeList: number[];
  isLoading: boolean;
  isLoadingAdd: boolean;
  subCommitmentStatusTypeisLoading: boolean;
  isLoaded: boolean;
  error: string | null;
}

const CommitmentStatusTypeInitialState: CommitmentStatusTypeState = {
  commitmentStatusTypesById: {},
  commitmentStatusTypeList: [],
  isLoading: false,
  isLoadingAdd: false,
  subCommitmentStatusTypeisLoading: false,
  isLoaded: false,
  error: null,
};

function startLoading(state: CommitmentStatusTypeState) {
  state.isLoading = true;
}

function startLoadingAdd(state: CommitmentStatusTypeState) {
  state.isLoadingAdd = true;
}

function loadingFailed(
  state: CommitmentStatusTypeState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const commitmentStatusTypes = createSlice({
  name: "commitmentStatusTypes",
  initialState: CommitmentStatusTypeInitialState,
  reducers: {
    getCommitmentStatusTypesStart: startLoading,
    getCommitmentStatusTypesSuccess(
      state,
      { payload }: PayloadAction<CommitmentStatusTypesResult>
    ) {
      const { commitmentStatusTypes } = payload;

      state.isLoading = false;
      state.error = null;
      state.isLoaded = true;

      commitmentStatusTypes.forEach((commitmentStatusType) => {
        state.commitmentStatusTypesById[
          commitmentStatusType.CommitmentStatusTypeID
        ] = commitmentStatusType;
      });

      state.commitmentStatusTypeList = commitmentStatusTypes.map(
        (commitmentStatusType) => commitmentStatusType.CommitmentStatusTypeID
      );
    },
    getCommitmentStatusTypesFailure: loadingFailed,
    createCommitmentStatusTypeStart: startLoadingAdd,
    createCommitmentStatusTypeSuccess(
      state,
      { payload }: PayloadAction<CommitmentStatusType>
    ) {
      const { CommitmentStatusTypeID } = payload;
      state.commitmentStatusTypesById[CommitmentStatusTypeID] = payload;
      state.commitmentStatusTypeList.push(CommitmentStatusTypeID);

      state.isLoading = false;
      state.isLoadingAdd = false;
      state.error = null;
    },
    updateCommitmentStatusTypeSuccess(
      state,
      { payload }: PayloadAction<CommitmentStatusType>
    ) {
      const { CommitmentStatusTypeID } = payload;

      state.commitmentStatusTypesById[CommitmentStatusTypeID] = payload;
      //state.commitmentStatusTypeList.push(CommitmentStatusTypeID);

      state.isLoading = false;
      state.error = null;
    },
    deleteCommitmentStatusTypeSuccess(
      state,
      { payload }: PayloadAction<number>
    ) {
      const CommitmentStatusTypeID = payload;
      delete state.commitmentStatusTypesById[CommitmentStatusTypeID];
      state.commitmentStatusTypeList = state.commitmentStatusTypeList.filter(
        (item) => item !== CommitmentStatusTypeID
      );

      state.isLoading = false;
      state.error = null;
    },
    createCommitmentStatusTypeFailure: loadingFailed,
  },
});

export const {
  getCommitmentStatusTypesStart,
  getCommitmentStatusTypesSuccess,
  getCommitmentStatusTypesFailure,
  createCommitmentStatusTypeStart,
  createCommitmentStatusTypeSuccess,
  updateCommitmentStatusTypeSuccess,
  deleteCommitmentStatusTypeSuccess,
  createCommitmentStatusTypeFailure,
} = commitmentStatusTypes.actions;

export default commitmentStatusTypes.reducer;

export const fetchCommitmentStatusTypes =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCommitmentStatusTypesStart());
      const commitmentStatusTypes = await getCommitmentStatusTypes(accessToken);
      dispatch(getCommitmentStatusTypesSuccess(commitmentStatusTypes));
    } catch (err: any) {
      dispatch(getCommitmentStatusTypesFailure(err.toString()));
    }
  };

export const addCommitmentStatusType =
  (
    accessToken: String,
    newCommitmentStatusType: Partial<CommitmentStatusType>,
    setReturnRoute: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCommitmentStatusTypeStart());
      const commitmentStatusType = await createCommitmentStatusType(
        accessToken,
        newCommitmentStatusType
      );
      dispatch(createCommitmentStatusTypeSuccess(commitmentStatusType));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(
          push(
            `/commitmentStatusTypes/commitmentStatusTypes/${commitmentStatusType.CommitmentStatusTypeID}`
          )
        );
      }
    } catch (err: any) {
      dispatch(createCommitmentStatusTypeFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updCommitmentStatusType =
  (
    accessToken: String,
    commitmentStatusTypeID: number,
    newCommitmentStatusType: Partial<CommitmentStatusType>,
    setReturnRoute: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCommitmentStatusTypeStart());
      const commitmentStatusType = await updateCommitmentStatusType(
        accessToken,
        commitmentStatusTypeID,
        newCommitmentStatusType
      );

      dispatch(updateCommitmentStatusTypeSuccess(commitmentStatusType));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(
          push(
            "/commitmentStatusTypes/commitmentStatusTypes/" +
              commitmentStatusTypeID
          )
        );
      }
    } catch (err: any) {
      dispatch(createCommitmentStatusTypeFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delCommitmentStatusType =
  (accessToken: String, commitmentStatusTypeID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCommitmentStatusTypeStart());
      const result = await deleteCommitmentStatusType(
        accessToken,
        commitmentStatusTypeID
      );
      dispatch(deleteCommitmentStatusTypeSuccess(commitmentStatusTypeID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
      dispatch(push("/commitments/commitmentStatusTypes"));
    } catch (err: any) {
      dispatch(createCommitmentStatusTypeFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };
