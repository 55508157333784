import { Box, Collapse, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { IppFormDivider } from "./IppFormDivider";
import { useTypedTranslation } from "utils/customHooks";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";

interface CollapsibleHeadersProps {
  title: string;
  children: React.ReactNode;
}

const CollapsibleHeaders: React.FC<CollapsibleHeadersProps> = ({
  title,
  children,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const t = useTypedTranslation(["strGen"]);

  const toggleContent = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <>
      <Grid item xs={12}>
        <Box
          onClick={toggleContent}
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box style={{ display: "flex", alignItems: "center" }}>
            {isCollapsed ? (
              <ArrowRight style={{ marginRight: 8 }} />
            ) : (
              <ArrowDropDown style={{ marginRight: 8 }} />
            )}
            <IppFormDivider title={title} />
          </Box>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ fontSize: "12px" }}
          >
            {isCollapsed
              ? `[${t("strGen:buttons.clicktoexpand")}]`
              : `[${t("strGen:buttons.clicktocollapse")}]`}
          </Typography>
        </Box>
      </Grid>
      <Collapse in={!isCollapsed} style={{ width: "100%" }}>
        <Grid container spacing={1}>
          {children}
        </Grid>
      </Collapse>
    </>
  );
};

export default CollapsibleHeaders;
