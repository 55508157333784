import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Upload } from "@progress/kendo-react-upload";
import { read, utils } from "xlsx";
import { RootState } from "app/rootReducer";
import { Box, Grid, Paper } from "@mui/material";
import { IppFormHeader } from "components/IppFormHeader";
import { IppAutocomplete } from "components/IppAutocomplete";
import { FileUploadErrors } from "components/FileUploadErrors/FileUploadErrors";
import { fetchProjects } from "features/project/ProjectSlice";
import { IppCheckbox } from "components/IppCheckbox";
import { ConvertDateOffset, isExcelDateValid } from "utils/DateFunctions";
import { ProcessedUploadErrors } from "utils/types/UploadErrors.types";
import {
  generateInteractionErrorTitles,
  getNewInteractionConstructedErrors,
  getNewInteractionConstructedWarnings,
} from "./InteractionUploadErrors";
import {
  processConstructedErrorsObject,
  pushListError,
} from "utils/uploadUtils";
import { useTypedTranslation } from "utils/customHooks";

const PREFIX = "InteractionUploadPage";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1000,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const InteractionUploadPage = (props: any) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const t = useTypedTranslation(["objPlt", "strGen"]);
  const [A0token, setA0token] = useState("");
  const [projectId, setProjectId] = useState(-1);
  const [needsReview, setNeedsReview] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [warnings, setWarnings] = useState<ProcessedUploadErrors[]>([]);
  const [files, setFiles] = useState<Partial<any>>({
    files: [] as any,
    events: [] as any,
    errors: [] as any,
    emptyFile: false,
  });

  const [isEditing, setIsEditing] = useState(true);
  const [isAdding, setIsAdding] = useState(true);

  const { ft_eng_IntenalCommunication } = useSelector(
    (state: RootState) => state.client
  );

  const {
    projectList,
    projectsById,
    isLoading: projectIsLoading,
  } = useSelector((state: RootState) => state.projects);

  const projects = projectList.map((id) => projectsById[id]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        setA0token(accessToken);
        dispatch(fetchProjects(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [projectId, dispatch, getAccessTokenSilently]);

  const basePath = process.env.REACT_APP_API;
  const pathArray = window.location.pathname.split("/");
  const clientShortName = pathArray[1];
  const baseURL = `${basePath}/${clientShortName}/api`;

  const fileStatuses = [
    "UploadFailed",
    "Initial",
    "Selected",
    "Uploading",
    "Uploaded",
    "RemoveFailed",
    "Removing",
  ];

  const onBeforeUpload = (event: any) => {
    event.headers.Authorization = `Bearer ${A0token}`;
    event.additionalData.projectID = projectId;
    event.additionalData.needsReview = needsReview;
  };

  const onAdd = (event: any) => {
    const afterStateChange = () => {
      event.affectedFiles
        .filter((file: any) => !file.validationErrors)
        .forEach((file: any) => {
          const reader = new FileReader();

          reader.onloadend = (ev) => {
            var data = ev.target ? ev.target.result : null;
            var workbook = read(data, {
              type: "binary",
              cellDates: true,
            });
            var sheetName = workbook.SheetNames[0];

            try {
              var XL_row_object = utils.sheet_to_json(
                workbook.Sheets[sheetName],
                //when no header provided, would need to map columns to object properties manually
                { defval: "" }
              );

              var XL_header_object: any[] = utils.sheet_to_json(
                workbook.Sheets[sheetName],
                { header: 1 }
              );

              let sheetIsValid = true;
              let emptyFile = false;

              //validate data from excel sheet here
              //-------------------------------------
              const constructedErrors = getNewInteractionConstructedErrors();
              const constructedWarnings =
                getNewInteractionConstructedWarnings();

              //Check file isn't empty
              if (XL_row_object.length < 1) {
                emptyFile = true;
              } else {
                let stringPropList = [
                  { propName: "Title", req: true },
                  { propName: "Date", req: true },
                  { propName: "Communication Type", req: false },
                  { propName: "Status", req: false },
                  { propName: "Contacts", req: false },
                  { propName: "Details", req: false },
                  { propName: "Related Groups", req: false },
                  { propName: "Person Responsible", req: true },
                  { propName: "Initiated By", req: false },
                  { propName: "Email Content", req: false },
                  { propName: "Related Topics", req: false },
                ];

                if (ft_eng_IntenalCommunication) {
                  stringPropList = [
                    ...stringPropList,
                    {
                      propName: "Internal?",
                      req: false,
                    },
                  ];
                }

                //Check required headers exist
                const headers = XL_header_object[0];

                stringPropList.forEach((prop: any) => {
                  if (!headers.includes(prop.propName)) {
                    prop.req
                      ? pushListError(
                          constructedErrors.requiredColumn,
                          prop.propName
                        )
                      : pushListError(
                          constructedWarnings.optionalColumn,
                          prop.propName
                        );
                  }
                });

                //Check required data exists and is in the correct format.
                XL_row_object.forEach((row: any, ix: number) => {
                  let rowNum = row.__rowNum__ + 1;

                  stringPropList.forEach((prop: any) => {
                    if (
                      prop.req === true &&
                      headers.includes(prop.propName) &&
                      (prop.propName in row !== true ||
                        !row[prop.propName] ||
                        row[prop.propName]?.toString().trim() === "")
                    ) {
                      pushListError(constructedErrors.requiredData, {
                        header: prop.propName,
                        rowNumber: rowNum,
                      });
                    }

                    if (prop.propName in row === true) {
                      if (typeof row[prop.propName].toString() != "string") {
                        pushListError(constructedErrors.incorrectType, {
                          header: prop.propName,
                          rowNumber: rowNum,
                          expectedValue: t(
                            "strGen:uploaderrors.expectedvalues.textvalue"
                          ),
                        });
                      }
                      if (
                        row[prop.propName].toString().length >
                          (prop.propName === "Title" || prop.propName === "Date"
                            ? 250
                            : 50) &&
                        prop.propName !== "Details" &&
                        prop.propName !== "Email Content" &&
                        prop.propName !== "Legal Subdivision" &&
                        prop.propName !== "Internal Notes" &&
                        prop.propName !== "Phase" &&
                        prop.propName !== "Contacts" &&
                        prop.propName !== "Related Groups"
                      ) {
                        pushListError(constructedErrors.characterLimit, {
                          header: prop.propName,
                          rowNumber: rowNum,
                          expectedValue:
                            prop.propName === "Title" ||
                            prop.propName === "Date"
                              ? 250
                              : 50,
                        });
                      }
                    }
                  });

                  if ("Date" in row === true && row["Date"]) {
                    // If row["Date"] isn't parsed into a valid date, push error
                    if (
                      row["Date"] === undefined ||
                      !isExcelDateValid(row["Date"])
                    ) {
                      pushListError(constructedErrors.incorrectType, {
                        header: "Date",
                        rowNumber: rowNum,
                        expectedValue: t(
                          "strGen:uploaderrors.expectedvalues.validdateformat"
                        ),
                      });
                    }
                    row["Date"] = ConvertDateOffset(new Date(row["Date"]));
                  }

                  if ("Contacts" in row === true && row["Contacts"]) {
                    let nameList = row["Contacts"].split(",");

                    for (let index = 0; index < nameList.length; index++) {
                      let rawName = nameList[index].trim().split(" ");
                      const firstName = rawName.length > 1 ? rawName[0] : null;
                      const surname =
                        rawName.length > 1
                          ? rawName.slice(1).join(" ")
                          : rawName[0];
                      if (firstName?.length > 50 || surname?.length > 50) {
                        const listString = t(
                          "strGen:uploaderrors.rownumberwithvalue",
                          { rownumber: rowNum, value: nameList[index] }
                        );

                        pushListError(
                          constructedErrors.invalidContacts,
                          listString
                        );
                      }
                    }
                  }
                });
              }
              //-------------------------------------
              const fileErrors = processConstructedErrorsObject(
                constructedErrors,
                generateInteractionErrorTitles
              );

              const fileWarnings = processConstructedErrorsObject(
                constructedWarnings,
                generateInteractionErrorTitles
              );

              if (fileWarnings.length > 0) {
                setWarnings(fileWarnings);
              }

              if (fileErrors.length > 0 || emptyFile) {
                sheetIsValid = false;
              }

              if (sheetIsValid) {
                setProcessing(false);
              } else {
                setFiles({
                  files: [],
                  events: [
                    ...files.events,
                    `File failed validatation: ${event.affectedFiles[0].name}`,
                  ],
                  errors: fileErrors,
                  emptyFile: emptyFile,
                });
                setProcessing(false);
              }
            } catch (err: any) {
              throw err;
            }
          };

          reader.onerror = function (ex) {
            console.log(ex);
          };

          reader.readAsBinaryString(file.getRawFile());
        });
    };

    setProcessing(true);

    setFiles({
      files: event.newState,
      events: [
        ...files.events,
        `File selected: ${event.affectedFiles[0].name}`,
      ],
      errors: [],
    });
    afterStateChange();
    setProcessing(false);

    // clear state
    setWarnings([]);
  };

  const onRemove = (event: any) => {
    setFiles({
      files: event.newState,
      events: [...files.events, `File removed: ${event.affectedFiles[0].name}`],
      errors: [],
    });
    setWarnings([]);
    setProcessing(false);

    // clear state
    setWarnings([]);
  };

  const onProgress = (event: any) => {
    setFiles({
      files: event.newState,
      events: [
        ...files.events,
        `On Progress: ${event.affectedFiles[0].progress} %`,
      ],
      errors: [...files.errors],
    });
  };

  const onStatusChange = (event: any) => {
    const file = event.affectedFiles[0];

    // On success, clear state
    if (file.status === 4) {
      setWarnings([]);
    }

    setFiles({
      files: event.newState,
      events: [
        ...files.events,
        `File '${file.name}' status changed to: ${fileStatuses[file.status]}`,
      ],
      errors: [...files.errors],
    });
  };

  const onProjectChange = (event: any, newValue: any) => {
    if (newValue) {
      setProjectId(newValue.ProjectID);
    } else {
      setProjectId(-1);
    }
  };

  let interactionUploadView = (
    <Root>
      <Box display="flex" justifyContent="center">
        <Paper className={classes.boxSpace}>
          <Grid container className={classes.editForm} spacing={1}>
            <IppFormHeader
              title={t("objStk:objects.interaction.name_other")}
              isEditing={isEditing}
              isAdding={isAdding}
              returnPath="/engagement/communications"
            />
            <Grid item xs={8}>
              <IppAutocomplete
                id="ProjectID"
                options={projects}
                value={projects.find((obj) => {
                  return obj.ProjectID === projectId;
                })}
                onChangeFunction={onProjectChange}
                label={t("objStk:objects.interaction.fields.project")}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                optionLabelFunction={(option: any) => option.ProjectName}
                textValueFunction={
                  !projectIsLoading && projectId > 0
                    ? projectsById[projectId].ProjectName
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <FileUploadErrors
                errors={files.errors}
                emptyFile={files.emptyFile}
              />

              {files.errors.length === 0 ? (
                <FileUploadErrors errors={warnings} isWarning />
              ) : (
                ""
              )}
            </Grid>

            <Grid item xs={4}>
              <IppCheckbox
                id="NeedsReview"
                label={t("strGen:pages.interaction.upload.setasneedingreview")}
                value={needsReview}
                onChangeFunction={(newValue: any) =>
                  setNeedsReview(!needsReview)
                }
                isEditing={isEditing}
              />
            </Grid>
            <Grid item xs={12}>
              <Upload
                disabled={projectId === -1}
                showActionButtons={
                  !processing && files.errors.length === 0 && A0token !== ""
                }
                autoUpload={false}
                multiple={false}
                files={files.files}
                restrictions={{
                  allowedExtensions: [".xlsx"],
                }}
                withCredentials={true}
                onAdd={onAdd}
                onRemove={onRemove}
                onProgress={onProgress}
                onStatusChange={onStatusChange}
                onBeforeUpload={onBeforeUpload}
                saveUrl={`${baseURL}/interaction/upload`}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Root>
  );

  return <div id="interaction-upload-page">{interactionUploadView}</div>;
};
