import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  PaymentPeriod,
  PaymentPeriodsResult,
  getPaymentPeriods,
  createPaymentPeriod,
  updatePaymentPeriod,
  deletePaymentPeriod,
  getPaymentPeriodsByCompany,
  getPaymentPeriodsByProjectComponent,
  bulkApprovePaymentPeriod,
} from "api/benefits/paymentPeriodAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface PaymentPeriodState {
  paymentPeriodsById: Record<number, PaymentPeriod>;
  paymentPeriodList: number[];
  isLoading: boolean;
  isLoadingAdd: boolean;
  isLoaded: boolean;
  error: string | null;
  errorList: any[] | null;
}

const PaymentPeriodInitialState: PaymentPeriodState = {
  paymentPeriodsById: {},
  paymentPeriodList: [],
  isLoading: false,
  isLoadingAdd: false,
  isLoaded: false,
  error: null,
  errorList: null,
};

function startLoading(state: PaymentPeriodState) {
  state.isLoading = true;
}

function startLoadingAdd(state: PaymentPeriodState) {
  state.isLoadingAdd = true;
}

function loadingFailed(
  state: PaymentPeriodState,
  action: PayloadAction<{ status: string; message: string; errors: [] }>
) {
  state.isLoading = false;
  state.error = action.payload.message;
  if (action.payload.errors) {
    state.errorList = action.payload.errors;
  }
}

const paymentPeriods = createSlice({
  name: "paymentPeriods",
  initialState: PaymentPeriodInitialState,
  reducers: {
    getPaymentPeriodsStart: startLoading,
    getPaymentPeriodsSuccess(
      state,
      { payload }: PayloadAction<PaymentPeriodsResult>
    ) {
      const { paymentPeriods } = payload;

      state.isLoading = false;
      state.error = null;
      state.errorList = null;
      state.isLoaded = true;

      paymentPeriods.forEach((paymentPeriod) => {
        state.paymentPeriodsById[paymentPeriod.PaymentPeriodID] = paymentPeriod;
      });

      state.paymentPeriodList = paymentPeriods.map(
        (paymentPeriod) => paymentPeriod.PaymentPeriodID
      );
    },
    getPaymentPeriodsFailure: loadingFailed,
    createPaymentPeriodStart: startLoadingAdd,
    createPaymentPeriodSuccess(
      state,
      { payload }: PayloadAction<PaymentPeriod>
    ) {
      const { PaymentPeriodID } = payload;
      state.paymentPeriodsById[PaymentPeriodID] = payload;
      state.paymentPeriodList.push(PaymentPeriodID);

      state.isLoading = false;
      state.isLoadingAdd = false;
      state.error = null;
      state.errorList = null;
    },
    updatePaymentPeriodSuccess(
      state,
      { payload }: PayloadAction<PaymentPeriod>
    ) {
      const { PaymentPeriodID } = payload;

      state.paymentPeriodsById[PaymentPeriodID] = payload;
      //state.paymentPeriodList.push(PaymentPeriodID);

      state.isLoading = false;
      state.error = null;
      state.errorList = null;
    },
    deletePaymentPeriodSuccess(state, { payload }: PayloadAction<number>) {
      const PaymentPeriodID = payload;
      delete state.paymentPeriodsById[PaymentPeriodID];
      state.paymentPeriodList = state.paymentPeriodList.filter(
        (item) => item !== PaymentPeriodID
      );

      state.isLoading = false;
      state.error = null;
      state.errorList = null;
    },
    createPaymentPeriodFailure: loadingFailed,
  },
});

export const {
  getPaymentPeriodsStart,
  getPaymentPeriodsSuccess,
  getPaymentPeriodsFailure,
  createPaymentPeriodStart,
  createPaymentPeriodSuccess,
  updatePaymentPeriodSuccess,
  deletePaymentPeriodSuccess,
  createPaymentPeriodFailure,
} = paymentPeriods.actions;

export default paymentPeriods.reducer;

export const fetchPaymentPeriods =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getPaymentPeriodsStart());
      const paymentPeriods = await getPaymentPeriods(accessToken);
      dispatch(getPaymentPeriodsSuccess(paymentPeriods));
    } catch (err: any) {
      dispatch(getPaymentPeriodsFailure(err.toString()));
    }
  };

export const fetchPaymentPeriodsByCompany =
  (accessToken: String, companyID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getPaymentPeriodsStart());
      const payments = await getPaymentPeriodsByCompany(accessToken, companyID);
      dispatch(getPaymentPeriodsSuccess(payments));
    } catch (err: any) {
      dispatch(getPaymentPeriodsFailure(err.toString()));
    }
  };

export const fetchPaymentPeriodsByProjectComponent =
  (accessToken: String, projectComponentID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getPaymentPeriodsStart());
      const payments = await getPaymentPeriodsByProjectComponent(
        accessToken,
        projectComponentID
      );
      dispatch(getPaymentPeriodsSuccess(payments));
    } catch (err: any) {
      dispatch(getPaymentPeriodsFailure(err.toString()));
    }
  };

export const addPaymentPeriod =
  (
    accessToken: String,
    newPaymentPeriod: Partial<PaymentPeriod>,
    setReturnRoute: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createPaymentPeriodStart());
      const paymentPeriod = await createPaymentPeriod(
        accessToken,
        newPaymentPeriod
      );
      dispatch(createPaymentPeriodSuccess(paymentPeriod));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(fetchPaymentPeriods(accessToken));
        dispatch(
          push(`/benefits/paymentPeriods/${paymentPeriod.PaymentPeriodID}`)
        );
      }
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(createPaymentPeriodFailure(error.response.data));
        dispatch(openSnackBar(error.response.data.message, "error"));
      } else {
        dispatch(createPaymentPeriodFailure(error.toString()));
        dispatch(openSnackBar(Constants.FAILED, "error"));
      }
    }
  };

export const updPaymentPeriod =
  (
    accessToken: String,
    paymentPeriodID: number,
    newPaymentPeriod: Partial<PaymentPeriod>,
    setReturnRoute: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createPaymentPeriodStart());
      const paymentPeriod = await updatePaymentPeriod(
        accessToken,
        paymentPeriodID,
        newPaymentPeriod
      );

      dispatch(updatePaymentPeriodSuccess(paymentPeriod));
      dispatch(fetchPaymentPeriods(accessToken));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(push("/benefits/paymentPeriods/" + paymentPeriodID));
      }
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(createPaymentPeriodFailure(error.response.data));
        dispatch(openSnackBar(error.response.data.message, "error"));
      } else {
        dispatch(createPaymentPeriodFailure(error.toString()));
        dispatch(openSnackBar(Constants.FAILED, "error"));
      }
    }
  };

export const bulkApprovePayPeriod =
  (accessToken: String, paymentPeriods: PaymentPeriod[]): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createPaymentPeriodStart());
      const paymentPeriod = await bulkApprovePaymentPeriod(
        accessToken,
        paymentPeriods
      );

      dispatch(updatePaymentPeriodSuccess(paymentPeriod));

      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(fetchPaymentPeriods(accessToken));
      dispatch(push("/benefits/paymentPeriods"));
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(createPaymentPeriodFailure(error.response.data));
        dispatch(openSnackBar(error.response.data.message, "error"));
      } else {
        dispatch(createPaymentPeriodFailure(error.toString()));
        dispatch(openSnackBar(Constants.FAILED, "error"));
      }
    }
  };

export const delPaymentPeriod =
  (accessToken: String, paymentPeriodID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createPaymentPeriodStart());
      const result = await deletePaymentPeriod(accessToken, paymentPeriodID);
      dispatch(deletePaymentPeriodSuccess(paymentPeriodID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
      dispatch(push("/benefits/paymentPeriods"));
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(createPaymentPeriodFailure(error.response.data));
        dispatch(openSnackBar(error.response.data.message, "error"));
      } else {
        dispatch(createPaymentPeriodFailure(error.toString()));
        dispatch(openSnackBar(Constants.FAILED, "error"));
      }
    }
  };
