import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { Alert } from "@mui/material";
import LoadingIndicator from "components/LoadingIndicator";
import { useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { fetchEnTExpenses } from "./EnTExpenseSlice";
import { EnTExpenseForm } from "./EnTExpenseForm";
import { useTypedTranslation } from "utils/customHooks";

interface EnTExpenseDetProps {
  entExpenseID: string;
}

export const EnTExpenseDetailPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  const { isLoading, error, errorList, entExpensesById } = useSelector(
    (state: RootState) => state.entExpenses
  );

  const { entExpenseID } = useParams<EnTExpenseDetProps>();
  const itemID = parseInt(entExpenseID, 10);

  const entExpense = entExpensesById[itemID];

  useEffect(() => {
    (async () => {
      try {
        // can avoid going to the DB if we navigated from inventory
        if (!entExpense) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchEnTExpenses(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [entExpense, dispatch, getAccessTokenSilently]);

  let entExpenseDetView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      {entExpense && (
        <>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              <span>
                <b>{t("strGen:errors.wentwrong")}</b> - {error.toString()}
                {errorList && (
                  <ul>
                    {errorList.map((error: any) => {
                      return (
                        <li key={error.param}>
                          <b>{error.param}:</b> {error.msg}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </span>
            </Alert>
          )}
          {!itemID && (
            <Alert severity="error" sx={{ mb: 2 }}>
              <span>
                <Trans
                  i18nKey="strGen:errors.invalidid"
                  /* eslint-disable-next-line jsx-a11y/heading-has-content */
                  components={[<h1 />, <div />]}
                  values={{
                    variable: entExpenseID,
                    fieldname: t("objBen:objects.entExpense.ent"),
                  }}
                />
              </span>
            </Alert>
          )}
          <EnTExpenseForm entExpense={entExpense} />
        </>
      )}
    </div>
  );

  return <div id="entExpense-detail-page">{entExpenseDetView}</div>;
};
