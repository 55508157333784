import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchPaymentPeriods } from "./PaymentPeriodSlice";
import { useParams } from "react-router-dom";
import { Alert } from "@mui/material";
import { PaymentPeriodForm } from "./PaymentPeriodForm";
import LoadingIndicator from "components/LoadingIndicator";
import { Trans, useTranslation } from "react-i18next";

interface PaymentPeriodDetProps {
  paymentPeriodID: string;
}

export const PaymentPeriodDetailPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation(["objStk", "objPlt", "strGen", "objCom"]);

  const { isLoading, error, errorList, paymentPeriodsById } = useSelector(
    (state: RootState) => state.paymentPeriods
  );

  // get paymentPeriod ID from url param and attempt to find company in store
  const { paymentPeriodID } = useParams<PaymentPeriodDetProps>();
  const itemID = parseInt(paymentPeriodID, 10);

  const paymentPeriod = paymentPeriodsById[parseInt(paymentPeriodID, 10)];

  useEffect(() => {
    (async () => {
      try {
        // can avoid going to the DB if we navigated from inventory
        if (!paymentPeriod) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchPaymentPeriods(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [paymentPeriod, dispatch, getAccessTokenSilently]);

  let paymentPeriodDetView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      {paymentPeriod && (
        <>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              <span>
                <b>{t("strGen:errors.wentwrong")}</b> - {error.toString()}
                {errorList && (
                  <ul>
                    {errorList.map((error: any) => {
                      return (
                        <li key={error.param}>
                          <b>{error.param}:</b> {error.msg}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </span>
            </Alert>
          )}
          {!itemID && (
            <Alert severity="error" sx={{ mb: 2 }}>
              <span>
                <Trans
                  i18nKey="strGen:errors.invalidid"
                  /* eslint-disable-next-line jsx-a11y/heading-has-content */
                  components={[<h1 />, <div />]}
                  values={{
                    variable: paymentPeriodID,
                    fieldname: t("objBen:objects.spendreporting.name"),
                  }}
                />
              </span>
            </Alert>
          )}
          <PaymentPeriodForm paymentPeriod={paymentPeriod} />
        </>
      )}
    </div>
  );

  return <div id="paymentPeriod-detail-page">{paymentPeriodDetView}</div>;
};
