import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  Notification,
  NotificationsResult,
  getNotifications,
  createNotification,
  updateNotification,
  deleteNotification,
  getNotificationsByUser,
} from "api/notificationAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface NotificationState {
  notificationsById: Record<number, Notification>;
  notificationList: number[];
  isLoading: boolean;
  error: string | null;
}

const NotificationInitialState: NotificationState = {
  notificationsById: {},
  notificationList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: NotificationState) {
  state.isLoading = true;
}

function loadingFailed(
  state: NotificationState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const notifications = createSlice({
  name: "notifications",
  initialState: NotificationInitialState,
  reducers: {
    getNotificationsStart: startLoading,
    getNotificationsSuccess(
      state,
      { payload }: PayloadAction<NotificationsResult>
    ) {
      const { notifications } = payload;
      // console.log("PAYLOAD", notifications);
      state.isLoading = false;
      state.error = null;

      notifications.forEach((notification) => {
        state.notificationsById[notification.NotificationID] = notification;
      });

      state.notificationList = notifications.map(
        (notification) => notification.NotificationID
      );
    },
    getNotificationsFailure: loadingFailed,
    createNotificationStart: startLoading,
    createNotificationSuccess(state, { payload }: PayloadAction<Notification>) {
      const { NotificationID } = payload;
      state.notificationsById[NotificationID] = payload;
      state.notificationList.push(NotificationID);

      state.isLoading = false;
      state.error = null;
    },
    updateNotificationSuccess(state, { payload }: PayloadAction<Notification>) {
      const { NotificationID } = payload;
      state.notificationsById[NotificationID] = payload;
      state.notificationList.push(NotificationID);

      state.isLoading = false;
      state.error = null;
    },
    deleteNotificationSuccess(state, { payload }: PayloadAction<number>) {
      const NotificationID = payload;
      delete state.notificationsById[NotificationID];
      state.notificationList = state.notificationList.filter(
        (item) => item !== NotificationID
      );

      state.isLoading = false;
      state.error = null;
    },
    createNotificationFailure: loadingFailed,
  },
});

export const {
  getNotificationsStart,
  getNotificationsSuccess,
  getNotificationsFailure,
  createNotificationStart,
  createNotificationSuccess,
  updateNotificationSuccess,
  deleteNotificationSuccess,
  createNotificationFailure,
} = notifications.actions;

export default notifications.reducer;

export const fetchNotifications =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getNotificationsStart());
      const notifications = await getNotifications(accessToken);
      //console.log("RETURN", notifications);
      dispatch(getNotificationsSuccess(notifications));
    } catch (err: any) {
      dispatch(getNotificationsFailure(err.toString()));
    }
  };

export const fetchNotificationsByUser =
  (accessToken: String, userAccountID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getNotificationsStart());
      const employees = await getNotificationsByUser(
        accessToken,
        userAccountID
      );
      //console.log("RETURN", notifications);
      dispatch(getNotificationsSuccess(employees));
    } catch (err: any) {
      dispatch(getNotificationsFailure(err.toString()));
    }
  };

export const addNotification =
  (accessToken: String, newNotification: Partial<Notification>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createNotificationStart());
      const notification = await createNotification(
        accessToken,
        newNotification
      );
      dispatch(createNotificationSuccess(notification));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push("/notifications/new"));
    } catch (err: any) {
      dispatch(createNotificationFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updNotification =
  (
    accessToken: String,
    notificationID: number,
    newNotification: Partial<Notification>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createNotificationStart());
      const notification = await updateNotification(
        accessToken,
        notificationID,
        newNotification
      );
      dispatch(updateNotificationSuccess(notification));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push("/notifications/" + notificationID));
    } catch (err: any) {
      dispatch(createNotificationFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delNotification =
  (accessToken: String, notificationID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createNotificationStart());
      const result = await deleteNotification(accessToken, notificationID);
      dispatch(deleteNotificationSuccess(notificationID));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push("/notifications/" + notificationID));
    } catch (err: any) {
      dispatch(createNotificationFailure(err.toString()));
    }
  };
