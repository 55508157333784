import axiosClient from "./axiosClient";

export interface CustomNotification {
  SubjectLine: string;
  SummaryContent: string;
  TemplateContent: string;
  SendEmail: boolean;
  SendInApp: boolean;
  Users: any;
}

export interface CustomNotificationsResult {
  customNotifications: CustomNotification[];
}

export async function createCustomNotification(
  accessToken: String,
  newCommInv: Partial<CustomNotification>
): Promise<CustomNotification> {
  const url = `/customNotification`;

  try {
    const { data } = await axiosClient.post<CustomNotification>(
      url,
      newCommInv,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}
