import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ActionType,
  ActionTypesResult,
  getActionTypes,
  createActionType,
  updateActionType,
  deleteActionType,
} from "api/commitments/actionTypeAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface ActionTypeState {
  actionTypesById: Record<number, ActionType>;
  actionTypeList: number[];
  isLoading: boolean;
  isLoadingAdd: boolean;
  subActionTypeisLoading: boolean;
  isLoaded: boolean;
  error: string | null;
}

const ActionTypeInitialState: ActionTypeState = {
  actionTypesById: {},
  actionTypeList: [],
  isLoading: false,
  isLoadingAdd: false,
  subActionTypeisLoading: false,
  isLoaded: false,
  error: null,
};

function startLoading(state: ActionTypeState) {
  state.isLoading = true;
}

function startLoadingAdd(state: ActionTypeState) {
  state.isLoadingAdd = true;
}

function loadingFailed(state: ActionTypeState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const actionTypes = createSlice({
  name: "actionTypes",
  initialState: ActionTypeInitialState,
  reducers: {
    getActionTypesStart: startLoading,
    getActionTypesSuccess(
      state,
      { payload }: PayloadAction<ActionTypesResult>
    ) {
      const { actionTypes } = payload;

      state.isLoading = false;
      state.error = null;
      state.isLoaded = true;

      actionTypes.forEach((actionType) => {
        state.actionTypesById[actionType.ActionTypeID] = actionType;
      });

      state.actionTypeList = actionTypes.map(
        (actionType) => actionType.ActionTypeID
      );
    },
    getActionTypesFailure: loadingFailed,
    createActionTypeStart: startLoadingAdd,
    createActionTypeSuccess(state, { payload }: PayloadAction<ActionType>) {
      const { ActionTypeID } = payload;
      state.actionTypesById[ActionTypeID] = payload;
      state.actionTypeList.push(ActionTypeID);

      state.isLoading = false;
      state.isLoadingAdd = false;
      state.error = null;
    },
    updateActionTypeSuccess(state, { payload }: PayloadAction<ActionType>) {
      const { ActionTypeID } = payload;

      state.actionTypesById[ActionTypeID] = payload;
      //state.actionTypeList.push(ActionTypeID);

      state.isLoading = false;
      state.error = null;
    },
    deleteActionTypeSuccess(state, { payload }: PayloadAction<number>) {
      const ActionTypeID = payload;
      delete state.actionTypesById[ActionTypeID];
      state.actionTypeList = state.actionTypeList.filter(
        (item) => item !== ActionTypeID
      );

      state.isLoading = false;
      state.error = null;
    },
    createActionTypeFailure: loadingFailed,
  },
});

export const {
  getActionTypesStart,
  getActionTypesSuccess,
  getActionTypesFailure,
  createActionTypeStart,
  createActionTypeSuccess,
  updateActionTypeSuccess,
  deleteActionTypeSuccess,
  createActionTypeFailure,
} = actionTypes.actions;

export default actionTypes.reducer;

export const fetchActionTypes =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getActionTypesStart());
      const actionTypes = await getActionTypes(accessToken);
      dispatch(getActionTypesSuccess(actionTypes));
    } catch (err: any) {
      dispatch(getActionTypesFailure(err.toString()));
    }
  };

export const addActionType =
  (
    accessToken: String,
    newActionType: Partial<ActionType>,
    setReturnRoute: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createActionTypeStart());
      const actionType = await createActionType(accessToken, newActionType);
      dispatch(createActionTypeSuccess(actionType));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(push(`/actionTypes/actionTypes/${actionType.ActionTypeID}`));
      }
    } catch (err: any) {
      dispatch(createActionTypeFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updActionType =
  (
    accessToken: String,
    actionTypeID: number,
    newActionType: Partial<ActionType>,
    setReturnRoute: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createActionTypeStart());
      const actionType = await updateActionType(
        accessToken,
        actionTypeID,
        newActionType
      );

      dispatch(updateActionTypeSuccess(actionType));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(push("/actionTypes/actionTypes/" + actionTypeID));
      }
    } catch (err: any) {
      dispatch(createActionTypeFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delActionType =
  (accessToken: String, actionTypeID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createActionTypeStart());
      const result = await deleteActionType(accessToken, actionTypeID);
      dispatch(deleteActionTypeSuccess(actionTypeID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
      dispatch(push("/actionTypes/actionTypes"));
    } catch (err: any) {
      dispatch(createActionTypeFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };
