// RejectDialog.tsx
import { useFormik } from "formik";
import * as yup from "yup"; // For form validation
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import { Save, CallMerge } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { IppTextField } from "components/IppTextField";
import { IppAutocomplete } from "components/IppAutocomplete";
import { t } from "i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import Fuse from "fuse.js";
import { Company } from "api/companyAPI";
import { rjtCompany } from "./CompaniesSlice";
import { ChangeEvent } from "react";
import { IppButton } from "components/Buttons/IppButton";
import { IppCancelButton } from "components/Buttons/IppCancelButton";

const validationSchema = yup.object({
  RejectReason: yup.string().required("Reject Reason is required"),
});

const PREFIX = "CompanyRejectDialog";

const classes = {
  boxSpace: `${PREFIX}-boxSpace`,
  kgrid: `${PREFIX}-kgrid`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
  [`& .${classes.kgrid}`]: {
    tableLayout: "fixed",
  },
}));

interface rejectDialogProps {
  compData: Company;
  setShowRejectInfo: any;
}

export const CompanyRejectDialog = (props: rejectDialogProps) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const { compData, setShowRejectInfo } = props;

  const { companiesById, companyList } = useSelector(
    (state: RootState) => state.companies
  );

  const companies = companyList
    .map((companyID) => companiesById[companyID])
    .filter((item) => item.CompanyID != compData.CompanyID);

  const submitFunc = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        dispatch(
          rjtCompany(
            accessToken,
            compData.CompanyID,
            values.RejectReason,
            values.MergeID
          )
        );
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const subformik = useFormik({
    initialValues: {
      RejectReason: undefined,
      MergeID: -1,
    },
    validationSchema: validationSchema,
    onSubmit: submitFunc,
  });

  // ----------------------- fuse.js ----------------------------

  // Using Fuse.js to search for matching company data
  const fuseOptions = {
    // Customizable keys to search by
    //includeScore: true,
    threshold: 0.3, // lower means higher accuracy
    keys: ["CompanyName", "ProvinceState"],
  };
  const fuseQueries = {
    CompanyName: compData.CompanyName,
    ProvinceState: compData.ProvinceState ? compData.ProvinceState : "",
  };

  const fuse = new Fuse(companies, fuseOptions);

  const searchResults = fuse.search(fuseQueries).map((id) => id.item);

  // -------------------- fuse ----------------------------------

  return (
    <Root id="company-reject-dialog">
      <form onSubmit={subformik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            Enter reason for rejecting this company or pick a similar already
            existing company to merge into.
          </Grid>
          <Grid item xs={12}>
            <IppTextField
              id="RejectReason"
              label="Reject Reason"
              required
              value={subformik.values.RejectReason}
              onChangeFunction={subformik.handleChange}
              isEditing={true}
              setIsEditing={null}
              touchedExpression={subformik.touched.RejectReason}
              errorsExpression={subformik.errors.RejectReason}
            />
          </Grid>
          <Grid item xs={12}>
            {`Here are some similar companies we found for ${compData.CompanyName}.`}
          </Grid>
          <Grid item xs={12}>
            {searchResults.map((item, index) => (
              <>
                <Grid
                  container
                  alignItems={"center"}
                  justifyContent="space-between"
                  style={{ border: "2px solid yellow", padding: "8px" }}
                >
                  <Typography key={index}>
                    {item.CompanyName} - {item.ProvinceState} - {item.TownCity}
                  </Typography>
                </Grid>
              </>
            ))}
          </Grid>
          <Grid item xs={12}>
            <IppAutocomplete
              id="MergeID"
              label="Existing Company"
              //required
              value={companies.find((obj) => {
                return obj.CompanyID === subformik.values.MergeID;
              })}
              options={companies}
              optionLabelFunction={(option: any) => option.CompanyName}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  subformik.setFieldValue("MergeID", newValue.CompanyID);
                } else {
                  subformik.setFieldValue("MergeID", -1);
                }
              }}
              setIsEditing={null}
              isEditing={true}
              touchedFunction={subformik.touched.MergeID}
              errorFunction={subformik.errors.MergeID}
            />
          </Grid>
          <DialogActions>
            <IppButton
              variant="outlined"
              color="secondary"
              type="submit"
              hidden={subformik.values.MergeID <= 0}
              startIcon={<CallMerge />}
            >
              Merge
            </IppButton>
            <IppButton
              color="success"
              hidden={subformik.values.MergeID > 0}
              type="submit"
              onClick={() => {
                subformik.setFieldValue("MergeID", -1);
              }}
              startIcon={<Save />}
            >
              Reject
            </IppButton>
            <IppCancelButton onClick={() => setShowRejectInfo(false)} />
          </DialogActions>
        </Grid>
      </form>
    </Root>
  );
};
