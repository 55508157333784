import React from "react";
import { MenuItem, ListItemIcon } from "@mui/material";
import { Link } from "react-router-dom";

interface ModuleSelectorProps {
  handleClose: () => void;
  clientModules: Array<{
    ClientModuleID: number;
    ModuleID: number;
    ShortName: string;
    ModuleName: string;
  }>;
  iconList: (moduleID: number) => React.ReactNode;
}

const ModuleSelector: React.FC<ModuleSelectorProps> = ({
  handleClose,
  clientModules,
  iconList,
}) => {
  return (
    <>
      {clientModules.length > 0 ? (
        clientModules.map((item) => (
          <MenuItem
            key={item.ClientModuleID}
            component={Link}
            to={`/${item.ShortName}`}
            onClick={handleClose}
          >
            <ListItemIcon>{iconList(item.ModuleID)}</ListItemIcon>
            {item.ModuleName}
          </MenuItem>
        ))
      ) : (
        <MenuItem>No modules available</MenuItem>
      )}
    </>
  );
};

export default ModuleSelector;
