import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Tier,
  TiersResult,
  getAllTiers,
  createTier,
  updateTier,
  deleteTier,
} from "api/tierAPI";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { push } from "redux-first-history";

interface TierState {
  tiersById: Record<number, Tier>;
  tierList: number[];
  tierIsLoading: boolean;
  tierError: string | null;
}

const TierInitialState: TierState = {
  tiersById: {},
  tierList: [],
  tierIsLoading: false,
  tierError: null,
};

function startLoading(state: TierState) {
  state.tierIsLoading = true;
}

function loadingFailed(state: TierState, action: PayloadAction<string>) {
  state.tierIsLoading = false;
  state.tierError = action.payload;
}

const tiers = createSlice({
  name: "tiers",
  initialState: TierInitialState,
  reducers: {
    getTiersStart: startLoading,
    getTiersSuccess(state, { payload }: PayloadAction<TiersResult>) {
      const { tiers } = payload;
      state.tierIsLoading = false;
      state.tierError = null;

      tiers.forEach((tier) => {
        state.tiersById[tier.TierID] = tier;
      });

      state.tierList = tiers.map((tier) => tier.TierID);
    },
    getTiersFailure: loadingFailed,
    createTierStart: startLoading,
    createTierSuccess(state, { payload }: PayloadAction<Tier>) {
      const { TierID } = payload;
      state.tiersById[TierID] = payload;
      state.tierList.push(TierID);

      state.tierIsLoading = false;
      state.tierError = null;
    },
    createTierFailure: loadingFailed,
    updateTierStart: startLoading,
    updateTierSuccess(state, { payload }: PayloadAction<Tier>) {
      const { TierID } = payload;
      state.tiersById[TierID] = payload;

      state.tierIsLoading = false;
      state.tierError = null;
    },
    updateTierFailure: loadingFailed,
    deleteTierStart: startLoading,
    deleteTierSuccess(state, { payload }: PayloadAction<number>) {
      const TierID = payload;
      delete state.tiersById[TierID];
      state.tierList = state.tierList.filter((item) => item !== TierID);

      state.tierIsLoading = false;
      state.tierError = null;
    },
    deleteTierFailure: loadingFailed,
  },
});

export const {
  getTiersStart,
  getTiersSuccess,
  getTiersFailure,
  createTierStart,
  createTierSuccess,
  createTierFailure,
  updateTierStart,
  updateTierSuccess,
  updateTierFailure,
  deleteTierStart,
  deleteTierSuccess,
  deleteTierFailure,
} = tiers.actions;

export default tiers.reducer;

export const fetchAllTiers =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getTiersStart());
      const tiers = await getAllTiers(accessToken);
      dispatch(getTiersSuccess(tiers));
    } catch (err: any) {
      dispatch(getTiersFailure(err.toString()));
    }
  };

export const addTier =
  (accessToken: String, newTier: Partial<Tier>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createTierStart());
      const tier = await createTier(accessToken, newTier);
      dispatch(createTierSuccess(tier));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push("/admin/tiers"));
    } catch (err: any) {
      dispatch(createTierFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updTier =
  (accessToken: String, tierID: number, updatedTier: Partial<Tier>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateTierStart());
      const tier = await updateTier(accessToken, tierID, updatedTier);
      dispatch(updateTierSuccess(tier));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push("/admin/tiers"));
    } catch (err: any) {
      dispatch(updateTierFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delTier =
  (accessToken: String, tierID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteTierStart());
      await deleteTier(accessToken, tierID);
      dispatch(deleteTierSuccess(tierID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(deleteTierFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };
