import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "app/store";
import {
  InteractionPhase,
  InteractionPhasesResult,
  getInteractionPhases,
  getInteractionPhasesByInteraction,
} from "api/stakeholder/interactionPhaseAPI";

interface InteractionPhaseState {
  interactionPhasesById: Record<number, InteractionPhase>;
  interactionPhaseList: number[];
  isLoadingAdd: boolean;
  isLoading: boolean;
  isLoaded: boolean;
  error: string | null;
}

const InteractionPhaseInitialState: InteractionPhaseState = {
  interactionPhasesById: {},
  interactionPhaseList: [],
  isLoading: false,
  isLoadingAdd: false,
  isLoaded: false,
  error: null,
};

function startLoading(state: InteractionPhaseState) {
  state.isLoading = true;
}

function startLoadingAdd(state: InteractionPhaseState) {
  state.isLoadingAdd = true;
}

function loadingFailed(
  state: InteractionPhaseState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const interactionPhases = createSlice({
  name: "interactionPhases",
  initialState: InteractionPhaseInitialState,
  reducers: {
    getInteractionPhasesStart: startLoading,
    getInteractionPhasesSuccess(
      state,
      { payload }: PayloadAction<InteractionPhasesResult>
    ) {
      const { interactionPhases } = payload;

      state.isLoading = false;
      state.error = null;
      state.isLoaded = true;

      interactionPhases.forEach((interactionPhase: any) => {
        state.interactionPhasesById[interactionPhase.InteractionPhaseID] =
          interactionPhase;
      });

      state.interactionPhaseList = interactionPhases.map(
        (interactionPhase: any) => interactionPhase.InteractionPhaseID
      );
    },
    getInteractionPhasesFailure: loadingFailed,
  },
});

export const {
  getInteractionPhasesStart,
  getInteractionPhasesSuccess,
  getInteractionPhasesFailure,
} = interactionPhases.actions;

export default interactionPhases.reducer;

export const fetchInteractionPhases =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getInteractionPhasesStart());
      const interactionPhases = await getInteractionPhases(accessToken);
      dispatch(getInteractionPhasesSuccess(interactionPhases));
    } catch (err: any) {
      dispatch(getInteractionPhasesFailure(err.toString()));
    }
  };

export const fetchInteractionPhasesByInteraction =
  (accessToken: String, interactionID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getInteractionPhasesStart());
      const interactionPhases = await getInteractionPhasesByInteraction(
        accessToken,
        interactionID
      );
      dispatch(getInteractionPhasesSuccess(interactionPhases));
    } catch (err: any) {
      dispatch(getInteractionPhasesFailure(err.toString()));
    }
  };

export {};
