import axiosClient from "api/axiosClient";

export interface JobHistory {
  JobHistoryID: number;
  EmployeeID: number;
  JobPositionID: number;
  ApprenticeStatus: string;
  ApprenticeYear: number;
  EndDate: Date;
  TempEmployee: boolean;
  JobPositionName?: string;
  StartDate?: Date | null;
}

export interface ExtendedJobHistory extends JobHistory {
  PositionStatus?: string;
}

export interface JobHistoryResult {
  jobHistory: JobHistory[];
}

const BASE_URL = "/jobHistory";

export const getJobHistoryByEmployee = async (
  accessToken: String,
  employeeID: number
): Promise<JobHistoryResult> => {
  const url = `${BASE_URL}/${employeeID}`;
  try {
    const { data } = await axiosClient.get<JobHistoryResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createJobHistory = async (
  accessToken: String,
  jobHistory: Partial<JobHistory>
): Promise<JobHistory> => {
  const url = BASE_URL;

  try {
    const { data } = await axiosClient.post<JobHistory>(url, jobHistory, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateJobHistory = async (
  accessToken: String,
  jobHistory: JobHistory
): Promise<JobHistory> => {
  const url = `${BASE_URL}/${jobHistory.JobHistoryID}`;

  try {
    const { data } = await axiosClient.post<JobHistory>(url, jobHistory, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteJobHistory = async (
  accessToken: String,
  jobHistoryID: number
): Promise<string> => {
  const url = `${BASE_URL}/${jobHistoryID}`;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
