import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { useParams } from "react-router-dom";
import { Alert } from "@mui/material";
import LoadingIndicator from "components/LoadingIndicator";
import { fetchContracts } from "./ContractSlice";
import { ContractForm } from "./ContractForm";

interface ContractDetProps {
  contractID: string;
}

export const ContractDetailPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const { isLoading, error, contractsById, contractList } = useSelector(
    (state: RootState) => state.contracts
  );

  // get contract ID from url param and attempt to find company in store
  const { contractID } = useParams<ContractDetProps>();
  const itemID = parseInt(contractID, 10);

  const contract = contractsById[parseInt(contractID, 10)];

  useEffect(() => {
    (async () => {
      try {
        // can avoid going to the DB if we navigated from inventory
        if (!contract) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchContracts(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [contract, dispatch, getAccessTokenSilently]);

  let contractDetView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      {contract && (
        <>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              <span>
                <b>Something went wrong</b> - {error.toString()}
              </span>
            </Alert>
          )}
          {typeof itemID != "number" && (
            <Alert severity="error" sx={{ mb: 2 }}>
              <span>
                Something went wrong - {itemID} is not a valid Contract ID
              </span>
            </Alert>
          )}
          <ContractForm contract={contract} />
        </>
      )}
    </div>
  );

  return <div id="contract-detail-page">{contractDetView}</div>;
};
