import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchInitiatives } from "./InitiativeSlice";
import { useParams } from "react-router-dom";
import { Alert } from "@mui/material";
import { InitiativeForm } from "./InitiativeForm";
import LoadingIndicator from "components/LoadingIndicator";
import { Trans, useTranslation } from "react-i18next";

interface InitiativeDetProps {
  initiativeID: string;
}

export const InitiativeDetailPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation(["objStk", "objPlt", "strGen", "objCom"]);

  const { isLoading, error, errorList, initiativesById } = useSelector(
    (state: RootState) => state.initiatives
  );

  // get initiative ID from url param and attempt to find company in store
  const { initiativeID } = useParams<InitiativeDetProps>();
  const itemID = parseInt(initiativeID, 10);

  const initiative = initiativesById[itemID];

  useEffect(() => {
    (async () => {
      try {
        // can avoid going to the DB if we navigated from inventory
        if (!initiative) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchInitiatives(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [initiative, dispatch, getAccessTokenSilently]);

  let initiativeDetView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      {initiative && (
        <>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              <span>
                <b>{t("strGen:errors.wentwrong")}</b> - {error.toString()}
                {errorList && (
                  <ul>
                    {errorList.map((error: any) => {
                      return (
                        <li key={error.param}>
                          <b>{error.param}:</b> {error.msg}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </span>
            </Alert>
          )}
          {!itemID && (
            <Alert severity="error" sx={{ mb: 2 }}>
              <span>
                <Trans
                  i18nKey="strGen:errors.invalidid"
                  /* eslint-disable-next-line jsx-a11y/heading-has-content */
                  components={[<h1 />, <div />]}
                  values={{
                    variable: initiativeID,
                    fieldname: t("objBen:objects.initiative.name"),
                  }}
                />
              </span>
            </Alert>
          )}
          <InitiativeForm initiative={initiative} />
        </>
      )}
    </div>
  );

  return <div id="initiative-detail-page">{initiativeDetView}</div>;
};
