import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CountriesStates,
  CountriesStatesResult,
  getCountriesStates,
} from "api/dataList/countriesStatesAPI";
import { AppThunk } from "app/store";

interface CountriesStatesState {
  countriesStatesById: Record<number, CountriesStates>;
  countriesStatesList: number[];
  isLoading: boolean;
  error: string | null;
}

const CountriesStatesInitialState: CountriesStatesState = {
  countriesStatesById: {},
  countriesStatesList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: CountriesStatesState) {
  state.isLoading = true;
}

function loadingFailed(
  state: CountriesStatesState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const countriesStates = createSlice({
  name: "countriesStates",
  initialState: CountriesStatesInitialState,
  reducers: {
    getCountriesStatesStart: startLoading,
    getCountriesStatesSuccess(
      state,
      { payload }: PayloadAction<CountriesStatesResult>
    ) {
      const { countriesStates } = payload;
      state.isLoading = false;
      state.error = null;

      countriesStates.forEach((countriesState) => {
        state.countriesStatesById[countriesState.CountriesStatesID] =
          countriesState;
      });

      state.countriesStatesList = countriesStates.map(
        (countriesState) => countriesState.CountriesStatesID
      );
    },
    getCountriesStatesFailure: loadingFailed,
  },
});

export const {
  getCountriesStatesStart,
  getCountriesStatesSuccess,
  getCountriesStatesFailure,
} = countriesStates.actions;

export default countriesStates.reducer;

export const fetchCountriesStates =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCountriesStatesStart());
      const countriesStates = await getCountriesStates(accessToken);
      dispatch(getCountriesStatesSuccess(countriesStates));
    } catch (err: any) {
      dispatch(getCountriesStatesFailure(err.toString()));
    }
  };
