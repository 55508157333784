import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Upload } from "@progress/kendo-react-upload";
import { read, utils } from "xlsx";
import { RootState } from "app/rootReducer";
import { Box, Grid, Paper } from "@mui/material";
import { IppFormHeader } from "components/IppFormHeader";
import { FileUploadErrors } from "components/FileUploadErrors/FileUploadErrors";
import { fetchUsers } from "features/users/UsersSlice";
import { ProcessedUploadErrors } from "utils/types/UploadErrors.types";
import {
  generateGroupErrorTitles,
  getNewGroupConstructedErrors,
  getNewGroupConstructedWarnings,
} from "./StakeholderGroupUploadErrors";
import {
  processConstructedErrorsObject,
  pushListError,
} from "utils/uploadUtils";
import { useTypedTranslation } from "utils/customHooks";

const PREFIX = "StakeholderGroupUploadPage";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1000,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const StakeholderGroupUploadPage = (props: any) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const t = useTypedTranslation(["objPlt", "strGen"]);
  const [A0token, setA0token] = useState("");
  const [processing, setProcessing] = useState(false);
  const [warnings, setWarnings] = useState<ProcessedUploadErrors[]>([]);
  const [files, setFiles] = useState<Partial<any>>({
    files: [] as any,
    events: [] as any,
    errors: [] as any,
    emptyFile: false,
  });

  const [isEditing, setIsEditing] = useState(true);
  const [isAdding, setIsAdding] = useState(true);

  const { clientId } = useSelector((state: RootState) => state.client);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        setA0token(accessToken);
        dispatch(fetchUsers(accessToken, clientId));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  const basePath = process.env.REACT_APP_API;
  const pathArray = window.location.pathname.split("/");
  const clientShortName = pathArray[1];
  const baseURL = `${basePath}/${clientShortName}/api`;

  const fileStatuses = [
    "UploadFailed",
    "Initial",
    "Selected",
    "Uploading",
    "Uploaded",
    "RemoveFailed",
    "Removing",
  ];

  const onBeforeUpload = (event: any) => {
    event.headers.Authorization = `Bearer ${A0token}`;
  };

  const onAdd = (event: any) => {
    const afterStateChange = () => {
      event.affectedFiles
        .filter((file: any) => !file.validationErrors)
        .forEach((file: any) => {
          const reader = new FileReader();

          reader.onloadend = (ev) => {
            var data = ev.target ? ev.target.result : null;
            var workbook = read(data, {
              type: "binary",
            });
            var sheetName = workbook.SheetNames[0];

            try {
              var XL_row_object = utils.sheet_to_json(
                workbook.Sheets[sheetName],
                //when no header provided, would need to map columns to object properties manually
                { defval: "" }
              );

              var XL_header_object: any[] = utils.sheet_to_json(
                workbook.Sheets[sheetName],
                { header: 1 }
              );

              let sheetIsValid = true;
              let emptyFile = false;

              //validate data from excel sheet here
              //-------------------------------------
              const constructedErrors = getNewGroupConstructedErrors();
              const constructedWarnings = getNewGroupConstructedWarnings();

              //Check file isn't empty

              if (XL_row_object.length < 1) {
                emptyFile = true;
              } else {
                let stringPropList = [
                  { propName: "Name", req: true },
                  { propName: "Organization Type", req: true },
                  { propName: "Indigenous?", req: false },
                  { propName: "Indigenous Group", req: false },
                  { propName: "Address", req: false },
                  { propName: "Town/City", req: false },
                  { propName: "Province/State", req: false },
                  { propName: "Country", req: false },
                  { propName: "Postal/Zip", req: false },
                  { propName: "Website", req: false },
                  { propName: "Details", req: false },
                  { propName: "Phone #", req: false },
                  { propName: "Level of Importance", req: false },
                  { propName: "Level of Interest", req: false },
                  { propName: "Level of Influence", req: false },
                  { propName: "Relationship Owner", req: false },
                ];

                //Check required headers exist
                const headers = XL_header_object[0];

                stringPropList.forEach((prop: any) => {
                  if (!headers.includes(prop.propName)) {
                    prop.req
                      ? pushListError(
                          constructedErrors.requiredColumn,
                          prop.propName
                        )
                      : pushListError(
                          constructedWarnings.optionalColumn,
                          prop.propName
                        );
                  }
                });

                //Check required data exists and is in the correct format.
                XL_row_object.forEach((row: any, ix: number) => {
                  let rowNum = row.__rowNum__ + 1;

                  stringPropList.forEach((prop: any) => {
                    if (
                      prop.req === true &&
                      headers.includes(prop.propName) &&
                      (prop.propName in row !== true ||
                        !row[prop.propName] ||
                        row[prop.propName]?.toString().trim() === "")
                    ) {
                      pushListError(constructedErrors.requiredData, {
                        header: prop.propName,
                        rowNumber: rowNum,
                      });
                    }

                    if (prop.propName in row === true) {
                      if (typeof row[prop.propName].toString() != "string") {
                        pushListError(constructedErrors.incorrectType, {
                          header: prop.propName,
                          rowNumber: rowNum,
                          expectedValue: t(
                            "strGen:uploaderrors.expectedvalues.textvalue"
                          ),
                        });
                      }
                      if (
                        prop.propName !== "Details" &&
                        prop.propName !== "Name" &&
                        prop.propName !== "Address" &&
                        prop.propName !== "Website" &&
                        row[prop.propName].toString().length > 50
                        //(prop.propName === "Details" ? 1000 : 50)
                      ) {
                        pushListError(constructedErrors.characterLimit, {
                          header: prop.propName,
                          rowNumber: rowNum,
                          expectedValue: 50,
                        });
                      }
                      if (
                        prop.propName === "Name" &&
                        row[prop.propName].toString().length > 250
                      ) {
                        pushListError(constructedErrors.characterLimit, {
                          header: prop.propName,
                          rowNumber: rowNum,
                          expectedValue: 250,
                        });
                      }
                      if (
                        (prop.propName === "Address" ||
                          prop.propName === "Website") &&
                        row[prop.propName].toString().length > 100
                      ) {
                        pushListError(constructedErrors.characterLimit, {
                          header: prop.propName,
                          rowNumber: rowNum,
                          expectedValue: 100,
                        });
                      }
                    }
                  });

                  if ("Level of Interest" in row === true) {
                    if (
                      !(
                        row["Level of Interest"] === undefined ||
                        row["Level of Interest"]?.toString().trim() === ""
                      )
                    ) {
                      if (
                        row["Level of Interest"] < 1 ||
                        row["Level of Interest"] > 7 ||
                        Number.isNaN(
                          Number(row["Level of Interest"]?.toString().trim())
                        )
                      ) {
                        pushListError(constructedErrors.incorrectType, {
                          header: "Level of Interest",
                          rowNumber: rowNum,
                          expectedValue: t(
                            "strGen:uploaderrors.expectedvalues.wholenumber1to7"
                          ),
                        });
                      }
                    }
                  }

                  if ("Level of Influence" in row === true) {
                    if (
                      !(
                        row["Level of Influence"] === undefined ||
                        row["Level of Influence"]?.toString().trim() === ""
                      )
                    ) {
                      if (
                        row["Level of Influence"] < 1 ||
                        row["Level of Influence"] > 7 ||
                        Number.isNaN(
                          Number(row["Level of Influence"]?.toString().trim())
                        )
                      ) {
                        pushListError(constructedErrors.incorrectType, {
                          header: "Level of Influence",
                          rowNumber: rowNum,
                          expectedValue: t(
                            "strGen:uploaderrors.expectedvalues.wholenumber1to7"
                          ),
                        });
                      }
                    }
                  }
                });
              }
              //-------------------------------------
              const fileErrors = processConstructedErrorsObject(
                constructedErrors,
                generateGroupErrorTitles
              );

              const fileWarnings = processConstructedErrorsObject(
                constructedWarnings,
                generateGroupErrorTitles
              );

              if (fileWarnings.length > 0) {
                setWarnings(fileWarnings);
              }

              if (fileErrors.length > 0 || emptyFile) {
                sheetIsValid = false;
              }

              if (sheetIsValid) {
                setProcessing(false);
              } else {
                setFiles({
                  files: [],
                  events: [
                    ...files.events,
                    `File failed validatation: ${event.affectedFiles[0].name}`,
                  ],
                  errors: fileErrors,
                  emptyFile: emptyFile,
                });
                setProcessing(false);
              }
            } catch (err: any) {
              throw err;
            }
          };

          reader.onerror = function (ex) {
            console.log(ex);
          };

          reader.readAsBinaryString(file.getRawFile());
        });
    };

    setProcessing(true);

    setFiles({
      files: event.newState,
      events: [
        ...files.events,
        `File selected: ${event.affectedFiles[0].name}`,
      ],
      errors: [],
    });

    afterStateChange();
    setProcessing(false);

    // clear state
    setWarnings([]);
  };

  const onRemove = (event: any) => {
    setFiles({
      files: event.newState,
      events: [...files.events, `File removed: ${event.affectedFiles[0].name}`],
      errors: [],
    });
    setProcessing(false);

    // clear state
    setWarnings([]);
  };

  const onProgress = (event: any) => {
    setFiles({
      files: event.newState,
      events: [
        ...files.events,
        `On Progress: ${event.affectedFiles[0].progress} %`,
      ],
      errors: [...files.errors],
    });
  };

  const onStatusChange = (event: any) => {
    const file = event.affectedFiles[0];

    // On success, clear state
    if (file.status === 4) {
      setWarnings([]);
    }

    setFiles({
      files: event.newState,
      events: [
        ...files.events,
        `File '${file.name}' status changed to: ${fileStatuses[file.status]}`,
      ],
      errors: [...files.errors],
    });
  };

  let stakeholderGroupUploadView = (
    <Root>
      <Box display="flex" justifyContent="center">
        <Paper className={classes.boxSpace}>
          <Grid container className={classes.editForm} spacing={1}>
            <IppFormHeader
              title={t("objPlt:objects.group.name_other")}
              isEditing={isEditing}
              isAdding={isAdding}
              returnPath="/engagement/groups"
            />

            <Grid item xs={12}>
              <FileUploadErrors
                errors={files.errors}
                emptyFile={files.emptyFile}
              />

              {files.errors.length === 0 ? (
                <FileUploadErrors errors={warnings} isWarning />
              ) : (
                ""
              )}
            </Grid>

            <Grid item xs={12}>
              <Upload
                showActionButtons={
                  !processing && files.errors.length === 0 && A0token !== ""
                }
                autoUpload={false}
                multiple={false}
                files={files.files}
                restrictions={{
                  allowedExtensions: [".xlsx"],
                }}
                withCredentials={true}
                onAdd={onAdd}
                onRemove={onRemove}
                onProgress={onProgress}
                onStatusChange={onStatusChange}
                onBeforeUpload={onBeforeUpload}
                saveUrl={`${baseURL}/stakeholderGroup/upload`}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Root>
  );

  return (
    <div id="stakeholderGroup-upload-page">{stakeholderGroupUploadView}</div>
  );
};
