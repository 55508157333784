import { InitiativeForm } from "./InitiativeForm";

export const InitiativeCreatePage = () => {
  let initiativeCreateView = (
    <div>
      <InitiativeForm />
    </div>
  );

  return <div id="initiative-create-page"> {initiativeCreateView} </div>;
};
