import axiosClient from "../axiosClient";

export interface CommitmentPhase {
  CommitmentPhaseID: number;
  CommitmentID: number;
  PhaseID: number;
  Name: string;
  Rank: number;
}

export interface CommitmentPhasesResult {
  commitmentPhases: CommitmentPhase[];
}

export async function getCommitmentPhases(
  accessToken: String
): Promise<CommitmentPhasesResult> {
  const url = `/commitmentPhase`;

  try {
    const { data } = await axiosClient.get<CommitmentPhasesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getCommitmentPhasesByCommitment(
  accessToken: String,
  commitmentID: number
): Promise<CommitmentPhasesResult> {
  const url = `/commitmentPhase/commitment/${commitmentID}`;

  try {
    const { data } = await axiosClient.get<CommitmentPhasesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createCommitmentPhase(
  accessToken: String,
  newCommPhase: Partial<CommitmentPhase>
): Promise<CommitmentPhase> {
  const url = `/commitmentPhase`;

  try {
    const { data } = await axiosClient.post<CommitmentPhase>(
      url,
      newCommPhase,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateCommitmentPhase(
  accessToken: String,
  commPhaseID: number,
  newCommPhase: Partial<CommitmentPhase>
): Promise<CommitmentPhase> {
  const url = `/commitmentPhase/` + newCommPhase;

  try {
    const { data } = await axiosClient.post<CommitmentPhase>(
      url,
      newCommPhase,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteCommitmentPhase(
  accessToken: String,
  commPhaseID: number
): Promise<string> {
  const url = `/commitmentPhase/` + commPhaseID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
