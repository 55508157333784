import axiosClient from "./axiosClient";

export interface Payment {
  PaymentID: number;
  CompanyID: number;
  ProjectComponentID: number;
  PaymentDate: Date;
  PaymentAmount: number;
  CurrencyType: string;
  WorkflowStageID: number;
  CompanyName?: string;
  ProjectComponentName?: string;
  WorkflowStageName?: string;
  ProjectID?: number;
  ProjectName?: string;
  VendorID?: string;
  ContractID?: number;
  SequenceID?: number;
  PaymentPeriodSequenceID?: number;
  PaymentPeriodID?: number;
  PaidByCompanyID: number;
  PaidByCompanyName: string;
  PaidByVendorID?: string;
  PaymentPeriodStatusName?: string;
  ContractTitle?: string;
}

export interface PaymentsResult {
  payments: Payment[];
}

export async function getClientPayments(
  accessToken: String
): Promise<PaymentsResult> {
  const url = `/payment/clientPayments`;

  try {
    const { data } = await axiosClient.get<PaymentsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getSinglePayment(
  accessToken: String,
  paymentID: number
): Promise<PaymentsResult> {
  const url = `/payment/${paymentID}`;

  try {
    const { data } = await axiosClient.get<PaymentsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getPaymentsByPeriod(
  accessToken: String,
  periodID: number
): Promise<PaymentsResult> {
  const url = `/payment/period/${periodID}`;

  try {
    const { data } = await axiosClient.get<PaymentsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getIndirectPaymentsByCompany(
  accessToken: String,
  companyID: number
): Promise<PaymentsResult> {
  const url = `/payment/indirectPayments/${companyID}`;

  try {
    const { data } = await axiosClient.get<PaymentsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createPayment(
  accessToken: String,
  newPayment: Partial<Payment>
): Promise<Payment> {
  const url = `/payment`;

  try {
    const { data } = await axiosClient.post<Payment>(url, newPayment, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updatePayment(
  accessToken: String,
  paymentID: number,
  newPayment: Partial<Payment>
): Promise<Payment> {
  const url = `/payment/` + paymentID;

  try {
    const { data } = await axiosClient.post<Payment>(url, newPayment, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deletePayment(
  accessToken: String,
  paymentID: number,
  periodID: number | null
): Promise<string> {
  let url = `/payment/` + paymentID;

  if (periodID) {
    url += `?periodID=${periodID}`;
  }

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    //console.log("DELDATA", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}
