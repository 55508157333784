import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  TextField,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { Grid as KendoGrid, GridColumn } from "@progress/kendo-react-grid";
import LoadingIndicator from "components/LoadingIndicator";
import { Link } from "react-router-dom";
import { getCurrentModule } from "../../../utils/urlUtils";
import { useTypedTranslation } from "utils/customHooks";
import { IppMergeButton } from "components/Buttons/IppMergeButton";

interface CheckDuplicatesProps {
  originalID: number;
  isOpen: boolean;
  closeWindow: any;
}

export const GroupCheckDuplicates = (props: CheckDuplicatesProps) => {
  const t = useTypedTranslation(["objPlt", "strGen"]);
  const { originalID, isOpen, closeWindow } = props;
  const [filterString, setFilterString] = useState("");

  const {
    stakeholderGroupList,
    stakeholderGroupsById,
    isLoading: groupIsLoading,
  } = useSelector((state: RootState) => state.stakeholderGroups);

  const stakeholdergroups = stakeholderGroupList.map(
    (id) => stakeholderGroupsById[id]
  );

  const data = stakeholdergroups
    .filter((item) => item.GroupID != originalID)
    .map((p) => {
      let n = Object.assign({}, p) as any;
      //n.FullName = p.FirstName + " " + p.Surname;
      n.SearchName = p.FirstName;
      if (!p.Contacts) {
        n.ContactList = "";
      } else {
        let contacts = p.Contacts.split(",");
        if (contacts.length > 1) {
          n.ContactList =
            p.Contacts.slice(0, 10) + " +" + (contacts.length - 1);
        } else {
          n.ContactList = p.Contacts.slice(0, 10);
        }
      }

      return n;
    });

  const onChangeFilterString = (event: any) => {
    setFilterString(event.target.value);
  };

  const linkCell = (props: any) => {
    return (
      <td align="right">
        <Link
          to={
            `/${getCurrentModule()}/groups/merge/` +
            originalID +
            "/" +
            props.dataItem.GroupID
          }
        >
          <Tooltip
            title={t("strGen:pages.merge.selecttooltip", {
              fieldname: t("objPlt:objects.group.name", { count: 2 }),
            })}
          >
            <IppMergeButton buttonText={t("strGen:pages.merge.select")} />
          </Tooltip>
        </Link>
      </td>
    );
  };

  return groupIsLoading ? (
    <LoadingIndicator />
  ) : (
    <Dialog open={isOpen} onClose={closeWindow} maxWidth="lg" fullWidth>
      <DialogTitle>
        {t("strGen:pages.merge.checkforduplicate", {
          fieldname: t("objPlt:objects.group.name", { count: 2 }),
        })}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body2">
              {t("strGen:pages.merge.checkinfo", {
                fieldname: t("objPlt:objects.group.name", { count: 2 }),
                fieldname2: t("objPlt:objects.group.name"),
              })}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1">
              {t("strGen:pages.merge.searchfor")}
            </Typography>
          </Grid>
          <Grid item xs>
            <TextField
              id="txtSearch"
              placeholder={t("strGen:pages.merge.searchplaceholder")}
              onChange={onChangeFilterString}
              value={filterString}
            />
          </Grid>
          <Grid item xs={12}>
            <KendoGrid
              data={
                filterString === ""
                  ? data
                  : data.filter((item) =>
                      item.GroupName.toLowerCase().includes(
                        filterString.toLowerCase()
                      )
                    )
              }
            >
              <GridColumn
                field="GroupName"
                title={t("objPlt:objects.group.name")}
              />
              <GridColumn
                field="OrganizationTypeName"
                title={t("objPlt:objects.group.fields.organizationtype")}
              />
              <GridColumn
                field="TownCity"
                title={t("objPlt:objects.group.fields.towncity")}
              />
              <GridColumn
                field="ProvinceState"
                title={t("objPlt:objects.group.fields.provincestate")}
              />
              <GridColumn
                field="ContactList"
                title={t("objPlt:objects.group.fields.contact", { count: 2 })}
              />
              <GridColumn
                field="GroupID"
                title=" "
                cell={linkCell}
                width="110"
              />
            </KendoGrid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
