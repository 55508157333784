import axiosClient from "./axiosClient";

export interface JobPosition {
  JobPositionID: number;
  JobPositionName: string;
  JobCategoryID: number;
  ApprenticeableTrade: boolean;
  NOC_Code: number;
  NOC_Major: number;
  NOC_Minor: number;
}

export interface JobPositionsResult {
  jobPositions: JobPosition[];
}

export async function getJobPositions(
  accessToken: String
): Promise<JobPositionsResult> {
  const url = `/jobposition`;

  try {
    const { data } = await axiosClient.get<JobPositionsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getJobPositionsByClient(
  accessToken: String
): Promise<JobPositionsResult> {
  const url = `/jobposition/client`;

  try {
    const { data } = await axiosClient.get<JobPositionsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
