import { CommitmentActionForm } from "./CommitmentActionForm";

export const CommitmentActionCreatePage = () => {
  let CommitmentActionCreateView = (
    <div>
      <CommitmentActionForm />
    </div>
  );

  return (
    <div id="CommitmentAction-detail-page">{CommitmentActionCreateView}</div>
  );
};
