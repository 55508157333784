import axiosClient from "api/axiosClient";
import { DateAsString } from "utils/DateFunctions";

export interface VendorSocioEconEmployees {
  ContractID: number;
  CompanyID: number;
  ContractNumber: string;
  ContractTitle: string;
  ContractEmployeeTarget: number;
  ContractHoursTarget: number;
  ProjectEmployeeTarget: number;
  ProjectHoursTarget: number;
  TotalEmployees: number;
  indigenousEmployees: number;
  TotalHours: number;
  IndigenousHours: number;
}

export interface VendorSocioEconEmployeesResult {
  vendorSocioEconEmployees: VendorSocioEconEmployees[];
}

export async function getVendorSocioEconEmployees(
  accessToken: String,
  startDate: Date,
  endDate: Date,
  regionID: number,
  projectID: number
): Promise<VendorSocioEconEmployeesResult> {
  const start = DateAsString(startDate);
  const end = DateAsString(endDate);
  const url = `/charts/socioeconEmployees/${start}/${end}/${regionID}/${projectID}`;
  try {
    const { data } = await axiosClient.get<VendorSocioEconEmployeesResult>(
      url,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}
