import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchCommitmentSources } from "./CommitmentSourceSlice";
import { useParams } from "react-router-dom";

import { CommitmentSourceForm } from "./CommitmentSourceForm";
import LoadingIndicator from "components/LoadingIndicator";
import { Trans, useTranslation } from "react-i18next";

interface CommitmentSourceDetProps {
  commitmentSourceID: string;
}

export const CommitmentSourceDetailPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation(["objStk", "objPlt", "strGen", "objCom"]);

  const {
    isLoading,
    error: compError,
    commitmentSourcesById,
  } = useSelector((state: RootState) => state.commitmentSources);

  // get commitmentSource ID from url param and attempt to find company in store
  const { commitmentSourceID } = useParams<CommitmentSourceDetProps>();
  const itemID = parseInt(commitmentSourceID, 10);

  const commitmentSource =
    commitmentSourcesById[parseInt(commitmentSourceID, 10)];

  useEffect(() => {
    (async () => {
      try {
        // can avoid going to the DB if we navigated from inventory
        if (!commitmentSource) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchCommitmentSources(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [commitmentSource, dispatch, getAccessTokenSilently]);

  if (!itemID) {
    return (
      <div>
        <Trans
          i18nKey="strGen:errors.invalidid"
          /* eslint-disable-next-line jsx-a11y/heading-has-content */
          components={[<h1 />, <div />]}
          values={{
            variable: commitmentSourceID,
            fieldname: t("objCom:objects.commitmentsource.name", {
              sourcecount: 1,
            }),
          }}
        />
      </div>
    );
  }

  if (compError) {
    return (
      <div>
        <h1>{t("strGen:errors.wentwrong")}</h1>
        <div>{compError.toString()}</div>
      </div>
    );
  }

  let commitmentSourceDetView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      {commitmentSource && (
        <CommitmentSourceForm commitmentSource={commitmentSource} />
      )}
    </div>
  );

  return <div id="commitmentSource-detail-page">{commitmentSourceDetView}</div>;
};
