import axiosClient from "./axiosClient";

export interface ClientInteractionType {
  ClientInteractionTypeID: number;
  ClientID: number;
  InteractionTypeID: number;
  InteractionTypeName: string;
  IsRequired: boolean;
}

export interface ClientInteractionTypesResult {
  clientInteractionTypes: ClientInteractionType[];
}

export async function getClientInteractionTypes(
  accessToken: String
): Promise<ClientInteractionTypesResult> {
  const url = `/clientInteractionType`;

  try {
    const { data } = await axiosClient.get<ClientInteractionTypesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    // console.log("GETDATA", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createClientInteractionType(
  accessToken: String,
  newClientInteractionType: Partial<ClientInteractionType>
): Promise<ClientInteractionType> {
  const url = `/ClientInteractionType`;

  try {
    const { data } = await axiosClient.post<ClientInteractionType>(
      url,
      newClientInteractionType,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateClientInteractionType(
  accessToken: String,
  ClientInteractionTypeID: number,
  newClientInteractionType: Partial<ClientInteractionType>
): Promise<ClientInteractionType> {
  const url = `/ClientInteractionType/` + ClientInteractionTypeID;

  try {
    const { data } = await axiosClient.post<ClientInteractionType>(
      url,
      newClientInteractionType,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteClientInteractionType(
  accessToken: String,
  ClientInteractionTypeID: number
): Promise<string> {
  const url = `/ClientInteractionType/` + ClientInteractionTypeID;
  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    // console.log("DELDATA", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}
