import * as yup from "yup";
import { useTypedTranslation } from "utils/customHooks";
import { dateCheck } from "utils/DateFunctions";

export const WorkHourPeriodValidationSchema = (
  contractEnabled: boolean,
  subcontractorEnabled: boolean
) => {
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  return yup.object({
    ProjectID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.project.name"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.project.name"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:objects.project.name"),
        })
      ),
    ProjectComponentID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.projectcomponent.fullname"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.projectcomponent.fullname"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:objects.projectcomponent.fullname"),
        })
      ),
    CompanyID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.company.name"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.company.name"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.company.name"),
        })
      ),
    ContractID: yup
      .number()
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.workhour.period.fields.contract"),
        })
      )
      .when([], {
        is: () => contractEnabled === true,
        then: (schema) => {
          return schema
            .positive(
              t("strGen:validation.required", {
                fieldname: t("objBen:objects.workhour.period.fields.contract"),
              })
            )
            .required(
              t("strGen:validation.required", {
                fieldname: t("objBen:objects.workhour.period.fields.contract"),
              })
            );
        },
        otherwise: (schema) => schema.nullable(),
      }),
    StartDate: yup
      .date()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.workhour.period.fields.startdate"),
        })
      )
      .typeError(t("strGen:validation.date.improperformat")),
    EndDate: yup
      .date()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.workhour.period.fields.enddate"),
        })
      )
      .typeError(t("strGen:validation.date.improperformat"))
      .test(
        "EndDate",
        t("strGen:validation.date.notearlierthan", {
          fieldname: t("objBen:objects.workhour.period.fields.enddate"),
          fieldname2: t("objBen:objects.workhour.period.fields.startdate"),
        }),
        (value, testContext) => dateCheck(testContext.parent.StartDate, value)
      ),
    IsSubcontractor: yup.boolean().nullable(),
    ContractorID: yup
      .number()
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.workhour.period.fields.contractor"),
        })
      )
      .when(["IsSubcontractor"], {
        is: (isSubcontractor: boolean) =>
          subcontractorEnabled === true && isSubcontractor === true,
        then: (schema) => {
          return schema
            .positive(
              t("strGen:validation.required", {
                fieldname: t(
                  "objBen:objects.workhour.period.fields.contractor"
                ),
              })
            )
            .required(
              t("strGen:validation.required", {
                fieldname: t(
                  "objBen:objects.workhour.period.fields.contractor"
                ),
              })
            );
        },
        otherwise: (schema) => schema.nullable(),
      }),
  });
};
