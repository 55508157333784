import { Alert, AlertColor, AlertTitle } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { IppAccordion } from "components/IppAccordion";
import { ProcessedUploadErrors } from "utils/types/index.types";
import { FileUploadErrorMessage } from "./FileUploadErrorMessage";
import { FileUploadErrorList } from "./FileUploadErrorList";

const Root = styled("div")(({ theme }) => ({
  "& .MuiAlert-message": {
    width: "100%",
  },
}));

interface FileUploadErrorsProps {
  errors: ProcessedUploadErrors[];
  emptyFile?: boolean;
  isWarning?: boolean;
  extendedAlertTitle?: string | React.ReactNode;
}

const getAlertProperties = (
  isWarning: boolean,
  errors: ProcessedUploadErrors[],
  theme: any
) => {
  if (isWarning) {
    return {
      alertTitle:
        "Hey, we noticed a few things you may want to review before continuing. If everything looks okay, you can proceed with your upload by clicking the Upload button below.",
      severity: "warning" as AlertColor,
      accordionColor: theme.palette.warning.light,
      titlePrefix: "Warning",
      titlePrefixColor: theme.palette.warning.main,
    };
  } else {
    return {
      alertTitle: `Wait! There ${
        errors.length === 1 ? "is 1 error" : `are ${errors.length} errors`
      } preventing your upload. Please fill in the missing information before you upload again.`,
      severity: "error" as AlertColor,
      accordionColor: theme.palette.error.light,
      titlePrefix: "Error",
      titlePrefixColor: theme.palette.error.main,
    };
  }
};

export const FileUploadErrors = ({
  errors,
  emptyFile = false,
  isWarning = false,
  extendedAlertTitle,
}: FileUploadErrorsProps) => {
  const theme = useTheme();
  const alertProps = getAlertProperties(isWarning, errors, theme);

  const renderErrors = () =>
    errors.map((error, index) => (
      <IppAccordion
        key={index}
        titleContent={error.title}
        titlePrefix={`${alertProps.titlePrefix} ${index + 1}:`}
        titlePrefixColor={alertProps.titlePrefixColor}
        bgColor={alertProps.accordionColor}
        showExpandInfo={
          !!(
            error.childList ||
            error.message ||
            (error.list && error.list.length > 1)
          )
        }
        expandable={
          !!(
            error.childList ||
            error.message ||
            (error.list && error.list.length > 1)
          )
        }
      >
        {error.message && <FileUploadErrorMessage message={error.message} />}
        <FileUploadErrorList
          items={error.childList || error.list}
          showSingleItem={error.showSingleItem}
          renderAsList={error.renderAsList}
        />
      </IppAccordion>
    ));

  return !errors.length && !emptyFile ? (
    <Root />
  ) : (
    <Root>
      <Alert
        severity={alertProps.severity}
        sx={{ backgroundColor: "transparent" }}
      >
        {emptyFile ? (
          <AlertTitle>
            Empty File: Imported Excel file cannot be empty
          </AlertTitle>
        ) : (
          <>
            <AlertTitle>
              {alertProps.alertTitle}
              {extendedAlertTitle}
            </AlertTitle>
            {renderErrors()}
          </>
        )}
      </Alert>
    </Root>
  );
};
