import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Asset,
  AssetsResult,
  getAssets,
  createAsset,
  updateAsset,
  deleteAsset,
} from "api/benefits/assetAPI";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { push } from "redux-first-history";

interface AssetState {
  assetsById: Record<number, Asset>;
  assetList: number[];
  assetIsLoading: boolean;
  assetError: string | null;
  assetErrorList: any[] | null;
}

const AssetInitialState: AssetState = {
  assetsById: {},
  assetList: [],
  assetIsLoading: false,
  assetError: null,
  assetErrorList: null,
};

function startLoading(state: AssetState) {
  state.assetIsLoading = true;
}

function loadingFailed(
  state: AssetState,
  action: PayloadAction<{ status: string; message: string; errors: [] }>
) {
  state.assetIsLoading = false;
  state.assetError = action.payload.message;
  if (action.payload.errors) {
    state.assetErrorList = action.payload.errors;
  }
}

const assets = createSlice({
  name: "assets",
  initialState: AssetInitialState,
  reducers: {
    getAssetsStart: startLoading,
    getAssetsSuccess(state, { payload }: PayloadAction<AssetsResult>) {
      const { assets } = payload;
      state.assetIsLoading = false;
      state.assetError = null;
      state.assetErrorList = null;

      assets.forEach((asset) => {
        state.assetsById[asset.AssetID] = asset;
      });

      state.assetList = assets.map((asset) => asset.AssetID);
    },
    getAssetsFailure: loadingFailed,
    createAssetStart: startLoading,
    createAssetSuccess(state, { payload }: PayloadAction<Asset>) {
      const { AssetID } = payload;
      state.assetsById[AssetID] = payload;
      state.assetList.push(AssetID);

      state.assetIsLoading = false;
      state.assetError = null;
      state.assetErrorList = null;
    },
    updateAssetStart: startLoading,
    updateAssetSuccess(state, { payload }: PayloadAction<Asset>) {
      const { AssetID } = payload;
      state.assetsById[AssetID] = payload;

      state.assetIsLoading = false;
      state.assetError = null;
      state.assetErrorList = null;
    },
    deleteAssetStart: startLoading,
    deleteAssetSuccess(state, { payload }: PayloadAction<number>) {
      const AssetID = payload;
      delete state.assetsById[AssetID];
      state.assetList = state.assetList.filter((id) => id !== AssetID);

      state.assetIsLoading = false;
      state.assetError = null;
      state.assetErrorList = null;
    },
    assetFailure: loadingFailed,
  },
});

export const {
  getAssetsStart,
  getAssetsSuccess,
  getAssetsFailure,
  createAssetStart,
  createAssetSuccess,
  updateAssetStart,
  updateAssetSuccess,
  deleteAssetStart,
  deleteAssetSuccess,
  assetFailure,
} = assets.actions;

export default assets.reducer;

export const fetchAssets =
  (accessToken: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getAssetsStart());
      const assets = await getAssets(accessToken);
      dispatch(getAssetsSuccess(assets));
    } catch (err: any) {
      dispatch(getAssetsFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, err.toString()));
    }
  };

export const addAsset =
  (accessToken: string, asset: Partial<Asset>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createAssetStart());
      const newAsset = await createAsset(accessToken, asset);
      dispatch(createAssetSuccess(newAsset));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push(`/benefits/assets/${newAsset.AssetID}`));
    } catch (err: any) {
      dispatch(assetFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, err.toString()));
    }
  };

export const updAsset =
  (accessToken: string, assetID: number, asset: Asset): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateAssetStart());
      const updatedAsset = await updateAsset(accessToken, assetID, asset);
      dispatch(updateAssetSuccess(updatedAsset));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      //dispatch(push(`/benefits/assets`));
    } catch (err: any) {
      dispatch(assetFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, err.toString()));
    }
  };

export const delAsset =
  (accessToken: string, assetID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteAssetStart());
      await deleteAsset(accessToken, assetID);
      dispatch(deleteAssetSuccess(assetID));
      dispatch(push("/benefits/assets"));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(assetFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, err.toString()));
    }
  };
