import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IppCancelButton } from "./Buttons/IppCancelButton";
import { IppDeleteButton } from "./Buttons/IppDeleteButton";

const ConfirmDialog = (props: any) => {
  const { title, children, open, setOpen, onConfirm } = props;
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="sm"
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <IppCancelButton onClick={() => setOpen(false)} />
        <IppDeleteButton
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
        />
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;
