import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  NewThreadInteraction,
  ThreadInteraction,
  ThreadInteractionsResult,
  ThreadInteractionWithDetail,
  ThreadInteractionWithDetailsResult,
  createThreadInteraction,
  deleteThreadInteraction,
  getThreadInteractionsByInteraction,
  getThreadInteractionsByThread,
  updateBulkThreadInteractions,
  updateBulkInteractionThreads,
} from "api/stakeholder/threadInteractionAPI";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { push } from "redux-first-history";
import { fetchThreads } from "./ThreadSlice";

interface ThreadInteractionState {
  threadInteractionsById: Record<
    number,
    ThreadInteraction | ThreadInteractionWithDetail
  >;
  threadInteractionList: number[];
  isLoading: boolean;
  error: string | null;
}

const ThreadInteractionInitialState: ThreadInteractionState = {
  threadInteractionsById: {},
  threadInteractionList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: ThreadInteractionState) {
  state.isLoading = true;
}

function loadingFailed(
  state: ThreadInteractionState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
  state.threadInteractionList = [];
}

const threadInteractions = createSlice({
  name: "threadInteractions",
  initialState: ThreadInteractionInitialState,
  reducers: {
    getThreadInteractionsStart: startLoading,
    getThreadInteractionsSuccess(
      state,
      {
        payload,
      }: PayloadAction<
        ThreadInteractionsResult | ThreadInteractionWithDetailsResult
      >
    ) {
      const { threadInteractions } = payload;
      state.isLoading = false;
      state.error = null;

      threadInteractions.forEach((threadInteraction) => {
        state.threadInteractionsById[threadInteraction.ThreadInteractionID] =
          threadInteraction;
      });
      state.threadInteractionList = threadInteractions.map(
        (threadInteraction) => threadInteraction.ThreadInteractionID
      );
    },

    getThreadInteractionsFailure: loadingFailed,
    createThreadInteractionStart: startLoading,
    createThreadInteractionSuccess(
      state,
      { payload }: PayloadAction<ThreadInteraction>
    ) {
      const { ThreadInteractionID } = payload;
      state.threadInteractionsById[ThreadInteractionID] = payload;
      state.threadInteractionList.push(ThreadInteractionID);

      state.isLoading = false;
      state.error = null;
    },
    updateThreadInteractionSuccess(
      state,
      { payload }: PayloadAction<ThreadInteraction>
    ) {
      state.error = null;
    },
    deleteThreadInteractionSuccess(state, { payload }: PayloadAction<number>) {
      const ThreadInteractionID = payload;
      delete state.threadInteractionsById[ThreadInteractionID];
      state.threadInteractionList = state.threadInteractionList.filter(
        (item) => item !== ThreadInteractionID
      );
      state.isLoading = false;
      state.error = null;
    },
    createThreadInteractionFailure: loadingFailed,
  },
});

export const {
  getThreadInteractionsStart,
  getThreadInteractionsSuccess,
  getThreadInteractionsFailure,
  createThreadInteractionStart,
  createThreadInteractionSuccess,
  updateThreadInteractionSuccess,
  deleteThreadInteractionSuccess,
  createThreadInteractionFailure,
} = threadInteractions.actions;

export default threadInteractions.reducer;

export const fetchThreadInteractionsByInteraction =
  (accessToken: String, interactionID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getThreadInteractionsStart());
      const threadInteractions = await getThreadInteractionsByInteraction(
        accessToken,
        interactionID
      );
      dispatch(getThreadInteractionsSuccess(threadInteractions));
    } catch (err: any) {
      dispatch(getThreadInteractionsFailure(err.toString()));
    }
  };

export const fetchThreadInteractionsByThread =
  (accessToken: String | void, threadID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getThreadInteractionsStart());
      const threadInteractions = await getThreadInteractionsByThread(
        accessToken,
        threadID
      );
      dispatch(getThreadInteractionsSuccess(threadInteractions));
    } catch (err: any) {
      dispatch(getThreadInteractionsFailure(err.toString()));
    }
  };

export const addThreadInteraction =
  (
    accessToken: String,
    newThreadInteraction: Partial<NewThreadInteraction>,
    setReturnRoute?: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      const threadInteraction = await createThreadInteraction(
        accessToken,
        newThreadInteraction
      );
      dispatch(createThreadInteractionSuccess(threadInteraction));
      if (setReturnRoute) {
        dispatch(
          push(
            `/engagement/communications/${newThreadInteraction?.InteractionID}`
          )
        );
      }
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createThreadInteractionFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };
// Used to update ThreadInteractions from Thread Form--Communication Tab
export const updBulkThreadInteractions =
  (accessToken: String | void, threadID: number, changes: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createThreadInteractionStart());
      const threadInteraction = await updateBulkThreadInteractions(
        accessToken,
        threadID,
        changes
      );
      dispatch(updateThreadInteractionSuccess(threadInteraction));
      await dispatch(fetchThreadInteractionsByThread(accessToken, threadID));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createThreadInteractionFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updBulkInteractionThreads =
  (
    accessToken: String,
    interactionID: number,
    threadInteractions: any
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createThreadInteractionStart());
      const threadInteraction = await updateBulkInteractionThreads(
        accessToken,
        interactionID,
        threadInteractions
      );
      await dispatch(
        fetchThreadInteractionsByInteraction(accessToken, interactionID)
      );
      await dispatch(fetchThreads(accessToken));
      dispatch(updateThreadInteractionSuccess(threadInteraction));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createThreadInteractionFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delThreadInteraction =
  (accessToken: String, threadInteractionID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createThreadInteractionStart());
      const result = await deleteThreadInteraction(
        accessToken,
        threadInteractionID
      );
      dispatch(deleteThreadInteractionSuccess(threadInteractionID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createThreadInteractionFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };
