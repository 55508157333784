import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  WorkHourPeriodException,
  WorkHourPeriodExceptionsResult,
  getWorkHourPeriodExceptions,
} from "api/workHourPeriodExceptionAPI";
import { AppThunk } from "app/store";

interface WorkHourPeriodExceptionState {
  workHourPeriodExceptionList: WorkHourPeriodException[];
  isLoading: boolean;
  error: string | null;
}

const WorkHourPeriodExceptionInitialState: WorkHourPeriodExceptionState = {
  workHourPeriodExceptionList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: WorkHourPeriodExceptionState) {
  state.isLoading = true;
}

function loadingFailed(
  state: WorkHourPeriodExceptionState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const workHourPeriodExceptions = createSlice({
  name: "workHourPeriodException",
  initialState: WorkHourPeriodExceptionInitialState,
  reducers: {
    getWorkHourPeriodExceptionsStart: startLoading,
    getWorkHourPeriodExceptionsSuccess(
      state,
      { payload }: PayloadAction<WorkHourPeriodExceptionsResult>
    ) {
      const { workHourPeriodExceptions } = payload;
      state.isLoading = false;
      state.error = null;

      // NOTE: This reducer is simplified compared to other slicers due to the format of the data returned by the Stored Procedure involved. There is no unique ID for each record, so we don't map it by ID like eslewhere. Instead, the state is simply set to the payload to be displayed in a simple inventory list.

      state.workHourPeriodExceptionList = workHourPeriodExceptions;
    },
    getWorkHourPeriodExceptionsFailure: loadingFailed,
  },
});

export const {
  getWorkHourPeriodExceptionsStart,
  getWorkHourPeriodExceptionsSuccess,
  getWorkHourPeriodExceptionsFailure,
} = workHourPeriodExceptions.actions;

export default workHourPeriodExceptions.reducer;

export const fetchWorkHourPeriodExceptions =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getWorkHourPeriodExceptionsStart());
      const workHourPeriodExceptions = await getWorkHourPeriodExceptions(
        accessToken
      );
      //   console.log("RETURN", workHourPeriodExceptions);
      dispatch(getWorkHourPeriodExceptionsSuccess(workHourPeriodExceptions));
    } catch (err: any) {
      dispatch(getWorkHourPeriodExceptionsFailure(err.toString()));
    }
  };
