import { Trans } from "react-i18next";
import {
  ConstructedErrors,
  GenericErrorTitlesObject,
} from "utils/types/index.types";
import {
  generateErrorTitle,
  getGenericConstructedErrors,
  getGenericConstructedWarnings,
} from "utils/uploadUtils";

type InteractionErrorTitles = GenericErrorTitlesObject & {
  invalidContacts: (count: number) => JSX.Element | "";
};

export const generateInteractionErrorTitles: InteractionErrorTitles = {
  ...generateErrorTitle,
  invalidContacts: (count: number) =>
    count > 0 ? (
      <Trans
        i18nKey="strGen:uploaderrors.errors.interaction.invalidcontacts.title"
        count={count}
      />
    ) : (
      ""
    ),
};

export const getNewInteractionConstructedErrors: () => ConstructedErrors =
  () => ({
    ...getGenericConstructedErrors(),
    invalidContacts: {
      count: 0,
      get message() {
        return (
          <Trans
            i18nKey="strGen:uploaderrors.errors.interaction.invalidcontacts.message"
            count={this.count}
          />
        );
      },
      list: [],
      renderAsList: true,
      showSingleItem: true,
    },
  });

export const getNewInteractionConstructedWarnings: () => ConstructedErrors =
  () => ({
    ...getGenericConstructedWarnings(),
  });
