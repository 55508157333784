import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { fetchCommitmentPendingActions } from "./CommitmentPendingActionSlice";
import { IppSkeleton } from "components/IppSkeleton";
import { ConvertDateOffset } from "utils/DateFunctions";
import { IppInventory } from "components/Inventory/IppInventory";
import { useTranslation } from "react-i18next";

export const CommitmentsPendingTable = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation(["objPlt", "strGen", "objCom"]);
  const { location } = useSelector((state: RootState) => state.router);

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const {
    commitmentPendingActionList,
    commitmentPendingActionsById,
    isLoading: acIsLoading,
    error: acError,
  } = useSelector((state: RootState) => state.commitmentPendingActions);

  const commitmentPendingActions = commitmentPendingActionList.map(
    (id) => commitmentPendingActionsById[id]
  );

  const data = commitmentPendingActions.map((p) => {
    let n = Object.assign({}, p) as any;
    if (p.RequiredDate !== null) {
      n.RequiredDate = ConvertDateOffset(p.RequiredDate);
    } else {
      n.RequiredDate = null;
    }

    return n;
  });

  const dataColumns = [
    {
      field: "SequenceID",
      title: t("objCom:objects.commitment.sequenceid"),
      format: "CMT-{0:0}",
      filter: "text",
      columnWidth: 80,
    },
    {
      field: "CommitmentTitle",
      title: t("objCom:objects.commitment.name"),
    },
    {
      field: "ActionDescription",
      title: t("objCom:objects.commitmentaction.name"),
    },
    {
      field: "RequiredDate",
      title: t("objPlt:platformwide.fields.daterequired"),
      filter: "date",
      format: "{0:d}",
      columnWidth: 120,
    },
    {
      field: "PersonResponsible",
      title: t("objCom:objects.commitment.fields.personresponsible"),
    },
    {
      field: "ActionStatusName",
      title: t("objCom:objects.commitmentaction.fields.status"),
      chips: true,
      columnWidth: 120,
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchCommitmentPendingActions(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [getAccessTokenSilently, dispatch]);

  let tableView = acIsLoading ? (
    <IppSkeleton height={300} />
  ) : (
    <div>
      <IppInventory
        title={t("objCom:objects.commitmentaction.upcomingactions", {
          count: 2,
        })}
        tableData={data}
        idField="CommitmentActionID"
        columns={dataColumns}
        isFiltered={false}
        showDetail={true}
        sortColumn={2}
        linkColumn={1}
        route={`${location.pathname}/actions`}
        hideExport={true}
        //searchByColumn={"ActionDescription"}
        //searchByPlaceholder={"Search Actions"}
      />
    </div>
  );

  return <div id="commitment-action-table">{tableView}</div>;
};
