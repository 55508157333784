import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  GroupIssue,
  GroupIssueResult,
  getGroupIssues,
  getGroupIssuesByGroup,
  getGroupIssuesByIssue,
  deleteGroupIssue,
} from "api/stakeholder/groupIssueAPI";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { SnackBarConstants } from "utils/customHooks";

interface GroupIssueState {
  groupIssuesById: Record<number, GroupIssue>;
  groupIssueList: number[];
  isLoading: boolean;
  subGroupIssueIsLoading: boolean;
  error: string | null;
}

const GroupIssueInitialState: GroupIssueState = {
  groupIssuesById: {},
  groupIssueList: [],
  isLoading: false,
  subGroupIssueIsLoading: false,
  error: null,
};

function startLoading(state: GroupIssueState) {
  state.isLoading = true;
}

function loadingFailed(state: GroupIssueState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const groupIssues = createSlice({
  name: "groupIssues",
  initialState: GroupIssueInitialState,
  reducers: {
    getGroupIssuesStart: startLoading,
    getGroupIssuesSuccess(state, { payload }: PayloadAction<GroupIssueResult>) {
      const { groupIssues } = payload;

      state.isLoading = false;
      state.error = null;

      groupIssues.forEach((groupIssue) => {
        state.groupIssuesById[groupIssue.GroupIssueID] = groupIssue;
      });

      state.groupIssueList = groupIssues.map(
        (groupIssue) => groupIssue.GroupIssueID
      );
    },
    getGroupIssuesFailure: loadingFailed,
    createGroupIssueStart: startLoading,
    createGroupIssueSuccess(state, { payload }: PayloadAction<GroupIssue>) {
      const { GroupIssueID } = payload;
      state.groupIssuesById[GroupIssueID] = payload;
      state.groupIssueList.push(GroupIssueID);

      state.isLoading = false;
      state.error = null;
    },
    updateGroupIssueSuccess(state, { payload }: PayloadAction<GroupIssue>) {
      const { GroupIssueID } = payload;
      state.groupIssuesById[GroupIssueID] = payload;
      state.isLoading = false;
      state.error = null;
    },
    deleteGroupIssueSuccess(state, { payload }: PayloadAction<number>) {
      const GroupIssueID = payload;
      delete state.groupIssuesById[GroupIssueID];
      state.groupIssueList = state.groupIssueList.filter(
        (item) => item !== GroupIssueID
      );

      state.isLoading = false;
      state.error = null;
    },
    createGroupIssueFailure: loadingFailed,
  },
});

export const {
  getGroupIssuesStart,
  getGroupIssuesSuccess,
  getGroupIssuesFailure,
  createGroupIssueStart,
  createGroupIssueSuccess,
  updateGroupIssueSuccess,
  deleteGroupIssueSuccess,
  createGroupIssueFailure,
} = groupIssues.actions;

export default groupIssues.reducer;

export const fetchGroupIssues =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getGroupIssuesStart());
      const groupIssues = await getGroupIssues(accessToken);
      dispatch(getGroupIssuesSuccess(groupIssues));
    } catch (err: any) {
      dispatch(getGroupIssuesFailure(err.toString()));
    }
  };

export const fetchGroupIssuesByGroup =
  (accessToken: String, groupID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getGroupIssuesStart());
      const groupIssues = await getGroupIssuesByGroup(accessToken, groupID);
      dispatch(getGroupIssuesSuccess(groupIssues));
    } catch (err: any) {
      dispatch(getGroupIssuesFailure(err.toString()));
    }
  };

export const fetchGroupIssuesByIssue =
  (accessToken: String, issueID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getGroupIssuesStart());
      const groupIssues = await getGroupIssuesByIssue(accessToken, issueID);
      dispatch(getGroupIssuesSuccess(groupIssues));
    } catch (err: any) {
      dispatch(getGroupIssuesFailure(err.toString()));
    }
  };

export const delGroupIssue =
  (
    accessToken: String,
    groupIssueID: number,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createGroupIssueStart());
      const result = await deleteGroupIssue(accessToken, groupIssueID);
      dispatch(deleteGroupIssueSuccess(groupIssueID));
      dispatch(openSnackBar(snackbarConstants.DELETE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createGroupIssueFailure(err.toString()));
      dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
    }
  };
