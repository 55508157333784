import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { addContact, updContact, delContact } from "./ContactSlice";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Tooltip,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { IppFormHeader } from "components/IppFormHeader";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppTextField } from "components/IppTextField";
import { IppAutocomplete } from "components/IppAutocomplete";
import { IppFormDivider } from "components/IppFormDivider";
import { IppDisplayField } from "components/IppDisplayField";
import { IppRating } from "components/IppRating";
import LoadingIndicator from "components/LoadingIndicator";
import { IppAttachmentInventory } from "components/IppAttachmentInventory";
import { fetchContactGroupsByContact } from "../../stakeholder/contactGroup/ContactGroupSlice";
import { fetchContactInteractionsByContact } from "../../stakeholder/contactInteraction/ContactInteractionSlice";
import { fetchGrievanceContactsByContact } from "../../stakeholder/grievanceContact/GrievanceContactSlice";
import { IppChildInventory } from "components/Inventory/IppChildInventory";
import { PersonRenderOption } from "utils/renderFunctions";
import { transformPILSDs } from "utils/functions";
import {
  ConvertDateOffset,
  getDateFromDateString,
} from "../../../utils/DateFunctions";
import { IppTokenAutocomplete } from "components/IppTokenAutoComplete";
import { fetchStakeholderGroups } from "../groups/StakeholderGroupSlice";
import { fetchDeleteChecksByContact } from "features/deleteCheck/DeleteCheckSlice";
import { fetchInteractions } from "../../stakeholder/interaction/InteractionSlice";
import { fetchGrievances } from "../../stakeholder/grievance/GrievanceSlice";
import { getCurrentModule } from "../../../utils/urlUtils";
import { fetchUsers } from "features/users/UsersSlice";
import { ContactCheckDuplicates } from "./ContactCheckDuplicatesPage";
import { ValidationSchema } from "./ContactValidation";
import { Prompt } from "react-router";
import { IppTabPanel } from "components/IppTabPanel";
import { countAttachments } from "api/attachmentsAPI";
import { User } from "api/usersAPI";
import { UserRole } from "api/userRoleAPI";
import { ClientModule } from "api/clientModuleAPI";
import { fetchContactIssuesByContact } from "features/stakeholder/contactIssue/ContactIssueSlice";
import { fetchIssues } from "features/stakeholder/issue/IssueSlice";
import { IppMultiTextField } from "components/IppMultiTextField";
import { IsMedSmall } from "utils/mediaQueries";
import { useSnackBarConstants, useTypedTranslation } from "utils/customHooks";
import { IppDisplayChip } from "components/IppDisplayChip";
import { UserWriteAccess } from "utils/userAccess";
import { fetchCommitmentsByAssociatedContact } from "features/commitments/commitment/CommitmentSlice";
import { IppTokenMultiSelect } from "components/IppTokenMultiSelect";
import { fetchTagsByRecordType } from "../admin/tag/TagSlice";
import { IppLocationDisplay } from "components/IppLocationDisplay";
import { IppLocationAutoComplete } from "components/IppLocationAutoComplete";
import { IppDisplayCheckbox } from "components/IppDisplayCheckbox";
import { IppCheckbox } from "components/IppCheckbox";
import { IppMergeButton } from "components/Buttons/IppMergeButton";
import { fetchProximityIndicatorLSDs } from "features/datalists/PILSDSlice";
import { IppDynamicPILSD } from "components/IppDynamicPILSD";
import { fetchClientInteractionTypes } from "../admin/clientInteractionTypes/ClientInteractionTypeSlice";
import { selectAllUserRoles } from "features/roles/UserRoleSlice";

const PREFIX = "ContactForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    // minWidth: 650,
    maxWidth: 1150,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const ContactForm = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen"]);
  const snackbarConstants = useSnackBarConstants();
  const customBP = IsMedSmall();

  const [didSaveInventory, setDidSaveInventory] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [showDuplicateCheck, setShowDuplicateCheck] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  let showCommunications = true;
  let showCommitments = true;
  let showGroups = true;
  let showGrievances = true;
  let showIssues = true;
  let showAttachments = true;
  //let index: number[] = [];

  const {
    clientId,
    isLoading: clientIsLoading,
    ft_eng_lvlOfEngagement,
    ft_eng_ContactIssue,
    ft_eng_Grievance,
    ft_all_Tag,
    ft_eng_PILSD,
    ft_eng_PreferredCommunicationMethod,
  } = useSelector((state: RootState) => state.client);

  //We want to control the tabs we display to the user depending on the module.
  let currentModule: string;
  switch (getCurrentModule()) {
    case "commitments":
      currentModule = "commitments";
      showCommunications = false;
      showGrievances = false;
      showIssues = false;
      //index = [-1, 0, -1, -1, 1];
      break;
    case "engagement":
      currentModule = "engagement";
      showCommitments = false;
      if (ft_eng_ContactIssue) {
        //index = [0, 1, 2, 3, 4];
      } else {
        showIssues = false;
        //index = [0, 1, 2, -1, 3];
      }
      break;
    default:
  }

  useEffect(() => {
    if (currentModule === "commitments") {
      setActiveTab(1);
    }
  }, []);

  let itemData = props.contact || {};
  const showEdit = UserWriteAccess("EngComm");

  const [isEditing, setIsEditing] = useState(itemData.ContactID ? false : true);
  const [isAdding] = useState(itemData.ContactID ? false : true);
  const [listGroups, setListGroups] = useState<Array<string>>([]);
  const [listIssues, setListIssues] = useState<Array<string>>([]);
  const [listComms, setListComms] = useState<Array<string>>([]);
  const [tagItems, setTagItems] = useState(
    itemData.Tags ? itemData.Tags : undefined
  );

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const {
    contactGroupList,
    contactGroupsById,
    isLoading: contactGroupIsLoading,
  } = useSelector((state: RootState) => state.contactGroups);

  const contactGroupsUnfiltered = contactGroupList.map(
    (id) => contactGroupsById[id]
  );

  const contactGroups = contactGroupsUnfiltered.filter(
    (groups) => groups.ContactID === itemData.ContactID
  );

  const { contactInteractionList, contactInteractionsById } = useSelector(
    (state: RootState) => state.contactInteractions
  );

  const contactInteractionsUnfiltered = contactInteractionList.map(
    (id) => contactInteractionsById[id]
  );

  const contactInteractions = contactInteractionsUnfiltered.filter(
    (comms) => comms.ContactID === itemData.ContactID
  );

  const ci = contactInteractions.map((p) => {
    let n = Object.assign({}, p);
    n.InteractionDate = ConvertDateOffset(p.InteractionDate);
    return n;
  });

  const { interactionList, interactionsById } = useSelector(
    (state: RootState) => state.interactions
  );

  const interactions = interactionList.map((id) => interactionsById[id]);

  const { grievanceList, grievancesById } = useSelector(
    (state: RootState) => state.grievances
  );

  const grievances = grievanceList.map((id) => grievancesById[id]);

  const { stakeholderGroupList, stakeholderGroupsById } = useSelector(
    (state: RootState) => state.stakeholderGroups
  );

  const stakeholderGroups = stakeholderGroupList.map(
    (id) => stakeholderGroupsById[id]
  );

  const { clientModuleList, clientModulesById } = useSelector(
    (state: RootState) => state.clientModules
  );

  const clientmodules = clientModuleList.map((id) => clientModulesById[id]);

  const { userList, usersById } = useSelector(
    (state: RootState) => state.users
  );

  const userRoles = useSelector((state: RootState) =>
    selectAllUserRoles(state)
  );

  //Filter for active users only
  const allUsers = userList.map((id) => usersById[id]);
  let users = allUsers.filter((item: User) => {
    if (!item.IsActive || item.CompanyID != currentProfile.CompanyID)
      return false;
    if (item.IsClientAdmin) {
      return true;
    } else {
      let found = userRoles.filter((role) => {
        return item.UserAccountID === role.UserAccountID;
      });
      if (found && found.length > 0) {
        let check = clientmodules.find((object: ClientModule) => {
          let clientMod = found.find((mod: UserRole) => {
            return (
              object.ClientModuleID === mod.ClientModuleID &&
              (object.ModuleID === 3 || object.ModuleID === 2)
            );
          });
          return clientMod;
        });
        return check;
      } else return false;
    }
  });

  const { grievanceContactList, grievanceContactsById } = useSelector(
    (state: RootState) => state.grievanceContacts
  );

  const grievanceContactsUnfiltered = grievanceContactList.map(
    (id) => grievanceContactsById[id]
  );

  const grievanceContacts = grievanceContactsUnfiltered.filter(
    (grievances) => grievances.ContactID === itemData.ContactID
  );

  const gc = grievanceContacts.map((p) => {
    let n = Object.assign({}, p) as any;
    if (p.ReportedDate !== null) {
      n.ReportedDate = ConvertDateOffset(p.ReportedDate);
    } else {
      n.ReportedDate = null;
    }

    if (p.ResolutionDate !== null) {
      n.ResolutionDate = ConvertDateOffset(p.ResolutionDate);
    } else {
      n.ResolutionDate = null;
    }

    return n;
  });

  const {
    contactIssueList,
    contactIssuesById,
    isLoading: contactIssueIsLoading,
  } = useSelector((state: RootState) => state.contactIssues);

  const contactIssuesUnfiltered = contactIssueList.map(
    (id) => contactIssuesById[id]
  );

  const contactIssues = contactIssuesUnfiltered.filter(
    (issues) => issues.ContactID === itemData.ContactID
  );

  const { issueList, issuesById } = useSelector(
    (state: RootState) => state.issues
  );

  const issues = issueList.map((id) => issuesById[id]);

  // used to display attachments count
  const [attachCount, setAttachCount] = useState({ Count: 0 } as any);

  //usestate for Rootstate of attachment
  const { attachmentsById, attachmentList } = useSelector(
    (state: RootState) => state.attachments
  );

  const attachments = attachmentList.map((id) => attachmentsById[id]);

  const {
    proximityIndicatorLSDList,
    proximityIndicatorLSDsById,
    isLoading: PILSDIsLoading,
  } = useSelector((state: RootState) => state.proximityIndicatorLSDs);

  const proximityIndicatorLSDs = ft_eng_PILSD
    ? proximityIndicatorLSDList.map((id) => proximityIndicatorLSDsById[id])
    : [];

  const {
    commitmentList,
    commitmentsById,
    isLoading: commitmentIsLoading,
  } = useSelector((state: RootState) => state.commitments);

  const commitments = commitmentList.map((id) => commitmentsById[id]);

  const {
    tagList,
    tagsById,
    isLoading: tagIsLoading,
  } = useSelector((state: RootState) => state.tag);

  const tags = ft_all_Tag ? tagList.map((id) => tagsById[id]) : [];

  // get lists for commitments  autocomplete fields
  useEffect(() => {
    // effect
    if (!commitmentIsLoading) {
      let newList: string[] = [];
      commitments.map((item) => newList.push(item.CommitmentTitle));
      setListComms(newList);
    }
    return () => {
      // cleanup
    };
  }, [itemData.GroupID, commitmentIsLoading, isEditing]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchIssues(accessToken));
        dispatch(fetchUsers(accessToken, clientId));
        dispatch(fetchStakeholderGroups(accessToken));
        dispatch(fetchInteractions(accessToken));
        dispatch(fetchGrievances(accessToken));
        if (ft_eng_PILSD) {
          dispatch(fetchProximityIndicatorLSDs(accessToken));
        }
        if (ft_all_Tag) {
          dispatch(fetchTagsByRecordType(accessToken, "Contact"));
        }

        if (ft_eng_PreferredCommunicationMethod) {
          dispatch(fetchClientInteractionTypes(accessToken));
        }

        if (itemData.ContactID) {
          dispatch(
            fetchContactGroupsByContact(accessToken, itemData.ContactID)
          );
          dispatch(
            fetchGrievanceContactsByContact(accessToken, itemData.ContactID)
          );
          dispatch(
            fetchContactInteractionsByContact(accessToken, itemData.ContactID)
          );
          dispatch(
            fetchContactIssuesByContact(accessToken, itemData.ContactID)
          );
          if (currentModule === "commitments") {
            dispatch(
              fetchCommitmentsByAssociatedContact(
                accessToken,
                itemData.ContactID
              )
            );
          }

          const count = await countAttachments(
            accessToken,
            "Contacts",
            itemData.ContactID
          );
          setAttachCount(count.attachments ? count.attachments : 0);
        }
        setPageLoading(false);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, itemData.ContactID, ft_all_Tag]);

  // used for attachment count
  useEffect(() => {
    (async () => {
      try {
        if (attachments.length > 0) {
          const attach = attachments.filter((item) => {
            return (
              item.RecordID === itemData.ContactID &&
              item.RecordType === "Contacts"
            );
          });
          if (attach && attach.length > 0)
            setAttachCount({ Count: attach.length });
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [attachmentList]);

  // get list of contacts for token autocomplete field
  useEffect(() => {
    // effect
    if (!contactGroupIsLoading) {
      let newList: string[] = [];
      contactGroups.map((item) => newList.push(item.GroupName));
      setListGroups(newList);
    }
    return () => {
      // cleanup
    };
  }, [itemData.InteractionID, contactGroupIsLoading, isEditing]);

  // get lists for issues  autocomplete fields
  useEffect(() => {
    // effect
    if (!contactIssueIsLoading) {
      let newList: string[] = [];
      contactIssues.map((item) => newList.push(item.IssueName));
      setListIssues(newList);
    }
    return () => {
      // cleanup
    };
  }, [itemData.ContactID, contactIssueIsLoading, isEditing]);

  // save when changes made to child records
  useEffect(() => {
    if (didSaveInventory) {
      submitFunc(itemData);
      setDidSaveInventory(false);
    }
    return () => {
      // cleanup
    };
  }, [didSaveInventory]);

  // convert tags to objects for multi autocomplete field
  useEffect(() => {
    // effect
    if (
      ft_all_Tag &&
      tags &&
      tags.length > 0 &&
      !tagIsLoading &&
      itemData.Tags
    ) {
      let newList = tags.filter((item) => itemData.Tags.includes(item.Name));
      setTagItems(newList);
    }
    return () => {
      // cleanup
    };
  }, [tagIsLoading, isEditing]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        if (ft_eng_PILSD) {
          values = {
            ...values,
            ProximityIndicatorLSDs: Array.isArray(values.ProximityIndicatorLSDs)
              ? values.ProximityIndicatorLSDs.filter(
                  (pilsd: any) =>
                    pilsd.ProximityIndicator !== "" ||
                    pilsd.LegalSubdivision !== ""
                )
              : [],
          };
        }

        dispatch(
          addContact(
            accessToken,
            values,
            listGroups,
            listIssues,
            true,
            snackbarConstants
          )
        );
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  if (itemData.ContactID) {
    //Update case
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });

          if (ft_eng_PILSD) {
            values = {
              ...values,
              ProximityIndicatorLSDs: Array.isArray(
                values.ProximityIndicatorLSDs
              )
                ? values.ProximityIndicatorLSDs.filter(
                    (pilsd: any) =>
                      pilsd.ProximityIndicator !== "" ||
                      pilsd.LegalSubdivision !== ""
                  )
                : [],
            };
          }

          dispatch(
            updContact(
              accessToken,
              values.ContactID,
              listGroups,
              listIssues,
              values,
              true,
              snackbarConstants
            )
          );
        } catch (e) {
          console.error(e);
        }
      })();
    };
  } else {
    itemData.Address = "";
    itemData.AltPhone = "";
    itemData.FirstName = "";
    itemData.Surname = "";
    itemData.ContactMethodID = -1;
    itemData.ContactTitle = "";
    itemData.Country = "";
    itemData.Email = "";
    itemData.PostalCode = "";
    itemData.PrimaryPhone = "";
    itemData.ProvinceState = "";
    itemData.TownCity = "";
    itemData.Notes = "";
    itemData.RelationshipOwner = null;
    itemData.Tags = [];
    itemData.ProximityIndicatorLSDs = [];
  }

  const handleDelete = () => {
    // function to delete current community investment entry
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(
          delContact(accessToken, itemData.ContactID, snackbarConstants)
        );
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const {
    // Preferred method of contact feature
    clientInteractionTypesById,
    clientInteractionTypeList,
    isLoading: typeIsLoading,
  } = useSelector((state: RootState) => state.clientInteractionTypes);

  const clientInteractionTypes = clientInteractionTypeList.map(
    (id) => clientInteractionTypesById[id]
  );

  const handleTabSelect = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };

  const onCloseDuplicateCheck = () => {
    setShowDuplicateCheck(false);
  };

  const formik = useFormik({
    initialValues: itemData,
    validationSchema: ValidationSchema(),
    onSubmit: submitFunc,
  });

  let viewForm = (
    <Root>
      <Box display="flex" justifyContent="center">
        <Grid container className={classes.editForm} spacing={1}>
          <Grid item xs={12}>
            <Paper className={classes.boxSpace}>
              <Grid container spacing={1}>
                <IppFormHeader
                  title={t("objPlt:objects.contact.name")}
                  isEditing={isEditing}
                  isAdding={isAdding}
                />
                <Grid item xs={6}>
                  <IppFormDivider
                    title={t("objPlt:objects.contact.headers.contactdetails")}
                  />
                </Grid>
                {showEdit && (
                  <Grid item xs={6}>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      sx={{ paddingTop: 1 }}
                    >
                      <Tooltip
                        title={t("strGen:pages.merge.tooltip", {
                          fieldname: t("objPlt:objects.contact.name", {
                            count: 2,
                          }),
                        })}
                      >
                        <IppMergeButton
                          onClick={() => setShowDuplicateCheck(true)}
                        />
                      </Tooltip>
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <IppDisplayField
                    showLabel={false}
                    value={
                      (!formik.values.FirstName
                        ? "\u00A0"
                        : formik.values.FirstName) +
                      " " +
                      (!formik.values.Surname
                        ? "\u00A0"
                        : formik.values.Surname)
                    }
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEmphasis={true}
                    subText={formik.values.ContactTitle}
                    showEdit={showEdit}
                  />
                </Grid>
                {ft_eng_PreferredCommunicationMethod && (
                  <Grid item xs={6}>
                    <IppDisplayField
                      label={t(
                        "objPlt:objects.contact.fields.preferredcommunicationmethod"
                      )}
                      value={
                        formik.values.InteractionTypeName || "Not specified"
                      }
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      showEdit={showEdit}
                    />
                  </Grid>
                )}
                <Grid item xs={6}>
                  <IppDisplayField
                    label={t("objPlt:objects.contact.fields.phoneprimary")}
                    value={formik.values.PrimaryPhone}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayField
                    label={t("objPlt:objects.contact.fields.phonealternate")}
                    value={formik.values.AltPhone}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
                {ft_all_Tag && tags.length > 0 && (
                  <Grid item xs={6}>
                    <IppDisplayChip
                      label={t("objPlt:objects.contact.fields.tag", {
                        count: itemData.Tags ? itemData.Tags.length : 1,
                      })}
                      value={itemData.Tags}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      showEdit={showEdit}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <IppDisplayChip
                    label={t("objPlt:objects.contact.fields.emailaddress")}
                    value={formik.values.Email}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
                <Grid item xs={12}>
                  <IppDisplayField
                    label={t("objPlt:objects.contact.fields.relationshipowner")}
                    value={
                      usersById && usersById[formik.values.RelationshipOwner]
                        ? usersById[formik.values.RelationshipOwner].FirstName +
                          " " +
                          usersById[formik.values.RelationshipOwner].Surname
                        : ""
                    }
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
                <Grid item xs={12}>
                  <IppDisplayField
                    label={t("objPlt:objects.contact.fields.notes")}
                    value={formik.values.Notes}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayCheckbox
                    label={t("objPlt:objects.contact.fields.internaluser")}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    isChecked={formik.values.InternalUser}
                  />
                </Grid>
                <IppLocationDisplay
                  address={formik.values.Address}
                  townCity={formik.values.TownCity}
                  provinceState={formik.values.ProvinceState}
                  country={formik.values.Country}
                  postalCode={formik.values.PostalCode}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
                {ft_eng_PILSD && (
                  <>
                    <IppFormDivider
                      title={t("objPlt:platformwide.headers.proximitylsd")}
                    />
                    <Grid item xs={12}>
                      {transformPILSDs(
                        formik.values?.ProximityIndicatorLSDs
                      ) ? (
                        <IppDisplayChip
                          label={""}
                          value={transformPILSDs(
                            formik.values?.ProximityIndicatorLSDs
                          )}
                          isEditing={isEditing}
                          setIsEditing={setIsEditing}
                          showEdit={showEdit}
                        />
                      ) : (
                        <IppDisplayField
                          value={t("objPlt:platformwide.notprovided")}
                          isEditing={isEditing}
                          setIsEditing={setIsEditing}
                        />
                      )}
                    </Grid>
                  </>
                )}
                {ft_eng_lvlOfEngagement && (
                  <>
                    <IppFormDivider
                      title={t(
                        "objPlt:objects.contact.headers.levelsofengagement"
                      )}
                    />
                    <Grid item xs={6}>
                      <IppRating
                        id="LevelOfInfluence"
                        label={t(
                          "objPlt:objects.contact.fields.levelofinfluence"
                        )}
                        value={formik.values.LevelOfInfluence}
                        onChangeFunction={formik.handleChange}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        showEditButton={showEdit}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <IppRating
                        id="LevelOfInterest"
                        label={t(
                          "objPlt:objects.contact.fields.levelofinterest"
                        )}
                        value={formik.values.LevelOfInterest}
                        onChangeFunction={formik.handleChange}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        showEditButton={showEdit}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <IppFormButtons
                    isEditing={isEditing}
                    isAdding={isAdding}
                    setIsEditing={setIsEditing}
                    showDelete={showEdit}
                    deleteFunction={() => handleDelete()}
                    fetchFunc={fetchDeleteChecksByContact}
                    info={t("strGen:prompts.deletechecks.unlinkitems")}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      {
        <ContactCheckDuplicates
          originalID={formik.values.ContactID}
          isOpen={showDuplicateCheck}
          closeWindow={onCloseDuplicateCheck}
        />
      }
    </Root>
  );

  let editForm = (
    <div>
      {!formik.isSubmitting && (
        <Prompt
          when={formik.dirty}
          message={t("strGen:prompts.unsavedchanges") || ""}
        />
      )}
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <IppFormHeader
            title={t("objPlt:objects.contact.name")}
            isEditing={isEditing}
            isAdding={isAdding}
          />
          <IppFormDivider
            title={t("objPlt:objects.contact.headers.contactdetails")}
          />
          <Grid item xs={6}>
            <IppTextField
              id="FirstName"
              label={t("objPlt:objects.contact.fields.firstname")}
              required={false}
              value={formik.values.FirstName}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.FirstName}
              errorsExpression={formik.errors.FirstName}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          <Grid item xs={6}>
            <IppTextField
              id="Surname"
              label={t("objPlt:objects.contact.fields.surname")}
              required={true}
              value={formik.values.Surname}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.Surname}
              errorsExpression={formik.errors.Surname}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          <Grid item xs={6}>
            <IppTextField
              id="ContactTitle"
              label={t("objPlt:objects.contact.fields.title")}
              required={false}
              value={formik.values.ContactTitle}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.ContactTitle}
              errorsExpression={formik.errors.ContactTitle}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          <Grid item xs={6}>
            <IppTextField
              id="PrimaryPhone"
              label={t("objPlt:objects.contact.fields.phoneprimary")}
              required={false}
              value={formik.values.PrimaryPhone}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.PrimaryPhone}
              errorsExpression={formik.errors.PrimaryPhone}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          <Grid item xs={6}>
            <IppTextField
              id="AltPhone"
              label={t("objPlt:objects.contact.fields.phonealternate")}
              required={false}
              value={formik.values.AltPhone}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.AltPhone}
              errorsExpression={formik.errors.AltPhone}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          <Grid item xs={6}>
            <IppMultiTextField
              id="Email"
              label={t("objPlt:objects.contact.fields.emailaddress")}
              required={false}
              options={[]}
              noOptionsText={t("strGen:components.nooptionstext")}
              value={formik.values.Email}
              setValue={(newValue: any) =>
                formik.setFieldValue("Email", newValue.toString())
              }
              touchedFunction={formik.touched.Email}
              errorFunction={formik.errors.Email}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              multiple={true}
              autoPopulate={false}
              freeSolo={true}
              emailSpaceToken={true}
            />
          </Grid>

          {ft_eng_PreferredCommunicationMethod && (
            <Grid item xs={6}>
              <IppAutocomplete
                id="ContactMethodID"
                options={clientInteractionTypes}
                value={clientInteractionTypes.find((obj) => {
                  return (
                    obj.InteractionTypeID === formik.values.ContactMethodID
                  );
                })}
                onChangeFunction={(event: ChangeEvent, newValue: any) => {
                  if (newValue) {
                    formik.setFieldValue(
                      "ContactMethodID",
                      newValue.InteractionTypeID
                    );
                  } else {
                    formik.setFieldValue("ContactMethodID", -1);
                  }
                }}
                label="Preferred Communication Method"
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                optionLabelFunction={(option: any) =>
                  option.InteractionTypeName
                }
                errorFunction={formik.errors.InteractionTypeName}
                touchedFunction={formik.touched.InteractionTypeName}
                textValueFunction={formik.values.InteractionTypeName}
              />
            </Grid>
          )}
          {ft_all_Tag && tags.length > 0 && (
            <Grid item xs={6}>
              <IppTokenMultiSelect
                id="Tags"
                label={t("objPlt:objects.contact.fields.tag", {
                  count: formik.values.Tags ? formik.values.Tags.length : 1,
                })}
                required={false}
                options={tags}
                noOptionsText={t("strGen:components.nooptionstext")}
                value={tagItems}
                setValue={(newValue: any) => {
                  setTagItems(newValue);
                  formik.setFieldValue("Tags", newValue);
                }}
                touchedFunction={formik.touched.Tags}
                errorFunction={formik.errors.Tags}
                isEditing={true}
                optionLabelFunction={(option: any) => option.Name}
                setIsEditing={null}
                toolTip={t("strGen:tooltips.tags")}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <IppAutocomplete
              id="RelationshipOwner"
              options={users}
              renderOption={(props: any, option: User) =>
                PersonRenderOption(props, option, [option.EmailAddress], "")
              }
              value={allUsers.find((obj) => {
                return obj.UserAccountID === formik.values.RelationshipOwner;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue(
                    "RelationshipOwner",
                    newValue.UserAccountID
                  );
                } else {
                  formik.setFieldValue("RelationshipOwner", null);
                }
              }}
              label={t("objPlt:objects.contact.fields.relationshipowner")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) =>
                option.FirstName + " " + option.Surname
              }
              errorFunction={formik.errors.RelationshipOwner}
              touchedFunction={formik.touched.RelationshipOwner}
              textValueFunction={formik.values.RelationshipOwner}
              autoPopulate={false}
            />
          </Grid>
          <Grid item xs={12}>
            <IppTextField
              id="Notes"
              label={t("objPlt:objects.contact.fields.notes")}
              value={formik.values.Notes}
              onChangeFunction={formik.handleChange}
              errorsExpression={formik.errors.Notes}
              touchedExpression={formik.touched.Notes}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              multiLine={true}
            />
          </Grid>
          <Grid item xs={6}>
            <IppCheckbox
              id="InternalUser"
              label={t("objPlt:objects.contact.fields.internaluser")}
              value={formik.values.InternalUser}
              isEditing={isEditing}
              onChangeFunction={formik.handleChange}
            />
          </Grid>
          <IppFormDivider
            title={t("objPlt:platformwide.headers.locationdetails")}
          />
          <IppLocationAutoComplete
            formik={formik}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            parentTitle={"Contact"}
          />

          {ft_eng_PILSD && (
            <>
              <Grid xs={12} item>
                <IppFormDivider
                  title={t("objPlt:platformwide.headers.proximitylsd")}
                />
                <IppDynamicPILSD
                  id="ProximityIndicatorLSDs"
                  options={proximityIndicatorLSDs}
                  value={formik.values.ProximityIndicatorLSDs}
                  onChangeFunction={(item: any) => {
                    formik.setFieldValue("ProximityIndicatorLSDs", item);
                  }}
                  touchedFunction={formik.touched.ProximityIndicatorLSDs}
                  label=""
                  isEditing={isEditing || isAdding}
                  setIsEditing={setIsEditing}
                />
              </Grid>
            </>
          )}
          {isAdding && (
            <>
              <IppFormDivider
                title={t("objPlt:objects.contact.headers.associatedgroups", {
                  count: listGroups.length,
                })}
              />
              <Grid item xs={12}>
                <IppTokenAutocomplete
                  id="GroupList"
                  label={t("objPlt:objects.contact.fields.groupsinvolved", {
                    count: listGroups.length,
                  })}
                  options={stakeholderGroups.map((option) => option.GroupName)}
                  selectedValues={listGroups}
                  setSelectedValues={setListGroups}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                />
              </Grid>
            </>
          )}
          {ft_eng_ContactIssue && isAdding && (
            <>
              <IppFormDivider
                title={t("objPlt:objects.contact.headers.relatedissues", {
                  count: listIssues.length,
                })}
              />
              <Grid item xs={12}>
                <IppTokenAutocomplete
                  id="IssueList"
                  label={t("objPlt:objects.contact.fields.issuesinvolved", {
                    count: listIssues.length,
                  })}
                  options={issues.map((option) => option.IssueName)}
                  selectedValues={listIssues}
                  setSelectedValues={setListIssues}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                />
              </Grid>
            </>
          )}
          {ft_eng_lvlOfEngagement && (
            <>
              <IppFormDivider
                title={t("objPlt:objects.contact.headers.levelsofengagement")}
              />
              <Grid item xs={6}>
                <IppRating
                  id="LevelOfInfluence"
                  label={t("objPlt:objects.contact.fields.levelofinfluence")}
                  value={formik.values.LevelOfInfluence}
                  onChangeFunction={formik.handleChange}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                />
              </Grid>
              <Grid item xs={6}>
                <IppRating
                  id="LevelOfInterest"
                  label={t("objPlt:objects.contact.fields.levelofinterest")}
                  value={formik.values.LevelOfInterest}
                  onChangeFunction={formik.handleChange}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            {!isAdding && (
              <Typography variant="caption">
                {t("strGen:forms.lastUpdated.withDateName", {
                  date: getDateFromDateString(formik.values.LastUpdated),
                  name:
                    formik.values.UserFirstName +
                    " " +
                    formik.values.UserSurname,
                })}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <IppFormButtons
              isEditing={isEditing}
              isAdding={isAdding}
              setIsEditing={setIsEditing}
              resetFunction={() => formik.resetForm()}
              showDelete={true}
              deleteFunction={() => handleDelete()}
              fetchFunc={fetchDeleteChecksByContact}
              info={t("strGen:prompts.deletechecks.unlinkitems")}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );

  const commTableData = commitments.map((p) => {
    let newItem = { ...p } as any;
    newItem.RecordedDate
      ? (newItem.RecordedDate = ConvertDateOffset(p.RecordedDate))
      : (newItem.RecordedDate = null);
    return newItem;
  });

  useEffect(() => {
    if (commTableData.length > 0 && !commitmentIsLoading) {
      if (
        !commTableData.every((comm) =>
          comm.hasOwnProperty("PersonResponsibleName")
        )
      ) {
        (async () => {
          try {
            const accessToken = await getAccessTokenSilently({
              authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
              },
            });
            dispatch(
              fetchCommitmentsByAssociatedContact(
                accessToken,
                itemData.ContactID
              )
            );
          } catch (e) {
            console.error(e);
          }
        })();
      }
    }
  }, [
    commTableData,
    itemData.ContactID,
    commitmentIsLoading,
    dispatch,
    getAccessTokenSilently,
  ]);

  const grievanceColumns = [
    {
      field: "SequenceID",
      title: t("objStk:objects.grievance.sequenceid"),
      format: "G-{0:0}",
      filter: "text",
      columnWidth: 60,
    },
    { field: "GrievanceName", title: t("objStk:objects.grievance.columnname") },
    {
      field: "ReportedDate",
      title: t("objStk:objects.grievance.fields.datereported"),
      format: "{0:d}",
      filter: "date",
    },
    {
      field: "ResolutionDate",
      title: t("objStk:objects.grievance.fields.dateresolved"),
      format: "{0:d}",
      filter: "date",
    },
    {
      field: "GrievanceStatusText",
      title: t("objStk:objects.grievance.fields.status"),
    },
  ];

  const groupColumns = [
    { field: "GroupName", title: t("objPlt:objects.group.name") },
    {
      field: "OrganizationTypeName",
      title: t("objPlt:objects.group.fields.organizationtype"),
    },
    {
      field: "TownCity",
      title: t("objPlt:objects.group.fields.towncity"),
    },
    {
      field: "ProvinceState",
      title: t("objPlt:objects.group.fields.provincestate"),
    },
  ];

  const interactionColumns = [
    {
      field: "CommunicationID",
      title: t("objStk:objects.interaction.sequenceid"),
      format: "C-{0:0}",
      filter: "string",
      columnWidth: 70,
    },
    {
      field: "InteractionTitle",
      title: t("objStk:objects.interaction.fields.title"),
    },
    {
      field: "InteractionTypeName",
      title: t("objStk:objects.interaction.fields.type"),
      icons: true,
      columnWidth: 60,
    },
    {
      field: "InteractionDate",
      title: t("objStk:objects.interaction.fields.date"),
      filter: "date",
      format: "{0:d}",
      columnWidth: 120,
    },
    {
      field: "ProjectName",
      title: t("objStk:objects.interaction.fields.project"),
    },
  ];

  const issueColumns = [
    { field: "IssueName", title: t("objStk:objects.issue.name") },
    {
      field: "IssueDescription",
      title: t("objStk:objects.issue.fields.description"),
    },
  ];

  const commitmentColumns = [
    {
      field: "SequenceID",
      title: t("objCom:objects.commitment.sequenceid"),
      format: "CMT-{0:0}",
      filter: "text",
      columnWidth: 80,
    },
    { field: "CommitmentTitle", title: t("objCom:objects.commitment.name") },
    {
      field: "PersonResponsibleName",
      title: t("objCom:objects.commitment.fields.responsible"),
    },
    {
      field: "RecordedDate",
      title: t("objPlt:platformwide.fields.date"),
      filter: "date",
      format: "{0:d}",
      columnWidth: 100,
    },
  ];

  let pageForm =
    pageLoading || clientIsLoading || tagIsLoading ? (
      <LoadingIndicator />
    ) : isAdding ? (
      <Box display="flex" justifyContent="center">
        <Grid container className={classes.editForm} spacing={1}>
          <Grid item xs={12}>
            <Paper className={classes.boxSpace}>{editForm}</Paper>
          </Grid>
        </Grid>
      </Box>
    ) : (
      <Grid container spacing={1}>
        <Grid item xl={4} xs={customBP ? 12 : 5}>
          {isEditing ? (
            <Dialog open={isEditing} fullWidth maxWidth="lg">
              <DialogContent>{editForm}</DialogContent>
            </Dialog>
          ) : (
            <div>{viewForm}</div>
          )}
        </Grid>
        <Grid item xl={8} xs={customBP ? 12 : 7}>
          <Paper>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={activeTab}
                  onChange={handleTabSelect}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                >
                  {showCommunications && (
                    <Tab
                      value={0}
                      label={`${t("objStk:objects.interaction.name", {
                        count: 2,
                      })} (${contactInteractions.length})`}
                    />
                  )}
                  {showCommitments && (
                    <Tab
                      value={1}
                      label={`${t("objCom:objects.commitment.name", {
                        count: 2,
                      })} (${commTableData.length})`}
                    />
                  )}
                  {showGroups && (
                    <Tab
                      value={2}
                      label={`${t("objPlt:objects.group.name", {
                        count: 2,
                      })} (${contactGroups.length})`}
                    />
                  )}
                  {ft_eng_Grievance && showGrievances && (
                    <Tab
                      value={3}
                      label={`${t("objStk:objects.grievance.name", {
                        count: 2,
                      })} (${grievanceContacts.length})`}
                    />
                  )}
                  {showIssues && (
                    <Tab
                      value={4}
                      label={`${t("objStk:objects.issue.name", {
                        count: 2,
                      })} (${contactIssues.length})`}
                    />
                  )}
                  {showAttachments && (
                    <Tab
                      value={5}
                      label={`${t("objPlt:objects.attachment.name", {
                        count: 2,
                      })} (${attachCount.Count})`}
                    />
                  )}
                </Tabs>
              </Box>
              {showCommunications && (
                <IppTabPanel value={activeTab} index={0}>
                  <Box>
                    <IppChildInventory
                      title={t("objStk:objects.interaction.name")}
                      parentTitle={t("objPlt:objects.contact.name")}
                      linkURL="communications"
                      tableData={ci}
                      idField="ContactInteractionID"
                      nameField="InteractionTitle"
                      parentID={itemData.ContactID}
                      relatedField="InteractionID"
                      columns={interactionColumns}
                      showAdd={true}
                      tableName="ContactInteraction"
                      id="CommunicationList"
                      label={t(
                        "objStk:objects.interaction.communicationhistory"
                      )}
                      options={interactions}
                      selectedValues={contactInteractions}
                      setSelectedValues={null}
                      setDidSaveInventory={setDidSaveInventory}
                      parentValue={itemData}
                      showOptions={true}
                      secondLinkCell={1}
                    />
                  </Box>
                </IppTabPanel>
              )}
              {showCommitments && (
                <IppTabPanel value={activeTab} index={1}>
                  <Box>
                    <IppChildInventory
                      title={t("objCom:objects.commitment.name")}
                      parentTitle={t("objPlt:objects.contact.name")}
                      linkURL="commitments"
                      tableData={commTableData}
                      idField="CommitmentID"
                      nameField="CommitmentTitle"
                      parentID={itemData.ContactID}
                      relatedField="CommitmentID"
                      columns={commitmentColumns}
                      showAdd={true}
                      tableName="Commitment"
                      id="CommitmentList"
                      label={t(
                        "objCom:objects.commitment.fields.commitmenthistory"
                      )}
                      options={commitments}
                      selectedValues={listComms}
                      setSelectedValues={setListComms}
                      setDidSaveInventory={setDidSaveInventory}
                      parentValue={itemData.ContactID}
                      showOptions={true}
                      secondLinkCell={1}
                    />
                  </Box>
                </IppTabPanel>
              )}
              <IppTabPanel value={activeTab} index={2}>
                <Box>
                  <IppChildInventory
                    title={t("objPlt:objects.group.name")}
                    parentTitle={t("objPlt:objects.contact.name")}
                    linkURL="groups"
                    tableData={contactGroups}
                    idField="ContactGroupID"
                    nameField="GroupName"
                    parentID={itemData.ContactID}
                    relatedField="GroupID"
                    columns={groupColumns}
                    showEdit={true}
                    tableName="ContactGroup"
                    id="GroupList"
                    label={t("objPlt:objects.contact.fields.groupsinvolved", {
                      count: listGroups.length,
                    })}
                    options={stakeholderGroups}
                    selectedValues={listGroups}
                    setSelectedValues={setListGroups}
                    setDidSaveInventory={setDidSaveInventory}
                    parentValue={null}
                    showOptions={true}
                  />
                </Box>
              </IppTabPanel>
              {ft_eng_Grievance && (
                <IppTabPanel value={activeTab} index={3}>
                  <Box>
                    <IppChildInventory
                      title={t("objStk:objects.grievance.name")}
                      parentTitle={t("objPlt:objects.contact.name")}
                      linkURL="grievances"
                      tableData={gc}
                      idField="GrievanceContactID"
                      nameField="GrievanceName"
                      parentID={itemData.ContactID}
                      relatedField="GrievanceID"
                      columns={grievanceColumns}
                      showAdd={true}
                      tableName="GrievanceContact"
                      id="GrivanceList"
                      label={t("objStk:objects.grievance.grievancehistory")}
                      options={grievances}
                      selectedValues={grievanceContacts}
                      setSelectedValues={null}
                      setDidSaveInventory={setDidSaveInventory}
                      parentValue={itemData}
                      showOptions={true}
                      secondLinkCell={1}
                    />
                  </Box>
                </IppTabPanel>
              )}

              {ft_eng_ContactIssue && (
                <IppTabPanel value={activeTab} index={4}>
                  <Box>
                    <IppChildInventory
                      title={t("objStk:objects.issue.name")}
                      parentTitle={t("objPlt:objects.contact.name")}
                      linkURL="issues"
                      tableData={contactIssues}
                      idField="ContactIssueID"
                      nameField="IssueName"
                      parentID={itemData.ContactID}
                      relatedField="IssueID"
                      columns={issueColumns}
                      tableName="ContactIssue"
                      id="IssueList"
                      label={t("objPlt:objects.contact.headers.relatedissues", {
                        count: listIssues.length,
                      })}
                      options={issues}
                      selectedValues={listIssues}
                      setSelectedValues={setListIssues}
                      setDidSaveInventory={setDidSaveInventory}
                      parentValue={null}
                      showOptions={true}
                    />
                  </Box>
                </IppTabPanel>
              )}
              <IppTabPanel value={activeTab} index={5}>
                <Box>
                  <IppAttachmentInventory
                    recordType="Contacts"
                    itemID={itemData.ContactID}
                    companyID={formik.values.CompanyID}
                    projectID={0}
                    moduleID={3}
                  />
                </Box>
              </IppTabPanel>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    );

  return <Root>{pageForm}</Root>;
};
