// ------------ Module Types ------------
export enum Modules {
  Benefits = 1,
  Commitments = 2,
  Engagements = 3,
  Planning = 4,
  Supplier = 5,
  SSHE = 6,
}

export enum ModuleRoles {
  BenefitsPowerUser = 1,
  BenefitsCompanyUser = 2,
  CommitmentsPowerUser = 3,
  CommitmentsContributor = 4,
  EngagementsPowerUser = 5,
  BenefitsViewOnly = 7,
  CommitmentsViewOnly = 8,
  EngagementsViewOnly = 9,
  SupplierPowerUser = 10,
  SSHEUser = 11,
}
