import axiosClient from "../axiosClient";

export interface ContactIssue {
  ContactIssueID: number;
  ContactID: number;
  IssueID: number;
  IssueName: string;
  ContactName: string;
  FirstName: string;
  Surname: string;
  ContactTitle: string;
  PrimaryPhone: string;
  Email: string;
  ClientID: number;
  IssueDescription: string;
  ParentIssueID: number;
}

export interface ContactIssuesResult {
  contactIssues: ContactIssue[];
}

// NOT BEING USED

// export async function getContactIssues(
//   accessToken: String
// ): Promise<ContactIssuesResult> {
//   const url = `/contactIssue`;

//   try {
//     const { data } = await axiosClient.get<ContactIssuesResult>(url, {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//     });

//     return data;
//   } catch (err: any) {
//     throw err;
//   }
// }

export async function getContactIssuesByIssue(
  accessToken: String,
  issueID: number
): Promise<ContactIssuesResult> {
  const url = `/contactIssue/issue/${issueID}`;

  try {
    const { data } = await axiosClient.get<ContactIssuesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getContactIssuesByContact(
  accessToken: String,
  contactID: number
): Promise<ContactIssuesResult> {
  const url = `/contactIssue/contact/${contactID}`;

  try {
    const { data } = await axiosClient.get<ContactIssuesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

// NOT BEING USED

// export async function createContactIssue(
//   accessToken: String,
//   newContactIssue: Partial<ContactIssue>
// ): Promise<ContactIssue> {
//   const url = `/contactIssue`;

//   try {
//     const { data } = await axiosClient.post<ContactIssue>(
//       url,
//       newContactIssue,
//       {
//         headers: {
//           Authorization: `Bearer ${accessToken}`,
//           "Content-Type": "application/json",
//         },
//       }
//     );

//     return data;
//   } catch (err: any) {
//     throw err;
//   }
// }

// export async function updateContactIssue(
//   accessToken: String,
//   contactIssueID: number,
//   newContactIssue: Partial<ContactIssue>
// ): Promise<ContactIssue> {
//   const url = `/contactIssue/` + contactIssueID;

//   try {
//     const { data } = await axiosClient.post<ContactIssue>(
//       url,
//       newContactIssue,
//       {
//         headers: {
//           Authorization: `Bearer ${accessToken}`,
//           "Content-Type": "application/json",
//         },
//       }
//     );

//     return data;
//   } catch (err: any) {
//     throw err;
//   }
// }

export async function deleteContactIssue(
  accessToken: String,
  contactIssueID: number
): Promise<string> {
  const url = `/contactIssue/` + contactIssueID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
