import React from "react";
import { Save } from "@mui/icons-material";
import { ButtonProps } from "@mui/material/Button";
import { IppButton, IppButtonProps } from "components/Buttons/IppButton";
import { useTypedTranslation } from "utils/customHooks";

interface IppSaveButtonProps extends IppButtonProps {
  buttonText?: string;
  handleSave: (() => Promise<void>) | (() => void);
  startIcon?: React.ReactNode;
  color?: ButtonProps["color"];
}

export const IppSaveButton = ({
  buttonText,
  handleSave,
  startIcon = <Save />,
  color = "secondary",
  ...props
}: IppSaveButtonProps) => {
  const t = useTypedTranslation(["strGen"]);

  const T_buttonText = buttonText || t("strGen:buttons.savechanges");

  return (
    <IppButton
      color={color}
      startIcon={startIcon}
      onClick={handleSave}
      {...props}
    >
      {T_buttonText}
    </IppButton>
  );
};
