import { ActionReminderForm } from "./ActionReminderForm";

export const ActionReminderCreatePage = () => {
  let actionReminderCreateView = (
    <div>
      <ActionReminderForm />
    </div>
  );

  return <div id="actionReminder-detail-page">{actionReminderCreateView}</div>;
};
