import { Company } from "api/companyAPI";
import * as yup from "yup";
import { useTypedTranslation } from "utils/customHooks";

interface BaseSchemaOptions {
  isChildSchema: true;
  regionEnabled: boolean | undefined;
}

interface FullSchemaOptions {
  isChildSchema: false;
  companies: Company[];
  compData: Company;
  isAdding: boolean;
  tierEnabled: boolean | undefined;
  regionEnabled: boolean | undefined;
  activityLocationEnabled: boolean | undefined;
  oldCompVendorID?: string;
  dupeCompVendorID?: string;
}

type ValidationSchemaOptions = BaseSchemaOptions | FullSchemaOptions;

export const CompanyValidationSchema = (options: ValidationSchemaOptions) => {
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  const baseSchema = {
    CompanyName: yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.company.fields.companyname"),
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.company.fields.companyname"),
          count: 50,
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.company.name"),
        })
      ),
    Address: yup
      .string()
      .trim()
      .nullable()
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:platformwide.fields.address"),
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objPlt:platformwide.fields.address"),
          count: 50,
        })
      ),
    TownCity: yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:platformwide.fields.towncity"),
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objPlt:platformwide.fields.towncity"),
          count: 50,
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:platformwide.fields.towncity"),
        })
      ),
    ProvinceState: yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:platformwide.fields.provincestate"),
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objPlt:platformwide.fields.provincestate"),
          count: 50,
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:platformwide.fields.provincestate"),
        })
      ),
    CountryName: yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:platformwide.fields.country"),
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objPlt:platformwide.fields.country"),
          count: 50,
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:platformwide.fields.country"),
        })
      ),
    PostalCode: yup
      .string()
      .trim()
      .nullable()
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:platformwide.fields.postalcode"),
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objPlt:platformwide.fields.postalcode"),
          count: 50,
        })
      ),
    CompanyNotes: yup.string().trim().nullable(),
    RegionID: yup
      .number()
      .typeError(
        t("strGen:validation.typeerror.number", {
          fieldname: t("objBen:objects.company.fields.region.name"),
        })
      )
      .when([], {
        is: () => options.regionEnabled,
        then: (schema) => {
          return schema
            .positive(
              t("strGen:validation.required", {
                fieldname: t("objBen:objects.company.fields.region.name"),
              })
            )
            .required(
              t("strGen:validation.required", {
                fieldname: t("objBen:objects.company.fields.region.name"),
              })
            );
        },
        otherwise: (schema) => schema.nullable(),
      })
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.company.fields.region.name"),
          count: 50,
        })
      ),
    Local: yup.boolean().nullable(),
  };

  if (!options.isChildSchema) {
    const fullSchema = {
      ...baseSchema,
      VendorID: yup
        .string()
        .trim()
        .nullable()
        .max(
          50,
          t("strGen:validation.max", {
            fieldname: t("objBen:objects.company.fields.vendorID"),
            count: 50,
          })
        )
        .test("VendorID", "Vendor already exist", (value) => {
          if (!value) return true;
          const ver = options.companies.find((c) => c.VendorID === value);
          if (ver) {
            if (ver.VendorID === options.compData.VendorID && !options.isAdding)
              return true;
            if (
              options.oldCompVendorID &&
              ver.VendorID === options.oldCompVendorID
            )
              return true;
            if (
              options.dupeCompVendorID &&
              ver.VendorID === options.dupeCompVendorID
            )
              return true;
            else return false;
          } else return true;
        })
        .typeError(
          t("strGen:validation.invalid", {
            fieldname: t("objBen:objects.company.fields.vendorID"),
          })
        ),
      CategoryID: yup
        .number()
        .nullable()
        .typeError(
          t("strGen:validation.typeerror.number", {
            fieldname: t("objBen:objects.initiative.fields.category"),
          })
        ),
      ActivityLocationID: yup
        .number()
        .typeError(
          t("strGen:validation.typeerror.number", {
            fieldname: t("objBen:objects.company.fields.activitylocation.name"),
          })
        )
        .when([], {
          is: () => options.activityLocationEnabled,
          then: (schema) => {
            return schema
              .positive(
                t("strGen:validation.required", {
                  fieldname: t(
                    "objBen:objects.company.fields.activitylocation.name"
                  ),
                })
              )
              .required(
                t("strGen:validation.required", {
                  fieldname: t(
                    "objBen:objects.company.fields.activitylocation.name"
                  ),
                })
              );
          },
          otherwise: (schema) => schema.nullable(),
        }),
      Indigenous: yup
        .boolean()
        .nullable()
        .typeError(t("strGen:validation.invalidformat")),
      CompanyStructureID: yup
        .number()
        .typeError(
          t("strGen:validation.typeerror.number", {
            fieldname: t("objBen:objects.company.fields.ownershipstructure"),
          })
        )
        .when("Indigenous", {
          is: true,
          then: (value) =>
            value
              .positive(
                t("strGen:validation.required", {
                  fieldname: t(
                    "objBen:objects.company.fields.ownershipstructure"
                  ),
                })
              )
              .required(
                t("strGen:validation.required", {
                  fieldname: t(
                    "objBen:objects.company.fields.ownershipstructure"
                  ),
                })
              ),
          otherwise: (value) => value.nullable(),
        }),
      IndigenousGroupID: yup
        .number()
        .typeError(
          t("strGen:validation.typeerror.number", {
            fieldname: t("objPlt:platformwide.indigenous.indigenousgroup"),
          })
        )
        .when("Indigenous", {
          is: true,
          then: (value) =>
            value
              .positive(
                t("strGen:validation.required", {
                  fieldname: t(
                    "objPlt:platformwide.indigenous.indigenousgroup"
                  ),
                })
              )
              .required(
                t("strGen:validation.required", {
                  fieldname: t(
                    "objPlt:platformwide.indigenous.indigenousgroup"
                  ),
                })
              ),
          otherwise: (value) => value.nullable(),
        }),
      TierID: yup
        .number()
        .typeError(
          t("strGen:validation.typeerror.number", {
            fieldname: t("objBen:objects.tiers.name"),
          })
        )
        .when([], {
          is: () => options.tierEnabled,
          then: (schema) => {
            return schema
              .positive(
                t("strGen:validation.required", {
                  fieldname: t("objBen:objects.tiers.name"),
                })
              )
              .required(
                t("strGen:validation.required", {
                  fieldname: t("objBen:objects.tiers.name"),
                })
              );
          },
          otherwise: (schema) => schema.nullable(),
        }),
      Reference: yup
        .string()
        .trim()
        .nullable()
        .max(
          50,
          t("strGen:validation.max", {
            fieldname: t("objBen:objects.company.fields.internalreference"),
            count: 50,
          })
        ),
      WomanOwned: yup.boolean().nullable(),
      VeteranOwned: yup.boolean().nullable(),
      NorthernResident: yup.boolean().nullable(),
      Disability: yup.boolean().nullable(),
      LGBTQ: yup.boolean().nullable(),
      VisibleMinority: yup.boolean().nullable(),
    };

    return yup.object().shape(fullSchema);
  }

  return yup.object().shape(baseSchema);
};
