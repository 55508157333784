import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CommitmentSource,
  CommitmentSourcesResult,
  getCommitmentSources,
  createCommitmentSource,
  updateCommitmentSource,
  deleteCommitmentSource,
} from "api/commitments/commitmentSourceAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface CommitmentSourceState {
  commitmentSourcesById: Record<number, CommitmentSource>;
  commitmentSourceList: number[];
  isLoading: boolean;
  isLoadingAdd: boolean;
  subCommitmentSourceisLoading: boolean;
  isLoaded: boolean;
  error: string | null;
}

const CommitmentSourceInitialState: CommitmentSourceState = {
  commitmentSourcesById: {},
  commitmentSourceList: [],
  isLoading: false,
  isLoadingAdd: false,
  subCommitmentSourceisLoading: false,
  isLoaded: false,
  error: null,
};

function startLoading(state: CommitmentSourceState) {
  state.isLoading = true;
}

function startLoadingAdd(state: CommitmentSourceState) {
  state.isLoadingAdd = true;
}

function loadingFailed(
  state: CommitmentSourceState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const commitmentSources = createSlice({
  name: "commitmentSources",
  initialState: CommitmentSourceInitialState,
  reducers: {
    getCommitmentSourcesStart: startLoading,
    getCommitmentSourcesSuccess(
      state,
      { payload }: PayloadAction<CommitmentSourcesResult>
    ) {
      const { commitmentSources } = payload;

      state.isLoading = false;
      state.error = null;
      state.isLoaded = true;

      commitmentSources.forEach((commitmentSource) => {
        state.commitmentSourcesById[commitmentSource.CommitmentSourceID] =
          commitmentSource;
      });

      state.commitmentSourceList = commitmentSources.map(
        (commitmentSource) => commitmentSource.CommitmentSourceID
      );
    },
    getCommitmentSourcesFailure: loadingFailed,
    createCommitmentSourceStart: startLoadingAdd,
    createCommitmentSourceSuccess(
      state,
      { payload }: PayloadAction<CommitmentSource>
    ) {
      const { CommitmentSourceID } = payload;
      state.commitmentSourcesById[CommitmentSourceID] = payload;
      state.commitmentSourceList.push(CommitmentSourceID);

      state.isLoading = false;
      state.isLoadingAdd = false;
      state.error = null;
    },
    updateCommitmentSourceSuccess(
      state,
      { payload }: PayloadAction<CommitmentSource>
    ) {
      const { CommitmentSourceID } = payload;

      state.commitmentSourcesById[CommitmentSourceID] = payload;
      //state.commitmentSourceList.push(CommitmentSourceID);

      state.isLoading = false;
      state.error = null;
    },
    deleteCommitmentSourceSuccess(state, { payload }: PayloadAction<number>) {
      const CommitmentSourceID = payload;
      delete state.commitmentSourcesById[CommitmentSourceID];
      state.commitmentSourceList = state.commitmentSourceList.filter(
        (item) => item !== CommitmentSourceID
      );

      state.isLoading = false;
      state.error = null;
    },
    createCommitmentSourceFailure: loadingFailed,
  },
});

export const {
  getCommitmentSourcesStart,
  getCommitmentSourcesSuccess,
  getCommitmentSourcesFailure,
  createCommitmentSourceStart,
  createCommitmentSourceSuccess,
  updateCommitmentSourceSuccess,
  deleteCommitmentSourceSuccess,
  createCommitmentSourceFailure,
} = commitmentSources.actions;

export default commitmentSources.reducer;

export const fetchCommitmentSources =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCommitmentSourcesStart());
      const commitmentSources = await getCommitmentSources(accessToken);
      dispatch(getCommitmentSourcesSuccess(commitmentSources));
    } catch (err: any) {
      dispatch(getCommitmentSourcesFailure(err.toString()));
    }
  };

export const addCommitmentSource =
  (
    accessToken: String,
    newCommitmentSource: Partial<CommitmentSource>,
    setReturnRoute: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCommitmentSourceStart());
      const commitmentSource = await createCommitmentSource(
        accessToken,
        newCommitmentSource
      );
      dispatch(createCommitmentSourceSuccess(commitmentSource));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(fetchCommitmentSources(accessToken));
      if (setReturnRoute) {
        dispatch(
          push(`/commitments/sources/${commitmentSource.CommitmentSourceID}`)
        );
      }
    } catch (err: any) {
      dispatch(createCommitmentSourceFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updCommitmentSource =
  (
    accessToken: String,
    commitmentSourceID: number,
    newCommitmentSource: Partial<CommitmentSource>,
    setReturnRoute: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCommitmentSourceStart());
      const commitmentSource = await updateCommitmentSource(
        accessToken,
        commitmentSourceID,
        newCommitmentSource
      );

      dispatch(updateCommitmentSourceSuccess(commitmentSource));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(fetchCommitmentSources(accessToken));
      if (setReturnRoute) {
        dispatch(push("/commitments/sources/" + commitmentSourceID));
      }
    } catch (err: any) {
      dispatch(createCommitmentSourceFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delCommitmentSource =
  (accessToken: String, commitmentSourceID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCommitmentSourceStart());
      const result = await deleteCommitmentSource(
        accessToken,
        commitmentSourceID
      );
      dispatch(deleteCommitmentSourceSuccess(commitmentSourceID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
      dispatch(push("/commitments/sources"));
    } catch (err: any) {
      dispatch(createCommitmentSourceFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };
