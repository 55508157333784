import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  GroupInteraction,
  GroupInteractionsResult,
  SuggestedGroup,
  SuggestedGroupList,
  getGroupInteractions,
  getGroupInteractionsByGroup,
  getGroupInteractionsByInteraction,
  getSuggestedGroupsByInteractionContacts,
  createGroupInteraction,
  updateGroupInteraction,
  deleteGroupInteraction,
} from "api/stakeholder/groupInteractionAPI";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { SnackBarConstants } from "utils/customHooks";

interface GroupInteractionState {
  groupInteractionsById: Record<number, GroupInteraction>;
  groupInteractionList: number[];
  suggestedGroupList: SuggestedGroup[];
  isLoading: boolean;
  subGroupInteractionisLoading: boolean;
  error: string | null;
}

const GroupInteractionInitialState: GroupInteractionState = {
  groupInteractionsById: {},
  groupInteractionList: [],
  suggestedGroupList: [],
  isLoading: false,
  subGroupInteractionisLoading: false,
  error: null,
};

function startLoading(state: GroupInteractionState) {
  state.isLoading = true;
}

function loadingFailed(
  state: GroupInteractionState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const groupInteractions = createSlice({
  name: "groupInteractions",
  initialState: GroupInteractionInitialState,
  reducers: {
    getGroupInteractionsStart: startLoading,
    getGroupInteractionsSuccess(
      state,
      { payload }: PayloadAction<GroupInteractionsResult>
    ) {
      const { groupInteractions } = payload;

      state.isLoading = false;
      state.error = null;

      groupInteractions.forEach((groupInteraction) => {
        state.groupInteractionsById[groupInteraction.GroupInteractionID] =
          groupInteraction;
      });

      state.groupInteractionList = groupInteractions.map(
        (groupInteraction) => groupInteraction.GroupInteractionID
      );
    },
    getGroupInteractionsFailure: loadingFailed,
    getSuggestedGroupsStart: startLoading,
    getSuggestedGroupsSuccess(
      state,
      { payload }: PayloadAction<SuggestedGroupList>
    ) {
      const { suggestedGroups } = payload;

      state.isLoading = false;
      state.error = null;
      state.suggestedGroupList = suggestedGroups;
    },
    getSuggestedGroupsFailure: loadingFailed,
    createGroupInteractionStart: startLoading,
    createGroupInteractionSuccess(
      state,
      { payload }: PayloadAction<GroupInteraction>
    ) {
      const { GroupInteractionID } = payload;
      state.groupInteractionsById[GroupInteractionID] = payload;
      state.groupInteractionList.push(GroupInteractionID);

      state.isLoading = false;
      state.error = null;
    },
    updateGroupInteractionSuccess(
      state,
      { payload }: PayloadAction<GroupInteraction>
    ) {
      const { GroupInteractionID } = payload;

      state.groupInteractionsById[GroupInteractionID] = payload;
      //state.groupInteractionList.push(GroupInteractionID);

      state.isLoading = false;
      state.error = null;
    },
    deleteGroupInteractionSuccess(state, { payload }: PayloadAction<number>) {
      const GroupInteractionID = payload;
      delete state.groupInteractionsById[GroupInteractionID];
      state.groupInteractionList = state.groupInteractionList.filter(
        (item) => item !== GroupInteractionID
      );

      state.isLoading = false;
      state.error = null;
    },
    createGroupInteractionFailure: loadingFailed,
  },
});

export const {
  getGroupInteractionsStart,
  getGroupInteractionsSuccess,
  getGroupInteractionsFailure,
  getSuggestedGroupsStart,
  getSuggestedGroupsSuccess,
  getSuggestedGroupsFailure,
  createGroupInteractionStart,
  createGroupInteractionSuccess,
  updateGroupInteractionSuccess,
  deleteGroupInteractionSuccess,
  createGroupInteractionFailure,
} = groupInteractions.actions;

export default groupInteractions.reducer;

export const fetchGroupInteractions =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getGroupInteractionsStart());
      const groupInteractions = await getGroupInteractions(accessToken);
      dispatch(getGroupInteractionsSuccess(groupInteractions));
    } catch (err: any) {
      dispatch(getGroupInteractionsFailure(err.toString()));
    }
  };

export const fetchGroupInteractionsByGroup =
  (accessToken: String, groupID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getGroupInteractionsStart());
      const groupInteractions = await getGroupInteractionsByGroup(
        accessToken,
        groupID
      );
      dispatch(getGroupInteractionsSuccess(groupInteractions));
    } catch (err: any) {
      dispatch(getGroupInteractionsFailure(err.toString()));
    }
  };

export const fetchGroupInteractionsByInteraction =
  (accessToken: String, interactionID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getGroupInteractionsStart());
      const groupInteractions = await getGroupInteractionsByInteraction(
        accessToken,
        interactionID
      );
      dispatch(getGroupInteractionsSuccess(groupInteractions));
    } catch (err: any) {
      dispatch(getGroupInteractionsFailure(err.toString()));
    }
  };

export const fetchSuggestedGroupsByInteractionContacts =
  (accessToken: String, interactionID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getSuggestedGroupsStart());
      const suggestedGroups = await getSuggestedGroupsByInteractionContacts(
        accessToken,
        interactionID
      );
      dispatch(getSuggestedGroupsSuccess(suggestedGroups));
    } catch (err: any) {
      dispatch(getSuggestedGroupsFailure(err.toString()));
    }
  };

//! COMMENTED OUT FOR FUTURE CLEAN UP
// export const addGroupInteraction =
//   (
//     accessToken: String,
//     newGroupInteraction: Partial<GroupInteraction>,
//     snackbarConstants: SnackBarConstants
//   ): AppThunk =>
//   async (dispatch) => {
//     try {
//       dispatch(createGroupInteractionStart());
//       const groupInteraction = await createGroupInteraction(
//         accessToken,
//         newGroupInteraction
//       );
//       dispatch(createGroupInteractionSuccess(groupInteraction));
//       dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
//     } catch (err: any) {
//       dispatch(createGroupInteractionFailure(err.toString()));
//       dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
//     }
//   };

// export const updGroupInteraction =
//   (
//     accessToken: String,
//     groupInteractionID: number,
//     newGroupInteraction: Partial<GroupInteraction>,
//     snackbarConstants: SnackBarConstants
//   ): AppThunk =>
//   async (dispatch) => {
//     try {
//       dispatch(createGroupInteractionStart());
//       const groupInteraction = await updateGroupInteraction(
//         accessToken,
//         groupInteractionID,
//         newGroupInteraction
//       );
//       dispatch(updateGroupInteractionSuccess(groupInteraction));
//       dispatch(openSnackBar(snackbarConstants.UPDATE_SUCCESS, "success"));
//     } catch (err: any) {
//       dispatch(createGroupInteractionFailure(err.toString()));
//       dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
//     }
//   };

export const delGroupInteraction =
  (
    accessToken: String,
    groupInteractionID: number,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createGroupInteractionStart());
      const result = await deleteGroupInteraction(
        accessToken,
        groupInteractionID
      );
      dispatch(deleteGroupInteractionSuccess(groupInteractionID));
      dispatch(openSnackBar(snackbarConstants.DELETE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createGroupInteractionFailure(err.toString()));
      dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
    }
  };
