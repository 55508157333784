import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import {
  addCommitmentAction,
  updCommitmentAction,
} from "./CommitmentActionSlice";
import { Grid, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { IppTextField } from "components/IppTextField";
import LoadingIndicator from "components/LoadingIndicator";
import { IppAutocomplete } from "components/IppAutocomplete";
import { IppRichTextEditor } from "components/RichTextEditor/IppRichTextEditor";
import { ConvertDateOffset, GetJSDate } from "../../../utils/DateFunctions";
import { fetchActionTypes } from "../actionType/ActionTypeSlice";
import { fetchClientActionTypes } from "features/platform/admin/clientActionTypes/ClientActionTypeSlice";
import ScheduleFrequency from "components/ScheduleFrequency";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { push } from "redux-first-history";
import { IppCheckbox } from "components/IppCheckbox";
import { IppDatePicker } from "components/IppDatePicker";
import { fetchActionStatuss } from "features/datalists/ActionStatusSlice";
import { IppPersonResponsible } from "components/IppPersonResponsible";
import { useTypedTranslation } from "utils/customHooks";
import { PersonRenderOption } from "utils/renderFunctions";
import { Contact } from "api/stakeholder/contactAPI";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import useOverallResponsibilities from "components/CommitmentResponsibility";

interface ActionChildFormProps {
  closeAction: any; // action to close dialog
  setDidSaveInventory: any;
  parentTitle: string; // name of field the form is called from
  parentValue: any; // if called from child grid, init parent value
  parentID: any; // if called from child grid, init parent value
  action?: any; // existing interaction to be edited
}

export const CommitmentActionChildForm = (props: ActionChildFormProps) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);
  const OverallResponsibilities = useOverallResponsibilities();
  const [canSave, setCanSave] = useState(false);
  const [selectedFrequency, setSelectedFrequency] = useState("");

  const newDate = new Date(
    new Date().setFullYear(new Date().getFullYear() + 1)
  );
  const [continueUntil, setContinueUntil] = useState(newDate);
  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const { closeAction, setDidSaveInventory, parentID, parentValue, action } =
    props;

  const {
    clientId,
    isLoading: clientIsLoading,
    ft_all_ExternalPersonRes,
  } = useSelector((state: RootState) => state.client);

  const {
    actionStatusList,
    actionStatussById,
    isLoading: actionStatusIsLoading,
    error: actionStatusError,
  } = useSelector((state: RootState) => state.actionStatuss);

  const actionStatuses = actionStatusList.map((id) => actionStatussById[id]);

  const {
    commitmentList,
    commitmentsById,
    isLoading: commitmentIsLoading,
    error: commitmentError,
  } = useSelector((state: RootState) => state.commitments);

  const commitments = commitmentList.map((id) => commitmentsById[id]);

  const {
    userList,
    usersById,
    error: userError,
    isLoading: userIsLoading,
  } = useSelector((state: RootState) => state.users);

  //Filter for active users only
  const allUsers = userList.map((id) => usersById[id]);
  let users = allUsers.filter((item) => item.IsActive === true);

  const {
    actionTypeList,
    actionTypesById,
    isLoading: actionTypeIsLoading,
    error: actionTypeError,
  } = useSelector((state: RootState) => state.actionTypes);

  const actionTypes = actionTypeList.map((id) => actionTypesById[id]);

  const {
    clientActionTypesById,
    clientActionTypeList,
    isLoading: typeIsLoading,
    error: typeError,
  } = useSelector((state: RootState) => state.clientActionTypes);

  const clientActionTypes = clientActionTypeList.map(
    (id) => clientActionTypesById[id]
  );

  const {
    contactList,
    contactsById,
    isLoading: contactIsLoading,
    error: contactError,
  } = useSelector((state: RootState) => state.contacts);

  const contacts = contactList.map((id) => contactsById[id]);

  let itemData: any;

  if (action) {
    itemData = action;
  } else
    itemData = {
      ActionDescription: "",
      ActionDetails: "",
      ActionTypeID: -1,
      CommitmentID: parentID,
      ActionStatusID: -1,
      CompletionDate: null,
      RequiredDate: null,
      PersonResponsible: currentProfile.UserAccountID,
      PersonResponsibleName:
        currentProfile.FirstName + " " + currentProfile.Surname,
      OverallResponsibility: "",
      ContactID: null,
      IsRecurring: false,
    };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchActionTypes(accessToken));
        dispatch(fetchClientActionTypes(accessToken));
        dispatch(fetchActionStatuss(accessToken));
        //dispatch(fetchContacts(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        if (values.IsRecurring) {
          // process actions up to the selected end date

          // check required fields
          if (selectedFrequency === "" || continueUntil === null) {
            dispatch(
              openSnackBar(
                t("objCom:objects.commitmentaction.frequencyenddate"),
                "error"
              )
            );
          } else {
            /*  // correct date to avoid weekends
             const dow = getDay(currentDate);
             switch (dow) {
               case 0: // Sunday
                 currentDate.setDate(currentDate.getDate() - 2);
                 break;

               case 6: //Saturday
                 currentDate.setDate(currentDate.getDate() - 1);
                 break;

               default:
                 break;
             } */
            values.EndDate = continueUntil;
            values.Frequency = selectedFrequency;
            dispatch(addCommitmentAction(accessToken, values, false));
            dispatch(push(`/commitments/commitments/${values.CommitmentID}`));

            setDidSaveInventory(true);
            closeAction();
          }
        } else {
          // add only a single action
          dispatch(addCommitmentAction(accessToken, values, false));
          setDidSaveInventory(true);
          closeAction();
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  if (action) {
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(
            updCommitmentAction(accessToken, values.GrievanceID, values, false)
          );
          closeAction();
        } catch (e) {
          console.error(e);
        }
      })();
    };
  }

  const validationSchema = yup.object({
    ActionDescription: yup.string().required(
      t("strGen:validation.required", {
        fieldname: t("objCom:objects.commitmentaction.name"),
      })
    ),
    ActionTypeID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objCom:objects.commitmentaction.fields.actiontype"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objCom:objects.commitmentaction.fields.actiontype"),
        })
      ),
    ActionStatusID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objCom:objects.commitment.fields.status"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objCom:objects.commitment.fields.status"),
        })
      ),
    PersonResponsible: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objCom:objects.commitment.fields.personresponsible"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objCom:objects.commitment.fields.personresponsible"),
        })
      ),
    OverallResponsibility: yup.string().nullable(),
    RequiredDate: yup
      .date()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:platformwide.fields.date"),
        })
      )
      .typeError(t("strGen:validation.date.improperformat")),
    CompletionDate: yup
      .date()
      .when("ActionStatusID", {
        is: 4,
        then: (validationSchema) =>
          validationSchema.required(
            t("strGen:validation.requiredifclosed", {
              fieldname: t("objPlt:platformwide.fields.datecompleted"),
              fieldname2: t("objCom:objects.commitmentaction.fields.status"),
            })
          ),
      })
      .max(
        new Date(),
        t("strGen:validation.date.nofuturedate", {
          fieldname: t("objPlt:platformwide.fields.datecompleted"),
        })
      )
      .nullable()
      .typeError(t("strGen:validation.date.improperformat")),
    ContactID: yup
      .number()
      .nullable()
      .positive(
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:objects.contact.name"),
        })
      ),
  });

  const formik = useFormik({
    initialValues: itemData,
    validationSchema: validationSchema,
    onSubmit: onSub,
  });

  const projectID =
    commitments.find((item) => {
      return item.CommitmentID === formik.values.CommitmentID;
    })?.ProjectID || -1;

  let detailForm = clientIsLoading ? (
    <LoadingIndicator />
  ) : (
    <form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <IppTextField
            id="ActionDescription"
            label={t("objCom:objects.commitmentaction.name")}
            required={true}
            value={formik.values.ActionDescription}
            onChangeFunction={formik.handleChange}
            errorsExpression={formik.errors.ActionDescription}
            touchedExpression={formik.touched.ActionDescription}
            isEditing={true}
            setIsEditing={null}
          />
        </Grid>
        <Grid item xs={6}>
          <IppAutocomplete
            id="ActionTypeID"
            required={true}
            options={clientActionTypes}
            value={actionTypes.find((obj) => {
              return obj.ActionTypeID === formik.values.ActionTypeID;
            })}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                formik.setFieldValue("ActionTypeID", newValue.ActionTypeID);
              } else {
                formik.setFieldValue("ActionTypeID", -1);
              }
            }}
            label={t("objCom:objects.commitmentaction.fields.actiontype")}
            isEditing={true}
            setIsEditing={null}
            optionLabelFunction={(option: any) => option.ActionTypeName}
            errorFunction={formik.errors.ActionTypeID}
            touchedFunction={formik.touched.ActionTypeID}
            textValueFunction={formik.values.ActionTypeID}
          />
        </Grid>
        <Grid item xs={6}>
          <IppAutocomplete
            id="ActionStatusID"
            required={true}
            options={actionStatuses}
            value={actionStatuses.find((obj) => {
              return obj.ActionStatusID === formik.values.ActionStatusID;
            })}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                formik.setFieldValue("ActionStatusID", newValue.ActionStatusID);
              } else {
                formik.setFieldValue("ActionStatusID", -1);
              }
            }}
            label={t("objCom:objects.commitment.fields.status")}
            isEditing={true}
            setIsEditing={null}
            optionLabelFunction={(option: any) => option.ActionStatusName}
            errorFunction={formik.errors.ActionStatusID}
            touchedFunction={formik.touched.ActionStatusID}
            textValueFunction={formik.values.ActionStatusID}
          />
        </Grid>
        <Grid item xs={6}>
          <IppDatePicker
            id="RequiredDate"
            label={t("objPlt:platformwide.fields.daterequired")}
            required={true}
            value={ConvertDateOffset(formik.values.RequiredDate)}
            onChangeFunction={(newValue: any) => {
              formik.setFieldValue("RequiredDate", GetJSDate(newValue), true);
              formik.setFieldTouched("RequiredDate", true, false);
            }}
            errorsExpression={formik.errors.RequiredDate}
            touchedExpression={formik.touched.RequiredDate}
            isEditing={true}
            setIsEditing={null}
          />
        </Grid>
        <Grid item xs={6}>
          <IppDatePicker
            id="CompletionDate"
            label={t("objPlt:platformwide.fields.datecompleted")}
            required={formik.values.ActionStatusID === 4 ? true : false}
            value={ConvertDateOffset(formik.values.CompletionDate)}
            onChangeFunction={(newValue: any) => {
              formik.setFieldValue("CompletionDate", GetJSDate(newValue), true);
              formik.setFieldTouched("CompletionDate", true, false);
            }}
            errorsExpression={formik.errors.CompletionDate}
            touchedExpression={formik.touched.CompletionDate}
            isEditing={true}
            setIsEditing={null}
          />
        </Grid>
        <Grid item xs={6}>
          <IppPersonResponsible
            id="PersonResponsible"
            value={allUsers.find((obj) => {
              return obj.UserAccountID === formik.values.PersonResponsible;
            })}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                formik.setFieldValue(
                  "PersonResponsible",
                  newValue.UserAccountID
                );
              } else {
                formik.setFieldValue("PersonResponsible", null);
              }
            }}
            label={
              t("objCom:objects.commitment.fields.personresponsible") + "*"
            }
            isEditing={true}
            setIsEditing={null}
            optionLabelFunction={(option: any) =>
              option.FirstName + " " + option.Surname
            }
            errorFunction={formik.errors.PersonResponsible}
            touchedFunction={formik.touched.PersonResponsible}
            textValueFunction={formik.values.PersonResponsibleName}
            projectID={projectID}
          />
        </Grid>
        <Grid item xs={6}>
          <IppAutocomplete
            id="OverallResponsibility"
            label={t("objCom:objects.commitment.fields.overallresponsibility")}
            options={OverallResponsibilities}
            value={formik.values.OverallResponsibility}
            textValueFunction={formik.values.OverallResponsibility}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                if (
                  newValue ===
                  t("strGen:datalists.overallresponsibility.corporate")
                ) {
                  formik.setFieldValue("ContactID", null);
                }
                formik.setFieldValue("OverallResponsibility", newValue);
              } else {
                formik.setFieldValue("OverallResponsibility", "");
                formik.setFieldValue("ContactID", null);
              }
            }}
            touchedFunction={formik.touched.OverallResponsibility}
            errorFunction={formik.errors.OverallResponsibility}
            isEditing={true}
            setIsEditing={null}
          />
        </Grid>
        {ft_all_ExternalPersonRes && (
          <Grid item xs={6}>
            <IppAutocomplete
              id="ContactID"
              label={t(
                "objCom:objects.commitment.fields.additionalpersonresponsible"
              )}
              options={contacts}
              renderOption={(props: any, option: Contact) =>
                PersonRenderOption(
                  props,
                  option,
                  [
                    option.Email,
                    option.FirstGroupName,
                    option.PrimaryPhone,
                    option.ContactTitle,
                  ],
                  `* ${t("objPlt:objects.contact.missingname")} *`
                )
              }
              value={contacts.find((obj) => {
                return obj.ContactID === formik.values.ContactID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("ContactID", newValue.ContactID);
                } else {
                  formik.setFieldValue("ContactID", null);
                }
              }}
              isEditing={true}
              setIsEditing={null}
              optionLabelFunction={(option: any) => option.ContactName}
              errorFunction={formik.errors.ContactID}
              touchedFunction={formik.touched.ContactID}
              textValueFunction={formik.values.ContactName}
              toolTip="An external contact who shares responsibility for this record but does have access to the platform"
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <IppCheckbox
            id="IsRecurring"
            label={t(
              "objCom:objects.commitmentaction.recurringaction.isthisarecurringaction"
            )}
            value={formik.values.IsRecurring}
            onChangeFunction={formik.handleChange}
            isEditing={true}
          />
        </Grid>
        {formik.values.IsRecurring && (
          <>
            <Grid item xs={12}>
              <Typography variant="caption">
                {t(
                  "objCom:objects.commitmentaction.recurringaction.partofarecurringaction"
                )}{" "}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <IppAutocomplete
                id="RepeatFrequency"
                options={ScheduleFrequency}
                required={formik.values.IsRecurring}
                value={selectedFrequency}
                onChangeFunction={(event: ChangeEvent, newValue: any) => {
                  if (newValue) {
                    setSelectedFrequency(newValue);
                  } else {
                    setSelectedFrequency("");
                  }
                }}
                textValueFunction={formik.values.RepeatFrequency}
                touchedFunction={formik.touched.RepeatFrequency}
                errorFunction={formik.errors.RepeatFrequency}
                label={t("objCom:objects.commitmentaction.frequency")}
                isEditing={true}
                setIsEditing={null}
              />
            </Grid>
            <Grid item xs={6}>
              <IppDatePicker
                id="ContinueUntil"
                label={t("objCom:objects.commitmentaction.continueuntil")}
                value={continueUntil}
                onChangeFunction={(newValue: any) => {
                  setContinueUntil(newValue);
                }}
                // errorsExpression={formik.errors.CompletionDate}
                // touchedExpression={formik.touched.CompletionDate}
                isEditing={true}
                setIsEditing={null}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <IppRichTextEditor
            id="ActionDetails"
            label={t("objCom:objects.commitmentaction.fields.actiondetails")}
            value={formik.values.ActionDetails}
            isEditing={true}
            touchedExpression={formik.touched.ActionDetails}
            errorsExpression={formik.errors.ActionDetails}
            setFieldValue={formik.setFieldValue}
            standardStatementsRecordType="CommitmentActionDetails"
          />
        </Grid>
        <Grid item xs={12}>
          <IppFormButtons
            isEditing={true}
            setIsEditing={null}
            isAdding={!action}
            submitDisabled={canSave}
            showCancel={true}
            resetFunction={closeAction}
          />
        </Grid>
      </Grid>
    </form>
  );

  return <div id="action-child-form">{detailForm}</div>;
};
