import { CustomRnDForm } from "./CustomRnDForm";

export const CustomRnDCreatePage = () => {
  let CustomRnDCreateView = (
    <div>
      <CustomRnDForm />
    </div>
  );

  return <div id="CustomRnD-detail-page">{CustomRnDCreateView}</div>;
};
