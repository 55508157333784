import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import { fetchExpenditureCharts } from "./ExpenditureChartSlice";

import "hammerjs";
import { IppSkeleton } from "components/IppSkeleton";
import { useTypedTranslation } from "utils/customHooks";

export const ExpenditureChart = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const { expenditureChartList, expenditureChartsById, error, isLoading } =
    useSelector((state: RootState) => state.expenditureCharts);

  const expenditureData = expenditureChartList.map(
    (id) => expenditureChartsById[id]
  );

  const t = useTypedTranslation(["objPlt"]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchExpenditureCharts(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const categories = expenditureData.map((item) => {
    let newItem = item.MonthName;
    return newItem;
  });

  let expenditureChartForm = isLoading ? (
    <IppSkeleton height={300} />
  ) : (
    <div>
      <Chart style={{ height: 300 }}>
        {/* <ChartTitle text="Expenditures by Affiliation" /> */}

        <ChartLegend position="bottom" />
        <ChartCategoryAxis>
          <ChartCategoryAxisItem categories={categories} />
        </ChartCategoryAxis>

        <ChartValueAxis>
          <ChartValueAxisItem title={{ text: "Spend" }} />
        </ChartValueAxis>

        <ChartSeries>
          <ChartSeriesItem
            data={expenditureData}
            type="line"
            style="smooth"
            field="Indigenous"
            name={t("objPlt:platformwide.indigenous.name")}
            tooltip={{ visible: true, format: "{0:c0}" }}
          />
          <ChartSeriesItem
            data={expenditureData}
            type="line"
            style="smooth"
            field="NonIndigenous"
            name={`Non-${t("objPlt:platformwide.indigenous.name")}`}
            tooltip={{ visible: true, format: "{0:c0}" }}
          />
        </ChartSeries>
        <ChartLegend position="bottom" />
      </Chart>
    </div>
  );

  return <div id="work-hour-chart-form">{expenditureChartForm}</div>;
};
