import axiosClient from "api/axiosClient";

export interface EducationTraining {
  EducationTrainingID: number;
  Type: string;
  ProjectID: number;
  ProjectName?: string;
  Activity: string;
  Description: string;
  ExpenditureDate: Date;
  Expenditure: number;
  Provider: string;
  Location: string;
  CreatedBy: number;
  LastUpdatedBy: number;
  CompanyID: number;
  CompanyName?: string;
}

export interface EducationTrainingsResult {
  educationTraining: EducationTraining[];
}

const BASE_URL = "/educationTraining";

export const getEducationTrainings = async (
  accessToken: String
): Promise<EducationTrainingsResult> => {
  const url = `${BASE_URL}`;
  try {
    const { data } = await axiosClient.get<EducationTrainingsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createEducationTraining = async (
  accessToken: String,
  educationTraining: Partial<EducationTraining>
): Promise<EducationTraining> => {
  const url = BASE_URL;

  try {
    const { data } = await axiosClient.post<EducationTraining>(
      url,
      educationTraining,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateEducationTraining = async (
  accessToken: String,
  educationTraining: EducationTraining
): Promise<EducationTraining> => {
  const url = `${BASE_URL}/${educationTraining.EducationTrainingID}`;

  try {
    const { data } = await axiosClient.post<EducationTraining>(
      url,
      educationTraining,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteEducationTraining = async (
  accessToken: String,
  educationTrainingID: number
): Promise<void> => {
  const url = `${BASE_URL}/${educationTrainingID}`;

  try {
    await axiosClient.delete(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};
