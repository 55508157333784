import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { IppSkeleton } from "components/IppSkeleton";
import { ArrowBack, ArrowForward, Lock } from "@mui/icons-material";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { push } from "redux-first-history";
import {
  addClientCommitmentCategory,
  delClientCommitmentCategory,
  fetchClientCommitmentCategories,
} from "./ClientCommitmentCategorySlice";
import { fetchCommitmentCategories } from "features/commitments/commitmentCategory/CommitmentCategorySlice";
import { IppFormDivider } from "components/IppFormDivider";
import { IppSaveButton } from "components/Buttons/IppSaveButton";

const PREFIX = "ClientCategoryForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
  roleCard: `${PREFIX}-roleCard`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1100,
  },

  [`& .${classes.boxSpace}`]: {
    padding: 10,
  },

  [`& .${classes.roleCard}`]: {
    minWidth: 250,

    minHeight: 300,
  },
}));

// format for display lists
interface listValue {
  itemID: number;
  itemLabel: string;
  wasSelected: boolean; // to track deletions
  isRequired: boolean;
}

export const ClientCommitmentCategoryForm = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [pageLoaded, setPageLoaded] = useState(false);

  const [allGroups, setAllGroups] = useState<listValue[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<listValue[]>([]);

  const { clientId } = useSelector((state: RootState) => state.client);

  const {
    clientCommitmentCategoryList,
    clientCommitmentCategoriesById,
    isLoading: clientCommitmentCategoriesIsLoading,
    error: clientCommitmentCategoriesError,
  } = useSelector((state: RootState) => state.clientCommitmentCategories);

  const clientCommitmentCategories = clientCommitmentCategoryList.map(
    (id: any) => clientCommitmentCategoriesById[id]
  );

  const {
    commitmentCategoryList,
    commitmentCategoriesById,
    isLoading: commitmentCategoriesIsLoading,
    error: commitmentCategoriesError,
  } = useSelector((state: RootState) => state.commitmentCategories);

  const commitmentCategories = commitmentCategoryList.map(
    (id: any) => commitmentCategoriesById[id]
  );

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchClientCommitmentCategories(accessToken));
        dispatch(fetchCommitmentCategories(accessToken));
        setPageLoaded(true);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  // make sure lists are initialized only one time
  useEffect(() => {
    if (
      pageLoaded &&
      !commitmentCategoriesIsLoading &&
      !clientCommitmentCategoriesIsLoading &&
      allGroups.length === 0
    ) {
      initializeLists();
    }
  }, [
    pageLoaded,
    commitmentCategoriesIsLoading,
    clientCommitmentCategoriesIsLoading,
  ]);

  const initializeLists = () => {
    // initialize full list
    let data1: listValue[] = [];
    commitmentCategories.forEach((item) => {
      // check if item is already selected
      const foundItem = clientCommitmentCategories.find((obj: any) => {
        return obj.CommitmentCategoryID === item.CommitmentCategoryID;
      });
      if (!foundItem) {
        let n: listValue = {
          itemID: item.CommitmentCategoryID,
          itemLabel: item.CommitmentCategoryName,
          wasSelected: false,
          isRequired: false,
        };
        data1.push(n);
      }
    });
    setAllGroups(data1);

    let data2 = clientCommitmentCategories.map((item: any) => {
      let n: listValue = {
        itemID: item.CommitmentCategoryID,
        itemLabel: item.CommitmentCategoryName,
        wasSelected: true,
        isRequired: false,
      };
      return n;
    });
    setSelectedGroups(data2);
  };

  const handleSave = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
      },
    });
    // Delete items marked for removal
    // if item was selected and is now in all groups,
    // then item was REMOVED
    allGroups.forEach((item) => {
      if (item.wasSelected) {
        dispatch(delClientCommitmentCategory(accessToken, item.itemID));
      }
    });

    // insert new items
    selectedGroups.forEach((item) => {
      if (!item.wasSelected) {
        const newItem = {
          ClientID: clientId,
          CommitmentCategoryID: item.itemID,
        };
        dispatch(addClientCommitmentCategory(accessToken, newItem));
      }
    });
    dispatch(openSnackBar("Updates completed", "success"));
    dispatch(push("/admin/clientCommitmentCategories"));
  };

  const handleToggleRight = (value: listValue) => () => {
    // add item to full list, and sort it
    setAllGroups((oldArray) =>
      allGroups.concat(value).sort((a, b) => {
        let fa = a.itemLabel.toLowerCase(),
          fb = b.itemLabel.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      })
    );

    // remove item from selected list
    setSelectedGroups(
      selectedGroups.filter(function (a) {
        return a !== value;
      })
    );
  };

  const handleToggleLeft = (value: listValue) => () => {
    // add item to selected list, and sort it
    setSelectedGroups((oldArray) =>
      selectedGroups.concat(value).sort((a, b) => {
        let fa = a.itemLabel.toLowerCase(),
          fb = b.itemLabel.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      })
    );

    // remove item from full list list
    setAllGroups(
      allGroups.filter(function (a) {
        return a !== value;
      })
    );
  };

  let fullList = commitmentCategoriesIsLoading ? (
    <IppSkeleton height={400} />
  ) : (
    <Paper sx={{ height: 400, overflow: "auto" }}>
      <List dense component="div" role="list">
        {allGroups.map((item) => {
          return (
            <Tooltip title="Click to add item to selected groups">
              <ListItem
                button
                key={item.itemID}
                onClick={handleToggleLeft(item)}
                role="listitem"
              >
                <ListItemIcon>
                  <ArrowForward />
                </ListItemIcon>
                <ListItemText primary={item.itemLabel} />
              </ListItem>
            </Tooltip>
          );
        })}
      </List>
    </Paper>
  );

  let selectedList = clientCommitmentCategoriesIsLoading ? (
    <IppSkeleton height={400} />
  ) : (
    <Paper sx={{ height: 400, overflow: "auto" }}>
      <List dense component="div" role="list">
        {selectedGroups.map((item) => {
          return item.isRequired ? (
            <Tooltip title="This is a required item and cannot be removed">
              <ListItem button role="listitem" key={item.itemID}>
                <ListItemIcon>
                  <Lock />
                </ListItemIcon>
                <ListItemText primary={item.itemLabel} />
              </ListItem>
            </Tooltip>
          ) : (
            <Tooltip title="Click to remove item from selected list">
              <ListItem
                button
                role="listitem"
                key={item.itemID}
                onClick={handleToggleRight(item)}
              >
                <ListItemIcon>
                  <ArrowBack />
                </ListItemIcon>
                <ListItemText primary={item.itemLabel} />
              </ListItem>
            </Tooltip>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <Root>
      <Box display="flex" justifyContent="center">
        <Paper className={classes.boxSpace}>
          <Grid container className={classes.editForm} spacing={2}>
            <Grid item xs={9}>
              <Typography variant="h5" component="h1">
                Commitment Categories
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Grid container justifyContent="flex-end">
                <IppSaveButton handleSave={handleSave} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <IppFormDivider title="Guide" />
              <Typography display="inline">
                This option allows you to select the categories you wish to have
                appear in dropdown lists. Only the items you select will be
                available to users. Selecting a category from one list will move
                it into the other list. Once you have completed your changes,
                click
                <strong> Save Changes</strong> to finalize them.
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h6">Available Categories</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h6">Selected Categories</Typography>
            </Grid>
            <Grid item xs={6}>
              {fullList}
            </Grid>
            <Grid item xs={6}>
              {selectedList}
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Root>
  );
};
