import { useTypedTranslation } from "utils/customHooks";
import { convertToAddressArray } from "utils/functions";
import * as yup from "yup";

export const ValidationSchema = () => {
  const t = useTypedTranslation(["objPlt", "objBen", "strGen"]);

  return yup.object({
    BusinessName: yup
      .string()
      .max(250, "Character limit is 250")
      .required("Business Name is required."),
    Address: yup
      .string()
      .max(250, "Character limit is 250")
      .required("Address is required."),
    TownCity: yup
      .string()
      .max(250, "Character limit is 250")
      .required("City/Town is required."),
    ProvinceState: yup
      .string()
      .max(50, "Character limit is 50")
      .required("Province is required."),
    CountryName: yup
      .string()
      .max(50, "Character limit is 50")
      .required("Country is required."),
    PostalCode: yup
      .string()
      .max(100, "Character limit is 100")
      .required("Postal Code is required."),
    Website: yup.string().nullable(),
    FirstName: yup
      .string()
      .max(100, "Character limit is 100")
      .required("First Name is required."),
    LastName: yup
      .string()
      .max(100, "Character limit is 100")
      .required("Last Name is required."),
    Title: yup.string().nullable(),
    Email: yup
      .string()
      .max(100, "Character limit is 100")
      .email("This is not a valid email format")
      .required("Email address is required."),
    Phone: yup
      .string()
      .typeError("Phone number is required.")
      .required("Phone number is required."),
    CorpNumber: yup
      .string()
      .max(50, "Character limit is 50")
      .typeError("Corporation number is required.")
      .required("Corporation number is required."),
    RegisteredProvinces: yup
      .string()
      .required("At least 1 address is required")
      .test(
        "has-valid-address",
        "At least 1 address is required",
        (value: any) => {
          const addresses = convertToAddressArray(value);
          return (
            addresses.length > 0 &&
            addresses.some((address) => address.province && address.district)
          );
        }
      )
      .test(
        "complete-address",
        "Every address must have both a province and a district",
        (value: any) => {
          const addresses = convertToAddressArray(value);
          return addresses.every(
            (address) => address.province && address.district
          );
        }
      ),
    Insurance: yup
      .number()
      .typeError("Insurance liability must be a number.")
      .required("Insurance liability limit is required."),
    CoreBusiness: yup.lazy((val: any) =>
      Array.isArray(val)
        ? yup
            .array()
            .of(yup.string())
            .min(1, "Please select at least one core business")
            .required("Please select at least one core business")
        : yup.string().required("Please select a value")
    ),
    /* ---- This is the prefered way but "is" has an error -----
      .mixed()
      .oneOf([yup.array().of(yup.string()), yup.string()])
      .when("CoreBusiness", {
        is: (value: any) => Array.isArray(value) === true,
        then: yup
          .array()
          .of(yup.string())
          .min(1, "Please select at least one core business")
          .required("Please select at least one core business"),
        otherwise: yup.string().required("Please select a value"),
      }), */
    IsoCertified: yup.boolean().required("This field is required."),
    IsoNumber: yup
      .string()
      .max(50, "Character limit is 50")
      .nullable()
      .when("IsoCertified", {
        is: true,
        then: (value) => value.required("ISO number is required."),
        otherwise: (value) =>
          value
            .nullable()
            .test(
              "is-empty",
              "ISO number must be empty if you are not ISO-Net certified.",
              (value) => !value
            ),
      }),
    CorCertified: yup.boolean().required("This field is required."),
    CertDate: yup
      .date()
      .when("CorCertified", {
        is: true,
        then: (value) => value.required("Certification Date is required."),
        otherwise: (value) =>
          value
            .nullable()
            .test(
              "is-empty",
              "Certification Date must be empty if you are not COR® certified.",
              (value) => !value
            ),
      })
      .typeError("Certification Date must be a Valid Date")
      .nullable(),
    HSECertified: yup.boolean().required("This field is required."),
    IndigenousOwned: yup.boolean().required("This field is required."),
    IndigenousCommunity: yup
      .string()
      .nullable()
      .max(250, "Character limit is 250")
      .when("IndigenousOwned", {
        is: true,
        then: (value) =>
          value.required("An Indigenous entity/community is required."),
        otherwise: (value) =>
          value
            .nullable()
            .test(
              "is-empty",
              "Indigenous entity/community must be empty if you are not Indigenous owned/partnered.",
              (value) => !value
            ),
      }),
    IndigenousCorpPercentage: yup
      .number()
      .max(100, "Percent value cannot be more than 100% ")
      .min(0, "Percent value cannot be less than 0%")
      .typeError("Indigenous Corp Percentage must be a number between 0 - 100.")
      .nullable(),
    InclusionPolicy: yup.boolean().required("This field is required."),
    NumberEmployees: yup.number().nullable().typeError("Invalid number."),
    IndigenousEmployeePercentage: yup
      .number()
      .max(100, "Percent value cannot be more than 100% ")
      .min(0, "Percent value cannot be less than 0%")
      .typeError("Must be a number between 0 - 100.")
      .nullable(),
    GwoTraining: yup.boolean().required("This field is required."),
    EnergyProjects: yup.boolean().required("This field is required."),
    ProjectReferences: yup.string().nullable(),
  });
};
