export const Currencies = ["CAD", "USD"];

export const clientCurrency = [ 
    {
        Currency: "CAD",
        Name: "Canadian Dollar",
        Symbol: "$"
    },

]
export const currencies =  [
    {
        Currency: "AED",
        Name: "UAE Dirham"
    },
    {
        Currency: "AFN",
        Name: "Afghani",
        Symbol: "؋"
    },
    {
        Currency: "ALL",
        Name: "Lek",
        Symbol: "Lek"
    },
    {
        Currency: "AMD",
        Name: "Armenian Dram"
    },
    {
        Currency: "ANG",
        Name: "Netherlands Antillian Guilder",
        Symbol: "ƒ"
    },
    {
        Currency: "AOA",
        Name: "Kwanza"
    },
    {
        Currency: "ARS",
        Name: "Argentine Peso",
        Symbol: "$"
    },
    {
        Currency: "AUD",
        Name: "Australian Dollar",
        Symbol: "$"
    },
    {
        Currency: "AWG",
        Name: "Aruban Guilder",
        Symbol: "ƒ"
    },
    {
        Currency: "AZN",
        Name: "Azerbaijanian Manat",
        Symbol: "ман"
    },
    {
        Currency: "BAM",
        Name: "Convertible Marks",
        Symbol: "KM"
    },
    {
        Currency: "BBD",
        Name: "Barbados Dollar",
        Symbol: "$"
    },
    {
        Currency: "BDT",
        Name: "Taka"
    },
    {
        Currency: "BGN",
        Name: "Bulgarian Lev",
        Symbol: "лв"
    },
    {
        Currency: "BHD",
        Name: "Bahraini Dinar"
    },
    {
        Currency: "BIF",
        Name: "Burundi Franc"
    },
    {
        Currency: "BMD",
        Name: "Bermudian Dollar (customarily known as Bermuda Dollar)",
        Symbol: "$"
    },
    {
        Currency: "BND",
        Name: "Brunei Dollar",
        Symbol: "$"
    },
    {
        Currency: "BOB BOV",
        Name: "Boliviano Mvdol",
        Symbol: "$b"
    },
    {
        Currency: "BRL",
        Name: "Brazilian Real",
        Symbol: "R$"
    },
    {
        Currency: "BSD",
        Name: "Bahamian Dollar",
        Symbol: "$"
    },
    {
        Currency: "BWP",
        Name: "Pula",
        Symbol: "P"
    },
    {
        Currency: "BYR",
        Name: "Belarussian Ruble",
        Symbol: "p."
    },
    {
        Currency: "BZD",
        Name: "Belize Dollar",
        Symbol: "BZ$"
    },
    {
        Currency: "CAD",
        Name: "Canadian Dollar",
        Symbol: "$"
    },
    {
        Currency: "CDF",
        Name: "Congolese Franc"
    },
    {
        Currency: "CHF",
        Name: "Swiss Franc",
        Symbol: "CHF"
    },
    {
        Currency: "CLP CLF",
        Name: "Chilean Peso Unidades de fomento",
        Symbol: "$"
    },
    {
        Currency: "CNY",
        Name: "Yuan Renminbi",
        Symbol: "¥"
    },
    {
        Currency: "COP COU",
        Name: "Colombian Peso Unidad de Valor Real",
        Symbol: "$"
    },
    {
        Currency: "CRC",
        Name: "Costa Rican Colon",
        Symbol: "₡"
    },
    {
        Currency: "CUP CUC",
        Name: "Cuban Peso Peso Convertible",
        Symbol: "₱"
    },
    {
        Currency: "CVE",
        Name: "Cape Verde Escudo"
    },
    {
        Currency: "CZK",
        Name: "Czech Koruna",
        Symbol: "Kč"
    },
    {
        Currency: "DJF",
        Name: "Djibouti Franc"
    },
    {
        Currency: "DKK",
        Name: "Danish Krone",
        Symbol: "kr"
    },
    {
        Currency: "DOP",
        Name: "Dominican Peso",
        Symbol: "RD$"
    },
    {
        Currency: "DZD",
        Name: "Algerian Dinar"
    },
    {
        Currency: "EEK",
        Name: "Kroon"
    },
    {
        Currency: "EGP",
        Name: "Egyptian Pound",
        Symbol: "£"
    },
    {
        Currency: "ERN",
        Name: "Nakfa"
    },
    {
        Currency: "ETB",
        Name: "Ethiopian Birr"
    },
    {
        Currency: "EUR",
        Name: "Euro",
        Symbol: "€"
    },
    {
        Currency: "FJD",
        Name: "Fiji Dollar",
        Symbol: "$"
    },
    {
        Currency: "FKP",
        Name: "Falkland Islands Pound",
        Symbol: "£"
    },
    {
        Currency: "GBP",
        Name: "Pound Sterling",
        Symbol: "£"
    },
    {
        Currency: "GEL",
        Name: "Lari"
    },
    {
        Currency: "GHS",
        Name: "Cedi"
    },
    {
        Currency: "GIP",
        Name: "Gibraltar Pound",
        Symbol: "£"
    },
    {
        Currency: "GMD",
        Name: "Dalasi"
    },
    {
        Currency: "GNF",
        Name: "Guinea Franc"
    },
    {
        Currency: "GTQ",
        Name: "Quetzal",
        Symbol: "Q"
    },
    {
        Currency: "GYD",
        Name: "Guyana Dollar",
        Symbol: "$"
    },
    {
        Currency: "HKD",
        Name: "Hong Kong Dollar",
        Symbol: "$"
    },
    {
        Currency: "HNL",
        Name: "Lempira",
        Symbol: "L"
    },
    {
        Currency: "HRK",
        Name: "Croatian Kuna",
        Symbol: "kn"
    },
    {
        Currency: "HTG USD",
        Name: "Gourde US Dollar"
    },
    {
        Currency: "HUF",
        Name: "Forint",
        Symbol: "Ft"
    },
    {
        Currency: "IDR",
        Name: "Rupiah",
        Symbol: "Rp"
    },
    {
        Currency: "ILS",
        Name: "New Israeli Sheqel",
        Symbol: "₪"
    },
    {
        Currency: "INR",
        Name: "Indian Rupee"
    },
    {
        Currency: "INR BTN",
        Name: "Indian Rupee Ngultrum"
    },
    {
        Currency: "IQD",
        Name: "Iraqi Dinar"
    },
    {
        Currency: "IRR",
        Name: "Iranian Rial",
        Symbol: "﷼"
    },
    {
        Currency: "ISK",
        Name: "Iceland Krona",
        Symbol: "kr"
    },
    {
        Currency: "JMD",
        Name: "Jamaican Dollar",
        Symbol: "J$"
    },
    {
        Currency: "JOD",
        Name: "Jordanian Dinar"
    },
    {
        Currency: "JPY",
        Name: "Yen",
        Symbol: "¥"
    },
    {
        Currency: "KES",
        Name: "Kenyan Shilling"
    },
    {
        Currency: "KGS",
        Name: "Som",
        Symbol: "лв"
    },
    {
        Currency: "KHR",
        Name: "Riel",
        Symbol: "៛"
    },
    {
        Currency: "KMF",
        Name: "Comoro Franc"
    },
    {
        Currency: "KPW",
        Name: "North Korean Won",
        Symbol: "₩"
    },
    {
        Currency: "KRW",
        Name: "Won",
        Symbol: "₩"
    },
    {
        Currency: "KWD",
        Name: "Kuwaiti Dinar"
    },
    {
        Currency: "KYD",
        Name: "Cayman Islands Dollar",
        Symbol: "$"
    },
    {
        Currency: "KZT",
        Name: "Tenge",
        Symbol: "лв"
    },
    {
        Currency: "LAK",
        Name: "Kip",
        Symbol: "₭"
    },
    {
        Currency: "LBP",
        Name: "Lebanese Pound",
        Symbol: "£"
    },
    {
        Currency: "LKR",
        Name: "Sri Lanka Rupee",
        Symbol: "₨"
    },
    {
        Currency: "LRD",
        Name: "Liberian Dollar",
        Symbol: "$"
    },
    {
        Currency: "LTL",
        Name: "Lithuanian Litas",
        Symbol: "Lt"
    },
    {
        Currency: "LVL",
        Name: "Latvian Lats",
        Symbol: "Ls"
    },
    {
        Currency: "LYD",
        Name: "Libyan Dinar"
    },
    {
        Currency: "MAD",
        Name: "Moroccan Dirham"
    },
    {
        Currency: "MDL",
        Name: "Moldovan Leu"
    },
    {
        Currency: "MGA",
        Name: "Malagasy Ariary"
    },
    {
        Currency: "MKD",
        Name: "Denar",
        Symbol: "ден"
    },
    {
        Currency: "MMK",
        Name: "Kyat"
    },
    {
        Currency: "MNT",
        Name: "Tugrik",
        Symbol: "₮"
    },
    {
        Currency: "MOP",
        Name: "Pataca"
    },
    {
        Currency: "MRO",
        Name: "Ouguiya"
    },
    {
        Currency: "MUR",
        Name: "Mauritius Rupee",
        Symbol: "₨"
    },
    {
        Currency: "MVR",
        Name: "Rufiyaa"
    },
    {
        Currency: "MWK",
        Name: "Kwacha"
    },
    {
        Currency: "MXN MXV",
        Name: "Mexican Peso Mexican Unidad de Inversion (UDI)",
        Symbol: "$"
    },
    {
        Currency: "MYR",
        Name: "Malaysian Ringgit",
        Symbol: "RM"
    },
    {
        Currency: "MZN",
        Name: "Metical",
        Symbol: "MT"
    },
    {
        Currency: "NGN",
        Name: "Naira",
        Symbol: "₦"
    },
    {
        Currency: "NIO",
        Name: "Cordoba Oro",
        Symbol: "C$"
    },
    {
        Currency: "NOK",
        Name: "Norwegian Krone",
        Symbol: "kr"
    },
    {
        Currency: "NPR",
        Name: "Nepalese Rupee",
        Symbol: "₨"
    },
    {
        Currency: "NZD",
        Name: "New Zealand Dollar",
        Symbol: "$"
    },
    {
        Currency: "OMR",
        Name: "Rial Omani",
        Symbol: "﷼"
    },
    {
        Currency: "PAB USD",
        Name: "Balboa US Dollar",
        Symbol: "B/."
    },
    {
        Currency: "PEN",
        Name: "Nuevo Sol",
        Symbol: "S/."
    },
    {
        Currency: "PGK",
        Name: "Kina"
    },
    {
        Currency: "PHP",
        Name: "Philippine Peso",
        Symbol: "Php"
    },
    {
        Currency: "PKR",
        Name: "Pakistan Rupee",
        Symbol: "₨"
    },
    {
        Currency: "PLN",
        Name: "Zloty",
        Symbol: "zł"
    },
    {
        Currency: "PYG",
        Name: "Guarani",
        Symbol: "Gs"
    },
    {
        Currency: "QAR",
        Name: "Qatari Rial",
        Symbol: "﷼"
    },
    {
        Currency: "RON",
        Name: "New Leu",
        Symbol: "lei"
    },
    {
        Currency: "RSD",
        Name: "Serbian Dinar",
        Symbol: "Дин."
    },
    {
        Currency: "RUB",
        Name: "Russian Ruble",
        Symbol: "руб"
    },
    {
        Currency: "RWF",
        Name: "Rwanda Franc"
    },
    {
        Currency: "SAR",
        Name: "Saudi Riyal",
        Symbol: "﷼"
    },
    {
        Currency: "SBD",
        Name: "Solomon Islands Dollar",
        Symbol: "$"
    },
    {
        Currency: "SCR",
        Name: "Seychelles Rupee",
        Symbol: "₨"
    },
    {
        Currency: "SDG",
        Name: "Sudanese Pound"
    },
    {
        Currency: "SEK",
        Name: "Swedish Krona",
        Symbol: "kr"
    },
    {
        Currency: "SGD",
        Name: "Singapore Dollar",
        Symbol: "$"
    },
    {
        Currency: "SHP",
        Name: "Saint Helena Pound",
        Symbol: "£"
    },
    {
        Currency: "SLL",
        Name: "Leone"
    },
    {
        Currency: "SOS",
        Name: "Somali Shilling",
        Symbol: "S"
    },
    {
        Currency: "SRD",
        Name: "Surinam Dollar",
        Symbol: "$"
    },
    {
        Currency: "STD",
        Name: "Dobra"
    },
    {
        Currency: "SVC USD",
        Name: "El Salvador Colon US Dollar",
        Symbol: "$"
    },
    {
        Currency: "SYP",
        Name: "Syrian Pound",
        Symbol: "£"
    },
    {
        Currency: "SZL",
        Name: "Lilangeni"
    },
    {
        Currency: "THB",
        Name: "Baht",
        Symbol: "฿"
    },
    {
        Currency: "TJS",
        Name: "Somoni"
    },
    {
        Currency: "TMT",
        Name: "Manat"
    },
    {
        Currency: "TND",
        Name: "Tunisian Dinar"
    },
    {
        Currency: "TOP",
        Name: "Pa'anga"
    },
    {
        Currency: "TRY",
        Name: "Turkish Lira",
        Symbol: "TL"
    },
    {
        Currency: "TTD",
        Name: "Trinidad and Tobago Dollar",
        Symbol: "TT$"
    },
    {
        Currency: "TWD",
        Name: "New Taiwan Dollar",
        Symbol: "NT$"
    },
    {
        Currency: "TZS",
        Name: "Tanzanian Shilling"
    },
    {
        Currency: "UAH",
        Name: "Hryvnia",
        Symbol: "₴"
    },
    {
        Currency: "UGX",
        Name: "Uganda Shilling"
    },
    {
        Currency: "USD",
        Name: "US Dollar",
        Symbol: "$"
    },
    {
        Currency: "UYU UYI",
        Name: "Peso Uruguayo Uruguay Peso en Unidades Indexadas",
        Symbol: "$U"
    },
    {
        Currency: "UZS",
        Name: "Uzbekistan Sum",
        Symbol: "лв"
    },
    {
        Currency: "VEF",
        Name: "Bolivar Fuerte",
        Symbol: "Bs"
    },
    {
        Currency: "VND",
        Name: "Dong",
        Symbol: "₫"
    },
    {
        Currency: "VUV",
        Name: "Vatu"
    },
    {
        Currency: "WST",
        Name: "Tala"
    },
    {
        Currency: "XAF",
        Name: "CFA Franc BEAC"
    },
    {
        Currency: "XAG",
        Name: "Silver"
    },
    {
        Currency: "XAU",
        Name: "Gold"
    },
    {
        Currency: "XBA",
        Name: "Bond Markets Units European Composite Unit (EURCO)"
    },
    {
        Currency: "XBB",
        Name: "European Monetary Unit (E.M.U.-6)"
    },
    {
        Currency: "XBC",
        Name: "European Unit of Account 9(E.U.A.-9)"
    },
    {
        Currency: "XBD",
        Name: "European Unit of Account 17(E.U.A.-17)"
    },
    {
        Currency: "XCD",
        Name: "East Caribbean Dollar",
        Symbol: "$"
    },
    {
        Currency: "XDR",
        Name: "SDR"
    },
    {
        Currency: "XFU",
        Name: "UIC-Franc"
    },
    {
        Currency: "XOF",
        Name: "CFA Franc BCEAO"
    },
    {
        Currency: "XPD",
        Name: "Palladium"
    },
    {
        Currency: "XPF",
        Name: "CFP Franc"
    },
    {
        Currency: "XPT",
        Name: "Platinum"
    },
    {
        Currency: "XTS",
        Name: "Codes specifically reserved for testing purposes"
    },
    {
        Currency: "YER",
        Name: "Yemeni Rial",
        Symbol: "﷼"
    },
    {
        Currency: "ZAR",
        Name: "Rand",
        Symbol: "R"
    },
    {
        Currency: "ZAR LSL",
        Name: "Rand Loti"
    },
    {
        Currency: "ZAR NAD",
        Name: "Rand Namibia Dollar"
    },
    {
        Currency: "ZMK",
        Name: "Zambian Kwacha"
    },
    {
        Currency: "ZWL",
        Name: "Zimbabwe Dollar"
    }
]

export default Currencies;
