import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { IppInventory } from "components/Inventory/IppInventory";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";
import { fetchInteractionsForReview } from "./InteractionSlice";
import { ConvertDateOffset } from "../../../utils/DateFunctions";
import { useTranslation } from "react-i18next";

export const InteractionReviewInventoryPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation(["objPlt", "strGen", "objCom", "objStk"]);

  const {
    interactionList,
    interactionsById,
    isLoading: interactionIsLoading,
    error: interactionError,
  } = useSelector((state: RootState) => state.interactions);

  const interactions = interactionList.map((id) => interactionsById[id]);

  const data = interactions.map((p) => {
    let n = Object.assign({}, p) as any;
    if (p.InteractionDate !== null) {
      n.InteractionDate = ConvertDateOffset(p.InteractionDate);
    } else {
      n.InteractionDate = null;
    }

    if (p.IsOverdue) {
      n.StatusTypeName = t("objStk:objects.interaction.statustype.overdue");
    }
    if (!p.InteractionTitle || p.InteractionTitle === " ") {
      n.InteractionTitle = t("objStk:objects.interaction.missingtitle");
    }
    return n;
  });

  const dataColumns = [
    {
      field: "CommunicationID",
      title: t("objStk:objects.interaction.sequenceid"),
      format: "C-{0:0}",
      filter: "string",
      columnWidth: 80,
    },
    {
      field: "InteractionTitle",
      title: t("objStk:objects.interaction.fields.title"),
    },
    {
      field: "InteractionTypeName",
      title: t("objStk:objects.interaction.fields.type"),
      icons: true,
      columnWidth: 60,
    },
    {
      field: "InteractionDate",
      title: t("objStk:objects.interaction.fields.date"),
      filter: "date",
      format: "{0:d}",
      columnWidth: 110,
    },
    {
      field: "ProjectName",
      title: t("objStk:objects.interaction.fields.project"),
    },
    {
      field: "PersonResponsibleName",
      title: t("objStk:objects.interaction.fields.personresponsible"),
      columnWidth: 175,
    },
    {
      field: "StatusTypeName",
      title: t("objStk:objects.interaction.fields.status"),
      chips: true,
      columnWidth: 110,
    },
    {
      field: "InitiatedBy",
      title: t("objStk:objects.interaction.fields.initiatedby"),
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchInteractionsForReview(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  if (interactionError) {
    return <ErrorPage errMsg={interactionError} />;
  }

  let interactionInvView = interactionIsLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <IppInventory
        title={t("objStk:objects.interaction.needingreview", {
          count: 2,
        })}
        tableData={data}
        idField="InteractionID"
        columns={dataColumns}
        isFiltered={true}
        showDetail={true}
        sortColumn={3}
        sortReverse={true}
        linkColumn={1}
        searchByColumn={"InteractionTitle"}
        searchByPlaceholder={t("strGen:filters.searchobj", {
          objectname: t("objStk:objects.interaction.fields.title", {
            count: 2,
          }),
        })}
      />
    </div>
  );

  return <div id="interaction-review-inventory-page">{interactionInvView}</div>;
};
