import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ClientCountry,
  ClientCountriesResult,
  getClientCountries,
  createClientCountry,
  updateClientCountry,
  deleteClientCountry,
} from "api/clientCountryAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface ClientCountryState {
  clientCountriesById: Record<number, ClientCountry>;
  clientCountryList: number[];
  isLoading: boolean;
  error: string | null;
}

const ClientCountryInitialState: ClientCountryState = {
  clientCountriesById: {},
  clientCountryList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: ClientCountryState) {
  state.isLoading = true;
}

function loadingFailed(
  state: ClientCountryState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const clientCountries = createSlice({
  name: "clientCountries",
  initialState: ClientCountryInitialState,
  reducers: {
    getClientCountriesStart: startLoading,
    getClientCountriesSuccess(
      state,
      { payload }: PayloadAction<ClientCountriesResult>
    ) {
      const { clientCountries } = payload;
      // console.log("PAYLOAD", clientCountries);
      state.isLoading = false;
      state.error = null;

      clientCountries.forEach((clientCountry) => {
        state.clientCountriesById[clientCountry.ClientCountryID] =
          clientCountry;
      });

      state.clientCountryList = clientCountries.map(
        (clientCountry) => clientCountry.ClientCountryID
      );
    },
    getClientCountriesFailure: loadingFailed,
    createClientCountryStart: startLoading,
    createClientCountrySuccess(
      state,
      { payload }: PayloadAction<ClientCountry>
    ) {
      const { ClientCountryID } = payload;
      state.clientCountriesById[ClientCountryID] = payload;
      state.clientCountryList.push(ClientCountryID);

      state.isLoading = false;
      state.error = null;
    },
    updateClientCountrySuccess(
      state,
      { payload }: PayloadAction<ClientCountry>
    ) {
      const { ClientCountryID } = payload;
      state.clientCountriesById[ClientCountryID] = payload;

      state.isLoading = false;
      state.error = null;
    },
    deleteClientCountrySuccess(state, { payload }: PayloadAction<number>) {
      const ClientCountryID = payload;
      delete state.clientCountriesById[ClientCountryID];
      state.clientCountryList = state.clientCountryList.filter(
        (item) => item !== ClientCountryID
      );

      state.isLoading = false;
      state.error = null;
    },
    createClientCountryFailure: loadingFailed,
  },
});

export const {
  getClientCountriesStart,
  getClientCountriesSuccess,
  getClientCountriesFailure,
  createClientCountryStart,
  createClientCountrySuccess,
  updateClientCountrySuccess,
  deleteClientCountrySuccess,
  createClientCountryFailure,
} = clientCountries.actions;

export default clientCountries.reducer;

export const fetchClientCountries =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getClientCountriesStart());
      const clientCountries = await getClientCountries(accessToken);
      //console.log("RETURN", clientCountries);
      dispatch(getClientCountriesSuccess(clientCountries));
    } catch (err: any) {
      dispatch(getClientCountriesFailure(err.toString()));
    }
  };

export const addClientCountry =
  (accessToken: String, newClientCountry: Partial<ClientCountry>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientCountryStart());
      const clientCountry = await createClientCountry(
        accessToken,
        newClientCountry
      );
      dispatch(createClientCountrySuccess(clientCountry));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(fetchClientCountries(accessToken));
      dispatch(push("/admin/clientCountry"));
    } catch (err: any) {
      dispatch(createClientCountryFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updClientCountry =
  (
    accessToken: String,
    clientCountryID: number,
    newClientCountry: Partial<ClientCountry>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientCountryStart());
      const clientCountry = await updateClientCountry(
        accessToken,
        clientCountryID,
        newClientCountry
      );
      dispatch(updateClientCountrySuccess(clientCountry));
      dispatch(fetchClientCountries(accessToken));
      //dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push("/clientCountries/" + clientCountryID));
    } catch (err: any) {
      dispatch(createClientCountryFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delClientCountry =
  (accessToken: String, clientCountryID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientCountryStart());
      const result = await deleteClientCountry(accessToken, clientCountryID);
      dispatch(deleteClientCountrySuccess(clientCountryID));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push("/admin/clientCountry"));
    } catch (err: any) {
      dispatch(createClientCountryFailure(err.toString()));
    }
  };
