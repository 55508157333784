import axiosClient from "../axiosClient";

export interface InteractionPhase {
  InteractionPhaseID: number;
  InteractionID: number;
  PhaseID: number;
  Name: string;
  Rank: number;
}

export interface InteractionPhasesResult {
  interactionPhases: InteractionPhase[];
}

export async function getInteractionPhases(
  accessToken: String
): Promise<InteractionPhasesResult> {
  const url = `/interactionPhase`;

  try {
    const { data } = await axiosClient.get<InteractionPhasesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getInteractionPhasesByInteraction(
  accessToken: String,
  interactionID: number
): Promise<InteractionPhasesResult> {
  const url = `/interactionPhase/interaction/${interactionID}`;

  try {
    const { data } = await axiosClient.get<InteractionPhasesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
