import axiosClient from "./axiosClient";

export interface NotificationType {
  NotificationTypeID: number;
  ProjectID: number;
  NotificationTypeName: string;
  TemplateContent: string;
}

export interface NotificationTypesResult {
  notificationTypes: NotificationType[];
}

export async function getNotificationTypes(
  accessToken: String
): Promise<NotificationTypesResult> {
  const url = `/notificationType`;

  try {
    const { data } = await axiosClient.get<NotificationTypesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
