import axiosClient from "./axiosClient";

export interface WorkHourPeriodException {
  CompanyID: number;
  CompanyName: string;
  ProjectName: string;
  LastReport: string;
}

export interface WorkHourPeriodExceptionsResult {
  workHourPeriodExceptions: WorkHourPeriodException[];
}

export async function getWorkHourPeriodExceptions(
  accessToken: String
): Promise<WorkHourPeriodExceptionsResult> {
  const url = `/workhourperiodexception`;
  try {
    const { data } = await axiosClient.get<WorkHourPeriodExceptionsResult>(
      url,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}
