import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ExpenditureChart,
  ExpenditureChartsResult,
  getExpenditureCharts,
} from "api/expenditureChartAPI";
import { AppThunk } from "app/store";

interface ExpenditureChartState {
  expenditureChartsById: Record<number, ExpenditureChart>;
  expenditureChartList: number[];
  isLoading: boolean;
  error: string | null;
}

const ExpenditureChartInitialState: ExpenditureChartState = {
  expenditureChartsById: {},
  expenditureChartList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: ExpenditureChartState) {
  state.isLoading = true;
}

function loadingFailed(
  state: ExpenditureChartState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const expenditureCharts = createSlice({
  name: "spendtype",
  initialState: ExpenditureChartInitialState,
  reducers: {
    getExpenditureChartsStart: startLoading,
    getExpenditureChartsSuccess(
      state,
      { payload }: PayloadAction<ExpenditureChartsResult>
    ) {
      const { expenditures } = payload;
      state.isLoading = false;
      state.error = null;

      expenditures.forEach((item) => {
        state.expenditureChartsById[item.MonthSequenceID] = item;
      });

      state.expenditureChartList = expenditures.map(
        (item) => item.MonthSequenceID
      );
    },
    getExpenditureChartsFailure: loadingFailed,
  },
});

export const {
  getExpenditureChartsStart,
  getExpenditureChartsSuccess,
  getExpenditureChartsFailure,
} = expenditureCharts.actions;

export default expenditureCharts.reducer;

export const fetchExpenditureCharts =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getExpenditureChartsStart());
      const expenditureCharts = await getExpenditureCharts(accessToken);
      // console.log("RETURN", expenditureCharts);
      dispatch(getExpenditureChartsSuccess(expenditureCharts));
    } catch (err: any) {
      dispatch(getExpenditureChartsFailure(err.toString()));
    }
  };
