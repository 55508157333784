import { CommitmentSourceForm } from "./CommitmentSourceForm";

export const CommitmentSourceCreatePage = () => {
  let CommitmentSourceCreateView = (
    <div>
      <CommitmentSourceForm />
    </div>
  );

  return (
    <div id="CommitmentSource-detail-page">{CommitmentSourceCreateView}</div>
  );
};
