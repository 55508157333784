import { AssetForm } from "./AssetForm";

export const AssetCreatePage = () => {
  let assetCreateView = (
    <div>
      <AssetForm />
    </div>
  );

  return <div id="asset-create-page"> {assetCreateView} </div>;
};
