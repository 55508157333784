import * as yup from "yup";
import { useTypedTranslation } from "utils/customHooks";

export const CommunityInvestmentValidationSchema = () => {
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  return yup.object({
    OrganizationName: yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t(
            "objBen:objects.communityinvestment.fields.organizationname"
          ),
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t(
            "objBen:objects.communityinvestment.fields.organizationname"
          ),
          count: 50,
        })
      ),
    TownCity: yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:platformwide.fields.towncity"),
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objPlt:platformwide.fields.towncity"),
          count: 50,
        })
      ),
    ProvinceState: yup
      .string()
      .trim()
      .nullable()
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objPlt:platformwide.fields.provincestate"),
          count: 50,
        })
      ),
    CountryName: yup
      .string()
      .trim()
      .nullable()
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objPlt:platformwide.fields.country"),
          count: 50,
        })
      ),
    CompanyID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.company.name"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.company.name"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.company.name"),
        })
      ),
    ProjectID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.project.name"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.project.name"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:objects.project.name"),
        })
      ),
    ProjectComponentID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.projectcomponent.fullname"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.projectcomponent.fullname"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:objects.projectcomponent.fullname"),
        })
      ),
    CommunityInvestmentTypeID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t(
            "objBen:objects.communityinvestment.fields.investmenttype"
          ),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t(
            "objBen:objects.communityinvestment.fields.investmenttype"
          ),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t(
            "objBen:objects.communityinvestment.fields.investmenttype"
          ),
        })
      ),
    Indigenous: yup.boolean().nullable(),
    IndigenousGroupID: yup.number().when("Indigenous", {
      is: true,
      then: (value) => {
        return value
          .positive(
            t("strGen:validation.required", {
              fieldname: t("objPlt:platformwide.indigenous.indigenousgroup"),
            })
          )
          .required(
            t("strGen:validation.required", {
              fieldname: t("objPlt:platformwide.indigenous.indigenousgroup"),
            })
          )
          .typeError(
            t("strGen:validation.invalid", {
              fieldname: t("objPlt:platformwide.indigenous.indigenousgroup"),
            })
          );
      },
      otherwise: (value) => value.nullable(),
    }),
    InvestmentDate: yup
      .date()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:platformwide.fields.date"),
        })
      )
      .typeError(t("strGen:validation.date.improperformat")),
    SpendAmount: yup
      .number()
      .positive(
        t("strGen:validation.positive", {
          fieldname: t(
            "objBen:objects.communityinvestment.fields.investmentamount"
          ),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t(
            "objBen:objects.communityinvestment.fields.investmentamount"
          ),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t(
            "objBen:objects.communityinvestment.fields.investmentamount"
          ),
        })
      ),
    Description: yup.string().nullable(),
    CommunityInvestmentGroup: yup.string().nullable(),
  });
};
