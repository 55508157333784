import { PaymentPeriod } from "api/benefits/paymentPeriodAPI";
import { format as dateFormat } from "date-fns";

// Returns the ISO string representation of the first day in the previous month.
function FirstDayInPreviousMonth() {
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);

  return firstDay.toISOString().slice(0, 10);
}

// Returns the ISO string representation of the last day in the previous month.
function LastDayInPreviousMonth() {
  var date = new Date();
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 0, 0);

  return lastDay.toISOString().slice(0, 10);
}

// Correct for UTC offset
// Adjusts the input Date for its timezone offset and returns the adjusted Date.
// If the input Date is falsy, it returns the input Date as-is.
function ConvertDateOffset(date: Date) {
  if (date) {
    let dt = new Date(date);
    return new Date(dt.getTime() + dt.getTimezoneOffset() * 60000);
  } else return date;
}

// Converts the input Date object into a formatted YYYY-MM-DD string.
// If the input Date is falsy, it returns the input Date as-is.
function GetJSDate(date?: Date | null) {
  let convertedDate;

  if (!date) {
    return date;
  }

  // Getting the year, month, and day components of the Date object.
  convertedDate = [
    date.getFullYear(),
    // Adding 1 to month as it's zero-indexed.
    ("0" + (date.getMonth() + 1)).slice(-2),
    // Ensuring two-digit representation of day.
    ("0" + date.getDate()).slice(-2),
  ].join("-");

  return convertedDate;
}

function GetJSYear(date?: Date | null, month?: number, day?: number) {
  let convertedDate;

  if (!date) {
    return date;
  }

  // Getting the year component of the Date object
  const year = date.getFullYear();

  // If month and day are provided, use them; otherwise default to yyyy-01-01
  const paddedMonth = month ? ("0" + month).slice(-2) : "01";
  const paddedDay = day ? ("0" + day).slice(-2) : "01";

  // Constructing the date string
  convertedDate = [year, paddedMonth, paddedDay].join("-");

  // Check if the constructed date is a valid date
  const isValidDate = !isNaN(Date.parse(convertedDate));

  return isValidDate ? convertedDate : null;
}

// Gets the date from the input string and returns it if input string isn't null
const getDateFromDateString = (inDate: string) => {
  if (inDate === null) {
    return null;
  } else {
    let newDate = inDate.slice(0, 10);
    return newDate;
  }
};

// Converts a valid input date string into a formatted YYYY-MM-DD date.
// If the input is not a valid date string, it returns "None".
function ConvertToJSDate(inDate: string) {
  if (inDate && typeof inDate === "string" && inDate.length >= 10) {
    return inDate.substring(0, 10);
  }

  return "None";
}

// checks if first date is NOT past current date
// if second date is passed in checks that second date is NOT  before the first
//made this the discovered yup can test against current date ...
const dateCheck = (date: any, date2?: any) => {
  let curDate = getDateFromDateString(
    new Date().toISOString().substring(0, 10)
  );
  //if check is to prevent undefined error
  if (date2 && date) {
    return date2 > date ? true : false;
  } else if (date) {
    console.log("DATE", date);
    if ((curDate || 1) < (getDateFromDateString(date.toISOString()) || 0)) {
      return false;
    } else return true;
  } else return true;
};

const getFirstAndLastDateOfMonth = (date: Date | null) => {
  if (!date) {
    return { firstDay: null, lastDay: null };
  }

  // Get the year and month from the given date
  const year = date.getFullYear();
  const month = date.getMonth();

  // Create a new Date object for the first day of the month
  const firstDayOfMonth = new Date(year, month, 1);

  // Create a new Date object for the last day of the month
  const lastDayOfMonth = new Date(year, month + 1, 0);

  // Return an object containing the first and last dates
  return {
    firstDay: firstDayOfMonth,
    lastDay: lastDayOfMonth,
  };
};

// Converts a JavaScript Date object or date string into its corresponding Excel serial date value.
// The returned value represents the number of days since the Excel date base (January 1, 1900).
function excelValueFromJSDate(inDate: any) {
  let dateValue = Date.parse(inDate);
  let excelValue = Math.trunc(dateValue / 86400000) + 25569;
  return excelValue;
}

// checks and converts an Excel date value into a JavaScript Date object
function excelDateToJsDate(value: number | Date): Date | null {
  const isExcelDate = (val: any): val is number => typeof val === "number";

  if (isExcelDate(value)) {
    // Excel date starts from January 1, 1900
    const MS_PER_DAY: number = 24 * 60 * 60 * 1000;
    const excelEpoch: Date = new Date("1900-01-01T00:00:00Z");
    // Excel incorrectly considers 1900 as a leap year, so we need to adjust
    const offsetDays: number = value > 60 ? 1 : 0;
    // Convert to milliseconds and add to the Excel epoch
    const convertedDate = new Date(
      excelEpoch.getTime() + (value - offsetDays) * MS_PER_DAY
    );
    //console.log("Typeof: ", typeof(value), value, convertedDate)
    return ConvertDateOffset(convertedDate);
  } else if (value instanceof Date) {
    return ConvertDateOffset(value);
  }

  return null;
}

/**
 * Check if the provided value is a valid date.
 * It accepts date as a JavaScript Date object, a string.
 * Returns true if the value is a valid date, otherwise returns false.
 */
export const isExcelDateValid = (dateValue: Date | string): boolean => {
  // expect either yyyy/mm/dd or yyyy-mm-dd
  const iso8601StrPattern = /^\d{4}(\/|-)\d{2}(\/|-)\d{2}$/;
  if (typeof dateValue === "string" && iso8601StrPattern.test(dateValue)) {
    const cleanedDateValue = dateValue.trim().replace(/\//g, "-");
    const parsedDate = new Date(cleanedDateValue);

    try {
      // Convert the Date object to a string in the "YYYY-MM-DD" format
      const parsedDateString = parsedDate.toISOString().substring(0, 10);

      // These two conditions ensure that the date string is a valid date and
      // is not an invalid date that JavaScript still manages to parse
      // For example, "2022-02-30" is parsed as "2022-03-02"
      return (
        cleanedDateValue === parsedDateString && !isNaN(parsedDate.getTime())
      );
    } catch (error) {
      return false;
    }
  }
  if (dateValue instanceof Date) {
    return !isNaN(dateValue.getTime());
  }
  return false;
};

export const checkdates = (date: Date): boolean => {
  const curDate =
    getDateFromDateString(new Date().toISOString().substring(0, 10)) || 1;
  const dateStr = getDateFromDateString(date.toISOString()) || 0;
  return curDate >= dateStr;
};

const normalizeDate = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const validateDate = (
  date: Date,
  paymentPeriod: PaymentPeriod | undefined
) => {
  const normalizedDate = normalizeDate(ConvertDateOffset(date));
  if (!checkdates(date)) {
    return false;
  }

  // Check if the date is within the payment period, if provided
  if (paymentPeriod) {
    const normalizedStartDate = normalizeDate(
      ConvertDateOffset(new Date(paymentPeriod.StartDate))
    );
    const normalizedEndDate = normalizeDate(
      ConvertDateOffset(new Date(paymentPeriod.EndDate))
    );
    return (
      normalizedDate >= normalizedStartDate &&
      normalizedDate <= normalizedEndDate
    );
  }

  return true;
};

export const DateAsString = (date: Date) => {
  const myDate = normalizeDate(ConvertDateOffset(date));
  if (!checkdates(date)) {
    return false;
  }

  return dateFormat(myDate, "yyyyMMdd").toString();
};

export {
  FirstDayInPreviousMonth,
  LastDayInPreviousMonth,
  ConvertDateOffset,
  GetJSDate,
  GetJSYear,
  getFirstAndLastDateOfMonth,
  excelDateToJsDate,
  getDateFromDateString,
  ConvertToJSDate,
  dateCheck,
  excelValueFromJSDate,
};
