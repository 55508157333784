import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { addGrievance, updGrievance, delGrievance } from "./GrievanceSlice";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { IppFormHeader } from "components/IppFormHeader";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppTextField } from "components/IppTextField";
import { IppAutocomplete } from "components/IppAutocomplete";
import { IppDisplayField } from "components/IppDisplayField";
import LoadingIndicator from "components/LoadingIndicator";
import { IppAttachmentInventory } from "components/IppAttachmentInventory";
import { IppChildInventory } from "components/Inventory/IppChildInventory";
import {
  ConvertDateOffset,
  ConvertToJSDate,
  GetJSDate,
} from "../../../utils/DateFunctions";
import { IppTokenAutocomplete } from "components/IppTokenAutoComplete";
import { fetchStakeholderGroups } from "../../platform/groups/StakeholderGroupSlice";
import { fetchGrievanceStatuses } from "features/datalists/GrievanceStatusSlice";
import { IppStaticTextfield } from "components/IppStaticTextfield";
import { fetchDeleteChecksByGrievance } from "features/deleteCheck/DeleteCheckSlice";
import { fetchUsers } from "features/users/UsersSlice";
import { Contact } from "../../../api/stakeholder/contactAPI";
import { fetchGrievanceContactsByGrievance } from "../grievanceContact/GrievanceContactSlice";
import { fetchGrievanceGroupsByGrievance } from "../grievanceGroup/GrievancGroupSlice";
import {
  addGrievanceInteraction,
  fetchGrievanceInteractionsByGrievance,
} from "../grievanceInteraction/GrievanceInteractionSlice";
import { fetchGrievanceIssuesByGrievance } from "../grievanceIssue/GrievanceIssueSlice";
import { fetchContacts } from "../../platform/contacts/ContactSlice";
import { IppContactAutoComplete } from "components/IppContactAutoComplete";
import { fetchIssues } from "../issue/IssueSlice";
import { fetchInteractions } from "../interaction/InteractionSlice";
import { Interaction } from "api/stakeholder/interactionAPI";
import { Prompt } from "react-router";
import { IppDatePicker } from "components/IppDatePicker";
import { IppTabPanel } from "components/IppTabPanel";
import { countAttachments } from "api/attachmentsAPI";
import { IsMedSmall } from "utils/mediaQueries";
import {
  useRoleChecks,
  useSnackBarConstants,
  useTypedTranslation,
} from "utils/customHooks";
import { UserWriteAccess } from "utils/userAccess";
import { IppPersonResponsible } from "components/IppPersonResponsible";

const PREFIX = "GrievanceForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    // minWidth: 650,
    maxWidth: 1150,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const GrievanceForm = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);
  const snackbarConstants = useSnackBarConstants();
  const customBP = IsMedSmall();

  const [didSaveInventory, setDidSaveInventory] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  let itemData = props.grievance || {};
  const showEdit = UserWriteAccess("Engagement", itemData.ProjectID);

  //Add or remove Asterisk * when changing validation
  const validationSchema = yup.object({
    GrievanceName: yup
      .string()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.grievance.name"),
        })
      )
      .max(
        250,
        t("strGen:validation.max", {
          fieldname: t("objStk:objects.grievance.name"),
          count: 250,
        })
      ),
    ReportedDate: yup
      .date()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.grievance.fields.datereported"),
        })
      )
      .max(
        new Date(),
        t("strGen:validation.date.nofuturedate", {
          fieldname: t("objStk:objects.grievance.fields.datereported"),
        })
      )
      .typeError(t("strGen:validation.date.improperformat"))
      .nullable(),
    ResolutionDate: yup
      .date()
      .nullable()
      .typeError(t("strGen:validation.date.improperformat"))
      .max(
        new Date(),
        t("strGen:validation.date.nofuturedate", {
          fieldname: t("objStk:objects.grievance.fields.dateresolved"),
        })
      )
      .when("GrievanceStatusID", {
        is: 4,
        then: (validationSchema) =>
          validationSchema.required(
            t("strGen:validation.requiredifcompleted", {
              fieldname: t("objStk:objects.grievance.fields.dateresolved"),
              fieldname2: t("objStk:objects.grievance.fields.status"),
            })
          ),
      })
      .min(
        yup.ref("ReportedDate"),
        t("strGen:validation.date.notearlierthan", {
          fieldname: t("objStk:objects.grievance.fields.dateresolved"),
          fieldname2: t("objStk:objects.grievance.fields.datereported"),
        })
      ),
    GrievanceStatusID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.grievance.fields.status"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.grievance.fields.status"),
        })
      ),
    ProjectID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.grievance.fields.project"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.grievance.fields.project"),
        })
      ),
    PersonResponsible: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.grievance.fields.personresponsible"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.grievance.fields.personresponsible"),
        })
      ),
    Reference: yup
      .string()
      .trim()
      .nullable()
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objStk:objects.grievance.fields.reference"),
          count: 50,
        })
      ),
    Accomodation: yup.string().trim().nullable(),
    InterestAddressed: yup.string().trim().nullable(),
    ResidualConcerns: yup.string().trim().nullable(),
  });

  const [isEditing, setIsEditing] = useState(
    itemData.GrievanceID ? false : true
  );
  const [isAdding] = useState(itemData.GrievanceID ? false : true);
  const [listGroups, setListGroups] = useState<Array<string>>([]);
  const [listIssues, setListIssues] = useState<Array<string>>([]);
  const [communication, setCommunication] = useState<Interaction>();
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
  // used to display attachments count
  const [attachCount, setAttachCount] = useState<any>({ Count: 0 });

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const {
    clientId,
    isLoading: clientIsLoading,
    ft_eng_Sentiment,
    ft_eng_BCER,
  } = useSelector((state: RootState) => state.client);

  const {
    grievanceContactList,
    grievanceContactsById,
    isLoading: grievanceContactIsLoading,
    error: grievanceContactError,
  } = useSelector((state: RootState) => state.grievanceContacts);

  const grievanceContactsUnfiltered = grievanceContactList.map(
    (id) => grievanceContactsById[id]
  );

  const grievanceContacts = grievanceContactsUnfiltered
    .filter((grievance) => grievance.GrievanceID === itemData.GrievanceID)
    .map((p) => {
      let n = Object.assign({}, p) as any;
      n.ContactName = p.ContactName
        ? p.ContactName.trim() !== ""
          ? p.ContactName.trim()
          : `* ${t("objPlt:objects.contact.missingname")} *`
        : `* ${t("objPlt:objects.contact.missingname")} *`;
      return n;
    });

  const {
    grievanceGroupList,
    grievanceGroupsById,
    isLoading: grievanceGroupIsLoading,
  } = useSelector((state: RootState) => state.grievanceGroups);

  const grievanceGroupsUnfiltered = grievanceGroupList.map(
    (id) => grievanceGroupsById[id]
  );

  const grievanceGroups = grievanceGroupsUnfiltered.filter(
    (items) => items.GrievanceID === itemData.GrievanceID
  );

  const {
    grievanceInteractionList,
    grievanceInteractionsById,
    isLoading: grievanceInteractionIsLoading,
  } = useSelector((state: RootState) => state.grievanceInteractions);

  const grievanceInteractionsUnfiltered = grievanceInteractionList.map(
    (id) => grievanceInteractionsById[id]
  );

  const grievanceInteractions = grievanceInteractionsUnfiltered.filter(
    (items) => items.GrievanceID === itemData.GrievanceID
  );

  // create a list of interaction IDs already linked to the current Grievance
  const grievanceInteractionIntIDs = grievanceInteractions.reduce(
    (giIntIDs, currVal) => {
      giIntIDs.add(currVal.InteractionID);
      return giIntIDs;
    },
    new Set()
  );

  const gi = grievanceInteractions.map((p) => {
    let newObj = Object.assign({}, p);
    newObj.InteractionDate = ConvertDateOffset(new Date(p.InteractionDate));

    return newObj;
  });

  const {
    grievanceIssueList,
    grievanceIssuesById,
    isLoading: grievanceIssueIsLoading,
    error: grievanceIssueError,
  } = useSelector((state: RootState) => state.grievanceIssues);

  const grievanceIssuesUnfiltered = grievanceIssueList.map(
    (id) => grievanceIssuesById[id]
  );

  const grievanceIssues = grievanceIssuesUnfiltered.filter(
    (items) => items.GrievanceID === itemData.GrievanceID
  );

  const {
    contactList,
    contactsById,
    isLoading: contactIsLoading,
    error: contactError,
  } = useSelector((state: RootState) => state.contacts);

  const contacts = contactList.map((id) => contactsById[id]);

  const {
    stakeholderGroupList,
    stakeholderGroupsById,
    isLoading: groupIsLoading,
    error: groupError,
  } = useSelector((state: RootState) => state.stakeholderGroups);

  const stakeholderGroups = stakeholderGroupList.map(
    (id) => stakeholderGroupsById[id]
  );

  const {
    issueList,
    issuesById,
    isLoading: issueIsLoading,
    error: issueError,
  } = useSelector((state: RootState) => state.issues);

  const issues = issueList.map((id) => issuesById[id]);

  const {
    grievanceStatusList,
    grievanceStatusesById,
    isLoading: statusIsLoading,
    error: statusError,
  } = useSelector((state: RootState) => state.grievanceStatus);

  const grievanceStatuses = grievanceStatusList.map(
    (id) => grievanceStatusesById[id]
  );

  const { getEngagementsPowerUserRoles } = useRoleChecks();

  // if non-admin, only show userRoles correspondering to Stakeholder Module (ModuleRoleID === 5)
  const stkPowerUserRoles = getEngagementsPowerUserRoles();

  // list of project IDs available in this Module
  const accessibleProjectIDs = stkPowerUserRoles
    .filter((role) => role.UserAccountID === currentProfile.UserAccountID)
    .map((role) => role.ProjectID);

  const {
    projectList,
    projectsById,
    isLoading: projectIsLoading,
    error: projectError,
  } = useSelector((state: RootState) => state.projects);

  // if admin, show all projects
  // if non-admin, only show projects included in accessibleProjectIds
  const projects = currentProfile.IsClientAdmin
    ? projectList.map((id) => projectsById[id])
    : projectList
        .map((id) => projectsById[id])
        .filter((project) => accessibleProjectIDs.includes(project.ProjectID));

  const {
    userList,
    usersById,
    error: userError,
    isLoading: userIsLoading,
  } = useSelector((state: RootState) => state.users);

  // Filter for active users only
  const allUsers = userList.map((id) => usersById[id]);
  let users = allUsers.filter((item) => item.IsActive === true);

  const { interactionList, interactionsById, subInteractionisLoading } =
    useSelector((state: RootState) => state.interactions);

  const unfilteredInteractions = interactionList.map(
    (id) => interactionsById[id]
  );

  // filter interactions to only list interactions not currently linked, and that the current user has a Stk PowerUser Role for.
  const filteredInteractions = unfilteredInteractions.filter((int) => {
    if (
      int.InteractionID === itemData.InteractionID ||
      grievanceInteractionIntIDs.has(int.InteractionID)
    ) {
      return false;
    }

    // if not an Admin, check that the user has a Stakeholder PowerUser Role on the project
    if (!currentProfile.IsClientAdmin) {
      return stkPowerUserRoles.some(
        (role: any) => role.ProjectID === int.ProjectID
      );
    }

    return true;
  });

  // adds auto complete display value to options
  const interactions = filteredInteractions.map((int) => {
    let displayInteraction = Object.assign({}, int) as any;
    displayInteraction.DisplayValue = `C-${displayInteraction.CommunicationID}  ${displayInteraction.InteractionTitle}`;
    return displayInteraction;
  });

  //usestate for Rootstate of attachment
  const { attachmentsById, attachmentList, error, isLoading } = useSelector(
    (state: RootState) => state.attachments
  );

  const attachments = attachmentList.map((id) => attachmentsById[id]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(
          fetchGrievanceContactsByGrievance(accessToken, itemData.GrievanceID)
        );
        dispatch(
          fetchGrievanceGroupsByGrievance(accessToken, itemData.GrievanceID)
        );
        dispatch(
          fetchGrievanceInteractionsByGrievance(
            accessToken,
            itemData.GrievanceID
          )
        );
        dispatch(
          fetchGrievanceIssuesByGrievance(accessToken, itemData.GrievanceID)
        );
        dispatch(fetchGrievanceStatuses(accessToken));
        dispatch(fetchUsers(accessToken, clientId));
        dispatch(fetchContacts(accessToken));
        dispatch(fetchStakeholderGroups(accessToken));
        dispatch(fetchIssues(accessToken));
        dispatch(fetchInteractions(accessToken));
        const count = await countAttachments(
          accessToken,
          "Grievances",
          itemData.GrievanceID
        );
        setAttachCount(count.attachments ? count.attachments : 0);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, itemData.GrievanceID, dispatch, getAccessTokenSilently]);

  // used for attachment count
  useEffect(() => {
    (async () => {
      try {
        if (attachments.length > 0) {
          const attach = attachments.filter((item) => {
            return (
              item.RecordID === itemData.GrievanceID &&
              item.RecordType === "Grievances"
            );
          });
          if (attach && attach.length > 0)
            setAttachCount({ Count: attach.length });
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [attachmentList]);

  // get lists for group  autocomplete fields
  useEffect(() => {
    // effect
    if (!grievanceGroupIsLoading) {
      let newList: string[] = [];
      grievanceGroups.map((item) => newList.push(item.GroupName));
      setListGroups(newList);
    }
    return () => {
      // cleanup
    };
  }, [itemData.GrievanceID, grievanceGroupIsLoading, isEditing]);

  const linkInteraction = (comm: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        let item = {
          GrievanceID: itemData.GrievanceID,
          InteractionID: comm.InteractionID,
        };

        dispatch(addGrievanceInteraction(accessToken, item, snackbarConstants));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  // get lists for Interactions
  useEffect(() => {
    // effect
    if (!grievanceInteractionIsLoading) {
      let newList: string[] = [];
      grievanceInteractions.map((item) => newList.push(item.InteractionTitle));
    }
    return () => {
      // cleanup
    };
  }, [itemData.GrievanceID, grievanceInteractionIsLoading, isEditing]);

  // get lists for contact  autocomplete fields
  useEffect(() => {
    // effect
    if (!grievanceContactIsLoading) {
      let concat = contacts.filter((contact) =>
        grievanceContacts.some(
          (grievance) => grievance.ContactID === contact.ContactID
        )
      );
      setSelectedContacts(concat);
    }
    return () => {
      // cleanup
    };
  }, [
    itemData.GrievanceID,
    grievanceContactIsLoading,
    isEditing,
    contactIsLoading,
  ]);

  // get lists for issues  autocomplete fields
  useEffect(() => {
    // effect
    if (!grievanceIssueIsLoading) {
      let newList: string[] = [];
      grievanceIssues.map((item) => newList.push(item.IssueName));
      setListIssues(newList);
    }
    return () => {
      // cleanup
    };
  }, [itemData.GrievanceID, grievanceIssueIsLoading, isEditing]);

  // save when changes made to child records
  useEffect(() => {
    if (didSaveInventory) {
      if (communication) {
        linkInteraction(communication);
      }
      submitFunc(itemData);
      setDidSaveInventory(false);
    }
    return () => {
      // cleanup
    };
  }, [didSaveInventory]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        dispatch(
          addGrievance(
            accessToken,
            values,
            selectedContacts,
            listGroups,
            listIssues,
            [], //Can't add an Interaction from the New Grievance Form
            true,
            snackbarConstants
          )
        );
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  if (itemData.GrievanceID) {
    //Update case
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(
            updGrievance(
              accessToken,
              values.GrievanceID,
              values,
              selectedContacts,
              listGroups,
              listIssues,
              true,
              snackbarConstants
            )
          );

          setIsEditing(false);
        } catch (e) {
          console.error(e);
        }
      })();
    };
  } else {
    let thisDate = new Date();
    itemData.GrievanceName = "";
    itemData.ClientID = clientId;
    itemData.Details = "";
    itemData.ReportedDate = thisDate.toISOString().slice(0, 10);
    itemData.ResolutionDate = null;
    itemData.ProjectID = -1;
    itemData.GrievanceStatusID = -1;
    itemData.PersonResponsible = currentProfile.UserAccountID;
    itemData.PersonResponsibleName =
      currentProfile.FirstName + " " + currentProfile.Surname;
    itemData.Reference = "";
    itemData.Accomodation = "";
    itemData.InterestAddressed = "";
    itemData.ResidualConcerns = "";
  }

  const handleDelete = () => {
    // function to delete current community investment entry
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(
          delGrievance(accessToken, itemData.GrievanceID, snackbarConstants)
        );
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const handleTabSelect = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };

  const formik = useFormik({
    initialValues: itemData,
    validationSchema: validationSchema,
    onSubmit: submitFunc,
  });

  // const checkStatus = () => {
  //   // can't have a resolution date unless completed
  //   console.log("ReportedDate:", formik.values.ReportedDate);
  //   console.log("ResolutionDate:", formik.values.ResolutionDate);
  //   if (
  //     formik.values.GrievanceStatusID === 4 &&
  //     !formik.values.ResolutionDate
  //   ) {
  //     return false;
  //   }
  //   return true;
  // };

  let viewForm = (
    <Root>
      <Box display="flex" justifyContent="center">
        <Grid container className={classes.editForm} spacing={1}>
          <Grid item xs={12}>
            <Paper className={classes.boxSpace}>
              <Grid container spacing={1}>
                <IppFormHeader
                  title={t("objStk:objects.grievance.name")}
                  isEditing={isEditing}
                  isAdding={isAdding}
                />
                <Grid item xs={12}>
                  <IppDisplayField
                    showLabel={false}
                    value={
                      "G-" + itemData.SequenceID + "  " + itemData.GrievanceName
                    }
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEmphasis={true}
                    showEdit={showEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayField
                    label={t("objStk:objects.grievance.fields.datereported")}
                    value={ConvertToJSDate(itemData.ReportedDate)}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayField
                    label={t("objStk:objects.grievance.fields.dateresolved")}
                    value={ConvertToJSDate(itemData.ResolutionDate)}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayField
                    label={t("objStk:objects.grievance.fields.status")}
                    value={itemData.GrievanceStatusText}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayField
                    label={t("objStk:objects.grievance.fields.project")}
                    value={itemData.ProjectName}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayField
                    label={t(
                      "objStk:objects.grievance.fields.personresponsible"
                    )}
                    value={itemData.PersonResponsibleName}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayField
                    label={t("objStk:objects.grievance.fields.reference")}
                    value={formik.values.Reference}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                    isWebLink={formik.values.Reference ? true : false}
                    route={formik.values.Reference}
                    openInNewTab={true}
                    tooltip={t("strGen:tooltips.linkwillopennewtab")}
                  />
                </Grid>
                {ft_eng_BCER && (
                  <Grid item xs={6}>
                    <IppDisplayField
                      label={t("objStk:objects.grievance.fields.accomodation")}
                      value={itemData.Accomodation}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      showEdit={showEdit}
                    />
                  </Grid>
                )}
                {ft_eng_BCER && (
                  <Grid item xs={6}>
                    <IppDisplayField
                      label={t(
                        "objStk:objects.grievance.fields.interestaddressed"
                      )}
                      value={itemData.InterestAddressed}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      showEdit={showEdit}
                    />
                  </Grid>
                )}
                {ft_eng_BCER && (
                  <Grid item xs={6}>
                    <IppDisplayField
                      label={t(
                        "objStk:objects.grievance.fields.residualconcerns"
                      )}
                      value={itemData.ResidualConcerns}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      showEdit={showEdit}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <IppDisplayField
                    label={t("objStk:objects.grievance.fields.details")}
                    value={itemData.Details}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
                <Grid item xs={12}>
                  <IppFormButtons
                    isEditing={isEditing}
                    isAdding={isAdding}
                    setIsEditing={setIsEditing}
                    resetFunction={() => formik.resetForm()}
                    showDelete={showEdit}
                    deleteFunction={() => handleDelete()}
                    fetchFunc={fetchDeleteChecksByGrievance}
                    info={t("strGen:prompts.deletechecks.unlinkitems")}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Root>
  );

  let editForm = (
    <Root>
      {!formik.isSubmitting && (
        <Prompt
          when={formik.dirty}
          message={t("strGen:prompts.unsavedchanges")}
        />
      )}

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <IppFormHeader
            title={t("objStk:objects.grievance.name")}
            isEditing={isEditing}
            isAdding={isAdding}
          />
          <Grid item xs={isAdding ? 12 : 9}>
            <IppTextField
              id="GrievanceName"
              label={t("objStk:objects.grievance.name")}
              value={formik.values.GrievanceName}
              required
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.GrievanceName}
              errorsExpression={formik.errors.GrievanceName}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          {!isAdding && (
            <Grid item xs={3}>
              <IppStaticTextfield
                id="SequenceID"
                value={"G-" + formik.values.SequenceID}
                label={t("objStk:objects.grievance.sequenceid")}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <IppDatePicker
              id="ReportedDate"
              label={t("objStk:objects.grievance.fields.datereported")}
              required
              value={ConvertDateOffset(formik.values.ReportedDate)}
              onChangeFunction={(newValue: any) => {
                formik.setFieldValue("ReportedDate", GetJSDate(newValue), true);
                formik.setFieldTouched("ReportedDate", true, false);
              }}
              errorsExpression={formik.errors.ReportedDate}
              touchedExpression={formik.touched.ReportedDate}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="caption">
                <b>{t("objStk:objects.grievance.datereportednote")}</b>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <IppDatePicker
              id="ResolutionDate"
              label={t("objStk:objects.grievance.fields.dateresolved")}
              required={formik.values.GrievanceStatusID === 4 ? true : false}
              value={ConvertDateOffset(formik.values.ResolutionDate)}
              onChangeFunction={(newValue: any) => {
                formik.setFieldValue(
                  "ResolutionDate",
                  GetJSDate(newValue),
                  true
                );
                formik.setFieldTouched("ResolutionDate", true, false);
              }}
              errorsExpression={formik.errors.ResolutionDate}
              touchedExpression={formik.touched.ResolutionDate}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          <Grid item xs={6}>
            <IppAutocomplete
              id="GrievanceStatusID"
              required
              options={grievanceStatuses}
              value={grievanceStatuses.find((obj) => {
                return (
                  obj.GrievanceStatusID === formik.values.GrievanceStatusID
                );
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue(
                    "GrievanceStatusID",
                    newValue.GrievanceStatusID
                  );
                } else {
                  formik.setFieldValue("GrievanceStatusID", -1);
                }
              }}
              label={t("objStk:objects.grievance.fields.status")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.GrievanceStatusText}
              errorFunction={formik.errors.GrievanceStatusID}
              touchedFunction={formik.touched.GrievanceStatusID}
              textValueFunction={formik.values.GrievanceStatusText}
            />
          </Grid>
          <Grid item xs={6}>
            <IppAutocomplete
              id="ProjectID"
              required
              options={projects}
              value={projects.find((obj) => {
                return obj.ProjectID === formik.values.ProjectID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("ProjectID", newValue.ProjectID);
                } else {
                  formik.setFieldValue("ProjectID", -1);
                }
              }}
              label={t("objStk:objects.grievance.fields.project")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.ProjectName}
              errorFunction={formik.errors.ProjectID}
              touchedFunction={formik.touched.ProjectID}
              textValueFunction={formik.values.ProjectName}
            />
          </Grid>
          <Grid item xs={6}>
            <IppPersonResponsible
              id="PersonResponsible"
              required
              value={allUsers.find((obj) => {
                return obj.UserAccountID === formik.values.PersonResponsible;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue(
                    "PersonResponsible",
                    newValue.UserAccountID
                  );
                } else {
                  formik.setFieldValue("PersonResponsible", null);
                }
              }}
              label={t("objStk:objects.grievance.fields.personresponsible")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) =>
                option.FirstName + " " + option.Surname
              }
              errorFunction={formik.errors.PersonResponsible}
              touchedFunction={formik.touched.PersonResponsible}
              textValueFunction={formik.values.PersonResponsibleName}
              projectID={formik.values.ProjectID}
            />
          </Grid>
          <Grid item xs={6}>
            <IppTextField
              id="Reference"
              label={t("objStk:objects.grievance.fields.reference")}
              value={formik.values.Reference}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.Reference}
              errorsExpression={formik.errors.Reference}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              toolTip={t("strGen:tooltips.grievance.reference")}
            />
          </Grid>
          {ft_eng_BCER && (
            <Grid item xs={6}>
              <IppTextField
                id="Accomodation"
                label={t("objStk:objects.grievance.fields.accomodation")}
                value={formik.values.Accomodation}
                multiLine={true}
                required={false}
                onChangeFunction={formik.handleChange}
                touchedExpression={formik.touched.Accomodation}
                errorsExpression={formik.errors.Accomodation}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Grid>
          )}
          {ft_eng_BCER && (
            <Grid item xs={6}>
              <IppTextField
                id="InterestAddressed"
                label={t("objStk:objects.grievance.fields.interestaddressed")}
                value={formik.values.InterestAddressed}
                multiLine={true}
                required={false}
                onChangeFunction={formik.handleChange}
                touchedExpression={formik.touched.InterestAddressed}
                errorsExpression={formik.errors.InterestAddressed}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Grid>
          )}
          {ft_eng_BCER && (
            <Grid item xs={6}>
              <IppTextField
                id="ResidualConcerns"
                label={t("objStk:objects.grievance.fields.residualconcerns")}
                value={formik.values.ResidualConcerns}
                multiLine={true}
                required={false}
                onChangeFunction={formik.handleChange}
                touchedExpression={formik.touched.ResidualConcerns}
                errorsExpression={formik.errors.ResidualConcerns}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <IppTextField
              id="Details"
              label={t("objStk:objects.grievance.fields.details")}
              value={formik.values.Details}
              multiLine={true}
              required={false}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.Details}
              errorsExpression={formik.errors.Details}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          {isAdding && (
            <>
              <Grid item xs={12}>
                <IppTokenAutocomplete
                  id="GroupList"
                  label={t("objStk:objects.grievance.fields.groupsinvolved", {
                    count: 2,
                  })}
                  options={stakeholderGroups.map((option) => option.GroupName)}
                  selectedValues={listGroups}
                  setSelectedValues={setListGroups}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                />
              </Grid>
            </>
          )}
          {isAdding && (
            <Grid item xs={12}>
              <IppContactAutoComplete
                options={contacts}
                selectedContacts={selectedContacts}
                setSelectedContacts={setSelectedContacts}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            {isAdding && (
              <IppTokenAutocomplete
                id="IssueList"
                label={t("objStk:objects.grievance.fields.relatedissues", {
                  count: 2,
                })}
                options={issues.map((option) => option.IssueName)}
                selectedValues={listIssues}
                setSelectedValues={setListIssues}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <IppFormButtons
              isEditing={isEditing}
              isAdding={isAdding}
              setIsEditing={setIsEditing}
              resetFunction={() => formik.resetForm()}
              showDelete={true}
              deleteFunction={() => handleDelete()}
              fetchFunc={fetchDeleteChecksByGrievance}
              info={t("strGen:prompts.deletechecks.unlinkitems")}
            />
          </Grid>
        </Grid>
      </form>
    </Root>
  );

  const contactColumns = [
    {
      field: "ContactName",
      title: t("objPlt:objects.contact.name", { count: 2 }),
    },
    {
      field: "AddressedTo",
      title: " ",
      icons: true,
      columnWidth: 50,
    },
    { field: "ContactTitle", title: t("objPlt:objects.contact.fields.title") },
    { field: "PrimaryPhone", title: t("objPlt:objects.contact.fields.phone") },
    {
      field: "Email",
      title: t("objPlt:objects.contact.fields.email"),
      commaDelimited: true,
    },
  ];

  const groupColumns = [
    { field: "GroupName", title: t("objPlt:objects.group.name") },
    {
      field: "OrganizationTypeName",
      title: t("objPlt:objects.group.fields.grouptype"),
    },
    {
      field: "TownCity",
      title: t("objPlt:platformwide.fields.towncity"),
    },
    {
      field: "ProvinceState",
      title: t("objPlt:platformwide.fields.provincestate"),
    },
  ];

  const interactionColumns = [
    {
      field: "CommunicationID",
      title: t("objStk:objects.interaction.sequenceid"),
      format: "C-{0:0}",
      filter: "string",
      columnWidth: 70,
    },
    {
      field: "InteractionTitle",
      title: t("objStk:objects.interaction.fields.title"),
    },
    {
      field: "InteractionTypeName",
      title: t("objStk:objects.interaction.fields.type"),
      icons: true,
      columnWidth: 60,
    },
    {
      field: "InteractionDate",
      title: t("objStk:objects.interaction.fields.date"),
      filter: "date",
      format: "{0:d}",
      columnWidth: 150,
    },
    ...(ft_eng_Sentiment
      ? [
          {
            field: "SentimentLevel",
            title: t("objStk:objects.interaction.fields.sentiment"),
            icons: true,
            columnWidth: 100,
          },
        ]
      : []),
  ];

  const issueColumns = [
    { field: "IssueName", title: t("objStk:objects.issue.name") },
    {
      field: "IssueDescription",
      title: t("objStk:objects.issue.fields.description"),
    },
  ];

  let pageForm =
    clientIsLoading || grievanceInteractionIsLoading ? (
      <LoadingIndicator />
    ) : isAdding ? (
      <Box display="flex" justifyContent="center">
        <Grid container className={classes.editForm} spacing={1}>
          <Grid item xs={12}>
            <Paper className={classes.boxSpace}>{editForm}</Paper>
          </Grid>
        </Grid>
      </Box>
    ) : (
      <Grid container spacing={1}>
        <Grid item xl={4} xs={customBP ? 12 : 5}>
          {isEditing ? (
            <Dialog open={isEditing} fullWidth maxWidth="lg">
              <DialogContent>{editForm}</DialogContent>
            </Dialog>
          ) : (
            <div>{viewForm}</div>
          )}
        </Grid>
        <Grid item xl={8} xs={customBP ? 12 : 7}>
          <Paper>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={activeTab}
                  onChange={handleTabSelect}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                >
                  <Tab
                    value={0}
                    label={`${t("objStk:objects.interaction.name", {
                      count: 2,
                    })} (${grievanceInteractions.length})`}
                  />
                  <Tab
                    value={1}
                    label={`${t("objPlt:objects.group.name", {
                      count: 2,
                    })} (${grievanceGroups.length})`}
                  />
                  <Tab
                    value={2}
                    label={`${t("objPlt:objects.contact.name", {
                      count: 2,
                    })} (${grievanceContacts.length})`}
                  />
                  <Tab
                    value={3}
                    label={`${t("objStk:objects.issue.name", {
                      count: 2,
                    })} (${grievanceIssues.length})`}
                  />
                  <Tab
                    value={4}
                    label={`${t("objPlt:objects.attachment.name", {
                      count: 2,
                    })} (${attachCount.Count})`}
                  />
                </Tabs>
              </Box>
              <IppTabPanel value={activeTab} index={0}>
                <Box>
                  <IppChildInventory
                    title={t("objStk:objects.interaction.name")}
                    parentTitle={t("objStk:objects.grievance.name")}
                    linkURL="communications"
                    tableData={gi}
                    idField="GrievanceInteractionID"
                    nameField="InteractionTitle"
                    parentID={itemData.GrievanceID}
                    parentProjectID={itemData.ProjectID}
                    relatedField="InteractionID"
                    columns={interactionColumns}
                    showAdd={true}
                    showLinkButton={true}
                    tableName="GrievanceInteraction"
                    id="CommunicationList"
                    label={t("objStk:objects.interaction.communicationhistory")}
                    options={interactions}
                    unfilteredOptions={unfilteredInteractions}
                    selectedValues={communication}
                    setSelectedValues={setCommunication}
                    setDidSaveInventory={setDidSaveInventory}
                    parentValue={itemData}
                    showOptions={true}
                    secondLinkCell={1}
                  />
                </Box>
              </IppTabPanel>
              <IppTabPanel value={activeTab} index={1}>
                <Box>
                  <IppChildInventory
                    title={t("objPlt:objects.group.name")}
                    parentTitle={t("objStk:objects.grievance.name")}
                    linkURL="groups"
                    tableData={grievanceGroups}
                    idField="GrievanceGroupID"
                    nameField="GroupName"
                    parentID={itemData.GrievanceID}
                    parentProjectID={itemData.ProjectID}
                    relatedField="GroupID"
                    columns={groupColumns}
                    showEdit={true}
                    tableName="GrievanceGroup"
                    id="GroupList"
                    label={t("objPlt:objects.group.groupsinvolved", {
                      count: 2,
                    })}
                    options={stakeholderGroups}
                    selectedValues={listGroups}
                    setSelectedValues={setListGroups}
                    setDidSaveInventory={setDidSaveInventory}
                    parentValue={null}
                    showOptions={true}
                  />
                </Box>
              </IppTabPanel>
              <IppTabPanel value={activeTab} index={2}>
                <Box>
                  <IppChildInventory
                    title={t("objPlt:objects.contact.name")}
                    parentTitle={t("objStk:objects.grievance.name")}
                    linkURL="contacts"
                    tableData={grievanceContacts}
                    idField="GrievanceContactID"
                    nameField="ContactName"
                    parentID={itemData.GrievanceID}
                    parentProjectID={itemData.ProjectID}
                    relatedField="ContactID"
                    columns={contactColumns}
                    showEdit={true}
                    tableName="GrievanceContact"
                    id="CommunicationList"
                    label={t("objPlt:objects.contact.relatedcontacts", {
                      count: 2,
                    })}
                    options={contacts}
                    selectedValues={selectedContacts}
                    setSelectedValues={setSelectedContacts}
                    setDidSaveInventory={setDidSaveInventory}
                    parentValue={null}
                    showOptions={true}
                  />
                </Box>
              </IppTabPanel>
              <IppTabPanel value={activeTab} index={3}>
                <Box>
                  <IppChildInventory
                    title={t("objStk:objects.issue.name")}
                    parentTitle={t("objStk:objects.grievance.name")}
                    linkURL="issues"
                    tableData={grievanceIssues}
                    idField="GrievanceIssueID"
                    nameField="IssueName"
                    parentID={itemData.GrievanceID}
                    parentProjectID={itemData.ProjectID}
                    relatedField="IssueID"
                    columns={issueColumns}
                    showEdit={true}
                    tableName="GrievanceIssue"
                    id="IssueList"
                    label={t("objStk:objects.issue.relatedissues", {
                      count: 2,
                    })}
                    options={issues}
                    selectedValues={listIssues}
                    setSelectedValues={setListIssues}
                    setDidSaveInventory={setDidSaveInventory}
                    parentValue={null}
                    showOptions={true}
                  />
                </Box>
              </IppTabPanel>
              <IppTabPanel value={activeTab} index={4}>
                <Box>
                  <Grid className={classes.editForm}>
                    <IppAttachmentInventory
                      recordType="Grievances"
                      itemID={itemData.GrievanceID}
                      companyID={formik.values.CompanyID}
                      projectID={formik.values.ProjectID}
                      moduleID={3}
                    />
                  </Grid>
                </Box>
              </IppTabPanel>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    );

  return <Root>{pageForm}</Root>;
};
