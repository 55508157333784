import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  EnTExclusionList,
  EnTExclusionListResult,
  getEnTExclusionList,
} from "api/benefits/entExclusionListAPI";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { push } from "redux-first-history";

interface EnTExclusionListState {
  exclusionListById: Record<number, EnTExclusionList>;
  exclusionList: number[];
  exclusionListIsLoading: boolean;
  exclusionListError: string | null;
}

const EnTExclusionListInitialState: EnTExclusionListState = {
  exclusionListById: {},
  exclusionList: [],
  exclusionListIsLoading: false,
  exclusionListError: null,
};

function startLoading(state: EnTExclusionListState) {
  state.exclusionListIsLoading = true;
}

function loadingFailed(
  state: EnTExclusionListState,
  action: PayloadAction<string>
) {
  state.exclusionListIsLoading = false;
  state.exclusionListError = action.payload;
}

const entExclusionList = createSlice({
  name: "exclusionList",
  initialState: EnTExclusionListInitialState,
  reducers: {
    getExclusionListStart: startLoading,
    getExclusionListSuccess(
      state,
      { payload }: PayloadAction<EnTExclusionListResult>
    ) {
      const { exclusionList } = payload;
      state.exclusionListIsLoading = false;
      state.exclusionListError = null;

      exclusionList.forEach((exclusion: any) => {
        state.exclusionListById[exclusion.EnTExclusionListID] = exclusion;
      });

      state.exclusionList = exclusionList.map(
        (exclusion: any) => exclusion.EnTExclusionListID
      );
    },
    getExclusionListFailure: loadingFailed,
    createExclusionListStart: startLoading,
    createExclusionListSuccess(
      state,
      { payload }: PayloadAction<EnTExclusionList>
    ) {
      const { EnTExclusionListID } = payload;
      state.exclusionListById[EnTExclusionListID] = payload;
      state.exclusionList.push(EnTExclusionListID);

      state.exclusionListIsLoading = false;
      state.exclusionListError = null;
    },
    updateExclusionListStart: startLoading,
    updateExclusionListSuccess(
      state,
      { payload }: PayloadAction<EnTExclusionList>
    ) {
      const { EnTExclusionListID } = payload;
      state.exclusionListById[EnTExclusionListID] = payload;

      state.exclusionListIsLoading = false;
      state.exclusionListError = null;
    },
    deleteExclusionListStart: startLoading,
    deleteExclusionListSuccess(state, { payload }: PayloadAction<number>) {
      const EnTExclusionListID = payload;
      delete state.exclusionListById[EnTExclusionListID];
      state.exclusionList = state.exclusionList.filter(
        (id) => id !== EnTExclusionListID
      );

      state.exclusionListIsLoading = false;
      state.exclusionListError = null;
    },
    deleteExclusionListFailure: loadingFailed,
  },
});

export const {
  getExclusionListStart,
  getExclusionListSuccess,
  getExclusionListFailure,
  /* createExclusionListStart,
  createExclusionListSuccess,
  updateExclusionListStart,
  updateExclusionListSuccess,
  deleteExclusionListStart,
  deleteExclusionListSuccess,
  deleteExclusionListFailure, */
} = entExclusionList.actions;

export default entExclusionList.reducer;

export const fetchEnTExclusionList =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getExclusionListStart());
      const entExclusionList = await getEnTExclusionList(accessToken);
      dispatch(getExclusionListSuccess(entExclusionList));
    } catch (err: any) {
      dispatch(getExclusionListFailure(err.toString()));
    }
  };
