import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  JobPosition,
  JobPositionsResult,
  getJobPositions,
  getJobPositionsByClient,
} from "api/jobPositionAPI";
import { AppThunk } from "app/store";

interface JobPositionState {
  jobPositionsById: Record<number, JobPosition>;
  jobPositionList: number[];
  isLoading: boolean;
  error: string | null;
}

const JobPositionInitialState: JobPositionState = {
  jobPositionsById: {},
  jobPositionList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: JobPositionState) {
  state.isLoading = true;
}

function loadingFailed(state: JobPositionState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const jobPositions = createSlice({
  name: "jobPosition",
  initialState: JobPositionInitialState,
  reducers: {
    getJobPositionsStart: startLoading,
    getJobPositionsSuccess(
      state,
      { payload }: PayloadAction<JobPositionsResult>
    ) {
      const { jobPositions } = payload;
      state.isLoading = false;
      state.error = null;

      jobPositions.forEach((jobPosition) => {
        state.jobPositionsById[jobPosition.JobPositionID] = jobPosition;
      });

      state.jobPositionList = jobPositions.map(
        (jobPosition) => jobPosition.JobPositionID
      );
    },
    getJobPositionsFailure: loadingFailed,
  },
});

export const {
  getJobPositionsStart,
  getJobPositionsSuccess,
  getJobPositionsFailure,
} = jobPositions.actions;

export default jobPositions.reducer;

export const fetchJobPositions =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getJobPositionsStart());
      const jobPositions = await getJobPositions(accessToken);
      //console.log("RETURN", jobPositions);
      dispatch(getJobPositionsSuccess(jobPositions));
    } catch (err: any) {
      dispatch(getJobPositionsFailure(err.toString()));
    }
  };

export const fetchJobPositionsByClient =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getJobPositionsStart());
      const jobPositions = await getJobPositionsByClient(accessToken);
      //console.log("RETURN", jobPositions);
      dispatch(getJobPositionsSuccess(jobPositions));
    } catch (err: any) {
      dispatch(getJobPositionsFailure(err.toString()));
    }
  };
