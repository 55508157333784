import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ScheduledNotificationType,
  ScheduledNotificationTypesResult,
  getScheduledNotificationTypes,
} from "api/scheduledNotificationTypeAPI";
import { AppThunk } from "app/store";

interface ScheduledNotificationTypeState {
  scheduledNotificationTypesById: Record<number, ScheduledNotificationType>;
  scheduledNotificationTypeList: number[];
  isLoading: boolean;
  error: string | null;
}

const ScheduledNotificationTypeInitialState: ScheduledNotificationTypeState = {
  scheduledNotificationTypesById: {},
  scheduledNotificationTypeList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: ScheduledNotificationTypeState) {
  state.isLoading = true;
}

function loadingFailed(
  state: ScheduledNotificationTypeState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const scheduledNotificationTypes = createSlice({
  name: "indigenousgroup",
  initialState: ScheduledNotificationTypeInitialState,
  reducers: {
    getScheduledNotificationTypesStart: startLoading,
    getScheduledNotificationTypesSuccess(
      state,
      { payload }: PayloadAction<ScheduledNotificationTypesResult>
    ) {
      const { scheduledNotificationTypes } = payload;
      // console.log("PAYLOAD", scheduledNotificationTypes)
      state.isLoading = false;
      state.error = null;

      scheduledNotificationTypes.forEach((scheduledNotificationType) => {
        state.scheduledNotificationTypesById[
          scheduledNotificationType.ScheduledNotificationTypeID
        ] = scheduledNotificationType;
      });

      state.scheduledNotificationTypeList = scheduledNotificationTypes.map(
        (scheduledNotificationType) =>
          scheduledNotificationType.ScheduledNotificationTypeID
      );
    },
    getScheduledNotificationTypesFailure: loadingFailed,
  },
});

export const {
  getScheduledNotificationTypesStart,
  getScheduledNotificationTypesSuccess,
  getScheduledNotificationTypesFailure,
} = scheduledNotificationTypes.actions;

export default scheduledNotificationTypes.reducer;

export const fetchScheduledNotificationTypes =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getScheduledNotificationTypesStart());
      const scheduledNotificationTypes = await getScheduledNotificationTypes(
        accessToken
      );
      //console.log("RETURN", scheduledNotificationTypes);
      dispatch(
        getScheduledNotificationTypesSuccess(scheduledNotificationTypes)
      );
    } catch (err: any) {
      dispatch(getScheduledNotificationTypesFailure(err.toString()));
    }
  };
