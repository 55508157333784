import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";

import { UserForm } from "./UserForm";
import LoadingIndicator from "components/LoadingIndicator";

interface UserDetProps {
  companyID: string;
}

export const UserCreatePage = () => {
  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  let userCreateView = clientIsLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <UserForm user={{}} clientId={clientId} />
    </div>
  );

  return <div id="user-detail-page">{userCreateView}</div>;
};
