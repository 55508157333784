import axiosClient from "./axiosClient";

export interface ClientActivityLocation {
  ClientActivityLocationID: number;
  ClientID: number;
  ActivityLocationID: number;
  ActivityLocationName: string;
}

export interface ClientActivityLocationsResult {
  clientActivityLocations: ClientActivityLocation[];
}

export async function getClientActivityLocations(
  accessToken: String
): Promise<ClientActivityLocationsResult> {
  const url = `/clientActivityLocation`;

  try {
    const { data } = await axiosClient.get<ClientActivityLocationsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createClientActivityLocation(
  accessToken: String,
  newClientActivityLocation: Partial<ClientActivityLocation>
): Promise<ClientActivityLocation> {
  const url = `/ClientActivityLocation`;

  try {
    const { data } = await axiosClient.post<ClientActivityLocation>(
      url,
      newClientActivityLocation,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateClientActivityLocation(
  accessToken: String,
  ClientActivityLocationID: number,
  newClientActivityLocation: Partial<ClientActivityLocation>
): Promise<ClientActivityLocation> {
  const url = `/ClientActivityLocation/` + ClientActivityLocationID;

  try {
    const { data } = await axiosClient.post<ClientActivityLocation>(
      url,
      newClientActivityLocation,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteClientActivityLocation(
  accessToken: String,
  ClientActivityLocationID: number
): Promise<string> {
  const url = `/ClientActivityLocation/` + ClientActivityLocationID;
  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}
