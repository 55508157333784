import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  VendorActiveEmployees,
  VendorActiveEmployeesByCategory,
  VendorActiveEmployeesByCategoryResult,
  VendorActiveEmployeesByGender,
  VendorActiveEmployeesByGenderResult,
  VendorActiveEmployeesResult,
  getVendorActiveEmployees,
  getVendorActiveEmployeesByCategory,
  getVendorActiveEmployeesByGender,
} from "api/benefits/vendorInsights/vendorActiveEmployeesAPI";
import { AppThunk } from "app/store";

interface VendorActiveEmployeesState {
  vendorActiveEmployeessById: Record<number, VendorActiveEmployees>;
  vendorActiveEmployeesList: number[];
  vendorActiveEmployeesByCategoryById: Record<
    number,
    VendorActiveEmployeesByCategory
  >;
  vendorActiveEmployeesByCategoryList: number[];
  vendorActiveEmployeesByGenderById: Record<
    number,
    VendorActiveEmployeesByGender
  >;
  vendorActiveEmployeesByGenderList: number[];
  isLoading: boolean;
  error: string | null;
}

const VendorActiveEmployeesInitialState: VendorActiveEmployeesState = {
  vendorActiveEmployeessById: {},
  vendorActiveEmployeesList: [],
  vendorActiveEmployeesByCategoryById: {},
  vendorActiveEmployeesByCategoryList: [],
  vendorActiveEmployeesByGenderById: {},
  vendorActiveEmployeesByGenderList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: VendorActiveEmployeesState) {
  state.isLoading = true;
}

function loadingFailed(
  state: VendorActiveEmployeesState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const vendorActiveEmployeess = createSlice({
  name: "vendorActiveEmployees",
  initialState: VendorActiveEmployeesInitialState,
  reducers: {
    getVendorActiveEmployeessStart: startLoading,
    getVendorActiveEmployeessSuccess(
      state,
      { payload }: PayloadAction<VendorActiveEmployeesResult>
    ) {
      const { vendorActiveEmployees } = payload;
      state.isLoading = false;
      state.error = null;

      vendorActiveEmployees.forEach((vendorActiveEmployees) => {
        state.vendorActiveEmployeessById[
          vendorActiveEmployees.IndigenousGroupID
        ] = vendorActiveEmployees;
      });

      state.vendorActiveEmployeesList = vendorActiveEmployees.map(
        (vendorActiveEmployees) => vendorActiveEmployees.IndigenousGroupID
      );
    },
    getVendorActiveEmployeesByCategorySuccess(
      state,
      { payload }: PayloadAction<VendorActiveEmployeesByCategoryResult>
    ) {
      const { vendorActiveEmployeesByCategory } = payload;
      state.isLoading = false;
      state.error = null;

      vendorActiveEmployeesByCategory.forEach((vendorActiveEmployees) => {
        state.vendorActiveEmployeesByCategoryById[
          vendorActiveEmployees.CustomEmpCategoryID
        ] = vendorActiveEmployees;
      });

      state.vendorActiveEmployeesByCategoryList =
        vendorActiveEmployeesByCategory.map(
          (vendorActiveEmployees) => vendorActiveEmployees.CustomEmpCategoryID
        );
    },
    getVendorActiveEmployeesByGenderSuccess(
      state,
      { payload }: PayloadAction<VendorActiveEmployeesByGenderResult>
    ) {
      const { vendorActiveEmployeesByGender } = payload;
      state.isLoading = false;
      state.error = null;

      vendorActiveEmployeesByGender.forEach((vendorActiveEmployees) => {
        state.vendorActiveEmployeesByGenderById[
          vendorActiveEmployees.DisciplineID
        ] = vendorActiveEmployees;
      });

      state.vendorActiveEmployeesByGenderList =
        vendorActiveEmployeesByGender.map(
          (vendorActiveEmployees) => vendorActiveEmployees.DisciplineID
        );
    },
    getVendorActiveEmployeessFailure: loadingFailed,
  },
});

export const {
  getVendorActiveEmployeessStart,
  getVendorActiveEmployeessSuccess,
  getVendorActiveEmployeesByCategorySuccess,
  getVendorActiveEmployeessFailure,
  getVendorActiveEmployeesByGenderSuccess,
} = vendorActiveEmployeess.actions;

export default vendorActiveEmployeess.reducer;

export const fetchVendorActiveEmployeess =
  (
    accessToken: String,
    startDate: Date,
    endDate: Date,
    regionID: number,
    projectID: number
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getVendorActiveEmployeessStart());
      const vendorActiveEmployeess = await getVendorActiveEmployees(
        accessToken,
        startDate,
        endDate,
        regionID,
        projectID
      );
      dispatch(getVendorActiveEmployeessSuccess(vendorActiveEmployeess));
    } catch (err: any) {
      dispatch(getVendorActiveEmployeessFailure(err.toString()));
    }
  };

export const fetchVendorActiveEmployeesByCategory =
  (
    accessToken: String,
    startDate: Date,
    endDate: Date,
    regionID: number,
    projectID: number
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getVendorActiveEmployeessStart());
      const vendorActiveEmployeesByCategory =
        await getVendorActiveEmployeesByCategory(
          accessToken,
          startDate,
          endDate,
          regionID,
          projectID
        );
      dispatch(
        getVendorActiveEmployeesByCategorySuccess(
          vendorActiveEmployeesByCategory
        )
      );
    } catch (err: any) {
      dispatch(getVendorActiveEmployeessFailure(err.toString()));
    }
  };

export const fetchVendorActiveEmployeesByGender =
  (
    accessToken: String,
    startDate: Date,
    endDate: Date,
    regionID: number,
    projectID: number
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getVendorActiveEmployeessStart());
      const vendorActiveEmployeesByGender =
        await getVendorActiveEmployeesByGender(
          accessToken,
          startDate,
          endDate,
          regionID,
          projectID
        );
      dispatch(
        getVendorActiveEmployeesByGenderSuccess(vendorActiveEmployeesByGender)
      );
    } catch (err: any) {
      dispatch(getVendorActiveEmployeessFailure(err.toString()));
    }
  };
