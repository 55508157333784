import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  EngagementLevel,
  EngagementLevelsResult,
  getEngagementLevels,
} from "api/stakeholder/charts/engagementLevelAPI";
import { AppThunk } from "app/store";

interface EngagementLevelState {
  engagementLevelsById: Record<number, EngagementLevel>;
  engagementLevelList: number[];
  isLoading: boolean;
  error: string | null;
}

const EngagementLevelInitialState: EngagementLevelState = {
  engagementLevelsById: {},
  engagementLevelList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: EngagementLevelState) {
  state.isLoading = true;
}

function loadingFailed(
  state: EngagementLevelState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const engagementLevels = createSlice({
  name: "engagementLevels",
  initialState: EngagementLevelInitialState,
  reducers: {
    getEngagementLevelsStart: startLoading,
    getEngagementLevelsSuccess(
      state,
      { payload }: PayloadAction<EngagementLevelsResult>
    ) {
      const { engagementLevels } = payload;
      state.isLoading = false;
      state.error = null;

      engagementLevels.forEach((item) => {
        state.engagementLevelsById[item.MonthSequenceID] = item;
      });

      state.engagementLevelList = engagementLevels.map(
        (item) => item.MonthSequenceID
      );
    },
    getEngagementLevelsFailure: loadingFailed,
  },
});

export const {
  getEngagementLevelsStart,
  getEngagementLevelsSuccess,
  getEngagementLevelsFailure,
} = engagementLevels.actions;

export default engagementLevels.reducer;

export const fetchEngagementLevels =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getEngagementLevelsStart());
      const engagementLevels = await getEngagementLevels(accessToken);
      // console.log("RETURN", engagementLevels);
      dispatch(getEngagementLevelsSuccess(engagementLevels));
    } catch (err: any) {
      dispatch(getEngagementLevelsFailure(err.toString()));
    }
  };
