import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  JobHistory,
  JobHistoryResult,
  createJobHistory,
  deleteJobHistory,
  getJobHistoryByEmployee,
  updateJobHistory,
} from "api/benefits/jobHistoryAPI";
import * as Constants from "utils/snackBarConstants";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface JobHistoryState {
  jobHistoryById: Record<number, JobHistory>;
  jobHistoryList: number[];
  isLoading: boolean;
  error: string | null;
}

const JobHistoryInitialState: JobHistoryState = {
  jobHistoryById: {},
  jobHistoryList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: JobHistoryState) {
  state.isLoading = true;
}

function loadingFailed(state: JobHistoryState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const jobHistory = createSlice({
  name: "jobHistory",
  initialState: JobHistoryInitialState,
  reducers: {
    getJobHistoryStart: startLoading,
    getJobHistorySuccess(state, { payload }: PayloadAction<JobHistoryResult>) {
      const { jobHistory } = payload;
      state.isLoading = false;
      state.error = null;

      jobHistory.forEach((jobHistory) => {
        state.jobHistoryById[jobHistory.JobHistoryID] = jobHistory;
      });

      state.jobHistoryList = jobHistory.map(
        (jobHistory) => jobHistory.JobHistoryID
      );
    },
    getJobHistoryFailure: loadingFailed,
    createJobHistoryStart: startLoading,
    createJobHistorySuccess(state, { payload }: PayloadAction<JobHistory>) {
      const { JobHistoryID } = payload;
      state.jobHistoryById[JobHistoryID] = payload;
      state.jobHistoryList.push(JobHistoryID);

      state.isLoading = false;
      state.error = null;
    },
    createJobHistoryFailure: loadingFailed,
    updateJobHistoryStart: startLoading,
    updateJobHistorySuccess(state, { payload }: PayloadAction<JobHistory>) {
      const { JobHistoryID } = payload;
      state.jobHistoryById[JobHistoryID] = payload;

      state.isLoading = false;
      state.error = null;
    },
    updateJobHistoryFailure: loadingFailed,
    deleteJobHistoryStart: startLoading,
    deleteJobHistorySuccess(state, { payload }: PayloadAction<number>) {
      const JobHistoryID = payload;
      delete state.jobHistoryById[JobHistoryID];
      state.jobHistoryList = state.jobHistoryList.filter(
        (item) => item !== JobHistoryID
      );

      state.isLoading = false;
      state.error = null;
    },
    deleteJobHistoryFailure: loadingFailed,
  },
});

export const {
  getJobHistoryStart,
  getJobHistorySuccess,
  getJobHistoryFailure,
  createJobHistoryStart,
  createJobHistorySuccess,
  createJobHistoryFailure,
  updateJobHistoryStart,
  updateJobHistorySuccess,
  updateJobHistoryFailure,
  deleteJobHistoryStart,
  deleteJobHistorySuccess,
  deleteJobHistoryFailure,
} = jobHistory.actions;

export default jobHistory.reducer;

export const fetchJobHistoryByEmployee =
  (accessToken: String, employeeID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getJobHistoryStart());
      const jobHistory = await getJobHistoryByEmployee(accessToken, employeeID);
      dispatch(getJobHistorySuccess(jobHistory));
    } catch (err: any) {
      dispatch(getJobHistoryFailure(err.toString()));
    }
  };

export const addJobHistory =
  (accessToken: String, jobHistory: JobHistory): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createJobHistoryStart());
      const newJobHistory = await createJobHistory(accessToken, jobHistory);
      dispatch(createJobHistorySuccess(newJobHistory));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(fetchJobHistoryByEmployee(accessToken, jobHistory.EmployeeID));
    } catch (err: any) {
      dispatch(createJobHistoryFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updJobHistory =
  (accessToken: String, jobHistory: JobHistory): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateJobHistoryStart());
      const newJobHistory = await updateJobHistory(accessToken, jobHistory);
      dispatch(updateJobHistorySuccess(newJobHistory));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(fetchJobHistoryByEmployee(accessToken, jobHistory.EmployeeID));
    } catch (err: any) {
      dispatch(updateJobHistoryFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delJobHistory =
  (accessToken: String, jobHistoryID: number, employeeID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteJobHistoryStart());
      await deleteJobHistory(accessToken, jobHistoryID);
      dispatch(deleteJobHistorySuccess(jobHistoryID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
      dispatch(fetchJobHistoryByEmployee(accessToken, employeeID));
    } catch (err: any) {
      dispatch(deleteJobHistoryFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };
