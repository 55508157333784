import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Region,
  RegionsResult,
  getAllRegions,
  createRegion,
  updateRegion,
  deleteRegion,
  getRegionsByRecordType,
  RegionRecordType,
} from "api/regionAPI";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { push } from "redux-first-history";

interface RegionState {
  regionsById: Record<number, Region>;
  regionList: number[];
  regionIsLoading: boolean;
  regionError: string | null;
}

const RegionInitialState: RegionState = {
  regionsById: {},
  regionList: [],
  regionIsLoading: false,
  regionError: null,
};

function startLoading(state: RegionState) {
  state.regionIsLoading = true;
}

function loadingFailed(state: RegionState, action: PayloadAction<string>) {
  state.regionIsLoading = false;
  state.regionError = action.payload;
}

const regions = createSlice({
  name: "regions",
  initialState: RegionInitialState,
  reducers: {
    getRegionsStart: startLoading,
    getRegionsSuccess(state, { payload }: PayloadAction<RegionsResult>) {
      const { regions } = payload;
      state.regionIsLoading = false;
      state.regionError = null;

      regions.forEach((region: any) => {
        state.regionsById[region.RegionID] = region;
      });

      state.regionList = regions.map((region: any) => region.RegionID);
    },
    getRegionsFailure: loadingFailed,
    createRegionStart: startLoading,
    createRegionSuccess(state, { payload }: PayloadAction<Region>) {
      const { RegionID } = payload;
      state.regionsById[RegionID] = payload;
      state.regionList.push(RegionID);

      state.regionIsLoading = false;
      state.regionError = null;
    },
    createRegionFailure: loadingFailed,
    updateRegionStart: startLoading,
    updateRegionSuccess(state, { payload }: PayloadAction<Region>) {
      const { RegionID } = payload;
      state.regionsById[RegionID] = payload;

      state.regionIsLoading = false;
      state.regionError = null;
    },
    updateRegionFailure: loadingFailed,
    deleteRegionStart: startLoading,
    deleteRegionSuccess(state, { payload }: PayloadAction<number>) {
      const RegionID = payload;
      delete state.regionsById[RegionID];
      state.regionList = state.regionList.filter((item) => item !== RegionID);

      state.regionIsLoading = false;
      state.regionError = null;
    },
    deleteRegionFailure: loadingFailed,
  },
});

export const {
  getRegionsStart,
  getRegionsSuccess,
  getRegionsFailure,
  createRegionStart,
  createRegionSuccess,
  createRegionFailure,
  updateRegionStart,
  updateRegionSuccess,
  updateRegionFailure,
  deleteRegionStart,
  deleteRegionSuccess,
  deleteRegionFailure,
} = regions.actions;

export default regions.reducer;

export const fetchAllRegions =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getRegionsStart());
      const regions = await getAllRegions(accessToken);
      dispatch(getRegionsSuccess(regions));
    } catch (err: any) {
      dispatch(getRegionsFailure(err.toString()));
    }
  };

export const fetchRegionsByRecordType =
  (accessToken: string, recordType: RegionRecordType): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getRegionsStart());
      const regions = await getRegionsByRecordType(accessToken, recordType);
      dispatch(getRegionsSuccess(regions));
    } catch (err: any) {
      dispatch(getRegionsFailure(err.toString()));
    }
  };

export const addRegion =
  (accessToken: String, newRegion: Partial<Region>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createRegionStart());
      const region = await createRegion(accessToken, newRegion);
      dispatch(createRegionSuccess(region));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push("/admin/regions"));
    } catch (err: any) {
      dispatch(createRegionFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updRegion =
  (
    accessToken: String,
    regionID: number,
    updatedRegion: Partial<Region>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateRegionStart());
      const region = await updateRegion(accessToken, regionID, updatedRegion);
      dispatch(updateRegionSuccess(region));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push("/admin/regions"));
    } catch (err: any) {
      dispatch(updateRegionFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delRegion =
  (accessToken: String, regionID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteRegionStart());
      await deleteRegion(accessToken, regionID);
      dispatch(deleteRegionSuccess(regionID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(deleteRegionFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };
