import axiosClient from "./axiosClient";

export interface PaymentPeriodException {
  CompanyID: number;
  CompanyName: string;
  ProjectName: string;
  LastReport: string;
}

export interface PaymentPeriodExceptionsResult {
  paymentPeriodExceptions: PaymentPeriodException[];
}

export async function getPaymentPeriodExceptions(
  accessToken: String
): Promise<PaymentPeriodExceptionsResult> {
  const url = `/paymentPeriodException`;
  try {
    const { data } = await axiosClient.get<PaymentPeriodExceptionsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
