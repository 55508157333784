import {
  Dialog,
  TextField,
  DialogContent,
  Typography,
  Autocomplete,
  Grid,
  Box,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { useState } from "react";
import { createCustomAutoCompleteFilter } from "utils/customFilters";
import { IppSaveButton } from "./Buttons/IppSaveButton";
import { IppCancelButton } from "./Buttons/IppCancelButton";
import { useTypedTranslation } from "utils/customHooks";

interface ippChildLinkProps {
  title: string; // title to show on sub page
  parentTitle: string; // title to show for container page
  parentID: number; // id value for parent record
  isOpen: boolean; // whether dialog is open or not
  closeAction: any; // action to close dialog
  tableName: string; // name of underlying table being edited
  id: string;
  label: string;
  options: any;
  selectedValues: any;
  setSelectedValues: any;
  setDidSaveInventory: any;
}

export const IppChildLink = (props: ippChildLinkProps) => {
  const dispatch = useDispatch();
  const t = useTypedTranslation(["strGen"]);
  const [newItem, setNewItem] = useState();
  const [didSaveChild, setDidSaveChild] = useState(false);

  const {
    title,
    parentTitle,
    isOpen,
    closeAction,
    id,
    label,
    options, //Array of objects with extra object.DisplayValue String
    setSelectedValues,
    setDidSaveInventory,
  } = props;

  const handleClose = (event: any, reason: any) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      dispatch(
        openSnackBar(t("strGen:components.snackbar.saveorcancel"), "info")
      );
    } else {
      // form was closed using either Save or Cancel
      closeAction();
    }
  };

  const handleLinking = () => {
    setSelectedValues(newItem);
    setDidSaveChild(false);
    setDidSaveInventory(true);
  };

  // Custom filter logic - normalize input and compare to options
  const customFilter = createCustomAutoCompleteFilter(
    (option: any) => option.DisplayValue
  );

  let linkForm = (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6">
          {t("strGen:components.ippchildlink.title", {
            title: title,
            parenttitle: parentTitle,
          })}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          {t("strGen:components.ippchildlink.body", {
            title: title,
            parenttitle: parentTitle,
          })}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          id={id}
          options={options}
          filterOptions={customFilter}
          getOptionLabel={(option: any) => option.DisplayValue}
          value={newItem}
          handleHomeEndKeys={true}
          onChange={(_: any, newValue: any | null) => {
            if (newValue) {
              setNewItem(newValue);
            }
          }}
          //onInputChange={handleInputChange}
          clearOnEscape
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              InputLabelProps={{ shrink: true }}
            />
          )}
          renderOption={(props, option: any) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              {option.DisplayValue}
            </Box>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <IppSaveButton handleSave={handleLinking} />
        <IppCancelButton onClick={closeAction} />
      </Grid>
    </Grid>
  );

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md">
        <DialogContent>
          <div>{linkForm}</div>
        </DialogContent>
      </Dialog>
    </>
  );
};
