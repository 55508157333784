import { Edit } from "@mui/icons-material";
import {
  Divider,
  Grid,
  IconButton,
  Typography,
  Chip,
  Box,
  Tooltip,
} from "@mui/material";
import { IppDisplayField } from "./IppDisplayField";

interface IppDisplayChipProps {
  label?: string | null;
  value: any;
  isEditing: boolean;
  setIsEditing: ((isEditing: boolean) => void) | null;
  showFooter?: boolean;
  showEdit?: boolean;
  showLabel?: boolean;
  isJson?: boolean; // if value string contains ',', use Json to pass the value
  noChipPlaceholder?: string;
  statusChip?: (status: string) => JSX.Element;
}

export const IppDisplayChip = (props: IppDisplayChipProps) => {
  const {
    label,
    value,
    isEditing,
    setIsEditing,
    showFooter,
    showEdit,
    showLabel,
    isJson = false,
    noChipPlaceholder,
    statusChip,
  } = props;

  const editChange = () => {
    if (setIsEditing) setIsEditing(!isEditing);
  };

  const valueArray = (
    value ? (isJson ? JSON.parse(value) : value.split(",")) : []
  )
    .map((val: string) => val.trim())
    .filter((val: string) => val !== "");

  return noChipPlaceholder ? (
    <IppDisplayField
      label={label}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      value={noChipPlaceholder}
      showEdit={showEdit}
      disabled={true}
    />
  ) : (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          {showLabel && (
            <Typography
              noWrap={true}
              variant="caption"
              sx={{ color: "text.secondary" }}
            >
              {label}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs
          container
          sx={{
            alignItems: "center",
          }}
        >
          <Grid item xs={10}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
                alignItems: "center",
              }}
            >
              {
                // Render chips for other values
                valueArray.map((val: string) =>
                  statusChip ? (
                    statusChip(val)
                  ) : (
                    <Tooltip key={val} title={val} placement="top">
                      <Chip
                        label={val}
                        size="small"
                        variant={statusChip ? "filled" : "outlined"}
                      />
                    </Tooltip>
                  )
                )
              }
            </Box>
          </Grid>
          {showEdit && setIsEditing ? (
            <Grid item xs={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <IconButton onClick={editChange} size="small">
                  <Edit fontSize="inherit" />
                </IconButton>
              </Box>
            </Grid>
          ) : (
            <div style={{ height: 28 }}></div> // Placeholder for consistent spacing
          )}
        </Grid>
      </Grid>

      {showFooter && <Divider />}
    </div>
  );
};

IppDisplayChip.defaultProps = {
  showFooter: true,
  showEdit: true,
  showLabel: true,
};
