import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  GrievanceStatus,
  GrievanceStatusResult,
  getGrievanceStatus,
} from "api/stakeholder/grievanceStatusAPI";
import { AppThunk } from "app/store";

interface GrievanceStatusState {
  grievanceStatusesById: Record<number, GrievanceStatus>;
  grievanceStatusList: number[];
  isLoading: boolean;
  error: string | null;
}

const GrievanceStatusInitialState: GrievanceStatusState = {
  grievanceStatusesById: {},
  grievanceStatusList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: GrievanceStatusState) {
  state.isLoading = true;
}

function loadingFailed(
  state: GrievanceStatusState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const grievanceStatuses = createSlice({
  name: "grievanceStatus",
  initialState: GrievanceStatusInitialState,
  reducers: {
    getGrievanceStatusesStart: startLoading,
    getGrievanceStatusesSuccess(
      state,
      { payload }: PayloadAction<GrievanceStatusResult>
    ) {
      const { grievanceStatuses } = payload;
      state.isLoading = false;
      state.error = null;

      grievanceStatuses.forEach((grievanceStatus) => {
        state.grievanceStatusesById[grievanceStatus.GrievanceStatusID] =
          grievanceStatus;
      });

      state.grievanceStatusList = grievanceStatuses.map(
        (grievanceStatus) => grievanceStatus.GrievanceStatusID
      );
    },
    getGrievanceStatusesFailure: loadingFailed,
  },
});

export const {
  getGrievanceStatusesStart,
  getGrievanceStatusesSuccess,
  getGrievanceStatusesFailure,
} = grievanceStatuses.actions;

export default grievanceStatuses.reducer;

export const fetchGrievanceStatuses =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getGrievanceStatusesStart());
      const grievanceStatuses = await getGrievanceStatus(accessToken);

      dispatch(getGrievanceStatusesSuccess(grievanceStatuses));
    } catch (err: any) {
      dispatch(getGrievanceStatusesFailure(err.toString()));
    }
  };
