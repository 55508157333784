import * as yup from "yup";

export const ThreadValidationSchema = (t: any) =>
  yup.object({
    ThreadTitle: yup
      .string()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.thread.fields.title"),
        })
      )
      .max(
        250,
        t("strGen:validation.max", {
          fieldname: t("objStk:objects.thread.fields.title"),
          count: 250,
        })
      ),
    ProjectID: yup
      .number()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.thread.fields.project"),
        })
      )
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.thread.fields.project"),
        })
      ),
    Summary: yup.string().nullable(),
  });
