import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  FeedbackByGroup,
  FeedbackByGroupsResult,
  getFeedbackByGroups,
} from "api/stakeholder/logTables/feedbackByGroupAPI";
import { AppThunk } from "app/store";

interface FeedbackByGroupState {
  feedbackByGroupsById: Record<number, FeedbackByGroup>;
  feedbackByGroupList: number[];
  isLoading: boolean;
  error: string | null;
}

const FeedbackByGroupInitialState: FeedbackByGroupState = {
  feedbackByGroupsById: {},
  feedbackByGroupList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: FeedbackByGroupState) {
  state.isLoading = true;
}

function loadingFailed(
  state: FeedbackByGroupState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const feedbackByGroups = createSlice({
  name: "feedbackByGroup",
  initialState: FeedbackByGroupInitialState,
  reducers: {
    getFeedbackByGroupsStart: startLoading,
    getFeedbackByGroupsSuccess(
      state,
      { payload }: PayloadAction<FeedbackByGroupsResult>
    ) {
      const { feedbackByGroups } = payload;
      state.isLoading = false;
      state.error = null;

      feedbackByGroups.forEach((item) => {
        state.feedbackByGroupsById[item.InteractionCommentID] = item;
      });

      state.feedbackByGroupList = feedbackByGroups.map(
        (item) => item.InteractionCommentID
      );
    },
    getFeedbackByGroupsFailure: loadingFailed,
  },
});

export const {
  getFeedbackByGroupsStart,
  getFeedbackByGroupsSuccess,
  getFeedbackByGroupsFailure,
} = feedbackByGroups.actions;

export default feedbackByGroups.reducer;

export const fetchFeedbackByGroups =
  (accessToken: String, projectID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getFeedbackByGroupsStart());
      const feedbackByGroups = await getFeedbackByGroups(
        accessToken,
        projectID
      );
      dispatch(getFeedbackByGroupsSuccess(feedbackByGroups));
    } catch (err: any) {
      dispatch(getFeedbackByGroupsFailure(err.toString()));
    }
  };
