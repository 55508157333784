import { Trans } from "react-i18next";
import {
  ConstructedErrors,
  GenericErrorTitlesObject,
} from "utils/types/index.types";
import {
  generateErrorTitle,
  getGenericConstructedErrors,
  getGenericConstructedWarnings,
} from "utils/uploadUtils";

type PaymentChildErrorTitles = GenericErrorTitlesObject & {
  invalidRegion: (count: number) => JSX.Element | "";
  newCompanies: (count: number) => JSX.Element | "";
};

export const generatePaymentChildErrorTitles: PaymentChildErrorTitles = {
  ...generateErrorTitle,
  invalidRegion: (count: number) =>
    count > 0 ? (
      <Trans
        i18nKey="strGen:uploaderrors.errors.payment.invalidregion.title"
        count={count}
      />
    ) : (
      ""
    ),
  newCompanies: (count: number) =>
    count > 0 ? (
      <Trans
        i18nKey="strGen:uploaderrors.errors.payment.newcompanies.title"
        count={count}
      />
    ) : (
      ""
    ),
  potentialDuplicate: (count: number) =>
    count > 0 ? (
      <Trans i18nKey="strGen:uploaderrors.errors.payment.potentialduplicate.title" />
    ) : (
      ""
    ),
};

export const getNewPmtChildConstructedErrors: () => ConstructedErrors = () => ({
  invalidRegion: {
    count: 0,
    get message() {
      return this.count > 0
        ? `To continue with this upload, all new companies must contain a valid Region. The following ${
            this.count === 1 ? "Region does" : "Regions do"
          } not exist in the platform. If they should exist, please contact your administrator. Otherwise, please review the appropriate rows and update the ${
            this.count === 1 ? "Region" : "Regions"
          }.`
        : "";
    },
    childList: [],
  },
  ...getGenericConstructedErrors(),
});

export const getNewPmtChildConstructedWarnings: () => ConstructedErrors =
  () => ({
    newCompanies: {
      count: 0,
      get message() {
        return this.count > 0
          ? `If you continue with this upload,
            ${
              this.count === 1 ? "this Company" : "these Companies"
            } will be sent to the platform administrator for approval.`
          : "";
      },
      childList: [],
    },
    potentialDuplicate: {
      count: 0,
      get message() {
        return this.count > 0 ? (
          <>
            This upload appears to be a duplicate; there
            {this.count === 1
              ? " is at least 1 item that is identical to an already existing spend on this report. "
              : ` are at least ${this.count} items that are identical to already existing spends on this report. `}
            Please ensure you are uploading the correct file before proceeding.
          </>
        ) : (
          ""
        );
      },
      list: [],
    },
    ...getGenericConstructedWarnings(),
  });
