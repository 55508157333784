import {
  GridColumnMenuFilter,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuProps,
} from "@progress/kendo-react-grid";
import "./styles.css";

export const ColumnMenu = (props: GridColumnMenuProps, field: string) => {
  return (
    <div className="active1">
      <GridColumnMenuFilter
        {...props}
        expanded={true}
        hideSecondFilter={true}
      />
    </div>
  );
};

export const CheckboxFilter = (props: any) => {
  return (
    <GridColumnMenuCheckboxFilter
      {...props}
      data={props.data}
      expanded={true}
      //searchBox={() => null}
    />
  );
};

export const CommaDelimitedCheckboxFilter = (props: any) => {
  let checkboxOptions: any[] = [];

  let unFilteredOptions = props.data.map((e: any) => e[props.column.field]);

  //Iterate the unfiltered options, split the string by comma.
  for (const option of unFilteredOptions) {
    if (option) {
      let splitOptions = option
        .toString()
        .split(",")
        .map((item: any) => item.trim());

      //Iterate separated options and push to array if they are unique.
      for (const splitOption of splitOptions) {
        if (!checkboxOptions.includes(splitOption)) {
          let objec: any = {};
          objec[`${props.column.field}`] = splitOption;

          checkboxOptions.push(objec);
        }
      }
    }
  }

  //Sort options alphabetically and ignoring case.
  checkboxOptions.sort(function (a, b) {
    let x = a[`${props.column.field}`].toLowerCase();
    let y = b[`${props.column.field}`].toLowerCase();

    if (x < y) {
      return -1;
    }

    if (y < x) {
      return 1;
    }

    return 0;
  });

  return (
    <GridColumnMenuCheckboxFilter
      {...props}
      data={checkboxOptions}
      expanded={true}
      //searchBox={() => null}
    />
  );
};
