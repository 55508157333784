import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  Phase,
  PhasesResult,
  getPhases,
  createPhase,
  updatePhase,
  deletePhase,
} from "api/dataList/phaseAPI";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { push } from "redux-first-history";

interface PhaseState {
  phasesById: Record<number, Phase>;
  phaseList: number[];
  isLoading: boolean;
  error: string | null;
}

const PhaseInitialState: PhaseState = {
  phasesById: {},
  phaseList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: PhaseState) {
  state.isLoading = true;
}

function loadingFailed(state: PhaseState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const phases = createSlice({
  name: "phases",
  initialState: PhaseInitialState,
  reducers: {
    getPhasesStart: startLoading,
    getPhasesSuccess(state, { payload }: PayloadAction<PhasesResult>) {
      const { phases } = payload;
      state.isLoading = false;
      state.error = null;

      phases.forEach((phase) => {
        state.phasesById[phase.PhaseID] = phase;
      });

      state.phaseList = phases.map((phase) => phase.PhaseID);
    },
    getPhasesFailure: loadingFailed,
    createPhaseStart: startLoading,
    createPhaseSuccess(state, { payload }: PayloadAction<Phase>) {
      const { PhaseID } = payload;
      state.phasesById[PhaseID] = payload;
      state.phaseList.push(PhaseID);

      state.isLoading = false;
      state.error = null;
    },
    updatePhaseSuccess(state, { payload }: PayloadAction<Phase>) {
      const { PhaseID } = payload;
      state.phasesById[PhaseID] = payload;
      state.phaseList.push(PhaseID);

      state.isLoading = false;
      state.error = null;
    },
    deletePhaseSuccess(state, { payload }: PayloadAction<number>) {
      const PhaseID = payload;
      delete state.phasesById[PhaseID];
      state.phaseList = state.phaseList.filter((item) => item !== PhaseID);

      state.isLoading = false;
      state.error = null;
    },
    createPhaseFailure: loadingFailed,
  },
});

export const {
  getPhasesStart,
  getPhasesSuccess,
  getPhasesFailure,
  createPhaseStart,
  createPhaseSuccess,
  updatePhaseSuccess,
  deletePhaseSuccess,
  createPhaseFailure,
} = phases.actions;

export default phases.reducer;

export const fetchPhases =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getPhasesStart());
      const phases = await getPhases(accessToken);
      //console.log("Slice", phases);
      dispatch(getPhasesSuccess(phases));
    } catch (err: any) {
      dispatch(getPhasesFailure(err.toString()));
    }
  };

export const addPhase =
  (accessToken: String, newPhase: Partial<Phase>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createPhaseStart());
      //console.log("Phase Slice", newPhase);
      const phase = await createPhase(accessToken, newPhase);
      dispatch(createPhaseSuccess(phase));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push(`/admin/phases`));
    } catch (err: any) {
      dispatch(createPhaseFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updPhase =
  (accessToken: String, phaseID: number, newPhase: Partial<Phase>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createPhaseStart());
      const phase = await updatePhase(accessToken, phaseID, newPhase);
      dispatch(updatePhaseSuccess(phase));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push("/admin/phases"));
    } catch (err: any) {
      dispatch(createPhaseFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delPhase =
  (accessToken: String, phaseID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createPhaseStart());
      const result = await deletePhase(accessToken, phaseID);
      dispatch(deletePhaseSuccess(phaseID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
      //dispatch(push("/benefits/phases/"));
    } catch (err: any) {
      dispatch(createPhaseFailure(err.toString()));
    }
  };
