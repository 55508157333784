import { useMediaQuery } from "@mui/material";

/**
 * Checks if the screen size is medium small (max-width: 1075px).
 * @returns {boolean} True if the screen size is medium small, false otherwise.
 */
export const IsMedSmall = () => useMediaQuery("(max-width:1075px)");

/**
 * Checks if the screen size is medium large (max-width: 1250px).
 * @returns {boolean} True if the screen size is medium large, false otherwise.
 */
export const IsMedLg = () => useMediaQuery("(max-width:1250px)");

/**
 * Checks if the screen size is set to a specific maximum width.
 * @param {number} maxPx - The maximum width in pixels.
 * @returns {boolean} True if the screen size is set to the specified maximum width, false otherwise.
 */
export const IsSetPx = (maxPx: number) =>
  useMediaQuery(`(max-width:${maxPx}px)`);

/**
 * Checks if the screen size is between 1075px and 1575px.
 * @returns {boolean} True if the screen size is between 1075px and 1575px, false otherwise.
 */
export const IsBetween1075and1575 = () =>
  useMediaQuery("(min-width:1075px) and (max-width:1575px)");

/**
 * Checks if the screen size is extra large (min-width: 1575px).
 * @returns {boolean} True if the screen size is extra large, false otherwise.
 */
export const IsExtraLargeScreen = () => useMediaQuery("(min-width:1575px)");
