import React from "react";
import {
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  TextFieldProps,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { markRequired } from "utils/functions";
import { NumericFormat, NumericFormatProps } from "react-number-format";

interface ippPercentFieldProps {
  id: string;
  label: string;
  value: any;
  onChangeFunction: (event: { target: { name: string; value: any } }) => void;
  touchedExpression?: any;
  errorsExpression?: any;
  isEditing: boolean;
  setIsEditing: any;
  defaultValue?: any;
  placeholder?: string;
  required?: boolean;
  toolTip?: string;
  disabled?: boolean;
}

export const IppPercentField = (props: ippPercentFieldProps) => {
  const {
    id,
    label,
    value,
    onChangeFunction,
    touchedExpression,
    errorsExpression,
    isEditing,
    setIsEditing,
    placeholder,
    required,
    toolTip,
    disabled,
  } = props;

  return (
    <TextField
      id={id}
      name={id}
      label={markRequired(label, required)}
      value={value * 100}
      onChange={(e) => onChangeFunction(e)}
      disabled={!isEditing || disabled}
      placeholder={placeholder ?? "Example: 50%"}
      error={touchedExpression && Boolean(errorsExpression)}
      helperText={touchedExpression && errorsExpression}
      InputProps={{
        inputComponent: NumericFormatCustom as any,
        endAdornment: (
          <InputAdornment position="end">
            {/* <InputAdornment position="start">%</InputAdornment> */}
            {toolTip && (
              <Tooltip title={toolTip}>
                <EditIcon />
              </Tooltip>
            )}
          </InputAdornment>
        ),
        readOnly: !isEditing,
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

interface CustomProps {
  onChange: (event: { target: { name: string; value: number } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, name, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: name,
              value: values.floatValue ? values.floatValue / 100 : 0, // Convert back to a fraction for storage
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        suffix="%"
        decimalScale={3}
        //numericFormatter={(value, NumericFormatProps)}
        //fixedDecimalScale
      />
    );
  }
);

IppPercentField.defaultProps = {};
