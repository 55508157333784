import { Circle, CircleOutlined, Edit } from "@mui/icons-material";
import { Rating, Tooltip, Typography, Grid, IconButton } from "@mui/material";
import { useTypedTranslation } from "utils/customHooks";

interface IppRatingProps {
  id: string;
  label: string;
  value: number;
  onChangeFunction: any;
  isEditing: boolean;
  setIsEditing: any;
  showEditButton?: boolean;
}

export const IppRating = (props: IppRatingProps) => {
  const {
    id,
    label,
    value,
    onChangeFunction,
    isEditing,
    setIsEditing,
    showEditButton,
  } = props;
  const t = useTypedTranslation(["strGen"]);

  const editChange = () => {
    setIsEditing(!isEditing);
  };

  return (
    <div>
      <Typography component="legend" variant="caption">
        {label}
      </Typography>
      {showEditButton ? (
        <Grid container spacing={0}>
          <Grid item xs>
            <Rating
              id={id}
              name={id}
              value={value}
              onChange={onChangeFunction}
              max={7}
              icon={<Circle />}
              emptyIcon={<CircleOutlined />}
              disabled={!isEditing}
            />
          </Grid>
          <Grid item width={30}>
            <IconButton onClick={editChange} size="small">
              <Edit fontSize="inherit" />
            </IconButton>
          </Grid>
        </Grid>
      ) : (
        <Tooltip title={t("strGen:tooltips.ipprating", { label })}>
          <Rating
            id={id}
            name={id}
            value={value}
            onChange={onChangeFunction}
            max={7}
            icon={<Circle />}
            emptyIcon={<CircleOutlined />}
            disabled={!isEditing}
          />
        </Tooltip>
      )}
    </div>
  );
};

IppRating.defaultProps = {
  showEditButton: false,
};
