import axiosClient from "./axiosClient";

interface BaseAttachment {
  IsUploaded: boolean;
  UploadDate: Date;
  UploadedBy: string;
  AttachmentID: number;
  ClientID: number;
  RecordType: string;
  RecordID: number;
  CompanyID: number;
  ProjectID: number;
  ModuleID: number;
  UserAccountID: any;
  FirstName: string;
  Surname: string;
}

export type FileAttachment = {
  FileName: string;
  FileDescription: string;
  FilePath: string;
} & BaseAttachment;

export type LinkAttachment = {
  Link: string;
} & BaseAttachment;

export type Attachment = FileAttachment | LinkAttachment;

export interface attachmentsResult {
  attachments: FileAttachment[];
}

export async function countAttachments(
  accessToken: string,
  recordType: string,
  recordID: number
): Promise<attachmentsResult> {
  const url = `/attachments/${recordType}/${recordID}/count`;

  try {
    const { data } = await axiosClient.get<attachmentsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    //console.log("API count", data)

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getAttachments(
  accessToken: String,
  recordType: string,
  recordID: number
): Promise<attachmentsResult> {
  const url = `/attachments/${recordType}/${recordID}`;

  try {
    const { data } = await axiosClient.get<attachmentsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createAttachment<T extends Attachment>(
  accessToken: String,
  recordType: string,
  recordID: number,
  newAttachment: Partial<T>
): Promise<T> {
  const url = `/attachments/${recordType}/${recordID}`;

  try {
    const { data } = await axiosClient.post<T>(url, newAttachment, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateAttachment<T extends Attachment>(
  accessToken: String,
  attachmentID: number,
  newAttachment: Partial<T>
): Promise<T> {
  const url = `/attachments/` + attachmentID;
  //console.log("UPDINFO", newAttachment);

  try {
    const { data } = await axiosClient.post<T>(url, newAttachment, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteAttachment(
  accessToken: String,
  attachmentID: number
): Promise<string> {
  const url = `/attachments/` + attachmentID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    //console.log("DELDATA", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}
