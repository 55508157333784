import axiosClient from "api/axiosClient";

export interface CommitmentSummary {
  ItemID: number;
  Completed: number;
  Outstanding: number;
}

export interface CommitmentSummarysResult {
  commitmentSummarys: CommitmentSummary[];
}

export async function getCommitmentSummarys(
  accessToken: String
): Promise<CommitmentSummarysResult> {
  const url = `/commitmentHomePage/summary`;
  try {
    const { data } = await axiosClient.get<CommitmentSummarysResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
