import { ContractForm } from "./ContractForm";

export const ContractCreatePage = () => {
  let contractCreateView = (
    <div>
      <ContractForm />
    </div>
  );

  return <div id="contract-detail-page">{contractCreateView}</div>;
};
