import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Status, StatusResult, getStatus } from "api/dataList/statusAPI";
import { AppThunk } from "app/store";

interface StatusState {
  statusById: Record<number, Status>;
  statusList: number[];
  isLoading: boolean;
  error: string | null;
}

const StatusInitialState: StatusState = {
  statusById: {},
  statusList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: StatusState) {
  state.isLoading = true;
}

function loadingFailed(state: StatusState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const status = createSlice({
  name: "status",
  initialState: StatusInitialState,
  reducers: {
    getStatusStart: startLoading,
    getStatusSuccess(state, { payload }: PayloadAction<StatusResult>) {
      const { statuses } = payload;
      state.isLoading = false;
      state.error = null;
      //console.log("slice", status);
      statuses.forEach((item) => {
        state.statusById[item.StatusID] = item;
      });

      state.statusList = statuses.map((stat) => stat.StatusID);
    },
    getStatusFailure: loadingFailed,
  },
});

export const { getStatusStart, getStatusSuccess, getStatusFailure } =
  status.actions;

export default status.reducer;

export const fetchStatus =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getStatusStart());
      const status = await getStatus(accessToken);

      dispatch(getStatusSuccess(status));
    } catch (err: any) {
      dispatch(getStatusFailure(err.toString()));
    }
  };
