import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  FeedbackByStakeholder,
  FeedbackByStakeholdersResult,
  getFeedbackByStakeholders,
} from "api/stakeholder/logTables/feedbackByStakeholderAPI";
import { AppThunk } from "app/store";

interface FeedbackByStakeholderState {
  feedbackByStakeholdersById: Record<number, FeedbackByStakeholder>;
  feedbackByStakeholderList: number[];
  isLoading: boolean;
  error: string | null;
}

const FeedbackByStakeholderInitialState: FeedbackByStakeholderState = {
  feedbackByStakeholdersById: {},
  feedbackByStakeholderList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: FeedbackByStakeholderState) {
  state.isLoading = true;
}

function loadingFailed(
  state: FeedbackByStakeholderState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const feedbackByStakeholders = createSlice({
  name: "feedbackByStakeholder",
  initialState: FeedbackByStakeholderInitialState,
  reducers: {
    getFeedbackByStakeholdersStart: startLoading,
    getFeedbackByStakeholdersSuccess(
      state,
      { payload }: PayloadAction<FeedbackByStakeholdersResult>
    ) {
      const { feedbackByStakeholders } = payload;
      state.isLoading = false;
      state.error = null;

      feedbackByStakeholders.forEach((item) => {
        state.feedbackByStakeholdersById[item.InteractionCommentID] = item;
      });

      state.feedbackByStakeholderList = feedbackByStakeholders.map(
        (item) => item.InteractionCommentID
      );
    },
    getFeedbackByStakeholdersFailure: loadingFailed,
  },
});

export const {
  getFeedbackByStakeholdersStart,
  getFeedbackByStakeholdersSuccess,
  getFeedbackByStakeholdersFailure,
} = feedbackByStakeholders.actions;

export default feedbackByStakeholders.reducer;

export const fetchFeedbackByStakeholders =
  (accessToken: String, projectID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getFeedbackByStakeholdersStart());
      const feedbackByStakeholders = await getFeedbackByStakeholders(
        accessToken,
        projectID
      );
      dispatch(getFeedbackByStakeholdersSuccess(feedbackByStakeholders));
    } catch (err: any) {
      dispatch(getFeedbackByStakeholdersFailure(err.toString()));
    }
  };
