import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTypedTranslation } from "utils/customHooks";

const PREFIX = "FooterToolbar";

const classes = {
  menuIcon: `${PREFIX}-menuIcon`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.menuIcon}`]: {
    paddingRight: theme.spacing(1),
  },
}));

function FooterToolbar() {
  const t = useTypedTranslation(["strGen"]);
  return (
    <Root>
      <Typography variant="caption">{t("strGen:footer.legaltext")}</Typography>
    </Root>
  );
}

export default FooterToolbar;
