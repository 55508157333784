import axiosClient from "../axiosClient";

export interface GroupIssue {
  GroupIssueID: number;
  GroupID: number;
  IssueID: number;
  GroupName: string;
  OrganizationType: string;
  ClientID: number;
  TownCity: string;
  ProvinceState: string;
  IssueName: string;
  IssueDescription: string;
}

export interface GroupIssueResult {
  groupIssues: GroupIssue[];
}

// COMMENTED OUT FOR FUTURE CLEANUP
export async function getGroupIssues(
  accessToken: String
): Promise<GroupIssueResult> {
  const url = `/groupIssue`;

  try {
    const { data } = await axiosClient.get<GroupIssueResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getGroupIssuesByGroup(
  accessToken: String,
  groupID: number
): Promise<GroupIssueResult> {
  const url = `/groupIssue/group/${groupID}`;

  try {
    const { data } = await axiosClient.get<GroupIssueResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getGroupIssuesByIssue(
  accessToken: String,
  issueID: number
): Promise<GroupIssueResult> {
  const url = `groupIssue/issue/${issueID}`;

  try {
    const { data } = await axiosClient.get<GroupIssueResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

// COMMENTED OUT FOR FUTURE CLEANUP
// export async function createGroupIssue(
//   accessToken: String,
//   newGroupIssue: Partial<GroupIssue>
// ): Promise<GroupIssue> {
//   const url = `/groupIssue`;

//   try {
//     const { data } = await axiosClient.post<GroupIssue>(url, newGroupIssue, {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//         "Content-Type": "application/json",
//       },
//     });

//     return data;
//   } catch (err: any) {
//     throw err;
//   }
// }
//
// export async function updateGroupIssue(
//   accessToken: String,
//   groupIssueID: number,
//   newGroupIssue: Partial<GroupIssue>
// ): Promise<GroupIssue> {
//   const url = `/groupIssue/${groupIssueID}`;

//   try {
//     const { data } = await axiosClient.post<GroupIssue>(url, newGroupIssue, {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//         "Content-Type": "application/json",
//       },
//     });

//     return data;
//   } catch (err: any) {
//     throw err;
//   }
// }

export async function deleteGroupIssue(
  accessToken: String,
  groupIssueID: number
): Promise<string> {
  const url = `/groupIssue/${groupIssueID}`;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
