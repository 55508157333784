import axiosClient from "../axiosClient";

export interface InteractionIssue {
  InteractionIssueID: number;
  InteractionID: number;
  IssueID: number;
  IssueDate: Date;
  InteractionTitle: string;
  IssueName: string;
  IssueDescription: string;
  InteractionProjectID: number;
  InteractionDate: Date;
  InteractionTypeName: string;
  ClientID: number;
  SentimentLevel: number;
  CommunicationID: number;
}

export interface InteractionIssuesResult {
  interactionIssues: InteractionIssue[];
}

// NOT BEING USED

// export async function getInteractionIssues(
//   accessToken: String
// ): Promise<InteractionIssuesResult> {
//   const url = `/interactionIssue`;

//   try {
//     const { data } = await axiosClient.get<InteractionIssuesResult>(url, {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//     });

//     return data;
//   } catch (err: any) {
//     throw err;
//   }
// }

export async function getInteractionIssuesByIssue(
  accessToken: String,
  issueID: number
): Promise<InteractionIssuesResult> {
  const url = `/interactionIssue/issue/${issueID}`;

  try {
    const { data } = await axiosClient.get<InteractionIssuesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getInteractionIssuesByInteraction(
  accessToken: String,
  interactionID: number
): Promise<InteractionIssuesResult> {
  const url = `/interactionIssue/interaction/${interactionID}`;

  try {
    const { data } = await axiosClient.get<InteractionIssuesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createInteractionIssue(
  accessToken: String,
  newCommInv: Partial<InteractionIssue>
): Promise<InteractionIssue> {
  const url = `/interactionIssue`;

  try {
    const { data } = await axiosClient.post<InteractionIssue>(url, newCommInv, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

// export async function updateInteractionIssue(
//   accessToken: String,
//   commInvID: number,
//   newCommInv: Partial<InteractionIssue>
// ): Promise<InteractionIssue> {
//   const url = `/interactionIssue/` + commInvID;

//   try {
//     const { data } = await axiosClient.post<InteractionIssue>(url, newCommInv, {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//         "Content-Type": "application/json",
//       },
//     });

//     return data;
//   } catch (err: any) {
//     throw err;
//   }
// }

export async function deleteInteractionIssue(
  accessToken: String,
  commInvID: number
): Promise<string> {
  const url = `/interactionIssue/` + commInvID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
