import { useAuth0 } from "@auth0/auth0-react";
import {
  getClientShortnameFromAuthOrgId,
  getClientShortnameFromCustomDomain,
} from "api/clientAPI";
import { useEffect, useState } from "react";
import { Redirect } from "react-router";

const pathArray = window.location.pathname.split("/");
const redirectLoc = window.location.origin + "/" + pathArray[1];

const TenantNameRedirect = (props: any) => {
  const { user } = useAuth0();

  //const [shortname, setShortname] = useState("");

  useEffect(() => {
    (async () => {
      try {
        //check for logged in user and look up shortname
        if (user && user.org_id && pathArray[1] == "") {
          const clientShortname = await getClientShortnameFromAuthOrgId(
            user.org_id
          );
          window.location.replace(clientShortname);
          //setShortname(clientShortname);
        } else if (pathArray[1] == "") {
          //check for custom domain and look up shortname
          const clientShortname = await getClientShortnameFromCustomDomain();
          if (clientShortname) {
            window.location.replace(clientShortname);
          }
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [user]);

  return <div>{pathArray && pathArray[1] != "" && props.children}</div>;
};

export default TenantNameRedirect;
