import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchCommunityInvestments } from "./CommunityInvestmentSlice";
import { fetchCommunityInvestmentTypes } from "features/datalists/CommunityInvestmentTypeSlice";
import { useParams } from "react-router-dom";
import { Alert } from "@mui/material";

import { CommunityInvestmentForm } from "./CommunityInvestmentForm";
import LoadingIndicator from "components/LoadingIndicator";

interface CommunityInvestmentDetProps {
  communityInvestmentID: string;
}

export const CommunityInvestmentDetailPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const {
    isLoading,
    error: compError,
    communityInvestmentsById,
  } = useSelector((state: RootState) => state.communityInvestments);

  // get communityInvestment ID from url param and attempt to find company in store
  const { communityInvestmentID } = useParams<CommunityInvestmentDetProps>();
  const payID = parseInt(communityInvestmentID, 10);

  const communityInvestment =
    communityInvestmentsById[parseInt(communityInvestmentID, 10)];

  useEffect(() => {
    (async () => {
      try {
        // can avoid going to the DB if we navigated from inventory
        if (!communityInvestment) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchCommunityInvestments(accessToken));
          dispatch(fetchCommunityInvestmentTypes(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [communityInvestment, dispatch, getAccessTokenSilently]);

  let communityInvestmentDetView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      {communityInvestment && (
        <>
          {compError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              <span>
                <b>Something went wrong</b> - {compError.toString()}
              </span>
            </Alert>
          )}
          {typeof payID != "number" && (
            <Alert severity="error" sx={{ mb: 2 }}>
              <span>
                Something went wrong - {communityInvestmentID} is not a valid
                Company ID
              </span>
            </Alert>
          )}
          <CommunityInvestmentForm communityInvestment={communityInvestment} />
        </>
      )}
    </div>
  );

  return (
    <div id="communityInvestment-detail-page">{communityInvestmentDetView}</div>
  );
};
