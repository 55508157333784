import {
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
  Edit,
} from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";

interface ippDisplayCheckboxProps {
  label?: string;
  isChecked: boolean;
  isEditing: boolean;
  setIsEditing: any;
  showEdit?: boolean;
  disabled?: boolean;
}

export const IppDisplayCheckbox = (props: ippDisplayCheckboxProps) => {
  const { label, isChecked, isEditing, setIsEditing, showEdit, disabled } =
    props;

  const editChange = () => {
    setIsEditing(!isEditing);
  };

  const disabledStyle = {
    color: "#9e9e9e",
  };

  return (
    <div>
      <Grid container spacing={0}>
        <Grid item width={30}>
          {isChecked ? (
            <CheckBoxOutlined
              fontSize="small"
              sx={disabled ? disabledStyle : { color: "text.secondary" }}
            />
          ) : (
            <CheckBoxOutlineBlankOutlined
              fontSize="small"
              sx={disabled ? disabledStyle : { color: "text.secondary" }}
            />
          )}
        </Grid>
        <Grid item xs>
          <Typography
            variant="body2"
            sx={disabled ? disabledStyle : { color: "text.secondary" }}
          >
            {label}
          </Typography>
        </Grid>
        {showEdit && !disabled && (
          <Grid item width={30}>
            <IconButton onClick={editChange} size="small">
              <Edit fontSize="inherit" />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

IppDisplayCheckbox.defaultProps = {
  showEdit: true,
};
