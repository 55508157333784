import { TextField, Tooltip, Stack, Chip, Grid } from "@mui/material";
import { IppAddButton } from "./Buttons/IppAddButton";
import { useTypedTranslation } from "utils/customHooks";
import { IppChildTable, ippChildTableProps } from "./IppChildTable";
import { useState } from "react";
import { IppCancelButton } from "./Buttons/IppCancelButton";
import { IppMergeButton } from "./Buttons/IppMergeButton";
import { truncateText } from "utils/functions";

interface IppTokenTableProps {
  id: string;
  value: any[];
  setValue: (value: any[]) => void;
  label: string;
  optionLabelFunction?: (option: any) => string;
  disabled?: any;
  required?: boolean;
  title: string;
  parentTitle: string;
  closeAction?: any;
  idField?: string; // unique id field for displayed table data
  relatedField: string; // id of related table (for linkURL and edit child)
  options?: any[]; // all available options for link item
  optionTableProps?: Partial<ippChildTableProps>;
  tableName?: string;
}

export const IppTokenTable = (props: IppTokenTableProps) => {
  const {
    id,
    value,
    setValue,
    label,
    optionLabelFunction,
    disabled = false,
    required,
    title,
    parentTitle,
    idField = "",
    relatedField,
    options = [],
    optionTableProps,
    tableName = "",
  } = props;
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);
  /********  Link item from IppChildTable  ***********/
  const [childTableOpen, setChildTableOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<any>(options);
  // Filter options to exclude selected values
  const updateFilteredOptions = () => {
    let updOptions = options.filter(
      (option) =>
        !value.some(
          (selectedItem) => selectedItem[relatedField] === option[relatedField]
        )
    );
    setFilteredOptions(updOptions);
  };

  const toggleChildTableDialog = () => {
    // Filter options before open dialog
    !childTableOpen && updateFilteredOptions();
    setChildTableOpen(!childTableOpen);
  };

  // Option Table Item Select and Unselect
  const handleAddItem = (item: any) => {
    const newData = [...value, { ...item, isAdded: true, expanded: false }];
    item.isAdded = true;
    setValue(newData);
  };
  const handleRemoveItem = (item: any) => {
    const remainingData = value.filter(
      (data: any) => data[relatedField] !== item[relatedField]
    );
    setValue(remainingData);
    if (item.isAdded === true) {
      item.isAdded = false;
    }
  };

  const SelectCell = (props: any) => {
    return (
      <td>
        <Grid container justifyContent="center" alignItems="center">
          <Tooltip
            title={t("strGen:components.customcells.addCell.addTo", {
              fieldname: t("objStk:objects.interaction.name"),
            })}
          >
            {props.dataItem?.isAdded === true ? (
              <IppCancelButton
                buttonText={t("strGen:buttons.unselect")}
                onClick={() => handleRemoveItem(props?.dataItem)}
              />
            ) : (
              <IppMergeButton
                buttonText={t("strGen:pages.merge.select")}
                onClick={() => handleAddItem(props?.dataItem)}
              />
            )}
          </Tooltip>
        </Grid>
      </td>
    );
  };
  const updOptionsColumns = [
    ...(optionTableProps?.optionColumns || []),
    {
      field: idField,
      title: "",
      button: true,
      buttonCell: SelectCell,
      columnWidth: 120,
    },
  ];

  return (
    <div>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} md={10.5}>
          <TextField
            id={id}
            name={id}
            label={label}
            required={required}
            disabled={disabled}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <Stack
                  direction="row"
                  sx={{
                    flexWrap: "wrap",
                    marginY: 1,
                    width: "100%",
                  }}
                  rowGap={1}
                >
                  {value?.map((option, index) => (
                    <Chip
                      key={index}
                      label={
                        optionLabelFunction
                          ? truncateText(optionLabelFunction(option), 30)
                          : truncateText(option, 30)
                      }
                      variant="outlined"
                      onDelete={() => handleRemoveItem(option)}
                    />
                  ))}
                </Stack>
              ),
            }}
            InputLabelProps={{ shrink: true }}
            fullWidth
            // Hide the input itself
            sx={{
              input: {
                width: 0,
              },
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={1.5}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <IppAddButton
            buttonText={t("strGen:buttons.select")}
            onClick={toggleChildTableDialog}
            disabled={!!disabled}
            tooltip={
              disabled ||
              t(
                "strGen:components.ippchildinventory.tooltips.link.linknewobj",
                {
                  childobjectname: title,
                  objectname: parentTitle,
                }
              )
            }
          />
        </Grid>
      </Grid>
      {/* Selections table */}
      <IppChildTable
        title={title}
        parentTitle={parentTitle}
        isOpen={childTableOpen}
        closeAction={toggleChildTableDialog}
        tableName={tableName}
        idField={relatedField}
        options={filteredOptions}
        optionColumns={updOptionsColumns}
        optionSearch={optionTableProps?.optionSearch}
        optionDetail={optionTableProps?.optionDetail}
        linkColumn={optionTableProps?.linkColumn}
      />
    </div>
  );
};
