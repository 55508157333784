import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ProjectContact,
  ProjectContactsResult,
  getProjectContacts,
  createProjectContact,
  updateProjectContact,
  deleteProjectContact,
  getProjectContactsByCompanyAndProject,
} from "api/projectContactAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface ProjectContactState {
  projectContactsById: Record<number, ProjectContact>;
  projectContactList: number[];
  isLoading: boolean;
  error: string | null;
}

const ProjectContactInitialState: ProjectContactState = {
  projectContactsById: {},
  projectContactList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: ProjectContactState) {
  state.isLoading = true;
}

function loadingFailed(
  state: ProjectContactState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const projectContacts = createSlice({
  name: "projectContacts",
  initialState: ProjectContactInitialState,
  reducers: {
    getProjectContactsStart: startLoading,
    getProjectContactsSuccess(
      state,
      { payload }: PayloadAction<ProjectContactsResult>
    ) {
      const { projectContacts } = payload;
      // console.log("PAYLOAD", projectContacts);
      state.isLoading = false;
      state.error = null;

      projectContacts.forEach((projectContact) => {
        state.projectContactsById[projectContact.ProjectContactID] =
          projectContact;
      });

      state.projectContactList = projectContacts.map(
        (projectContact) => projectContact.ProjectContactID
      );
    },
    getProjectContactsFailure: loadingFailed,
    createProjectContactStart: startLoading,
    createProjectContactSuccess(
      state,
      { payload }: PayloadAction<ProjectContact>
    ) {
      const { ProjectContactID } = payload;
      state.projectContactsById[ProjectContactID] = payload;
      state.projectContactList.push(ProjectContactID);

      state.isLoading = false;
      state.error = null;
    },
    updateProjectContactSuccess(
      state,
      { payload }: PayloadAction<ProjectContact>
    ) {
      const { ProjectContactID } = payload;
      state.projectContactsById[ProjectContactID] = payload;
      state.projectContactList.push(ProjectContactID);

      state.isLoading = false;
      state.error = null;
    },
    deleteProjectContactSuccess(state, { payload }: PayloadAction<number>) {
      const ProjectContactID = payload;
      delete state.projectContactsById[ProjectContactID];
      state.projectContactList = state.projectContactList.filter(
        (item) => item !== ProjectContactID
      );

      state.isLoading = false;
      state.error = null;
    },
    createProjectContactFailure: loadingFailed,
  },
});

export const {
  getProjectContactsStart,
  getProjectContactsSuccess,
  getProjectContactsFailure,
  createProjectContactStart,
  createProjectContactSuccess,
  updateProjectContactSuccess,
  deleteProjectContactSuccess,
  createProjectContactFailure,
} = projectContacts.actions;

export default projectContacts.reducer;

export const fetchProjectContacts =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getProjectContactsStart());
      const projectContacts = await getProjectContacts(accessToken);
      //console.log("RETURN", projectContacts);
      dispatch(getProjectContactsSuccess(projectContacts));
    } catch (err: any) {
      dispatch(getProjectContactsFailure(err.toString()));
    }
  };

export const fetchProjectContactsByCompanyAndProject =
  (accessToken: String, companyID: number, projectID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getProjectContactsStart());
      const employees = await getProjectContactsByCompanyAndProject(
        accessToken,
        companyID,
        projectID
      );
      //console.log("RETURN", projectContacts);
      dispatch(getProjectContactsSuccess(employees));
    } catch (err: any) {
      dispatch(getProjectContactsFailure(err.toString()));
    }
  };

export const addProjectContact =
  (accessToken: String, newProjectContact: Partial<ProjectContact>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createProjectContactStart());
      const projectContact = await createProjectContact(
        accessToken,
        newProjectContact
      );
      dispatch(createProjectContactSuccess(projectContact));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push("/projectContacts/new"));
    } catch (err: any) {
      dispatch(createProjectContactFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updProjectContact =
  (
    accessToken: String,
    projectContactID: number,
    newProjectContact: Partial<ProjectContact>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createProjectContactStart());
      const projectContact = await updateProjectContact(
        accessToken,
        projectContactID,
        newProjectContact
      );
      dispatch(updateProjectContactSuccess(projectContact));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push("/projectContacts/" + projectContactID));
    } catch (err: any) {
      dispatch(createProjectContactFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delProjectContact =
  (accessToken: String, projectContactID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createProjectContactStart());
      const result = await deleteProjectContact(accessToken, projectContactID);
      dispatch(deleteProjectContactSuccess(projectContactID));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push("/projectContacts/" + projectContactID));
    } catch (err: any) {
      dispatch(createProjectContactFailure(err.toString()));
    }
  };
