import { styled } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Grid as KendoGrid,
  GridColumn,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { Delete, Edit } from "@mui/icons-material";
import { IppTextField } from "components/IppTextField";
import { useTypedTranslation } from "utils/customHooks";
import LoadingIndicator from "components/LoadingIndicator";
import { IppButton } from "components/Buttons/IppButton";
import { IppAddButton } from "components/Buttons/IppAddButton";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppDeleteButton } from "components/Buttons/IppDeleteButton";
import { IppCancelButton } from "components/Buttons/IppCancelButton";
import IppConfirmDialog from "components/IppConfirmDialog";
import {
  addEthnicity,
  delEthnicity,
  fetchEthnicitys,
  updEthnicity,
} from "./EthnicitySlice";
import { fetchDeleteChecksByEthnicity } from "features/deleteCheck/DeleteCheckSlice";

const PREFIX = "EthnicityForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
  roleCard: `${PREFIX}-roleCard`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1100,
  },

  [`& .${classes.boxSpace}`]: {
    padding: 10,
    maxWidth: 900,
  },

  [`& .${classes.roleCard}`]: {
    minWidth: 250,

    minHeight: 300,
  },
}));

export const EthnicityForm = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [editID, setEditID] = useState(1);
  const [isEditing, setIsEditing] = useState(true);
  const [editOpen, setEditOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteName, setDeleteName] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);

  const { clientId } = useSelector((state: RootState) => state.client);

  const { ethnicityList, ethnicitysById, isLoading } = useSelector(
    (state: RootState) => state.ethnicity
  );
  const ethnicitys = ethnicityList.map((id) => ethnicitysById[id]);

  const [editItem, setEditItem] = useState({
    EthnicityName: undefined,
  });

  const validationSchema = yup.object({
    EthnicityName: yup
      .string()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.employee.fields.ethnicityname"),
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.employee.fields.ethnicityname"),
          count: 50,
        })
      ),
  });

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchEthnicitys(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        if (isAdding) {
          dispatch(addEthnicity(accessToken, values));
        } else {
          dispatch(updEthnicity(accessToken, editID, values));
        }
        setEditOpen(false);
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const handleDelete = (ethnicityId: number) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(delEthnicity(accessToken, ethnicityId));
        setDeleteOpen(false);
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  const deleteCell = (props: any) => {
    return (
      <td>
        <Tooltip title={t("strGen:buttons.tooltip.deleteentry")}>
          <div
            style={{ cursor: "pointer", color: "blue" }}
            onClick={(e) => {
              setEditID(props.dataItem.EthnicityID);
              setDeleteName(props.dataItem.EthnicityName);
              setDeleteOpen(true);
            }}
          >
            <IppButton variant="text" startIcon={<Delete />} color={"error"} />
          </div>
        </Tooltip>
      </td>
    );
  };

  const editCell = (props: any) => {
    return (
      <td>
        <Tooltip title={t("strGen:buttons.tooltip.editentry")}>
          <div
            style={{ cursor: "pointer", color: "blue" }}
            onClick={(e) => {
              setEditID(props.dataItem.EthnicityID);
              formik.setValues(props.dataItem);
              setEditOpen(true);
            }}
          >
            <IppButton variant="text" startIcon={<Edit />} />
          </div>
        </Tooltip>
      </td>
    );
  };

  const formik = useFormik({
    initialValues: editItem,
    validationSchema: validationSchema,
    onSubmit: submitFunc,
    enableReinitialize: true,
  });

  let ethnicityView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <Box display="flex" justifyContent="center">
        <Paper className={classes.boxSpace}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h5" component="h3">
                {t("objBen:objects.employee.fields.ethnicity_other")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Grid container justifyContent="flex-end">
                <IppAddButton
                  buttonText={t("strGen:buttons.addobj", {
                    objectname: t("objBen:objects.employee.fields.ethnicity"),
                  })}
                  onClick={() => {
                    setIsAdding(true);
                    setEditOpen(true);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <KendoGrid data={ethnicitys} total={ethnicitys.length}>
                <GridNoRecords>** No Ethnicities added yet. **</GridNoRecords>
                <GridColumn field="EthnicityName" title="Ethnicity" />
                <GridColumn
                  cell={editCell}
                  width="50px"
                  field="EthnicityID"
                  title=" "
                />
                <GridColumn cell={deleteCell} width="50px" title=" " />
              </KendoGrid>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <Dialog
        open={editOpen}
        onClose={() => {
          setEditOpen(false);
          formik.resetForm();
        }}
      >
        <DialogTitle>{isAdding ? "Add " : "Edit Active"}</DialogTitle>
        <DialogContent>
          <DialogContentText variant="body1">
            {isAdding
              ? "You are adding a new Ethnicity"
              : "You are modifying the values for this Ethnicity"}
          </DialogContentText>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <IppTextField
                  id="EthnicityName"
                  label="Ethnicity"
                  value={formik.values.EthnicityName || ""}
                  required={true}
                  onChangeFunction={formik.handleChange}
                  errorsExpression={formik.errors.EthnicityName}
                  touchedExpression={formik.touched.EthnicityName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                />
              </Grid>
              <Grid item xs={12}>
                <IppFormButtons
                  isEditing={true}
                  setIsEditing={null}
                  isAdding={isAdding}
                  showCancel={true}
                  resetFunction={() => {
                    setEditOpen(false);
                    formik.resetForm();
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      <IppConfirmDialog
        title={t("strGen:prompts.deletechecks.titles.ethnicity", {
          title:
            ethnicitys.find((ethnicity) => ethnicity.EthnicityID === editID)
              ?.EthnicityName || "",
        })}
        open={deleteOpen}
        setOpen={() => setDeleteOpen(!deleteOpen)}
        onConfirm={() => handleDelete(editID)}
        fetchFunc={fetchDeleteChecksByEthnicity}
        recordID={editID}
        info={t("strGen:prompts.deletechecks.ethnicities")}
      />
    </div>
  );

  return <Root id="Ethnicity-Page">{ethnicityView}</Root>;
};
