import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CommitmentSummary,
  CommitmentSummarysResult,
  getCommitmentSummarys,
} from "api/commitments/homePage/commitmentSummaryAPI";
import { AppThunk } from "app/store";

interface CommitmentSummaryState {
  commitmentSummarysById: Record<number, CommitmentSummary>;
  commitmentSummaryList: number[];
  isLoading: boolean;
  error: string | null;
}

const CommitmentSummaryInitialState: CommitmentSummaryState = {
  commitmentSummarysById: {},
  commitmentSummaryList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: CommitmentSummaryState) {
  state.isLoading = true;
}

function loadingFailed(
  state: CommitmentSummaryState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const commitmentSummarys = createSlice({
  name: "commitmentSummarys",
  initialState: CommitmentSummaryInitialState,
  reducers: {
    getCommitmentSummarysStart: startLoading,
    getCommitmentSummarysSuccess(
      state,
      { payload }: PayloadAction<CommitmentSummarysResult>
    ) {
      const { commitmentSummarys } = payload;
      state.isLoading = false;
      state.error = null;

      commitmentSummarys.forEach((item) => {
        state.commitmentSummarysById[item.ItemID] = item;
      });

      state.commitmentSummaryList = commitmentSummarys.map(
        (item) => item.ItemID
      );
    },
    getCommitmentSummarysFailure: loadingFailed,
  },
});

export const {
  getCommitmentSummarysStart,
  getCommitmentSummarysSuccess,
  getCommitmentSummarysFailure,
} = commitmentSummarys.actions;

export default commitmentSummarys.reducer;

export const fetchCommitmentSummarys =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCommitmentSummarysStart());
      const commitmentSummarys = await getCommitmentSummarys(accessToken);
      dispatch(getCommitmentSummarysSuccess(commitmentSummarys));
    } catch (err: any) {
      dispatch(getCommitmentSummarysFailure(err.toString()));
    }
  };
