import { useTypedTranslation } from "utils/customHooks";
import * as yup from "yup";

export const StandardStatementValidationSchema = () => {
  const t = useTypedTranslation(["objPlt", "strGen"]);

  return yup.object({
    StandardStatement: yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.standardstatement.fields.statement"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:objects.standardstatement.fields.statement"),
        })
      ),
    InteractionDetails: yup
      .boolean()
      .nullable()
      .typeError(t("strGen:validation.invalidformat")),
    InteractionInternalNotes: yup
      .boolean()
      .nullable()
      .typeError(t("strGen:validation.invalidformat")),
    InteractionActionDetails: yup
      .boolean()
      .nullable()
      .typeError(t("strGen:validation.invalidformat")),
    CommitmentDetails: yup
      .boolean()
      .nullable()
      .typeError(t("strGen:validation.invalidformat")),
    CommitmentInternalNotes: yup
      .boolean()
      .nullable()
      .typeError(t("strGen:validation.invalidformat")),
    CommitmentLegalText: yup
      .boolean()
      .nullable()
      .typeError(t("strGen:validation.invalidformat")),
    CommitmentActionDetails: yup
      .boolean()
      .nullable()
      .typeError(t("strGen:validation.invalidformat")),
    InitiativeDetails: yup
      .boolean()
      .nullable()
      .typeError(t("strGen:validation.invalidformat")),
    PaymentPeriodNotes: yup
      .boolean()
      .nullable()
      .typeError(t("strGen:validation.invalidformat")),
  });
};
