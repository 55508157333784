import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import {
  CommitmentPhase,
  CommitmentPhasesResult,
  createCommitmentPhase,
  deleteCommitmentPhase,
  getCommitmentPhases,
  getCommitmentPhasesByCommitment,
  updateCommitmentPhase,
} from "api/commitments/commitmentPhaseAPI";

interface CommitmentPhaseState {
  commitmentPhasesById: Record<number, CommitmentPhase>;
  commitmentPhaseList: number[];
  isLoadingAdd: boolean;
  isLoading: boolean;
  isLoaded: boolean;
  error: string | null;
}

const CommitmentPhaseInitialState: CommitmentPhaseState = {
  commitmentPhasesById: {},
  commitmentPhaseList: [],
  isLoading: false,
  isLoadingAdd: false,
  isLoaded: false,
  error: null,
};

function startLoading(state: CommitmentPhaseState) {
  state.isLoading = true;
}

function startLoadingAdd(state: CommitmentPhaseState) {
  state.isLoadingAdd = true;
}

function loadingFailed(
  state: CommitmentPhaseState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const commitmentPhases = createSlice({
  name: "commitmentPhases",
  initialState: CommitmentPhaseInitialState,
  reducers: {
    getCommitmentPhasesStart: startLoading,
    getCommitmentPhasesSuccess(
      state,
      { payload }: PayloadAction<CommitmentPhasesResult>
    ) {
      const { commitmentPhases } = payload;

      state.isLoading = false;
      state.error = null;
      state.isLoaded = true;

      commitmentPhases.forEach((commitmentPhase: any) => {
        state.commitmentPhasesById[commitmentPhase.CommitmentPhaseID] =
          commitmentPhase;
      });

      state.commitmentPhaseList = commitmentPhases.map(
        (commitmentPhase: any) => commitmentPhase.CommitmentPhaseID
      );
    },
    getCommitmentPhasesFailure: loadingFailed,
    createCommitmentPhaseStart: startLoadingAdd,
    createCommitmentPhaseSuccess(
      state,
      { payload }: PayloadAction<CommitmentPhase>
    ) {
      const { CommitmentPhaseID } = payload;
      state.commitmentPhasesById[CommitmentPhaseID] = payload;
      state.commitmentPhaseList.push(CommitmentPhaseID);

      state.isLoading = false;
      state.isLoadingAdd = false;
      state.error = null;
    },
    updateCommitmentPhaseSuccess(
      state,
      { payload }: PayloadAction<CommitmentPhase>
    ) {
      const { CommitmentPhaseID } = payload;

      state.commitmentPhasesById[CommitmentPhaseID] = payload;
      //state.commitmentPhaseList.push(CommitmentPhaseID);

      state.isLoading = false;
      state.error = null;
    },
    deleteCommitmentPhaseSuccess(state, { payload }: PayloadAction<number>) {
      const CommitmentPhaseID = payload;
      delete state.commitmentPhasesById[CommitmentPhaseID];
      state.commitmentPhaseList = state.commitmentPhaseList.filter(
        (item) => item !== CommitmentPhaseID
      );

      state.isLoading = false;
      state.error = null;
    },
    createCommitmentPhaseFailure: loadingFailed,
  },
});

export const {
  getCommitmentPhasesStart,
  getCommitmentPhasesSuccess,
  getCommitmentPhasesFailure,
  createCommitmentPhaseStart,
  createCommitmentPhaseSuccess,
  updateCommitmentPhaseSuccess,
  deleteCommitmentPhaseSuccess,
  createCommitmentPhaseFailure,
} = commitmentPhases.actions;

export default commitmentPhases.reducer;

export const fetchCommitmentPhases =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCommitmentPhasesStart());
      const commitmentPhases = await getCommitmentPhases(accessToken);
      dispatch(getCommitmentPhasesSuccess(commitmentPhases));
    } catch (err: any) {
      dispatch(getCommitmentPhasesFailure(err.toString()));
    }
  };

export const fetchCommitmentPhasesByCommitment =
  (accessToken: String, commitmentPhaseID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCommitmentPhasesStart());
      const commitmentPhases = await getCommitmentPhasesByCommitment(
        accessToken,
        commitmentPhaseID
      );
      dispatch(getCommitmentPhasesSuccess(commitmentPhases));
    } catch (err: any) {
      dispatch(getCommitmentPhasesFailure(err.toString()));
    }
  };

export const addCommitmentPhase =
  (
    accessToken: String,
    newCommitmentPhase: Partial<CommitmentPhase>,
    setReturnRoute: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCommitmentPhaseStart());
      const commitmentPhase = await createCommitmentPhase(
        accessToken,
        newCommitmentPhase
      );
      dispatch(createCommitmentPhaseSuccess(commitmentPhase));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(
          push(
            `/commitments/commitmentPhases/${commitmentPhase.CommitmentPhaseID}`
          )
        );
      }
    } catch (err: any) {
      dispatch(createCommitmentPhaseFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updCommitmentPhase =
  (
    accessToken: String,
    commitmentPhaseID: number,
    newCommitmentPhase: Partial<CommitmentPhase>,
    setReturnRoute: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCommitmentPhaseStart());
      const commitmentPhase = await updateCommitmentPhase(
        accessToken,
        commitmentPhaseID,
        newCommitmentPhase
      );

      dispatch(updateCommitmentPhaseSuccess(commitmentPhase));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(push("/commitments/commitmentPhases/" + commitmentPhaseID));
      }
    } catch (err: any) {
      dispatch(createCommitmentPhaseFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delCommitmentPhase =
  (accessToken: String, commitmentPhaseID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCommitmentPhaseStart());
      const result = await deleteCommitmentPhase(
        accessToken,
        commitmentPhaseID
      );
      dispatch(deleteCommitmentPhaseSuccess(commitmentPhaseID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
      dispatch(push("/commitments/commitmentPhases"));
    } catch (err: any) {
      dispatch(createCommitmentPhaseFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export {};
