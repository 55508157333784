import axiosClient from "./axiosClient";

export interface ScheduledNotificationType {
  ScheduledNotificationTypeID: number;
  ScheduledNotificationTypeName: string;
  ModuleID: number;
}

export interface ScheduledNotificationTypesResult {
  scheduledNotificationTypes: ScheduledNotificationType[];
}

export async function getScheduledNotificationTypes(
  accessToken: String
): Promise<ScheduledNotificationTypesResult> {
  const url = `/scheduledNotificationType`;

  try {
    const { data } = await axiosClient.get<ScheduledNotificationTypesResult>(
      url,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}
