import { useFormik } from "formik";
import { ThreadValidationSchema } from "./ThreadValidation";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useTypedTranslation } from "utils/customHooks";
import { useState } from "react";
import { addThread, updThread } from "./ThreadSlice";
import { Grid, Typography } from "@mui/material";
import { IppTextField } from "components/IppTextField";
import { IppRichTextEditor } from "components/RichTextEditor/IppRichTextEditor";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { RootState } from "app/rootReducer";
import { IppGenerateSummaryButton } from "components/Buttons/IppGenerateSummaryButton";
import { IppGenerateSummaryDialog } from "components/IppGenerateSummaryDialog";
import { generateThreadSummaryWithAi } from "api/stakeholder/threadAPI";

interface threadChildFormProps {
  closeAction: any; // action to close dialog
  setDidSaveInventory: any;
  parentTitle: string; // name of field the form is called from
  parentValue: any; // if called from child grid, init parent value
  thread?: any; // existing interaction to be edited
  canWRITE?: boolean; // check if can be edited
}

export const ThreadChildForm = (props: threadChildFormProps) => {
  const {
    closeAction,
    setDidSaveInventory,
    parentTitle,
    parentValue,
    thread,
    canWRITE,
  } = props;

  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);
  const [generateDialogOpen, setGenerateDialogOpen] = useState(false);
  const [summaryKey, setSummaryKey] = useState(0);

  const { aiEnabled } = useSelector((state: RootState) => state.client);

  let itemData: any;

  if (thread) {
    itemData = thread;
  } else {
    itemData = {
      ThreadID: "",
      ProjectID: parentValue?.ProjectID,
      Summary: "",
      interactions: {},
    };
  }

  // Generate Summary button should only show if EmailContent is not empty or there are interactions
  const hasContentForGenerateButton =
    aiEnabled && (thread?.EmailContent || parentValue?.InteractionID);

  const [isEditing, setIsEditing] = useState(true);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        values.interactions = parentValue
          ? { InteractionID: parentValue?.InteractionID }
          : {};
        await dispatch(
          addThread(
            accessToken,
            values,
            true,
            `/engagement/communications/${parentValue?.InteractionID}`
          )
        );
        setDidSaveInventory(true);
        closeAction();
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  if (thread) {
    submitFunc = async (values: any) => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(
          updThread(
            accessToken,
            values.ThreadID,
            values,
            false,
            parentValue?.InteractionID
          )
        );
        closeAction();
      } catch (e) {
        console.error(e);
      }
    };
  }

  const formik = useFormik({
    initialValues: itemData,
    validationSchema: ThreadValidationSchema(t),
    onSubmit: submitFunc,
  });

  let detailForm = (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        {thread && isEditing && (
          <Grid item xs={12}>
            <Typography variant="h6">
              {t("strGen:prompts.edit.edittitle", {
                fieldname: t("objStk:objects.thread.name"),
              })}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <IppTextField
            id="ThreadTitle"
            required={true}
            label={t("objStk:objects.thread.fields.title")}
            value={formik.values.ThreadTitle}
            onChangeFunction={formik.handleChange}
            errorsExpression={formik.errors.ThreadTitle}
            touchedExpression={formik.touched.ThreadTitle}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        <Grid item xs={12}>
          <IppTextField
            id={"ProjectName"}
            label={t("objStk:objects.thread.fields.project")}
            value={parentValue?.ProjectName}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            disabled={true}
            onChangeFunction={() => {}}
          />
        </Grid>

        <Grid item xs={12}>
          <IppRichTextEditor
            key={summaryKey}
            id="Summary"
            label={t("objStk:objects.thread.fields.summary")}
            value={formik.values.Summary}
            isEditing={isEditing}
            touchedExpression={formik.touched.Summary}
            errorsExpression={formik.errors.Summary}
            setFieldValue={formik.setFieldValue}
            standardStatementsRecordType="InteractionThreadSummary"
            customEditorTool={
              aiEnabled && itemData?.ThreadID ? (
                <IppGenerateSummaryButton
                  onClick={() => setGenerateDialogOpen(true)}
                  disabled={!hasContentForGenerateButton}
                  toolTipMessage={
                    !hasContentForGenerateButton
                      ? t(
                          "strGen:buttons.tooltip.generatesummarymissingcontent",
                          {
                            fieldname: `a ${t(
                              "objStk:objects.thread.fields.summary"
                            )}`,
                            objectname: t("objStk:objects.thread.name"),
                            conditions: t(
                              "strGen:buttons.tooltip.generatesummarymissingcontentconditions.thread"
                            ),
                          }
                        )
                      : t("strGen:buttons.tooltip.generatesummary")
                  }
                  onMouseDown={(e) => e.preventDefault()}
                  onPointerDown={(e) => e.preventDefault()}
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                  sx={{
                    textTransform: "none",
                    fontWeight: "normal",
                    fontFamily: "inherit",
                    fontSize: "inherit",
                    padding: "8px",
                    height: "38px",
                    width: "38px",
                    "& .MuiSvgIcon-root": {
                      fontSize: "1.25rem",
                    },
                  }}
                />
              ) : undefined
            }
          />
        </Grid>
        <Grid item xs={12}>
          <IppFormButtons
            isEditing={isEditing}
            isAdding={!thread}
            setIsEditing={setIsEditing}
            showCancel={true}
            submitDisabled={canWRITE === false}
            resetFunction={() => {
              closeAction();
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
  return (
    <div id="thread-form">
      {detailForm}
      {aiEnabled && generateDialogOpen && (
        <IppGenerateSummaryDialog
          titleKey="threads"
          fieldname={t("objStk:objects.thread.fields.summary")}
          data={formik.values}
          initialValue={formik.values.Summary}
          aiSummaryApiFunction={generateThreadSummaryWithAi}
          open={generateDialogOpen}
          setOpen={setGenerateDialogOpen}
          onSubmit={(value: string) => {
            formik.setFieldValue("Summary", value);
            setSummaryKey((prev) => prev + 1);

            if (!isEditing) formik.handleSubmit();
          }}
          standardStatementsRecordType="InteractionThreadSummary"
        />
      )}
    </div>
  );
};
