// import { useEffect } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { useAuth0 } from "@auth0/auth0-react";
// import { RootState } from "app/rootReducer";
// import { fetchClientPayments } from "./PaymentSlice";
// import { IppInventory } from "components/Inventory/IppInventory";
// import ErrorPage from "components/ErrorPage";
// import LoadingIndicator from "components/LoadingIndicator";
// import { ConvertDateOffset } from "../../../utils/DateFunctions";
// import { useTypedTranslation } from "utils/customHooks";

// interface paymentInvProps {
//   companyID?: number;
// }

// export const PaymentUploadReviewPage = (props: paymentInvProps) => {
//   const dispatch = useDispatch();
//   const { getAccessTokenSilently } = useAuth0();
//   const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

//   const {
//     paymentList,
//     isLoading,
//     error: paymentsError,
//     paymentsById,
//   } = useSelector((state: RootState) => state.payments);

//   const payments = paymentList.map((id) => paymentsById[id]);
//   const pmts = payments.map((p) => {
//     let newPayment = Object.assign({}, p);
//     newPayment.PaymentDate = ConvertDateOffset(p.PaymentDate);
//     return newPayment;
//   });

//   //filter out non-review payments

//   const pmtCols = [
//     { field: "CompanyName", title: t("objBen:objects.company.name") },
//     {
//       field: "PaymentDate",
//       title: t("objPlt:platformwide.fields.date"),
//       format: "{0:d}",
//     },
//     {
//       field: "PaymentAmount",
//       title: t("objBen:objects.payment.fields.net"),
//       format: "{0:c}",
//       filter: "numeric",
//     },
//     {
//       field: "ProjectComponentName",
//       title: t("objPlt:objects.projectcomponent.name"),
//     },
//     {
//       field: "WorkflowStageName",
//       chips: true,
//       title: t("strGen:inventory.fields.stage"),
//     },
//   ];

//   useEffect(() => {
//     (async () => {
//       try {
//         const accessToken = await getAccessTokenSilently({
//           authorizationParams: {
//             audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
//           },
//         });
//         dispatch(fetchClientPayments(accessToken));
//       } catch (e) {
//         console.error(e);
//       }
//     })();
//   }, [dispatch, getAccessTokenSilently]);

//   if (paymentsError) {
//     return <ErrorPage errMsg={paymentsError} />;
//   }

//   let paymentInvView = isLoading ? (
//     <LoadingIndicator />
//   ) : (
//     <div>
//       <IppInventory
//         title={t("strGen:inventory.headers.reviewuploadedobj", {
//           objectname: t("objBen:objects.payment.name", { count: 2 }),
//         })}
//         tableData={reviewPmts}
//         idField="PaymentID"
//         columns={pmtCols}
//         isFiltered={true}
//         showDetail={true}
//         showAdd={false}
//         showUpload={false}
//         showTemplate={false}
//       />
//     </div>
//   );

//   return <div id="payment-upload-review-page">{paymentInvView}</div>;
// };

export const PaymentUploadReviewPage = () => {
  return <div>Payment Upload Review Page</div>;
};

// WILL BE RE-WORKED TO BE A REVIEW PAYMENTS PAGE BASED ON MISSING FIELDS
