import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { addPayment, updPayment } from "./PaymentSlice";
import { Box, Grid, Typography } from "@mui/material";
import { ChangeEvent, useEffect } from "react";
import { IppAutocomplete } from "components/IppAutocomplete";
import { IppStaticTextfield } from "components/IppStaticTextfield";
import LoadingIndicator from "components/LoadingIndicator";
import { Prompt } from "react-router";
import { IppDatePicker } from "components/IppDatePicker";
import { ConvertDateOffset, GetJSDate } from "utils/DateFunctions";
import { PaymentValidation } from "./PaymentValidation";
import { useTypedTranslation } from "utils/customHooks";
import { IppCurrencyField } from "components/IppCurrencyField";
import { fetchCompanyShortForms } from "../companies/CompanyShortFormSlice";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { Company } from "api/companyAPI";
import { CompanyOptionsRender } from "utils/renderFunctions";

interface PaymentChildFormProps {
  closeAction: any;
  setDidSaveInventory: any;
  paymentPeriod: any;
  paymentID?: number;
}

export const PaymentChildForm = (props: PaymentChildFormProps) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen", "objSlr", "objPlt", "strGen"]);

  const { closeAction, setDidSaveInventory, paymentPeriod, paymentID } = props;

  const { isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const {
    paymentsById,
    paymentList,
    isLoading: paymentIsLoading,
  } = useSelector((state: RootState) => state.payments);
  const payments = paymentList.map((PaymentID) => paymentsById[PaymentID]);
  const payment = payments.find((pmt) => pmt.PaymentID === paymentID);

  const {
    companyShortFormList,
    companyShortFormsById,
    isLoading: companyShortFormIsLoading,
  } = useSelector((state: RootState) => state.companyShortForms);

  const companies = companyShortFormList
    .map((CompanyID: number) => companyShortFormsById[CompanyID])
    .filter((company) => {
      const isCompanyActive = company.ShowAsActive.toLowerCase() === "active";

      // Show all companies for IsClient and IsClientAdmin, and only active companies for contractors/company users
      return (
        (currentProfile.IsClient ||
          currentProfile.IsClientAdmin ||
          isCompanyActive) &&
        company.CompanyID !== paymentPeriod.CompanyID
      );
    });

  let paymentData: any;
  if (payment) {
    paymentData = payment;
  } else
    paymentData = {
      PaymentPeriodID: paymentPeriod.PaymentPeriodID,
      ProjectID: paymentPeriod.ProjectID,
      ProjectComponentID: paymentPeriod.ProjectComponentID,
      CurrencyType: paymentPeriod.Currency,
      ContractID: paymentPeriod.ContractID,
      PaidByCompanyID: paymentPeriod.CompanyID,
      PaymentDate: paymentPeriod.EndDate,
      CompanyID: -1,
      CompanyName: "",
      PaymentAmount: 0,
    };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchCompanyShortForms(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        if (payment) {
          dispatch(updPayment(accessToken, values.PaymentID, values, true));
        } else {
          dispatch(addPayment(accessToken, values, true));
        }
        setDidSaveInventory(true);
        closeAction();
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const formik = useFormik({
    initialValues: paymentData,
    validationSchema: PaymentValidation(paymentPeriod),
    onSubmit: onSub,
  });

  let pageForm =
    paymentIsLoading || clientIsLoading || companyShortFormIsLoading ? (
      <LoadingIndicator />
    ) : (
      <form noValidate onSubmit={formik.handleSubmit}>
        {!formik.isSubmitting && (
          <Prompt
            when={formik.dirty}
            message={t("strGen:prompts.unsavedchanges")}
          />
        )}

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">
              {payment ? t("strGen:buttons.edit") : t("strGen:buttons.addnew")}{" "}
              {t("objBen:objects.payment.name")}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <IppAutocomplete
              id="CompanyID"
              options={companies.sort((a, b) =>
                a.ShowAsActive.localeCompare(b.ShowAsActive)
              )}
              groupBy={(option: any) => option.ShowAsActive}
              value={companies.find((obj) => {
                return obj.CompanyID === formik.values.CompanyID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("CompanyID", newValue.CompanyID);
                } else {
                  formik.setFieldValue("CompanyID", -1);
                }
              }}
              label={t("objBen:objects.payment.fields.paidto")}
              required={true}
              isEditing={true}
              setIsEditing={null}
              optionLabelFunction={(option: any) => option.CompanyName}
              renderOption={(props: any, option: Company) => {
                return CompanyOptionsRender(props, option);
              }}
              textValueFunction={formik.values.CompanyName}
              errorFunction={formik.errors.CompanyID}
              touchedFunction={formik.touched.CompanyID}
            />
          </Grid>
          <Grid item xs={6}>
            <IppDatePicker
              id="PaymentDate"
              label={t("objBen:objects.spendreporting.fields.spenddate")}
              required={true}
              value={ConvertDateOffset(formik.values.PaymentDate)}
              disableFuture={true}
              onChangeFunction={(newValue: any) => {
                formik.setFieldValue("PaymentDate", GetJSDate(newValue), true);
                formik.setFieldTouched("PaymentDate", true, false);
              }}
              errorsExpression={formik.errors.PaymentDate}
              touchedExpression={formik.touched.PaymentDate}
              isEditing={true}
              setIsEditing={null}
            />
          </Grid>
          <Grid item xs={6}>
            <IppCurrencyField
              id="PaymentAmount"
              label={t("objBen:objects.spendreporting.fields.spendamount")}
              required
              value={formik.values.PaymentAmount}
              onChangeFunction={(newValue) =>
                formik.setFieldValue("PaymentAmount", newValue)
              }
              touchedExpression={formik.touched.PaymentAmount}
              errorsExpression={formik.errors.PaymentAmount}
              isEditing
            />
          </Grid>
          <Grid item xs={6}>
            <IppStaticTextfield
              id="CurrencyType"
              label={t("objBen:objects.payment.fields.currency")}
              value={formik.values.CurrencyType}
            />
          </Grid>
          <Grid item xs={12}>
            <IppFormButtons
              isEditing={true}
              setIsEditing={null}
              isAdding={!paymentID}
              showCancel={true}
              resetFunction={closeAction}
            />
          </Grid>
        </Grid>
      </form>
    );

  return <div id="payment-child-form">{pageForm}</div>;
};
