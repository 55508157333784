import axiosClient from "../axiosClient";

export interface AssetPercentage {
  AssetPercentageID: number;
  AssetID: number;
  Percentage: number;
  CompanyID: number;
  AssetName?: string;
  CompanyName?: string;
}

export interface DisplayAssetPercentage extends Partial<AssetPercentage> {
  WholePercentage?: string; // Optional as it's only used in the frontend
}

export interface AssetPercentagesResult {
  assetPercentages: AssetPercentage[];
}

export const getAssetPercentages = async (
  accessToken: String
): Promise<AssetPercentagesResult> => {
  const url = `/assetPercentage`;
  try {
    const { data } = await axiosClient.get<AssetPercentagesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAssetPercentagesByAsset = async (
  accessToken: String,
  assetID: number
): Promise<AssetPercentagesResult> => {
  const url = `/assetPercentage/${assetID}`;

  try {
    const { data } = await axiosClient.get<AssetPercentagesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createAssetPercentage = async (
  accessToken: String,
  assetPercentage: Partial<AssetPercentage>
): Promise<AssetPercentage> => {
  const url = `/assetPercentage`;
  // odd scenaria where percent is possibly set on create, needs flow optimized
  delete assetPercentage.AssetPercentageID;

  try {
    const { data } = await axiosClient.post<AssetPercentage>(
      url,
      assetPercentage,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateAssetPercentage = async (
  accessToken: String,
  assetPercentageID: number,
  assetPercentage: Partial<AssetPercentage>
): Promise<AssetPercentage> => {
  const url = `/assetPercentage/${assetPercentageID}`;

  try {
    const { data } = await axiosClient.post<AssetPercentage>(
      url,
      assetPercentage,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteAssetPercentage = async (
  accessToken: String,
  assetPercentageID: number
): Promise<void> => {
  const url = `/assetPercentage/${assetPercentageID}`;

  try {
    await axiosClient.delete(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};
