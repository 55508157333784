import { Alert, AlertTitle, ButtonGroup, Grid } from "@mui/material";
import { ReactElement } from "react";
import { CSSProperties } from "react";
import { useTypedTranslation } from "utils/customHooks";

export interface AlertButtonProps {
  onClick: () => void;
  startIcon: JSX.Element;
  disabled: boolean;
  color?: CSSProperties["color"];
  text: string;
  tooltipMessage?: string;
  //gridSize?: number;
}

type ButtonsArrayProps = AlertButtonProps;

interface IppAlertHeaderProps {
  header: string;
  description: any;
  buttons?: ButtonsArrayProps[] | typeof ButtonGroup | ReactElement;
  type: "info" | "warning" | "error";
  width?: string;
}

export const IppAlertHeader = (props: IppAlertHeaderProps) => {
  const { header, description, buttons, type } = props;
  const t = useTypedTranslation(["strGen"]);

  const defaultDescription = t(
    "strGen:components.ippalertheader.defaultdescription"
  );

  return (
    <Grid>
      <Alert severity={type} action={buttons}>
        <AlertTitle>{header}</AlertTitle>
        {description || defaultDescription}
      </Alert>
    </Grid>
  );
};

IppAlertHeader.defaultProps = {
  type: "warning",
};
