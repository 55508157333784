import axiosClient from "./axiosClient";

export interface ClientCurrency {
  ClientCurrencyID: number;
  ClientID: number;
  //CurrencyID: number;
  CurrencyName: string;
  IsRequired: boolean;
}

export interface ClientCurrenciesResult {
  clientCurrencies: ClientCurrency[];
}

export async function getClientCurrencies(
  accessToken: String
): Promise<ClientCurrenciesResult> {
  const url = `/clientCurrency`;

  try {
    //console.log("API START")
    const { data } = await axiosClient.get<ClientCurrenciesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    //console.log("API END")

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createClientCurrency(
  accessToken: String,
  newClientCurrency: Partial<ClientCurrency>
): Promise<ClientCurrency> {
  const url = `/ClientCurrency`;

  try {
    const { data } = await axiosClient.post<ClientCurrency>(
      url,
      newClientCurrency,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateClientCurrency(
  accessToken: String,
  ClientCurrencyID: number,
  newClientCurrency: Partial<ClientCurrency>
): Promise<ClientCurrency> {
  const url = `/ClientCurrency/` + ClientCurrencyID;

  try {
    //console.log("UPDATE START")
    const { data } = await axiosClient.post<ClientCurrency>(
      url,
      newClientCurrency,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteClientCurrency(
  accessToken: String,
  ClientCurrencyID: number
): Promise<string> {
  const url = `/ClientCurrency/` + ClientCurrencyID;
  try {
    //console.log("DEL START")
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    //console.log("DELDATA", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}
