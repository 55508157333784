import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ClientModule,
  ClientModulesResult,
  getClientModules,
} from "api/clientModuleAPI";
import { AppThunk } from "app/store";

interface ClientModuleState {
  clientModulesById: Record<number, ClientModule>;
  clientModuleList: number[];
  isLoading: boolean;
  error: string | null;
}

const ClientModuleInitialState: ClientModuleState = {
  clientModulesById: {},
  clientModuleList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: ClientModuleState) {
  state.isLoading = true;
}

function loadingFailed(
  state: ClientModuleState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const clientModules = createSlice({
  name: "clientmodule",
  initialState: ClientModuleInitialState,
  reducers: {
    getClientModulesStart: startLoading,
    getClientModulesSuccess(
      state,
      { payload }: PayloadAction<ClientModulesResult>
    ) {
      const { clientModules } = payload;
      // console.log("PAYLOAD", clientModules)
      state.isLoading = false;
      state.error = null;

      clientModules.forEach((clientModule) => {
        state.clientModulesById[clientModule.ClientModuleID] = clientModule;
      });

      state.clientModuleList = clientModules.map(
        (clientModule) => clientModule.ClientModuleID
      );
    },
    getClientModulesFailure: loadingFailed,
  },
});

export const {
  getClientModulesStart,
  getClientModulesSuccess,
  getClientModulesFailure,
} = clientModules.actions;

export default clientModules.reducer;

export const fetchClientModules =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getClientModulesStart());
      const clientModules = await getClientModules(accessToken);
      //console.log("RETURN", clientModules)
      dispatch(getClientModulesSuccess(clientModules));
    } catch (err: any) {
      dispatch(getClientModulesFailure(err.toString()));
    }
  };
