import axiosClient from "../axiosClient";

export interface GrievanceIssue {
  GrievanceIssueID: number;
  GrievanceID: number;
  IssueID: number;
  Details: string;
  GrievanceName: string;
  IssueName: string;
  ClientID: number;
  GrievanceStatusText: string;
  ReportedDate: Date;
  ResolutionDate: Date;
  GrievanceProjectID: number;
  SequenceID: number;
}

export interface GrievanceIssuesResult {
  grievanceIssues: GrievanceIssue[];
}

// NOT BEING USED

// export async function getGrievanceIssues(
//   accessToken: String
// ): Promise<GrievanceIssuesResult> {
//   const url = `/grievanceIssue`;

//   try {
//     const { data } = await axiosClient.get<GrievanceIssuesResult>(url, {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//     });

//     return data;
//   } catch (err: any) {
//     throw err;
//   }
// }

export async function getGrievanceIssuesByIssue(
  accessToken: String,
  issueID: number
): Promise<GrievanceIssuesResult> {
  const url = `/grievanceIssue/issue/${issueID}`;

  try {
    const { data } = await axiosClient.get<GrievanceIssuesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getGrievanceIssuesByGrievance(
  accessToken: String,
  grievanceID: number
): Promise<GrievanceIssuesResult> {
  const url = `/grievanceIssue/grievance/${grievanceID}`;

  try {
    const { data } = await axiosClient.get<GrievanceIssuesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

// NOT BEING USED

// export async function createGrievanceIssue(
//   accessToken: String,
//   newCommInv: Partial<GrievanceIssue>
// ): Promise<GrievanceIssue> {
//   const url = `/grievanceIssue`;

//   try {
//     const { data } = await axiosClient.post<GrievanceIssue>(url, newCommInv, {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//         "Content-Type": "application/json",
//       },
//     });

//     return data;
//   } catch (err: any) {
//     throw err;
//   }
// }

// export async function updateGrievanceIssue(
//   accessToken: String,
//   commInvID: number,
//   newCommInv: Partial<GrievanceIssue>
// ): Promise<GrievanceIssue> {
//   const url = `/grievanceIssue/` + commInvID;

//   try {
//     const { data } = await axiosClient.post<GrievanceIssue>(url, newCommInv, {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//         "Content-Type": "application/json",
//       },
//     });

//     return data;
//   } catch (err: any) {
//     throw err;
//   }
// }

export async function deleteGrievanceIssue(
  accessToken: String,
  commInvID: number
): Promise<string> {
  const url = `/grievanceIssue/` + commInvID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
