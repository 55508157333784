import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CompanyProject,
  CompanyProjectsResult,
  getCompanyProjects,
  createCompanyProject,
  updateCompanyProject,
  deleteCompanyProject,
  getCompanyProjectsByCompany,
  getCompanyProjectsByProject,
} from "api/companyProjectAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface CompanyProjectState {
  companyProjectsById: Record<number, CompanyProject>;
  companyProjectList: number[];
  isLoading: boolean;
  error: string | null;
}

const CompanyProjectInitialState: CompanyProjectState = {
  companyProjectsById: {},
  companyProjectList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: CompanyProjectState) {
  state.isLoading = true;
}

function loadingFailed(
  state: CompanyProjectState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const companyProjects = createSlice({
  name: "companyProjects",
  initialState: CompanyProjectInitialState,
  reducers: {
    getCompanyProjectsStart: startLoading,
    getCompanyProjectsSuccess(
      state,
      { payload }: PayloadAction<CompanyProjectsResult>
    ) {
      const { companyProjects } = payload;
      // console.log("PAYLOAD", companyProjects);
      state.isLoading = false;
      state.error = null;

      companyProjects.forEach((companyProject) => {
        state.companyProjectsById[companyProject.CompanyProjectID] =
          companyProject;
      });

      state.companyProjectList = companyProjects.map(
        (companyProject) => companyProject.CompanyProjectID
      );
    },
    getCompanyProjectsFailure: loadingFailed,
    createCompanyProjectStart: startLoading,
    createCompanyProjectSuccess(
      state,
      { payload }: PayloadAction<CompanyProject>
    ) {
      const { CompanyProjectID } = payload;
      state.companyProjectsById[CompanyProjectID] = payload;
      state.companyProjectList.push(CompanyProjectID);

      state.isLoading = false;
      state.error = null;
    },
    updateCompanyProjectSuccess(
      state,
      { payload }: PayloadAction<CompanyProject>
    ) {
      const { CompanyProjectID } = payload;
      state.companyProjectsById[CompanyProjectID] = payload;

      state.isLoading = false;
      state.error = null;
    },
    deleteCompanyProjectSuccess(state, { payload }: PayloadAction<number>) {
      const CompanyProjectID = payload;
      delete state.companyProjectsById[CompanyProjectID];
      state.companyProjectList = state.companyProjectList.filter(
        (item) => item !== CompanyProjectID
      );

      state.isLoading = false;
      state.error = null;
    },
    createCompanyProjectFailure: loadingFailed,
  },
});

export const {
  getCompanyProjectsStart,
  getCompanyProjectsSuccess,
  getCompanyProjectsFailure,
  createCompanyProjectStart,
  createCompanyProjectSuccess,
  updateCompanyProjectSuccess,
  deleteCompanyProjectSuccess,
  createCompanyProjectFailure,
} = companyProjects.actions;

export default companyProjects.reducer;

export const fetchCompanyProjects =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCompanyProjectsStart());
      const companyProjects = await getCompanyProjects(accessToken);
      //console.log("RETURN", companyProjects);
      dispatch(getCompanyProjectsSuccess(companyProjects));
    } catch (err: any) {
      dispatch(getCompanyProjectsFailure(err.toString()));
    }
  };

export const fetchCompanyProjectsByCompany =
  (accessToken: String, companyID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCompanyProjectsStart());
      const companyProjects = await getCompanyProjectsByCompany(
        accessToken,
        companyID
      );
      //console.log("RETURN", companyProjects);
      dispatch(getCompanyProjectsSuccess(companyProjects));
    } catch (err: any) {
      dispatch(getCompanyProjectsFailure(err.toString()));
    }
  };

export const fetchCompanyProjectsByProject =
  (accessToken: String, projectID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCompanyProjectsStart());
      const companyProjects = await getCompanyProjectsByProject(
        accessToken,
        projectID
      );
      //console.log("RETURN", companyProjects);
      dispatch(getCompanyProjectsSuccess(companyProjects));
    } catch (err: any) {
      dispatch(getCompanyProjectsFailure(err.toString()));
    }
  };

export const addCompanyProject =
  (
    accessToken: String,
    newCompanyProject: Partial<CompanyProject>,
    fetchID: number,
    showField: string
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCompanyProjectStart());
      const companyProject = await createCompanyProject(
        accessToken,
        newCompanyProject
      );
      dispatch(createCompanyProjectSuccess(companyProject));
      showField === "CompanyName"
        ? dispatch(fetchCompanyProjectsByProject(accessToken, fetchID))
        : dispatch(fetchCompanyProjectsByCompany(accessToken, fetchID));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      // dispatch(push("/companyProjects/new"));
    } catch (err: any) {
      dispatch(createCompanyProjectFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updCompanyProject =
  (
    accessToken: String,
    companyProjectID: number,
    newCompanyProject: Partial<CompanyProject>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCompanyProjectStart());
      const companyProject = await updateCompanyProject(
        accessToken,
        companyProjectID,
        newCompanyProject
      );
      dispatch(updateCompanyProjectSuccess(companyProject));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      // dispatch(push("/companyProjects/" + companyProjectID));
    } catch (err: any) {
      dispatch(createCompanyProjectFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delCompanyProject =
  (accessToken: String, companyProjectID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCompanyProjectStart());
      const result = await deleteCompanyProject(accessToken, companyProjectID);
      dispatch(deleteCompanyProjectSuccess(companyProjectID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
      // dispatch(push("/companyProjects/" + companyProjectID));
    } catch (err: any) {
      dispatch(createCompanyProjectFailure(err.toString()));
    }
  };
