import * as yup from "yup";
import { useTypedTranslation } from "utils/customHooks";

export const ContractValidationSchema = () => {
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  return yup.object({
    ContractNumber: yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.contract.fields.number"),
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.contract.fields.number"),
          count: 50,
        })
      ),
    CompanyID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.company.name"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.company.name"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.company.name"),
        })
      ),
    ProjectID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.project.name"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.project.name"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:objects.project.name"),
        })
      ),
    ContractTitle: yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.contract.fields.title"),
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.contract.fields.title"),
          count: 50,
        })
      ),
    AwardDate: yup
      .date()
      .nullable()
      .max(
        new Date(),
        t("strGen:validation.date.nofuturedate", {
          fieldname: t("objBen:objects.contract.fields.awarddate"),
        })
      )
      .typeError(t("strGen:validation.date.improperformat")),
    ExpiryDate: yup
      .date()
      .nullable()
      .min(
        yup.ref("AwardDate"),
        t("strGen:validation.date.notearlierthan", {
          fieldname: t("objBen:objects.contract.fields.expirydate"),
          fieldname2: t("objBen:objects.contract.fields.awarddate"),
        })
      )
      .typeError(t("strGen:validation.date.improperformat")),
    EmployeeTarget: yup
      .number()
      .positive()
      .nullable()
      .min(
        0,
        t("strGen:validation.percentdecimalrange", {
          fieldname: t("objBen:objects.contract.fields.targets.name"),
        })
      )
      .max(
        1,
        t("strGen:validation.percentdecimalrange", {
          fieldname: t("objBen:objects.contract.fields.targets.name"),
        })
      )
      .typeError(t("strGen:validation.invalidnumber")),
    HoursTarget: yup
      .number()
      .positive()
      .nullable()
      .min(
        0,
        t("strGen:validation.percentdecimalrange", {
          fieldname: t("objBen:objects.contract.fields.targets.name"),
        })
      )
      .max(
        1,
        t("strGen:validation.percentdecimalrange", {
          fieldname: t("objBen:objects.contract.fields.targets.name"),
        })
      )
      .typeError(t("strGen:validation.invalidnumber")),
    ExpenditureTarget: yup
      .number()
      .positive()
      .nullable()
      .min(
        0,
        t("strGen:validation.percentdecimalrange", {
          fieldname: t("objBen:objects.contract.fields.targets.name"),
        })
      )
      .max(
        1,
        t("strGen:validation.percentdecimalrange", {
          fieldname: t("objBen:objects.contract.fields.targets.name"),
        })
      )
      .typeError(t("strGen:validation.invalidnumber")),
    IsLocal: yup.boolean().nullable(),
    IsIndigenous: yup.boolean().nullable(),
    ContractScope: yup.string().nullable(),
    ContractNotes: yup.string().nullable(),
    SpendReportingRequired: yup.boolean().nullable(),
    EmployeeReportingRequired: yup.boolean().nullable(),
    IsActive: yup.boolean().nullable(),
  });
};
