import { WorkHourPeriodForm } from "./WorkHourPeriodForm";

export const WorkHourPeriodCreatePage = () => {
  let workHourPeriodCreateView = (
    <div>
      <WorkHourPeriodForm />
    </div>
  );

  return <div id="workHourPeriod-detail-page">{workHourPeriodCreateView}</div>;
};
