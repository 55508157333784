import { NotificationTemplateForm } from "./NotificationTemplateForm";

export const NotificationTemplateCreatePage = () => {
  let notificationTemplateCreateView = (
    <div>
      <NotificationTemplateForm />
    </div>
  );

  return (
    <div id="notificationTemplate-detail-page">
      {notificationTemplateCreateView}
    </div>
  );
};
