import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ContactGroup,
  ContactGroupsResult,
  getContactGroups,
  getContactGroupsByGroup,
  getContactGroupsByContact,
  updateContactGroup,
  deleteContactGroup,
} from "api/stakeholder/contactGroupAPI";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { SnackBarConstants } from "utils/customHooks";

interface ContactGroupState {
  contactGroupsById: Record<number, ContactGroup>;
  contactGroupList: number[];
  isLoading: boolean;
  subContactGroupisLoading: boolean;
  error: string | null;
}

const ContactGroupInitialState: ContactGroupState = {
  contactGroupsById: {},
  contactGroupList: [],
  isLoading: false,
  subContactGroupisLoading: false,
  error: null,
};

function startLoading(state: ContactGroupState) {
  state.isLoading = true;
}

function loadingFailed(
  state: ContactGroupState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const contactGroups = createSlice({
  name: "contactGroups",
  initialState: ContactGroupInitialState,
  reducers: {
    getContactGroupsStart: startLoading,
    getContactGroupsSuccess(
      state,
      { payload }: PayloadAction<ContactGroupsResult>
    ) {
      const { contactGroups } = payload;

      state.isLoading = false;
      state.error = null;

      contactGroups.forEach((contactGroup) => {
        state.contactGroupsById[contactGroup.ContactGroupID] = contactGroup;
      });

      state.contactGroupList = contactGroups.map(
        (contactGroup) => contactGroup.ContactGroupID
      );
    },
    getContactGroupsFailure: loadingFailed,
    createContactGroupStart: startLoading,
    createContactGroupSuccess(state, { payload }: PayloadAction<ContactGroup>) {
      const { ContactGroupID } = payload;
      state.contactGroupsById[ContactGroupID] = payload;
      state.contactGroupList.push(ContactGroupID);

      state.isLoading = false;
      state.error = null;
    },
    updateContactGroupSuccess(state, { payload }: PayloadAction<ContactGroup>) {
      const { ContactGroupID } = payload;

      // front end fix to show other ISPrimary as false
      /* state.contactGroupList.forEach(cgID => {
        state.contactGroupsById[cgID].IsPrimary = false;
      }); */

      state.contactGroupsById[ContactGroupID] = payload;
      //state.contactGroupList.push(ContactGroupID);

      state.isLoading = false;
      state.error = null;
    },
    deleteContactGroupSuccess(state, { payload }: PayloadAction<number>) {
      const ContactGroupID = payload;
      delete state.contactGroupsById[ContactGroupID];
      state.contactGroupList = state.contactGroupList.filter(
        (item) => item !== ContactGroupID
      );

      state.isLoading = false;
      state.error = null;
    },
    createContactGroupFailure: loadingFailed,
  },
});

export const {
  getContactGroupsStart,
  getContactGroupsSuccess,
  getContactGroupsFailure,
  createContactGroupStart,
  createContactGroupSuccess,
  updateContactGroupSuccess,
  deleteContactGroupSuccess,
  createContactGroupFailure,
} = contactGroups.actions;

export default contactGroups.reducer;

export const fetchContactGroups =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getContactGroupsStart());
      const contactGroups = await getContactGroups(accessToken);
      dispatch(getContactGroupsSuccess(contactGroups));
    } catch (err: any) {
      dispatch(getContactGroupsFailure(err.toString()));
    }
  };

export const fetchContactGroupsByGroup =
  (accessToken: String, groupID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getContactGroupsStart());
      const contactGroups = await getContactGroupsByGroup(accessToken, groupID);
      dispatch(getContactGroupsSuccess(contactGroups));
    } catch (err: any) {
      dispatch(getContactGroupsFailure(err.toString()));
    }
  };

export const fetchContactGroupsByContact =
  (accessToken: String, contactID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getContactGroupsStart());
      const contactGroups = await getContactGroupsByContact(
        accessToken,
        contactID
      );
      dispatch(getContactGroupsSuccess(contactGroups));
    } catch (err: any) {
      dispatch(getContactGroupsFailure(err.toString()));
    }
  };

//! COMMENTED OUT FOR FUTURE CLEAN UP
// export const addContactGroup =
//   (accessToken: String, newContactGroup: Partial<ContactGroup>): AppThunk =>
//   async (dispatch) => {
//     try {
//       dispatch(createContactGroupStart());
//       const contactGroup = await createContactGroup(
//         accessToken,
//         newContactGroup
//       );
//       dispatch(createContactGroupSuccess(contactGroup));
//       dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
//     } catch (err: any) {
//       dispatch(createContactGroupFailure(err.toString()));
//       dispatch(openSnackBar(Constants.FAILED, "error"));
//     }
//   };

export const updContactGroup =
  (
    accessToken: String,
    contactGroupID: number,
    newContactGroup: Partial<ContactGroup>,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createContactGroupStart());
      const contactGroup = await updateContactGroup(
        accessToken,
        contactGroupID,
        newContactGroup
      );

      dispatch(updateContactGroupSuccess(contactGroup));
      if (newContactGroup.GroupID) {
        dispatch(
          fetchContactGroupsByGroup(accessToken, newContactGroup.GroupID)
        );
      }
      dispatch(openSnackBar(snackbarConstants.UPDATE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createContactGroupFailure(err.toString()));
      dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
    }
  };

export const delContactGroup =
  (
    accessToken: String,
    contactGroupID: number,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createContactGroupStart());
      const result = await deleteContactGroup(accessToken, contactGroupID);
      dispatch(deleteContactGroupSuccess(contactGroupID));
      dispatch(openSnackBar(snackbarConstants.DELETE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createContactGroupFailure(err.toString()));
      dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
    }
  };
