import { Box, Divider, ListItem, Typography } from "@mui/material";
import { Company } from "api/companyAPI";
import { Contact } from "api/stakeholder/contactAPI";
import { User } from "api/usersAPI";

export const AutoCompleteDivider = () => {
  return (
    <Divider sx={{ my: 0.5, mx: 2, borderColor: "rgba(0, 0, 0, 0.05)" }} />
  );
};

export const defaultRenderOption = (
  props: any,
  option: any,
  optionLabelFunction?: (option: any) => string
) => {
  return (
    <>
      <ListItem
        {...props}
        variant={"body1"}
        sx={{
          flexWrap: "wrap",
        }}
      >
        {optionLabelFunction ? optionLabelFunction(option) : option}
      </ListItem>
      <AutoCompleteDivider />
    </>
  );
};

export const CompanyOptionsRender = (props: any, option: Company) => {
  const location =
    option.TownCity || option.ProvinceState
      ? ` - ${option.TownCity ?? ""}, ${option.ProvinceState ?? ""}`
      : "";

  return (
    <>
      <Box
        {...props}
        sx={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Typography variant={"body1"}>{option.CompanyName}</Typography>
        {location && (
          <Typography sx={{ ml: 1 }} variant={"subtitle2"} color="primary">
            {location}
          </Typography>
        )}
      </Box>
      <AutoCompleteDivider />
    </>
  );
};

export const PersonRenderOption = (
  props: any,
  option: Contact | User,
  textArray: string[],
  defaultName: string
) => {
  const personName = option.FirstName + " " + option.Surname;
  const text = textArray.find(Boolean);
  return (
    <>
      <Box
        {...props}
        key={"ContactID" in option ? option.ContactID : option.UserAccountID}
      >
        <Typography>{personName !== " " ? personName : defaultName}</Typography>
        <Typography mx={1} variant={"subtitle2"} color="primary">
          [{text}]
        </Typography>
      </Box>
      <AutoCompleteDivider />
    </>
  );
};
