// Delete a sub-item from stakeholder consultation form
import { useAuth0 } from "@auth0/auth0-react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { delRnDExpense } from "features/benefits/RnDExpense/RnDExpenseSlice";
import { delEnTExpense } from "features/benefits/entExpenses/EnTExpenseSlice";
import { delJobHistory } from "features/benefits/jobHistory/JobHistorySlice";
import { delPayment } from "features/benefits/payments/PaymentSlice";
import { delWorkHourDetail } from "features/benefits/workHours/WorkHourDetailSlice";
import { delCommitmentInteraction } from "features/crossModule/stakeholderCommitment/commitmentInteractionSlice";
import { delContactGroup } from "features/stakeholder/contactGroup/ContactGroupSlice";
import { delContactInteraction } from "features/stakeholder/contactInteraction/ContactInteractionSlice";
import { delContactIssue } from "features/stakeholder/contactIssue/ContactIssueSlice";
import { delGrievanceContact } from "features/stakeholder/grievanceContact/GrievanceContactSlice";
import { delGrievanceGroup } from "features/stakeholder/grievanceGroup/GrievancGroupSlice";
import { delGrievanceInteraction } from "features/stakeholder/grievanceInteraction/GrievanceInteractionSlice";
import { delGrievanceIssue } from "features/stakeholder/grievanceIssue/GrievanceIssueSlice";
import { delGroupInteraction } from "features/stakeholder/groupInteraction/GroupInteractionSlice";
import { delGroupIssue } from "features/stakeholder/groupIssue/GroupIssueSlice";
import { delInteractionComment } from "features/stakeholder/interactionComment/InteractionCommentSlice";
import { delInteractionIssue } from "features/stakeholder/interactionIssue/InteractionIssueSlice";
import { useDispatch } from "react-redux";
import { IppDeleteButton } from "./Buttons/IppDeleteButton";
import { IppCancelButton } from "./Buttons/IppCancelButton";
import { useSnackBarConstants, useTypedTranslation } from "utils/customHooks";
import { Trans } from "react-i18next";
import { delThreadInteraction } from "features/stakeholder/thread/ThreadInteractionSlice";

export type DeleteMessageType = "NameValueFirst" | "NameWithTitle" | "Default";
export type DeleteNoteType = "Delete" | "DeletePlural" | "Unlink";

interface ippChildDeleteProps {
  title: string; // title to show on sub page
  parentTitle: string; // title to show for container page
  itemID: number; // id value for current record
  nameValue: string; // descriptive name
  isOpen: boolean; // whether dialog is open or not
  closeAction: any; // action to close dialog
  tableName: string;
  parentID: number;
  deleteMessageType?: DeleteMessageType;
  deleteNoteType?: DeleteNoteType;
  deleteNameValueTitle?: string;
}

export const IppChildDelete = (props: ippChildDeleteProps) => {
  const {
    title,
    parentTitle,
    itemID,
    nameValue,
    isOpen,
    closeAction,
    tableName,
    parentID,
    deleteMessageType = "Default",
    deleteNoteType = "Unlink",
    deleteNameValueTitle = "",
  } = props;
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["strGen"]);
  const snackbarConstants = useSnackBarConstants();

  const messageConfigs: Record<
    string,
    (
      nameValue: string,
      title: string,
      parentTitle: string,
      nameValueTitle: string
    ) => JSX.Element
  > = {
    NameValueFirst: (nameValue, title, parentTitle) => (
      <>
        <Trans
          i18nKey={t(
            "strGen:components.ippchilddelete.messages.namevaluefirst"
          )}
          components={[<strong />]}
          values={{
            namevalue: nameValue,
            title: title,
            parenttitle: parentTitle,
          }}
        />
      </>
    ),
    NameWithTitle: (nameValue, title, parentTitle, nameValueTitle) => (
      <>
        <Trans
          i18nKey={t("strGen:components.ippchilddelete.messages.namewithtitle")}
          components={[<strong />]}
          values={{
            namevaluetitle: nameValueTitle,
            namevalue: nameValue,
            title: title,
            parenttitle: parentTitle,
          }}
        />
      </>
    ),
    Default: (nameValue, title, parentTitle) => (
      <>
        <Trans
          i18nKey={t("strGen:components.ippchilddelete.messages.default")}
          components={[<strong />]}
          values={{
            namevalue: nameValue,
            title: title,
            parenttitle: parentTitle,
          }}
        />
      </>
    ),
  };

  const notesConfig: Record<
    string,
    (title: string, parentTitle: string) => string
  > = {
    Delete: (title) =>
      t("strGen:components.ippchilddelete.notes.delete", { title: title }),
    DeletePlural: (title) =>
      t("strGen:components.ippchilddelete.notes.deleteplural", {
        title: title,
      }),
    Unlink: (title, parentTitle) =>
      t("strGen:components.ippchilddelete.notes.unlink", {
        title: title,
        parenttitle: parentTitle,
      }),
  };

  const handleDelete = () => {
    // function to delete current community investment entry
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        switch (tableName) {
          case "ContactGroup":
            dispatch(delContactGroup(accessToken, itemID, snackbarConstants));
            break;
          case "ContactInteraction":
            dispatch(
              delContactInteraction(accessToken, itemID, snackbarConstants)
            );
            break;
          case "GroupInteraction":
            dispatch(
              delGroupInteraction(accessToken, itemID, snackbarConstants)
            );
            break;
          case "InteractionIssue":
            dispatch(
              delInteractionIssue(accessToken, itemID, snackbarConstants)
            );
            break;
          case "GrievanceInteraction":
            dispatch(
              delGrievanceInteraction(accessToken, itemID, snackbarConstants)
            );
            break;
          case "GrievanceGroup":
            dispatch(delGrievanceGroup(accessToken, itemID, snackbarConstants));
            break;
          case "GrievanceContact":
            dispatch(
              delGrievanceContact(accessToken, itemID, snackbarConstants)
            );
            break;
          case "GrievanceIssue":
            dispatch(delGrievanceIssue(accessToken, itemID, snackbarConstants));
            break;
          case "InteractionComment":
            dispatch(
              delInteractionComment(accessToken, itemID, snackbarConstants)
            );
            break;
          case "GroupIssue":
            dispatch(delGroupIssue(accessToken, itemID, snackbarConstants));
            break;
          case "ContactIssue":
            dispatch(delContactIssue(accessToken, itemID, snackbarConstants));
            break;
          case "CommitmentInteraction":
            dispatch(delCommitmentInteraction(accessToken, itemID));
            break;
          case "InteractionThread":
            dispatch(delThreadInteraction(accessToken, itemID));
            break;
          case "Payments":
            dispatch(delPayment(accessToken, itemID, parentID, true));
            break;
          case "JobHistory":
            dispatch(delJobHistory(accessToken, itemID, parentID));
            break;
          case "RnDExpense":
            dispatch(delRnDExpense(accessToken, itemID, false));
            break;
          case "EnTExpense":
            dispatch(delEnTExpense(accessToken, itemID, false));
            break;
          case "PeriodWorkHourDetails":
            dispatch(delWorkHourDetail(accessToken, itemID));
            break;
          default:
            break;
        }
        closeAction();
      } catch (e) {
        console.error(e);
      }
    })();
  };

  return (
    <>
      {isOpen && (
        <Dialog open={isOpen} onClose={closeAction}>
          <DialogTitle>
            {t("strGen:components.ippchilddelete.dialogtitle")}
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              {messageConfigs[deleteMessageType](
                nameValue,
                title,
                parentTitle,
                deleteNameValueTitle
              )}
            </Typography>
            <Typography variant="caption">
              {notesConfig[deleteNoteType](title, parentTitle)}
            </Typography>
          </DialogContent>
          <DialogActions>
            <IppDeleteButton
              buttonText={t("strGen:buttons.removeitem")}
              onClick={handleDelete}
            />
            <IppCancelButton onClick={closeAction} />
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
