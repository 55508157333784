import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  WorkHourDetail,
  WorkHourDetailsResult,
  getWorkHourDetails,
  getWorkHourDetailsByPeriod,
  createWorkHourDetail,
  updateWorkHourDetail,
  deleteWorkHourDetail,
  getWorkHourDetailsByEmployee,
  bulkDeleteWorkHourDetailByPeriod,
} from "api/workHourDetailAPI";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { push } from "redux-first-history";
import { fetchWorkHourPeriods } from "./WorkHourPeriodSlice";

interface WorkHourDetailState {
  workHourDetailsById: Record<number, WorkHourDetail>;
  workHourDetailList: number[];
  isLoading: boolean;
  error: string | null;
}

const WorkHourDetailInitialState: WorkHourDetailState = {
  workHourDetailsById: {},
  workHourDetailList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: WorkHourDetailState) {
  state.isLoading = true;
}

function loadingFailed(
  state: WorkHourDetailState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const workHourDetails = createSlice({
  name: "workHourDetails",
  initialState: WorkHourDetailInitialState,
  reducers: {
    getWorkHourDetailsStart: startLoading,
    getWorkHourDetailsSuccess(
      state,
      { payload }: PayloadAction<WorkHourDetailsResult>
    ) {
      const { workHourDetails } = payload;
      // console.log("PAYLOAD", workHourDetails);
      state.isLoading = false;
      state.error = null;

      workHourDetails.forEach((workHourDetail) => {
        state.workHourDetailsById[workHourDetail.WorkHourDetailID] =
          workHourDetail;
      });

      state.workHourDetailList = workHourDetails.map(
        (workHourDetail) => workHourDetail.WorkHourDetailID
      );
    },
    getWorkHourDetailsFailure: loadingFailed,
    createWorkHourDetailStart: startLoading,
    createWorkHourDetailSuccess(
      state,
      { payload }: PayloadAction<WorkHourDetail>
    ) {
      const { WorkHourDetailID } = payload;
      state.workHourDetailsById[WorkHourDetailID] = payload;
      state.workHourDetailList.push(WorkHourDetailID);

      state.isLoading = false;
      state.error = null;
    },
    updateWorkHourDetailSuccess(
      state,
      { payload }: PayloadAction<WorkHourDetail>
    ) {
      const { WorkHourDetailID } = payload;
      state.workHourDetailsById[WorkHourDetailID] = payload;

      state.isLoading = false;
      state.error = null;
    },
    deleteWorkHourDetailSuccess(state, { payload }: PayloadAction<number>) {
      const WorkHourDetailID = payload;
      delete state.workHourDetailsById[WorkHourDetailID];
      state.workHourDetailList = state.workHourDetailList.filter(
        (item) => item !== WorkHourDetailID
      );

      state.isLoading = false;
      state.error = null;
    },
    bulkDeleteWorkHourDetailsByPeriodSuccess(
      state,
      { payload }: PayloadAction<number>
    ) {
      const workHourPeriodID = payload;

      let newWorkHourDetailList: number[] = [];

      for (const key in state.workHourDetailsById) {
        const id = parseInt(key, 10); // Convert string key to a number
        const detail = state.workHourDetailsById[id];

        if (detail && detail.PeriodID === workHourPeriodID) {
          delete state.workHourDetailsById[id];
        } else {
          newWorkHourDetailList.push(id);
        }
      }

      state.workHourDetailList = newWorkHourDetailList;
      state.isLoading = false;
      state.error = null;
    },
    createWorkHourDetailFailure: loadingFailed,
  },
});

export const {
  getWorkHourDetailsStart,
  getWorkHourDetailsSuccess,
  getWorkHourDetailsFailure,
  createWorkHourDetailStart,
  createWorkHourDetailSuccess,
  updateWorkHourDetailSuccess,
  deleteWorkHourDetailSuccess,
  bulkDeleteWorkHourDetailsByPeriodSuccess,
  createWorkHourDetailFailure,
} = workHourDetails.actions;

export default workHourDetails.reducer;

export const fetchWorkHourDetails =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getWorkHourDetailsStart());
      const workHourDetails = await getWorkHourDetails(accessToken);
      //console.log("RETURN", workHourDetails);
      dispatch(getWorkHourDetailsSuccess(workHourDetails));
    } catch (err: any) {
      dispatch(getWorkHourDetailsFailure(err.toString()));
    }
  };

export const fetchWorkHourDetailsByEmployee =
  (accessToken: String, employeeID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getWorkHourDetailsStart());
      const workHourDetails = await getWorkHourDetailsByEmployee(
        accessToken,
        employeeID
      );
      dispatch(getWorkHourDetailsSuccess(workHourDetails));
    } catch (err: any) {
      dispatch(getWorkHourDetailsFailure(err.toString()));
    }
  };

export const fetchWorkHourDetailsByPeriod =
  (accessToken: String, periodID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getWorkHourDetailsStart());
      const workHourDetails = await getWorkHourDetailsByPeriod(
        accessToken,
        periodID
      );
      //console.log("RETURN", workHourDetails);
      dispatch(getWorkHourDetailsSuccess(workHourDetails));
    } catch (err: any) {
      dispatch(getWorkHourDetailsFailure(err.toString()));
    }
  };

export const addWorkHourDetail =
  (accessToken: String, newWorkHourDetail: Partial<WorkHourDetail>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createWorkHourDetailStart());
      const workHourDetail = await createWorkHourDetail(
        accessToken,
        newWorkHourDetail
      );
      dispatch(createWorkHourDetailSuccess(workHourDetail));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(fetchWorkHourPeriods(accessToken));
      dispatch(push(`/benefits/workhours/${workHourDetail.PeriodID}`));
    } catch (err: any) {
      dispatch(createWorkHourDetailFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updWorkHourDetail =
  (
    accessToken: String,
    workHourDetailID: number,
    newWorkHourDetail: Partial<WorkHourDetail>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createWorkHourDetailStart());
      const workHourDetail = await updateWorkHourDetail(
        accessToken,
        workHourDetailID,
        newWorkHourDetail
      );
      dispatch(updateWorkHourDetailSuccess(workHourDetail));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(fetchWorkHourPeriods(accessToken));
      dispatch(push(`/benefits/workhours/${workHourDetail.PeriodID}`));
    } catch (err: any) {
      dispatch(createWorkHourDetailFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delWorkHourDetail =
  (accessToken: String, workHourDetailID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createWorkHourDetailStart());
      const result = await deleteWorkHourDetail(accessToken, workHourDetailID);
      dispatch(deleteWorkHourDetailSuccess(workHourDetailID));
      // dispatch(push("/benefits/workHourDetails/" + workHourDetailID));
    } catch (err: any) {
      console.log("WHD DELETE ERROR", err);
      dispatch(createWorkHourDetailFailure(err.toString()));
    }
  };

export const bulkDelWorkHourDetailsByPeriod =
  (accessToken: String, workHourPeriodID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createWorkHourDetailStart());
      const result = await bulkDeleteWorkHourDetailByPeriod(
        accessToken,
        workHourPeriodID
      );
      dispatch(bulkDeleteWorkHourDetailsByPeriodSuccess(workHourPeriodID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createWorkHourDetailFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };
