import { Toolbar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Menu, MenuItem } from "@progress/kendo-react-layout";
import AppSelector from "./appSelector";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import menuLinkRender from "./menuLinkRender";
import { AdminMenu } from "./adminMenu";
import { useRoleChecks, useTypedTranslation } from "utils/customHooks";
import { UserWriteAccess } from "utils/userAccess";
import { useEffect, useState } from "react";
import { fetchCompaniesForReview } from "features/benefits/companies/CompaniesSlice";
import { useAuth0 } from "@auth0/auth0-react";

const PREFIX = "HeaderMenu";

const classes = {
  titleText: `${PREFIX}-titleText`,
  grid: `${PREFIX}-grid`,
};

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  [`& .${classes.titleText}`]: {
    textTransform: "capitalize",
    paddingRight: theme.spacing(2),
  },
  [`& .${classes.grid}`]: {
    display: "flex",
    flexDirection: "row",
  },
  ".k-menu:not(.k-context-menu)": {
    color: "inherit",
  },
}));

const HeaderMenu = (props: any) => {
  const loc = useSelector((state: RootState) => state.router.location);
  const t = useTypedTranslation(["strGen", "objBen"]);
  const pathArray = loc.pathname.split("/");
  const moduleName = pathArray[1];
  const { isUpperCase } = props;
  const { currentProfile } = useSelector((state: RootState) => state.profile);
  const { hasSomeContributorRole } = useRoleChecks();
  const isContributor = hasSomeContributorRole();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const {
    clientId,
    ft_ben_Contract,
    ft_eng_Comment,
    ft_eng_Grievance,
    ft_ben_BenefitsMenu,
    ft_ben_RND,
    ft_ben_CustomRnD,
    ft_ben_Spend,
    ft_ben_SpendReport,
    ft_ben_CommunityInvestment,
    ft_ben_Asset,
    ft_ben_WorkForce,
    ft_eng_Thread,
  } = useSelector((state: RootState) => state.client);

  const { companyList, companiesById } = useSelector(
    (state: RootState) => state.companies
  );

  const [incompleteCompanyCount, setIncompleteCompanyCount] = useState(0);

  const hasDirectSpendAccess = currentProfile.IsClient && ft_ben_Spend;

  const { clientModulesById } = useSelector(
    (state: RootState) => state.clientModules
  );

  let clntModsByModID: any = {};

  for (const key in clientModulesById) {
    clntModsByModID[clientModulesById[key].ModuleID] = clientModulesById[key];
  }

  // setup for view only users; right now it's only for engagement,
  // but leave it specific in case we add view-only to other modules
  const showEngagementEdit = UserWriteAccess("Engagement");

  useEffect(() => {
    (async () => {
      try {
        if (clientId !== -1) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          // Dispatch the action to fetch companies needing review
          dispatch(fetchCompaniesForReview(accessToken, clientId));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  // Count the companies needing review
  useEffect(() => {
    const companiesNeedingReview = companyList.map(
      (CompanyID) => companiesById[CompanyID]
    );
    setIncompleteCompanyCount(companiesNeedingReview.length);
  }, [companyList, companiesById]);

  const ModuleMenu = (moduleName: string) => {
    switch (moduleName) {
      case "benefits":
        return (
          <Menu linkRender={menuLinkRender}>
            <MenuItem text={t("strGen:navbar.home")} data="/benefits" />
            {ft_ben_BenefitsMenu &&
              (hasDirectSpendAccess || ft_ben_SpendReport) && (
                <MenuItem
                  text={t("objBen:objects.payment.name")}
                  data={
                    hasDirectSpendAccess
                      ? "/benefits/payments"
                      : "/benefits/paymentPeriods"
                  }
                >
                  {hasDirectSpendAccess && (
                    <MenuItem
                      text={
                        ft_ben_SpendReport
                          ? t("objBen:objects.payment.directspend")
                          : t("strGen:buttons.showall")
                      }
                      data="/benefits/payments"
                    >
                      {ft_ben_SpendReport && [
                        <MenuItem
                          text={t("strGen:buttons.showall")}
                          data="/benefits/payments"
                          key="showall-payments"
                        />,
                        <MenuItem
                          text={t("strGen:buttons.addnew")}
                          data="/benefits/payments/new"
                          key="addnew-payments"
                        />,
                      ]}
                    </MenuItem>
                  )}
                  {ft_ben_SpendReport && (
                    <MenuItem
                      text={
                        hasDirectSpendAccess
                          ? t("objBen:objects.spendreporting.name_other")
                          : t("strGen:buttons.showall")
                      }
                      data="/benefits/paymentPeriods"
                    >
                      {hasDirectSpendAccess && [
                        <MenuItem
                          text={t("strGen:buttons.showall")}
                          data="/benefits/paymentPeriods"
                          key="showall-paymentPeriods"
                        />,
                        <MenuItem
                          text={t("strGen:buttons.addnew")}
                          data="/benefits/paymentPeriods/new"
                          key="addnew-paymentPeriods"
                        />,
                      ]}
                    </MenuItem>
                  )}
                  {!(hasDirectSpendAccess && ft_ben_SpendReport) && (
                    <MenuItem
                      text={t("strGen:buttons.addnew")}
                      data={
                        hasDirectSpendAccess
                          ? "/benefits/payments/new"
                          : "/benefits/paymentPeriods/new"
                      }
                    />
                  )}
                </MenuItem>
              )}
            {ft_ben_BenefitsMenu && ft_ben_WorkForce && (
              <MenuItem text="Employees" data="/benefits/employees">
                <MenuItem
                  text={t("strGen:navbar.showall")}
                  data="/benefits/employees"
                />
                <MenuItem
                  text={t("strGen:navbar.addnew")}
                  data="/benefits/employees/new"
                />
                <MenuItem
                  text="Employees Needing Review"
                  data="/benefits/employees/uploadreview"
                />
              </MenuItem>
            )}
            {ft_ben_BenefitsMenu && ft_ben_WorkForce && (
              <MenuItem text="Work Hours" data="/benefits/workhours">
                <MenuItem
                  text={t("strGen:navbar.showall")}
                  data="/benefits/workhours"
                />
                <MenuItem
                  text={t("strGen:navbar.addnew")}
                  data="/benefits/workhours/new"
                />
              </MenuItem>
            )}
            {/* hide social responsibility if no features are toggled on  */}
            {(ft_ben_RND ||
              ft_ben_CustomRnD ||
              ft_ben_CommunityInvestment ||
              (ft_ben_Asset && currentProfile.IsClientAdmin)) && (
              <MenuItem
                text="Social Responsibility"
                data={
                  ft_ben_CommunityInvestment
                    ? "/benefits/communityinvestments"
                    : ft_ben_Asset && currentProfile.IsClientAdmin
                    ? "/benefits/assets"
                    : ft_ben_RND
                    ? "/benefits/initiatives"
                    : ft_ben_CustomRnD
                    ? "/benefits/customrnds"
                    : ""
                }
              >
                {ft_ben_CommunityInvestment && (
                  <MenuItem
                    text="Community Investments"
                    data="/benefits/communityinvestments"
                  >
                    <MenuItem
                      text={t("strGen:navbar.showall")}
                      data="/benefits/communityinvestments"
                    />
                    <MenuItem
                      text={t("strGen:navbar.addnew")}
                      data="/benefits/communityinvestments/new"
                    />
                  </MenuItem>
                )}
                {ft_ben_Asset && currentProfile.IsClientAdmin && (
                  <MenuItem
                    text={t("objBen:objects.asset.name", { count: 2 })}
                    data="/benefits/assets"
                  >
                    <MenuItem
                      text={t("strGen:navbar.showall")}
                      data="/benefits/assets"
                    />
                    <MenuItem
                      text={t("strGen:navbar.addnew")}
                      data="/benefits/assets/new"
                    />
                  </MenuItem>
                )}
                {ft_ben_RND && (
                  <MenuItem text="Initiatives" data="/benefits/initiatives">
                    <MenuItem
                      text={t("strGen:navbar.showall")}
                      data="/benefits/initiatives"
                    />
                    <MenuItem
                      text={t("strGen:navbar.addnew")}
                      data="/benefits/initiatives/new"
                    />
                  </MenuItem>
                )}
                {ft_ben_RND && (
                  <MenuItem
                    text="Research & Development"
                    data="/benefits/rndexpenses"
                  >
                    <MenuItem
                      text={t("strGen:navbar.showall")}
                      data="/benefits/rndexpenses"
                    />
                    <MenuItem
                      text={t("strGen:navbar.addnew")}
                      data="/benefits/rndexpenses/new"
                    />
                  </MenuItem>
                )}
                {ft_ben_RND && (
                  <MenuItem
                    text={t("objBen:objects.entExpense.ent")}
                    data="/benefits/entexpenses"
                  >
                    <MenuItem
                      text={t("strGen:navbar.showall")}
                      data="/benefits/entexpenses"
                    />
                    <MenuItem
                      text={t("strGen:navbar.addnew")}
                      data="/benefits/entexpenses/new"
                    />
                  </MenuItem>
                )}
                {ft_ben_CustomRnD && (
                  <MenuItem text="R&D Expenditure" data="/benefits/customrnds">
                    <MenuItem text="Show All" data="/benefits/customrnds" />
                    <MenuItem text="Add New" data="/benefits/customrnds/new" />
                  </MenuItem>
                )}
                {ft_ben_CustomRnD && (
                  <MenuItem
                    text={"E&T Expenditure"}
                    data="/benefits/educationtrainings"
                  >
                    <MenuItem
                      text="Show All"
                      data="/benefits/educationtrainings"
                    />
                    <MenuItem
                      text="Add New"
                      data="/benefits/educationtrainings/new"
                    />
                  </MenuItem>
                )}
              </MenuItem>
            )}

            {currentProfile.IsClient ? (
              <MenuItem text="Companies" data="/benefits/companies">
                <MenuItem
                  text={t("strGen:navbar.showall")}
                  data="/benefits/companies"
                />
                <MenuItem
                  text={t("strGen:navbar.addnew")}
                  data="/benefits/companies/new"
                />
                {(ft_ben_Spend || incompleteCompanyCount > 0) && (
                  <MenuItem
                    text="Incomplete Company Profiles"
                    data="/benefits/companies/review"
                  />
                )}
                <MenuItem
                  text="New Company Requests"
                  data="/benefits/companies/unconfirmed"
                />
              </MenuItem>
            ) : (
              <MenuItem
                text="My Company"
                data={`/benefits/companies/${currentProfile.CompanyID}`}
              ></MenuItem>
            )}
            {ft_ben_Contract &&
              ft_ben_BenefitsMenu &&
              currentProfile.IsClient && (
                <MenuItem text="Contracts" data="/benefits/contracts">
                  <MenuItem
                    text={t("strGen:navbar.showall")}
                    data="/benefits/contracts"
                  />
                  {currentProfile.IsClient && (
                    <MenuItem
                      text={t("strGen:navbar.addnew")}
                      data="/benefits/contracts/new"
                    />
                  )}
                </MenuItem>
              )}
            {(currentProfile.IsClientAdmin ||
              (currentProfile.IsClient && currentProfile.BenReportAccess)) &&
              clntModsByModID[1] &&
              clntModsByModID[1].ReportID && (
                <MenuItem
                  text={t("strGen:navbar.reports")}
                  data="/benefits/reporting"
                />
              )}

            {!currentProfile.IsClient && (
              <MenuItem text="Insights" data="/benefits/insights" />
            )}
          </Menu>
        );
      case "commitments":
        return (
          <Menu linkRender={menuLinkRender}>
            <MenuItem text={t("strGen:navbar.home")} data="/commitments" />
            {!isContributor && (
              <MenuItem text="Sources" data="/commitments/sources">
                <MenuItem
                  text={t("strGen:navbar.showall")}
                  data="/commitments/sources"
                />
                <MenuItem
                  text={t("strGen:navbar.addnew")}
                  data="/commitments/sources/new"
                />
              </MenuItem>
            )}
            {isContributor ? (
              <MenuItem text="Commitments" data="/commitments/commitments" />
            ) : (
              <MenuItem text="Commitments" data="/commitments/commitments">
                <MenuItem
                  text={t("strGen:navbar.showall")}
                  data="/commitments/commitments"
                />
                <MenuItem
                  text={t("strGen:navbar.addnew")}
                  data="/commitments/commitments/new"
                />
              </MenuItem>
            )}
            <MenuItem text="Actions" data="/commitments/actions">
              <MenuItem
                text={t("strGen:navbar.showall")}
                data="/commitments/actions"
              />
              <MenuItem
                text={t("strGen:navbar.addnew")}
                data="/commitments/actions/new"
              />
            </MenuItem>
            {!isContributor && (
              <MenuItem text="Groups" data="/commitments/groups">
                <MenuItem
                  text={t("strGen:navbar.showall")}
                  data="/commitments/groups"
                />
                <MenuItem
                  text={t("strGen:navbar.addnew")}
                  data="/commitments/groups/new"
                />
              </MenuItem>
            )}
            {!isContributor && (
              <MenuItem text="Contacts" data="/commitments/contacts">
                <MenuItem
                  text={t("strGen:navbar.showall")}
                  data="/commitments/contacts"
                />
                <MenuItem
                  text={t("strGen:navbar.addnew")}
                  data="/commitments/contacts/new"
                />
              </MenuItem>
            )}
            {(currentProfile.IsClientAdmin ||
              (!isContributor &&
                currentProfile.IsClient &&
                currentProfile.ComReportAccess)) &&
              clntModsByModID[2] &&
              clntModsByModID[2].ReportID && (
                <MenuItem
                  text={t("strGen:navbar.reports")}
                  data="/commitments/reporting"
                />
              )}
          </Menu>
        );
      case "engagement":
        return (
          <Menu linkRender={menuLinkRender}>
            <MenuItem text={t("strGen:navbar.home")} data="/engagement" />
            <MenuItem
              text={t("objStk:objects.interaction.name", { count: 2 })}
              data="/engagement/communications"
            >
              <MenuItem
                text={t("objStk:objects.interaction.name", { count: 2 })}
                data="/engagement/communications"
              />
              {ft_eng_Thread && (
                <MenuItem
                  text={t("objStk:objects.thread.name", { count: 2 })}
                  data="/engagement/communicationthreads"
                />
              )}
              <MenuItem
                text={t("objStk:objects.interactionaction.name", { count: 2 })}
                data="/engagement/communicationactions"
              />
              {ft_eng_Comment && (
                <MenuItem
                  text={t("objStk:objects.interactioncomment.name", {
                    count: 2,
                  })}
                  data="/engagement/comments"
                />
              )}
              <MenuItem
                text={t("objStk:objects.interaction.needingreview", {
                  count: 2,
                })}
                data="/engagement/communications/review"
              />
              {showEngagementEdit && (
                <MenuItem
                  text={t("strGen:navbar.addnew")}
                  data="/engagement/communications/new"
                />
              )}
            </MenuItem>
            <MenuItem
              text={t("objPlt:objects.group.name", { count: 2 })}
              data="/engagement/groups"
            >
              <MenuItem
                text={t("strGen:navbar.showall")}
                data="/engagement/groups"
              />
              {showEngagementEdit && (
                <MenuItem
                  text={t("strGen:navbar.addnew")}
                  data="/engagement/groups/new"
                />
              )}
            </MenuItem>
            <MenuItem
              text={t("objPlt:objects.contact.name", { count: 2 })}
              data="/engagement/contacts"
            >
              <MenuItem
                text={t("strGen:navbar.showall")}
                data="/engagement/contacts"
              />
              {showEngagementEdit && (
                <MenuItem
                  text={t("strGen:navbar.addnew")}
                  data="/engagement/contacts/new"
                />
              )}
            </MenuItem>
            <MenuItem
              text={t("objStk:objects.issue.name", { count: 2 })}
              data="/engagement/issues"
            >
              <MenuItem
                text={t("strGen:navbar.showall")}
                data="/engagement/issues"
              />
              {showEngagementEdit && (
                <MenuItem
                  text={t("strGen:navbar.addnew")}
                  data="/engagement/issues/new"
                />
              )}
            </MenuItem>
            {ft_eng_Grievance && (
              <MenuItem
                text={t("objStk:objects.grievance.name", { count: 2 })}
                data="/engagement/grievances"
              >
                <MenuItem
                  text={t("strGen:navbar.showall")}
                  data="/engagement/grievances"
                />
                {showEngagementEdit && (
                  <MenuItem
                    text={t("strGen:navbar.addnew")}
                    data="/engagement/grievances/new"
                  />
                )}
              </MenuItem>
            )}
            {(currentProfile.IsClientAdmin ||
              (currentProfile.IsClient && currentProfile.EngReportAccess)) &&
              clntModsByModID[3] &&
              clntModsByModID[3].ReportID && (
                <MenuItem
                  text={t("strGen:navbar.reports")}
                  data="/engagement/reporting"
                />
              )}
          </Menu>
        );
      case "admin":
        return (
          <div>
            <AdminMenu
              currentProfile={currentProfile}
              clntModsByModID={clntModsByModID}
              showVertical={false}
            />
          </div>
        );
      case "suppliers":
        return (
          <Menu linkRender={menuLinkRender}>
            <MenuItem text={t("strGen:navbar.home")} data="/suppliers" />
            <MenuItem text="Suppliers" data="/suppliers/suppliers">
              <MenuItem
                text={t("strGen:navbar.showall")}
                data="/suppliers/suppliers"
              />
              <MenuItem
                text="Needing review"
                data="/suppliers/suppliers/review"
              />
              <MenuItem
                text={t("strGen:navbar.addnew")}
                data="/suppliers/suppliers/new"
              />
            </MenuItem>
          </Menu>
        );
      case "sshe":
        return (
          <Menu linkRender={menuLinkRender}>
            {/* Todo : home page view */}
            {/* <MenuItem text={t("strGen:navbar.home") || "Home"} data="/sshe" /> */}
            <MenuItem text="SSHE" data="/sshe/sshe">
              <MenuItem text={t("strGen:navbar.showall")} data="/sshe/sshe" />
              {/* <MenuItem text="drafts" data="/sshe/sshe/draft" /> */}
              <MenuItem
                text={t("strGen:navbar.addnew")}
                data="/sshe/sshe/new"
              />
            </MenuItem>
            {currentProfile.IsClient ? (
              <MenuItem text="Companies" data="/sshe/companies">
                <MenuItem
                  text={t("strGen:navbar.showall")}
                  data="/sshe/companies"
                />
                <MenuItem
                  text={t("strGen:navbar.addnew")}
                  data="/sshe/companies/new"
                />

                <MenuItem
                  text="Incomplete Company Profiles"
                  data="/sshe/companies/review"
                />

                <MenuItem
                  text="New Company Requests"
                  data="/sshe/companies/unconfirmed"
                />
              </MenuItem>
            ) : (
              <MenuItem
                text="My Company"
                data={`/sshe/companies/${currentProfile.CompanyID}`}
              />
            )}
            {currentProfile.IsClientAdmin &&
              clntModsByModID[6] &&
              clntModsByModID[6].ReportID && (
                <MenuItem
                  text={t("strGen:navbar.reports")}
                  data="/sshe/reporting"
                />
              )}
          </Menu>
        );
      case "platform":
        return (
          <Menu linkRender={menuLinkRender}>
            <MenuItem text={t("strGen:navbar.platformhome")} data="/platform" />
          </Menu>
        );
      default:
        moduleName = "<<Unspecified>>";
        return (
          <Menu linkRender={menuLinkRender}>
            <MenuItem text={t("strGen:navbar.home")} />
          </Menu>
        );
    }
  };

  return (
    <StyledToolbar variant="dense">
      <AppSelector type="modules" />
      <Typography variant="h5" className={classes.titleText}>
        {isUpperCase ? moduleName?.toUpperCase() : moduleName}
      </Typography>
      {ModuleMenu(moduleName)}
    </StyledToolbar>
  );
};

export default HeaderMenu;
