import { styled } from '@mui/material/styles';

const PREFIX = 'FileUploadErrors';

const classes = {
    errorItem: `${PREFIX}-errorItem`,
    errorType: `${PREFIX}-errorType`
};

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.errorItem}`]: {
        listStyleType: 'none'
    },
    [`& .${classes.errorType}`]: {
        fontSize: 'bold',
        color: 'red'
    }
}));

export default Root;