import axiosClient from ".././axiosClient";

export interface CountriesStates {
  CountriesStatesID: number;
  CountryID: number;
  CountryName: string;
  ProvinceState: string;
}

export interface CountriesStatesResult {
  countriesStates: CountriesStates[];
}

export const getCountriesStates = async (
  accessToken: String
): Promise<CountriesStatesResult> => {
  const url = `/countriesStates`;

  try {
    const { data } = await axiosClient.get<CountriesStatesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    // console.log("COUNTRIES STATES", data);
    return data;
  } catch (err: any) {
    throw err;
  }
};
