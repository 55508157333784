import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchNotificationTemplates } from "./NotificationTemplateSlice";
import { IppInventory } from "components/Inventory/IppInventory";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";

export const NotificationTemplateInventoryPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const {
    notificationTemplateList,
    isLoading,
    error: notificationTemplatesError,
    notificationTemplatesById,
  } = useSelector((state: RootState) => state.notificationTemplates);

  const notificationTemplates = notificationTemplateList.map(
    (id) => notificationTemplatesById[id]
  );

  const {
    indigenousGroupsById,
    error: indigenousGroupError,
    isLoading: indigenousGroupIsLoading,
  } = useSelector((state: RootState) => state.indigenousGroups);

  const dataColumns = [
    { field: "ProjectName", title: "Project" },
    { field: "NotificationTypeName", title: "Notification Type" },
    { field: "SubjectLine", title: "Subject" },
  ];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchNotificationTemplates(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  if (notificationTemplatesError) {
    return <ErrorPage errMsg={notificationTemplatesError} />;
  }

  console.log("NOTIF", notificationTemplates);

  let notificationTemplateInvView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <IppInventory
        title="Notification Templates"
        tableData={notificationTemplates}
        idField="NotificationTemplateID"
        columns={dataColumns}
        isFiltered={true}
        showDetail={true}
        buttonProps={{
          showAdd: true,
        }}
      />
    </div>
  );

  return (
    <div id="notificationTemplate-inventory-page">
      {notificationTemplateInvView}
    </div>
  );
};
