import { StakeholderGroupForm } from "./StakeholderGroupForm";

export const StakeholderGroupCreatePage = () => {
  let StakeholderGroupCreateView = (
    <div>
      <StakeholderGroupForm />
    </div>
  );

  return (
    <div id="StakeholderGroup-detail-page">{StakeholderGroupCreateView}</div>
  );
};
