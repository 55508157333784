import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchThreads } from "./ThreadSlice";
import { useParams } from "react-router-dom";
import { Alert } from "@mui/material";
import { ThreadForm } from "./ThreadForm";
import LoadingIndicator from "components/LoadingIndicator";
import { useTranslation } from "react-i18next";

interface ThreadDetProps {
  threadID: string;
}

export const ThreadDetailPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation(["strGen"]);

  const { isLoading, threadsById, error } = useSelector(
    (state: RootState) => state.threads
  );

  // get thread ID from url param
  const { threadID } = useParams<ThreadDetProps>();
  const itemID = parseInt(threadID, 10);
  const paramErr = `${t(
    "strGen:errors.wentwrong"
  )} - ${threadID} is not a valid thread ID`;
  const thread = threadsById[parseInt(threadID, 10)];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchThreads(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, threadID, getAccessTokenSilently]);

  let threadDetView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      {/* parma is invalid */}
      {Number.isNaN(itemID) && (
        <Alert severity="error" sx={{ mb: 2 }}>
          <span>{paramErr}</span>
        </Alert>
      )}
      {/* get threads error */}
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          <span>
            <b>{t("strGen:errors.wentwrong")}</b> - {error.toString()}
          </span>
        </Alert>
      )}
      {/* display thread if exists */}
      {thread ? (
        <ThreadForm thread={thread} />
      ) : (
        <Alert severity="error" sx={{ mb: 2 }}>
          <span>{paramErr}</span>
        </Alert>
      )}
    </div>
  );

  return <div id="thread-detail-page">{threadDetView}</div>;
};
