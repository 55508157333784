import { useTypedTranslation } from "utils/customHooks";

interface errProps {
  errMsg?: string;
  errCode?: number;
}

const ErrorPage = (props: errProps) => {
  const { errMsg } = props;
  const t = useTypedTranslation(["strGen"]);

  return (
    <div>
      <h1>{t("strGen:errors.wentwrong")}</h1>
      <div>{errMsg || ""}</div>
    </div>
  );
};

export default ErrorPage;
