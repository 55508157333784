import axiosClient from "api/axiosClient";

export interface PaymentPeriod {
  PaymentPeriodID: number;
  SequenceID: number;
  CompanyID: number;
  ProjectComponentID: number;
  ContractID?: number;
  ContractTitle?: string;
  StartDate: Date;
  EndDate: Date;
  TotalPayment: number;
  WorkflowStageID: number;
  Currency: string;
  ReviewReason: string;
  WorkflowStageName?: string;
  PaymentPeriodStatusID: number;
  PaymentPeriodStatusName?: string;
  CompanyName?: string;
  ProjectComponentName?: string;
  ProjectID?: number;
  ProjectName?: string;
}

export interface PaymentPeriodsResult {
  paymentPeriods: PaymentPeriod[];
}

export async function getPaymentPeriods(
  accessToken: String
): Promise<PaymentPeriodsResult> {
  const url = `/paymentPeriod`;

  try {
    const { data } = await axiosClient.get<PaymentPeriodsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

/* export async function getSinglePaymentPeriod(
  accessToken: String,
  paymentPeriodID: number
): Promise<PaymentPeriodsResult> {
  const url = `/paymentPeriod/${paymentPeriodID}`;

  try {
    const { data } = await axiosClient.get<PaymentPeriodsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}
 */
export async function getPaymentPeriodsByCompany(
  accessToken: String,
  companyID: number
): Promise<PaymentPeriodsResult> {
  const url = `/paymentPeriod/companies/${companyID}`;

  try {
    const { data } = await axiosClient.get<PaymentPeriodsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getPaymentPeriodsByProjectComponent(
  accessToken: String,
  projectComponentID: number
): Promise<PaymentPeriodsResult> {
  const url = `/paymentPeriod/component/${projectComponentID}`;

  try {
    const { data } = await axiosClient.get<PaymentPeriodsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createPaymentPeriod(
  accessToken: String,
  newPaymentPeriod: Partial<PaymentPeriod>
): Promise<PaymentPeriod> {
  const url = `/paymentPeriod`;

  try {
    const { data } = await axiosClient.post<PaymentPeriod>(
      url,
      newPaymentPeriod,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updatePaymentPeriod(
  accessToken: String,
  paymentPeriodID: number,
  newPaymentPeriod: Partial<PaymentPeriod>
): Promise<PaymentPeriod> {
  const url = `/paymentPeriod/` + paymentPeriodID;

  try {
    const { data } = await axiosClient.post<PaymentPeriod>(
      url,
      newPaymentPeriod,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function bulkApprovePaymentPeriod(
  accessToken: String,
  paymentPeriods: PaymentPeriod[]
): Promise<PaymentPeriod> {
  const url = `/paymentPeriod/approve`;

  try {
    const { data } = await axiosClient.post<PaymentPeriod>(
      url,
      paymentPeriods,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deletePaymentPeriod(
  accessToken: String,
  paymentPeriodID: number
): Promise<string> {
  const url = `/paymentPeriod/` + paymentPeriodID;
  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    console.log("DELDATA", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}
