import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  Project,
  ProjectsResult,
  getProjects,
  createProject,
  updateProject,
  deleteProject,
} from "api/projectAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface ProjectState {
  projectsById: Record<number, Project>;
  projectList: number[];
  isLoading: boolean;
  error: string | null;
}

const ProjectInitialState: ProjectState = {
  projectsById: {},
  projectList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: ProjectState) {
  state.isLoading = true;
}

function loadingFailed(state: ProjectState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const projects = createSlice({
  name: "projects",
  initialState: ProjectInitialState,
  reducers: {
    getProjectsStart: startLoading,
    getProjectsSuccess(state, { payload }: PayloadAction<ProjectsResult>) {
      const { projects } = payload;
      // console.log("PAYLOAD", projects);
      state.isLoading = false;
      state.error = null;

      projects.forEach((project) => {
        state.projectsById[project.ProjectID] = project;
      });

      state.projectList = projects.map((project) => project.ProjectID);
    },
    getProjectsFailure: loadingFailed,
    createProjectStart: startLoading,
    createProjectSuccess(state, { payload }: PayloadAction<Project>) {
      const { ProjectID } = payload;
      state.projectsById[ProjectID] = payload;
      state.projectList.push(ProjectID);

      state.isLoading = false;
      state.error = null;
    },
    updateProjectSuccess(state, { payload }: PayloadAction<Project>) {
      const { ProjectID } = payload;
      state.projectsById[ProjectID] = payload;
      state.projectList.push(ProjectID);

      state.isLoading = false;
      state.error = null;
    },
    deleteProjectSuccess(state, { payload }: PayloadAction<number>) {
      const ProjectID = payload;
      delete state.projectsById[ProjectID];
      state.projectList = state.projectList.filter(
        (item) => item !== ProjectID
      );

      state.isLoading = false;
      state.error = null;
    },
    createProjectFailure: loadingFailed,
  },
});

export const {
  getProjectsStart,
  getProjectsSuccess,
  getProjectsFailure,
  createProjectStart,
  createProjectSuccess,
  updateProjectSuccess,
  deleteProjectSuccess,
  createProjectFailure,
} = projects.actions;

export default projects.reducer;

export const fetchProjects =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getProjectsStart());
      const projects = await getProjects(accessToken);
      dispatch(getProjectsSuccess(projects));
    } catch (err: any) {
      dispatch(getProjectsFailure(err.toString()));
    }
  };

export const addProject =
  (accessToken: String, newProject: Partial<Project>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createProjectStart());
      const project = await createProject(accessToken, newProject);
      dispatch(createProjectSuccess(project));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push(`/admin/projects/${project.ProjectID}`));
    } catch (err: any) {
      dispatch(createProjectFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updProject =
  (
    accessToken: String,
    projectID: number,
    newProject: Partial<Project>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createProjectStart());
      const project = await updateProject(accessToken, projectID, newProject);
      dispatch(updateProjectSuccess(project));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push("/admin/projects/" + projectID));
    } catch (err: any) {
      dispatch(createProjectFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delProject =
  (accessToken: String, projectID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createProjectStart());
      const result = await deleteProject(accessToken, projectID);
      dispatch(deleteProjectSuccess(projectID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
      dispatch(push("/admin/projects/"));
    } catch (err: any) {
      dispatch(createProjectFailure(err.toString()));
    }
  };
