import {
  CustomNotification,
  createCustomNotification,
} from "api/customNotificationAPI";

import { AppThunk } from "app/store";

export const addCustomNotification =
  (
    accessToken: String,
    newCustomNotification: Partial<CustomNotification>
  ): AppThunk =>
  async (dispatch) => {
    try {
      const customNotification = await createCustomNotification(
        accessToken,
        newCustomNotification
      );
    } catch (err: any) {
      throw err;
    }
  };
