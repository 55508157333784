import { Grid, Typography } from "@mui/material";
import { Upload } from "@progress/kendo-react-upload";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { changeClientLogo } from "features/client/ClientSlice";

export const LogoForm = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const {
    clientId,
    clientLogo,
    clientName,
    clientSearchString,
    headerColor,
    primaryColor,
    secondaryColor,
    logoPath,
  } = useSelector((state: RootState) => state.client);

  let formData = {
    ClientName: clientName,
    ClientSearchString: clientSearchString || "",
    HeaderColor: headerColor || "",
    PrimaryColor: primaryColor || "",
    SecondaryColor: secondaryColor || "",
    ClientLogo: clientLogo || "",
  };

  const [A0token, setA0token] = useState("");
  const [fileDescription, setFileDescription] = useState("");
  const [processing, setProcessing] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [show, setShow] = useState(false);
  const [files, setFiles] = useState<Partial<any>>({
    files: [] as any,
    events: [] as any,
    errors: [] as any,
  });

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        setA0token(accessToken);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  const fileStatuses = [
    "UploadFailed",
    "Initial",
    "Selected",
    "Uploading",
    "Uploaded",
    "RemoveFailed",
    "Removing",
  ];

  const onBeforeUpload = (event: any) => {
    event.headers.Authorization = `Bearer ${A0token}`;
    // event.additionalData.recordType = recordType;
    // event.additionalData.recordID = recordID;
  };

  const onAdd = (event: any) => {
    setProcessing(true);

    setFiles({
      files: event.newState,
      events: [
        ...files.events,
        `File selected: ${event.affectedFiles[0].name}`,
      ],
      errors: [],
    });
    setProcessing(false);
  };

  const onRemove = (event: any) => {
    setFiles({
      files: event.newState,
      events: [...files.events, `File removed: ${event.affectedFiles[0].name}`],
      errors: [],
    });
    setProcessing(false);
  };

  const onProgress = (event: any) => {
    setFiles({
      files: event.newState,
      events: [
        ...files.events,
        `On Progress: ${event.affectedFiles[0].progress} %`,
      ],
      errors: [...files.errors],
    });
  };

  const onStatusChange = (event: any) => {
    const file = event.affectedFiles[0];

    setFiles({
      files: event.newState,
      events: [
        ...files.events,
        `File '${file.name}' status changed to: ${fileStatuses[file.status]}`,
      ],
      errors: [...files.errors],
    });
  };

  const onSaveRequest = (files: any, options: any, onProgress: any): any => {
    const saveRequestPromise = new Promise((resolve, reject) => {
      setShow(true);

      //eventually this could be used to check that the upload is finished
      setIsUploading(true);

      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(changeClientLogo(accessToken, clientId, files[0], formData));
        } catch (e) {
          console.error(e);
        }
      })();

      resolve({ uid: files[0].uid });
    });

    return saveRequestPromise;
  };

  const logoForm = (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h5" component="h1">
          Logo
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">
          This option allows you to use your own logo on the platform, which
          will then appear at top left. Note that logo should be in PNG or JPG
          format, with a maximum width of 250 pixels and maximum height of 50
          pixels. Transparency is supported for PNG files.
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2">Current Logo</Typography>
      </Grid>
      <Grid item xs={4}>
        {clientLogo !== "" ? (
          <img src={logoPath} alt="Logo" />
        ) : (
          "** no logo selected **"
        )}
      </Grid>
      <Grid item xs={12}>
        <Upload
          showActionButtons={
            !processing && files.errors.length === 0 && A0token !== ""
          }
          autoUpload={false}
          multiple={false}
          files={files.files}
          restrictions={{
            allowedExtensions: [".png", ".jpg", ".jpeg"],
          }}
          withCredentials={true}
          onAdd={onAdd}
          onRemove={onRemove}
          onProgress={onProgress}
          onStatusChange={onStatusChange}
          onBeforeUpload={onBeforeUpload}
          saveUrl={onSaveRequest}
        />
      </Grid>
    </Grid>
  );

  return <div>{logoForm}</div>;
};
