import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { ChangeEvent, useEffect, useState } from "react";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { Grid, Typography } from "@mui/material";
import { IppTextField } from "components/IppTextField";
import { Contact } from "api/stakeholder/contactAPI";
import { IppTokenAutocomplete } from "components/IppTokenAutoComplete";
import { IppContactAutoComplete } from "components/IppContactAutoComplete";
import { fetchIssues } from "../issue/IssueSlice";
import { fetchContacts } from "../../platform/contacts/ContactSlice";
import { fetchStakeholderGroups } from "../../platform/groups/StakeholderGroupSlice";
import {
  addInteractionComment,
  updInteractionComment,
} from "./InteractionCommentSlice";
import { IppSentiment } from "components/IppSentiment";
import { useSnackBarConstants, useTypedTranslation } from "utils/customHooks";
import { IppFormButtons } from "components/Buttons/IppFormButtons";

interface interactionCommentChildFormProps {
  closeAction: any; // action to close dialog
  setDidSaveInventory: any;
  parentTitle: string; // name of field the form is called from
  parentValue: any; // if called from child grid, init parent value
  parentID: any;
  interactionComment?: any; // existing interaction to be edited
}

export const InteractionCommentChildForm = (
  props: interactionCommentChildFormProps
) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);
  const snackbarConstants = useSnackBarConstants();

  const {
    closeAction,
    setDidSaveInventory,
    parentTitle,
    interactionComment,
    parentID,
  } = props;

  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
  const [listIssues, setListIssues] = useState<Array<string>>([]);
  const [listGroups, setListGroups] = useState<Array<string>>([]);
  const [isEditing, setIsEditing] = useState(true);
  const [isAdding, setIsAdding] = useState(true);
  const [canSave, setCanSave] = useState(false);

  const { clientId } = useSelector((state: RootState) => state.client);

  const validationSchema = yup.object({
    CommentDetails: yup.string().required(
      t("strGen:validation.required", {
        fieldname: t("objStk:objects.interactioncomment.name"),
      })
    ),
  });

  let itemData;
  if (interactionComment) {
    itemData = interactionComment;
  } else {
    itemData = {
      InteractionID: parentID,
      CommentDetails: "",
      ContactID: -1,
      GroupID: -1,
      ContactName: "",
      GroupName: "",
      Issues: "",
      SentimentLevel: null,
      Response: "",
    };
  }

  const { contactList, contactsById } = useSelector(
    (state: RootState) => state.contacts
  );

  const contacts = contactList.map((id) => contactsById[id]);

  const { stakeholderGroupList, stakeholderGroupsById } = useSelector(
    (state: RootState) => state.stakeholderGroups
  );

  const stakeholderGroups = stakeholderGroupList.map(
    (id) => stakeholderGroupsById[id]
  );

  const { issueList, issuesById } = useSelector(
    (state: RootState) => state.issues
  );

  const issues = issueList.map((id) => issuesById[id]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchIssues(accessToken));
        dispatch(fetchContacts(accessToken));
        dispatch(fetchStakeholderGroups(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, parentTitle, getAccessTokenSilently]);

  // initialize values of array for issues
  useEffect(() => {
    if (interactionComment && interactionComment.Issues) {
      let items = interactionComment.Issues.split(", ");
      setListIssues(items);
    }

    return () => {
      // cleanup
    };
  }, []);

  // initialize contact
  useEffect(() => {
    if (interactionComment && interactionComment.ContactID > 0) {
      let cnt = contacts.find(
        (item) => item.ContactID === interactionComment.ContactID
      );
      if (cnt) {
        setSelectedContacts([cnt]);
      }
    }
  }, []);

  // initialize group
  useEffect(() => {
    if (interactionComment && interactionComment.GroupID > 0) {
      setListGroups([interactionComment.GroupName]);
    }
  }, []);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        // get value of other fields
        let n = Object.assign({}, values) as any;
        if (selectedContacts.length > 0) {
          n.ContactID = selectedContacts[0].ContactID;
        }
        if (listGroups.length > 0) {
          let grp = stakeholderGroups.find(
            (item) => item.GroupName === listGroups[0]
          );
          if (grp) {
            n.GroupID = grp.GroupID;
          }
        }

        if (interactionComment) {
          dispatch(
            updInteractionComment(
              accessToken,
              values.InteractionCommentID,
              n,
              listIssues,
              snackbarConstants
            )
          );
          setDidSaveInventory(true);
        } else {
          dispatch(
            addInteractionComment(accessToken, n, listIssues, snackbarConstants)
          );

          setDidSaveInventory(true);
        }
        closeAction();
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  const formik = useFormik({
    initialValues: itemData,
    validationSchema: validationSchema,
    onSubmit: submitFunc,
  });

  let detailForm = (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        {interactionComment && isEditing && (
          <Grid item xs={12}>
            <Typography variant="h6">Edit Comment</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <IppTextField
            id="CommentDetails"
            required
            label={t("objStk:objects.interactioncomment.name")}
            value={formik.values.CommentDetails}
            onChangeFunction={formik.handleChange}
            touchedExpression={formik.touched.CommentDetails}
            errorsExpression={formik.errors.CommentDetails}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>

        <Grid item xs={12}>
          <IppContactAutoComplete
            options={contacts}
            selectedContacts={selectedContacts}
            setSelectedContacts={setSelectedContacts}
            singleItem={true}
          />
        </Grid>
        <Grid item xs={12}>
          <IppTokenAutocomplete
            id="GroupList"
            label={t("objStk:objects.interactioncomment.fields.group", {
              count: 2,
            })}
            options={stakeholderGroups.map((option) => option.GroupName)}
            selectedValues={listGroups}
            setSelectedValues={setListGroups}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            setCanSave={setCanSave}
            singleItem={true}
          />
        </Grid>
        <Grid item xs={12}>
          <IppTokenAutocomplete
            id="IssueList"
            label={t("objStk:objects.interactioncomment.fields.relatedissues")}
            options={issues.map((option) => option.IssueName)}
            selectedValues={listIssues}
            setSelectedValues={setListIssues}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            setCanSave={setCanSave}
          />
        </Grid>
        <Grid item xs={12}>
          <IppSentiment
            id="SentimentLevel"
            label={t("objStk:objects.interactioncomment.fields.sentiment")}
            sentimentLevel={formik.values.SentimentLevel}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                formik.setFieldValue("SentimentLevel", newValue);
              } else {
                formik.setFieldValue("SentimentLevel", null);
              }
            }}
            errorsExpression={formik.errors.SentimentLevel}
          />
        </Grid>
        <Grid item xs={12}>
          <IppTextField
            id="Response"
            label={t("objStk:objects.interactioncomment.fields.response")}
            value={formik.values.Response}
            onChangeFunction={formik.handleChange}
            touchedExpression={formik.touched.Response}
            errorsExpression={formik.errors.Response}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        <Grid item xs={12}>
          <IppFormButtons
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            isAdding={isAdding}
            showCancel={true}
            resetFunction={() => {
              closeAction();
            }}
          />
        </Grid>
      </Grid>
    </form>
  );

  return <div>{detailForm}</div>;
};
