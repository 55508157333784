import {
  Grid,
  IconButton,
  Typography,
  Tooltip,
  ToggleButton,
  ToggleButtonGroup,
  FormHelperText,
} from "@mui/material";
import {
  Edit,
  SentimentDissatisfied,
  SentimentDissatisfiedTwoTone,
  SentimentNeutral,
  SentimentNeutralTwoTone,
  SentimentSatisfied,
  SentimentSatisfiedTwoTone,
  SentimentVeryDissatisfied,
  SentimentVeryDissatisfiedTwoTone,
  SentimentVerySatisfied,
  SentimentVerySatisfiedTwoTone,
} from "@mui/icons-material";
import { useState } from "react";
import { useTypedTranslation } from "utils/customHooks";

// show and edit sentiment

interface itemProps {
  id: string;
  label: string;
  sentimentLevel: any;
  isEditing: boolean;
  setIsEditing: any;
  onChangeFunction: any;
  showLabel?: boolean;
  touchedExpression?: any;
  errorsExpression?: any;
  showEdit?: boolean;
}

const useSentiments = () => {
  const t = useTypedTranslation(["strGen"]);

  return [
    {
      altText: t(
        "strGen:components.inventoryicons.getsentimenticons.verydissatisfied"
      ),
      iconValue: 1,
      icon: <SentimentVeryDissatisfied fontSize="large" color="disabled" />,
      selectedIcon: (
        <SentimentVeryDissatisfiedTwoTone fontSize="large" color="error" />
      ),
    },
    {
      altText: t(
        "strGen:components.inventoryicons.getsentimenticons.dissatisfied"
      ),
      iconValue: 2,
      icon: <SentimentDissatisfied fontSize="large" color="disabled" />,
      selectedIcon: (
        <SentimentDissatisfiedTwoTone fontSize="large" color="error" />
      ),
    },
    {
      altText: t("strGen:components.inventoryicons.getsentimenticons.neutral"),
      iconValue: 3,
      icon: <SentimentNeutral fontSize="large" color="disabled" />,
      selectedIcon: (
        <SentimentNeutralTwoTone fontSize="large" color="warning" />
      ),
    },
    {
      altText: t(
        "strGen:components.inventoryicons.getsentimenticons.satisfied"
      ),
      iconValue: 4,
      icon: <SentimentSatisfied fontSize="large" color="disabled" />,
      selectedIcon: <SentimentSatisfiedTwoTone fontSize="large" color="info" />,
    },
    {
      altText: t(
        "strGen:components.inventoryicons.getsentimenticons.verysatisfied"
      ),
      iconValue: 5,
      icon: <SentimentVerySatisfied fontSize="large" color="disabled" />,
      selectedIcon: (
        <SentimentVerySatisfiedTwoTone fontSize="large" color="success" />
      ),
    },
  ];
};

const IppSentiment = (props: itemProps) => {
  const {
    id,
    label,
    sentimentLevel,
    isEditing,
    setIsEditing,
    onChangeFunction,
    showLabel,
    errorsExpression,
    showEdit,
  } = props;
  const sentiments = useSentiments();
  const t = useTypedTranslation(["strGen"]);

  // create state variable to ensure display is consistent
  const [displayValue, setDisplayValue] = useState(
    sentimentLevel > 0 ? sentimentLevel : null
  );

  const editChange = () => {
    setIsEditing(!isEditing);
  };

  const handleChange = (event: any, newValue: any) => {
    onChangeFunction(event, newValue[0]);
    setDisplayValue(newValue[0]);
  };

  let viewForm = (
    <Grid container spacing={0}>
      {showLabel && (
        <Grid item xs={12}>
          <Typography variant="caption" sx={{ color: "text.secondary" }}>
            {label} -{" "}
            <Typography variant="caption" fontWeight="bold">
              {sentiments[displayValue - 1]
                ? sentiments[displayValue - 1].altText
                : t("strGen:components.none")}
            </Typography>
          </Typography>
        </Grid>
      )}
      <Grid item xs>
        {sentiments.map((item) => (
          <Tooltip title={item.altText} key={item.iconValue}>
            <>
              {displayValue === item.iconValue ? item.selectedIcon : item.icon}
            </>
          </Tooltip>
        ))}
      </Grid>

      {showEdit && (
        <Grid item width={30}>
          <Tooltip title={t("strGen:tooltips.ippsentiment.clicktoedit")}>
            <IconButton onClick={editChange} size="small">
              <Edit fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );

  let editForm = (
    <Grid container spacing={0}>
      {showLabel && (
        <Grid item xs={12}>
          <Typography variant="caption" sx={{ color: "text.secondary" }}>
            {label} -{" "}
            <Typography variant="caption" fontWeight="bold">
              {sentiments[displayValue - 1]
                ? sentiments[displayValue - 1].altText
                : t("strGen:components.none")}
            </Typography>
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <ToggleButtonGroup id={id} onChange={handleChange} size="small">
          {sentiments.map((item) => (
            <Tooltip title={item.altText} key={item.iconValue}>
              <ToggleButton value={item.iconValue} key={item.iconValue}>
                {displayValue === item.iconValue
                  ? item.selectedIcon
                  : item.icon}
              </ToggleButton>
            </Tooltip>
          ))}
        </ToggleButtonGroup>
      </Grid>
      {errorsExpression && (
        <FormHelperText error={true} filled={true}>
          {errorsExpression}
        </FormHelperText>
      )}
    </Grid>
  );

  return isEditing ? <div>{editForm}</div> : <div>{viewForm}</div>;
};

const IppSentimentInventoryView = (props: itemProps) => {
  const { sentimentLevel } = props;
  const sentiments = useSentiments();
  const t = useTypedTranslation(["strGen"]);

  function getSentiment(selectedValue: number) {
    if (selectedValue === null || selectedValue < 1 || selectedValue > 5) {
      return sentiments[2];
    } else {
      return sentiments[selectedValue - 1];
    }
  }

  return (
    <Tooltip
      title={t("strGen:tooltips.ippsentiment.inventoryview", {
        label: getSentiment(sentimentLevel).altText,
      })}
    >
      {getSentiment(sentimentLevel).selectedIcon}
    </Tooltip>
  );
};

IppSentiment.defaultProps = {
  showLabel: true,
};

export { IppSentiment, IppSentimentInventoryView };
