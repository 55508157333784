import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ClientPosition,
  ClientPositionsResult,
  getClientPositions,
  createClientPosition,
  updateClientPosition,
  deleteClientPosition,
} from "api/clientPositionAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface ClientPositionState {
  clientPositionsById: Record<number, ClientPosition>;
  clientPositionList: number[];
  isLoading: boolean;
  error: string | null;
}

const ClientPositionInitialState: ClientPositionState = {
  clientPositionsById: {},
  clientPositionList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: ClientPositionState) {
  state.isLoading = true;
}

function loadingFailed(
  state: ClientPositionState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const clientPositions = createSlice({
  name: "clientPositions",
  initialState: ClientPositionInitialState,
  reducers: {
    getClientPositionsStart: startLoading,
    getClientPositionsSuccess(
      state,
      { payload }: PayloadAction<ClientPositionsResult>
    ) {
      const { clientPositions } = payload;
      // console.log("PAYLOAD", clientPositions);
      state.isLoading = false;
      state.error = null;

      clientPositions.forEach((clientPosition) => {
        state.clientPositionsById[clientPosition.ClientPositionID] =
          clientPosition;
      });

      state.clientPositionList = clientPositions.map(
        (clientPosition) => clientPosition.ClientPositionID
      );
    },
    getClientPositionsFailure: loadingFailed,
    createClientPositionStart: startLoading,
    createClientPositionSuccess(
      state,
      { payload }: PayloadAction<ClientPosition>
    ) {
      const { ClientPositionID } = payload;
      state.clientPositionsById[ClientPositionID] = payload;
      state.clientPositionList.push(ClientPositionID);

      state.isLoading = false;
      state.error = null;
    },
    updateClientPositionSuccess(
      state,
      { payload }: PayloadAction<ClientPosition>
    ) {
      const { ClientPositionID } = payload;
      state.clientPositionsById[ClientPositionID] = payload;

      state.isLoading = false;
      state.error = null;
    },
    deleteClientPositionSuccess(state, { payload }: PayloadAction<number>) {
      const ClientPositionID = payload;
      delete state.clientPositionsById[ClientPositionID];
      state.clientPositionList = state.clientPositionList.filter(
        (item) => item !== ClientPositionID
      );

      state.isLoading = false;
      state.error = null;
    },
    createClientPositionFailure: loadingFailed,
  },
});

export const {
  getClientPositionsStart,
  getClientPositionsSuccess,
  getClientPositionsFailure,
  createClientPositionStart,
  createClientPositionSuccess,
  updateClientPositionSuccess,
  deleteClientPositionSuccess,
  createClientPositionFailure,
} = clientPositions.actions;

export default clientPositions.reducer;

export const fetchClientPositions =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getClientPositionsStart());
      const clientPositions = await getClientPositions(accessToken);
      //console.log("RETURN", clientPositions);
      dispatch(getClientPositionsSuccess(clientPositions));
    } catch (err: any) {
      dispatch(getClientPositionsFailure(err.toString()));
    }
  };

export const addClientPosition =
  (accessToken: String, newClientPosition: Partial<ClientPosition>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientPositionStart());
      const clientPosition = await createClientPosition(
        accessToken,
        newClientPosition
      );
      dispatch(createClientPositionSuccess(clientPosition));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push("/admin/clientpositions"));
    } catch (err: any) {
      dispatch(createClientPositionFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updClientPosition =
  (
    accessToken: String,
    clientPositionID: number,
    newClientPosition: Partial<ClientPosition>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientPositionStart());
      const clientPosition = await updateClientPosition(
        accessToken,
        clientPositionID,
        newClientPosition
      );
      dispatch(updateClientPositionSuccess(clientPosition));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      // dispatch(push("/clientPositions/" + clientPositionID));
    } catch (err: any) {
      dispatch(createClientPositionFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delClientPosition =
  (accessToken: String, clientPositionID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientPositionStart());
      const result = await deleteClientPosition(accessToken, clientPositionID);
      dispatch(deleteClientPositionSuccess(clientPositionID));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push("/admin/clientpositions"));
    } catch (err: any) {
      dispatch(createClientPositionFailure(err.toString()));
    }
  };
