import { Trans } from "react-i18next";
import {
  ConstructedErrors,
  GenericErrorTitlesObject,
} from "utils/types/index.types";
import {
  generateErrorTitle,
  getGenericConstructedErrors,
  getGenericConstructedWarnings,
} from "utils/uploadUtils";

type PaymentErrorTitles = GenericErrorTitlesObject & {
  invalidContract: (count: number) => JSX.Element | "";
  newCompanies: (count: number) => JSX.Element | "";
  potentialDuplicate: (count: number) => JSX.Element | "";
};

export const generatePaymentErrorTitles: PaymentErrorTitles = {
  ...generateErrorTitle,
  invalidContract: (count: number) =>
    count > 0 ? (
      <Trans
        i18nKey="strGen:uploaderrors.errors.payment.invalidcontract.title"
        count={count}
      />
    ) : (
      ""
    ),
  newCompanies: (count: number) =>
    count > 0 ? (
      <Trans
        i18nKey="strGen:uploaderrors.errors.payment.newcompanies.title"
        count={count}
      />
    ) : (
      ""
    ),
  potentialDuplicate: (count: number) =>
    count > 0 ? (
      <Trans i18nKey="strGen:uploaderrors.errors.payment.potentialduplicate.title" />
    ) : (
      ""
    ),
};

export const getNewPmtConstructedErrors: () => ConstructedErrors = () => ({
  ...getGenericConstructedErrors(),
  invalidContract: {
    count: 0,
    get message() {
      return this.count > 0
        ? `Please verify that the contract information is correct. To continue, please add the following ${
            this.count === 1 ? "Contract" : "Contracts"
          } to the platform:`
        : "";
    },
    list: [],
    renderAsList: true,
    showSingleItem: true,
  },
});

export const getNewPmtConstructedWarnings: () => ConstructedErrors = () => ({
  newCompanies: {
    count: 0,
    get message() {
      return this.count > 0 ? (
        <>
          The following {this.count === 1 ? "Company" : "Companies"} will be
          created in the platform. If you continue with this upload, please
          visit the <strong>Incomplete Company Page</strong> to complete Company{" "}
          {this.count === 1 ? "Profile" : "Profiles"}.
        </>
      ) : (
        ""
      );
    },
    childList: [],
  },
  potentialDuplicate: {
    count: 0,
    get message() {
      return this.count > 0 ? (
        <>
          This upload appears to be a duplicate; there
          {this.count === 1
            ? " is at least 1 item that is identical to an already existing direct spend in the platform. "
            : ` are at least ${this.count} items that are identical to already existing direct spends in the platform. `}
          Please ensure you are uploading the correct file before proceeding.
        </>
      ) : (
        ""
      );
    },
    list: [],
  },
  ...getGenericConstructedWarnings(),
});
