import axiosClient from "./axiosClient";

export interface Region {
  RegionID: number;
  ClientD: number;
  RegionName: string;
  RecordType: string;
}

export interface RegionsResult {
  regions: Region[];
}

export type RegionRecordType = "Company" | "Employee" | "Project";

export const getAllRegions = async (
  accessToken: String
): Promise<RegionsResult> => {
  const url = `/region`;

  try {
    const { data } = await axiosClient.get<RegionsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
};

export const getRegionsByRecordType = async (
  accessToken: string,
  recordType: RegionRecordType
): Promise<RegionsResult> => {
  const url = `/region/recordType/${recordType}`;

  try {
    const { data } = await axiosClient.get<RegionsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
};

export const createRegion = async (
  accessToken: String,
  newRegion: Partial<Region>
): Promise<Region> => {
  const url = `/region`;

  try {
    const { data } = await axiosClient.post<Region>(url, newRegion, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
};

export const updateRegion = async (
  accessToken: String,
  regionID: number,
  newRegion: Partial<Region>
): Promise<Region> => {
  const url = `/region/` + regionID;

  try {
    const { data } = await axiosClient.post<Region>(url, newRegion, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
};

export const deleteRegion = async (
  accessToken: String,
  regionID: number
): Promise<Region> => {
  const url = `/region/` + regionID;

  try {
    const { data } = await axiosClient.delete<Region>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
};
