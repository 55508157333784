import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { ButtonProps } from "@mui/material/Button";
import { IppButton, IppButtonProps } from "components/Buttons/IppButton";
import { useTypedTranslation } from "utils/customHooks";

interface IppMenuButtonProps extends IppButtonProps {
  buttonText?: string;
  color?: ButtonProps["color"];
  isOpenButton?: boolean;
  tooltip?: string;
}

export const IppMenuButton = ({
  buttonText,
  color = "secondary",
  isOpenButton = true,
  tooltip,
  ...props
}: IppMenuButtonProps) => {
  const t = useTypedTranslation(["strGen"]);

  const openText = t(`strGen:buttons.menu`);
  const closeText = t(`strGen:buttons.close`);

  const T_buttonText = buttonText || (isOpenButton ? openText : closeText);
  const icon = isOpenButton ? <ExpandMore /> : <ExpandLess />;

  return (
    <IppButton
      noTimeout
      color={color}
      endIcon={icon}
      tooltip={tooltip}
      {...props}
    >
      {T_buttonText}
    </IppButton>
  );
};
