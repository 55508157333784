import axiosClient from "../axiosClient";

export interface CommitmentSourceAmendment {
  AmendmentID: number;
  AmendmentName: string;
  CommitmentSourceID: number;
  CreatedBy: number;
  FileName: string;
  FilePath: string;
  IsUploaded: boolean;
  UploadDate: Date;
  UploadedByName?: string;
  AmendmentDate: Date;
  FirstName: string;
  Surname: string;
}

export interface CommitmentSourceAmendmentsResult {
  commitmentSourceAmendments: CommitmentSourceAmendment[];
}

export async function getCommitmentSourceAmendments(
  accessToken: String,
  commitmentSourceID: number
): Promise<CommitmentSourceAmendmentsResult> {
  const url = `/commitmentSourceAmendment/${commitmentSourceID}`;

  try {
    const { data } = await axiosClient.get<CommitmentSourceAmendmentsResult>(
      url,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createCommitmentSourceAmendment(
  accessToken: String,
  commitmentSourceID: number,
  newCommitmentSourceAmendment: Partial<CommitmentSourceAmendment>
): Promise<CommitmentSourceAmendment> {
  const url = `/commitmentSourceAmendment`;

  newCommitmentSourceAmendment.CommitmentSourceID = commitmentSourceID;
  try {
    const { data } = await axiosClient.post<CommitmentSourceAmendment>(
      url,
      newCommitmentSourceAmendment,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateCommitmentSourceAmendment(
  accessToken: String,
  amendmentID: number,
  newCommitmentSourceAmendment: Partial<CommitmentSourceAmendment>
): Promise<CommitmentSourceAmendment> {
  const url = `/commitmentSourceAmendment/` + amendmentID;

  try {
    const { data } = await axiosClient.post<CommitmentSourceAmendment>(
      url,
      newCommitmentSourceAmendment,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteCommitmentSourceAmendment(
  accessToken: String,
  amendmentID: number
): Promise<string> {
  const url = `/commitmentSourceAmendment/` + amendmentID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
