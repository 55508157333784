import { PaymentForm } from "./PaymentForm";

export const PaymentCreatePage = () => {
  let paymentCreateView = (
    <div>
      <PaymentForm />
    </div>
  );

  return <div id="payment-detail-page">{paymentCreateView}</div>;
};
