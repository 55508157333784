import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchInitiatives } from "./InitiativeSlice";
import { IppInventory } from "components/Inventory/IppInventory";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";
import { ConvertDateOffset } from "utils/DateFunctions";
import { useTranslation } from "react-i18next";
import { IsExtraLargeScreen } from "utils/mediaQueries";

export const InitiativeInventoryPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation(["objPlt", "strGen", "objCom"]);
  const [pageLoading, setPageLoading] = useState(true);

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const {
    initiativeList,
    isLoading: initiativeIsLoading,
    error: initiativesError,
    initiativesById,
  } = useSelector((state: RootState) => state.initiatives);

  const initiatives = initiativeList.map((id) => initiativesById[id]);

  const { RnDExpenseList, RnDExpensesById } = useSelector(
    (state: RootState) => state.RnDExpense
  );

  const { entExpenseList, entExpensesById } = useSelector(
    (state: RootState) => state.entExpenses
  );

  const data = initiatives.map((p: any) => {
    let newItem = Object.assign({}, p) as any;

    if (p.Approved) {
      newItem.ApprovedStatus = "Yes";
    } else newItem.ApprovedStatus = "No";

    newItem.TotalEligibleExpense =
      p.Category === "Research & Development"
        ? p.TotalEligibleRnDExpense
        : p.TotalEligibleEnTExpense;

    return newItem;
  });

  // the data columns to display in the table for the initiative inventory

  const statusColumn = currentProfile.IsClient
    ? [
        {
          field: "StatusName",
          title: t("objBen:objects.initiative.fields.submitstatusidinventory"),
          columnWidth: 150,
          chips: true,
          className: "Submitted for Review",
        },
      ]
    : [];

  const sourceOfFundsColumn = currentProfile.IsClient
    ? [
        {
          field: "Owner",
          title: t("objBen:objects.initiative.fields.owner"),
          filter: "text",
        },
      ]
    : [];

  const dataColumns = [
    {
      field: "InitiativeName",
      title: t("objBen:objects.initiative.fields.name"),
    },
    {
      field: "InitiativeGroup",
      title: t("objBen:objects.initiative.fields.initiativegroup"),
      filter: "text",
    },
    ...statusColumn,
    {
      field: "ApprovedStatus",
      title: t("objBen:objects.initiative.fields.approved"),
      columnWidth: 150,
    },
    {
      field: "Category",
      title: t("objBen:objects.initiative.fields.category"),
      columnWidth: 250,
      //filter: "text",
    },
    {
      field: "QualifiedValue",
      title: t("objBen:objects.initiative.fields.qualifiedvalue"),
      format: "{0:c0}",
      filter: "numeric",
      columnWidth: IsExtraLargeScreen() ? 180 : 150,
    },
    ...sourceOfFundsColumn,
    {
      field: "TotalEligibleExpense",
      title: t("objBen:objects.rndExpense.fields.totaleligiblespend"),
      format: "{0:c0}",
      filter: "numeric",
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchInitiatives(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  if (initiativesError) {
    return <ErrorPage errMsg={initiativesError} />;
  }

  let InitiativeInvView =
    initiativeIsLoading || clientIsLoading ? (
      <LoadingIndicator />
    ) : (
      <div>
        <IppInventory
          title="Initiative"
          tableData={data}
          idField="InitiativeID"
          columns={dataColumns}
          isFiltered={true}
          showDetail={true}
          buttonProps={{
            showAdd: true,
          }}
          sortColumn={0}
          sortReverse={true}
          //linkColumn={2}
          searchByColumn={"InitiativeGroup"}
          searchByPlaceholder="Initiative Group"
        />
      </div>
    );

  return <div id="Initiative-inventory-page">{InitiativeInvView}</div>;
};
