import { Check } from "@mui/icons-material";
import { ButtonProps } from "@mui/material";
import { useTypedTranslation } from "utils/customHooks";
import { IppButtonProps, IppButton } from "components/Buttons/IppButton";

interface IppCompleteButtonProps extends IppButtonProps {
  buttonText?: string;
  color?: ButtonProps["color"];
  endIcon?: React.ReactNode;
}

export const IppCompleteButton = ({
  buttonText,
  color = "success",
  endIcon = <Check />,
  ...props
}: IppCompleteButtonProps) => {
  const t = useTypedTranslation(["strGen"]);

  const T_buttonText = buttonText || t("strGen:buttons.completeaction");

  return (
    <IppButton color={color} endIcon={endIcon} {...props}>
      {T_buttonText}
    </IppButton>
  );
};
