
import { InteractionForm } from "./InteractionForm";

export const InteractionCreatePage = () => {
  let InteractionCreateView = (
    <div>
      <InteractionForm />
    </div>
  );

  return <div id="Interaction-detail-page">{InteractionCreateView}</div>;
};
