import { Box, Drawer, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { AdminMenu } from "features/header/adminMenu";
import { useTypedTranslation } from "utils/customHooks";

interface sidebarProps {
  adminDrawerOpen: boolean;
  setAdminDrawerOpen: any;
}

export const AdminSidebar = (props: sidebarProps) => {
  const t = useTypedTranslation(["strGen"]);
  const { adminDrawerOpen, setAdminDrawerOpen } = props;

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const { clientModulesById } = useSelector(
    (state: RootState) => state.clientModules
  );

  let clntModsByModID: any = {};

  for (const key in clientModulesById) {
    clntModsByModID[clientModulesById[key].ModuleID] = clientModulesById[key];
  }

  const handleToggle = () => {
    setAdminDrawerOpen(!adminDrawerOpen);
  };

  const drawerWidth = 200;

  return (
    <Drawer
      anchor="right"
      open={adminDrawerOpen}
      onClose={() => setAdminDrawerOpen(false)}
      sx={{
        zIndex: 1501,
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      hideBackdrop={false}
    >
      <Box p={1} onClick={handleToggle}>
        <Typography variant="h6">
          {t("strGen:navbar.admin.sidebar.title")}
        </Typography>
        <AdminMenu
          clntModsByModID={clntModsByModID}
          currentProfile={currentProfile}
          showVertical={true}
        />
      </Box>
    </Drawer>
  );
};
