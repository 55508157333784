import { Edit } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { GridCellProps } from "@progress/kendo-react-grid";
import { IppButton } from "components/Buttons/IppButton";
import { useTypedTranslation } from "utils/customHooks";

interface EditCellProps extends GridCellProps {
  idField: string;
  setID: (id: number) => void;
  setEditOpen: (open: boolean) => void;
}

export const EditCell: React.FC<EditCellProps> = (props) => {
  const { idField, setID, setEditOpen, dataItem } = props;
  const t = useTypedTranslation(["strGen"]);

  return (
    <td>
      <Tooltip title={t("strGen:buttons.tooltip.editentry")}>
        <div
          style={{ cursor: "pointer", color: "blue" }}
          onClick={(e) => {
            setID(dataItem[idField]);
            setEditOpen(true);
          }}
        >
          <IppButton variant="text" startIcon={<Edit />} color={"secondary"} />
        </div>
      </Tooltip>
    </td>
  );
};
