import { useTypedTranslation } from "utils/customHooks";
import * as yup from "yup";
import * as RegexConstants from "../../../validation/regexConstants";

export const EducationTrainingValidation = () => {
  const t = useTypedTranslation(["objPlt", "strGen", "objBen"]);

  return yup.object({
    Type: yup
      .string()
      .required("Type is required")
      .max(250, "Type should be less than 250 characters")
      .typeError("Invalid Type"),
    Activity: yup
      .string()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.educationtraining.fields.activity"),
        })
      )
      .max(
        250,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.educationtraining.fields.activity"),
          count: 250,
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.educationtraining.fields.activity"),
        })
      ),
    ProjectID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.project.name"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.project.name"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:objects.project.name"),
        })
      ),
    Description: yup
      .string()
      .nullable()
      .max(
        250,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.educationtraining.fields.description"),
          count: 250,
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.educationtraining.fields.description"),
        })
      ),
    ExpenditureDate: yup
      .date()
      .required(
        t("strGen:validation.required", {
          fieldname: t(
            "objBen:objects.educationtraining.fields.expendituredate"
          ),
        })
      )
      .max(
        new Date(),
        t("strGen:validation.date.nofuturedate", {
          fieldname: t("objBen:objects.entExpense.fields.expensedate"),
        })
      )
      .typeError(t("strGen:validation.date.improperformat")),
    Expenditure: yup
      .number()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.educationtraining.fields.expenditure"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.educationtraining.fields.expenditure"),
        })
      ),
    Provider: yup
      .string()
      .max(
        250,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.educationtraining.fields.provider"),
          count: 250,
        })
      )
      .nullable()
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.educationtraining.fields.provider"),
        })
      ),
    Location: yup
      .string()
      .max(
        250,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.educationtraining.fields.location"),
          count: 250,
        })
      )
      .nullable()
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.educationtraining.fields.location"),
        })
      ),
    CompanyID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.company.name"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.company.name"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.company.name"),
        })
      ),
  });
};
