import { useTypedTranslation } from "utils/customHooks";
import * as yup from "yup";

export const ValidationSchema = () => {
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);

  return yup.object({
    CommitmentTitle: yup
      .string()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objCom:objects.commitment.fields.commitmenttitle"),
        })
      )
      .max(
        500,
        t("strGen:validation.max", {
          fieldname: t("objCom:objects.commitment.fields.commitmenttitle"),
          count: 500,
        })
      ),
    RecordedDate: yup
      .date()
      .nullable()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:platformwide.fields.daterecorded"),
        })
      )
      .typeError(t("strGen:validation.date.improperformat"))
      .max(
        new Date(),
        t("strGen:validation.date.nofuturedate", {
          fieldname: t("objPlt:platformwide.fields.daterecorded"),
        })
      ),
    CommitmentReference: yup.string().nullable(),
    CommitmentStatusTypeID: yup
      .number()
      .nullable()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.group.fields.organizationtype"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objCom:objects.commitment.fields.status"),
        })
      ),
    CommitmentSourceID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objCom:objects.commitmentsource.source"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objCom:objects.commitmentsource.source"),
        })
      ),
    CommitmentCategoryID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objCom:objects.commitment.fields.category"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objCom:objects.commitment.fields.category"),
        })
      ),
    ProjectID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.project.name"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.project.name"),
        })
      ),
    PersonResponsible: yup
      .number()
      .nullable()
      .positive(
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:objects.user.name"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objCom:objects.commitment.fields.personresponsible"),
        })
      ),
    OverallResponsibility: yup.string().required(
      t("strGen:validation.required", {
        fieldname: t("objCom:objects.commitment.fields.overallresponsibility"),
      })
    ),
    ContactID: yup
      .number()
      .nullable()
      .positive(
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:objects.contact.name"),
        })
      ),
    AssociatedContactID: yup
      .number()
      .nullable()
      .positive(
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:objects.contact.fields.associatedcontact"),
        })
      ),
    CompletedDate: yup
      .date()
      .nullable()
      .typeError(t("strGen:validation.date.improperformat"))
      .max(
        new Date(),
        t("strGen:validation.date.nofuturedate", {
          fieldname: t("objPlt:platformwide.fields.datecompleted"),
        })
      ),
  });
};
