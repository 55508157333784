import { useFormik } from "formik";
import { ChangeEvent, useEffect } from "react";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { Grid, Typography } from "@mui/material";
import { IppTextField } from "components/IppTextField";
import LoadingIndicator from "components/LoadingIndicator";
import { addIssue, fetchIssues } from "./IssueSlice";
import { IppAutocomplete } from "components/IppAutocomplete";
import { useSnackBarConstants, useTypedTranslation } from "utils/customHooks";
import { IppFormButtons } from "components/Buttons/IppFormButtons";

interface IssueChildFormProps {
  setAddingItem: any;
  issueName: string;
  setNewItem: any;
  setDidSaveChild: any;
  issue?: any;
}

export const IssueChildForm = (props: IssueChildFormProps) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);
  const snackbarConstants = useSnackBarConstants();

  const { setAddingItem, issueName, setNewItem, setDidSaveChild, issue } =
    props;

  const {
    issueList,
    issuesById,
    isLoaded: issuesLoaded,
  } = useSelector((state: RootState) => state.issues);

  let issues = issueList.map((id) => issuesById[id]);

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        //Fetch Issues on page if they haven't already been fetched by another action.
        if (!issuesLoaded) {
          dispatch(fetchIssues(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  var itemData: any;

  if (issue) {
    itemData = issue;
  } else
    itemData = {
      IssueName: issueName,
      IssueDescription: "",
      ParentIssueID: -1,
      ClientID: clientId,
    };

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        setNewItem(values.IssueName);
        dispatch(
          addIssue(accessToken, values, [], [], false, snackbarConstants)
        );
        setAddingItem(false);
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const validationSchema = yup.object({
    IssueName: yup
      .string()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.issue.name"),
        })
      )
      .max(
        250,
        t("strGen:validation.max", {
          fieldname: t("objStk:objects.issue.name"),
          count: 250,
        })
      )
      .test(
        "checkDuplicate",
        t("strGen:validation.exists", {
          fieldname: t("objStk:objects.issue.name"),
        }),
        function (value: any) {
          if (value === itemData.IssueName) {
            return true;
          }

          let name = value ? value : "";

          let checkDupe = issues.filter(
            (obj: any) => obj.IssueName.toLowerCase() === name.toLowerCase()
          ).length;

          if (checkDupe === 0) {
            return true;
          } else {
            return false;
          }
        }
      ),
  });

  const formik = useFormik({
    initialValues: itemData,
    validationSchema: validationSchema,
    onSubmit: onSub,
  });

  const handleAddClose = () => {
    setDidSaveChild(false);
    setNewItem("");
    setAddingItem(false);
  };

  let detailForm = clientIsLoading ? (
    <LoadingIndicator />
  ) : (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6">
            {issue
              ? t("strGen:buttons.editobj", {
                  objectname: t("objStk:objects.issue.name"),
                })
              : t("strGen:buttons.addnewobj", {
                  objectname: t("objStk:objects.issue.name"),
                })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <IppTextField
            id="IssueName"
            required
            label={t("objStk:objects.issue.fields.title")}
            value={formik.values.IssueName}
            onChangeFunction={formik.handleChange}
            touchedExpression={formik.touched.IssueName}
            errorsExpression={formik.errors.IssueName}
            isEditing={true}
            setIsEditing={null}
          />
        </Grid>
        <Grid item xs={12}>
          <IppTextField
            id="IssueDescription"
            label={t("objStk:objects.issue.fields.description")}
            required={false}
            value={formik.values.IssueDescription}
            onChangeFunction={formik.handleChange}
            touchedExpression={formik.touched.IssueDescription}
            errorsExpression={formik.errors.IssueDescription}
            isEditing={true}
            setIsEditing={null}
            multiLine={true}
          />
        </Grid>
        <Grid item xs={12}>
          <IppAutocomplete
            id="ParentIssueID"
            options={issues}
            required={false}
            autoPopulate={false}
            value={issues.find((obj) => {
              return obj.IssueID === formik.values.ParentIssueID;
            })}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                formik.setFieldValue("ParentIssueID", newValue.IssueID);
              } else {
                formik.setFieldValue("ParentIssueID", -1);
              }
            }}
            label={t("objStk:objects.issue.fields.parentissueifapplicable")}
            isEditing={true}
            setIsEditing={null}
            optionLabelFunction={(option: any) => option.IssueName}
            textValueFunction={
              formik.values.ParentIssueID > 0 &&
              issuesById[formik.values.ParentIssueID]
                ? issuesById[formik.values.ParentIssueID].IssueName
                : ""
            }
            touchedFunction={formik.touched.ParentIssueID}
            errorFunction={formik.errors.ParentIssueID}
            toolTip={t("strGen:tooltips.issue.fields.parentissue")}
          />
        </Grid>
        <Grid item xs={12}>
          <IppFormButtons
            isEditing={true}
            setIsEditing={null}
            isAdding={!issue}
            showCancel={true}
            resetFunction={handleAddClose}
          />
        </Grid>
      </Grid>
    </form>
  );

  return <div id="issue-child-form">{detailForm}</div>;
};
