//import { IppDebug } from "./../app/App";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";

// how to handle type coversion of states since its also built in
interface filterState {
  filter?: any;
  take?: number;
  skip?: number;
  sort?: any;
}

const filterInitialState: filterState = {
  filter: {
    filters: [] as any,
    logic: "and",
  },
  take: 10,
  skip: 0,
  sort: [] as any,
};

interface inventoryState {
  filtersByInv: Record<string, filterState>;
}

const invInitialState: inventoryState = {
  filtersByInv: {},
};

const filterStates = createSlice({
  name: "filterState",
  initialState: invInitialState,
  reducers: {
    setFilter(state, action: PayloadAction<any>) {
      const filter = action.payload.filter;
      //console.log("SETFILTER", action.payload);
      if (!state.filtersByInv[action.payload.page]) {
        let initialState = {
          filter: {
            filters: [] as any,
            logic: "and",
          },
          take: action.payload.take,
          skip: 0,
          sort: [] as any,
        };
        state.filtersByInv[action.payload.page] = initialState;
      }
      state.filtersByInv[action.payload.page].filter = filter;
    },

    setSkip(state, { payload }: PayloadAction<any>) {
      const skip = payload.skip;
      state.filtersByInv[payload.page].skip = skip;
    },

    setSort(state, { payload }: PayloadAction<any>) {
      const sort = payload.sort;
      let data = Object.assign(
        {},
        state.filtersByInv[payload.page] || filterInitialState
      );
      data.sort = sort;
      // console.log(" DATA SLICE", data);
      state.filtersByInv[payload.page] = data;
    },

    setTake(state, { payload }: PayloadAction<any>) {
      //console.log(" TAKE SLICE", current(state));
      const take = payload.take;
      state.filtersByInv[payload.page].take = take;
    },

    clearFilterState: (state, { payload }: PayloadAction<string>) => {
      //console.log("Clearing Filter", payload);
      const page = payload;
      state.filtersByInv[page].filter = {
        filters: [] as any,
        logic: "and",
      } as CompositeFilterDescriptor;
      // state.filtersByInv[page].take = payload.take;
      state.filtersByInv[page].skip = 0;
      state.filtersByInv[page].sort = [];
    },
  },
});

export const { setFilter, setSkip, setSort, setTake, clearFilterState } =
  filterStates.actions;

export default filterStates.reducer;

/* export const setFilterState =
  ( curInventory: string, filter?: any, skip?: any, sort?: any, take?: any): AppThunk =>
  async (dispatch) => {
    if (filter) dispatch(setFilter({ filter }));
    if (skip) dispatch(setSkip({ skip }));
    if (sort) dispatch(setSort({ sort }));
    if (take) dispatch(setTake({ take }));
};

*/
/* export const setSkip =
  ( inv: string, skip: number | undefined): AppThunk =>
  async (dispatch) => {
    dispatch(setSkips(skip));
    console.log("CAME TO SKIP SLICE", skip);
  };

export const setTake = ( inv: string, take: number | undefined) => async (dispatch: any) => {
  dispatch(setTakes(take));
};

export const setSort = (sort: any) => async (dispatch: any) => {
  dispatch(setSorts(sort));
};

export const setFilter = (filter: any) => async (dispatch: any) => {
  console.log("FILTER SLICE", filter);
  dispatch(setFilters(filter));
};
 */
