import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { IppFormHeader } from "components/IppFormHeader";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppTextField } from "components/IppTextField";
import { IppCheckbox } from "components/IppCheckbox";
import { IppAutocomplete } from "components/IppAutocomplete";
import LoadingIndicator from "components/LoadingIndicator";
import { Prompt } from "react-router";
import { addScheduler, updScheduler, delScheduler } from "./SchedulerSlice";
import { fetchScheduledNotificationTypes } from "features/datalists/ScheduledNotificationTypeSlice";
import ScheduleFrequency from "components/ScheduleFrequency";
import { IppDatePicker } from "components/IppDatePicker";
import { ConvertDateOffset, GetJSDate } from "utils/DateFunctions";

const PREFIX = "SchedulerForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1000,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

const validationSchema = yup.object({
  ScheduledNotificationTypeID: yup
    .number()
    .positive("Notification Type is required")
    .required("Notification Type is required"),
  TriggerDate: yup.date().required("Date is required").nullable(),
  RepeatNotification: yup.boolean().required("This field is required"),
  RepeatFrequency: yup.string().when("RepeatNotification", {
    is: true,
    then: (value) => value.required("A Frequency is required").nullable(),
  }),
});

export const SchedulerForm = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  let schedData = props.scheduler || {};

  const [isEditing, setIsEditing] = useState(
    schedData.SchedulerID ? false : true
  );
  const [isAdding, setIsAdding] = useState(
    schedData.SchedulerID ? false : true
  );

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const {
    scheduledNotificationTypeList,
    scheduledNotificationTypesById,
    isLoading: typeIsLoading,
    error: typeError,
  } = useSelector((state: RootState) => state.scheduledNotificationTypes);

  const notificationTypes = scheduledNotificationTypeList.map(
    (id) => scheduledNotificationTypesById[id]
  );

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchScheduledNotificationTypes(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        dispatch(addScheduler(accessToken, values));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  if (schedData.SchedulerID) {
    //Update case
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });

          dispatch(updScheduler(accessToken, values.SchedulerID, values));
        } catch (e) {
          console.error(e);
        }
      })();
    };
  } else {
    schedData.ScheduledNotificationTypeID = -1;
    schedData.TriggerDate = null;
    schedData.RepeatNotification = true;
    schedData.RepeatFrequency = "Monthly";
    schedData.ItemCompleted = false;
  }

  const getJSDate = (inDate: string) => {
    if (inDate === null) {
      return null;
    } else {
      let newDate = inDate.slice(0, 10);
      return newDate;
    }
  };

  const handleDelete = () => {
    // function to delete current community investment entry
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(delScheduler(accessToken, schedData.SchedulerID));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const formik = useFormik({
    initialValues: schedData,
    validationSchema: validationSchema,
    onSubmit: submitFunc,
  });

  let schedForm =
    clientIsLoading || typeIsLoading ? (
      <LoadingIndicator />
    ) : (
      <Root>
        {!isAdding && (
          <Prompt
            when={formik.dirty}
            message="You have unsaved changes; are you sure you want to leave this page?"
          />
        )}

        <form onSubmit={formik.handleSubmit}>
          <Box display="flex" justifyContent="center">
            <Grid container className={classes.editForm} spacing={1}>
              <Grid item xs={12}>
                <Paper className={classes.boxSpace}>
                  <Grid container spacing={1}>
                    <IppFormHeader
                      title="Scheduled Notification"
                      isEditing={isEditing}
                      isAdding={isAdding}
                      returnPath="/admin/scheduler"
                    />
                    <Grid item xs={6}>
                      <IppAutocomplete
                        id="ScheduledNotificationTypeID"
                        options={notificationTypes}
                        value={notificationTypes.find((obj) => {
                          return (
                            obj.ScheduledNotificationTypeID ===
                            formik.values.ScheduledNotificationTypeID
                          );
                        })}
                        onChangeFunction={(
                          event: ChangeEvent,
                          newValue: any
                        ) => {
                          if (newValue) {
                            formik.setFieldValue(
                              "ScheduledNotificationTypeID",
                              newValue.ScheduledNotificationTypeID
                            );
                          } else {
                            formik.setFieldValue(
                              "ScheduledNotificationTypeID",
                              -1
                            );
                          }
                        }}
                        label="Notification Type*"
                        touchedFunction={
                          formik.touched.ScheduledNotificationTypeID
                        }
                        errorFunction={
                          formik.errors.ScheduledNotificationTypeID
                        }
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        optionLabelFunction={(option: any) =>
                          option.ScheduledNotificationTypeName
                        }
                        textValueFunction={
                          !typeIsLoading &&
                          !typeError &&
                          formik.values.ScheduledNotificationTypeID > 0 &&
                          scheduledNotificationTypesById[
                            formik.values.ScheduledNotificationTypeID
                          ]
                            ? scheduledNotificationTypesById[
                                formik.values.ScheduledNotificationTypeID
                              ].ScheduledNotificationTypeName
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <IppDatePicker
                        id="TriggerDate"
                        label="Trigger Date*"
                        value={ConvertDateOffset(formik.values.TriggerDate)}
                        onChangeFunction={(newValue: any) => {
                          setIsEditing(true);
                          formik.setFieldValue(
                            "TriggerDate",
                            GetJSDate(newValue),
                            true
                          );
                          formik.setFieldTouched("TriggerDate", true, false);
                        }}
                        errorsExpression={formik.errors.TriggerDate}
                        touchedExpression={formik.touched.TriggerDate}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                      />
                      {formik.values.RepeatNotification && (
                        <Typography variant="caption">
                          This will repeat{" "}
                          <strong>{formik.values.RepeatFrequency}</strong>
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <IppCheckbox
                        id="RepeatNotification"
                        label="Repeat notification?"
                        value={formik.values.RepeatNotification}
                        onChangeFunction={formik.handleChange}
                        isEditing={isEditing}
                      />
                    </Grid>
                    {formik.values.RepeatNotification && (
                      <Grid item xs={6}>
                        <IppAutocomplete
                          id="RepeatFrequency"
                          options={ScheduleFrequency}
                          value={formik.values.RepeatFrequency}
                          onChangeFunction={(
                            event: ChangeEvent,
                            newValue: any
                          ) => {
                            if (newValue) {
                              formik.setFieldValue("RepeatFrequency", newValue);
                            } else {
                              formik.setFieldValue("RepeatFrequency", "");
                            }
                          }}
                          textValueFunction={formik.values.RepeatFrequency}
                          touchedFunction={formik.touched.RepeatFrequency}
                          errorFunction={formik.errors.RepeatFrequency}
                          label="Frequency"
                          isEditing={isEditing}
                          setIsEditing={setIsEditing}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <IppFormButtons
                        isEditing={isEditing}
                        isAdding={isAdding}
                        setIsEditing={setIsEditing}
                        resetFunction={() => formik.resetForm()}
                        showDelete={true}
                        deleteFunction={() => handleDelete()}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Root>
    );

  return <div id="sched-form">{schedForm}</div>;
};
