import axiosClient from "api/axiosClient";

export interface CommitmentPendingAction {
  CommitmentID: number;
  SequenceID: number;
  CommitmentTitle: string;
  CommitmentActionID: number;
  ActionDescription: string;
  RequiredDate: Date;
  CommitmentStatusTypeName: string;
  PersonResponsible: string;
}

export interface CommitmentPendingActionsResult {
  commitmentPendingActions: CommitmentPendingAction[];
}

export async function getCommitmentPendingActions(
  accessToken: String
): Promise<CommitmentPendingActionsResult> {
  const url = `/commitmentHomePage/pendingActions`;
  try {
    const { data } = await axiosClient.get<CommitmentPendingActionsResult>(
      url,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}
