import axiosClient from "../axiosClient";

export interface GrievanceGroup {
  GrievanceGroupID: number;
  GroupID: number;
  GrievanceID: number;
  GrievanceName: string;
  GroupName: string;
  ClientID: number;
  GrievanceStatusText: string;
  ReportedDate: Date;
  ResolutionDate: Date;
  GrievanceProjectID: number;
  SequenceID: number;
}

export interface GrievanceGroupsResult {
  grievanceGroups: GrievanceGroup[];
}

export async function getGrievanceGroups(
  accessToken: String
): Promise<GrievanceGroupsResult> {
  const url = `/grievanceGroup`;

  try {
    const { data } = await axiosClient.get<GrievanceGroupsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getGrievanceGroupsByGrievance(
  accessToken: String,
  grievanceID: number
): Promise<GrievanceGroupsResult> {
  const url = `/grievanceGroup/grievance/${grievanceID}`;

  try {
    const { data } = await axiosClient.get<GrievanceGroupsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getGrievanceGroupsByGroup(
  accessToken: String,
  groupID: number
): Promise<GrievanceGroupsResult> {
  const url = `/grievanceGroup/group/${groupID}`;

  try {
    const { data } = await axiosClient.get<GrievanceGroupsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createGrievanceGroup(
  accessToken: String,
  newCommInv: Partial<GrievanceGroup>
): Promise<GrievanceGroup> {
  const url = `/grievanceGroup`;

  try {
    const { data } = await axiosClient.post<GrievanceGroup>(url, newCommInv, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateGrievanceGroup(
  accessToken: String,
  commInvID: number,
  newCommInv: Partial<GrievanceGroup>
): Promise<GrievanceGroup> {
  const url = `/grievanceGroup/` + commInvID;

  try {
    const { data } = await axiosClient.post<GrievanceGroup>(url, newCommInv, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteGrievanceGroup(
  accessToken: String,
  commInvID: number
): Promise<string> {
  const url = `/grievanceGroup/` + commInvID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
