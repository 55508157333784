import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import { IppSkeleton } from "components/IppSkeleton";
import { fetchEngagementLevels } from "./EngagementLevelSlice";
import { useTypedTranslation } from "utils/customHooks";

export const EngagementLevelChart = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["strGen"]);

  const { isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const {
    engagementLevelList,
    engagementLevelsById,
    isLoading: chartIsLoading,
  } = useSelector((state: RootState) => state.engagementLevels);

  const data = engagementLevelList.map((item) => engagementLevelsById[item]);

  const categories = data.map((item) => {
    let newItem = item.MonthName;
    return newItem;
  });

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchEngagementLevels(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [getAccessTokenSilently, dispatch]);

  let pageView =
    clientIsLoading || chartIsLoading ? (
      <IppSkeleton height={350} />
    ) : (
      <div>
        <Chart style={{ height: 350 }}>
          <ChartLegend visible={false} />

          <ChartValueAxis>
            <ChartValueAxisItem
              title={{
                text: t(
                  "strGen:pages.home.engagement.shadowboxes.engagementlevel.chartvalueaxis"
                ),
              }}
            />
          </ChartValueAxis>

          <ChartCategoryAxis>
            <ChartCategoryAxisItem categories={categories} />
          </ChartCategoryAxis>

          <ChartSeries>
            <ChartSeriesItem
              data={data}
              type="column"
              field="InteractionCount"
              name="Communications"
              tooltip={{
                visible: true,
                format: `${t("objStk:objects.interaction.name", {
                  count: 2,
                })}: {0:n0}`,
              }}
            />
          </ChartSeries>
        </Chart>
      </div>
    );

  return <div id="engagement-health-chart">{pageView}</div>;
};
