import axiosClient from "./axiosClient";

export interface Feedback {
  FeedbackID: number;
  FeedbackType: string;
  CurrentPage: string;
  Message: string;
  UserEmail: string;
  DateReported: Date;
  //not as crucial now
  FollowUpConsent: boolean;
  ClientID: number;
  IsHandled: boolean;
}

export async function getFeedbacks(accessToken: String): Promise<Feedback> {
  const url = `/feedback`;

  try {
    const { data } = await axiosClient.get<Feedback>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createFeedback(
  accessToken: String,
  newFeedback: Partial<Feedback>
): Promise<Feedback> {
  const url = `/feedback`;
  try {
    const { data } = await axiosClient.post<Feedback>(url, newFeedback, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    //console.log("FEEDBACK", data);
    return data;
  } catch (err: any) {
    console.log("FAILED ");
    throw err;
  }
}

export async function updateFeedback(
  accessToken: String,
  feedbackID: number,
  newFeedback: Partial<Feedback>
): Promise<Feedback> {
  const url = `/feedbacks/` + feedbackID;

  try {
    const { data } = await axiosClient.post<Feedback>(url, newFeedback, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteFeedback(
  accessToken: String,
  feedbackID: number
): Promise<string> {
  const url = `/feedback/` + feedbackID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    console.log("DELDATA", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}
