import { Cancel } from "@mui/icons-material";
import { ButtonProps } from "@mui/material/Button";
import { IppButton, IppButtonProps } from "components/Buttons/IppButton";
import { useTypedTranslation } from "utils/customHooks";

interface IppCancelButtonProps extends IppButtonProps {
  buttonText?: string;
  color?: ButtonProps["color"];
  startIcon?: React.ReactNode;
}

export const IppCancelButton = ({
  buttonText,
  color = "secondary",
  startIcon = <Cancel />,
  ...props
}: IppCancelButtonProps) => {
  const t = useTypedTranslation(["strGen"]);
  const T_buttonText = buttonText || t("strGen:buttons.cancel");

  return (
    <IppButton
      color={color}
      startIcon={startIcon}
      variant="outlined"
      {...props}
    >
      {T_buttonText}
    </IppButton>
  );
};
