import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  Supplier,
  SuppliersResult,
  getSuppliers,
  createSupplier,
  updateSupplier,
  deleteSupplier,
  getSuppliersForReview,
} from "api/supplier/supplierAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface SupplierState {
  suppliersById: Record<number, Supplier>;
  supplierList: number[];
  isLoading: boolean;
  error: string | null;
  errorList: any[] | null;
}

const SupplierInitialState: SupplierState = {
  suppliersById: {},
  supplierList: [],
  isLoading: false,
  error: null,
  errorList: null,
};

function startLoading(state: SupplierState) {
  state.isLoading = true;
}

function loadingFailed(
  state: SupplierState,
  action: PayloadAction<{ status: string; message: string; errors: [] }>
) {
  state.isLoading = false;
  state.error = action.payload.message;
  if (action.payload.errors) {
    state.errorList = action.payload.errors;
  }
}

const suppliers = createSlice({
  name: "suppliers",
  initialState: SupplierInitialState,
  reducers: {
    getSuppliersStart: startLoading,
    getSuppliersSuccess(state, { payload }: PayloadAction<SuppliersResult>) {
      const { suppliers } = payload;
      // console.log("PAYLOAD", suppliers);
      state.isLoading = false;
      state.error = null;
      state.errorList = null;

      suppliers.forEach((supplier) => {
        state.suppliersById[supplier.SupplierID] = supplier;
      });

      state.supplierList = suppliers.map((supplier) => supplier.SupplierID);
    },
    getSuppliersFailure: loadingFailed,
    createSupplierStart: startLoading,
    createSupplierSuccess(state, { payload }: PayloadAction<Supplier>) {
      const { SupplierID } = payload;
      state.suppliersById[SupplierID] = payload;
      state.supplierList.push(SupplierID);

      state.isLoading = false;
      state.error = null;
      state.errorList = null;
    },
    updateSupplierSuccess(state, { payload }: PayloadAction<Supplier>) {
      const { SupplierID } = payload;
      state.suppliersById[SupplierID] = payload;
      state.supplierList.push(SupplierID);

      state.isLoading = false;
      state.error = null;
      state.errorList = null;
    },
    deleteSupplierSuccess(state, { payload }: PayloadAction<number>) {
      const SupplierID = payload;
      delete state.suppliersById[SupplierID];
      state.supplierList = state.supplierList.filter(
        (item) => item !== SupplierID
      );

      state.isLoading = false;
      state.error = null;
      state.errorList = null;
    },
    createSupplierFailure: loadingFailed,
  },
});

export const {
  getSuppliersStart,
  getSuppliersSuccess,
  getSuppliersFailure,
  createSupplierStart,
  createSupplierSuccess,
  updateSupplierSuccess,
  deleteSupplierSuccess,
  createSupplierFailure,
} = suppliers.actions;

export default suppliers.reducer;

export const fetchSuppliers =
  (accessToken: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getSuppliersStart());
      const suppliers = await getSuppliers(accessToken);
      dispatch(getSuppliersSuccess(suppliers));
    } catch (err: any) {
      dispatch(getSuppliersFailure(err.toString()));
    }
  };

export const fetchSuppliersForReview =
  (accessToken: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getSuppliersStart());
      const suppliers = await getSuppliersForReview(accessToken);
      dispatch(getSuppliersSuccess(suppliers));
    } catch (err: any) {
      dispatch(getSuppliersFailure(err.toString()));
    }
  };

export const addSupplier =
  (accessToken: string, newSupplier: Partial<Supplier>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createSupplierStart());
      const supplier = await createSupplier(accessToken, newSupplier);
      dispatch(createSupplierSuccess(supplier));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push(`/suppliers/suppliers/${supplier.SupplierID}`));
    } catch (err: any) {
      dispatch(createSupplierFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updSupplier =
  (
    accessToken: string,
    supplierID: number,
    newSupplier: Partial<Supplier>,
    fromReview?: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createSupplierStart());
      const supplier = await updateSupplier(
        accessToken,
        supplierID,
        newSupplier
      );
      dispatch(updateSupplierSuccess(supplier));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      fromReview
        ? dispatch(push("/suppliers/suppliers/review/" + supplierID))
        : dispatch(push("/suppliers/suppliers/" + supplierID));
    } catch (err: any) {
      dispatch(createSupplierFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delSupplier =
  (accessToken: string, supplierID: number, fromReview?: boolean): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createSupplierStart());
      const result = await deleteSupplier(accessToken, supplierID);
      dispatch(deleteSupplierSuccess(supplierID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
      fromReview
        ? dispatch(push("/suppliers/suppliers/review"))
        : dispatch(push("/suppliers/suppliers/"));
    } catch (err: any) {
      dispatch(createSupplierFailure(err.toString()));
    }
  };
