import { FormikValues } from "formik";
import { IppRichTextEditor } from "components/RichTextEditor/IppRichTextEditor";
import { IppTextField } from "components/IppTextField";

// Get form data with valid changes
export const getValidChangedValues = (formik: any) => {
  const formValues: FormikValues = formik.values;
  const initialValues: FormikValues = formik.initialValues;
  for (const key in formValues) {
    if (Object.keys(formik.errors).includes(key)) {
      formValues[key] = initialValues[key];
    }
  }
  return formValues;
};

export const getNumField = (props: any) => {
  const { id, label, formik, isEditing, setIsEditing } = props;
  return (
    <IppTextField
      required
      id={id}
      label={label || ""}
      value={formik.values[id]?.toString()}
      onChangeFunction={formik.handleChange}
      // bug: formik.touched() is always false
      touchedExpression={formik.touched[id] || formik.errors[id]}
      errorsExpression={formik.errors[id]}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      fieldType="number"
    />
  );
};
export const getRichTextField = (props: any) => {
  const { id, label, formik, isEditing, height } = props;
  return (
    <IppRichTextEditor
      id={id}
      label={label}
      value={formik.values[id]}
      touchedExpression={formik.touched[id] || formik.errors[id]}
      errorsExpression={formik.errors[id]}
      isEditing={isEditing}
      setFieldValue={formik.setFieldValue}
      height={height}
    />
  );
};
