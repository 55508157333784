import axiosClient from "./axiosClient";

export interface Scheduler {
  SchedulerID: number;
  SchedulerName: string;
  ScheduledNotificationTypeID: number;
  TriggerDate: Date;
  RepeatNotification: boolean;
  RepeatFrequencey: string;
  ItemCompleted: boolean;
  ScheduledNotificationTypeName: string;
}

export interface SchedulersResult {
  schedulers: Scheduler[];
}

export async function getSchedulers(
  accessToken: String
): Promise<SchedulersResult> {
  const url = `/scheduler`;

  try {
    const { data } = await axiosClient.get<SchedulersResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getSchedulersActive(
  accessToken: String
): Promise<SchedulersResult> {
  const url = `/scheduler/active`;

  try {
    const { data } = await axiosClient.get<SchedulersResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createScheduler(
  accessToken: String,
  newScheduler: Partial<Scheduler>
): Promise<Scheduler> {
  const url = `/scheduler`;

  try {
    const { data } = await axiosClient.post<Scheduler>(url, newScheduler, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateScheduler(
  accessToken: String,
  schedulerID: number,
  newScheduler: Partial<Scheduler>
): Promise<Scheduler> {
  const url = `/scheduler/` + schedulerID;

  try {
    const { data } = await axiosClient.post<Scheduler>(url, newScheduler, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteScheduler(
  accessToken: String,
  schedulerID: number
): Promise<string> {
  const url = `/scheduler/` + schedulerID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    console.log("DELDATA", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}
