import { Grid, IconButton } from "@mui/material";
import { IppTextField } from "./IppTextField";
import { IppAutocomplete } from "./IppAutocomplete";
import { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { FormikProps } from "formik";
import { IppDisplayField } from "./IppDisplayField";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { markRequired } from "utils/functions";
import { useTypedTranslation } from "utils/customHooks";

interface IppLocationAutoCompleteMergeProps {
  formik: FormikProps<any>;
  oldValues: any;
  dupeValues: any;
  showAddress?: boolean;
  townCityOptions?: {
    show: boolean;
    required?: boolean;
  };
  provinceStateOptions?: {
    show: boolean;
    required?: boolean;
  };
  countryOptions?: {
    show: boolean;
    required?: boolean;
  };
  showPostalCode?: boolean;
  parentTitle?: string;
}

export const IppLocationAutoCompleteMerge: React.FC<
  IppLocationAutoCompleteMergeProps
> = ({
  formik,
  oldValues,
  dupeValues,
  showAddress,
  townCityOptions,
  provinceStateOptions,
  countryOptions,
  showPostalCode,
  parentTitle,
}) => {
  const t = useTypedTranslation(["objPlt"]);

  const { countriesStatesList, countriesStatesById } = useSelector(
    (state: RootState) => state.countriesStates
  );

  const countriesStates = countriesStatesList.map(
    (id) => countriesStatesById[id]
  );

  const { clientCountryList, clientCountriesById } = useSelector(
    (state: RootState) => state.clientCountries
  );

  const clientCountriesObject = clientCountryList.map(
    (id) => clientCountriesById[id]
  );
  const clientCountries = clientCountriesObject.map((item) => item.CountryName);
  const clientProvinceStates = countriesStates
    .filter((obj) => clientCountries.includes(obj.CountryName))
    .map((obj) => {
      return obj.ProvinceState;
    });
  const [provinces, setProvinces] = useState(clientProvinceStates);

  // Update Province/State Options anytime Form Country value changes
  useEffect(() => {
    const countryField =
      parentTitle === "Contact"
        ? formik.values.Country
        : formik.values.CountryName;

    const foundCountry = clientCountries.find(
      (country) => country === countryField
    );

    if (!countryField || !foundCountry) {
      setProvinces(clientProvinceStates);
    } else {
      const filteredProvinces = countriesStates
        .filter((obj) => obj.CountryName === countryField)
        .map((ele) => ele.ProvinceState);

      if (filteredProvinces.length === 0) {
        setProvinces(clientProvinceStates);
      } else {
        setProvinces(filteredProvinces);
      }
    }
  }, [formik.values.CountryName, formik.values.Country]);

  return (
    <>
      {showAddress && (
        <>
          <Grid item xs={4}>
            <Grid container spacing={0}>
              <Grid item xs={10}>
                <IppDisplayField
                  label={t("objPlt:platformwide.fields.address")}
                  value={oldValues.Address}
                  isEditing={false}
                  setIsEditing={null}
                  showEdit={false}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  onClick={() =>
                    formik.setFieldValue("Address", oldValues.Address)
                  }
                >
                  <ArrowRight />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <IppTextField
              id="Address"
              label={t("objPlt:platformwide.fields.address")}
              value={formik.values.Address}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.Address}
              errorsExpression={formik.errors.Address}
              isEditing={true}
              setIsEditing={null}
            />
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={0}>
              <Grid item xs={2}>
                <IconButton
                  onClick={() =>
                    formik.setFieldValue("Address", dupeValues.Address)
                  }
                >
                  <ArrowLeft />
                </IconButton>
              </Grid>
              <Grid item xs={10}>
                <IppDisplayField
                  label={t("objPlt:platformwide.fields.address")}
                  value={dupeValues.Address}
                  isEditing={false}
                  setIsEditing={null}
                  showEdit={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {townCityOptions && townCityOptions.show && (
        <>
          <Grid item xs={4}>
            <Grid container spacing={0}>
              <Grid item xs={10}>
                <IppDisplayField
                  label={t("objPlt:platformwide.fields.towncity")}
                  value={oldValues.TownCity}
                  isEditing={false}
                  setIsEditing={null}
                  showEdit={false}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  onClick={() =>
                    formik.setFieldValue("TownCity", oldValues.TownCity)
                  }
                >
                  <ArrowRight />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <IppTextField
              id="TownCity"
              label={markRequired(
                t("objPlt:platformwide.fields.towncity"),
                townCityOptions.required
              )}
              value={formik.values.TownCity}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.TownCity}
              errorsExpression={formik.errors.TownCity}
              isEditing={true}
              setIsEditing={null}
            />
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={0}>
              <Grid item xs={2}>
                <IconButton
                  onClick={() =>
                    formik.setFieldValue("TownCity", dupeValues.TownCity)
                  }
                >
                  <ArrowLeft />
                </IconButton>
              </Grid>
              <Grid item xs={10}>
                <IppDisplayField
                  label={t("objPlt:platformwide.fields.towncity")}
                  value={dupeValues.TownCity}
                  isEditing={false}
                  setIsEditing={null}
                  showEdit={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {provinceStateOptions && provinceStateOptions.show && (
        <>
          <Grid item xs={4}>
            <Grid container spacing={0}>
              <Grid item xs={10}>
                <IppDisplayField
                  label={t("objPlt:platformwide.fields.provincestate")}
                  value={oldValues.ProvinceState}
                  isEditing={false}
                  setIsEditing={null}
                  showEdit={false}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  onClick={() => {
                    formik.setFieldValue(
                      "ProvinceState",
                      oldValues.ProvinceState
                    );
                    formik.setFieldValue(
                      parentTitle === "Contact" ? "Country" : "CountryName",
                      countriesStates.find(
                        (obj) => obj.ProvinceState === oldValues.ProvinceState
                      )?.CountryName || ""
                    );
                  }}
                >
                  <ArrowRight />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <IppAutocomplete
              id="ProvinceState"
              options={provinces}
              value={formik.values.ProvinceState}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("ProvinceState", newValue);
                  formik.setFieldValue(
                    parentTitle === "Contact" ? "Country" : "CountryName",
                    countriesStates.find(
                      (obj) => obj.ProvinceState === newValue
                    )?.CountryName || ""
                  );
                } else {
                  formik.setFieldValue("ProvinceState", "");
                }
              }}
              textValueFunction={formik.values.ProvinceState}
              touchedFunction={formik.touched.ProvinceState}
              errorFunction={formik.errors.ProvinceState}
              label={markRequired(
                t("objPlt:platformwide.fields.provincestate"),
                provinceStateOptions.required
              )}
              isEditing={true}
              setIsEditing={null}
            />
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={0}>
              <Grid item xs={2}>
                <IconButton
                  onClick={() => {
                    formik.setFieldValue(
                      "ProvinceState",
                      dupeValues.ProvinceState
                    );
                    formik.setFieldValue(
                      parentTitle === "Contact" ? "Country" : "CountryName",
                      countriesStates.find(
                        (obj) => obj.ProvinceState === dupeValues.ProvinceState
                      )?.CountryName || ""
                    );
                  }}
                >
                  <ArrowLeft />
                </IconButton>
              </Grid>
              <Grid item xs={10}>
                <IppDisplayField
                  label={t("objPlt:platformwide.fields.provincestate")}
                  value={dupeValues.ProvinceState}
                  isEditing={false}
                  setIsEditing={null}
                  showEdit={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {countryOptions && countryOptions.show && (
        <>
          <Grid item xs={4}>
            <Grid container spacing={0}>
              <Grid item xs={10}>
                <IppDisplayField
                  label={t("objPlt:platformwide.fields.country")}
                  value={
                    parentTitle === "Contact"
                      ? oldValues.Country
                      : oldValues.CountryName
                  }
                  isEditing={false}
                  setIsEditing={null}
                  showEdit={false}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  onClick={() => {
                    formik.setFieldValue(
                      parentTitle === "Contact" ? "Country" : "CountryName",
                      parentTitle === "Contact"
                        ? oldValues.Country
                        : oldValues.CountryName
                    );
                  }}
                >
                  <ArrowRight />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <IppAutocomplete
              id={parentTitle === "Contact" ? "Country" : "CountryName"}
              options={clientCountries}
              value={
                parentTitle === "Contact"
                  ? formik.values.Country
                  : formik.values.CountryName
              }
              textValueFunction={
                parentTitle === "Contact"
                  ? formik.values.Country
                  : formik.values.CountryName
              }
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue(
                    parentTitle === "Contact" ? "Country" : "CountryName",
                    newValue
                  );
                  formik.setFieldValue("ProvinceState", "");
                  setProvinces(
                    countriesStates
                      .filter((obj) => obj.CountryName === newValue)
                      .map((ele) => ele.ProvinceState)
                  );
                } else {
                  formik.setFieldValue(
                    parentTitle === "Contact" ? "Country" : "CountryName",
                    ""
                  );
                  setProvinces(clientProvinceStates);
                }
              }}
              label={markRequired(
                t("objPlt:platformwide.fields.country"),
                countryOptions.required
              )}
              isEditing={true}
              setIsEditing={null}
              touchedFunction={
                parentTitle === "Contact"
                  ? formik.touched.Country
                  : formik.touched.CountryName
              }
              errorFunction={
                parentTitle === "Contact"
                  ? formik.errors.Country
                  : formik.errors.CountryName
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={0}>
              <Grid item xs={2}>
                <IconButton
                  onClick={() => {
                    formik.setFieldValue(
                      parentTitle === "Contact" ? "Country" : "CountryName",
                      parentTitle === "Contact"
                        ? dupeValues.Country
                        : dupeValues.CountryName
                    );
                  }}
                >
                  <ArrowLeft />
                </IconButton>
              </Grid>
              <Grid item xs={10}>
                <IppDisplayField
                  label={t("objPlt:platformwide.fields.country")}
                  value={
                    parentTitle === "Contact"
                      ? dupeValues.Country
                      : dupeValues.CountryName
                  }
                  isEditing={false}
                  setIsEditing={null}
                  showEdit={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {showPostalCode && (
        <>
          <Grid item xs={4}>
            <Grid container spacing={0}>
              <Grid item xs={10}>
                <IppDisplayField
                  label={t("objPlt:platformwide.fields.postalcode")}
                  value={oldValues.PostalCode}
                  isEditing={false}
                  setIsEditing={null}
                  showEdit={false}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  onClick={() =>
                    formik.setFieldValue("PostalCode", oldValues.PostalCode)
                  }
                >
                  <ArrowRight />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <IppTextField
              id="PostalCode"
              label={t("objPlt:platformwide.fields.postalcode")}
              value={formik.values.PostalCode}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.PostalCode}
              errorsExpression={formik.errors.PostalCode}
              isEditing={true}
              setIsEditing={null}
            />
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={0}>
              <Grid item xs={2}>
                <IconButton
                  onClick={() =>
                    formik.setFieldValue("PostalCode", dupeValues.PostalCode)
                  }
                >
                  <ArrowLeft />
                </IconButton>
              </Grid>
              <Grid item xs={10}>
                <IppDisplayField
                  label={t("objPlt:platformwide.fields.postalcode")}
                  value={dupeValues.PostalCode}
                  isEditing={false}
                  setIsEditing={null}
                  showEdit={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

IppLocationAutoCompleteMerge.defaultProps = {
  showAddress: true,
  townCityOptions: {
    show: true,
    required: false,
  },
  provinceStateOptions: {
    show: true,
    required: false,
  },
  countryOptions: {
    show: true,
    required: false,
  },
  showPostalCode: true,
};
