import { EnTExpenseForm } from "./EnTExpenseForm";

export const EnTExpenseCreatePage = () => {
  let entExpenseCreateView = (
    <div>
      <EnTExpenseForm />
    </div>
  );

  return <div id="entExpense-create-page"> {entExpenseCreateView} </div>;
};
