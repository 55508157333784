import { Box } from "@mui/material";
import { Loader } from "@progress/kendo-react-indicators";

function SmallLoadingIndicator() {
  return (
    <Box display="flex" justifyContent="center">
      <div>
        <Loader type="pulsing" />
      </div>
    </Box>
  );
}

export default SmallLoadingIndicator;
