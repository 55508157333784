import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CommitmentPendingAction,
  CommitmentPendingActionsResult,
  getCommitmentPendingActions,
} from "api/commitments/homePage/commitmentPendingActionAPI";
import { AppThunk } from "app/store";

interface CommitmentPendingActionState {
  commitmentPendingActionsById: Record<number, CommitmentPendingAction>;
  commitmentPendingActionList: number[];
  isLoading: boolean;
  error: string | null;
}

const CommitmentPendingActionInitialState: CommitmentPendingActionState = {
  commitmentPendingActionsById: {},
  commitmentPendingActionList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: CommitmentPendingActionState) {
  state.isLoading = true;
}

function loadingFailed(
  state: CommitmentPendingActionState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const commitmentPendingActions = createSlice({
  name: "spendtype",
  initialState: CommitmentPendingActionInitialState,
  reducers: {
    getCommitmentPendingActionsStart: startLoading,
    getCommitmentPendingActionsSuccess(
      state,
      { payload }: PayloadAction<CommitmentPendingActionsResult>
    ) {
      const { commitmentPendingActions } = payload;
      state.isLoading = false;
      state.error = null;

      commitmentPendingActions.forEach((item) => {
        state.commitmentPendingActionsById[item.CommitmentActionID] = item;
      });

      state.commitmentPendingActionList = commitmentPendingActions.map(
        (item) => item.CommitmentActionID
      );
    },
    getCommitmentPendingActionsFailure: loadingFailed,
  },
});

export const {
  getCommitmentPendingActionsStart,
  getCommitmentPendingActionsSuccess,
  getCommitmentPendingActionsFailure,
} = commitmentPendingActions.actions;

export default commitmentPendingActions.reducer;

export const fetchCommitmentPendingActions =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCommitmentPendingActionsStart());
      const commitmentPendingActions = await getCommitmentPendingActions(
        accessToken
      );
      dispatch(getCommitmentPendingActionsSuccess(commitmentPendingActions));
    } catch (err: any) {
      dispatch(getCommitmentPendingActionsFailure(err.toString()));
    }
  };
