import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ClientGroup,
  ClientGroupsResult,
  getClientGroups,
  createClientGroup,
  updateClientGroup,
  deleteClientGroup,
} from "api/clientGroupAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface ClientGroupState {
  clientGroupsById: Record<number, ClientGroup>;
  clientGroupList: number[];
  isLoading: boolean;
  error: string | null;
}

const ClientGroupInitialState: ClientGroupState = {
  clientGroupsById: {},
  clientGroupList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: ClientGroupState) {
  state.isLoading = true;
}

function loadingFailed(state: ClientGroupState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const clientGroups = createSlice({
  name: "clientGroups",
  initialState: ClientGroupInitialState,
  reducers: {
    getClientGroupsStart: startLoading,
    getClientGroupsSuccess(
      state,
      { payload }: PayloadAction<ClientGroupsResult>
    ) {
      const { clientGroups } = payload;
      // console.log("PAYLOAD", clientGroups);
      state.isLoading = false;
      state.error = null;

      clientGroups.forEach((clientGroup) => {
        state.clientGroupsById[clientGroup.ClientGroupID] = clientGroup;
      });

      state.clientGroupList = clientGroups.map(
        (clientGroup) => clientGroup.ClientGroupID
      );
    },
    getClientGroupsFailure: loadingFailed,
    createClientGroupStart: startLoading,
    createClientGroupSuccess(state, { payload }: PayloadAction<ClientGroup>) {
      const { ClientGroupID } = payload;
      state.clientGroupsById[ClientGroupID] = payload;
      state.clientGroupList.push(ClientGroupID);

      state.isLoading = false;
      state.error = null;
    },
    updateClientGroupSuccess(state, { payload }: PayloadAction<ClientGroup>) {
      const { ClientGroupID } = payload;
      state.clientGroupsById[ClientGroupID] = payload;

      state.isLoading = false;
      state.error = null;
    },
    deleteClientGroupSuccess(state, { payload }: PayloadAction<number>) {
      const ClientGroupID = payload;
      delete state.clientGroupsById[ClientGroupID];
      state.clientGroupList = state.clientGroupList.filter(
        (item) => item !== ClientGroupID
      );

      state.isLoading = false;
      state.error = null;
    },
    createClientGroupFailure: loadingFailed,
  },
});

export const {
  getClientGroupsStart,
  getClientGroupsSuccess,
  getClientGroupsFailure,
  createClientGroupStart,
  createClientGroupSuccess,
  updateClientGroupSuccess,
  deleteClientGroupSuccess,
  createClientGroupFailure,
} = clientGroups.actions;

export default clientGroups.reducer;

export const fetchClientGroups =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getClientGroupsStart());
      const clientGroups = await getClientGroups(accessToken);
      //console.log("RETURN", clientGroups);
      dispatch(getClientGroupsSuccess(clientGroups));
    } catch (err: any) {
      dispatch(getClientGroupsFailure(err.toString()));
    }
  };

export const addClientGroup =
  (accessToken: String, newClientGroup: Partial<ClientGroup>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientGroupStart());
      const clientGroup = await createClientGroup(accessToken, newClientGroup);
      dispatch(createClientGroupSuccess(clientGroup));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push("/admin/clientgroups"));
    } catch (err: any) {
      dispatch(createClientGroupFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updClientGroup =
  (
    accessToken: String,
    clientGroupID: number,
    newClientGroup: Partial<ClientGroup>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientGroupStart());
      const clientGroup = await updateClientGroup(
        accessToken,
        clientGroupID,
        newClientGroup
      );
      dispatch(updateClientGroupSuccess(clientGroup));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      // dispatch(push("/clientGroups/" + clientGroupID));
    } catch (err: any) {
      dispatch(createClientGroupFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delClientGroup =
  (accessToken: String, clientGroupID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientGroupStart());
      const result = await deleteClientGroup(accessToken, clientGroupID);
      dispatch(deleteClientGroupSuccess(clientGroupID));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push("/admin/clientgroups"));
    } catch (err: any) {
      dispatch(createClientGroupFailure(err.toString()));
    }
  };
