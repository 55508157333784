// define a standard skeleton-type placeholder to show while objects are loading

import { Skeleton } from "@mui/material";

interface IppSkeletonProps {
  height: number;
}
export const IppSkeleton = (props: IppSkeletonProps) => {
  const { height } = props;

  return <Skeleton variant="rectangular" animation="wave" height={height} />;
};
