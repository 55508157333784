import axiosClient from "api/axiosClient";
import { DateAsString } from "utils/DateFunctions";

export interface VendorActiveEmployees {
  IndigenousGroupID: number;
  IndigenousGroupName: string;
  EmployeeCount: number;
  TotalHours: number;
}

export interface VendorActiveEmployeesResult {
  vendorActiveEmployees: VendorActiveEmployees[];
}

export interface VendorActiveEmployeesByCategory {
  CustomEmpCategoryID: number;
  CustomEmpCategoryName: string;
  EmployeeCount: number;
  TotalHours: number;
}

export interface VendorActiveEmployeesByCategoryResult {
  vendorActiveEmployeesByCategory: VendorActiveEmployeesByCategory[];
}

export interface VendorActiveEmployeesByGender {
  DisciplineID: number;
  Discipline: string;
  FemaleCount: number;
  FemalePercentage: number;
  MaleCount: number;
  MalePercentage: number;
  OtherCount: number;
  OtherPercentage: number;
  TotalCount: number;
  FemaleWorkHours: number;
  FemaleWorkHoursPercentage: number;
  MaleWorkHours: number;
  MaleWorkHoursPercentage: number;
  OtherWorkHours: number;
  OtherWorkHoursPercentage: number;
  TotalHours: number;
}

export interface VendorActiveEmployeesByGenderResult {
  vendorActiveEmployeesByGender: VendorActiveEmployeesByGender[];
}

export async function getVendorActiveEmployees(
  accessToken: String,
  startDate: Date,
  endDate: Date,
  regionID: number,
  projectID: number
): Promise<VendorActiveEmployeesResult> {
  const start = DateAsString(startDate);
  const end = DateAsString(endDate);
  const url = `/charts/activeEmployees/${start}/${end}/${regionID}/${projectID}`;
  try {
    const { data } = await axiosClient.get<VendorActiveEmployeesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getVendorActiveEmployeesByCategory(
  accessToken: String,
  startDate: Date,
  endDate: Date,
  regionID: number,
  projectID: number
): Promise<VendorActiveEmployeesByCategoryResult> {
  const start = DateAsString(startDate);
  const end = DateAsString(endDate);
  const url = `/charts/activeEmployeesByCategory/${start}/${end}/${regionID}/${projectID}`;
  try {
    const { data } =
      await axiosClient.get<VendorActiveEmployeesByCategoryResult>(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getVendorActiveEmployeesByGender(
  accessToken: String,
  startDate: Date,
  endDate: Date,
  regionID: number,
  projectID: number
): Promise<VendorActiveEmployeesByGenderResult> {
  const start = DateAsString(startDate);
  const end = DateAsString(endDate);
  const url = `/charts/activeEmployeesByGender/${start}/${end}/${regionID}/${projectID}`;
  try {
    const { data } = await axiosClient.get<VendorActiveEmployeesByGenderResult>(
      url,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}
