import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import { useDatePickerDefaultizedProps } from "@mui/x-date-pickers/DatePicker/shared";
import { markRequired } from "utils/functions";
import { IconButton, Tooltip } from "@mui/material";

interface IppDatePickerProps {
  id: string;
  label: string;
  value: any;
  onChangeFunction: any;
  touchedExpression?: any;
  errorsExpression?: any;
  isEditing: boolean;
  setIsEditing: any;
  disablePast?: any;
  disableFuture?: any;
  required?: boolean;
  onError?: any;
  yearOnly?: boolean;
  monthOnly?: boolean;
  disabled?: boolean;
  toolTip?: string;
}

export const IppDatePicker = (props: IppDatePickerProps) => {
  const {
    id,
    label,
    value,
    onChangeFunction,
    touchedExpression,
    errorsExpression,
    isEditing,
    setIsEditing,
    disablePast,
    disableFuture,
    required,
    yearOnly,
    monthOnly,
    disabled,
    toolTip,
  } = props;

  // can later be used for admin controls of date format
  /* const [locale, setLocale] = React.useState<keyof typeof maskMap>('en');
    const selectLocale = (newLocale: any) => {
        setLocale(newLocale);
      }; */

  const editChange = () => {
    setIsEditing(!isEditing);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div>
        <DatePicker
          {...useDatePickerDefaultizedProps}
          inputFormat={yearOnly ? "yyyy" : monthOnly ? "MM/yy" : "yyyy-MM-dd"}
          label={markRequired(label, required)}
          disabled={disabled}
          views={
            yearOnly ? ["year"] : monthOnly ? ["month", "year"] : undefined
          }
          disableFuture={disableFuture}
          disablePast={disablePast}
          value={value}
          clearable={true}
          onChange={onChangeFunction}
          renderInput={(params: any) => (
            <TextField
              {...params}
              disabled={disabled}
              helperText={touchedExpression && errorsExpression}
              error={touchedExpression && Boolean(errorsExpression)}
              id={id}
              InputLabelProps={{
                shrink: true,
                style: { pointerEvents: "auto" },
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: toolTip ? (
                  <Tooltip title={toolTip}>
                    <IconButton>{params.InputProps.endAdornment}</IconButton>
                  </Tooltip>
                ) : (
                  params.InputProps.endAdornment
                ),
              }}
            />
          )}
        />
      </div>
    </LocalizationProvider>
  );
};
