import axiosClient from "../axiosClient";

export interface Status {
  StatusID: number;
  StatusName: string;
}

export interface StatusResult {
  statuses: Status[];
}

export async function getStatus(accessToken: String): Promise<StatusResult> {
  const url = `/status`;

  try {
    const { data } = await axiosClient.get<StatusResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    //console.log("API", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}
