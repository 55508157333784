import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  BenefitsTiles,
  BenefitsTilesResult,
  getBenefitsTiles,
} from "api/benefits/charts/benefitsTilesAPI";

import { AppThunk } from "app/store";

interface BenefitsTilesState {
  benefitsTilesById: Record<number, BenefitsTiles>;
  benefitsTilesList: number[];
  isLoading: boolean;
  error: string | null;
}

const BenefitsTilesInitialState: BenefitsTilesState = {
  benefitsTilesById: {},
  benefitsTilesList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: BenefitsTilesState) {
  state.isLoading = true;
}

function loadingFailed(
  state: BenefitsTilesState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const benefitsTiles = createSlice({
  name: "benefitsTiles",
  initialState: BenefitsTilesInitialState,
  reducers: {
    getBenefitsTilesStart: startLoading,
    getBenefitsTilesSuccess(
      state,
      { payload }: PayloadAction<BenefitsTilesResult>
    ) {
      const { benefitsTiles } = payload;
      state.isLoading = false;
      state.error = null;

      benefitsTiles.forEach((item) => {
        state.benefitsTilesById[item.ItemID] = item;
      });

      state.benefitsTilesList = benefitsTiles.map((item) => item.ItemID);
    },
    getBenefitsTilesFailure: loadingFailed,
  },
});

export const {
  getBenefitsTilesStart,
  getBenefitsTilesSuccess,
  getBenefitsTilesFailure,
} = benefitsTiles.actions;

export default benefitsTiles.reducer;

export const fetchBenefitsTiles =
  (accessToken: String): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(getBenefitsTilesStart());
      const result = await getBenefitsTiles(accessToken);
      dispatch(getBenefitsTilesSuccess(result));
    } catch (err: any) {
      dispatch(getBenefitsTilesFailure(err.message));
    }
  };
