import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  LevelOfImportance,
  LevelOfImportancesResult,
  getLevelOfImportances,
} from "api/dataList/levelOfImportanceAPI";
import { AppThunk } from "app/store";

interface LevelOfImportanceState {
  levelOfImportancesById: Record<number, LevelOfImportance>;
  levelOfImportanceList: number[];
  isLoading: boolean;
  error: string | null;
}

const LevelOfImportanceInitialState: LevelOfImportanceState = {
  levelOfImportancesById: {},
  levelOfImportanceList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: LevelOfImportanceState) {
  state.isLoading = true;
}

function loadingFailed(state: LevelOfImportanceState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const levelOfImportances = createSlice({
  name: "levelOfImportance",
  initialState: LevelOfImportanceInitialState,
  reducers: {
    getLevelOfImportancesStart: startLoading,
    getLevelOfImportancesSuccess(
      state,
      { payload }: PayloadAction<LevelOfImportancesResult>
    ) {
      const { levelOfImportances } = payload;
      state.isLoading = false;
      state.error = null;

      levelOfImportances.forEach((levelOfImportance) => {
        state.levelOfImportancesById[levelOfImportance.LevelOfImportanceID] = levelOfImportance;
      });

      state.levelOfImportanceList = levelOfImportances.map(
        (levelOfImportance) => levelOfImportance.LevelOfImportanceID
      );
    },
    getLevelOfImportancesFailure: loadingFailed,
  },
});

export const {
  getLevelOfImportancesStart,
  getLevelOfImportancesSuccess,
  getLevelOfImportancesFailure,
} = levelOfImportances.actions;

export default levelOfImportances.reducer;

export const fetchLevelOfImportances =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getLevelOfImportancesStart());
      const levelOfImportances = await getLevelOfImportances(accessToken);
      //console.log("RETURN", levelOfImportances);
      dispatch(getLevelOfImportancesSuccess(levelOfImportances));
    } catch (err: any) {
      dispatch(getLevelOfImportancesFailure(err.toString()));
    }
};

