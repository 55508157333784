import axiosClient from "./axiosClient";

export interface ProximityIndicatorLSD {
  PILSDID: number;
  ProximityIndicator: string;
  LegalSubdivision: string;
  ClientID?: number;
  RecordType?: string;
  RecordID?: number;
}

export interface ProximityIndicatorLSDsResult {
  proximityIndicatorLSDs: ProximityIndicatorLSD[];
}

export async function getProximityIndicatorLSDs(
  accessToken: String
): Promise<ProximityIndicatorLSDsResult> {
  const url = `/proximityIndicatorLSD`;

  try {
    const { data } = await axiosClient.get<ProximityIndicatorLSDsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
