import { UserRole } from "api/userRoleAPI";
import {
  CommitmentsActionCreateResource,
  CommitmentsActionDeleteResource,
  CommitmentsActionEditResource,
  CommitmentsCommitmentEditResource,
  CommitmentsPermissions,
  ModuleRoles,
  Modules,
  PermissionCondition,
} from "utils/types/index.types";

const checkIsPowerUser = (userRoles: UserRole[]) =>
  userRoles.some(
    (role) => role.ModuleRoleID === ModuleRoles.CommitmentsPowerUser
  );

const checkIsContributor = (userRoles: UserRole[]) =>
  userRoles.some(
    (role) => role.ModuleRoleID === ModuleRoles.CommitmentsContributor
  );

const checkCommitmentEditPermission: PermissionCondition<
  CommitmentsCommitmentEditResource
> = (userAccountID, resource, matchingRoles): boolean => {
  const isPowerUser = checkIsPowerUser(matchingRoles);

  if (isPowerUser) return true;

  const isContributor = checkIsContributor(matchingRoles);

  // Contributors can edit if they are the person responsible
  return isContributor && resource.personResponsible === userAccountID;
};

const checkActionCreatePermission: PermissionCondition<
  CommitmentsActionCreateResource
> = (userAccountID, resource, matchingRoles): boolean => {
  const isPowerUser = checkIsPowerUser(matchingRoles);

  if (isPowerUser) return true;

  const isContributor = checkIsContributor(matchingRoles);

  // Contributors can create if they are the person responsible for the parent Commitment
  return isContributor && resource.parent?.PersonResponsible === userAccountID;
};

const checkActionEditPermission: PermissionCondition<
  CommitmentsActionEditResource
> = (userAccountID, resource, matchingRoles): boolean => {
  const isPowerUser = checkIsPowerUser(matchingRoles);

  if (isPowerUser) return true;

  const isContributor = checkIsContributor(matchingRoles);

  // Contributors can edit if they are responsible for the Action or its parent Commitment
  return (
    isContributor &&
    (resource.personResponsible === userAccountID ||
      resource.parent?.PersonResponsible === userAccountID)
  );
};

const checkActionDeletePermission: PermissionCondition<
  CommitmentsActionDeleteResource
> = (userAccountID, resource, matchingRoles): boolean => {
  const isPowerUser = checkIsPowerUser(matchingRoles);

  if (isPowerUser) return true;

  const isContributor = checkIsContributor(matchingRoles);

  // Contributors can delete Actions if they are responsible for the Action or its parent Commitment
  return (
    isContributor &&
    (resource.personResponsible === userAccountID ||
      resource.parent?.PersonResponsible === userAccountID)
  );
};

/**
 * **Permissions Definitions for the Commitments Module**
 *
 * Specifies which user roles are allowed to perform certain actions (create, edit, delete) on each resource type, and includes any additional conditions that must be met for the action to be permitted.
 *
 * **Structure Overview:**
 *
 * - The top-level keys represent the different resource types:
 *   - `'Source'`
 *   - `'Amendment'`
 *   - `'Commitment'`
 *   - `'Action'`
 *
 * - Each resource type contains an object mapping CRUD actions to their corresponding permissions:
 *   - `'create'`
 *   - `'edit'`
 *   - `'delete'`
 *
 * - Each action maps to a `PermissionDetail` object, which includes:
 *   - `allowedRoles`: An array of roles that are permitted to perform the action.
 *     - Each allowed role specifies the module and the role identifier.
 *   - `condition` (optional): A function that implements additional business logic checks for evaluating permissions.
 *
 * **Purpose and Usage:**
 *
 * - This permissions object is utilized by the usePermissions hook to determine if a user is authorized to perform a specific action on a resource.
 * - The system checks the user's roles against the `allowedRoles` for the action.
 * - If a `condition` is specified, the system also evaluates it to enforce additional business logic that cannot be captured by roles alone.
 * - This setup allows for granular control over permissions and helps ensure that users can only perform actions they are permitted to.
 *
 * **Example Usage:**
 *
 * ```typescript
 * const canEditCommitment = isActionAllowed(user, {
 *   module: 'Commitments',
 *   type: 'Commitment',
 *   action: 'edit',
 *   projectID: 123,
 *   personResponsible: 456,
 * });
 * ```
 *
 * In this example, the system will:
 * - Check if the user has either the `CommitmentsPowerUser` or `CommitmentsContributor` role.
 * - If the user is a `CommitmentsContributor`, verify that they are the person responsible for the commitment.
 *
 */

// Define the permissions for the Commitments module
export const commitmentsPermissions: CommitmentsPermissions = {
  Source: {
    create: {
      allowedRoles: [
        {
          module: Modules.Commitments,
          role: ModuleRoles.CommitmentsPowerUser,
        },
      ],
    },
    edit: {
      allowedRoles: [
        {
          module: Modules.Commitments,
          role: ModuleRoles.CommitmentsPowerUser,
        },
      ],
    },
    delete: {
      allowedRoles: [
        {
          module: Modules.Commitments,
          role: ModuleRoles.CommitmentsPowerUser,
        },
      ],
    },
  },
  Amendment: {
    create: {
      allowedRoles: [
        {
          module: Modules.Commitments,
          role: ModuleRoles.CommitmentsPowerUser,
        },
      ],
    },
    edit: {
      allowedRoles: [
        {
          module: Modules.Commitments,
          role: ModuleRoles.CommitmentsPowerUser,
        },
      ],
    },
    delete: {
      allowedRoles: [
        {
          module: Modules.Commitments,
          role: ModuleRoles.CommitmentsPowerUser,
        },
      ],
    },
  },
  Commitment: {
    create: {
      allowedRoles: [
        {
          module: Modules.Commitments,
          role: ModuleRoles.CommitmentsPowerUser,
        },
      ],
    },
    edit: {
      allowedRoles: [
        {
          module: Modules.Commitments,
          role: ModuleRoles.CommitmentsPowerUser,
        },
        {
          module: Modules.Commitments,
          role: ModuleRoles.CommitmentsContributor,
        },
      ],
      condition: checkCommitmentEditPermission,
    },
    delete: {
      allowedRoles: [
        {
          module: Modules.Commitments,
          role: ModuleRoles.CommitmentsPowerUser,
        },
      ],
    },
  },
  Action: {
    create: {
      allowedRoles: [
        {
          module: Modules.Commitments,
          role: ModuleRoles.CommitmentsPowerUser,
        },
        {
          module: Modules.Commitments,
          role: ModuleRoles.CommitmentsContributor,
        },
      ],
      condition: checkActionCreatePermission,
    },
    edit: {
      allowedRoles: [
        {
          module: Modules.Commitments,
          role: ModuleRoles.CommitmentsPowerUser,
        },
        {
          module: Modules.Commitments,
          role: ModuleRoles.CommitmentsContributor,
        },
      ],
      condition: checkActionEditPermission,
    },
    delete: {
      allowedRoles: [
        {
          module: Modules.Commitments,
          role: ModuleRoles.CommitmentsPowerUser,
        },
        {
          module: Modules.Commitments,
          role: ModuleRoles.CommitmentsContributor,
        },
      ],
      condition: checkActionDeletePermission,
    },
  },
};
