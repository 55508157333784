import { Suspense, useEffect, useCallback } from "react";
import { History } from "history";
//import { HistoryRouter as Router } from "redux-first-history/rr6";
import { Router } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";
import routes from "../routes";
import { styled, useTheme } from "@mui/material/styles";
import "@progress/kendo-theme-material/dist/all.css";
import "./App.css";
import Header from "../features/header/header";
import Footer from "../features/footer/footer";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchClientId } from "features/client/ClientSlice";
import { fetchCategories } from "features/datalists/CategorySlice";
import {
  fetchCurrentUserRoles,
  fetchProfile,
} from "features/profile/ProfileSlice";
import { fetchClientModules } from "features/roles/ClientModuleSlice";
import { fetchModuleRoles } from "features/roles/ModuleRoleSlice";
import { fetchProjects } from "features/project/ProjectSlice";
import { fetchClientCountries } from "features/platform/admin/clientCountry/ClientCountrySlice";
import IppSnackBars from "features/snackBar/IppSnackBar";
import ErrorPage from "./errorPage";
import { fetchWorkflowStages } from "features/datalists/WorkflowStageSlice";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import Splashscreen from "components/Splashscreen";
import debugModule from "debug";
import { getCustomLabels } from "../api/i18nAPI";
import { DatePickerPropsContext } from "@progress/kendo-react-dateinputs";
import { fetchCountriesStates } from "features/datalists/CountriesStatesSlice";
import { fetchClientCurrencies } from "features/platform/admin/currency/ClientCurrencySlice";
import IppScrollToTopButton from "components/Buttons/IppScrollToTopButton";
import { fetchAllUserRoles } from "features/roles/UserRoleSlice";

const PREFIX = "AppPage";

// check if debug logging is enabled
if (process.env.REACT_APP_DEBUG) {
  debugModule.enable(process.env.REACT_APP_DEBUG);
  debugModule.log = console.info.bind(console);
} else {
  // disable debug logging
  // could also keep enabled but direct logging to another stdout
  debugModule.disable();
}

export const IppDebug = debugModule("WEB");

const classes = {
  root: `${PREFIX}-root`,
  main: `${PREFIX}-main`,
  paper: `${PREFIX}-paper`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    background: "linear-gradient(#E3E4E5, #E3E4E5)",
  },
  [`& .${classes.main}`]: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  [`& th.k-header.active > div > div`]: {
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
  },
}));

interface AppProps {
  history: History;
}

declare module "@mui/material/styles" {
  interface Palette {
    headerColor?: Palette["primary"];
  }
  interface PaletteOptions {
    headerColor?: PaletteOptions["primary"];
  }
}

function App({ history }: AppProps) {
  const dispatch = useDispatch();
  const { getAccessTokenSilently, isLoading, isAuthenticated } = useAuth0();

  const {
    clientName,
    headerColor,
    primaryColor,
    secondaryColor,
    isLoading: isClientLoading,
    error: clientError,
  } = useSelector((state: RootState) => state.client);

  const { isLoading: profileIsLoading } = useSelector(
    (state: RootState) => state.profile
  );

  const { isLoading: userRolesIsLoading } = useSelector(
    (state: RootState) => state.userRoles
  );

  const { isLoading: clientCurrencyIsLoading } = useSelector(
    (state: RootState) => state.clientCurrencies
  );

  const datePickerProps = useCallback(
    (datePickerProps: any) => ({
      ...datePickerProps,
      format: "yyyy-MM-dd",
    }),
    []
  );

  // regular expression to validate HEX code
  const reg = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i;

  const existingTheme = useTheme();

  const globalTheme = createTheme({
    palette: {
      primary: {
        light:
          primaryColor && reg.test(primaryColor) ? primaryColor : "#0B618A",
        main: primaryColor && reg.test(primaryColor) ? primaryColor : "#0B618A",
      },
      secondary: {
        light:
          secondaryColor && reg.test(secondaryColor)
            ? secondaryColor
            : "#0B618A",
        main:
          secondaryColor && reg.test(secondaryColor)
            ? secondaryColor
            : "#0B618A",
        dark: "#00000099",
      },
      error: {
        ...existingTheme.palette.error,
        light: "#FFEBEE",
      },
      warning: {
        ...existingTheme.palette.warning,
        light: "#FFF3E0",
      },
      headerColor: {
        light: headerColor && reg.test(headerColor) ? headerColor : "#0B618A",
        main: headerColor && reg.test(headerColor) ? headerColor : "#0B618A",
      },
      tonalOffset: 0.5,
    },
    components: {
      MuiButton: {
        defaultProps: {
          color: "secondary",
          size: "small",
          variant: "contained",
        },
        styleOverrides: {
          root: { marginRight: "5px" },
        },
      },
      MuiTextField: {
        defaultProps: {
          fullWidth: true,
          margin: "dense",
          size: "small",
        },
        styleOverrides: {
          root: { marginRight: "5px" },
        },
      },
      MuiChip: {
        defaultProps: {
          color: "primary",
        },
        styleOverrides: {
          root: { marginRight: "5px" },
        },
      },
    },
  });

  useEffect(() => {
    IppDebug("Starting app with debug logging enabled");
  }, []);

  //load commonly used data into Redux
  useEffect(() => {
    (async () => {
      try {
        if (!isLoading && isAuthenticated) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchClientId(accessToken, clientName));
          dispatch(fetchProfile(accessToken));
          dispatch(fetchCategories(accessToken));
          dispatch(fetchCurrentUserRoles(accessToken));
          dispatch(fetchClientModules(accessToken));
          dispatch(fetchModuleRoles(accessToken));
          dispatch(fetchProjects(accessToken));
          dispatch(fetchWorkflowStages(accessToken));
          dispatch(fetchClientCountries(accessToken));
          dispatch(fetchCountriesStates(accessToken));
          dispatch(fetchClientCurrencies(accessToken));
          dispatch(fetchAllUserRoles(accessToken));
          await getCustomLabels(accessToken, "en");
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [
    isLoading,
    isAuthenticated,
    clientName,
    dispatch,
    getAccessTokenSilently,
  ]);

  if (clientError) {
    console.log("checking App");
    return (
      <Root>
        <ErrorPage />
        <Footer />
      </Root>
    );
  }

  return isClientLoading ||
    clientCurrencyIsLoading ||
    profileIsLoading ||
    userRolesIsLoading ? (
    <Splashscreen />
  ) : (
    <Suspense fallback={<Splashscreen />}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={globalTheme}>
          <DatePickerPropsContext.Provider value={datePickerProps}>
            <Root>
              <div className={classes.root}>
                <Router history={history}>
                  <CompatRouter>
                    {!isLoading && (
                      <div>
                        <Header />
                        <IppSnackBars />
                        <div className={classes.main}>{routes}</div>
                        <IppScrollToTopButton />
                      </div>
                    )}
                  </CompatRouter>
                </Router>
                <Footer />
              </div>
            </Root>
          </DatePickerPropsContext.Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Suspense>
  );
}

export default App;
