import axiosClient from "../axiosClient";

export interface Grievance {
  GrievanceID: number;
  GrievanceName: string;
  Details: string;
  ReportedDate: Date;
  PersonResponsible: number;
  ProjectID: number;
  ClientID: number;
  ResolutionDate: Date;
  GrievanceStatusText: string;
  FirstName: string;
  Surname: string;
  PersonResponsibleName: string;
  LatestInteraction: Date;
  SequenceID: number;
  Reference: string;
  Accomodation: string;
  InterestAddressed: string;
  ResidualConcerns: string;
}

export interface GrievancesResult {
  grievances: Grievance[];
}

export async function getGrievances(
  accessToken: String
): Promise<GrievancesResult> {
  const url = `/grievance`;

  try {
    const { data } = await axiosClient.get<GrievancesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

// NOT BEING USED

// export async function getGrievancesByIssue(
//   accessToken: String,
//   issueID: number
// ): Promise<GrievancesResult> {
//   const url = `/grievance/issue/${issueID}`;

//   try {
//     const { data } = await axiosClient.get<GrievancesResult>(url, {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//     });

//     return data;
//   } catch (err: any) {
//     throw err;
//   }
// }

// export async function getGrievancesByContact(
//   accessToken: String,
//   contactID: number
// ): Promise<GrievancesResult> {
//   const url = `/grievance/contact/${contactID}`;

//   try {
//     const { data } = await axiosClient.get<GrievancesResult>(url, {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//     });

//     return data;
//   } catch (err: any) {
//     throw err;
//   }
// }

// export async function getGrievancesByInteraction(
//   accessToken: String,
//   interactionID: number
// ): Promise<GrievancesResult> {
//   const url = `/grievance/interaction/${interactionID}`;

//   try {
//     const { data } = await axiosClient.get<GrievancesResult>(url, {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//     });

//     return data;
//   } catch (err: any) {
//     throw err;
//   }
// }

// export async function getGrievancesByGroup(
//   accessToken: String,
//   groupID: number
// ): Promise<GrievancesResult> {
//   const url = `/grievance/group/${groupID}`;

//   try {
//     const { data } = await axiosClient.get<GrievancesResult>(url, {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//     });

//     return data;
//   } catch (err: any) {
//     throw err;
//   }
// }

export async function createGrievance(
  accessToken: String,
  newCommInv: Partial<Grievance>,
  contactList: any[],
  groupList: string[],
  issueList: string[],
  interactionList: number[]
): Promise<Grievance> {
  const url = `/grievance`;

  try {
    const { data } = await axiosClient.post<Grievance>(
      url,
      {
        itemData: newCommInv,
        contactList: contactList,
        groupList: groupList,
        issueList: issueList,
        interactionList: interactionList,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateGrievance(
  accessToken: String,
  commInvID: number,
  newCommInv: Partial<Grievance>,
  contactList: any[] | null,
  groupList: string[] | null,
  issueList: string[] | null
): Promise<Grievance> {
  const url = `/grievance/` + commInvID;

  try {
    const { data } = await axiosClient.post<Grievance>(
      url,
      {
        itemData: newCommInv,
        contactList: contactList,
        groupList: groupList,
        issueList: issueList,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteGrievance(
  accessToken: String,
  commInvID: number
): Promise<string> {
  const url = `/grievance/` + commInvID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
