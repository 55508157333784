import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { IppSkeleton } from "components/IppSkeleton";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { addClientCountry, delClientCountry } from "./ClientCountrySlice";
import { IppFormDivider } from "components/IppFormDivider";
import { IppSaveButton } from "components/Buttons/IppSaveButton";
import { fetchCountries } from "features/datalists/CountrySlice";

const PREFIX = "ClientCountryForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
  roleCard: `${PREFIX}-roleCard`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1100,
  },

  [`& .${classes.boxSpace}`]: {
    padding: 10,
  },

  [`& .${classes.roleCard}`]: {
    minWidth: 250,

    minHeight: 300,
  },
}));

// format for display lists
interface listValue {
  countryID: number;
  clientCountryID: number;
  countryName: string;
  wasSelected: boolean; // to track deletions
}

export const ClientCountryForm = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [pageLoaded, setPageLoaded] = useState(false);

  // allCountries contains unfiltered list of groups
  // filteredCountries contains list of groups with filter applied
  // selectedCountries contains list of items selected
  const [allCountries, setAllCountries] = useState<listValue[]>([]);
  const [filteredCountries, setFilteredCountries] = useState<listValue[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<listValue[]>([]);
  const [filterValue, setFilterValue] = useState("");

  const { clientId } = useSelector((state: RootState) => state.client);

  const {
    countriesById,
    countriesList,
    isLoading: countryIsLoading,
  } = useSelector((state: RootState) => state.countries);

  const countries = countriesList.map((id) => countriesById[id]);

  const {
    clientCountryList,
    clientCountriesById,
    isLoading: clientCountryIsLoading,
  } = useSelector((state: RootState) => state.clientCountries);

  const clientCountries = clientCountryList.map(
    (id) => clientCountriesById[id]
  );

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchCountries(accessToken));
        setPageLoaded(true);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  // make sure lists are initialized only one time
  useEffect(() => {
    if (
      pageLoaded &&
      !countryIsLoading &&
      !clientCountryIsLoading &&
      allCountries.length === 0
    ) {
      initializeLists();
    }
  }, [pageLoaded, countryIsLoading, clientCountryIsLoading]);

  const initializeLists = () => {
    // initialize full list
    let data1: listValue[] = [];
    countries.forEach((item) => {
      // check if item is already selected
      const foundItem = clientCountries.find((obj) => {
        return obj.CountryID === item.CountryID;
      });
      if (!foundItem) {
        let n: listValue = {
          countryID: item.CountryID,
          clientCountryID: -1,
          countryName: item.CountryName,
          wasSelected: false,
        };
        data1.push(n);
      }
    });
    setAllCountries(data1);
    setFilteredCountries(data1);

    let data2 = clientCountries.map((item) => {
      let n: listValue = {
        countryID: item.CountryID,
        countryName: item.CountryName,
        clientCountryID: item.ClientCountryID,
        wasSelected: true,
      };
      return n;
    });
    setSelectedCountries(data2);
  };

  const handleSave = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
      },
    });
    // Delete items marked for removal
    // if item was selected and is now in all groups,
    // then item was REMOVED
    allCountries.forEach((item) => {
      if (item.wasSelected) {
        dispatch(delClientCountry(accessToken, item.clientCountryID));
      }
    });

    // insert new items
    selectedCountries.forEach((item) => {
      if (!item.wasSelected) {
        const newItem = {
          ClientID: clientId,
          CountryID: item.countryID,
        };
        dispatch(addClientCountry(accessToken, newItem));
      }
    });
    //dispatch(openSnackBar("Updates completed", "success"));
    //dispatch(push("/admin/clientCountry"));
  };

  const handleToggleRight = (value: listValue) => () => {
    // add item to full list, and sort it
    setAllCountries((oldArray) =>
      allCountries.concat(value).sort((a, b) => {
        let fa = a.countryName.toLowerCase(),
          fb = b.countryName.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      })
    );

    setFilteredCountries((oldArray) =>
      filteredCountries.concat(value).sort((a, b) => {
        let fa = a.countryName.toLowerCase(),
          fb = b.countryName.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      })
    );

    // remove item from selected list
    setSelectedCountries(
      selectedCountries.filter(function (a) {
        return a !== value;
      })
    );
  };

  const handleToggleLeft = (value: listValue) => () => {
    // add item to selected list, and sort it
    setSelectedCountries((oldArray) =>
      selectedCountries.concat(value).sort((a, b) => {
        let fa = a.countryName.toLowerCase(),
          fb = b.countryName.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      })
    );

    // remove item from full list list
    setAllCountries(
      allCountries.filter(function (a) {
        return a !== value;
      })
    );

    setFilteredCountries(
      filteredCountries.filter(function (a) {
        return a !== value;
      })
    );
  };

  const onFilterChange = (e: any) => {
    const val = e.target.value || "";
    setFilterValue(val);
    if (val === "") {
      setFilteredCountries(allCountries);
    } else {
      setFilteredCountries(
        allCountries.filter(function (a) {
          return a.countryName.toLowerCase().includes(val.toLowerCase());
        })
      );
    }
  };

  let fullList = (
    <Paper sx={{ height: 400, overflow: "auto" }}>
      <List dense component="div" role="list">
        {filteredCountries.map((item) => {
          return (
            <Tooltip title="Click to add item to selected groups">
              <ListItem
                button
                key={item.countryID}
                onClick={handleToggleLeft(item)}
                role="listitem"
              >
                <ListItemIcon>
                  <ArrowForward />
                </ListItemIcon>
                <ListItemText primary={item.countryName} />
              </ListItem>
            </Tooltip>
          );
        })}
      </List>
    </Paper>
  );

  let selectedList =
    clientCountryIsLoading || countryIsLoading ? (
      <IppSkeleton height={400} />
    ) : (
      <Paper sx={{ height: 400, overflow: "auto" }}>
        <List dense component="div" role="list">
          {selectedCountries.map((item) => {
            return (
              <Tooltip title="Click to remove item from selected list">
                <ListItem
                  button
                  role="listitem"
                  key={item.countryID}
                  onClick={handleToggleRight(item)}
                >
                  <ListItemIcon>
                    <ArrowBack />
                  </ListItemIcon>
                  <ListItemText primary={item.countryName} />
                </ListItem>
              </Tooltip>
            );
          })}
        </List>
      </Paper>
    );

  return (
    <Root>
      <Box display="flex" justifyContent="center">
        <Paper className={classes.boxSpace}>
          <Grid container className={classes.editForm} spacing={2}>
            <Grid item xs={9}>
              <Typography variant="h5" component="h1">
                Select Countries
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Grid container justifyContent="flex-end">
                <IppSaveButton handleSave={handleSave} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <IppFormDivider title="Guide" />
              <Typography display="inline">
                This option allows you to select the countries you wish to have
                appear in dropdown lists. Only the items you select will be
                available to users. Selecting a country from one list will move
                it into the other list. Once you have completed your changes,
                click
                <strong> Save Changes</strong> to finalize them.
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="h6">Available Countries</Typography>
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Search for..."
                value={filterValue}
                onChange={onFilterChange}
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">Selected Countries</Typography>
            </Grid>
            <Grid item xs={6}>
              {fullList}
            </Grid>
            <Grid item xs={6}>
              {selectedList}
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Root>
  );
};
