import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CommitmentSourceAmendment,
  CommitmentSourceAmendmentsResult,
  getCommitmentSourceAmendments,
  createCommitmentSourceAmendment,
  updateCommitmentSourceAmendment,
  deleteCommitmentSourceAmendment,
} from "api/commitments/commitmentSourceAmendmentAPI";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { BlockBlobClient } from "@azure/storage-blob";

interface CommitmentSourceAmendmentState {
  commitmentSourceAmendmentsById: Record<number, CommitmentSourceAmendment>;
  commitmentSourceAmendmentList: number[];
  isLoading: boolean;
  error: string | null;
}

const CommitmentSourceAmendmentInitialState: CommitmentSourceAmendmentState = {
  commitmentSourceAmendmentsById: {},
  commitmentSourceAmendmentList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: CommitmentSourceAmendmentState) {
  state.isLoading = true;
}

function loadingFailed(
  state: CommitmentSourceAmendmentState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const commitmentSourceAmendments = createSlice({
  name: "commitmentSourceAmendments",
  initialState: CommitmentSourceAmendmentInitialState,
  reducers: {
    getCommitmentSourceAmendmentsStart: startLoading,
    getCommitmentSourceAmendmentsSuccess(
      state,
      { payload }: PayloadAction<CommitmentSourceAmendmentsResult>
    ) {
      const { commitmentSourceAmendments } = payload;

      state.isLoading = false;
      state.error = null;

      commitmentSourceAmendments.forEach((commitmentSourceAmendments) => {
        state.commitmentSourceAmendmentsById[
          commitmentSourceAmendments.AmendmentID
        ] = commitmentSourceAmendments;
      });

      state.commitmentSourceAmendmentList = commitmentSourceAmendments.map(
        (commitmentSourceAmendments) => commitmentSourceAmendments.AmendmentID
      );
    },
    getCommitmentSourceAmendmentsFailure: loadingFailed,
    createCommitmentSourceAmendmentStart: startLoading,
    createCommitmentSourceAmendmentSuccess(
      state,
      { payload }: PayloadAction<CommitmentSourceAmendment>
    ) {
      const { AmendmentID } = payload;
      state.commitmentSourceAmendmentsById[AmendmentID] = payload;
      state.commitmentSourceAmendmentList.push(AmendmentID);

      state.isLoading = false;
      state.error = null;
    },

    deleteCommitmentSourceAmendmentSuccess(
      state,
      { payload }: PayloadAction<number>
    ) {
      const AmendmentID = payload;
      delete state.commitmentSourceAmendmentsById[AmendmentID];
      state.commitmentSourceAmendmentList =
        state.commitmentSourceAmendmentList.filter(
          (item) => item !== AmendmentID
        );

      state.isLoading = false;
      state.error = null;
    },
    createCommitmentSourceAmendmentFailure: loadingFailed,
  },
});

export const {
  getCommitmentSourceAmendmentsStart,
  getCommitmentSourceAmendmentsSuccess,
  getCommitmentSourceAmendmentsFailure,
  createCommitmentSourceAmendmentStart,
  createCommitmentSourceAmendmentSuccess,
  deleteCommitmentSourceAmendmentSuccess,
  createCommitmentSourceAmendmentFailure,
} = commitmentSourceAmendments.actions;

export default commitmentSourceAmendments.reducer;

export const fetchCommitmentSourceAmendments =
  (accessToken: String, commitmentSourceID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCommitmentSourceAmendmentsStart());
      const commitmentSourceAmendments = await getCommitmentSourceAmendments(
        accessToken,
        commitmentSourceID
      );
      dispatch(
        getCommitmentSourceAmendmentsSuccess(commitmentSourceAmendments)
      );
    } catch (err: any) {
      dispatch(getCommitmentSourceAmendmentsFailure(err.toString()));
    }
  };

export const addCommitmentSourceAmendment =
  (
    accessToken: string,
    commitmentSourceID: number,
    amendmentName: string,
    amendmentDate: Date,
    file: any
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCommitmentSourceAmendmentStart());

      const newCommitmentSourceAmendment = {
        AmendmentName: amendmentName,
        AmendmentDate: amendmentDate,
        FileName: file.name,
        CommitmentSourceID: commitmentSourceID,
      };

      //Create Amendment and Azure upload token
      const amendment = await createCommitmentSourceAmendment(
        accessToken,
        commitmentSourceID,
        newCommitmentSourceAmendment
      );

      const blockBlobClient = new BlockBlobClient(amendment.FilePath);
      const promise = blockBlobClient.uploadData(file.getRawFile());
      await promise;

      //Update DB isUploaded flag
      let completedAmendment = await updateCommitmentSourceAmendment(
        accessToken,
        amendment.AmendmentID,
        { IsUploaded: true }
      );

      completedAmendment.FilePath = amendment.FilePath;

      dispatch(createCommitmentSourceAmendmentSuccess(completedAmendment));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(
        fetchCommitmentSourceAmendments(accessToken, commitmentSourceID)
      );
    } catch (err: any) {
      dispatch(createCommitmentSourceAmendmentFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delCommitmentSourceAmendment =
  (accessToken: String, amendmentID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCommitmentSourceAmendmentStart());
      const result = await deleteCommitmentSourceAmendment(
        accessToken,
        amendmentID
      );
      dispatch(deleteCommitmentSourceAmendmentSuccess(amendmentID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createCommitmentSourceAmendmentFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };
