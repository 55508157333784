import axiosClient from "./axiosClient";

export interface CustomJobPosition {
  CustomJobPositionID: number;
  JobPositionName: string;
  ClientID: number;
}

export interface CustomJobPositionsResult {
  customJobPositions: CustomJobPosition[];
}

export async function getCustomJobPositionsByClient(
  accessToken: String
): Promise<CustomJobPositionsResult> {
  const url = `/customJobPosition/client`;

  try {
    const { data } = await axiosClient.get<CustomJobPositionsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
