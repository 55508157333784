import { Box, Grid, Paper, Tooltip, Typography } from "@mui/material";
import {
  GridColumn,
  GridNoRecords,
  Grid as KendoGrid,
} from "@progress/kendo-react-grid";
import { RootState } from "app/rootReducer";
import { IppAddButton } from "components/Buttons/IppAddButton";
import { IppButton } from "components/Buttons/IppButton";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useTypedTranslation } from "utils/customHooks";
import { EditComponentDialog } from "./EditComponentDialog";
import { DeleteComponentDialog } from "./DeleteComponentDialog";
import { Delete, Edit } from "@mui/icons-material";
import { Project } from "api/projectAPI";

interface ProjectComponentFormProps {
  projData: Project;
  isAdding: boolean;
  isEditing: boolean;
}

export const ProjectComponentForm = ({
  projData,
  isAdding,
  isEditing,
}: ProjectComponentFormProps) => {
  const t = useTypedTranslation(["objPlt", "strGen"]);

  const [cmp, setCmp] = useState({
    ProjectComponentName: "",
    ProjectID: 0,
    ComponentDescription: "",
    ComponentLocation: "",
  });
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [cmpID, setCmpID] = useState(0);
  const [isAddingComponent, setIsAddingComponent] = useState(false);

  const { projectComponentList, projectComponentsById } = useSelector(
    (state: RootState) => state.projectComponents
  );

  const components = projectComponentList.map(
    (ProjectID) => projectComponentsById[ProjectID]
  );

  // display edit cell on subpayment grid
  const editCell = (props: any) => {
    return (
      <td>
        <Tooltip title="Edit this Entry">
          <div
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() => {
              setCmp(props.dataItem);
              setCmpID(props.dataItem.ProjectComponentID);
              setIsAddingComponent(false);
              setEditOpen(true);
            }}
          >
            <IppButton
              variant="text"
              startIcon={<Edit />}
              color={"secondary"}
              disabled={isEditing}
            />
          </div>
        </Tooltip>
      </td>
    );
  };

  // display delete cell on subpayment grid
  const deleteCell = (props: any) => {
    return (
      <td>
        <Tooltip title="Delete this Entry">
          <div
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() => {
              setCmp(props.dataItem);
              setCmpID(props.dataItem.ProjectComponentID);
              setDeleteOpen(true);
            }}
          >
            <IppButton
              variant="text"
              startIcon={<Delete />}
              color={"error"}
              disabled={isEditing}
            />
          </div>
        </Tooltip>
      </td>
    );
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const addNewComponent = () => {
    let newComponent = {
      ProjectID: projData.ProjectID,
      ProjectComponentName: "",
      ComponentDescription: "",
      ComponentLocation: "",
    };
    setCmp(newComponent);
    setCmpID(0);
    setIsAddingComponent(true);
    setEditOpen(true);
  };

  return (
    <>
      <Grid item xs={12}>
        {!isAdding && (
          <Box display="flex" justifyContent="center">
            <Paper sx={{ padding: 1 }}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography variant="h5">
                    {t("objPlt:objects.projectcomponent.fullname")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Grid container justifyContent="flex-end">
                    <IppAddButton
                      buttonText={t("strGen:buttons.addobj", {
                        objectname: t("objPlt:objects.projectcomponent.name"),
                      })}
                      onClick={addNewComponent}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <KendoGrid data={components}>
                    <GridNoRecords>
                      ** No {t("objPlt:objects.projectcomponent.name")} added.
                      All projects should have at least one{" "}
                      {t("objPlt:objects.projectcomponent.name")}. **
                    </GridNoRecords>
                    <GridColumn
                      field="ProjectComponentName"
                      title={
                        t("objPlt:objects.projectcomponent.name") || "Component"
                      }
                    />
                    <GridColumn
                      field="ComponentLocation"
                      title={
                        t("objPlt:objects.projectcomponent.fields.location") ||
                        "Location"
                      }
                    />
                    <GridColumn
                      cell={editCell}
                      width="50px"
                      field="PaymentID"
                      title=" "
                    />
                    <GridColumn
                      cell={deleteCell}
                      width="50px"
                      field="PaymentID"
                      title=" "
                    />
                  </KendoGrid>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        )}
      </Grid>
      <EditComponentDialog
        isOpen={editOpen}
        handleClose={handleEditClose}
        cmp={cmp}
        cmpID={cmpID}
        isAdding={isAddingComponent}
      />
      <DeleteComponentDialog
        isOpen={deleteOpen}
        handleClose={handleDeleteClose}
        cmp={cmp}
        cmpID={cmpID}
      />
    </>
  );
};
