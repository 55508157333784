import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  InteractionIssue,
  InteractionIssuesResult,
  getInteractionIssuesByIssue,
  getInteractionIssuesByInteraction,
  deleteInteractionIssue,
} from "api/stakeholder/interactionIssueAPI";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { SnackBarConstants } from "utils/customHooks";

interface InteractionIssueState {
  interactionIssuesById: Record<number, InteractionIssue>;
  interactionIssueList: number[];
  isLoading: boolean;
  subInteractionIssueisLoading: boolean;
  error: string | null;
}

const InteractionIssueInitialState: InteractionIssueState = {
  interactionIssuesById: {},
  interactionIssueList: [],
  isLoading: false,
  subInteractionIssueisLoading: false,
  error: null,
};

function startLoading(state: InteractionIssueState) {
  state.isLoading = true;
}

function loadingFailed(
  state: InteractionIssueState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const interactionIssues = createSlice({
  name: "interactionIssues",
  initialState: InteractionIssueInitialState,
  reducers: {
    getInteractionIssuesStart: startLoading,
    getInteractionIssuesSuccess(
      state,
      { payload }: PayloadAction<InteractionIssuesResult>
    ) {
      const { interactionIssues } = payload;

      state.isLoading = false;
      state.error = null;

      interactionIssues.forEach((interactionIssue) => {
        state.interactionIssuesById[interactionIssue.InteractionIssueID] =
          interactionIssue;
      });

      state.interactionIssueList = interactionIssues.map(
        (interactionIssue) => interactionIssue.InteractionIssueID
      );
    },
    getInteractionIssuesFailure: loadingFailed,
    createInteractionIssueStart: startLoading,
    createInteractionIssueSuccess(
      state,
      { payload }: PayloadAction<InteractionIssue>
    ) {
      const { InteractionIssueID } = payload;
      state.interactionIssuesById[InteractionIssueID] = payload;
      state.interactionIssueList.push(InteractionIssueID);

      state.isLoading = false;
      state.error = null;
    },
    updateInteractionIssueSuccess(
      state,
      { payload }: PayloadAction<InteractionIssue>
    ) {
      const { InteractionIssueID } = payload;

      state.interactionIssuesById[InteractionIssueID] = payload;
      //state.interactionIssueList.push(InteractionIssueID);

      state.isLoading = false;
      state.error = null;
    },
    deleteInteractionIssueSuccess(state, { payload }: PayloadAction<number>) {
      const InteractionIssueID = payload;
      delete state.interactionIssuesById[InteractionIssueID];
      state.interactionIssueList = state.interactionIssueList.filter(
        (item) => item !== InteractionIssueID
      );

      state.isLoading = false;
      state.error = null;
    },
    createInteractionIssueFailure: loadingFailed,
  },
});

export const {
  getInteractionIssuesStart,
  getInteractionIssuesSuccess,
  getInteractionIssuesFailure,
  createInteractionIssueStart,
  createInteractionIssueSuccess,
  updateInteractionIssueSuccess,
  deleteInteractionIssueSuccess,
  createInteractionIssueFailure,
} = interactionIssues.actions;

export default interactionIssues.reducer;

export const fetchInteractionIssuesByIssue =
  (accessToken: String, issueID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getInteractionIssuesStart());
      const interactionIssues = await getInteractionIssuesByIssue(
        accessToken,
        issueID
      );
      dispatch(getInteractionIssuesSuccess(interactionIssues));
    } catch (err: any) {
      dispatch(getInteractionIssuesFailure(err.toString()));
    }
  };

export const fetchInteractionIssuesByInteraction =
  (accessToken: String, interactionID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getInteractionIssuesStart());
      const interactionIssues = await getInteractionIssuesByInteraction(
        accessToken,
        interactionID
      );
      dispatch(getInteractionIssuesSuccess(interactionIssues));
    } catch (err: any) {
      dispatch(getInteractionIssuesFailure(err.toString()));
    }
  };

//! COMMENTED OUT FOR FUTURE CLEAN UP
// export const addInteractionIssue =
//   (
//     accessToken: String,
//     newInteractionIssue: Partial<InteractionIssue>
//   ): AppThunk =>
//   async (dispatch) => {
//     try {
//       dispatch(createInteractionIssueStart());
//       const interactionIssue = await createInteractionIssue(
//         accessToken,
//         newInteractionIssue
//       );
//       dispatch(createInteractionIssueSuccess(interactionIssue));
//       dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
//     } catch (err: any) {
//       dispatch(createInteractionIssueFailure(err.toString()));
//       dispatch(openSnackBar(Constants.FAILED, "error"));
//     }
//   };

export const delInteractionIssue =
  (
    accessToken: String,
    interactionIssueID: number,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createInteractionIssueStart());
      const result = await deleteInteractionIssue(
        accessToken,
        interactionIssueID
      );
      dispatch(deleteInteractionIssueSuccess(interactionIssueID));
      dispatch(openSnackBar(snackbarConstants.DELETE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createInteractionIssueFailure(err.toString()));
      dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
    }
  };
