import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useMemo, useState } from "react";
import { Delete } from "@mui/icons-material";
import { Box, Grid, Typography, Tooltip } from "@mui/material";
import {
  Grid as KendoGrid,
  GridColumn,
  GridNoRecords,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import LoadingIndicator from "./LoadingIndicator";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import ConfirmDialog from "./ConfirmDialog";
import { ConvertDateOffset } from "utils/DateFunctions";
import { delCommitmentSourceAmendment } from "features/commitments/commitmentSourceAmendment/CommitmentSourceAmendmentSlice";
import { AmendmentUploadDialog } from "features/platform/amendments/AmendmentUploadDialog";
import { IppButton } from "./Buttons/IppButton";
import { IppAddButton } from "./Buttons/IppAddButton";
import { useTypedTranslation } from "utils/customHooks";
import {
  CommitmentsSourceOrAmendmentResource,
  CrudAction,
} from "utils/types/index.types";
import { usePermissions } from "utils/permissions/usePermissions";

const PREFIX = "IppAmendment";

const classes = {
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export type filterNames = "boolean" | "numeric" | "date" | "text";

interface myProps {
  title: string;
  itemID: number;
  commitmentSourceID: number;
  disabled?: boolean;
}

export const IppAmendment = (props: myProps) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { isActionAllowed } = usePermissions();
  const t = useTypedTranslation(["objPlt", "strGen"]);

  const { itemID, commitmentSourceID, disabled = false } = props;

  const [editOpen, setEditOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [deleteID, setDeleteID] = useState(0);

  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);

  const handleEditClose = () => {
    setEditOpen(false);
  };

  //------------------Define Permissions------------------
  const permissionsResourceBuilder = (
    actionType: CrudAction
  ): CommitmentsSourceOrAmendmentResource => {
    return {
      module: "Commitments",
      type: "Amendment",
      action: actionType,
    };
  };

  const createPermissionsResource = permissionsResourceBuilder("create");
  const canCreate = useMemo(
    () => isActionAllowed(createPermissionsResource),
    [createPermissionsResource]
  );

  const deletePermissionsResource = permissionsResourceBuilder("delete");
  const canDelete = useMemo(
    () => isActionAllowed(deletePermissionsResource),
    [deletePermissionsResource]
  );

  //usestate for Rootstate of attachment
  const {
    commitmentSourceAmendmentsById,
    commitmentSourceAmendmentList,
    isLoading,
  } = useSelector((state: RootState) => state.commitmentSourceAmendments);

  const commitmentSourceAmendments = commitmentSourceAmendmentList.map(
    (id) => commitmentSourceAmendmentsById[id]
  );

  const pageChange = (event: GridPageChangeEvent) => {
    setSkip(event.page.skip);
    setTake(event.page.take);
  };

  // This is to adjust the date
  const amendment = commitmentSourceAmendments.map((a) => {
    // Convert UploadDate
    let newCommitmentSourceAmendment = Object.assign({}, a);
    let dateOfUpload = new Date(
      (a.UploadDate as unknown as string).slice(0, 10)
    );
    newCommitmentSourceAmendment.UploadDate = ConvertDateOffset(dateOfUpload);

    // Convert AmendmentDate
    if (a.AmendmentDate) {
      let dateOfAmendment = new Date(
        (a.AmendmentDate as unknown as string).slice(0, 10)
      );
      newCommitmentSourceAmendment.AmendmentDate =
        ConvertDateOffset(dateOfAmendment);
    }

    // This is to adjust the uploaded by
    if (
      newCommitmentSourceAmendment.FirstName &&
      newCommitmentSourceAmendment.Surname
    ) {
      newCommitmentSourceAmendment.UploadedByName =
        newCommitmentSourceAmendment.FirstName +
        " " +
        newCommitmentSourceAmendment.Surname;
    } else
      newCommitmentSourceAmendment.UploadedByName =
        t("objPlt:objects.attachment.externalcontact") || undefined;

    return newCommitmentSourceAmendment;
  });

  const downloadAmendment = (props: any) => {
    const dataURL = props.dataItem.FilePath;
    dispatch(openSnackBar("Amendment Download Requested", "info"));
    window.open(dataURL, "_blank");
  };

  const delAmendment = () => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(delCommitmentSourceAmendment(accessToken, deleteID));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const firstCol = (props: any) => {
    return (
      <td
        onClick={(e) => {
          downloadAmendment(props);
        }}
      >
        <Tooltip title="Download Amendment">
          <div style={{ cursor: "pointer", color: "blue" }}>
            {props.dataItem[props.field]}
          </div>
        </Tooltip>
      </td>
    );
  };

  const deleteCell = (props: any) => {
    if (canDelete) {
      return (
        <td>
          {
            <Tooltip title="Delete Amendment">
              <div
                style={{ cursor: "pointer", color: "blue" }}
                onClick={(e) => {
                  setDeleteID(props.dataItem.AmendmentID);
                  setConfirmOpen(true);
                }}
              >
                <IppButton
                  variant="text"
                  startIcon={<Delete />}
                  disabled={disabled}
                  color={"error"}
                />
              </div>
            </Tooltip>
          }
        </td>
      );
    } else {
      return null;
    }
  };

  const addFile = () => {
    setEditOpen(true);
  };

  //is loading currently only based on client how to get other if it is dynamic
  let amendmentInvView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <Root>
      <Box display="flex" justifyContent="center">
        <>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant="h6">Amendments</Typography>
            </Grid>
            <Grid item xs={6}>
              <Grid container justifyContent="flex-end">
                {canCreate && (
                  <IppAddButton
                    buttonText={t("strGen:buttons.addobj", {
                      objectname: t(
                        "objCom:objects.commitmentsource.amendment.name"
                      ),
                    })}
                    onClick={addFile}
                    disabled={disabled}
                  />
                )}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <KendoGrid
                data={amendment.slice(skip, (take ? take : 10) + (skip || 0))}
                pageable={{
                  buttonCount: 5,
                  info: true,
                  pageSizes: [5, 10, 25, 50, 100],
                }}
                skip={skip}
                take={take}
                total={amendment.length}
                onPageChange={pageChange}
                onRowClick={downloadAmendment}
              >
                <GridNoRecords>** No Amendment added **</GridNoRecords>
                <GridColumn
                  cell={firstCol}
                  field="AmendmentName"
                  title="Amendment Name"
                />
                <GridColumn
                  field="AmendmentDate"
                  title="Amendment Date"
                  filter="date"
                  format="{0:yyyy-MM-dd}"
                />
                <GridColumn
                  field="UploadedByName"
                  title={t("objPlt:objects.attachment.fields.uploadedby") || ""}
                />
                <GridColumn
                  field="UploadDate"
                  title="Upload Date"
                  filter="date"
                  format="{0:yyyy-MM-dd}"
                />
                <GridColumn
                  cell={deleteCell}
                  width="100px"
                  field="AmendmentID"
                  title=" "
                />
              </KendoGrid>
            </Grid>
          </Grid>
        </>
      </Box>
      <AmendmentUploadDialog
        isOpen={editOpen}
        handleClose={handleEditClose}
        commitmentSourceID={commitmentSourceID}
      />
      <ConfirmDialog
        title="Delete Amendment?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={delAmendment}
      >
        {t("strGen:prompts.delete.deleteinquiry")}
      </ConfirmDialog>
    </Root>
  );

  return <div id="amendment-inventory-view">{amendmentInvView}</div>;
};
