import axiosClient from "./axiosClient";

export interface ActionReminder {
  ActionReminderID: number;
  ClientID: number;
  ActionSource: string;
  ActionOffset: number;
}

export interface ActionRemindersResult {
  actionReminders: ActionReminder[];
}

export async function getActionReminders(
  accessToken: String
): Promise<ActionRemindersResult> {
  const url = `/actionReminder`;

  try {
    const { data } = await axiosClient.get<ActionRemindersResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createActionReminder(
  accessToken: String,
  newActionReminder: Partial<ActionReminder>
): Promise<ActionReminder> {
  const url = `/actionReminder`;

  try {
    const { data } = await axiosClient.post<ActionReminder>(
      url,
      newActionReminder,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateActionReminder(
  accessToken: String,
  actionReminderID: number,
  newActionReminder: Partial<ActionReminder>
): Promise<ActionReminder> {
  const url = `/actionReminder/` + actionReminderID;

  try {
    const { data } = await axiosClient.post<ActionReminder>(
      url,
      newActionReminder,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteActionReminder(
  accessToken: String,
  actionReminderID: number
): Promise<string> {
  const url = `/actionReminder/` + actionReminderID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    console.log("DELDATA", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}
