import { useTypedTranslation } from "utils/customHooks";

const useActionSources = () => {
  const t = useTypedTranslation(["objCom", "objStk"]);

  return [
    t("objCom:objects.commitmentaction.commitmentaction", {
      actioncount: 3,
    }),
    t("objStk:objects.interactionaction.namefull_plural"),
    t("objStk:objects.interaction.name_other"),
    t("objCom:objects.commitment.name_other"),
  ];
};

export default useActionSources;

//export default ActionSources;
