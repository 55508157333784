import { EducationTrainingForm } from "./EducationTrainingForm";

export const EducationTrainingCreatePage = () => {
  let educationTrainingCreateView = (
    <div>
      <EducationTrainingForm />
    </div>
  );

  return (
    <div id="educationTraining-create-page">{educationTrainingCreateView}</div>
  );
};
