import { Box, Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

const PREFIX = "IppCheckbox";

const classes = {
  root: `${PREFIX}-root`,
  label: `${PREFIX}-label`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    color: theme.palette.primary.main,
  },
}));

interface IppCheckboxProps {
  id: string;
  value: boolean;
  onChangeFunction: any;
  label: string;
  isEditing: boolean;
  disabled?: boolean;
  tooltip?: string;
  error?: string; // To handle Formik error
  touched?: boolean; // To handle Formik touched
}

export const IppCheckbox = (props: IppCheckboxProps) => {
  const {
    id,
    value,
    onChangeFunction,
    label,
    disabled,
    tooltip,
    error,
    touched,
  } = props;

  const disabledStyle = {
    color: "#9e9e9e",
  };

  const errorStyle = {
    color: "#f44336",
    fontSize: "0.875rem",
  };

  return (
    <Root>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Tooltip title={tooltip}>
          <FormControlLabel
            control={
              <Checkbox
                id={id}
                name={id}
                value={value}
                checked={value}
                onChange={onChangeFunction}
                disabled={disabled}
              />
            }
            label={label}
            componentsProps={{
              typography: {
                sx: disabled ? disabledStyle : { color: "text.primary" },
              },
            }}
          />
        </Tooltip>
        {touched && error ? <div style={errorStyle}>{error}</div> : null}
      </Box>
    </Root>
  );
};

IppCheckbox.defaultProps = {
  disabled: false,
  tooltip: "",
};
