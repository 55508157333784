import axiosClient from "./axiosClient";

export interface Project {
  ProjectID: number;
  ProjectName: string;
  ProjectCode: string;
  ProjectDescription: string;
  ProvinceState: string;
  CountryName: string;
  Location: string;
  RegionID: number | null;
  RegionName: string | null;
  ClientID: number;
  ProjectTypeID: number;
  ExpenditureTarget: number;
  EmployeeTarget: number;
  HoursTarget: number;
  Tags: string;
  ProjectLeadID: number;
  ProjectLeadName: string;
}

export interface ProjectsResult {
  projects: Project[];
}

export async function getProjects(
  accessToken: String
): Promise<ProjectsResult> {
  const url = `/project`;

  try {
    const { data } = await axiosClient.get<ProjectsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createProject(
  accessToken: String,
  newProject: Partial<Project>
): Promise<Project> {
  const url = `/project`;

  try {
    const { data } = await axiosClient.post<Project>(url, newProject, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateProject(
  accessToken: String,
  projectID: number,
  newProject: Partial<Project>
): Promise<Project> {
  const url = `/project/` + projectID;

  try {
    const { data } = await axiosClient.post<Project>(url, newProject, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteProject(
  accessToken: String,
  projectID: number
): Promise<string> {
  const url = `/project/` + projectID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
