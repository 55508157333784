import { useTypedTranslation } from "utils/customHooks";

const useOverallResponsibilities = () => {
  const t = useTypedTranslation(["strGen"]);

  return [
    t("strGen:datalists.overallresponsibility.corporate"),
    t("strGen:datalists.overallresponsibility.externalgroup"),
    t("strGen:datalists.overallresponsibility.shared"),
  ];
};

export default useOverallResponsibilities;
