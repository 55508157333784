import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface FileUploadErrorMessageProps {
  message: string | React.ReactNode;
}

export const FileUploadErrorMessage = (props: FileUploadErrorMessageProps) => {
  const theme = useTheme();
  const { message } = props;

  return (
    <Typography
      variant="body1"
      sx={{
        paddingLeft: theme.spacing(2),
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(1),
      }}
    >
      {message}
    </Typography>
  );
};
