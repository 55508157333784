import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { addFileAttachment } from "../AttachmentSlice";
import { IppTextField } from "components/IppTextField";
import { Upload } from "@progress/kendo-react-upload";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { IppCancelButton } from "components/Buttons/IppCancelButton";
import { useSnackBarConstants, useTypedTranslation } from "utils/customHooks";
import { IppExpandableTypography } from "components/IppExpandableTypography";
import { allowedExtensions } from "utils/constants/generalConstants";

const PREFIX = "AttachmentUploadDialog";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1000,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

interface UploadAttachmentProps {
  isOpen: boolean;
  handleClose: any;
  recordType: string;
  recordID: number;
  companyID: number;
  projectID: number;
  moduleID: number;
  setIsUploading: (isUploading: boolean) => void;
  objectName?: string;
}

export const AttachmentUploadDialog = (props: UploadAttachmentProps) => {
  const {
    isOpen,
    handleClose,
    recordType,
    recordID,
    companyID,
    projectID,
    moduleID,
    setIsUploading,
    objectName,
  } = props;
  const dispatch = useDispatch();
  const t = useTypedTranslation(["strGen"]);
  const snackbarConstants = useSnackBarConstants();

  const { getAccessTokenSilently } = useAuth0();
  const [A0token, setA0token] = useState("");
  const [fileDescription, setFileDescription] = useState("");
  const [processing, setProcessing] = useState(false);
  const [files, setFiles] = useState<Partial<any>>({
    files: [] as any,
    events: [] as any,
    errors: [] as any,
  });

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        setA0token(accessToken);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  const fileStatuses = [
    "UploadFailed",
    "Initial",
    "Selected",
    "Uploading",
    "Uploaded",
    "RemoveFailed",
    "Removing",
  ];

  const onBeforeUpload = (event: any) => {
    event.headers.Authorization = `Bearer ${A0token}`;
    event.additionalData.recordType = recordType;
    event.additionalData.recordID = recordID;
  };

  const onAdd = (event: any) => {
    setProcessing(true);

    setFiles({
      files: event.newState,
      events: [
        ...files.events,
        t("strGen:pages.attachments.fileselected", {
          filename: event.affectedFiles[0].name,
        }),
      ],
      errors: [],
    });
    setProcessing(false);
  };

  const onRemove = (event: any) => {
    setFiles({
      files: event.newState,
      events: [
        ...files.events,
        t("strGen:pages.attachments.fileremoved", {
          filename: event.affectedFiles[0].name,
        }),
      ],
      errors: [],
    });
    setProcessing(false);
  };

  const onProgress = (event: any) => {
    setFiles({
      files: event.newState,
      events: [
        ...files.events,
        t("strGen:pages.attachments.onprogress", {
          progress: event.affectedFiles[0].progress,
        }),
      ],
      errors: [...files.errors],
    });
  };

  const onStatusChange = (event: any) => {
    const file = event.affectedFiles[0];

    setFiles({
      files: event.newState,
      events: [
        ...files.events,
        t("strGen:pages.attachments.statuschange", {
          filename: file.name,
          status: fileStatuses[file.status],
        }),
      ],
      errors: [...files.errors],
    });
  };

  const onSaveRequest = (files: any, options: any, onProgress: any): any => {
    const saveRequestPromise = new Promise((resolve, reject) => {
      //eventually this could be used to check that the upload is finished
      setIsUploading(true);

      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(
            addFileAttachment(
              accessToken,
              recordType,
              recordID,
              fileDescription,
              companyID,
              projectID,
              moduleID,
              files[0],
              setIsUploading,
              snackbarConstants
            )
          );
          cleanupState();
          handleClose();
        } catch (e) {
          console.error(e);
        }
      })();

      resolve({ uid: files[0].uid });
    });

    return saveRequestPromise;
  };

  let attDescription = {
    description: fileDescription,
  };

  const validationSchema = yup.object({
    description: yup.string(),
  });

  const cleanupState = () => {
    setFileDescription("");
    setFiles({
      files: [],
      events: [],
      errors: [],
    });
  };

  const closeAttachment = (
    _: React.MouseEvent<HTMLButtonElement>,
    reason?: string
  ) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      dispatch(
        openSnackBar(t("strGen:components.snackbar.uploadorcancel"), "info")
      );
    } else {
      cleanupState();
      handleClose();
    }
  };

  let onsub = () => {};

  const formik = useFormik({
    initialValues: attDescription,
    validationSchema: validationSchema,
    onSubmit: onsub,
  });

  return (
    <StyledDialog maxWidth="sm" open={isOpen} onClose={closeAttachment}>
      <DialogTitle>
        {t("strGen:pages.attachments.headertitle", {
          objectname: objectName || recordType,
        })}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              {t("strGen:pages.attachments.guide.genericmessage")}
            </Typography>
            <Typography variant="h5" paddingTop="16px">
              {t("strGen:pages.attachments.guide.header")}
            </Typography>
            <Typography>
              - {t("strGen:pages.attachments.guide.uploadingimage")}
            </Typography>
            - {t("strGen:pages.attachments.guide.acceptablefiletypes")}
            <IppExpandableTypography text={allowedExtensions.join(", ")} />
            <Grid item xs={12}>
              {" "}
            </Grid>
            <Grid item xs={12}>
              {t("strGen:pages.attachments.guide.selectwhenready")}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <IppTextField
              id="FileDescription"
              label={t("objPlt:objects.attachment.fields.filedescription")}
              value={fileDescription}
              onChangeFunction={(event: any) => {
                setFileDescription(event.target.value);
              }}
              onBlurFunction={(event: any) => {
                setFileDescription(event.target.value.trim());
              }}
              isEditing={true}
              setIsEditing={null}
              touchedExpression={formik.touched.description}
              errorsExpression={formik.errors.description}
            />
          </Grid>
          <Grid item xs={12}>
            <Upload
              disabled={false}
              showActionButtons={
                !processing && files.errors.length === 0 && A0token !== ""
              }
              autoUpload={false}
              multiple={false}
              files={files.files}
              restrictions={{
                allowedExtensions: allowedExtensions,
              }}
              withCredentials={true}
              onAdd={onAdd}
              onRemove={onRemove}
              onProgress={onProgress}
              onStatusChange={onStatusChange}
              onBeforeUpload={onBeforeUpload}
              saveUrl={onSaveRequest}
            />
          </Grid>

          <Grid item xs={12}>
            <IppCancelButton
              onClick={() => {
                cleanupState();
                handleClose();
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
};
