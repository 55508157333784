import axiosClient from "./axiosClient";

export interface ClientCompanyStructure {
  ClientCompanyStructureID: number;
  CompanyStructureName: string;
  CompanyStructureID: number;
  ClientID: number;
  IsRequired: boolean;
}

export interface ClientCompanyStructuresResult {
  clientCompanyStructures: ClientCompanyStructure[];
}

const url = `/clientCompanyStructure`;

export async function getClientCompanyStructures(
  accessToken: String
): Promise<ClientCompanyStructuresResult> {
  //const url = `/clientCompanyStructure`;

  try {
    const { data } = await axiosClient.get<ClientCompanyStructuresResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createClientCompanyStructure(
  accessToken: String,
  newClientCompanyStructure: Partial<ClientCompanyStructure>
): Promise<ClientCompanyStructure> {
  //const url = `/clientCompanyStructure`;

  try {
    const { data } = await axiosClient.post<ClientCompanyStructure>(
      url,
      newClientCompanyStructure,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateClientCompanyStructure(
  accessToken: String,
  clientCompanyStructureID: number,
  newClientCompanyStructure: Partial<ClientCompanyStructure>
): Promise<ClientCompanyStructure> {
  try {
    const { data } = await axiosClient.post<ClientCompanyStructure>(
      `${url}/${clientCompanyStructureID}`,
      newClientCompanyStructure,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteClientCompanyStructure(
  accessToken: String,
  clientCompanyStructureID: number
): Promise<string> {
  try {
    const { data } = await axiosClient.delete<string>(
      `${url}/${clientCompanyStructureID}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}
