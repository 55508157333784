import axiosClient from "../axiosClient";

export interface GrievanceStatus {
  GrievanceStatusID: number;
  GrievanceStatusText: string;
}

export interface GrievanceStatusResult {
  grievanceStatuses: GrievanceStatus[];
}

export async function getGrievanceStatus(
  accessToken: String
): Promise<GrievanceStatusResult> {
  const url = `/grievanceStatus`;

  try {
    const { data } = await axiosClient.get<GrievanceStatusResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
