import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchCommitmentActions } from "./CommitmentActionSlice";
import { IppInventory } from "components/Inventory/IppInventory";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";
import { ConvertDateOffset } from "utils/DateFunctions";
import { useTranslation } from "react-i18next";

export const CommitmentActionInventoryPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation(["objPlt", "strGen", "objCom"]);

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const {
    commitmentActionList,
    isLoading,
    error: commitmentActionsError,
    commitmentActionsById,
  } = useSelector((state: RootState) => state.commitmentActions);

  const commitmentActions = commitmentActionList.map(
    (id) => commitmentActionsById[id]
  );

  const data = commitmentActions.map((p) => {
    let newItem = Object.assign({}, p) as any;
    newItem.RequiredDate =
      p.RequiredDate === null ? null : ConvertDateOffset(p.RequiredDate);
    newItem.CompletionDate =
      p.CompletionDate === null ? null : ConvertDateOffset(p.CompletionDate);
    return newItem;
  });

  const dataColumns = [
    {
      field: "ActionDescription",
      title: t("objCom:objects.commitmentaction.name"),
    },
    {
      field: "ActionTypeName",
      title: t("objCom:objects.commitmentaction.fields.actiontype"),
      columnWidth: 150,
    },
    { field: "CommitmentTitle", title: t("objCom:objects.commitment.name") },
    {
      field: "ProjectName",
      title: t("objPlt:objects.project.name"),
    },
    {
      field: "PersonResponsibleName",
      title: t("objCom:objects.commitment.fields.personresponsible"),
    },
    {
      field: "RequiredDate",
      title: t("objPlt:platformwide.fields.daterequired"),
      format: "{0:d}",
      filter: "date",
      columnWidth: 120,
    },
    {
      field: "CompletionDate",
      title: t("objPlt:platformwide.fields.datecompleted"),
      format: "{0:d}",
      filter: "date",
      columnWidth: 120,
    },
    {
      field: "ActionStatusName",
      title: t("objCom:objects.commitmentaction.fields.status"),
      chips: true,
      columnWidth: 150,
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchCommitmentActions(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  if (commitmentActionsError) {
    return <ErrorPage errMsg={commitmentActionsError} />;
  }

  let CommitmentActionInvView =
    isLoading || clientIsLoading ? (
      <LoadingIndicator />
    ) : (
      <div>
        <IppInventory
          title={t("objCom:objects.commitmentaction.commitmentaction", {
            actioncount: 2,
          })}
          tableData={data}
          idField="CommitmentActionID"
          columns={dataColumns}
          isFiltered={true}
          showDetail={true}
          buttonProps={{
            showAdd: true,
          }}
          sortColumn={4}
          linkColumn={0}
          searchByColumn={"ActionDescription"}
          searchByPlaceholder={t(
            "objCom:objects.commitmentaction.searchactions",
            { count: 2 }
          )}
        />
      </div>
    );

  return (
    <div id="CommitmentAction-inventory-page">{CommitmentActionInvView}</div>
  );
};
