import axiosClient from "../axiosClient";

export interface Supplier {
  SupplierID: number;
  ProjectID: number;
  SupplierNotes: string;
  ClientID: number;
  BusinessName: string;
  Address: string;
  TownCity: string;
  ProvinceState: string;
  CountryName: string;
  PostalCode: string;
  Website: string;
  Notes: string;
  FirstName: string;
  LastName: string;
  Email: string;
  Phone: string;
  CorpNumber: number;
  RegisteredProvinces: [];
  Municipal: string;
  Insurance: boolean;
  CoreBusiness: [];
  IsoCertified: boolean;
  IsoNumber: string;
  CorCertified: boolean;
  CertDate: Date;
  IndigenousOwned: boolean;
  IndigenousCommunity: string;
  IndigenousCorpPercentage: number;
  InclusionPolicy: boolean;
  NumberEmployees: number;
  IndigenousEmployeePercentage: number;
  GwoTraining: boolean;
  EnergyProjects: string;
  ProjectReferences: string;
  IsApproved: boolean;
  HSECertified: boolean;
}

export interface SuppliersResult {
  suppliers: Supplier[];
}

export async function getSuppliers(
  accessToken: string
): Promise<SuppliersResult> {
  const url = `/supplier`;

  try {
    const { data } = await axiosClient.get<SuppliersResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getSuppliersForReview(
  accessToken: string
): Promise<SuppliersResult> {
  const url = `/supplier/review`;

  try {
    const { data } = await axiosClient.get<SuppliersResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createSupplier(
  accessToken: string,
  newItemData: Partial<Supplier>
): Promise<Supplier> {
  const url = `/supplier`;

  try {
    const { data } = await axiosClient.post<Supplier>(url, newItemData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateSupplier(
  accessToken: string,
  itemDataID: number,
  newItemData: Partial<Supplier>
): Promise<Supplier> {
  const url = `/supplier/` + itemDataID;

  try {
    const { data } = await axiosClient.post<Supplier>(url, newItemData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteSupplier(
  accessToken: string,
  itemDataID: number
): Promise<string> {
  const url = `/supplier/` + itemDataID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
