import axiosClient from "./axiosClient";

export interface DeleteCheck {
  ItemID: number;
  HasChildren: boolean;
  RecordType: string;
  ChildDetails: string;
}

export interface DeleteChecksResult {
  deleteChecks: DeleteCheck[];
}

export async function getDeleteChecksByCompany(
  accessToken: String,
  companyID: number
): Promise<DeleteChecksResult> {
  const url = `/deleteCheck/company/${companyID}`;

  try {
    const { data } = await axiosClient.get<DeleteChecksResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getDeleteChecksByProject(
  accessToken: String,
  projectID: number
): Promise<DeleteChecksResult> {
  const url = `/deleteCheck/project/${projectID}`;

  try {
    const { data } = await axiosClient.get<DeleteChecksResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getDeleteChecksByEmployee(
  accessToken: String,
  employeeID: number
): Promise<DeleteChecksResult> {
  const url = `/deleteCheck/employee/${employeeID}`;

  try {
    const { data } = await axiosClient.get<DeleteChecksResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getDeleteChecksByWorkHourPeriod(
  accessToken: String,
  whpID: number
): Promise<DeleteChecksResult> {
  const url = `/deleteCheck/workHourPeriod/${whpID}`;

  try {
    const { data } = await axiosClient.get<DeleteChecksResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getDeleteChecksByProjectComponent(
  accessToken: String,
  projCompID: number
): Promise<DeleteChecksResult> {
  const url = `/deleteCheck/projectComponent/${projCompID}`;

  try {
    const { data } = await axiosClient.get<DeleteChecksResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getDeleteChecksByPayment(
  accessToken: String,
  paymentID: number
): Promise<DeleteChecksResult> {
  const url = `/deleteCheck/payment/${paymentID}`;

  try {
    const { data } = await axiosClient.get<DeleteChecksResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getDeleteChecksByContact(
  accessToken: String,
  contactID: number
): Promise<DeleteChecksResult> {
  const url = `/deleteCheck/contact/${contactID}`;

  try {
    const { data } = await axiosClient.get<DeleteChecksResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getDeleteChecksByInteraction(
  accessToken: String,
  InteractionID: number
): Promise<DeleteChecksResult> {
  const url = `/deleteCheck/interaction/${InteractionID}`;

  try {
    const { data } = await axiosClient.get<DeleteChecksResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getDeleteChecksByGroup(
  accessToken: String,
  GroupID: number
): Promise<DeleteChecksResult> {
  const url = `/deleteCheck/group/${GroupID}`;

  try {
    const { data } = await axiosClient.get<DeleteChecksResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getDeleteChecksByIssue(
  accessToken: String,
  IssueID: number
): Promise<DeleteChecksResult> {
  const url = `/deleteCheck/issue/${IssueID}`;

  try {
    const { data } = await axiosClient.get<DeleteChecksResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getDeleteChecksByGrievance(
  accessToken: String,
  GrievanceID: number
): Promise<DeleteChecksResult> {
  const url = `/deleteCheck/grievance/${GrievanceID}`;

  try {
    const { data } = await axiosClient.get<DeleteChecksResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getDeleteChecksByCommitment(
  accessToken: String,
  CommitmentID: number
): Promise<DeleteChecksResult> {
  const url = `/deleteCheck/commitment/${CommitmentID}`;

  try {
    const { data } = await axiosClient.get<DeleteChecksResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getDeleteChecksByCommitmentSource(
  accessToken: String,
  CommitmentSourceID: number
): Promise<DeleteChecksResult> {
  const url = `/deleteCheck/commitmentSource/${CommitmentSourceID}`;

  try {
    const { data } = await axiosClient.get<DeleteChecksResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getDeleteChecksByTier(
  accessToken: String,
  TierID: number
): Promise<DeleteChecksResult> {
  const url = `/deleteCheck/tier/${TierID}`;

  try {
    const { data } = await axiosClient.get<DeleteChecksResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getDeleteChecksByProjectTypes(
  accessToken: String,
  ProjectTypesID: number
): Promise<DeleteChecksResult> {
  const url = `/deleteCheck/projectTypes/${ProjectTypesID}`;

  try {
    const { data } = await axiosClient.get<DeleteChecksResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getDeleteChecksByTag(
  accessToken: String,
  tagID: number
): Promise<DeleteChecksResult> {
  const url = `/deleteCheck/tag/${tagID}`;

  try {
    const { data } = await axiosClient.get<DeleteChecksResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getDeleteChecksByRegion(
  accessToken: String,
  RegionID: number
): Promise<DeleteChecksResult> {
  const url = `/deleteCheck/region/${RegionID}`;

  try {
    const { data } = await axiosClient.get<DeleteChecksResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getDeleteChecksByPhase(
  accessToken: String,
  PhaseID: number
): Promise<DeleteChecksResult> {
  const url = `/deleteCheck/phase/${PhaseID}`;

  try {
    const { data } = await axiosClient.get<DeleteChecksResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getDeleteChecksByEthnicity(
  accessToken: String,
  EthnicityID: number
): Promise<DeleteChecksResult> {
  const url = `/deleteCheck/ethnicity/${EthnicityID}`;

  try {
    const { data } = await axiosClient.get<DeleteChecksResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getDeleteChecksByThread(
  accessToken: String,
  ThreadID: number
): Promise<DeleteChecksResult> {
  const url = `/deleteCheck/thread/${ThreadID}`;

  try {
    const { data } = await axiosClient.get<DeleteChecksResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getDeleteChecksByCustomEmpCategory(
  accessToken: String,
  customEmpCategoryID: number
): Promise<DeleteChecksResult> {
  const url = `/deleteCheck/customEmpCategory/${customEmpCategoryID}`;

  try {
    const { data } = await axiosClient.get<DeleteChecksResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
