import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CompanyStructure,
  CompanyStructuresResult,
  getCompanyStructures,
} from "api/companyStructureAPI";
import { AppThunk } from "app/store";

interface CompanyStructureState {
  companyStructuresById: Record<number, CompanyStructure>;
  companyStructureList: number[];
  isLoading: boolean;
  error: string | null;
}

const CompanyStructureInitialState: CompanyStructureState = {
  companyStructuresById: {},
  companyStructureList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: CompanyStructureState) {
  state.isLoading = true;
}

function loadingFailed(
  state: CompanyStructureState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const companyStructures = createSlice({
  name: "spendtype",
  initialState: CompanyStructureInitialState,
  reducers: {
    getCompanyStructuresStart: startLoading,
    getCompanyStructuresSuccess(
      state,
      { payload }: PayloadAction<CompanyStructuresResult>
    ) {
      const { companyStructures } = payload;
      // console.log("PAYLOAD", companyStructures);
      state.isLoading = false;
      state.error = null;

      companyStructures.forEach((companyStructure) => {
        state.companyStructuresById[companyStructure.CompanyStructureID] =
          companyStructure;
      });

      state.companyStructureList = companyStructures.map(
        (companyStructure) => companyStructure.CompanyStructureID
      );
    },
    getCompanyStructuresFailure: loadingFailed,
  },
});

export const {
  getCompanyStructuresStart,
  getCompanyStructuresSuccess,
  getCompanyStructuresFailure,
} = companyStructures.actions;

export default companyStructures.reducer;

export const fetchCompanyStructures =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCompanyStructuresStart());
      const companyStructures = await getCompanyStructures(accessToken);
      //console.log("RETURN", companyStructures);
      dispatch(getCompanyStructuresSuccess(companyStructures));
    } catch (err: any) {
      dispatch(getCompanyStructuresFailure(err.toString()));
    }
  };
