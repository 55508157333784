import { Trans } from "react-i18next";
import {
  ConstructedErrors,
  GenericErrorTitlesObject,
} from "utils/types/index.types";
import {
  generateErrorTitle,
  getGenericConstructedErrors,
  getGenericConstructedWarnings,
} from "utils/uploadUtils";

type EmployeeErrorTitles = GenericErrorTitlesObject & {
  apprenticeTrades: (count: number) => JSX.Element | "";
  diversityDeclined: (count: number) => JSX.Element | "";
  jobHistoryStartDate: (count: number) => JSX.Element | "";
  employeeEthnicity: (count: number) => JSX.Element | "";
  customCategory: (count: number) => JSX.Element | "";
};

export const generateEmployeeErrorTitles: EmployeeErrorTitles = {
  ...generateErrorTitle,
  apprenticeTrades: (count: number) =>
    count > 0 ? (
      <Trans
        i18nKey="strGen:uploaderrors.errors.employee.apprenticetrades.title"
        count={count}
      />
    ) : (
      ""
    ),
  diversityDeclined: (count: number) =>
    count > 0 ? (
      <Trans
        i18nKey="strGen:uploaderrors.errors.employee.diversitydeclined.title"
        count={count}
      />
    ) : (
      ""
    ),
  jobHistoryStartDate: (count: number) =>
    count > 0 ? (
      <Trans
        i18nKey="strGen:uploaderrors.errors.employee.jobhistorystartdate.title"
        count={count}
      />
    ) : (
      ""
    ),
  employeeEthnicity: (count: number) =>
    count > 0 ? (
      <Trans
        i18nKey="strGen:uploaderrors.errors.employee.invalidethnicity.title"
        count={count}
      />
    ) : (
      ""
    ),
  customCategory: (count: number) =>
    count > 0 ? (
      <Trans
        i18nKey="strGen:uploaderrors.errors.employee.invalidcustomCategory.title"
        count={count}
      />
    ) : (
      ""
    ),
};

export const getNewEmployeeConstructedErrors: () => ConstructedErrors = () => ({
  ...getGenericConstructedErrors(),
});

export const getNewEmployeeConstructedWarnings: () => ConstructedErrors =
  () => ({
    ...getGenericConstructedWarnings(),
    apprenticeTrades: {
      count: 0,
      get message() {
        return this.count > 0 ? (
          <>
            If an employee's job position is an apprenticeable trade, they
            should have an apprentice status of either 'Apprentice' or
            'Journeyperson'. An 'Apprentice' should have an apprentice year
            greater than 0 and a 'Journeyperson' should have no apprentice year.
            The following {this.count === 1 ? "row contains" : "rows contain"}{" "}
            data inconsistent with these requirements:
          </>
        ) : (
          ""
        );
      },
      list: [],
      showSingleItem: true,
    },
    diversityDeclined: {
      count: 0,
      get message() {
        return this.count > 0 ? (
          <>
            When Diversity is Declined, all Diversity fields are considered
            Declined and should be left blank. The following{" "}
            {this.count === 1 ? "row contains" : "rows contain"} data
            inconsistent with these requirements:
          </>
        ) : (
          ""
        );
      },
      list: [],
      renderAsList: true,
      showSingleItem: true,
    },
    jobHistoryStartDate: {
      count: 0,
      get message() {
        return this.count > 0 ? (
          <>
            When updating Job Position information for an existing Employee,
            Start Date is required to create a Job History record. The following
            {this.count === 1 ? "row" : "rows"} in your upload are missing a
            Start Date. Please review to ensure that any updates that are
            intended to create a Job History record, include a valid Start Date
            (YYYY-MM-DD):
          </>
        ) : (
          ""
        );
      },
      list: [],
      showSingleItem: true,
    },
    employeeEthnicity: {
      count: 0,
      get message() {
        return this.count > 0 ? (
          <>
            The following{" "}
            {this.count === 1 ? "Ethnicity does" : "Ethnicities do"} not exist
            on the platform. If you continue with this upload, the{" "}
            {this.count === 1 ? "value" : "values"} will be discarded. If the
            spelling is correct and the {this.count === 1 ? "value" : "values"}{" "}
            should exist, please contact your Administrator.
          </>
        ) : (
          ""
        );
      },
      list: [],
      showSingleItem: true,
    },
    customCategory: {
      count: 0,
      get message() {
        return this.count > 0 ? (
          <>
            The following{" "}
            {this.count === 1 ? (
              <>
                <Trans
                  i18nKey="strGen:uploaderrors.errors.employee.invalidcustomCategory.title"
                  count={this.count}
                />{" "}
                does
              </>
            ) : (
              <>
                <Trans
                  i18nKey="strGen:uploaderrors.errors.employee.invalidcustomCategory.title_other"
                  count={this.count}
                />{" "}
                do
              </>
            )}{" "}
            not exist on the platform. If you continue with this upload, the{" "}
            {this.count === 1 ? "value" : "values"} will be discarded. If the
            spelling is correct and the {this.count === 1 ? "value" : "values"}{" "}
            should exist, please contact your Administrator.
          </>
        ) : (
          ""
        );
      },
      list: [],
      showSingleItem: true,
    },
  });
