import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  Contact,
  ContactsResult,
  getContacts,
  // getContactsByGroup,
  // getContactsByInteraction,
  createContact,
  updateContact,
  deleteContact,
  mergeContact,
} from "api/stakeholder/contactAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { fetchContactGroups } from "../../stakeholder/contactGroup/ContactGroupSlice";
import { fetchContactInteractions } from "../../stakeholder/contactInteraction/ContactInteractionSlice";
import { fetchGrievanceContacts } from "../../stakeholder/grievanceContact/GrievanceContactSlice";
import { getCurrentModule } from "../../../utils/urlUtils";
import { SnackBarConstants } from "utils/customHooks";

interface ContactState {
  contactsById: Record<number, Contact>;
  contactList: number[];
  isLoading: boolean;
  isLoadingAdd: boolean;
  subContactisLoading: boolean;
  error: string | null;
}

const ContactInitialState: ContactState = {
  contactsById: {},
  contactList: [],
  isLoading: false,
  isLoadingAdd: false,
  subContactisLoading: false,
  error: null,
};

function startLoading(state: ContactState) {
  state.isLoading = true;
}

function startLoadingAdd(state: ContactState) {
  state.isLoadingAdd = true;
}

function loadingFailed(state: ContactState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const contacts = createSlice({
  name: "contacts",
  initialState: ContactInitialState,
  reducers: {
    getContactsStart: startLoading,
    getContactsSuccess(state, { payload }: PayloadAction<ContactsResult>) {
      const { contacts } = payload;

      state.isLoading = false;
      state.error = null;

      contacts.forEach((contact) => {
        state.contactsById[contact.ContactID] = contact;
      });

      state.contactList = contacts.map((contact) => contact.ContactID);
    },
    getContactsFailure: loadingFailed,
    createContactStart: startLoadingAdd,
    createContactSuccess(state, { payload }: PayloadAction<Contact>) {
      const { ContactID } = payload;
      state.contactsById[ContactID] = payload;
      state.contactList.push(ContactID);

      state.isLoading = false;
      state.isLoadingAdd = false;
      state.error = null;
    },
    updateContactSuccess(state, { payload }: PayloadAction<Contact>) {
      const { ContactID } = payload;

      state.contactsById[ContactID] = payload;

      state.isLoading = false;
      state.error = null;
    },
    deleteContactSuccess(state, { payload }: PayloadAction<number>) {
      const ContactID = payload;
      delete state.contactsById[ContactID];
      state.contactList = state.contactList.filter(
        (item) => item !== ContactID
      );

      state.isLoading = false;
      state.error = null;
    },
    createContactFailure: loadingFailed,
  },
});

export const {
  getContactsStart,
  getContactsSuccess,
  getContactsFailure,
  createContactStart,
  createContactSuccess,
  updateContactSuccess,
  deleteContactSuccess,
  createContactFailure,
} = contacts.actions;

export default contacts.reducer;

export const fetchContacts =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getContactsStart());
      const contacts = await getContacts(accessToken);
      dispatch(getContactsSuccess(contacts));
    } catch (err: any) {
      dispatch(getContactsFailure(err.toString()));
    }
  };

export const addContact =
  (
    accessToken: String,
    newContact: Partial<Contact>,
    groupList: string[],
    issueList: string[],
    setReturnRoute: boolean,
    snackbarConstants: SnackBarConstants,
    isTokenCreate?: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createContactStart());
      const contact = await createContact(
        accessToken,
        newContact,
        groupList,
        issueList
      );
      dispatch(createContactSuccess(contact));
      dispatch(openSnackBar(snackbarConstants.ADD_SUCCESS, "success"));
      if (!isTokenCreate) {
        dispatch(fetchContacts(accessToken));
      }
      if (setReturnRoute) {
        dispatch(push(`/${getCurrentModule()}/contacts/${contact.ContactID}`));
      }
    } catch (err: any) {
      dispatch(createContactFailure(err.toString()));
      dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
    }
  };

export const updContact =
  (
    accessToken: String,
    contactID: number,
    groupList: string[] | null,
    issueList: string[] | null,
    newContact: Partial<Contact>,
    setReturnRoute: boolean,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createContactStart());
      const contact = await updateContact(
        accessToken,
        contactID,
        newContact,
        groupList,
        issueList
      );

      dispatch(updateContactSuccess(contact));
      dispatch(fetchContacts(accessToken));
      dispatch(fetchContactGroups(accessToken));
      dispatch(fetchContactInteractions(accessToken));
      dispatch(fetchGrievanceContacts(accessToken));
      dispatch(openSnackBar(snackbarConstants.UPDATE_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(push(`/${getCurrentModule()}/contacts/${contact.ContactID}`));
      }
    } catch (err: any) {
      dispatch(createContactFailure(err.toString()));
      dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
    }
  };

export const mrgContact =
  (
    accessToken: String,
    contactID: number,
    newContact: Partial<Contact>,
    duplicateID: number,
    setReturnRoute: boolean,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createContactStart());
      const contact = await mergeContact(
        accessToken,
        contactID,
        newContact,
        duplicateID
      );

      dispatch(updateContactSuccess(contact));
      dispatch(fetchContactGroups(accessToken));
      dispatch(fetchContactInteractions(accessToken));
      dispatch(fetchGrievanceContacts(accessToken));
      dispatch(openSnackBar(snackbarConstants.UPDATE_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(push(`/${getCurrentModule()}/contacts/${contact.ContactID}`));
      }
    } catch (err: any) {
      dispatch(createContactFailure(err.toString()));
      if (contactID === duplicateID) {
        dispatch(openSnackBar(snackbarConstants.FAILED_MERGE_CONTACT, "error"));
      } else {
        dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
      }
    }
  };

export const delContact =
  (
    accessToken: String,
    contactID: number,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createContactStart());
      const result = await deleteContact(accessToken, contactID);
      dispatch(deleteContactSuccess(contactID));
      dispatch(openSnackBar(snackbarConstants.DELETE_SUCCESS, "success"));
      dispatch(push(`/${getCurrentModule()}/contacts`));
    } catch (err: any) {
      dispatch(createContactFailure(err.toString()));
      dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
    }
  };
