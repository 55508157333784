import axiosClient from "../axiosClient";

export interface GroupInteraction {
  GroupInteractionID: number;
  GroupID: number;
  InteractionID: number;
  GroupName: string;
  InteractionTitle: string;
  InteractionDate: Date;
  ClientID: number;
  InteractionProjectID: number;
  ProjectName: string;
  OrganizationTypeName: string;
  TownCity: string;
  ProvinceState: string;
  SentimentLevel: number;
  CommunicationID: number;
}

export interface GroupInteractionsResult {
  groupInteractions: GroupInteraction[];
}

export interface SuggestedGroup {
  GroupID: number;
  GroupName: string;
  ContactCount: number;
}
export interface SuggestedGroupList {
  suggestedGroups: SuggestedGroup[];
}

export async function getGroupInteractions(
  accessToken: String
): Promise<GroupInteractionsResult> {
  const url = `/groupInteraction`;

  try {
    const { data } = await axiosClient.get<GroupInteractionsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getGroupInteractionsByInteraction(
  accessToken: String,
  interactionID: number
): Promise<GroupInteractionsResult> {
  const url = `/groupInteraction/interaction/${interactionID}`;

  try {
    const { data } = await axiosClient.get<GroupInteractionsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getGroupInteractionsByGroup(
  accessToken: String,
  groupID: number
): Promise<GroupInteractionsResult> {
  const url = `/groupInteraction/group/${groupID}`;

  try {
    const { data } = await axiosClient.get<GroupInteractionsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getSuggestedGroupsByInteractionContacts(
  accessToken: String,
  interactionID: number
): Promise<SuggestedGroupList> {
  const url = `/groupInteraction/suggestedbyinteractioncontacts/${interactionID}`;

  try {
    const { data } = await axiosClient.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createGroupInteraction(
  accessToken: String,
  newCommInv: Partial<GroupInteraction>
): Promise<GroupInteraction> {
  const url = `/groupInteraction`;

  try {
    const { data } = await axiosClient.post<GroupInteraction>(url, newCommInv, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateGroupInteraction(
  accessToken: String,
  commInvID: number,
  newCommInv: Partial<GroupInteraction>
): Promise<GroupInteraction> {
  const url = `/groupInteraction/` + commInvID;

  try {
    const { data } = await axiosClient.post<GroupInteraction>(url, newCommInv, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteGroupInteraction(
  accessToken: String,
  commInvID: number
): Promise<string> {
  const url = `/groupInteraction/` + commInvID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
