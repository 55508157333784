import { styled } from "@mui/material/styles";

import Paper from "@mui/material/Paper";
import { Box, Divider, Typography, Grid } from "@mui/material";
import { useTypedTranslation } from "utils/customHooks";

const PREFIX = "UnauthorizedUser";

const classes = {
  pageContainer: `${PREFIX}-pageContainer`,
  page: `${PREFIX}-page`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.pageContainer}`]: {
    width: 600,
  },

  [`& .${classes.page}`]: {
    marginLeft: "auto",
    marginRight: "auto",
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const UnauthorizedUser = () => {
  const t = useTypedTranslation(["strGen"]);

  let unauthPage = (
    <Root>
      <Box display="flex" justifyContent="center">
        <Paper className={classes.boxSpace}>
          <div className={classes.pageContainer}>
            <Grid container spacing={3} p={3}>
              <Grid item xs={12}>
                <Typography component="h1" variant="h5">
                  <img
                    src={process.env.PUBLIC_URL + "/iconSmall.png"}
                    alt="NetBenefits"
                  />
                  {t("strGen:pages.unauthorizeduser.title")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {t("strGen:pages.unauthorizeduser.attemptedaccess")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {t("strGen:pages.unauthorizeduser.iferrormessage")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
                <Typography variant="caption">
                  {t("strGen:pages.unauthorizeduser.allattemptslogged")}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Box>
    </Root>
  );

  return <div id="unauthorized-user-page">{unauthPage}</div>;
};
