import axiosClient from "../axiosClient";

export interface OrganizationType {
  OrganizationTypeID: number;
  OrganizationTypeName: string;
}

export interface OrganizationTypesResult {
  organizationTypes: OrganizationType[];
}

export async function getOrganizationTypes(
  accessToken: String
): Promise<OrganizationTypesResult> {
  const url = `/organizationtype`;

  try {
    const { data } = await axiosClient.get<OrganizationTypesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
