import { getCurrentModule } from "./urlUtils";

// Enum to define the modules that have training hubs
export enum ModuleShortName {
  ENGAGEMENT = "engagement",
  COMMITMENTS = "commitments",
  BENEFITS = "benefits",
}

const contractorUrls: Record<ModuleShortName, string> = {
  [ModuleShortName.ENGAGEMENT]:
    "https://www.netbenefitsoftware.com/training-material/engagement-training-hub",
  [ModuleShortName.COMMITMENTS]:
    "https://www.netbenefitsoftware.com/training-material/commitment-training-hub",
  [ModuleShortName.BENEFITS]:
    "https://www.netbenefitsoftware.com/training-material/contractor-training-hub",
};

const adminUrls: Record<ModuleShortName, string> = {
  [ModuleShortName.ENGAGEMENT]:
    "https://www.netbenefitsoftware.com/training-material/engagement-training-hub",
  [ModuleShortName.COMMITMENTS]:
    "https://www.netbenefitsoftware.com/training-material/commitment-training-hub",
  [ModuleShortName.BENEFITS]:
    "https://www.netbenefitsoftware.com/training-material/contractor-training-hub",
};

export const adminTrainingHubUrl =
  "https://www.netbenefitsoftware.com/admin-training-hub";

export const getContractorUrl = (
  customTrainingUrl: string | null,
  module?: string
): string => {
  const currentModule = module || getCurrentModule();

  if (!currentModule) return "";

  // Check benefits module and provide custom url
  if (currentModule === ModuleShortName.BENEFITS && customTrainingUrl) {
    return customTrainingUrl;
  }

  // Return the static contractor URL for the module
  return contractorUrls[currentModule as ModuleShortName] || "";
};

// get the admin URL
export const getAdminUrl = (module: string): string => {
  return adminUrls[module as ModuleShortName] || "";
};

// determine if the module has a training hub
export const hasTrainingHub = (module: string): boolean => {
  return module in contractorUrls;
};

// if no valid training hub exists, return contractor URL for benefits
export const getFallbackUrl = (customTrainingUrl: string | null): string => {
  return customTrainingUrl || contractorUrls[ModuleShortName.BENEFITS];
};
