import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CommitmentSourceType,
  CommitmentSourceTypesResult,
  getCommitmentSourceTypes,
} from "api/commitments/commitmentSourceTypeAPI";
import { AppThunk } from "app/store";

interface CommitmentSourceTypeState {
  commitmentSourceTypesById: Record<number, CommitmentSourceType>;
  commitmentSourceTypeList: number[];
  isLoading: boolean;
  error: string | null;
}

const CommitmentSourceTypeInitialState: CommitmentSourceTypeState = {
  commitmentSourceTypesById: {},
  commitmentSourceTypeList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: CommitmentSourceTypeState) {
  state.isLoading = true;
}

function loadingFailed(
  state: CommitmentSourceTypeState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const commitmentSourceTypes = createSlice({
  name: "commitmentSourceType",
  initialState: CommitmentSourceTypeInitialState,
  reducers: {
    getCommitmentSourceTypesStart: startLoading,
    getCommitmentSourceTypesSuccess(
      state,
      { payload }: PayloadAction<CommitmentSourceTypesResult>
    ) {
      const { commitmentSourceTypes } = payload;
      state.isLoading = false;
      state.error = null;

      commitmentSourceTypes.forEach((commitmentSourceType) => {
        state.commitmentSourceTypesById[commitmentSourceType.CommitmentSourceTypeID] =
          commitmentSourceType;
      });

      state.commitmentSourceTypeList = commitmentSourceTypes.map(
        (commitmentSourceType) => commitmentSourceType.CommitmentSourceTypeID
      );
    },
    getCommitmentSourceTypesFailure: loadingFailed,
  },
});

export const {
  getCommitmentSourceTypesStart,
  getCommitmentSourceTypesSuccess,
  getCommitmentSourceTypesFailure,
} = commitmentSourceTypes.actions;

export default commitmentSourceTypes.reducer;

export const fetchCommitmentSourceTypes =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCommitmentSourceTypesStart());
      const commitmentSourceTypes = await getCommitmentSourceTypes(accessToken);
      dispatch(getCommitmentSourceTypesSuccess(commitmentSourceTypes));
    } catch (err: any) {
      dispatch(getCommitmentSourceTypesFailure(err.toString()));
    }
  };