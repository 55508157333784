import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ClientCurrency,
  ClientCurrenciesResult,
  getClientCurrencies,
  createClientCurrency,
  updateClientCurrency,
  deleteClientCurrency,
} from "api/clientCurrencyAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface ClientCurrencyState {
  clientCurrenciesById: Record<number, ClientCurrency>;
  clientCurrencyList: number[];
  isLoading: boolean;
  error: string | null;
}

const ClientCurrencyInitialState: ClientCurrencyState = {
  clientCurrenciesById: {},
  clientCurrencyList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: ClientCurrencyState) {
  state.isLoading = true;
}

function loadingFailed(
  state: ClientCurrencyState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const clientCurrencies = createSlice({
  name: "clientCurrencies",
  initialState: ClientCurrencyInitialState,
  reducers: {
    getClientCurrenciesStart: startLoading,
    getClientCurrenciesSuccess(
      state,
      { payload }: PayloadAction<ClientCurrenciesResult>
    ) {
      const { clientCurrencies } = payload;
      // console.log("PAYLOAD", clientCurrencies);
      state.isLoading = false;
      state.error = null;

      clientCurrencies.forEach((clientCurrency) => {
        state.clientCurrenciesById[clientCurrency.ClientCurrencyID] =
          clientCurrency;
      });

      state.clientCurrencyList = clientCurrencies.map(
        (clientCurrency) => clientCurrency.ClientCurrencyID
      );
    },
    getClientCurrenciesFailure: loadingFailed,
    createClientCurrencyStart: startLoading,
    createClientCurrencySuccess(
      state,
      { payload }: PayloadAction<ClientCurrency>
    ) {
      const { ClientCurrencyID } = payload;
      state.clientCurrenciesById[ClientCurrencyID] = payload;
      state.clientCurrencyList.push(ClientCurrencyID);

      state.isLoading = false;
      state.error = null;
    },
    updateClientCurrencySuccess(
      state,
      { payload }: PayloadAction<ClientCurrency>
    ) {
      const { ClientCurrencyID } = payload;
      state.clientCurrenciesById[ClientCurrencyID] = payload;

      state.isLoading = false;
      state.error = null;
    },
    deleteClientCurrencySuccess(state, { payload }: PayloadAction<number>) {
      const ClientCurrencyID = payload;
      delete state.clientCurrenciesById[ClientCurrencyID];
      state.clientCurrencyList = state.clientCurrencyList.filter(
        (item) => item !== ClientCurrencyID
      );

      state.isLoading = false;
      state.error = null;
    },
    createClientCurrencyFailure: loadingFailed,
  },
});

export const {
  getClientCurrenciesStart,
  getClientCurrenciesSuccess,
  getClientCurrenciesFailure,
  createClientCurrencyStart,
  createClientCurrencySuccess,
  updateClientCurrencySuccess,
  deleteClientCurrencySuccess,
  createClientCurrencyFailure,
} = clientCurrencies.actions;

export default clientCurrencies.reducer;

export const fetchClientCurrencies =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getClientCurrenciesStart());
      const clientCurrencies = await getClientCurrencies(accessToken);
      //console.log("RETURN", clientCurrencies);
      dispatch(getClientCurrenciesSuccess(clientCurrencies));
    } catch (err: any) {
      dispatch(getClientCurrenciesFailure(err.toString()));
    }
  };

export const addClientCurrency =
  (accessToken: String, newClientCurrency: Partial<ClientCurrency>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientCurrencyStart());
      const clientCurrency = await createClientCurrency(
        accessToken,
        newClientCurrency
      );
      dispatch(createClientCurrencySuccess(clientCurrency));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      //dispatch(fetchClientCurrencies(accessToken));
      dispatch(push("/admin/clientCurrency"));
    } catch (err: any) {
      dispatch(createClientCurrencyFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updClientCurrency =
  (
    accessToken: String,
    clientCurrencyID: number,
    newClientCurrency: Partial<ClientCurrency>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientCurrencyStart());
      const clientCurrency = await updateClientCurrency(
        accessToken,
        clientCurrencyID,
        newClientCurrency
      );
      dispatch(updateClientCurrencySuccess(clientCurrency));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push("/admin/clientCurrency"));
    } catch (err: any) {
      dispatch(createClientCurrencyFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delClientCurrency =
  (accessToken: String, clientCurrencyID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientCurrencyStart());
      const result = await deleteClientCurrency(accessToken, clientCurrencyID);
      dispatch(deleteClientCurrencySuccess(clientCurrencyID));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push("/admin/clientCurrency"));
    } catch (err: any) {
      dispatch(createClientCurrencyFailure(err.toString()));
    }
  };
