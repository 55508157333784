import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  Commitment,
  CommitmentsResult,
  getCommitments,
  createCommitment,
  updateCommitment,
  deleteCommitment,
  getCommitmentsBySource,
  getCommitmentsByGroup,
  getCommitmentsByAssociatedContact,
} from "api/commitments/commitmentAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { fetchCommitmentInteractionsByInteraction } from "features/crossModule/stakeholderCommitment/commitmentInteractionSlice";

interface CommitmentState {
  commitmentsById: Record<number, Commitment>;
  commitmentList: number[];
  isLoading: boolean;
  isLoadingAdd: boolean;
  subCommitmentisLoading: boolean;
  isLoaded: boolean;
  error: string | null;
  errorList: any[] | null;
}

const CommitmentInitialState: CommitmentState = {
  commitmentsById: {},
  commitmentList: [],
  isLoading: false,
  isLoadingAdd: false,
  subCommitmentisLoading: false,
  isLoaded: false,
  error: null,
  errorList: null,
};

function startLoading(state: CommitmentState) {
  state.isLoading = true;
}

function startLoadingAdd(state: CommitmentState) {
  state.isLoadingAdd = true;
}

function loadingFailed(
  state: CommitmentState,
  action: PayloadAction<{ status: string; message: string; errors: [] }>
) {
  state.isLoading = false;
  state.error = action.payload.message;
  if (action.payload.errors) {
    state.errorList = action.payload.errors;
  }
}

const commitments = createSlice({
  name: "commitments",
  initialState: CommitmentInitialState,
  reducers: {
    getCommitmentsStart: startLoading,
    getCommitmentsSuccess(
      state,
      { payload }: PayloadAction<CommitmentsResult>
    ) {
      const { commitments } = payload;

      state.isLoading = false;
      state.error = null;
      state.errorList = null;
      state.isLoaded = true;

      commitments.forEach((commitment) => {
        state.commitmentsById[commitment.CommitmentID] = commitment;
      });

      state.commitmentList = commitments.map(
        (commitment) => commitment.CommitmentID
      );
    },
    getCommitmentsFailure: loadingFailed,
    createCommitmentStart: startLoadingAdd,
    createCommitmentSuccess(state, { payload }: PayloadAction<Commitment>) {
      const { CommitmentID } = payload;
      state.commitmentsById[CommitmentID] = payload;
      state.commitmentList.push(CommitmentID);

      state.isLoading = false;
      state.isLoadingAdd = false;
      state.error = null;
      state.errorList = null;
    },
    updateCommitmentSuccess(state, { payload }: PayloadAction<Commitment>) {
      const { CommitmentID } = payload;

      state.commitmentsById[CommitmentID] = payload;
      //state.commitmentList.push(CommitmentID);

      state.isLoading = false;
      state.error = null;
      state.errorList = null;
    },
    deleteCommitmentSuccess(state, { payload }: PayloadAction<number>) {
      const CommitmentID = payload;
      delete state.commitmentsById[CommitmentID];
      state.commitmentList = state.commitmentList.filter(
        (item) => item !== CommitmentID
      );

      state.isLoading = false;
      state.error = null;
      state.errorList = null;
    },
    createCommitmentFailure: loadingFailed,
  },
});

export const {
  getCommitmentsStart,
  getCommitmentsSuccess,
  getCommitmentsFailure,
  createCommitmentStart,
  createCommitmentSuccess,
  updateCommitmentSuccess,
  deleteCommitmentSuccess,
  createCommitmentFailure,
} = commitments.actions;

export default commitments.reducer;

export const fetchCommitments =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCommitmentsStart());
      const commitments = await getCommitments(accessToken);
      dispatch(getCommitmentsSuccess(commitments));
    } catch (err: any) {
      dispatch(getCommitmentsFailure(err.toString()));
    }
  };

export const fetchCommitmentsByGroup =
  (accessToken: string, groupID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCommitmentsStart());
      const commitments = await getCommitmentsByGroup(accessToken, groupID);
      dispatch(getCommitmentsSuccess(commitments));
    } catch (err: any) {
      dispatch(getCommitmentsFailure(err.toString()));
    }
  };

export const fetchCommitmentsByAssociatedContact =
  (accessToken: string, contactID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCommitmentsStart());
      const commitments = await getCommitmentsByAssociatedContact(
        accessToken,
        contactID
      );
      dispatch(getCommitmentsSuccess(commitments));
    } catch (err: any) {
      dispatch(getCommitmentsFailure(err.toString()));
    }
  };

export const fetchCommitmentsBySource =
  (accessToken: String, commitmentSourceID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCommitmentsStart());
      const commitments = await getCommitmentsBySource(
        accessToken,
        commitmentSourceID
      );
      dispatch(getCommitmentsSuccess(commitments));
    } catch (err: any) {
      dispatch(getCommitmentsFailure(err.toString()));
    }
  };

export const addCommitment =
  (
    accessToken: String,
    newCommitment: Partial<Commitment>,
    setReturnRoute: boolean,
    phaseList?: any[]
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCommitmentStart());
      const commitment = await createCommitment(
        accessToken,
        newCommitment,
        phaseList
      );
      dispatch(createCommitmentSuccess(commitment));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(fetchCommitments(accessToken));
        dispatch(push(`/commitments/commitments/${commitment.CommitmentID}`));
      }
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(createCommitmentFailure(error.response.data));
        dispatch(openSnackBar(error.response.data.message, "error"));
      } else {
        dispatch(createCommitmentFailure(error.toString()));
        dispatch(openSnackBar(Constants.FAILED, "error"));
      }
    }
  };

export const updCommitment =
  (
    accessToken: String,
    commitmentID: number,
    newCommitment: Partial<Commitment>,
    setReturnRoute: boolean,
    parentInteractionID: number | null = null,
    phases?: any[]
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCommitmentStart());
      const commitment = await updateCommitment(
        accessToken,
        commitmentID,
        newCommitment,
        phases
      );

      dispatch(updateCommitmentSuccess(commitment));
      if (parentInteractionID) {
        dispatch(
          fetchCommitmentInteractionsByInteraction(
            accessToken,
            parentInteractionID
          )
        );
      }
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(fetchCommitments(accessToken));
        dispatch(push("/commitments/commitments/" + commitmentID));
      }
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(createCommitmentFailure(error.response.data));
        dispatch(openSnackBar(error.response.data.message, "error"));
      } else {
        dispatch(createCommitmentFailure(error.toString()));
        dispatch(openSnackBar(Constants.FAILED, "error"));
      }
    }
  };

export const delCommitment =
  (accessToken: String, commitmentID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCommitmentStart());
      const result = await deleteCommitment(accessToken, commitmentID);
      dispatch(deleteCommitmentSuccess(commitmentID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
      dispatch(push("/commitments/commitments"));
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(createCommitmentFailure(error.response.data));
        dispatch(openSnackBar(error.response.data.message, "error"));
      } else {
        dispatch(createCommitmentFailure(error.toString()));
        dispatch(openSnackBar(Constants.FAILED, "error"));
      }
    }
  };
