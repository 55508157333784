import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchProfile } from "./ProfileSlice";
import { styled } from "@mui/material/styles";
import { Container, Typography, TextField, Paper, Grid } from "@mui/material";
import LoadingIndicator from "components/LoadingIndicator";

const PREFIX = "ProfilePage";

const classes = {
  appBar: `${PREFIX}-appBar`,
  layout: `${PREFIX}-layout`,
  paper: `${PREFIX}-paper`,
  heading: `${PREFIX}-heading`,
  grid: `${PREFIX}-grid`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.appBar}`]: {
    position: "relative",
  },

  [`& .${classes.layout}`]: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + parseInt(theme.spacing(2)) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + parseInt(theme.spacing(2)) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },

  [`& .${classes.heading}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.grid}`]: {
    marginBottom: theme.spacing(4),
  },
}));

interface profileProps {}

export const ProfilePage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const {
    currentProfile,
    isLoading,
    error: profileError,
  } = useSelector((state: RootState) => state.profile);
  //console.log("CURPROFILE", currentProfile);

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  useEffect(() => {
    (async () => {
      try {
        if (clientId !== -1) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchProfile(accessToken, clientId));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  if (profileError) {
    return (
      <Root>
        <h1>Something went wrong...</h1>
        <div>{profileError.toString()}</div>
      </Root>
    );
  }

  let profileView =
    isLoading || clientIsLoading ? (
      <LoadingIndicator />
    ) : (
      <Root>
        <React.Fragment>
          <Container>
            <Paper className={classes.paper}>
              <Typography
                component="h1"
                variant="h4"
                align="center"
                className={classes.heading}
              >
                My Profile
              </Typography>
              <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="FirstName"
                    value={currentProfile.FirstName}
                    label="First Name"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="Surname"
                    value={currentProfile.Surname}
                    label="Last name"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="EmailAddress"
                    value={currentProfile.EmailAddress}
                    label="Email"
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Typography
                component="h1"
                variant="h4"
                align="center"
                className={classes.heading}
              >
                Company Information
              </Typography>
              <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12}>
                  <TextField
                    disabled
                    id="CompanyName"
                    value={currentProfile.CompanyName}
                    label="Company Name"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled
                    id="AccountType"
                    value={
                      currentProfile.IsClient
                        ? "Client Account"
                        : "Basic Account"
                    }
                    label="Account Type"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </React.Fragment>
      </Root>
    );

  return <div id="profile-page">{profileView}</div>;
};
