import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  StatusType,
  StatusTypesResult,
  getStatusTypes,
} from "api/stakeholder/statusTypeAPI";
import { AppThunk } from "app/store";

interface StatusTypeState {
  statusTypesById: Record<number, StatusType>;
  statusTypeList: number[];
  isLoading: boolean;
  error: string | null;
}

const StatusTypeInitialState: StatusTypeState = {
  statusTypesById: {},
  statusTypeList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: StatusTypeState) {
  state.isLoading = true;
}

function loadingFailed(state: StatusTypeState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const statusTypes = createSlice({
  name: "statusType",
  initialState: StatusTypeInitialState,
  reducers: {
    getStatusTypesStart: startLoading,
    getStatusTypesSuccess(
      state,
      { payload }: PayloadAction<StatusTypesResult>
    ) {
      const { statusTypes } = payload;
      state.isLoading = false;
      state.error = null;

      statusTypes.forEach((statusType) => {
        state.statusTypesById[statusType.StatusTypeID] = statusType;
      });

      state.statusTypeList = statusTypes.map(
        (statusType) => statusType.StatusTypeID
      );
    },
    getStatusTypesFailure: loadingFailed,
  },
});

export const {
  getStatusTypesStart,
  getStatusTypesSuccess,
  getStatusTypesFailure,
} = statusTypes.actions;

export default statusTypes.reducer;

export const fetchStatusTypes =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getStatusTypesStart());
      const statusTypes = await getStatusTypes(accessToken);
      dispatch(getStatusTypesSuccess(statusTypes));
    } catch (err: any) {
      dispatch(getStatusTypesFailure(err.toString()));
    }
  };
