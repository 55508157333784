import axiosClient from "./axiosClient";

export interface ProjectComponent {
  ProjectComponentID: number;
  ProjectComponentName: string;
  ProjectID: number;
  ComponentDescription: string;
  ComponentLocation: string;
}

export interface ProjectComponentsResult {
  projectComponents: ProjectComponent[];
}

export async function getProjectComponents(
  accessToken: String
): Promise<ProjectComponentsResult> {
  const url = `/projectComponent`;

  try {
    const { data } = await axiosClient.get<ProjectComponentsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getProjectComponentsByProject(
  accessToken: String,
  projCompID: number
): Promise<ProjectComponentsResult> {
  const url = `/projectComponent/${projCompID}`;

  try {
    const { data } = await axiosClient.get<ProjectComponentsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createProjectComponent(
  accessToken: String,
  newProjectComp: Partial<ProjectComponent>
): Promise<ProjectComponent> {
  const url = `/projectComponent`;

  try {
    const { data } = await axiosClient.post<ProjectComponent>(
      url,
      newProjectComp,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateProjectComponent(
  accessToken: String,
  projectCompID: number,
  newProjectComp: Partial<ProjectComponent>
): Promise<ProjectComponent> {
  const url = `/projectComponent/` + projectCompID;

  try {
    const { data } = await axiosClient.post<ProjectComponent>(
      url,
      newProjectComp,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteProjectComponent(
  accessToken: String,
  projectCompID: number
): Promise<string> {
  const url = `/projectComponent/` + projectCompID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    console.log("DELDATA", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}
