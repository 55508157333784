import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { IppTextField } from "components/IppTextField";
import { Upload } from "@progress/kendo-react-upload";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import {
  addCommitmentSourceAmendment,
  fetchCommitmentSourceAmendments,
} from "features/commitments/commitmentSourceAmendment/CommitmentSourceAmendmentSlice";
import { IppDatePicker } from "components/IppDatePicker";
import { ConvertDateOffset, GetJSDate } from "utils/DateFunctions";
import { IppCancelButton } from "components/Buttons/IppCancelButton";

const PREFIX = "AmendmentUploadDialog";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1000,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

interface UploadAmendmentProps {
  isOpen: boolean;
  handleClose: any;
  commitmentSourceID: number;
}

export const AmendmentUploadDialog = (props: UploadAmendmentProps) => {
  const { isOpen, handleClose, commitmentSourceID } = props;
  const dispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();
  const [A0token, setA0token] = useState("");
  const [amendmentName, setAmendmentName] = useState("");
  const [processing, setProcessing] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isEditing, setIsEditing] = useState(true);

  const [files, setFiles] = useState<Partial<any>>({
    files: [] as any,
    events: [] as any,
    errors: [] as any,
  });

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        setA0token(accessToken);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  const fileStatuses = [
    "UploadFailed",
    "Initial",
    "Selected",
    "Uploading",
    "Uploaded",
    "RemoveFailed",
    "Removing",
  ];

  const onBeforeUpload = (event: any) => {
    event.headers.Authorization = `Bearer ${A0token}`;
    event.additionalData.commitmentSourceID = commitmentSourceID;
  };

  const onAdd = (event: any) => {
    setProcessing(true);

    setFiles({
      files: event.newState,
      events: [
        ...files.events,
        `File selected: ${event.affectedFiles[0].name}`,
      ],
      errors: [],
    });
    setProcessing(false);
  };

  const onRemove = (event: any) => {
    setFiles({
      files: event.newState,
      events: [...files.events, `File removed: ${event.affectedFiles[0].name}`],
      errors: [],
    });
    setProcessing(false);
  };

  const onProgress = (event: any) => {
    setFiles({
      files: event.newState,
      events: [
        ...files.events,
        `On Progress: ${event.affectedFiles[0].progress} %`,
      ],
      errors: [...files.errors],
    });
  };

  const onStatusChange = (event: any) => {
    const file = event.affectedFiles[0];

    setFiles({
      files: event.newState,
      events: [
        ...files.events,
        `File '${file.name}' status changed to: ${fileStatuses[file.status]}`,
      ],
      errors: [...files.errors],
    });
  };

  const closeAmendment = (event: any, reason: any) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      dispatch(openSnackBar("Please select either Save or Cancel", "info"));
    } else handleClose();
  };

  const onSaveRequest = (files: any, options: any, onProgress: any): any => {
    const saveRequestPromise = new Promise((resolve, reject) => {
      //eventually this could be used to check that the upload is finished
      setIsUploading(true);

      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(
            addCommitmentSourceAmendment(
              accessToken,
              commitmentSourceID,
              amendmentName,
              formik.values.AmendmentDate,
              files[0]
            )
          );
          dispatch(
            fetchCommitmentSourceAmendments(accessToken, commitmentSourceID)
          );
          handleClose();
        } catch (e) {
          console.error(e);
        }
      })();

      resolve({ uid: files[0].uid });
    });

    return saveRequestPromise;
  };

  let amendmentNameAndDate = {
    AmendmentName: amendmentName,
    AmendmentDate: new Date(),
  };

  const validationSchema = yup.object({
    AmendmentName: yup.string().required("Amendment Name is required."),
    AmendmentDate: yup.date().required("Amendment date is required."),
  });

  let onsub = () => {};

  const formik = useFormik({
    initialValues: amendmentNameAndDate,
    validationSchema: validationSchema,
    onSubmit: onsub,
  });

  return (
    <StyledDialog maxWidth="sm" open={isOpen} onClose={closeAmendment}>
      <DialogTitle>Add Amendment to Commitment Source</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Guide</Typography>
            <Typography>
              - When uploading an image, use the highest available resolution
            </Typography>
            - Acceptable file types include:
            <Typography color="primary">
              .png, .svg, .gif, .jpg, .ppt, .pptx .jpeg, .pdf, .doc, .docx,
              .xls, .xlsx
            </Typography>
            <Grid item xs={12}>
              {" "}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <IppTextField
              id="AmendmentName"
              label="Amendment Name"
              value={amendmentName}
              onChangeFunction={(event: any) => {
                setAmendmentName(event.target.value);
              }}
              onBlurFunction={(event: any, newValue: any) => {
                setAmendmentName(event.target.value.trim());
              }}
              isEditing={true}
              setIsEditing={null}
              touchedExpression={formik.touched.AmendmentName}
              errorsExpression={formik.errors.AmendmentName}
            />
          </Grid>
          <Grid item xs={12}>
            <IppDatePicker
              id="AmendmentDate"
              required={true}
              label="Amendment Date"
              value={ConvertDateOffset(formik.values.AmendmentDate)}
              onChangeFunction={(newValue: any) => {
                formik.setFieldValue(
                  "AmendmentDate",
                  GetJSDate(newValue),
                  true
                );
                formik.setFieldTouched("AmendmentDate", true, true);
              }}
              errorsExpression={formik.errors.AmendmentDate}
              touchedExpression={formik.touched.AmendmentDate}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          <Grid item xs={12}>
            <Upload
              disabled={false}
              showActionButtons={
                !processing && files.errors.length === 0 && A0token !== ""
              }
              autoUpload={false}
              multiple={false}
              files={files.files}
              restrictions={{
                allowedExtensions: [
                  ".png",
                  ".svg",
                  ".gif",
                  ".jpg",
                  ".jpeg",
                  ".pdf",
                  ".doc",
                  ".docx",
                  ".xls",
                  ".xlsx",
                  ".ppt",
                  ".pptx",
                ],
              }}
              withCredentials={true}
              onAdd={onAdd}
              onRemove={onRemove}
              onProgress={onProgress}
              onStatusChange={onStatusChange}
              onBeforeUpload={onBeforeUpload}
              saveUrl={onSaveRequest}
            />
          </Grid>

          <Grid item xs={12}>
            <IppCancelButton onClick={handleClose} />
          </Grid>
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
};
