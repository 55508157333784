export interface BaseErrorItem {
  count: number;
  message?: string | React.ReactNode;
  renderAsList?: boolean;
  showSingleItem?: boolean;
}

export interface ChildError {
  header: string;
  rowNumber: number;
  expectedValue?: string | number;
  messageOverride?: React.ReactNode;
}

export interface ErrorItemWithList extends BaseErrorItem {
  list: string[]; // 'list' is required
  childList?: never; // Ensures 'childList' is not present
}

export interface ErrorItemWithChildList extends BaseErrorItem {
  childList: ChildError[]; // 'childList' is required
  list?: never; // Ensures 'list' is not present
}

export type ConstructedErrorItem = ErrorItemWithList | ErrorItemWithChildList;

export interface ConstructedErrors {
  [key: string]: ConstructedErrorItem;
}

export interface BaseProcessedError {
  title: string;
  message?: string;
  renderAsList?: boolean;
  showSingleItem?: boolean;
}

export interface ProcessedErrorWithList extends BaseProcessedError {
  list: string[];
  childList?: never;
}

export interface ProcessedErrorWithChildList extends BaseProcessedError {
  childList: ReducedErrorList;
  list?: never;
}

export type ProcessedUploadErrors =
  | ProcessedErrorWithList
  | ProcessedErrorWithChildList;

export interface ReducedErrorList {
  [key: string]: {
    title: React.ReactNode;
    list: string[];
  };
}

export type GenericErrorKey =
  | "requiredColumn"
  | "requiredData"
  | "incorrectType"
  | "characterLimit"
  | "optionalColumn"
  | "disabledFeature"
  | "invalidHours";

export type ChildErrorKey =
  | "requiredData"
  | "incorrectType"
  | "characterLimit"
  | "disabledFeature"
  | "newCompanies"
  | "invalidRegion"
  | "employeeDoesntExist";

export type ErrorType = "headerOnly" | "mustBe" | "characterLimit";

export type GenericErrorTitlesObject = {
  [key: string]: (count: number, singleItem?: string) => JSX.Element | "";
} & {
  [Key in GenericErrorKey]: (
    count: number,
    singleItem?: string
  ) => JSX.Element | "";
};

//-------Type Guards-------//
/**
 * Type guard to check if a given item is of type ChildError.
 * @param item The item to be checked.
 * @returns true if the item is a ChildError, false otherwise.
 */
export const isChildError = (item: ChildError | string): item is ChildError => {
  return (
    typeof item === "object" &&
    item !== null &&
    "header" in item &&
    "rowNumber" in item
  );
};

export const isChildErrorKey = (key: string): key is ChildErrorKey => {
  return [
    "requiredData",
    "incorrectType",
    "characterLimit",
    "disabledFeature",
    "newCompanies",
    "invalidRegion",
    "employeeDoesntExist",
  ].includes(key);
};

export const isReducedErrorList = (obj: any): obj is ReducedErrorList => {
  // First, check if obj is an object and not null
  if (typeof obj !== "object" || obj === null) {
    return false;
  }

  // Check every key in the object
  for (const key of Object.keys(obj)) {
    const value = obj[key];

    // Each value must be an object with a title and a list
    if (
      typeof value !== "object" ||
      value === null ||
      // Check for React node for title
      !isValidReactNode(value.title) ||
      // Ensure 'list' is an array of strings
      !Array.isArray(value.list) ||
      !value.list.every((item: any) => typeof item === "string") // Ensure all items in the list are strings
    ) {
      return false;
    }
  }

  return true;
};
//-------------------------//

// Helper function to determine if a value is a valid React node
const isValidReactNode = (node: any): boolean => {
  return (
    // Check for allowable types for React nodes
    ["string", "number", "object"].includes(typeof node) &&
    node !== undefined &&
    node !== false &&
    node !== true
  );
};
