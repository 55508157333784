import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { IppTextField } from "components/IppTextField";
import LoadingIndicator from "components/LoadingIndicator";
import { IppRichTextEditor } from "components/RichTextEditor/IppRichTextEditor";
import { Email } from "@mui/icons-material";
import { IppCheckbox } from "components/IppCheckbox";
import { useEffect, useState } from "react";
import { addCustomNotification } from "./CustomNotificationSlice";
import { fetchUser } from "features/users/UsersSlice";
import { IppSubmitButton } from "components/Buttons/IppSubmitButton";
import { useTypedTranslation } from "utils/customHooks";

const PREFIX = "CustomNotificationForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1000,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

const validationSchema = yup.object({
  SubjectLine: yup.string().required("Subject Line is required"),
  SummaryContent: yup.string().required("Summary Content is required"),
  TemplateContent: yup.string().required("Template content is required"),
});

export const CustomNotificationForm = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["strGen"]);

  const [isEditing, setIsEditing] = useState(true);

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const {
    usersById,
    userList,
    error: userError,
    isLoading: userIsLoading,
  } = useSelector((state: RootState) => state.users);

  const users = userList.map((id) => usersById[id]);

  console.log("USERS1", users);

  let formData = {
    SubjectLine: "subjectLine",
    SummaryContent: "summaryContent",
    TemplateContent: "<p>template content</p>",
    SendEmail: true,
    SendInApp: true,
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        // dispatch(fetchUsers(accessToken, clientId));
        dispatch(fetchUser(accessToken, clientId, 6));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  const onSub = (values: any) => {
    (async () => {
      try {
        let newValues = Object.assign({}, values) as any;
        newValues.Users = users;
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(addCustomNotification(accessToken, newValues));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: submitFunc,
  });

  let customNotForm =
    clientIsLoading || userIsLoading ? (
      <LoadingIndicator />
    ) : (
      <Root>
        <form onSubmit={formik.handleSubmit}>
          <Box display="flex" justifyContent="center">
            <Grid container className={classes.editForm} spacing={1}>
              <Grid item xs={12}>
                <Paper className={classes.boxSpace}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="h5" component="h1">
                        Custom Notification
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <IppTextField
                        id="SubjectLine"
                        label="Subject Line"
                        value={formik.values.SubjectLine}
                        onChangeFunction={formik.handleChange}
                        touchedExpression={formik.touched.SubjectLine}
                        errorsExpression={formik.errors.SubjectLine}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <IppTextField
                        id="SummaryContent"
                        label="Summary (used for In-App notification)"
                        value={formik.values.SummaryContent}
                        onChangeFunction={formik.handleChange}
                        touchedExpression={formik.touched.SummaryContent}
                        errorsExpression={formik.errors.SummaryContent}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        multiLine={true}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <IppCheckbox
                        id="SendEmail"
                        label="Send Email notification"
                        value={formik.values.SendEmail}
                        onChangeFunction={formik.handleChange}
                        isEditing={isEditing}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <IppCheckbox
                        id="SendInAPp"
                        label="Send In-App notification"
                        value={formik.values.SendInApp}
                        onChangeFunction={formik.handleChange}
                        isEditing={isEditing}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <IppRichTextEditor
                        id="TemplateContent"
                        label="Message Content"
                        value={formik.values.TemplateContent}
                        isEditing={isEditing}
                        touchedExpression={formik.touched.TemplateContent}
                        errorsExpression={formik.errors.TemplateContent}
                        setFieldValue={formik.setFieldValue}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption">
                        ** Note that a standard footer will be inserted
                        automatically before sending
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <IppSubmitButton
                        buttonText={t("strGen:buttons.sendnotification")}
                        startIcon={<Email />}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Root>
    );

  return <div id="comm-inf-form">{customNotForm}</div>;
};
