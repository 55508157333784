import { Link } from "react-router-dom";
import {
  Tooltip,
  Typography,
  Grid,
  Rating,
  ButtonGroup,
  Box,
  Menu as MuiMenu,
  MenuItem,
  IconButton,
  Chip,
  // useTheme,
  // alpha,
} from "@mui/material";
import {
  Check,
  Clear,
  Circle,
  CircleOutlined,
  OpenInBrowser,
  Edit,
  RemoveCircle,
  Star,
  MoreVert,
  Delete,
  DoNotDisturb,
  PersonPin,
  AttachFile,
} from "@mui/icons-material";
import { IconWrap } from "@progress/kendo-react-common";
import { chevronRightIcon, chevronDownIcon } from "@progress/kendo-svg-icons";
import { GetIcons } from "./inventoryIcons";
import { getStatusChips } from "./inventoryStatusChips";
import { getClickableLink } from "../../utils/urlUtils";
import { updContactGroup } from "features/stakeholder/contactGroup/ContactGroupSlice";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useRef, useState } from "react";
import { RootState } from "app/rootReducer";
import { updGrievanceContact } from "features/stakeholder/grievanceContact/GrievanceContactSlice";
import { useSnackBarConstants, useTypedTranslation } from "utils/customHooks";
import { IppButton } from "components/Buttons/IppButton";
import { GridCellProps } from "@progress/kendo-react-grid";

export const CommaDelimitedCell = (props: any) => {
  let value = props.dataItem[props.field];

  if (!value) {
    return <td></td>;
  }

  let groups = value.split(",");

  return (
    <td>
      {groups.length > 1 ? (
        <Tooltip title={value.substring(value.indexOf(",") + 1)}>
          <div>
            <span>{groups[0]}</span>
            <Typography color={"secondary"} display="inline" variant="body2">
              ...+{groups.length - 1}
            </Typography>
          </div>
        </Tooltip>
      ) : (
        <span>{groups[0]}</span>
      )}
    </td>
  );
};

export const CommaDelimitedChips = (props: any) => {
  let values = props.dataItem[props.field];

  if (!values) {
    return <td></td>;
  }

  return (
    <td>
      {values
        .split(",")
        .filter((type: string[]) => type)
        .map((type: string, index: number) => (
          <div key={index} style={{ display: "inline-block", padding: "3px" }}>
            <Chip label={type.trim()} variant="outlined" size="small" />
          </div>
        ))}
    </td>
  );
};

// Custom cell component to render rich text
export const RichTextCell: React.FC<GridCellProps> = (props) => {
  const { dataItem, field } = props;

  // Ensure field is defined
  if (!field) {
    return <td></td>;
  }

  const value = dataItem[field];

  return (
    <td>
      <div dangerouslySetInnerHTML={{ __html: value || "" }} />
    </td>
  );
};

export const StatusChipCell = (props: any) => {
  return <td>{getStatusChips(props.field, props.dataItem[props.field])}</td>;
};

export const IconCell = (props: any) => {
  return <td>{GetIcons(props.field, props.dataItem[props.field])}</td>;
};

export const CustomEmailCell = (props: any) => {
  const t = useTypedTranslation(["strGen"]);

  return (
    <td>
      <Tooltip title={t("strGen:components.customcells.emailcell.tooltip")}>
        <div style={{ cursor: "pointer", color: "blue" }}>
          <a href={`mailto:${props.dataItem[props.field]}`}>
            {props.dataItem[props.field]}
          </a>
        </div>
      </Tooltip>
    </td>
  );
};

export const CustomListCell = (props: any) => {
  // takes string with items separated by spaces and a pipeline,
  // splits and displays them as unordered list
  let cl: any = props.dataItem[props.field];

  if (cl) {
    let nameList = cl.split(" | ");
    return (
      <td>
        <div>
          {nameList.map((item: string) => (
            <div>{"• " + item}</div>
          ))}
        </div>
      </td>
    );
  } else {
    return (
      <td>
        <div></div>
      </td>
    );
  }
};

export const ExternalLinkCell = (props: any) => {
  const t = useTypedTranslation(["strGen"]);

  return props.dataItem[props.field] ? (
    <td>
      <Grid container direction="row" alignItems="center" spacing={1}>
        <Grid item>
          <Tooltip
            title={t("strGen:components.customcells.externallinkcell.tooltip")}
          >
            <a
              href={getClickableLink(props.dataItem[props.field])}
              target="_blank"
              rel="noreferrer"
            >
              <OpenInBrowser
                fontSize="small"
                style={{ verticalAlign: "middle", lineHeight: "1px" }}
              />
            </a>
          </Tooltip>
        </Grid>
        <Grid item>
          <span>{props.dataItem[props.field]}</span>
        </Grid>
      </Grid>
    </td>
  ) : (
    <td></td>
  );
};

export const CustomColorCell = (props: any) => {
  return (
    <td
      style={{
        backgroundColor: props.colorFunction(props.dataItem[props.field]),
      }}
    >
      {props.dataItem[props.field]}
    </td>
  );
};

export const CustomCheckboxCell = (props: any) => {
  return (
    <td>
      {props.dataItem[props.field] ? (
        props.clearTrueValues ? (
          ""
        ) : (
          <Check />
        )
      ) : props.clearFalseValues ? (
        ""
      ) : (
        <Clear />
      )}
    </td>
  );
};

export const CustomRatingCell = (props: any) => {
  return (
    <td>
      <Rating
        id={props.dataItem[props.field]}
        name={props.dataItem[props.field]}
        value={props.dataItem[props.field]}
        max={7}
        icon={<Circle />}
        emptyIcon={<CircleOutlined />}
        disabled={true}
      />
    </td>
  );
};

export const CustomLinkCell = (props: any) => {
  const { dataItem, field, format, path } = props;
  const t = useTypedTranslation(["strGen"]);

  const [isTruncated, setIsTruncated] = useState(false);
  const cellRef = useRef<HTMLDivElement>(null);

  const formattedData = format
    ? format.replace("{0:0}", dataItem[field])
    : dataItem[field];

  // Check truncation using ref dimensions
  useEffect(() => {
    if (cellRef.current) {
      setIsTruncated(cellRef.current.scrollWidth > cellRef.current.clientWidth);
    }
  }, [formattedData]);

  return (
    <td>
      <Tooltip
        title={
          isTruncated
            ? formattedData
            : t("strGen:components.customcells.customlinkcell.tooltip")
        }
        placement="top"
      >
        <Link to={path} style={{ textDecoration: "none", color: "blue" }}>
          <div
            ref={cellRef}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {formattedData}
          </div>
        </Link>
      </Tooltip>
    </td>
  );
};

export const OptionsCell = (props: any) => {
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);
  return (
    <td>
      <ButtonGroup
        variant="text"
        size="small"
        aria-label="outlined button group"
      >
        {props.title !== t("objStk:objects.issue.name") && (
          <IppButton
            size="small"
            color={"secondary"}
            onClick={() =>
              props.handleEditEntity(props.dataItem[props.relatedField])
            }
          >
            <Edit fontSize="small" />
          </IppButton>
        )}

        {props.title.toLowerCase() !== "commitment" && (
          <IppButton
            size="small"
            color={"error"}
            onClick={() =>
              props.handleDeleteEntity(
                props.dataItem[props.idField],
                props.dataItem[props.field]
              )
            }
          >
            <RemoveCircle fontSize="small" color="error" />
          </IppButton>
        )}
        {props.title.toLowerCase() === "contact" && (
          <IppButton
            size="small"
            color={"primary"}
            onClick={() =>
              props.handleDeleteEntity(
                props.dataItem[props.idField],
                props.dataItem[props.field]
              )
            }
          >
            <Star fontSize="small" color="primary" />
          </IppButton>
        )}
      </ButtonGroup>
    </td>
  );
};

export const MenuCell = (props: any) => {
  const { location } = useSelector((state: RootState) => state.router);
  const title = props.title;
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);
  const snackbarConstants = useSnackBarConstants();
  // const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const { currentProfile, currentUserRoleList, currentUserRolesById } =
    useSelector((state: RootState) => state.profile);

  // set canWRITE to true for Client Admins
  let canWRITE = true;
  let pathName: string = `${location.pathname}`;

  // if User is not an Admin, check for UserRoles
  if (!currentProfile.IsClientAdmin && pathName.includes("/engagement")) {
    const userRoles = currentUserRoleList.map((id) => currentUserRolesById[id]);

    if (title.toLowerCase() === "communication") {
      // Must have Stakeholder PowerUser Role for the Interaction Project
      canWRITE = userRoles.some(
        (role) =>
          role.ProjectID === props.dataItem.InteractionProjectID &&
          role.ModuleRoleID === 5
      );
    }
    if (title.toLowerCase() === "grievance") {
      // Must have Stakeholder PowerUser Role for the Grievance Project
      canWRITE = userRoles.some(
        (role) =>
          role.ProjectID === props.dataItem.GrievanceProjectID &&
          role.ModuleRoleID === 5
      );
    }
    if (title.toLowerCase() === "commitment") {
      // Must have a role with WRITE access for the Commitment Project
      canWRITE = userRoles.some(
        (role) =>
          role.ProjectID === props.dataItem.CommitmentProjectID &&
          (role.ModuleRoleID === 3 || role.ModuleRoleID === 4)
      );
    }
    if (title.toLowerCase() === "contact" || title.toLowerCase() === "group") {
      // Must have any role with WRITE access on Stakeholder or Commitments
      canWRITE = userRoles.some(
        (role) =>
          role.ModuleRoleID === 3 ||
          role.ModuleRoleID === 4 ||
          role.ModuleRoleID === 5
      );
    }
    if (title === t("objStk:objects.issue.name")) {
      // Must have any Stakeholder PowerUser Role
      canWRITE = userRoles.some((role) => role.ModuleRoleID === 5);
    }
  }

  const unLink = () => {
    let unLinkTooltip = t(
      "strGen:components.customcells.menucell.unlinktooltip.remove"
    );
    /* (props.title.toLowerCase() !== "commitment" ||
            (props.title.toLowerCase() === "commitment" &&
              props.parentTitle.toLowerCase() === "communication")) &&
          props.title.toLowerCase() !== "comment" &&
          props.dataItem.CanDelete !== false ?  */
    //console.log("TOOLTIP", props)

    if (props.dataItem.CanDelete === false) {
      // Default cannot remove tooltip
      unLinkTooltip = t(
        "strGen:components.customcells.menucell.unlinktooltip.cannotremove"
      );

      if (props.title.toLowerCase() === "comment") {
        unLinkTooltip = t(
          "strGen:components.customcells.menucell.unlinktooltip.cannotremovereferenced"
        );
      }

      if (props.title.toLowerCase() !== "commitment") {
        unLinkTooltip = t(
          "strGen:components.customcells.menucell.unlinktooltip.cannotremovereferenced"
        );
      }
    }

    // cases for commitment as subgrid when parent not communication
    if (
      props.title.toLowerCase() === "commitment" &&
      props.parentTitle.toLowerCase() !== "communication"
    ) {
      unLinkTooltip = t(
        "strGen:components.customcells.menucell.unlinktooltip.cannotremovecommitment"
      );
    }
    return unLinkTooltip;
  };

  return (
    <td>
      <Box
        sx={{
          height: "fit-content",
          transform: "translateZ(0px)",
          flexGrow: 1,
        }}
      >
        <Tooltip
          title={t(
            "strGen:components.customcells.menucell.unlinktooltip.options"
          )}
          placement="right"
        >
          <IconButton
            onClick={handleClick}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            //! removed 2024/08/30 -- can be cleaned up if not requested back (this is the code that adds color to the options menu buttons)
            // sx={{
            //   backgroundColor: alpha(theme.palette.secondary.light, 0.08), // Set the background color to near transparent secondary
            //   "&:hover": {
            //     backgroundColor: alpha(theme.palette.secondary.light, 0.3), // Darken the background color on hover
            //   },
            //   color: "inherit",
            //   borderRadius: "50%", // Make the button circular
            //   border: "1px solid",
            //   borderColor: alpha(theme.palette.secondary.light, 0.3),
            //   padding: 1,
            // }}
          >
            <MoreVert />
          </IconButton>
        </Tooltip>
        <MuiMenu
          open={open}
          id="account-menu"
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          anchorEl={anchorEl}
          onClose={handleClose}
          onClick={handleClose}
        >
          {props.showAttachBtn && (
            <Tooltip
              title={t("objPlt:objects.attachment.manageattachments")}
              placement="right"
            >
              <MenuItem
                onClick={() => {
                  props.handleAttachmentEntity(props.dataItem[props.idField]);
                }}
                disabled={props.disabled ? true : canWRITE ? false : true}
              >
                <AttachFile color="secondary" />
              </MenuItem>
            </Tooltip>
          )}
          {props.title !== t("objStk:objects.issue.name") && (
            <Tooltip
              title={t(
                "strGen:components.customcells.menucell.unlinktooltip.edit"
              )}
              placement="right"
            >
              <MenuItem
                onClick={() =>
                  props.handleEditEntity(props.dataItem[props.relatedField])
                }
                disabled={props.disabled ? true : canWRITE ? false : true}
              >
                <Edit color="secondary" />
              </MenuItem>
            </Tooltip>
          )}
          {unLink() ===
            t("strGen:components.customcells.menucell.unlinktooltip.remove") &&
          props.title.toLowerCase() !== "comment" &&
          props.parentTitle !== t("objStk:objects.thread.name") &&
          props.title !== t("objStk:objects.thread.name") ? (
            <Tooltip title={unLink()} placement="right">
              <MenuItem
                onClick={() =>
                  props.handleDeleteEntity(
                    props.dataItem[props.idField],
                    props.dataItem[props.field]
                  )
                }
                disabled={props.disabled ? true : canWRITE ? false : true}
              >
                <RemoveCircle color="error" />
              </MenuItem>
            </Tooltip>
          ) : (
            props.title.toLowerCase() !== "comment" &&
            props.parentTitle !== t("objStk:objects.thread.name") &&
            props.title !== t("objStk:objects.thread.name") && (
              <Tooltip title={unLink()}>
                <MenuItem
                  disabled={props.disabled ? true : canWRITE ? false : true}
                >
                  <DoNotDisturb color="info" />
                </MenuItem>
              </Tooltip>
            )
          )}
          {props.title.toLowerCase() === "comment" && (
            <Tooltip
              title={t("strGen:components.customcells.menucell.deletetooltip")}
              placement="right"
            >
              <MenuItem
                onClick={() =>
                  props.handleDeleteEntity(
                    props.dataItem[props.idField],
                    props.dataItem[props.field]
                  )
                }
                disabled={props.disabled ? true : canWRITE ? false : true}
              >
                <Delete color="error" />
              </MenuItem>
            </Tooltip>
          )}
          {((props.title === t("objStk:objects.interaction.name") &&
            props.parentTitle === t("objStk:objects.thread.name")) ||
            props.title === t("objStk:objects.thread.name")) && (
            <Tooltip
              title={t(
                "strGen:components.customcells.menucell.unlinktooltip.remove"
              )}
              placement="right"
            >
              <MenuItem
                onClick={() => props.handleDeleteEntity(props.dataItem)}
                disabled={props.disabled ? true : canWRITE ? false : true}
              >
                <RemoveCircle color="error" />
              </MenuItem>
            </Tooltip>
          )}
          {props.title.toLowerCase() === "contact" &&
            props.parentTitle.toLowerCase() === "group" && (
              <Tooltip
                title={
                  props.dataItem.IsPrimary
                    ? t(
                        "strGen:components.customcells.menucell.clearprimarytooltip"
                      )
                    : t(
                        "strGen:components.customcells.menucell.setprimarytooltip"
                      )
                }
                placement="right"
              >
                <MenuItem
                  onClick={async () => {
                    // sets is primary field as true after deep copy
                    const newCG = {
                      ContactID: props.dataItem.ContactID,
                      GroupID: props.dataItem.GroupID,
                      IsPrimary: props.dataItem.IsPrimary ? false : true,
                    };
                    // waits for access token
                    const accessToken = await getAccessTokenSilently({
                      authorizationParams: {
                        audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
                      },
                    });
                    // updates intersection
                    dispatch(
                      updContactGroup(
                        accessToken,
                        props.dataItem.ContactGroupID,
                        newCG,
                        snackbarConstants
                      )
                    );
                    //props.setDidSaveInventory(true)
                  }}
                  disabled={props.disabled ? true : canWRITE ? false : true}
                >
                  <Star color="warning" />
                </MenuItem>
              </Tooltip>
            )}
          {props.title.toLowerCase() === "contact" &&
            props.parentTitle.toLowerCase() === "concern" && (
              <Tooltip
                title={
                  props.dataItem.AddressedTo
                    ? t(
                        "strGen:components.customcells.menucell.clearaddressedto"
                      )
                    : t("strGen:components.customcells.menucell.setaddressedto")
                }
                placement="right"
              >
                <MenuItem
                  onClick={async () => {
                    // sets AddressedTo field as true after deep copy
                    // ensures changes made to 'newCG' do not affect the original 'props.dataItem' - toggles the value of AddressedTo in the new object, creating a modified copy of 'props.dataItem'
                    const newCG = {
                      ContactID: props.dataItem.ContactID,
                      GrievanceID: props.dataItem.GrievanceID,
                      AddressedTo: props.dataItem.AddressedTo ? false : true,
                    };
                    // waits for access token
                    const accessToken = await getAccessTokenSilently({
                      authorizationParams: {
                        audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
                      },
                    });
                    // updates intersection
                    dispatch(
                      updGrievanceContact(
                        accessToken,
                        props.dataItem.GrievanceContactID,
                        newCG,
                        snackbarConstants
                      )
                    );
                  }}
                  disabled={props.disabled ? true : canWRITE ? false : true}
                >
                  <PersonPin color="inherit" />
                </MenuItem>
              </Tooltip>
            )}
        </MuiMenu>
      </Box>
    </td>
  );
};

export const ExpandCell = (props: any) => {
  return (
    <td {...props.tdProps}>
      <a
        onClick={(e) => {
          e.preventDefault();
          if (props.onChange) {
            props.onChange({
              dataItem: props.dataItem,
              dataIndex: props.dataIndex,
              syntheticEvent: e,
              field: "expanded_custom",
              value: !props.dataItem["expanded"],
            });
          }
        }}
        href="#"
        tabIndex={-1}
      >
        <IconWrap
          name={props.dataItem["expanded"] ? "chevronDown" : "chevronRight"}
          icon={props.dataItem["expanded"] ? chevronDownIcon : chevronRightIcon}
        />
      </a>
    </td>
  );
};
