import { InfoOutlined } from "@mui/icons-material";
import {
  TextField,
  InputAdornment,
  TextFieldProps,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import {
  MAX_CURRENCY_VALUE,
  MIN_CURRENCY_VALUE,
} from "utils/constants/generalConstants";
import { markRequired } from "utils/functions";

interface IppCurrencyFieldProps {
  id: string;
  label: string;
  required?: boolean;
  value: TextFieldProps["value"];
  onChangeFunction: TextFieldProps["onChange"];
  touchedExpression?: any;
  errorsExpression?: any;
  isEditing: boolean;
  toolTip?: string;
  allowNegative?: boolean;
}

interface CustomProps {
  onChange: (value: number) => void;
  value: string;
  name: string;
  allowNegative: boolean;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, value, allowNegative, ...other } = props;

    const [internalValue, setInternalValue] = useState<number | string>(value);

    const handleFocus = () => {
      if (internalValue === 0) {
        setInternalValue("");
      }
    };

    const handleBlur = () => {
      if (internalValue === "" || internalValue === undefined) {
        setInternalValue(0);
        onChange(0);
      }
    };

    const handleValueChange = (values: any) => {
      const newValue = values.floatValue;

      if (newValue > MAX_CURRENCY_VALUE) {
        setInternalValue(MAX_CURRENCY_VALUE);
        onChange(MAX_CURRENCY_VALUE);
        return;
      }

      if (newValue < MIN_CURRENCY_VALUE) {
        if (allowNegative) {
          setInternalValue(MIN_CURRENCY_VALUE);
          onChange(MIN_CURRENCY_VALUE);
          return;
        }

        setInternalValue(0);
        onChange(0);
        return;
      }

      setInternalValue(newValue);
      onChange(newValue);
    };

    return (
      <NumericFormat
        {...other}
        value={internalValue}
        getInputRef={ref}
        onValueChange={handleValueChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        thousandSeparator
        valueIsNumericString
        fixedDecimalScale
        decimalScale={2}
        allowNegative={allowNegative}
        isAllowed={(values) => {
          const { floatValue } = values;
          return (
            floatValue === undefined ||
            (floatValue <= MAX_CURRENCY_VALUE &&
              floatValue >= MIN_CURRENCY_VALUE)
          );
        }}
      />
    );
  }
);

export const IppCurrencyField = ({
  id,
  label,
  required,
  value,
  onChangeFunction,
  touchedExpression,
  errorsExpression,
  isEditing,
  toolTip,
  allowNegative,
}: IppCurrencyFieldProps) => (
  <TextField
    id={id}
    name={id}
    label={markRequired(label, required)}
    value={value}
    onChange={onChangeFunction}
    error={touchedExpression && Boolean(errorsExpression)}
    helperText={touchedExpression && errorsExpression}
    InputProps={
      isEditing
        ? {
            inputComponent: NumericFormatCustom as any,
            inputProps: { allowNegative },
            readOnly: false,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            endAdornment: toolTip ? (
              <InputAdornment position="end">
                <Tooltip title={toolTip}>
                  <InfoOutlined fontSize="small" />
                </Tooltip>
              </InputAdornment>
            ) : (
              ""
            ),
          }
        : {
            inputComponent: NumericFormatCustom as any,
            inputProps: { allowNegative },
            readOnly: true,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }
    }
  />
);

IppCurrencyField.defaultProps = {
  required: false,
  allowNegative: true,
};
