import axiosClient from "api/axiosClient";

export interface Initiative {
  InitiativeID: number;
  InitiativeName: string;
  StatusID: number;
  Aprroved: boolean;
  Category: string;
  QualifiedValue: number;
  Owner: string;
  Details: string;
  InitiativeReferences: string;
  CompanyID: number;
  CompanyName: number;
  TDI: string;
  InitiativeGroup: string;
  WBS: string;
  PO: string;
  StatusName?: string;
  ClientID?: number;
  AssetID: number;
  AssetName?: number;
  TotalEligibleRnDExpense: number;
  TotalEligibleEnTExpense: number;
  FirstName: string;
  Surname: string;
  LastUpdated: Date;
  LastUpdatedBy: number;
  CreatedBy: number;
  CreatedDate: Date;
  InitiativeApprovedDate: Date;
}

export interface InitiativesResult {
  initiatives: Initiative[];
}

export interface Owner {
  Owner: string;
}

export interface OwnersResult {
  owners: Owner[];
}

export async function getInitiatives(
  accessToken: String
): Promise<InitiativesResult> {
  const url = `/initiative`;

  try {
    const { data } = await axiosClient.get<InitiativesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getInitiativeOwners(
  accessToken: String
): Promise<OwnersResult> {
  const url = `/initiative/owners`;

  try {
    const { data } = await axiosClient.get<OwnersResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createInitiative(
  accessToken: String,
  newInitiative: Partial<Initiative>
): Promise<Initiative> {
  const url = `/initiative`;

  try {
    const { data } = await axiosClient.post<Initiative>(url, newInitiative, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateInitiative(
  accessToken: String,
  InitiativeID: number,
  newInitiative: Partial<Initiative>
): Promise<Initiative> {
  const url = `/initiative/` + InitiativeID;

  try {
    const { data } = await axiosClient.post<Initiative>(url, newInitiative, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteInitiative(
  accessToken: String,
  InitiativeID: number
): Promise<string> {
  const url = `/initiative/` + InitiativeID;
  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}
