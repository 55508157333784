import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import { useTypedTranslation } from "utils/customHooks";
import { IppDeleteButton } from "./Buttons/IppDeleteButton";
import { IppCancelButton } from "./Buttons/IppCancelButton";
import { Trans } from "react-i18next";

type IppBulkDeleteDialogProps = {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  messageType?: string;
  title: string;
  parentTitle: string;
};

export const IppBulkDeleteDialog = ({
  open,
  onConfirm,
  onCancel,
  messageType = "Default",
  title,
  parentTitle,
}: IppBulkDeleteDialogProps) => {
  const t = useTypedTranslation(["objPlt", "strGen"]);

  const messageConfigs: Record<
    string,
    (title: string, parentTitle: string) => JSX.Element
  > = {
    Default: (title, parentTitle) => (
      <Alert severity="warning">
        <Trans
          i18nKey="strGen:components.ippbulkdeletedialog.defaultmessage"
          /* eslint-disable-next-line jsx-a11y/heading-has-content */
          components={[<strong />]}
          values={{
            title: title,
            parenttitle: parentTitle,
          }}
        />
      </Alert>
    ),
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{t("strGen:prompts.delete.deleteall")}</DialogTitle>
      <Divider />
      <DialogContent>
        {messageConfigs[messageType](title, parentTitle)}
      </DialogContent>
      <DialogActions>
        <IppDeleteButton
          buttonText={t("strGen:buttons.deleteall")}
          onClick={onConfirm}
        />
        <IppCancelButton onClick={onCancel} />
      </DialogActions>
    </Dialog>
  );
};
