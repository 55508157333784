import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchCompanies } from "./CompaniesSlice";
import { useParams } from "react-router-dom";

import { CompanyForm } from "./CompanyForm";
// import { useFormik } from 'formik';
// import * as yup from 'yup';
// import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
import LoadingIndicator from "components/LoadingIndicator";

// const validationSchema = yup.object({
//     CompanyName: yup
//       .string()
//       .required('Company Name is required'),
//     VendorID: yup
//       .string()
// });

interface CompInvProps {
  companyID: string;
}

export const CompanyDetailPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const {
    isLoading,
    error: compError,
    companiesById,
  } = useSelector((state: RootState) => state.companies);

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  // get company ID from url param and attempt to find company in store
  const { companyID } = useParams<CompInvProps>();
  const compID = parseInt(companyID, 10);

  const company = companiesById[parseInt(companyID, 10)];
  // console.log("COMPID", companyID);
  // console.log("COMPANYDETAIL", company);

  useEffect(() => {
    (async () => {
      try {
        // can avoid going to the DB if we navigated from inventory
        //if (!company) {

        if (clientId !== -1) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchCompanies(accessToken, clientId));
        }
        //}
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  //   const formik = useFormik({
  //     initialValues: company,
  //     validationSchema: validationSchema,
  //     onSubmit: (values) => {
  //       alert(JSON.stringify(values, null, 2));
  //     },
  //   });

  if (typeof compID != "number") {
    return (
      <div>
        <h1>Something went wrong...</h1>
        <div>{companyID} is not a valid Company ID</div>
      </div>
    );
  }

  if (compError) {
    return (
      <div>
        <h1>Something went wrong...</h1>
        <div>{compError.toString()}</div>
      </div>
    );
  }

  let compDetView =
    isLoading || clientIsLoading ? (
      <LoadingIndicator />
    ) : (
      <div>{company && <CompanyForm company={company} />}</div>
    );

  return <div id="company-detail-page">{compDetView}</div>;
};
