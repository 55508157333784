import { Toolbar, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import FooterToolbar from "./footerToolbar";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useTypedTranslation } from "utils/customHooks";

const PREFIX = "Footer";

//Get platform version without the Web-specific subpatch version
let combinedPlatformVersion = `${process.env.REACT_APP_PLATFORM_VERSION}`
  .split(".", 3)
  .join(".");

//const platformVersionString: string = `Version: ${combinedPlatformVersion}`;

const classes = {
  footer: `${PREFIX}-footer`,
  grow: `${PREFIX}-grow`,
};

const StyledFooter = styled("footer")(({ theme }) => ({
  [`&.${classes.footer}`]: {
    zIndex: theme.zIndex.drawer + 1,
    padding: theme.spacing(0, 1),
    marginTop: "auto",
    borderTop: "solid",
    borderTopColor: theme.palette.grey[400],
    borderTopWidth: "1px",
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[800],
  },
  [`& .${classes.grow}`]: {
    flexGrow: 1,
  },
}));

function Footer() {
  const { clientLogo } = useSelector((state: RootState) => state.client);
  const t = useTypedTranslation(["strGen"]);

  return (
    <StyledFooter className={classes.footer}>
      <div>
        <Toolbar variant="dense">
          <FooterToolbar />
          <div className={classes.grow} />
          <div>
            <Typography variant="caption" mr={1}>
              {t("strGen:footer.version")}
              {combinedPlatformVersion}
            </Typography>
          </div>
          <div>
            <a
              href="https://www.netbenefitsoftware.com"
              target="_blank"
              rel="noreferrer"
            >
              <Tooltip title={t("strGen:footer.sitelink")}>
                <img
                  src={
                    clientLogo
                      ? process.env.PUBLIC_URL + "/logo_small_greyscale.png"
                      : process.env.PUBLIC_URL + "/iconSmall.png"
                  }
                  alt={t("strGen:platform")}
                />
              </Tooltip>
            </a>
          </div>
        </Toolbar>
      </div>
    </StyledFooter>
  );
}

export default Footer;
