import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { IppInventory } from "components/Inventory/IppInventory";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";
import { fetchContracts } from "./ContractSlice";
import { ConvertDateOffset } from "utils/DateFunctions";

export const ContractInventoryPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const {
    contractList,
    contractsById,
    isLoading: contractIsLoading,
    error: contractError,
  } = useSelector((state: RootState) => state.contracts);

  const contracts = contractList.map((id) => contractsById[id]);

  const data = contracts.map((p) => {
    let n = Object.assign({}, p) as any;

    //Adjust award date
    if (p.AwardDate !== null) {
      n.AwardDate = ConvertDateOffset(p.AwardDate);
    } else {
      n.AwardDate = null;
    }

    // Adjust Expiry date
    if (p.ExpiryDate !== null) {
      n.ExpiryDate = ConvertDateOffset(p.ExpiryDate);
    } else {
      n.ExpiryDate = null;
    }

    // fixes bad data missing a title
    if (!p.ContractTitle || p.ContractTitle === " ") {
      n.ContractTitle = "* missing title";
    }
    return n;
  });

  const dataColumns = [
    {
      field: "ContractNumber",
      title: "Number",
      filter: "string",
      columnWidth: 200,
    },
    { field: "ContractTitle", title: "Title" },
    {
      field: "AwardDate",
      title: "Awarded",
      filter: "date",
      columnWidth: 120,
    },
    { field: "ProjectName", title: "Project" },
    { field: "CompanyName", title: "Company" },
    {
      field: "ExpiryDate",
      title: "Expiry",
      filter: "date",
      columnWidth: 120,
    },
    { field: "ContractScope", title: "Contract Scope" },
  ];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchContracts(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  if (contractError) {
    return <ErrorPage errMsg={contractError} />;
  }

  let contractInvView = contractIsLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <IppInventory
        title="Contracts"
        tableData={data}
        idField="ContractID"
        columns={dataColumns}
        isFiltered={true}
        showDetail={true}
        buttonProps={{
          showAdd: !!currentProfile.IsClient,
        }}
        sortColumn={1}
        linkColumn={1}
        searchByColumn={"CompanyName"}
        searchByPlaceholder={"Search By Company Name"}
      />
    </div>
  );

  return <div id="contract-inventory-page">{contractInvView}</div>;
};
