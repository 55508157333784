import { CommunityInvestmentForm } from "./CommunityInvestmentForm";

export const CommunityInvestmentCreatePage = () => {
  let communityInvestmentCreateView = (
    <div>
      <CommunityInvestmentForm />
    </div>
  );

  return (
    <div id="communityInvestment-detail-page">
      {communityInvestmentCreateView}
    </div>
  );
};
