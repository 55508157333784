import axiosClient from "../axiosClient";

export async function downloadTemplate(
  templateName: string,
  accessToken: string,
  id?: number
): Promise<void> {
  const url = `/template/${encodeURIComponent(templateName)}${
    id !== undefined ? `/${id}` : ""
  }`;

  try {
    const response = await axiosClient.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      responseType: "blob",
    });

    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", `${templateName}Template.xlsx`);

    // Triggering download without appending to the DOM
    link.click();

    // Clean up by revoking the Blob URL
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error("Failed to download template", error);
  }
}
