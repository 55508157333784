import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  WorkHourChart,
  WorkHourChartsResult,
  getWorkHourCharts,
} from "api/workHourChartAPI";
import { AppThunk } from "app/store";

interface WorkHourChartState {
  workHourChartsById: Record<number, WorkHourChart>;
  workHourChartList: number[];
  isLoading: boolean;
  error: string | null;
}

const WorkHourChartInitialState: WorkHourChartState = {
  workHourChartsById: {},
  workHourChartList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: WorkHourChartState) {
  state.isLoading = true;
}

function loadingFailed(
  state: WorkHourChartState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const workHourCharts = createSlice({
  name: "workhourchart",
  initialState: WorkHourChartInitialState,
  reducers: {
    getWorkHourChartsStart: startLoading,
    getWorkHourChartsSuccess(
      state,
      { payload }: PayloadAction<WorkHourChartsResult>
    ) {
      const { workHours } = payload;
      state.isLoading = false;
      state.error = null;

      workHours.forEach((workHourChart) => {
        state.workHourChartsById[workHourChart.MonthSequenceID] = workHourChart;
      });

      state.workHourChartList = workHours.map((item) => item.MonthSequenceID);
    },
    getWorkHourChartsFailure: loadingFailed,
  },
});

export const {
  getWorkHourChartsStart,
  getWorkHourChartsSuccess,
  getWorkHourChartsFailure,
} = workHourCharts.actions;

export default workHourCharts.reducer;

export const fetchWorkHourCharts =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getWorkHourChartsStart());
      const workHourCharts = await getWorkHourCharts(accessToken);
      // console.log("RETURN", workHourCharts);
      dispatch(getWorkHourChartsSuccess(workHourCharts));
    } catch (err: any) {
      dispatch(getWorkHourChartsFailure(err.toString()));
    }
  };
