import axiosClient from "../axiosClient";

export interface LevelOfImportance {
  LevelOfImportanceID: number;
  LevelOfImportanceName: string;
}

export interface LevelOfImportancesResult {
  levelOfImportances: LevelOfImportance[];
}

export async function getLevelOfImportances(
  accessToken: String
): Promise<LevelOfImportancesResult> {
  const url = `/levelofimportance`;

  try {
    const { data } = await axiosClient.get<LevelOfImportancesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    //console.log("API", data)
    return data;
  } catch (err: any) {
    throw err;
  }
}