import axiosClient from "../axiosClient";

export interface GrievanceContact {
  GrievanceContactID: number;
  ContactID: number;
  GrievanceID: number;
  GrievanceName: string;
  ContactName: string;
  ClientID: number;
  GrievanceStatusText: string;
  ReportedDate: Date;
  ResolutionDate: Date;
  GrievanceProjectID: number;
  SequenceID: number;
  AddressedTo: boolean;
}

export interface GrievanceContactsResult {
  grievanceContacts: GrievanceContact[];
}

export async function getGrievanceContacts(
  accessToken: String
): Promise<GrievanceContactsResult> {
  const url = `/grievanceContact`;

  try {
    const { data } = await axiosClient.get<GrievanceContactsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getGrievanceContactsByGrievance(
  accessToken: String,
  grievanceID: number
): Promise<GrievanceContactsResult> {
  const url = `/grievanceContact/grievance/${grievanceID}`;

  try {
    const { data } = await axiosClient.get<GrievanceContactsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getGrievanceContactsByContact(
  accessToken: String,
  contactID: number
): Promise<GrievanceContactsResult> {
  const url = `/grievanceContact/contact/${contactID}`;

  try {
    const { data } = await axiosClient.get<GrievanceContactsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

// NOT BEING USED

// export async function createGrievanceContact(
//   accessToken: String,
//   newCommInv: Partial<GrievanceContact>
// ): Promise<GrievanceContact> {
//   const url = `/grievanceContact`;

//   try {
//     const { data } = await axiosClient.post<GrievanceContact>(url, newCommInv, {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//         "Content-Type": "application/json",
//       },
//     });

//     return data;
//   } catch (err: any) {
//     throw err;
//   }
// }

export async function updateGrievanceContact(
  accessToken: String,
  commInvID: number,
  newCommInv: Partial<GrievanceContact>
): Promise<GrievanceContact> {
  const url = `/grievanceContact/` + commInvID;

  try {
    const { data } = await axiosClient.post<GrievanceContact>(url, newCommInv, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteGrievanceContact(
  accessToken: String,
  commInvID: number
): Promise<string> {
  const url = `/grievanceContact/` + commInvID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
