import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  Tag,
  TagsResult,
  getTags,
  createTag,
  updateTag,
  deleteTag,
  getTagsByRecordType,
} from "api/tagAPI";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { push } from "redux-first-history";
import { TagRecordType } from "utils/constants/generalConstants";

interface TagState {
  tagsById: Record<number, Tag>;
  tagList: number[];
  isLoading: boolean;
  childTagsById: Record<number, Tag>;
  childTagList: number[];
  isChildLoading: boolean;
  error: string | null;
}

const TagInitialState: TagState = {
  tagsById: {},
  tagList: [],
  isLoading: false,
  childTagsById: {},
  childTagList: [],
  isChildLoading: false,
  error: null,
};

function startLoading(state: TagState) {
  state.isLoading = true;
}

function loadingFailed(state: TagState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

function startChildLoading(state: TagState) {
  state.isChildLoading = true;
}

function childLoadingFailed(state: TagState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const tags = createSlice({
  name: "tags",
  initialState: TagInitialState,
  reducers: {
    getTagsStart: startLoading,
    getChildTagsStart: startChildLoading,
    getTagsSuccess(state, { payload }: PayloadAction<TagsResult>) {
      const { tags } = payload;
      state.isLoading = false;
      state.error = null;

      tags.forEach((tag) => {
        state.tagsById[tag.TagID] = tag;
      });

      state.tagList = tags.map((tag) => tag.TagID);
    },
    getChildTagsSuccess(state, { payload }: PayloadAction<TagsResult>) {
      const { tags } = payload;
      state.isChildLoading = false;
      state.error = null;

      tags.forEach((tag) => {
        state.childTagsById[tag.TagID] = tag;
      });

      state.childTagList = tags.map((tag) => tag.TagID);
    },
    getTagsFailure: loadingFailed,
    getChildTagsFailure: childLoadingFailed,
    createTagStart: startLoading,
    createTagSuccess(state, { payload }: PayloadAction<Tag>) {
      const { TagID } = payload;
      state.tagsById[TagID] = payload;
      state.tagList.push(TagID);

      state.isLoading = false;
      state.error = null;
    },
    updateTagSuccess(state, { payload }: PayloadAction<Tag>) {
      const { TagID } = payload;
      state.tagsById[TagID] = payload;
      state.tagList.push(TagID);

      state.isLoading = false;
      state.error = null;
    },
    deleteTagSuccess(state, { payload }: PayloadAction<number>) {
      const TagID = payload;
      delete state.tagsById[TagID];
      state.tagList = state.tagList.filter((item) => item !== TagID);

      state.isLoading = false;
      state.error = null;
    },
    createTagFailure: loadingFailed,
  },
});

export const {
  getTagsStart,
  getChildTagsStart,
  getTagsSuccess,
  getChildTagsSuccess,
  getTagsFailure,
  getChildTagsFailure,
  createTagStart,
  createTagSuccess,
  updateTagSuccess,
  deleteTagSuccess,
  createTagFailure,
} = tags.actions;

export default tags.reducer;

export const fetchTags =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getTagsStart());
      const tags = await getTags(accessToken);
      dispatch(getTagsSuccess(tags));
    } catch (err: any) {
      dispatch(getTagsFailure(err.toString()));
    }
  };

export const fetchTagsByRecordType =
  (accessToken: String, recordType: TagRecordType): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getTagsStart());
      const tags = await getTagsByRecordType(accessToken, recordType);
      dispatch(getTagsSuccess(tags));
    } catch (err: any) {
      dispatch(getTagsFailure(err.toString()));
    }
  };

export const fetchChildTagsByRecordType =
  (accessToken: String, recordType: TagRecordType): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getChildTagsStart());
      const tags = await getTagsByRecordType(accessToken, recordType);
      dispatch(getChildTagsSuccess(tags));
    } catch (err: any) {
      dispatch(getChildTagsFailure(err.toString()));
    }
  };

export const addTag =
  (accessToken: String, newTag: Partial<Tag>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createTagStart());
      const tag = await createTag(accessToken, newTag);
      dispatch(createTagSuccess(tag));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push(`/admin/tags`));
    } catch (err: any) {
      dispatch(createTagFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updTag =
  (accessToken: String, tagID: number, newTag: Partial<Tag>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createTagStart());
      const tag = await updateTag(accessToken, tagID, newTag);
      dispatch(updateTagSuccess(tag));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push("/admin/tags"));
    } catch (err: any) {
      dispatch(createTagFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delTag =
  (accessToken: String, tagID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createTagStart());
      const result = await deleteTag(accessToken, tagID);
      dispatch(deleteTagSuccess(tagID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
      //dispatch(push("/benefits/tags/"));
    } catch (err: any) {
      dispatch(createTagFailure(err.toString()));
    }
  };
