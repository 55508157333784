import { CloudDownload } from "@mui/icons-material";
import { ButtonProps } from "@mui/material/Button";
import { IppButton, IppButtonProps } from "components/Buttons/IppButton";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { RefObject } from "react";
import { useTypedTranslation } from "utils/customHooks";

interface IppExportButtonProps extends IppButtonProps {
  buttonText?: string;
  exporter: RefObject<ExcelExport>;
  color?: ButtonProps["color"];
  startIcon?: React.ReactNode;
}

export const IppExportButton = ({
  buttonText,
  exporter,
  color = "secondary",
  startIcon = <CloudDownload />,
  ...props
}: IppExportButtonProps) => {
  const t = useTypedTranslation(["strGen"]);
  const T_buttonText = buttonText || t("strGen:buttons.export");

  const excelExport = () => {
    if (exporter.current) {
      exporter.current.save();
    }
  };

  return (
    <IppButton
      onClick={excelExport}
      color={color}
      startIcon={startIcon}
      {...props}
    >
      {T_buttonText}
    </IppButton>
  );
};
