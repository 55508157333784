import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchContacts } from "./ContactSlice";
import { IppInventory } from "components/Inventory/IppInventory";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";
import { ConvertDateOffset } from "../../../utils/DateFunctions";
import { IppDebug } from "app/App";
import { useTypedTranslation } from "utils/customHooks";

export const ContactInventoryPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen"]);

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const {
    currentProfile,
    isLoading: profileLoading,
    error: profileError,
  } = useSelector((state: RootState) => state.profile);

  const {
    contactList,
    contactsById,
    isLoading: groupIsLoading,
    error: groupError,
  } = useSelector((state: RootState) => state.contacts);

  const contacts = contactList.map((id) => contactsById[id]);

  const data = contacts.map((p) => {
    let n = Object.assign({}, p) as any;
    if (p.LatestInteraction !== null) {
      n.LatestInteraction = ConvertDateOffset(p.LatestInteraction);
    } else {
      n.LatestInteraction = null;
    }
    n.ContactName = p.ContactName
      ? p.ContactName.trim() !== ""
        ? p.ContactName.trim()
        : `* ${t("objPlt:objects.contact.missingname")} *`
      : `* ${t("objPlt:objects.contact.missingname")} *`;

    n.FullLocation = p.TownCity
      ? p.TownCity + ", " + p.ProvinceState
      : p.ProvinceState;
    n.RelationOwnerName = p.RelationFirstName
      ? p.RelationFirstName + " " + p.RelationSurname
      : "";
    return n;
  });

  IppDebug(contacts);

  const dataColumns = [
    { field: "ContactName", title: t("objPlt:objects.contact.fields.name") },
    {
      field: "Groups",
      title: t("objPlt:objects.contact.fields.group", { count: 2 }),
      commaDelimited: true,
    },
    {
      field: "ContactTitle",
      filter: "text",
      title: t("objPlt:objects.contact.fields.title"),
    },
    {
      field: "FullLocation",
      title: t("objPlt:objects.contact.fields.location"),
    },
    {
      field: "PrimaryPhone",
      filter: "text",
      title: t("objPlt:objects.contact.fields.phone"),
    },
    {
      field: "Email",
      title: t("objPlt:objects.contact.fields.emailaddress"),
      sendEmail: true,
      commaDelimited: true,
    },
    {
      field: "RelationOwnerName",
      title: t("objPlt:objects.contact.fields.relationshipowner"),
    },
    {
      field: "LatestInteraction",
      title: t("objPlt:objects.contact.latestinteraction"),
      filter: "date",
      format: "{0:d}",
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchContacts(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  if (groupError) {
    return <ErrorPage errMsg={groupError} />;
  }

  let contactInvView =
    groupIsLoading || clientIsLoading || profileLoading ? (
      <LoadingIndicator />
    ) : (
      <div>
        <IppInventory
          title={t("objPlt:objects.contact.name", { count: 2 })}
          tableData={data}
          idField="ContactID"
          columns={dataColumns}
          isFiltered={true}
          showDetail={true}
          buttonProps={{
            showAdd: true,
            showUpload: !!currentProfile.IsClientAdmin,
            showTemplate: !!currentProfile.IsClientAdmin,
          }}
          searchByColumn={"ContactName"}
          searchByPlaceholder={t("strGen:filters.searchfor", {
            fieldname: t("objPlt:objects.contact.fields.contactname"),
          })}
        />
      </div>
    );

  return <div id="contact-inventory-page">{contactInvView}</div>;
};
