import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  Payment,
  PaymentsResult,
  getClientPayments,
  getPaymentsByPeriod,
  createPayment,
  updatePayment,
  deletePayment,
  getSinglePayment,
  getIndirectPaymentsByCompany,
} from "api/paymentAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import * as Constants from "utils/snackBarConstants";

interface PaymentState {
  paymentsById: Record<number, Payment>;
  paymentList: number[];
  isLoading: boolean;
  error: string | null;
}

const PaymentInitialState: PaymentState = {
  paymentsById: {},
  paymentList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: PaymentState) {
  state.isLoading = true;
}

function loadingFailed(state: PaymentState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const payments = createSlice({
  name: "payments",
  initialState: PaymentInitialState,
  reducers: {
    getPaymentsStart: startLoading,
    getPaymentsSuccess(state, { payload }: PayloadAction<PaymentsResult>) {
      const { payments } = payload;
      state.isLoading = false;
      state.error = null;

      payments.forEach((payment) => {
        state.paymentsById[payment.PaymentID] = payment;
      });

      state.paymentList = payments.map((payment) => payment.PaymentID);
    },
    getPaymentsFailure: loadingFailed,
    createPaymentStart: startLoading,
    createPaymentSuccess(state, { payload }: PayloadAction<Payment>) {
      const { PaymentID } = payload;
      state.paymentsById[PaymentID] = payload;
      state.paymentList.push(PaymentID);

      state.isLoading = false;
      state.error = null;
    },
    updatePaymentSuccess(state, { payload }: PayloadAction<Payment>) {
      const { PaymentID } = payload;
      state.paymentsById[PaymentID] = payload;

      state.isLoading = false;
      state.error = null;
    },
    deletePaymentSuccess(state, { payload }: PayloadAction<number>) {
      const PaymentID = payload;
      delete state.paymentsById[PaymentID];
      state.paymentList = state.paymentList.filter(
        (item) => item !== PaymentID
      );

      state.isLoading = false;
      state.error = null;
    },
    createPaymentFailure: loadingFailed,
  },
});

export const {
  getPaymentsStart,
  getPaymentsSuccess,
  getPaymentsFailure,
  createPaymentStart,
  createPaymentSuccess,
  updatePaymentSuccess,
  deletePaymentSuccess,
  createPaymentFailure,
} = payments.actions;

export default payments.reducer;

export const fetchClientPayments =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getPaymentsStart());
      const payments = await getClientPayments(accessToken);
      dispatch(getPaymentsSuccess(payments));
    } catch (err: any) {
      dispatch(getPaymentsFailure(err.toString()));
    }
  };

export const fetchSinglePayment =
  (accessToken: String, paymentID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getPaymentsStart());
      const payments = await getSinglePayment(accessToken, paymentID);
      dispatch(getPaymentsSuccess(payments));
    } catch (err: any) {
      dispatch(getPaymentsFailure(err.toString()));
    }
  };

export const fetchPaymentsByPeriod =
  (accessToken: String, periodID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getPaymentsStart());
      const payments = await getPaymentsByPeriod(accessToken, periodID);
      dispatch(getPaymentsSuccess(payments));
    } catch (err: any) {
      dispatch(getPaymentsFailure(err.toString()));
    }
  };

export const fetchIndirectPaymentsByCompany =
  (accessToken: String, companyID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getPaymentsStart());
      const payments = await getIndirectPaymentsByCompany(
        accessToken,
        companyID
      );
      dispatch(getPaymentsSuccess(payments));
    } catch (err: any) {
      dispatch(getPaymentsFailure(err.toString()));
    }
  };

export const addPayment =
  (
    accessToken: String,
    newPayment: Partial<Payment>,
    isPeriodPayment: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createPaymentStart());
      const payment = await createPayment(accessToken, newPayment);
      dispatch(createPaymentSuccess(payment));

      let redirectPath: string;
      if (isPeriodPayment && newPayment.PaymentPeriodID) {
        dispatch(
          fetchPaymentsByPeriod(accessToken, newPayment.PaymentPeriodID)
        );
        redirectPath = `/benefits/paymentPeriods/${newPayment.PaymentPeriodID}`;
      } else {
        dispatch(fetchClientPayments(accessToken));
        redirectPath = `/benefits/payments/${payment.PaymentID}`;
      }

      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push(redirectPath));
    } catch (error: any) {
      if (error.response.data) {
        dispatch(openSnackBar(error.response.data.message, "error"));
        dispatch(createPaymentFailure(error.response.data.message));
      } else {
        dispatch(openSnackBar(Constants.FAILED, "error"));
        dispatch(createPaymentFailure(Constants.FAILED));
      }
    }
  };

export const updPayment =
  (
    accessToken: String,
    paymentID: number,
    newPayment: Partial<Payment>,
    isPeriodPayment: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createPaymentStart());
      const payment = await updatePayment(accessToken, paymentID, newPayment);

      dispatch(updatePaymentSuccess(payment));
      dispatch(fetchSinglePayment(accessToken, payment.PaymentID));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));

      let redirectPath: string;
      if (isPeriodPayment && newPayment.PaymentPeriodID) {
        dispatch(
          fetchPaymentsByPeriod(accessToken, newPayment.PaymentPeriodID)
        );
        redirectPath = `/benefits/paymentPeriods/${newPayment.PaymentPeriodID}`;
      } else {
        dispatch(fetchClientPayments(accessToken));
        redirectPath = `/benefits/payments/${payment.PaymentID}`;
      }

      dispatch(push(redirectPath));
    } catch (error: any) {
      if (error.response.data) {
        dispatch(openSnackBar(error.response.data.message, "error"));
        dispatch(createPaymentFailure(error.response.data.message));
      } else {
        dispatch(openSnackBar(Constants.FAILED, "error"));
        dispatch(createPaymentFailure(Constants.FAILED));
      }
    }
  };

export const delPayment =
  (
    accessToken: String,
    paymentID: number,
    periodID: number | null,
    isPeriodPayment: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createPaymentStart());

      const result = await deletePayment(accessToken, paymentID, periodID);
      dispatch(deletePaymentSuccess(paymentID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));

      if (isPeriodPayment && periodID) {
        dispatch(push(`/benefits/paymentPeriods/${periodID}`));
      } else {
        dispatch(push("/benefits/payments"));
      }
    } catch (error: any) {
      if (error.response.data) {
        dispatch(openSnackBar(error.response.data.message, "error"));
        dispatch(createPaymentFailure(error.response.data.message));
      } else {
        dispatch(openSnackBar(Constants.FAILED, "error"));
        dispatch(createPaymentFailure(Constants.FAILED));
      }
    }
  };
