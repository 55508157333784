import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BlockBlobClient } from "@azure/storage-blob";

import {
  Attachment,
  attachmentsResult,
  getAttachments,
  createAttachment,
  updateAttachment,
  deleteAttachment,
  FileAttachment,
  LinkAttachment,
} from "api/attachmentsAPI";

import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { SnackBarConstants } from "utils/customHooks";

interface AttachmentState {
  attachmentsById: Record<number, Attachment>;
  attachmentList: number[];
  isLoading: boolean;
  error: string | null;
}

const AttachmentInitialState: AttachmentState = {
  attachmentsById: {},
  attachmentList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: AttachmentState) {
  state.isLoading = true;
}

function loadingFailed(state: AttachmentState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const attachments = createSlice({
  name: "attachment",
  initialState: AttachmentInitialState,
  reducers: {
    getAttachmentsStart: startLoading,
    getAttachmentsSuccess(
      state,
      { payload }: PayloadAction<attachmentsResult>
    ) {
      const { attachments } = payload;
      // console.log("PAYLOAD", indigenousGroups)
      state.isLoading = false;
      state.error = null;

      attachments.forEach((attachments) => {
        state.attachmentsById[attachments.AttachmentID] = attachments;
      });

      state.attachmentList = attachments.map(
        (attachments) => attachments.AttachmentID
      );
    },
    getAttachmentsFailure: loadingFailed,
    createAttachmentStart: startLoading,
    createAttachmentSuccess(state, { payload }: PayloadAction<Attachment>) {
      const { AttachmentID } = payload;
      state.attachmentsById[AttachmentID] = payload;
      state.attachmentList.push(AttachmentID);

      state.isLoading = false;
      state.error = null;
    },
    updateAttachmentSuccess(state, { payload }: PayloadAction<Attachment>) {
      const { AttachmentID } = payload;
      state.attachmentsById[AttachmentID] = payload;
      state.attachmentList.push(AttachmentID);

      state.isLoading = false;
      state.error = null;
    },
    deleteAttachmentSuccess(state, { payload }: PayloadAction<number>) {
      const AttachmentID = payload;
      delete state.attachmentsById[AttachmentID];
      state.attachmentList = state.attachmentList.filter(
        (item) => item !== AttachmentID
      );

      state.isLoading = false;
      state.error = null;
    },
    createAttachmentFailure: loadingFailed,
  },
});

export const {
  getAttachmentsStart,
  getAttachmentsSuccess,
  getAttachmentsFailure,
  createAttachmentStart,
  createAttachmentSuccess,
  updateAttachmentSuccess,
  deleteAttachmentSuccess,
  createAttachmentFailure,
} = attachments.actions;

export default attachments.reducer;

export const fetchAttachments =
  (accessToken: String, recordType: string, recordID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getAttachmentsStart());
      const attachments = await getAttachments(
        accessToken,
        recordType,
        recordID
      );
      dispatch(getAttachmentsSuccess(attachments));
    } catch (err: any) {
      dispatch(getAttachmentsFailure(err.toString()));
    }
  };

export const addLinkAttachment =
  (
    accessToken: string,
    recordType: string,
    recordID: number,
    link: string,
    linkTitle: string,
    description: string,
    companyID: number,
    projectID: number,
    moduleID: number,
    setIsUploading: (isUploading: boolean) => void,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createAttachmentStart());

      const newAttachment = {
        Link: link,
        linkTitle: linkTitle,
        CompanyID: companyID,
        ProjectID: projectID,
        ModuleID: moduleID,
        FileDescription: description,
      };

      //Create Attachment record and Azure upload token
      const attachment = await createAttachment<LinkAttachment>(
        accessToken,
        recordType,
        recordID,
        newAttachment
      );

      let completedAttachment = await updateAttachment<LinkAttachment>(
        accessToken,
        attachment.AttachmentID,
        { IsUploaded: true }
      );

      dispatch(createAttachmentSuccess(completedAttachment));
      dispatch(fetchAttachments(accessToken, recordType, recordID));
      dispatch(openSnackBar(snackbarConstants.ADD_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createAttachmentFailure(err.toString()));
      dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
    }
    setIsUploading(false);
  };

export const addFileAttachment =
  (
    accessToken: string,
    recordType: string,
    recordID: number,
    fileDesc: string,
    companyID: number,
    projectID: number,
    moduleID: number,
    file: any,
    setIsUploading: (isUploading: boolean) => void,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createAttachmentStart());

      const newAttachment = {
        FileDescription: fileDesc,
        FileName: file.name,
        CompanyID: companyID,
        ProjectID: projectID,
        ModuleID: moduleID,
      };

      //Create Attachment record and Azure upload token
      const attachment = await createAttachment<FileAttachment>(
        accessToken,
        recordType,
        recordID,
        newAttachment
      );

      const blockBlobClient = new BlockBlobClient(attachment.FilePath);
      const promise = blockBlobClient.uploadData(file.getRawFile());
      await promise;

      //Update DB isUploaded flag
      let completedAttachment = await updateAttachment<FileAttachment>(
        accessToken,
        attachment.AttachmentID,
        { IsUploaded: true }
      );

      completedAttachment.FilePath = attachment.FilePath;

      dispatch(createAttachmentSuccess(completedAttachment));
      dispatch(fetchAttachments(accessToken, recordType, recordID));
      dispatch(openSnackBar(snackbarConstants.ADD_SUCCESS, "success"));
      //dispatch(push("/admin/projects/new"));
    } catch (err: any) {
      dispatch(createAttachmentFailure(err.toString()));
      dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
    }
    setIsUploading(false);
  };

export const updateFileAttachment =
  (
    accessToken: String,
    attachmentID: number,
    newAttachment: Partial<FileAttachment>,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createAttachmentStart());
      const project = await updateAttachment<FileAttachment>(
        accessToken,
        attachmentID,
        newAttachment
      );
      dispatch(updateAttachmentSuccess(project));
      dispatch(openSnackBar(snackbarConstants.ADD_SUCCESS, "success"));
      //dispatch(push("/admin/projects/" + attachmentID));
    } catch (err: any) {
      dispatch(createAttachmentFailure(err.toString()));
      dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
    }
  };

export const deleteFileAttachment =
  (
    accessToken: String,
    attachmentID: number,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createAttachmentStart());
      const result = await deleteAttachment(accessToken, attachmentID);
      dispatch(deleteAttachmentSuccess(attachmentID));
      dispatch(openSnackBar(snackbarConstants.DELETE_SUCCESS, "success"));
      //dispatch(push("/admin/projects/" + projectID));
    } catch (err: any) {
      dispatch(createAttachmentFailure(err.toString()));
      dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
    }
  };
