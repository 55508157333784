import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import LoadingIndicator from "components/LoadingIndicator";
import { fetchAllSshes } from "./SsheSlice";
import { IppInventory } from "components/Inventory/IppInventory";
import { ConvertDateOffset } from "utils/DateFunctions";
import { useTypedTranslation } from "utils/customHooks";

export const SsheInventoryPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objSshe", "objPlt", "strGen"]);
  const {
    isLoading: ssheIsLoading,
    error: ssheErr,
    ssheList,
    sshesById,
  } = useSelector((state: RootState) => state.sshe);
  const sshes = ssheList.map((id) => sshesById[id]);

  const data = sshes?.map((sshe) => ({
    ...sshe,
    EndingDate: ConvertDateOffset(sshe.EndingDate),
    CompletionDate: ConvertDateOffset(sshe?.CompletionDate),
    StatusName: sshe?.IsSubmitted
      ? t("objSshe:objects.status.submitted")
      : t("objSshe:objects.status.draft"),
  }));

  const dataColumns = [
    {
      field: "SSHE_ID",
      title: "ID",
      format: "SSHE-{0:0}",
      filter: "string",
      columnWidth: 120,
    },
    {
      field: "CompanyName",
      title: t("objPlt:objects.company.name"),
    },
    {
      field: "ProjectName",
      title: t("objPlt:objects.project.name"),
    },

    {
      field: "EndingDate",
      title: "Ending Date",
      filter: "date",
    },
    {
      field: "CompletionDate",
      title: "Completion Date",
      filter: "date",
    },
    {
      field: "CompletedByName",
      title: "Completed By",
    },
    {
      field: "ExposureHours",
      title: "Exposure Hours",
      filter: "string",
    },
    {
      field: "StatusName",
      title: "Status",
      chips: true,
    },
  ];
  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchAllSshes(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);
  let ssheInvView = ssheIsLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <IppInventory
        title={`${t("objSshe:ssheReports")}`}
        tableData={data}
        idField="SSHE_ID"
        columns={dataColumns}
        isFiltered={true}
        showDetail={true}
        buttonProps={{
          showAdd: true,
        }}
        sortColumn={3}
        sortReverse={true}
        linkColumn={0}
        searchByColumn={"ProjectName"}
        searchByPlaceholder={t("strGen:filters.searchfor", {
          fieldname: t("objPlt:objects.project.name"),
        })}
      />
    </div>
  );

  return <div id="sshe-inventory-page">{ssheInvView}</div>;
};
