import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import * as Constants from "utils/snackBarConstants";

import {
  ProjectComponent,
  ProjectComponentsResult,
  getProjectComponents,
  getProjectComponentsByProject,
  createProjectComponent,
  updateProjectComponent,
  deleteProjectComponent,
} from "api/projectComponentAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";

interface ProjectComponentState {
  projectComponentsById: Record<number, ProjectComponent>;
  projectComponentList: number[];
  isLoading: boolean;
  error: string | null;
}

const ProjectComponentInitialState: ProjectComponentState = {
  projectComponentsById: {},
  projectComponentList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: ProjectComponentState) {
  state.isLoading = true;
}

function loadingFailed(
  state: ProjectComponentState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const projectComponents = createSlice({
  name: "projectComponents",
  initialState: ProjectComponentInitialState,
  reducers: {
    getProjectComponentsStart: startLoading,
    getProjectComponentsSuccess(
      state,
      { payload }: PayloadAction<ProjectComponentsResult>
    ) {
      const { projectComponents } = payload;
      // console.log("PAYLOAD", projectComponents);
      state.isLoading = false;
      state.error = null;

      projectComponents.forEach((projectComponent) => {
        state.projectComponentsById[projectComponent.ProjectComponentID] =
          projectComponent;
      });

      state.projectComponentList = projectComponents.map(
        (projectComponent) => projectComponent.ProjectComponentID
      );
    },
    getProjectComponentsFailure: loadingFailed,
    createProjectComponentStart: startLoading,
    createProjectComponentSuccess(
      state,
      { payload }: PayloadAction<ProjectComponent>
    ) {
      const { ProjectComponentID } = payload;
      state.projectComponentsById[ProjectComponentID] = payload;
      state.projectComponentList.push(ProjectComponentID);

      state.isLoading = false;
      state.error = null;
    },
    updateProjectComponentSuccess(
      state,
      { payload }: PayloadAction<ProjectComponent>
    ) {
      const { ProjectComponentID } = payload;
      state.projectComponentsById[ProjectComponentID] = payload;
      state.projectComponentList.push(ProjectComponentID);

      state.isLoading = false;
      state.error = null;
    },
    deleteProjectComponentSuccess(state, { payload }: PayloadAction<number>) {
      const ProjectComponentID = payload;
      delete state.projectComponentsById[ProjectComponentID];
      state.projectComponentList = state.projectComponentList.filter(
        (item) => item !== ProjectComponentID
      );

      state.isLoading = false;
      state.error = null;
    },
    createProjectComponentFailure: loadingFailed,
  },
});

export const {
  getProjectComponentsStart,
  getProjectComponentsSuccess,
  getProjectComponentsFailure,
  createProjectComponentStart,
  createProjectComponentSuccess,
  updateProjectComponentSuccess,
  deleteProjectComponentSuccess,
  createProjectComponentFailure,
} = projectComponents.actions;

export default projectComponents.reducer;

export const fetchProjectComponentsByProject =
  (accessToken: String, projectID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getProjectComponentsStart());
      const projectComponents = await getProjectComponentsByProject(
        accessToken,
        projectID
      );
      dispatch(getProjectComponentsSuccess(projectComponents));
    } catch (err: any) {
      dispatch(getProjectComponentsFailure(err.toString()));
    }
  };

export const fetchProjectComponents =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getProjectComponentsStart());
      const projectComponents = await getProjectComponents(accessToken);
      dispatch(getProjectComponentsSuccess(projectComponents));
    } catch (err: any) {
      dispatch(getProjectComponentsFailure(err.toString()));
    }
  };

export const addProjectComponent =
  (accessToken: String, newProjectComp: Partial<ProjectComponent>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createProjectComponentStart());
      const projectComponent = await createProjectComponent(
        accessToken,
        newProjectComp
      );
      dispatch(createProjectComponentSuccess(projectComponent));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push(`/admin/projects/${newProjectComp.ProjectID}`));
    } catch (err: any) {
      dispatch(createProjectComponentFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "success"));
    }
  };

export const updProjectComponent =
  (
    accessToken: String,
    projectCompID: number,
    newProjectComp: Partial<ProjectComponent>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createProjectComponentStart());
      const projectComponent = await updateProjectComponent(
        accessToken,
        projectCompID,
        newProjectComp
      );
      dispatch(updateProjectComponentSuccess(projectComponent));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push(`/admin/projects/${newProjectComp.ProjectID}`));
    } catch (err: any) {
      dispatch(createProjectComponentFailure(err.toString()));
    }
  };

export const delProjectComponent =
  (accessToken: String, projectCompID: number, projectID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createProjectComponentStart());
      const result = await deleteProjectComponent(accessToken, projectCompID);
      dispatch(deleteProjectComponentSuccess(projectCompID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
      dispatch(push(`/admin/projects/${projectID}`));
    } catch (err: any) {
      dispatch(createProjectComponentFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };
