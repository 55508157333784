import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  EnTExpense,
  EnTExpensesResult,
  getEnTExpenses,
  createEnTExpense,
  updateEnTExpense,
  deleteEnTExpense,
} from "api/benefits/entExpenseAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { fetchInitiatives } from "../initiatives/InitiativeSlice";

interface EnTExpenseState {
  entExpensesById: Record<number, EnTExpense>;
  entExpenseList: number[];
  isLoading: boolean;
  error: string | null;
  errorList: any[] | null;
}

const EnTExpenseInitialState: EnTExpenseState = {
  entExpensesById: {},
  entExpenseList: [],
  isLoading: false,
  error: null,
  errorList: null,
};

function startLoading(state: EnTExpenseState) {
  state.isLoading = true;
}

function loadingFailed(
  state: EnTExpenseState,
  action: PayloadAction<{ status: string; message: string; errors: [] }>
) {
  state.isLoading = false;
  state.error = action.payload.message;
  if (action.payload.errors) {
    state.errorList = action.payload.errors;
  }
}

const entExpenses = createSlice({
  name: "entExpenses",
  initialState: EnTExpenseInitialState,
  reducers: {
    getEnTExpensesStart: startLoading,
    getEnTExpensesSuccess(
      state,
      { payload }: PayloadAction<EnTExpensesResult>
    ) {
      const { entExpenses } = payload;
      state.isLoading = false;
      state.error = null;
      state.errorList = null;
      entExpenses.forEach((entExpense) => {
        state.entExpensesById[entExpense.EnTExpenseID] = entExpense;
      });

      state.entExpenseList = entExpenses.map(
        (entExpense) => entExpense.EnTExpenseID
      );
    },
    getEnTExpensesFailure: loadingFailed,
    createEnTExpenseStart: startLoading,
    createEnTExpenseSuccess(state, { payload }: PayloadAction<EnTExpense>) {
      const { EnTExpenseID } = payload;
      state.entExpensesById[EnTExpenseID] = payload;
      state.entExpenseList.push(EnTExpenseID);

      state.isLoading = false;
      state.error = null;
      state.errorList = null;
    },
    updateEnTExpenseSuccess(state, { payload }: PayloadAction<EnTExpense>) {
      const { EnTExpenseID } = payload;
      state.entExpensesById[EnTExpenseID] = payload;

      state.isLoading = false;
      state.error = null;
      state.errorList = null;
    },
    deleteEnTExpenseSuccess(state, { payload }: PayloadAction<number>) {
      const EnTExpenseID = payload;
      delete state.entExpensesById[EnTExpenseID];
      state.entExpenseList = state.entExpenseList.filter(
        (item) => item !== EnTExpenseID
      );

      state.isLoading = false;
      state.error = null;
      state.errorList = null;
    },
    createEnTExpenseFailure: loadingFailed,
  },
});

export const {
  getEnTExpensesStart,
  getEnTExpensesSuccess,
  getEnTExpensesFailure,
  createEnTExpenseStart,
  createEnTExpenseSuccess,
  updateEnTExpenseSuccess,
  deleteEnTExpenseSuccess,
  createEnTExpenseFailure,
} = entExpenses.actions;

export default entExpenses.reducer;

export const fetchEnTExpenses =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getEnTExpensesStart());
      const entExpenses = await getEnTExpenses(accessToken);
      dispatch(getEnTExpensesSuccess(entExpenses));
    } catch (err: any) {
      dispatch(getEnTExpensesFailure(err.toString()));
    }
  };

export const addEnTExpense =
  (
    accessToken: String,
    newEnTExpense: Partial<EnTExpense>,
    setReturnRoute: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createEnTExpenseStart());
      const entExpense = await createEnTExpense(accessToken, newEnTExpense);
      dispatch(createEnTExpenseSuccess(entExpense));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      // If parent, return to E&T Expense inventory page. If child, stay on current Initiative
      if (setReturnRoute) {
        dispatch(push("/benefits/entexpenses"));
      } else {
        entExpense.InitiativeID
          ? dispatch(push(`/benefits/initiatives/${entExpense.InitiativeID}`))
          : dispatch(push("/benefits/entexpenses"));
      }
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(openSnackBar(error.response.data.message, "error"));
        dispatch(createEnTExpenseFailure(error.response.data.message));
      } else {
        dispatch(createEnTExpenseFailure(error.toString()));
        dispatch(openSnackBar(Constants.FAILED, "error"));
      }
    }
  };

export const updEnTExpense =
  (
    accessToken: String,
    entExpenseID: number,
    newEnTExpense: Partial<EnTExpense>,
    setReturnRoute: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createEnTExpenseStart());
      const entExpense = await updateEnTExpense(
        accessToken,
        entExpenseID,
        newEnTExpense
      );
      dispatch(updateEnTExpenseSuccess(entExpense));
      dispatch(fetchEnTExpenses(accessToken));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      //If parent, stay on current E&T Expense. If child, stay on current Initiative
      if (setReturnRoute) {
        dispatch(push("/benefits/entexpenses/" + entExpenseID));
      } else {
        dispatch(push(`/benefits/initiatives/${entExpense.InitiativeID}`));
      }
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(openSnackBar(error.response.data.message, "error"));
        dispatch(createEnTExpenseFailure(error.response.data.message));
      } else {
        dispatch(createEnTExpenseFailure(error.toString()));
        dispatch(openSnackBar(Constants.FAILED, "error"));
      }
    }
  };

export const delEnTExpense =
  (
    accessToken: String,
    entExpenseID: number,
    setReturnRoute: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createEnTExpenseStart());
      const result = await deleteEnTExpense(accessToken, entExpenseID);
      dispatch(deleteEnTExpenseSuccess(entExpenseID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
      // If parent, return to E&T Expense inventory page. If child, stay on current Initiative & refresh form to update total expense field
      if (setReturnRoute) {
        dispatch(push("/benefits/entexpenses"));
      } else {
        dispatch(fetchInitiatives(accessToken));
      }
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(openSnackBar(error.response.data.message, "error"));
        dispatch(createEnTExpenseFailure(error.response.data.message));
      } else {
        dispatch(createEnTExpenseFailure(error.toString()));
        dispatch(openSnackBar(Constants.FAILED, "error"));
      }
    }
  };
