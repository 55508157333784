import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CommunityInvestmentGroup,
  CommunityInvestmentGroupsResult,
  getCommunityInvestmentGroups,
} from "api/benefits/CommunityInvestmentGroupAPI";
import { AppThunk } from "app/store";

interface CommunityInvestmentGroupState {
  communityInvestmentGroupsById: Record<number, CommunityInvestmentGroup>;
  communityInvestmentGroupList: number[];
  isLoading: boolean;
  error: string | null;
}

const CommunityInvestmentGroupInitialState: CommunityInvestmentGroupState = {
  communityInvestmentGroupsById: {},
  communityInvestmentGroupList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: CommunityInvestmentGroupState) {
  state.isLoading = true;
}

function loadingFailed(
  state: CommunityInvestmentGroupState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const communityInvestmentGroups = createSlice({
  name: "communityInvestmentGroup",
  initialState: CommunityInvestmentGroupInitialState,
  reducers: {
    getCommunityInvestmentGroupsStart: startLoading,
    getCommunityInvestmentGroupsSuccess(
      state,
      { payload }: PayloadAction<CommunityInvestmentGroupsResult>
    ) {
      const { communityInvestmentGroups } = payload;
      state.isLoading = false;
      state.error = null;

      communityInvestmentGroups.forEach((communityInvestmentGroup) => {
        state.communityInvestmentGroupsById[
          communityInvestmentGroup.CommunityInvestmentGroupID
        ] = communityInvestmentGroup;
      });

      state.communityInvestmentGroupList = communityInvestmentGroups.map(
        (communityInvestmentGroup) =>
          communityInvestmentGroup.CommunityInvestmentGroupID
      );
    },
    getCommunityInvestmentGroupsFailure: loadingFailed,
  },
});

export const {
  getCommunityInvestmentGroupsStart,
  getCommunityInvestmentGroupsSuccess,
  getCommunityInvestmentGroupsFailure,
} = communityInvestmentGroups.actions;

export default communityInvestmentGroups.reducer;

export const fetchCommunityInvestmentGroups =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCommunityInvestmentGroupsStart());
      const communityInvestmentGroups = await getCommunityInvestmentGroups(
        accessToken
      );
      dispatch(getCommunityInvestmentGroupsSuccess(communityInvestmentGroups));
    } catch (err: any) {
      dispatch(getCommunityInvestmentGroupsFailure(err.toString()));
    }
  };
