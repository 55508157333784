import { Grid, IconButton, Tooltip } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useTypedTranslation } from "utils/customHooks";

interface ippFormDividerProps {
  isEditing: boolean;
  setIsEditing: any;
}

export const IppEditPencil = (props: ippFormDividerProps) => {
  const { isEditing, setIsEditing } = props;
  const t = useTypedTranslation(["strGen"]);

  const editChange = () => {
    setIsEditing(!isEditing);
  };

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="flex-end">
        {!isEditing && (
          <Tooltip title={t("strGen:components.ippeditpencil.tooltip")}>
            <IconButton onClick={editChange} size="small">
              <Edit fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
};
