import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchCommitmentSources } from "./CommitmentSourceSlice";
import { IppInventory } from "components/Inventory/IppInventory";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";
import { ConvertDateOffset } from "utils/DateFunctions";
import { useTranslation } from "react-i18next";

export const CommitmentSourceInventoryPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation(["objStk", "objPlt", "strGen", "objCom"]);

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const {
    commitmentSourceList,
    isLoading,
    error: commitmentSourcesError,
    commitmentSourcesById,
  } = useSelector((state: RootState) => state.commitmentSources);

  const commitmentSources = commitmentSourceList.map(
    (id) => commitmentSourcesById[id]
  );

  const data = commitmentSources.map((p) => {
    let newItem = Object.assign({}, p) as any;
    if (p.ExpiryDate !== null) {
      newItem.ExpiryDate = ConvertDateOffset(p.ExpiryDate);
    } else {
      newItem.ExpiryDate = null;
    }
    if (p.DocumentDate !== null) {
      newItem.DocumentDate = ConvertDateOffset(p.DocumentDate);
    } else {
      newItem.DocumentDate = null;
    }
    return newItem;
  });

  const dataColumns = [
    {
      field: "CommitmentSourceName",
      title: t("objCom:objects.commitmentsource.source"),
    },
    {
      field: "SourceDescription",
      filter: "text",
      title: t("objCom:objects.commitmentsource.fields.description"),
    },
    {
      field: "CommitmentSourceTypeName",
      title: t("objCom:objects.commitmentsource.fields.type"),
    },
    {
      field: "DocumentDate",
      title: t("objPlt:platformwide.fields.dateofdocument"),
      filter: "date",
      format: "{0:d}",
      columnWidth: 150,
    },
    {
      field: "ExpiryDate",
      title: t("objPlt:platformwide.fields.expirydate"),
      filter: "date",
      format: "{0:d}",
      columnWidth: 150,
    },
    {
      field: "CommitmentCount",
      title: t("objCom:objects.commitment.relatedcommitment", { count: 2 }),
      format: "{0:n0}",
      filter: "numeric",
      columnWidth: 190,
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchCommitmentSources(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  if (commitmentSourcesError) {
    return <ErrorPage errMsg={commitmentSourcesError} />;
  }

  let CommitmentSourceInvView =
    isLoading || clientIsLoading ? (
      <LoadingIndicator />
    ) : (
      <div>
        <IppInventory
          title={t("objCom:objects.commitmentsource.name", { sourcecount: 2 })}
          tableData={data}
          idField="CommitmentSourceID"
          columns={dataColumns}
          isFiltered={true}
          showDetail={true}
          buttonProps={{
            showAdd: true,
          }}
          sortColumn={0}
          linkColumn={0}
          searchByColumn={"CommitmentSourceName"}
          searchByPlaceholder={"Search Commitment Sources"}
        />
      </div>
    );

  return (
    <div id="CommitmentSource-inventory-page">{CommitmentSourceInvView}</div>
  );
};
