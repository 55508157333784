import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getInitiativeOwners, OwnersResult } from "api/benefits/initiativeAPI";
import { AppThunk } from "app/store";

interface InitiativeState {
  initiativeOwners: string[];
  isLoadingOwners: boolean;
  error: string | null;
  errorList: any[] | null;
}

const InitiativeOwnersInitialState: InitiativeState = {
  initiativeOwners: [],
  isLoadingOwners: false,
  error: null,
  errorList: null,
};

function startLoadingOwners(state: InitiativeState) {
  state.isLoadingOwners = true;
}

function loadingOwnersFailed(
  state: InitiativeState,
  action: PayloadAction<{ status: string; message: string; errors: [] }>
) {
  state.isLoadingOwners = false;
  state.error = action.payload.message;
  if (action.payload.errors) {
    state.errorList = action.payload.errors;
  }
}

const initiativeOwners = createSlice({
  name: "initiativeOwners",
  initialState: InitiativeOwnersInitialState,
  reducers: {
    getInitiativeOwnersStart: startLoadingOwners,
    getInitiativeOwnersSuccess(
      state,
      { payload }: PayloadAction<OwnersResult>
    ) {
      state.isLoadingOwners = false;
      state.error = null;
      state.errorList = null;

      // map the value down to just an array of strings
      state.initiativeOwners = payload.owners.map((item) => item.Owner);
    },
    getInitiativeOwnersFailure: loadingOwnersFailed,
  },
});

export const {
  getInitiativeOwnersStart,
  getInitiativeOwnersSuccess,
  getInitiativeOwnersFailure,
} = initiativeOwners.actions;

export default initiativeOwners.reducer;

export const fetchInitiativeOwners =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getInitiativeOwnersStart());
      const owners = await getInitiativeOwners(accessToken);
      dispatch(getInitiativeOwnersSuccess(owners));
    } catch (err: any) {
      dispatch(getInitiativeOwnersFailure(err.toString()));
    }
  };
