import FiberNewIcon from "@mui/icons-material/FiberNew";
import { MenuItemLink, MenuItemArrow } from "@progress/kendo-react-layout";
import { Link } from "react-router-dom";

function renderMenuIconIfApplicable(props: any) {
  return props.item.icon ? (
    <span
      className={`k-icon k-i-${props.item.icon}`}
      role="presentation"
      key="0"
    />
  ) : null;
}
function renderArrowIfApplicable(props: any) {
  return props.item.items && props.item.items.length > 0 ? (
    <MenuItemArrow
      itemId={props.itemId}
      verticalMenu={false}
      dir={props.dir}
      key="1"
    />
  ) : null;
}

const newLink = ["/engagement/communicationthreads"];

const menuLinkRender = (props: any) => {
  // console.log("PROPS", props);
  if (!props.item.data) {
    return (
      <MenuItemLink opened={props.opened}>
        {[
          renderMenuIconIfApplicable(props),
          props.item.text,
          renderArrowIfApplicable(props),
        ]}
      </MenuItemLink>
    );
  }
  if (props.item.data && props.item.data.startsWith("http")) {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={props.item.data}
        style={{ textDecoration: "inherit", color: "inherit" }}
      >
        <MenuItemLink opened={props.opened}>
          {[
            renderMenuIconIfApplicable(props),
            props.item.text,
            renderArrowIfApplicable(props),
          ]}
        </MenuItemLink>
      </a>
    );
  }
  return (
    <Link
      to={props.item.data}
      style={{ textDecoration: "inherit", color: "inherit" }}
    >
      <MenuItemLink opened={props.opened}>
        {[
          renderMenuIconIfApplicable(props),
          props.item.text,
          renderArrowIfApplicable(props),
        ]}
        {newLink.includes(props.item.data) && <FiberNewIcon color="error" />}
      </MenuItemLink>
    </Link>
  );
};

export default menuLinkRender;
