import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import { fetchAffiliationHoursCharts } from "./AffiliationHoursChartSlice";
import "hammerjs";
import { IppSkeleton } from "components/IppSkeleton";

export const AffiliationHoursChart = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const {
    affiliationHoursChartList,
    affiliationHoursChartsById,
    error,
    isLoading,
  } = useSelector((state: RootState) => state.affiliationHourCharts);

  const affiliationHoursData = affiliationHoursChartList.map(
    (id) => affiliationHoursChartsById[id]
  );

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchAffiliationHoursCharts(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, getAccessTokenSilently, dispatch]);

  // console.log("DATAzz", affiliationHoursChartList);

  const labelContent = (props: {
    dataItem: { value: any; IndigenousGroupName: any };
  }) => {
    return `${props.dataItem.IndigenousGroupName}`;
  };

  let affiliationHoursChartForm = isLoading ? (
    <IppSkeleton height={350} />
  ) : (
    <div>
      <Chart style={{ height: 350 }}>
        {/* <ChartTitle text="Hours by Affiliation (last 12 months)" /> */}

        <ChartLegend visible={false} />

        <ChartSeries>
          <ChartSeriesItem
            data={affiliationHoursData}
            type="pie"
            field="TotalHours"
            categoryField="IndigenousGroupName"
            labels={{ visible: true, content: labelContent, align: "column" }}
            tooltip={{ visible: true, format: "{0:n0}" }}
          />
        </ChartSeries>
      </Chart>
    </div>
  );

  return <div id="work-hour-chart-form">{affiliationHoursChartForm}</div>;
};
