import * as yup from "yup";
import { useTypedTranslation } from "utils/customHooks";

export const InteractionValidationSchema = () => {
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  return yup.object({
    InteractionTitle: yup.string().required(
      t("strGen:validation.required", {
        fieldname: t("objStk:objects.interaction.fields.title"),
      })
    ),
    InteractionDate: yup
      .date()
      .nullable()
      .typeError(t("strGen:validation.date.improperformat"))
      .required(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.interaction.fields.date"),
        })
      )
      .when("StatusTypeID", {
        is: 3,
        then: (validationSchema) =>
          validationSchema.max(
            new Date(),
            t("strGen:validation.date.nofuturewhencompleted", {
              fieldname: t("objStk:objects.interaction.fields.date"),
              fieldname2: t("objStk:objects.interaction.fields.status"),
            })
          ),
      }),
    InteractionTypeID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.interaction.fields.communicationtype"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.interaction.fields.communicationtype"),
        })
      ),
    StatusTypeID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.interaction.fields.status"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.interaction.fields.status"),
        })
      ),
    ProjectID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.interaction.fields.project"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.interaction.fields.project"),
        })
      ),
    PersonResponsible: yup
      .number()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.interaction.fields.personresponsible"),
        })
      )
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.interaction.fields.personresponsible"),
        })
      )
      .typeError(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.interaction.fields.personresponsible"),
        })
      ),
    InitiatedBy: yup.string().required(
      t("strGen:validation.required", {
        fieldname: t("objStk:objects.interaction.fields.initiatedby"),
      })
    ),
    ThreadID: yup.number().positive(
      t("strGen:validation.required", {
        fieldname: t("objStk:objects.interaction.fields.thread"),
      })
    ),

    SentimentLevel: yup.number().nullable(),
    Internal: yup
      .boolean()
      .nullable()
      .typeError(t("strGen:validation.invalidformat")),
  });
};
