import * as yup from "yup";
import { useTypedTranslation } from "utils/customHooks";

export const AssetValidationSchema = () => {
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  return yup.object({
    AssetName: yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.asset.fields.assetname"),
        })
      )
      .max(
        100,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.asset.fields.assetname"),
          count: 100,
        })
      ),
    OwnerPercentage: yup.number().required(
      t("strGen:validation.required", {
        fieldname: t("objBen:objects.asset.fields.ownerpercentage"),
      })
    ),
    AssetDetails: yup.string().trim().nullable(),
  });
};
