import axiosClient from "api/axiosClient";

export interface EnTExpense {
  EnTExpenseID: number;
  CourseName: string;
  InitiativeID: number;
  Date: Date;
  ExpenseAmount: number;
  Provider: string;
  Classification: string;
  Location: string;
  SequenceID: number;
  Description: string;
  CompanyID: number;
  AssetID?: number;
  Applied: boolean;
  AppliedDate: Date;
  SourceOfFunds: string;
  ClientID: number;
  AssetName?: string;
  InitiativeName?: string;
  ExpenseType: string;
  InEligible: boolean;
}

export interface EnTExpensesResult {
  entExpenses: EnTExpense[];
}

export async function getEnTExpenses(
  accessToken: String
): Promise<EnTExpensesResult> {
  const url = `/entexpense`;

  try {
    const { data } = await axiosClient.get<EnTExpensesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createEnTExpense(
  accessToken: String,
  newEnTExpense: Partial<EnTExpense>
): Promise<EnTExpense> {
  const url = `/entexpense`;

  try {
    const { data } = await axiosClient.post<EnTExpense>(url, newEnTExpense, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateEnTExpense(
  accessToken: String,
  EnTExpenseID: number,
  newEnTExpense: Partial<EnTExpense>
): Promise<EnTExpense> {
  const url = `/entexpense/` + EnTExpenseID;

  try {
    const { data } = await axiosClient.post<EnTExpense>(url, newEnTExpense, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteEnTExpense(
  accessToken: String,
  EnTExpenseID: number
): Promise<EnTExpense> {
  const url = `/entexpense/` + EnTExpenseID;

  try {
    const { data } = await axiosClient.delete<EnTExpense>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
