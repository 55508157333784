import * as yup from "yup";
import { useTypedTranslation } from "utils/customHooks";
import {
  startFields,
  incidentsFields,
  contractualFields,
  safetyFields,
  summaryFields,
} from "./SsheEditSteps";
import { maxDecimalPlaces } from "validation/validationFunctions";
interface FormField {
  [key: string]: string[];
}

const generateValidationSchema = (fieldsArrays: FormField[], t: any) => {
  const schema: any = {};
  for (const fields of fieldsArrays) {
    for (const fieldType in fields) {
      switch (fieldType) {
        case "number":
          for (const field of fields[fieldType]) {
            schema[field] = yup
              .number()
              .integer(
                t("strGen:validation.integer", {
                  fieldname: t(`objSshe:objects.form.fields.${field}`),
                })
              )
              .required(
                t("strGen:validation.required", {
                  fieldname: t(`objSshe:objects.form.fields.${field}`),
                })
              )
              .min(
                0,
                t("strGen:validation.nonNegative", {
                  fieldname: t(`objSshe:objects.form.fields.${field}`),
                })
              )
              .max(
                100000000,
                t("strGen:validation.maxNumber", {
                  fieldname: t(`objSshe:objects.form.fields.${field}`),
                  count: 100000000,
                })
              )
              .typeError(t("strGen:validation.invalidnumber"));
          }
          break;
        case "text":
          for (const field of fields[fieldType]) {
            schema[field] = yup.string().trim().nullable();
          }
          break;
        case "date":
          for (const field of fields[fieldType]) {
            if (field === "EndingDate") {
              schema["EndingDate"] = yup
                .date()
                .required(
                  t("strGen:validation.required", {
                    fieldname: t("objSshe:objects.form.fields.EndingDate"),
                  })
                )
                .max(
                  new Date(),
                  t("strGen:validation.date.nofuturedate", {
                    fieldname: t("objSshe:objects.form.fields.EndingDate"),
                  })
                )
                .typeError(t("strGen:validation.date.improperformat"));
            } else if (field === "CompletionDate") {
              schema["CompletionDate"] = yup
                .date()
                .required(
                  t("strGen:validation.required", {
                    fieldname: t("objSshe:objects.form.fields.CompletionDate"),
                  })
                )
                .min(
                  yup.ref("EndingDate"),
                  t("strGen:validation.date.notearlierthan", {
                    fieldname: t("objSshe:objects.form.fields.CompletionDate"),
                    fieldname2: t("objSshe:objects.form.fields.EndingDate"),
                  })
                )
                .typeError(t("strGen:validation.date.improperformat"));
            }
          }
          break;
        default:
          break;
      }
    }
  }

  return schema;
};

export const SsheValidationSchema = () => {
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);
  let schema = generateValidationSchema(
    [
      startFields,
      incidentsFields,
      contractualFields,
      safetyFields,
      summaryFields,
    ],
    t
  );
  schema["ProjectID"] = yup
    .number()
    .positive(
      t("strGen:validation.required", {
        fieldname: t("objPlt:objects.project.name"),
      })
    )
    .integer(
      t("strGen:validation.integer", {
        fieldname: t("objPlt:objects.project.name"),
      })
    )
    .required(
      t("strGen:validation.required", {
        fieldname: t("objPlt:objects.project.name"),
      })
    )
    .typeError(t("strGen:validation.invalidnumber"));
  schema["CompanyID"] = yup
    .number()
    .positive(
      t("strGen:validation.required", {
        fieldname: t("objSshe:objects.company.name"),
      })
    )
    .integer(
      t("strGen:validation.integer", {
        fieldname: t("objSshe:objects.company.name"),
      })
    )
    .required(
      t("strGen:validation.required", {
        fieldname: t("objSshe:objects.company.name"),
      })
    )
    .typeError(t("strGen:validation.invalidnumber"));
  // Hours max 2 decimals
  schema["ExposureHours"] = yup
    .number()
    .required(
      t("strGen:validation.required", {
        fieldname: t("objSshe:objects.form.fields.ExposureHours"),
      })
    )
    .min(
      0,
      t("strGen:validation.nonNegative", {
        fieldname: t("objSshe:objects.form.fields.ExposureHours"),
      })
    )
    .max(
      100000000,
      t("strGen:validation.maxNumber", {
        fieldname: t("objSshe:objects.form.fields.ExposureHours"),
        count: 100000000,
      })
    )
    .test(
      "max-two-decimal-places",
      t("strGen:validation.maxdecimals", {
        fieldname: t("objSshe:objects.form.fields.ExposureHours"),
        count: 2,
      }),
      function (value) {
        return maxDecimalPlaces(value, 2);
      }
    )
    .typeError(
      t("strGen:validation.typeerror.number", {
        fieldname: t("objSshe:objects.form.fields.ExposureHours"),
      })
    );
  schema["ExposureHoursSubcontractor"] = yup
    .number()
    .required(
      t("strGen:validation.required", {
        fieldname: t("objSshe:objects.form.fields.ExposureHoursSubcontractor"),
      })
    )
    .min(
      0,
      t("strGen:validation.nonNegative", {
        fieldname: t("objSshe:objects.form.fields.ExposureHoursSubcontractor"),
      })
    )
    .max(
      100000000,
      t("strGen:validation.maxNumber", {
        fieldname: t("objSshe:objects.form.fields.ExposureHoursSubcontractor"),
        count: 100000000,
      })
    )
    .test(
      "max-two-decimal-places",
      t("strGen:validation.maxdecimals", {
        fieldname: t("objSshe:objects.form.fields.ExposureHoursSubcontractor"),
        count: 2,
      }),
      function (value) {
        return maxDecimalPlaces(value, 2);
      }
    )
    .typeError(
      t("strGen:validation.typeerror.number", {
        fieldname: t("objSshe:objects.form.fields.ExposureHoursSubcontractor"),
      })
    );
  return yup.object().shape(schema);
};
