import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CommitmentCategory,
  CommitmentCategoriesResult,
  getCommitmentCategories,
  createCommitmentCategory,
  updateCommitmentCategory,
  deleteCommitmentCategory,
} from "api/commitments/commitmentCategoryAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface CommitmentCategoryState {
  commitmentCategoriesById: Record<number, CommitmentCategory>;
  commitmentCategoryList: number[];
  isLoading: boolean;
  isLoadingAdd: boolean;
  subCommitmentCategoryisLoading: boolean;
  isLoaded: boolean;
  error: string | null;
}

const CommitmentCategoryInitialState: CommitmentCategoryState = {
  commitmentCategoriesById: {},
  commitmentCategoryList: [],
  isLoading: false,
  isLoadingAdd: false,
  subCommitmentCategoryisLoading: false,
  isLoaded: false,
  error: null,
};

function startLoading(state: CommitmentCategoryState) {
  state.isLoading = true;
}

function startLoadingAdd(state: CommitmentCategoryState) {
  state.isLoadingAdd = true;
}

function loadingFailed(
  state: CommitmentCategoryState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const commitmentCategories = createSlice({
  name: "commitmentCategories",
  initialState: CommitmentCategoryInitialState,
  reducers: {
    getCommitmentCategoriesStart: startLoading,
    getCommitmentCategoriesSuccess(
      state,
      { payload }: PayloadAction<CommitmentCategoriesResult>
    ) {
      const { commitmentCategories } = payload;

      state.isLoading = false;
      state.error = null;
      state.isLoaded = true;

      commitmentCategories.forEach((commitmentCategory: any) => {
        state.commitmentCategoriesById[
          commitmentCategory.CommitmentCategoryID
        ] = commitmentCategory;
      });

      state.commitmentCategoryList = commitmentCategories.map(
        (commitmentCategory: any) => commitmentCategory.CommitmentCategoryID
      );
    },
    getCommitmentCategoriesFailure: loadingFailed,
    createCommitmentCategoryStart: startLoadingAdd,
    createCommitmentCategorySuccess(
      state,
      { payload }: PayloadAction<CommitmentCategory>
    ) {
      const { CommitmentCategoryID } = payload;
      state.commitmentCategoriesById[CommitmentCategoryID] = payload;
      state.commitmentCategoryList.push(CommitmentCategoryID);

      state.isLoading = false;
      state.isLoadingAdd = false;
      state.error = null;
    },
    updateCommitmentCategorySuccess(
      state,
      { payload }: PayloadAction<CommitmentCategory>
    ) {
      const { CommitmentCategoryID } = payload;

      state.commitmentCategoriesById[CommitmentCategoryID] = payload;
      //state.commitmentCategoryList.push(CommitmentCategoryID);

      state.isLoading = false;
      state.error = null;
    },
    deleteCommitmentCategorySuccess(state, { payload }: PayloadAction<number>) {
      const CommitmentCategoryID = payload;
      delete state.commitmentCategoriesById[CommitmentCategoryID];
      state.commitmentCategoryList = state.commitmentCategoryList.filter(
        (item) => item !== CommitmentCategoryID
      );

      state.isLoading = false;
      state.error = null;
    },
    createCommitmentCategoryFailure: loadingFailed,
  },
});

export const {
  getCommitmentCategoriesStart,
  getCommitmentCategoriesSuccess,
  getCommitmentCategoriesFailure,
  createCommitmentCategoryStart,
  createCommitmentCategorySuccess,
  updateCommitmentCategorySuccess,
  deleteCommitmentCategorySuccess,
  createCommitmentCategoryFailure,
} = commitmentCategories.actions;

export default commitmentCategories.reducer;

export const fetchCommitmentCategories =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCommitmentCategoriesStart());
      const commitmentCategorys = await getCommitmentCategories(accessToken);
      dispatch(getCommitmentCategoriesSuccess(commitmentCategorys));
    } catch (err: any) {
      dispatch(getCommitmentCategoriesFailure(err.toString()));
    }
  };

export const addCommitmentCategory =
  (
    accessToken: String,
    newCommitmentCategory: Partial<CommitmentCategory>,
    setReturnRoute: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCommitmentCategoryStart());
      const commitmentCategory = await createCommitmentCategory(
        accessToken,
        newCommitmentCategory
      );
      dispatch(createCommitmentCategorySuccess(commitmentCategory));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(
          push(
            `/commitments/commitmentCategories/${commitmentCategory.CommitmentCategoryID}`
          )
        );
      }
    } catch (err: any) {
      dispatch(createCommitmentCategoryFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updCommitmentCategory =
  (
    accessToken: String,
    commitmentCategoryID: number,
    newCommitmentCategory: Partial<CommitmentCategory>,
    setReturnRoute: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCommitmentCategoryStart());
      const commitmentCategory = await updateCommitmentCategory(
        accessToken,
        commitmentCategoryID,
        newCommitmentCategory
      );

      dispatch(updateCommitmentCategorySuccess(commitmentCategory));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(
          push("/commitments/commitmentCategories/" + commitmentCategoryID)
        );
      }
    } catch (err: any) {
      dispatch(createCommitmentCategoryFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delCommitmentCategory =
  (accessToken: String, commitmentCategoryID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCommitmentCategoryStart());
      const result = await deleteCommitmentCategory(
        accessToken,
        commitmentCategoryID
      );
      dispatch(deleteCommitmentCategorySuccess(commitmentCategoryID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
      dispatch(push("/commitments/commitmentCategories"));
    } catch (err: any) {
      dispatch(createCommitmentCategoryFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };
