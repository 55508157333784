import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  InteractionType,
  InteractionTypesResult,
  getInteractionTypes,
} from "api/stakeholder/interactionTypeAPI";
import { AppThunk } from "app/store";

interface InteractionTypeState {
  interactionTypesById: Record<number, InteractionType>;
  interactionTypeList: number[];
  isLoading: boolean;
  error: string | null;
}

const InteractionTypeInitialState: InteractionTypeState = {
  interactionTypesById: {},
  interactionTypeList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: InteractionTypeState) {
  state.isLoading = true;
}

function loadingFailed(
  state: InteractionTypeState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const interactionTypes = createSlice({
  name: "interactionType",
  initialState: InteractionTypeInitialState,
  reducers: {
    getInteractionTypesStart: startLoading,
    getInteractionTypesSuccess(
      state,
      { payload }: PayloadAction<InteractionTypesResult>
    ) {
      const { interactionTypes } = payload;
      state.isLoading = false;
      state.error = null;

      interactionTypes.forEach((interactionType) => {
        state.interactionTypesById[interactionType.InteractionTypeID] =
          interactionType;
      });

      state.interactionTypeList = interactionTypes.map(
        (interactionType) => interactionType.InteractionTypeID
      );
    },
    getInteractionTypesFailure: loadingFailed,
  },
});

export const {
  getInteractionTypesStart,
  getInteractionTypesSuccess,
  getInteractionTypesFailure,
} = interactionTypes.actions;

export default interactionTypes.reducer;

export const fetchInteractionTypes =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getInteractionTypesStart());
      const interactionTypes = await getInteractionTypes(accessToken);
      dispatch(getInteractionTypesSuccess(interactionTypes));
    } catch (err: any) {
      dispatch(getInteractionTypesFailure(err.toString()));
    }
  };
