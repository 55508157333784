import { PaymentPeriodForm } from "./PaymentPeriodForm";

export const PaymentPeriodCreatePage = () => {
    let paymentPeriodCreateView = (
        <div>
            <PaymentPeriodForm />
        </div>
    )

    return <div id="payment-period-detail-page"> {paymentPeriodCreateView} </div>
}