import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  StakeholderLog,
  StakeholderLogsResult,
  getStakeholderLogs,
} from "api/stakeholder/logTables/stakeholderLogAPI";
import { AppThunk } from "app/store";

interface StakeholderLogState {
  stakeholderLogsById: Record<number, StakeholderLog>;
  stakeholderLogList: number[];
  isLoading: boolean;
  error: string | null;
}

const StakeholderLogInitialState: StakeholderLogState = {
  stakeholderLogsById: {},
  stakeholderLogList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: StakeholderLogState) {
  state.isLoading = true;
}

function loadingFailed(
  state: StakeholderLogState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const stakeholderLogs = createSlice({
  name: "stakeholderLogs",
  initialState: StakeholderLogInitialState,
  reducers: {
    getStakeholderLogsStart: startLoading,
    getStakeholderLogsSuccess(
      state,
      { payload }: PayloadAction<StakeholderLogsResult>
    ) {
      const { stakeholderLogs } = payload;
      state.isLoading = false;
      state.error = null;

      stakeholderLogs.forEach((item) => {
        state.stakeholderLogsById[item.GroupID] = item;
      });

      state.stakeholderLogList = stakeholderLogs.map((item) => item.GroupID);
    },
    getStakeholderLogsFailure: loadingFailed,
  },
});

export const {
  getStakeholderLogsStart,
  getStakeholderLogsSuccess,
  getStakeholderLogsFailure,
} = stakeholderLogs.actions;

export default stakeholderLogs.reducer;

export const fetchStakeholderLogs =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getStakeholderLogsStart());
      const stakeholderLogs = await getStakeholderLogs(accessToken);
      dispatch(getStakeholderLogsSuccess(stakeholderLogs));
    } catch (err: any) {
      dispatch(getStakeholderLogsFailure(err.toString()));
    }
  };
