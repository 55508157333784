import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CustomEmpCategory,
  CustomEmpCategoriesResult,
  getCustomEmpCategories,
  createCustomEmpCategory,
  updateCustomEmpCategory,
  deleteCustomEmpCategory,
} from "api/dataList/customEmpCategoryAPI";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { push } from "redux-first-history";

interface CustomEmpCategoryState {
  customEmpCategoriesById: Record<number, CustomEmpCategory>;
  customEmpCategoryList: number[];
  isLoading: boolean;
  error: string | null;
}

const CustomEmpCategoryInitialState: CustomEmpCategoryState = {
  customEmpCategoriesById: {},
  customEmpCategoryList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: CustomEmpCategoryState) {
  state.isLoading = true;
}

function loadingFailed(
  state: CustomEmpCategoryState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const customEmpCategories = createSlice({
  name: "customEmpCategories",
  initialState: CustomEmpCategoryInitialState,
  reducers: {
    getCustomEmpCategoriesStart: startLoading,
    getCustomEmpCategoriesSuccess(
      state,
      { payload }: PayloadAction<CustomEmpCategoriesResult>
    ) {
      const { customEmpCategories } = payload;
      state.isLoading = false;
      state.error = null;

      customEmpCategories.forEach((customEmpCategory) => {
        state.customEmpCategoriesById[customEmpCategory.CustomEmpCategoryID] =
          customEmpCategory;
      });

      state.customEmpCategoryList = customEmpCategories.map(
        (customEmpCategory) => customEmpCategory.CustomEmpCategoryID
      );
    },
    getCustomEmpCategoriesFailure: loadingFailed,
    createCustomEmpCategoryStart: startLoading,
    createCustomEmpCategorySuccess(
      state,
      { payload }: PayloadAction<CustomEmpCategory>
    ) {
      const { CustomEmpCategoryID } = payload;
      state.customEmpCategoriesById[CustomEmpCategoryID] = payload;
      state.customEmpCategoryList.push(CustomEmpCategoryID);

      state.isLoading = false;
      state.error = null;
    },
    updateCustomEmpCategorySuccess(
      state,
      { payload }: PayloadAction<CustomEmpCategory>
    ) {
      const { CustomEmpCategoryID } = payload;
      state.customEmpCategoriesById[CustomEmpCategoryID] = payload;
      state.customEmpCategoryList.push(CustomEmpCategoryID);

      state.isLoading = false;
      state.error = null;
    },
    createCustomEmpCategoryFailure: loadingFailed,
  },
});

export const {
  getCustomEmpCategoriesStart,
  getCustomEmpCategoriesSuccess,
  getCustomEmpCategoriesFailure,
  createCustomEmpCategoryStart,
  createCustomEmpCategorySuccess,
  updateCustomEmpCategorySuccess,
  createCustomEmpCategoryFailure,
} = customEmpCategories.actions;

export default customEmpCategories.reducer;

export const fetchCustomEmpCategories =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCustomEmpCategoriesStart());
      const customEmpCategories = await getCustomEmpCategories(accessToken);
      dispatch(getCustomEmpCategoriesSuccess(customEmpCategories));
    } catch (err: any) {
      dispatch(getCustomEmpCategoriesFailure(err.toString()));
    }
  };

export const addCustomEmpCategory =
  (
    accessToken: String,
    newCustomEmpCategory: Partial<CustomEmpCategory>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCustomEmpCategoryStart());
      const customEmpCategory = await createCustomEmpCategory(
        accessToken,
        newCustomEmpCategory
      );
      dispatch(createCustomEmpCategorySuccess(customEmpCategory));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push(`/admin/customEmpCategories`));
    } catch (err: any) {
      dispatch(createCustomEmpCategoryFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updCustomEmpCategory =
  (
    accessToken: String,
    customEmpCategoryID: number,
    newCustomEmpCategory: Partial<CustomEmpCategory>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCustomEmpCategoryStart());
      const customEmpCategory = await updateCustomEmpCategory(
        accessToken,
        customEmpCategoryID,
        newCustomEmpCategory
      );
      dispatch(updateCustomEmpCategorySuccess(customEmpCategory));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push("/admin/customEmpCategories"));
    } catch (err: any) {
      dispatch(createCustomEmpCategoryFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delCustomEmpCategory =
  (accessToken: String, customEmpCategoryID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCustomEmpCategoryStart());
      const customEmpCategories = await deleteCustomEmpCategory(
        accessToken,
        customEmpCategoryID
      );
      dispatch(getCustomEmpCategoriesSuccess(customEmpCategories));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createCustomEmpCategoryFailure(err.toString()));
    }
  };
