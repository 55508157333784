import axiosClient from "api/axiosClient";
import { DateAsString } from "utils/DateFunctions";

export interface VendorSocioEconSpend {
  ContractID: number;
  ContractNumber: string;
  ContractTitle: string;
  ContractTarget: number;
  ProjectTarget: number;
  IndigenousSpend: number;
  TotalSpend: number;
}

export interface VendorSocioEconSpendResult {
  vendorSocioEconSpend: VendorSocioEconSpend[];
}

export async function getVendorSocioEconSpend(
  accessToken: String,
  startDate: Date,
  endDate: Date,
  regionID: number,
  projectID: number
): Promise<VendorSocioEconSpendResult> {
  const start = DateAsString(startDate);
  const end = DateAsString(endDate);
  const url = `/charts/socioeconspend/${start}/${end}/${regionID}/${projectID}`;
  try {
    const { data } = await axiosClient.get<VendorSocioEconSpendResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
