import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";
import { fetchContacts, mrgContact } from "./ContactSlice";
import { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { IppDisplayField } from "components/IppDisplayField";
import { IppSkeleton } from "components/IppSkeleton";
import { IppRating } from "components/IppRating";
import { IppTextField } from "components/IppTextField";
import { IppAutocomplete } from "components/IppAutocomplete";
import { ValidationSchema } from "./ContactValidation";
import { fetchUsers } from "features/users/UsersSlice";
import { User } from "api/usersAPI";
import { UserRole } from "api/userRoleAPI";
import { ClientModule } from "api/clientModuleAPI";
import { Contact } from "api/stakeholder/contactAPI";
import { IppMultiTextField } from "components/IppMultiTextField";
import { useSnackBarConstants, useTypedTranslation } from "utils/customHooks";
import { IppDisplayChip } from "components/IppDisplayChip";
import { fetchTagsByRecordType } from "../admin/tag/TagSlice";
import { IppTokenMultiSelect } from "components/IppTokenMultiSelect";
import { PersonRenderOption } from "utils/renderFunctions";
import { mergeProximityIndicatorLSDs } from "utils/functions";
import { IppLocationAutoCompleteMerge } from "components/IppLocationAutoCompleteMerge";
import { IppSubmitButton } from "components/Buttons/IppSubmitButton";
import { fetchClientInteractionTypes } from "../admin/clientInteractionTypes/ClientInteractionTypeSlice";
import { selectAllUserRoles } from "features/roles/UserRoleSlice";

interface ContactMergeProps {
  originalID: string;
  newID: string;
}

const PREFIX = "ContactMerge";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    // minWidth: 650,
    maxWidth: 1150,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const ContactMergePage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen"]);
  const snackbarConstants = useSnackBarConstants();
  const [validationOK, setValidationOK] = useState(true);

  const { originalID, newID } = useParams<ContactMergeProps>();
  const originalID_int = parseInt(originalID, 10);
  const newID_int = parseInt(newID, 10);
  if (typeof originalID_int != "number" && typeof newID_int != "number") {
    setValidationOK(false);
  }

  const {
    clientId,
    ft_eng_lvlOfEngagement,
    ft_all_Tag,
    ft_eng_PILSD,
    ft_eng_PreferredCommunicationMethod,
  } = useSelector((state: RootState) => state.client);

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const {
    contactList,
    contactsById,
    isLoading: contactIsLoading,
  } = useSelector((state: RootState) => state.contacts);

  const contacts = contactList.map((id) => contactsById[id]);

  const { clientModuleList, clientModulesById } = useSelector(
    (state: RootState) => state.clientModules
  );

  const clientmodules = clientModuleList.map((id) => clientModulesById[id]);

  const { userList, usersById } = useSelector(
    (state: RootState) => state.users
  );

  const userRoles = useSelector((state: RootState) =>
    selectAllUserRoles(state)
  );

  // Filter for active users only
  const allUsers = userList.map((id) => usersById[id]);
  let users = allUsers.filter((item: User) => {
    if (!item.IsActive || item.CompanyID != currentProfile.CompanyID)
      return false;
    if (item.IsClientAdmin) {
      return true;
    } else {
      let found = userRoles.filter((role) => {
        return item.UserAccountID === role.UserAccountID;
      });
      if (found && found.length > 0) {
        let check = clientmodules.find((object: ClientModule) => {
          let clientMod = found.find((mod: UserRole) => {
            return (
              object.ClientModuleID === mod.ClientModuleID &&
              (object.ModuleID === 3 || object.ModuleID === 2)
            );
          });
          return clientMod;
        });
        return check;
      } else return false;
    }
  });

  const {
    tagList,
    tagsById,
    isLoading: tagIsLoading,
  } = useSelector((state: RootState) => state.tag);

  const tags = tagList.map((id) => tagsById[id]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        if (contacts.length === 0) {
          dispatch(fetchContacts(accessToken));
          dispatch(fetchUsers(accessToken, clientId));
        }
        if (ft_all_Tag) {
          dispatch(fetchTagsByRecordType(accessToken, "Contact"));
        }
        if (ft_eng_PreferredCommunicationMethod) {
          dispatch(fetchClientInteractionTypes(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [contacts.length, dispatch, getAccessTokenSilently]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        if (ft_eng_PILSD) {
          values = {
            ...values,
            ProximityIndicatorLSDs: values.ProximityIndicatorLSDs?.filter(
              (pilsd: any) =>
                pilsd.ProximityIndicator !== "" || pilsd.LegalSubdivision !== ""
            ),
          };
        }
        dispatch(
          mrgContact(
            accessToken,
            originalID_int,
            values,
            newID_int,
            true,
            snackbarConstants
          )
        );
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const oldContact: Contact = contactsById[Number(originalID_int)];
  const dupeContact: Contact = contactsById[Number(newID_int)];
  const mergeContact: { [key: string]: any } = { ...oldContact };

  for (const [key, value] of Object.entries(mergeContact)) {
    if (!value) {
      mergeContact[key] = dupeContact[key as keyof Contact];
    }
    if (key === "Tags" && oldContact.Tags && dupeContact.Tags) {
      // merge oldContact Tags and dupeContact Tags - split both into arrays and concatenate them, then check for duplicates before joining them back into a string
      mergeContact[key] = oldContact.Tags.split(",")
        .concat(dupeContact.Tags.split(","))
        .filter((item, index, self) => self.indexOf(item) === index)
        .join(",");
    }
    if (key === "Email" && oldContact.Email && dupeContact.Email) {
      mergeContact[key] = oldContact.Email.split(",")
        .concat(dupeContact.Email.split(","))
        .filter((item, index, self) => self.indexOf(item) === index)
        .join(",");
    }
    if (
      key === "ProximityIndicatorLSDs" &&
      (oldContact.ProximityIndicatorLSDs || dupeContact.ProximityIndicatorLSDs)
    ) {
      mergeContact[key] = mergeProximityIndicatorLSDs(
        oldContact?.ProximityIndicatorLSDs,
        dupeContact?.ProximityIndicatorLSDs
      );
    }
  }

  const strictMergeContact: Contact = mergeContact as Contact;

  const [tagItems, setTagItems] = useState<any>(
    strictMergeContact.Tags ? strictMergeContact.Tags : undefined
  );

  // convert tags to objects for multi autocomplete field
  useEffect(() => {
    // effect
    if (!tagIsLoading && strictMergeContact?.Tags) {
      let newList = tags.filter((item) =>
        strictMergeContact?.Tags?.includes(item.Name)
      );
      setTagItems(newList);
    }
  }, [strictMergeContact?.Tags, tagIsLoading]);

  const getTagsClicked = (type: string) => {
    if (type === "old") return oldContact?.Tags;
    if (type === "dupe") return dupeContact?.Tags;
    return "";
  };

  const {
    // Preferred method of contact feature
    clientInteractionTypesById,
    clientInteractionTypeList,
    isLoading: typeIsLoading,
  } = useSelector((state: RootState) => state.clientInteractionTypes);

  const clientInteractionTypes = clientInteractionTypeList.map(
    (id) => clientInteractionTypesById[id]
  );

  const handleArrowClick = (direction: string) => {
    const contactTags = getTagsClicked(direction);
    const newList = contactTags
      ? tags.filter((item) => contactTags.includes(item.Name))
      : [];
    setTagItems(newList);
    formik.setFieldValue("Tags", contactTags);
  };

  const formik = useFormik({
    initialValues: strictMergeContact,
    validationSchema: ValidationSchema(),
    onSubmit: onSub,
  });

  let formView =
    contactIsLoading || !oldContact || !formik.values || tagIsLoading ? (
      <IppSkeleton height={400} />
    ) : (
      <form onSubmit={formik.handleSubmit}>
        <Paper elevation={6} className={classes.boxSpace}>
          <Box padding={1} sx={{ borderRadius: 1 }}>
            <Grid container spacing={0}>
              <Grid item xs={12} container justifyContent="flex-end">
                <IppSubmitButton buttonText={t("strGen:buttons.save")} />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      {t("strGen:pages.merge.originalvalues", {
                        fieldname: t("objPlt:objects.contact.name"),
                      })}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={8}>
                    <Typography variant="h6" gutterBottom>
                      {t("strGen:pages.merge.mergedvalues", {
                        fieldname: t("objPlt:objects.contact.name"),
                      })}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      {t("strGen:pages.merge.duplicatevalues", {
                        fieldname: t("objPlt:objects.contact.name"),
                      })}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/*  grid block */}
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label={t("objPlt:objects.contact.fields.firstname")}
                      value={oldContact.FirstName}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue("FirstName", oldContact.FirstName)
                      }
                    >
                      <ArrowRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <IppTextField
                  id="FirstName"
                  label={t("objPlt:objects.contact.fields.firstname")}
                  required={false}
                  value={formik.values.FirstName}
                  onChangeFunction={formik.handleChange}
                  touchedExpression={formik.touched.FirstName}
                  errorsExpression={formik.errors.FirstName}
                  isEditing={true}
                  setIsEditing={null}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue("FirstName", dupeContact.FirstName)
                      }
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label={t("objPlt:objects.contact.fields.firstname")}
                      value={dupeContact.FirstName}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label={t("objPlt:objects.contact.fields.surname")}
                      value={oldContact.Surname}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue("Surname", oldContact.Surname)
                      }
                    >
                      <ArrowRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <IppTextField
                  id="Surname"
                  label={t("objPlt:objects.contact.fields.surname")}
                  required={true}
                  value={formik.values.Surname}
                  onChangeFunction={formik.handleChange}
                  touchedExpression={formik.touched.Surname}
                  errorsExpression={formik.errors.Surname}
                  isEditing={true}
                  setIsEditing={null}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue("Surname", dupeContact.Surname)
                      }
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label={t("objPlt:objects.contact.fields.surname")}
                      value={dupeContact.Surname}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label={t("objPlt:objects.contact.fields.title")}
                      value={oldContact.ContactTitle}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "ContactTitle",
                          oldContact.ContactTitle
                        )
                      }
                    >
                      <ArrowRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <IppTextField
                  id="ContactTitle"
                  label={t("objPlt:objects.contact.fields.title")}
                  required={false}
                  value={formik.values.ContactTitle}
                  onChangeFunction={formik.handleChange}
                  touchedExpression={formik.touched.ContactTitle}
                  errorsExpression={formik.errors.ContactTitle}
                  isEditing={true}
                  setIsEditing={null}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "ContactTitle",
                          dupeContact.ContactTitle
                        )
                      }
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label={t("objPlt:objects.contact.fields.title")}
                      value={dupeContact.ContactTitle}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label={t("objPlt:objects.contact.fields.phoneprimary")}
                      value={oldContact.PrimaryPhone}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "PrimaryPhone",
                          oldContact.PrimaryPhone
                        )
                      }
                    >
                      <ArrowRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <IppTextField
                  id="PrimaryPhone"
                  label={t("objPlt:objects.contact.fields.phoneprimary")}
                  required={false}
                  value={formik.values.PrimaryPhone}
                  onChangeFunction={formik.handleChange}
                  touchedExpression={formik.touched.PrimaryPhone}
                  errorsExpression={formik.errors.PrimaryPhone}
                  isEditing={true}
                  setIsEditing={null}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "PrimaryPhone",
                          dupeContact.PrimaryPhone
                        )
                      }
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label={t("objPlt:objects.contact.fields.phoneprimary")}
                      value={dupeContact.PrimaryPhone}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label={t("objPlt:objects.contact.fields.phonealternate")}
                      value={oldContact.AltPhone}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue("AltPhone", oldContact.AltPhone)
                      }
                    >
                      <ArrowRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <IppTextField
                  id="AltPhone"
                  label={t("objPlt:objects.contact.fields.phonealternate")}
                  required={false}
                  value={formik.values.AltPhone}
                  onChangeFunction={formik.handleChange}
                  touchedExpression={formik.touched.AltPhone}
                  errorsExpression={formik.errors.AltPhone}
                  isEditing={true}
                  setIsEditing={null}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue("AltPhone", dupeContact.AltPhone)
                      }
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label={t("objPlt:objects.contact.fields.phonealternate")}
                      value={dupeContact.AltPhone}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <IppDisplayChip
                      label={t("objPlt:objects.contact.fields.emailaddress")}
                      value={oldContact.Email}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue("Email", oldContact.Email)
                      }
                    >
                      <ArrowRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <IppMultiTextField
                  id="Email"
                  label={t("objPlt:objects.contact.fields.emailaddress")}
                  required={false}
                  options={[]}
                  noOptionsText={t("strGen:components.nooptionstext")}
                  value={formik.values.Email}
                  setValue={(newValue: any) =>
                    formik.setFieldValue("Email", newValue.toString())
                  }
                  touchedFunction={formik.touched.Email}
                  errorFunction={formik.errors.Email}
                  isEditing={true}
                  setIsEditing={null}
                  multiple={true}
                  autoPopulate={false}
                  freeSolo={true}
                  emailSpaceToken={true}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue("Email", dupeContact.Email)
                      }
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <IppDisplayChip
                      label={t("objPlt:objects.contact.fields.emailaddress")}
                      value={dupeContact.Email}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {ft_eng_PreferredCommunicationMethod && (
                <>
                  {/* Original Contact - Preferred Communication Method */}
                  <Grid item xs={4}>
                    <Grid container spacing={0}>
                      <Grid item xs={10}>
                        <IppDisplayField
                          label={t(
                            "objPlt:objects.contact.fields.preferredcommunicationmethod"
                          )}
                          value={
                            oldContact.InteractionTypeName || "Not Specified"
                          }
                          isEditing={false}
                          setIsEditing={null}
                          showEdit={false}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() =>
                            formik.setFieldValue(
                              "ContactMethodID",
                              oldContact.ContactMethodID
                            )
                          }
                        >
                          <ArrowRight />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Merge Contact - Preferred Communication Method */}
                  <Grid item xs={4}>
                    <IppAutocomplete
                      id="ContactMethodID"
                      options={clientInteractionTypes}
                      value={clientInteractionTypes.find((obj) => {
                        return (
                          obj.InteractionTypeID ===
                          formik.values.ContactMethodID
                        );
                      })}
                      onChangeFunction={(event: ChangeEvent, newValue: any) => {
                        if (newValue) {
                          formik.setFieldValue(
                            "ContactMethodID",
                            newValue.InteractionTypeID
                          );
                        } else {
                          formik.setFieldValue("ContactMethodID", -1);
                        }
                      }}
                      label="Preferred Communication Method"
                      isEditing={true}
                      setIsEditing={null}
                      optionLabelFunction={(option: any) =>
                        option.InteractionTypeName
                      }
                      errorFunction={formik.errors.ContactMethodID}
                      touchedFunction={formik.touched.ContactMethodID}
                      textValueFunction={formik.values.ContactMethodID}
                    />
                  </Grid>

                  {/* Duplicate Contact - Preferred Communication Method */}
                  <Grid item xs={4}>
                    <Grid container spacing={0}>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() =>
                            formik.setFieldValue(
                              "ContactMethodID",
                              dupeContact.ContactMethodID
                            )
                          }
                        >
                          <ArrowLeft />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10}>
                        <IppDisplayField
                          label={t(
                            "objPlt:objects.contact.fields.preferredcommunicationmethod"
                          )}
                          value={
                            dupeContact.InteractionTypeName || "Not Specified"
                          }
                          isEditing={false}
                          setIsEditing={null}
                          showEdit={false}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label={t(
                        "objPlt:objects.contact.fields.relationshipowner"
                      )}
                      value={
                        usersById && usersById[oldContact.RelationshipOwner]
                          ? usersById[oldContact.RelationshipOwner].FirstName +
                            " " +
                            usersById[oldContact.RelationshipOwner].Surname
                          : ""
                      }
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "RelationshipOwner",
                          oldContact.RelationshipOwner
                        )
                      }
                    >
                      <ArrowRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <IppAutocomplete
                  id="RelationshipOwner"
                  options={users}
                  renderOption={(props: any, option: User) =>
                    PersonRenderOption(props, option, [option.EmailAddress], "")
                  }
                  value={
                    allUsers.find((obj) => {
                      return (
                        obj.UserAccountID === formik.values.RelationshipOwner
                      );
                    }) || null
                  }
                  textValueFunction={
                    usersById && usersById[formik.values.RelationshipOwner]
                      ? usersById[formik.values.RelationshipOwner].FirstName +
                        " " +
                        usersById[formik.values.RelationshipOwner].Surname
                      : ""
                  }
                  onChangeFunction={(event: ChangeEvent, newValue: any) => {
                    if (newValue) {
                      formik.setFieldValue(
                        "RelationshipOwner",
                        newValue.UserAccountID
                      );
                    } else {
                      formik.setFieldValue("RelationshipOwner", null);
                    }
                  }}
                  optionLabelFunction={(option: any) =>
                    option.FirstName + " " + option.Surname
                  }
                  touchedFunction={formik.touched.RelationshipOwner}
                  errorFunction={formik.errors.RelationshipOwner}
                  label={t("objPlt:objects.contact.fields.relationshipowner")}
                  isEditing={true}
                  setIsEditing={null}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "RelationshipOwner",
                          dupeContact.RelationshipOwner
                        )
                      }
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label={t(
                        "objPlt:objects.contact.fields.relationshipowner"
                      )}
                      value={
                        usersById && usersById[dupeContact.RelationshipOwner]
                          ? usersById[dupeContact.RelationshipOwner].FirstName +
                            " " +
                            usersById[dupeContact.RelationshipOwner].Surname
                          : ""
                      }
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label={t("objPlt:objects.contact.fields.notes")}
                      value={oldContact.Notes}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue("Notes", oldContact.Notes)
                      }
                    >
                      <ArrowRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <IppTextField
                  id="Notes"
                  label={t("objPlt:objects.contact.fields.notes")}
                  value={formik.values.Notes}
                  onChangeFunction={formik.handleChange}
                  errorsExpression={formik.errors.Notes}
                  touchedExpression={formik.touched.Notes}
                  isEditing={true}
                  setIsEditing={null}
                  multiLine={true}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue("Notes", dupeContact.Notes)
                      }
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label={t("objPlt:objects.contact.fields.notes")}
                      value={dupeContact.Notes}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <IppLocationAutoCompleteMerge
                formik={formik}
                oldValues={oldContact}
                dupeValues={dupeContact}
                parentTitle="Contact"
              />

              {ft_all_Tag && (
                <>
                  <Grid item xs={4}>
                    <Grid container spacing={0}>
                      <Grid item xs={10}>
                        <IppDisplayChip
                          label={t("objPlt:objects.contact.fields.tag", {
                            count: oldContact.Tags ? oldContact.Tags.length : 1,
                          })}
                          value={oldContact.Tags}
                          isEditing={false}
                          setIsEditing={null}
                          showEdit={false}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton onClick={() => handleArrowClick("old")}>
                          <ArrowRight />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <IppTokenMultiSelect
                      id="Tags"
                      label={t("objPlt:objects.contact.fields.tag", {
                        count: formik.values.Tags
                          ? formik.values.Tags.length
                          : 1,
                      })}
                      required={false}
                      options={tags}
                      noOptionsText={t("strGen:components.nooptionstext")}
                      value={tagItems}
                      setValue={(newValue: any) => {
                        setTagItems(newValue);
                        formik.setFieldValue("Tags", newValue);
                      }}
                      touchedFunction={formik.touched.Tags}
                      errorFunction={formik.errors.Tags}
                      isEditing={true}
                      optionLabelFunction={(option: any) => option.Name}
                      setIsEditing={null}
                      toolTip={t("strGen:tooltips.tags")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={0}>
                      <Grid item xs={2}>
                        <IconButton onClick={() => handleArrowClick("dupe")}>
                          <ArrowLeft />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10}>
                        <IppDisplayChip
                          label={t("objPlt:objects.contact.fields.tag", {
                            count: dupeContact.Tags
                              ? dupeContact.Tags.length
                              : 1,
                          })}
                          value={dupeContact.Tags}
                          isEditing={false}
                          setIsEditing={null}
                          showEdit={false}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}

              {ft_eng_lvlOfEngagement && (
                <>
                  <Grid item xs={4}>
                    <Grid container spacing={0}>
                      <Grid item xs={10}>
                        <IppRating
                          id="LevelOfInfluence"
                          label={t(
                            "objPlt:objects.contact.fields.levelofinfluence"
                          )}
                          value={oldContact.LevelOfInfluence}
                          onChangeFunction={null}
                          isEditing={false}
                          setIsEditing={null}
                          showEditButton={false}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() =>
                            formik.setFieldValue(
                              "LevelOfInfluence",
                              oldContact.LevelOfInfluence
                            )
                          }
                        >
                          <ArrowRight />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <IppRating
                      id="LevelOfInfluence"
                      label={t(
                        "objPlt:objects.contact.fields.levelofinfluence"
                      )}
                      value={formik.values.LevelOfInfluence}
                      onChangeFunction={formik.handleChange}
                      isEditing={true}
                      setIsEditing={null}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={0}>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() =>
                            formik.setFieldValue(
                              "LevelOfInfluence",
                              dupeContact.LevelOfInfluence
                            )
                          }
                        >
                          <ArrowLeft />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10}>
                        <IppRating
                          id="LevelOfInfluence"
                          label={t(
                            "objPlt:objects.contact.fields.levelofinfluence"
                          )}
                          value={dupeContact.LevelOfInfluence}
                          onChangeFunction={null}
                          isEditing={false}
                          setIsEditing={null}
                          showEditButton={false}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={0}>
                      <Grid item xs={10}>
                        <IppRating
                          id="LevelOfInterest"
                          label={t(
                            "objPlt:objects.contact.fields.levelofinterest"
                          )}
                          value={oldContact.LevelOfInterest}
                          onChangeFunction={null}
                          isEditing={false}
                          setIsEditing={null}
                          showEditButton={false}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() =>
                            formik.setFieldValue(
                              "LevelOfInterest",
                              oldContact.LevelOfInterest
                            )
                          }
                        >
                          <ArrowRight />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <IppRating
                      id="LevelOfInterest"
                      label={t("objPlt:objects.contact.fields.levelofinterest")}
                      value={formik.values.LevelOfInterest}
                      onChangeFunction={formik.handleChange}
                      isEditing={true}
                      setIsEditing={null}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={0}>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() =>
                            formik.setFieldValue(
                              "LevelOfInterest",
                              dupeContact.LevelOfInterest
                            )
                          }
                        >
                          <ArrowLeft />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10}>
                        <IppRating
                          id="LevelOfInterest"
                          label={t(
                            "objPlt:objects.contact.fields.levelofinterest"
                          )}
                          value={dupeContact.LevelOfInterest}
                          onChangeFunction={null}
                          isEditing={false}
                          setIsEditing={null}
                          showEditButton={false}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              {/* end of grid block */}
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end">
              <IppSubmitButton buttonText={t("strGen:buttons.save")} />
            </Grid>
          </Box>
        </Paper>
      </form>
    );

  return (
    <Root>
      <Box display="flex" justifyContent="center">
        <Grid container className={classes.editForm} spacing={1}>
          <Grid item xs={12}>
            <Paper elevation={6} className={classes.boxSpace}>
              <Box padding={1} sx={{ borderRadius: 1 }}>
                <Typography variant="h5" gutterBottom>
                  {t("strGen:pages.merge.mergefull", {
                    fieldname: t("objPlt:objects.contact.name", {
                      count: 2,
                    }),
                  })}
                </Typography>
                <Typography variant="body1">
                  {t("strGen:pages.merge.info", {
                    fieldname: t("objPlt:objects.contact.name", {
                      count: 2,
                    }),
                    fieldname2: t("objPlt:objects.contact.name"),
                  })}
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            {formView}
          </Grid>
        </Grid>
      </Box>
    </Root>
  );
};
