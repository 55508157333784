import axiosClient from "./axiosClient";

export interface EmployeesByGenderChart {
  MonthSequenceID: number;
  YearNumber: number;
  MonthName: string;
  Male: number;
  Female: number;
  Other: number;
}

export interface EmployeesByGenderChartsResult {
  employeesByGender: EmployeesByGenderChart[];
}

export async function getEmployeesByGenderCharts(
  accessToken: String
): Promise<EmployeesByGenderChartsResult> {
  const url = `/charts/employeesByGender`;
  try {
    const { data } = await axiosClient.get<EmployeesByGenderChartsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
