import axiosClient from "./axiosClient";

export interface ClientCommitmentSourceType {
  ClientCommitmentSourceTypeID: number;
  ClientID: number;
  CommitmentSourceTypeID: number;
  CommitmentSourceTypeName: string;
  IsRequired: boolean;
}

export interface ClientCommitmentSourceTypesResult {
  clientCommitmentSourceTypes: ClientCommitmentSourceType[];
}

export async function getClientCommitmentSourceTypes(
  accessToken: String
): Promise<ClientCommitmentSourceTypesResult> {
  const url = `/clientCommitmentSourceType`;

  try {
    const { data } = await axiosClient.get<ClientCommitmentSourceTypesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    // console.log("GETDATA", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createClientCommitmentSourceType(
  accessToken: String,
  newClientCommitmentSourceType: Partial<ClientCommitmentSourceType>
): Promise<ClientCommitmentSourceType> {
  const url = `/ClientCommitmentSourceType`;

  try {
    const { data } = await axiosClient.post<ClientCommitmentSourceType>(
      url,
      newClientCommitmentSourceType,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateClientCommitmentSourceType(
  accessToken: String,
  ClientCommitmentSourceTypeID: number,
  newClientCommitmentSourceType: Partial<ClientCommitmentSourceType>
): Promise<ClientCommitmentSourceType> {
  const url = `/ClientCommitmentSourceType/` + ClientCommitmentSourceTypeID;

  try {
    const { data } = await axiosClient.post<ClientCommitmentSourceType>(
      url,
      newClientCommitmentSourceType,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteClientCommitmentSourceType(
  accessToken: String,
  ClientCommitmentSourceTypeID: number
): Promise<string> {
  const url = `/ClientCommitmentSourceType/` + ClientCommitmentSourceTypeID;
  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    // console.log("DELDATA", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}