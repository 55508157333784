import { Grid, Typography } from "@mui/material";
import { IppAutocomplete } from "components/IppAutocomplete";
import { IppDatePicker } from "components/IppDatePicker";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { ConvertDateOffset, GetJSDate } from "utils/DateFunctions";
import { IppFormDivider } from "components/IppFormDivider";
import { IppDisplayField } from "components/IppDisplayField";
import { getNumField, getRichTextField } from "utils/formHelpers";

export interface FormField {
  select: string[];
  date: string[];
  number: string[];
  text: string[];
}
export const startFields: Partial<FormField> = {
  select: ["ProjectID", "CompanyID"],
  date: ["EndingDate", "CompletionDate"],
  number: ["ExposureHours", "ExposureHoursSubcontractor", "NumberInWorkforce"],
};
export const incidentsFields: Partial<FormField> = {
  number: [
    "NoTreatment",
    "FirstAid",
    "MedicalTreatment",
    "RestrictedWork",
    "LostTime",
    "SubcontractorIncidents",
    "SpillsReleases",
    "SafetyAlerts",
    "EnvironmentalAlerts",
    "ProcessSafety",
    "ProcessSafetyAlerts",
  ],
  text: ["LTI_Description", "RI_Description", "StatisticsNotes"],
};
export const contractualFields: Partial<FormField> = {
  number: [
    "VisitInPerson",
    "VisitVirtual",
    "RandomTestingRequired",
    "RandomTestingCompleted",
    "RandomTestingCompliant",
    "RandomTestingNonCompliant",
    "ReasonableCauseRequired",
    "ReasonableCauseCompleted",
    "ReasonableCauseCompliant",
    "ReasonableCauseNonCompliant",
    "PreAccessRequiredNew",
    "PreAccessCompletedNew",
    "PreAccessCompliantNew",
    "PreAccessNonCompliantNew",
    "PreAccessRequiredRecert",
    "PreAccessCompletedRecert",
    "PreAccessCompliantRecert",
    "PreAccessNonCompliantRecert",
    "PostIncidentRequired",
    "PostIncidentCompleted",
    "PostIncidentCompliant",
    "PostIncidentNonCompliant",
    "TB_Required",
    "TB_Completed",
    "TB_Compliant",
    "TB_NonCompliant",
  ],
  text: ["ContractualNotes"],
};
export const safetyFields: Partial<FormField> = {
  number: [
    "BBO",
    "PermitAssessments",
    "HazID",
    "NearMisses",
    "PotentialHurt",
    "WorkSiteInspections",
    "JSA",
    "ItemsOutstanding",
    "Walkthroughs",
    "CriticalProcedures",
  ],
  text: ["NotesComments"],
};
export const summaryFields: Partial<FormField> = {
  text: [
    "StatusOpenItems",
    "ImprovementOpportunities",
    "BestPractices",
    "SafetyObjectives",
  ],
};

export const getTextField = (props: any) => {
  const { label, ...rest } = props;
  const textField = { ...props, label: "", height: 150 };
  return (
    <Grid container flexDirection="column" rowSpacing={1}>
      <Grid item xs={12}>
        <IppFormDivider title={label} />
      </Grid>
      <Grid item xs={12}>
        {getRichTextField(textField)}
      </Grid>
    </Grid>
  );
};
export const Start = (props: any) => {
  const { t } = useTranslation(["objSshe", "objPlt"]);
  const {
    formik,
    projects,
    projectIsLoading,
    projectsById,
    confirmedCompanies,
    companiesById,
    currentProfile,
    isEditing,
    setIsEditing,
  } = props;
  const startNumFields = startFields.number?.map((field) => {
    return {
      id: field,
      label: t(`objSshe:objects.form.fields.${field}`),
      formik: formik,
      isEditing: isEditing,
      setIsEditing: setIsEditing,
      fieldType: "number",
    };
  });
  return (
    <Grid container padding={1} spacing={1}>
      <Grid item xs={12} md={6}>
        {currentProfile?.IsClientAdmin ? (
          <IppAutocomplete
            id="CompanyID"
            options={confirmedCompanies.sort(
              (a: { ShowAsActive: string }, b: { ShowAsActive: any }) =>
                a.ShowAsActive.localeCompare(b.ShowAsActive)
            )}
            value={confirmedCompanies.find((obj: { CompanyID: any }) => {
              return obj.CompanyID === formik.values.CompanyID;
            })}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                formik.setFieldValue("CompanyID", newValue.CompanyID);
              } else {
                formik.setFieldValue("CompanyID", -1);
              }
            }}
            groupBy={(option: any) => option.ShowAsActive}
            required
            label={t("objSshe:objects.company.name")}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            errorFunction={formik.errors.CompanyID}
            touchedFunction={
              formik.touched.CompanyID || formik.errors.CompanyID
            }
            optionLabelFunction={(option: any) => option.CompanyName}
            textValueFunction={
              formik.values.CompanyID > 0 &&
              companiesById[formik.values.CompanyID]
                ? companiesById[formik.values.CompanyID].CompanyName
                : ""
            }
          />
        ) : (
          <IppDisplayField
            label={t("objSshe:objects.company.name")}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            value={currentProfile?.CompanyName}
            showEdit={false}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <IppAutocomplete
          id="ProjectID"
          options={projects}
          required={true}
          value={projects.find((obj: any) => {
            return obj.ProjectID === formik.values?.ProjectID;
          })}
          onChangeFunction={(event: ChangeEvent, newValue: any) => {
            if (newValue) {
              // setProjectID(newValue.ProjectID);
              formik.setFieldValue("ProjectID", newValue.ProjectID);
            } else {
              // setProjectID(-1);
              formik.setFieldValue("ProjectID", -1);
            }
          }}
          label={t("objPlt:objects.project.name")}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          optionLabelFunction={(option: any) => option.ProjectName}
          textValueFunction={
            formik.values?.ProjectID > 0 &&
            !projectIsLoading &&
            projectsById[formik.values?.ProjectID]
              ? projectsById[formik.values?.ProjectID].ProjectName
              : ""
          }
          errorFunction={formik.errors.ProjectID}
          touchedFunction={formik.touched.ProjectID || formik.errors.ProjectID}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <IppDatePicker
          id="EndingDate"
          label="Ending Date"
          value={ConvertDateOffset(formik.values.EndingDate)}
          required
          onChangeFunction={(newValue: any) => {
            formik.setFieldValue("EndingDate", GetJSDate(newValue), true);
            formik.setFieldTouched("EndingDate", true, false);
          }}
          errorsExpression={formik.errors.EndingDate}
          touchedExpression={
            formik.touched.EndingDate || formik.errors.EndingDate
          }
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
      </Grid>
      <Grid item xs={6}>
        <IppDatePicker
          id="CompletionDate"
          label="Completion Date"
          required
          value={ConvertDateOffset(formik.values.CompletionDate)}
          onChangeFunction={(newValue: any) => {
            formik.setFieldValue("CompletionDate", GetJSDate(newValue), true);
            formik.setFieldTouched("CompletionDate", true, true);
          }}
          errorsExpression={formik.errors.CompletionDate}
          touchedExpression={
            formik.touched.CompletionDate || formik.errors.CompletionDate
          }
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
      </Grid>
      {startNumFields?.map((field, index) => {
        return (
          <Grid key={index} item xs={12} md={6}>
            {getNumField(field)}
          </Grid>
        );
      })}
    </Grid>
  );
};

export const IncidentsStatistics = (props: any) => {
  const { formik, isEditing, setIsEditing } = props;
  const { t } = useTranslation(["objSshe"]);
  const incidentsNumFields = incidentsFields.number?.map((field) => {
    return {
      id: field,
      label: t(`objSshe:objects.form.fields.${field}`),
      formik: formik,
      isEditing: isEditing,
      setIsEditing: setIsEditing,
      fieldType: "number",
    };
  });

  const incidentsTextFields = incidentsFields.text?.map((field) => {
    return {
      id: field,
      label: t(`objSshe:objects.form.fields.${field}`),
      formik: formik,
      isEditing: isEditing,
    };
  });

  return (
    <Grid container padding={1} spacing={1}>
      <Grid container item xs={12} padding={1} spacing={1}>
        {incidentsNumFields?.slice(0, 5).map((field, index) => {
          return (
            <Grid key={index} item xs={12} md={6}>
              {getNumField(field)}
            </Grid>
          );
        })}
      </Grid>
      {incidentsTextFields?.slice(0, 2).map((field, index) => {
        return (
          <Grid key={index} item xs={12} md={6}>
            {getTextField(field)}
          </Grid>
        );
      })}
      <Grid container item xs={12} padding={1} spacing={1}>
        {incidentsNumFields?.slice(5).map((field, index) => {
          return (
            <Grid key={index} item xs={12} md={6}>
              {getNumField(field)}
            </Grid>
          );
        })}
      </Grid>
      {incidentsTextFields?.slice(2).map((field, index) => {
        return (
          <Grid key={index} item xs={12}>
            {getTextField(field)}
          </Grid>
        );
      })}
    </Grid>
  );
};

export const ContractualObligation = (props: any) => {
  const { formik, isEditing, setIsEditing } = props;
  const { t } = useTranslation(["objSshe"]);
  const contractualNumFields = contractualFields.number?.map((field) => {
    return {
      id: field,
      label: t(`objSshe:objects.form.fields.${field}`),
      formik: formik,
      isEditing: isEditing,
      setIsEditing: setIsEditing,
      fieldType: "number",
    };
  });

  const contractualTextFields = contractualFields.text?.map((field) => {
    return {
      id: field,
      label: t(`objSshe:objects.form.fields.${field}`),
      formik: formik,
      isEditing: isEditing,
    };
  });
  return (
    <Grid container padding={1} spacing={1}>
      <Grid container item xs={12} spacing={1}>
        {contractualNumFields?.slice(0, 2)?.map((field, index) => {
          return (
            <Grid key={index} item xs={12} md={6}>
              {getNumField(field)}
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <Typography component="h4" color="primary" fontWeight="">
            {t("objSshe:objects.form.title.A&DRandom")}
          </Typography>
        </Grid>
        {contractualNumFields?.slice(2, 6)?.map((field, index) => {
          return (
            <Grid key={index} item xs={12} md={6}>
              {getNumField(field)}
            </Grid>
          );
        })}
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12}>
          <Typography component="h4" color="primary" fontWeight="">
            {t("objSshe:objects.form.title.A&DReasonable")}
          </Typography>
        </Grid>
        {contractualNumFields?.slice(6, 10)?.map((field, index) => {
          return (
            <Grid key={index} item xs={12} md={6}>
              {getNumField(field)}
            </Grid>
          );
        })}
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12}>
          <Typography component="h4" color="primary">
            {t("objSshe:objects.form.title.A&DPre")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography component="h4" color="#808080">
            {t("objSshe:objects.form.title.newWorker")}
          </Typography>
        </Grid>
        {contractualNumFields?.slice(10, 14)?.map((field, index) => {
          return (
            <Grid key={index} item xs={12} md={6}>
              {getNumField(field)}
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <Typography component="h4" color="#808080">
            {t("objSshe:objects.form.title.recert")}
          </Typography>
        </Grid>
        {contractualNumFields?.slice(14, 18)?.map((field, index) => {
          return (
            <Grid key={index} item xs={12} md={6}>
              {getNumField(field)}
            </Grid>
          );
        })}
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12}>
          <Typography component="h4" color="primary">
            {t("objSshe:objects.form.title.A&DPost")}
          </Typography>
        </Grid>
        {contractualNumFields?.slice(18, 22)?.map((field, index) => {
          return (
            <Grid key={index} item xs={12} md={6}>
              {getNumField(field)}
            </Grid>
          );
        })}
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12}>
          <Typography component="h4" color="primary" fontWeight="">
            {t("objSshe:objects.form.title.TCP")}
          </Typography>
        </Grid>
        {contractualNumFields?.slice(22, 26)?.map((field, index) => {
          return (
            <Grid key={index} item xs={12} md={6}>
              {getNumField(field)}
            </Grid>
          );
        })}
      </Grid>
      {contractualTextFields?.map((field, index) => {
        return (
          <Grid key={index} item xs={12}>
            {getTextField(field)}
          </Grid>
        );
      })}
    </Grid>
  );
};

export const SafetyManagement = (props: any) => {
  const { formik, isEditing, setIsEditing } = props;
  const { t } = useTranslation(["objSshe"]);
  const safetyNumFields = safetyFields.number?.map((field) => {
    return {
      id: field,
      label: t(`objSshe:objects.form.fields.${field}`),
      formik: formik,
      isEditing: isEditing,
      setIsEditing: setIsEditing,
      fieldType: "number",
    };
  });
  const safetyTextFields = safetyFields.text?.map((field) => {
    return {
      id: field,
      label: t(`objSshe:objects.form.fields.${field}`),
      formik: formik,
      isEditing: isEditing,
    };
  });
  return (
    <Grid container padding={1} spacing={1}>
      {safetyNumFields?.map((field, index) => {
        return (
          <Grid key={index} item xs={12} md={index < 8 ? 6 : 12}>
            {getNumField(field)}
          </Grid>
        );
      })}
      {safetyTextFields?.map((field, index) => {
        return (
          <Grid key={index} item xs={12}>
            {getTextField(field)}
          </Grid>
        );
      })}
    </Grid>
  );
};

export const SummaryNotes = (props: any) => {
  const { formik, isEditing, setIsEditing } = props;
  const { t } = useTranslation(["objSshe"]);
  const summaryTextFields = summaryFields.text?.map((field) => {
    return {
      id: field,
      label: t(`objSshe:objects.form.fields.${field}`),
      formik: formik,
      isEditing: isEditing,
    };
  });
  return (
    <Grid container padding={1} spacing={1}>
      {summaryTextFields?.map((field, index) => {
        return (
          <Grid key={index} item xs={12}>
            {getTextField(field)}
          </Grid>
        );
      })}
    </Grid>
  );
};
