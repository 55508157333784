import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CommunityInvestment,
  CommunityInvestmentsResult,
  getCommunityInvestments,
  getCommunityInvestmentsByCompany,
  createCommunityInvestment,
  updateCommunityInvestment,
  deleteCommunityInvestment,
} from "api/communityInvestmentAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import * as Constants from "utils/snackBarConstants";

interface CommunityInvestmentState {
  communityInvestmentsById: Record<number, CommunityInvestment>;
  communityInvestmentList: number[];
  isLoading: boolean;
  subCommunityInvestmentisLoading: boolean;
  error: string | null;
}

const CommunityInvestmentInitialState: CommunityInvestmentState = {
  communityInvestmentsById: {},
  communityInvestmentList: [],
  isLoading: false,
  subCommunityInvestmentisLoading: false,
  error: null,
};

function startLoading(state: CommunityInvestmentState) {
  state.isLoading = true;
}

function startLoadingSub(state: CommunityInvestmentState) {
  state.subCommunityInvestmentisLoading = true;
}

function loadingFailed(
  state: CommunityInvestmentState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

function loadingSubFailed(
  state: CommunityInvestmentState,
  action: PayloadAction<string>
) {
  state.subCommunityInvestmentisLoading = false;
  state.error = action.payload;
}

const communityInvestments = createSlice({
  name: "communityInvestments",
  initialState: CommunityInvestmentInitialState,
  reducers: {
    getCommunityInvestmentsStart: startLoading,
    getCommunityInvestmentsSuccess(
      state,
      { payload }: PayloadAction<CommunityInvestmentsResult>
    ) {
      const { communityInvestments } = payload;
      // console.log("PAYLOAD", communityInvestments);
      state.isLoading = false;
      state.error = null;

      communityInvestments.forEach((communityInvestment) => {
        state.communityInvestmentsById[
          communityInvestment.CommunityInvestmentID
        ] = communityInvestment;
      });

      state.communityInvestmentList = communityInvestments.map(
        (communityInvestment) => communityInvestment.CommunityInvestmentID
      );
    },
    getCommunityInvestmentsFailure: loadingFailed,
    createCommunityInvestmentStart: startLoading,
    createCommunityInvestmentSuccess(
      state,
      { payload }: PayloadAction<CommunityInvestment>
    ) {
      const { CommunityInvestmentID } = payload;
      state.communityInvestmentsById[CommunityInvestmentID] = payload;
      state.communityInvestmentList.push(CommunityInvestmentID);

      state.isLoading = false;
      state.error = null;
    },
    updateCommunityInvestmentSuccess(
      state,
      { payload }: PayloadAction<CommunityInvestment>
    ) {
      const { CommunityInvestmentID } = payload;
      state.communityInvestmentsById[CommunityInvestmentID] = payload;

      state.isLoading = false;
      state.error = null;
    },
    deleteCommunityInvestmentSuccess(
      state,
      { payload }: PayloadAction<number>
    ) {
      const CommunityInvestmentID = payload;
      delete state.communityInvestmentsById[CommunityInvestmentID];
      state.communityInvestmentList = state.communityInvestmentList.filter(
        (item) => item !== CommunityInvestmentID
      );

      state.isLoading = false;
      state.error = null;
    },
    createCommunityInvestmentFailure: loadingFailed,
  },
});

export const {
  getCommunityInvestmentsStart,
  getCommunityInvestmentsSuccess,
  getCommunityInvestmentsFailure,
  createCommunityInvestmentStart,
  createCommunityInvestmentSuccess,
  updateCommunityInvestmentSuccess,
  deleteCommunityInvestmentSuccess,
  createCommunityInvestmentFailure,
} = communityInvestments.actions;

export default communityInvestments.reducer;

export const fetchCommunityInvestments =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCommunityInvestmentsStart());
      const communityInvestments = await getCommunityInvestments(accessToken);
      dispatch(getCommunityInvestmentsSuccess(communityInvestments));
    } catch (err: any) {
      dispatch(getCommunityInvestmentsFailure(err.toString()));
    }
  };

export const fetchCommunityInvestmentsByCompany =
  (accessToken: String, companyID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCommunityInvestmentsStart());
      const communityInvestments = await getCommunityInvestmentsByCompany(
        accessToken,
        companyID
      );
      dispatch(getCommunityInvestmentsSuccess(communityInvestments));
    } catch (err: any) {
      dispatch(getCommunityInvestmentsFailure(err.toString()));
    }
  };

export const addCommunityInvestment =
  (
    accessToken: String,
    newCommunityInvestment: Partial<CommunityInvestment>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCommunityInvestmentStart());
      const communityInvestment = await createCommunityInvestment(
        accessToken,
        newCommunityInvestment
      );
      dispatch(createCommunityInvestmentSuccess(communityInvestment));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(
        push(
          `/benefits/communityInvestments/${communityInvestment.CommunityInvestmentID}`
        )
      );
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(openSnackBar(error.response.data.message, "error"));
        dispatch(createCommunityInvestmentFailure(error.response.data.message));
      } else {
        dispatch(openSnackBar(Constants.FAILED, "error"));
        dispatch(createCommunityInvestmentFailure(Constants.FAILED));
      }
    }
  };

export const updCommunityInvestment =
  (
    accessToken: String,
    communityInvestmentID: number,
    newCommunityInvestment: Partial<CommunityInvestment>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCommunityInvestmentStart());
      const communityInvestment = await updateCommunityInvestment(
        accessToken,
        communityInvestmentID,
        newCommunityInvestment
      );
      dispatch(updateCommunityInvestmentSuccess(communityInvestment));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(openSnackBar(error.response.data.message, "error"));
        dispatch(createCommunityInvestmentFailure(error.response.data.message));
      } else {
        dispatch(openSnackBar(Constants.FAILED, "error"));
        dispatch(createCommunityInvestmentFailure(Constants.FAILED));
      }
    }
  };

export const delCommunityInvestment =
  (
    accessToken: String,
    communityInvestmentID: number,
    communityInvestment: Partial<CommunityInvestment>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCommunityInvestmentStart());
      const result = await deleteCommunityInvestment(
        accessToken,
        communityInvestmentID,
        communityInvestment
      );
      dispatch(deleteCommunityInvestmentSuccess(communityInvestmentID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
      dispatch(push("/benefits/communityInvestments"));
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(openSnackBar(error.response.data.message, "error"));
        dispatch(createCommunityInvestmentFailure(error.response.data.message));
      } else {
        dispatch(openSnackBar(Constants.FAILED, "error"));
        dispatch(createCommunityInvestmentFailure(Constants.FAILED));
      }
    }
  };
