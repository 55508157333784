import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  PaymentPeriodStatus,
  PaymentPeriodStatusesResult,
  getPaymentPeriodStatuses,
} from "api/benefits/paymentPeriodStatusAPI";
import { AppThunk } from "app/store";

interface PaymentPeriodStatusState {
  paymentPeriodStatusesById: Record<number, PaymentPeriodStatus>;
  paymentPeriodStatusList: number[];
  isLoading: boolean;
  error: string | null;
}

const PaymentPeriodStatusInitialState: PaymentPeriodStatusState = {
  paymentPeriodStatusesById: {},
  paymentPeriodStatusList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: PaymentPeriodStatusState) {
  state.isLoading = true;
}

function loadingFailed(
  state: PaymentPeriodStatusState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const paymentPeriodStatuses = createSlice({
  name: "paymentPeriod",
  initialState: PaymentPeriodStatusInitialState,
  reducers: {
    getPaymentPeriodStatusesStart: startLoading,
    getPaymentPeriodStatusessSuccess(
      state,
      { payload }: PayloadAction<PaymentPeriodStatusesResult>
    ) {
      const { paymentPeriodStatuses } = payload;
      state.isLoading = false;
      state.error = null;

      paymentPeriodStatuses.forEach((paymentPeriodStatus) => {
        state.paymentPeriodStatusesById[
          paymentPeriodStatus.PaymentPeriodStatusID
        ] = paymentPeriodStatus;
      });

      state.paymentPeriodStatusList = paymentPeriodStatuses.map(
        (paymentPeriodStatus) => paymentPeriodStatus.PaymentPeriodStatusID
      );
    },
    getPaymentPeriodStatusesFailure: loadingFailed,
  },
});

export const {
  getPaymentPeriodStatusesStart,
  getPaymentPeriodStatusessSuccess,
  getPaymentPeriodStatusesFailure,
} = paymentPeriodStatuses.actions;

export default paymentPeriodStatuses.reducer;

export const fetchPaymentPeriodStatuses =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getPaymentPeriodStatusesStart());
      const paymentPeriodStatuses = await getPaymentPeriodStatuses(accessToken);
      dispatch(getPaymentPeriodStatusessSuccess(paymentPeriodStatuses));
    } catch (err: any) {
      dispatch(getPaymentPeriodStatusesFailure(err.toString()));
    }
  };
