import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  OrganizationType,
  OrganizationTypesResult,
  getOrganizationTypes,
} from "api/stakeholder/organizationTypeAPI";
import { AppThunk } from "app/store";

interface OrganizationTypeState {
  organizationTypesById: Record<number, OrganizationType>;
  organizationTypeList: number[];
  isLoading: boolean;
  error: string | null;
}

const OrganizationTypeInitialState: OrganizationTypeState = {
  organizationTypesById: {},
  organizationTypeList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: OrganizationTypeState) {
  state.isLoading = true;
}

function loadingFailed(
  state: OrganizationTypeState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const organizationTypes = createSlice({
  name: "organizationType",
  initialState: OrganizationTypeInitialState,
  reducers: {
    getOrganizationTypesStart: startLoading,
    getOrganizationTypesSuccess(
      state,
      { payload }: PayloadAction<OrganizationTypesResult>
    ) {
      const { organizationTypes } = payload;
      state.isLoading = false;
      state.error = null;

      organizationTypes.forEach((organizationType) => {
        state.organizationTypesById[organizationType.OrganizationTypeID] =
          organizationType;
      });

      state.organizationTypeList = organizationTypes.map(
        (organizationType) => organizationType.OrganizationTypeID
      );
    },
    getOrganizationTypesFailure: loadingFailed,
  },
});

export const {
  getOrganizationTypesStart,
  getOrganizationTypesSuccess,
  getOrganizationTypesFailure,
} = organizationTypes.actions;

export default organizationTypes.reducer;

export const fetchOrganizationTypes =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getOrganizationTypesStart());
      const organizationTypes = await getOrganizationTypes(accessToken);
      dispatch(getOrganizationTypesSuccess(organizationTypes));
    } catch (err: any) {
      dispatch(getOrganizationTypesFailure(err.toString()));
    }
  };
