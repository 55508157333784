import React, { ComponentType, useEffect, useState } from "react";
import { Redirect, Route } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import LoadingIndicator from "components/LoadingIndicator";

import UserRoleSlice from "features/roles/UserRoleSlice";
import { fetchProfile } from "features/profile/ProfileSlice";

//import { Loading } from "../components/index";

const ProtectedRoute = ({ component, ...args }: any) => {
  //Get the current relative URL path
  let pathname = window.location.pathname;

  return (
    //If there is a trailing "/" redirect to the current component
    pathname.slice(-1) === "/" ? (
      <Redirect to={args.path} />
    ) : (
      <Route
        component={withAuthenticationRequired(
          AdminCheck(component, args.path),
          {
            onRedirecting: () => (
              <div>
                <LoadingIndicator />
              </div>
            ),
          }
        )}
        {...args}
      />
    )
  );
};

const AdminCheck = (component: ComponentType, path: string) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [pageLoaded, setPageLoaded] = useState(false);

  const {
    clientId,
    ft_com_Phase,
    ft_eng_Phase,
    ft_ben_Tier,
    ft_all_ProjectTypes,
    ft_ben_JobPosition,
    ft_ben_Region,
    ft_ben_Ethnicity,
    isLoading: clientIsLoading,
  } = useSelector((state: RootState) => state.client);

  const {
    currentProfile,
    currentUserRoleList,
    currentUserRolesById,
    isLoading,
    error: profileError,
  } = useSelector((state: RootState) => state.profile);
  const userroles = currentUserRoleList.map((id) => currentUserRolesById[id]);

  const {
    clientModuleList,
    clientModulesById,
    error: clientModuleError,
    isLoading: clientModuleIsLoading,
  } = useSelector((state: RootState) => state.clientModules);
  const clientRoles = clientModuleList.map((id) => clientModulesById[id]);
  //const clientRoles = ["benefits", "consultation"]

  let cRoles = [] as any;
  clientRoles.forEach((e) => {
    cRoles.push(e.ShortName.toLowerCase());
  });

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        // only fetch profile if not already loaded
        if (!currentProfile && !isLoading) {
          dispatch(fetchProfile(accessToken, clientId));
        }
        setPageLoaded(true);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, currentProfile, dispatch, getAccessTokenSilently]);

  // checks what module and that the client has access to the module

  if (pageLoaded && !isLoading && !clientModuleIsLoading) {
    let roles: number[] = [];
    userroles.forEach((e) => {
      roles.push(e.ModuleID);
    });
    // Check if Client has Module, if true check if User is Admin or has a Module Role, if true Allow access, if false redirect to Unauthorized
    if (path.match(/benefits/)) {
      return cRoles.includes("benefits") &&
        (currentProfile.IsClientAdmin || roles.includes(1))
        ? component
        : () => <Redirect to="/unauthorized" />;
    } else if (path.match(/commitments/)) {
      return cRoles.includes("commitments") &&
        (currentProfile.IsClientAdmin || roles.includes(2))
        ? component
        : () => <Redirect to="/unauthorized" />;
    } else if (path.match(/engagement/)) {
      return cRoles.includes("engagement") &&
        (currentProfile.IsClientAdmin || roles.includes(3))
        ? component
        : () => <Redirect to="/unauthorized" />;
    } else if (path.match(/suppliers/)) {
      return cRoles.includes("suppliers") &&
        (currentProfile.IsClientAdmin || roles.includes(5))
        ? component
        : () => <Redirect to="/unauthorized" />;
    } else if (path.match(/admin/)) {
      if (!ft_com_Phase && !ft_eng_Phase) {
        if (path.match(/phases/))
          return () => <Redirect to="/admin/disabledFeature" />;
      }
      if (!ft_ben_Ethnicity) {
        if (path.match(/ethnicitys/))
          return () => <Redirect to="/admin/disabledFeature" />;
      }
      if (!ft_ben_Tier) {
        if (path.match(/tiers/))
          return () => <Redirect to="/admin/disabledFeature" />;
      }
      if (!ft_all_ProjectTypes) {
        if (path.match(/projectTypes/))
          return () => <Redirect to="/admin/disabledFeature" />;
      }
      if (!ft_ben_JobPosition) {
        if (path.match(/clientPositions/))
          return () => <Redirect to="/admin/disabledFeature" />;
      }
      if (!ft_ben_Region) {
        if (path.match(/regions/))
          return () => <Redirect to="/admin/disabledFeature" />;
      }

      // Check if current user is an Admin
      return currentProfile.IsClientAdmin
        ? component
        : () => <Redirect to="/unauthorized" />;
    }
  }
  return component;
};

export default ProtectedRoute;
