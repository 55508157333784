import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConvertDateOffset } from "utils/DateFunctions";
import { fetchRnDExpenses } from "./RnDExpenseSlice";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";
import { useTypedTranslation } from "utils/customHooks";
import { IppInventory } from "components/Inventory/IppInventory";
import { IsExtraLargeScreen } from "utils/mediaQueries";

export const RnDExpenseInventoryPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  const { isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const {
    RnDExpenseList,
    RnDExpensesById,
    isLoading,
    error: expenseError,
  } = useSelector((state: RootState) => state.RnDExpense);

  const expenses = RnDExpenseList.map((id) => RnDExpensesById[id]);

  const data = expenses.map((p) => {
    let n = Object.assign({}, p) as any;

    if (p.InEligible) {
      n.EligibilityStatus = "No";
    } else n.EligibilityStatus = "Yes";

    if (p.ExpenseDate !== null) {
      n.ExpenseDate = ConvertDateOffset(p.ExpenseDate);
    }
    if (!p.InitiativeName || p.InitiativeName === "") {
      n.InitiativeName = "Missing Name";
    }

    return n;
  });

  const expenseColumns = [
    {
      field: "SequenceID",
      title: "ID",
      filter: "text",
      format: "RD-{0:0}",
      columnWidth: IsExtraLargeScreen() ? 150 : 100,
    },
    {
      field: "InitiativeName",
      title: "Initiative",
    },
    {
      field: "ExpenseDate",
      title: t("objBen:objects.rndExpense.fields.expensedate"),
      filter: "date",
      format: "{0:d}",
      columnWidth: IsExtraLargeScreen() ? 200 : 150,
    },
    {
      field: "ExpenseAmount",
      title: t("objBen:objects.rndExpense.fields.expenseamount"),
      format: "{0:c0}",
      filter: "numeric",
      columnWidth: IsExtraLargeScreen() ? 180 : 150,
    },
    {
      field: "EligibilityStatus",
      title: t("objBen:objects.rndExpense.eligible"),
      columnWidth: 150,
    },
    { field: "Classification", title: "Classification" },
    { field: "Reference", title: "Reference" },
  ];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchRnDExpenses(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  if (expenseError) {
    return <ErrorPage errMsg={expenseError} />;
  }

  let RnDExpenseInvView =
    isLoading || clientIsLoading ? (
      <LoadingIndicator />
    ) : (
      <div>
        <IppInventory
          title="Research & Development Expenses"
          tableData={data}
          idField="RnDExpenseID"
          columns={expenseColumns}
          isFiltered={true}
          showDetail={true}
          buttonProps={{
            showAdd: true,
          }}
          sortColumn={0}
          sortReverse={true}
          searchByColumn={"InitiativeName"}
          searchByPlaceholder={"Search by Initiative"}
        />
      </div>
    );

  return <div id="RnDExpense-inventory-page">{RnDExpenseInvView}</div>;
};
