import { Alert, AlertTitle, Chip, Grid, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";

interface suggestionBannerProps {
  /**
   * The title to be displayed in the banner.
   */
  title?: string;

  /**
   * A list of suggestion objects, each containing:
   * - value: The value to be displayed on the chip.
   * - tooltip (optional): The tooltip text to be shown when hovering over the chip.
   */
  suggestions?: Array<{ value: string; tooltip?: string }>;

  /**
   * The list that the suggestion affects, where selected suggestions will be added.
   */
  list?: Array<string>;

  /**
   * The function to set the updated list with selected suggestions.
   */
  setList?: (newList: Array<string>) => void;
}
const StyledAlert = styled(Alert)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingTop: 0,
  paddingBottom: 0,
  "& .MuiAlert-icon": {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  "& .MuiAlert-message": {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
}));
export const SuggestionBanner = (props: suggestionBannerProps) => {
  const { title, suggestions = [], list = [], setList } = props;
  const handleDelete = (e: React.SyntheticEvent, value: string) => {
    e.preventDefault();
    if (setList) {
      setList([...list, value]);
    }
  };
  return (
    <StyledAlert severity="info">
      <AlertTitle>{title}</AlertTitle>
      <Grid container rowSpacing={1}>
        {suggestions?.map((i: any, index: number) => (
          <Grid item key={index}>
            <Chip
              variant="outlined"
              label={i?.label}
              deleteIcon={<AddCircleIcon />}
              onDelete={(e) => handleDelete(e, i.value)}
            />
          </Grid>
        ))}
      </Grid>
    </StyledAlert>
  );
};
