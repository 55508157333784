import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { IppSkeleton } from "components/IppSkeleton";
import { ArrowBack, ArrowForward, Lock } from "@mui/icons-material";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { push } from "redux-first-history";
import { fetchJobPositions } from "features/datalists/JobPositionSlice";
import {
  addClientPosition,
  delClientPosition,
  fetchClientPositions,
} from "./ClientPositionSlice";
import { pluralize } from "utils/functions";
import { IppFormDivider } from "components/IppFormDivider";
import { IppSaveButton } from "components/Buttons/IppSaveButton";

const PREFIX = "ClientPositionForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
  roleCard: `${PREFIX}-roleCard`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1100,
  },

  [`& .${classes.boxSpace}`]: {
    padding: 10,
  },

  [`& .${classes.roleCard}`]: {
    minWidth: 250,

    minHeight: 300,
  },
}));

// format for display lists
interface listValue {
  itemID: number;
  itemLabel: string;
  wasSelected: boolean; // to track deletions
  clientPositionID: number;
  employeeCount: number;
  jobHistoryCount: number;
}

export const ClientPositionForm = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [pageLoaded, setPageLoaded] = useState(false);

  // allGroups contains unfiltered list of groups
  // filteredGroups contains list of groups with filter applied
  // selectedGroups contains list of items selected
  // filteredSelectedGroups contains list of selected items with filter applied
  const [allGroups, setAllGroups] = useState<listValue[]>([]);
  const [filteredGroups, setFilteredGroups] = useState<listValue[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<listValue[]>([]);
  const [filteredSelectedGroups, setFilteredSelectedGroups] = useState<
    listValue[]
  >([]);
  const [filterValue, setFilterValue] = useState("");

  const { clientId } = useSelector((state: RootState) => state.client);

  const {
    clientPositionList,
    clientPositionsById,
    isLoading: clientPositionsIsLoading,
    error: clientPositionsError,
  } = useSelector((state: RootState) => state.clientPositions);

  const clientPositions = clientPositionList.map(
    (id) => clientPositionsById[id]
  );

  const {
    jobPositionList,
    jobPositionsById,
    isLoading: jobPositionsIsLoading,
    error: jobPositionsError,
  } = useSelector((state: RootState) => state.jobPositions);

  const jobPositions = jobPositionList.map((id) => jobPositionsById[id]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchJobPositions(accessToken));
        dispatch(fetchClientPositions(accessToken));
        setPageLoaded(true);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  // make sure lists are initialized only one time
  useEffect(() => {
    if (
      pageLoaded &&
      !jobPositionsIsLoading &&
      !clientPositionsIsLoading &&
      allGroups.length === 0
    ) {
      initializeLists();
    }
  }, [pageLoaded, jobPositionsIsLoading, clientPositionsIsLoading]);

  const initializeLists = () => {
    // initialize full list
    let data1: listValue[] = [];
    jobPositions.forEach((item) => {
      // check if item is already selected
      const foundItem = clientPositions.find((obj) => {
        return obj.JobPositionID === item.JobPositionID;
      });
      if (!foundItem) {
        let n: listValue = {
          itemID: item.JobPositionID,
          itemLabel: item.JobPositionName,
          wasSelected: false,
          clientPositionID: -1,
          employeeCount: 0,
          jobHistoryCount: 0,
        };
        data1.push(n);
      }
    });
    setAllGroups(data1);
    setFilteredGroups(data1);

    let data2 = clientPositions.map((item) => {
      let n: listValue = {
        itemID: item.JobPositionID,
        itemLabel: item.JobPositionName,
        wasSelected: true,
        clientPositionID: item.ClientPositionID,
        employeeCount: item.EmployeeCount,
        jobHistoryCount: item.JobHistoryCount,
      };
      return n;
    });
    setSelectedGroups(data2);
    setFilteredSelectedGroups(data2);
  };

  const handleSave = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
      },
    });
    // Delete items marked for removal
    // if item was selected and is now in all groups,
    // then item was REMOVED
    allGroups.forEach((item) => {
      if (item.wasSelected) {
        dispatch(delClientPosition(accessToken, item.clientPositionID));
      }
    });

    // insert new items
    selectedGroups.forEach((item) => {
      if (!item.wasSelected) {
        const newItem = { ClientID: clientId, JobPositionID: item.itemID };
        dispatch(addClientPosition(accessToken, newItem));
      }
    });
    dispatch(openSnackBar("Updates completed", "success"));
    dispatch(push("/admin/clientpositions"));
  };

  const handleToggleRight = (value: listValue) => () => {
    // add item to full list, and sort it
    setAllGroups((oldArray) =>
      allGroups.concat(value).sort((a, b) => {
        let fa = a.itemLabel.toLowerCase(),
          fb = b.itemLabel.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      })
    );

    setFilteredGroups((oldArray) =>
      filteredGroups.concat(value).sort((a, b) => {
        let fa = a.itemLabel.toLowerCase(),
          fb = b.itemLabel.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      })
    );

    // remove item from selected list
    setSelectedGroups(
      selectedGroups.filter(function (a) {
        return a !== value;
      })
    );
    setFilteredSelectedGroups(
      filteredSelectedGroups.filter(function (a) {
        return a !== value;
      })
    );
  };

  const handleToggleLeft = (value: listValue) => () => {
    // add item to selected list, and sort it
    setSelectedGroups((oldArray) =>
      selectedGroups.concat(value).sort((a, b) => {
        let fa = a.itemLabel.toLowerCase(),
          fb = b.itemLabel.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      })
    );

    setFilteredSelectedGroups((oldArray) =>
      filteredSelectedGroups.concat(value).sort((a, b) => {
        let fa = a.itemLabel.toLowerCase(),
          fb = b.itemLabel.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      })
    );

    // remove item from full list list
    setAllGroups(
      allGroups.filter(function (a) {
        return a !== value;
      })
    );

    setFilteredGroups(
      filteredGroups.filter(function (a) {
        return a !== value;
      })
    );
  };

  const onFilterChange = (e: any) => {
    const val = e.target.value || "";
    setFilterValue(val);
    if (val === "") {
      setFilteredGroups(allGroups);
      setFilteredSelectedGroups(selectedGroups);
    } else {
      setFilteredGroups(
        allGroups.filter(function (a) {
          return a.itemLabel.toLowerCase().includes(val.toLowerCase());
        })
      );
      setFilteredSelectedGroups(
        selectedGroups.filter(function (a) {
          return a.itemLabel.toLowerCase().includes(val.toLowerCase());
        })
      );
    }
  };

  let fullList = jobPositionsIsLoading ? (
    <IppSkeleton height={400} />
  ) : (
    <Paper sx={{ height: 400, overflow: "auto" }}>
      <List dense component="div" role="list">
        {filteredGroups.map((item) => {
          return (
            <Tooltip title="Click to add item to selected groups">
              <ListItem
                button
                key={item.itemID}
                onClick={handleToggleLeft(item)}
                role="listitem"
              >
                <ListItemIcon>
                  <ArrowForward />
                </ListItemIcon>
                <ListItemText primary={item.itemLabel} />
              </ListItem>
            </Tooltip>
          );
        })}
      </List>
    </Paper>
  );

  // Construct the title
  const constructTooltipTitle = (
    empCount: number | null,
    jhCount: number | null
  ): string => {
    let title = "Item cannot be removed; ";

    if (empCount) {
      title += `${empCount} ${pluralize("employee", empCount)}`;
    }

    if (jhCount) {
      if (empCount) {
        title += " and ";
      }
      title += `${jhCount} ${pluralize("job history record", jhCount)}`;
    }

    return (title += " with this position");
  };

  let selectedList = clientPositionsIsLoading ? (
    <IppSkeleton height={400} />
  ) : (
    <Paper sx={{ height: 400, overflow: "auto" }}>
      <List dense component="div" role="list">
        {filteredSelectedGroups.map((item) => {
          const empCount = item.employeeCount > 0 ? item.employeeCount : null;
          const jhCount =
            item.jobHistoryCount > 0 ? item.jobHistoryCount : null;

          const title =
            empCount || jhCount ? constructTooltipTitle(empCount, jhCount) : "";
          return empCount || jhCount ? (
            <Tooltip
              title={title}
              // title={
              //   "Item cannot be removed; " +
              //   item.employeeCount +
              //   " employee(s) with this position"
              // }
            >
              <ListItem button role="listitem" key={item.itemID}>
                <ListItemIcon>
                  <Lock />
                </ListItemIcon>
                <ListItemText primary={item.itemLabel} />
              </ListItem>
            </Tooltip>
          ) : (
            <Tooltip title="Click to remove item from selected list">
              <ListItem
                button
                role="listitem"
                key={item.itemID}
                onClick={handleToggleRight(item)}
              >
                <ListItemIcon>
                  <ArrowBack />
                </ListItemIcon>
                <ListItemText primary={item.itemLabel} />
              </ListItem>
            </Tooltip>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <Root>
      <Box display="flex" justifyContent="center">
        <Paper className={classes.boxSpace}>
          <Grid container className={classes.editForm} spacing={2}>
            <Grid item xs={9}>
              <Typography variant="h5" component="h1">
                Job Positions
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Grid container justifyContent="flex-end">
                <IppSaveButton handleSave={handleSave} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <IppFormDivider title="Guide" />
              <Typography display="inline">
                This option allows you to select the job positions you wish to
                have appear in dropdown lists. Only the items you select will be
                available to users. Selecting a job position from one list will
                move it into the other list. Once you have completed your
                changes, click
                <strong> Save Changes</strong> to finalize them.
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="h6">Available Positions</Typography>
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Search for..."
                value={filterValue}
                onChange={onFilterChange}
                size="small"
              />
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h6">Selected Positions</Typography>
            </Grid>
            <Grid item xs={6}>
              {fullList}
            </Grid>
            <Grid item xs={6}>
              {selectedList}
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Root>
  );
};
