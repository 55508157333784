import axiosClient from "./axiosClient";

export interface ModuleRole {
  ModuleRoleID: number;
  ClientModuleID: number;
  ModuleID: number;
  RoleName: string;
}

export interface ModuleRolesResult {
  moduleRoles: ModuleRole[];
}

export async function getModuleRoles(
  accessToken: String
): Promise<ModuleRolesResult> {
  const url = `/moduleRoles`;

  try {
    const { data } = await axiosClient.get<ModuleRolesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
