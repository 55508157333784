import { styled } from "@mui/material/styles";
import { Grid, Paper, Box } from "@mui/material";
import { BrandingForm } from "./BrandingForm";
import { LogoForm } from "./LogoForm";

const PREFIX = "ClientBranding";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    // minWidth: 650,
    maxWidth: 1400,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const ClientBranding = () => {
  return (
    <Root>
      <Box display="flex" justifyContent="center">
        <Grid container className={classes.editForm} spacing={1}>
          <Grid item xs={12} md={6}>
            <Paper className={classes.boxSpace}>
              <BrandingForm />
            </Paper>
          </Grid>
          {/* logo disabled while errors are investigated */}
          <Grid item xs={12} md={6}>
            <Paper className={classes.boxSpace}>
              <LogoForm />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Root>
  );
};
