import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { fetchCompanies } from "features/benefits/companies/CompaniesSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { Upload } from "@progress/kendo-react-upload";
import { read, utils } from "xlsx";
import { RootState } from "app/rootReducer";
import { Alert, AlertTitle, Box, Grid, Paper } from "@mui/material";
import { IppFormHeader } from "components/IppFormHeader";
import { IppFormDivider } from "components/IppFormDivider";
import { FileUploadErrors } from "components/FileUploadErrors/FileUploadErrors";
import { fetchJobPositionsByClient } from "features/datalists/JobPositionSlice";
import { excelDateToJsDate } from "utils/DateFunctions";
import {
  isBooleanFalse,
  processConstructedErrorsObject,
  pushFeatureToggles,
  pushListError,
  resolveBool,
} from "utils/uploadUtils";
import Info from "@mui/icons-material/Info";
import { fetchRegionsByRecordType } from "../../regions/RegionSlice";
import { useTypedTranslation } from "utils/customHooks";
import { IppAutocomplete } from "components/IppAutocomplete";
import { IppButton } from "components/Buttons/IppButton";
import EmployeeUploadGuide from "./EmployeeUploadGuide";
import {
  generateEmployeeErrorTitles,
  getNewEmployeeConstructedErrors,
  getNewEmployeeConstructedWarnings,
} from "./EmployeeUploadErrors";
import { ProcessedUploadErrors } from "utils/types/index.types";
import {
  formatCommaSeparatedList,
  formatStringForCompare,
} from "utils/functions";
import { fetchCustomJobPositionsByClient } from "features/datalists/CustomJobPositionSlice";
import { fetchEthnicitys } from "features/platform/admin/ethnicity/EthnicitySlice";
import { fetchCustomEmpCategories } from "features/platform/admin/customEmpCategory/CustomEmpCategorySlice";

const PREFIX = "EmployeeUploadPage";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1000,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const EmployeeUploadPage = (props: any) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const t = useTypedTranslation(["strGen", "objPlt", "objBen"]);

  const [A0token, setA0token] = useState("");
  const [companyId, setCompanyId] = useState(-1);
  const [processing, setProcessing] = useState(false);
  const [warnings, setWarnings] = useState<ProcessedUploadErrors[]>([]);
  const [files, setFiles] = useState<Partial<any>>({
    files: [] as any,
    events: [] as any,
    errors: [] as any,
    emptyFile: false,
  });

  const {
    ft_ben_JobPosition,
    ft_ben_JobHistory,
    ft_ben_CustomJobPosition,
    ft_ben_Region,
    ft_ben_TempEmployee,
    ft_ben_DiversityDeclined,
    ft_ben_Ethnicity,
    ft_ben_EmployeePostalCode,
    dm_Disability,
    dm_VisibleMinority,
    dm_LGBTQ,
    dm_Local,
    dm_NorthernResident,
    ft_ben_CustomEmpCategory,
  } = useSelector((state: RootState) => state.client);

  let employeeData = props.employee || {};
  const [isEditing, setIsEditing] = useState(
    employeeData.EmployeeID ? false : true
  );
  const [isAdding, setIsAdding] = useState(
    employeeData.EmployeeID ? false : true
  );

  const [isModalGuideOpen, setModalGuideOpen] = useState(false);

  const {
    companyList,
    companiesById,
    isLoading: compIsLoading,
  } = useSelector((state: RootState) => state.companies);
  const companies = companyList.map((CompanyID) => companiesById[CompanyID]);
  const confirmedCompanies = companies.filter((comp) => comp.ListingConfirmed);

  const { jobPositionList, jobPositionsById } = useSelector(
    (state: RootState) => state.jobPositions
  );

  const jobPositions = jobPositionList.map((id) => jobPositionsById[id]);

  // custom job positions
  const {
    customJobPositionList,
    customJobPositionsById,
    isLoading: customJobPositionIsLoading,
  } = useSelector((state: RootState) => state.customJobPositions);
  const customJobPositions = ft_ben_CustomJobPosition
    ? customJobPositionList.map((id) => customJobPositionsById[id])
    : [];

  const { customEmpCategoryList, customEmpCategoriesById, isLoading } =
    useSelector((state: RootState) => state.customEmpCategories);
  const customEmpCategories = customEmpCategoryList.map(
    (id) => customEmpCategoriesById[id]
  );

  const { regionList, regionsById } = useSelector(
    (state: RootState) => state.region
  );

  const regions = ft_ben_Region ? regionList.map((id) => regionsById[id]) : [];
  const hasRegionTypes = !!(ft_ben_Region && regions.length > 0);

  const { ethnicityList, ethnicitysById } = useSelector(
    (state: RootState) => state.ethnicity
  );
  const ethnicitys = ethnicityList.map((id) => ethnicitysById[id]);

  const ethnicityNames = ethnicitys.map((ethnicity) => ethnicity.EthnicityName);

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        setA0token(accessToken);
        dispatch(fetchCompanies(accessToken, 1));
        if (ft_ben_Ethnicity) {
          dispatch(fetchEthnicitys(accessToken));
        }
        dispatch(fetchJobPositionsByClient(accessToken));
        if (ft_ben_CustomJobPosition)
          dispatch(fetchCustomJobPositionsByClient(accessToken));
        if (ft_ben_Region)
          dispatch(fetchRegionsByRecordType(accessToken, "Employee"));
        if (ft_ben_CustomEmpCategory)
          dispatch(fetchCustomEmpCategories(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  const basePath = process.env.REACT_APP_API;
  const pathArray = window.location.pathname.split("/");
  const clientShortName = pathArray[1];
  const baseURL = `${basePath}/${clientShortName}/api`;

  const fileStatuses = [
    "UploadFailed",
    "Initial",
    "Selected",
    "Uploading",
    "Uploaded",
    "RemoveFailed",
    "Removing",
  ];

  const onBeforeUpload = (event: any) => {
    event.headers.Authorization = `Bearer ${A0token}`;
    event.additionalData.compID = companyId;
  };

  const onAdd = (event: any) => {
    const afterStateChange = () => {
      event.affectedFiles
        .filter((file: any) => !file.validationErrors)
        .forEach((file: any) => {
          const reader = new FileReader();

          reader.onloadend = (ev) => {
            var data = ev.target ? ev.target.result : null;
            var workbook = read(data, {
              type: "binary",
            });
            var sheetName = workbook.SheetNames[0];

            try {
              var XL_row_object = utils.sheet_to_json(
                workbook.Sheets[sheetName]
              );

              var XL_header_object: any[] = utils.sheet_to_json(
                workbook.Sheets[sheetName],
                { header: 1 }
              );

              let sheetIsValid = true;
              let emptyFile = false;

              //validate data from excel sheet here
              //-------------------------------------
              const constructedErrors = getNewEmployeeConstructedErrors();
              const constructedWarnings = getNewEmployeeConstructedWarnings();

              //make sure sheet isn't empty
              if (XL_row_object.length < 1) {
                emptyFile = true;
              } else {
                let stringPropList = [
                  {
                    propName: t(
                      "objBen:objects.employee.upload.fields.employeeid"
                    ),
                    req: true,
                  },
                  {
                    propName: t(
                      "objBen:objects.employee.upload.fields.towncity"
                    ),
                    req: false,
                  },
                  {
                    propName: t(
                      "objBen:objects.employee.upload.fields.provincestate"
                    ),
                    req: false,
                  },
                  {
                    propName: t(
                      "objBen:objects.employee.upload.fields.country"
                    ),
                    req: false,
                  },
                  {
                    propName: t("objBen:objects.employee.upload.fields.gender"),
                    req: false,
                  },
                  {
                    propName: t(
                      "objBen:objects.employee.upload.fields.indigenousgroup"
                    ),
                    req: false,
                  },
                ];

                if (dm_Disability) {
                  stringPropList = [
                    ...stringPropList,
                    {
                      propName: t(
                        "objBen:objects.employee.upload.fields.disability"
                      ),
                      req: false,
                    },
                  ];
                }

                if (dm_VisibleMinority) {
                  stringPropList = [
                    ...stringPropList,
                    {
                      propName: t(
                        "objBen:objects.employee.upload.fields.visibleminority"
                      ),
                      req: false,
                    },
                  ];
                }

                if (dm_NorthernResident) {
                  stringPropList = [
                    ...stringPropList,
                    {
                      propName: t(
                        "objBen:objects.employee.upload.fields.northernresident"
                      ),
                      req: false,
                    },
                  ];
                }

                if (dm_LGBTQ) {
                  stringPropList = [
                    ...stringPropList,
                    {
                      propName: t(
                        "objBen:objects.employee.upload.fields.lgbtq"
                      ),
                      req: false,
                    },
                  ];
                }

                if (dm_Local) {
                  stringPropList = [
                    ...stringPropList,
                    {
                      propName: t(
                        "objBen:objects.employee.upload.fields.local"
                      ),
                      req: false,
                    },
                  ];
                }

                if (ft_ben_Ethnicity) {
                  stringPropList = [
                    ...stringPropList,
                    {
                      propName: t(
                        "objBen:objects.employee.upload.fields.ethnicity"
                      ),
                      req: false,
                    },
                  ];
                }

                if (ft_ben_JobPosition) {
                  stringPropList = [
                    ...stringPropList,
                    {
                      propName: t(
                        "objBen:objects.employee.upload.fields.jobposition"
                      ),
                      req: true,
                    },
                    {
                      propName: t(
                        "objBen:objects.employee.upload.fields.apprenticestatus"
                      ),
                      req: false,
                    },
                    {
                      propName: t(
                        "objBen:objects.employee.upload.fields.apprenticeyear"
                      ),
                      req: false,
                    },
                  ];

                  if (ft_ben_JobHistory) {
                    stringPropList = [
                      ...stringPropList,
                      {
                        propName: t(
                          "objBen:objects.employee.upload.fields.startdate"
                        ),
                        req: false,
                      },
                    ];
                  }
                }

                if (ft_ben_CustomJobPosition) {
                  stringPropList = [
                    ...stringPropList,
                    {
                      propName: t(
                        "objBen:objects.employee.upload.fields.customJobPosition"
                      ),
                      req: true,
                    },
                  ];
                }
                if (ft_ben_CustomEmpCategory) {
                  stringPropList = [
                    ...stringPropList,
                    {
                      propName: t(
                        "objBen:objects.employee.upload.fields.customCategory"
                      ),
                      req: false,
                    },
                  ];
                }

                if (hasRegionTypes)
                  stringPropList = [
                    ...stringPropList,
                    {
                      propName: t(
                        "objBen:objects.employee.upload.fields.region"
                      ),
                      req: false,
                    },
                  ];

                if (ft_ben_TempEmployee)
                  stringPropList = [
                    ...stringPropList,
                    {
                      propName: t("objBen:objects.employee.upload.fields.temp"),
                      req: false,
                    },
                  ];

                if (ft_ben_DiversityDeclined)
                  stringPropList = [
                    ...stringPropList,
                    {
                      propName: t(
                        "objBen:objects.employee.upload.fields.diversitydeclined"
                      ),
                      req: false,
                    },
                  ];

                if (ft_ben_EmployeePostalCode)
                  stringPropList = [
                    ...stringPropList,
                    {
                      propName: t(
                        "objBen:objects.employee.upload.fields.postalcode"
                      ),
                      req: false,
                    },
                  ];

                //Check required headers exist
                const headers = XL_header_object[0];

                stringPropList.forEach((prop) => {
                  if (!headers.includes(prop.propName)) {
                    prop.req
                      ? pushListError(
                          constructedErrors.requiredColumn,
                          prop.propName
                        )
                      : pushListError(
                          constructedWarnings.optionalColumn,
                          prop.propName
                        );
                  }
                });

                const hasStartDateColumn = headers.includes(
                  t("objBen:objects.employee.upload.fields.startdate")
                );

                //Check required Headers exist and data is in correct format
                XL_row_object.forEach((row: any, ix: number) => {
                  const rowNum = row.__rowNum__ + 1;
                  const rowString = `Row ${rowNum}`;

                  stringPropList.forEach((prop: any) => {
                    if (
                      prop.req === true &&
                      headers.includes(prop.propName) &&
                      (prop.propName in row !== true ||
                        !row[prop.propName] ||
                        row[prop.propName]?.toString().trim() === "")
                    ) {
                      pushListError(constructedErrors.requiredData, {
                        header: prop.propName,
                        rowNumber: rowNum,
                      });
                    }

                    if (prop.propName in row === true) {
                      if (typeof row[prop.propName].toString() != "string") {
                        pushListError(constructedErrors.incorrectType, {
                          header: prop.propName,
                          rowNumber: rowNum,
                          expectedValue: "a text value",
                        });
                      }

                      if (
                        row[prop.propName].toString().length >=
                        (prop.propName === "Job Position" ||
                        prop.propName ===
                          t("objBen:objects.employee.fields.customJobPosition")
                          ? 100
                          : prop.propName !==
                            t(
                              "objBen:objects.employee.upload.fields.postalcode"
                            )
                          ? 50
                          : 10)
                      ) {
                        pushListError(constructedErrors.characterLimit, {
                          header: prop.propName,
                          rowNumber: rowNum,
                          expectedValue:
                            prop.propName === "Job Position" ||
                            prop.propName ===
                              t(
                                "objBen:objects.employee.fields.customJobPosition"
                              )
                              ? 100
                              : prop.propName !==
                                t(
                                  "objBen:objects.employee.upload.fields.postalcode"
                                )
                              ? 50
                              : 10,
                        });
                      }
                    }
                  });

                  // check if position is apprenticeable, and if so
                  // require the apprentice status
                  const positionName =
                    row[t("objBen:objects.employee.upload.fields.jobposition")];
                  const position = positionName
                    ? jobPositions.find(
                        (item: any) => item.JobPositionName === positionName
                      )
                    : null;
                  const status =
                    row[
                      t(
                        "objBen:objects.employee.upload.fields.apprenticestatus"
                      )
                    ];
                  const year =
                    row[
                      t("objBen:objects.employee.upload.fields.apprenticeyear")
                    ];
                  let shouldPushApprenticeableWarning = false;

                  if (
                    t("objBen:objects.employee.upload.fields.jobposition") in
                    row
                  ) {
                    if (
                      !ft_ben_JobPosition &&
                      (positionName?.trim() !== "" ||
                        status?.trim() !== "" ||
                        year?.toString().trim() !== "")
                    ) {
                      pushListError(
                        constructedWarnings.disabledFeature,
                        {
                          header: t(
                            "objBen:objects.employee.upload.fields.jobposition"
                          ),
                          rowNumber: rowNum,
                          messageOverride: (
                            <>
                              The{" "}
                              <strong>
                                {t(
                                  "objBen:objects.employee.upload.fields.jobposition"
                                )}
                              </strong>{" "}
                              Feature is disabled, if you wish to see Data for{" "}
                              {t(
                                "objBen:objects.employee.upload.fields.jobposition"
                              )}
                              ,{" "}
                              {t(
                                "objBen:objects.employee.upload.fields.apprenticestatus"
                              )}{" "}
                              and{" "}
                              {t(
                                "objBen:objects.employee.upload.fields.apprenticeyear"
                              )}{" "}
                              on the platform please contact your administrator
                              to have the Feature enabled. The following rows in
                              your upload have{" "}
                              {t(
                                "objBen:objects.employee.upload.fields.jobposition"
                              )}{" "}
                              Data:
                            </>
                          ),
                        },
                        true // onlyCountUniqueHeaders
                      );
                    }

                    if (position && position.ApprenticeableTrade) {
                      if (
                        !status ||
                        (status.toUpperCase().substring(0, 1) !== "A" &&
                          status.toUpperCase().substring(0, 1) !== "J")
                      ) {
                        shouldPushApprenticeableWarning = true;
                      }

                      // check that if apprentice year exist it is of type number
                      if (
                        t(
                          "objBen:objects.employee.upload.fields.apprenticestatus"
                        ) in row
                      ) {
                        const isApprentice = status
                          ?.toUpperCase()
                          .startsWith("A");
                        const isValidYear =
                          typeof year === "number" && year >= 0;

                        if (isApprentice && !isValidYear) {
                          shouldPushApprenticeableWarning = true;
                        }
                      }

                      // check that if apprentice year exist it is of type number
                      if (
                        t(
                          "objBen:objects.employee.upload.fields.apprenticeyear"
                        ) in row
                      ) {
                        const isJourneyperson = status
                          ?.toUpperCase()
                          .startsWith("J");
                        const isValidYear =
                          typeof year === "number" && year >= 0;

                        if (
                          (isJourneyperson && year !== 0) ||
                          (!isJourneyperson && !isValidYear)
                        ) {
                          shouldPushApprenticeableWarning = true;
                        }
                      }
                    }
                  }

                  if (shouldPushApprenticeableWarning)
                    pushListError(
                      constructedWarnings.apprenticeTrades,
                      rowString
                    );

                  // makes start date required if job position is provided
                  if (
                    t("objBen:objects.employee.upload.fields.startdate") in
                      row ===
                    true
                  ) {
                    if (
                      row[
                        t("objBen:objects.employee.upload.fields.startdate")
                      ] &&
                      row[t("objBen:objects.employee.upload.fields.startdate")]
                        .toString()
                        .trim() !== ""
                    ) {
                      if (!ft_ben_JobHistory) {
                        // rowsWithStartDate.push(`Row ${rowNum}`);

                        pushListError(
                          constructedWarnings.jobHistoryStartDate,
                          rowString
                        );

                        row[
                          t("objBen:objects.employee.upload.fields.startdate")
                        ] = null;
                      } else if (
                        row[
                          t("objBen:objects.employee.upload.fields.startdate")
                        ] === undefined ||
                        !excelDateToJsDate(
                          row[
                            t("objBen:objects.employee.upload.fields.startdate")
                          ]
                        )
                      ) {
                        pushListError(constructedErrors.incorrectType, {
                          header: "Start Date",
                          rowNumber: rowNum,
                          expectedValue:
                            "a valid Date in the format 'YYYY-MM-DD' and cannot be in the future",
                        });
                      } else {
                        row[
                          t("objBen:objects.employee.upload.fields.startdate")
                        ] = excelDateToJsDate(
                          row[
                            t("objBen:objects.employee.upload.fields.startdate")
                          ]
                        );
                      }
                    }
                  } else {
                    if (ft_ben_JobHistory && hasStartDateColumn) {
                      pushListError(
                        constructedWarnings.jobHistoryStartDate,
                        rowString
                      );
                    }

                    row[t("objBen:objects.employee.upload.fields.startdate")] =
                      null;
                  }

                  // Custom job position check
                  const customPositionName =
                    row[
                      t(
                        "objBen:objects.employee.upload.fields.customJobPosition"
                      )
                    ];

                  // Custom job position row exist without feature toggle on
                  if (
                    t(
                      "objBen:objects.employee.upload.fields.customJobPosition"
                    ) in row
                  ) {
                    if (
                      !ft_ben_CustomJobPosition &&
                      customPositionName?.trim() !== ""
                    ) {
                      pushListError(
                        constructedWarnings.disabledFeature,
                        {
                          header: t(
                            "objBen:objects.employee.upload.fields.customJobPosition"
                          ),
                          rowNumber: rowNum,
                          messageOverride: (
                            <>
                              The{" "}
                              <strong>
                                {t(
                                  "objBen:objects.employee.upload.fields.customJobPosition"
                                )}
                              </strong>{" "}
                              Feature is disabled, if you wish to see Data for{" "}
                              {t(
                                "objBen:objects.employee.upload.fields.customJobPosition"
                              )}
                              on the platform please contact your administrator
                              to have the Feature enabled. The following rows in
                              your upload have{" "}
                              {t(
                                "objBen:objects.employee.upload.fields.customJobPosition"
                              )}{" "}
                              Data:
                            </>
                          ),
                        },
                        true // onlyCountUniqueHeaders
                      );
                    }
                  }

                  // Gender Check - Gender is required if Diversity is not Declined
                  if (
                    !(
                      t("objBen:objects.employee.upload.fields.gender") in row
                    ) ||
                    (t("objBen:objects.employee.upload.fields.gender") in row &&
                      (row[
                        t("objBen:objects.employee.upload.fields.gender")
                      ]?.trim() ?? "") === "")
                  ) {
                    if (
                      !ft_ben_DiversityDeclined ||
                      (ft_ben_DiversityDeclined &&
                        (!headers.includes(
                          t(
                            "objBen:objects.employee.upload.fields.diversitydeclined"
                          )
                        ) ||
                          isBooleanFalse(
                            row[
                              t(
                                "objBen:objects.employee.upload.fields.diversitydeclined"
                              )
                            ] ?? ""
                          )))
                    ) {
                      pushListError(constructedErrors.requiredData, {
                        header: t(
                          "objBen:objects.employee.upload.fields.gender"
                        ),
                        rowNumber: rowNum,
                        messageOverride: (
                          <>
                            {t("objBen:objects.employee.upload.fields.gender")}{" "}
                            is required
                            {ft_ben_DiversityDeclined
                              ? ` when Diversity is not Declined`
                              : ``}
                          </>
                        ),
                      });
                    }
                  }

                  // Diversity Declined Checks
                  const diversityBooleanFields = [
                    t("objBen:objects.employee.upload.fields.disability"),
                    t("objBen:objects.employee.upload.fields.visibleminority"),
                    t("objBen:objects.employee.upload.fields.lgbtq"),
                    t("objBen:objects.employee.upload.fields.local"),
                    t("objBen:objects.employee.upload.fields.ethnicity"),
                    t("objBen:objects.employee.upload.fields.northernresident"),
                    t("objBen:objects.employee.upload.fields.customCategory"),
                  ];

                  if (ft_ben_DiversityDeclined) {
                    let hasInvalidChecks: string[] = [];

                    // Checking the "Diversity Declined" field for valid input.
                    if (
                      !isBooleanFalse(
                        row[
                          t(
                            "objBen:objects.employee.upload.fields.diversitydeclined"
                          )
                        ] ?? ""
                      )
                    ) {
                      // Validate each diversity field based on the defined criteria.
                      for (const field of diversityBooleanFields) {
                        if (field in row && !isBooleanFalse(row[field])) {
                          hasInvalidChecks.push(
                            field.trim().replace(/\?$/, "")
                          );
                        }
                      }

                      if (
                        t("objBen:objects.employee.upload.fields.gender") in
                          row &&
                        row[
                          t("objBen:objects.employee.upload.fields.gender")
                        ] &&
                        row[
                          t("objBen:objects.employee.upload.fields.gender")
                        ].trim() !== ""
                      )
                        hasInvalidChecks.push(
                          t("objBen:objects.employee.upload.fields.gender")
                        );

                      if (
                        t(
                          "objBen:objects.employee.upload.fields.indigenousgroup"
                        ) in row &&
                        row[
                          t(
                            "objBen:objects.employee.upload.fields.indigenousgroup"
                          )
                        ]?.trim() !== ""
                      )
                        hasInvalidChecks.push(
                          t(
                            "objBen:objects.employee.upload.fields.indigenousgroup"
                          )
                        );

                      if (
                        t(
                          "objBen:objects.employee.upload.fields.customCategory"
                        ) in row &&
                        row[
                          t(
                            "objBen:objects.employee.upload.fields.customCategory"
                          )
                        ]?.trim() !== ""
                      )
                        hasInvalidChecks.push(
                          t(
                            "objBen:objects.employee.upload.fields.customCategory"
                          )
                        );

                      if (hasInvalidChecks.length > 0) {
                        const rowStringWithFields = `- ${rowString} has data for ${formatCommaSeparatedList(
                          ...hasInvalidChecks
                        )}`;

                        pushListError(
                          constructedWarnings.diversityDeclined,
                          rowStringWithFields
                        );
                      }
                    }
                  }

                  const featureToggleConfigs = [
                    {
                      toggle: hasRegionTypes,
                      header: t("objBen:objects.employee.upload.fields.region"),
                      messageOverride: ft_ben_Region ? (
                        <>
                          <strong>
                            {t("objBen:objects.employee.upload.fields.region")}
                          </strong>{" "}
                          is enabled but has no matching Record Types. If you
                          wish to see this Data on the platform please Contact
                          your Administrator to have Record Types added for
                          {t("objBen:objects.employee.upload.fields.region")}.
                        </>
                      ) : undefined,
                    },
                    {
                      toggle: ft_ben_DiversityDeclined,
                      header: t(
                        "objBen:objects.employee.upload.fields.diversitydeclined"
                      ),
                      messageOverride: (
                        <>
                          The{" "}
                          <strong>
                            {t(
                              "objBen:objects.employee.upload.fields.diversitydeclined"
                            )}
                          </strong>{" "}
                          Feature is disabled. No data will be saved for this
                          field until the Feature is enabled by an
                          Administrator. The following rows in your upload have
                          a{" "}
                          {t(
                            "objBen:objects.employee.upload.fields.diversitydeclined"
                          )}{" "}
                          Status:
                        </>
                      ),
                    },
                    {
                      toggle: ft_ben_TempEmployee,
                      header: t("objBen:objects.employee.upload.fields.temp"),
                    },
                    {
                      toggle: dm_Disability,
                      header: t(
                        "objBen:objects.employee.upload.fields.disability"
                      ),
                    },
                    {
                      toggle: dm_VisibleMinority,
                      header: t(
                        "objBen:objects.employee.upload.fields.visibleminority"
                      ),
                    },
                    {
                      toggle: dm_NorthernResident,
                      header: t(
                        "objBen:objects.employee.upload.fields.northernresident"
                      ),
                    },
                    {
                      toggle: dm_LGBTQ,
                      header: t("objBen:objects.employee.upload.fields.lgbtq"),
                    },
                    {
                      toggle: dm_Local,
                      header: t("objBen:objects.employee.upload.fields.local"),
                    },
                    {
                      toggle: ft_ben_Ethnicity,
                      header: t(
                        "objBen:objects.employee.upload.fields.ethnicity"
                      ),
                    },
                    {
                      toggle: ft_ben_CustomEmpCategory,
                      header: t(
                        "objBen:objects.employee.upload.fields.customCategory"
                      ),
                    },
                  ];

                  pushFeatureToggles(
                    featureToggleConfigs,
                    row,
                    rowNum,
                    constructedWarnings.disabledFeature
                  );

                  const diversityDeclinedColumn = t(
                    "objBen:objects.employee.upload.fields.diversitydeclined"
                  );
                  const isDiversityDeclined = resolveBool(
                    row[diversityDeclinedColumn]
                  );

                  // if Diversity Declined column is set to YES and there is an Ethnicity entered that doesn't exist on the platform, that row will not throw the Ethnicity warning below or that row will be ignored if there are other rows that have DD set to NO with a non-existent Ethnicity that do throw the warning.
                  if (
                    !isDiversityDeclined &&
                    t("objBen:objects.employee.fields.ethnicity") in row &&
                    row[
                      t("objBen:objects.employee.fields.ethnicity")
                    ].trim() !== ""
                  ) {
                    const ethnicityFieldName = t(
                      "objBen:objects.employee.fields.ethnicity"
                    );
                    const ethnicityValue = row[ethnicityFieldName].trim();

                    const foundEthnicity = ethnicityNames.find(
                      (ethnicityName) =>
                        formatStringForCompare(ethnicityName) ===
                        formatStringForCompare(ethnicityValue)
                    );

                    if (!foundEthnicity) {
                      const rowString = `Row ${rowNum} - ${ethnicityValue}`;
                      pushListError(
                        constructedWarnings.employeeEthnicity,
                        rowString
                      );
                    }
                  }

                  // Custom Emp Category check
                  const customEmpCategoryName =
                    row[
                      t("objBen:objects.employee.upload.fields.customCategory")
                    ];
                  if (
                    !isDiversityDeclined &&
                    t("objBen:objects.employee.upload.fields.customCategory") in
                      row &&
                    ft_ben_CustomEmpCategory &&
                    customEmpCategoryName?.trim() !== ""
                  ) {
                    const foundCustomEmpCategory = customEmpCategories.find(
                      (customEmpCategory: any) =>
                        formatStringForCompare(customEmpCategory?.Name) ===
                        formatStringForCompare(customEmpCategoryName)
                    );
                    if (!foundCustomEmpCategory) {
                      const rowString = `Row ${rowNum} - ${customEmpCategoryName}`;
                      pushListError(
                        constructedWarnings.customCategory,
                        rowString
                      );
                    }
                  }
                });
              }

              //-------------------------------------
              const fileErrors = processConstructedErrorsObject(
                constructedErrors,
                generateEmployeeErrorTitles
              );

              const fileWarnings = processConstructedErrorsObject(
                constructedWarnings,
                generateEmployeeErrorTitles
              );

              if (fileWarnings.length > 0) {
                setWarnings(fileWarnings);
              }

              if (fileErrors.length > 0 || emptyFile) {
                sheetIsValid = false;
              }

              if (sheetIsValid) {
                setProcessing(false);
              } else {
                setFiles({
                  files: [],
                  events: [
                    ...files.events,
                    `File failed validatation: ${event.affectedFiles[0].name}`,
                  ],
                  errors: fileErrors,
                  emptyFile: emptyFile,
                });
                setProcessing(false);
              }
            } catch (err: any) {
              throw err;
            }
          };

          reader.onerror = function (ex) {
            console.log(ex);
          };

          reader.readAsBinaryString(file.getRawFile());
        });
    };

    setProcessing(true);

    setFiles({
      files: event.newState,
      events: [
        ...files.events,
        `File selected: ${event.affectedFiles[0].name}`,
      ],
      errors: [],
    });

    afterStateChange();
    setProcessing(false);

    // clear state
    setWarnings([]);
  };

  const onRemove = (event: any) => {
    setFiles({
      files: event.newState,
      events: [...files.events, `File removed: ${event.affectedFiles[0].name}`],
      errors: [],
    });

    // clear state
    setWarnings([]);
    setProcessing(false);
  };

  const onProgress = (event: any) => {
    setFiles({
      files: event.newState,
      events: [
        ...files.events,
        `On Progress: ${event.affectedFiles[0].progress} %`,
      ],
      errors: [...files.errors],
    });
  };

  const onStatusChange = (event: any) => {
    const file = event.affectedFiles[0];

    // On success, clear state
    if (file.status === 4) {
      setWarnings([]);
    }

    setFiles({
      files: event.newState,
      events: [
        ...files.events,
        `File '${file.name}' status changed to: ${fileStatuses[file.status]}`,
      ],
      errors: [...files.errors],
    });
  };

  const onCompChange = (event: any, newValue: any) => {
    if (newValue) {
      setCompanyId(newValue.CompanyID);
    } else {
      setCompanyId(-1);
    }
  };

  const openModalGuide = () => {
    setModalGuideOpen(true);
  };

  const closeModalGuide = () => {
    setModalGuideOpen(false);
  };

  let employeeUploadView = (
    <Root>
      <Box display="flex" justifyContent="center">
        <Paper className={classes.boxSpace}>
          <Grid container className={classes.editForm} spacing={1}>
            <IppFormHeader
              title="Employees"
              isEditing={isEditing}
              isAdding={isAdding}
              returnPath="/benefits/employees"
            />
            <Grid item container xs justifyContent="flex-end">
              <IppButton
                onClick={openModalGuide}
                startIcon={<Info />}
                variant="text"
              >
                Guide
              </IppButton>
              {isModalGuideOpen && (
                <EmployeeUploadGuide
                  ft_ben_JobPosition={ft_ben_JobPosition}
                  ft_ben_CustomJobPosition={ft_ben_CustomJobPosition}
                  ft_ben_JobHistory={ft_ben_JobHistory}
                  hasRegionTypes={hasRegionTypes}
                  ft_ben_TempEmployee={ft_ben_TempEmployee}
                  ft_ben_Ethnicity={ft_ben_Ethnicity}
                  ft_ben_CustomEmpCategory={ft_ben_CustomEmpCategory}
                  closeModalGuide={closeModalGuide}
                />
              )}
            </Grid>
            <IppFormDivider title="Company & File Details" />
            <Grid item xs={6}>
              <IppAutocomplete
                id="CompanyID"
                label="Company"
                disabled={false}
                options={confirmedCompanies}
                value={confirmedCompanies.find((obj) => {
                  return obj.CompanyID === companyId;
                })}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                onChangeFunction={onCompChange}
                optionLabelFunction={(option: any) => option.CompanyName}
                textValueFunction={
                  !compIsLoading && companyId > 0
                    ? companiesById[companyId].CompanyName
                    : ""
                }
                autoPopulate={!currentProfile.IsClient ? true : false}
              />
            </Grid>

            <Grid item xs={12}>
              <FileUploadErrors
                errors={files.errors}
                emptyFile={files.emptyFile}
              />

              {files.errors.length === 0 ? (
                <FileUploadErrors
                  errors={warnings}
                  isWarning
                  extendedAlertTitle={
                    <span>
                      {" "}
                      After uploading this document, please proceed to the{" "}
                      <strong>Employees Needing Review</strong> page to ensure
                      all required data has been captured.
                    </span>
                  }
                />
              ) : (
                ""
              )}

              {warnings.length === 0 && !files.emptyFile ? (
                <Alert severity="info" sx={{ backgroundColor: "transparent" }}>
                  <AlertTitle>
                    After uploading this document, please proceed to the{" "}
                    <strong>Employees Needing Review</strong> page to ensure all
                    required data has been captured.
                  </AlertTitle>
                </Alert>
              ) : undefined}
            </Grid>

            <Grid item xs={12}>
              <Upload
                disabled={companyId === -1}
                showActionButtons={
                  !processing && files.errors.length === 0 && A0token !== ""
                }
                autoUpload={false}
                multiple={false}
                files={files.files}
                restrictions={{
                  allowedExtensions: [".xlsx"],
                }}
                withCredentials={true}
                onAdd={onAdd}
                onRemove={onRemove}
                onProgress={onProgress}
                onStatusChange={onStatusChange}
                onBeforeUpload={onBeforeUpload}
                saveUrl={`${baseURL}/employee/upload`}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Root>
  );

  return <div id="employee-upload-page">{employeeUploadView}</div>;
};
