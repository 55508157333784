import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCustomRnDs } from "./CustomRnDSlice";
import { useParams } from "react-router";
import LoadingIndicator from "components/LoadingIndicator";
import { Alert } from "@mui/material";
import { CustomRnDForm } from "./CustomRnDForm";
import { useTranslation } from "react-i18next";
interface CustomRnDDetProps {
  CustomRnDID: string;
}

export const CustomRnDDetailPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation(["strGen"]);
  const {
    isLoading: customRnDIsLoading,
    error,
    customRnDsById,
  } = useSelector((state: RootState) => state.customRnD);

  const { CustomRnDID } = useParams<CustomRnDDetProps>();
  const itemID = parseInt(CustomRnDID, 10);
  const paramErr = `${t(
    "strGen:errors.wentwrong"
  )} - ${itemID} is not a valid CustomRnD ID`;

  const customRnD = customRnDsById[itemID];

  useEffect(() => {
    (async () => {
      try {
        if (!customRnD?.ProjectName) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchAllCustomRnDs(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently, customRnD]);

  let CustomRnDDetView = customRnDIsLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      {/* parma is invalid */}
      {Number.isNaN(itemID) && (
        <Alert severity="error" sx={{ mb: 2 }}>
          <span>{paramErr}</span>
        </Alert>
      )}
      {/* get customRnDs error */}
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          <span>
            <b>{t("strGen:errors.wentwrong")}</b> - {error.toString()}
          </span>
        </Alert>
      )}
      {/* display sshe if exists */}
      {customRnD ? (
        <CustomRnDForm customRnD={customRnD} />
      ) : (
        <Alert severity="error" sx={{ mb: 2 }}>
          <span>{paramErr}</span>
        </Alert>
      )}
    </div>
  );
  return <div id="CustomRnD-detail-page">{CustomRnDDetView}</div>;
};
