import React from "react";
import { styled, alpha } from "@mui/material/styles";
import { AppBar, Toolbar, Typography } from "@mui/material";
import HeaderToolbar from "./headerToolbar";
import HeaderMenu from "./headerMenu";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useTypedTranslation } from "utils/customHooks";

const PREFIX = "Header";

const classes = {
  logo: `${PREFIX}-logo`,
  header: `${PREFIX}-header`,
  grow: `${PREFIX}-grow`,
  spacer: `${PREFIX}-spacer`,
  search: `${PREFIX}-search`,
  searchIcon: `${PREFIX}-searchIcon`,
  inputRoot: `${PREFIX}-inputRoot`,
  inputInput: `${PREFIX}-inputInput`,
  menu: `${PREFIX}-menu`,
};

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.headerColor!.main,
  color: theme.palette.getContrastText(theme.palette.headerColor!.main),
  [`& .${classes.logo}`]: {
    paddingRight: theme.spacing(2),
  },
  [`&.${classes.header}`]: {
    spacing: 1,
    // display: `"${showMenu}"`,
  },
  [`&.${classes.menu}`]: {},
  [`& .${classes.grow}`]: {
    flexGrow: 1,
  },
  [`& .${classes.spacer}`]: {
    flex: "1 1 auto",
  },
  [`& .${classes.search}`]: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.35),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.65),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  [`& .${classes.searchIcon}`]: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  [`& .${classes.inputRoot}`]: {
    color: "inherit",
  },
  [`& .${classes.inputInput}`]: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
// Modules List that need to be uppercase
const UpperCaseModuleList: string[] = ["sshe"];

const Header = () => {
  const loc = useSelector((state: RootState) => state.router.location);
  const t = useTypedTranslation(["strGen"]);
  const module = loc.pathname.split("/")[1];
  const [showMenu, setsShowMenu] = React.useState(true);

  const { clientName, logoPath } = useSelector(
    (state: RootState) => state.client
  );

  const { currentProfile, isLoading: profileIsLoading } = useSelector(
    (state: RootState) => state.profile
  );

  // can export this to allow triggering this toggle globally
  const setShowMenu = () => {
    setsShowMenu(!showMenu);
  };

  return (
    <StyledAppBar position="static" className={classes.header}>
      <div>
        <Toolbar>
          <a href={`/${clientName}/${module}`}>
            <img
              src={
                logoPath
                  ? logoPath
                  : process.env.PUBLIC_URL + "/logo_small_white.png"
              }
              alt={t("strGen:platform")}
              className={classes.logo}
            />
          </a>
          <div className={classes.grow} />
          {!profileIsLoading && (
            <Typography variant="body1">
              {t("strGen:header.welcome")}
              <strong>{currentProfile.FirstName}</strong>!
            </Typography>
          )}
          <HeaderToolbar displayMenu={setShowMenu} />
        </Toolbar>
        {showMenu && (
          <div>
            <HeaderMenu isUpperCase={UpperCaseModuleList.includes(module)} />
          </div>
        )}
      </div>
    </StyledAppBar>
  );
};

export default Header;
