import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchEmployees } from "./EmployeeSlice";
import { useParams } from "react-router-dom";

import { EmployeeForm } from "./EmployeeForm";
import LoadingIndicator from "components/LoadingIndicator";
import { Alert } from "@mui/material";

interface EmployeeDetProps {
  employeeID: string;
}

export const EmployeeDetailPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const {
    isLoading: employeeIsLoading,
    error: compError,
    employeesById,
  } = useSelector((state: RootState) => state.employees);

  // get employee ID from url param and attempt to find company in store
  const { employeeID } = useParams<EmployeeDetProps>();
  const payID = parseInt(employeeID, 10);

  const employee = employeesById[parseInt(employeeID, 10)];

  useEffect(() => {
    (async () => {
      try {
        // can avoid going to the DB if we navigated from inventory
        if (!employee || !employee.CompanyName) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchEmployees(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [employee, dispatch, getAccessTokenSilently]);

  let employeeDetView = employeeIsLoading ? (
    <LoadingIndicator />
  ) : (
    <>
      {compError && (
        <Alert severity="error" sx={{ mb: 2 }}>
          <span>
            <b>Something went wrong</b> - {compError.toString()}
          </span>
        </Alert>
      )}
      {typeof payID != "number" && (
        <Alert severity="error" sx={{ mb: 2 }}>
          <span>
            Something went wrong - {employeeID} is not a valid Company ID
          </span>
        </Alert>
      )}
      <div>{employee && <EmployeeForm employee={employee} />}</div>
    </>
  );

  return <div id="employee-detail-page">{employeeDetView}</div>;
};
