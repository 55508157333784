import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PaymentPeriodException,
  PaymentPeriodExceptionsResult,
  getPaymentPeriodExceptions,
} from "api/paymentPeriodExceptionAPI";
import { AppThunk } from "app/store";

interface PaymentPeriodExceptionState {
  paymentPeriodExceptionList: PaymentPeriodException[];
  isLoading: boolean;
  error: string | null;
}

const PaymentPeriodExceptionInitialState: PaymentPeriodExceptionState = {
  paymentPeriodExceptionList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: PaymentPeriodExceptionState) {
  state.isLoading = true;
}

function loadingFailed(
  state: PaymentPeriodExceptionState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const paymentPeriodExceptions = createSlice({
  name: "paymentPeriodException",
  initialState: PaymentPeriodExceptionInitialState,
  reducers: {
    getPaymentPeriodExceptionsStart: startLoading,
    getPaymentPeriodExceptionsSuccess(
      state,
      { payload }: PayloadAction<PaymentPeriodExceptionsResult>
    ) {
      const { paymentPeriodExceptions } = payload;
      state.isLoading = false;
      state.error = null;

      // NOTE: This reducer is simplified compared to other slicers due to the format of the data returned by the Stored Procedure involved. There is no unique ID for each record, so we don't map it by ID like eslewhere. Instead, the state is simply set to the payload to be displayed in a simple inventory list.

      state.paymentPeriodExceptionList = paymentPeriodExceptions;
    },
    getPaymentPeriodExceptionsFailure: loadingFailed,
  },
});

export const {
  getPaymentPeriodExceptionsStart,
  getPaymentPeriodExceptionsSuccess,
  getPaymentPeriodExceptionsFailure,
} = paymentPeriodExceptions.actions;

export default paymentPeriodExceptions.reducer;

export const fetchPaymentPeriodExceptions =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getPaymentPeriodExceptionsStart());
      const paymentPeriodExceptions = await getPaymentPeriodExceptions(
        accessToken
      );
      dispatch(getPaymentPeriodExceptionsSuccess(paymentPeriodExceptions));
    } catch (err: any) {
      dispatch(getPaymentPeriodExceptionsFailure(err.toString()));
    }
  };
