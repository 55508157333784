import axiosClient from "../axiosClient";

export interface StatusType {
  StatusTypeID: number;
  StatusTypeName: string;
}

export interface StatusTypesResult {
  statusTypes: StatusType[];
}

export async function getStatusTypes(
  accessToken: String
): Promise<StatusTypesResult> {
  const url = `/statusType`;

  try {
    const { data } = await axiosClient.get<StatusTypesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
