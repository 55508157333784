import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";
import SaveIcon from "@mui/icons-material/Save";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import CreateIcon from "@mui/icons-material/Create";
import {
  Alert,
  AlertTitle,
  Box,
  ButtonGroup,
  Grid,
  Paper,
  styled,
} from "@mui/material";
import { useFormik } from "formik";
import { Prompt } from "react-router";
import {
  Start,
  IncidentsStatistics,
  ContractualObligation,
  SafetyManagement,
  SummaryNotes,
  startFields,
  incidentsFields,
  contractualFields,
  safetyFields,
  summaryFields,
} from "./SsheEditSteps";
import { SsheValidationSchema } from "./SsheValidation";
import { RootState } from "app/rootReducer";
import { addSshe, delSshe, updSshe } from "./SsheSlice";
import { fetchProjects } from "features/project/ProjectSlice";
import { fetchCompanies } from "../benefits/companies/CompaniesSlice";
import {
  FirstDayInPreviousMonth,
  LastDayInPreviousMonth,
} from "utils/DateFunctions";
import { flatten } from "utils/functions";
import {
  ContractualObligationView,
  IncidentsStatisticsView,
  SafetyManagementView,
  StartView,
  SummaryNotesView,
} from "./SsheViewSteps";
import { IppFormHeader } from "components/IppFormHeader";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { useTypedTranslation } from "utils/customHooks";
import LoadingIndicator from "components/LoadingIndicator";
import { IppStepper, step } from "components/IppStepper";
import { getValidChangedValues } from "utils/formHelpers";
import { IppButton } from "components/Buttons/IppButton";
import { IppSaveButton } from "components/Buttons/IppSaveButton";
import { IppSubmitButton } from "components/Buttons/IppSubmitButton";
import { Save } from "@mui/icons-material";
import IppConfirmDialog from "components/IppConfirmDialog";

const PREFIX = "Sshe";

const classes = {
  editForm: `${PREFIX}-editForm`,
  viewForm: `${PREFIX}-viewForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    // minWidth: 650,
    maxWidth: 1150,
  },
  [`& .${classes.viewForm}`]: {
    // minWidth: 650,
    maxWidth: 1150,
  },
  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const SsheForm = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objSshe"]);

  // ------------------------ Get client info ----------------------------
  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );
  // fetch data on page load
  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchProjects(accessToken));
        dispatch(fetchCompanies(accessToken, clientId));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);
  const { currentProfile, currentUserRoleList, currentUserRolesById } =
    useSelector((state: RootState) => state.profile);
  // if non-admin, only show currentUserRoles correspondering to sshe Module (ModuleRoleID === 11)
  const currentUserRoles = currentProfile.IsClientAdmin
    ? currentUserRoleList.map((id) => currentUserRolesById[id])
    : currentUserRoleList
        .map((id) => currentUserRolesById[id])
        .filter((role) => role.ModuleRoleID === 11);

  // get project list
  // list of project IDs available in this Module
  const accessibleProjectIDs = currentUserRoles.map((role) => role.ProjectID);
  let {
    projectList,
    projectsById,
    isLoading: projectIsLoading,
    error: projectError,
  } = useSelector((state: RootState) => state.projects);

  // if admin, show all projects
  // if non-admin, only show projects included in accessibleProjectIds
  let projects = currentProfile.IsClientAdmin
    ? projectList.map((id) => projectsById[id])
    : projectList
        .map((id) => projectsById[id])
        .filter((project) => accessibleProjectIDs.includes(project.ProjectID));
  // companies
  let {
    companiesById,
    companyList,
    isLoading: companyIsLoading,
  } = useSelector((state: RootState) => state.companies);
  let companies = companyList.map((CompanyID) => companiesById[CompanyID]);
  let confirmedCompanies = companies.filter((comp) => comp.ListingConfirmed);
  // ------------------------ Stepper settings ----------------------------
  const [steps, setSteps] = useState<step[]>([
    {
      id: "start",
      label: t("objSshe:objects.form.steps.start"),
      error: undefined,
      fields: flatten(Object.values(startFields)),
    },
    {
      id: "incidents",
      label: t("objSshe:objects.form.steps.incidents"),
      error: undefined,
      fields: flatten(Object.values(incidentsFields)),
    },
    {
      id: "contractual",
      label: t("objSshe:objects.form.steps.contractual"),
      error: undefined,
      fields: flatten(Object.values(contractualFields)),
    },
    {
      id: "safety",
      label: t("objSshe:objects.form.steps.safety"),
      error: undefined,
      fields: flatten(Object.values(safetyFields)),
    },
    {
      id: "summary",
      label: t("objSshe:objects.form.steps.summary"),
      error: undefined,
      fields: flatten(Object.values(summaryFields)),
    },
  ]);

  // steps control
  const totalSteps = steps.length - 1;
  const [activeStep, setActiveStep] = useState(0);
  const isLastStep = activeStep === totalSteps;
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  // ------------------------ Form setup ----------------------------
  const defaultValues: {} = {
    CompanyID: currentProfile.CompanyID,
    ProjectID: !!projects?.length && projects[0].ProjectID,
    EndingDate: FirstDayInPreviousMonth(),
    CompletionDate: LastDayInPreviousMonth(),
    ExposureHours: 0,
    NumberInWorkforce: 0,
    ExposureHoursSubcontractor: 0,
    NoTreatment: 0,
    FirstAid: 0,
    MedicalTreatment: 0,
    RestrictedWork: 0,
    LostTime: 0,
    LTI_Description: "",
    RI_Description: "",
    SubcontractorIncidents: 0,
    SpillsReleases: 0,
    SafetyAlerts: 0,
    EnvironmentalAlerts: 0,
    StatisticsNotes: "",
    RandomTestingRequired: 0,
    RandomTestingCompleted: 0,
    RandomTestingCompliant: 0,
    RandomTestingNonCompliant: 0,
    ReasonableCauseRequired: 0,
    ReasonableCauseCompleted: 0,
    ReasonableCauseCompliant: 0,
    ReasonableCauseNonCompliant: 0,
    SafetyManagementNotes: "",
    PreAccessRequiredRecert: 0,
    PreAccessCompletedRecert: 0,
    PreAccessCompliantRecert: 0,
    PreAccessNonCompliantRecert: 0,
    PreAccessRequiredNew: 0,
    PreAccessCompletedNew: 0,
    PreAccessCompliantNew: 0,
    PreAccessNonCompliantNew: 0,
    PostIncidentRequired: 0,
    PostIncidentCompleted: 0,
    PostIncidentCompliant: 0,
    PostIncidentNonCompliant: 0,
    TB_Required: 0,
    TB_Completed: 0,
    TB_Compliant: 0,
    TB_NonCompliant: 0,
    ContractualNotes: "",
    BBO: 0,
    PermitAssessments: 0,
    HazID: 0,
    NearMisses: 0,
    PotentialHurt: 0,
    Walkthroughs: 0,
    WorkSiteInspections: 0,
    JSA: 0,
    CriticalProcedures: 0,
    ItemsOutstanding: 0,
    StatusOpenItems: "",
    ImprovementOpportunities: "",
    BestPractices: "",
    SafetyObjectives: "",
    CompletedBy: currentProfile?.UserAccountID,
    NotesComments: "",
    ProcessSafety: 0,
    ProcessSafetyAlerts: 0,
    VisitInPerson: 0,
    VisitVirtual: 0,
    IsSubmitted: 0,
  };

  const ssheData = props.sshe;
  const dataItem = props.sshe || defaultValues;
  const [isEditing, setIsEditing] = useState(dataItem.SSHE_ID ? false : true);
  const [isAdding, setIsAdding] = useState(dataItem.SSHE_ID ? false : true);
  const [isSaved, setIsSaved] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  // ------------------------ Edit Form ----------------------------
  const isPreviousStepsValid =
    steps
      .slice(0, activeStep)
      .findIndex((currentStep) => currentStep.error === true) === -1;

  // submit function
  const onSub = async (values: any) => {
    const subValues = {
      ...values,
      IsSubmitted: 1,
    };
    setIsSaved(true);
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
        },
      });
      dispatch(addSshe(accessToken, subValues, true));
    } catch (e) {
      console.error(e);
    }
  };
  let submitFunc = onSub;

  // editing form submit function
  if (!isAdding) {
    submitFunc = async (values: any) => {
      setIsSaved(true);
      const subValues = {
        ...values,
        IsSubmitted: 1,
      };
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        // form can be submitted in draft view or valid edit view
        if (!isEditing || (isLastStep && isPreviousStepsValid)) {
          dispatch(updSshe(accessToken, values.SSHE_ID, subValues, true));
        }
      } catch (e) {
        console.error(e);
      }
    };
  }

  const formik = useFormik({
    initialValues: dataItem,
    validationSchema: SsheValidationSchema(),
    onSubmit: submitFunc,
    validateOnChange: true,
  });

  // Check if the active step contains invalid fieldName
  const checkStepValidity = async (activeStepIndex: number) => {
    await formik.validateForm(formik.values);
    const activeStep = steps[activeStepIndex];
    const hasError = activeStep.fields?.some((field) => formik.errors[field]);
    const updatedSteps = steps.map((step, index) => ({
      ...step,
      error: index === activeStepIndex ? hasError : step.error,
    }));
    setSteps(updatedSteps);
  };
  useEffect(() => {
    checkStepValidity(activeStep);
  }, [formik.errors, activeStep]);

  const onDraft = async () => {
    // onSave draft:set invalid values to default values
    setIsSaved(true);
    const validValues = getValidChangedValues(formik);
    const updatedValues = {
      ...validValues,
      IsSubmitted: 0,
    };
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
        },
      });
      dispatch(addSshe(accessToken, updatedValues, true));
    } catch (e) {
      console.error(e);
    }
  };

  let onSaveDraft = onDraft;
  // Update Draft if edit from details page
  if (props.ssheID) {
    onSaveDraft = async () => {
      setIsSaved(true);
      const validValues = getValidChangedValues(formik);
      const updatedValues = {
        ...validValues,
        CompletedBy: currentProfile?.UserAccountID,
        IsSubmitted: 0,
      };
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(
          updSshe(accessToken, validValues.SSHE_ID, updatedValues, true)
        );
      } catch (e) {
        console.error(e);
      }
    };
  }
  const stepEditForms = [
    Start({
      formik,
      projects,
      projectIsLoading,
      projectsById,
      confirmedCompanies,
      companiesById,
      currentProfile,
      isEditing,
      setIsEditing,
    }),
    IncidentsStatistics({ formik, isEditing, setIsEditing }),
    ContractualObligation({ formik, isEditing, setIsEditing }),
    SafetyManagement({ formik, isEditing, setIsEditing }),
    SummaryNotes({ formik, isEditing, setIsEditing }),
  ];

  const currentEditStep = stepEditForms[activeStep];

  // Confirm Dialog Actions
  const submitAfterConfirm = () => {
    if (isAdding || isEditing) {
      formik.handleSubmit();
    } else {
      // used for submit btn in Edit banner (view form)
      submitFunc(dataItem);
    }
  };
  const openConfirm = async () => {
    if (!currentProfile.IsClientAdmin) {
      setConfirmOpen(true);
    } else {
      submitAfterConfirm();
    }
  };
  const handleConfirm = async () => {
    setConfirmOpen(false);
    submitAfterConfirm();
  };

  let editForm =
    clientIsLoading || projectIsLoading || companyIsLoading ? (
      <LoadingIndicator />
    ) : (
      <Paper className={classes.boxSpace}>
        {!isSaved && (
          <Prompt
            when={formik.dirty}
            message="You have unsaved changes. Are you sure you want to leave this page?"
          />
        )}
        <form
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            openConfirm(); // Call openConfirm before submitting the form
          }}
        >
          <Grid container className={classes.editForm} spacing={1}>
            <IppFormHeader
              title={`${t("objSshe:ssheReport")}`}
              returnTitle={`${t("objSshe:ssheList")}`}
              isEditing={props?.ssheID}
              isAdding={!props?.ssheID}
              returnPath="/sshe/sshe"
            />

            <Grid item xs={12}>
              <IppStepper
                steps={steps}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                viewOnly={false}
              />
            </Grid>
            <Grid container item padding={1} spacing={1} xs={12}>
              {/* render form fields based on active step */}
              {currentEditStep}
              {/* button controls */}
              <Grid
                container
                item
                xs={12}
                padding={1}
                spacing={1}
                justifyContent="space-between"
              >
                <Grid item>
                  <IppSaveButton
                    buttonText={t("strGen:buttons.saveDraft")}
                    startIcon={<SaveAsIcon />}
                    handleSave={onSaveDraft}
                  />
                  {activeStep > 0 && (
                    <IppButton type="button" onClick={handleBack} noTimeout>
                      {t("strGen:buttons.previous")}
                    </IppButton>
                  )}
                </Grid>
                <Grid item>
                  {isLastStep && (
                    <IppSubmitButton
                      buttonText={t("strGen:buttons.submit")}
                      startIcon={<SaveIcon />}
                      disabled={!isLastStep || !isPreviousStepsValid}
                    />
                  )}
                  {!isLastStep && (
                    <IppButton
                      type="button"
                      onClick={handleNext}
                      disabled={isLastStep}
                      noTimeout
                    >
                      {t("strGen:buttons.next")}
                    </IppButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    );

  // ------------------------ View Form ----------------------------
  // check if it is submitted; if yes, only clientAdmin can edit it
  const isSubmitted = props.isSubmitted;
  const showEdit =
    !isSubmitted || (isSubmitted && currentProfile.IsClientAdmin);

  const stepViewForms = [
    StartView({ ssheData, showEdit, isEditing, setIsEditing }),
    IncidentsStatisticsView({
      ssheData,
      showEdit,
      isEditing,
      setIsEditing,
    }),
    ContractualObligationView({
      ssheData,
      showEdit,
      isEditing,
      setIsEditing,
    }),
    SafetyManagementView({
      ssheData,
      showEdit,
      isEditing,
      setIsEditing,
    }),
    SummaryNotesView({
      ssheData,
      showEdit,
      isEditing,
      setIsEditing,
    }),
  ];
  const handleDelete = () => {
    // delete current SSHE form
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(delSshe(accessToken, ssheData?.SSHE_ID));
      } catch (e) {
        console.error(e);
      }
    })();
  };
  const currentViewForm = stepViewForms[activeStep];
  let viewForm = (
    <Paper className={classes.boxSpace}>
      <Grid container className={classes.viewForm} spacing={1}>
        <IppFormHeader
          title={`${t("objSshe:ssheReport")}`}
          returnTitle={`${t("objSshe:ssheList")}`}
          isEditing={isEditing}
          isAdding={isAdding}
          returnPath="/sshe/sshe"
        />
        {/* Edit banner*/}
        {showEdit && !isSubmitted && (
          <Grid item xs={12}>
            <Alert
              severity="warning"
              action={
                <ButtonGroup
                  variant="outlined"
                  aria-label="outlined button group"
                >
                  <IppButton
                    color="success"
                    startIcon={<CreateIcon />}
                    onClick={() => {
                      setIsEditing(true);
                    }}
                  >
                    {t("strGen:buttons.edit")}
                  </IppButton>
                  <IppButton startIcon={<Save />} onClick={() => openConfirm()}>
                    {t("strGen:buttons.submit")}
                  </IppButton>
                </ButtonGroup>
              }
            >
              <AlertTitle>
                {t("objSshe:objects.form.banner.draftTitle")}
              </AlertTitle>
              {t("objSshe:objects.form.banner.draftText")}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <IppStepper
            steps={steps}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            viewOnly={true}
          />
        </Grid>
        <Grid container item spacing={1} xs={12}>
          {/* Render form fields based on active step */}
          <Grid container item padding={1} xs={12}>
            {currentViewForm}
          </Grid>
          {/* Button controls */}
          <Grid
            container
            item
            xs={12}
            paddingLeft={2}
            flexWrap="nowrap"
            justifyContent={activeStep === 0 ? "flex-end" : "space-between"}
          >
            <Grid item>
              {activeStep !== 0 && (
                <IppButton
                  type="button"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                  noTimeout
                >
                  {t("strGen:buttons.previous")}
                </IppButton>
              )}
            </Grid>
            <Grid
              container
              flexDirection="column"
              alignItems="flex-end"
              gap={2}
            >
              <Grid item>
                {!isLastStep && (
                  <IppButton
                    disableTime={500}
                    type="button"
                    onClick={handleNext}
                    disabled={isLastStep}
                    noTimeout
                  >
                    {t("strGen:buttons.next")}
                  </IppButton>
                )}
              </Grid>
              {showEdit && (
                <Grid item width="100%">
                  <IppFormButtons
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    isAdding={isAdding}
                    resetFunction={() => formik.resetForm()}
                    showDelete={true}
                    deleteFunction={handleDelete}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );

  let SsheForm = (
    <Box display="flex" justifyContent="center">
      {isAdding || isEditing ? editForm : viewForm}
      {/* Confirm dialog for nonAdmin before submit */}
      <IppConfirmDialog
        title={t("strGen:prompts.submit.title", {
          fieldname: t("objSshe:ssheReport"),
        })}
        open={confirmOpen}
        setOpen={() => setConfirmOpen(!confirmOpen)}
        onConfirm={handleConfirm}
        content={
          t("strGen:prompts.submit.submitconfirm", {
            fieldname: t("objSshe:ssheReport"),
          }) +
          (isAdding || isEditing ? t("strGen:prompts.submit.saveasdraft") : "")
        }
        confirmText={t("strGen:buttons.submit")}
      />
    </Box>
  );

  return <Root>{SsheForm}</Root>;
};
