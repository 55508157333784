import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { PopupPosition } from "notificationapi-js-client-sdk/lib/interfaces";
import { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

import NotificationAPI from "notificationapi-js-client-sdk";
import "notificationapi-js-client-sdk/dist/styles.css";

const Root = styled("div")(({ theme }) => ({
  ".notificationapi-button": {
    color: "inherit !important",
  },
}));

// component is memo-ized to ensure it doesn't reinitialize the SDK
const IppNotificationButton = memo(() => {
  const { t } = useTranslation(["strGen"]);
  const { currentProfile, isLoading, error } = useSelector(
    (state: RootState) => state.profile
  );

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  useEffect(() => {
    if (currentProfile.UserAccountID) {
      // get prefix so that dev/stg users don't conflict with prod
      const prefix = process.env.REACT_APP_NOTIFICATIONAPI_PREFIX || "";

      // configure notification API and show bell
      const notificationAPI = new NotificationAPI({
        clientId: "6gihkpha9f1n26d34tq2ba5ghn",
        userId: prefix + currentProfile.UserAccountID.toString(),
        userIdHash: currentProfile.NotifApiHashedUID,
      });
      notificationAPI.showInApp({
        root: "buttonRoot", // root element containing the button
        popupPosition: PopupPosition.BottomLeft,
      });
    }
  }, [currentProfile]);

  return (
    <Root>
      <Tooltip title={t("strGen:header.icons.notifications.tooltip")}>
        <div id="buttonRoot"></div>
      </Tooltip>
    </Root>
  );
});

export default IppNotificationButton;
