import { Grid, Typography } from "@mui/material";
import { IppDisplayField } from "components/IppDisplayField";
import { ConvertToJSDate } from "utils/DateFunctions";
import { useTypedTranslation } from "utils/customHooks";
import {
  startFields,
  incidentsFields,
  contractualFields,
  safetyFields,
  summaryFields,
} from "./SsheEditSteps";
import { IppDisplayRichText } from "components/IppDisplayRichText";

type FormField = {
  [key: string]: string[];
};
export const getDisplayField = (props: any) => {
  const { value, label, isEditing, setIsEditing, showEdit } = props;
  return (
    <IppDisplayField
      label={label || ""}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      value={value}
      showEdit={showEdit}
    />
  );
};
export const getRichTextField = (props: any) => {
  const { value, label, isEditing, setIsEditing, showEdit } = props;
  return (
    <Grid container flexDirection="column" rowSpacing={1}>
      <IppDisplayRichText
        label={label || ""}
        value={value}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        showEdit={showEdit}
      />
    </Grid>
  );
};

function getAllValuesOfKeysArray(
  fields: Partial<FormField>,
  keys: string[]
): string[] {
  return Object.entries(fields).flatMap(([currentKey, value]) =>
    keys.includes(currentKey) && Array.isArray(value) ? value : []
  ) as string[];
}

export const StartView = (props: any) => {
  const t = useTypedTranslation(["objSshe"]);
  const { ssheData, showEdit, isEditing, setIsEditing } = props;
  const modifiedData = {
    ...ssheData,
    EndingDate: ConvertToJSDate(ssheData?.EndingDate),
    CompletionDate: ConvertToJSDate(ssheData?.CompletionDate),
  };
  const viewStartFields = {
    ...startFields,
    select: ["CompanyName", "ProjectName"],
  };
  const startDisplayFields = getAllValuesOfKeysArray(viewStartFields, [
    "select",
    "date",
    "number",
  ])?.map((field) => {
    return {
      id: field,
      value: modifiedData ? modifiedData[field]?.toString() : "",
      label: t(`objSshe:objects.form.fields.${field}`),
      isEditing: isEditing,
      setIsEditing: setIsEditing,
      showEdit: showEdit,
    };
  });
  return (
    <Grid container padding={1} spacing={1}>
      {startDisplayFields?.map((field, index) => {
        return (
          <Grid key={index} item xs={12} md={6}>
            {getDisplayField(field)}
          </Grid>
        );
      })}
    </Grid>
  );
};

export const IncidentsStatisticsView = (props: any) => {
  const t = useTypedTranslation(["objSshe"]);
  const { ssheData, showEdit, isEditing, setIsEditing } = props;
  const incidentsDisplayFields = incidentsFields.number?.map((field) => {
    return {
      id: field,
      value: ssheData ? ssheData[field]?.toString() : "",
      label: t(`objSshe:objects.form.fields.${field}`),
      isEditing: isEditing,
      setIsEditing: setIsEditing,
      showEdit: showEdit,
    };
  });
  const incidentsRichFields = incidentsFields.text?.map((field) => {
    return {
      id: field,
      value: ssheData ? ssheData[field] : "",
      label: t(`objSshe:objects.form.fields.${field}`),
      isEditing: isEditing,
      setIsEditing: setIsEditing,
      showEdit: showEdit,
    };
  });
  return (
    <Grid container padding={1} spacing={1}>
      <Grid container item xs={12} padding={1} spacing={1}>
        {incidentsDisplayFields?.slice(0, 5).map((field, index) => {
          return (
            <Grid key={index} item xs={12} md={6}>
              {getDisplayField(field)}
            </Grid>
          );
        })}
      </Grid>
      {incidentsRichFields?.slice(0, 2).map((field, index) => {
        return (
          <Grid key={index} item xs={12} md={6} marginTop={1}>
            {getRichTextField(field)}
          </Grid>
        );
      })}
      <Grid container item xs={12} padding={1} spacing={1}>
        {incidentsDisplayFields?.slice(5).map((field, index) => {
          return (
            <Grid key={index} item xs={12} md={6}>
              {getDisplayField(field)}
            </Grid>
          );
        })}
      </Grid>
      {incidentsRichFields?.slice(2).map((field, index) => {
        return (
          <Grid key={index} item xs={12} marginTop={1}>
            {getRichTextField(field)}
          </Grid>
        );
      })}
    </Grid>
  );
};

export const ContractualObligationView = (props: any) => {
  const t = useTypedTranslation(["objSshe"]);
  const { ssheData, showEdit, isEditing, setIsEditing } = props;
  const contractualDisplayFields = contractualFields.number?.map((field) => {
    return {
      id: field,
      value: ssheData ? ssheData[field]?.toString() : "",
      label: t(`objSshe:objects.form.fields.${field}`),
      isEditing: isEditing,
      setIsEditing: setIsEditing,
      showEdit: showEdit,
    };
  });
  const contractualRichFields = contractualFields.text?.map((field) => {
    return {
      id: field,
      value: ssheData ? ssheData[field] : "",
      label: t(`objSshe:objects.form.fields.${field}`),
      isEditing: isEditing,
      setIsEditing: setIsEditing,
      showEdit: showEdit,
    };
  });
  return (
    <Grid container padding={1} spacing={1}>
      {contractualDisplayFields?.slice(0, 2).map((field, index) => {
        return (
          <Grid key={index} item xs={12} md={6}>
            {getDisplayField(field)}
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Typography component="h4" color="primary" fontWeight="">
          {t("objSshe:objects.form.title.A&DRandom")}
        </Typography>
      </Grid>
      {contractualDisplayFields?.slice(2, 6).map((field, index) => {
        return (
          <Grid key={index} item xs={12} md={6}>
            {getDisplayField(field)}
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Typography component="h4" color="primary" fontWeight="">
          {t("objSshe:objects.form.title.A&DReasonable")}
        </Typography>
      </Grid>
      {contractualDisplayFields?.slice(6, 10).map((field, index) => {
        return (
          <Grid key={index} item xs={12} md={6}>
            {getDisplayField(field)}
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Typography component="h4" color="primary">
          {t("objSshe:objects.form.title.A&DPre")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography component="h4" color="#808080">
          {t("objSshe:objects.form.title.newWorker")}
        </Typography>
      </Grid>
      {contractualDisplayFields?.slice(10, 14).map((field, index) => {
        return (
          <Grid key={index} item xs={12} md={6}>
            {getDisplayField(field)}
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Typography component="h4" color="#808080">
          {t("objSshe:objects.form.title.recert")}
        </Typography>
      </Grid>
      {contractualDisplayFields?.slice(14, 18).map((field, index) => {
        return (
          <Grid key={index} item xs={12} md={6}>
            {getDisplayField(field)}
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Typography component="h4" color="primary">
          {t("objSshe:objects.form.title.A&DPost")}
        </Typography>
      </Grid>
      {contractualDisplayFields?.slice(18, 22).map((field, index) => {
        return (
          <Grid key={index} item xs={12} md={6}>
            {getDisplayField(field)}
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Typography component="h4" color="primary" fontWeight="">
          {t("objSshe:objects.form.title.TCP")}
        </Typography>
      </Grid>
      {contractualDisplayFields?.slice(22, 26).map((field, index) => {
        return (
          <Grid key={index} item xs={12} md={6}>
            {getDisplayField(field)}
          </Grid>
        );
      })}
      {contractualRichFields?.map((field, index) => {
        return (
          <Grid key={index} item xs={12} marginTop={1}>
            {getRichTextField(field)}
          </Grid>
        );
      })}
    </Grid>
  );
};

export const SafetyManagementView = (props: any) => {
  const t = useTypedTranslation(["objSshe"]);
  const { ssheData, showEdit, isEditing, setIsEditing } = props;
  const safetyDisplayFields = safetyFields.number?.map((field) => {
    return {
      id: field,
      value: ssheData ? ssheData[field]?.toString() : "",
      label: t(`objSshe:objects.form.fields.${field}`),
      isEditing: isEditing,
      setIsEditing: setIsEditing,
      showEdit: showEdit,
    };
  });
  const safetyRichFields = safetyFields.text?.map((field) => {
    return {
      id: field,
      value: ssheData ? ssheData[field] : "",
      label: t(`objSshe:objects.form.fields.${field}`),
      isEditing: isEditing,
      setIsEditing: setIsEditing,
      showEdit: showEdit,
    };
  });
  return (
    <Grid container padding={1} spacing={1}>
      {safetyDisplayFields?.map((field, index) => {
        return (
          <Grid key={index} item xs={12} md={index < 8 ? 6 : 12}>
            {getDisplayField(field)}
          </Grid>
        );
      })}
      {safetyRichFields?.map((field, index) => {
        return (
          <Grid key={index} item xs={12} marginTop={1}>
            {getRichTextField(field)}
          </Grid>
        );
      })}
    </Grid>
  );
};

export const SummaryNotesView = (props: any) => {
  const t = useTypedTranslation(["objSshe"]);
  const { ssheData, showEdit, isEditing, setIsEditing } = props;
  const summaryRichFields = summaryFields.text?.map((field) => {
    return {
      id: field,
      value: ssheData ? ssheData[field] : "",
      label: t(`objSshe:objects.form.fields.${field}`),
      isEditing: isEditing,
      setIsEditing: setIsEditing,
      showEdit: showEdit,
    };
  });
  return (
    <Grid container padding={1} spacing={1}>
      {summaryRichFields?.map((field, index) => {
        return (
          <Grid key={index} item xs={12} marginTop={1}>
            {getRichTextField(field)}
          </Grid>
        );
      })}
    </Grid>
  );
};
