import { useMemo } from "react";
import { useTypedTranslation } from "utils/customHooks";

export const useRecordTypeLabel = () => {
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);

  return useMemo(() => {
    const recordTypeMappings: Record<string, string> = {
      Contact: t("objPlt:objects.contact.name"),
      Commitment: t("objCom:objects.commitment.name"),
      Project: t("objPlt:objects.project.name"),
      Interaction: t("objStk:objects.interaction.name"),
    };

    // Function to get label for a given record type
    const getLabel = (recordType: string): string => {
      return recordTypeMappings[recordType] || recordType; // Default to original type if not found
    };

    // Function to get sorted labels from a list of record types
    const getSortedLabels = (
      recordTypes: string[]
    ): { value: string; label: string }[] => {
      return (
        recordTypes
          ?.map((type) => ({
            value: type,
            label: getLabel(type),
          }))
          .sort((a, b) => a.label.localeCompare(b.label)) ?? []
      );
    };

    // Function to find the record type by label
    const getTypeByLabel = (label: string): string | null => {
      const entries = Object.entries(recordTypeMappings);
      for (const [type, mappedLabel] of entries) {
        if (mappedLabel === label) {
          return type;
        }
      }
      return null; // Return null if not found
    };

    return { getLabel, getSortedLabels, getTypeByLabel };
  }, [t]);
};
