import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const HierarchyBanner = ({
  items,
}: {
  items: { label: string; value: string; link?: string }[];
}) => (
  <Box
    sx={{
      backgroundColor: "#e0e0e0",
      width: "100%",
      marginTop: "10px",
      marginLeft: "8px",
      paddingLeft: "5px",
    }}
  >
    <Grid container spacing={2}>
      {items.map((item, index) => (
        <Grid item xs={6} key={index}>
          <Typography variant="subtitle1">
            <b>{item.label}</b>:
          </Typography>
          {item.link ? (
            <Link to={item.link}>
              <Typography variant="body2">{item.value}</Typography>
            </Link>
          ) : (
            <Typography variant="body2">{item.value}</Typography>
          )}
        </Grid>
      ))}
    </Grid>
  </Box>
);
