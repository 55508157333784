import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";

import { ProjectForm } from "./ProjectForm";
import LoadingIndicator from "components/LoadingIndicator";

interface ProjDetProps {
  projectID: string;
}

export const ProjectCreatePage = () => {
  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  let projCreateView = clientIsLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <ProjectForm project={{ ClientID: clientId }} />
    </div>
  );

  return <div id="project-detail-page">{projCreateView}</div>;
};
