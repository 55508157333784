export const MAX_CURRENCY_VALUE = 99999999999.0;
export const MIN_CURRENCY_VALUE = -99999999999.0;

export const MAX_INTEGER_VALUE = 999999999;
export const MIN_INTEGER_VALUE = -999999999;

export const MAX_DECIMAL_8_2_VALUE = 999999.99;
export const MIN_DECIMAL_8_2_VALUE = -999999.99;

// TAGS Constants
export type TagRecordType =
  | "Contact"
  | "Commitment"
  | "Project"
  | "Interaction";

export const VALID_RECORD_TYPES = [
  "Contact",
  "Commitment",
  "Project",
  "Interaction",
];

// Standard Statements Constants
export type StandardStatementType =
  | "InteractionDetails"
  | "InteractionInternalNotes"
  | "InteractionActionDetails"
  | "CommitmentDetails"
  | "CommitmentInternalNotes"
  | "CommitmentLegalText"
  | "CommitmentActionDetails"
  | "InitiativeDetails"
  | "PaymentPeriodNotes";

// Allowed Attachment file types
export const allowedExtensions = [
  ".png",
  ".svg",
  ".gif",
  ".jpg",
  ".jpeg",
  ".pdf",
  ".doc",
  ".docx",
  ".xls",
  ".xlsx",
  ".ppt",
  ".pptx",
  ".kmz",
  ".kml",
  ".zip",
  ".msg",
  ".html",
  ".htm",
  ".rtf",
  ".shp",
  ".mp3",
  ".cpg",
  ".dbf",
  ".prj",
  ".sbn",
  ".sbx",
  ".shx",
  ".cpg",
  ".xml",
  ".xlsm",
  ".vcf",
  ".bmp",
];
