import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchActionReminders } from "./ActionReminderSlice";
import { useParams } from "react-router-dom";

import { ActionReminderForm } from "./ActionReminderForm";
import LoadingIndicator from "components/LoadingIndicator";

interface ActionReminderDetProps {
  actionReminderID: string;
}

export const ActionReminderDetailPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const {
    isLoading,
    error: compError,
    actionRemindersById,
  } = useSelector((state: RootState) => state.actionReminders);

  // get actionReminder ID from url param and attempt to find company in store
  const { actionReminderID } = useParams<ActionReminderDetProps>();
  const itemID = parseInt(actionReminderID, 10);

  const actionReminder = actionRemindersById[parseInt(actionReminderID, 10)];

  useEffect(() => {
    (async () => {
      try {
        // can avoid going to the DB if we navigated from inventory
        if (!actionReminder) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchActionReminders(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [actionReminder, dispatch, getAccessTokenSilently]);

  if (typeof itemID != "number") {
    return (
      <div>
        <h1>Something went wrong...</h1>
        <div>{actionReminderID} is not a valid Action Reminder ID</div>
      </div>
    );
  }

  if (compError) {
    return (
      <div>
        <h1>Something went wrong...</h1>
        <div>{compError.toString()}</div>
      </div>
    );
  }

  let actionReminderDetView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      {actionReminder && <ActionReminderForm actionReminder={actionReminder} />}
    </div>
  );

  return <div id="actionReminder-detail-page">{actionReminderDetView}</div>;
};
