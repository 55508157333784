import { ThreadForm } from "./ThreadForm";

export const ThreadCreatePage = () => {
  let threadCreateView = (
    <div>
      <ThreadForm />
    </div>
  );

  return <div id="thread-create-page">{threadCreateView}</div>;
};
