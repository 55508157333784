import * as yup from "yup";
import * as RegexConstants from "../../../validation/regexConstants";
import { useTypedTranslation } from "utils/customHooks";

export const RnDExpenseValidationSchema = (isAdding: boolean) => {
  const t = useTypedTranslation(["objPlt", "strGen", "objBen"]);

  let validationSchema: Record<string, yup.AnySchema> = {
    InitiativeID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.initiative.name"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.initiative.name"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.initiative.name"),
        })
      ),
    Reference: yup
      .string()
      .trim()
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.rndExpense.fields.reference"),
          count: 50,
        })
      )
      .nullable()
      .typeError(t("strGen:validation.invalidformat")),
    Classification: yup
      .string()
      .trim()
      .max(
        250,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.rndExpense.fields.classification"),
          count: 250,
        })
      )
      .nullable()
      .typeError(t("strGen:validation.invalidformat")),
    ExpenseDate: yup
      .date()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.rndExpense.fields.expensedate"),
        })
      )
      .max(
        new Date(),
        t("strGen:validation.date.nofuturedate", {
          fieldname: t("objBen:objects.rndExpense.fields.expensedate"),
        })
      )
      .typeError(t("strGen:validation.date.improperformat")),
    CompanyID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.company.name"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.company.name"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.company.name"),
        })
      ),
    AssetID: yup
      .number()
      .positive(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.asset.name"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.asset.name"),
        })
      )
      .when("Applied", (applied, schema) => {
        return applied[0]
          ? schema.required(
              t("strGen:validation.required", {
                fieldname: t("objBen:objects.asset.name"),
              })
            )
          : schema.nullable();
      }),
    Applied: yup
      .boolean()
      .nullable() // equates to false, handles bad data
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.rndExpense.fields.applied"),
        })
      ),
    AppliedDate: yup
      .date()
      .max(
        new Date(),
        t("strGen:validation.date.nofuturedate", {
          fieldname: t("objBen:objects.rndExpense.fields.applieddate"),
        })
      )
      .typeError(
        t("strGen:validation.date.yearformat", {
          fieldname: t("objBen:objects.rndExpense.fields.applieddate"),
        })
      )
      .when("Applied", (applied, schema) => {
        if (applied[0]) {
          return schema.required(
            t("strGen:validation.required", {
              fieldname: t("objBen:objects.rndExpense.fields.applieddate"),
            })
          );
        } else {
          return schema.nullable().notRequired();
        }
      }),
    SourceOfFunds: yup
      .string()
      .trim()
      .max(
        100,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.rndExpense.fields.sourceoffunds"),
          count: 100,
        })
      )
      .nullable()
      .typeError(t("strGen:validation.invalidformat")),
    InEligible: yup.boolean().nullable(),
  };
  if (!isAdding) {
    validationSchema["ExpenseAmount"] = yup
      .number()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.rndExpense.fields.expenseamount"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.rndExpense.fields.expenseamount"),
        })
      );

    validationSchema["Classification"] = yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.entExpense.fields.classification"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.entExpense.fields.classification"),
        })
      );
  } else {
    validationSchema["RnDExpenses"] = yup
      .array()
      .required("At least one expense is required")
      .min(
        1,
        t("strGen:validation.morethanone", {
          fieldname: t("objBen:objects.rndExpense.fields.expenseamount"),
          count: 1,
        })
      )
      .test(
        "RnDExpenses",
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.rndExpense.expense"),
        }),
        (arrayValue) => {
          return (
            !!arrayValue &&
            arrayValue.length > 0 &&
            arrayValue.filter(
              (v) =>
                v.classification &&
                (v.amount !== undefined || v.amount !== null)
            ).length === arrayValue.length
          );
        }
      );
  }

  return yup.object().shape(validationSchema);
};
