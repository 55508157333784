import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { ChangeEvent, useState } from "react";
import { useFormik } from "formik";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { Grid as KendoGrid, GridColumn } from "@progress/kendo-react-grid";
import { Delete, Edit } from "@mui/icons-material";
import { useTypedTranslation } from "utils/customHooks";
import {
  addAssetPercentage,
  updAssetPercentage,
  delAssetPercentage,
} from "./AssetPercentageSlice";
import { IppAutocomplete } from "components/IppAutocomplete";
import { Asset } from "api/benefits/assetAPI";
import { AssetPercentageValidationSchema } from "./AssetPercentageValidation";
import { IppPercentField } from "components/IppPercentField";
import { IppButton } from "components/Buttons/IppButton";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppAddButton } from "components/Buttons/IppAddButton";
import { IppDeleteButton } from "components/Buttons/IppDeleteButton";
import { IppCancelButton } from "components/Buttons/IppCancelButton";

const PREFIX = "AssetPercentagePage";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
  roleCard: `${PREFIX}-roleCard`,
  kgrid: `${PREFIX}-kgrid`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1100,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
    //maxWidth: 900,
  },
  [`& .${classes.kgrid}`]: {
    tableLayout: "fixed",
  },

  [`& .${classes.roleCard}`]: {
    minWidth: 250,
    minHeight: 300,
  },
}));

interface assetPercentageProps {
  asset: Asset;
}

export const AssetPercentagePage = (props: assetPercentageProps) => {
  const { asset } = props;

  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [editItem, setEditItem] = useState({
    AssetPercentageID: 0, // will be deleted on backend for adding
    AssetID: asset.AssetID,
    CompanyID: null,
    Percentage: null,
  });
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);

  isAdding ??
    setEditItem({
      AssetPercentageID: 0,
      AssetID: asset.AssetID,
      CompanyID: null,
      Percentage: null,
    });

  const { assetPercentageList, assetPercentagesById, error } = useSelector(
    (state: RootState) => state.assetPercentages
  );

  const assetPercentages = assetPercentageList.map(
    (id) => assetPercentagesById[id]
  );

  const assetPercentageCompanies = assetPercentages.map((ap) => ap.CompanyID);

  const wholeAssetPercentages = assetPercentages.map((ap) => {
    return {
      ...ap,
      WholePercentage: (Math.round(ap.Percentage * 1000) / 10).toFixed(1) + "%",
    };
  });

  const {
    isLoading: companyIsLoading,
    companiesById,
    companyList,
  } = useSelector((state: RootState) => state.companies);

  const companies = companyList
    .map((id) => companiesById[id])
    .filter((comp) => !assetPercentageCompanies.includes(comp.CompanyID));

  const confirmedCompanies = companies.filter((comp) => comp.ListingConfirmed);

  const submitFunc = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        if (isAdding) {
          dispatch(addAssetPercentage(accessToken, values));
        } else {
          dispatch(
            updAssetPercentage(accessToken, editItem.AssetPercentageID, values)
          );
        }
        setEditOpen(false);
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const editCell = (props: any) => {
    return (
      <td>
        <Tooltip
          title={t("strGen:buttons.editobj", {
            objectname: t("objBen:objects.assetpercentage.name"),
          })}
        >
          <IppButton
            variant="text"
            startIcon={<Edit />}
            onClick={() => {
              setEditItem(props.dataItem);
              setIsAdding(false);
              setEditOpen(true);
              formik.setValues(props.dataItem);
            }}
          />
        </Tooltip>
      </td>
    );
  };

  const deleteCell = (props: any) => {
    return (
      <td>
        <Tooltip
          title={t("strGen:buttons.deleteobj", {
            objectname: t("objBen:objects.assetpercentage.name"),
          })}
        >
          <IppButton
            variant="text"
            startIcon={<Delete />}
            color="error"
            onClick={() => {
              setEditItem(props.dataItem);
              setDeleteOpen(true);
            }}
          />
        </Tooltip>
      </td>
    );
  };

  const deleteFunc = () => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        dispatch(
          delAssetPercentage(
            accessToken,
            editItem.AssetPercentageID,
            asset.AssetID
          )
        );
        setDeleteOpen(false);
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const formik = useFormik({
    initialValues: editItem,
    validationSchema: AssetPercentageValidationSchema(
      asset.OwnerPercentage,
      editItem.Percentage
    ),
    onSubmit: submitFunc,
    enableReinitialize: true,
  });

  let assetPercentage = error ? (
    <Typography color="error">{error}</Typography>
  ) : (
    <Root>
      <Box className={classes.boxSpace}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography variant="h5" component="h3">
              {t("objBen:objects.assetpercentage.fields.percentage")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent="flex-end">
              <IppAddButton
                buttonText={t("strGen:buttons.addobj", {
                  objectname: t("objBen:objects.initiative.fields.ownerother"),
                })}
                onClick={() => {
                  setIsAdding(true);
                  setEditOpen(true);
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <KendoGrid
              data={wholeAssetPercentages}
              total={wholeAssetPercentages.length}
            >
              <GridColumn
                field="CompanyName"
                title={t("objBen:objects.company.name")}
              />

              <GridColumn
                field="WholePercentage"
                title={t("objBen:objects.assetpercentage.fields.percentage")}
              />
              <GridColumn
                cell={editCell}
                width="50px"
                field="PhaseID"
                title=" "
              />
              <GridColumn cell={deleteCell} width="50px" title=" " />
            </KendoGrid>
          </Grid>
        </Grid>
      </Box>

      <Dialog
        maxWidth="sm"
        open={editOpen}
        onClose={() => {
          setEditOpen(false);
          formik.resetForm();
        }}
      >
        <DialogTitle>
          {isAdding
            ? t("strGen:buttons.addobj", {
                objectname: t("objBen:objects.assetpercentage.name"),
              })
            : t("strGen:buttons.editobj", {
                objectname: t("objBen:objects.assetpercentage.name"),
              })}
        </DialogTitle>
        <DialogContent>
          <form noValidate onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <IppAutocomplete
                  id="CompanyID"
                  options={confirmedCompanies.sort((a, b) =>
                    a.ShowAsActive.localeCompare(b.ShowAsActive)
                  )}
                  groupBy={(option: any) => option.ShowAsActive}
                  value={confirmedCompanies.find((obj) => {
                    return obj.CompanyID === formik.values.CompanyID;
                  })}
                  onChangeFunction={(event: ChangeEvent, newValue: any) => {
                    if (newValue) {
                      formik.setFieldValue("CompanyID", newValue.CompanyID);
                    } else {
                      formik.setFieldValue("CompanyID", null);
                    }
                  }}
                  label={t("objBen:objects.company.name")}
                  required={true}
                  isEditing={true}
                  setIsEditing={null}
                  //disabled={isEditing && !isAdding}
                  optionLabelFunction={(option: any) => option.CompanyName}
                  touchedFunction={formik.touched.CompanyID}
                  errorFunction={formik.errors.CompanyID}
                  textValueFunction={
                    !companyIsLoading &&
                    formik.values.CompanyID &&
                    companiesById[formik.values.CompanyID]
                      ? companiesById[formik.values.CompanyID].CompanyName
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <IppPercentField
                  id="Percentage"
                  label={t("objBen:objects.assetpercentage.fields.percentage")}
                  required={true}
                  value={formik.values.Percentage}
                  onChangeFunction={formik.handleChange}
                  errorsExpression={formik.errors.Percentage}
                  touchedExpression={formik.touched.Percentage}
                  isEditing={true}
                  setIsEditing={null}
                  //placeholder="0.50 not 50%"
                />
              </Grid>

              <Grid item xs={12}>
                <IppFormButtons
                  isEditing={true}
                  setIsEditing={null}
                  isAdding={isAdding}
                  showCancel={true}
                  resetFunction={() => {
                    setEditOpen(false);
                    formik.resetForm();
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
        <DialogTitle>Delete item</DialogTitle>
        <DialogContent>
          <DialogContentText variant="body1">
            You have selected to delete this item.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IppDeleteButton onClick={() => deleteFunc()} />
          <IppCancelButton onClick={() => setDeleteOpen(false)} />
        </DialogActions>
      </Dialog>
    </Root>
  );

  return <div>{assetPercentage}</div>;
};
