import axiosClient from "./axiosClient";

export interface ClientModule {
  ClientModuleID: number;
  ModuleID: number;
  ModuleName: string;
  ShortName: string;
  ReportID: any;
}

export interface ClientModulesResult {
  clientModules: ClientModule[];
}

export async function getClientModules(
  accessToken: String
): Promise<ClientModulesResult> {
  const url = `/clientModules`;

  try {
    const { data } = await axiosClient.get<ClientModulesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
