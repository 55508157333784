import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ModuleRole,
  ModuleRolesResult,
  getModuleRoles,
} from "api/moduleRoleAPI";
import { AppThunk } from "app/store";

interface ModuleRoleState {
  moduleRolesById: Record<number, ModuleRole>;
  moduleRoleList: number[];
  isLoading: boolean;
  error: string | null;
}

const ModuleRoleInitialState: ModuleRoleState = {
  moduleRolesById: {},
  moduleRoleList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: ModuleRoleState) {
  state.isLoading = true;
}

function loadingFailed(state: ModuleRoleState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const moduleRoles = createSlice({
  name: "moduleroles",
  initialState: ModuleRoleInitialState,
  reducers: {
    getModuleRolesStart: startLoading,
    getModuleRolesSuccess(
      state,
      { payload }: PayloadAction<ModuleRolesResult>
    ) {
      const { moduleRoles } = payload;
      // console.log("PAYLOAD", moduleRoles)
      state.isLoading = false;
      state.error = null;

      moduleRoles.forEach((moduleRole) => {
        state.moduleRolesById[moduleRole.ModuleRoleID] = moduleRole;
      });

      state.moduleRoleList = moduleRoles.map(
        (moduleRole) => moduleRole.ModuleRoleID
      );
    },
    getModuleRolesFailure: loadingFailed,
  },
});

export const {
  getModuleRolesStart,
  getModuleRolesSuccess,
  getModuleRolesFailure,
} = moduleRoles.actions;

export default moduleRoles.reducer;

export const fetchModuleRoles =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getModuleRolesStart());
      const moduleRoles = await getModuleRoles(accessToken);
      //console.log("RETURN", moduleRoles)
      dispatch(getModuleRolesSuccess(moduleRoles));
    } catch (err: any) {
      dispatch(getModuleRolesFailure(err.toString()));
    }
  };
