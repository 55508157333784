import { RnDExpenseForm } from "./RnDExpenseForm";

export const RnDExpenseCreatePage = () => {
  let RnDExpenseCreateView = (
    <div>
      <RnDExpenseForm />
    </div>
  );

  return <div id="RnDExpense-detail-page">{RnDExpenseCreateView}</div>;
};
