import axiosClient from "api/axiosClient";

export interface CommitmentActionSummary {
  ItemID: number;
  Completed: number;
  Outstanding: number;
  Overdue: number;
}

export interface CommitmentActionSummarysResult {
  commitmentActionSummarys: CommitmentActionSummary[];
}

export async function getCommitmentActionSummarys(
  accessToken: String
): Promise<CommitmentActionSummarysResult> {
  const url = `/commitmentHomePage/actionSummary`;
  try {
    const { data } = await axiosClient.get<CommitmentActionSummarysResult>(
      url,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}
