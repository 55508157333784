import axiosClient from "./axiosClient";

export interface Tier {
  TierID: number;
  ClientD: number;
  TierName: string;
  TierDescription: string;
  TierRank: number;
}

export interface TiersResult {
  tiers: Tier[];
}

export const getAllTiers = async (
  accessToken: String
): Promise<TiersResult> => {
  const url = `/tier`;

  try {
    const { data } = await axiosClient.get<TiersResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
};

export const createTier = async (
  accessToken: String,
  newTier: Partial<Tier>
): Promise<Tier> => {
  const url = `/tier`;

  try {
    const { data } = await axiosClient.post<Tier>(url, newTier, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
};

export const updateTier = async (
  accessToken: String,
  tierID: number,
  newTier: Partial<Tier>
): Promise<Tier> => {
  const url = `/tier/` + tierID;

  try {
    const { data } = await axiosClient.post<Tier>(url, newTier, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
};

export const deleteTier = async (
  accessToken: String,
  tierID: number
): Promise<Tier> => {
  const url = `/tier/` + tierID;

  try {
    const { data } = await axiosClient.delete<Tier>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
};
