import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { updPaymentPeriod } from "./PaymentPeriodSlice";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Stepper } from "@progress/kendo-react-layout";
import { Publish, ThumbDown, ThumbUp, ArrowUpward } from "@mui/icons-material";

import { IppTextField } from "components/IppTextField";
import Root from "components/FileUploadErrors/styles";
import { IsBetween1075and1575, IsSetPx } from "utils/mediaQueries";
import { useTypedTranslation } from "utils/customHooks";
import { PaymentPeriod } from "api/benefits/paymentPeriodAPI";
import { IppButton } from "components/Buttons/IppButton";
import { IppSaveButton } from "components/Buttons/IppSaveButton";
import { IppCancelButton } from "components/Buttons/IppCancelButton";

interface periodWorkflowBannerProps {
  period: PaymentPeriod;
  isEditing: boolean;
  hideActions?: boolean;
}

export const PaymentPeriodWorkflowBanner = (
  props: periodWorkflowBannerProps
) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);
  const checkScreenWidth = IsBetween1075and1575();
  const customBP = IsSetPx(1405);
  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState("");
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { workflowStagesById, workflowStageList } = useSelector(
    (state: RootState) => state.workflowStages
  );

  const workflowStages = workflowStageList.map((WorkflowStageID) => {
    let wfStage = {
      label: workflowStagesById[WorkflowStageID].WorkflowStageName,
    };
    return wfStage;
  });

  const { period, isEditing, hideActions } = props;
  let periodData = Object.assign({}, period);
  const statusColors = ["warning.main", "info.main", "success.main", "error"];

  const onSubmit = () => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        // let periodData = Object.assign({}, period);
        periodData.PaymentPeriodStatusID = 2;
        periodData.WorkflowStageID = 2;

        dispatch(
          updPaymentPeriod(
            accessToken,
            periodData.PaymentPeriodID,
            periodData,
            false
          )
        );
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const onAccept = () => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        // let periodData = Object.assign({}, period);
        periodData.PaymentPeriodStatusID = 3;
        periodData.WorkflowStageID = 3;
        periodData.ReviewReason = "";

        dispatch(
          updPaymentPeriod(
            accessToken,
            periodData.PaymentPeriodID,
            periodData,
            false
          )
        );
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const onRecall = () => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        periodData.PaymentPeriodStatusID = 1;
        periodData.WorkflowStageID = 1;
        periodData.ReviewReason = "";

        dispatch(
          updPaymentPeriod(
            accessToken,
            periodData.PaymentPeriodID,
            periodData,
            false
          )
        );
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const onReject = () => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        if (reason) {
          // let periodData = Object.assign({}, period);
          periodData.PaymentPeriodStatusID = 4;
          periodData.WorkflowStageID = 1;
          periodData.ReviewReason = reason;

          dispatch(
            updPaymentPeriod(
              accessToken,
              periodData.PaymentPeriodID,
              periodData,
              false
            )
          );
          handleClose();
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let rejectForm = (
    <Root>
      <Box display="flex" justifyContent={"center"}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <IppTextField
              id="ReviewReason"
              label="Reject Reason"
              required
              value={reason}
              onChangeFunction={(event: any, newValue: any) => {
                setReason(event.target.value);
              }}
              onBlurFunction={(event: any, newValue: any) => {
                setReason(event.target.value.trim());
              }}
              isEditing={true}
              setIsEditing={null}
              touchedExpression={reason == ""}
              errorsExpression={
                reason == "" ?? "A valid Rejection reason is required"
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Tooltip title="Notifies user about rejection">
              <IppSaveButton
                buttonText={t("strGen:buttons.reject")}
                handleSave={onReject}
                disabled={reason === ""}
              />
            </Tooltip>
            <IppCancelButton onClick={handleClose} />
          </Grid>
        </Grid>
      </Box>
    </Root>
  );

  // console.log("PEriod flow", period);

  const genActions = (wfStageID: number) => {
    switch (wfStageID) {
      case 1:
        // In Progress
        return (
          <IppButton
            disabled={isEditing}
            color={"secondary"}
            startIcon={<Publish />}
            onClick={onSubmit}
          >
            {t("strGen:buttons.submitforreview")}
          </IppButton>
        );
      case 2:
        // In Review
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              maxWidth: checkScreenWidth ? "150px" : null,
            }}
          >
            {!currentProfile.IsClient ? (
              <IppButton
                disabled={isEditing}
                startIcon={<ArrowUpward />}
                onClick={onRecall}
                sx={{ marginBottom: 1 }}
              >
                {t("strGen:buttons.withdraw")}
              </IppButton>
            ) : (
              <>
                <IppButton
                  color={"secondary"}
                  disabled={isEditing}
                  startIcon={<ThumbUp />}
                  onClick={onAccept}
                  sx={{
                    marginBottom: 1,
                  }}
                >
                  {t("strGen:buttons.accept")}
                </IppButton>
                <IppButton
                  disabled={isEditing}
                  color="error"
                  startIcon={<ThumbDown />}
                  onClick={handleOpen}
                  sx={{ paddingX: 1 }}
                >
                  {t("strGen:buttons.returnforcorrections")}
                </IppButton>
              </>
            )}
          </Box>
        );
      case 3:
        // Accepted
        return (
          <IppButton
            disabled={isEditing}
            color="error"
            startIcon={<ThumbDown />}
            onClick={handleOpen}
            sx={{ paddingX: 1 }}
          >
            {t("strGen:buttons.returnforcorrections")}
          </IppButton>
        );
      default:
        // Complete or No Stage/Error case
        return null;
    }
  };

  return (
    <>
      <Grid item xs={customBP ? 12 : 9}>
        <Stepper
          value={period.WorkflowStageID - 1}
          items={workflowStages}
          mode={"labels"}
        />
      </Grid>
      {!hideActions && (
        <Grid item xs={customBP ? 12 : 3}>
          {!isEditing && (
            <Grid container justifyContent="flex-end">
              {genActions(period.WorkflowStageID)}
            </Grid>
          )}
          <Grid container justifyContent="flex-end" color="primary">
            <Box mt={1}>
              {t("strGen:inventory.fields.status") + ": "}
              <Typography
                variant="caption"
                fontWeight="bold"
                //sx={{color: "text.primary"}}
                color={statusColors[period.PaymentPeriodStatusID - 1]}
              >
                {period.PaymentPeriodStatusName}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
      <Dialog open={open} fullWidth maxWidth="lg">
        <DialogContent>{rejectForm}</DialogContent>
      </Dialog>
    </>
  );
};
