import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchCompanies } from "./CompaniesSlice";

import { CompanyForm } from "./CompanyForm";
import LoadingIndicator from "components/LoadingIndicator";

interface CompInvProps {
  companyID: string;
}

export const CompanyCreatePage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const { isLoading, error: compError } = useSelector(
    (state: RootState) => state.companies
  );

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  useEffect(() => {
    (async () => {
      try {
        // can avoid going to the DB if we navigated from inventory
        //if (!company) {
        if (clientId !== -1) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchCompanies(accessToken, clientId));
        }
        //}
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  let compCreateView =
    clientIsLoading || isLoading ? (
      <LoadingIndicator />
    ) : (
      <div>
        <CompanyForm company={{ ClientID: clientId }} />
      </div>
    );

  return <div id="company-detail-page">{compCreateView}</div>;
};
