import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  Contract,
  ContractsResult,
  getContracts,
  getContractsByCompany,
  getContractsByCompanyAndProject,
  createContract,
  updateContract,
  deleteContract,
  getContractsByProject,
} from "api/contractAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface ContractState {
  contractsById: Record<number, Contract>;
  contractList: number[];
  isLoading: boolean;
  error: string | null;
}

const ContractInitialState: ContractState = {
  contractsById: {},
  contractList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: ContractState) {
  state.isLoading = true;
}

function loadingFailed(state: ContractState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const contracts = createSlice({
  name: "contracts",
  initialState: ContractInitialState,
  reducers: {
    getContractsStart: startLoading,
    getContractsSuccess(state, { payload }: PayloadAction<ContractsResult>) {
      const { contracts } = payload;
      state.isLoading = false;
      state.error = null;

      contracts.forEach((contract) => {
        state.contractsById[contract.ContractID] = contract;
      });

      state.contractList = contracts.map((contract) => contract.ContractID);
    },
    getContractsFailure: loadingFailed,
    createContractStart: startLoading,
    createContractSuccess(state, { payload }: PayloadAction<Contract>) {
      const { ContractID } = payload;
      state.contractsById[ContractID] = payload;
      state.contractList.push(ContractID);

      state.isLoading = false;
      state.error = null;
    },
    updateContractSuccess(state, { payload }: PayloadAction<Contract>) {
      const { ContractID } = payload;
      state.contractsById[ContractID] = payload;
      state.contractList.push(ContractID);

      state.isLoading = false;
      state.error = null;
    },
    deleteContractSuccess(state, { payload }: PayloadAction<number>) {
      const ContractID = payload;
      delete state.contractsById[ContractID];
      state.contractList = state.contractList.filter(
        (item) => item !== ContractID
      );

      state.isLoading = false;
      state.error = null;
    },
    createContractFailure: loadingFailed,
  },
});

export const {
  getContractsStart,
  getContractsSuccess,
  getContractsFailure,
  createContractStart,
  createContractSuccess,
  updateContractSuccess,
  deleteContractSuccess,
  createContractFailure,
} = contracts.actions;

export default contracts.reducer;

export const fetchContracts =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getContractsStart());
      const contracts = await getContracts(accessToken);
      dispatch(getContractsSuccess(contracts));
    } catch (err: any) {
      dispatch(getContractsFailure(err.toString()));
    }
  };

export const fetchContractsByCompany =
  (accessToken: String, companyID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getContractsStart());
      const contracts = await getContractsByCompany(accessToken, companyID);
      dispatch(getContractsSuccess(contracts));
    } catch (err: any) {
      dispatch(getContractsFailure(err.toString()));
    }
  };

export const fetchContractsByProject =
  (accessToken: String, projectID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getContractsStart());
      const contracts = await getContractsByProject(accessToken, projectID);
      dispatch(getContractsSuccess(contracts));
    } catch (err: any) {
      dispatch(getContractsFailure(err.toString()));
    }
  };

export const fetchContractsByCompanyAndProject =
  (accessToken: String, companyID: number, projectID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getContractsStart());
      const contracts = await getContractsByCompanyAndProject(
        accessToken,
        companyID,
        projectID
      );
      dispatch(getContractsSuccess(contracts));
    } catch (err: any) {
      dispatch(getContractsFailure(err.toString()));
    }
  };

export const addContract =
  (accessToken: String, newContract: Partial<Contract>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createContractStart());
      const contract = await createContract(accessToken, newContract);
      dispatch(createContractSuccess(contract));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(fetchContracts(accessToken));
      dispatch(push(`/benefits/contracts/${contract.ContractID}`));
    } catch (err: any) {
      dispatch(createContractFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updContract =
  (
    accessToken: String,
    contractID: number,
    newContract: Partial<Contract>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createContractStart());
      const contract = await updateContract(
        accessToken,
        contractID,
        newContract
      );
      dispatch(updateContractSuccess(contract));
      dispatch(fetchContracts(accessToken));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push("/benefits/contracts/" + contractID));
    } catch (err: any) {
      dispatch(createContractFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delContract =
  (accessToken: String, contractID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createContractStart());
      const result = await deleteContract(accessToken, contractID);
      dispatch(deleteContractSuccess(contractID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
      dispatch(push("/benefits/contracts/"));
    } catch (err: any) {
      dispatch(createContractFailure(err.toString()));
    }
  };
