import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  StakeholderGroup,
  StakeholderGroupsResult,
  getStakeholderGroups,
  // getStakeholderGroupsByContact,
  // getStakeholderGroupsByInteraction,
  createStakeholderGroup,
  updateStakeholderGroup,
  deleteStakeholderGroup,
  mergeStakeholderGroup,
} from "api/stakeholder/stakeholderGroupAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { Contact } from "../../../api/stakeholder/contactAPI";
import { fetchGroupInteractions } from "../../stakeholder/groupInteraction/GroupInteractionSlice";
import { fetchContactGroups } from "../../stakeholder/contactGroup/ContactGroupSlice";
import { fetchGrievanceGroups } from "../../stakeholder/grievanceGroup/GrievancGroupSlice";
import { getCurrentModule } from "../../../utils/urlUtils";
import {
  fetchGroupIssues,
  fetchGroupIssuesByGroup,
} from "features/stakeholder/groupIssue/GroupIssueSlice";
import { SnackBarConstants } from "utils/customHooks";

interface StakeholderGroupState {
  stakeholderGroupsById: Record<number, StakeholderGroup>;
  stakeholderGroupList: number[];
  isLoading: boolean;
  isLoadingAdd: boolean;
  isLoaded: boolean;
  subStakeholderGroupisLoading: boolean;
  error: string | null;
}

const StakeholderGroupInitialState: StakeholderGroupState = {
  stakeholderGroupsById: {},
  stakeholderGroupList: [],
  isLoading: false,
  isLoadingAdd: false,
  isLoaded: false,
  subStakeholderGroupisLoading: false,
  error: null,
};

function startLoading(state: StakeholderGroupState) {
  state.isLoading = true;
}

function startLoadingAdd(state: StakeholderGroupState) {
  state.isLoadingAdd = true;
}

function loadingFailed(
  state: StakeholderGroupState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const stakeholderGroups = createSlice({
  name: "stakeholderGroups",
  initialState: StakeholderGroupInitialState,
  reducers: {
    getStakeholderGroupsStart: startLoading,
    getStakeholderGroupsSuccess(
      state,
      { payload }: PayloadAction<StakeholderGroupsResult>
    ) {
      const { stakeholderGroups } = payload;

      state.isLoading = false;
      state.error = null;
      state.isLoaded = true;

      stakeholderGroups.forEach((stakeholderGroup) => {
        state.stakeholderGroupsById[stakeholderGroup.GroupID] =
          stakeholderGroup;
      });

      state.stakeholderGroupList = stakeholderGroups.map(
        (stakeholderGroup) => stakeholderGroup.GroupID
      );
    },
    getStakeholderGroupsFailure: loadingFailed,
    createStakeholderGroupStart: startLoadingAdd,
    createStakeholderGroupSuccess(
      state,
      { payload }: PayloadAction<StakeholderGroup>
    ) {
      const { GroupID } = payload;
      state.stakeholderGroupsById[GroupID] = payload;
      state.stakeholderGroupList.push(GroupID);

      state.isLoading = false;
      state.isLoadingAdd = false;
      state.error = null;
    },
    updateStakeholderGroupSuccess(
      state,
      { payload }: PayloadAction<StakeholderGroup>
    ) {
      const { GroupID } = payload;

      state.stakeholderGroupsById[GroupID] = payload;

      state.isLoading = false;
      state.error = null;
    },
    deleteStakeholderGroupSuccess(state, { payload }: PayloadAction<number>) {
      const StakeholderGroupID = payload;
      delete state.stakeholderGroupsById[StakeholderGroupID];
      state.stakeholderGroupList = state.stakeholderGroupList.filter(
        (item) => item !== StakeholderGroupID
      );

      state.isLoading = false;
      state.error = null;
    },
    createStakeholderGroupFailure: loadingFailed,
  },
});

export const {
  getStakeholderGroupsStart,
  getStakeholderGroupsSuccess,
  getStakeholderGroupsFailure,
  createStakeholderGroupStart,
  createStakeholderGroupSuccess,
  updateStakeholderGroupSuccess,
  deleteStakeholderGroupSuccess,
  createStakeholderGroupFailure,
} = stakeholderGroups.actions;

export default stakeholderGroups.reducer;

export const fetchStakeholderGroups =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getStakeholderGroupsStart());
      const stakeholderGroups = await getStakeholderGroups(accessToken);
      dispatch(getStakeholderGroupsSuccess(stakeholderGroups));
    } catch (err: any) {
      dispatch(getStakeholderGroupsFailure(err.toString()));
    }
  };

export const addStakeholderGroup =
  (
    accessToken: String,
    newStakeholderGroup: Partial<StakeholderGroup>,
    contactList: Contact[],
    issueList: String[],
    setReturnRoute: boolean,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    let contactIDs: any[] = contactList.map((contact) => {
      return { ContactID: contact.ContactID };
    });

    try {
      dispatch(createStakeholderGroupStart());
      const stakeholderGroup = await createStakeholderGroup(
        accessToken,
        newStakeholderGroup,
        contactIDs,
        issueList
      );
      dispatch(createStakeholderGroupSuccess(stakeholderGroup));
      dispatch(openSnackBar(snackbarConstants.ADD_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(
          push(`/${getCurrentModule()}/groups/${stakeholderGroup.GroupID}`)
        );
      }
    } catch (err: any) {
      dispatch(createStakeholderGroupFailure(err.toString()));
      dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
    }
  };

export const updStakeholderGroup =
  (
    accessToken: String,
    stakeholderGroupID: number,
    newStakeholderGroup: Partial<StakeholderGroup>,
    contactList: Contact[] | null,
    issueList: String[] | null,
    setReturnRoute: boolean,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      let contactIDs: any[] | null = contactList
        ? contactList.map((contact) => {
            return { ContactID: contact.ContactID };
          })
        : null;

      dispatch(createStakeholderGroupStart());
      const stakeholderGroup = await updateStakeholderGroup(
        accessToken,
        stakeholderGroupID,
        newStakeholderGroup,
        contactIDs,
        issueList
      );

      dispatch(updateStakeholderGroupSuccess(stakeholderGroup));
      dispatch(fetchStakeholderGroups(accessToken));
      dispatch(fetchGroupInteractions(accessToken));
      dispatch(fetchContactGroups(accessToken));
      dispatch(fetchGrievanceGroups(accessToken));
      dispatch(fetchGroupIssues(accessToken));
      dispatch(openSnackBar(snackbarConstants.UPDATE_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(push(`/${getCurrentModule()}/groups/${stakeholderGroupID}`));
      }
    } catch (err: any) {
      dispatch(createStakeholderGroupFailure(err.toString()));
      dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
    }
  };

export const mrgStakeholderGroup =
  (
    accessToken: String,
    stakeholderGroupID: number,
    newStakeholderGroup: Partial<StakeholderGroup>,
    setReturnRoute: boolean,
    duplicateID: number,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      //console.log("newMrg data", newStakeholderGroup, contactList)
      dispatch(createStakeholderGroupStart());
      const stakeholderGroup = await mergeStakeholderGroup(
        accessToken,
        stakeholderGroupID,
        newStakeholderGroup,
        duplicateID
      );

      dispatch(updateStakeholderGroupSuccess(stakeholderGroup));
      dispatch(fetchGroupInteractions(accessToken));
      dispatch(fetchContactGroups(accessToken));
      dispatch(fetchGroupIssuesByGroup(accessToken, stakeholderGroupID));
      dispatch(fetchGrievanceGroups(accessToken));
      dispatch(openSnackBar(snackbarConstants.UPDATE_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(push(`/${getCurrentModule()}/groups/${stakeholderGroupID}`));
      }
    } catch (err: any) {
      dispatch(createStakeholderGroupFailure(err.toString()));
      if (stakeholderGroupID === duplicateID) {
        dispatch(
          openSnackBar(
            `${snackbarConstants.FAILED_MERGE_GROUP}: Cannot Merge a Group into itself.`,
            "error"
          )
        );
      } else {
        dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
      }
    }
  };

export const delStakeholderGroup =
  (
    accessToken: String,
    stakeholderGroupID: number,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createStakeholderGroupStart());
      const result = await deleteStakeholderGroup(
        accessToken,
        stakeholderGroupID
      );
      dispatch(deleteStakeholderGroupSuccess(stakeholderGroupID));
      dispatch(openSnackBar(snackbarConstants.DELETE_SUCCESS, "success"));
      dispatch(push(`/${getCurrentModule()}/groups`));
    } catch (err: any) {
      dispatch(createStakeholderGroupFailure(err.toString()));
      dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
    }
  };
