import { Edit, OpenInBrowser, WarningAmber } from "@mui/icons-material";
import { Divider, Grid, IconButton, Typography, Tooltip } from "@mui/material";
import { getClickableLink } from "../utils/urlUtils";
import { CSSProperties } from "react";
import { useTypedTranslation } from "utils/customHooks";

interface ippDisplayFieldProps {
  label?: string | null;
  value: any;
  subText?: any;
  isEditing: boolean;
  setIsEditing: any;
  showFooter?: boolean;
  showEdit?: boolean;
  showLabel?: boolean;
  isWebLink?: boolean;
  showEmphasis?: boolean;
  showWarning?: boolean;
  warningMessage?: string;
  route?: string;
  openInNewTab?: boolean;
  color?: CSSProperties["color"];
  tooltip?: string;
  disabled?: boolean;
}

export const IppDisplayField = (props: ippDisplayFieldProps) => {
  const {
    label,
    value,
    subText,
    isEditing,
    setIsEditing,
    showFooter,
    showEdit,
    showLabel,
    isWebLink,
    showEmphasis,
    showWarning,
    warningMessage,
    route,
    openInNewTab, // defaults to true
    color,
    tooltip,
    disabled,
  } = props;
  const t = useTypedTranslation(["strGen"]);

  const editChange = () => {
    setIsEditing(!isEditing);
  };

  let variantType: any = showEmphasis ? "h6" : "body2";

  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          {showLabel && (
            <Typography
              noWrap={true}
              variant="caption"
              sx={{ color: "text.secondary" }}
            >
              {label}
            </Typography>
          )}
        </Grid>
        <Grid item xs style={{ flexGrow: 1, overflowWrap: "break-word" }}>
          {isWebLink && value ? (
            <Tooltip title={tooltip || ""} disableHoverListener={!tooltip}>
              <a
                href={getClickableLink(route || value)}
                target={openInNewTab ? "_blank" : "_self"}
                rel="noreferrer"
              >
                <Typography variant={variantType}>
                  {!value ? "\u00A0" : value}
                </Typography>
              </a>
            </Tooltip>
          ) : (
            <div
              style={{
                overflow: "auto",
                textOverflow: "ellipsis",
                width: "auto",
                overflowWrap: "break-word",
                maxHeight: 100,
              }}
            >
              <Typography
                variant={variantType}
                sx={{
                  color: disabled
                    ? "#9e9e9e"
                    : !color
                    ? "text.secondary"
                    : color,
                }}
              >
                {!value ? "\u00A0" : value}
              </Typography>
            </div>
          )}
          {subText && <Typography variant="caption">{subText}</Typography>}
        </Grid>
        {isWebLink && !route && value ? (
          <Tooltip title={t("strGen:tooltips.visitwebsite")}>
            <IconButton
              href={getClickableLink(route || value)}
              target="_blank"
              size="small"
            >
              <OpenInBrowser fontSize="inherit" />
            </IconButton>
          </Tooltip>
        ) : (
          ""
        )}
        {showWarning && (
          <Grid item width={30}>
            <Tooltip title={warningMessage ? warningMessage : ""}>
              <IconButton size="small">
                <WarningAmber color="error" fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
        {
          <Grid item>
            {showEdit ? (
              <IconButton onClick={editChange} size="small">
                <Edit fontSize="inherit" />
              </IconButton>
            ) : (
              <div style={{ height: 28 }}></div> // Placeholder for consistent spacing
            )}
          </Grid>
        }
      </Grid>

      {showFooter && <Divider />}
    </div>
  );
};

IppDisplayField.defaultProps = {
  showFooter: true,
  showEdit: true,
  showLabel: true,
  isWebLink: false,
  showEmphasis: false,
  openInNewTab: true,
};
