import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FileUploadErrorMessage } from "./FileUploadErrorMessage";
import { ReducedErrorList, isReducedErrorList } from "utils/types/index.types";

interface FileUploadErrorListProps {
  items: string[] | ReducedErrorList;
  showSingleItem?: boolean;
  renderAsList?: boolean;
}

export const FileUploadErrorList = (props: FileUploadErrorListProps) => {
  const theme = useTheme();
  const { items, showSingleItem = false, renderAsList = false } = props;

  // Handle the case when items is a Record<string, ReducedErrorList>
  if (isReducedErrorList(items)) {
    return (
      <>
        {Object.keys(items).map((key, idx) => (
          <div key={idx}>
            <FileUploadErrorMessage message={items[key].title} />
            {renderAsList ? (
              items[key].list.map((item) => (
                <Typography
                  variant="body1"
                  sx={{
                    paddingLeft: theme.spacing(4),
                    color: theme.palette.text.secondary,
                    marginBottom: theme.spacing(1),
                  }}
                >
                  {item}
                </Typography>
              ))
            ) : (
              <Typography
                variant="body1"
                sx={{
                  paddingLeft: theme.spacing(4),
                  color: theme.palette.text.secondary,
                  marginBottom: theme.spacing(1),
                }}
              >
                {items[key].list.join(", ")}
              </Typography>
            )}
          </div>
        ))}
      </>
    );
  }

  // Since isReducedErrorList(items) is false, items must be string[]
  const stringItems = items as string[];

  // If the array has one or less items, we don't render a list as it is handled by the title
  if (!showSingleItem && items.length <= 1) return null;

  return (
    <div>
      {renderAsList ? (
        stringItems.map((item, itemIdx) => (
          <Typography
            key={itemIdx}
            variant="body1"
            sx={{
              paddingLeft: theme.spacing(2),
              color: theme.palette.text.secondary,
              marginBottom: theme.spacing(1),
            }}
          >
            {item}
          </Typography>
        ))
      ) : (
        <Typography
          variant="body1"
          sx={{
            paddingLeft: theme.spacing(2),
            color: theme.palette.text.secondary,
            marginBottom: theme.spacing(1),
          }}
        >
          {stringItems.join(", ")}
        </Typography>
      )}
    </div>
  );
};
