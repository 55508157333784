import * as yup from "yup";
import { dateCheck } from "utils/DateFunctions";
import { useTypedTranslation } from "utils/customHooks";

export const PaymentPeriodValidation = (
  contractEnabled: boolean,
  spendEnabled: boolean,
  periodStatusID: number
) => {
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  return yup.object({
    CompanyID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.company.name"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.company.name"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.company.name"),
        })
      ),
    ProjectID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.project.name"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.project.name"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:objects.project.name"),
        })
      ),
    ProjectComponentID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.projectcomponent.fullname"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.projectcomponent.fullname"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:objects.projectcomponent.fullname"),
        })
      ),
    ContractID: yup
      .number()
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.contract.name"),
        })
      )
      .when([], {
        is: () => contractEnabled === true,
        then: (schema) => {
          return schema
            .positive(
              t("strGen:validation.required", {
                fieldname: t("objBen:objects.contract.name"),
              })
            )
            .required(
              t("strGen:validation.required", {
                fieldname: t("objBen:objects.contract.name"),
              })
            );
        },
        otherwise: (schema) => schema.nullable(),
      }),
    StartDate: yup
      .date()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.spendreporting.fields.startdate"),
        })
      )
      .typeError(t("strGen:validation.date.improperformat")),
    EndDate: yup
      .date()
      .required(
        t("strGen:validation.required", {
          fieldname: spendEnabled
            ? t("objBen:objects.spendreporting.fields.date")
            : t("objBen:objects.spendreporting.fields.enddate"),
        })
      )
      .test(
        "EndDate",
        t("strGen:validation.date.notearlierthan", {
          fieldname: t("objBen:objects.spendreporting.fields.enddate"),
          fieldname2: t("objBen:objects.spendreporting.fields.startdate"),
        }) || "",
        (value, testContext) =>
          spendEnabled ? true : dateCheck(testContext.parent.StartDate, value)
      )
      .typeError(
        spendEnabled
          ? t("strGen:validation.date.improperformatmonthonly")
          : t("strGen:validation.date.improperformat")
      ),
    ReviewReason: yup
      .string()
      .trim()
      .when([], {
        is: () => periodStatusID === 4,
        then: (schema) => {
          return schema.required(
            t("strGen:validation.required", {
              fieldname: t("objBen:objects.spendreporting.fields.reviewreason"),
            })
          );
        },
        otherwise: (schema) => schema.nullable(),
      }),
  });
};
