import axiosClient from "../axiosClient";
import { Interaction } from "./interactionAPI";

export interface NewThreadInteraction {
  ThreadID: number;
  InteractionID: number;
  ThreadTitle: string;
  Summary: string;
  ProjectID: number;
  ProjectName: string;
  InteractionCount?: number;
  OrderIndex?: number;
  interactions?: any;
}
export interface ThreadInteraction extends NewThreadInteraction {
  ThreadInteractionID: number;
}

export interface ThreadInteractionsResult {
  threadInteractions: ThreadInteraction[];
}
export interface ThreadInteractionWithDetail
  extends Interaction,
    ThreadInteraction {
  expanded?: boolean;
}

export interface ThreadInteractionWithDetailsResult {
  threadInteractions: ThreadInteractionWithDetail[];
}

export async function getThreadInteractionsByThread(
  accessToken: String | void,
  threadID: number
): Promise<ThreadInteractionWithDetailsResult> {
  const url = `/threadinteraction/thread/${threadID}`;

  try {
    const { data } = await axiosClient.get<ThreadInteractionWithDetailsResult>(
      url,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getThreadInteractionsByInteraction(
  accessToken: String,
  interactionID: number
): Promise<ThreadInteractionsResult> {
  const url = `/threadinteraction/interaction/${interactionID}`;

  try {
    const { data } = await axiosClient.get<ThreadInteractionsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createThreadInteraction(
  accessToken: String | void,
  newThreadInteraction: Partial<NewThreadInteraction>
): Promise<ThreadInteraction> {
  const url = `/threadinteraction`;

  try {
    const { data } = await axiosClient.post<ThreadInteraction>(
      url,
      newThreadInteraction,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return data;
  } catch (err: any) {
    throw err;
  }
}

// Used to update ThreadInteractions from Thread Form--Communication Tab
export async function updateBulkThreadInteractions(
  accessToken: String | void,
  threadID: number,
  changes: any
): Promise<ThreadInteraction> {
  const url = `/threadinteraction/thread/${threadID}`;
  try {
    const { data } = await axiosClient.post<ThreadInteraction>(url, changes, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
// Used to update ThreadInteractions from Communication Form--Thread Tab
export async function updateBulkInteractionThreads(
  accessToken: String,
  interactionID: number,
  threadInteractions: any
): Promise<ThreadInteraction> {
  const url = `/threadinteraction/interaction/${interactionID}`;
  try {
    const { data } = await axiosClient.post<ThreadInteraction>(
      url,
      threadInteractions,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteThreadInteraction(
  accessToken: String | void,
  threadInteractionID: number
): Promise<string> {
  const url = `/threadinteraction/${threadInteractionID}`;
  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
