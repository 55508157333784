import axiosClient from "../axiosClient";

export interface ActionType {
  ActionTypeID: number;
  ActionTypeName: string;
}

export interface ActionTypesResult {
  actionTypes: ActionType[];
}

export async function getActionTypes(
  accessToken: String
): Promise<ActionTypesResult> {
  const url = `/actionType`;

  try {
    const { data } = await axiosClient.get<ActionTypesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createActionType(
  accessToken: String,
  newCommInv: Partial<ActionType>
): Promise<ActionType> {
  const url = `/actionType`;

  try {
    const { data } = await axiosClient.post<ActionType>(url, newCommInv, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateActionType(
  accessToken: String,
  commInvID: number,
  newCommInv: Partial<ActionType>
): Promise<ActionType> {
  const url = `/actionType/` + commInvID;

  try {
    const { data } = await axiosClient.post<ActionType>(url, newCommInv, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteActionType(
  accessToken: String,
  commInvID: number
): Promise<string> {
  const url = `/actionType/` + commInvID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
