import axiosClient from "../axiosClient";

export interface CommitmentSource {
  CommitmentSourceID: number;
  CommitmentSourceName: string;
  DocumentDate: Date;
  SourceDescription: string;
  ExpiryDate: Date;
  CommitmentCount: number;
}

export interface CommitmentSourcesResult {
  commitmentSources: CommitmentSource[];
}

export async function getCommitmentSources(
  accessToken: String
): Promise<CommitmentSourcesResult> {
  const url = `/commitmentSource`;

  try {
    const { data } = await axiosClient.get<CommitmentSourcesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createCommitmentSource(
  accessToken: String,
  newCommInv: Partial<CommitmentSource>
): Promise<CommitmentSource> {
  const url = `/commitmentSource`;

  try {
    const { data } = await axiosClient.post<CommitmentSource>(url, newCommInv, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateCommitmentSource(
  accessToken: String,
  commInvID: number,
  newCommInv: Partial<CommitmentSource>
): Promise<CommitmentSource> {
  const url = `/commitmentSource/` + commInvID;

  try {
    const { data } = await axiosClient.post<CommitmentSource>(url, newCommInv, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteCommitmentSource(
  accessToken: String,
  commInvID: number
): Promise<string> {
  const url = `/commitmentSource/` + commInvID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
