import { useTypedTranslation } from "utils/customHooks";

const EmployeeUploadGuide = ({
  ft_ben_JobPosition,
  ft_ben_CustomJobPosition,
  hasRegionTypes,
  ft_ben_TempEmployee,
  ft_ben_JobHistory,
  ft_ben_Ethnicity,
  ft_ben_CustomEmpCategory,
  closeModalGuide,
}: {
  ft_ben_JobPosition: any;
  ft_ben_CustomJobPosition: any;
  hasRegionTypes: any;
  ft_ben_TempEmployee: any;
  ft_ben_JobHistory: any;
  ft_ben_Ethnicity: any;
  ft_ben_CustomEmpCategory: any;
  closeModalGuide: any;
}) => {
  const t = useTypedTranslation(["objBen"]);
  const columns = [
    {
      value: "Employee ID",
      description:
        "A unique ID for the Employee - this should not contain the person's name or identifying information.",
    },
    { value: "Gender", description: "The gender of the Employee" },
    {
      value: "Town/City",
      description: "The Town or City where the Employee resides",
    },
    {
      value: "Province/State",
      description: "The Province or State where the Employee resides",
    },
    { value: "Country", description: "The Country where the Employee resides" },
    ft_ben_Ethnicity && {
      value: "Ethnicity",
      description: "The Ethnicity of the Employee",
    },
    {
      value: "Indigenous Group",
      description:
        "If the Employee identifies as a member of an Indigenous Group, enter the Group name",
    },
    ft_ben_JobHistory && {
      value: "Start Date",
      description:
        "The start date of the Job Position (YYYY-MM-DD). When updating Job Position for an existing Employee, this field is required for the previous position to be added to Job History",
    },
    ft_ben_JobPosition && {
      value: "Job Position",
      description: "The Job Position of the Employee",
    },

    hasRegionTypes && {
      value: "Region",
      description:
        "Indicates a specific region the Employee is from (ie Southern) or employed from",
    },
    ft_ben_CustomJobPosition && {
      value: t("objBen:objects.employee.fields.customJobPosition"),
      description: `The ${t(
        "objBen:objects.employee.fields.customJobPosition"
      )} of the Employee`,
    },
    ft_ben_CustomEmpCategory && {
      value: t("objBen:objects.employee.fields.customCategory"),
      description: `The ${t(
        "objBen:objects.employee.fields.customCategory"
      )} of the Employee`,
    },
    {
      value: "Person with Disability",
      description:
        "Indicate if the Employee self-identifies as a person with disability (ie N or No)",
    },
    {
      value: "Member of Visible Minority",
      description:
        "Indicate if the Employee self-identifies as a member of Visible Minority (ie N or No)",
    },
    ft_ben_JobPosition && {
      value: "Apprentice Status",
      description:
        "If the Job Position is an apprenticeable trade, enter the status (ie Apprentice/Journeyperson)",
    },
    ft_ben_JobPosition && {
      value: "Apprentice Year",
      description:
        "The current Apprentice year if the Job Position is apprenticeable (ie 1)",
    },
    ft_ben_TempEmployee && {
      value: "Temp",
      description: "Indicates if the Job Position is temporary (ie No)",
    },
  ].filter(Boolean) as { value: string; description: string }[];

  return (
    <div className="modal-overlay">
      <div className="modal">
        <span className="close" onClick={closeModalGuide}>
          &times;
        </span>
        <h2>Upload Employees</h2>
        <p>
          Employee data is uploaded to the platform using an Excel spreadsheet
          and must be in Excel format (.xlsx) <br />
          Refer to the guide below to correctly fill out your spreadsheet.
        </p>
        <div className="guide-table">
          <div className="guide-row">
            <div className="column-header">Column</div>
            <div className="value-header">Value</div>
            <div className="description-header">Description</div>
          </div>
          <div className="scrollable-content">
            {columns.map(({ value, description }, index) => (
              <div className="guide-row" key={index}>
                <div className="guide-cell">
                  {String.fromCharCode(65 + index)}
                </div>
                <div className="guide-cell value-cell">{value}</div>
                <div className="guide-cell description-cell">{description}</div>
              </div>
            ))}
          </div>
          <strong>
            <p>
              The following fields are required: Employee ID
              {ft_ben_JobPosition &&
                " and Apprentice Status/Year if the Job Position is an apprenticable trade."}{" "}
              <br />
              If the other fields are unknown, leave them blank.
            </p>
          </strong>
        </div>
      </div>
    </div>
  );
};

export default EmployeeUploadGuide;
