import axiosClient from "../axiosClient";

export interface StakeholderGroup {
  GroupID: number;
  GroupName: string;
  OrganizationTypeID: number;
  LevelOfInfluence: number;
  LevelOfInterest: number;
  LevelOfFocus: number;
  HealthScore: number;
  ClientID: number;
  GroupDetails: string;
  OrganizationTypeName: string;
  IndigenousGroupID: number;
  Address: string;
  TownCity: string;
  ProvinceState: string;
  CountryName: string;
  PostalCode: string;
  LastUpdated: Date;
  ModifiedBy: number;
  Surname: string;
  FirstName: string;
  LatestInteraction: Date;
  WebsiteAddress: string;
  Indigenous: boolean;
  Contacts: string;
  RelationshipOwner: number;
  PhoneNumber: string;
  GroupEmail: string;
  LegalSubdivision: string;
  ProximityIndicator: string;
  LevelOfImportanceID: number;
  LevelOfImportanceName: string;
  ProximityIndicatorLSDs?: [];
}

export interface StakeholderGroupsResult {
  stakeholderGroups: StakeholderGroup[];
}

export async function getStakeholderGroups(
  accessToken: String
): Promise<StakeholderGroupsResult> {
  const url = `/stakeholderGroup`;

  try {
    const { data } = await axiosClient.get<StakeholderGroupsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

// COMMENTED OUT FOR FUTURE CLEAN UP
// export async function getStakeholderGroupsByContact(
//   accessToken: String,
//   contactID: number
// ): Promise<StakeholderGroupsResult> {
//   const url = `/stakeholderGroup/contact/${contactID}`;

//   try {
//     const { data } = await axiosClient.get<StakeholderGroupsResult>(url, {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//     });

//     return data;
//   } catch (err: any) {
//     throw err;
//   }
// }

// COMMENTED OUT FOR FUTURE CLEAN UP
// export async function getStakeholderGroupsByInteraction(
//   accessToken: String,
//   interactionID: number
// ): Promise<StakeholderGroupsResult> {
//   const url = `/stakeholderGroup/interaction/${interactionID}`;

//   try {
//     const { data } = await axiosClient.get<StakeholderGroupsResult>(url, {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//     });

//     return data;
//   } catch (err: any) {
//     throw err;
//   }
// }

export async function createStakeholderGroup(
  accessToken: String,
  itemData: Partial<StakeholderGroup>,
  contactList: any[],
  issueList: any[]
): Promise<StakeholderGroup> {
  const url = `/stakeholderGroup`;

  try {
    const { data } = await axiosClient.post<StakeholderGroup>(
      url,
      { itemData: itemData, contactList: contactList, issueList: issueList },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateStakeholderGroup(
  accessToken: String,
  commInvID: number,
  itemData: Partial<StakeholderGroup>,
  contactList: any[] | null,
  issueList: any[] | null
): Promise<StakeholderGroup> {
  const url = `/stakeholderGroup/` + commInvID;

  try {
    const { data } = await axiosClient.post<StakeholderGroup>(
      url,
      { itemData: itemData, contactList: contactList, issueList: issueList },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function mergeStakeholderGroup(
  accessToken: String,
  objectID: number,
  itemData: Partial<StakeholderGroup>,
  duplicateID: number
): Promise<StakeholderGroup> {
  const url = `/stakeholderGroup/merge/` + objectID;

  try {
    const { data } = await axiosClient.post<StakeholderGroup>(
      url,
      {
        itemData: itemData,
        duplicateID: duplicateID,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteStakeholderGroup(
  accessToken: String,
  commInvID: number
): Promise<string> {
  const url = `/stakeholderGroup/` + commInvID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
