import { CommitmentForm } from "./CommitmentForm";

export const CommitmentCreatePage = () => {
  let CommitmentCreateView = (
    <div>
      <CommitmentForm />
    </div>
  );

  return <div id="Commitment-detail-page">{CommitmentCreateView}</div>;
};
