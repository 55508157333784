import {
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Autocomplete,
  Stack,
  Chip,
} from "@mui/material";
import { InfoOutlined, Edit } from "@mui/icons-material";
import { useState } from "react";
import { defaultRenderOption } from "utils/renderFunctions";

interface IppTokenMultiSelectProps {
  id: string;
  options: any;
  value: any;
  setValue: any;
  label: string;
  isEditing: boolean;
  setIsEditing: any;
  optionLabelFunction?: any;
  touchedFunction?: any;
  errorFunction?: any;
  textValueFunction?: any;
  disabled?: boolean;
  required?: boolean;
  toolTip?: string;
  noOptionsText?: string | null;
  placeholder?: string;
  renderOption?: any; // Provides workaround for MUI 5 bug with handling repeated options
}

export const IppTokenMultiSelect = (props: IppTokenMultiSelectProps) => {
  const {
    id,
    options,
    value,
    setValue,
    label,
    isEditing,
    setIsEditing,
    optionLabelFunction,
    touchedFunction,
    errorFunction,
    textValueFunction,
    disabled = false,
    required,
    toolTip,
    noOptionsText,
    placeholder,
    renderOption,
  } = props;

  const editChange = () => {
    setIsEditing(!isEditing);
  };

  const [typedEntry, setTypedEntry] = useState("");

  const setTooltip = !toolTip ? "" : toolTip;

  //This is to auto-select an option when there is only one. Overriding the 'value' is necessary
  //so that it shows on the input box.
  let sanitizedValue =
    typeof value === "string"
      ? value.split(",").filter((s: string) => s != "")
      : value
      ? value
      : [];

  const handleChange = (event: any, newInput: any, reason: any) => {
    if (newInput) {
      setValue(newInput);
      //setInput("");
    } else {
      setValue(null);
    }
  };

  const handleInputChange = (event: any, value: string) => {
    setTypedEntry(value);
  };

  return (
    <div>
      {isEditing ? (
        <Autocomplete
          sx={{ width: "100%" }}
          id={id}
          size="small"
          disabled={disabled}
          multiple
          freeSolo={false}
          autoHighlight={true}
          autoComplete={true}
          filterSelectedOptions
          value={sanitizedValue}
          inputValue={typedEntry}
          onChange={handleChange}
          onInputChange={handleInputChange}
          options={options}
          renderOption={
            renderOption ||
            ((props: any, option: any) =>
              defaultRenderOption(props, option, optionLabelFunction))
          }
          getOptionLabel={optionLabelFunction}
          noOptionsText={noOptionsText}
          renderTags={(value, getTagProps) =>
            value?.map((option, index) => (
              <Chip
                variant="outlined"
                size="small"
                label={
                  optionLabelFunction ? optionLabelFunction(option) : option
                }
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              placeholder={placeholder}
              required={required}
              error={touchedFunction && Boolean(errorFunction)}
              helperText={touchedFunction && errorFunction}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Stack direction="row" spacing={1}>
                    {setTooltip.length > 0 ? (
                      <InputAdornment position="end">
                        <Tooltip title={setTooltip} placement="top">
                          <InfoOutlined fontSize="small" />
                        </Tooltip>
                      </InputAdornment>
                    ) : (
                      ""
                    )}
                    {params.InputProps.endAdornment}
                  </Stack>
                ),
              }}
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      ) : (
        <TextField
          id={id}
          name={id}
          label={label}
          placeholder={placeholder}
          value={textValueFunction}
          required={required}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                {setTooltip.length > 0 ? (
                  <Tooltip title={setTooltip} placement="top">
                    <InfoOutlined fontSize="small" style={{ marginTop: -3 }} />
                  </Tooltip>
                ) : (
                  ""
                )}
                <IconButton onClick={editChange} size="small">
                  <Edit fontSize="inherit" />
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ shrink: true }}
        />
      )}
    </div>
  );
};
