import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  InteractionAction,
  InteractionActionsResult,
  getInteractionActions,
  createInteractionAction,
  updateInteractionAction,
  deleteInteractionAction,
} from "api/stakeholder/interactionActionAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { getInteractionActionsByInteraction } from "api/stakeholder/interactionActionAPI";
import { SnackBarConstants } from "utils/customHooks";

interface InteractionActionState {
  interactionActionsById: Record<number, InteractionAction>;
  interactionActionList: number[];
  isLoading: boolean;
  isLoadingAdd: boolean;
  subInteractionActionisLoading: boolean;
  isLoaded: boolean;
  error: string | null;
  errorList: any[] | null;
}

const InteractionActionInitialState: InteractionActionState = {
  interactionActionsById: {},
  interactionActionList: [],
  isLoading: false,
  isLoadingAdd: false,
  subInteractionActionisLoading: false,
  isLoaded: false,
  error: null,
  errorList: null,
};

function startLoading(state: InteractionActionState) {
  state.isLoading = true;
}

function startLoadingAdd(state: InteractionActionState) {
  state.isLoadingAdd = true;
}

function loadingFailed(
  state: InteractionActionState,
  action: PayloadAction<{ status: string; message: string; errors: [] }>
) {
  state.isLoading = false;
  state.error = action.payload.message;
  if (action.payload.errors) {
    state.errorList = action.payload.errors;
  }
}

const interactionActions = createSlice({
  name: "interactionActions",
  initialState: InteractionActionInitialState,
  reducers: {
    getInteractionActionsStart: startLoading,
    getInteractionActionsSuccess(
      state,
      { payload }: PayloadAction<InteractionActionsResult>
    ) {
      const { interactionActions } = payload;

      state.isLoading = false;
      state.error = null;
      state.errorList = null;
      state.isLoaded = true;

      interactionActions.forEach((interactionAction) => {
        state.interactionActionsById[interactionAction.InteractionActionID] =
          interactionAction;
      });

      state.interactionActionList = interactionActions.map(
        (interactionAction) => interactionAction.InteractionActionID
      );
    },
    getInteractionActionsFailure: loadingFailed,
    createInteractionActionStart: startLoadingAdd,
    createInteractionActionSuccess(
      state,
      { payload }: PayloadAction<InteractionAction>
    ) {
      const { InteractionActionID } = payload;
      state.interactionActionsById[InteractionActionID] = payload;
      state.interactionActionList.push(InteractionActionID);

      state.isLoading = false;
      state.isLoadingAdd = false;
      state.error = null;
    },
    updateInteractionActionSuccess(
      state,
      { payload }: PayloadAction<InteractionAction>
    ) {
      const { InteractionActionID } = payload;

      state.interactionActionsById[InteractionActionID] = payload;

      state.isLoading = false;
      state.error = null;
    },
    deleteInteractionActionSuccess(state, { payload }: PayloadAction<number>) {
      const InteractionActionID = payload;
      delete state.interactionActionsById[InteractionActionID];
      state.interactionActionList = state.interactionActionList.filter(
        (item) => item !== InteractionActionID
      );

      state.isLoading = false;
      state.error = null;
    },
    createInteractionActionFailure: loadingFailed,
  },
});

export const {
  getInteractionActionsStart,
  getInteractionActionsSuccess,
  getInteractionActionsFailure,
  createInteractionActionStart,
  createInteractionActionSuccess,
  updateInteractionActionSuccess,
  deleteInteractionActionSuccess,
  createInteractionActionFailure,
} = interactionActions.actions;

export default interactionActions.reducer;

export const fetchInteractionActions =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getInteractionActionsStart());
      const interactionActions = await getInteractionActions(accessToken);
      dispatch(getInteractionActionsSuccess(interactionActions));
    } catch (err: any) {
      dispatch(getInteractionActionsFailure(err.toString()));
    }
  };

export const fetchInteractionActionsByInteraction =
  (accessToken: String, interactionID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getInteractionActionsStart());
      const interactionActions = await getInteractionActionsByInteraction(
        accessToken,
        interactionID
      );
      dispatch(getInteractionActionsSuccess(interactionActions));
    } catch (err: any) {
      dispatch(getInteractionActionsFailure(err.toString()));
    }
  };

export const addInteractionAction =
  (
    accessToken: String,
    newInteractionAction: Partial<InteractionAction>,
    setReturnRoute: boolean,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createInteractionActionStart());
      const interactionAction = await createInteractionAction(
        accessToken,
        newInteractionAction
      );
      dispatch(createInteractionActionSuccess(interactionAction));
      dispatch(openSnackBar(snackbarConstants.ADD_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(
          push(
            `/engagement/communications/${interactionAction.InteractionID}/actions/${interactionAction.InteractionActionID}`
          )
        );
      }
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(createInteractionActionFailure(error.response.data));
        dispatch(openSnackBar(error.response.data.message, "error"));
      } else {
        dispatch(createInteractionActionFailure(error.toString()));
        dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
      }
    }
  };

export const updInteractionAction =
  (
    accessToken: String,
    interactionActionID: number,
    newInteractionAction: Partial<InteractionAction>,
    setReturnRoute: boolean,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    console.log(newInteractionAction);
    try {
      dispatch(createInteractionActionStart());
      const interactionAction = await updateInteractionAction(
        accessToken,
        interactionActionID,
        newInteractionAction
      );

      dispatch(updateInteractionActionSuccess(interactionAction));
      dispatch(openSnackBar(snackbarConstants.UPDATE_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(
          push(
            `/engagement/communications/${interactionAction.InteractionID}/actions/${interactionActionID}`
          )
        );
      }
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(createInteractionActionFailure(error.response.data));
        dispatch(openSnackBar(error.response.data.message, "error"));
      } else {
        dispatch(createInteractionActionFailure(error.toString()));
        dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
      }
    }
  };

export const delInteractionAction =
  (
    accessToken: String,
    interactionID: number,
    interactionActionID: number,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createInteractionActionStart());
      const result = await deleteInteractionAction(
        accessToken,
        interactionActionID
      );
      dispatch(deleteInteractionActionSuccess(interactionActionID));
      dispatch(openSnackBar(snackbarConstants.DELETE_SUCCESS, "success"));
      dispatch(push(`/engagement/communications/${interactionID}`));
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(createInteractionActionFailure(error.response.data));
        dispatch(openSnackBar(error.response.data.message, "error"));
      } else {
        dispatch(createInteractionActionFailure(error.toString()));
        dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
      }
    }
  };
