import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  EngagementLog,
  EngagementLogsResult,
  getEngagementLogs,
} from "api/stakeholder/logTables/engagementLogAPI";
import { AppThunk } from "app/store";

interface EngagementLogState {
  engagementLogsById: Record<number, EngagementLog>;
  engagementLogList: number[];
  isLoading: boolean;
  error: string | null;
}

const EngagementLogInitialState: EngagementLogState = {
  engagementLogsById: {},
  engagementLogList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: EngagementLogState) {
  state.isLoading = true;
}

function loadingFailed(
  state: EngagementLogState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const engagementLogs = createSlice({
  name: "engagementLogs",
  initialState: EngagementLogInitialState,
  reducers: {
    getEngagementLogsStart: startLoading,
    getEngagementLogsSuccess(
      state,
      { payload }: PayloadAction<EngagementLogsResult>
    ) {
      const { engagementLogs } = payload;
      state.isLoading = false;
      state.error = null;

      engagementLogs.forEach((item) => {
        state.engagementLogsById[item.InteractionID] = item;
      });

      state.engagementLogList = engagementLogs.map(
        (item) => item.InteractionID
      );
    },
    getEngagementLogsFailure: loadingFailed,
  },
});

export const {
  getEngagementLogsStart,
  getEngagementLogsSuccess,
  getEngagementLogsFailure,
} = engagementLogs.actions;

export default engagementLogs.reducer;

export const fetchEngagementLogs =
  (accessToken: String, startDate: string, endDate: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getEngagementLogsStart());
      const engagementLogs = await getEngagementLogs(
        accessToken,
        startDate,
        endDate
      );
      dispatch(getEngagementLogsSuccess(engagementLogs));
    } catch (err: any) {
      dispatch(getEngagementLogsFailure(err.toString()));
    }
  };
