import axiosClient from "./axiosClient";

export interface EmbedURL {
  id: string;
  name: string;
  embedUrl: string;
}

export interface ReportEmbed {
  accessToken: string;
  expiry: Date;
  status: number;
  embedUrl: EmbedURL[];
  DatasetID: string;
  ReportRefreshURL: string;
  LastReportRefresh: Date;
}

export interface RefreshResponse {
  id: string;
  name: string;
  embedUrl: string;
}

export async function getEmbedToken(
  accessToken: String,
  moduleShortName: String
): Promise<ReportEmbed> {
  const url = `/reportEmbed/${moduleShortName}`;

  try {
    const { data } = await axiosClient.get<ReportEmbed>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function postReportRefresh(
  accessToken: String,
  moduleShortName: any
): Promise<any> {
  const url = `/reportEmbed/refresh/${moduleShortName}`;

  try {
    const response = await axiosClient.post<any>(
      url,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error.response;
  }
}
