import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { Prompt } from "react-router";
import {
  addInteraction,
  updInteraction,
  delInteraction,
} from "./InteractionSlice";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { IppFormHeader } from "components/IppFormHeader";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppTextField } from "components/IppTextField";
import { IppAutocomplete } from "components/IppAutocomplete";
import LoadingIndicator from "components/LoadingIndicator";
import { fetchClientInteractionTypes } from "features/platform/admin/clientInteractionTypes/ClientInteractionTypeSlice";
import { fetchProjects } from "features/project/ProjectSlice";
import { fetchStatusTypes } from "features/datalists/StatusTypeSlice";
import { IppAttachmentInventory } from "components/IppAttachmentInventory";
import {
  fetchGroupInteractionsByInteraction,
  fetchSuggestedGroupsByInteractionContacts,
} from "../groupInteraction/GroupInteractionSlice";
import { fetchInteractionIssuesByInteraction } from "../interactionIssue/InteractionIssueSlice";
import { fetchContactInteractionsByInteraction } from "../contactInteraction/ContactInteractionSlice";
import { IppChildInventory } from "components/Inventory/IppChildInventory";
import { IppTabPanel } from "components/IppTabPanel";
import { ConvertDateOffset } from "../../../utils/DateFunctions";
import { fetchContacts } from "../../platform/contacts/ContactSlice";
import { fetchStakeholderGroups } from "../../platform/groups/StakeholderGroupSlice";
import { fetchIssues } from "../issue/IssueSlice";
import { IppTokenAutocomplete } from "components/IppTokenAutoComplete";
import { IppStaticTextfield } from "components/IppStaticTextfield";
import { IppContactAutoComplete } from "components/IppContactAutoComplete";
import { Contact } from "../../../api/stakeholder/contactAPI";
import { fetchDeleteChecksByInteraction } from "features/deleteCheck/DeleteCheckSlice";
import { IppDisplayField } from "components/IppDisplayField";
import { fetchUsers } from "features/users/UsersSlice";
import { GetJSDate, ConvertToJSDate } from "../../../utils/DateFunctions";
import { fetchGrievances } from "../grievance/GrievanceSlice";
import {
  addGrievanceInteraction,
  fetchGrievanceInteractionsByInteraction,
} from "../grievanceInteraction/GrievanceInteractionSlice";
import { IppDisplayRichText } from "components/IppDisplayRichText";
import { SanitizeHTML } from "utils/customXSS";
import { IppRichTextEditor } from "components/RichTextEditor/IppRichTextEditor";
import { Grievance } from "api/stakeholder/grievanceAPI";
import { IppSentiment } from "components/IppSentiment";
import { IppDatePicker } from "components/IppDatePicker";
import { fetchInteractionActionsByInteraction } from "../interactionAction/InteractionActionSlice";
import { countAttachments } from "api/attachmentsAPI";
import InitiatedByValues from "components/InitiatedByValues";
import { fetchInteractionCommentsByInteraction } from "../interactionComment/InteractionCommentSlice";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { fetchIssueCommentsByInteraction } from "../interactionComment/IssueCommentSlice";
import { IppPersonResponsible } from "components/IppPersonResponsible";
import { IppMultiTextField } from "components/IppMultiTextField";
import { IsMedLg, IsMedSmall } from "utils/mediaQueries";
import {
  addCommitmentInteraction,
  fetchCommitmentInteractionsByInteraction,
} from "features/crossModule/stakeholderCommitment/commitmentInteractionSlice";
import { fetchCommitments } from "features/commitments/commitment/CommitmentSlice";
import { Commitment } from "api/commitments/commitmentAPI";
import { CommitmentInteraction } from "api/crossModule/stakeholderCommitment/commitmentInteractionAPI";
import {
  useRoleChecks,
  useSnackBarConstants,
  useTypedTranslation,
} from "utils/customHooks";
import { IppDisplayChip } from "components/IppDisplayChip";
import { UserWriteAccess } from "utils/userAccess";
import { fetchPhases } from "features/platform/admin/phase/PhaseSlice";
import { fetchInteractionPhasesByInteraction } from "../interactionPhase/InteractionPhaseSlice";
import { fetchThreads, updThread } from "../thread/ThreadSlice";
import {
  fetchThreadInteractionsByInteraction,
  updBulkInteractionThreads,
} from "../thread/ThreadInteractionSlice";
import { generateThreadSummaryWithAi, Thread } from "api/stakeholder/threadAPI";
import { IppTokenMultiSelect } from "components/IppTokenMultiSelect";
import { IppButton } from "components/Buttons/IppButton";
import { InteractionValidationSchema } from "./InteractionValidationSchema";
import { SuggestionBanner } from "components/SuggestionBanner";
import { IppCheckbox } from "components/IppCheckbox";
import { IppDisplayCheckbox } from "components/IppDisplayCheckbox";
import { fetchStandardStatements } from "features/platform/admin/standardStatement/StandardStatementSlice";
import { fetchTagsByRecordType } from "features/platform/admin/tag/TagSlice";
import IppChangeWarn from "components/IppChangeWarn";
import { IppTokenTable } from "components/IppTokenTable";
import { IppGenerateSummaryButton } from "components/Buttons/IppGenerateSummaryButton";
import { generateInteractionDetailsWithAi } from "api/stakeholder/interactionAPI";
import { IppGenerateSummaryDialog } from "components/IppGenerateSummaryDialog";
import * as Constants from "utils/snackBarConstants";
import { IppNeedsReviewBanner } from "components/IppNeedsReviewBanner";
import { fetchInteractionTypes } from "features/datalists/InteractionTypeSlice";

const PREFIX = "InteractionForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    // minWidth: 650,
    //maxWidth: 1150,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const InteractionForm = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);
  const snackbarConstants = useSnackBarConstants();

  const customBP = IsMedSmall();
  const isML = IsMedLg();

  const [activeTab, setActiveTab] = useState(0);
  const [listCommitments, setListCommitments] = useState<Commitment>();
  const [listGroups, setListGroups] = useState<Array<string>>([]);
  const [listIssues, setListIssues] = useState<Array<string>>([]);
  const [listGrievances, setListGrievances] = useState<Grievance>();
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
  const [listThreads, setListThreads] = useState<Thread[]>();
  const [pageLoading, setPageLoading] = useState(true);
  const [didSaveInventory, setDidSaveInventory] = useState(false);
  const [didSaveComment, setDidSaveComment] = useState(false);
  const [isInvUpdated, setIsInvUpdated] = useState(false);
  let [threadTokenOptions, setThreadTokenOptions] = useState<Thread[]>();
  // used to display attachments count
  const [attachCount, setAttachCount] = useState({ Count: 0 } as any);
  const [generateDialogOpen, setGenerateDialogOpen] = useState(false);
  const [detailsKey, setDetailsKey] = useState(0);
  const [generateObjectType, setGenerateObjectType] = useState<
    "thread" | "interaction"
  >("interaction");
  const [threadData, setThreadData] = useState<any>(null);

  // to track whether related communications were updated
  // const [relatedUpdated, setRelatedUpdated] = useState(false);

  // to trigger an update for review flag
  const [reviewPress, setReviewPress] = useState(false);

  // items for soft validation
  const [canContinue, setCanContinue] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [warningDisabled, setWarningDisabled] = useState(false);

  let itemData = props.interaction || {};

  const [tagItems, setTagItems] = useState(
    itemData.Tags ? itemData.Tags : undefined
  );

  const [isEditing, setIsEditing] = useState(
    itemData.InteractionID ? false : true
  );
  const [isAdding, setIsAdding] = useState(
    itemData.InteractionID ? false : true
  );
  const showEdit = UserWriteAccess("Engagement", itemData.ProjectID);

  const {
    clientId,
    aiEnabled,
    isLoading: clientIsLoading,
    ft_eng_PILSD,
    ft_eng_Sentiment,
    ft_eng_InternalNotes,
    ft_eng_Comment,
    ft_comeng_CommitmentInteraction,
    ft_eng_Grievance,
    ft_eng_Phase,
    ft_eng_IntenalCommunication,
    ft_eng_Thread,
    ft_all_Tag,
    ft_eng_ThreadQuickAdd,
  } = useSelector((state: RootState) => state.client);

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const { phaseList, phasesById } = useSelector(
    (state: RootState) => state.phase
  );

  const phases = phaseList.map((id) => phasesById[id]);

  const {
    interactionPhaseList,
    interactionPhasesById,
    isLoading: interactionPhaseIsLoading,
  } = useSelector((state: RootState) => state.interactionPhases);

  const InteractionPhase = interactionPhaseList.map(
    (id) => interactionPhasesById[id]
  );

  const {
    clientInteractionTypesById,
    clientInteractionTypeList,
    isLoading: typeIsLoading,
  } = useSelector((state: RootState) => state.clientInteractionTypes);

  const clientInteractionTypes = clientInteractionTypeList.map(
    (id) => clientInteractionTypesById[id]
  );

  const { interactionTypeList, interactionTypesById } = useSelector(
    (state: RootState) => state.interactionTypes
  );

  const interactionTypes = interactionTypeList.map(
    (id) => interactionTypesById[id]
  );

  const {
    statusTypeList,
    statusTypesById,
    isLoading: statusIsLoading,
  } = useSelector((state: RootState) => state.statusTypes);

  const statusTypes = statusTypeList.map((id) => statusTypesById[id]);

  const { getEngagementsPowerUserRoles, hasCommitmentInteractionRoles } =
    useRoleChecks();

  const stkPowerUserRoles = getEngagementsPowerUserRoles();
  const userHasStkCom = hasCommitmentInteractionRoles();
  // list of project IDs available in this Module
  const accessibleProjectIDs = stkPowerUserRoles
    .filter((role) => role.UserAccountID === currentProfile.UserAccountID)
    .map((role) => role.ProjectID);

  const {
    projectList,
    projectsById,
    isLoading: projectIsLoading,
  } = useSelector((state: RootState) => state.projects);

  // if admin, show all projects
  // if non-admin, only show projects included in accessibleProjectIds
  const projects = currentProfile.IsClientAdmin
    ? projectList.map((id) => projectsById[id])
    : projectList
        .map((id) => projectsById[id])
        .filter((project) => accessibleProjectIDs.includes(project.ProjectID));

  const {
    groupInteractionList,
    groupInteractionsById,
    suggestedGroupList,
    isLoading: groupInteractionIsLoading,
  } = useSelector((state: RootState) => state.groupInteractions);

  const groups = groupInteractionList.map((id) => groupInteractionsById[id]);
  const groupInteractions = groups.filter(
    (group) => group.InteractionID === itemData.InteractionID
  );

  const {
    contactInteractionList,
    contactInteractionsById,
    isLoading: contactInteractionIsLoading,
  } = useSelector((state: RootState) => state.contactInteractions);

  const contactInteractionsUnfiltered = contactInteractionList.map(
    (id) => contactInteractionsById[id]
  );
  const contactInteractions = contactInteractionsUnfiltered.filter(
    (item) => item.InteractionID === itemData.InteractionID
  );

  const {
    interactionIssueList,
    interactionIssuesById,
    isLoading: interactionIssueIsLoading,
  } = useSelector((state: RootState) => state.interactionIssues);

  const interactionIssues = interactionIssueList.map(
    (id) => interactionIssuesById[id]
  );

  const {
    contactList,
    contactsById,
    isLoading: contactIsLoading,
  } = useSelector((state: RootState) => state.contacts);

  const contacts = contactList.map((id) => contactsById[id]);

  const { stakeholderGroupList, stakeholderGroupsById } = useSelector(
    (state: RootState) => state.stakeholderGroups
  );

  const stakeholderGroups = stakeholderGroupList.map(
    (id) => stakeholderGroupsById[id]
  );

  const { issueList, issuesById } = useSelector(
    (state: RootState) => state.issues
  );

  const issues = issueList.map((id) => issuesById[id]);

  const iiList = interactionIssues.map((p) => {
    let n = Object.assign({}, p);
    n.IssueDate = ConvertDateOffset(p.IssueDate);
    return n;
  });

  const { issueCommentList, issueCommentsById } = useSelector(
    (state: RootState) => state.issueComments
  );

  const issueComments = issueCommentList.map((id) => issueCommentsById[id]);

  const { userList, usersById } = useSelector(
    (state: RootState) => state.users
  );

  // Filter for active users only
  const allUsers = userList.map((id) => usersById[id]);

  const {
    grievanceInteractionList,
    grievanceInteractionsById,
    isLoading: grievanceInteractionIsLoading,
  } = useSelector((state: RootState) => state.grievanceInteractions);

  const grievanceInteractionsUnfiltered = grievanceInteractionList.map(
    (id) => grievanceInteractionsById[id]
  );

  const grievanceInteractions = grievanceInteractionsUnfiltered.filter(
    (items) => items.InteractionID === itemData.InteractionID
  );

  // create a list of Grievance IDs already linked to the current Interaction
  const grievanceInteractionGrievIDs = grievanceInteractions.reduce(
    (giGrievIDs, currVal) => {
      giGrievIDs.add(currVal.GrievanceID);
      return giGrievIDs;
    },
    new Set()
  );

  const gi = grievanceInteractions.map((p) => {
    let n = Object.assign({}, p) as any;
    if (p.ReportedDate !== null) {
      n.ReportedDate = ConvertDateOffset(p.ReportedDate);
    } else {
      n.ReportedDate = null;
    }

    if (p.ResolutionDate !== null) {
      n.ResolutionDate = ConvertDateOffset(p.ResolutionDate);
    } else {
      n.ResolutionDate = null;
    }

    return n;
  });

  const { grievanceList, grievancesById } = useSelector(
    (state: RootState) => state.grievances
  );

  const unfiliteredGrievances = grievanceList.map((id) => grievancesById[id]);

  // filter Grievances to only list Grievances not currently linked, and that the current user has a Stk PowerUser Role for.
  const filteredGrievances = unfiliteredGrievances.filter((int) => {
    if (
      int.GrievanceID === itemData.GrievanceID ||
      grievanceInteractionGrievIDs.has(int.GrievanceID)
    ) {
      return false;
    }

    // if not an Admin, check that the user has a Stakeholder PowerUser Role on the project
    if (!currentProfile.IsClientAdmin) {
      return stkPowerUserRoles.some(
        (role: any) => role.ProjectID === int.ProjectID
      );
    }

    return true;
  });

  // adds auto complete display value to options
  const grievances = filteredGrievances.map((griev) => {
    let displayGrievance = Object.assign({}, griev) as any;
    displayGrievance.DisplayValue = `G-${displayGrievance.SequenceID}  ${displayGrievance.GrievanceName}`;
    return displayGrievance;
  });

  const { interactionActionList, interactionActionsById } = useSelector(
    (state: RootState) => state.interactionActions
  );

  const interactionActions = interactionActionList.map(
    (id) => interactionActionsById[id]
  );

  const ia = interactionActions.map((p) => {
    let n = Object.assign({}, p) as any;
    if (p.RequiredDate !== null) {
      n.RequiredDate = ConvertDateOffset(p.RequiredDate);
    } else {
      n.RequiredDate = null;
    }
    if (p.CompletionDate !== null) {
      n.CompletionDate = ConvertDateOffset(p.CompletionDate);
    } else {
      n.CompletionDate = null;
    }
    return n;
  });

  const { interactionList, interactionsById } = useSelector(
    (state: RootState) => state.interactions
  );

  const interactions = interactionList.map((id) => interactionsById[id]);

  //usestate for Rootstate of attachment
  const { attachmentsById, attachmentList } = useSelector(
    (state: RootState) => state.attachments
  );

  const attachments = attachmentList.map((id) => attachmentsById[id]);

  const {
    interactionCommentList,
    interactionCommentsById,
    isLoading: interactionCommentIsLoading,
  } = useSelector((state: RootState) => state.interactionComments);

  const interactionComments = interactionCommentList.map(
    (id) => interactionCommentsById[id]
  );

  const { commitmentInteractionList, commitmentInteractionsByID } = useSelector(
    (state: RootState) => state.commitmentInteractions
  );

  const { commitmentList, commitmentsById } = useSelector(
    (state: RootState) => state.commitments
  );

  let showCommitmentInteractions =
    ft_comeng_CommitmentInteraction &&
    (currentProfile.IsClientAdmin || userHasStkCom)
      ? true
      : false;

  let commitmentInteractions: CommitmentInteraction[] = [];
  let commitments: Commitment[] = [];
  let commitmentsFiltered: Commitment[] = [];

  if (showCommitmentInteractions) {
    const commitmentInteractionsUnfiltered = commitmentInteractionList.map(
      (id: number) => commitmentInteractionsByID[id]
    );

    const commitmentInteractionsFiltered =
      commitmentInteractionsUnfiltered.filter(
        (commitmentInteractions: CommitmentInteraction) =>
          commitmentInteractions.InteractionID === itemData.InteractionID
      );

    // Create list of current interactions on this Commitment
    commitmentInteractions = commitmentInteractionsFiltered.map((ci: any) => {
      let newObj = { ...ci } as any;
      if (ci.InteractionDate !== null) {
        newObj.InteractionDate = ConvertDateOffset(ci.InteractionDate);
      } else {
        newObj.InteractionDate = null;
      }

      return newObj;
    });

    const commitIntsCommitmentIDs = commitmentInteractions.map(
      (commitInt) => commitInt.CommitmentID
    );

    // Create list of all available commitments
    commitments = commitmentList.map((id) => {
      let displayCommitment = Object.assign({}, commitmentsById[id]) as any;
      displayCommitment.DisplayValue = `C-${displayCommitment.SequenceID} ${displayCommitment.CommitmentTitle}`;
      return displayCommitment;
    });

    commitmentsFiltered = commitments.filter(
      (commitment) => !commitIntsCommitmentIDs.includes(commitment.CommitmentID)
    );
  }

  // Threads tab setup
  const {
    threadList,
    threadsById,
    isLoading: tiIsLoading,
  } = useSelector((state: RootState) => state.threads);
  let unfilteredThreads = threadList?.map((id) => threadsById[id]);
  const { threadInteractionList, threadInteractionsById } = useSelector(
    (state: RootState) => state.threadInteractions
  );
  let threadInteractions = threadInteractionList.map(
    (id) => threadInteractionsById[id]
  );

  let originalThreads = threadInteractions.map((t: any) => {
    return {
      ...t,
      expanded: false,
      DisplayValue: `T-${t.SequenceID}  ${t.ThreadTitle}`,
    };
  });

  useEffect(() => {
    setListThreads(originalThreads);
  }, [tiIsLoading]);

  const linkedThreads = listThreads?.map((i) => i?.ThreadID);
  const filteredThreads = threadList.filter(
    (threadId) => !linkedThreads?.includes(threadId)
  );
  const threadOptions = filteredThreads
    .map((id) => {
      return {
        ...threadsById[id],
        lastInteractionDate: !!threadsById[id]?.lastInteractionDate
          ? ConvertDateOffset(threadsById[id].lastInteractionDate)
          : "",
      };
    })
    .filter((i) => i.ProjectID === itemData?.ProjectID);

  const { isLoading: standardStatementIsLoading } = useSelector(
    (state: RootState) => state.standardStatement
  );

  const {
    tagList,
    tagsById,
    isLoading: tagIsLoading,
  } = useSelector((state: RootState) => state.tag);

  const tags = ft_all_Tag ? tagList.map((id) => tagsById[id]) : [];

  //checks user Access for edit buttons
  //UserWriteAccess("Write", "Engagement", itemData.ProjectID);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        // start
        if (!isAdding && itemData.InteractionID) {
          dispatch(
            fetchInteractionActionsByInteraction(
              accessToken,
              itemData.InteractionID
            )
          );
          dispatch(
            fetchGroupInteractionsByInteraction(
              accessToken,
              itemData.InteractionID
            )
          );
          dispatch(
            fetchContactInteractionsByInteraction(
              accessToken,
              itemData.InteractionID
            )
          );
          dispatch(
            fetchInteractionIssuesByInteraction(
              accessToken,
              itemData.InteractionID
            )
          );
          dispatch(
            fetchGrievanceInteractionsByInteraction(
              accessToken,
              itemData.InteractionID
            )
          );
          ft_eng_Thread &&
            dispatch(
              fetchThreadInteractionsByInteraction(
                accessToken,
                itemData.InteractionID
              )
            );
          ft_eng_Comment &&
            dispatch(
              fetchInteractionCommentsByInteraction(
                accessToken,
                itemData.InteractionID
              )
            );
          // dispatch below used in more than just Comments
          dispatch(
            fetchIssueCommentsByInteraction(accessToken, itemData.InteractionID)
          );
          if (ft_comeng_CommitmentInteraction) {
            dispatch(fetchCommitments(accessToken));
            dispatch(
              fetchCommitmentInteractionsByInteraction(
                accessToken,
                itemData.InteractionID
              )
            );
          }
          if (ft_eng_Phase) {
            // dispatch(fetchPhases(accessToken));
            dispatch(
              fetchInteractionPhasesByInteraction(
                accessToken,
                itemData.InteractionID
              )
            );
          }
          const count = await countAttachments(
            accessToken,
            "Communications",
            itemData.InteractionID
          );
          setAttachCount(count.attachments ? count.attachments : 0);
        }

        // end
        if (ft_eng_Phase) {
          dispatch(fetchPhases(accessToken));
        }
        if (ft_all_Tag) {
          dispatch(fetchTagsByRecordType(accessToken, "Interaction"));
        }
        dispatch(fetchStatusTypes(accessToken));
        dispatch(fetchClientInteractionTypes(accessToken));
        dispatch(fetchInteractionTypes(accessToken));
        dispatch(fetchProjects(accessToken));
        ft_eng_Thread && dispatch(fetchThreads(accessToken));
        dispatch(fetchContacts(accessToken));
        dispatch(fetchStakeholderGroups(accessToken));
        dispatch(fetchIssues(accessToken));
        dispatch(fetchUsers(accessToken, clientId));
        dispatch(fetchGrievances(accessToken));
        dispatch(fetchStandardStatements(accessToken));
        setPageLoading(false);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [
    ft_eng_Comment,
    clientId,
    itemData.InteractionID,
    dispatch,
    getAccessTokenSilently,
    isAdding,
    currentProfile.UserAccountID,
  ]);

  // used for attachment count
  useEffect(() => {
    (async () => {
      try {
        if (attachments.length > 0 && !isAdding) {
          const attach = attachments.filter((item) => {
            return (
              item.RecordID === itemData.InteractionID &&
              item.RecordType === "Communications"
            );
          });
          if (attach && attach.length > 0)
            setAttachCount({ Count: attach.length });
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [attachmentList]);

  useEffect(() => {
    if (itemData.InteractionID && !groupInteractionIsLoading) {
      // Initial load when not editing or when groups haven't been loaded yet during editing
      if (!isEditing || (isEditing && listGroups.length === 0)) {
        const newList: string[] = groupInteractions.map(
          (item) => item.GroupName
        );
        setListGroups(newList);
      }
    }

    return () => {
      // Cleanup
    };
  }, [itemData.InteractionID, groupInteractionIsLoading, isEditing]);

  // get lists for contact  autocomplete fields
  useEffect(() => {
    // effect
    if (!contactInteractionIsLoading) {
      let concat = contacts.filter((contact) =>
        contactInteractions.some(
          (interaction) => interaction.ContactID === contact.ContactID
        )
      );
      setSelectedContacts(concat);
    }
    return () => {
      // cleanup
    };
  }, [
    itemData.InteractionID,
    contactInteractionIsLoading,
    isEditing,
    contactIsLoading,
  ]);

  // get lists for issues  autocomplete fields
  useEffect(() => {
    // effect
    if (!interactionIssueIsLoading && !isAdding) {
      let newList: string[] = [];
      interactionIssues.map((item) => newList.push(item.IssueName));
      setListIssues(newList);
    }
    return () => {
      // cleanup
    };
  }, [itemData.InteractionID, interactionIssueIsLoading, isEditing]);

  const linkGrievance = (griev: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        let item = {
          GrievanceID: griev.GrievanceID,
          InteractionID: itemData.InteractionID,
        };

        dispatch(addGrievanceInteraction(accessToken, item, snackbarConstants));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const linkCommitment = (commitment: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        let newCommitmentInteraction = {
          CommitmentID: commitment.CommitmentID,
          InteractionID: itemData.InteractionID,
        };

        dispatch(
          addCommitmentInteraction(
            accessToken,
            newCommitmentInteraction,
            false,
            itemData.InteractionID
          )
        );
      } catch (e) {
        console.error(e);
      }
    })();
  };

  // convert tags to objects for multi autocomplete field
  useEffect(() => {
    // effect
    if (
      ft_all_Tag &&
      tags &&
      tags.length > 0 &&
      !tagIsLoading &&
      itemData.Tags
    ) {
      let newList = tags.filter((item) => itemData.Tags.includes(item.Name));
      setTagItems(newList);
    }
    return () => {};
  }, [tagIsLoading, isEditing]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        let canCreate = checkValidation();
        if (canContinue || warningDisabled || canCreate) {
          dispatch(
            addInteraction(
              accessToken,
              values,
              selectedContacts,
              listGroups,
              listIssues,
              [], //Can't add Grievance from Ne Interaction Form
              true,
              snackbarConstants
            )
          );
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  // modified to correct invalid offset
  // let thisDate = ConvertDateOffset(new Date());
  let thisDate = new Date();

  if (itemData.InteractionID) {
    //Update case
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });

          // check to see whether any required items were removed
          checkGroups();
          checkContacts();
          checkIssues();

          const didEditParent = formik.dirty ? true : false;
          //values.NeedsReview = false;
          dispatch(
            updInteraction(
              accessToken,
              values.InteractionID,
              values,
              selectedContacts,
              listGroups,
              listIssues,
              didEditParent,
              snackbarConstants
            )
          );
        } catch (e) {
          console.error(e);
        }
      })();
    };
  } else {
    itemData.Details = "";
    itemData.InteractionDate = thisDate.toISOString().slice(0, 10);
    itemData.InteractionTitle = "";
    itemData.InteractionTypeID = -1;
    itemData.ProjectID = -1;
    itemData.StatusTypeID = -1;
    itemData.PersonResponsible = currentProfile.UserAccountID;
    itemData.PersonResponsibleName =
      currentProfile.FirstName + " " + currentProfile.Surname;
    itemData.SentimentLevel = null;
    itemData.InitiatedBy = "";
    itemData.LegalSubdivision = "";
    itemData.InternalNotes = "";
    itemData.NeedsReview = false;
    itemData.Internal = false;
    itemData.InteractionPhase = [];
    itemData.ThreadInteraction = [];
    itemData.Tags = [];
  }

  const threadSubmitHandler = (values: any) => {
    if (!values.ThreadID || !values.ThreadTitle || !values.ProjectID) {
      dispatch(openSnackBar(Constants.FAILED, "error"));
      return;
    }

    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        const updateThread = {
          ThreadID: values.ThreadID,
          ThreadTitle: values.ThreadTitle,
          ProjectID: values.ProjectID,
          Summary: values.Summary,
        };

        dispatch(
          updThread(
            accessToken,
            updateThread.ThreadID,
            updateThread,
            false,
            itemData?.InteractionID
          )
        );
      } catch (e) {
        console.error(e);
      }
    })();
  };

  // save when changes made to child records
  useEffect(() => {
    if (didSaveInventory) {
      if (listGrievances) {
        linkGrievance(listGrievances);
      }
      if (ft_comeng_CommitmentInteraction && listCommitments) {
        linkCommitment(listCommitments);
      }
      if (!isInvUpdated) {
        submitFunc(itemData);
      }
      setDidSaveInventory(false);
    }
    return () => {
      // cleanup
    };
  }, [didSaveInventory]);

  // save when changes made to comments
  useEffect(() => {
    (async () => {
      if (didSaveComment) {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          if (itemData.InteractionID) {
            dispatch(
              fetchInteractionCommentsByInteraction(
                accessToken,
                itemData.InteractionID
              )
            );
            dispatch(
              fetchGroupInteractionsByInteraction(
                accessToken,
                itemData.InteractionID
              )
            );
            dispatch(
              fetchContactInteractionsByInteraction(
                accessToken,
                itemData.InteractionID
              )
            );
            dispatch(
              fetchInteractionIssuesByInteraction(
                accessToken,
                itemData.InteractionID
              )
            );
          }

          setDidSaveComment(false);
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [
    didSaveComment,
    itemData.InteractionID,
    dispatch,
    getAccessTokenSilently,
  ]);

  const handleDelete = () => {
    // function to delete current community investment entry
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(
          delInteraction(accessToken, itemData.InteractionID, snackbarConstants)
        );
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const handleTabSelect = (event: any, newValue: any) => {
    setActiveTab(newValue);
    setIsInvUpdated(false);
  };

  const namePhaseString = InteractionPhase.map((phase: any) => phase.Name).join(
    ","
  );

  // Helper function to see if record has either contact/group/issues
  const checkValidation = () => {
    if (!warningDisabled) {
      if (
        selectedContacts.length === 0 &&
        listGroups.length === 0 &&
        listIssues.length === 0
      ) {
        setCanContinue(false);
        setShowWarning(true);
        return false;
      } else {
        setCanContinue(true);
        setShowWarning(false);
        return true;
      }
    } else return true;
  };

  const handleReturn = () => {
    setShowWarning(false);
    setCanContinue(false);
    setWarningDisabled(false);
  };

  const handleContinue = () => {
    setShowWarning(false);
    setCanContinue(true);
    setWarningDisabled(true);
  };

  const formik = useFormik({
    initialValues: itemData,
    validationSchema: InteractionValidationSchema(),
    onSubmit: submitFunc,
    validateOnMount: !isAdding,
  });

  const checkGroups = () => {
    // check whether any groups referenced in comments were removed
    interactionComments.forEach((cmt) => {
      if (cmt.GroupID > 0) {
        let fnd = listGroups.find((grp) => cmt.GroupName === grp);
        if (!fnd) {
          // add item back and display message
          listGroups.push(cmt.GroupName);
          dispatch(
            openSnackBar(
              t("strGen:prompts.delete.cannotremove", {
                fieldname: t("objPlt:objects.group.name"),
                variable: cmt.GroupName,
              }),
              "warning"
            )
          );
        }
      }
    });
  };

  const checkContacts = () => {
    // check whether any contacts referenced in comments were removed
    interactionComments.forEach((cmt) => {
      if (cmt.ContactID > 0) {
        let fnd = selectedContacts.find(
          (cnt) => cnt.ContactID === cmt.ContactID
        );
        if (!fnd) {
          // add contact back and display message
          let newContact = contacts.find(
            (item) => item.ContactID === cmt.ContactID
          );
          if (newContact) {
            selectedContacts.push(newContact);
            dispatch(
              openSnackBar(
                t("strGen:prompts.delete.cannotremove", {
                  fieldname: t("objPlt:objects.contact.name"),
                  variable: cmt.ContactName,
                }),
                "warning"
              )
            );
          }
        }
      }
    });
  };

  const checkIssues = () => {
    // check whether any issues referenced in comments were removed
    interactionComments.forEach((cmt) => {
      if (cmt.Issues) {
        let iss = cmt.Issues.split(", ");
        //IppDebug(cmt.CommentDetails, iss);

        iss.forEach((issueItem) => {
          let fnd = listIssues.find((item) => issueItem === item);
          if (!fnd) {
            // add item back and display message
            listIssues.push(issueItem);
            dispatch(
              openSnackBar(
                t("strGen:prompts.delete.cannotremove", {
                  fieldname: t("objStk:objects.issue.name"),
                  variable: issueItem,
                }),
                "warning"
              )
            );
          }
        });
      }
    });
  };

  // set up for unlink thread warning upon project change
  const [warnUnlinkThread, setWarnUnlinkThread] = useState(false);
  const [firstProjectChange, setFirstProjectChange] = useState(true);
  const [pendingProject, setPendingProject] = useState(null);

  // Function to check project change and set warnings
  const checkProject = (newProjectID: any) => {
    if (
      !isAdding &&
      !!formik.values.ThreadInteraction?.length &&
      firstProjectChange
    ) {
      if (newProjectID !== formik.initialValues?.ProjectID) {
        setWarnUnlinkThread(true);
        setPendingProject(newProjectID);
      } else {
        proceedWithChange(newProjectID);
      }
    } else {
      proceedWithChange(newProjectID);
    }
  };

  const proceedWithChange = (newProjectID: any) => {
    setFirstProjectChange(false);
    formik.setFieldValue("ProjectID", newProjectID);
    formik.setFieldValue("ThreadInteraction", []);
    setWarnUnlinkThread(false);
    setPendingProject(null);
  };

  // Handle revert project
  const handleRevertProject = (values: any) => {
    setWarnUnlinkThread(false);
    setPendingProject(null);
    formik.setFieldValue("ProjectID", itemData.ProjectID);
    formik.setFieldValue("ThreadInteraction", values);
  };

  // Handle confirm change
  const handleChangeProject = () => {
    if (pendingProject !== null) {
      proceedWithChange(pendingProject);
    }
  };

  // this specifically handles the reviewed button
  useEffect(() => {
    if (formik.values.NeedsReview === false) {
      let review = formik.values;
      reviewPress && markReviewed(review);
    }
    formik.dirty && setReviewPress(false);
    if (!isAdding && !isEditing) {
      formik.validateForm();
    }
    return () => {
      // cleanup
    };
  }, [reviewPress, isEditing]);

  let markReviewed: (values: any) => void;
  if (itemData.InteractionID) {
    //mark as Review case
    markReviewed = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });

          dispatch(
            updInteraction(
              accessToken,
              itemData.InteractionID,
              values,
              null,
              null,
              null,
              true,
              snackbarConstants
            )
          );
        } catch (e) {
          console.error(e);
        }
      })();
    };
  }

  // update ti formik value if thread tab updates
  useEffect(() => {
    if (isEditing) {
      let selectedThreads = listThreads?.map((t: any) => {
        return { ...t, DisplayValue: `T-${t.SequenceID}  ${t.ThreadTitle}` };
      });
      formik.setFieldValue("ThreadInteraction", selectedThreads);
    }
  }, [isInvUpdated, isEditing]);

  let viewForm = (
    <Box display="flex" justifyContent="center">
      <Grid container className={classes.editForm} spacing={1}>
        <Grid item xs={12}>
          {isInvUpdated && (
            <Prompt
              when={isInvUpdated}
              message={t("strGen:prompts.unsavedchanges")}
            />
          )}
          <Paper className={classes.boxSpace}>
            <Grid container spacing={1}>
              <IppFormHeader
                title={t("objStk:objects.interaction.name")}
                isEditing={isEditing}
                isAdding={isAdding}
              />
              {(!formik.isValid || itemData.NeedsReview) && (
                <IppNeedsReviewBanner
                  objectTitle={t("objStk:objects.interaction.name")}
                  acceptOnClick={
                    formik.isValid
                      ? () => {
                          formik
                            .setFieldValue("NeedsReview", false)
                            .then((data: any) => setReviewPress(true));
                        }
                      : () => setIsEditing(true)
                  }
                  reviewOnClick={
                    formik.isValid ? () => setIsEditing(true) : undefined
                  }
                  reviewButtonText={t("strGen:buttons.review")}
                  buttonColor={!formik.isValid ? "primary" : undefined}
                  alertText={
                    !formik.isValid
                      ? undefined
                      : t(
                          "strGen:components.needsreviewbanner.pendingreviewbccmessage"
                        )
                  }
                  acceptButtonText={
                    !formik.isValid
                      ? undefined
                      : t("strGen:buttons.markreviewed")
                  }
                />
              )}
              <Grid item xs={12}>
                <IppDisplayField
                  showLabel={false}
                  value={
                    <>
                      <Typography variant="subtitle1" gutterBottom>
                        {"C-" +
                          formik.values.CommunicationID +
                          "  " +
                          formik.values.InteractionTitle}
                      </Typography>
                    </>
                  }
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEmphasis={true}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objStk:objects.interaction.fields.date")}
                  value={ConvertToJSDate(formik.values.InteractionDate)}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t(
                    "objStk:objects.interaction.fields.communicationtype"
                  )}
                  value={formik.values.InteractionTypeName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objStk:objects.interaction.fields.status")}
                  value={formik.values.StatusTypeName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showWarning={itemData.IsOverdue}
                  warningMessage={t(
                    "objStk:objects.interaction.statustype.overdue",
                    {
                      fieldname: t("objStk:objects.interaction.fields.date"),
                    }
                  )}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objStk:objects.interaction.fields.project")}
                  value={formik.values.ProjectName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              {ft_eng_Phase && (
                <Grid item xs={6}>
                  <IppDisplayChip
                    label={t("objStk:objects.interaction.fields.phase", {
                      count: namePhaseString.split(",").length,
                    })}
                    value={namePhaseString}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
              )}
              {ft_all_Tag && tags.length > 0 && (
                <Grid item xs={6}>
                  <IppDisplayChip
                    label={t("objPlt:objects.tag.name", {
                      count: itemData.Tags ? itemData.Tags.length : 1,
                    })}
                    value={itemData.Tags}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <IppDisplayField
                  label={t(
                    "objStk:objects.interaction.fields.personresponsible"
                  )}
                  value={formik.values.PersonResponsibleName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objStk:objects.interaction.fields.initiatedby")}
                  value={formik.values.InitiatedBy}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              {ft_eng_IntenalCommunication && (
                <Grid item xs={6} style={{ marginTop: 30 }}>
                  <IppDisplayCheckbox
                    label={
                      t(
                        "objStk:objects.interaction.fields.internalcommunication"
                      ) + "?"
                    }
                    isChecked={formik.values.Internal}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
              )}
              {ft_eng_PILSD && (
                <Grid item xs={12}>
                  <IppDisplayChip
                    label={t(
                      "objStk:objects.interaction.fields.legalsubdivision"
                    )}
                    value={formik.values.LegalSubdivision}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
              )}
              {ft_eng_Sentiment && (
                <Grid item xs={12}>
                  <IppSentiment
                    id="SentimentLevel"
                    label={t("objStk:objects.interaction.fields.sentiment")}
                    sentimentLevel={formik.values.SentimentLevel}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    onChangeFunction={null}
                    showEdit={showEdit}
                  />
                </Grid>
              )}
              {ft_eng_InternalNotes && (
                <Grid item xs={12}>
                  <IppDisplayRichText
                    label={t("objStk:objects.interaction.fields.internalnotes")}
                    value={formik.values.InternalNotes}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <IppDisplayRichText
                  label={t("objStk:objects.interaction.fields.details")}
                  value={formik.values.Details}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                  button={
                    aiEnabled ? (
                      <IppGenerateSummaryButton
                        onClick={() => {
                          setGenerateObjectType("interaction");
                          setGenerateDialogOpen(true);
                        }}
                        disabled={!formik.isValid}
                        toolTipMessage={
                          !formik.isValid
                            ? t(
                                "strGen:buttons.tooltip.generatesummarydisabled",
                                {
                                  fieldname: t(
                                    "objStk:objects.interaction.fields.details"
                                  ),
                                }
                              )
                            : t("strGen:buttons.tooltip.generatedetails")
                        }
                        isIconButton={false}
                        variant="text"
                        child={t("strGen:buttons.generatefieldname", {
                          fieldname: t(
                            "objStk:objects.interaction.fields.details"
                          ),
                        })}
                        sx={{
                          fontSize: "0.7rem",
                        }}
                      />
                    ) : undefined
                  }
                />
              </Grid>
              {formik.values.EmailContent && (
                <Grid item xs={12}>
                  <IppDisplayRichText
                    label={t("objStk:objects.interaction.fields.emailcontent")}
                    value={SanitizeHTML(formik.values.EmailContent)}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <IppFormButtons
                  isEditing={isEditing}
                  isAdding={isAdding}
                  setIsEditing={setIsEditing}
                  resetFunction={() => formik.resetForm()}
                  showDelete={showEdit}
                  deleteFunction={() => handleDelete()}
                  fetchFunc={fetchDeleteChecksByInteraction}
                  info={t("strGen:prompts.deletechecks.unlinkitems")}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );

  // Group Suggestions Setup
  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        if (
          !isAdding &&
          itemData.InteractionID &&
          (!formik.isValid || itemData?.NeedsReview)
        ) {
          // Get group suggestions if it's Needs Review
          dispatch(
            fetchSuggestedGroupsByInteractionContacts(
              accessToken,
              itemData.InteractionID
            )
          );
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [
    clientId,
    itemData?.InteractionID,
    dispatch,
    getAccessTokenSilently,
    isAdding,
    formik?.isValid,
  ]);
  // only show suggestions that not in listGroups
  let filteredSuggestedGroups = suggestedGroupList?.filter(
    (suggestedGroup) => !listGroups?.includes(suggestedGroup?.GroupName)
  );
  let groupSuggestions = filteredSuggestedGroups?.map((suggestedGroup) => ({
    value: suggestedGroup?.GroupName,
    label: `${suggestedGroup?.GroupName} (${suggestedGroup?.ContactCount} ${t(
      "objPlt:objects.contact.name",
      {
        count: suggestedGroup?.ContactCount,
      }
    )})`,
  }));
  // only show suggestion if has suggestions and iteraction NeedsReview
  let showGroupSuggestions =
    !!groupSuggestions?.length &&
    (!formik.isValid || itemData?.NeedsReview) &&
    itemData?.InteractionID;

  //  get lists for thread autocomplete fields
  useEffect(() => {
    const threadIDs = (formik.values?.ThreadInteraction || []).map(
      (ti: any) => ti.ThreadID
    );
    // if projectID changes, reset ThreadOptions, empty the ThreadInteraction
    let threadOptions = unfilteredThreads
      .filter(
        (thread) =>
          thread.ProjectID === formik.values.ProjectID &&
          !threadIDs.includes(thread.ThreadID)
      )
      .map((t: any) => {
        return {
          ...t,
          DisplayValue: `T-${t.SequenceID}  ${t.ThreadTitle}`,
          lastInteractionDate: ConvertDateOffset(t.lastInteractionDate),
        };
      });
    setThreadTokenOptions(threadOptions);
  }, [
    itemData.InteractionID,
    formik.values?.ProjectID,
    formik.values?.ThreadInteraction,
  ]);

  const threadColumns = [
    {
      field: "SequenceID",
      title: t("objStk:objects.thread.fields.id"),
      format: "T-{0:0}",
      filter: "string",
      columnWidth: 80,
      noLink: true,
    },
    {
      field: "ThreadTitle",
      title: t("objStk:objects.thread.name"),
      columnWidth: 300,
    },
    {
      field: "Contacts",
      title: t("objStk:objects.interaction.fields.contact", { count: 2 }),
      commaDelimited: true,
    },
    {
      field: "Groups",
      title: t("objStk:objects.interaction.fields.groups"),
      commaDelimited: true,
    },
    {
      field: "InteractionCount",
      title: t("objStk:objects.thread.interactioncount"),
    },
    {
      field: "lastInteractionDate",
      title: t("objStk:objects.thread.lastinteractiondate"),
      filter: "date",
      format: "{0:d}",
    },
  ];
  const DetailComponent = (props: any) => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <IppDisplayRichText
            label={t("objStk:objects.thread.fields.summary")}
            value={props.dataItem?.Summary}
            isEditing={false}
            setIsEditing={() => {}}
            showEdit={false}
            height={props.dataItem?.Summary ? "80px" : ""}
            button={
              aiEnabled ? (
                <IppGenerateSummaryButton
                  onClick={() => {
                    setGenerateObjectType("thread");
                    setThreadData(props.dataItem);
                    setGenerateDialogOpen(true);
                  }}
                  disabled={
                    !props.dataItem?.ThreadTitle || !props.dataItem?.ProjectID
                  }
                  toolTipMessage={
                    !props.dataItem?.ThreadTitle || !props.dataItem?.ProjectID
                      ? t("strGen:buttons.tooltip.generatesummarydisabled", {
                          fieldname: `a ${t(
                            "objStk:objects.thread.fields.summary"
                          )}`,
                        })
                      : t("strGen:buttons.tooltip.generatesummary")
                  }
                  isIconButton={false}
                  variant="text"
                  child={t("strGen:buttons.generatefieldname", {
                    fieldname: t("objStk:objects.thread.fields.summary"),
                  })}
                  sx={{
                    fontSize: "0.7rem",
                  }}
                />
              ) : undefined
            }
          />
        </Grid>
      </Grid>
    );
  };
  let threadOptionProps = {
    optionColumns: threadColumns,
    optionDetail: DetailComponent,
    optionSearch: "ThreadTitle",
  };

  let editForm = (
    <Root>
      {!formik.isSubmitting && (
        <Prompt
          when={formik.dirty}
          message={t("strGen:prompts.unsavedchanges")}
        />
      )}
      <form onSubmit={formik.handleSubmit} id="form">
        <Grid container spacing={1}>
          <IppFormHeader
            title={t("objStk:objects.interaction.name")}
            isEditing={isEditing}
            isAdding={isAdding}
          />

          <Grid item xs={isAdding ? 12 : 9}>
            <IppTextField
              id="InteractionTitle"
              label={t("objStk:objects.interaction.fields.title")}
              value={formik.values.InteractionTitle}
              required
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.InteractionTitle}
              errorsExpression={formik.errors.InteractionTitle}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          {!isAdding && (
            <Grid item xs={3}>
              <IppStaticTextfield
                id="CommunicationID"
                value={"C-" + formik.values.CommunicationID}
                label={t("objStk:objects.interaction.sequenceid")}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <IppDatePicker
              id="InteractionDate"
              required
              label={t("objStk:objects.interaction.fields.date")}
              value={ConvertDateOffset(formik.values.InteractionDate)}
              onChangeFunction={(newValue: any) => {
                formik.setFieldValue(
                  "InteractionDate",
                  GetJSDate(newValue),
                  true
                );
                formik.setFieldTouched("InteractionDate", true, false); //affects live validations
              }}
              errorsExpression={formik.errors.InteractionDate}
              touchedExpression={formik.touched.InteractionDate}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          <Grid item xs={6}>
            <IppAutocomplete
              id="InteractionTypeID"
              required
              options={clientInteractionTypes}
              value={interactionTypes.find((obj) => {
                return (
                  obj.InteractionTypeID === formik.values.InteractionTypeID
                );
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue(
                    "InteractionTypeID",
                    newValue.InteractionTypeID
                  );
                } else {
                  formik.setFieldValue("InteractionTypeID", -1);
                }
              }}
              label={t("objStk:objects.interaction.fields.communicationtype")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.InteractionTypeName}
              errorFunction={formik.errors.InteractionTypeID}
              touchedFunction={formik.touched.InteractionTypeID}
              textValueFunction={formik.values.InteractionTypeName}
            />
          </Grid>
          <Grid item xs={6}>
            <IppAutocomplete
              id="StatusTypeID"
              required
              options={statusTypes}
              value={statusTypes.find((obj) => {
                return obj.StatusTypeID === formik.values.StatusTypeID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("StatusTypeID", newValue.StatusTypeID);
                } else {
                  formik.setFieldValue("StatusTypeID", -1);
                }
              }}
              label={t("objStk:objects.interaction.fields.status")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.StatusTypeName}
              errorFunction={formik.errors.StatusTypeID}
              touchedFunction={formik.touched.StatusTypeID}
              textValueFunction={formik.values.StatusTypeName}
            />
          </Grid>
          <Grid item xs={6}>
            <IppAutocomplete
              id="ProjectID"
              required
              options={projects}
              value={projects.find((obj) => {
                return obj.ProjectID === formik.values.ProjectID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                checkProject(newValue?.ProjectID || -1);
              }}
              label={t("objStk:objects.interaction.fields.project")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.ProjectName}
              errorFunction={formik.errors.ProjectID}
              touchedFunction={formik.touched.ProjectID}
              textValueFunction={formik.values.ProjectName}
            />
          </Grid>
          {ft_eng_Phase && (
            <Grid item xs={6}>
              <IppTokenMultiSelect
                id="InteractionPhase"
                label={t("objStk:objects.interaction.fields.phase", {
                  count: formik.values.InteractionPhase
                    ? formik.values.InteractionPhase.length
                    : 1,
                })}
                required={false}
                options={phases}
                noOptionsText={t("strGen:components.nooptionstext")}
                value={
                  formik.values.InteractionPhase
                    ? formik.values.InteractionPhase
                    : InteractionPhase
                }
                setValue={(newValue: any) => {
                  formik.setFieldValue("InteractionPhase", newValue);
                }}
                touchedFunction={formik.touched.InteractionPhase}
                errorFunction={formik.errors.InteractionPhase}
                isEditing={true}
                optionLabelFunction={(option: any) => option.Name}
                setIsEditing={null}
              />
            </Grid>
          )}
          {ft_all_Tag && tags.length > 0 && (
            <Grid item xs={6}>
              <IppTokenMultiSelect
                id="Tags"
                label={t("objPlt:objects.tag.name_other", {
                  count: formik.values.Tags ? formik.values.Tags.length : 1,
                })}
                required={false}
                options={tags}
                noOptionsText={t("strGen:components.nooptionstext")}
                value={tagItems}
                setValue={(newValue: any) => {
                  setTagItems(newValue);
                  formik.setFieldValue("Tags", newValue);
                }}
                touchedFunction={formik.touched.Tags}
                errorFunction={formik.errors.Tags}
                isEditing={true}
                optionLabelFunction={(option: any) => option.Name}
                setIsEditing={null}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <IppPersonResponsible
              id="PersonResponsible"
              required
              value={allUsers.find((obj) => {
                return obj.UserAccountID === formik.values.PersonResponsible;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue(
                    "PersonResponsible",
                    newValue.UserAccountID
                  );
                } else {
                  formik.setFieldValue("PersonResponsible", null);
                }
              }}
              label={t("objStk:objects.interaction.fields.personresponsible")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) =>
                option.FirstName + " " + option.Surname
              }
              errorFunction={formik.errors.PersonResponsible}
              touchedFunction={formik.touched.PersonResponsible}
              textValueFunction={formik.values.PersonResponsibleName}
              projectID={formik.values.ProjectID}
            />
          </Grid>
          <Grid item xs={6}>
            <IppAutocomplete
              id="InitiatedBy"
              required
              options={InitiatedByValues}
              value={formik.values.InitiatedBy}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("InitiatedBy", newValue);
                } else {
                  formik.setFieldValue("InitiatedBy", "");
                }
              }}
              label={t("objStk:objects.interaction.fields.initiatedby")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option}
              errorFunction={formik.errors.InitiatedBy}
              touchedFunction={formik.touched.InitiatedBy}
              textValueFunction={formik.values.InitiatedBy}
              toolTip={t("strGen:tooltips.interaction.fields.initiatedby")}
            />
          </Grid>
          {ft_eng_IntenalCommunication && (
            <Grid item xs={6}>
              <IppCheckbox
                id="Internal"
                label={
                  t("objStk:objects.interaction.fields.internalcommunication") +
                  "?"
                }
                value={formik.values.Internal}
                onChangeFunction={formik.handleChange}
                isEditing={isEditing}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <IppTokenAutocomplete
              id="GroupList"
              label={t("objStk:objects.interaction.fields.groupsinvolved", {
                count: 2,
              })}
              options={stakeholderGroups.map((option) => option.GroupName)}
              selectedValues={listGroups}
              setSelectedValues={(newValue: any) => {
                setListGroups(newValue);
              }}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              setCanSave={setCanContinue}
            />
            {showGroupSuggestions && (
              <Grid item xs={12} marginX={2}>
                <SuggestionBanner
                  title={t("strGen:suggestion.title", {
                    objectname1: t("objPlt:objects.group.name", {
                      count: groupSuggestions?.length,
                    }),
                    number: "one",
                    objectname2: t("objPlt:objects.contact.name"),
                  })}
                  suggestions={groupSuggestions}
                  list={listGroups}
                  setList={setListGroups}
                />
              </Grid>
            )}
          </Grid>

          <Grid item xs={12}>
            <IppContactAutoComplete
              options={contacts}
              selectedContacts={selectedContacts}
              setSelectedContacts={setSelectedContacts}
            />
          </Grid>
          <Grid item xs={12}>
            <IppTokenAutocomplete
              id="IssueList"
              label={t("objStk:objects.interaction.fields.relatedissues", {
                count: 2,
              })}
              options={issues.map((option) => option.IssueName)}
              selectedValues={listIssues}
              setSelectedValues={setListIssues}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              setCanSave={setCanContinue}
            />
          </Grid>
          {ft_eng_PILSD && (
            <Grid item xs={12}>
              <IppMultiTextField
                id="LegalSubdivision"
                label={t("objStk:objects.interaction.fields.legalsubdivision")}
                required={false}
                options={[]}
                noOptionsText={t("strGen:components.nooptionstext")}
                value={formik.values.LegalSubdivision}
                setValue={(newValue: any) =>
                  formik.setFieldValue("LegalSubdivision", newValue.toString())
                }
                touchedFunction={formik.touched.LegalSubdivision}
                errorFunction={formik.errors.LegalSubdivision}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                multiple={true}
                autoPopulate={false}
                freeSolo={true}
              />
            </Grid>
          )}
          {ft_eng_Thread && ft_eng_ThreadQuickAdd && (
            <Grid item xs={12}>
              <IppTokenTable
                id="ThreadInteraction"
                label={t("objStk:objects.interaction.fields.thread")}
                value={formik.values.ThreadInteraction}
                setValue={(newValue: any) => {
                  formik.setFieldValue("ThreadInteraction", newValue);
                }}
                optionLabelFunction={(option) => option.DisplayValue}
                disabled={
                  formik.values.ProjectID === -1
                    ? t("strGen:tooltips.thread.buttons.disabledchildadd")
                    : ""
                }
                required={false}
                title={t("objStk:objects.thread.name")}
                parentTitle={t("objStk:objects.interaction.name")}
                idField="ThreadInteractionID"
                relatedField="ThreadID"
                options={threadTokenOptions}
                optionTableProps={threadOptionProps}
                tableName="InteractionThread"
              />
            </Grid>
          )}
          {ft_eng_Sentiment && (
            <Grid item xs={12}>
              <IppSentiment
                id="SentimentLevel"
                label={t("objStk:objects.interaction.fields.sentiment")}
                sentimentLevel={formik.values.SentimentLevel}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                onChangeFunction={(event: ChangeEvent, newValue: any) => {
                  if (newValue) {
                    formik.setFieldValue("SentimentLevel", newValue);
                  } else {
                    formik.setFieldValue("SentimentLevel", null);
                  }
                }}
                errorsExpression={formik.errors.SentimentLevel}
              />
            </Grid>
          )}
          {ft_eng_InternalNotes && (
            <Grid item xs={12}>
              <IppRichTextEditor
                id="InternalNotes"
                label={t("objStk:objects.interaction.fields.internalnotes")}
                value={formik.values.InternalNotes}
                touchedExpression={formik.touched.InternalNotes}
                errorsExpression={formik.errors.InternalNotes}
                isEditing={isEditing}
                setFieldValue={formik.setFieldValue}
                height={150}
                toolTip={t("strGen:tooltips.interaction.fields.internalnotes")}
                standardStatementsRecordType="InteractionInternalNotes"
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <IppRichTextEditor
              key={detailsKey}
              id="Details"
              label={t("objStk:objects.interaction.fields.details")}
              value={formik.values.Details}
              touchedExpression={formik.touched.Details}
              errorsExpression={formik.errors.Details}
              isEditing={isEditing}
              setFieldValue={formik.setFieldValue}
              height={150}
              standardStatementsRecordType="InteractionDetails"
              toolTip={t("strGen:tooltips.interaction.fields.overview")}
              customEditorTool={
                aiEnabled && !isAdding ? (
                  <IppGenerateSummaryButton
                    onClick={() => {
                      setGenerateObjectType("interaction");
                      setGenerateDialogOpen(true);
                    }}
                    toolTipMessage={t("strGen:buttons.tooltip.generatedetails")}
                    onMouseDown={(e) => e.preventDefault()}
                    onPointerDown={(e) => e.preventDefault()}
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    sx={{
                      ...props.sx,
                      textTransform: "none",
                      fontWeight: "normal",
                      fontFamily: "inherit",
                      fontSize: "inherit",
                      padding: "8px",
                      height: "38px",
                      width: "38px",
                      "& .MuiSvgIcon-root": {
                        fontSize: "1.25rem",
                      },
                    }}
                  />
                ) : undefined
              }
            />
          </Grid>
          {formik.values.EmailContent && (
            <Grid item xs={12}>
              <IppDisplayRichText
                label={t("objStk:objects.interaction.fields.emailcontent")}
                value={SanitizeHTML(formik.values.EmailContent)}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <IppFormButtons
              isEditing={isEditing}
              isAdding={isAdding}
              setIsEditing={setIsEditing}
              resetFunction={() => formik.resetForm()}
              showDelete={true}
              deleteFunction={() => handleDelete()}
              fetchFunc={fetchDeleteChecksByInteraction}
              info={t("strGen:prompts.deletechecks.unlinkitems")}
            />
          </Grid>
        </Grid>
      </form>
    </Root>
  );

  const interactionActionColumns = [
    {
      field: "ActionDescription",
      title: t("objStk:objects.interactionaction.name"),
    },
    {
      field: "PersonResponsibleName",
      title: t("objStk:objects.interactionaction.fields.responsible"),
    },
    {
      field: "RequiredDate",
      title: t("objStk:objects.interactionaction.fields.daterequired"),
      filter: "date",
      columnWidth: 120,
    },
    {
      field: "CompletionDate",
      title: t("objStk:objects.interactionaction.fields.datecompleted"),
      filter: "date",
      columnWidth: 120,
    },
    {
      field: "ActionStatusName",
      title: t("objStk:objects.interactionaction.fields.status"),
      chips: true,
      columnWidth: 150,
    },
  ];

  const groupColumns = [
    { field: "GroupName", title: t("objPlt:objects.group.name") },
    {
      field: "OrganizationTypeName",
      title: t("objPlt:objects.group.fields.grouptype"),
    },
    {
      field: "TownCity",
      title: t("objPlt:objects.group.fields.towncity"),
    },
    {
      field: "ProvinceState",
      title: t("objPlt:objects.group.fields.provincestate"),
    },
  ];

  const contactColumns = [
    {
      field: "ContactName",
      title: t("objPlt:objects.contact.name", { count: 2 }),
    },
    { field: "ContactTitle", title: t("objPlt:objects.contact.fields.title") },
    {
      field: "InternalUser",
      title: t("objPlt:objects.contact.fields.internal"),
      checkbox: true,
      columnWidth: 80,
    },
    { field: "PrimaryPhone", title: t("objPlt:objects.contact.fields.phone") },
    {
      field: "Email",
      title: t("objPlt:objects.contact.fields.email"),
      commaDelimited: true,
    },
  ];

  const issueColumns = [
    { field: "IssueName", title: t("objStk:objects.issue.name") },
    {
      field: "IssueDescription",
      title: t("objStk:objects.issue.fields.description"),
    },
  ];

  const grievanceColumns = [
    {
      field: "SequenceID",
      title: t("objStk:objects.grievance.sequenceid"),
      format: "G-{0:0}",
      filter: "text",
      columnWidth: 60,
    },
    { field: "GrievanceName", title: t("objStk:objects.grievance.columnname") },
    {
      field: "ReportedDate",
      title: t("objStk:objects.grievance.fields.datereported"),
      filter: "date",
    },
    {
      field: "ResolutionDate",
      title: t("objStk:objects.grievance.fields.dateresolved"),
      filter: "date",
    },
    {
      field: "GrievanceStatusText",
      title: t("objStk:objects.grievance.fields.status"),
    },
  ];

  const commentColumns = [
    {
      field: "SequenceID",
      title: t("objStk:objects.interactioncomment.sequenceid"),
      format: "CC-{0:0}",
      columnWidth: 80,
    },
    {
      field: "CommentDetails",
      title: t("objStk:objects.interactioncomment.name"),
      className: "impact-nowrap",
    },
    {
      field: "SentimentLevel",
      title: t("objStk:objects.interactioncomment.fields.sentiment"),
      icons: true,
      columnWidth: 60,
    },
    {
      field: "ContactName",
      title: t("objStk:objects.interactioncomment.fields.contact"),
    },
    {
      field: "GroupName",
      title: t("objStk:objects.interactioncomment.fields.group"),
    },
    {
      field: "Issues",
      title: t("objStk:objects.interactioncomment.fields.issues"),
      commaDelimited: true,
    },
    {
      field: "Response",
      title: t("objStk:objects.interactioncomment.fields.response"),
    },
  ];

  const commitmentColumns = [
    {
      field: "SequenceID",
      title: t("objCom:objects.commitment.sequenceid"),
      format: "CMT-{0:0}",
      filter: "text",
      columnWidth: 80,
    },
    {
      field: "PriorityName",
      title: t("objCom:objects.commitment.fields.priority"),
      icons: true,
      columnWidth: 60,
    },
    { field: "CommitmentTitle", title: t("objCom:objects.commitment.name") },
    {
      field: "PersonResponsibleName",
      title: t("objCom:objects.commitmentaction.fields.responsible"),
    },
    {
      field: "CommitmentStatusTypeName",
      title: t("objCom:objects.commitment.fields.status"),
      columnWidth: 150,
      chips: true,
    },
  ];

  // set booleans to determine if group can be deleted
  const groupData = groupInteractions.map((p) => {
    let n = Object.assign({}, p) as any;
    const fnd = interactionComments.find((item) => item.GroupID === p.GroupID);
    n.CanDelete = fnd ? false : true;
    return n;
  });

  const contactData = contactInteractions.map((p) => {
    let n = Object.assign({}, p) as any;
    const fnd = interactionComments.find(
      (item) => item.ContactID === p.ContactID
    );
    n.CanDelete = fnd ? false : true;
    n.ContactName = p.ContactName
      ? p.ContactName.trim() !== ""
        ? p.ContactName.trim()
        : `* ${t("objPlt:objects.contact.missingname")} *`
      : `* ${t("objPlt:objects.contact.missingname")} *`;
    return n;
  });

  const issueData = iiList.map((p) => {
    let n = Object.assign({}, p) as any;
    const fnd = issueComments.find((item) => item.IssueID === p.IssueID);
    n.CanDelete = fnd ? false : true;
    return n;
  });

  let pageLayout =
    pageLoading ||
    clientIsLoading ||
    typeIsLoading ||
    projectIsLoading ||
    statusIsLoading ||
    interactionPhaseIsLoading ||
    interactionIssueIsLoading ||
    grievanceInteractionIsLoading ||
    interactionCommentIsLoading ||
    standardStatementIsLoading ||
    tagIsLoading ? (
      <LoadingIndicator />
    ) : isAdding ? (
      <Box display="flex" justifyContent="center">
        <Grid container className={classes.editForm} spacing={1}>
          <Grid item xs={12}>
            <Paper className={classes.boxSpace}>{editForm} </Paper>
          </Grid>
        </Grid>
      </Box>
    ) : (
      <Grid container spacing={1}>
        <Grid item xl={4} xs={customBP ? 12 : 5}>
          {isEditing ? (
            <Dialog open={isEditing} fullWidth maxWidth="lg">
              <DialogContent>{editForm}</DialogContent>
            </Dialog>
          ) : (
            <div>{viewForm}</div>
          )}
        </Grid>
        <Grid item xl={8} xs={customBP ? 12 : 7}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={activeTab}
                      onChange={handleTabSelect}
                      variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                    >
                      <Tab
                        value={0}
                        label={`${t("objPlt:objects.contact.name", {
                          count: 2,
                        })} (${contactInteractions.length})`}
                      />
                      <Tab
                        value={1}
                        label={`${t("objPlt:objects.group.name", {
                          count: 2,
                        })} (${groupInteractions.length})`}
                      />
                      <Tab
                        value={2}
                        label={`${t("objStk:objects.issue.name", {
                          count: 2,
                        })} (${iiList.length})`}
                      />
                      <Tab
                        value={3}
                        label={`${t("objStk:objects.interactionaction.name", {
                          count: 2,
                        })} (${interactionActions.length})`}
                      />
                      {ft_eng_Grievance && (
                        <Tab
                          value={4}
                          label={`${t("objStk:objects.grievance.name", {
                            count: 2,
                          })} (${grievanceInteractions.length})`}
                        />
                      )}
                      {ft_eng_Thread && (
                        <Tab
                          value={5}
                          label={`${t("objStk:objects.thread.name", {
                            count: 2,
                          })} (${threadInteractionList?.length})`}
                        />
                      )}
                      {showCommitmentInteractions && (
                        <Tab
                          value={6}
                          label={`${t("objCom:objects.commitment.name", {
                            count: 2,
                          })} (${commitmentInteractions.length})`}
                        />
                      )}
                      <Tab
                        value={8}
                        label={`${t("objPlt:objects.attachment.name", {
                          count: 2,
                        })} (${attachCount.Count})`}
                      />
                    </Tabs>
                  </Box>
                  <IppTabPanel value={activeTab} index={0}>
                    <Box>
                      <IppChildInventory
                        title={t("objPlt:objects.contact.name")}
                        parentTitle={t("objStk:objects.interaction.name")}
                        linkURL="contacts"
                        tableData={contactData}
                        idField="ContactInteractionID"
                        nameField="ContactName"
                        parentID={itemData.InteractionID}
                        parentProjectID={itemData.ProjectID}
                        relatedField="ContactID"
                        columns={contactColumns}
                        showEdit={true}
                        tableName="ContactInteraction"
                        id="CommunicationList"
                        label={t(
                          "objStk:objects.interaction.communicationhistory"
                        )}
                        options={contacts}
                        selectedValues={selectedContacts}
                        setSelectedValues={setSelectedContacts}
                        setDidSaveInventory={setDidSaveInventory}
                        parentValue={null}
                        showOptions={true}
                      />
                    </Box>
                  </IppTabPanel>
                  <IppTabPanel value={activeTab} index={1}>
                    <Box>
                      <IppChildInventory
                        title={t("objPlt:objects.group.name")}
                        parentTitle={t("objStk:objects.interaction.name")}
                        linkURL="groups"
                        tableData={groupData}
                        idField="GroupInteractionID"
                        nameField="GroupName"
                        parentID={itemData.InteractionID}
                        parentProjectID={itemData.ProjectID}
                        relatedField="GroupID"
                        columns={groupColumns}
                        showEdit={true}
                        tableName="GroupInteraction"
                        id="GroupList"
                        label={t(
                          "objStk:objects.interaction.fields.groupsinvolved",
                          {
                            count: 2,
                          }
                        )}
                        options={stakeholderGroups}
                        selectedValues={listGroups}
                        setSelectedValues={setListGroups}
                        setDidSaveInventory={setDidSaveInventory}
                        parentValue={null}
                        showOptions={true}
                        suggestions={groupSuggestions}
                        showSuggestions={showGroupSuggestions}
                        suggestionTitle={t("strGen:suggestion.title", {
                          objectname1: t("objPlt:objects.group.name", {
                            count: groupSuggestions?.length,
                          }),
                          number: "one",
                          objectname2: t("objPlt:objects.contact.name"),
                        })}
                      />
                    </Box>
                  </IppTabPanel>
                  <IppTabPanel value={activeTab} index={2}>
                    <Box>
                      <IppChildInventory
                        title={t("objStk:objects.issue.name")}
                        parentTitle={t("objStk:objects.interaction.name")}
                        linkURL="issues"
                        tableData={issueData}
                        idField="InteractionIssueID"
                        nameField="IssueName"
                        parentID={itemData.InteractionID}
                        parentProjectID={itemData.ProjectID}
                        relatedField="IssueID"
                        columns={issueColumns}
                        showEdit={true}
                        tableName="InteractionIssue"
                        id="IssueList"
                        label={t(
                          "objStk:objects.interaction.fields.relatedissues",
                          {
                            count: 2,
                          }
                        )}
                        options={issues}
                        selectedValues={listIssues}
                        setSelectedValues={setListIssues}
                        setDidSaveInventory={setDidSaveInventory}
                        parentValue={null}
                        showOptions={true}
                      />
                    </Box>
                  </IppTabPanel>
                  <IppTabPanel value={activeTab} index={3}>
                    <Box>
                      <IppChildInventory
                        title={t("objStk:objects.interactionaction.namefull")}
                        parentTitle={t("objStk:objects.interaction.name")}
                        linkURL={`communications/${itemData.InteractionID}/actions`}
                        tableData={ia}
                        idField="InteractionActionID"
                        nameField="ActionDescription"
                        parentID={itemData.InteractionID}
                        parentProjectID={itemData.ProjectID}
                        relatedField="InteractionActionID"
                        columns={interactionActionColumns}
                        showAdd={true}
                        tableName="InteractionAction"
                        id="InteractionActionList"
                        label=""
                        options={interactionActions}
                        selectedValues={interactionActions}
                        setSelectedValues={null}
                        setDidSaveInventory={setDidSaveInventory}
                        parentValue={itemData.InteractionTitle}
                        disabled={
                          !itemData.ProjectID || !itemData.PersonResponsible
                            ? t(
                                "strGen:tooltips.interactionaction.buttons.disabledchildadd"
                              )
                            : ""
                        }
                      />
                    </Box>
                  </IppTabPanel>
                  {ft_eng_Grievance && (
                    <IppTabPanel value={activeTab} index={4}>
                      <Box>
                        <IppChildInventory
                          title={t("objStk:objects.grievance.name")}
                          parentTitle={t("objStk:objects.interaction.name")}
                          linkURL="grievances"
                          tableData={gi}
                          idField="GrievanceInteractionID"
                          nameField="GrievanceName"
                          parentID={itemData.InteractionID}
                          parentProjectID={itemData.ProjectID}
                          relatedField="GrievanceID"
                          columns={grievanceColumns}
                          showAdd={true}
                          showLinkButton={true}
                          tableName="GrievanceInteraction"
                          id="GrievanceList"
                          label={t("objStk:objects.grievance.grievancehistory")}
                          options={grievances}
                          unfilteredOptions={unfiliteredGrievances}
                          selectedValues={listGrievances}
                          setSelectedValues={setListGrievances}
                          setDidSaveInventory={setDidSaveInventory}
                          parentValue={itemData}
                          showOptions={true}
                          secondLinkCell={1}
                        />
                      </Box>
                    </IppTabPanel>
                  )}
                  {ft_eng_Thread && (
                    <IppTabPanel value={activeTab} index={5}>
                      <Box>
                        <IppChildInventory
                          title={t("objStk:objects.thread.name")}
                          parentTitle={t("objStk:objects.interaction.name")}
                          linkURL="communicationthreads"
                          tableData={originalThreads}
                          idField="ThreadInteractionID"
                          nameField="ThreadID"
                          parentID={itemData.InteractionID}
                          parentProjectID={itemData.ProjectID}
                          relatedField="ThreadID"
                          columns={
                            isML && !customBP
                              ? threadColumns?.slice(0, 4)
                              : threadColumns?.slice(0, 5)
                          }
                          showAdd={true}
                          showEdit={true}
                          tableName="InteractionThread"
                          id="InteractionThreadList"
                          label={t("objStk:objects.thread.name")}
                          options={threadOptions}
                          unfilteredOptions={unfilteredThreads}
                          selectedValues={listThreads}
                          setSelectedValues={setListThreads}
                          setDidSaveInventory={setDidSaveInventory}
                          parentValue={itemData}
                          isExpandable={true}
                          detailComponent={DetailComponent}
                          isDraggable={false}
                          showSave={true}
                          showCancel={true}
                          optionTableProps={threadOptionProps}
                          updAction={updBulkInteractionThreads}
                          secondLinkCell={1}
                          setIsInvUpdated={setIsInvUpdated}
                          showOptions={!!itemData.ProjectID}
                          disabled={
                            !itemData.ProjectID
                              ? t(
                                  "strGen:tooltips.thread.buttons.disabledchildadd"
                                )
                              : ""
                          }
                        />
                      </Box>
                    </IppTabPanel>
                  )}
                  {showCommitmentInteractions && (
                    <IppTabPanel value={activeTab} index={6}>
                      <Box>
                        <IppChildInventory
                          title={t("objCom:objects.commitment.name")}
                          parentTitle={t("objStk:objects.interaction.name")}
                          linkURL="commitments"
                          tableData={commitmentInteractions}
                          idField="CommitmentInteractionID"
                          nameField="CommitmentTitle"
                          parentID={itemData.InteractionID}
                          parentProjectID={itemData.ProjectID}
                          relatedField="CommitmentID"
                          columns={commitmentColumns}
                          tableName="CommitmentInteraction"
                          id="CommitmentList"
                          label={t(
                            "objCom:objects.commitment.fields.commitmenthistory"
                          )}
                          options={commitmentsFiltered}
                          unfilteredOptions={commitments}
                          selectedValues={listCommitments}
                          setSelectedValues={setListCommitments}
                          setDidSaveInventory={setDidSaveInventory}
                          parentValue={itemData.InteractionID}
                          showOptions={true}
                          secondLinkCell={2}
                          showLinkButton
                        />
                      </Box>
                    </IppTabPanel>
                  )}

                  <IppTabPanel value={activeTab} index={8}>
                    <Box>
                      <Grid className={classes.editForm}>
                        <IppAttachmentInventory
                          recordType="Communications"
                          itemID={itemData.InteractionID}
                          companyID={formik.values.CompanyID}
                          projectID={itemData.ProjectID}
                          objectName={t("objStk:objects.interaction.name")}
                          moduleID={3}
                        />
                      </Grid>
                    </Box>
                  </IppTabPanel>
                </Box>
              </Paper>
            </Grid>
            {ft_eng_Comment && (
              <Grid item xs={12}>
                <Paper>
                  <Box p={2}>
                    <IppChildInventory
                      title={t("objStk:objects.interactioncomment.name")}
                      parentTitle={t("objStk:objects.interaction.name")}
                      linkURL={`communications/${itemData.InteractionID}/actions`}
                      tableData={interactionComments}
                      idField="InteractionCommentID"
                      nameField="CommentDetails"
                      parentID={itemData.InteractionID}
                      parentProjectID={itemData.ProjectID}
                      relatedField="InteractionCommentID"
                      columns={commentColumns}
                      tableName="InteractionComment"
                      id="InteractionCommentList"
                      label={t("objStk:objects.interactioncomment.name_other")}
                      options={interactionComments}
                      selectedValues={interactionComments}
                      setSelectedValues={null}
                      setDidSaveInventory={setDidSaveComment}
                      parentValue={itemData.InteractionTitle}
                      showOptions={true}
                      noLinkCell={true}
                      deleteNoteType="Delete"
                      showAdd={true}
                    />
                  </Box>
                </Paper>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    );

  return (
    <div id="interaction-form">
      <Root>{pageLayout}</Root>
      <Dialog open={showWarning}>
        <DialogTitle>
          {t("strGen:pages.interaction.prompts.warningtitle")}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            {t("strGen:pages.interaction.prompts.missingfields")}
            <ul>
              <li>{t("objPlt:objects.group.stakeholdergroup")}</li>
              <li>{t("objPlt:objects.contact.name")}</li>
              <li>{t("objStk:objects.issue.name")}</li>
            </ul>
          </Typography>

          <Typography variant="body2">
            {t("strGen:pages.interaction.prompts.noadditionalinfo")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <IppButton color={"secondary"} onClick={handleReturn}>
            {t("strGen:buttons.returntoform")}
          </IppButton>
          <IppButton
            color={"secondary"}
            variant="outlined"
            onClick={handleContinue}
          >
            {t("strGen:buttons.disablewarning")}
          </IppButton>
        </DialogActions>
      </Dialog>
      {/* unlink thread warning upon Project change */}
      <IppChangeWarn
        open={warnUnlinkThread}
        fieldName={t("objStk:objects.interaction.fields.project")}
        itemName={t("objStk:objects.thread.name", {
          count: formik.values.ThreadInteraction?.length,
        })}
        onConfirm={handleChangeProject}
        onRevert={() => handleRevertProject(formik.values?.ThreadInteraction)}
      >
        <ul>
          {formik.values?.ThreadInteraction?.map((i: any, index: number) => {
            return <li key={index}>{i?.DisplayValue}</li>;
          })}
        </ul>
      </IppChangeWarn>
      {aiEnabled &&
        generateDialogOpen &&
        (generateObjectType === "interaction" ? (
          <IppGenerateSummaryDialog
            titleKey="interactions"
            fieldname={t("objStk:objects.interaction.fields.details")}
            data={{
              ...formik.values,
              ContactList: isEditing ? selectedContacts : undefined,
              GroupList: isEditing ? listGroups : undefined,
              IssueList: isEditing ? listIssues : undefined,
            }}
            initialValue={formik.values.Details}
            aiSummaryApiFunction={generateInteractionDetailsWithAi}
            open={generateDialogOpen}
            setOpen={setGenerateDialogOpen}
            onSubmit={(value: string) => {
              formik.setFieldValue("Details", value);
              setDetailsKey((prev) => prev + 1);

              if (!isEditing) formik.handleSubmit();
            }}
            standardStatementsRecordType="InteractionDetails"
          />
        ) : (
          <IppGenerateSummaryDialog
            titleKey="threads"
            fieldname={t("objStk:objects.thread.fields.summary")}
            data={threadData}
            initialValue={threadData.Summary}
            aiSummaryApiFunction={generateThreadSummaryWithAi}
            open={generateDialogOpen}
            setOpen={setGenerateDialogOpen}
            onSubmit={(value: string) => {
              setThreadData({ ...threadData, Summary: value });
              threadSubmitHandler({ ...threadData, Summary: value });
            }}
            standardStatementsRecordType="InteractionThreadSummary"
          />
        ))}
    </div>
  );
};
