import axiosClient from "./axiosClient";

export interface CompanyShortForm {
  CompanyID: number;
  CompanyName: string;
  VendorID: string;
  ShowAsActive: any;
  IsClient: boolean | undefined;
  TownCity?: string;
  ProvinceState?: string;
}

export interface CompanyShortFormResult {
  companyShortForms: CompanyShortForm[];
}

export async function getCompanyShortForms(
  accessToken: String
): Promise<CompanyShortFormResult> {
  const url = `/company/shortform`;

  try {
    const { data } = await axiosClient.get<CompanyShortFormResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    data.companyShortForms.forEach((item) => {
      item.ShowAsActive = item.ShowAsActive == 1 ? "Active" : "Inactive";
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
