import axiosClient from "api/axiosClient";
import { DateAsString } from "utils/DateFunctions";

export interface VendorThirdPartySpend {
  CompanyID: number;
  CompanyName: string;
  IndigenousGroupName: string;
  TierName: string;
  Spend: number;
}

export interface VendorThirdPartySpendResult {
  vendorThirdPartySpend: VendorThirdPartySpend[];
}

export async function getVendorThirdPartySpend(
  accessToken: String,
  startDate: Date,
  endDate: Date,
  regionID: number,
  projectID: number
): Promise<VendorThirdPartySpendResult> {
  const start = DateAsString(startDate);
  const end = DateAsString(endDate);
  const url = `/charts/thirdpartyspend/${start}/${end}/${regionID}/${projectID}`;
  try {
    const { data } = await axiosClient.get<VendorThirdPartySpendResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
