import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ClientInteractionType,
  ClientInteractionTypesResult,
  getClientInteractionTypes,
  createClientInteractionType,
  updateClientInteractionType,
  deleteClientInteractionType,
} from "api/clientInteractionTypeAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface ClientInteractionTypeState {
  clientInteractionTypesById: Record<number, ClientInteractionType>;
  clientInteractionTypeList: number[];
  isLoading: boolean;
  error: string | null;
}

const ClientInteractionTypeInitialState: ClientInteractionTypeState = {
  clientInteractionTypesById: {},
  clientInteractionTypeList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: ClientInteractionTypeState) {
  state.isLoading = true;
}

function loadingFailed(
  state: ClientInteractionTypeState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const clientInteractionTypes = createSlice({
  name: "clientInteractionTypes",
  initialState: ClientInteractionTypeInitialState,
  reducers: {
    getClientInteractionTypesStart: startLoading,
    getClientInteractionTypesSuccess(
      state,
      { payload }: PayloadAction<ClientInteractionTypesResult>
    ) {
      const { clientInteractionTypes } = payload;
      // console.log("PAYLOAD", clientInteractionTypes);
      state.isLoading = false;
      state.error = null;

      clientInteractionTypes.forEach((clientInteractionType) => {
        state.clientInteractionTypesById[
          clientInteractionType.ClientInteractionTypeID
        ] = clientInteractionType;
      });

      state.clientInteractionTypeList = clientInteractionTypes.map(
        (clientInteractionType) => clientInteractionType.ClientInteractionTypeID
      );
    },
    getClientInteractionTypesFailure: loadingFailed,
    createClientInteractionTypeStart: startLoading,
    createClientInteractionTypeSuccess(
      state,
      { payload }: PayloadAction<ClientInteractionType>
    ) {
      const { ClientInteractionTypeID } = payload;
      state.clientInteractionTypesById[ClientInteractionTypeID] = payload;
      state.clientInteractionTypeList.push(ClientInteractionTypeID);

      state.isLoading = false;
      state.error = null;
    },
    updateClientInteractionTypeSuccess(
      state,
      { payload }: PayloadAction<ClientInteractionType>
    ) {
      const { ClientInteractionTypeID } = payload;
      state.clientInteractionTypesById[ClientInteractionTypeID] = payload;

      state.isLoading = false;
      state.error = null;
    },
    deleteClientInteractionTypeSuccess(
      state,
      { payload }: PayloadAction<number>
    ) {
      const ClientInteractionTypeID = payload;
      delete state.clientInteractionTypesById[ClientInteractionTypeID];
      state.clientInteractionTypeList = state.clientInteractionTypeList.filter(
        (item) => item !== ClientInteractionTypeID
      );

      state.isLoading = false;
      state.error = null;
    },
    createClientInteractionTypeFailure: loadingFailed,
  },
});

export const {
  getClientInteractionTypesStart,
  getClientInteractionTypesSuccess,
  getClientInteractionTypesFailure,
  createClientInteractionTypeStart,
  createClientInteractionTypeSuccess,
  updateClientInteractionTypeSuccess,
  deleteClientInteractionTypeSuccess,
  createClientInteractionTypeFailure,
} = clientInteractionTypes.actions;

export default clientInteractionTypes.reducer;

export const fetchClientInteractionTypes =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getClientInteractionTypesStart());
      const clientInteractionTypes = await getClientInteractionTypes(
        accessToken
      );
      //console.log("RETURN", clientInteractionTypes);
      dispatch(getClientInteractionTypesSuccess(clientInteractionTypes));
    } catch (err: any) {
      dispatch(getClientInteractionTypesFailure(err.toString()));
    }
  };

export const addClientInteractionType =
  (
    accessToken: String,
    newClientInteractionType: Partial<ClientInteractionType>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientInteractionTypeStart());
      const clientInteractionType = await createClientInteractionType(
        accessToken,
        newClientInteractionType
      );
      dispatch(createClientInteractionTypeSuccess(clientInteractionType));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push("/admin/clientInteractionTypes"));
    } catch (err: any) {
      dispatch(createClientInteractionTypeFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updClientInteractionType =
  (
    accessToken: String,
    clientInteractionTypeID: number,
    newClientInteractionType: Partial<ClientInteractionType>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientInteractionTypeStart());
      const clientInteractionType = await updateClientInteractionType(
        accessToken,
        clientInteractionTypeID,
        newClientInteractionType
      );
      dispatch(updateClientInteractionTypeSuccess(clientInteractionType));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push("/admin/clientInteractionTypes"));
    } catch (err: any) {
      dispatch(createClientInteractionTypeFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delClientInteractionType =
  (accessToken: String, clientInteractionTypeID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientInteractionTypeStart());
      const result = await deleteClientInteractionType(
        accessToken,
        clientInteractionTypeID
      );
      dispatch(deleteClientInteractionTypeSuccess(clientInteractionTypeID));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push("/admin/clientInteractionTypes"));
    } catch (err: any) {
      dispatch(createClientInteractionTypeFailure(err.toString()));
    }
  };
