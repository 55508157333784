import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  FeedbackByIssue,
  FeedbackByIssuesResult,
  getFeedbackByIssues,
} from "api/stakeholder/logTables/feedbackByIssueAPI";
import { AppThunk } from "app/store";

interface FeedbackByIssueState {
  feedbackByIssuesById: Record<number, FeedbackByIssue>;
  feedbackByIssueList: number[];
  isLoading: boolean;
  error: string | null;
}

const FeedbackByIssueInitialState: FeedbackByIssueState = {
  feedbackByIssuesById: {},
  feedbackByIssueList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: FeedbackByIssueState) {
  state.isLoading = true;
}

function loadingFailed(
  state: FeedbackByIssueState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const feedbackByIssues = createSlice({
  name: "feedbackByIssue",
  initialState: FeedbackByIssueInitialState,
  reducers: {
    getFeedbackByIssuesStart: startLoading,
    getFeedbackByIssuesSuccess(
      state,
      { payload }: PayloadAction<FeedbackByIssuesResult>
    ) {
      const { feedbackByIssues } = payload;
      state.isLoading = false;
      state.error = null;

      feedbackByIssues.forEach((item) => {
        state.feedbackByIssuesById[item.InteractionCommentID] = item;
      });

      state.feedbackByIssueList = feedbackByIssues.map(
        (item) => item.InteractionCommentID
      );
    },
    getFeedbackByIssuesFailure: loadingFailed,
  },
});

export const {
  getFeedbackByIssuesStart,
  getFeedbackByIssuesSuccess,
  getFeedbackByIssuesFailure,
} = feedbackByIssues.actions;

export default feedbackByIssues.reducer;

export const fetchFeedbackByIssues =
  (accessToken: String, projectID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getFeedbackByIssuesStart());
      const feedbackByIssues = await getFeedbackByIssues(
        accessToken,
        projectID
      );
      dispatch(getFeedbackByIssuesSuccess(feedbackByIssues));
    } catch (err: any) {
      dispatch(getFeedbackByIssuesFailure(err.toString()));
    }
  };
