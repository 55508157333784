import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchEducationTrainings } from "./EducationTrainingSlice";
import { useParams } from "react-router-dom";
import { Alert } from "@mui/material";
import { EducationTrainingForm } from "./EducationTrainingForm";
import { useTypedTranslation } from "utils/customHooks";
import LoadingIndicator from "components/LoadingIndicator";
import { Trans } from "react-i18next";

interface EducationTrainingDetProps {
  educationTrainingID: string;
}

export const EducationTrainingDetailPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  const { isLoading, error, errorList, educationTrainingsById } = useSelector(
    (state: RootState) => state.educationTrainings
  );

  const { educationTrainingID } = useParams<EducationTrainingDetProps>();
  const itemID = parseInt(educationTrainingID, 10);

  const educationTraining = educationTrainingsById[itemID];

  useEffect(() => {
    (async () => {
      try {
        // can avoid going to the DB if we navigated from inventory
        if (!educationTraining) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchEducationTrainings(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [educationTraining, dispatch, getAccessTokenSilently]);

  let educationTrainingDetView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      {educationTraining && (
        <>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              <span>
                <b>{t("strGen:errors.wentwrong")}</b> - {error.toString()}
                {errorList && (
                  <ul>
                    {errorList.map((err, index) => (
                      <li key={index}>{err}</li>
                    ))}
                  </ul>
                )}
              </span>
            </Alert>
          )}
          {!itemID && (
            <Alert severity="error" sx={{ mb: 2 }}>
              <span>
                <Trans
                  i18nKey="strGen:errors.invalidid"
                  /* eslint-disable-next-line jsx-a11y/heading-has-content */
                  components={[<h1 />, <div />]}
                  values={{
                    variable: educationTrainingID,
                    fieldname: t("objBen:objects.entExpense.entexpenditure"),
                  }}
                />
              </span>
            </Alert>
          )}
          <EducationTrainingForm educationTraining={educationTraining} />
        </>
      )}
    </div>
  );

  return (
    <div id="educationTraining-detail-page">{educationTrainingDetView}</div>
  );
};
