import { Grid } from "@mui/material";
import { Loader } from "@progress/kendo-react-indicators";

function Splashscreen() {
  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100vh" }}
    >
      <Grid item xs={12}>
        <img
          src={process.env.PUBLIC_URL + "/logoMedium.png"}
          alt="NetBenefit Platform"
        />
      </Grid>
      <Grid item xs={12}>
        <Loader type="converging-spinner" size="large" />
      </Grid>
    </Grid>
  );
}

export default Splashscreen;
