import { styled } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { fetchPhases, addPhase, updPhase, delPhase } from "./PhaseSlice";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Grid as KendoGrid,
  GridColumn,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { Delete, Edit } from "@mui/icons-material";
import { IppTextField } from "components/IppTextField";
import { useTypedTranslation } from "utils/customHooks";
import LoadingIndicator from "components/LoadingIndicator";
import { IppButton } from "components/Buttons/IppButton";
import { IppAddButton } from "components/Buttons/IppAddButton";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppDeleteButton } from "components/Buttons/IppDeleteButton";
import { IppCancelButton } from "components/Buttons/IppCancelButton";
import IppConfirmDialog from "components/IppConfirmDialog";
import { fetchDeleteChecksByPhase } from "features/deleteCheck/DeleteCheckSlice";

const PREFIX = "PhaseForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
  roleCard: `${PREFIX}-roleCard`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1100,
  },

  [`& .${classes.boxSpace}`]: {
    padding: 10,
    maxWidth: 900,
  },

  [`& .${classes.roleCard}`]: {
    minWidth: 250,

    minHeight: 300,
  },
}));

export const PhaseForm = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [editID, setEditID] = useState(1);
  const [isEditing, setIsEditing] = useState(true);
  const [editOpen, setEditOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteName, setDeleteName] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  //const [updated, setUpdated] = useState(false);
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);

  //const [pageLoaded, setPageLoaded] = useState(false);

  const { clientId } = useSelector((state: RootState) => state.client);

  const { phaseList, phasesById, isLoading } = useSelector(
    (state: RootState) => state.phase
  );
  const phases = phaseList.map((id) => phasesById[id]);

  const [editItem, setEditItem] = useState({
    PhaseID: -1,
    Name: undefined,
    Rank: phases.length + 1,
  });

  const validationSchema = yup.object({
    Name: yup.string().required("Phase Name is required").nullable(),
    Rank: yup.number().positive().required("Phase position is required"),
  });

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchPhases(accessToken));
        //setPageLoaded(true);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        //console.log("Edit", values);
        if (isAdding) {
          dispatch(addPhase(accessToken, values));
        } else {
          dispatch(updPhase(accessToken, editItem.PhaseID, values));
        }
        setEditOpen(false);
        //setUpdated(true);
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const handleDelete = (phaseId: number) => {
    // function to delete current company entry
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(delPhase(accessToken, phaseId));
        setDeleteOpen(false);
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  const deleteCell = (props: any) => {
    return (
      <td>
        <Tooltip title="Delete Phase">
          <div
            style={{ cursor: "pointer", color: "blue" }}
            onClick={(e) => {
              //console.log("DeleteAtch", "done");
              setEditID(props.dataItem.PhaseID);
              setDeleteName(props.dataItem.Name);
              setDeleteOpen(true);
            }}
          >
            <IppButton variant="text" startIcon={<Delete />} color={"error"} />
          </div>
        </Tooltip>
      </td>
    );
  };

  const editCell = (props: any) => {
    return (
      <td>
        <Tooltip title="Edit Phase">
          <div
            style={{ cursor: "pointer", color: "blue" }}
            onClick={(e) => {
              //console.log("Edit props", props);
              setEditID(props.dataItem.PhaseID);
              formik.setValues(props.dataItem);
              setEditOpen(true);
            }}
          >
            <IppButton variant="text" startIcon={<Edit />} />
          </div>
        </Tooltip>
      </td>
    );
  };

  const formik = useFormik({
    initialValues: editItem,
    validationSchema: validationSchema,
    onSubmit: submitFunc,
    enableReinitialize: true,
  });

  let phaseView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <Box display="flex" justifyContent="center">
        <Paper className={classes.boxSpace}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h5" component="h3">
                {t("objPlt:platformwide.phase.name_other")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Grid container justifyContent="flex-end">
                <IppAddButton
                  buttonText={t("strGen:buttons.addobj", {
                    objectname: t("objPlt:objects.phase.name"),
                  })}
                  onClick={() => {
                    setIsAdding(true);
                    setEditOpen(true);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <KendoGrid data={phases} total={phases.length}>
                <GridNoRecords>** No phases added yet. **</GridNoRecords>
                <GridColumn field="Rank" title="Order" width={50} />
                <GridColumn field="Name" title="Phase" />
                <GridColumn
                  cell={editCell}
                  width="50px"
                  field="PhaseID"
                  title=" "
                />
                <GridColumn cell={deleteCell} width="50px" title=" " />
              </KendoGrid>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <Dialog
        open={editOpen}
        onClose={() => {
          setEditOpen(false);
          formik.resetForm();
        }}
      >
        <DialogTitle>{isAdding ? "Add " : "Edit Active"}</DialogTitle>
        <DialogContent>
          <DialogContentText variant="body1">
            {isAdding
              ? "You are adding a new phase"
              : "You are modifying the values for this phase"}
          </DialogContentText>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <IppTextField
                  id="Rank"
                  label="Phase Order"
                  value={formik.values.Rank || ""}
                  onChangeFunction={formik.handleChange}
                  errorsExpression={formik.errors.Rank}
                  touchedExpression={formik.touched.Rank}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                />
              </Grid>
              <Grid item xs={9}>
                <IppTextField
                  id="Name"
                  label="Phase Name"
                  value={formik.values.Name || ""}
                  onChangeFunction={formik.handleChange}
                  errorsExpression={formik.errors.Name}
                  touchedExpression={formik.touched.Name}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                />
              </Grid>
              <Grid item xs={12}>
                <IppFormButtons
                  isEditing={true}
                  setIsEditing={null}
                  isAdding={isAdding}
                  showCancel={true}
                  resetFunction={() => {
                    setEditOpen(false);
                    formik.resetForm();
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
        <DialogTitle>
          {" "}
          {t("strGen:prompts.delete.deletetitle", {
            objectname: t("objPlt:platformwide.phase.name"),
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText variant="body1">
            {t("strGen:prompts.delete.deletecannotbeundone", {
              objectname: t("objPlt:platformwide.phase.name"),
            })}
          </DialogContentText>
          <DialogContentText
            variant="body1"
            sx={{ textAlign: "center", fontWeight: "bold", paddingTop: 1 }}
          >
            {deleteName}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IppDeleteButton onClick={() => setConfirmOpen(!confirmOpen)} />
          <IppCancelButton onClick={() => setDeleteOpen(false)} />
        </DialogActions>
      </Dialog>

      <IppConfirmDialog
        title={t("strGen:prompts.delete.deletetitle", {
          objectname: t("objPlt:platformwide.phase.name"),
        })}
        open={confirmOpen}
        setOpen={() => setConfirmOpen(!confirmOpen)}
        onConfirm={() => handleDelete(editID)}
        fetchFunc={fetchDeleteChecksByPhase}
        recordID={editID}
      />
    </div>
  );

  return <Root id="Phase-Page">{phaseView}</Root>;
};
