import axiosClient from "api/axiosClient";

export interface Phase {
  PhaseID: number;
  Name: string;
  Rank: number;
  ClientID: number;
}

export interface PhasesResult {
  phases: Phase[];
}

export async function getPhases(accessToken: String): Promise<PhasesResult> {
  const url = `/phase`;

  try {
    const { data } = await axiosClient.get<PhasesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createPhase(
  accessToken: String,
  newPhase: Partial<Phase>
): Promise<Phase> {
  const url = `/phase`;

  try {
    //console.log("Phase API", newPhase);
    const { data } = await axiosClient.post<Phase>(url, newPhase, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updatePhase(
  accessToken: String,
  phaseID: number,
  newPhase: Partial<Phase>
): Promise<Phase> {
  const url = `/phase/` + phaseID;

  try {
    const { data } = await axiosClient.post<Phase>(url, newPhase, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deletePhase(
  accessToken: String,
  phaseID: number
): Promise<string> {
  const url = `/phase/` + phaseID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    console.log("DELDATA", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}
