import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ContactInteraction,
  ContactInteractionsResult,
  getContactInteractions,
  getContactInteractionsByContact,
  getContactInteractionsByInteraction,
  deleteContactInteraction,
} from "api/stakeholder/contactInteractionAPI";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { SnackBarConstants } from "utils/customHooks";

interface ContactInteractionState {
  contactInteractionsById: Record<number, ContactInteraction>;
  contactInteractionList: number[];
  isLoading: boolean;
  subContactInteractionisLoading: boolean;
  error: string | null;
}

const ContactInteractionInitialState: ContactInteractionState = {
  contactInteractionsById: {},
  contactInteractionList: [],
  isLoading: false,
  subContactInteractionisLoading: false,
  error: null,
};

function startLoading(state: ContactInteractionState) {
  state.isLoading = true;
}

function loadingFailed(
  state: ContactInteractionState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const contactInteractions = createSlice({
  name: "contactInteractions",
  initialState: ContactInteractionInitialState,
  reducers: {
    getContactInteractionsStart: startLoading,
    getContactInteractionsSuccess(
      state,
      { payload }: PayloadAction<ContactInteractionsResult>
    ) {
      const { contactInteractions } = payload;

      state.isLoading = false;
      state.error = null;

      contactInteractions.forEach((contactInteraction) => {
        state.contactInteractionsById[contactInteraction.ContactInteractionID] =
          contactInteraction;
      });

      state.contactInteractionList = contactInteractions.map(
        (contactInteraction) => contactInteraction.ContactInteractionID
      );
    },
    getContactInteractionsFailure: loadingFailed,
    createContactInteractionStart: startLoading,
    createContactInteractionSuccess(
      state,
      { payload }: PayloadAction<ContactInteraction>
    ) {
      const { ContactInteractionID } = payload;
      state.contactInteractionsById[ContactInteractionID] = payload;
      state.contactInteractionList.push(ContactInteractionID);

      state.isLoading = false;
      state.error = null;
    },
    updateContactInteractionSuccess(
      state,
      { payload }: PayloadAction<ContactInteraction>
    ) {
      const { ContactInteractionID } = payload;

      state.contactInteractionsById[ContactInteractionID] = payload;
      //state.contactInteractionList.push(ContactInteractionID);

      state.isLoading = false;
      state.error = null;
    },
    deleteContactInteractionSuccess(state, { payload }: PayloadAction<number>) {
      const ContactInteractionID = payload;
      delete state.contactInteractionsById[ContactInteractionID];
      state.contactInteractionList = state.contactInteractionList.filter(
        (item) => item !== ContactInteractionID
      );

      state.isLoading = false;
      state.error = null;
    },
    createContactInteractionFailure: loadingFailed,
  },
});

export const {
  getContactInteractionsStart,
  getContactInteractionsSuccess,
  getContactInteractionsFailure,
  createContactInteractionStart,
  createContactInteractionSuccess,
  updateContactInteractionSuccess,
  deleteContactInteractionSuccess,
  createContactInteractionFailure,
} = contactInteractions.actions;

export default contactInteractions.reducer;

export const fetchContactInteractions =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getContactInteractionsStart());
      const contactInteractions = await getContactInteractions(accessToken);
      dispatch(getContactInteractionsSuccess(contactInteractions));
    } catch (err: any) {
      dispatch(getContactInteractionsFailure(err.toString()));
    }
  };

export const fetchContactInteractionsByContact =
  (accessToken: String, contactID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getContactInteractionsStart());
      const contactInteractions = await getContactInteractionsByContact(
        accessToken,
        contactID
      );
      dispatch(getContactInteractionsSuccess(contactInteractions));
    } catch (err: any) {
      dispatch(getContactInteractionsFailure(err.toString()));
    }
  };

export const fetchContactInteractionsByInteraction =
  (accessToken: String, interactionID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getContactInteractionsStart());
      const contactInteractions = await getContactInteractionsByInteraction(
        accessToken,
        interactionID
      );
      dispatch(getContactInteractionsSuccess(contactInteractions));
    } catch (err: any) {
      dispatch(getContactInteractionsFailure(err.toString()));
    }
  };

//! COMMENTED OUT FOR FUTURE CLEANUP
// export const addContactInteraction =
//   (
//     accessToken: String,
//     newContactInteraction: Partial<ContactInteraction>
//   ): AppThunk =>
//   async (dispatch) => {
//     try {
//       dispatch(createContactInteractionStart());
//       const contactInteraction = await createContactInteraction(
//         accessToken,
//         newContactInteraction
//       );
//       dispatch(createContactInteractionSuccess(contactInteraction));
//       dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
//     } catch (err: any) {
//       dispatch(createContactInteractionFailure(err.toString()));
//       dispatch(openSnackBar(Constants.FAILED, "error"));
//     }
//   };

export const delContactInteraction =
  (
    accessToken: String,
    contactInteractionID: number,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createContactInteractionStart());
      const result = await deleteContactInteraction(
        accessToken,
        contactInteractionID
      );
      dispatch(deleteContactInteractionSuccess(contactInteractionID));
      dispatch(openSnackBar(snackbarConstants.DELETE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createContactInteractionFailure(err.toString()));
      dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
    }
  };
