import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  InteractionComment,
  InteractionCommentsResult,
  getInteractionComments,
  getInteractionCommentsByInteraction,
  createInteractionComment,
  updateInteractionComment,
  deleteInteractionComment,
} from "api/stakeholder/interactionCommentAPI";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { fetchIssueCommentsByComment } from "./IssueCommentSlice";
import { SnackBarConstants } from "utils/customHooks";

interface InteractionCommentState {
  interactionCommentsById: Record<number, InteractionComment>;
  interactionCommentList: number[];
  isLoading: boolean;
  isLoadingAdd: boolean;
  subInteractionCommentisLoading: boolean;
  error: string | null;
  errorList: any[] | null;
}

const InteractionCommentInitialState: InteractionCommentState = {
  interactionCommentsById: {},
  interactionCommentList: [],
  isLoading: false,
  isLoadingAdd: false,
  subInteractionCommentisLoading: false,
  error: null,
  errorList: null,
};

function startLoading(state: InteractionCommentState) {
  state.isLoading = true;
}

function startLoadingAdd(state: InteractionCommentState) {
  state.isLoadingAdd = true;
}

function loadingFailed(
  state: InteractionCommentState,
  action: PayloadAction<{ status: string; message: string; errors: [] }>
) {
  state.isLoading = false;
  state.error = action.payload.message;
  if (action.payload.errors) {
    state.errorList = action.payload.errors;
  }
}

const interactionComments = createSlice({
  name: "interactionComments",
  initialState: InteractionCommentInitialState,
  reducers: {
    getInteractionCommentsStart: startLoading,
    getInteractionCommentsSuccess(
      state,
      { payload }: PayloadAction<InteractionCommentsResult>
    ) {
      const { interactionComments } = payload;

      state.isLoading = false;
      state.error = null;
      state.errorList = null;

      interactionComments.forEach((interactionComment) => {
        state.interactionCommentsById[interactionComment.InteractionCommentID] =
          interactionComment;
      });

      state.interactionCommentList = interactionComments.map(
        (interactionComment) => interactionComment.InteractionCommentID
      );
    },
    getInteractionCommentsFailure: loadingFailed,
    createInteractionCommentStart: startLoadingAdd,
    createInteractionCommentSuccess(
      state,
      { payload }: PayloadAction<InteractionComment>
    ) {
      const { InteractionCommentID } = payload;
      state.interactionCommentsById[InteractionCommentID] = payload;
      state.interactionCommentList.push(InteractionCommentID);

      state.isLoading = false;
      state.isLoadingAdd = false;
      state.error = null;
      state.errorList = null;
    },
    updateInteractionCommentSuccess(
      state,
      { payload }: PayloadAction<InteractionComment>
    ) {
      const { InteractionCommentID } = payload;

      state.interactionCommentsById[InteractionCommentID] = payload;

      state.isLoading = false;
      state.error = null;
      state.errorList = null;
    },
    deleteInteractionCommentSuccess(state, { payload }: PayloadAction<number>) {
      const InteractionCommentID = payload;
      delete state.interactionCommentsById[InteractionCommentID];
      state.interactionCommentList = state.interactionCommentList.filter(
        (item) => item !== InteractionCommentID
      );

      state.isLoading = false;
      state.error = null;
      state.errorList = null;
    },
    createInteractionCommentFailure: loadingFailed,
  },
});

export const {
  getInteractionCommentsStart,
  getInteractionCommentsSuccess,
  getInteractionCommentsFailure,
  createInteractionCommentStart,
  createInteractionCommentSuccess,
  updateInteractionCommentSuccess,
  deleteInteractionCommentSuccess,
  createInteractionCommentFailure,
} = interactionComments.actions;

export default interactionComments.reducer;

export const fetchInteractionComments =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getInteractionCommentsStart());
      const interactionComments = await getInteractionComments(accessToken);
      dispatch(getInteractionCommentsSuccess(interactionComments));
    } catch (err: any) {
      dispatch(getInteractionCommentsFailure(err.toString()));
    }
  };

export const fetchInteractionCommentsByInteraction =
  (accessToken: String, interactionID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getInteractionCommentsStart());
      const interactionComments = await getInteractionCommentsByInteraction(
        accessToken,
        interactionID
      );

      dispatch(getInteractionCommentsSuccess(interactionComments));
    } catch (err: any) {
      dispatch(getInteractionCommentsFailure(err.toString()));
    }
  };

export const addInteractionComment =
  (
    accessToken: String,
    newInteractionComment: Partial<InteractionComment>,
    issueList: string[],
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createInteractionCommentStart());
      const interactionComment = await createInteractionComment(
        accessToken,
        newInteractionComment,
        issueList
      );
      dispatch(createInteractionCommentSuccess(interactionComment));
      dispatch(
        fetchInteractionCommentsByInteraction(
          accessToken,
          interactionComment.InteractionID
        )
      );
      dispatch(
        fetchIssueCommentsByComment(
          accessToken,
          interactionComment.InteractionCommentID
        )
      );
      dispatch(openSnackBar(snackbarConstants.ADD_SUCCESS, "success"));
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(createInteractionCommentFailure(error.response.data));
        dispatch(openSnackBar(error.response.data.message, "error"));
      } else {
        dispatch(createInteractionCommentFailure(error.toString()));
        dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
      }
    }
  };

export const updInteractionComment =
  (
    accessToken: String,
    interactionCommentID: number,
    newInteractionComment: Partial<InteractionComment>,
    issueList: string[] | null,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createInteractionCommentStart());
      const interactionComment = await updateInteractionComment(
        accessToken,
        interactionCommentID,
        newInteractionComment,
        issueList
      );

      dispatch(updateInteractionCommentSuccess(interactionComment));
      dispatch(
        fetchInteractionCommentsByInteraction(
          accessToken,
          interactionComment.InteractionID
        )
      );
      dispatch(fetchIssueCommentsByComment(accessToken, interactionCommentID));
      dispatch(openSnackBar(snackbarConstants.UPDATE_SUCCESS, "success"));
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(createInteractionCommentFailure(error.response.data));
        dispatch(openSnackBar(error.response.data.message, "error"));
      } else {
        dispatch(createInteractionCommentFailure(error.toString()));
        dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
      }
    }
  };

export const delInteractionComment =
  (
    accessToken: String,
    interactionCommentID: number,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createInteractionCommentStart());
      const result = await deleteInteractionComment(
        accessToken,
        interactionCommentID
      );
      dispatch(deleteInteractionCommentSuccess(interactionCommentID));
      dispatch(openSnackBar(snackbarConstants.DELETE_SUCCESS, "success"));
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(createInteractionCommentFailure(error.response.data));
        dispatch(openSnackBar(error.response.data.message, "error"));
      } else {
        dispatch(createInteractionCommentFailure(error.toString()));
        dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
      }
    }
  };
