import { useTypedTranslation } from "utils/customHooks";
import * as yup from "yup";

export const ValidationSchema = () => {
  const t = useTypedTranslation(["objPlt", "strGen"]);

  const validateEmailList = (message: string) => {
    return yup.string().test("Email", message, (list) => {
      if (!list) return true; // Allows for empty field
      const emailList = list.split(",").map((email) => email.trim());
      return emailList.every((email) =>
        yup.string().email().isValidSync(email)
      );
    });
  };

  return yup.object({
    FirstName: yup
      .string()
      .nullable()
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.contact.fields.firstname"),
          count: 50,
        })
      ),
    Surname: yup
      .string()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.contact.fields.surname"),
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.contact.fields.surname"),
          count: 50,
        })
      ),
    ContactTitle: yup
      .string()
      .nullable()
      .max(
        250,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.contact.fields.title"),
          count: 250,
        })
      ),
    Address: yup
      .string()
      .nullable()
      .max(
        250,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.contact.fields.address"),
          count: 250,
        })
      ),
    TownCity: yup
      .string()
      .nullable()
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.contact.fields.towncity"),
          count: 50,
        })
      ),
    PostalCode: yup
      .string()
      .nullable()
      .max(
        20,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.contact.fields.postalcode"),
          count: 20,
        })
      ),
    Email: validateEmailList(
      t("strGen:validation.validrequired", {
        fieldname: t("objPlt:objects.contact.fields.email"),
      })
    ).nullable(),
  });
};
