import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItemText,
  Tooltip,
  ListItemButton,
  Divider,
} from "@mui/material";
import { EditorUtils } from "@progress/kendo-react-editor";
import { EditorView } from "@progress/kendo-editor-common";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useTypedTranslation } from "utils/customHooks";
import { StandardStatement } from "api/standardStatementAPI";

interface InsertTextToolSettings {
  nodeType: string;
}

interface StatementsDialogProps {
  open: boolean;
  handleClose: () => void;
  handleInsert: (html: string) => void;
  standardStatements: StandardStatement[];
}

const StatementsDialog = ({
  open,
  handleClose,
  handleInsert,
  standardStatements,
}: StatementsDialogProps) => {
  const t = useTypedTranslation(["objPlt", "strGen"]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="standard-statements-dialog-modal"
      aria-describedby="dialog-to-select-standard-statements"
    >
      <DialogTitle id="standardStatementsDialog">
        {t(
          "strGen:components.ipprichtexteditor.customtools.standardstatements.dialogtitle"
        )}
      </DialogTitle>
      <DialogContent dividers>
        <List>
          {standardStatements.map((statement, index) => (
            <React.Fragment key={statement.StandardStatementID}>
              <ListItemButton
                onClick={() => handleInsert(statement.StandardStatement)}
              >
                <ListItemText
                  primary={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: statement.StandardStatement,
                      }}
                    />
                  }
                />
              </ListItemButton>
              {index < standardStatements.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

const createInsertTextTool =
  (settings: InsertTextToolSettings) =>
  (props: { view: EditorView; standardStatements: StandardStatement[] }) => {
    const { view, standardStatements } = props;
    const nodeType = view && view.state.schema.nodes[settings.nodeType];
    const canInsert = view && EditorUtils.canInsert(view.state, nodeType);
    const [open, setOpen] = useState(false);
    const t = useTypedTranslation(["objPlt", "strGen"]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleInsert = (html: string) => {
      const schema = view.state.schema;
      const node = EditorUtils.createDocument(schema, html);

      if (node) {
        const tr = view.state.tr.replaceSelectionWith(node);
        view.dispatch(tr.scrollIntoView());
      }

      //! This will insert the node at the beginning of the document
      // if (node) {
      //   const tr = view.state.tr.insert(0, node);
      //   view.dispatch(tr.scrollIntoView());
      // }

      handleClose();
    };

    return (
      <>
        <Tooltip
          title={t(
            "strGen:components.ipprichtexteditor.customtools.standardstatements.tooltip"
          )}
        >
          <Button
            onClick={handleOpen}
            variant="outlined"
            startIcon={<InsertDriveFileIcon />}
            disabled={!canInsert}
            onMouseDown={(e) => e.preventDefault()}
            onPointerDown={(e) => e.preventDefault()}
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
            style={{
              textTransform: "none",
              fontWeight: "normal",
              fontFamily: "inherit",
              fontSize: "inherit",
            }}
          >
            {t("objPlt:objects.standardstatement.name", { count: 2 })}
          </Button>
        </Tooltip>
        <StatementsDialog
          open={open}
          handleClose={handleClose}
          handleInsert={handleInsert}
          standardStatements={standardStatements}
        />
      </>
    );
  };

export const InsertTextTool = createInsertTextTool({
  nodeType: "paragraph",
});
