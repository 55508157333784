import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchInteractionActions } from "./InteractionActionSlice";
import { useParams } from "react-router-dom";
import { Alert } from "@mui/material";
import { InteractionActionForm } from "./InteractionActionForm";
import LoadingIndicator from "components/LoadingIndicator";
import { Trans, useTranslation } from "react-i18next";

interface InteractionActionDetProps {
  interactionActionID: string;
}

export const InteractionActionDetailPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation(["objPlt", "strGen", "objCom", "objStk"]);

  const {
    isLoading,
    error: compError,
    errorList: compErrorList,
    interactionActionsById,
  } = useSelector((state: RootState) => state.interactionActions);

  // get commitmentAction ID from url param and attempt to find company in store
  const { interactionActionID } = useParams<InteractionActionDetProps>();
  const itemID = parseInt(interactionActionID, 10);

  const interactionAction =
    interactionActionsById[parseInt(interactionActionID, 10)];

  useEffect(() => {
    (async () => {
      try {
        // can avoid going to the DB if we navigated from inventory
        if (!interactionAction || !interactionAction.InteractionTitle) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchInteractionActions(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [interactionAction, dispatch, getAccessTokenSilently]);

  let interactionActionDetView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      {interactionAction && (
        <>
          {compError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              <span>
                <b>{t("strGen:errors.wentwrong")}</b> - {compError.toString()}
                {compErrorList && (
                  <ul>
                    {compErrorList.map((error: any) => {
                      return (
                        <li key={error.param}>
                          <b>{error.param}:</b> {error.msg}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </span>
            </Alert>
          )}
          {!itemID && (
            <Alert severity="error" sx={{ mb: 2 }}>
              <div>
                <Trans
                  i18nKey="strGen:errors.invalidid"
                  /* eslint-disable-next-line jsx-a11y/heading-has-content */
                  components={[<h1 />, <div />]}
                  values={{
                    variable: itemID,
                    fieldname: t("objStk:objects.interactionaction.name"),
                  }}
                />
              </div>
            </Alert>
          )}
          <InteractionActionForm interactionAction={interactionAction} />
        </>
      )}
    </div>
  );

  return (
    <div id="interactionAction-detail-page">{interactionActionDetView}</div>
  );
};
