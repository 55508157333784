import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import {
  addNotificationTemplate,
  updNotificationTemplate,
  delNotificationTemplate,
} from "./NotificationTemplateSlice";
import { Box, Grid, Paper } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { IppFormHeader } from "components/IppFormHeader";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppTextField } from "components/IppTextField";
import { IppAutocomplete } from "components/IppAutocomplete";
import { fetchProjects } from "features/project/ProjectSlice";
import LoadingIndicator from "components/LoadingIndicator";
import { fetchNotificationTypes } from "features/datalists/NotificationTypeSlice";
import { IppRichTextEditor } from "components/RichTextEditor/IppRichTextEditor";
import { EditorChangeEvent } from "@progress/kendo-react-editor";

const PREFIX = "NotificationTemplateForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1000,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

const validationSchema = yup.object({
  SubjectLine: yup.string().required("Subject Line is required"),
  SummaryContent: yup.string().required("Summary Content is required"),
  TemplateContent: yup.string().required("Template content is required"),
  NotificationTypeID: yup
    .number()
    .positive("Notification Type is required")
    .required("Notification Type is required"),
  ProjectID: yup
    .number()
    .positive("Project is required")
    .required("Project is required"),
});

export const NotificationTemplateForm = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  let invData = props.notificationTemplate || {};

  const [isEditing, setIsEditing] = useState(
    invData.NotificationTemplateID ? false : true
  );
  const [isAdding, setIsAdding] = useState(
    invData.NotificationTemplateID ? false : true
  );

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const {
    projectList,
    projectsById,
    isLoading: projectIsLoading,
    error: projectError,
  } = useSelector((state: RootState) => state.projects);

  const projects = projectList.map((id) => projectsById[id]);

  const {
    notificationTypeList,
    notificationTypesById,
    isLoading: notificationTypesIsLoading,
    error: notificationTypesError,
  } = useSelector((state: RootState) => state.notificationTypes);

  const notificationTypes = notificationTypeList.map(
    (id) => notificationTypesById[id]
  );

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchProjects(accessToken));
        dispatch(fetchNotificationTypes(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        dispatch(addNotificationTemplate(accessToken, values));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  if (invData.NotificationTemplateID) {
    //Update case
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });

          dispatch(
            updNotificationTemplate(
              accessToken,
              values.NotificationTemplateID,
              values
            )
          );
        } catch (e) {
          console.error(e);
        }
      })();
    };
  } else {
    invData.SubjectLine = "";
    invData.SummaryContent = "";
    invData.TemplateContent = "";
    invData.ProjectID = -1;
    invData.NotificationTypeID = -1;
  }

  const handleDelete = () => {
    // function to delete current community investment entry
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(
          delNotificationTemplate(accessToken, invData.NotificationTemplateID)
        );
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const formik = useFormik({
    initialValues: invData,
    validationSchema: validationSchema,
    onSubmit: submitFunc,
  });

  let commInvForm =
    clientIsLoading || projectIsLoading || notificationTypesIsLoading ? (
      <LoadingIndicator />
    ) : (
      <Root>
        <form onSubmit={formik.handleSubmit}>
          <Box display="flex" justifyContent="center">
            <Grid container className={classes.editForm} spacing={1}>
              <Grid item xs={12}>
                <Paper className={classes.boxSpace}>
                  <Grid container spacing={1}>
                    <IppFormHeader
                      title="Notification Template"
                      isEditing={isEditing}
                      isAdding={isAdding}
                      returnPath="/admin/notificationTemplates"
                    />
                    <Grid item xs={6}>
                      <IppTextField
                        id="SubjectLine"
                        label="Subject Line"
                        value={formik.values.SubjectLine}
                        onChangeFunction={formik.handleChange}
                        touchedExpression={formik.touched.SubjectLine}
                        errorsExpression={formik.errors.SubjectLine}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <IppTextField
                        id="SummaryContent"
                        label="Summary"
                        value={formik.values.SummaryContent}
                        onChangeFunction={formik.handleChange}
                        touchedExpression={formik.touched.SummaryContent}
                        errorsExpression={formik.errors.SummaryContent}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <IppAutocomplete
                        id="ProjectID"
                        options={projects}
                        value={projects.find((obj) => {
                          return obj.ProjectID === formik.values.ProjectID;
                        })}
                        onChangeFunction={(
                          event: ChangeEvent,
                          newValue: any
                        ) => {
                          if (newValue) {
                            formik.setFieldValue(
                              "ProjectID",
                              newValue.ProjectID
                            );
                          } else {
                            formik.setFieldValue("ProjectID", -1);
                          }
                        }}
                        label="Project"
                        touchedFunction={formik.touched.ProjectID}
                        errorFunction={formik.errors.ProjectID}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        optionLabelFunction={(option: any) =>
                          option.ProjectName
                        }
                        textValueFunction={
                          !projectIsLoading &&
                          !projectError &&
                          formik.values.ProjectID > 0 &&
                          projectsById[formik.values.ProjectID]
                            ? projectsById[formik.values.ProjectID].ProjectName
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <IppAutocomplete
                        id="NotificationTypeID"
                        options={notificationTypes}
                        value={notificationTypes.find((obj) => {
                          return (
                            obj.NotificationTypeID ===
                            formik.values.NotificationTypeID
                          );
                        })}
                        onChangeFunction={(
                          event: ChangeEvent,
                          newValue: any
                        ) => {
                          if (newValue) {
                            formik.setFieldValue(
                              "NotificationTypeID",
                              newValue.NotificationTypeID
                            );
                          } else {
                            formik.setFieldValue("NotificationTypeID", -1);
                          }
                        }}
                        label="Notification Type"
                        touchedFunction={formik.touched.NotificationTypeID}
                        errorFunction={formik.errors.NotificationTypeID}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        optionLabelFunction={(option: any) =>
                          option.NotificationTypeName
                        }
                        textValueFunction={
                          !notificationTypesIsLoading &&
                          formik.values.NotificationTypeID > 0 &&
                          notificationTypesById[
                            formik.values.NotificationTypeID
                          ]
                            ? notificationTypesById[
                                formik.values.NotificationTypeID
                              ].NotificationTypeName
                            : ""
                        }
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <IppRichTextEditor
                        id="TemplateContent"
                        label="TemplateContent"
                        value={formik.values.TemplateContent}
                        isEditing={isEditing}
                        touchedExpression={formik.touched.TemplateContent}
                        errorsExpression={formik.errors.TemplateContent}
                        setFieldValue={formik.setFieldValue}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <IppFormButtons
                        isEditing={isEditing}
                        isAdding={isAdding}
                        setIsEditing={setIsEditing}
                        resetFunction={() => formik.resetForm()}
                        showDelete={true}
                        deleteFunction={() => handleDelete()}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Root>
    );

  return <div id="comm-inf-form">{commInvForm}</div>;
};
