import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  EducationTraining,
  EducationTrainingsResult,
  getEducationTrainings,
  createEducationTraining,
  updateEducationTraining,
  deleteEducationTraining,
} from "api/benefits/educationTrainingAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface EducationTrainingState {
  educationTrainingsById: Record<number, EducationTraining>;
  educationTrainingList: number[];
  isLoading: boolean;
  error: string | null;
  errorList: any[] | null;
}

const EducationTrainingInitialState: EducationTrainingState = {
  educationTrainingsById: {},
  educationTrainingList: [],
  isLoading: false,
  error: null,
  errorList: null,
};

function startLoading(state: EducationTrainingState) {
  state.isLoading = true;
}

function loadingFailed(
  state: EducationTrainingState,
  action: PayloadAction<{ status: string; message: string; errors: [] }>
) {
  state.isLoading = false;
  state.error = action.payload.message;
  if (action.payload.errors) {
    state.errorList = action.payload.errors;
  }
}

const educationTraining = createSlice({
  name: "educationTraining",
  initialState: EducationTrainingInitialState,
  reducers: {
    getEducationTrainingsStart: startLoading,
    getEducationTrainingsSuccess(
      state,
      { payload }: PayloadAction<EducationTrainingsResult>
    ) {
      const { educationTraining } = payload;

      state.isLoading = false;
      state.error = null;
      state.errorList = null;

      educationTraining.forEach((educationTraining) => {
        state.educationTrainingsById[educationTraining.EducationTrainingID] =
          educationTraining;
      });

      state.educationTrainingList = educationTraining.map(
        (educationTraining) => educationTraining.EducationTrainingID
      );
    },
    getEducationTrainingsFailure: loadingFailed,
    createEducationTrainingStart: startLoading,
    createEducationTrainingSuccess(
      state,
      { payload }: PayloadAction<EducationTraining>
    ) {
      const { EducationTrainingID } = payload;
      state.educationTrainingsById[EducationTrainingID] = payload;
      state.educationTrainingList.push(EducationTrainingID);
    },
    updateEducationTrainingStart: startLoading,
    updateEducationTrainingSuccess(
      state,
      { payload }: PayloadAction<EducationTraining>
    ) {
      const { EducationTrainingID } = payload;
      state.educationTrainingsById[EducationTrainingID] = payload;

      state.isLoading = false;
      state.error = null;
    },
    updateEducationTrainingFailure: loadingFailed,
    deleteEducationTrainingStart: startLoading,
    deleteEducationTrainingSuccess(state, { payload }: PayloadAction<number>) {
      // const  EducationTrainingID  = payload;
      delete state.educationTrainingsById[payload];
      state.educationTrainingList = state.educationTrainingList.filter(
        (educationTrainingID) => educationTrainingID !== payload
      );

      state.isLoading = false;
      state.error = null;
    },
    educationTrainingFailure: loadingFailed,
  },
});

export const {
  getEducationTrainingsStart,
  getEducationTrainingsSuccess,
  getEducationTrainingsFailure,
  createEducationTrainingStart,
  createEducationTrainingSuccess,
  updateEducationTrainingStart,
  updateEducationTrainingSuccess,
  updateEducationTrainingFailure,
  deleteEducationTrainingStart,
  deleteEducationTrainingSuccess,
  educationTrainingFailure,
} = educationTraining.actions;

export default educationTraining.reducer;

export const fetchEducationTrainings =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getEducationTrainingsStart());
      const educationTraining = await getEducationTrainings(accessToken);
      dispatch(getEducationTrainingsSuccess(educationTraining));
    } catch (err: any) {
      dispatch(getEducationTrainingsFailure(err.toString()));
    }
  };

export const addEducationTraining =
  (
    accessToken: String,
    educationTraining: EducationTraining,
    setReturnRoute: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createEducationTrainingStart());
      const newEducationTraining = await createEducationTraining(
        accessToken,
        educationTraining
      );
      dispatch(createEducationTrainingSuccess(newEducationTraining));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(
          push(
            "/benefits/educationtrainings/" +
              newEducationTraining.EducationTrainingID
          )
        );
      }
      dispatch(fetchEducationTrainings(accessToken));
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(educationTrainingFailure(error.response.data));
        dispatch(openSnackBar(Constants.FAILED, "error"));
      }
      dispatch(educationTrainingFailure(error.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updEducationTraining =
  (
    accessToken: String,
    educationTraining: EducationTraining,
    setReturnRoute: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateEducationTrainingStart());
      const newEducationTraining = await updateEducationTraining(
        accessToken,
        educationTraining
      );
      dispatch(updateEducationTrainingSuccess(newEducationTraining));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(fetchEducationTrainings(accessToken));
      if (setReturnRoute) {
        dispatch(
          push(
            "/benefits/educationtrainings/" +
              newEducationTraining.EducationTrainingID
          )
        );
      }
    } catch (error: any) {
      dispatch(updateEducationTrainingFailure(error.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delEducationTraining =
  (accessToken: String, educationTrainingID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteEducationTrainingStart());
      await deleteEducationTraining(accessToken, educationTrainingID);
      dispatch(deleteEducationTrainingSuccess(educationTrainingID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
      dispatch(push("/benefits/educationtrainings"));
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(educationTrainingFailure(error.response.data));
        dispatch(openSnackBar(Constants.FAILED, "error"));
      }
      dispatch(educationTrainingFailure(error.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };
