import React, { useRef } from "react";
import {
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Autocomplete,
  Stack,
  Chip,
} from "@mui/material";
import { InfoOutlined, Edit } from "@mui/icons-material";
import { useState } from "react";
import * as yup from "yup";
import { useTypedTranslation } from "utils/customHooks";
import { createCustomAutoCompleteFilter } from "utils/customFilters";
import { defaultRenderOption } from "utils/renderFunctions";

interface IppMultiTextFieldProps {
  id: string;
  options: any;
  value: any;
  setValue: any;
  label: string;
  isEditing: boolean;
  setIsEditing: any;
  optionLabelFunction?: any;
  touchedFunction?: any;
  errorFunction?: any;
  textValueFunction?: any;
  disabled?: boolean;
  required?: boolean;
  toolTip?: string;
  autoPopulate?: boolean;
  noOptionsText?: string | null;
  multiple?: boolean;
  freeSolo?: boolean;
  renderOption?: any; // Provides workaround for MUI 5 bug with handling repeated options
  emailSpaceToken?: boolean;
}

export const IppMultiTextField = (props: IppMultiTextFieldProps) => {
  const {
    id,
    options,
    value,
    setValue,
    label,
    isEditing,
    setIsEditing,
    optionLabelFunction,
    touchedFunction,
    errorFunction,
    textValueFunction,
    disabled = false,
    required,
    toolTip,
    autoPopulate,
    noOptionsText,
    multiple,
    freeSolo,
    renderOption,
    emailSpaceToken,
  } = props;
  const t = useTypedTranslation(["objPlt", "strGen"]);
  const editChange = () => {
    setIsEditing(!isEditing);
  };

  const [input, setInput] = useState("");
  const [showEmailHelperText, setShowEmailHelperText] = useState(false);

  const setTooltip = !toolTip ? "" : toolTip;

  // sets multiple to false by default
  const setMultiple = multiple ? true : false;

  // sets default freesolo to false
  const setFreeSolo = freeSolo ? true : false;

  //This is to auto-select an option when there is only one. Overriding the 'value' is necessary
  //so that it shows on the input box.
  let sanitizedValue =
    typeof value === "string"
      ? value.split(",").filter((s: string) => s != "")
      : value
      ? value
      : [];

  const handleInputchange = (event: any, newInput: any, reason: any) => {
    const delimiters = props.emailSpaceToken ? /[;, ]+/ : /[;,]+/;

    const options = newInput.split(delimiters);

    const val =
      typeof value === "string"
        ? value.split(",").filter((s: string) => s != "")
        : value
        ? value
        : [];

    if (options.length > 1) {
      setValue(
        val
          .concat(options)
          .map((x: any) => x.trim())
          .filter((x: any) => x)
      );
      setInput("");
    } else {
      setInput(newInput);
    }
  };

  let invalidEmails: string[] = [];
  const validateEmail = (value: any) => {
    const isValid = yup.string().email().isValidSync(value);

    if (!isValid) {
      invalidEmails.push(value);
      setShowEmailHelperText(true);
      return false;
    } else {
      return true;
    }
  };

  const handleChange = (event: any, newInput: any, reason: any) => {
    if (newInput) {
      setValue(newInput);
      setInput("");
    } else {
      setValue(null);
    }

    if (id === "Email") {
      invalidEmails = invalidEmails.filter((email) => newInput.includes(email));

      if (invalidEmails.length === 0) {
        setShowEmailHelperText(false);
      }
    }
  };

  const handleBlur = (event: any) => {
    const val =
      typeof value === "string"
        ? value.split(",").filter((s: string) => s != "")
        : value
        ? value
        : [];

    setValue(
      val
        .concat(input)
        .map((x: any) => x.trim())
        .filter((x: any) => x)
    );
    setInput("");
  };

  // Custom filter logic - normalize input and compare to options
  const customFilter = createCustomAutoCompleteFilter((option: any) =>
    optionLabelFunction ? optionLabelFunction(option) : option
  );

  return (
    <div>
      {isEditing ? (
        <Autocomplete
          sx={{ width: "100%" }}
          id={id}
          disabled={disabled}
          multiple={setMultiple}
          freeSolo={setFreeSolo}
          autoHighlight={true}
          autoComplete={true}
          value={sanitizedValue}
          inputValue={input}
          onChange={handleChange}
          onInputChange={handleInputchange}
          onBlur={(e) => {
            handleBlur(e);
          }}
          options={options}
          renderOption={
            renderOption ||
            ((props: any, option: any) =>
              defaultRenderOption(props, option, optionLabelFunction))
          }
          filterOptions={customFilter}
          getOptionLabel={optionLabelFunction}
          noOptionsText={noOptionsText}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const label = optionLabelFunction
                ? optionLabelFunction(option)
                : option;

              return (
                <Tooltip key={index} title={label} placement="top">
                  <Chip
                    variant="outlined"
                    size="small"
                    label={label}
                    {...getTagProps({ index })}
                    color={
                      id !== "Email"
                        ? "primary"
                        : validateEmail(label)
                        ? "primary"
                        : "error"
                    }
                  />
                </Tooltip>
              );
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              required={required}
              error={touchedFunction && Boolean(errorFunction)}
              FormHelperTextProps={{
                sx: { color: "red" },
              }}
              helperText={
                touchedFunction && errorFunction
                  ? errorFunction
                  : id === "Email" && showEmailHelperText
                  ? t("strGen:validation.validrequired", {
                      fieldname: t("objPlt:objects.contact.fields.email"),
                    })
                  : null
              }
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Stack direction="row" spacing={1}>
                    {setTooltip.length > 0 ? (
                      <InputAdornment position="end">
                        <Tooltip title={setTooltip} placement="top">
                          <InfoOutlined fontSize="small" />
                        </Tooltip>
                      </InputAdornment>
                    ) : (
                      ""
                    )}
                    {params.InputProps.endAdornment}
                  </Stack>
                ),
              }}
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      ) : (
        <TextField
          id={id}
          name={id}
          label={label}
          value={textValueFunction}
          required={required}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                {setTooltip.length > 0 ? (
                  <Tooltip title={setTooltip} placement="top">
                    <InfoOutlined fontSize="small" style={{ marginTop: -3 }} />
                  </Tooltip>
                ) : (
                  ""
                )}
                <IconButton onClick={editChange} size="small">
                  <Edit fontSize="inherit" />
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ shrink: true }}
        />
      )}
    </div>
  );
};
