import axiosClient from "./axiosClient";

export interface Contract {
  ContractID: number;
  CompanyID: number;
  CompanyName: string;
  ProjectID: number;
  ProjectName: string;
  ContractNumber: string;
  ContractTitle: string;
  ContractScope: string;
  AwardDate: any;
  ExpiryDate: any;
  ContractNotes: string;
  ExpenditureTarget: number;
  EmployeeTarget: number;
  HoursTarget: number;
  IsLocal: boolean;
  IsIndigenous: boolean;
  SpendReportingRequired: boolean;
  EmployeeReportingRequired: boolean;
  IsActive: boolean;
}

export interface ContractsResult {
  contracts: Contract[];
}

export async function getContracts(
  accessToken: String
): Promise<ContractsResult> {
  const url = `/contract`;

  try {
    const { data } = await axiosClient.get<ContractsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getContractsByCompany(
  accessToken: String,
  companyID: number
): Promise<ContractsResult> {
  const url = `/contract/${companyID}`;

  try {
    const { data } = await axiosClient.get<ContractsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getContractsByProject(
  accessToken: String,
  projectID: number
): Promise<ContractsResult> {
  const url = `/contract/project/${projectID}`;

  try {
    const { data } = await axiosClient.get<ContractsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getContractsByCompanyAndProject(
  accessToken: String,
  companyID: number,
  projectID: number
): Promise<ContractsResult> {
  const url = `contract/companies/${companyID}/projects/${projectID}`;

  try {
    const { data } = await axiosClient.get<ContractsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createContract(
  accessToken: String,
  newContract: Partial<Contract>
): Promise<Contract> {
  const url = `/contract`;

  try {
    const { data } = await axiosClient.post<Contract>(url, newContract, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateContract(
  accessToken: String,
  contractID: number,
  newContract: Partial<Contract>
): Promise<Contract> {
  const url = `/contract/` + contractID;

  try {
    const { data } = await axiosClient.post<Contract>(url, newContract, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteContract(
  accessToken: String,
  contractID: number
): Promise<string> {
  const url = `/contract/` + contractID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    console.log("DELDATA", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}
