import { Tag } from "api/tagAPI";
import { useTypedTranslation } from "utils/customHooks";
import { formatStringForCompare } from "utils/functions";
import * as yup from "yup";

interface SchemaOptions {
  tags: Tag[];
  tagID: number | undefined;
}

export const TagValidationSchema = (options: SchemaOptions) => {
  const t = useTypedTranslation(["objPlt", "strGen"]);

  const existingTagNames = options.tags
    .filter((tag) => tag.TagID !== options.tagID)
    .map((tag) => formatStringForCompare(tag.Name));

  return yup.object({
    Name: yup
      .string()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.tag.fields.tagname"),
        })
      )
      .test(
        "unique",
        t("strGen:validation.unique", {
          fieldname: t("objPlt:objects.tag.fields.tagname"),
        }),
        function (value) {
          const isDuplicate = existingTagNames.find(
            (name) => name === formatStringForCompare(value)
          );
          if (isDuplicate) return false;

          return true;
        }
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:objects.tag.fields.tagname"),
        })
      ),
    RecordType: yup
      .string()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.tag.fields.recordtype"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:objects.tag.fields.recordtype"),
        })
      ),
  });
};
