import axiosClient from "api/axiosClient";

export interface CustomEmpCategory {
  CustomEmpCategoryID: number;
  Name: string;
  Rank: number;
  ClientID: number;
}

export interface CustomEmpCategoriesResult {
  customEmpCategories: CustomEmpCategory[];
}

export async function getCustomEmpCategories(
  accessToken: String
): Promise<CustomEmpCategoriesResult> {
  const url = `/customEmpCategory`;

  try {
    const { data } = await axiosClient.get<CustomEmpCategoriesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createCustomEmpCategory(
  accessToken: String,
  newCustomEmpCategory: Partial<CustomEmpCategory>
): Promise<CustomEmpCategory> {
  const url = `/customEmpCategory`;

  try {
    const { data } = await axiosClient.post<CustomEmpCategory>(
      url,
      newCustomEmpCategory,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateCustomEmpCategory(
  accessToken: String,
  customEmpCategoryID: number,
  newCustomEmpCategory: Partial<CustomEmpCategory>
): Promise<CustomEmpCategory> {
  const url = `/customEmpCategory/` + customEmpCategoryID;

  try {
    const { data } = await axiosClient.post<CustomEmpCategory>(
      url,
      newCustomEmpCategory,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteCustomEmpCategory(
  accessToken: String,
  customEmpCategoryID: number
): Promise<CustomEmpCategoriesResult> {
  const url = `/customEmpCategory/` + customEmpCategoryID;

  try {
    const { data } = await axiosClient.delete<CustomEmpCategoriesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}
