import { School } from "@mui/icons-material";
import { Button, Paper, Typography, lighten, useTheme } from "@mui/material";
import { RootState } from "app/rootReducer";
import { useSelector } from "react-redux";

const TrainingHubBox = () => {
  const { customTrainingUrl } = useSelector((state: RootState) => state.client);

  const trainingURL =
    customTrainingUrl && customTrainingUrl !== ""
      ? customTrainingUrl
      : "https://www.netbenefitsoftware.com/contractor-training-hub";

  const handleButtonClick = () => {
    window.open(trainingURL, "_blank");
  };

  const theme = useTheme();

  return (
    <Paper
      elevation={6}
      sx={{
        padding: 2,
        borderRadius: 1,
        border: "2px solid #007BFF",
        backgroundColor: "transparent",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "165px",
      }}
    >
      <Typography
        variant="h5"
        component="div"
        sx={{ marginBottom: 4, fontWeight: "bold", color: "inherit" }}
      >
        Need Help? Start Here!
      </Typography>

      <Button
        variant="contained"
        sx={{
          backgroundColor: theme.palette.secondary.main,
          color: "white",
          fontSize: "16px",
          padding: "10px 20px",
          "&:hover": {
            backgroundColor: lighten(theme.palette.secondary.main, 0.2),
            transform: "scale(1.05)", // Slightly enlarge the button
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
          },
        }}
        onClick={handleButtonClick}
        endIcon={<School />}
      >
        Training Hub
      </Button>
    </Paper>
  );
};

export default TrainingHubBox;
