import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { IppInventory } from "components/Inventory/IppInventory";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";
import { fetchGrievances } from "./GrievanceSlice";
import { ConvertDateOffset } from "../../../utils/DateFunctions";
import { useTranslation } from "react-i18next";

export const GrievanceInventoryPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation(["objPlt", "strGen", "objStk"]);

  const {
    grievanceList,
    grievancesById,
    isLoading: grievanceIsLoading,
    error: grievanceError,
  } = useSelector((state: RootState) => state.grievances);

  const grievances = grievanceList.map((id) => grievancesById[id]);

  const data = grievances.map((p) => {
    let n = Object.assign({}, p) as any;
    if (p.ReportedDate !== null) {
      n.ReportedDate = ConvertDateOffset(p.ReportedDate);
    } else {
      n.ReportedDate = null;
    }
    if (p.ResolutionDate !== null) {
      n.ResolutionDate = ConvertDateOffset(p.ResolutionDate);
    } else {
      n.ResolutionDate = null;
    }
    if (p.LatestInteraction !== null) {
      n.LatestInteraction = ConvertDateOffset(p.LatestInteraction);
    } else {
      n.LatestInteraction = null;
    }
    return n;
  });

  const dataColumns = [
    {
      field: "SequenceID",
      title: t("objStk:objects.grievance.sequenceid"),
      format: "G-{0:0}",
      filter: "text",
      columnWidth: 80,
    },
    { field: "GrievanceName", title: t("objStk:objects.grievance.name") },
    {
      field: "GrievanceStatusText",
      title: t("objStk:objects.grievance.fields.status"),
      columnWidth: 150,
    },
    {
      field: "ReportedDate",
      title: t("objStk:objects.grievance.reported"),
      filter: "date",
      format: "{0:d}",
      columnWidth: 125,
    },
    {
      field: "ResolutionDate",
      title: t("objStk:objects.grievance.resolved"),
      filter: "date",
      format: "{0:d}",
      columnWidth: 125,
    },
    {
      field: "ProjectName",
      title: t("objStk:objects.grievance.fields.project"),
    },
    {
      field: "PersonResponsibleName",
      title: t("objStk:objects.grievance.fields.personresponsible"),
    },
    {
      field: "LatestInteraction",
      title: t("objStk:objects.grievance.active"),
      filter: "date",
      format: "{0:d}",
      columnWidth: 125,
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchGrievances(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  if (grievanceError) {
    return <ErrorPage errMsg={grievanceError} />;
  }

  let grievanceInvView = grievanceIsLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <IppInventory
        title={t("objStk:objects.grievance.name", { count: 2 })}
        tableData={data}
        idField="GrievanceID"
        columns={dataColumns}
        isFiltered={true}
        showDetail={true}
        buttonProps={{
          showAdd: true,
        }}
        sortColumn={3}
        sortReverse={true}
        linkColumn={1}
        searchByColumn={"GrievanceName"}
        searchByPlaceholder={t("strGen:filters.searchby", {
          fieldname: t("objStk:objects.grievance.name"),
        })}
      />
    </div>
  );

  return <div id="grievance-inventory-page">{grievanceInvView}</div>;
};
