import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { addInitiative, updInitiative, delInitiative } from "./InitiativeSlice";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppFormHeader } from "components/IppFormHeader";
import { IppAutocomplete } from "components/IppAutocomplete";
import { IppStaticTextfield } from "components/IppStaticTextfield";
import LoadingIndicator from "components/LoadingIndicator";
import { IppAttachmentInventory } from "components/IppAttachmentInventory";
import { Prompt } from "react-router";
import { IppDisplayField } from "components/IppDisplayField";

import { IsExtraLargeScreen, IsMedSmall } from "utils/mediaQueries";
import { UserWriteAccess } from "utils/userAccess";
import { InitiativeValidation } from "./InitiativeValidation";
import { useTypedTranslation } from "utils/customHooks";
import { IppTabPanel } from "components/IppTabPanel";
import { countAttachments } from "api/attachmentsAPI";
import { IppCurrencyField } from "components/IppCurrencyField";
import { IppTextField } from "components/IppTextField";
import { IppCheckbox } from "components/IppCheckbox";
import { IppDisplayChip } from "components/IppDisplayChip";
import { IppDisplayCheckbox } from "components/IppDisplayCheckbox";
import { IppDisplayRichText } from "components/IppDisplayRichText";
import { IppRichTextEditor } from "components/RichTextEditor/IppRichTextEditor";
import { fetchStatus } from "features/datalists/StatusSlice";
import { IppChildInventory } from "components/Inventory/IppChildInventory";
import {
  ConvertDateOffset,
  getDateFromDateString,
  GetJSDate,
} from "utils/DateFunctions";
import { fetchRnDExpenses } from "../RnDExpense/RnDExpenseSlice";
import { fetchInitiativeOwners } from "./InitiativeOwnersSlice";
import { fetchCompanies } from "../companies/CompaniesSlice";
import { fetchAssets } from "../assets/AssetSlice";
import { fetchEnTExpenses } from "../entExpenses/EnTExpenseSlice";
import { IppCurrencyDisplay } from "components/IppCurrencyDisplayField";
import { fetchStandardStatements } from "features/platform/admin/standardStatement/StandardStatementSlice";
import { Company } from "api/companyAPI";
import { CompanyOptionsRender } from "utils/renderFunctions";
import { IppDatePicker } from "components/IppDatePicker";
import { RecordHistoryDisplay } from "components/Reusable Utils/RecordHistoryDisplay";

const PREFIX = "InitiativeForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    // minWidth: 650,
    maxWidth: 1150,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const InitiativeForm = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen", "objSlr", "objPlt", "strGen"]);
  const customBP = IsMedSmall();

  let initiativeData = props.initiative || {};

  const [isEditing, setIsEditing] = useState(
    initiativeData.InitiativeID ? false : true
  );
  const [isAdding, setIsAdding] = useState(
    initiativeData.InitiativeID ? false : true
  );
  const [activeTab, setActiveTab] = useState(
    initiativeData.Category === "Research & Development" ? 0 : 1
  );
  // used to display attachments count
  const [attachCount, setAttachCount] = useState({ Count: 0 } as any);

  const [didSaveInventory, setDidSaveInventory] = useState(false);

  const showEdit = UserWriteAccess("Benefits");

  const {
    clientId,
    isLoading: clientIsLoading,
    ft_ben_Asset,
  } = useSelector((state: RootState) => state.client);

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const { isLoadingOwners: initiativeOwnersIsLoading, initiativeOwners } =
    useSelector((state: RootState) => state.initiativeOwners);

  const {
    clientCurrencyList,
    clientCurrenciesById,
    isLoading: clientCurrencyIsLoading,
  } = useSelector((state: RootState) => state.clientCurrencies);

  const clientCurrency = clientCurrencyList.map(
    (id) => clientCurrenciesById[id]
  );

  const { attachmentsById, attachmentList } = useSelector(
    (state: RootState) => state.attachments
  );
  const attachments = attachmentList.map((id) => attachmentsById[id]);

  const { assetsById, assetList } = useSelector(
    (state: RootState) => state.assets
  );
  const assets = assetList.map((id) => assetsById[id]);

  const {
    statusById,
    statusList,
    isLoading: statusIsLoading,
  } = useSelector((state: RootState) => state.status);

  const submitStatus = statusList.map((id) => statusById[id]);

  const {
    isLoading: companyIsLoading,
    companiesById,
    companyList,
  } = useSelector((state: RootState) => state.companies);
  const companies = companyList.map((id) => companiesById[id]);
  const confirmedCompanies = companies.filter((comp) => comp.ListingConfirmed);

  const {
    RnDExpenseList,
    RnDExpensesById,
    isLoading: expenseIsLoading,
  } = useSelector((state: RootState) => state.RnDExpense);

  const expenses = RnDExpenseList.map((id) => RnDExpensesById[id]);

  const filteredExpenses = expenses.filter(
    (expense) => expense.InitiativeID === initiativeData.InitiativeID
  );

  const data = filteredExpenses.map((p) => {
    let n = Object.assign({}, p) as any;

    if (p.InEligible) {
      n.EligibilityStatus = "No";
    } else n.EligibilityStatus = "Yes";

    if (p.ExpenseDate !== null) {
      n.ExpenseDate = ConvertDateOffset(p.ExpenseDate);
    }
    if (!p.InitiativeName || p.InitiativeName === "") {
      n.InitiativeName = "Missing Name";
    }

    return n;
  });

  const { entExpenseList, entExpensesById } = useSelector(
    (state: RootState) => state.entExpenses
  );

  const entExpenses = entExpenseList.map((id) => entExpensesById[id]);

  const filteredEnTExpenses = entExpenses.filter(
    (expense) => expense.InitiativeID === initiativeData.InitiativeID
  );

  const entdata = filteredEnTExpenses.map((p) => {
    let n = Object.assign({}, p) as any;

    if (p.InEligible) {
      n.EligibilityStatus = "No";
    } else n.EligibilityStatus = "Yes";

    if (p.Date !== null) {
      n.Date = ConvertDateOffset(p.Date);
    }
    if (!p.InitiativeName || p.InitiativeName === "") {
      n.InitiativeName = "Missing Name";
    }

    return n;
  });

  const hasExpenses =
    filteredExpenses.length > 0 || filteredEnTExpenses.length > 0;

  const { isLoading: standardStatementIsLoading } = useSelector(
    (state: RootState) => state.standardStatement
  );

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        dispatch(fetchStatus(accessToken));
        dispatch(fetchInitiativeOwners(accessToken));
        dispatch(fetchCompanies(accessToken, clientId));
        dispatch(fetchRnDExpenses(accessToken));
        dispatch(fetchEnTExpenses(accessToken));
        dispatch(fetchStandardStatements(accessToken));
        if (ft_ben_Asset) {
          dispatch(fetchAssets(accessToken));
        }
        if (initiativeData.InitiativeID) {
          const count = await countAttachments(
            accessToken,
            "Initiatives",
            initiativeData.InitiativeID
          );
          setAttachCount(count.attachments ? count.attachments : 0);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  // used for attachment count
  useEffect(() => {
    (async () => {
      try {
        if (attachments.length > 0 && !isAdding) {
          const attach = attachments.filter((item) => {
            return (
              item.RecordID === initiativeData.InitiativeID &&
              item.RecordType === "Initiatives"
            );
          });
          if (attach && attach.length > 0)
            setAttachCount({ Count: attach.length });
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [attachmentList]);

  // save when changes made to child records
  useEffect(() => {
    if (didSaveInventory) {
      submitFunc(initiativeData);
      setDidSaveInventory(false);
    }
    return () => {};
  }, [didSaveInventory]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(addInitiative(accessToken, values));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const handleDelete = () => {
    if (hasExpenses) return;
    // function to delete current Initiative entry
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(delInitiative(accessToken, initiativeData.InitiativeID));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const handleTabSelect = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };

  let submitFunc = onSub;

  if (!isAdding) {
    //Update case
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(updInitiative(accessToken, values.InitiativeID, values));
        } catch (e) {
          console.error(e);
        }
      })();
    };
  } else {
    initiativeData.InitiativeName = "";
    initiativeData.StatusID = 1;
    initiativeData.Approved = false;
    initiativeData.Category = null;
    initiativeData.QualifiedValue = 0;
    initiativeData.Owner = null;
    initiativeData.Details = null;
    initiativeData.InitiativeReferences = null;
    initiativeData.TDI = null;
    initiativeData.InitiativeGroup = null;
    initiativeData.WBS = null;
    initiativeData.PO = null;
    initiativeData.CompanyID = currentProfile.CompanyID;
    initiativeData.AssetID = null;
    initiativeData.InitiativeApprovedDate = null;
  }

  const formik = useFormik({
    initialValues: initiativeData,
    validationSchema: InitiativeValidation(currentProfile.IsClient),
    onSubmit: submitFunc,
  });

  const isRnDCategory = initiativeData.Category === "Research & Development";

  const totalEligibleExpense = isRnDCategory
    ? formik.values?.TotalEligibleRnDExpense ?? 0
    : formik.values?.TotalEligibleEnTExpense ?? 0;

  let viewForm = (
    <Box display="flex" justifyContent="center">
      <Grid container className={classes.editForm} spacing={1}>
        <Grid item xs={12}>
          <Paper className={classes.boxSpace}>
            <Grid container spacing={1}>
              <IppFormHeader
                title="Initiative"
                isEditing={isEditing}
                isAdding={isAdding}
                // returnPath="/benefits/initiatives"
                showReturn={true}
                showReturnToInv={true}
              />
              <Grid item xs={12}>
                <IppDisplayField
                  label={t("objBen:objects.initiative.fields.name")}
                  value={formik.values.InitiativeName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              {currentProfile.IsClient && (
                <Grid item xs={6}>
                  <IppDisplayChip
                    label={t("objBen:objects.initiative.fields.submitstatusid")}
                    value={formik.values.StatusName}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objBen:objects.initiative.fields.category")}
                  value={formik.values.Category}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={false}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  // id="CurrencyType" purely a display field
                  label="Currency"
                  value={clientCurrency[0]?.CurrencyName}
                  isEditing={false}
                  setIsEditing={null}
                  showEdit={false}
                />
              </Grid>
              {currentProfile.IsClient && (
                <Grid item xs={6}>
                  <IppCurrencyDisplay
                    label={t("objBen:objects.initiative.fields.qualifiedvalue")}
                    value={formik.values.QualifiedValue}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
              )}
              {currentProfile.IsClient && (
                <Grid item xs={6}>
                  <IppDisplayField
                    label={t("objBen:objects.initiative.fields.owner")}
                    value={formik.values.Owner}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <IppDisplayField
                  label="Company"
                  value={
                    companyList.length > 0
                      ? companiesById[formik.values.CompanyID]?.CompanyName
                      : ""
                  }
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              {formik.values.Approved && (
                <Grid item xs={6}>
                  <IppDisplayField
                    label="Approved Date"
                    value={getDateFromDateString(
                      formik.values.InitiativeApprovedDate
                    )}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
              )}
              {ft_ben_Asset && (
                <Grid item xs={6}>
                  <IppDisplayField
                    label={t("objBen:objects.asset.name")}
                    value={assetsById[formik.values.AssetID]?.AssetName}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <IppDisplayCheckbox
                  label={t("objBen:objects.initiative.fields.approved")}
                  isChecked={formik.values.Approved}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              {!isAdding && (
                <Grid item xs={6}>
                  <IppDisplayField
                    value={
                      "$" +
                      totalEligibleExpense?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }
                    label={t(
                      "objBen:objects.rndExpense.fields.totaleligibleexpense"
                    )}
                    isEditing={isEditing}
                    showEdit={false}
                    setIsEditing={setIsEditing}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <IppDisplayRichText
                  label={t("objBen:objects.initiative.fields.details")}
                  value={formik.values.Details}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              {currentProfile.IsClient && (
                <Grid item xs={6}>
                  <IppDisplayField
                    label={t(
                      "objBen:objects.initiative.fields.initiativereference"
                    )}
                    value={formik.values.InitiativeReferences}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
              )}
              {currentProfile.IsClient && (
                <Grid item xs={6}>
                  <IppDisplayField
                    label={t("objBen:objects.initiative.fields.tdi")}
                    value={formik.values.TDI}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
              )}
              {currentProfile.IsClient && (
                <Grid item xs={6}>
                  <IppDisplayField
                    label={t("objBen:objects.initiative.fields.wbs")}
                    value={formik.values.WBS}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
              )}
              {currentProfile.IsClient && (
                <Grid item xs={6}>
                  <IppDisplayField
                    label={t("objBen:objects.initiative.fields.po")}
                    value={formik.values.PO}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
              )}
              {currentProfile.IsClient && (
                <Grid item xs={6}>
                  <RecordHistoryDisplay
                    lastUpdated={formik.values.LastUpdated}
                    lastUpdatedBy={formik.values.LastUpdatedBy}
                    createDate={formik.values.CreateDate}
                    createdBy={formik.values.CreatedBy}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <IppFormButtons
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  isAdding={isAdding}
                  resetFunction={() => formik.resetForm()}
                  showDelete={showEdit}
                  deleteDisabled={hasExpenses}
                  deleteFunction={handleDelete}
                  deleteTooltip={
                    hasExpenses
                      ? "Cannot delete Initiative with Expenses"
                      : undefined
                  }
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );

  let editForm =
    clientIsLoading || clientCurrencyIsLoading ? (
      <LoadingIndicator />
    ) : (
      <form noValidate onSubmit={formik.handleSubmit}>
        {!formik.isSubmitting && (
          <Prompt
            when={formik.dirty}
            message={t("strGen:prompts.unsavedchanges")}
          />
        )}

        <Grid container spacing={1}>
          <IppFormHeader
            title="Initiative"
            isEditing={isEditing}
            isAdding={isAdding}
            returnPath="/benefits/initiatives"
          />

          <Grid item xs={6}>
            <IppTextField
              id="InitiativeName"
              label={t("objBen:objects.initiative.fields.name")}
              required={true}
              value={formik.values.InitiativeName}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.InitiativeName}
              errorsExpression={formik.errors.InitiativeName}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              toolTip={t("objBen:objects.initiative.tooltips.initiative")}
            />
          </Grid>
          {!isAdding ? (
            <Grid item xs={6}>
              <IppStaticTextfield
                id="Category"
                label={t("objBen:objects.initiative.fields.category")}
                value={formik.values.Category}
              />
            </Grid>
          ) : (
            <Grid item xs={6}>
              <IppAutocomplete
                id="Category"
                options={["Research & Development", "Education & Training"]}
                required={true}
                value={formik.values.Category}
                onChangeFunction={(event: ChangeEvent, newValue: any) => {
                  if (newValue) {
                    formik.setFieldValue("Category", newValue);
                  } else {
                    formik.setFieldValue("Category", null);
                  }
                }}
                label="Category"
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                errorFunction={formik.errors.Category}
                touchedFunction={formik.touched.Category}
                toolTip={t("objBen:objects.initiative.tooltips.category")}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <IppStaticTextfield
              id="CurrencyType"
              label="Currency"
              value={clientCurrency[0]?.CurrencyName}
            />
          </Grid>
          {currentProfile.IsClient && (
            <Grid item xs={6}>
              <IppCurrencyField
                id="QualifiedValue"
                label={t("objBen:objects.initiative.fields.qualifiedvalue")}
                required
                value={formik.values.QualifiedValue}
                onChangeFunction={(newValue) =>
                  formik.setFieldValue("QualifiedValue", newValue)
                }
                touchedExpression={formik.touched.QualifiedValue}
                errorsExpression={formik.errors.QualifiedValue}
                isEditing={isEditing}
                toolTip={t("objBen:objects.initiative.tooltips.qualifiedvalue")}
                allowNegative={false}
              />
            </Grid>
          )}
          {currentProfile.IsClient && (
            <Grid item xs={6}>
              <IppAutocomplete
                id="StatusID"
                options={submitStatus}
                required={currentProfile.IsClient ? true : false}
                value={submitStatus.find((obj) => {
                  return obj.StatusID === formik.values.StatusID;
                })}
                onChangeFunction={(event: ChangeEvent, newValue: any) => {
                  if (newValue) {
                    formik.setFieldValue("StatusID", newValue.StatusID);
                  } else {
                    formik.setFieldValue("StatusID", null);
                  }
                }}
                label={t("objBen:objects.initiative.fields.submitstatusid")}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                optionLabelFunction={(option: any) => option.StatusName}
                errorFunction={formik.errors.StatusID}
                touchedFunction={formik.touched.StatusID}
                textValueFunction={
                  submitStatus.find(
                    (item) => item.StatusID === formik.values.StatusID
                  )?.StatusName
                }
              />
            </Grid>
          )}
          {currentProfile.IsClient && (
            <Grid item xs={6}>
              <IppAutocomplete
                id="Owner"
                options={initiativeOwners}
                required={currentProfile.IsClient ? true : false}
                value={formik.values.Owner}
                onChangeFunction={(event: ChangeEvent, newValue: any) => {
                  if (newValue) {
                    formik.setFieldValue("Owner", newValue);
                  } else {
                    formik.setFieldValue("Owner", null);
                  }
                }}
                label={t("objBen:objects.initiative.fields.owner")}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                errorFunction={formik.errors.Owner}
                touchedFunction={formik.touched.Owner}
                freeSolo={true}
              />
            </Grid>
          )}
          {ft_ben_Asset && (
            <Grid item xs={6}>
              <IppAutocomplete
                id="AssetID"
                options={assets}
                value={assets.find((obj) => {
                  return obj.AssetID === formik.values.AssetID;
                })}
                onChangeFunction={(event: ChangeEvent, newValue: any) => {
                  if (newValue) {
                    formik.setFieldValue("AssetID", newValue.AssetID);
                  } else {
                    formik.setFieldValue("AssetID", null);
                  }
                }}
                label={t("objBen:objects.asset.name")}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                optionLabelFunction={(option: any) => option.AssetName}
                errorFunction={formik.errors.AssetID}
                touchedFunction={formik.touched.AssetID}
                textValueFunction={formik.values.AssetName}
                placeholder="What asset is the initiative linked to?"
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <IppAutocomplete
              id="CompanyID"
              options={confirmedCompanies.sort((a, b) =>
                a.ShowAsActive.localeCompare(b.ShowAsActive)
              )}
              groupBy={(option: any) => option.ShowAsActive}
              value={confirmedCompanies.find((obj) => {
                return obj.CompanyID === formik.values.CompanyID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("CompanyID", newValue.CompanyID);
                } else {
                  formik.setFieldValue("CompanyID", null);
                }
              }}
              label={t("objBen:objects.company.name")}
              required={true}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              //disabled={isEditing && !isAdding}
              optionLabelFunction={(option: any) => option.CompanyName}
              renderOption={(props: any, option: Company) => {
                return CompanyOptionsRender(props, option);
              }}
              touchedFunction={formik.touched.CompanyID}
              errorFunction={formik.errors.CompanyID}
              textValueFunction={
                !companyIsLoading &&
                formik.values.CompanyID > 0 &&
                companiesById[formik.values.CompanyID]
                  ? companiesById[formik.values.CompanyID].CompanyName
                  : ""
              }
            />
          </Grid>
          {formik.values.Approved && (
            <Grid item xs={6}>
              <IppDatePicker
                id="InitiativeApprovedDate"
                label="Approved Date"
                value={ConvertDateOffset(formik.values.InitiativeApprovedDate)}
                onError={formik.errors.InitiativeApprovedDate}
                onChangeFunction={(newValue: any) => {
                  formik.setFieldValue(
                    "InitiativeApprovedDate",
                    GetJSDate(newValue),
                    true
                  );
                  formik.setFieldTouched("InitiativeApprovedDate", true, false);
                }}
                errorsExpression={formik.errors.InitiativeApprovedDate}
                touchedExpression={formik.touched.InitiativeApprovedDate}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <IppCheckbox
              id="Approved"
              label={t("objBen:objects.initiative.fields.approved")}
              value={formik.values.Approved}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                formik.setFieldValue("Approved", newValue);

                if (!newValue) {
                  formik.setFieldValue("Approved", false);
                  formik.setFieldValue("InitiativeApprovedDate", null);
                }
              }}
              isEditing={isEditing}
            />
          </Grid>
          {!isAdding && (
            <Grid item xs={6}>
              <IppStaticTextfield
                id={
                  isRnDCategory
                    ? "TotalEligibleRnDExpense"
                    : "TotalEligibleEnTExpense"
                }
                value={totalEligibleExpense.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                label={t(
                  "objBen:objects.rndExpense.fields.totaleligibleexpense"
                )}
                isCurrency={true}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <IppRichTextEditor
              id="Details"
              label={t("objBen:objects.initiative.fields.details")}
              //required={false}
              value={formik.values.Details}
              setFieldValue={formik.setFieldValue}
              touchedExpression={formik.touched.Details}
              errorsExpression={formik.errors.Details}
              isEditing={isEditing}
              height={100}
              standardStatementsRecordType="InitiativeDetails"
            />
          </Grid>
          {currentProfile.IsClient && (
            <Grid item xs={6}>
              <IppTextField
                id="InitiativeReferences"
                label={t(
                  "objBen:objects.initiative.fields.initiativereference"
                )}
                required={false}
                value={formik.values.InitiativeReferences}
                onChangeFunction={formik.handleChange}
                touchedExpression={formik.touched.InitiativeReferences}
                errorsExpression={formik.errors.InitiativeReferences}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Grid>
          )}
          {currentProfile.IsClient && (
            <Grid item xs={6}>
              <IppTextField
                id="TDI"
                label={t("objBen:objects.initiative.fields.tdi")}
                required={false}
                value={formik.values.TDI}
                onChangeFunction={formik.handleChange}
                touchedExpression={formik.touched.TDI}
                errorsExpression={formik.errors.TDI}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Grid>
          )}
          {currentProfile.IsClient && (
            <Grid item xs={6}>
              <IppTextField
                id="InitiativeGroup"
                label={t("objBen:objects.initiative.fields.initiativegroup")}
                required={false}
                value={formik.values.InitiativeGroup}
                onChangeFunction={formik.handleChange}
                touchedExpression={formik.touched.InitiativeGroup}
                errorsExpression={formik.errors.InitiativeGroup}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Grid>
          )}
          {currentProfile.IsClient && (
            <Grid item xs={6}>
              <IppTextField
                id="WBS"
                label="WBS"
                required={false}
                value={formik.values.WBS}
                onChangeFunction={formik.handleChange}
                touchedExpression={formik.touched.WBS}
                errorsExpression={formik.errors.WBS}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Grid>
          )}
          {currentProfile.IsClient && (
            <Grid item xs={6}>
              <IppTextField
                id="PO"
                label="PO"
                required={false}
                value={formik.values.PO}
                onChangeFunction={formik.handleChange}
                touchedExpression={formik.touched.PO}
                errorsExpression={formik.errors.PO}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <IppFormButtons
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              isAdding={isAdding}
              resetFunction={() => formik.resetForm()}
              showDelete={true}
              deleteDisabled={hasExpenses}
              deleteFunction={handleDelete}
              deleteTooltip={
                hasExpenses
                  ? "Cannot delete Initiative with Expenses"
                  : undefined
              }
            />
          </Grid>
        </Grid>
      </form>
    );

  const expenseColumns = [
    {
      field: "SequenceID",
      title: " ",
      format: "RD-{0:0}",
      columnWidth: IsExtraLargeScreen() ? 100 : 80,
    },
    {
      field: "ExpenseAmount",
      title: t("objBen:objects.rndExpense.fields.expenseamount"),
      format: "{0:c0}",
      filter: "numeric",
      columnWidth: IsExtraLargeScreen() ? 150 : 100,
    },
    {
      field: "ExpenseDate",
      title: t("objBen:objects.rndExpense.fields.expensedate"),
      filter: "date",
      format: "{0:d}",
      columnWidth: IsExtraLargeScreen() ? 150 : 120,
    },
    {
      field: "Classification",
      title: t("objBen:objects.rndExpense.fields.classification"),
    },
    {
      field: "Reference",
      title: t("objBen:objects.rndExpense.fields.reference"),
      columnWidth: IsExtraLargeScreen() ? "" : 150,
    },
    {
      field: "EligibilityStatus",
      title: t("objBen:objects.entExpense.eligible"),
      columnWidth: 120,
    },
  ];

  const entExpenseColumns = [
    {
      field: "SequenceID",
      title: " ",
      format: "ET-{0:0}",
      columnWidth: IsExtraLargeScreen() ? 100 : 80,
    },
    {
      field: "ExpenseAmount",
      title: t("objBen:objects.entExpense.fields.expenseamount"),
      format: "{0:c0}",
      filter: "numeric",
      columnWidth: IsExtraLargeScreen() ? 150 : 100,
    },
    {
      field: "Date",
      title: t("objBen:objects.entExpense.fields.expensedate"),
      filter: "date",
      format: "{0:d}",
      columnWidth: IsExtraLargeScreen() ? 150 : 120,
    },
    {
      field: "CourseName",
      title: t("objBen:objects.entExpense.fields.course"),
    },
    {
      field: "Classification",
      title: t("objBen:objects.entExpense.fields.classification"),
    },
    {
      field: "EligibilityStatus",
      title: t("objBen:objects.entExpense.eligible"),
      columnWidth: 120,
    },
  ];

  let pageForm =
    initiativeOwnersIsLoading ||
    clientIsLoading ||
    companyIsLoading ||
    statusIsLoading ||
    clientCurrencyIsLoading ||
    expenseIsLoading ||
    standardStatementIsLoading ? (
      <LoadingIndicator />
    ) : isAdding ? (
      <Box display="flex" justifyContent="center">
        <Grid container className={classes.editForm} spacing={1}>
          <Grid item xs={12}>
            <Paper className={classes.boxSpace}>{editForm}</Paper>
          </Grid>
        </Grid>
      </Box>
    ) : (
      <Grid container spacing={1}>
        <Grid item xs={customBP ? 12 : 5}>
          {isEditing ? (
            <Dialog open={isEditing} fullWidth maxWidth="lg">
              <DialogContent>{editForm}</DialogContent>
            </Dialog>
          ) : (
            <div>{viewForm}</div>
          )}
        </Grid>
        <Grid item xs={customBP ? 12 : 7}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={activeTab}
                      onChange={handleTabSelect}
                      variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                    >
                      {initiativeData.Category === "Research & Development" && (
                        <Tab
                          value={0}
                          label={`R&D Expenses (${filteredExpenses.length})`}
                        />
                      )}
                      {initiativeData.Category === "Education & Training" && (
                        <Tab
                          value={1}
                          label={`E&T Expenses (${filteredEnTExpenses.length})`}
                        />
                      )}
                      <Tab
                        value={2}
                        label={`Attachments (${attachCount.Count})`}
                      />
                    </Tabs>
                  </Box>
                  <IppTabPanel value={activeTab} index={0}>
                    <Box>
                      <IppChildInventory
                        title="R&D Expense"
                        parentTitle="Initiative"
                        linkURL="rndexpenses"
                        tableData={data}
                        idField="RnDExpenseID"
                        nameField="SequenceID"
                        parentID={initiativeData.InitiativeID}
                        relatedField="RnDExpenseID"
                        columns={expenseColumns}
                        showAdd={true}
                        showEdit={false}
                        tableName="RnDExpense"
                        id="RnDExpenseList"
                        label="Expenses"
                        options={expenses}
                        selectedValues={filteredExpenses}
                        setSelectedValues={null}
                        setDidSaveInventory={setDidSaveInventory}
                        parentValue={initiativeData}
                        showOptions={true}
                        //deleteMessageType="Delete"
                        deleteNoteType="Delete"
                      />
                    </Box>
                  </IppTabPanel>
                  <IppTabPanel value={activeTab} index={1}>
                    <Box>
                      <IppChildInventory
                        title="E&T Expense"
                        parentTitle="Initiative"
                        linkURL="entexpenses"
                        tableData={entdata}
                        idField="EnTExpenseID"
                        nameField="SequenceID"
                        parentID={initiativeData.InitiativeID}
                        relatedField="EnTExpenseID"
                        columns={entExpenseColumns}
                        showAdd={true}
                        showEdit={false}
                        tableName="EnTExpense"
                        id="entExpenseList"
                        label="Expenses"
                        options={entExpenses}
                        selectedValues={filteredEnTExpenses}
                        setSelectedValues={null}
                        setDidSaveInventory={setDidSaveInventory}
                        parentValue={initiativeData}
                        showOptions={true}
                        deleteNoteType="Delete"
                      />
                    </Box>
                  </IppTabPanel>
                  <IppTabPanel value={activeTab} index={2}>
                    <Box>
                      <IppAttachmentInventory
                        recordType="Initiatives"
                        itemID={initiativeData.InitiativeID}
                        companyID={formik.values.CompanyID}
                        projectID={0}
                        moduleID={1}
                        //disabled={handleIsBtnDisabled()}
                      />
                    </Box>
                  </IppTabPanel>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );

  return <Root>{pageForm}</Root>;
};
