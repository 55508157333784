import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchStakeholderGroups } from "./StakeholderGroupSlice";
import { useParams } from "react-router-dom";

import { StakeholderGroupForm } from "./StakeholderGroupForm";
import LoadingIndicator from "components/LoadingIndicator";
import { useTypedTranslation } from "utils/customHooks";
import { Trans } from "react-i18next";

interface StakeholderGroupDetProps {
  groupID: string;
}

export const StakeholderGroupDetailPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen"]);

  const {
    isLoading,
    error: compError,
    stakeholderGroupsById,
  } = useSelector((state: RootState) => state.stakeholderGroups);
  const { ft_eng_PILSD } = useSelector((state: RootState) => state.client);

  // get stakeholderGroup ID from url param and attempt to find company in store
  const { groupID } = useParams<StakeholderGroupDetProps>();
  const parsedGroupID = parseInt(groupID, 10);

  const stakeholderGroup = stakeholderGroupsById[parseInt(groupID, 10)];

  useEffect(() => {
    (async () => {
      try {
        // can avoid going to the DB if we navigated from inventory
        if (
          !stakeholderGroup ||
          !stakeholderGroup.FirstName ||
          !stakeholderGroup.Surname ||
          (ft_eng_PILSD &&
            !Object.keys(stakeholderGroup).includes("ProximityIndicatorLSDs"))
        ) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchStakeholderGroups(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [stakeholderGroup, dispatch, getAccessTokenSilently]);

  if (!parsedGroupID) {
    return (
      <div>
        <Trans
          i18nKey="strGen:errors.invalidid"
          /* eslint-disable-next-line jsx-a11y/heading-has-content */
          components={[<h1 />, <div />]}
          values={{
            variable: groupID,
            fieldname: t("objPlt:objects.group.name"),
          }}
        />
      </div>
    );
  }

  if (compError) {
    return (
      <div>
        <h1>{t("strGen:errors.wentwrong")}</h1>
        <div>{compError.toString()}</div>
      </div>
    );
  }

  let stakeholderGroupDetView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      {stakeholderGroup && (
        <StakeholderGroupForm stakeholderGroup={stakeholderGroup} />
      )}
    </div>
  );

  return <div id="stakeholderGroup-detail-page">{stakeholderGroupDetView}</div>;
};
