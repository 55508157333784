import { IssueForm } from "./IssueForm";

export const IssueCreatePage = () => {
  let IssueCreateView = (
    <div>
      <IssueForm />
    </div>
  );

  return <div id="Issue-detail-page">{IssueCreateView}</div>;
};
