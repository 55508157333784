import axiosClient from "../axiosClient";

export interface ContactGroup {
  ContactGroupID: number;
  ContactID: number;
  GroupID: number;
  GroupName: string;
  ContactName: string;
  FirstName: string;
  Surname: string;
  ContactTitle: string;
  PrimaryPhone: string;
  Email: string;
  ClientID: number;
  OrganizationTypeName: string;
  TownCity: string;
  ProvinceState: string;
  IsPrimary: boolean;
}

export interface ContactGroupsResult {
  contactGroups: ContactGroup[];
}

export async function getContactGroups(
  accessToken: String
): Promise<ContactGroupsResult> {
  const url = `/contactGroup`;

  try {
    const { data } = await axiosClient.get<ContactGroupsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getContactGroupsByGroup(
  accessToken: String,
  groupID: number
): Promise<ContactGroupsResult> {
  const url = `/contactGroup/group/${groupID}`;

  try {
    const { data } = await axiosClient.get<ContactGroupsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getContactGroupsByContact(
  accessToken: String,
  contactID: number
): Promise<ContactGroupsResult> {
  const url = `/contactGroup/contact/${contactID}`;

  try {
    const { data } = await axiosClient.get<ContactGroupsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createContactGroup(
  accessToken: String,
  newContactGroup: Partial<ContactGroup>
): Promise<ContactGroup> {
  const url = `/contactGroup`;

  try {
    const { data } = await axiosClient.post<ContactGroup>(url, newContactGroup, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateContactGroup(
  accessToken: String,
  contactGroupID: number,
  newContactGroup: Partial<ContactGroup>,
): Promise<ContactGroup> {
  const url = `/contactGroup/` + contactGroupID;

  try {
    const { data } = await axiosClient.post<ContactGroup>(url, newContactGroup, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteContactGroup(
  accessToken: String,
  contactGroupID: number
): Promise<string> {
  const url = `/contactGroup/` + contactGroupID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
