import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getPriorities, Priority, PriorityResult } from "api/priorityAPI";
import { AppThunk } from "app/store";

interface PriorityState {
  prioritiesById: Record<number, Priority>;
  priorityList: number[];
  isLoading: boolean;
  error: string | null;
}

const PriorityInitialState: PriorityState = {
  prioritiesById: {},
  priorityList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: PriorityState) {
  state.isLoading = true;
}

function loadingFailed(state: PriorityState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const priorities = createSlice({
  name: "priority",
  initialState: PriorityInitialState,
  reducers: {
    getPrioritiesStart: startLoading,
    getPrioritiesSuccess(state, { payload }: PayloadAction<PriorityResult>) {
      const { priorities } = payload;
      state.isLoading = false;
      state.error = null;

      priorities.forEach((priority) => {
        state.prioritiesById[priority.PriorityID] = priority;
      });

      state.priorityList = priorities.map((priority) => priority.PriorityID);
    },
    getPrioritiesFailure: loadingFailed,
  },
});

export const {
  getPrioritiesStart,
  getPrioritiesSuccess,
  getPrioritiesFailure,
} = priorities.actions;

export default priorities.reducer;

export const fetchPriorities =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getPrioritiesStart());
      const priorities = await getPriorities(accessToken);
      dispatch(getPrioritiesSuccess(priorities));
    } catch (err: any) {
      dispatch(getPrioritiesFailure(err.toString()));
    }
  };
