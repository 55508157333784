import {
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Autocomplete,
  Stack,
} from "@mui/material";
import { InfoOutlined, Edit } from "@mui/icons-material";
import { createCustomAutoCompleteFilter } from "utils/customFilters";
import { defaultRenderOption } from "utils/renderFunctions";

interface IppFreeSoloFieldProps {
  id: string;
  options: any;
  value: any;
  onChangeFunction: any;
  label: string;
  isEditing: boolean;
  setIsEditing: any;
  optionLabelFunction?: any;
  touchedFunction?: any;
  errorFunction?: any;
  textValueFunction?: any;
  disabled?: boolean;
  required?: boolean;
  toolTip?: string;
  autoPopulate?: boolean;
  noOptionsText?: string;
  multiple?: boolean;
  freeSolo?: boolean;
  onInputChangeFunction: any;
  inputValue: any;
  renderOption?: any; // Provides workaround for MUI 5 bug with handling repeated options
}

export const IppFreeSoloField = (props: IppFreeSoloFieldProps) => {
  const {
    id,
    options,
    value,
    onChangeFunction,
    label,
    isEditing,
    setIsEditing,
    optionLabelFunction,
    touchedFunction,
    errorFunction,
    textValueFunction,
    disabled = false,
    required,
    toolTip,
    autoPopulate,
    noOptionsText,
    multiple,
    freeSolo,
    onInputChangeFunction,
    inputValue,
    renderOption,
  } = props;

  const editChange = () => {
    setIsEditing(!isEditing);
  };

  const setTooltip = !toolTip ? "" : toolTip;

  //If autoPopulate is True or NULL we should still attempt to populate the value.
  const setAutoPopulate = autoPopulate === false ? false : true;

  // sets multiple to false by default
  const setMultiple = multiple ? true : false;

  // sets default freesolo to false
  const setFreeSolo = freeSolo ? true : false;

  //This is to auto-select an option when there is only one. Overriding the 'value' is necessary
  //so that it shows on the input box.
  let defaultValue = value;
  if (options) {
    if (setAutoPopulate && options.length === 1 && (!value || value === "")) {
      onChangeFunction(null, options[0]);
      defaultValue = options[0];
    }
  }

  // Custom filter logic - normalize input and compare to options
  const customFilter = createCustomAutoCompleteFilter((option: any) =>
    optionLabelFunction ? optionLabelFunction(option) : option
  );

  return (
    <div>
      {isEditing ? (
        <Autocomplete
          sx={{ width: "100%" }}
          id={id}
          disabled={disabled}
          multiple={setMultiple}
          freeSolo={setFreeSolo}
          autoHighlight={true}
          autoComplete={true}
          value={defaultValue}
          inputValue={inputValue}
          onChange={onChangeFunction}
          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
            // if freesolo, capture the input on blur
            if (setFreeSolo) {
              const target = event.target as HTMLInputElement;
              onChangeFunction(event, target.value.trim());
            }
          }}
          onInputChange={onInputChangeFunction}
          options={options}
          renderOption={
            renderOption ||
            ((props: any, option: any) =>
              defaultRenderOption(props, option, optionLabelFunction))
          }
          filterOptions={customFilter}
          getOptionLabel={optionLabelFunction}
          noOptionsText={noOptionsText}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              required={required}
              error={touchedFunction && Boolean(errorFunction)}
              helperText={touchedFunction && errorFunction}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Stack direction="row" spacing={1}>
                    {setTooltip.length > 0 ? (
                      <InputAdornment position="end">
                        <Tooltip title={setTooltip} placement="top">
                          <InfoOutlined fontSize="small" />
                        </Tooltip>
                      </InputAdornment>
                    ) : (
                      ""
                    )}
                    {params.InputProps.endAdornment}
                  </Stack>
                ),
              }}
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      ) : (
        <TextField
          id={id}
          name={id}
          label={label}
          value={textValueFunction}
          required={required}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                {setTooltip.length > 0 ? (
                  <Tooltip title={setTooltip} placement="top">
                    <InfoOutlined fontSize="small" style={{ marginTop: -3 }} />
                  </Tooltip>
                ) : (
                  ""
                )}
                <IconButton onClick={editChange} size="small">
                  <Edit fontSize="inherit" />
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ shrink: true }}
        />
      )}
    </div>
  );
};
