import axiosClient from "./axiosClient";

export interface ClientGroup {
  ClientGroupID: number;
  ClientID: number;
  IndigenousGroupID: number;
  IndigenousGroupName: string;
  EmployeeCount: number;
  CompanyCount: number;
}

export interface ClientGroupsResult {
  clientGroups: ClientGroup[];
}

export async function getClientGroups(
  accessToken: String
): Promise<ClientGroupsResult> {
  const url = `/clientGroup`;

  try {
    const { data } = await axiosClient.get<ClientGroupsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createClientGroup(
  accessToken: String,
  newClientGroup: Partial<ClientGroup>
): Promise<ClientGroup> {
  const url = `/ClientGroup`;

  try {
    const { data } = await axiosClient.post<ClientGroup>(url, newClientGroup, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateClientGroup(
  accessToken: String,
  ClientGroupID: number,
  newClientGroup: Partial<ClientGroup>
): Promise<ClientGroup> {
  const url = `/ClientGroup/` + ClientGroupID;

  try {
    const { data } = await axiosClient.post<ClientGroup>(url, newClientGroup, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteClientGroup(
  accessToken: String,
  ClientGroupID: number
): Promise<string> {
  const url = `/ClientGroup/` + ClientGroupID;
  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    console.log("DELDATA", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}
