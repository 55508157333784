import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  GrievanceContact,
  GrievanceContactsResult,
  getGrievanceContacts,
  getGrievanceContactsByGrievance,
  getGrievanceContactsByContact,
  deleteGrievanceContact,
  updateGrievanceContact,
} from "api/stakeholder/grievanceContactAPI";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { SnackBarConstants } from "utils/customHooks";

interface GrievanceContactState {
  grievanceContactsById: Record<number, GrievanceContact>;
  grievanceContactList: number[];
  isLoading: boolean;
  subGrievanceContactisLoading: boolean;
  error: string | null;
}

const GrievanceContactInitialState: GrievanceContactState = {
  grievanceContactsById: {},
  grievanceContactList: [],
  isLoading: false,
  subGrievanceContactisLoading: false,
  error: null,
};

function startLoading(state: GrievanceContactState) {
  state.isLoading = true;
}

function loadingFailed(
  state: GrievanceContactState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const grievanceContacts = createSlice({
  name: "grievanceContacts",
  initialState: GrievanceContactInitialState,
  reducers: {
    getGrievanceContactsStart: startLoading,
    getGrievanceContactsSuccess(
      state,
      { payload }: PayloadAction<GrievanceContactsResult>
    ) {
      const { grievanceContacts } = payload;

      state.isLoading = false;
      state.error = null;

      grievanceContacts.forEach((grievanceContact) => {
        state.grievanceContactsById[grievanceContact.GrievanceContactID] =
          grievanceContact;
      });

      state.grievanceContactList = grievanceContacts.map(
        (grievanceContact) => grievanceContact.GrievanceContactID
      );
    },
    getGrievanceContactsFailure: loadingFailed,
    createGrievanceContactStart: startLoading,
    createGrievanceContactSuccess(
      state,
      { payload }: PayloadAction<GrievanceContact>
    ) {
      const { GrievanceContactID } = payload;
      state.grievanceContactsById[GrievanceContactID] = payload;
      state.grievanceContactList.push(GrievanceContactID);

      state.isLoading = false;
      state.error = null;
    },
    updateGrievanceContactSuccess(
      state,
      { payload }: PayloadAction<GrievanceContact>
    ) {
      const { GrievanceContactID } = payload;

      state.grievanceContactsById[GrievanceContactID] = payload;
      //state.grievanceContactList.push(GrievanceContactID);

      state.isLoading = false;
      state.error = null;
    },
    deleteGrievanceContactSuccess(state, { payload }: PayloadAction<number>) {
      const GrievanceContactID = payload;
      delete state.grievanceContactsById[GrievanceContactID];
      state.grievanceContactList = state.grievanceContactList.filter(
        (item) => item !== GrievanceContactID
      );

      state.isLoading = false;
      state.error = null;
    },
    createGrievanceContactFailure: loadingFailed,
  },
});

export const {
  getGrievanceContactsStart,
  getGrievanceContactsSuccess,
  getGrievanceContactsFailure,
  createGrievanceContactStart,
  createGrievanceContactSuccess,
  updateGrievanceContactSuccess,
  deleteGrievanceContactSuccess,
  createGrievanceContactFailure,
} = grievanceContacts.actions;

export default grievanceContacts.reducer;

export const fetchGrievanceContacts =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getGrievanceContactsStart());
      const grievanceContacts = await getGrievanceContacts(accessToken);
      dispatch(getGrievanceContactsSuccess(grievanceContacts));
    } catch (err: any) {
      dispatch(getGrievanceContactsFailure(err.toString()));
    }
  };

export const fetchGrievanceContactsByGrievance =
  (accessToken: String, grievanceID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getGrievanceContactsStart());
      const grievanceContacts = await getGrievanceContactsByGrievance(
        accessToken,
        grievanceID
      );
      dispatch(getGrievanceContactsSuccess(grievanceContacts));
    } catch (err: any) {
      dispatch(getGrievanceContactsFailure(err.toString()));
    }
  };

export const fetchGrievanceContactsByContact =
  (accessToken: String, contactID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getGrievanceContactsStart());
      const grievanceContacts = await getGrievanceContactsByContact(
        accessToken,
        contactID
      );
      dispatch(getGrievanceContactsSuccess(grievanceContacts));
    } catch (err: any) {
      dispatch(getGrievanceContactsFailure(err.toString()));
    }
  };

export const updGrievanceContact =
  (
    accessToken: String,
    grievanceContactID: number,
    newGrievanceContact: Partial<GrievanceContact>,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createGrievanceContactStart());
      const grievanceContact = await updateGrievanceContact(
        accessToken,
        grievanceContactID,
        newGrievanceContact
      );

      dispatch(updateGrievanceContactSuccess(grievanceContact));
      if (newGrievanceContact.GrievanceID) {
        dispatch(
          fetchGrievanceContactsByGrievance(
            accessToken,
            newGrievanceContact.GrievanceID
          )
        );
      }
      dispatch(openSnackBar(snackbarConstants.UPDATE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createGrievanceContactFailure(err.toString()));
      dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
    }
  };

export const delGrievanceContact =
  (
    accessToken: String,
    grievanceContactID: number,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createGrievanceContactStart());
      const result = await deleteGrievanceContact(
        accessToken,
        grievanceContactID
      );
      dispatch(deleteGrievanceContactSuccess(grievanceContactID));
      dispatch(openSnackBar(snackbarConstants.DELETE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createGrievanceContactFailure(err.toString()));
      dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
    }
  };
