import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { IppInventory } from "components/Inventory/IppInventory";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";
import { useTypedTranslation } from "utils/customHooks";
import { fetchThreads } from "./ThreadSlice";
import { ConvertDateOffset } from "utils/DateFunctions";

export const ThreadInventoryPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);

  const {
    threadList,
    threadsById,
    isLoading: threadIsLoading,
    error: threadError,
  } = useSelector((state: RootState) => state.threads);

  const threads = threadList.map((id) => threadsById[id]);
  const data = threads.map((t: any) => {
    return {
      ...t,
      lastInteractionDate: ConvertDateOffset(t.lastInteractionDate),
    };
  });
  const dataColumns = [
    {
      field: "SequenceID",
      title: t("objStk:objects.thread.fields.id"),
      format: "T-{0:0}",
      filter: "string",
      columnWidth: 100,
    },
    {
      field: "ThreadTitle",
      title: t("objStk:objects.thread.fields.title"),
    },
    {
      field: "ProjectName",
      title: t("objStk:objects.thread.fields.project"),
    },
    {
      field: "Contacts",
      title: t("objStk:objects.interaction.fields.contact", { count: 2 }),
      commaDelimited: true,
      columnWidth: 150,
    },
    {
      field: "Groups",
      title: t("objStk:objects.interaction.fields.groups"),
      commaDelimited: true,
      columnWidth: 150,
    },
    {
      field: "InteractionCount",
      title: t("objStk:objects.thread.interactioncount"),
      columnWidth: 180,
    },
    {
      field: "lastInteractionDate",
      title: t("objStk:objects.thread.lastinteractiondate"),
      filter: "date",
      format: "{0:d}",
      columnWidth: 200,
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchThreads(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  if (threadError) {
    return <ErrorPage errMsg={threadError} />;
  }

  let threadInvView = threadIsLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <IppInventory
        title={t("objStk:objects.thread.name", { count: 2 })}
        tableData={data}
        idField="ThreadID"
        columns={dataColumns}
        isFiltered={true}
        showDetail={true}
        buttonProps={{
          showAdd: true,
        }}
        sortColumn={0}
        linkColumn={1}
        sortReverse={false}
        searchByColumn={"ThreadTitle"}
        searchByPlaceholder={t("strGen:filters.searchobj", {
          objectname: t("objStk:objects.interaction.fields.title", {
            count: 2,
          }),
        })}
      />
    </div>
  );

  return <div id="thread-inventory-page">{threadInvView}</div>;
};
