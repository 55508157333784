import axiosClient from "../axiosClient";

export interface InteractionAction {
  InteractionActionID: number;
  InteractionID: number;
  ActionDescription: string;
  ActionDetails: string;
  PersonResponsible: number;
  OverallResponsibility: string;
  RequiredDate: Date;
  CompletionDate: Date;
  InteractionTitle: string;
  ActionTypeName: string;
  ActionTypeID: number;
  ActionStatusName: string;
  ActionStatusID: number;
  PersonResponsibleName: string;
  IsOverdue: boolean;
  ContactID: number | null;
  MitigationAction: boolean;
}

export interface InteractionActionsResult {
  interactionActions: InteractionAction[];
}

export async function getInteractionActions(
  accessToken: String
): Promise<InteractionActionsResult> {
  const url = `/interactionAction`;

  try {
    const { data } = await axiosClient.get<InteractionActionsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getInteractionActionsByInteraction(
  accessToken: String,
  interactionID: number
): Promise<InteractionActionsResult> {
  const url = `/interactionAction/${interactionID}`;

  try {
    const { data } = await axiosClient.get<InteractionActionsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createInteractionAction(
  accessToken: String,
  newCommInv: Partial<InteractionAction>
): Promise<InteractionAction> {
  const url = `/interactionAction`;

  try {
    const { data } = await axiosClient.post<InteractionAction>(
      url,
      newCommInv,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateInteractionAction(
  accessToken: String,
  commInvID: number,
  newCommInv: Partial<InteractionAction>
): Promise<InteractionAction> {
  const url = `/interactionAction/` + commInvID;

  try {
    const { data } = await axiosClient.post<InteractionAction>(
      url,
      newCommInv,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteInteractionAction(
  accessToken: String,
  commInvID: number
): Promise<string> {
  const url = `/interactionAction/` + commInvID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
