import { configureStore, Action } from "@reduxjs/toolkit";
import { createReduxHistoryContext } from "redux-first-history";
import { ThunkAction } from "redux-thunk";
import { createBrowserHistory, History } from "history";

import rootReducer, { RootState } from "./rootReducer";

//get Tenant from URL
const pathArray = window.location.pathname.split("/");
const tenantName = pathArray[1];
// console.log("TENANT", tenantName);

//Set tenant as basename for the router
// export const history: History = createBrowserHistory({
//   basename: tenantName,
// });

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory({ basename: tenantName }),
    savePreviousLocations: 5,
  });

export const store = configureStore({
  reducer: rootReducer(routerReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(routerMiddleware),
});

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./rootReducer", () => {
    const newRootReducer = require("./rootReducer").default;
    store.replaceReducer(newRootReducer);
  });
}

export const history = createReduxHistory(store);

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

//export default store;
