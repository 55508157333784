import axiosClient from "./axiosClient";

export interface Priority {
  PriorityID: number;
  PriorityName: string;
  PriorityRank: number;
}

export interface PriorityResult {
  priorities: Priority[];
}

export async function getPriorities(
  accessToken: String
): Promise<PriorityResult> {
  const url = `/priority`;

  try {
    const { data } = await axiosClient.get<PriorityResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
