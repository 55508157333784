import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  NotificationTemplate,
  NotificationTemplatesResult,
  getNotificationTemplates,
  createNotificationTemplate,
  updateNotificationTemplate,
  deleteNotificationTemplate,
} from "api/notificationTemplateAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";

interface NotificationTemplateState {
  notificationTemplatesById: Record<number, NotificationTemplate>;
  notificationTemplateList: number[];
  isLoading: boolean;
  subNotificationTemplateisLoading: boolean;
  error: string | null;
}

const NotificationTemplateInitialState: NotificationTemplateState = {
  notificationTemplatesById: {},
  notificationTemplateList: [],
  isLoading: false,
  subNotificationTemplateisLoading: false,
  error: null,
};

function startLoading(state: NotificationTemplateState) {
  state.isLoading = true;
}

function startLoadingSub(state: NotificationTemplateState) {
  state.subNotificationTemplateisLoading = true;
}

function loadingFailed(
  state: NotificationTemplateState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

function loadingSubFailed(
  state: NotificationTemplateState,
  action: PayloadAction<string>
) {
  state.subNotificationTemplateisLoading = false;
  state.error = action.payload;
}

const notificationTemplates = createSlice({
  name: "notificationTemplates",
  initialState: NotificationTemplateInitialState,
  reducers: {
    getNotificationTemplatesStart: startLoading,
    getNotificationTemplatesSuccess(
      state,
      { payload }: PayloadAction<NotificationTemplatesResult>
    ) {
      const { notificationTemplates } = payload;
      // console.log("PAYLOAD", notificationTemplates);
      state.isLoading = false;
      state.error = null;

      notificationTemplates.forEach((notificationTemplate) => {
        state.notificationTemplatesById[
          notificationTemplate.NotificationTemplateID
        ] = notificationTemplate;
      });

      state.notificationTemplateList = notificationTemplates.map(
        (notificationTemplate) => notificationTemplate.NotificationTemplateID
      );
    },
    getNotificationTemplatesFailure: loadingFailed,
    createNotificationTemplateStart: startLoading,
    createNotificationTemplateSuccess(
      state,
      { payload }: PayloadAction<NotificationTemplate>
    ) {
      const { NotificationTemplateID } = payload;
      state.notificationTemplatesById[NotificationTemplateID] = payload;
      state.notificationTemplateList.push(NotificationTemplateID);

      state.isLoading = false;
      state.error = null;
    },
    updateNotificationTemplateSuccess(
      state,
      { payload }: PayloadAction<NotificationTemplate>
    ) {
      const { NotificationTemplateID } = payload;
      state.notificationTemplatesById[NotificationTemplateID] = payload;

      state.isLoading = false;
      state.error = null;
    },
    deleteNotificationTemplateSuccess(
      state,
      { payload }: PayloadAction<number>
    ) {
      const NotificationTemplateID = payload;
      delete state.notificationTemplatesById[NotificationTemplateID];
      state.notificationTemplateList = state.notificationTemplateList.filter(
        (item) => item !== NotificationTemplateID
      );

      state.isLoading = false;
      state.error = null;
    },
    createNotificationTemplateFailure: loadingFailed,
  },
});

export const {
  getNotificationTemplatesStart,
  getNotificationTemplatesSuccess,
  getNotificationTemplatesFailure,
  createNotificationTemplateStart,
  createNotificationTemplateSuccess,
  updateNotificationTemplateSuccess,
  deleteNotificationTemplateSuccess,
  createNotificationTemplateFailure,
} = notificationTemplates.actions;

export default notificationTemplates.reducer;

export const fetchNotificationTemplates =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getNotificationTemplatesStart());
      const notificationTemplates = await getNotificationTemplates(accessToken);
      //console.log("RETURN", notificationTemplates);
      dispatch(getNotificationTemplatesSuccess(notificationTemplates));
    } catch (err: any) {
      dispatch(getNotificationTemplatesFailure(err.toString()));
    }
  };

export const addNotificationTemplate =
  (
    accessToken: String,
    newNotificationTemplate: Partial<NotificationTemplate>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createNotificationTemplateStart());
      const notificationTemplate = await createNotificationTemplate(
        accessToken,
        newNotificationTemplate
      );
      dispatch(createNotificationTemplateSuccess(notificationTemplate));
      dispatch(push("/benefits/notificationTemplates/new"));
    } catch (err: any) {
      dispatch(createNotificationTemplateFailure(err.toString()));
    }
  };

export const updNotificationTemplate =
  (
    accessToken: String,
    notificationTemplateID: number,
    newNotificationTemplate: Partial<NotificationTemplate>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createNotificationTemplateStart());
      const notificationTemplate = await updateNotificationTemplate(
        accessToken,
        notificationTemplateID,
        newNotificationTemplate
      );
      console.log("UPDRETTHK", notificationTemplate);
      dispatch(updateNotificationTemplateSuccess(notificationTemplate));
      //dispatch(push("/benefits/notificationTemplates/" + notificationTemplateID));
    } catch (err: any) {
      dispatch(createNotificationTemplateFailure(err.toString()));
    }
  };

export const delNotificationTemplate =
  (accessToken: String, notificationTemplateID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createNotificationTemplateStart());
      const result = await deleteNotificationTemplate(
        accessToken,
        notificationTemplateID
      );
      console.log("COMMINV_DG", notificationTemplateID);
      dispatch(deleteNotificationTemplateSuccess(notificationTemplateID));
      dispatch(push("/benefits/notificationTemplates"));
    } catch (err: any) {
      console.log("PMT DELETE ERROR", err);
      dispatch(createNotificationTemplateFailure(err.toString()));
    }
  };
