import axiosClient from "./axiosClient";

export interface ExpenditureChart {
  MonthSequenceID: number;
  YearNumber: number;
  MonthName: string;
  Indigenous: number;
  NonIndigenous: number;
}

export interface ExpenditureChartsResult {
  expenditures: ExpenditureChart[];
}

export async function getExpenditureCharts(
  accessToken: String
): Promise<ExpenditureChartsResult> {
  const url = `/charts/expenditures`;
  try {
    const { data } = await axiosClient.get<ExpenditureChartsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
