import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { delProjectComponent } from "./ProjectComponentSlice";
import { RootState } from "app/rootReducer";
import { useEffect } from "react";
import { fetchDeleteChecksByProjectComponent } from "features/deleteCheck/DeleteCheckSlice";
import { IppDeleteButton } from "components/Buttons/IppDeleteButton";
import { IppCancelButton } from "components/Buttons/IppCancelButton";

interface DeletePaymentProps {
  isOpen: boolean;
  handleClose: any;
  cmp: any;
  cmpID: number;
}

export const DeleteComponentDialog = (props: DeletePaymentProps) => {
  const { isOpen, handleClose, cmp, cmpID } = props;
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const { deleteChecksArray: deleteChecks, isLoading: deleteChecksIsLoading } =
    useSelector((state: RootState) => state.deleteChecks);

  let disDel = true;
  let display = [""];

  if (deleteChecks) {
    const Record = deleteChecks.find(
      (d) => d.RecordType == "ProjectComponent" && d.ItemID == cmpID
    );

    if (Record) {
      disDel = Record.HasChildren;
      display = Record.ChildDetails.split(",");
    }
  }

  useEffect(() => {
    (async () => {
      try {
        if (isOpen) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchDeleteChecksByProjectComponent(accessToken, cmpID));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [isOpen]);

  const handleDelete = () => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(delProjectComponent(accessToken, cmpID, cmp.ProjectID));
        handleClose();
      } catch (e) {
        console.error(e);
      }
    })();
  };

  return (
    <>
      <Dialog maxWidth="sm" open={isOpen} onClose={handleClose}>
        <DialogTitle>Delete Project Component</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              You have selected to delete the project component shown below.
              This cannot be undone.
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption">Component:</Typography>
            </Grid>
            <Grid item xs={9}>
              {cmp.ProjectComponentName}
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption">Location:</Typography>
            </Grid>
            <Grid item xs={9}>
              {cmp.ComponentLocation}
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption">Details:</Typography>
            </Grid>
            <Grid item xs={9}>
              {cmp.ComponentDescription}
            </Grid>
            {!deleteChecksIsLoading && disDel && (
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ margin: 2 }}>
                  You cannot delete this item because the following things are
                  dependent on it. Please resolve them first.
                </Grid>
                <ul>{display}</ul>
              </Grid>
            )}
            <Grid item xs={12}>
              <IppDeleteButton
                onClick={() => handleDelete()}
                disabled={disDel}
              />
              <IppCancelButton onClick={handleClose} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
