import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CurrentCompany, getCurrentCompany } from "api/companyAPI";
import { AppThunk } from "app/store";

interface CurrentCompanyState {
  currentCompany: CurrentCompany;
  isLoading: boolean;
  error: string | null;
}

const currentCompanyInitialState: CurrentCompanyState = {
  currentCompany: {} as CurrentCompany,
  isLoading: false,
  error: null,
};

function startLoading(state: CurrentCompanyState) {
  state.isLoading = true;
}

function loadingFailed(
  state: CurrentCompanyState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const currentCompany = createSlice({
  name: "currentCompany",
  initialState: currentCompanyInitialState,
  reducers: {
    getCurrentCompanyStart: startLoading,
    getCurrentCompanySuccess(
      state,
      { payload }: PayloadAction<CurrentCompany>
    ) {
      state.currentCompany = payload;
      state.isLoading = false;
      state.error = null;
    },
    getCurrentCompanyFailure: loadingFailed,
  },
});

export const {
  getCurrentCompanyStart,
  getCurrentCompanySuccess,
  getCurrentCompanyFailure,
} = currentCompany.actions;

export default currentCompany.reducer;

export const fetchCurrentCompany =
  (accessToken: String, clientId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCurrentCompanyStart());
      const currentComp = await getCurrentCompany(accessToken, clientId);
      dispatch(getCurrentCompanySuccess(currentComp));
    } catch (err: any) {
      dispatch(getCurrentCompanyFailure(err.toString()));
    }
  };
