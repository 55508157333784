import * as yup from "yup";
import { useTypedTranslation } from "utils/customHooks";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";

export const ProjectValidationSchema = (
  projectCodes: (string | null)[],
  regionRequired: boolean
) => {
  const t = useTypedTranslation(["objPlt", "strGen"]);

  const { ft_ben_Region } = useSelector((state: RootState) => state.client);

  return yup.object({
    ProjectName: yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.project.fields.projectname"),
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.project.fields.projectname"),
          count: 50,
        })
      )
      .typeError(t("strGen:validation.invalidformat")),
    ProjectCode: yup
      .string()
      .nullable()
      .trim()
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.project.fields.projectcode"),
          count: 50,
        })
      )
      .matches(
        /^[A-Z0-9]+$/,
        t("strGen:validation.alphaupper", {
          fieldname: t("objPlt:objects.project.fields.projectcode"),
        })
      )
      .test(
        "code-unique",
        t("strGen:validation.exists", {
          fieldname: t("objPlt:objects.project.fields.projectcode"),
        }),
        (value: any) => {
          if (value && projectCodes.includes(value) && value !== "") {
            return false;
          } else return true;
        }
      )
      .typeError(t("strGen:validation.invalidformat")),
    ProjectTypeID: yup.number().nullable(),
    ProjectLeadID: yup.number().nullable(),
    RegionID: yup
      .number()
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.company.fields.region.name"),
        })
      )
      .when([], {
        is: () => regionRequired,
        then: (schema) =>
          schema
            .positive(
              t("strGen:validation.required", {
                fieldname: t("objPlt:objects.project.fields.region"),
              })
            )
            .required(
              t("strGen:validation.required", {
                fieldname: t("objPlt:objects.project.fields.region"),
              })
            ),
        otherwise: (schema) => schema.nullable(),
      }),

    Location: yup
      .string()
      .trim()
      .nullable()
      .min(
        2,
        t("strGen:validation.min", {
          fieldname: t("objPlt:objects.project.fields.location"),
          count: 2,
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.project.fields.location"),
          count: 50,
        })
      )
      .typeError(t("strGen:validation.invalidformat")),

    ProvinceState: yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:platformwide.fields.provincestate"),
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objPlt:platformwide.fields.provincestate"),
          count: 50,
        })
      )
      .typeError(t("strGen:validation.invalidformat")),
    CountryName: yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:platformwide.fields.country"),
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objPlt:platformwide.fields.country"),
          count: 50,
        })
      )
      .typeError(t("strGen:validation.invalidformat")),
    ProjectDescription: yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.project.fields.description"),
        })
      )
      .min(
        2,
        t("strGen:validation.min", {
          fieldname: t("objPlt:objects.project.fields.description"),
          count: 2,
        })
      )
      .typeError(t("strGen:validation.invalidformat")),
    EmployeeTarget: yup
      .number()
      .positive()
      .nullable()
      .min(
        0,
        t("strGen:validation.percentdecimalrange", {
          fieldname: t("objBen:objects.contract.fields.targets.name"),
        })
      )
      .max(
        1,
        t("strGen:validation.percentdecimalrange", {
          fieldname: t("objBen:objects.contract.fields.targets.name"),
        })
      )
      .typeError(t("strGen:validation.invalidnumber")),
    HoursTarget: yup
      .number()
      .positive()
      .nullable()
      .min(
        0,
        t("strGen:validation.percentdecimalrange", {
          fieldname: t("objBen:objects.contract.fields.targets.name"),
        })
      )
      .max(
        1,
        t("strGen:validation.percentdecimalrange", {
          fieldname: t("objBen:objects.contract.fields.targets.name"),
        })
      )
      .typeError(t("strGen:validation.invalidnumber")),
    ExpenditureTarget: yup
      .number()
      .positive()
      .nullable()
      .min(
        0,
        t("strGen:validation.percentdecimalrange", {
          fieldname: t("objBen:objects.contract.fields.targets.name"),
        })
      )
      .max(
        1,
        t("strGen:validation.percentdecimalrange", {
          fieldname: t("objBen:objects.contract.fields.targets.name"),
        })
      )
      .typeError(t("strGen:validation.invalidnumber")),
  });
};
