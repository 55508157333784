import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Divider,
} from "@mui/material";
import { useTypedTranslation } from "utils/customHooks";
import { IppCancelButton } from "./Buttons/IppCancelButton";
import { IppButton } from "./Buttons/IppButton";
import { useState } from "react";
import {
  Interaction,
  InteractionWithLists,
} from "api/stakeholder/interactionAPI";
import { Thread } from "api/stakeholder/threadAPI";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingIndicator from "./LoadingIndicator";
import {
  IppRichTextEditor,
  StandardStatementRecordTypes,
} from "./RichTextEditor/IppRichTextEditor";

interface IppGenerateSummaryDialogProps {
  fieldname: string;
  titleKey: string;
  data: Thread | InteractionWithLists;
  initialValue: string | null;
  aiSummaryApiFunction: (
    accessToken: string,
    data: Thread | InteractionWithLists
  ) => Promise<string>;
  open: boolean;
  setOpen: (open: boolean) => void;
  onSubmit: (value: string) => void;
  standardStatementsRecordType?: StandardStatementRecordTypes;
}

export const IppGenerateSummaryDialog = ({
  fieldname,
  titleKey,
  data,
  initialValue,
  aiSummaryApiFunction,
  open,
  setOpen,
  onSubmit,
  standardStatementsRecordType,
}: IppGenerateSummaryDialogProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["strGen"]);
  const [generatedSummary, setGeneratedSummary] = useState(initialValue);
  const [isGenerating, setIsGenerating] = useState(false);

  // string variables
  const details = t("objStk:objects.interaction.fields.details");
  const interaction = t("objStk:objects.interaction.name");
  const interactions = t("objStk:objects.interaction.name_other");
  const contacts = t("objStk:objects.interaction.fields.contact_other");
  const groups = t("objStk:objects.interaction.fields.groups");
  const issues = t("objStk:objects.interaction.fields.issues");
  const thread = t("objStk:objects.thread.name");
  const threadfull = t("objStk:objects.thread.namefull");
  const summary = t("objStk:objects.thread.fields.summary");

  // Derived values based on summaryIsGenerating
  const dialogTitle = t(
    `strGen:components.ippgeneratesummarydialog.${titleKey}.title${
      isGenerating ? "generating" : ""
    }`
  );

  const dialogContentText = t(
    `strGen:components.ippgeneratesummarydialog.${titleKey}.textcontent${
      isGenerating ? "generating" : ""
    }`,
    {
      details,
      interaction,
      thread,
      threadfull,
      summary,
      interactions,
      contacts,
      groups,
      issues,
    }
  );

  const contentTextColor = isGenerating ? "primary" : "text.secondary";

  const contentTextStyles = {
    fontWeight: isGenerating ? "bold" : "normal",
    mb: 3,
    textAlign: isGenerating ? "center" : "left",
  };

  const generateButtonLabel = t(
    `strGen:buttons.${
      generatedSummary ? "regeneratefieldname" : "generatefieldname"
    }`,
    {
      fieldname: fieldname,
    }
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleAccept = () => {
    onSubmit(generatedSummary ?? "");
    setOpen(false);
  };

  const handleGenerate = async () => {
    try {
      setIsGenerating(true);
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
        },
      });
      const aiResponse = await aiSummaryApiFunction(accessToken, data);

      setGeneratedSummary(aiResponse);
      setIsGenerating(false);
    } catch (e) {
      console.error(e);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <Box sx={{ padding: 2 }}>
        <DialogTitle
          color="primary"
          sx={{ typography: "h5", fontWeight: "bold" }}
        >
          {dialogTitle}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText color={contentTextColor} sx={contentTextStyles}>
            {dialogContentText}
          </DialogContentText>

          {isGenerating ? (
            <LoadingIndicator useRingLoader />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 5,
              }}
            >
              <IppRichTextEditor
                label=""
                value={generatedSummary}
                isEditing={true}
                touchedExpression={null}
                errorsExpression={null}
                setFieldValue={setGeneratedSummary}
                height={160}
                standardStatementsRecordType={standardStatementsRecordType}
              />

              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                <IppButton onClick={handleGenerate}>
                  {generateButtonLabel}
                </IppButton>
                <IppButton onClick={handleAccept}>
                  {t("strGen:buttons.accept")}
                </IppButton>
                <IppCancelButton onClick={handleClose} />
              </Box>
            </Box>
          )}
        </DialogContent>
      </Box>
    </Dialog>
  );
};
