import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Grid as KendoGrid, GridColumn } from "@progress/kendo-react-grid";
import LoadingIndicator from "components/LoadingIndicator";
import { Link } from "react-router-dom";
import { getCurrentModule } from "../../../utils/urlUtils";
import { useTypedTranslation } from "utils/customHooks";
import { IppMergeButton } from "components/Buttons/IppMergeButton";

interface CheckDuplicatesProps {
  originalID: number;
  isOpen: boolean;
  closeWindow: any;
}

export const ContactCheckDuplicates = (props: CheckDuplicatesProps) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen"]);
  const { originalID, isOpen, closeWindow } = props;
  const [filterString, setFilterString] = useState("");

  const {
    contactList,
    contactsById,
    isLoading: contactIsLoading,
  } = useSelector((state: RootState) => state.contacts);

  const contacts = contactList.map((id) => contactsById[id]);

  const data = contacts
    .filter((item) => item.ContactID != originalID)
    .map((p) => {
      let n = Object.assign({}, p) as any;
      n.FullName = p.FirstName + " " + p.Surname;
      n.SearchName = p.FirstName ? p.FirstName : "";
      if (!p.Groups) {
        n.GroupList = "";
      } else {
        let groups = p.Groups.split(",");
        if (groups.length > 1) {
          n.GroupList = p.FirstGroupName + " +" + (groups.length - 1);
        } else {
          n.GroupList = p.FirstGroupName;
        }
      }

      return n;
    });

  const onChangeFilterString = (event: any) => {
    setFilterString(event.target.value);
  };

  const linkCell = (props: any) => {
    return (
      <td align="right">
        <Link
          to={
            `/${getCurrentModule()}/contacts/merge/` +
            originalID +
            "/" +
            props.dataItem.ContactID
          }
        >
          <Tooltip
            title={t("strGen:pages.merge.selecttooltip", {
              fieldname: t("objPlt:objects.contact.name", { count: 2 }),
            })}
          >
            <IppMergeButton buttonText={t("strGen:pages.merge.select")} />
          </Tooltip>
        </Link>
      </td>
    );
  };

  return contactIsLoading ? (
    <LoadingIndicator />
  ) : (
    <Dialog open={isOpen} onClose={closeWindow} maxWidth="lg" fullWidth>
      <DialogTitle>
        {t("strGen:pages.merge.checkforduplicate", {
          fieldname: t("objPlt:objects.contact.name", { count: 2 }),
        })}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body2">
              {t("strGen:pages.merge.checkinfo", {
                fieldname: t("objPlt:objects.contact.name", { count: 2 }),
                fieldname2: t("objPlt:objects.contact.name"),
              })}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1">
              {t("strGen:pages.merge.searchfor")}
            </Typography>
          </Grid>
          <Grid item xs>
            <TextField
              id="txtSearch"
              placeholder={t("strGen:pages.merge.searchplaceholder")}
              onChange={onChangeFilterString}
              value={filterString}
            />
          </Grid>
          <Grid item xs={12}>
            <KendoGrid
              data={
                filterString === ""
                  ? data
                  : data.filter((item) =>
                      item.FullName.toLowerCase().includes(
                        filterString.toLowerCase()
                      )
                    )
              }
            >
              <GridColumn
                field="FullName"
                title={t("objPlt:objects.contact.fields.name")}
              />
              <GridColumn
                field="Email"
                title={t("objPlt:objects.contact.fields.emailaddress")}
              />
              <GridColumn
                field="PrimaryPhone"
                title={t("objPlt:objects.contact.fields.phone")}
              />
              <GridColumn
                field="TownCity"
                title={t("objPlt:objects.contact.fields.towncity")}
              />
              <GridColumn
                field="GroupList"
                title={t("objPlt:objects.contact.fields.group", { count: 2 })}
              />
              <GridColumn
                field="ContactID"
                title=" "
                cell={linkCell}
                width="110"
              />
            </KendoGrid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
