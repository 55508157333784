import { AutoFixHigh } from "@mui/icons-material";
import { IppButton, IppButtonProps } from "components/Buttons/IppButton";
import { useTypedTranslation } from "utils/customHooks";

interface IppGenerateSummaryButtonProps extends IppButtonProps {
  startIcon?: React.ReactNode;
  child?: React.ReactNode;
  toolTipMessage?: string;
  isIconButton?: boolean;
}

export const IppGenerateSummaryButton = ({
  startIcon,
  child = <AutoFixHigh />,
  toolTipMessage,
  isIconButton = true,
  ...props
}: IppGenerateSummaryButtonProps) => {
  const t = useTypedTranslation(["strGen"]);
  const T_toolTipMessage =
    toolTipMessage || t("strGen:buttons.tooltip.generatesummary");

  return (
    <IppButton
      variant="outlined"
      isIconButton={isIconButton}
      size="small"
      startIcon={isIconButton ? undefined : startIcon ?? <AutoFixHigh />}
      tooltip={T_toolTipMessage}
      {...props}
    >
      {child}
    </IppButton>
  );
};
