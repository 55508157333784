import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  EmployeesByGenderChart,
  EmployeesByGenderChartsResult,
  getEmployeesByGenderCharts,
} from "api/employeesByGenderChartAPI";
import { AppThunk } from "app/store";

interface EmployeesByGenderChartState {
  employeesByGenderChartsById: Record<number, EmployeesByGenderChart>;
  employeesByGenderChartList: number[];
  isLoading: boolean;
  error: string | null;
}

const EmployeesByGenderChartInitialState: EmployeesByGenderChartState = {
  employeesByGenderChartsById: {},
  employeesByGenderChartList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: EmployeesByGenderChartState) {
  state.isLoading = true;
}

function loadingFailed(
  state: EmployeesByGenderChartState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const employeesByGenderCharts = createSlice({
  name: "employeesByGender",
  initialState: EmployeesByGenderChartInitialState,
  reducers: {
    getEmployeesByGenderChartsStart: startLoading,
    getEmployeesByGenderChartsSuccess(
      state,
      { payload }: PayloadAction<EmployeesByGenderChartsResult>
    ) {
      const { employeesByGender } = payload;
      state.isLoading = false;
      state.error = null;

      employeesByGender.forEach((item) => {
        state.employeesByGenderChartsById[item.MonthSequenceID] = item;
      });

      state.employeesByGenderChartList = employeesByGender.map(
        (item) => item.MonthSequenceID
      );
    },
    getEmployeesByGenderChartsFailure: loadingFailed,
  },
});

export const {
  getEmployeesByGenderChartsStart,
  getEmployeesByGenderChartsSuccess,
  getEmployeesByGenderChartsFailure,
} = employeesByGenderCharts.actions;

export default employeesByGenderCharts.reducer;

export const fetchEmployeesByGenderCharts =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getEmployeesByGenderChartsStart());
      const employeesByGenderCharts = await getEmployeesByGenderCharts(
        accessToken
      );

      dispatch(getEmployeesByGenderChartsSuccess(employeesByGenderCharts));
    } catch (err: any) {
      dispatch(getEmployeesByGenderChartsFailure(err.toString()));
    }
  };
