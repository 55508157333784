import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  EngagementTile,
  EngagementTilesResult,
  getEngagementTiles,
} from "api/stakeholder/charts/engagementTileAPI";
import { AppThunk } from "app/store";

interface EngagementTileState {
  engagementTilesById: Record<number, EngagementTile>;
  engagementTileList: number[];
  isLoading: boolean;
  error: string | null;
}

const EngagementTileInitialState: EngagementTileState = {
  engagementTilesById: {},
  engagementTileList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: EngagementTileState) {
  state.isLoading = true;
}

function loadingFailed(
  state: EngagementTileState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const engagementTiles = createSlice({
  name: "engagementTiles",
  initialState: EngagementTileInitialState,
  reducers: {
    getEngagementTilesStart: startLoading,
    getEngagementTilesSuccess(
      state,
      { payload }: PayloadAction<EngagementTilesResult>
    ) {
      const { engagementTiles } = payload;
      state.isLoading = false;
      state.error = null;

      engagementTiles.forEach((item) => {
        state.engagementTilesById[item.ItemID] = item;
      });

      state.engagementTileList = engagementTiles.map((item) => item.ItemID);
    },
    getEngagementTilesFailure: loadingFailed,
  },
});

export const {
  getEngagementTilesStart,
  getEngagementTilesSuccess,
  getEngagementTilesFailure,
} = engagementTiles.actions;

export default engagementTiles.reducer;

export const fetchEngagementTiles =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getEngagementTilesStart());
      const engagementTiles = await getEngagementTiles(accessToken);
      // console.log("RETURN", engagementTiles);
      dispatch(getEngagementTilesSuccess(engagementTiles));
    } catch (err: any) {
      dispatch(getEngagementTilesFailure(err.toString()));
    }
  };
