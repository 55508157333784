import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  Ethnicity,
  EthnicitysResult,
  getEthnicitys,
  createEthnicity,
  updateEthnicity,
  deleteEthnicity,
} from "api/dataList/ethnicityAPI";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { push } from "redux-first-history";

interface EthnicityState {
  ethnicitysById: Record<number, Ethnicity>;
  ethnicityList: number[];
  isLoading: boolean;
  error: string | null;
}

const EthnicityInitialState: EthnicityState = {
  ethnicitysById: {},
  ethnicityList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: EthnicityState) {
  state.isLoading = true;
}

function loadingFailed(state: EthnicityState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const ethnicitys = createSlice({
  name: "ethnicitys",
  initialState: EthnicityInitialState,
  reducers: {
    getEthnicitysStart: startLoading,
    getEthnicitysSuccess(state, { payload }: PayloadAction<EthnicitysResult>) {
      const { ethnicitys } = payload;
      state.isLoading = false;
      state.error = null;

      ethnicitys.forEach((ethnicity) => {
        state.ethnicitysById[ethnicity.EthnicityID] = ethnicity;
      });

      state.ethnicityList = ethnicitys.map(
        (ethnicity) => ethnicity.EthnicityID
      );
    },
    getEthnicitysFailure: loadingFailed,
    createEthnicityStart: startLoading,
    createEthnicitySuccess(state, { payload }: PayloadAction<Ethnicity>) {
      const { EthnicityID } = payload;
      state.ethnicitysById[EthnicityID] = payload;
      state.ethnicityList.push(EthnicityID);

      state.isLoading = false;
      state.error = null;
    },
    updateEthnicitySuccess(state, { payload }: PayloadAction<Ethnicity>) {
      const { EthnicityID } = payload;
      state.ethnicitysById[EthnicityID] = payload;
      state.ethnicityList.push(EthnicityID);

      state.isLoading = false;
      state.error = null;
    },
    deleteEthnicitySuccess(state, { payload }: PayloadAction<number>) {
      const EthnicityID = payload;
      delete state.ethnicitysById[EthnicityID];
      state.ethnicityList = state.ethnicityList.filter(
        (item) => item !== EthnicityID
      );

      state.isLoading = false;
      state.error = null;
    },
    createEthnicityFailure: loadingFailed,
  },
});

export const {
  getEthnicitysStart,
  getEthnicitysSuccess,
  getEthnicitysFailure,
  createEthnicityStart,
  createEthnicitySuccess,
  updateEthnicitySuccess,
  deleteEthnicitySuccess,
  createEthnicityFailure,
} = ethnicitys.actions;

export default ethnicitys.reducer;

export const fetchEthnicitys =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getEthnicitysStart());
      const ethnicitys = await getEthnicitys(accessToken);
      dispatch(getEthnicitysSuccess(ethnicitys));
    } catch (err: any) {
      dispatch(getEthnicitysFailure(err.toString()));
    }
  };

export const addEthnicity =
  (accessToken: String, newEthnicity: Partial<Ethnicity>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createEthnicityStart());
      const ethnicity = await createEthnicity(accessToken, newEthnicity);
      dispatch(createEthnicitySuccess(ethnicity));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push(`/admin/ethnicitys`));
    } catch (err: any) {
      dispatch(createEthnicityFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updEthnicity =
  (
    accessToken: String,
    ethnicityID: number,
    newEthnicity: Partial<Ethnicity>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createEthnicityStart());
      const ethnicity = await updateEthnicity(
        accessToken,
        ethnicityID,
        newEthnicity
      );
      dispatch(updateEthnicitySuccess(ethnicity));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push("/admin/ethnicitys"));
    } catch (err: any) {
      dispatch(createEthnicityFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delEthnicity =
  (accessToken: String, ethnicityID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createEthnicityStart());
      const result = await deleteEthnicity(accessToken, ethnicityID);
      dispatch(deleteEthnicitySuccess(ethnicityID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createEthnicityFailure(err.toString()));
    }
  };
