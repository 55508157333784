import { Trans } from "react-i18next";
import {
  ConstructedErrors,
  GenericErrorTitlesObject,
} from "utils/types/index.types";
import {
  generateErrorTitle,
  getGenericConstructedErrors,
  getGenericConstructedWarnings,
} from "utils/uploadUtils";

type WorkHoursErrorTitles = GenericErrorTitlesObject & {
  employeeDoesntExist: (count: number, singleItem?: string) => JSX.Element | "";
  outdatedRecords: (count: number) => JSX.Element | "";
};

export const generateWorkHoursErrorTitles: WorkHoursErrorTitles = {
  ...generateErrorTitle,
  employeeDoesntExist: (count: number) =>
    count > 0 ? (
      <Trans
        i18nKey="strGen:uploaderrors.errors.workhours.employeedoesntexist.title"
        count={count}
      />
    ) : (
      ""
    ),
  outdatedRecords: (count: number) =>
    count > 0 ? (
      <Trans
        i18nKey="strGen:uploaderrors.errors.workhours.outdatedrecords.title"
        count={count}
      />
    ) : (
      ""
    ),
};

export const getNewWorkHoursConstructedErrors: () => ConstructedErrors =
  () => ({
    ...getGenericConstructedErrors(),
  });

// the constructed error objects are built to show lists, but "outdatedRecords" does not require a list. For now it is built to have it's count set to 1 if it needs to be shown, and will have no list to render.
export const getNewWorkHoursConstructedWarnings: () => ConstructedErrors =
  () => ({
    ...getGenericConstructedWarnings(),
    outdatedRecords: {
      count: 0,
      get message() {
        return (
          <>
            Due to Platform updates, Employee requirements have changed. This
            upload contains one or more Employees that are now missing required
            information. After uploading Work Hour Details, these Employee
            records will be available to review on the{" "}
            <strong>Employees Needing Review</strong> page.
          </>
        );
      },
      list: [],
    },
    employeeDoesntExist: {
      count: 0,
      get message() {
        return (
          <>
            <Trans
              i18nKey="strGen:uploaderrors.errors.workhours.employeedoesntexist.message"
              count={this.count}
            />
          </>
        );
      },
      childList: [],
    },
  });
