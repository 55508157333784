import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  DeleteCheck,
  DeleteChecksResult,
  getDeleteChecksByCompany,
  getDeleteChecksByProject,
  getDeleteChecksByEmployee,
  getDeleteChecksByWorkHourPeriod,
  getDeleteChecksByProjectComponent,
  getDeleteChecksByPayment,
  getDeleteChecksByIssue,
  getDeleteChecksByContact,
  getDeleteChecksByGroup,
  getDeleteChecksByInteraction,
  getDeleteChecksByGrievance,
  getDeleteChecksByCommitment,
  getDeleteChecksByCommitmentSource,
  getDeleteChecksByTier,
  getDeleteChecksByProjectTypes,
  getDeleteChecksByTag,
  getDeleteChecksByRegion,
  getDeleteChecksByPhase,
  getDeleteChecksByThread,
  getDeleteChecksByEthnicity,
  getDeleteChecksByCustomEmpCategory,
} from "api/deleteCheckAPI";
import { AppThunk } from "app/store";

interface DeleteCheckState {
  deleteChecksArray: DeleteCheck[];
  isLoading: boolean;
  error: string | null;
}

const DeleteCheckInitialState: DeleteCheckState = {
  deleteChecksArray: [] as DeleteCheck[],
  isLoading: false,
  error: null,
};

function startLoading(state: DeleteCheckState) {
  state.isLoading = true;
}

function loadingFailed(state: DeleteCheckState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const deleteChecks = createSlice({
  name: "deleteCheck",
  initialState: DeleteCheckInitialState,
  reducers: {
    getDeleteChecksStart: startLoading,
    getDeleteChecksSuccess(
      state,
      { payload }: PayloadAction<DeleteChecksResult>
    ) {
      const { deleteChecks } = payload;
      state.isLoading = false;
      state.error = null;

      state.deleteChecksArray = deleteChecks;
    },
    getDeleteChecksFailure: loadingFailed,
  },
});

export const {
  getDeleteChecksStart,
  getDeleteChecksSuccess,
  getDeleteChecksFailure,
} = deleteChecks.actions;

export default deleteChecks.reducer;

// make sure these functions take only access token and recordID
// as the Confirm dialog relies on it
export const fetchDeleteChecksByCompany =
  (accessToken: String, companyID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getDeleteChecksStart());
      const deleteChecks = await getDeleteChecksByCompany(
        accessToken,
        companyID
      );
      dispatch(getDeleteChecksSuccess(deleteChecks));
    } catch (err: any) {
      dispatch(getDeleteChecksFailure(err.toString()));
    }
  };

export const fetchDeleteChecksByProject =
  (accessToken: String, projectID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getDeleteChecksStart());
      const deleteChecks = await getDeleteChecksByProject(
        accessToken,
        projectID
      );
      dispatch(getDeleteChecksSuccess(deleteChecks));
    } catch (err: any) {
      dispatch(getDeleteChecksFailure(err.toString()));
    }
  };

export const fetchDeleteChecksByEmployee =
  (accessToken: String, employeeID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getDeleteChecksStart());
      const deleteChecks = await getDeleteChecksByEmployee(
        accessToken,
        employeeID
      );
      dispatch(getDeleteChecksSuccess(deleteChecks));
    } catch (err: any) {
      dispatch(getDeleteChecksFailure(err.toString()));
    }
  };

export const fetchDeleteChecksByWorkHourPeriod =
  (accessToken: String, whpID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getDeleteChecksStart());
      const deleteChecks = await getDeleteChecksByWorkHourPeriod(
        accessToken,
        whpID
      );
      dispatch(getDeleteChecksSuccess(deleteChecks));
    } catch (err: any) {
      dispatch(getDeleteChecksFailure(err.toString()));
    }
  };

export const fetchDeleteChecksByProjectComponent =
  (accessToken: String, projCompID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getDeleteChecksStart());
      const deleteChecks = await getDeleteChecksByProjectComponent(
        accessToken,
        projCompID
      );
      dispatch(getDeleteChecksSuccess(deleteChecks));
    } catch (err: any) {
      dispatch(getDeleteChecksFailure(err.toString()));
    }
  };

export const fetchDeleteChecksByPayment =
  (accessToken: String, paymentID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getDeleteChecksStart());
      const deleteChecks = await getDeleteChecksByPayment(
        accessToken,
        paymentID
      );
      dispatch(getDeleteChecksSuccess(deleteChecks));
    } catch (err: any) {
      dispatch(getDeleteChecksFailure(err.toString()));
    }
  };

export const fetchDeleteChecksByContact =
  (accessToken: String, contactID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getDeleteChecksStart());
      const deleteChecks = await getDeleteChecksByContact(
        accessToken,
        contactID
      );
      dispatch(getDeleteChecksSuccess(deleteChecks));
    } catch (err: any) {
      dispatch(getDeleteChecksFailure(err.toString()));
    }
  };

export const fetchDeleteChecksByIssue =
  (accessToken: String, issueID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getDeleteChecksStart());
      const deleteChecks = await getDeleteChecksByIssue(accessToken, issueID);
      dispatch(getDeleteChecksSuccess(deleteChecks));
    } catch (err: any) {
      dispatch(getDeleteChecksFailure(err.toString()));
    }
  };

export const fetchDeleteChecksByGroup =
  (accessToken: String, groupID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getDeleteChecksStart());
      const deleteChecks = await getDeleteChecksByGroup(accessToken, groupID);
      dispatch(getDeleteChecksSuccess(deleteChecks));
    } catch (err: any) {
      dispatch(getDeleteChecksFailure(err.toString()));
    }
  };

export const fetchDeleteChecksByInteraction =
  (accessToken: String, interactionID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getDeleteChecksStart());
      const deleteChecks = await getDeleteChecksByInteraction(
        accessToken,
        interactionID
      );
      dispatch(getDeleteChecksSuccess(deleteChecks));
    } catch (err: any) {
      dispatch(getDeleteChecksFailure(err.toString()));
    }
  };

export const fetchDeleteChecksByGrievance =
  (accessToken: String, grievanceID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getDeleteChecksStart());
      const deleteChecks = await getDeleteChecksByGrievance(
        accessToken,
        grievanceID
      );
      dispatch(getDeleteChecksSuccess(deleteChecks));
    } catch (err: any) {
      dispatch(getDeleteChecksFailure(err.toString()));
    }
  };

export const fetchDeleteChecksByCommitment =
  (accessToken: String, commitmentID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getDeleteChecksStart());
      const deleteChecks = await getDeleteChecksByCommitment(
        accessToken,
        commitmentID
      );
      dispatch(getDeleteChecksSuccess(deleteChecks));
    } catch (err: any) {
      dispatch(getDeleteChecksFailure(err.toString()));
    }
  };

export const fetchDeleteChecksByCommitmentSource =
  (accessToken: String, commitmentSourceID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getDeleteChecksStart());
      const deleteChecks = await getDeleteChecksByCommitmentSource(
        accessToken,
        commitmentSourceID
      );
      dispatch(getDeleteChecksSuccess(deleteChecks));
    } catch (err: any) {
      dispatch(getDeleteChecksFailure(err.toString()));
    }
  };

export const fetchDeleteChecksByTier =
  (accessToken: String, tierID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getDeleteChecksStart());
      const deleteChecks = await getDeleteChecksByTier(accessToken, tierID);
      dispatch(getDeleteChecksSuccess(deleteChecks));
    } catch (err: any) {
      dispatch(getDeleteChecksFailure(err.toString()));
    }
  };

export const fetchDeleteChecksByProjectTypes =
  (accessToken: String, projectTypesID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getDeleteChecksStart());
      const deleteChecks = await getDeleteChecksByProjectTypes(
        accessToken,
        projectTypesID
      );
      dispatch(getDeleteChecksSuccess(deleteChecks));
    } catch (err: any) {
      dispatch(getDeleteChecksFailure(err.toString()));
    }
  };

export const fetchDeleteChecksByTag =
  (accessToken: String, tagID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getDeleteChecksStart());
      const deleteChecks = await getDeleteChecksByTag(accessToken, tagID);
      dispatch(getDeleteChecksSuccess(deleteChecks));
    } catch (err: any) {
      dispatch(getDeleteChecksFailure(err.toString()));
    }
  };

export const fetchDeleteChecksByRegion =
  (accessToken: String, regionID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getDeleteChecksStart());
      const deleteChecks = await getDeleteChecksByRegion(accessToken, regionID);
      dispatch(getDeleteChecksSuccess(deleteChecks));
    } catch (err: any) {
      dispatch(getDeleteChecksFailure(err.toString()));
    }
  };

export const fetchDeleteChecksByPhase =
  (accessToken: String, phaseID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getDeleteChecksStart());
      const deleteChecks = await getDeleteChecksByPhase(accessToken, phaseID);
      dispatch(getDeleteChecksSuccess(deleteChecks));
    } catch (err: any) {
      dispatch(getDeleteChecksFailure(err.toString()));
    }
  };

export const fetchDeleteChecksByThread =
  (accessToken: String, threadID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getDeleteChecksStart());
      const deleteChecks = await getDeleteChecksByThread(accessToken, threadID);
      dispatch(getDeleteChecksSuccess(deleteChecks));
    } catch (err: any) {
      dispatch(getDeleteChecksFailure(err.toString()));
    }
  };

export const fetchDeleteChecksByEthnicity =
  (accessToken: String, ethnicityID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getDeleteChecksStart());
      const deleteChecks = await getDeleteChecksByEthnicity(
        accessToken,
        ethnicityID
      );
      dispatch(getDeleteChecksSuccess(deleteChecks));
    } catch (err: any) {
      dispatch(getDeleteChecksFailure(err.toString()));
    }
  };

export const fetchDeleteChecksByCustomEmpCategory =
  (accessToken: String, customEmpCategoryID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getDeleteChecksStart());
      const deleteChecks = await getDeleteChecksByCustomEmpCategory(
        accessToken,
        customEmpCategoryID
      );
      dispatch(getDeleteChecksSuccess(deleteChecks));
    } catch (err: any) {
      dispatch(getDeleteChecksFailure(err.toString()));
    }
  };
