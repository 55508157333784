import axiosClient from "api/axiosClient";

export interface EngagementLevel {
  MonthSequenceID: number;
  YearNumber: number;
  MonthNumber: number;
  MonthName: string;
  InteractionCount: number;
}

export interface EngagementLevelsResult {
  engagementLevels: EngagementLevel[];
}

export async function getEngagementLevels(
  accessToken: String
): Promise<EngagementLevelsResult> {
  const url = `/charts/engagementLevel`;

  try {
    const { data } = await axiosClient.get<EngagementLevelsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
