import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

i18next.use(initReactI18next).use(Backend);
// .init({
//   lng: "en", // if using a language detector, do not define the lng option
//   fallbackLng: "en",
//   backend: {
//     loadPath: "/locales/{{lng}}/{{ns}}.json",
//     allowMultiLoading: true,
//   },
//   debug: true,
//   interpolation: {
//     escapeValue: false, // not needed for react as it escapes by default
//   },
//   partialBundledLanguages: true,
//   //   resources,
//   //   defaultNS,
// });

export default i18next;
