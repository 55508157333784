import axiosClient from "../axiosClient";

export interface Thread {
  ThreadID: number;
  ThreadTitle: string;
  Summary: string;
  ProjectID: number;
  ProjectName?: string;
  InteractionCount?: number;
  interactions?: any;
  expanded?: boolean;
  DisplayValue?: string;
  Contacts?: string;
  Groups?: string;
  lastInteractionDate: Date;
  EmailContent?: string;
}

export interface ThreadsResult {
  threads: Thread[];
}

export async function getThreads(accessToken: String): Promise<ThreadsResult> {
  const url = `/thread`;

  try {
    const { data } = await axiosClient.get<ThreadsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getThreadsByProject(
  accessToken: String,
  projectID: number
): Promise<ThreadsResult> {
  const url = `/thread/project/${projectID}`;

  try {
    const { data } = await axiosClient.get<ThreadsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createThread(
  accessToken: String,
  newThread: Partial<Thread>
): Promise<Thread> {
  const url = `/thread`;

  try {
    const { data } = await axiosClient.post<Thread>(url, newThread, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export const generateThreadSummaryWithAi = async (
  accessToken: string,
  thread: Partial<Thread>
): Promise<string> => {
  const url = `/thread/${thread.ThreadID}/summarize`;

  try {
    const { data } = await axiosClient.post<string>(url, thread, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
};

export async function updateThread(
  accessToken: String,
  threadID: number,
  newThread: Partial<Thread>
): Promise<Thread> {
  const url = `/thread/` + threadID;

  try {
    const { data } = await axiosClient.post<Thread>(url, newThread, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteThread(
  accessToken: String,
  threadID: number
): Promise<string> {
  const url = `/thread/` + threadID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
