import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchClientPayments } from "./PaymentSlice";
import { IppInventory } from "components/Inventory/IppInventory";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";
import { ConvertDateOffset } from "../../../utils/DateFunctions";
import { useTypedTranslation } from "utils/customHooks";

interface paymentInvProps {
  companyID?: number;
}

export const PaymentInventoryPage = (props: paymentInvProps) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  const compID = props.companyID;

  const { ft_ben_Contract } = useSelector((state: RootState) => state.client);

  const {
    paymentList,
    isLoading,
    error: paymentsError,
    paymentsById,
  } = useSelector((state: RootState) => state.payments);

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const payments = paymentList.map((id) => paymentsById[id]);

  const pmts = payments.map((p) => {
    let newPayment = Object.assign({}, p) as any;

    newPayment.PaymentDate = ConvertDateOffset(p.PaymentDate);

    return newPayment;
  });

  const pmtCols = [
    {
      field: "SequenceID",
      title: "ID",
      filter: "text",
      format: "DS-{0:0}",
      columnWidth: 150,
    },
    { field: "CompanyName", title: t("objBen:objects.payment.fields.paidto") },
    {
      field: "PaymentDate",
      title: t("objPlt:platformwide.fields.date"),
      filter: "date",
      format: "{0:d}",
      columnWidth: 125,
    },
    {
      field: "PaymentAmount",
      title: t("objBen:objects.payment.fields.amount"),
      format: "{0:c}",
      filter: "numeric",
      columnWidth: 125,
    },
    { field: "ProjectName", title: t("objPlt:objects.project.name") },
    {
      field: "ProjectComponentName",
      title: t("objPlt:objects.projectcomponent.name"),
    },
    ...(ft_ben_Contract
      ? [
          {
            field: "ContractTitle",
            title: t("objBen:objects.contract.name"),
          },
        ]
      : []),
  ];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchClientPayments(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [compID, dispatch, getAccessTokenSilently]);

  if (paymentsError) {
    return <ErrorPage errMsg={paymentsError} />;
  }

  let paymentInvView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <IppInventory
        title={t("objBen:objects.payment.directspend")}
        tableData={pmts}
        idField="PaymentID"
        columns={pmtCols}
        isFiltered={true}
        showDetail={true}
        buttonProps={{
          showAdd: true,
          showTemplate: true,
          templateType: "directpayment",
          showUpload: !!currentProfile.IsClient,
        }}
        sortColumn={2}
        sortReverse={true}
        searchByColumn={"CompanyName"}
        linkColumn={0}
        searchByPlaceholder={t("strGen:filters.searchfor", {
          fieldname: t("objBen:objects.company.fields.companyname"),
        })}
      />
    </div>
  );

  return <div id="payment-inventory-page">{paymentInvView}</div>;
};
