import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import { fetchCommitmentGroupSummarys } from "./CommitmentGroupSummarySlice";
import { IppSkeleton } from "components/IppSkeleton";

export const CommitmentsByGroupChart = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const {
    commitmentGroupSummaryList,
    commitmentGroupSummarysById,
    isLoading: cmIsLoading,
    error: cmError,
  } = useSelector((state: RootState) => state.commitmentGroupSummarys);

  const commitmentGroupSummarys = commitmentGroupSummaryList.map(
    (id) => commitmentGroupSummarysById[id]
  );

  const categories = commitmentGroupSummarys.map((item) => {
    let newItem =
      item.GroupName.length > 30
        ? item.GroupName.substring(0, 30) + "..."
        : item.GroupName;
    return newItem;
  });

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchCommitmentGroupSummarys(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [getAccessTokenSilently, dispatch]);

  let commitmentChart = cmIsLoading ? (
    <IppSkeleton height={350} />
  ) : (
    <div>
      <Chart style={{ height: 350 }}>
        {/* <ChartTitle text="Commitments by Group" /> */}

        <ChartLegend position="bottom" />

        <ChartCategoryAxis>
          <ChartCategoryAxisItem categories={categories} />
        </ChartCategoryAxis>

        <ChartValueAxis>
          <ChartValueAxisItem title={{ text: "Commitments" }} />
        </ChartValueAxis>

        <ChartSeries>
          <ChartSeriesItem
            data={commitmentGroupSummarys}
            type="bar"
            stack={true}
            field="Completed"
            name="Completed"
            tooltip={{ visible: true, format: "Completed: {0:n0}" }}
          />
          <ChartSeriesItem
            data={commitmentGroupSummarys}
            type="bar"
            field="Outstanding"
            name="Outstanding"
            tooltip={{ visible: true, format: "Outstanding: {0:n0}" }}
          />
        </ChartSeries>
        <ChartLegend position="bottom" />
      </Chart>
    </div>
  );

  return <div id="commitment-chart-form">{commitmentChart}</div>;
};
