import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CommunityInvestmentType,
  CommunityInvestmentTypesResult,
  getCommunityInvestmentTypes,
} from "api/communityInvestmentTypeAPI";
import { AppThunk } from "app/store";

interface CommunityInvestmentTypeState {
  communityInvestmentTypesById: Record<number, CommunityInvestmentType>;
  communityInvestmentTypeList: number[];
  isLoading: boolean;
  error: string | null;
}

const CommunityInvestmentTypeInitialState: CommunityInvestmentTypeState = {
  communityInvestmentTypesById: {},
  communityInvestmentTypeList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: CommunityInvestmentTypeState) {
  state.isLoading = true;
}

function loadingFailed(
  state: CommunityInvestmentTypeState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const communityInvestmentTypes = createSlice({
  name: "communityinvestmenttype",
  initialState: CommunityInvestmentTypeInitialState,
  reducers: {
    getCommunityInvestmentTypesStart: startLoading,
    getCommunityInvestmentTypesSuccess(
      state,
      { payload }: PayloadAction<CommunityInvestmentTypesResult>
    ) {
      const { communityInvestmentTypes } = payload;
      // console.log("PAYLOAD", communityInvestmentTypes);
      state.isLoading = false;
      state.error = null;

      communityInvestmentTypes.forEach((communityInvestmentType) => {
        state.communityInvestmentTypesById[
          communityInvestmentType.CommunityInvestmentTypeID
        ] = communityInvestmentType;
      });

      state.communityInvestmentTypeList = communityInvestmentTypes.map(
        (communityInvestmentType) =>
          communityInvestmentType.CommunityInvestmentTypeID
      );
    },
    getCommunityInvestmentTypesFailure: loadingFailed,
  },
});

export const {
  getCommunityInvestmentTypesStart,
  getCommunityInvestmentTypesSuccess,
  getCommunityInvestmentTypesFailure,
} = communityInvestmentTypes.actions;

export default communityInvestmentTypes.reducer;

export const fetchCommunityInvestmentTypes =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCommunityInvestmentTypesStart());
      const communityInvestmentTypes = await getCommunityInvestmentTypes(
        accessToken
      );
      //console.log("RETURN", communityInvestmentTypes);
      dispatch(getCommunityInvestmentTypesSuccess(communityInvestmentTypes));
    } catch (err: any) {
      dispatch(getCommunityInvestmentTypesFailure(err.toString()));
    }
  };
