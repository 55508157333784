import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CustomRnD,
  CustomRnDsResult,
  getAllCustomRnDs,
  createCustomRnD,
  updateCustomRnD,
  deleteCustomRnD,
} from "api/benefits/customRnDAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import * as Constants from "utils/snackBarConstants";
interface CustomRnDState {
  customRnDsById: Record<number, CustomRnD>;
  customRnDList: number[];
  isLoading: boolean;
  isLoadingAdd: boolean;
  error: string | null;
}

const CustomRnDInitialState: CustomRnDState = {
  customRnDsById: {},
  customRnDList: [],
  isLoading: false,
  isLoadingAdd: false,
  error: null,
};

function startLoading(state: CustomRnDState) {
  state.isLoading = true;
}

function startLoadingAdd(state: CustomRnDState) {
  state.isLoadingAdd = true;
}

function loadingFailed(state: CustomRnDState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const customRnD = createSlice({
  name: "customRnD",
  initialState: CustomRnDInitialState,
  reducers: {
    getCustomRnDsStart: startLoading,
    getCustomRnDsSuccess(state, { payload }: PayloadAction<CustomRnDsResult>) {
      const { customRnDs } = payload;
      state.isLoading = false;
      state.error = null;

      customRnDs?.forEach((customRnD) => {
        state.customRnDsById[customRnD.CustomRnDID] = customRnD;
      });

      state.customRnDList = customRnDs?.map(
        (customRnD) => customRnD.CustomRnDID
      );
    },
    getCustomRnDsFailure: loadingFailed,
    createCustomRnDStart: startLoadingAdd,
    createCustomRnDSuccess(state, { payload }: PayloadAction<CustomRnD>) {
      const { CustomRnDID } = payload;
      state.customRnDsById[CustomRnDID] = payload;
      state.customRnDList.push(CustomRnDID);

      state.isLoading = false;
      state.isLoadingAdd = false;
      state.error = null;
    },
    updateCustomRnDStart: startLoading,
    updateCustomRnDSuccess(state, { payload }: PayloadAction<CustomRnD>) {
      const { CustomRnDID } = payload;

      state.customRnDsById[CustomRnDID] = payload;

      state.isLoading = false;
      state.error = null;
    },
    deleteCustomRnDSuccess(state, { payload }: PayloadAction<number>) {
      const RnDID = payload;
      delete state.customRnDsById[RnDID];
      state.customRnDList = state.customRnDList.filter(
        (item) => item !== RnDID
      );
      state.isLoading = false;
      state.error = null;
    },
    createCustomRnDFailure: loadingFailed,
  },
});

export const {
  getCustomRnDsStart,
  getCustomRnDsSuccess,
  getCustomRnDsFailure,
  createCustomRnDStart,
  createCustomRnDSuccess,
  updateCustomRnDStart,
  updateCustomRnDSuccess,
  deleteCustomRnDSuccess,
  createCustomRnDFailure,
} = customRnD.actions;

export default customRnD.reducer;

export const fetchAllCustomRnDs =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCustomRnDsStart());
      const customRnDs = await getAllCustomRnDs(accessToken);
      dispatch(getCustomRnDsSuccess(customRnDs));
    } catch (err: any) {
      console.log(err);
      dispatch(getCustomRnDsFailure(err.toString()));
    }
  };

export const addCustomRnD =
  (
    accessToken: String,
    newCustomRnD: Partial<CustomRnD>,
    setReturnRoute: boolean,
    isTokenCreate?: boolean
  ): AppThunk =>
  async (dispatch) => {
    console.log(newCustomRnD);
    try {
      dispatch(createCustomRnDStart());
      const customRnD = await createCustomRnD(accessToken, newCustomRnD);
      dispatch(createCustomRnDSuccess(customRnD));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      if (!isTokenCreate) {
        dispatch(fetchAllCustomRnDs(accessToken));
      }
      if (setReturnRoute) {
        dispatch(push(`/benefits/customrnds/${customRnD.CustomRnDID}`));
      }
    } catch (err: any) {
      console.log(err);
      dispatch(createCustomRnDFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updCustomRnD =
  (
    accessToken: String,
    customRnDID: number,
    newCustomRnD: Partial<CustomRnD>,
    setReturnRoute: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateCustomRnDStart());
      const customRnD = await updateCustomRnD(
        accessToken,
        customRnDID,
        newCustomRnD
      );
      dispatch(updateCustomRnDSuccess(customRnD));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(push(`/benefits/customrnds/${customRnD.CustomRnDID}`));
      }
    } catch (err: any) {
      dispatch(createCustomRnDFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delCustomRnD =
  (accessToken: String, customRnDID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCustomRnDStart());
      const result = await deleteCustomRnD(accessToken, customRnDID);
      dispatch(deleteCustomRnDSuccess(customRnDID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
      dispatch(push("/benefits/customrnds"));
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(createCustomRnDFailure(error.response.data));
        dispatch(openSnackBar(error.response.data.message, "error"));
      } else {
        dispatch(createCustomRnDFailure(error.toString()));
        dispatch(openSnackBar(Constants.FAILED, "error"));
      }
    }
  };
