import React, { useState, useEffect } from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import {
  IconButton,
  IconButtonProps,
  PaletteColor,
  Tooltip,
} from "@mui/material";
import { LinkProps } from "react-router-dom";

export interface IppButtonProps extends ButtonProps {
  disableTime?: number;
  noTimeout?: boolean;
  component?: React.ElementType<LinkProps>;
  to?: LinkProps["to"];
  tooltip?: string;
  isIconButton?: boolean;
}

export const IppButton: React.FC<IppButtonProps> = ({
  disableTime = 2000, // Default disable time set to 2 seconds
  noTimeout,
  onClick,
  disabled = false,
  type = "button",
  color = "secondary",
  variant = "contained",
  tooltip,
  isIconButton = false,
  ...props
}) => {
  const theme = useTheme();
  const [isTimedout, setIsTimedout] = useState<boolean>(false);

  // Handle effective disabled state
  const isDisabled = disabled || isTimedout;

  // Compute effective color from theme
  const buttonColor = theme.palette[color as keyof typeof theme.palette]
    ? (theme.palette[color as keyof typeof theme.palette] as PaletteColor).main
    : theme.palette.secondary.main; // Default to "secondary" color if not found

  // Conditional styling based on button variant
  const disabledStyle =
    variant === "outlined"
      ? {
          border: `1px solid ${buttonColor}`,
          color: buttonColor,
          backgroundColor: "transparent",
        }
      : {
          color: theme.palette.getContrastText(buttonColor),
          backgroundColor: buttonColor,
        };

  // Effect to manage timeout disabling
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;
    if (isTimedout) {
      timeout = setTimeout(() => setIsTimedout(false), disableTime);
    }

    // Cleanup to clear timeout if component unmounts
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isTimedout, disableTime]);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (isDisabled) return;

    if (!noTimeout) setIsTimedout(true);
    onClick?.(event);

    if (type === "submit") {
      const form = event.currentTarget.closest("form");
      form?.requestSubmit();
    }
  };

  const button = isIconButton ? (
    <IconButton
      {...(props as IconButtonProps)}
      onClick={handleClick}
      disabled={isDisabled}
      color={color}
      sx={{
        ...props.sx,
        "&.Mui-disabled": {
          ...(isTimedout && {
            ...disabledStyle,
            opacity: 1,
            pointerEvents: "none",
            "&:hover": {
              backgroundColor:
                variant === "outlined" ? "transparent" : buttonColor,
            },
          }),
        },
      }}
    >
      {props.children}
    </IconButton>
  ) : (
    <Button
      {...(props as ButtonProps)}
      onClick={handleClick}
      disabled={isDisabled}
      type={type}
      color={color}
      variant={variant}
      sx={{
        ...props.sx,
        "&.Mui-disabled": {
          ...(isTimedout && {
            ...disabledStyle,
            opacity: 1,
            pointerEvents: "none",
            "&:hover": {
              backgroundColor:
                variant === "outlined" ? "transparent" : buttonColor,
            },
          }),
        },
      }}
    >
      {props.children}
    </Button>
  );

  return tooltip ? (
    <Tooltip title={tooltip}>
      {/* span required to show tooltip on disabled button */}
      <span>{button}</span>
    </Tooltip>
  ) : (
    button
  );
};
