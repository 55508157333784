import axiosClient from "../axiosClient";

export interface ContactInteraction {
  ContactInteractionID: number;
  ContactID: number;
  InteractionID: number;
  InteractionProjectID: number;
  ProjectName: string;
  PrimaryContact: boolean;
  ContactName: string;
  FirstName: string;
  Surname: string;
  InteractionTitle: string;
  InteractionDate: Date;
  ClientID: number;
  InteractionTypeName: string;
  InteractionDescription: string;
  SentimentLevel: number;
  CommunicationID: number;
}

export interface ContactInteractionsResult {
  contactInteractions: ContactInteraction[];
}

export async function getContactInteractions(
  accessToken: String
): Promise<ContactInteractionsResult> {
  const url = `/contactInteraction`;

  try {
    const { data } = await axiosClient.get<ContactInteractionsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getContactInteractionsByInteraction(
  accessToken: String,
  interactionID: number
): Promise<ContactInteractionsResult> {
  const url = `/contactInteraction/interaction/${interactionID}`;

  try {
    const { data } = await axiosClient.get<ContactInteractionsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getContactInteractionsByContact(
  accessToken: String,
  contactID: number
): Promise<ContactInteractionsResult> {
  const url = `/contactInteraction/contact/${contactID}`;

  try {
    const { data } = await axiosClient.get<ContactInteractionsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createContactInteraction(
  accessToken: String,
  newCommInv: Partial<ContactInteraction>
): Promise<ContactInteraction> {
  const url = `/contactInteraction`;

  try {
    const { data } = await axiosClient.post<ContactInteraction>(
      url,
      newCommInv,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

// COMMENTED OUT FOR FUTURE CLEANUP - HANDLED WITH INTERSECTION RECORD
// export async function updateContactInteraction(
//   accessToken: String,
//   commInvID: number,
//   newCommInv: Partial<ContactInteraction>
// ): Promise<ContactInteraction> {
//   const url = `/contactInteraction/` + commInvID;

//   try {
//     const { data } = await axiosClient.post<ContactInteraction>(
//       url,
//       newCommInv,
//       {
//         headers: {
//           Authorization: `Bearer ${accessToken}`,
//           "Content-Type": "application/json",
//         },
//       }
//     );

//     return data;
//   } catch (err: any) {
//     throw err;
//   }
// }

export async function deleteContactInteraction(
  accessToken: String,
  commInvID: number
): Promise<string> {
  const url = `/contactInteraction/` + commInvID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
