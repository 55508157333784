import { Trans } from "react-i18next";
import {
  ConstructedErrors,
  GenericErrorTitlesObject,
} from "utils/types/index.types";
import {
  generateErrorTitle,
  getGenericConstructedErrors,
  getGenericConstructedWarnings,
} from "utils/uploadUtils";

type ContactErrorTitles = GenericErrorTitlesObject & {
  groupCharacterLimit: (count: number) => JSX.Element | "";
  invalidTags: (count: number) => JSX.Element | "";
};

export const generateContactErrorTitles: ContactErrorTitles = {
  ...generateErrorTitle,
  groupCharacterLimit: (count: number) =>
    count > 0 ? (
      <Trans
        i18nKey="strGen:uploaderrors.errors.contact.groupcharacterlimit.title"
        count={count}
      />
    ) : (
      ""
    ),
  invalidTags: (count: number) =>
    count > 0 ? (
      <Trans
        i18nKey="strGen:uploaderrors.errors.contact.invalidtags.title"
        count={count}
      />
    ) : (
      ""
    ),
};

export const getNewContactConstructedErrors: () => ConstructedErrors = () => ({
  ...getGenericConstructedErrors(),
  groupCharacterLimit: {
    count: 0,
    get message() {
      return (
        <Trans
          i18nKey="strGen:uploaderrors.errors.contact.groupcharacterlimit.message"
          count={this.count}
        />
      );
    },
    list: [],
    renderAsList: true,
    showSingleItem: true,
  },
  invalidTags: {
    count: 0,
    get message() {
      return (
        <Trans
          i18nKey="strGen:uploaderrors.errors.contact.invalidtags.message"
          count={this.count}
        />
      );
    },
    list: [],
    renderAsList: true,
    showSingleItem: true,
  },
});

export const getNewContactConstructedWarnings: () => ConstructedErrors =
  () => ({
    ...getGenericConstructedWarnings(),
  });
