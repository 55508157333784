import { Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";

interface HoverTypographyProps {
  text: string;
}

export const IppExpandableTypography = ({ text }: HoverTypographyProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const checkTruncation = () => {
      if (textRef.current) {
        const { scrollWidth, clientWidth } = textRef.current;
        setIsTruncated(scrollWidth > clientWidth);
      }
    };

    checkTruncation();
    window.addEventListener("resize", checkTruncation);

    return () => window.removeEventListener("resize", checkTruncation);
  }, [text]);

  const handleClick = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div
      style={{
        maxWidth: "90%",
        overflow: "hidden",
        cursor: isTruncated ? "pointer" : "default",
        position: "relative",
        paddingBottom: !isExpanded && isTruncated ? "1.5em" : 0, // Add space for "Click to view more"
      }}
      onClick={handleClick}
    >
      <Typography
        color="primary"
        ref={textRef}
        style={{
          whiteSpace: isExpanded ? "normal" : "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          margin: 0,
          transition: "max-height 0.3s ease",
        }}
      >
        {text}
      </Typography>
      {!isExpanded && isTruncated && (
        <Typography
          color="primary"
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          Click to view more
        </Typography>
      )}
    </div>
  );
};
