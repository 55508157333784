import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { IppButton } from "./Buttons/IppButton";
import { useTypedTranslation } from "utils/customHooks";

// Define props interface
interface IppChangeWarnProps {
  open: boolean;
  fieldName: string; //field that is changed
  itemName?: string; //affected items due to field change
  onConfirm: () => void; //proceed the change
  onRevert: () => void; //revert the field change
  children?: any;
}

const IppChangeWarn: React.FC<IppChangeWarnProps> = ({
  open,
  fieldName,
  itemName,
  onConfirm,
  onRevert,
  children,
}) => {
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);

  return (
    <Dialog open={open}>
      <DialogTitle>
        {t("strGen:components.ippchangewarn.title", {
          fieldname: fieldName,
        })}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          {t("strGen:components.ippchangewarn.unlinkitems", {
            itemname: itemName,
            fieldname: fieldName,
          })}
        </Typography>
        {children}
        <Typography variant="body2">
          {t("strGen:components.ippchangewarn.revertchange", {
            fieldname: fieldName,
          })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <IppButton color={"secondary"} onClick={onConfirm}>
          {t("strGen:buttons.confirm")}
        </IppButton>
        <IppButton color={"secondary"} variant="outlined" onClick={onRevert}>
          {t("strGen:buttons.revert")}
        </IppButton>
      </DialogActions>
    </Dialog>
  );
};

export default IppChangeWarn;
