import { SupplierForm } from "./SupplierForm";

export const SupplierCreatePage = () => {
  let SupplierCreateView = (
    <div>
      <SupplierForm />
    </div>
  );

  return <div id="Supplier-detail-page">{SupplierCreateView}</div>;
};
