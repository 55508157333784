import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import * as Constants from "utils/snackBarConstants";

import {
  AssetPercentage,
  AssetPercentagesResult,
  getAssetPercentages,
  getAssetPercentagesByAsset,
  createAssetPercentage,
  updateAssetPercentage,
  deleteAssetPercentage,
  DisplayAssetPercentage,
} from "api/benefits/assetPercentageAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import { fetchAssets } from "./AssetSlice";

interface AssetPercentageState {
  assetPercentagesById: Record<number, AssetPercentage>;
  assetPercentageList: number[];
  isLoading: boolean;
  error: string | null;
}

const AssetPercentageInitialState: AssetPercentageState = {
  assetPercentagesById: {},
  assetPercentageList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: AssetPercentageState) {
  state.isLoading = true;
}

function loadingFailed(
  state: AssetPercentageState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const assetPercentages = createSlice({
  name: "assetPercentages",
  initialState: AssetPercentageInitialState,
  reducers: {
    getAssetPercentagesStart: startLoading,
    getAssetPercentagesSuccess(
      state,
      { payload }: PayloadAction<AssetPercentagesResult>
    ) {
      const { assetPercentages } = payload;
      state.isLoading = false;
      state.error = null;

      assetPercentages.forEach((assetPercentage: AssetPercentage) => {
        state.assetPercentagesById[assetPercentage.AssetPercentageID] =
          assetPercentage;
      });

      state.assetPercentageList = assetPercentages.map(
        (assetPercentage: any) => assetPercentage.AssetPercentageID
      );
    },
    getAssetPercentagesFailure: loadingFailed,
    createAssetPercentageStart: startLoading,
    createAssetPercentageSuccess(
      state,
      { payload }: PayloadAction<AssetPercentage>
    ) {
      const { AssetPercentageID } = payload;
      state.assetPercentagesById[AssetPercentageID] = payload;
      state.assetPercentageList.push(AssetPercentageID);

      state.isLoading = false;
      state.error = null;
    },
    updateAssetPercentageSuccess(
      state,
      { payload }: PayloadAction<AssetPercentage>
    ) {
      const { AssetPercentageID } = payload;
      state.assetPercentagesById[AssetPercentageID] = payload;
      state.assetPercentageList.push(AssetPercentageID);

      state.isLoading = false;
      state.error = null;
    },
    deleteAssetPercentageSuccess(state, { payload }: PayloadAction<number>) {
      const AssetPercentageID = payload;
      delete state.assetPercentagesById[AssetPercentageID];
      state.assetPercentageList = state.assetPercentageList.filter(
        (item) => item !== AssetPercentageID
      );

      state.isLoading = false;
      state.error = null;
    },
    createAssetPercentageFailure: loadingFailed,
  },
});

export const {
  getAssetPercentagesStart,
  getAssetPercentagesSuccess,
  getAssetPercentagesFailure,
  createAssetPercentageStart,
  createAssetPercentageSuccess,
  updateAssetPercentageSuccess,
  deleteAssetPercentageSuccess,
  createAssetPercentageFailure,
} = assetPercentages.actions;

export default assetPercentages.reducer;

export const fetchAssetPercentagesByAsset =
  (accessToken: String, assetID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getAssetPercentagesStart());
      const assetPercentages = await getAssetPercentagesByAsset(
        accessToken,
        assetID
      );
      dispatch(getAssetPercentagesSuccess(assetPercentages));
    } catch (err: any) {
      dispatch(getAssetPercentagesFailure(err.toString()));
    }
  };

export const fetchAssetPercentages =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getAssetPercentagesStart());
      const assetPercentages = await getAssetPercentages(accessToken);
      dispatch(getAssetPercentagesSuccess(assetPercentages));
    } catch (err: any) {
      dispatch(getAssetPercentagesFailure(err.toString()));
    }
  };

export const addAssetPercentage =
  (
    accessToken: string,
    newAssetPercentage: Partial<AssetPercentage>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createAssetPercentageStart());
      const assetPercentage = await createAssetPercentage(
        accessToken,
        newAssetPercentage
      );
      dispatch(createAssetPercentageSuccess(assetPercentage));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(
        fetchAssetPercentagesByAsset(accessToken, assetPercentage.AssetID)
      );
      dispatch(fetchAssets(accessToken));
    } catch (err: any) {
      dispatch(createAssetPercentageFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "success"));
    }
  };

export const updAssetPercentage =
  (
    accessToken: string,
    assetPercentageID: number,
    newAssetPercentage: Partial<DisplayAssetPercentage>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createAssetPercentageStart());

      const { WholePercentage, ...assetPercentageToUpdate } =
        newAssetPercentage;

      const assetPercentage = await updateAssetPercentage(
        accessToken,
        assetPercentageID,
        assetPercentageToUpdate as Partial<AssetPercentage>
      );
      dispatch(updateAssetPercentageSuccess(assetPercentage));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push(`/benefits/assets/${newAssetPercentage.AssetID}`));
      dispatch(fetchAssets(accessToken));
    } catch (err: any) {
      dispatch(createAssetPercentageFailure(err.toString()));
    }
  };

export const delAssetPercentage =
  (accessToken: string, assetPercentageID: number, assetID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createAssetPercentageStart());
      const result = await deleteAssetPercentage(
        accessToken,
        assetPercentageID
      );
      dispatch(deleteAssetPercentageSuccess(assetPercentageID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
      dispatch(fetchAssets(accessToken));
      dispatch(push(`/benefits/assets/${assetID}`));
    } catch (err: any) {
      dispatch(createAssetPercentageFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };
