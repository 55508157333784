import {
  ConstructedErrors,
  GenericErrorTitlesObject,
} from "utils/types/index.types";
import {
  generateErrorTitle,
  getGenericConstructedErrors,
  getGenericConstructedWarnings,
} from "utils/uploadUtils";

type CommitmentErrorTitles = GenericErrorTitlesObject & {};

export const generateCommitmentErrorTitles: CommitmentErrorTitles = {
  ...generateErrorTitle,
};

export const getNewCommitmentConstructedErrors: () => ConstructedErrors =
  () => ({
    ...getGenericConstructedErrors(),
  });

export const getNewCommitmentConstructedWarnings: () => ConstructedErrors =
  () => ({
    ...getGenericConstructedWarnings(),
  });
