import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IndigenousGroup,
  IndigenousGroupsResult,
  getIndigenousGroups,
  getIndigenousGroupsByClient,
} from "api/indigenousGroupAPI";
import { AppThunk } from "app/store";

interface IndigenousGroupState {
  indigenousGroupsById: Record<number, IndigenousGroup>;
  indigenousGroupList: number[];
  isLoading: boolean;
  error: string | null;
}

const IndigenousGroupInitialState: IndigenousGroupState = {
  indigenousGroupsById: {},
  indigenousGroupList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: IndigenousGroupState) {
  state.isLoading = true;
}

function loadingFailed(
  state: IndigenousGroupState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const indigenousGroups = createSlice({
  name: "indigenousgroup",
  initialState: IndigenousGroupInitialState,
  reducers: {
    getIndigenousGroupsStart: startLoading,
    getIndigenousGroupsSuccess(
      state,
      { payload }: PayloadAction<IndigenousGroupsResult>
    ) {
      const { indigenousGroups } = payload;
      // console.log("PAYLOAD", indigenousGroups)
      state.isLoading = false;
      state.error = null;

      indigenousGroups.forEach((indigenousGroup) => {
        state.indigenousGroupsById[indigenousGroup.IndigenousGroupID] =
          indigenousGroup;
      });

      state.indigenousGroupList = indigenousGroups.map(
        (indigenousGroup) => indigenousGroup.IndigenousGroupID
      );
    },
    getIndigenousGroupsFailure: loadingFailed,
  },
});

export const {
  getIndigenousGroupsStart,
  getIndigenousGroupsSuccess,
  getIndigenousGroupsFailure,
} = indigenousGroups.actions;

export default indigenousGroups.reducer;

export const fetchIndigenousGroups =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getIndigenousGroupsStart());
      const indigenousGroups = await getIndigenousGroups(accessToken);
      //console.log("RETURN", indigenousGroups);
      dispatch(getIndigenousGroupsSuccess(indigenousGroups));
    } catch (err: any) {
      dispatch(getIndigenousGroupsFailure(err.toString()));
    }
  };

export const fetchIndigenousGroupsByClient =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getIndigenousGroupsStart());
      const indigenousGroups = await getIndigenousGroupsByClient(accessToken);
      //console.log("RETURN", indigenousGroups);
      dispatch(getIndigenousGroupsSuccess(indigenousGroups));
    } catch (err: any) {
      dispatch(getIndigenousGroupsFailure(err.toString()));
    }
  };
