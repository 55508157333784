import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchCompanies } from "./CompaniesSlice";
import { ConvertDateOffset } from "../../../utils/DateFunctions";
import { IppInventory } from "components/Inventory/IppInventory";
import LoadingIndicator from "components/LoadingIndicator";
import ErrorPage from "components/ErrorPage";
import { IsExtraLargeScreen, IsMedSmall } from "utils/mediaQueries";
import { useTypedTranslation } from "utils/customHooks";
import { fetchRegionsByRecordType } from "../regions/RegionSlice";

export const CompanyInventoryPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  const {
    companyList,
    isLoading,
    error: compError,
    companiesById,
  } = useSelector((state: RootState) => state.companies);

  const {
    clientId,
    ft_ben_Tier,
    ft_ben_Region,
    ft_ben_WorkForce,
    ft_ben_Spend,
    isLoading: clientIsLoading,
  } = useSelector((state: RootState) => state.client);

  const { regionList, regionsById } = useSelector(
    (state: RootState) => state.region
  );

  const regions = ft_ben_Region ? regionList.map((id) => regionsById[id]) : [];
  const hasRegionTypes = !!(ft_ben_Region && regions.length > 0);

  const companies = companyList.map((CompanyID) => companiesById[CompanyID]);

  const data = companies.map((p) => {
    let n = Object.assign({}, p) as any;

    if (p.LastPaymentDate !== null) {
      n.LastPaymentDate = ConvertDateOffset(p.LastPaymentDate);
    } else {
      n.LastPaymentDate = null;
    }

    if (p.LastHoursDate !== null) {
      n.LastHoursDate = ConvertDateOffset(p.LastHoursDate);
    } else {
      n.LastHoursDate = null;
    }

    if (!p.CompanyName) {
      n.CompanyName = "* Missing name";
    }

    return n;
  });

  const tierColumn = ft_ben_Tier
    ? [
        {
          field: "TierName",
          title: t("objBen:objects.tiers.name"),
          columnWidth: 150,
        },
      ]
    : [];

  const empColumn = ft_ben_WorkForce
    ? [
        {
          field: "EmployeeCount",
          title: "Active Employees",
          filter: "numeric",
          format: "{0:n0}",
          columnWidth: IsExtraLargeScreen() ? 200 : 150,
        },
      ]
    : [];

  const actUsersColumn = [
    {
      field: "ActiveUsersCount",
      title: "Active Users",
      filter: "numeric",
      format: "{0:n0}",
      columnWidth: IsExtraLargeScreen() ? 200 : 150,
    },
  ];

  const hoursColumn = ft_ben_WorkForce
    ? [
        {
          field: "LastHoursDate",
          title: "Last Work Hours",
          filter: "date",
          format: "{0:d}",
          columnWidth: IsExtraLargeScreen() ? 200 : 150,
        },
      ]
    : [];

  const regionColumn = hasRegionTypes
    ? [
        {
          field: "RegionName",
          title: t("objBen:objects.company.fields.region.name"),
        },
      ]
    : [];

  const lastSpendDateColumn = ft_ben_Spend
    ? [
        {
          field: "LastPaymentDate",
          title: "Last Spend Date",
          filter: "date",
          format: "{0:d}",
          columnWidth: IsExtraLargeScreen() ? 200 : 150,
        },
      ]
    : [];

  const compCols = [
    { field: "CompanyName", title: "Company" },
    ...tierColumn,
    { field: "TownCity", title: "Town/City" },
    { field: "ProvinceState", title: "Province/State" },
    ...regionColumn,
    ...actUsersColumn,
    ...empColumn,
    ...hoursColumn,
    ...lastSpendDateColumn,
  ];

  useEffect(() => {
    (async () => {
      try {
        if (clientId !== -1) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchCompanies(accessToken, clientId));
          if (ft_ben_Region) {
            dispatch(fetchRegionsByRecordType(accessToken, "Company"));
          }
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  if (compError) {
    return <ErrorPage errMsg={compError} />;
  }

  let compInvView =
    isLoading || clientIsLoading ? (
      <LoadingIndicator />
    ) : (
      <div>
        <IppInventory
          title="Companies"
          tableData={data}
          idField="CompanyID"
          columns={compCols}
          isFiltered={true}
          showDetail={true}
          buttonProps={{
            showAdd: true,
          }}
          searchByColumn={"CompanyName"}
          searchByPlaceholder={"Search by Company Name"}
        />
      </div>
    );

  return <div id="company-inventory-page">{compInvView}</div>;
};
