import { ContactForm } from "./ContactForm";

export const ContactCreatePage = () => {
  let ContactCreateView = (
    <div>
      <ContactForm />
    </div>
  );

  return <div id="Contact-detail-page">{ContactCreateView}</div>;
};
