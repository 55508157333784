import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CommitmentGroupSummary,
  CommitmentGroupSummarysResult,
  getCommitmentGroupSummarys,
} from "api/commitments/homePage/commitmentGroupSummaryAPI";
import { AppThunk } from "app/store";

interface CommitmentGroupSummaryState {
  commitmentGroupSummarysById: Record<number, CommitmentGroupSummary>;
  commitmentGroupSummaryList: number[];
  isLoading: boolean;
  error: string | null;
}

const CommitmentGroupSummaryInitialState: CommitmentGroupSummaryState = {
  commitmentGroupSummarysById: {},
  commitmentGroupSummaryList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: CommitmentGroupSummaryState) {
  state.isLoading = true;
}

function loadingFailed(
  state: CommitmentGroupSummaryState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const commitmentGroupSummarys = createSlice({
  name: "commitmentGroupSummarys",
  initialState: CommitmentGroupSummaryInitialState,
  reducers: {
    getCommitmentGroupSummarysStart: startLoading,
    getCommitmentGroupSummarysSuccess(
      state,
      { payload }: PayloadAction<CommitmentGroupSummarysResult>
    ) {
      const { commitmentGroupSummarys } = payload;
      state.isLoading = false;
      state.error = null;

      commitmentGroupSummarys.forEach((item) => {
        state.commitmentGroupSummarysById[item.GroupID] = item;
      });

      state.commitmentGroupSummaryList = commitmentGroupSummarys.map(
        (item) => item.GroupID
      );
    },
    getCommitmentGroupSummarysFailure: loadingFailed,
  },
});

export const {
  getCommitmentGroupSummarysStart,
  getCommitmentGroupSummarysSuccess,
  getCommitmentGroupSummarysFailure,
} = commitmentGroupSummarys.actions;

export default commitmentGroupSummarys.reducer;

export const fetchCommitmentGroupSummarys =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCommitmentGroupSummarysStart());
      const commitmentGroupSummarys = await getCommitmentGroupSummarys(
        accessToken
      );
      dispatch(getCommitmentGroupSummarysSuccess(commitmentGroupSummarys));
    } catch (err: any) {
      dispatch(getCommitmentGroupSummarysFailure(err.toString()));
    }
  };
