import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  Scheduler,
  SchedulersResult,
  getSchedulers,
  createScheduler,
  updateScheduler,
  deleteScheduler,
} from "api/schedulerAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";

interface SchedulerState {
  schedulersById: Record<number, Scheduler>;
  schedulerList: number[];
  isLoading: boolean;
  subSchedulerisLoading: boolean;
  error: string | null;
}

const SchedulerInitialState: SchedulerState = {
  schedulersById: {},
  schedulerList: [],
  isLoading: false,
  subSchedulerisLoading: false,
  error: null,
};

function startLoading(state: SchedulerState) {
  state.isLoading = true;
}

function startLoadingSub(state: SchedulerState) {
  state.subSchedulerisLoading = true;
}

function loadingFailed(state: SchedulerState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

function loadingSubFailed(
  state: SchedulerState,
  action: PayloadAction<string>
) {
  state.subSchedulerisLoading = false;
  state.error = action.payload;
}

const schedulers = createSlice({
  name: "schedulers",
  initialState: SchedulerInitialState,
  reducers: {
    getSchedulersStart: startLoading,
    getSchedulersSuccess(state, { payload }: PayloadAction<SchedulersResult>) {
      const { schedulers } = payload;
      // console.log("PAYLOAD", schedulers);
      state.isLoading = false;
      state.error = null;

      schedulers.forEach((scheduler) => {
        state.schedulersById[scheduler.SchedulerID] = scheduler;
      });

      state.schedulerList = schedulers.map(
        (scheduler) => scheduler.SchedulerID
      );
    },
    getSchedulersFailure: loadingFailed,
    createSchedulerStart: startLoading,
    createSchedulerSuccess(state, { payload }: PayloadAction<Scheduler>) {
      const { SchedulerID } = payload;
      state.schedulersById[SchedulerID] = payload;
      state.schedulerList.push(SchedulerID);

      state.isLoading = false;
      state.error = null;
    },
    updateSchedulerSuccess(state, { payload }: PayloadAction<Scheduler>) {
      const { SchedulerID } = payload;
      state.schedulersById[SchedulerID] = payload;

      state.isLoading = false;
      state.error = null;
    },
    deleteSchedulerSuccess(state, { payload }: PayloadAction<number>) {
      const SchedulerID = payload;
      delete state.schedulersById[SchedulerID];
      state.schedulerList = state.schedulerList.filter(
        (item) => item !== SchedulerID
      );

      state.isLoading = false;
      state.error = null;
    },
    createSchedulerFailure: loadingFailed,
  },
});

export const {
  getSchedulersStart,
  getSchedulersSuccess,
  getSchedulersFailure,
  createSchedulerStart,
  createSchedulerSuccess,
  updateSchedulerSuccess,
  deleteSchedulerSuccess,
  createSchedulerFailure,
} = schedulers.actions;

export default schedulers.reducer;

export const fetchSchedulers =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getSchedulersStart());
      const schedulers = await getSchedulers(accessToken);
      //console.log("RETURN", schedulers);
      dispatch(getSchedulersSuccess(schedulers));
    } catch (err: any) {
      dispatch(getSchedulersFailure(err.toString()));
    }
  };

export const addScheduler =
  (accessToken: String, newScheduler: Partial<Scheduler>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createSchedulerStart());
      const scheduler = await createScheduler(accessToken, newScheduler);
      dispatch(createSchedulerSuccess(scheduler));
      dispatch(push(`/admin/scheduler/${scheduler.SchedulerID}`));
    } catch (err: any) {
      dispatch(createSchedulerFailure(err.toString()));
    }
  };

export const updScheduler =
  (
    accessToken: String,
    schedulerID: number,
    newScheduler: Partial<Scheduler>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createSchedulerStart());
      const scheduler = await updateScheduler(
        accessToken,
        schedulerID,
        newScheduler
      );
      console.log("UPDRETTHK", scheduler);
      dispatch(updateSchedulerSuccess(scheduler));
      //dispatch(push("/benefits/schedulers/" + schedulerID));
    } catch (err: any) {
      dispatch(createSchedulerFailure(err.toString()));
    }
  };

export const delScheduler =
  (accessToken: String, schedulerID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createSchedulerStart());
      const result = await deleteScheduler(accessToken, schedulerID);
      dispatch(deleteSchedulerSuccess(schedulerID));
      dispatch(push("/admin/scheduler"));
    } catch (err: any) {
      dispatch(createSchedulerFailure(err.toString()));
    }
  };
