import { Delete } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { GridCellProps } from "@progress/kendo-react-grid";
import { IppButton } from "components/Buttons/IppButton";
import { useTypedTranslation } from "utils/customHooks";

interface DeleteCellProps extends GridCellProps {
  idField: string;
  setID: (id: number) => void;
  setDeleteOpen: (open: boolean) => void;
}

export const DeleteCell: React.FC<DeleteCellProps> = (props) => {
  const { idField, setID, setDeleteOpen, dataItem } = props;
  const t = useTypedTranslation(["strGen"]);

  return (
    <td>
      <Tooltip title={t("strGen:buttons.tooltip.deleteentry")}>
        <div
          style={{ cursor: "pointer", color: "blue" }}
          onClick={(e) => {
            setID(dataItem[idField]);
            setDeleteOpen(true);
          }}
        >
          <IppButton variant="text" startIcon={<Delete />} color={"error"} />
        </div>
      </Tooltip>
    </td>
  );
};
