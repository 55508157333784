import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchCompaniesForReview } from "./CompaniesSlice";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";
import { IppInventory } from "components/Inventory/IppInventory";
import { ConvertDateOffset } from "../../../utils/DateFunctions";
import { fetchCategories } from "features/datalists/CategorySlice";
import { useTypedTranslation } from "utils/customHooks";

export const CompanyReviewInventoryPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  const {
    companyList,
    isLoading,
    error: compError,
    companiesById,
  } = useSelector((state: RootState) => state.companies);

  const {
    categoriesById,
    categoryList,
    error: categoryError,
    isLoading: categoryIsLoading,
  } = useSelector((state: RootState) => state.categories);

  const categories = categoryList.map(
    (CategoryID) => categoriesById[CategoryID]
  );

  const {
    clientId,
    ft_ben_Tier,
    ft_ben_BusinessCategory,
    isLoading: clientIsLoading,
  } = useSelector((state: RootState) => state.client);

  const companies = companyList.map((CompanyID) => companiesById[CompanyID]);

  const data = companies.map((p) => {
    let n = Object.assign({}, p) as any;

    if (p.LastPaymentDate !== null) {
      n.LastPaymentDate = ConvertDateOffset(p.LastPaymentDate);
    } else {
      n.LastPaymentDate = null;
    }
    if (p.CategoryID == null || p.CategoryID <= 0 || !p.CategoryID) {
      n.CategoryID = null;
    } else if (categoriesById[p.CategoryID]) {
      n.CategoryID = categoriesById[p.CategoryID].CategoryName;
    } else {
      n.CategoryID = null;
    }

    if (!p.CompanyName) {
      n.CompanyName = "* Missing name";
    }

    return n;
  });

  const tierColumn = ft_ben_Tier
    ? [{ field: "TierName", title: t("objBen:objects.tiers.name") }]
    : [];

  const categoryColumn = ft_ben_BusinessCategory
    ? [
        {
          field: "CategoryID",
          title: t("objBen:objects.company.fields.businesscategory"),
        },
      ]
    : [];

  const compCols = [
    { field: "CompanyName", title: "Company" },
    { field: "VendorID", title: "Vendor ID", filter: "text" },
    ...tierColumn,
    { field: "TownCity", title: "Town/City" },
    { field: "ProvinceState", title: "Province/State" },
    { field: "CountryName", title: "Country" },
    ...categoryColumn,
  ];

  useEffect(() => {
    (async () => {
      try {
        if (clientId !== -1) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchCompaniesForReview(accessToken, clientId));
          dispatch(fetchCategories(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  if (compError) {
    return <ErrorPage errMsg={compError} />;
  }

  let compInvView =
    isLoading || clientIsLoading || categoryIsLoading ? (
      <LoadingIndicator />
    ) : (
      <div>
        <IppInventory
          title="Incomplete Company Profiles"
          tableData={data}
          idField="CompanyID"
          columns={compCols}
          isFiltered={true}
          showDetail={true}
          searchByColumn={"CompanyName"}
          searchByPlaceholder={"Search by Company Name"}
        />
      </div>
    );

  return <div id="company-inventory-page">{compInvView}</div>;
};
