import { FormControlLabel, Switch, SwitchProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const PREFIX = "IppCheckbox";

const classes = {
  root: `${PREFIX}-root`,
  label: `${PREFIX}-label`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    color: theme.palette.primary.main,
  },

  [`& .${classes.label}`]: {
    "&.MuiFormControlLabel-label.Mui-disabled": {
      color: "black",
    },
  },
}));

interface IppSwitchProps {
  id: string;
  value: boolean;
  onChangeFunction: any;
  label: string;
  isEditing: any;
  disabled?: boolean;
  color?: SwitchProps["color"];
}

export const IppSwitch = (props: IppSwitchProps) => {
  const { id, value, onChangeFunction, label, isEditing, disabled, color } =
    props;

  return (
    <Root>
      <FormControlLabel
        control={
          <Switch
            id={id}
            name={id}
            value={value}
            checked={value}
            onChange={onChangeFunction}
            disabled={disabled || !isEditing}
            inputProps={{ "aria-label": "controlled" }}
            color={color}
          />
        }
        label={label}
        classes={{
          label: classes.label,
        }}
      />
    </Root>
  );
};
