import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  Initiative,
  InitiativesResult,
  getInitiatives,
  createInitiative,
  updateInitiative,
  deleteInitiative,
} from "api/benefits/initiativeAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface InitiativeState {
  initiativesById: Record<number, Initiative>;
  initiativeList: number[];
  isLoading: boolean;
  error: string | null;
  errorList: any[] | null;
}

const InitiativeInitialState: InitiativeState = {
  initiativesById: {},
  initiativeList: [],
  isLoading: false,
  error: null,
  errorList: null,
};

function startLoading(state: InitiativeState) {
  state.isLoading = true;
}

function loadingFailed(
  state: InitiativeState,
  action: PayloadAction<{ status: string; message: string; errors: [] }>
) {
  state.isLoading = false;
  state.error = action.payload.message;
  if (action.payload.errors) {
    state.errorList = action.payload.errors;
  }
}

const initiatives = createSlice({
  name: "initiatives",
  initialState: InitiativeInitialState,
  reducers: {
    getInitiativesStart: startLoading,
    getInitiativesSuccess(
      state,
      { payload }: PayloadAction<InitiativesResult>
    ) {
      const { initiatives } = payload;
      // console.log("PAYLOAD", initiatives);
      state.isLoading = false;
      state.error = null;
      state.errorList = null;

      initiatives.forEach((initiative) => {
        state.initiativesById[initiative.InitiativeID] = initiative;
      });

      state.initiativeList = initiatives.map(
        (initiative) => initiative.InitiativeID
      );
    },
    getInitiativesFailure: loadingFailed,
    createInitiativeStart: startLoading,
    createInitiativeSuccess(state, { payload }: PayloadAction<Initiative>) {
      const { InitiativeID } = payload;
      state.initiativesById[InitiativeID] = payload;
      state.initiativeList.push(InitiativeID);

      state.isLoading = false;
      state.error = null;
      state.errorList = null;
    },
    updateInitiativeSuccess(state, { payload }: PayloadAction<Initiative>) {
      const { InitiativeID } = payload;
      state.initiativesById[InitiativeID] = payload;

      state.isLoading = false;
      state.error = null;
      state.errorList = null;
    },
    deleteInitiativeSuccess(state, { payload }: PayloadAction<number>) {
      const InitiativeID = payload;
      delete state.initiativesById[InitiativeID];
      state.initiativeList = state.initiativeList.filter(
        (item) => item !== InitiativeID
      );

      state.isLoading = false;
      state.error = null;
      state.errorList = null;
    },
    createInitiativeFailure: loadingFailed,
  },
});

export const {
  getInitiativesStart,
  getInitiativesSuccess,
  getInitiativesFailure,
  createInitiativeStart,
  createInitiativeSuccess,
  updateInitiativeSuccess,
  deleteInitiativeSuccess,
  createInitiativeFailure,
} = initiatives.actions;

export default initiatives.reducer;

export const fetchInitiatives =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getInitiativesStart());
      const initiatives = await getInitiatives(accessToken);
      //console.log("RETURN", initiatives);
      dispatch(getInitiativesSuccess(initiatives));
    } catch (err: any) {
      dispatch(getInitiativesFailure(err.toString()));
    }
  };

export const addInitiative =
  (accessToken: String, newInitiative: Partial<Initiative>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createInitiativeStart());
      const initiative = await createInitiative(accessToken, newInitiative);
      dispatch(createInitiativeSuccess(initiative));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(fetchInitiatives(accessToken));
      dispatch(push(`/benefits/initiatives/${initiative.InitiativeID}`));
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(openSnackBar(error.response.data.message, "error"));
        dispatch(createInitiativeFailure(error.response.data.message));
      } else {
        dispatch(createInitiativeFailure(error.toString()));
        dispatch(openSnackBar(Constants.FAILED, "error"));
      }
    }
  };

export const updInitiative =
  (
    accessToken: String,
    initiativeID: number,
    newInitiative: Partial<Initiative>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createInitiativeStart());
      const initiative = await updateInitiative(
        accessToken,
        initiativeID,
        newInitiative
      );
      dispatch(updateInitiativeSuccess(initiative));
      dispatch(fetchInitiatives(accessToken));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(openSnackBar(error.response.data.message, "error"));
        dispatch(createInitiativeFailure(error.response.data.message));
      } else {
        dispatch(createInitiativeFailure(error.toString()));
        dispatch(openSnackBar(Constants.FAILED, "error"));
      }
    }
  };

export const delInitiative =
  (accessToken: String, initiativeID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createInitiativeStart());
      const result = await deleteInitiative(accessToken, initiativeID);
      dispatch(deleteInitiativeSuccess(initiativeID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
      dispatch(push(`/benefits/initiatives`));
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(openSnackBar(error.response.data.message, "error"));
        dispatch(createInitiativeFailure(error.response.data.message));
      } else {
        dispatch(createInitiativeFailure(error.toString()));
        dispatch(openSnackBar(Constants.FAILED, "error"));
      }
    }
  };
