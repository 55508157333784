import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { IppSkeleton } from "components/IppSkeleton";
import { ArrowBack, ArrowForward, Lock } from "@mui/icons-material";
import {
  addClientCommitmentStatusType,
  delClientCommitmentStatusType,
  fetchClientCommitmentStatusTypes,
} from "./ClientCommitmentStatusTypeSlice";
import { fetchCommitmentStatusTypes } from "../../../commitments/commitmentStatusType/CommitmentStatusTypeSlice";
import { IppFormDivider } from "components/IppFormDivider";
import { IppSaveButton } from "components/Buttons/IppSaveButton";

const PREFIX = "ClientCommitmentStatusTypeForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
  roleCard: `${PREFIX}-roleCard`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1100,
  },

  [`& .${classes.boxSpace}`]: {
    padding: 10,
  },

  [`& .${classes.roleCard}`]: {
    minWidth: 250,

    minHeight: 300,
  },
}));

// format for display lists
interface listValue {
  itemID: number;
  itemLabel: string;
  wasSelected: boolean; // to track deletions
  isRequired: boolean;
}

export const ClientCommitmentStatusTypeForm = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [pageLoaded, setPageLoaded] = useState(false);

  const [allGroups, setAllGroups] = useState<listValue[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<listValue[]>([]);

  const { clientId } = useSelector((state: RootState) => state.client);

  const {
    clientCommitmentStatusTypeList,
    clientCommitmentStatusTypesById,
    isLoading: clientCommitmentStatusTypesIsLoading,
    error: clientCommitmentStatusTypesError,
  } = useSelector((state: RootState) => state.clientCommitmentStatusTypes);

  const clientCommitmentStatusTypes = clientCommitmentStatusTypeList.map(
    (id) => clientCommitmentStatusTypesById[id]
  );

  const {
    commitmentStatusTypeList,
    commitmentStatusTypesById,
    isLoading: commitmentStatusIsLoading,
    error: commitmentStatusError,
  } = useSelector((state: RootState) => state.commitmentStatusTypes);

  const commitmentStatusTypes = commitmentStatusTypeList.map(
    (id) => commitmentStatusTypesById[id]
  );

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchCommitmentStatusTypes(accessToken));
        dispatch(fetchClientCommitmentStatusTypes(accessToken));
        setPageLoaded(true);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  // make sure lists are initialized only one time
  useEffect(() => {
    if (
      pageLoaded &&
      !commitmentStatusIsLoading &&
      !clientCommitmentStatusTypesIsLoading &&
      allGroups.length === 0
    ) {
      initializeLists();
    }
  }, [
    pageLoaded,
    commitmentStatusIsLoading,
    clientCommitmentStatusTypesIsLoading,
  ]);

  const initializeLists = () => {
    // initialize full list
    let data1: listValue[] = [];

    commitmentStatusTypes.forEach((item) => {
      // check if item is already selected
      const foundItem = clientCommitmentStatusTypes.find((obj) => {
        return obj.CommitmentStatusTypeID === item.CommitmentStatusTypeID;
      });
      if (!foundItem) {
        let n: listValue = {
          itemID: item.CommitmentStatusTypeID,
          itemLabel: item.CommitmentStatusTypeName,
          wasSelected: false,
          isRequired: false,
        };
        data1.push(n);
      }
    });
    setAllGroups(data1);
    //console.log("FILTERED LIST", data1)

    let data2 = clientCommitmentStatusTypes.map((item) => {
      let n: listValue = {
        itemID: item.CommitmentStatusTypeID,
        itemLabel: item.CommitmentStatusTypeName,
        wasSelected: true,
        isRequired: item.IsRequired,
      };
      return n;
    });
    setSelectedGroups(data2);
  };

  const handleSave = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
      },
    });
    // Delete items marked for removal
    // if item was selected and is now in all groups,
    // then item was REMOVED
    allGroups.forEach((item) => {
      if (item.wasSelected) {
        dispatch(delClientCommitmentStatusType(accessToken, item.itemID));
      }
    });

    // insert new items
    selectedGroups.forEach((item) => {
      if (!item.wasSelected) {
        const newItem = {
          ClientID: clientId,
          CommitmentStatusTypeID: item.itemID,
        };
        dispatch(addClientCommitmentStatusType(accessToken, newItem));
      }
    });
    //dispatch(openSnackBar("Updates completed", "success"));
    //dispatch(push("/admin/clientCommitmentStatusTypes"));
  };

  const handleToggleRight = (value: listValue) => () => {
    // add item to full list, and sort it
    setAllGroups((oldArray) =>
      allGroups.concat(value).sort((a, b) => {
        let fa = a.itemLabel.toLowerCase(),
          fb = b.itemLabel.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      })
    );

    // remove item from selected list
    setSelectedGroups(
      selectedGroups.filter(function (a) {
        return a !== value;
      })
    );
  };

  const handleToggleLeft = (value: listValue) => () => {
    // add item to selected list, and sort it
    setSelectedGroups((oldArray) =>
      selectedGroups.concat(value).sort((a, b) => {
        let fa = a.itemLabel.toLowerCase(),
          fb = b.itemLabel.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      })
    );

    // remove item from full list list
    setAllGroups(
      allGroups.filter(function (a) {
        return a !== value;
      })
    );
  };

  let fullList = commitmentStatusIsLoading ? (
    <IppSkeleton height={400} />
  ) : (
    <Paper sx={{ height: 400, overflow: "auto" }}>
      <List dense component="div" role="list">
        {allGroups.map((item) => {
          return (
            <Tooltip title="Click to add item to selected groups">
              <ListItem
                button
                key={item.itemID}
                onClick={handleToggleLeft(item)}
                role="listitem"
              >
                <ListItemIcon>
                  <ArrowForward />
                </ListItemIcon>
                <ListItemText primary={item.itemLabel} />
              </ListItem>
            </Tooltip>
          );
        })}
      </List>
    </Paper>
  );

  let selectedList =
    clientCommitmentStatusTypesIsLoading && commitmentStatusIsLoading ? (
      <IppSkeleton height={400} />
    ) : (
      <Paper sx={{ height: 400, overflow: "auto" }}>
        <List dense component="div" role="list">
          {selectedGroups.map((item) => {
            return item.isRequired ? (
              <Tooltip title="This is a required item and cannot be removed">
                <ListItem button role="listitem" key={item.itemID}>
                  <ListItemIcon>
                    <Lock />
                  </ListItemIcon>
                  <ListItemText primary={item.itemLabel} />
                </ListItem>
              </Tooltip>
            ) : (
              <Tooltip title="Click to remove item from selected list">
                <ListItem
                  button
                  role="listitem"
                  key={item.itemID}
                  onClick={handleToggleRight(item)}
                >
                  <ListItemIcon>
                    <ArrowBack />
                  </ListItemIcon>
                  <ListItemText primary={item.itemLabel} />
                </ListItem>
              </Tooltip>
            );
          })}
        </List>
      </Paper>
    );

  return (
    <Root>
      <Box display="flex" justifyContent="center">
        <Paper className={classes.boxSpace}>
          <Grid container className={classes.editForm} spacing={2}>
            <Grid item xs={9}>
              <Typography variant="h5" component="h1">
                Commitment Status Types
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Grid container justifyContent="flex-end">
                <IppSaveButton handleSave={handleSave} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <IppFormDivider title="Guide" />
              <Typography display="inline">
                This option allows you to select the status types you wish to
                have appear in dropdown lists. Only the items you select will be
                available to users. Selecting a status type from one list will
                move it into the other list. Once you have completed your
                changes, click
                <strong> Save Changes</strong> to finalize them.
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h6">Available Types</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h6">Selected Types</Typography>
            </Grid>
            <Grid item xs={6}>
              {fullList}
            </Grid>
            <Grid item xs={6}>
              {selectedList}
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Root>
  );
};
