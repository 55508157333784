import axiosClient from "./axiosClient";

export interface ClientCommitmentCategory {
  ClientCommitmentCategoryID: number;
  ClientID: number;
  CommitmentCategoryID: number;
  CommitmentCategoryName: string;
}

export interface ClientCommitmentCategoriesResult {
  clientCommitmentCategories: ClientCommitmentCategory[];
}

export async function getClientCommitmentCategories(
  accessToken: String
): Promise<ClientCommitmentCategoriesResult> {
  const url = `/clientCommitmentCategory`;

  try {
    const { data } = await axiosClient.get<ClientCommitmentCategoriesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    // console.log("GETDATA", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createClientCommitmentCategory(
  accessToken: String,
  newClientCommitmentCategory: Partial<ClientCommitmentCategory>
): Promise<ClientCommitmentCategory> {
  const url = `/ClientCommitmentCategory`;

  try {
    const { data } = await axiosClient.post<ClientCommitmentCategory>(
      url,
      newClientCommitmentCategory,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateClientCommitmentCategory(
  accessToken: String,
  ClientCommitmentCategoryID: number,
  newClientCommitmentCategory: Partial<ClientCommitmentCategory>
): Promise<ClientCommitmentCategory> {
  const url = `/ClientCommitmentCategory/` + ClientCommitmentCategoryID;

  try {
    const { data } = await axiosClient.post<ClientCommitmentCategory>(
      url,
      newClientCommitmentCategory,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteClientCommitmentCategory(
  accessToken: String,
  ClientCommitmentCategoryID: number
): Promise<string> {
  const url = `/ClientCommitmentCategory/` + ClientCommitmentCategoryID;
  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    // console.log("DELDATA", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}
