import React from "react";
import { GrievanceForm } from "./GrievanceForm";

export const GrievanceCreatePage = () => {
  let GrievanceCreateView = (
    <div>
      <GrievanceForm />
    </div>
  );

  return <div id="grievance-detail-page">{GrievanceCreateView}</div>;
};
