import { Grid, Typography, IconButton, Box } from "@mui/material";
import { BackspaceOutlined } from "@mui/icons-material";
import { ChangeEvent, useState } from "react";
import { IppTextField } from "./IppTextField";
import { IppAutocomplete } from "./IppAutocomplete";
import {
  convertToAddressArray,
  convertToAddressString,
  markRequired,
} from "utils/functions";
import { FormikTouched } from "formik";
import { IppButton } from "./Buttons/IppButton";
import { useTypedTranslation } from "utils/customHooks";

interface IppDynamicAddressProps {
  id: string;
  options: string[];
  value: string;
  onChangeFunction: (value: any) => void;
  label: string;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  touchedFunction?:
    | boolean
    | FormikTouched<any>
    | FormikTouched<any>[]
    | undefined;
  required?: boolean;
}

interface Municipal {
  province: string;
  district: string;
}

export const IppDynamicAddress = (props: IppDynamicAddressProps) => {
  const {
    options,
    value,
    onChangeFunction,
    label,
    isEditing,
    setIsEditing,
    touchedFunction,
    required,
  } = props;
  const t = useTypedTranslation(["strGen"]);

  const [municipals, setMunicipals] = useState<Municipal[]>(
    convertToAddressArray(value)
  );

  const handleMunicipals = () => {
    setMunicipals([...municipals, { province: "", district: "" }]);
  };

  const handleRemoveMunicipal = (index: number) => {
    const updatedMunicipals = municipals.filter((_, idx) => idx !== index);
    setMunicipals(updatedMunicipals);
  };

  const validateField = (field: "province" | "district", index: number) => {
    const municipal = municipals[index];
    const { province, district } = municipal;

    const isSingleMunicipal = municipals.length === 1;
    const isMultipleMunicipals = municipals.length > 1;

    if (field === "district" && district?.includes(",")) {
      return t(
        "strGen:components.ippdynamicaddress.validation.districtcannotcontaincomma"
      );
    }

    if (isSingleMunicipal && !province && !district) {
      return field === "province"
        ? t("strGen:components.ippdynamicaddress.validation.atleastoneprovince")
        : t(
            "strGen:components.ippdynamicaddress.validation.atleastonedistrict"
          );
    }

    if (isMultipleMunicipals && !province && !district) {
      return t(
        "strGen:components.ippdynamicaddress.validation.provideprovinceanddistrict"
      );
    }

    if (field === "province" && !province && district) {
      return t(
        "strGen:components.ippdynamicaddress.validation.provideprovincefordistrict",
        { district }
      );
    }

    if (field === "district" && !district && province) {
      return t(
        "strGen:components.ippdynamicaddress.validation.providedistrictforprovince",
        { province }
      );
    }

    return null;
  };

  return (
    <>
      <Grid item xs={12} marginY={1}>
        <Typography>{label}</Typography>
      </Grid>
      {municipals.map((address, index) => (
        <Grid container spacing={2} key={index}>
          <Grid item xs={5.5}>
            <IppAutocomplete
              id={`Municipal.${index}.province`}
              options={options}
              value={address.province}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  let data = [...municipals];
                  data[index]["province"] = newValue;
                  setMunicipals(data);
                  onChangeFunction(convertToAddressString(data));
                }
              }}
              touchedFunction={touchedFunction}
              errorFunction={validateField("province", index)}
              label={markRequired(
                t(
                  "strGen:components.ippdynamicaddress.label.registeredprovince"
                ),
                required
              )}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          <Grid item xs={5.5}>
            <IppTextField
              id={`Municipal.${index}.district`}
              label={t("strGen:components.ippdynamicaddress.label.district")}
              value={municipals[index].district}
              onChangeFunction={(event: ChangeEvent<HTMLInputElement>) => {
                let data = [...municipals];
                data[index]["district"] = event.target.value;
                setMunicipals(data);
                onChangeFunction(convertToAddressString(data));
              }}
              touchedExpression={touchedFunction}
              errorsExpression={validateField("district", index)}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          <Grid item xs={1} alignSelf={"center"}>
            <IconButton
              color="error"
              onClick={() => handleRemoveMunicipal(index)}
              disabled={municipals.length > 1 ? false : true}
              size="large"
            >
              <BackspaceOutlined />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <IppButton
          variant="outlined"
          color="primary"
          onClick={() => handleMunicipals()}
          disabled={!isEditing}
        >
          {t("strGen:buttons.addoffices")}
        </IppButton>
      </Box>
    </>
  );
};
