import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchUser } from "../../users/UsersSlice";
import { useParams } from "react-router-dom";
import { UserForm } from "./UserForm";
import LoadingIndicator from "components/LoadingIndicator";
import { useTypedTranslation } from "utils/customHooks";

interface UserDetProps {
  userID: string;
}

export const UserDetailPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "objBen", "strGen"]);

  const {
    isLoading,
    error: userError,
    usersById,
  } = useSelector((state: RootState) => state.users);

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  // get user ID from url param and attempt to find company in store
  const { userID } = useParams<UserDetProps>();
  const uID = parseInt(userID, 10);

  const user = usersById[parseInt(userID, 10)];

  useEffect(() => {
    (async () => {
      try {
        // can avoid going to the DB if we navigated from inventory
        if (!user) {
          if (clientId !== -1) {
            const accessToken = await getAccessTokenSilently({
              authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
              },
            });
            dispatch(fetchUser(accessToken, clientId, uID));
          }
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently, uID, user]);

  if (typeof uID != "number") {
    return (
      <div>
        <h1>{t("strGen:errors.wentwrong")}</h1>
        <div>
          {uID}{" "}
          {t("strGen:errors.wentwrong", {
            fieldname: t("objBen:objects.company.name"),
          })}
        </div>
      </div>
    );
  }

  if (userError) {
    return (
      <div>
        <h1>{t("strGen:errors.wentwrong")}</h1>
        <div>{userError.toString()}</div>
      </div>
    );
  }

  let userDetView =
    isLoading || clientIsLoading ? (
      <LoadingIndicator />
    ) : (
      <div>{user && <UserForm user={user} clientId={clientId} />}</div>
    );

  return <div id="user-detail-page">{userDetView}</div>;
};
