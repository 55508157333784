import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AlertColor } from "@mui/material/Alert";
import { AppThunk } from "app/store";

interface SnackBarState {
  message: String;
  severity: AlertColor;
  open: boolean;
  duration: number | null | undefined;
}

const SnackBarInitialState: SnackBarState = {
  message: "",
  severity: "info",
  open: false,
  duration: 5000,
};

const snackBar = createSlice({
  name: "snackbar",
  initialState: SnackBarInitialState,
  reducers: {
    setSnackBar(state, { payload }: PayloadAction<Partial<SnackBarState>>) {
      const { message, severity, duration } = payload;
      state.message = message || "";
      state.severity = severity || "info";
      state.open = true;
      state.duration = duration;
    },

    closeSnackBar: (state) => {
      state.message = "";
      state.open = false;
      //state.severity = "info";
    },
  },
});

export const { setSnackBar, closeSnackBar } = snackBar.actions;

export default snackBar.reducer;

export const openSnackBar =
  (message: String, severity: AlertColor): AppThunk =>
  async (dispatch) => {
    dispatch(setSnackBar({ message, severity, duration: 5000 }));
  };

export const failedSnackBar =
  (message?: String | "Attempt Failed"): AppThunk =>
  async (dispatch) => {
    dispatch(setSnackBar({ message, severity: "error", duration: 5000 }));
  };

export const warningSnackBar =
  (
    message?: String | "Warning",
    duration?: number | null | undefined
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setSnackBar({ message, severity: "warning", duration }));
  };
