import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  GrievanceIssue,
  GrievanceIssuesResult,
  getGrievanceIssuesByGrievance,
  getGrievanceIssuesByIssue,
  deleteGrievanceIssue,
} from "api/stakeholder/grievanceIssueAPI";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { SnackBarConstants } from "utils/customHooks";

interface GrievanceIssueState {
  grievanceIssuesById: Record<number, GrievanceIssue>;
  grievanceIssueList: number[];
  isLoading: boolean;
  subGrievanceIssueisLoading: boolean;
  error: string | null;
}

const GrievanceIssueInitialState: GrievanceIssueState = {
  grievanceIssuesById: {},
  grievanceIssueList: [],
  isLoading: false,
  subGrievanceIssueisLoading: false,
  error: null,
};

function startLoading(state: GrievanceIssueState) {
  state.isLoading = true;
}

function loadingFailed(
  state: GrievanceIssueState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const grievanceIssues = createSlice({
  name: "grievanceIssues",
  initialState: GrievanceIssueInitialState,
  reducers: {
    getGrievanceIssuesStart: startLoading,
    getGrievanceIssuesSuccess(
      state,
      { payload }: PayloadAction<GrievanceIssuesResult>
    ) {
      const { grievanceIssues } = payload;

      state.isLoading = false;
      state.error = null;

      grievanceIssues.forEach((grievanceIssue) => {
        state.grievanceIssuesById[grievanceIssue.GrievanceIssueID] =
          grievanceIssue;
      });

      state.grievanceIssueList = grievanceIssues.map(
        (grievanceIssue) => grievanceIssue.GrievanceIssueID
      );
    },
    getGrievanceIssuesFailure: loadingFailed,
    createGrievanceIssueStart: startLoading,
    createGrievanceIssueSuccess(
      state,
      { payload }: PayloadAction<GrievanceIssue>
    ) {
      const { GrievanceIssueID } = payload;
      state.grievanceIssuesById[GrievanceIssueID] = payload;
      state.grievanceIssueList.push(GrievanceIssueID);

      state.isLoading = false;
      state.error = null;
    },
    updateGrievanceIssueSuccess(
      state,
      { payload }: PayloadAction<GrievanceIssue>
    ) {
      const { GrievanceIssueID } = payload;

      state.grievanceIssuesById[GrievanceIssueID] = payload;
      //state.grievanceIssueList.push(GrievanceIssueID);

      state.isLoading = false;
      state.error = null;
    },
    deleteGrievanceIssueSuccess(state, { payload }: PayloadAction<number>) {
      const GrievanceIssueID = payload;
      delete state.grievanceIssuesById[GrievanceIssueID];
      state.grievanceIssueList = state.grievanceIssueList.filter(
        (item) => item !== GrievanceIssueID
      );

      state.isLoading = false;
      state.error = null;
    },
    createGrievanceIssueFailure: loadingFailed,
  },
});

export const {
  getGrievanceIssuesStart,
  getGrievanceIssuesSuccess,
  getGrievanceIssuesFailure,
  createGrievanceIssueStart,
  createGrievanceIssueSuccess,
  updateGrievanceIssueSuccess,
  deleteGrievanceIssueSuccess,
  createGrievanceIssueFailure,
} = grievanceIssues.actions;

export default grievanceIssues.reducer;

export const fetchGrievanceIssuesByGrievance =
  (accessToken: String, grievanceID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getGrievanceIssuesStart());
      const grievanceIssues = await getGrievanceIssuesByGrievance(
        accessToken,
        grievanceID
      );
      dispatch(getGrievanceIssuesSuccess(grievanceIssues));
    } catch (err: any) {
      dispatch(getGrievanceIssuesFailure(err.toString()));
    }
  };

export const fetchGrievanceIssuesByIssue =
  (accessToken: String, issueID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getGrievanceIssuesStart());
      const grievanceIssues = await getGrievanceIssuesByIssue(
        accessToken,
        issueID
      );
      dispatch(getGrievanceIssuesSuccess(grievanceIssues));
    } catch (err: any) {
      dispatch(getGrievanceIssuesFailure(err.toString()));
    }
  };

export const delGrievanceIssue =
  (
    accessToken: String,
    grievanceIssueID: number,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createGrievanceIssueStart());
      const result = await deleteGrievanceIssue(accessToken, grievanceIssueID);
      dispatch(deleteGrievanceIssueSuccess(grievanceIssueID));
      dispatch(openSnackBar(snackbarConstants.DELETE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createGrievanceIssueFailure(err.toString()));
      dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
    }
  };
