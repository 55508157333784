import axiosClient from "./axiosClient";

export interface CompanyStructure {
  CompanyStructureID: number;
  CompanyStructureName: string;
}

export interface CompanyStructuresResult {
  companyStructures: CompanyStructure[];
}

export async function getCompanyStructures(
  accessToken: String
): Promise<CompanyStructuresResult> {
  const url = `/companystructure`;

  try {
    const { data } = await axiosClient.get<CompanyStructuresResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
