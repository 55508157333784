import { useParams } from "react-router-dom";

import { UserInventoryPage } from "features/platform/admin/UserInventoryPage";

interface CompUserInvProps {
  companyID: string;
}

export const CompanyUserInvPage = () => {
  const { companyID } = useParams<CompUserInvProps>();
  const compID = parseInt(companyID, 10);
  console.log("COMPANY Id is", compID);

  return (
    <div>
      <UserInventoryPage companyID={compID} />
    </div>
  );
};
