import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Profile, getProfile } from "api/usersAPI";
import {
  UserRole,
  UserRolesResult,
  getCurrentUserRoles,
} from "api/userRoleAPI";
import { AppThunk } from "app/store";

interface ProfileState {
  currentUserRolesById: Record<number, UserRole>;
  currentUserRoleList: number[];
  currentProfile: Profile;
  isLoading: boolean;
  error: string | null;
}

const profileInitialState: ProfileState = {
  currentUserRolesById: {},
  currentUserRoleList: [],
  currentProfile: {} as Profile,
  isLoading: false,
  error: null,
};

function startLoading(state: ProfileState) {
  state.isLoading = true;
}

function loadingFailed(state: ProfileState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const profile = createSlice({
  name: "profile",
  initialState: profileInitialState,
  reducers: {
    getProfileStart: startLoading,
    getProfileSuccess(state, { payload }: PayloadAction<Profile>) {
      state.currentProfile = payload;
      state.isLoading = false;
      state.error = null;
    },
    getUserRolesSuccess(state, { payload }: PayloadAction<UserRolesResult>) {
      const { userRoles } = payload;
      // console.log("PAYLOAD", userRoles)
      state.isLoading = false;
      state.error = null;

      userRoles.forEach((userRole) => {
        state.currentUserRolesById[userRole.UserRoleID] = userRole;
      });

      state.currentUserRoleList = userRoles.map(
        (userRole) => userRole.UserRoleID
      );
    },
    getProfileFailure: loadingFailed,
  },
});

export const {
  getProfileStart,
  getProfileSuccess,
  getUserRolesSuccess,
  getProfileFailure,
} = profile.actions;

export default profile.reducer;

export const fetchProfile =
  (accessToken: String, clientId?: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getProfileStart());
      const users = await getProfile(accessToken);
      dispatch(getProfileSuccess(users));
    } catch (err: any) {
      dispatch(getProfileFailure(err.toString()));
    }
  };

export const fetchCurrentUserRoles =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getProfileStart());
      const userRoles = await getCurrentUserRoles(accessToken);
      dispatch(getUserRolesSuccess(userRoles));
    } catch (err: any) {
      dispatch(getProfileFailure(err.toString()));
    }
  };
