import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { addLinkAttachment } from "../AttachmentSlice";
import { IppTextField } from "components/IppTextField";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { useSnackBarConstants, useTypedTranslation } from "utils/customHooks";
import { IppFormButtons } from "components/Buttons/IppFormButtons";

type FormValues = {
  link: string;
  linkTitle: string;
  description: string;
};

const PREFIX = "AttachmentLinkDialog";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1000,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

interface Props {
  isOpen: boolean;
  handleClose: any;
  recordType: string;
  recordID: number;
  companyID: number;
  projectID: number;
  moduleID: number;
  setIsUploading: (isUploading: boolean) => void;
  objectName?: string;
}

export const AttachmentLinkDialog = (props: Props) => {
  const {
    isOpen,
    handleClose,
    recordType,
    recordID,
    companyID,
    projectID,
    moduleID,
    setIsUploading,
    objectName,
  } = props;
  const dispatch = useDispatch();
  const t = useTypedTranslation(["objPlt", "strGen"]);
  const snackbarConstants = useSnackBarConstants();

  const { getAccessTokenSilently } = useAuth0();

  const closeAttachment = (
    _: React.MouseEvent<HTMLButtonElement>,
    reason?: "escapeKeyDown" | "backdropClick"
  ) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      dispatch(
        openSnackBar(t("strGen:components.snackbar.saveorcancel"), "info")
      );
    } else {
      formik.resetForm();
      handleClose();
    }
  };

  const validationSchema = yup.object<FormValues>({
    link: yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.attachment.fields.linktitle"),
        })
      )
      .max(
        500,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.attachment.fields.linktitle"),
          count: 500,
        })
      ),
    linkTitle: yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.attachment.fields.hyperlink"),
        })
      )
      .max(
        500,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.attachment.fields.hyperlink"),
          count: 500,
        })
      ),
    description: yup
      .string()
      .nullable()
      .max(
        250,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.attachment.fields.description"),
          count: 250,
        })
      ),
  });

  const onSubmit = async ({ link, linkTitle, description }: FormValues) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
      },
    });
    setIsUploading(true);
    dispatch(
      addLinkAttachment(
        accessToken,
        recordType,
        recordID,
        link,
        linkTitle,
        description,
        companyID,
        projectID,
        moduleID,
        setIsUploading,
        snackbarConstants
      )
    );
    formik.resetForm();
    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      link: "",
      linkTitle: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <StyledDialog maxWidth="sm" open={isOpen} onClose={closeAttachment}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Attach link to {objectName || recordType}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <IppTextField
                id="linkTitle"
                label={t("objPlt:objects.attachment.fields.linktitle")}
                isEditing={true}
                setIsEditing={null}
                onChangeFunction={formik.handleChange}
                value={formik.values.linkTitle}
                touchedExpression={formik.touched.linkTitle}
                errorsExpression={formik.errors.linkTitle}
              />
            </Grid>
            <Grid item xs={12}>
              <IppTextField
                id="link"
                label={t("objPlt:objects.attachment.fields.hyperlink")}
                isEditing={true}
                placeholder="http://..., https://..., ftp://..."
                setIsEditing={null}
                onChangeFunction={formik.handleChange}
                value={formik.values.link}
                touchedExpression={formik.touched.link}
                errorsExpression={formik.errors.link}
              />
            </Grid>
            <Grid item xs={12}>
              <IppTextField
                id="description"
                label={t("objPlt:objects.attachment.fields.description")}
                isEditing={true}
                setIsEditing={null}
                onChangeFunction={formik.handleChange}
                value={formik.values.description}
                touchedExpression={formik.touched.description}
                errorsExpression={formik.errors.description}
              />
            </Grid>
            <Grid item xs={12}>
              <IppFormButtons
                isEditing={true}
                setIsEditing={null}
                isAdding={true}
                showCancel={true}
                resetFunction={closeAttachment}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </form>
    </StyledDialog>
  );
};
