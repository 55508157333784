import axiosClient from "../axiosClient";

export interface IssueComment {
  IssueCommentID: number;
  InteractionCommentID: number;
  IssueID: number;
  IssueName: string;
}

export interface IssueCommentsResult {
  issueComments: IssueComment[];
}

export async function getIssueComments(
  accessToken: String
): Promise<IssueCommentsResult> {
  const url = `/issueComment`;

  try {
    const { data } = await axiosClient.get<IssueCommentsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getIssueCommentsByInteraction(
  accessToken: String,
  interactionID: number
): Promise<IssueCommentsResult> {
  const url = `/issueComment/${interactionID}`;

  try {
    const { data } = await axiosClient.get<IssueCommentsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getIssueCommentsByComment(
  accessToken: String,
  interactionCommentID: number
): Promise<IssueCommentsResult> {
  const url = `/issueComment/${interactionCommentID}`;

  try {
    const { data } = await axiosClient.get<IssueCommentsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createIssueComment(
  accessToken: String,
  newCommInv: Partial<IssueComment>
): Promise<IssueComment> {
  const url = `/issueComment`;

  try {
    const { data } = await axiosClient.post<IssueComment>(url, newCommInv, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (error: any) {
    throw error;
  }
}

export async function updateIssueComment(
  accessToken: String,
  itemID: number,
  itemData: Partial<IssueComment>
): Promise<IssueComment> {
  const url = `/issueComment/` + itemID;

  try {
    const result = await axiosClient.post<IssueComment>(url, itemData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error: any) {
    throw error;
  }
}

export async function deleteIssueComment(
  accessToken: String,
  itemID: number
): Promise<string> {
  const url = `/issueComment/` + itemID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (error: any) {
    throw error;
  }
}
