import { IconButton, Tooltip } from "@mui/material";
import { Key } from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { pwChange } from "api/usersAPI";

const pathName = window.location.pathname;
const redirectLoc = window.location.origin + pathName;

const PasswordChangeButton = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation(["strGen"]);

  const onClickPasswordChange = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
        },
      });

      pwChange(accessToken, redirectLoc).then((data: any) => {
        window.location.href = data;
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Tooltip title={t("strGen:header.icons.passchange.tooltip")}>
      <IconButton color="inherit" onClick={() => onClickPasswordChange()}>
        <Key />
      </IconButton>
    </Tooltip>
  );
};

export default PasswordChangeButton;
