import axiosClient from "./axiosClient";

export interface NotificationTemplate {
  NotificationTemplateID: number;
  NotificationTypeID: number;
  ProjectID: number;
  SubjectLine: string;
  SummaryContent: string;
  TemplateContent: string;
  NotificationTypeName: string;
  ProjectName: string;
}

export interface NotificationTemplatesResult {
  notificationTemplates: NotificationTemplate[];
}

export async function getNotificationTemplates(
  accessToken: String
): Promise<NotificationTemplatesResult> {
  const url = `/notificationTemplate`;

  try {
    const { data } = await axiosClient.get<NotificationTemplatesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createNotificationTemplate(
  accessToken: String,
  newCommInv: Partial<NotificationTemplate>
): Promise<NotificationTemplate> {
  const url = `/notificationTemplate`;

  try {
    const { data } = await axiosClient.post<NotificationTemplate>(
      url,
      newCommInv,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateNotificationTemplate(
  accessToken: String,
  commInvID: number,
  newCommInv: Partial<NotificationTemplate>
): Promise<NotificationTemplate> {
  const url = `/notificationTemplate/` + commInvID;

  try {
    const { data } = await axiosClient.post<NotificationTemplate>(
      url,
      newCommInv,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteNotificationTemplate(
  accessToken: String,
  commInvID: number
): Promise<string> {
  const url = `/notificationTemplate/` + commInvID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    // console.log("DELDATA_PI", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}
