import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchWorkHourPeriods } from "./WorkHourPeriodSlice";
import { useParams } from "react-router-dom";

import { WorkHourPeriodForm } from "./WorkHourPeriodForm";
import LoadingIndicator from "components/LoadingIndicator";

interface WorkHourPeriodDetProps {
  periodID: string;
}

export const WorkHourPeriodDetailPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const {
    isLoading,
    error: compError,
    workHourPeriodsById,
  } = useSelector((state: RootState) => state.workHourPeriods);

  // get workHourPeriod ID from url param and attempt to find details in store
  const { periodID } = useParams<WorkHourPeriodDetProps>();
  const prID = parseInt(periodID, 10);

  const workHourPeriod = workHourPeriodsById[parseInt(periodID, 10)];

  const {
    projectComponentsById,
    error: projCompError,
    isLoading: projCompIsLoading,
  } = useSelector((state: RootState) => state.projectComponents);

  const {
    projectsById,
    error: projectError,
    isLoading: projectIsLoading,
  } = useSelector((state: RootState) => state.projects);

  useEffect(() => {
    (async () => {
      try {
        // can avoid going to the DB if we navigated from inventory
        if (
          !workHourPeriod ||
          !workHourPeriod.ProjectID ||
          !workHourPeriod.SequenceID
        ) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchWorkHourPeriods(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [workHourPeriod, dispatch, getAccessTokenSilently]);

  if (typeof prID != "number") {
    return (
      <div>
        <h1>Something went wrong...</h1>
        <div>{periodID} is not a valid Period ID</div>
      </div>
    );
  }

  if (compError) {
    return (
      <div>
        <h1>Something went wrong...</h1>
        <div>{compError.toString()}</div>
      </div>
    );
  }

  let workHourPeriodDetView =
    isLoading && projCompIsLoading && projectIsLoading ? (
      <LoadingIndicator />
    ) : (
      <div>
        {workHourPeriod && (
          <WorkHourPeriodForm workHourPeriod={workHourPeriod} />
        )}
      </div>
    );

  return <div id="workHourPeriod-detail-page">{workHourPeriodDetView}</div>;
};
