import * as React from "react";
import {
  useDraggable,
  useDroppable,
  NormalizedDragEvent,
} from "@progress/kendo-react-common";
import { GridRowProps } from "@progress/kendo-react-grid";

type ContextProps<T> = {
  reorder: (dataItem: T, direction: "after" | "before" | null) => void;
  dragStart: (dataItem: T) => void;
};

export const ReorderContext = React.createContext<ContextProps<any>>({
  reorder: () => {},
  dragStart: () => {},
});
export const DraggableRow: React.FC<GridRowProps> = ({
  dataItem,
  ...gridRowProps
}) => {
  const [dropped, setDropped] = React.useState(false);
  const [dragged, setDragged] = React.useState(false);
  const [direction, setDirection] = React.useState<"after" | "before" | null>(
    null
  );
  const [initial, setInitial] = React.useState({
    x: 0,
    y: 0,
  });
  const { dragStart, reorder } = React.useContext(ReorderContext);
  const element = React.useRef<HTMLTableRowElement>(null);
  const handlePress = (event: NormalizedDragEvent) => {
    setInitial({
      x: event.clientX - event.offsetX,
      y: event.clientY - event.offsetY,
    });
  };
  const handleDragStart = (event: NormalizedDragEvent) => {
    setDragged(true);
    dragStart(dataItem);
  };
  const handleDrag = (event: NormalizedDragEvent) => {
    if (!element.current || !dragged) {
      return;
    }
    element.current.style.transform = `translateY(${
      event.clientY - initial.y + event.scrollY
    }px)`;
  };
  const handleDragEnd = () => {
    setDragged(false);
    setDropped(false);
    setInitial({
      x: 0,
      y: 0,
    });
    // prevent right click odd behaviour
    element.current?.style.removeProperty("transform");
  };
  const handleRelease = () => {
    if (!element.current) {
      return;
    }
    element.current.style.transform = "";
  };
  const handleDragEnter = () => {
    setDropped(true);
    setDirection(null);
  };
  const handleDragOver = (event: NormalizedDragEvent) => {
    if (!element.current) {
      return;
    }
    const rect = element.current.getBoundingClientRect();
    setDirection(
      rect.top + rect.height / 2 <= event.pageY ? "after" : "before"
    );
  };
  const handleDragLeave = () => {
    setDropped(false);
    setDirection(null);
  };
  const handleDrop = () => {
    reorder(dataItem, direction);
    setDropped(false);
    setDirection(null);
  };
  useDraggable(
    element,
    {
      onPress: handlePress,
      onDragStart: handleDragStart,
      onDrag: handleDrag,
      onDragEnd: handleDragEnd,
      onRelease: handleRelease,
    },
    {
      autoScroll: false, // was "dragged", but our inventories use page instead of scroll
    }
  );
  useDroppable(element, {
    onDragEnter: handleDragEnter,
    onDragOver: handleDragOver,
    onDragLeave: handleDragLeave,
    onDrop: handleDrop,
  });
  return (
    <React.Fragment>
      {dropped && direction === "before" && (
        <tr
          style={{
            outlineStyle: "solid",
            outlineWidth: 1,
            outlineColor: "red",
          }}
        />
      )}
      <tr
        {...gridRowProps}
        ref={element}
        style={{
          userSelect: "none",
          pointerEvents: dragged ? "none" : undefined,
          opacity: dragged ? "0.8" : undefined,
          cursor: "move",
        }}
      />
      {dropped && direction === "after" && (
        <tr
          style={{
            outlineStyle: "solid",
            outlineWidth: 1,
            outlineColor: "red",
          }}
        />
      )}
    </React.Fragment>
  );
};
