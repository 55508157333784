import axiosClient from "./axiosClient";

export interface CommunityInvestmentType {
  CommunityInvestmentTypeID: number;
  CommunityInvestmentTypeName: string;
}

export interface CommunityInvestmentTypesResult {
  communityInvestmentTypes: CommunityInvestmentType[];
}

export async function getCommunityInvestmentTypes(
  accessToken: String
): Promise<CommunityInvestmentTypesResult> {
  const url = `/communityinvestmenttype`;

  try {
    const { data } = await axiosClient.get<CommunityInvestmentTypesResult>(
      url,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}
