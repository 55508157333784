import * as yup from "yup";
import { useTypedTranslation } from "utils/customHooks";
import { maxDecimalPlaces } from "validation/validationFunctions";

export const AssetPercentageValidationSchema = (
  totalPercent: number,
  initialPercentage: number | null // this is used for update
) => {
  const t = useTypedTranslation(["objBen", "strGen"]);

  return yup.object({
    CompanyID: yup
      .number()
      .positive(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.company.name"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.company.name"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.company.name"),
        })
      ),

    Percentage: yup
      .number()
      .positive(
        t("strGen:validation.positive", {
          fieldname: t("objBen:objects.assetpercentage.fields.percentage"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.asset.fields.ownerpercentage"),
        })
      )
      .min(
        0.01,
        t("strGen:validation.percentrange", {
          fieldname: t("objBen:objects.assetpercentage.fields.percentage"),
        })
      )
      .max(
        1.0,
        t("strGen:validation.percentrange", {
          fieldname: t("objBen:objects.assetpercentage.fields.percentage"),
        })
      )
      .test(
        "is-three-decimal-places",
        t("strGen:validation.maxdecimals", {
          fieldname: t("objBen:objects.assetpercentage.fields.percentage"),
          count: 1, // 3 decimal places xx.y
        }),
        function (value) {
          return maxDecimalPlaces(value, 3);
        }
      )
      .test(
        "Percentage",
        "This will cause the total percentage to be equal or less than 0%",
        (value) => {
          return initialPercentage
            ? totalPercent - value + initialPercentage > 0
            : totalPercent - value > 0;
        }
      )
      .typeError(t("strGen:validation.invalidnumber")),
  });
};
