import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { UserRole } from "api/userRoleAPI";
import {
  CurrentUser,
  PermissionDetail,
  Resource,
} from "utils/types/index.types";
import { permissions } from "./modulePermissions";

// Custom hook to check if the current user is allowed to perform a specific action on a given resource
export const usePermissions = () => {
  const { currentProfile, currentUserRoleList, currentUserRolesById } =
    useSelector((state: RootState) => state.profile);

  const userRoles: UserRole[] = currentUserRoleList.map(
    (id) => currentUserRolesById[id]
  );

  // Constructing the CurrentUser object
  const user: CurrentUser = {
    UserAccountID: currentProfile.UserAccountID,
    IsClientAdmin: currentProfile.IsClientAdmin,
    UserRoles: userRoles,
  };

  // Function to check if an action is allowed on a given resource
  const isActionAllowed = <R extends Resource>(resource: R): boolean => {
    // Client admin has full permissions
    if (user.IsClientAdmin) return true;

    // Get permissions for the module provided in the resource
    const modulePermissions = permissions[resource.module];
    if (!modulePermissions) return false;

    // Get permissions for the object type provided in the resource
    const objectPermissions = modulePermissions[resource.type];
    if (!objectPermissions) return false;

    // Get permissions for the specific CRUD action (Read not currently included in these checks) provided in the resource
    const crudActionPermissions = objectPermissions[
      resource.action
    ] as PermissionDetail<R>;
    if (!crudActionPermissions) return false;

    const { allowedRoles, condition } = crudActionPermissions;

    // If no roles are allowed to perform this action
    if (!allowedRoles || allowedRoles.length === 0) return false;

    // Find all user roles that match the allowed roles and modules returned from the permissions structure
    const matchingRoles = user.UserRoles.filter((userRole) => {
      return allowedRoles.some((allowedRole) => {
        const moduleMatches = allowedRole.module === userRole.ModuleID;
        const roleMatches = allowedRole.role === userRole.ModuleRoleID;

        // Check if the user has access to the specific project if provided in the resource
        const projectMatches =
          resource.projectID === undefined || // ignore Project check if Project ID is not provided
          userRole.ProjectID === resource.projectID;

        // Return the UserRole if all conditions are met
        return moduleMatches && roleMatches && projectMatches;
      });
    });

    // If the user does not have any matching roles
    if (matchingRoles.length === 0) return false;

    // If there's a condition function in the permissions structure, evaluate it with the matching roles. If there is no condition function, the user has passed all permissions checks
    return condition
      ? condition(user.UserAccountID, resource, matchingRoles)
      : true;
  };

  // Return the isActionAllowed function for use in components
  return { isActionAllowed };
};
